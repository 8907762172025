import React from "react";
import {StoreMetaDataContext} from "./contexts";
import {usePeriodBasedParams} from "./hooks";
import {useSbuStoreListL3} from "../DataProvider/hooks";



export const StoreMetaDataProvider = ({children, params}) => {
    // we are storing the needed meta info for branches here
    // to avoid multiple queries with potentially slightly different params

    const paramsBasedOnPeriodL3 = usePeriodBasedParams({ params })

    const {data, isLoading} = useSbuStoreListL3({
        params: {
            ...paramsBasedOnPeriodL3,
        }
    })

    return (
        <StoreMetaDataContext.Provider value={{
            storeMeta: data || [],
            isLoading: isLoading
        }}>
            {children}
        </StoreMetaDataContext.Provider>
    )
}


export const useStoreMetaData = () => {
    const context = React.useContext(StoreMetaDataContext);
    if (context === undefined) {
        throw new Error('useStoreMetaData must be used within a StoreMetaDataProvider');
    }
    return context;
}