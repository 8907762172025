export const CATEGORY = {
    'mall_type': 'Store Type',
    'store_size': 'Store Size',
    'mall_category': 'Store Subtype',
    'region': 'Region',
    'product_group': 'Product Category',
    'storeSize': 'Store Size',
    'storeType': 'Store Type',
}

export const SHORTEN_SALES_COMPANY = {
    7: "SVI",
    2: "SSM",
    142: "SMCO"
}

// defines the prefix and postfix for numeric values
export const NUMERIC_VALUE_PROPS_BY_METRIC = {
    'actual_sales': {prefix: '₱'},
    'actual_smac_sales': {prefix: '₱'},
    'actual_transactions': {},
    'actual_mall_foot_traffic': {},
    'actual_sm_store_foot_traffic': {},
    'actual_catchment_rate': {postfix: '%'},
    'actual_conversion_rate': {postfix: '%'},
    'actual_space_nsa': {prefix: '₱'},
    'actual_space_tfa': {prefix: '₱'},
    'actual_units_sold': {},
    'actual_units_sold_per_txn': {},
    'DEFAULT': {prefix: '₱'}
}
