import {useQuery} from "react-query";
import {
    appendGroupedActualSalesToOpex,
    calculateDerivedValuesPerGroup,
    convertParamsToYearMonthDate,
    convertStoreParamsToYearMonthDate,
    deriveOpexPerStoreValues,
    joinCurrentAndLastYearOpexPerStore,
    mergeOpexDataByOperationType,
    mergeOpexDataToStores,
    preProcessParams,
    regroupDataBy,
    regroupDataByNOI,
    regroupDataByPerGroup
} from "./util";
import ApiClient from "../../../common/API";
import moment from "moment/moment";
import React, {useMemo} from "react";
import {useSalesOverallPerformanceData, useSalesPerformanceData, useStorePerformanceData} from "../Sales/hooks";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../Constants/settings";
import {
    OPERATION_TYPE_LIGHT_AND_WATER,
    LOOKUP_TABLE,
    OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
    OPEX_TABLE,
    OPERATION_TYPE_OTHER_CONTROLLABLE,
    OPERATION_TYPE_OTHER_NON_CONTROLLABLE,
    OPERATION_TYPE_RENT,
    OPERATION_TYPE_REPAIR_AND_MAINTENANCE,
    OPERATION_TYPE_SALARIES_AND_WAGES,
    OPERATION_TYPE_SECURITY,
    OPERATION_TYPE_TOTAL_CONTROLLABLE,
    OPERATION_TYPE_TOTAL_NON_CONTROLLABLE,
    OPERATION_TYPE_TOTAL_OPERATING_EXPENSES, CONTROLLABLE_OPERATION_TYPES, NON_CONTROLLABLE_OPERATION_TYPES,
    PERCENT_TO_SALES_MULTIPLIER
} from "./constants";
import {OperationTypeFilterContext} from "./contexts";
import {rollupDataBy} from "../../../common/utils";

export function useAvailableCompanies() {
    const { data: companyData, isLoading: isLoadingCompanyData } = useQuery(
        ['markets', 'opex', "company_id"],
        () => {
            return ApiClient().get(`data/table/${OPEX_TABLE}/query/`, {
                params: {
                    columns: 'company_id',
                    group_by: 'company_id'
                }
            }).then(res => {

                const availableCompanies = res.data.data;
                return Array.isArray(availableCompanies)
                    ? availableCompanies.map((company) => company.company_id)
                    : [availableCompanies.company_id];
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS
        }
    );
    return {
        availableCompanies: companyData, //Array.isArray(companyData) ? companyData.map((company) => company.company_id) : [companyData.company_id], 
        isAvailableCompaniesLoading: isLoadingCompanyData
    }
};

export function useAvailableDateRange() {
    const dates = useQuery(
        ['markets', 'opex', "date_range"],
        () => {
            return ApiClient().get(`data/table/${OPEX_TABLE}/query/`, {
                params: {
                    columns: 'year_month',
                    group_by: 'year_month',
                    aggregates: 'opex_value_sum',
                    limit: 1000
                }
            }).then(res => {
                // Filter out entries with opex_value_sum === null
                const filteredData = res.data.data.filter(entry => entry.opex_value_sum !== null);

                // Extract and return only the year_month values
                const yearMonths = filteredData.map(entry => entry.year_month);

                return yearMonths.slice().sort();
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS
        }
    );
    return dates;
}

export function useOpexData({ params }) {

    const isNotGroupedByOperationType = !(params && params.columns === "operation_type");
    const hasDateParams = params?.month?.length > 0 && !!params?.year
    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasDateParams && maxDate && maxSelectedMonth && moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    

    const { data: lastYearData, isLoading: isLoadingYearData } = useQuery(
        ["opex", "overall", params, 'last_year'],
        () => {
            const lastYearParams = {
                ...params,
            }
            if (lastYearParams.year) {
                lastYearParams.year = lastYearParams.year - 1;
            }

            const processedParams = preProcessParams(
                convertParamsToYearMonthDate(lastYearParams, dayOfMonthMaxDate)
            );

            return ApiClient().get(`data/table/${OPEX_TABLE}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: "opex_value_sum"
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasDateParams
        }
    );

    const { data: opexData, isLoading: isLoadingOpexData } = useQuery(
        ["opex-data", params, convertParamsToYearMonthDate],
        () => {
            const processedParams = preProcessParams(
                convertParamsToYearMonthDate(params, dayOfMonthMaxDate)
            );
  
            return ApiClient().get(`data/table/${OPEX_TABLE}/query`, {
                params: {
                    ...processedParams,
                    aggregates: 'opex_value_sum,budget_sum'
                }
            }).then((res) => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasDateParams
        }
    );

    return {
        opexData,
        lastYearData,
        isLoading: isLoadingOpexData || isLoadingYearData
    }
}

export function useOpexTotals({ params }) {

    const {
        opexData: opexTotalsData,
        lastYearData: opexTotalsLastYearData,
        isLoading: isLoadingOpexTotalsData,
    } = useOpexData({
        params: {
            columns: 'operation_type',
            group_by: 'operation_type',
            limit: 1000,
            ...params,
        }
    });

    return {
        opexTotalsData,
        opexTotalsLastYearData,
        isLoadingOpexTotalsData
    };
}

export function useOpexPerGroup({ params }) {
    const hasDateParams = params?.month?.length > 0 && !!params?.year
    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasDateParams && maxDate && maxSelectedMonth && moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    // FIXME: repetitive call of actual sales for each grouping
    const { data: storePerformanceDataByRegion, isLoading: isLoadingStorePerformanceRegion } = useStorePerformanceData({
        params: {
            ...params,
            group_by: 'region',
            limit: 1000
        },
    });

    const actualSalesByRegion = storePerformanceDataByRegion?.map(({ region, actual_sales, ...rest }) => ({
        region,
        actual_sales, // might be actual_sales_sum property (there are 2 actual sales values in useStorePerformanceData)
    }))

    const { data: storePerformanceDataByStoreSize, isLoading: isLoadingStorePerformanceStoreSize } = useStorePerformanceData({
        params: {
            ...params,
            group_by: 'store_size',
            limit: 1000
        },
    });
    const actualSalesByStoreSize = storePerformanceDataByStoreSize?.map(({ store_size, actual_sales, ...rest }) => ({
        store_size,
        actual_sales, // might be actual_sales_sum property (there are 2 actual sales values in useStorePerformanceData)
    }))

    const { data: storePerformanceDataByStoreType, isLoading: isLoadingStorePerformanceStoreType } = useStorePerformanceData({
        params: {
            ...params,
            group_by: 'mall_type',
            limit: 1000
        },
    });
    const actualSalesByStoreType = storePerformanceDataByStoreType?.map(({ mall_type, actual_sales, ...rest }) => ({
        store_type: mall_type,
        actual_sales, // might be actual_sales_sum property (there are 2 actual sales values in useStorePerformanceData)
    }))

    const { data: storePerformanceDataByStoreSubType, isLoading: isLoadingStorePerformanceStoreSubType } = useStorePerformanceData({
        params: {
            ...params,
            group_by: 'mall_category',
            limit: 1000
        },
    });

    const actualSalesByStoreSubType = storePerformanceDataByStoreSubType?.map(({ mall_category, actual_sales, ...rest }) => ({
        store_subtype: mall_category,
        actual_sales, // might be actual_sales_sum property (there are 2 actual sales values in useStorePerformanceData)
    }))

    const valuesToSum = ['opex_value_sum', 'last_year_opex_value_sum', 'budget_sum', 'actual_sales'];

    // ------------------------------------------------------
    const {
        mergedData,
        isLoadingOpexPerStore 
    } = useOpexPerStore({
            params: {
                ...params,
                limit: 1000
            }});



    const dataByRegion = regroupDataByPerGroup(mergedData, 'region', valuesToSum)
    const dataByStoreSize = regroupDataByPerGroup(mergedData, 'store_size', valuesToSum)
    const dataByStoreType = regroupDataByPerGroup(mergedData, 'store_type', valuesToSum)
    const dataByStoreSubType = regroupDataByPerGroup(mergedData, 'store_subtype', valuesToSum)

    const appendSalesToDataByRegion = appendGroupedActualSalesToOpex(actualSalesByRegion, dataByRegion, 'region');
    const appendSalesToDataByStoreSize = appendGroupedActualSalesToOpex(actualSalesByStoreSize, dataByStoreSize, 'store_size');
    const appendSalesToDataByStoreType = appendGroupedActualSalesToOpex(actualSalesByStoreType, dataByStoreType, 'store_type');
    const appendSalesToDataByStoreSubType = appendGroupedActualSalesToOpex(actualSalesByStoreSubType, dataByStoreSubType, 'store_subtype');

    const cleanedDataByRegion = Object.fromEntries(Object.entries(appendSalesToDataByRegion).filter(([key, value]) => key !== 'null'));
    const cleanedDataByStoreSize = Object.fromEntries(Object.entries(appendSalesToDataByStoreSize).filter(([key, value]) => key !== 'null'));
    const cleanedDataByStoreType = Object.fromEntries(Object.entries(appendSalesToDataByStoreType).filter(([key, value]) => key !== 'null'));
    const cleanedDataByStoreSubType = Object.fromEntries(Object.entries(appendSalesToDataByStoreSubType).filter(([key, value]) => key !== 'null'));

    const derivedDataByRegion = calculateDerivedValuesPerGroup(cleanedDataByRegion, 'region');
    const derivedDataByStoreSize = calculateDerivedValuesPerGroup(cleanedDataByStoreSize, 'store_size');
    const derivedDataByStoreType = calculateDerivedValuesPerGroup(cleanedDataByStoreType, 'store_type');
    const derivedDataByStoreSubType = calculateDerivedValuesPerGroup(cleanedDataByStoreSubType, 'store_subtype');

    return {
        derivedDataByRegion,
        derivedDataByStoreSize,
        derivedDataByStoreType,
        derivedDataByStoreSubType,
        isLoadingOpexPerGroupData: isLoadingStorePerformanceRegion || isLoadingStorePerformanceStoreSize || isLoadingStorePerformanceStoreType || isLoadingStorePerformanceStoreSubType || isLoadingOpexPerStore
    };
}


export function useOpexAggCol() {
    return useQuery(
        [],
        () =>
            ApiClient().get(`data/table/${OPEX_TABLE}`, {

            }).then((res) => res.data),
        {
            refetchOnWindowFocus: false,
            staleTime: 600000, // 10 mins
        }
    );
}

export function useOpexLookUpTable() {
    return useQuery(
        'look-up',
        () => ApiClient().get(`data/table/${LOOKUP_TABLE}/query`, {
            params: {
                limit: 1000
            }
        }).then((res) => res.data.data),
        {
            refetchOnWindowFocus: false
        }
    )
}


export function useOpexSummaryData({ params }) {

    const {
        opexData: mtdData,
        lastYearData: mtdDataLastYear,
        isLoading: mtdIsLoading
    } = useOpexData({
        params: {
            ...params,
            columns: 'operation_type',
            group_by: 'operation_type',
            limit: 1000,
        }
    })

    const {
        opexData: ytdData,
        lastYearData: ytdDataLastYear,
        isLoading: ytdIsLoading
    } = useOpexData({
        params: {
            ...params,
            month: [1, ...(params?.month || [])],
            columns: 'operation_type',
            group_by: 'operation_type',
            limit: 1000,
        }
    })



    // get Sales and last year sales MTD 
    const { actualSales: actualSalesMTD, lastYearSales: lastYearSalesMTD, targetSales: targetSalesMTD, isLoading: isLoadingSalesMTD } = useSalesOverallPerformanceData({ params })

    //get Sales and last year YTD
    const newParams = {
        ...params,
        month: [1, ...(params?.month || [])]
    }
    const { actualSales: actualSalesYTD, lastYearSales: lastYearSalesYTD, targetSales: targetSalesYTD, isLoading: isLoadingSalesYTD } = useSalesOverallPerformanceData({ params: newParams })

    const isLoading = mtdIsLoading && ytdIsLoading;

    const consolidatedData = useMemo(() => {
        if (isLoading) {
            return []
        }

        // Create a map for easy access to MTD, YTD, MTD last year, and YTD last year data
        const createDataMap = (data) => {
            return new Map((data || []).map(item => [item.operation_type, { opex_value_sum: item.opex_value_sum, budget_sum: item.budget_sum }]));
        };

        const mtdMap = createDataMap(mtdData);
        const ytdMap = createDataMap(ytdData);
        const mtdLastYearMap = createDataMap(mtdDataLastYear);
        const ytdLastYearMap = createDataMap(ytdDataLastYear);

        // Get unique operation types from both MTD and YTD data
        const operationTypes = Array.from(new Set([...(mtdData || []), ...(ytdData || []), ...(mtdDataLastYear || []), ...(ytdDataLastYear || [])].map(item => item.operation_type)));

        return operationTypes?.map(operation_type => {
            const opex_current_ytd = ytdMap.get(operation_type)?.opex_value_sum || 0;
            const opex_current_mtd = mtdMap.get(operation_type)?.opex_value_sum || 0;
            const opex_last_year_ytd = ytdLastYearMap.get(operation_type)?.opex_value_sum || 0;
            const opex_last_year_mtd = mtdLastYearMap.get(operation_type)?.opex_value_sum || 0;
            const opex_budget_mtd = mtdMap.get(operation_type)?.budget_sum || 0;
            const opex_budget_ytd = ytdMap.get(operation_type)?.budget_sum || 0;

            return {
                operation_type,
                opex_current_ytd,
                opex_current_mtd,
                opex_last_year_ytd,
                opex_last_year_mtd,
                opex_vs_last_year_ytd: opex_last_year_ytd !== 0 ? (opex_current_ytd / opex_last_year_ytd) * 100 : 0,
                opex_vs_last_year_mtd: opex_last_year_mtd !== 0 ? (opex_current_mtd / opex_last_year_mtd) * 100 : 0,
                opex_budget_mtd,
                opex_budget_ytd,
                opex_vs_budget_mtd: opex_budget_mtd !== 0 ? (opex_current_mtd / opex_budget_mtd) * 100 : 0,
                opex_vs_budget_ytd: opex_budget_ytd !== 0 ? (opex_current_ytd / opex_budget_ytd) * 100 : 0,
            };
        });

    }, [mtdData, ytdData, mtdDataLastYear, ytdDataLastYear]);

    const salesData = [
        {
            actual_sales_mtd: actualSalesMTD,
            actual_sales_ytd: actualSalesYTD,
            last_year_sales_mtd: lastYearSalesMTD,
            last_year_sales_ytd: lastYearSalesYTD,
            vs_last_year_mtd: (actualSalesMTD / lastYearSalesMTD) * 100,
            vs_last_year_ytd: (actualSalesYTD / lastYearSalesYTD) * 100,
            budget_sales_mtd: targetSalesMTD,
            budget_sales_ytd: targetSalesYTD,
            vs_budget_sales_mtd: (actualSalesMTD / targetSalesMTD) * 100,
            vs_budget_sales_ytd: (actualSalesYTD / targetSalesYTD) * 100
        }
    ];

    const opexPercentage = [
        {
            current_percentage_mtd: 99,
            last_year_percentage_mtd: 98,
            vs_last_year_percentage_mtd: 97,
            budget_percentage_mtd: 101,
            vs_budget_percentage_mtd: 96,
            current_percentage_ytd: 85,
            last_year_percentage_ytd: 86,
            vs_last_year_percentage_ytd: 79,
            budget_percentage_ytd: 100,
            vs_budget_percentage_ytd: 102

        }
    ];

    const finalData = [...consolidatedData, ...salesData, ...opexPercentage]

    return {
        finalData,
        isLoading
    }
}


export function useOpexSummaryDataV2 ({params}) {
    
    const { opexData, lastYearData: opexLastYearData, isLoading:isLoadingOpex}= useOpexData({
        params: {
        ...params,
        columns: 'operation_type',
        group_by: 'operation_type',
        limit:1000,
        }
    })

    const lossAndShrinkParams = {
        ...params,
        month: ['1','12'],
        year: params?.year - 1
    }

    const { opexData: lossesYTDData, isLoading:isLoadingLosses}= useOpexData({
        params: {
        ...lossAndShrinkParams,
        columns: 'operation_type',
        group_by: 'operation_type',
        operation_type: OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
        limit:1000,
        }
    })

    const ytdLossShrinkageValue = lossesYTDData ? lossesYTDData[0]?.opex_value_sum :  0
 
    const {actualSales , lastYearSales, targetSales, isLoading: isLoadingSales } =useSalesOverallPerformanceData({params})
    const isLoading = isLoadingOpex || isLoadingSales || isLoadingLosses;
    
    const consolidatedData = useMemo(() => {
        if (isLoading) {
        return []
        }
    
        // Create a map for easy access to MTD, YTD, MTD last year, and YTD last year data
        const createDataMap = (data) => {
        return new Map((data || []).map(item => [item.operation_type, { opex_value_sum: item.opex_value_sum, budget_sum: item.budget_sum }]));
        };
    
        const opexDataMap = createDataMap(opexData);
        const opexLastYearMap = createDataMap(opexLastYearData);
    
        // Get unique operation types from both MTD and YTD data
        const operationTypes = Array.from(new Set([...(opexData || []), ...(opexLastYearData || [])].map(item => item.operation_type)));
    
            return operationTypes?.map(operation_type => {
            const opex_current = opexDataMap.get(operation_type)?.opex_value_sum || 0;
            const opex_last_year = opexLastYearMap.get(operation_type)?.opex_value_sum || 0;
            const opex_budget = opexDataMap.get(operation_type)?.budget_sum || 0;
            const actual_sales = actualSales
        
                return {
                    operation_type,
                    opex_current,
                    actual_sales,
                    opex_last_year,
                    opex_vs_last_year: opex_last_year !== 0 ? (opex_current / opex_last_year) * 100 : null,
                    opex_budget,
                    opex_vs_budget: opex_budget !== 0 ? (opex_current / opex_budget) * 100 :null,
                    opex_percent_to_sales: (opex_current / (actual_sales * PERCENT_TO_SALES_MULTIPLIER)) * 100, // update new formula
                };
            });
 
    }, [opexData, opexLastYearData, actualSales, lastYearSales,targetSales, isLoading]);

    const finalData = [...consolidatedData]
 
    return {
        finalData,
        ytdLossShrinkageValue,
        isLoading 
    }
}


export function useOpexCompanyData({ params, period}) {
    const isMTD = period === 'mtd';
    const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] };

  const { opexData, lastYearData, isLoading } = useOpexData({
    params: {
      ...paramsBasedOnPeriod,
      columns: 'operation_type,company_id',
      group_by: 'operation_type,company_id',
      limit:100
    },
  });

  const {data: salesData, isLoading: salesIsLoading } = useSalesPerformanceData({params: {...paramsBasedOnPeriod, group_by: 'company_id'}})

    const filteredOpexData = opexData?.filter(item => item.operation_type === 'Total Operating Expense');

    const filteredLastYearData = lastYearData?.filter(item => item.operation_type === 'Total Operating Expense');

    const finalData = useMemo(() => {

    const createDataMap = (data) => {
      return new Map((data || []).map(item => [item.company_id, { opex_value_sum: item.opex_value_sum, budget_sum: item.budget_sum }]));
    };

    const createSalesDataMap = (data) => {
        return new Map((data || []).map(item => [parseInt(item.company_id), { actual_sales: item.actual_sales, last_year_sales: item.last_year_sales }]));
      };

    const opexDataMap = createDataMap(filteredOpexData);
    const opexLastYearMap = createDataMap(filteredLastYearData);
    const salesDataMap = createSalesDataMap(salesData)

    const companyId = Array.from(new Set([...(filteredOpexData || []), ...(filteredLastYearData || [])].map(item => item.company_id)));

    return companyId?.map(company_id => {
      const current = opexDataMap.get(company_id)?.opex_value_sum || 0;
      const actualSales = salesDataMap.get(company_id)?.actual_sales || 0;
      const ly = opexLastYearMap.get(company_id)?.opex_value_sum || 0;
      const budget = opexDataMap.get(company_id)?.budget_sum || 0;
      const percentageToSales = (opexDataMap.get(company_id)?.opex_value_sum / (salesDataMap.get(company_id)?.actual_sales * PERCENT_TO_SALES_MULTIPLIER)) * 100 || 0;  // update new formula
      const income = salesDataMap.get(company_id)?.actual_sales < opexDataMap.get(company_id)?.opex_value_sum ? "-" : "+" 

      const companyID = {
        2: 'SSM',
        7: 'SVI',
        142: 'SMCO'
    }
    
      const company_name = companyID[company_id]
 
      return {
          company_name,
          income,
          current,
          actualSales,
          ly,
          vsLy: ly !== 0 ? (current / ly) * 100 : null,
          budget,
          vsBudget: budget !== 0 ? (current / budget) * 100 : null,
          percentageToSales
      };
  })}, [params, filteredOpexData, filteredLastYearData, period])

  return {
    finalData,
    isLoading,
  };
}


function useOperationTypePerBranchData(params, queryOptions) {
    return useQuery(
        ["opex-data", 'per-branch', params],
        () => {
            return ApiClient().get(`data/table/${OPEX_TABLE}/query/`, {
                params: {
                    ...params,
                    columns: ['company_id', 'branch_id', 'branch_name', 'operation_type'].join(','),
                    group_by: ['company_id', 'branch_id', 'branch_name', 'operation_type'].join(','),
                    aggregates: ['opex_value_sum', 'budget_sum'].join(','),
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            ...queryOptions
        }
    );
}

export function useOpexPerBranchData({ params, isLastYear }) {
    const {selected: selectedOperationTypes} = React.useContext(OperationTypeFilterContext)

    const hasDateParams = params?.month?.length > 0 && !!params?.year
    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasDateParams && maxDate && maxSelectedMonth && moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;
    const marker = isLastYear ? 'last_year' : 'current'
    // const listOfOperationTypes = ["Total Operating Expense", "Total Controllable Expense", "Total Non-Controllable Expense", 
    //                                 "Light & Water", "Salaries & Wages", "Security", "Repairs & Maintenance", "Other Controllable Expense", 
    //                                 "Rent", "Other Non-Controllable Expense"]
       
    const valuesToSum = ['opex_value_sum', 'budget_sum', 'actual_sales'];

    const processedParams = preProcessParams(
        convertStoreParamsToYearMonthDate({
            params: {
                ...params,
            },
            dayOfMonthMaxDate
        })
    );

    const {data: totalLightWaterOpexData, isLoading: isLoadingLightWater} = useOperationTypePerBranchData({
        ...processedParams,
        operation_type: OPERATION_TYPE_LIGHT_AND_WATER
    }, {enabled: hasDateParams && !isLoadingDateRange});

    const { data: totalSalariesWagesOpexData, isLoading: isLoadingSalariesWages } = useOperationTypePerBranchData({
        ...processedParams,
        operation_type: OPERATION_TYPE_SALARIES_AND_WAGES
    }, {enabled: hasDateParams && !isLoadingDateRange});

    const { data: totalSecurityOpexData, isLoading: isLoadingSecurity } = useOperationTypePerBranchData({
        ...processedParams,
        operation_type: OPERATION_TYPE_SECURITY
    }, {enabled: hasDateParams && !isLoadingDateRange});

    const { data: totalRepairsMaintenanceOpexData, isLoading: isLoadingRepairsMaintenance } = useOperationTypePerBranchData({
        ...processedParams,
        operation_type: OPERATION_TYPE_REPAIR_AND_MAINTENANCE
    }, {enabled: hasDateParams && !isLoadingDateRange});

    const { data: totalOtherControllableOpexData, isLoading: isLoadingOtherControllable } = useOperationTypePerBranchData({
        ...processedParams,
        operation_type: OPERATION_TYPE_OTHER_CONTROLLABLE
    }, {enabled: hasDateParams && !isLoadingDateRange});

    const { data: totalRentOpexData, isLoading: isLoadingRent } = useOperationTypePerBranchData({
        ...processedParams,
        operation_type: OPERATION_TYPE_RENT
    }, {enabled: hasDateParams && !isLoadingDateRange});

    const { data: totalOtherNonControllableOpexData, isLoading: isLoadingOtherNonControllable } = useOperationTypePerBranchData({
        ...processedParams,
        operation_type: OPERATION_TYPE_OTHER_NON_CONTROLLABLE
    }, {enabled: hasDateParams && !isLoadingDateRange});


    const baseOpexData = React.useMemo(() => {
        return [
            ...(totalLightWaterOpexData || []),
            ...(totalSalariesWagesOpexData || []),
            ...(totalSecurityOpexData || []),
            ...(totalRepairsMaintenanceOpexData || []),
            ...(totalOtherControllableOpexData || []),
            ...(totalRentOpexData || []),
            ...(totalOtherNonControllableOpexData || []),
        ].filter(row => {
            return !selectedOperationTypes || selectedOperationTypes.length === 0 || selectedOperationTypes.includes(row.operation_type)
        })
    }, [selectedOperationTypes, totalOtherNonControllableOpexData, totalRentOpexData, totalOtherControllableOpexData, totalRepairsMaintenanceOpexData, totalSecurityOpexData, totalSalariesWagesOpexData, totalLightWaterOpexData])

     // instead of fetching total controllable, sum up the controllable items
    const totalControllableOpexData = React.useMemo(() => {
        if (!baseOpexData) {
            return [];
        }

        return rollupDataBy(
            baseOpexData.filter(row => CONTROLLABLE_OPERATION_TYPES.includes(row.operation_type)),
            ['branch_id', 'branch_name', 'company_id'],
            ['budget_sum', 'opex_value_sum']
        ).map(row => {
            return {
                ...row,
                operation_type: OPERATION_TYPE_TOTAL_CONTROLLABLE
            }
        })
    }, [baseOpexData])

    // instead of fetching total non controllable, sum up the non-controllable items
    const totalNonControllableOpexData = React.useMemo(() => {
        if (!totalRentOpexData || !totalOtherNonControllableOpexData) {
            return [];
        }

        return rollupDataBy(
            baseOpexData.filter(row => NON_CONTROLLABLE_OPERATION_TYPES.includes(row.operation_type)),
            ['branch_id', 'branch_name', 'company_id'],
            ['budget_sum', 'opex_value_sum']
        ).map(row => {
            return {
                ...row,
                operation_type: OPERATION_TYPE_TOTAL_NON_CONTROLLABLE
            }
        })
    }, [baseOpexData])

    const totalOpexData = React.useMemo(() => {
        if (!totalNonControllableOpexData || !totalControllableOpexData) {
            return [];
        }

        return rollupDataBy(
            [...totalNonControllableOpexData, ...totalControllableOpexData],
            ['branch_id', 'branch_name', 'company_id'],
            ['budget_sum', 'opex_value_sum']
        ).map(row => {
            return {
                ...row,
                operation_type: OPERATION_TYPE_TOTAL_OPERATING_EXPENSES
            }
        })
    }, [totalNonControllableOpexData, totalControllableOpexData])

    const { data: totalNOIOpexData, isLoading: isLoadingNOI } = useQuery(
        ["opex-data", "income", "ytd_income", params, `${marker}`],
        () => {
            const processedParams = preProcessParams(
                convertStoreParamsToYearMonthDate({
                    params: {
                        ...params,
                        month: [Math.max(...params.month)],  // for remarks or NOI, we only need the latest data
                        operation_type: "NOI",
                    }, 
                    dayOfMonthMaxDate
                })
            );

            return ApiClient().get(`data/table/${OPEX_TABLE}/query/`, {
                params: {
                    ...processedParams,
                    columns: ['company_id', 'branch_id', 'branch_name', 'operation_type', 'remarks', 'ytd_remarks'].join(','),
                    group_by: ['company_id', 'branch_id', 'branch_name', 'operation_type', 'remarks', 'ytd_remarks'].join(','),

                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasDateParams && !isLoadingDateRange
        }
    );

   
    const { data: totalLossesAndShrinkagesData, isLoading: isLoadingLossesAndShrinkages } = useQuery(
        ["opex-data", "losses-and-shrinkages", params, `${marker}`],
        () => {
            const processedParams = preProcessParams(
                convertStoreParamsToYearMonthDate({
                    params: {
                        ...params,
                        operation_type: OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
                    }, 
                    dayOfMonthMaxDate
                })
            );

            return ApiClient().get(`data/table/${OPEX_TABLE}/query/`, {
                params: {
                    ...processedParams,
                    columns: ['company_id', 'branch_id', 'branch_name', 'operation_type'].join(','),
                    group_by: ['company_id', 'branch_id', 'branch_name', 'operation_type'].join(','),
                    aggregates: ['opex_value_sum', 'budget_sum'].join(','),
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasDateParams && !isLoadingDateRange
        }
    );

    const subParams = {
        ...params,
        month:['1', '12'],
        year: params?.year - 1
    }

    const { data: YTDtotalLossesAndShrinkagesData, isLoading: isLoadingYTDLossesAndShrinkages } = useQuery(
        ["opex-data", "ytd-losses-and-shrinakage", 'ytd', params, `${marker}`],
        () => {
            const processedParams = preProcessParams(
                convertStoreParamsToYearMonthDate({
                    params: {
                        ...subParams,
                        operation_type: OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
                    }, 
                    dayOfMonthMaxDate
                })
            );

            return ApiClient().get(`data/table/${OPEX_TABLE}/query/`, {
                params: {
                    ...processedParams,
                    columns: ['company_id', 'branch_id', 'branch_name', 'operation_type'].join(','),
                    group_by: ['company_id', 'branch_id', 'branch_name', 'operation_type'].join(','),
                    aggregates: ['opex_value_sum', 'budget_sum'].join(','),
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasDateParams && !isLoadingDateRange
        }
    );

    const groupedOpexValues = regroupDataBy([
        ...(totalOpexData || []),
        ...(totalControllableOpexData || []),
        ...(totalNonControllableOpexData || []),
        ...baseOpexData,
    ], 'branch_id', 'operation_type', valuesToSum);

    const groupedLossesAndShrinkage = regroupDataBy(totalLossesAndShrinkagesData, 'branch_id','operation_type', valuesToSum)
    const groupedYTDLossesAndShrinkage = regroupDataBy(YTDtotalLossesAndShrinkagesData, 'branch_id','operation_type', valuesToSum)
    const groupedNOIOpex = regroupDataByNOI(totalNOIOpexData, 'branch_id', ['remarks', 'ytd_remarks']);

    const modifiedGroupedYTDLossesAndShrinkage = Object.entries(groupedYTDLossesAndShrinkage).reduce((acc, [outerKey, innerObj]) => {
        const modifiedInnerObj = Object.entries(innerObj).reduce((innerAcc, [innerKey, innerValue]) => {
            const modifiedInnerKey = `${innerKey} YTD`; // Changed inner key to avoid overwrite
          
            innerAcc[modifiedInnerKey] = innerValue;
            return innerAcc;
        }, {});
    
        acc[outerKey] = modifiedInnerObj;
        return acc;
    }, {});

    for (const key in groupedYTDLossesAndShrinkage) {
        if (groupedYTDLossesAndShrinkage.hasOwnProperty(key)) {
            groupedYTDLossesAndShrinkage[key]["Losses & Shrinkages YTD"] = groupedYTDLossesAndShrinkage[key]["Losses & Shrinkages"];
            delete groupedYTDLossesAndShrinkage[key]["Losses & Shrinkages"];
        }
    }

    //Merging of data
    const mergedOpexData10 = mergeOpexDataByOperationType(groupedOpexValues, groupedLossesAndShrinkage, "Losses & Shrinkages")
    const mergedOpexData11 = mergeOpexDataByOperationType(mergedOpexData10, groupedNOIOpex, "remarks");
    const mergedOpexData12 = mergeOpexDataByOperationType(mergedOpexData11,groupedNOIOpex, "ytd_remarks");
    const mergedOpexData13 = mergeOpexDataByOperationType(mergedOpexData12, modifiedGroupedYTDLossesAndShrinkage, "Losses & Shrinkages YTD");

    return {
        opexData: mergedOpexData13,
        isLoadingOpexData: isLoadingLightWater || isLoadingSalariesWages || isLoadingSecurity || isLoadingRepairsMaintenance || isLoadingOtherControllable || isLoadingRent || isLoadingOtherNonControllable || isLoadingNOI || isLoadingLossesAndShrinkages || isLoadingYTDLossesAndShrinkages
  }
}

export function  useOpexPerStore({ params }) {

    const {
        opexData: opexLastYearStoreData,
        isLoadingOpexData: isLoadingLastYearOpexStoreData
    } = useOpexPerBranchData({
            params: {
                ...params,
                year: params.year ? params.year - 1 : null
            },
            isLastYear: true
        })
    
    const {
        opexData: opexCurrentStoreData,
        isLoadingOpexData: isLoadingCurrentOpexStoreData
    } = useOpexPerBranchData({
            params,
            isLastYear: false
        });

    const { data: storePerformanceData, isLoading: isLoadingStorePerformance } = useStorePerformanceData({
        params: {
            ...params,
            group_by: 'branch_id,store,mall_type,store_size,mall_category,region,',
            limit: 1000,
        },
    });

 

    //geting only the needed properties from storePerformanceData 
    const storeData = storePerformanceData?.map(({ branch_id, store, actual_sales, mall_type, region, store_size, mall_category, sales_per_sqm, selling_floor, ...rest }) => ({
        branch_id,
        store,
        actual_sales,
        store_type: mall_type,
        region,
        store_size,
        store_subtype: mall_category,
        sales_per_sqm,
        selling_floor
    }))


    const mergedData = joinCurrentAndLastYearOpexPerStore(opexCurrentStoreData, opexLastYearStoreData);

    const mergedToStoreData = mergeOpexDataToStores(storeData, mergedData);
   

    const storeViewData = deriveOpexPerStoreValues(mergedToStoreData);

    return {
        mergedData: storeViewData,
        isLoadingOpexPerStore: isLoadingLastYearOpexStoreData || isLoadingCurrentOpexStoreData || isLoadingStorePerformance
    };
}