import React from "react";
import TmpTSSSales from "../TmpTSSSales";
import SelectorProvider from "../common/providers/selectorProvider";
import MetricFilter from "../TmpTSSSales/Filters/MetricFilter";


export default function SMStorePerformance() {

    const toplineMetricItems = [
        'actual_sales',
        'actual_transactions',
        'basket_size',
        'actual_smac_sales',
        'actual_mall_foot_traffic',
        'actual_catchment_rate',
        'actual_sm_store_foot_traffic',
        'actual_conversion_rate',
        'actual_space_nsa',
        'actual_space_tfa',
    ]

    return (
        <SelectorProvider defaults={{
            "viewBy": "channel",
            "metric": "actual_sales",
            "period": "mtd"
        }}>
            <TmpTSSSales
                componentProps={{
                    topLineDataProvider: {
                        topLineMetricItems: toplineMetricItems,
                        enableMetricPlan: toplineMetricItems,
                        enabledVSPlan: toplineMetricItems,
                        enabledVSLastYear: toplineMetricItems,
                    },
                    salesPerformancePerGroup: {
                        enabled: true,
                        enableBULink: false
                    },
                }}
            />
        </SelectorProvider>
    )
}