import { sortByOrder } from "../../../../../common/utils";


export const sortedDisplayRows = (array, orderArray) => {
    return array?.sort((a, b) => orderArray.indexOf(b.primary) - orderArray.indexOf(a.primary));
};

const orderArray = [];
const selectedViewBy = '';

export const sortingFn = (rowA, rowB) => {
    if (orderArray) {
      return sortByOrder(rowA.original, rowB.original, orderArray, selectedViewBy)
    } else {
      return rowA?.getValue() < rowB?.getValue() ? 1 : -1
    }
  };