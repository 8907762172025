import React from "react";
import {useFilterValues} from "../../../../../common/hooks/commonDataHooks";
import {POS_TABLE_NAME} from "../../hooks";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import {useSelectorContext, useSelectorValue} from "../../../common/providers/selectorProvider";
import {usePeriodBasedParams} from "../hooks";
import ConditionalSelectComponent from "../../../../../common/components/ConditionalSelectComponent";
import {getSelectedOption} from "../../../../../common/utils";
import { preProcessParams } from "../../util";
import { convertParamsToTranDate } from "../../../Customers/util";

const defaultPivotOption = {value: 'sbu_bu_group', label: 'Business Unit Groups', newFilter: {sbu_bu_group: null}, pivotBy: 'sbu_bu_group'}

function useHigherMBUAsPivotOptions({params}) {

    const {data: sbuGroupRawData, isLoading} = useFilterValues(
        POS_TABLE_NAME,
        'sbu_bu_group',
        preProcessParams(convertParamsToTranDate(usePeriodBasedParams({params})))
    )
    const sbuGroupOptions = sbuGroupRawData?.sbu_bu_group?.map(({sbu_bu_group}) => {
        return {
            value: sbu_bu_group,
            label: sbu_bu_group,
            newFilter: {
                sbu_bu_group
            },
            pivotBy: 'pm_business_unit_desc_standardized'
        }
    }) || [];


    const options = [
        defaultPivotOption,
        ...sbuGroupOptions
    ]

    return {
        options,
        isLoading
    }
}


function usePivotOption({filters, setFilters, options}) {
    const selectedPivot = useSelectorValue('storePerformancePivotKey') || 'sbu_bu_group'
    const {setValue} = useSelectorContext()

    const selectedBUGroup = filters.sbu_bu_group || null


    const selectedOption = options?.find(
        option => option.pivotBy === selectedPivot && option.newFilter.sbu_bu_group === selectedBUGroup
    )

    const setSelectedPivot = (value) => {
        setValue('storePerformancePivotKey', value)
    }

    const applyOption = (option) => {
        setFilters({
            ...filters,
            ...option.newFilter
        })
        setSelectedPivot(option.pivotBy)
    }

    return {
        setOption: applyOption,
        option: selectedOption
    }
}

export default function ComparativePivotSelector({filters, setFilters, params}) {

    const {options, isLoading } = useHigherMBUAsPivotOptions({params})
    const {option, setOption} = usePivotOption({filters, setFilters, options})

    if (isLoading) { 
        return (<LoadingComponent/>)
    }

    return (
        <ConditionalSelectComponent
            id={'storePerformancePivotKeySelector'}
            labelId={`storePerformancePivotKeySelector-filter-label`}
            options={options}
            value={option}
            onSelectChange={(event, newValue) => {
                const rawNewValue = event?.target?.value || newValue?.value;
                const option = getSelectedOption(rawNewValue, options)
                setOption(option)
            }}
            endAdornment={true}
            isLoading={isLoading}
            clearSelected={() => {
                setOption(defaultPivotOption)
            }}
            label={'Group by:'}
            disabled={isLoading}
            disableClearable={true}
        />
    )
}