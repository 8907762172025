import React from 'react'
import { Marker, Tooltip } from 'react-leaflet'
import L from 'leaflet';
import smIcon from '../../../../../img/pin-sm.png';
import ayalaIcon from '../../../../../img/pin-ayala.png';
import robinsonsIcon from '../../../../../img/pin-robinsons.png';
import vistaIcon from '../../../../../img/pin-vista-mall.png'
import genericIcon from '../../../../../img/pin-generic-dept-store.png'
import puregoldIcon from '../../../../../img/pin-puregold.png'
import waltermartIcon from '../../../../../img/pin-walter-mart.png'
import gaisanomallIcon from '../../../../../img/pin-gaisano-mall.png'
import citymallIcon from '../../../../../img/pin-city-mall.png'
import alldayIcon from '../../../../../img/pin-allday.png'

export default function CompanyLogoMarker(props) {

    let CustomIcon = L.Icon.extend({
        options: {
            iconSize: props.iconSize
        }
        
    });

    function showMallIcon(company) {
        if (company.includes("(SM)")) {
            return new CustomIcon({iconUrl: smIcon})
        }
        switch(company?.toLowerCase()) {
            case 'sm':
                return new CustomIcon({iconUrl: smIcon})

            case 'ayala':
                return new CustomIcon({iconUrl: ayalaIcon})

            case 'robinsons':
                return new CustomIcon({iconUrl: robinsonsIcon})

            case 'puregold':
                return new CustomIcon({iconUrl: puregoldIcon})

            case 'vista':
                return new CustomIcon({iconUrl: vistaIcon})

            case 'waltermart':
                return new CustomIcon({iconUrl: waltermartIcon})

            case 'gaisano':
                return new CustomIcon({iconUrl: gaisanomallIcon})

            case 'citymall':
                return new CustomIcon({iconUrl: citymallIcon})

            case 'allday':
                return new CustomIcon({iconUrl: alldayIcon})

            default:
                return  new CustomIcon({iconUrl: genericIcon})
        }
        
    }

    return (
        <Marker position={[props.lat, props.long]} icon={ showMallIcon(props.company)}>
            <Tooltip direction="right">
                    <h6>{props.mallName}</h6>
            </Tooltip>
        </Marker>
    )
    
}