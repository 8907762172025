import { Grid } from "@mui/material";
import Section from "../../../common/components/Dashboard/Section";
import PeriodicitySelector from "./Selectors/PeriodicitySelector";
import YearSelector from "./Selectors/YearSelector";
import PeriodToggleSelector from "./Selectors/PeriodToggleSelector";
import { usePeriodValues } from "./hooks";
import OrgranizationSelector from "./Selectors/OrganizationSelector";
import BranchSelector from "./Selectors/BranchSelector";

export default function DashboardLevelFilters({showUsageFilters=false}) {
    const { isLoading, isSuccess, asDateRange: dateRangeData, data: rawData } = usePeriodValues();
    return <Section
        title={''}
        isCollapsible={false}
        customStyle={{backgroundColor: "#f6f6f8", borderRadius: ".5rem"}}
        selectorsOrFilters={<>
        {
            showUsageFilters && <>
                <Grid item xs={12} sm={2} sx={{ paddingBottom: {xs: 1, md: 0} }}>
                    <OrgranizationSelector />
                </Grid>

                <Grid item xs={12} sm={2} sx={{}}>
                    <BranchSelector />
                </Grid>
            </>
        }

                <PeriodicitySelector
                    isLoading={isLoading}
                    dateRange={dateRangeData}
                    isSuccess={isSuccess}
                    rawData={rawData}
                />
            <Grid item sm={2} xs={12} >
                <YearSelector
                    isLoadingDateRange={isLoading}
                    dateRange={dateRangeData}
                />
            </Grid>
            <Grid item sm={2} xs={12}>
                <PeriodToggleSelector />
            </Grid>
        </>}
    >
    </Section>

}