import { TABLE_CUSTOMER_ATTRIBUTES, TABLE_CUSTOMER_METRICS, TABLE_VIEW_VALUE_SEGMENTS } from "../constants";
import { USE_QUERY_DEFAULT_OPTIONS } from "../../../../Constants/settings";
import ApiClient from "../../../../common/API";
import { useQuery } from "react-query";
import { convertParamsToTranDate, convertParamsToTransactionDate, preProcessParams} from "../util";
import { useAvailableDateRangeV2 } from "../hooks";
import moment from "moment/moment";


export const useValueSegmentation = (params, useQueryOptions) => {
    const columnList = [
        'customer_group_tag',
    ]

    const aggregateList = [
        'customer_group_count_sum',
        'gross_sales_sum'
    ]

    const queryKey = [TABLE_CUSTOMER_METRICS, TABLE_VIEW_VALUE_SEGMENTS, params, columnList, aggregateList]

    return useQuery(queryKey, () => {
        const processedParams = preProcessParams(
            convertParamsToTransactionDate(params)
        );
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_METRICS}/query/`, {
            params: {
                view: TABLE_VIEW_VALUE_SEGMENTS,
                ...processedParams,
                columns: 'customer_group_tag',//columnList.join(','),
                group_by: 'customer_group_tag',//columnList.join(','),
                aggregates: 'customer_group_count_sum,gross_sales_sum',//aggregateList.join(',')
            }
        }).then(res => {
            return res.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
}

export const useValueSegmentationV2 = (params, useQueryOptions) => {
    const hasCustomerDate = params?.month?.length > 0 && !!params?.year

    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRangeV2();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const columnList = [
        'value_segment_tag',
    ]

    const aggregateList = [
        'gcr_persistent_id_count_distinct',
        'pos_sku_gross_sales_sum'
    ]
    
    const queryKey = [TABLE_CUSTOMER_ATTRIBUTES, params, columnList, aggregateList]

    const useQueryResults = useQuery(queryKey, () => {
        const processedParams = preProcessParams(
            convertParamsToTranDate(params, dayOfMonthMaxDate)
        );
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...processedParams,
                columns: columnList.join(','),
                group_by: columnList.join(','),
                aggregates: aggregateList.join(','),
                value_segment_tag__is_not_null: true,
            }
        }).then(res => {
            return res.data.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
    return {
        ...useQueryResults,
    }
}