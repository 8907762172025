import {CircleMarker, Tooltip, useMap, Pane} from "react-leaflet";
import {SwitchCoordinate} from "../../../../../common/utils"
import {tooltipData} from "./LeafletUtils";
import {Polygon} from "leaflet/src/layer/vector/Polygon";
import {useMemo} from "react";
import { BLACKLISTEDKEYS } from "../constants";
import { getFirstKey } from "../util";

export default function SalesMetricPolygon(props) {
    const map = useMap()
    const changedDimensions = props.data?.geometry?.map(SwitchCoordinate)
    const firstKey = getFirstKey(props.data)
    const toolTipDataDisplay = tooltipData(props.data,firstKey,BLACKLISTEDKEYS);

    function getCentroid(arr) {
        const pol = new Polygon(arr)
        pol.addTo(map)
        const center = pol.getCenter()
        pol.remove()
        return center
    }

    const centroid = useMemo(() => {
        if (!changedDimensions) return null

        return props.data?.centroid ? SwitchCoordinate(props.data.centroid) : getCentroid(changedDimensions)
    }, [props.data?.centroid, changedDimensions])

    const radius = props.radius ? (30 * props.radius) + 10 : 10;

    if (!props.data?.geometry) {
        return null;
    }
    return <>
        <CircleMarker
            key={centroid.toString() + "|" + radius}
            center={centroid}
            color={!props.radius ? 'gray': '#0d6efd'}
            radius={radius}
            fillOpacity={0.75}
            stroke={false}
            >
            <Tooltip sticky direction="left">
                {toolTipDataDisplay}
            </Tooltip>
        </CircleMarker>
    </>
}