import {mergeDeep} from "./index";

// These are utils made for merging objects and arrays (https://www.npmjs.com/package/deepmerge)

export const combineMerge = (target, source, options) => {
    // Combines objects at the same index in the two arrays.

	const destination = target.slice()

	source.forEach((item, index) => {
		if (typeof destination[index] === 'undefined') {
			destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
		} else if (options.isMergeableObject(item)) {
			destination[index] = mergeDeep(target[index], item, options)
		} else if (target.indexOf(item) === -1) {
			destination.push(item)
		}
	})
	return destination
}