import React from 'react';
import ReactEcharts from "echarts-for-react";
import { Card, Grid, Typography } from '@mui/material';
import { useOverviewTrendlineData } from '../../../hooks';
import LoadingComponent from '../../../../../../common/components/LoadingComponent';

export default function Trendlines({params, period, title}) {

    const isMTD = period === 'mtd'

    const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] }

    const {finalData, isLoading} = useOverviewTrendlineData({params:{...paramsBasedOnPeriod}, title})


    //improve me: implementation based on mock data structure
    const DATA_KEYS = finalData?.map(dataObj => dataObj['year']) //array of keys

    const MARKLINE_DATA = finalData?.map((dataObj,index)=>{
        return [
            {
                name: dataObj?.indicator,
                coord: [index, dataObj?.value],
                label: {
                    position: 'middle',
                    formatter: [
                    '{indicator|{b}}'
                    ].join('\n'),
                rich: {
                    indicator: {
                        color: 'red',
                        backgroundColor: '#e3e4e6',
                        padding: [2, 10],
                        borderType: 'solid',
                        borderWidth: 1,
                        borderColor: "#979797",
                        borderRadius: 20
                    },
                },
                },
                symbol: 'none'
            },
            {
                coord: [index+1, finalData[index+1]?.value]
            }
        ]
    })

    const OPTIONS = {
        animation: false,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: [
        {
            type: 'category',
            data: DATA_KEYS
        }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [     
        {
            type: 'bar',
            data: finalData,
            barWidth: '50%',
            itemStyle: {
                color:'#caddeb'
            },
            emphasis: {
                focus: 'series'
            },
            markLine: {
                lineStyle: {
                    type: 'solid',
                    width: 2,
                    color:'#c6c6c6'
                },
                data: MARKLINE_DATA
            }
        },
        ]
    };


    return <Card  variant="outlined" sx={{ borderRadius:'.5rem'}}>
        <Grid item sm={12} xs={12} sx={{ backgroundColor:'#caddeb', padding: 1.5}}> <Typography variant="h6">{ title }</Typography></Grid>
        <ReactEcharts
            option={OPTIONS}
        />
    </Card>
}
