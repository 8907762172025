import GenderDemographics from "./Gender";
import AgeDemographics from "./Age";
import { Card, Grid } from "@mui/material";
import { useContext } from "react";
import moment from "moment";
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext";
import GenderAgeInteractionComponent  from "./GenderAgeInteraction";
import { useGenderAgeInteractionData, useGenderData, useAgeGenerationData, useDemographicsData } from "./hooks";
import { getParamsBasedOnPeriod } from "../../../Customers/util";


export default function Demographics({ filters, params, storeID, period, onDropdownChange, genAgeValue }) {
    const windowSize = useContext(WindowSizeContext);


    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';
    const paramsBasedOnPeriod = getParamsBasedOnPeriod(isMTD, isQoQ, params);

    const {ageGenderInteractionData, isAgeGenderInteractionDataLoading} = useGenderAgeInteractionData({params: {...paramsBasedOnPeriod,store_id: storeID, genAgeValue: genAgeValue}})
    const { genderData, isGenderDataLoading } = useGenderData({ params: { ...paramsBasedOnPeriod, store_id: storeID }})
    const { ageGenerationData, isAgeGenerationDataLoading } = useAgeGenerationData({ params: { ...paramsBasedOnPeriod, store_id: storeID, genAgeValue: genAgeValue}})

    return <>
        <Card sx={{ p: 2, width: '100%', borderRadius:' 0 0 .5rem .5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: 'space-evenly' }} flexDirection={windowSize.isOnMobile ? 'column' : ''}>
                <Grid item xs sm={3.5} px={.5} my={.5}>
                    <GenderDemographics filters={filters} params={params} period={period} storeID={storeID} onDropdownChange={onDropdownChange} data={genderData} isLoading={isGenderDataLoading} isOnMobile={windowSize.isOnMobile} />
                </Grid>
                <Grid item xs sm={3.5} px={.5} my={.5}>
                    <AgeDemographics filters={filters} params={params} period={period} storeID={storeID} onDropdownChange={onDropdownChange} genAgeValue={genAgeValue} data={ageGenerationData} isLoading={isAgeGenerationDataLoading} isOnMobile={windowSize.isOnMobile}/>
                </Grid>
                <Grid item xs sm={5} px={.5} my={.5}>
                    <GenderAgeInteractionComponent data={ageGenderInteractionData} genAgeValue={genAgeValue} isLoading={isAgeGenderInteractionDataLoading} isOnMobile={windowSize.isOnMobile} />
                </Grid>
            </Grid>
        </Card>
    </>

}