import Grid from '@mui/material/Grid';
import {useEnvironmentSetting} from "../../../common/providers/EnvironmentSettingsProvider";

import {ENABLE_CUSTOMER_CHAIN_VIEW, SHOW_NONPROD_BANNER} from "../../../Constants/settings";
import CardGroup from "./cardGroup";
import {useCheckHasAnyAccess} from "./hooks";
import React from "react";
import {DASHBOARD_GROUPS} from "../../../Constants/customDashboards";


export default function Dashboard() {
    const showCustomerChainView = useEnvironmentSetting(ENABLE_CUSTOMER_CHAIN_VIEW)
    const showBanner = useEnvironmentSetting(SHOW_NONPROD_BANNER)
    if (showBanner) {
        console.info('This is a non-production environment. Features and datasets may not be up-to-date or fully functional.')
    }

    const processedDashboardGroupList = React.useMemo(() => {
        // if we want any custom logic to disable any dashboard item, we can do it here
        if (!DASHBOARD_GROUPS) {
            return []
        }
        return DASHBOARD_GROUPS.map(dashboardGroup => {
            return {
                ...dashboardGroup,
                contents: dashboardGroup.contents.map(dashboardItem => {
                    return {
                        ...dashboardItem,
                        disabled: dashboardItem.disabled || (dashboardItem.path === '/sbu/customers' && !showCustomerChainView)
                    }
                })
            }
        })

    }, [DASHBOARD_GROUPS, showCustomerChainView])

    const allPaths = processedDashboardGroupList.flatMap(dashboardGroup => dashboardGroup.contents.map(dashboardItem => dashboardItem.path))
    const hasAnyDashboardAccess = useCheckHasAnyAccess(allPaths)

    return (
        <Grid container spacing={2} sx={{mt: 5}}>
            {
                !!hasAnyDashboardAccess ?
                    processedDashboardGroupList.map(dashboardGroup => {
                        return <CardGroup {...dashboardGroup} key={dashboardGroup.title}/>
                    }) :
                    <>No Dashboard Available</>
            }
        </Grid>
    )
}