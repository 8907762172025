import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Polygon, SVGOverlay, Tooltip, useMap} from "react-leaflet";
import {SwitchCoordinate} from "../../../../../common/utils"
import {tooltipData} from "./LeafletUtils";
import {BLACKLISTEDKEYS} from "../constants";
import {getFirstKey} from "../util";
import {StoreAnalyticsFilterContext} from "../../contexts";

export default function MetricPolygon(props) {
    const {setFilter} = useContext(StoreAnalyticsFilterContext)

    const changedDimensions = props.data?.geometry?.map(SwitchCoordinate)

    const newPositions = !props.IS_LONG_LAT ? props.data?.geometry :  changedDimensions
    const polygonRef = useRef(null);
    const [fontSize, setFontSize] = useState(1.2);

    const textRef = useRef()
    const svgRef = useRef()
    const map = useMap()

    const eventHandlers = useMemo(
      () => ({
        click(e) {
            setFilter('region', e.target?.options?.name)
            map.fitBounds(newPositions)
        },
      }),
      [map,newPositions,props],
    )

    useEffect(() => {
        const interval = window.setInterval(() => {

            const textWidth = textRef.current?.getComputedTextLength() * 2
            const svgWidth = svgRef.current?.getBBox().width
            if (!!textWidth && !!svgWidth) {
                if (textWidth > svgWidth) {
                    setFontSize(prevFontSize => prevFontSize - 0.1);
                } else if (textWidth + 100 < svgWidth) {
                    setFontSize(prevFontSize => {
                        if (prevFontSize < 1.2) {
                            return prevFontSize + 0.1
                        }
                        return prevFontSize
                    });
                }
            }
        }, 100)

        return () => {
            window.clearInterval(interval)
        }

    }, [])

    const firstKey = getFirstKey(props.data)
    const toolTipDataDisplay = tooltipData(props.data,firstKey,BLACKLISTEDKEYS);
    const displayName = props.data ? props.data[firstKey] : "";
    const pathOptions = {
        fillColor: props.color,
        color: props.color,
        weight: 1.5,
        fillOpacity: .85
    }
    return (
        <>
            <Polygon
                ref={polygonRef}
                pathOptions={pathOptions}
                positions={newPositions}
                eventHandlers={eventHandlers}
                name={props.data?.region}>
                    <Tooltip sticky direction="right">
                        {toolTipDataDisplay}
                    </Tooltip>
            </Polygon>
            <SVGOverlay bounds={newPositions}>
                <svg height="100%" width="200%">
                    <text ref={textRef} x="25%" y="50%" dominantBaseline="middle" textAnchor="middle"
                            fill="white" filter={"drop-shadow(0px 0px 3px rgb(0 0 0 / 1))"} fontSize={`${fontSize}em`}
                    >
                        {displayName}
                    </text>
                    <rect x="0" y="0" width="100%" height="100%" fill="rgba(0,0,0,0)" ref={svgRef}/>
                </svg>
            </SVGOverlay>
        </>
    )

}