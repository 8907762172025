import React from "react";
import GraphById from "../../../../common/components/GraphFactory/graphById";
import parse from 'html-react-parser';
import Box from '@mui/material/Box';

export default function Content({contentObject, filters}) {
    const [selectorOpen, setSelectorOpen] = React.useState(false)

    if (!contentObject) {
        return (
            <>
            {/*  This is a spacer  */}
            </>

        )
    }

    if (contentObject.hasOwnProperty("text")) {
        // return (<span>
        //     {parse(contentObject.text)}
        // </span>)
        return <Box component="span"> 
            {parse(contentObject.text)}
        </Box>
    }
    if (contentObject.hasOwnProperty("chart")) {
        return <GraphById graphId={contentObject.chart} filters={filters}/>
    }

    // return (<span className="text">
    //         Placeholder content here
    //     </span>)
    return <Box component="span"> 
        Placeholder content here
    </Box>
}