import React, { useState, useMemo } from 'react';
import DataTable from "react-data-table-component";
import LoadingComponent from "../../../common/components/LoadingComponent";
import { useSystemLogs } from "../../../common/API/hooks";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';


export default function CombinedSystemLogsTable({ searchQuery }) {
    const formatDate = (value) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(value).toLocaleString(undefined, options);
    };

    const { data: loginData, isLoading: loginLoading } = useSystemLogs();

   

    const columns = [
        {
            name: 'Email',
            selector: (row) => row.actor?.username || 'N/A',
            sortable: true,
        },
        {
            name: 'Login Date',
            selector: (row) => row.created_at,
            sortable: true,
            format: (row) => formatDate(row.created_at),
        },
        {
            name: 'Success/Fail',
            selector: (row) => row.action,
            sortable: true,
            cell: (row) => (
                <span>
                    {row.action === "login_success" ? (
                        <CheckIcon style={{ color: 'green' }} />
                    ) : row.action === "login_failed" ? (
                        <React.Fragment>
                            <ClearIcon style={{ color: 'red' }} />
                            {` ${row.message}`} {/* Display the message */}
                        </React.Fragment>
                    ) : null}
                </span>
            ),
        },
    ];

    return (
        <>
            <DataTable
                columns={columns}
                data={loginData ? loginData.results : []}
                pagination
                progressPending={loginLoading}
                progressComponent={<LoadingComponent/>}
                highlightOnHover
            />
        </>
    );
}