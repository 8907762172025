import { useMemo, useState } from "react";
import Section from "../../../../common/components/Dashboard/Section";
import { Box, Grid } from '@mui/material'
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import BarIndicator from "../../common/presentation/barIndicator";
import NumericValueDisplay from "../../common/presentation/numericValueDisplay"
import {useCityPenetrationTableData} from "../hooks";
import CellWithSubRow from "../../../../common/components/DataTable/CellWithSubRow";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import { IndicatorCellBox } from "../../Customers/Transactions/BUGroupShareOfWallet";
import { convertToValidNumber } from "../../../../common/utils";
import SearchBar from "../SearchBar";

export default function MemberPenetration() {
    const {data, isLoading} = useCityPenetrationTableData({})
    const [searchQuery, setSearchQuery] = useState('')

    const information = <div style={{width: '50rem'}}>
        <div>
            In 2024 only Regional Population data is available. To estimate the Projected Population of Municipalities,
            we use the growth rate of the Regional Population from 2020 to 2024.
        </div>
        <div>
            Sample computation: Makati 2024 Projected population = NCR Growth Rate * Makati 2020 Population
        </div>
    </div>

    const filteredData = useMemo(() => {
        if (!data) return [];

        const regexPattern = new RegExp(`^${searchQuery}`, 'i');

        return searchQuery
            ? data.flatMap((row) => {
                const { pk, subRows } = row;
                const parentMatches = regexPattern.test(pk);
                const matchingSubRows = subRows?.filter(({ pk: subPk }) => regexPattern.test(subPk)) || [];

                // Return the parent row with its subrows if it matches or if there are matching subrows
                if (parentMatches || matchingSubRows.length > 0) {
                    return {
                        ...row,
                        subRows: parentMatches ? subRows : matchingSubRows,
                    };
                }

                return []; // Return an empty array if there are no matches
            })
            : data;
    }, [data, searchQuery]);


    const tableColumns = useMemo(
        () => [
            {
                id: "city",
                header: "Province / Municipality",
                accessorKey: "pk",
                cell: ({row, getValue}) => {
                    const value = getValue();
                    return (
                        <CellWithSubRow row={row} marginLeftMinimum={1}>
                            {value} 
                        </CellWithSubRow>
                    )
                },
                customStyle: {
                    width: "15rem",
                    textAlign: "left",
                    verticalAlign: "middle",
                },
            },
            {
                id: "city_population",
                header: "Population",
                accessorKey: "city_population",
                cell: (row) => (
                    <Box sx={{ width: { sm: '40%' }, marginX: 'auto', my: .5 }}>
                        <NumericValueDisplay value={row?.getValue()} />
                    </Box>
                ),
                customStyle: {
                    width: "15rem",
                    textAlign: "left",
                    verticalAlign: "middle",
                },
            },
            {
                id: "smac_members",
                header: "Total member count",
                accessorKey: "smac_members",
                cell: (row) => (
                    <Box sx={{ width: { sm: '20%' }, marginX: 'auto' }}>
                        <NumericValueDisplay value={row?.getValue()} />
                    </Box>
                ),
                customStyle: {
                    width: "15rem",
                    textAlign: "left",
                    verticalAlign: "middle",
                },
            },
            {
                id: "vs_last_year_smac_members",
                header: "vs Last Year",
                accessorKey: "vs_last_year_smac_members",
                cell: (row) => (
                    <IndicatorCellBox>
                        {   row?.getValue() !== 0 ?
                            <BarIndicator colorFunctionFor={'SBU'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" /> 
                            : '-'
                        }
                    </IndicatorCellBox>
                ),
                customStyle: { 
                    width: '10rem', 
                    height: '2rem', 
                    textAlign: 'center', 
                    verticalAlign: 'middle' 
                },
            },
            {
                id: "penetration_penetration",
                header: "% Penetration",
                accessorKey: "penetration_perc",
                cell: (row) => (
                    <Box sx={{ width: { sm: '20%' }, marginX: 'auto' }}>
                        <NumericValueDisplay value={row.getValue()} isAbbreviated={true} postfix={'%'}/>
                    </Box>
                ),
                customStyle: {
                    width: "15rem",
                    textAlign: "left",
                    verticalAlign: "middle",
                },
            },
            {
                id: "vs_last_year_penetration",
                header: "vs Last Year",
                accessorKey: "vs_last_year_penetration",
                cell: (row) => (
                    <IndicatorCellBox>
                        {   row?.getValue() !== 0 ?
                            <BarIndicator colorFunctionFor={'SBU'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" /> 
                            : '-'
                        }
                    </IndicatorCellBox>
                ),
                customStyle: { 
                    width: '10rem', 
                    height: '2rem', 
                    textAlign: 'center', 
                    verticalAlign: 'middle' 
                },
            },
            {
                id: "active_member_count",
                header: "Active member count",
                accessorKey: "smac_active_members",
                cell: (row) => (
                    <Box sx={{ width: { sm: '20%' }, marginX: 'auto' }}>
                       <NumericValueDisplay value={row?.getValue()} />
                    </Box>
                ),
                customStyle: {
                    width: "15rem",
                    textAlign: "left",
                    verticalAlign: "middle",
                },
            },
            {
                id: "active_member_count_vsLastYear",
                header: "vs Last Year",
                accessorKey: "vs_last_year_smac_active_members",
                cell: (row) => (
                    <IndicatorCellBox>
                        {   row?.getValue() !== 0 ?
                            <BarIndicator colorFunctionFor={'SBU'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" /> 
                            : '-'
                        }
                    </IndicatorCellBox>
                ),
                customStyle: { 
                    width: '10rem', 
                    height: '2rem', 
                    textAlign: 'center', 
                    verticalAlign: 'middle' 
                },
            },
        ],
        []
    );

    if(isLoading){
        return <LoadingComponent />
    }
    
    return <Section
        title={'Member Penetration by Municipality'} 
        information={information} 
        selectorsOrFilters={ <Grid item xs={12} sm={3} sx={{ mx: 1}}><SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} label={'Search province / municipality'}/></Grid>}
        >
            <TableComponentV2
                columns={tableColumns}
                data={filteredData || []}
                tableId="memberPenetrationTable"
                isCompact
                customTableHeaderStyle={{ backgroundColor: "#caddeb" }}
                customTableContainerStyle={{ borderRadius: 0, boxShadow: "none" }}
                hasPagination
                />
    </Section>

}