import IndicatorFromValue from "./IndicatorFromValue"
import { convertToValidNumber, displayNumber } from "../../../common/utils"
import { WindowSizeContext } from "../../../common/context/WindowSizeContext";
import { useContext } from "react";
import { Typography, Box, Grid } from "@mui/material";
import BarIndicator from "../common/presentation/barIndicator";


export default function SalesIndicator({ value, label, labelValue, labelPosition, isOnTable }) {
    const val = labelValue ? labelValue : 0

    const newVal = typeof val === 'string' ? (Number.isNaN(parseFloat(val)) ? 0 : parseFloat(val)) : val
    const windowSize = useContext(WindowSizeContext);
    const valDisplay = newVal !== 0 ? `₱ ${displayNumber(newVal, windowSize.isOnMobile)}` : 'Not Applicable'

    function RenderContent() {
        switch (labelPosition) {
            case 'bottom':
                return <>
                    <Grid item sm={12} xs={12} justifyContent="center" alignItems="center" sx={{ height: '2.5rem' }}>
                        <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(value)?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={isOnTable} label={label} />
                    </Grid>

                    <Grid item sm={12} xs={12} textAlign="center">
                        ( {valDisplay} )
                    </Grid>
                </>
            case 'right':
                return <>
                    <Grid sx={{ my: 1 }} item md={2} sm={2} xs={5} alignSelf={"center"}>
                        <Box sx={{ width: '5rem', height: '2rem', textAlign: 'center' }}>
                            <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(value)?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={isOnTable} label={label} /> </Box>
                    </Grid>
                    <Grid item md={10} sm={10} xs={7} alignSelf={"center"}>
                        <Typography display={!windowSize.isOnMobile && 'flex'}>
                            {label}
                            {windowSize.isOnMobile && <br />}
                            <Typography sx={{ ml: 1, fontStyle: 'italic' }}>({valDisplay})</Typography>
                        </Typography>
                    </Grid>
                </>
            default:
                return <Grid item alignSelf={"center"} height={"4rem"}>
                    <IndicatorFromValue data={convertToValidNumber(value)?.toFixed(1)} />
                </Grid>
        }
    }

    return <Grid container data-tag="allowRowEvents" xs={12}>
        {RenderContent()}
    </Grid>

}