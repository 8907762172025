import React, { useMemo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Link, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableComponentV2 from '../../../../../../../common/components/TableComponentV2';
import { FormatPercentage } from '../utils';
import { LightTooltip } from './LightTooltip';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { blue } from '@mui/material/colors';

export const SMACPenetrationAccordion = ({
    cityPopDataIsLoading,
    smacPenetrationTable,
    setSelectedBarangay
}) => {
    const handleBarangayClick = (e, text) => {
        e.preventDefault();
        setSelectedBarangay(text)
    };
    
    const columns = useMemo(
        () => [
            {
                id: 'smacPenetrationTable',
                columns: [
                    {
                        id: 'barangay',
                        header: 'Barangay',
                        accessorKey: 'barangay_name',
                        cell: ({ row, getValue }) => (
                            <Link
                                component="span"
                                underline="hover"
                                style={{ cursor: 'pointer', color: 'black', fontSize: '16px' }}
                                onClick={(e) => handleBarangayClick(e, getValue())}
                                >
                                {getValue()}
                            </Link>
                        ),
                        customStyle: { textAlign: 'flex-start' },
                    },
                    {
                        id: 'population',
                        header: 'Population',
                        accessorKey: 'population_projected_2024',
                        cell: ({ row, getValue }) => (
                            <Typography>{getValue()}</Typography>
                        ),
                        customStyle: { textAlign: 'center' },
                    },
                    {
                        id: 'smac_members',
                        header: 'SMAC Members',
                        accessorKey: 'smac_member_count',
                        cell: ({ row, getValue }) => (
                            <Typography>{getValue()}</Typography>
                        ),
                        customStyle: { textAlign: 'center' },
                    },
                    {
                        id: 'smac_penetration',
                        header: '%Penetration',
                        accessorKey: 'smac_penetration_rate',
                        cell: ({ row, getValue }) => (
                            <FormatPercentage number={getValue()} decimalPlaces={2}/>
                        ),
                        customStyle: { textAlign: 'center' },
                    },
                    {
                        id: '%P6M',
                        header: '%P6M',
                        accessorKey: '%P6M',
                        cell: ({ row, getValue }) => (
                            <FormatPercentage number={getValue()} decimalPlaces={1}/>
                        ),
                        customStyle: { textAlign: 'center' },
                    },
                ],
            },
        ],
        [smacPenetrationTable],
    );
    return (
        <Accordion sx={{ width: '100%' }} disabled={cityPopDataIsLoading} defaultExpanded>
            <AccordionSummary
                sx={{ backgroundColor: '#F6F6F8', color: '#00000', fontWeight: 700 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <span style={{ marginRight: '10px' }}>SMAC Penetration</span>
                <LightTooltip title="Percentage of the population that are active SMAC Members." placement='top' >
                    <InfoRoundedIcon color='info'/>
                </LightTooltip>

                {cityPopDataIsLoading ? (
                    <CircularProgress size="1.5rem" sx={{ color: blue[500], marginLeft: '10px' }} />
                    ) : (
                    null
                )}

            </AccordionSummary>
            <AccordionDetails>
                {smacPenetrationTable !== undefined && (
                    <TableComponentV2
                        data={smacPenetrationTable}
                        tableId="smac_penetration"
                        columns={columns}
                        customTableContainerStyle={{
                            borderRadius: '0',
                            boxShadow: 'none',
                        }}
                        customTableHeaderStyle={{
                            backgroundColor: '#caddeb',
                        }}
                        hasPagination
                        isCompact
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
};