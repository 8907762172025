import React from "react";
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import IconButton  from '@mui/material/IconButton';
import SortIcon from '@mui/icons-material/Sort';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


export default function ToggleSort({sortDirection, setSortDirection}) {

    return <>
        <Stack direction='row' spacing={1} >
                <Typography variant="subtitle2" sx={{alignSelf:'center'}}> 
                    Sort by:
                </Typography>

                <IconButton sx={{p:0, '&:hover': { backgroundColor: 'transparent'}}} onClick={()=> {setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc')}}>
                    { sortDirection === "desc" ? 
                        
                        <Stack direction={'row'}  sx={{backgroundColor:'#0F2FEB', color:'white', borderRadius:'5px', p:.5}}>
                            <ArrowRightAltIcon sx={{transform:'rotate(90deg)', ml:-0.5, fontSize:'20px'}}/>
                            <SortIcon sx={{ml:-1, fontSize:'20px'}}/>
                        </Stack>
                         :
                         <Stack direction={'row'}  sx={{backgroundColor:'#0F2FEB', color:'white', borderRadius:'5px', p:.5}}>
                         <ArrowRightAltIcon sx={{transform:'rotate(270deg)', ml:-0.5, fontSize:'20px'}}/>
                         <SortIcon sx={{ml:-1, fontSize:'20px'}}/>
                     </Stack>
                    }
                    
                </IconButton>
            </Stack>
    </>
}