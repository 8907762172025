import { Grid, Typography, Card, GlobalStyles, Portal, Box } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GroupSelector from '../../selectors/groupSelector';
import ToggleSelector from '../../selectors/toggleSelector';
import { WindowSizeContext } from '../../../../../../common/context/WindowSizeContext';
import { RenderCustomerViewContent } from './renderCustomerViewContent';
import { useFiltersContext, useTogglePeriodContext } from '../Provider/context';
import CustomerTopline from '../../CustomerTopline';

const VIEW_BY = [
    { label: 'Demographics', value: 'demographics', disabled: false },
    { label: 'Transactions', value: 'transactions', disabled: false },
    { label: 'Segments', value: 'segments', disabled: true },
    { label: 'Cross-shopping', value: 'cross-shopping', disabled: true },
]

const TOGGLE_OPTIONS = [
    { label: 'Age', value: 'age_bin' },
    { label: 'Generation', value: 'generation_bin' },
]

export default function Customer({ togglePeriodValue, onTogglePeriodChange }) {
    const { branch, branch_id, month, year } = useParams()
    const { filters, setFilter } = useFiltersContext();
    const { period, updatePeriod } = useTogglePeriodContext();
    const [viewBy, setViewBy] = useState('demographics') // set demographics as default
    // const [togglePeriodState, setTogglePeriodState] = useState(period)
    const windowSize = useContext(WindowSizeContext)
    const [scrolling, setScrolling] = useState(false);
    const [prevScrollPos, setPrevScroll] = useState(0);
    
    
    // TODO: uncomment once YTD and QoQ are available for customer count values
    // useEffect(() => {
    //     onTogglePeriodChange(null, togglePeriodValue);
    //     updatePeriod(togglePeriodValue);
    // }, [togglePeriodValue])
    
    useEffect(() => {
        setFilter('month', month);
        setFilter('year', year);
    }, [month, year])

    const [toggleGenerationAge, setToggleGenerationAge] = useState('age_bin')

    const params = {
        month: [Number(month)],
        year: Number(year),
    }

    const onToggleGenerationAgeChange = (event, val) => {
        if (val !== null) {
            setToggleGenerationAge(val);
        }
    };
    // const [filters, setFilters] = useState({
    //     group: "channel",
    //     month: params?.month,
    //     year: params?.year,
    //     metric_column: 'actual_sales',
    //     standardized_business_unit: null,
    //     sbu_higher_mbu: null
    // })

    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        setSelectedRows([])
    }, [filters.group, filters.metric_column])


    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;

            // Set scrolling state based on scroll position and duration
            if (currentScrollPos <= 100) {
                setScrolling(false);
            } else {
                setScrolling(true);
            }

            setPrevScroll(currentScrollPos);
            // Close the tooltip when scrolling
            // setTooltipOpen(false);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, windowSize.isOnMobile]);

    return <>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />
        <Grid item xs={12} >
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem .5rem 0 0" }} mt={1}>
                <Grid container alignItems={'center'} paddingY={1} >
                    <Typography variant="h6" ml={3}> Customer Information </Typography>
                </Grid>
            </Box>
            <CustomerTopline
                params={{
                    ...params,
                    store_id: branch_id
                }}
                period={period} viewBy={'branch'} isOnDefaultView={true}
            />
        </Grid>
        <Grid item xs={12} sx={{ backgroundColor: '#e0e0e0' }}>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem .5rem 0 0" }} mt={3}>
                <Grid container alignItems={'center'} columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} >
                    {
                        windowSize.isOnMobile ?
                            <>
                                <Grid item xs sm={1} ml={2.5}>
                                    <Typography variant="h6"> View By </Typography>
                                </Grid>
                                <Grid item sm display='flex' justifyContent='flex-end' >
                                    {viewBy !== 'transactions' &&
                                        <ToggleSelector
                                            options={TOGGLE_OPTIONS}
                                            value={toggleGenerationAge}
                                            onChange={onToggleGenerationAgeChange}
                                            additionalStyles={{ textTransform: 'none' }}
                                            lastIndexOfMain={2}
                                        />}
                                </Grid>
                                <Grid item xs={12} sm={7} mx={windowSize.isOnMobile && 2}>
                                    <GroupSelector options={VIEW_BY}
                                        onSelectGroupChange={(value) => setViewBy(value)} //strings value
                                        selected={viewBy} />
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs sm={1} ml={2.5}>
                                    <Typography variant="h6"> View By </Typography>
                                </Grid>
                                <Grid item xs={12} sm={7} mx={windowSize.isOnMobile && 2}>
                                    <GroupSelector options={VIEW_BY}
                                        onSelectGroupChange={(value) => setViewBy(value)} //strings value
                                        selected={viewBy} />
                                </Grid>
                                <Grid item sm display='flex' justifyContent='flex-end' >
                                    {viewBy !== 'transactions' &&
                                        <ToggleSelector
                                            options={TOGGLE_OPTIONS}
                                            value={toggleGenerationAge}
                                            onChange={onToggleGenerationAgeChange}
                                            additionalStyles={{ textTransform: 'none' }}
                                            lastIndexOfMain={2}
                                        />}
                                </Grid>
                            </>
                    }
                </Grid>
            </Box>
            <RenderCustomerViewContent viewBy={viewBy} params={params} period={period} store={branch} storeID={branch_id} genAgeValue={toggleGenerationAge} onTogglePeriodChange={updatePeriod} filters={filters} onDropDownFilterChange={setFilter} />
        </Grid>
    </>
}