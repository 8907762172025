import React, { useContext }from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import {  useSalesOverallPerformanceData } from "../hooks";
import SalesIndicator from '../SalesIndicator';
import moment from 'moment';
import {  Grid, Typography } from '@mui/material';
import { displayNumber } from '../../../../common/utils';

export default function YearToDateSalesOverallPerf({ params }) {
    const newParams = {
        ...params,
        month: [1, ...(params?.month || [])]
    }
    const {
        actualSales,
        targetSales : YTDTargetSales,
        lastYearSales,
        dailyTargetSales,
        isLoading: isLoadingSalesData

    } = useSalesOverallPerformanceData({ params: newParams });
    const windowSize = useContext(WindowSizeContext);
    const year = params?.year
    let monthDisplay = params?.month?.[0] && moment().month(0).format('MMM') + '-' + moment().month(params?.month?.[0] - 1).format('MMM');
    const targetSalesDisplay = YTDTargetSales !== 0 ? `₱ ${displayNumber(YTDTargetSales, windowSize.isOnMobile)}` : '--';
    if (params?.month?.length > 1) {
        const maxMonth = Math.max(...params?.month) - 1 //minus since to match the actual month since we're using start of index 1 as month value

        monthDisplay = moment().month(0).format('MMM') + '-' + moment().month(maxMonth).format('MMM')
    }

    if (isLoadingSalesData) {
        return <LoadingComponent />
    }

    return <Grid container spacing={.25} paddingX={5} paddingY={.5} mt={0}>
        <Grid item sm={12} xs={12} display={"flex"}>
            <Typography variant='h6' style={{ color: '#000' }}> YTD Sales </Typography>                    
            <Typography variant='h6'ml={2} > ({monthDisplay} {year})</Typography>
        </Grid>
        <Grid item sm={12} xs={12}> 
            <Typography variant='h5' fontWeight='500' style={{ color: '#000' }} >
            {
                actualSales === undefined ?
                    '--' :
                    <>
                        ₱ {displayNumber(actualSales, windowSize.isOnMobile)}
                    </>
            }   
            </Typography>
            <Typography>
                YTD target: { targetSalesDisplay }
            </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
            <SalesIndicator labelValue={dailyTargetSales} label={'vs Running Daily Target'} labelPosition="right" value={(actualSales / dailyTargetSales) * 100} />
            <SalesIndicator labelValue={lastYearSales} label={'vs Last Year'} labelPosition="right" value={(actualSales / lastYearSales) * 100} />
        </Grid>
    </Grid>

}