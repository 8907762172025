import { useState } from "react";
import ApiClient from "../../../common/API";
import { useQueryClient } from "react-query";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function useTableRefresh() {
    const [isLoading, setIsLoading] = useState(null)
    const queryClient = useQueryClient()
    const refresh = tableId => {
        setIsLoading(true)
        return ApiClient().post(`data/table/${tableId}/refresh_columns/`)
            .then(response => {
                setIsLoading(false)
                queryClient.invalidateQueries(['data', 'table', tableId])
                return response.data
            })
    }
    return { refresh, isLoading }
}

// Custom hook for the test connect 
export function useTestConnect() {
    const testConnect = data => {
        return ApiClient().post('data/hosts/test_connect/', data)
            .then(response => {
                console.log(response.data)
                return toast.success('Test connect successful!');
            }).catch(error => {
                console.error(error);
                return toast.error("Test connect failed!");
            })
    }
    return { testConnect }
}

// Custom hook for saving hosts
export function useSave() {
    const queryClient = useQueryClient();
    const saveHost = data => {
        return ApiClient().post('data/hosts/', data)
            .then(() => {
                queryClient.invalidateQueries(['schema', 'list'])
                queryClient.invalidateQueries(['table', 'list'])
            })
    }
    return { saveHost }
}