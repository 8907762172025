import React from 'react';
import { Grid } from '@mui/material';
import Ranking from './ranking';
import TrendLinesContainer from './trendline';
import ToplineMetrics from './topLineMetrics';

export default function MerchandiseOveriew({params, period}) {

    return <>
        <ToplineMetrics params={params} period={period}/>

        <TrendLinesContainer params={params} period={period}/>

        <Ranking params={params} period={period}/>
    </>
}