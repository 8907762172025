import React, { useContext, useMemo } from 'react';
import ReactEcharts from "echarts-for-react";
import { Card, Divider, Grid, Typography } from '@mui/material';
import { abbreviateNumber, displayNumber, replaceLast } from '../../../../common/utils';
import { titleCaseString } from '../../common/util';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import LoadingComponent from '../../../../common/components/LoadingComponent';


export default function HorizontalBarGraph({ data, isLoading }) {
    
    const windowSize = useContext(WindowSizeContext);
    const isOnMobile = windowSize?.isOnMobile;

    const groupKeys = data ? Object.keys(data) : [];    
    const values = data ? Object.values(data) : [];
    const sumValue = [values.reduce((acc, curr) => acc + curr, 0)];
    // const HEX_CODES = ['#CADDEB', '#7E95C7', '#A2B2D2', '#AFCDFC', '#84BFF7', '#4E88DD'];

    const BG_COLOR_MAPPING = {
        'Email Only': '#84BFF7',
        'Mobile Only': '#60A3D9',
        'With Mobile and Email': '#4E88DD',
        'No Contact Info Provided': '#AFCDFC',
    };

    const options = {
        animation: false,
        tooltip: {
            show: false
        },
        grid: {
            top: isOnMobile ? 25 : 60, // TODO: check react echarts docs for other params of grid (i.e. left, right, bottom)
            left: 300,
            right: 100,
            bottom: 15,
        },
        xAxis: {
            show: true,
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisLabel: {
                show: false,
            }
        },
        yAxis: {
            show: true,
            type: 'category',
            data: groupKeys,
            inverse: true,
            animationDuration: 300,
            animationDurationUpdate: 300,
            axisLabel: {
                show: true,
                // fontSize: 14,
                color: 'black',
                fontFamily: "Henry Sans",
                formatter: function (value) {
                    return '{label|' + value + '}';
                },
                rich: {
                    label: {
                        fontSize: 14,
                        padding: isOnMobile ? 40 : 50
                    }
                }
            },
            splitLine: {
                show: false // TODO: sow only the last split line
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            //max: 3 // only the largest 4 bars will be displayed
        },
        series: [
            {
                data: values.map((value, index) => ({
                    value,
                    itemStyle: {
                        color: BG_COLOR_MAPPING[groupKeys[index]]
                    }
                })),
                type: 'bar',
                barWidth: '100%',
                label: {
                    fontSize: 14,
                    fontFamily: "Henry Sans",
                    color: 'black',
                    show: true,
                    // position: 'inside',
                    position: isOnMobile ? [5, 27] : [5, 33],
                    formatter: function (obj) {
                        return displayNumber(obj.value); // Display the legitimate values
                    }
                }
            }
        ]
    };

    const totalOptions = {
        animation: false,
        tooltip: {
            show: false
        },
        grid: {
            top: isOnMobile ? -30 : -60,
            left: 300,
            right: 100,
            bottom: 0,
        },
        xAxis: {
            show: true,
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisLabel: {
                show: false,
            }
        },
        yAxis: {
            show: true,
            type: 'category',
            data: ['Grand Total'],
            inverse: true,
            animationDuration: 300,
            animationDurationUpdate: 300,
            axisLabel: {
                show: true,
                fontFamily: "Henry Sans",
                color: 'black',
                fontWeight: 'bold',
                fontSize: 16,
                padding: isOnMobile ? 40 : 50
            },
            splitLine: {
                show: false // TODO: sow only the last split line
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
        },
        series: [
            {
                data: sumValue, // [318543]
                itemStyle: {
                    color: 'transparent' // Set the bar color to transparent
                },
                type: 'bar',
                barWidth: '50%',
                label: {
                    fontSize: 16,
                    fontWeight: 'bold',
                    fontFamily: "Henry Sans",
                    color: 'black',
                    show: true,
                    // position: 'inside',
                    position: isOnMobile ? [5, 20] : [5, 35], //positioning of grand total's value
                    formatter: function (obj) {
                        return displayNumber(obj.value); // Display the legitimate values
                    }
                }
            }
        ]
    };

    
    const content = (() => {
        if (isLoading) {
            return <LoadingComponent />;
        } else if (!isLoading && data?.length === 0) {
            return (
                <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 18 }}>
                    No Data Available
                </Grid>
            );
        } else {
            return (
                <>
                    <ReactEcharts
                        notMerge={true}
                        lazyUpdate={false}
                        option={options}
                        style={{
                            height: isOnMobile ? '20rem' : '25rem',
                            minWidth: isOnMobile ? '10vw' : 'none',
                        }}
                    />
                    <Divider
                        variant='middle'
                        color="#CADDEB"
                        sx={{ display: 'flex', justifyContent: 'center', marginX: '5vw', width: '80vw', color: 'black', borderBottomWidth: '3px' }}
                    />
                    <ReactEcharts
                        notMerge={true}
                        lazyUpdate={false}
                        option={totalOptions}
                        style={{
                            height: isOnMobile ? '5rem' : '7rem',
                            minWidth: isOnMobile ? '10vw' : 'none',
                        }}
                    />
                </>
            );
        }
    })();
    return (
        <Grid item sm={12} xs={12} alignItems="center" 
            sx={{ 
                flex: 1, 
                height: '100%', 
            }}
        >
            { content }
        </Grid>
    );
}
