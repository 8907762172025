import React, { useContext, useState } from "react";
import { Grid, Box, Typography, Collapse } from "@mui/material";
import ToggleSelectorWithAdditional from "../../TmpTSSSales/Filters/ToggleSelectorWithAdditional";
import { useTogglePeriodContext, useFiltersContext } from "../Provider/context";
import { useParams } from "react-router-dom";
import CustomerTopline from "../../common/presentation/CustomerTopline";
import { getParamsBasedOnPeriod } from "../util";
import CollapsibleComponent from "../../../../common/components/CollapsibleComponent";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { useEffect } from "react";
import { useAvailableMaxDate } from "../../TmpTSSSales/DataProvider/hooks";
import { checkIfCompletePeriod } from "../../TmpTSSSales/util";

const TOGGLE_OPTIONS = [
    { label: 'MTD', value: 'mtd' },
    { label: 'YTD', value: 'ytd' },
    { label: 'QoQ', value: 'qoq' }
]

export default function ToplineInformation() {
    const { filters } = useFiltersContext();
    const { period, updatePeriod } = useTogglePeriodContext();
    const [isExpand, setExpand] = useState(true);
    const isMTD = period === 'mtd'
    const isQoQ = period === 'qoq'
    const windowSize = useContext(WindowSizeContext);
    const [scrolling, setScrolling] = useState(false);
    const [prevScrollPos, setPrevScroll] = useState(0);

    const params = {
        month: filters?.month,
        year: filters?.year,
    };
    const paramsBasedOnPeriod = getParamsBasedOnPeriod(isMTD, isQoQ, params);
    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        setScrolling(prevScrollPos < currentScrollPos || currentScrollPos > 0);
        setPrevScroll(currentScrollPos);
        // Close the tooltip when scrolling
        // setTooltipOpen(false);
    };

    const { data: maxDate, isLoading: isLoadingMaxdate } = useAvailableMaxDate();
    const isCompletePeriod = checkIfCompletePeriod(params, maxDate, isQoQ)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    return (
        <Grid item xs={12} mb={2} sx={{ backgroundColor: '#e0e0e0' }}>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem .5rem 0 0" }} >
                <Grid container direction="row" paddingY={.75} alignItems={'center'}>
                    <Grid item xs sm alignItems={'center'} >
                        <Typography variant="h6" ml={3}> Topline Information </Typography>
                    </Grid>
                    <ToggleSelectorWithAdditional
                        options={TOGGLE_OPTIONS}
                        value={period}
                        onChange={(e) => updatePeriod(e.target.value)}
                        additionalStyles={{ textTransform: 'none' }}
                        lastIndexOfMain={2}
                    />
                    {
                        !windowSize?.isOnMobile && <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                    }
                </Grid>
            </Box>
            <Collapse in={isExpand}>
                {/* Note: for displaying conditionals for full scorecards and trim cards is based on the isCompletePeriod and scrolling*/}
                <CustomerTopline params={{ ...paramsBasedOnPeriod }} period={period} viewBy={'chain'} isOnDefaultView={(isCompletePeriod && !scrolling)} isStickyCustomerCarousel={(isCompletePeriod && scrolling) || (!isCompletePeriod)} />
            </Collapse>
        </Grid>
    );
}