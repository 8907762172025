import React from "react";
import BarIndicator from "../../../common/presentation/barIndicator";
import {Box} from "@mui/material";


export default function TableCellPercentageBox({value}) {
    return <Box sx={{
        width: '3.5rem',
        height: '1.5rem',
        marginX: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <BarIndicator value={value?.toFixed(2)} componentType={'sow'}/>
    </Box>
}