import { useState } from 'react';
import { FiltersContext } from './context';

export default function FiltersProvider({ children }) {
    const [filters, setFilters] = useState({
        company_id: [2, 7, 142], // Initial company IDs
        month: null,
        year: null,
    });

    const setFilter = (key, value) => {
        setFilters({
            ...filters,
            [key]: value,
        });
    };

    const handleCompanyFilterChange = (companyIds) => {
        setFilter('company_id', companyIds);
    };

    return (
        <FiltersContext.Provider
            value={{
                filters,
                setFilters,
                setFilter,
                handleCompanyFilterChange,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
}
