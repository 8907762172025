import React, {useEffect} from "react";
import {SelectorContext} from "../contexts";

export default function SelectorProvider({children, defaults={}}) {
    const [values, setValues] = React.useState({...defaults});

    const setValue = (key, value) => {
        setValues((prev) => ({...prev, [key]: value}));
    };

    const getValue = (key) => {
        return values[key];
    };

    const getValues = () => {
        return values;
    };

    const resetValues = () => {
        setValues({...defaults});
    };

    return (
        <SelectorContext.Provider value={{setValue, getValue, getValues, setValues, resetValues}}>
            {children}
        </SelectorContext.Provider>
    )
}


export function useSelectorContext() {
    return React.useContext(SelectorContext);
}


export function useSelectorValue(key) {
    const {getValue} = useSelectorContext();
    return getValue(key);
}


export function useSelectorState(key, initialValue=undefined) {
    const {getValue, setValue} = useSelectorContext();

    useEffect(() => {
        if (initialValue !== undefined && getValue(key) === undefined) {
            setValue(key, initialValue);
        }
    }, []);

    return [getValue(key), (value) => setValue(key, value)]
}