import TableComponentV2 from '../../../../common/components/TableComponentV2';
import React, { useContext, useMemo } from 'react';
import { Box, Grid, IconButton } from "@mui/material";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useOpexPerGroup } from '../hooks'
import VisibilityIcon from '@mui/icons-material/Visibility';
import BarIndicator from '../../common/presentation/barIndicator';
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import {
    OPERATION_TYPE_LIGHT_AND_WATER,
    OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
    OPERATION_TYPE_OTHER_CONTROLLABLE,
    OPERATION_TYPE_OTHER_NON_CONTROLLABLE,
    OPERATION_TYPE_RENT,
    OPERATION_TYPE_REPAIR_AND_MAINTENANCE,
    OPERATION_TYPE_SALARIES_AND_WAGES,
    OPERATION_TYPE_SECURITY,
    OPERATION_TYPE_TOTAL_CONTROLLABLE,
    OPERATION_TYPE_TOTAL_NON_CONTROLLABLE,
    OPERATION_TYPE_TOTAL_OPERATING_EXPENSES
} from "../constants";
import { OperationTypeFilterContext } from "../contexts";
import BaseNumericDisplay from "../../common/presentation/numericDisplays/baseNumericDisplay";
import { decimalToString } from "../../../../common/utils/numbers";

const IndicatorCellBox = (props) => (
    <Box sx={{ my: 1, width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);

const chooseGroup = {
    'region': 'region',
    'store_size': 'storeSize',
    'mall_type': 'mallType',
    'mall_category': 'mallCategory'
}

const header = {
    region: 'Region',
    storeSize: 'Store Size',
    storeType: 'Store Type',
    mall_category: 'Store Subtype'
}

const getData = (data, category) => {
    return Object.keys(data).map(key => ({ [category]: key, operation_types: [data[key]] }))
}

export default function PerGroupChart({ params, filters, onToggleChange, selectedRows, onSelectRow, expanded, period }) {
    const { selected: selectedOperationTypes } = React.useContext(OperationTypeFilterContext)
    const showOperationType = operationType => {
        return !selectedOperationTypes || selectedOperationTypes.length === 0 || selectedOperationTypes.includes(operationType)
    }

    const isMTD = period === 'mtd';
    let orderArray;
    const windowSize = useContext(WindowSizeContext)

    switch (chooseGroup[filters?.group]) {
        case 'region':
            orderArray = ['NCR', 'NORTH LUZON', 'SOUTH LUZON', 'VISAYAS', 'MINDANAO'];
            // absoluteHeaderLabel = "Region"
            break;
        case 'store_size':
            orderArray = ['EXTRA SMALL', 'SMALL', 'MEDIUM', 'LARGE'];
            // absoluteHeaderLabel = "Store Size"
            break;
        case 'mallType':
            orderArray = ['STAND ALONE RESIDENTIAL', 'STAND ALONE COMMERCIAL', 'STAND ALONE RESIDENTIAL/CONDO', 'MALL BASED RESIDENTIAL', 'MALL BASED COMMERCIAL', 'MALL BASED/ RESIDENTAIL / CONDO'];
            // absoluteHeaderLabel = "Store Type"
            break;
        default:
            orderArray = [];
            break;
    }

    const { derivedDataByRegion, derivedDataByStoreSize, derivedDataByStoreType, derivedDataByStoreSubType } = useOpexPerGroup({ params });


    let data;
    if (params?.group_by === 'region') {
        data = getData(derivedDataByRegion, 'region');
    } else if (params?.group_by === 'storeSize') {
        data = getData(derivedDataByStoreSize, 'storeSize');
    } else if (params?.group_by === 'storeType') {
        data = getData(derivedDataByStoreType, 'storeType');
    } else if (params?.group_by === 'mall_category') {
        data = getData(derivedDataByStoreSubType, 'mall_category');
    } else {
        data = null; // Handle the case where params.group_by is not recognized
    }


    const formatData = (data) => {
        const accessorKey = params.group_by ? params.group_by : ''

        const filteredData = data?.map(item => {
            const getData = Object.values(item.operation_types)

            return getData
        }
        );

        const sortedData = data?.sort((a, b) => {
            const first = orderArray.indexOf(a[accessorKey]);
            const last = orderArray.indexOf(b[accessorKey]);
            return first - last;
        });

        const tableData = sortedData?.map((row) => {
            const subRows = [];
            const operationData = row.operation_types[0];

            const controllableSubRow = {
                [accessorKey]: 'Controllable',
                // income: 0,
                current: operationData?.[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.opex_value_sum || 0,
                lyMTD: operationData?.[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.vs_budget || 0,
                sales: operationData?.[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.percent_to_sales || 0,
                subRows: [],
            };

            showOperationType(OPERATION_TYPE_SALARIES_AND_WAGES) && controllableSubRow.subRows.push({
                [accessorKey]: 'Salaries & Wages',
                // income: 0,
                current: operationData?.[OPERATION_TYPE_SALARIES_AND_WAGES]?.opex_value_sum || 0,
                lyMTD: operationData?.[OPERATION_TYPE_SALARIES_AND_WAGES]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_SALARIES_AND_WAGES]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_SALARIES_AND_WAGES]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_SALARIES_AND_WAGES]?.vs_budget || 0,
                sales: operationData?.[OPERATION_TYPE_SALARIES_AND_WAGES]?.percent_to_sales || 0,
            });

            showOperationType(OPERATION_TYPE_LIGHT_AND_WATER) && controllableSubRow.subRows.push({
                [accessorKey]: 'Light & Water',
                // income: 0,
                current: operationData?.[OPERATION_TYPE_LIGHT_AND_WATER]?.opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_LIGHT_AND_WATER]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_LIGHT_AND_WATER]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_LIGHT_AND_WATER]?.vs_budget || 0,
                sales: operationData?.[OPERATION_TYPE_LIGHT_AND_WATER]?.percent_to_sales || 0,
            });

            showOperationType(OPERATION_TYPE_SECURITY) && controllableSubRow.subRows.push({
                [accessorKey]: 'Security',
                // income: 0,
                current: operationData?.[OPERATION_TYPE_SECURITY]?.opex_value_sum || 0,
                lyMTD: operationData?.[OPERATION_TYPE_SECURITY]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_SECURITY]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_SECURITY]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_SECURITY]?.vs_budget || 0,
                sales: operationData?.[OPERATION_TYPE_SECURITY]?.percent_to_sales || 0,
            });

            showOperationType(OPERATION_TYPE_REPAIR_AND_MAINTENANCE) && controllableSubRow.subRows.push({
                [accessorKey]: 'Repairs & Maintenance',
                // income: 0,
                current: operationData?.[OPERATION_TYPE_REPAIR_AND_MAINTENANCE]?.opex_value_sum || 0,
                lyMTD: operationData?.[OPERATION_TYPE_REPAIR_AND_MAINTENANCE]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_REPAIR_AND_MAINTENANCE]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_REPAIR_AND_MAINTENANCE]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_REPAIR_AND_MAINTENANCE]?.vs_budget || 0,
                sales: operationData?.[OPERATION_TYPE_REPAIR_AND_MAINTENANCE]?.percent_to_sales || 0,
            });

            showOperationType(OPERATION_TYPE_OTHER_CONTROLLABLE) && controllableSubRow.subRows.push({
                [accessorKey]: 'Other Controllable',
                // income: 0,
                current: operationData?.[OPERATION_TYPE_OTHER_CONTROLLABLE]?.opex_value_sum || 0,
                lyMTD: operationData?.[OPERATION_TYPE_OTHER_CONTROLLABLE]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_OTHER_CONTROLLABLE]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_OTHER_CONTROLLABLE]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_OTHER_CONTROLLABLE]?.vs_budget || 0,
                sales: operationData?.[OPERATION_TYPE_OTHER_CONTROLLABLE]?.percent_to_sales || 0,
            });



            const nonControllableSubRow = {
                [accessorKey]: 'Non Controllable',
                // income: 0,
                current: operationData?.[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.opex_value_sum || 0,
                lyMTD: operationData?.[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.vs_budget || 0,
                sales: operationData?.[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.percent_to_sales || 0,
                subRows: [],
            };

            showOperationType(OPERATION_TYPE_RENT) && nonControllableSubRow.subRows.push({
                [accessorKey]: 'Rent',
                // income: 0,
                current: operationData?.[OPERATION_TYPE_RENT]?.opex_value_sum || 0,
                lyMTD: operationData?.[OPERATION_TYPE_RENT]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_RENT]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_RENT]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_RENT]?.vs_budget || 0,
                sales: operationData?.[OPERATION_TYPE_RENT]?.percent_to_sales || 0,
            });

            showOperationType(OPERATION_TYPE_OTHER_NON_CONTROLLABLE) && nonControllableSubRow.subRows.push({
                [accessorKey]: 'Other Non-Controllable Expense',
                // income: 0,
                current: operationData?.[OPERATION_TYPE_OTHER_NON_CONTROLLABLE]?.opex_value_sum || 0,  // Replace 'opex_value_sum_other' with the correct key if needed
                lyMTD: operationData?.[OPERATION_TYPE_OTHER_NON_CONTROLLABLE]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_OTHER_NON_CONTROLLABLE]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_OTHER_NON_CONTROLLABLE]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_OTHER_NON_CONTROLLABLE]?.vs_budget || 0,
                sales: operationData?.[OPERATION_TYPE_OTHER_NON_CONTROLLABLE]?.percent_to_sales || 0,
            });

            const lossesShrinkageData = {
                [accessorKey]: OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
                // income: 0,
                current: operationData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.opex_value_sum || 0,
                lyMTD: operationData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.vs_last_year || 0,
                budgetMTD: isMTD ? operationData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.budget_sum : operationData?.['Losses & Shrinkages YTD']?.opex_value_sum,
                vsBudget: isMTD ? operationData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.vs_budget : (operationData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.opex_value_sum / operationData?.['Losses & Shrinkages YTD']?.opex_value_sum) * 100,
                sales: operationData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.percent_to_sales || 0,
                subRows: [],
            }

            if (controllableSubRow.subRows.length > 0) {
                subRows.push(controllableSubRow)
            }
            if (nonControllableSubRow.subRows.length > 0) {
                subRows.push(nonControllableSubRow)
            }


            const rowData = {
                [accessorKey]: row[accessorKey],
                income: operationData?.[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES]?.income || 0,
                current: operationData?.[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES]?.opex_value_sum || 0,
                lyMTD: operationData?.[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES]?.last_year_opex_value_sum || 0,
                vsLy: operationData?.[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES]?.vs_last_year || 0,
                budgetMTD: operationData?.[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES]?.budget_sum || 0,
                vsBudget: operationData?.[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES]?.vs_budget || 0,
                salesMTD: operationData?.[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES]?.actual_sales || 0,
                sales: operationData?.[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES]?.percent_to_sales || 0,
                subRows: [
                    ...subRows,
                    lossesShrinkageData,
                    // ...percentSalesSubData,
                ],
            };
            return rowData
        })
        return tableData
    };

    const isGroupCategory = filters?.group === 'group';
    const table_by_group = useMemo(
        () => [
            {
                id: 'empty',
                columns: [
                    {
                        id: 'group',
                        header: params.group_by ? header[params.group_by] : '',
                        accessorKey: params.group_by ? params.group_by : '',
                        cell: ({ row, getValue }) => (
                            <Box sx={{ justifyItems: 'center', marginLeft: `${row.depth * 2}rem`, whiteSpace: 'nowrap' }}>
                                <Grid alignItems="center" display="flex">
                                    {row.depth === 0 ? (
                                        <VisibilityIcon sx={{ marginRight: '.5rem' }} fontSize='small' color={selectedRows?.includes(row?.original?.[filters?.group]) ? 'primary' : 'disabled'} />
                                    ) : null}
                                    <Box sx={{ fontWeight: row.depth === 0 || row.depth === 1 ? 'bold' : 'normal' }}>
                                        {getValue()}
                                    </Box>
                                    {row.getCanExpand() ?
                                        <IconButton size='small' onClick={(event) => {
                                            event.stopPropagation(); // Stop the click event from propagating
                                            row.getToggleExpandedHandler()();
                                        }}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize='small' /> : <ArrowRightRoundedIcon fontSize='small' color='#e0e0e0' />}
                                        </IconButton>
                                        : ''}
                                </Grid>
                            </Box>
                        ),
                        customStyle: { width: '10rem', alignItems: 'center' }
                    },
                    {
                        header: isMTD ? 'MTD OPEX' : 'YTD OPEX',
                        accessorKey: 'current',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
                        },
                        customStyle: { width: '8rem', textAlign: 'left' }
                    },
                    {
                        header: 'Last Year',
                        accessorKey: 'lyMTD',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
                        },
                        customStyle: { width: '8rem', textAlign: 'left' }
                    },
                    {
                        header: 'vs Last Year',
                        accessorKey: 'vsLy',
                        cell: (row) => <>
                            <IndicatorCellBox>
                                <BarIndicator colorFunctionFor={'opex'} value={row?.getValue()?.toFixed(1)} isInverted={true} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </IndicatorCellBox>
                        </>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle', whiteSpace: 'nowrap' }
                    },
                    {
                        header: 'Budget',
                        accessorKey: 'budgetMTD',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
                        },
                        customStyle: { width: '8rem', textAlign: 'left' }
                    },
                    {
                        header: 'vs Budget',
                        accessorKey: 'vsBudget',
                        cell: (row) => <>
                            <IndicatorCellBox>
                                <BarIndicator colorFunctionFor={'opex'} value={row?.getValue()?.toFixed(1)} isInverted={true} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </IndicatorCellBox>

                        </>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle', whiteSpace: 'nowrap' }
                    },
                    {
                        header: isMTD ? 'MTD Sales' : 'YTD Sales',
                        accessorKey: 'salesMTD',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
                        },
                        customStyle: { width: '8rem', textAlign: 'left' }
                    },
                    {
                        header: '% to Sales',
                        accessorKey: 'sales',
                        cell: ({ row, getValue }) => {
                            const value = decimalToString(getValue(), { fixedDigits: 2 })
                            return <Box sx={{ whiteSpace: 'nowrap' }}>
                                <BaseNumericDisplay value={value} postfix={'%'} />
                            </Box>
                        },
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                ]
            }
        ], [data, windowSize, expanded]
    )

    return (
        <>
            <TableComponentV2
                columns={table_by_group}
                data={formatData(data)}
                tableId={'TableFilter'}
                enableRowSelection={true}
                onSelectRow={onSelectRow}
                isCompact={true}
                hasPagination={false}
                customTableHeaderStyle={{
                    backgroundColor: '#caddeb'
                }}
                propPageSize={100}
            />
        </>
    );
}


