export const CATEGORY = {
    'mall_type': 'Store Type',
    'store_size': 'Store Size',
    'mall_category': 'Store Subtype',
    'region': 'Region',
    'product_group': 'Product Category',
    'storeSize': 'Store Size',
    'storeType': 'Store Type',
}

export const SHORTEN_SALES_COMPANY = {
    7: "SVI",
    2: "SSM",
    142: "SMCO"
}