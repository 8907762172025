import moment from "moment";
import { Box, Typography } from "@mui/material";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import { useAvailableMaxDateL3 } from "../DataProvider/hooks";

export default function AsOfDate() {

    const {data, isLoading} = useAvailableMaxDateL3()

    if (isLoading) {
        return <Box md={2} sx={{ marginY: -1, paddingX: 2, display: 'flex', justifyContent: 'end', width: "100%" }} >
            <LoadingComponent/>
        </Box>
    }
    return <Box textAlign="end" width={"100%"} >
        <Typography variant="body1" >
            Data as of {moment(data).format('MMMM DD, YYYY')}
            </Typography>
    </Box>
}