
export const SEGMENT_TYPE_RFM = "RFM";
export const SEGMENT_TYPE_PRODUCTS = "Products";
export const SEGMENT_TYPE_CARD_TYPE = "Card Type";
export const SEGMENT_TYPE_DEMOGRAPHICS = "Demographics";
export const SEGMENT_TYPE_CUSTOMER = "Customer";  // this is a placeholder, will be replaced with actual segment type when available

export const SEGMENT_ORG_SM_STORE = "SM Store";
export const SEGMENT_ORG_SM_MARKETS = "SM Markets";
export const SEGMENT_BRANCH_ALL_BRANCH = "All Branch";

export const SEGMENT_PERIOD_PREVIOUS = "H1";
export const SEGMENT_PERIOD_LATEST = "Q1-Q3";

export const CATCHMENT_5_MINUTE = '5-Minute';
export const CATCHMENT_10_MINUTE = '10-Minute';
export const CATCHMENT_15_MINUTE = '15-Minute';

export const TOTAL_SMAC_BASE = 'Total SMAC Base';