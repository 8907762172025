import FilterCompany from '../../../common/components/FilterCompany';
import SalesToplinePerformance from './SalesToplinePerformance';
import SalesPerformancePerGroup from './SalesPerformancePerGroup';
import { useState, useContext, useEffect } from 'react';
import { WindowSizeContext } from '../../../common/context/WindowSizeContext';
import FilterMonth from '../../../common/components/FilterMonth'
import FilterYear from '../../../common/components/FilterYear';
import AsOfDate from './Filters/AsOfDate';
import { TABLE_NAME, useAvailableDateRange } from "./hooks";
import { Grid, Typography, Box, GlobalStyles, IconButton } from '@mui/material';
import Back from '../../../common/components/BackNavigateComponent';
import StoreSalesPerformance from './StoreSalesPerformance';
import SalesOverallPerformance from './SalesOverallPerformance';
import { toast } from 'react-toastify';
import { MKT_SCHEDULE_REFRESH } from '../../../Constants/settings';
import { useEnvironmentSetting } from '../../../common/providers/EnvironmentSettingsProvider';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ScheduleToastNotification =(REFRESH_SCHEDULE)=> {
    const now = moment(new Date()).format()
    const dateNow = moment(new Date()).format('YYYY-MM-DD')
    const pastDays = moment(dateNow).subtract(1, 'days').format('YYYY-MM-DD')
    const futureDays = moment(dateNow).add(1, 'days').format('YYYY-MM-DD')
    const yesterdaySchedule = REFRESH_SCHEDULE?.map(val => moment(pastDays+'T'+val).format())
    const todaySchedule = REFRESH_SCHEDULE?.map(val => moment(dateNow+'T'+val).format())
    const tomorrowSchedule = REFRESH_SCHEDULE?.map(val => moment(futureDays+'T'+val).format())
    const schedules = [ ...yesterdaySchedule, ...todaySchedule, ...tomorrowSchedule ]
    const pastSchedules = schedules.filter(sched => sched < now)
    const futureSchedules = schedules.filter(sched => sched > now)
    const lastRefresh = moment.max(pastSchedules.map(val => moment(val))).format()
    const nextRefresh = moment.min(futureSchedules.map(val => moment(val))).format()

    const lastRefreshDisplay = moment(lastRefresh).fromNow().replace('minutes', 'mins').replace('seconds', 'secs')
    const nextRefreshDisplay = moment(nextRefresh).fromNow().replace('minutes', 'mins').replace('seconds', 'secs')
    const lastRefreshTooltipDisplay = moment(lastRefresh).format('MMMM Do YYYY, h:mm a')
    const nextRefreshTooltipDisplay = moment(nextRefresh).format('MMMM Do YYYY, h:mm a')

    toast.info(<>
        <Typography> Data last refreshed: 
            <span title={lastRefreshTooltipDisplay} placement="right"> <strong> { lastRefreshDisplay }</strong> </span> 
        </Typography>
        <Typography> Next refresh
            <span title={nextRefreshTooltipDisplay} placement="right"><strong> { nextRefreshDisplay } </strong> </span>
        </Typography>
    </>, {
        autoClose: 15000
    })

}
export default function Sales() {
    const REFRESH_SCHEDULE = useEnvironmentSetting(MKT_SCHEDULE_REFRESH)
    const windowSize = useContext(WindowSizeContext);
    const [filters, setFilters] = useState({
        company_id: [2, 7, 142],
        group: "product_group",
        month: null,
        year: null
    })
    const [selectedRows, setSelectedRows] = useState([]);
    const [dataView, setDataView] = useState('total');

    useEffect(() => {
        //reset selectrow only when filter group change
        setSelectedRows([])
    }, [filters.group])

    //show toast on load of page
    useEffect(() => {
        ScheduleToastNotification(REFRESH_SCHEDULE)
    },[])

    const { data: dateRangeV2, dateRangeIsLoading, error, isSuccess } = useAvailableDateRange()
    const [period, setPeriod] = useState('mtd');
    const dateRange = (dateRangeV2 && !dateRangeIsLoading) ? {
        from: dateRangeV2.min,
        to: dateRangeV2.max
    } : {};

    const startofPeriodOfCoverage = filters?.year && filters?.month ? moment(filters.year + '-' + filters.month[0], 'YYYY-MM').subtract(1, 'year').startOf(period === 'mtd' ? 'month' : 'year').format('YYYY-MM-DD') : null;
    const endofPeriodOfCoverage = filters?.year && filters?.month ? moment(filters.year + '-' + filters.month[0], 'YYYY-MM').endOf('month').format('YYYY-MM-DD') : null;
 
    const params = {
        company_id: filters?.company_id,
        month: filters?.month,
        year: filters?.year,
        opening_date__lte: dataView === 'likeforlike' ? startofPeriodOfCoverage : undefined,
        closed_date__gte: dataView === 'likeforlike' ? endofPeriodOfCoverage : undefined
    }

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setPeriod(val);
        }
    };

    function onDropdownFilterChange(value, field) {
        setFilters({
            ...filters,
            [field]: value
        })
    }

    const onDataViewChange = (event, val) => {
        if (val !== null) {
            setDataView(val);
        }
    };

    function onSelectRow(row) {
        const isProductGroup = filters?.group === 'product_group';
        const PRODUCT_CAT_COLS = ['888', '888 (Outright)', '4k']
        const isSelectedTopSale = PRODUCT_CAT_COLS?.includes(row[filters?.group])
        if (selectedRows?.includes(row[filters?.group])) {
            //unselecting of row
            setSelectedRows(selectedRows?.filter(val => val !== row[filters?.group]))
        } else {
            if (isProductGroup) {
                if (isSelectedTopSale) {
                    const newSelectedRows = [...(selectedRows?.filter(row => PRODUCT_CAT_COLS?.includes(row)) || []), row[filters?.group]]
                    //if 888 is selected, remove 888 (Outright)
                    if(row[filters?.group] === '888' && selectedRows?.includes('888 (Outright)')) {
                        setSelectedRows(newSelectedRows?.filter(row => row !== '888 (Outright)'))
                    //if 888 (Outright) is selected, remove 888
                    } else if(row[filters?.group] === '888 (Outright)' && selectedRows?.includes('888')) {
                        setSelectedRows(newSelectedRows?.filter(row => row !== '888'))
                    } else {
                        setSelectedRows(newSelectedRows)
                    }
                } else {
                    setSelectedRows([...(selectedRows?.filter(row => !PRODUCT_CAT_COLS?.includes(row)) || []), row[filters?.group]])
                }
            } else {
                setSelectedRows([...(selectedRows || []), row[filters?.group]])
            }
        }
    }

    return <>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />
        <Grid container>
            <Grid item xs={12} marginY={1} display="flex">
                <Back />
                <Typography variant='h5' ml={3} > Markets Sales Dashboard</Typography>
            </Grid>

            <Grid container sx={{ position: `${!windowSize.isOnMobile ? 'sticky' : ''}`, top: `${!windowSize.isOnMobile ? '5rem' : ''}`, zIndex: 5, backgroundColor: 'transparent' }}>
                <Grid item xs={12} marginBottom={2}>
                    <Box flexGrow={1} xs={12} paddingX={1} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem" }}>
                        <Grid container columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} paddingX={windowSize.isOnMobile && 1}>
                            {
                                windowSize.isOnMobile && <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <AsOfDate />
                                    <IconButton onClick={() => ScheduleToastNotification(REFRESH_SCHEDULE)}> <InfoOutlinedIcon sx={{ color: "#0A4FB4" }} /></IconButton>
                                </Grid>
                            }
                            <Grid item xs={12} sm={5}>
                                <FilterCompany
                                    tableName={TABLE_NAME}
                                    filters={filters}
                                    onPillSelectChange={onDropdownFilterChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <FilterMonth
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={isSuccess} dateRangeIsLoading={dateRangeIsLoading} />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <FilterYear
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={isSuccess} dateRangeIsLoading={dateRangeIsLoading} />
                            </Grid>
                            {
                                !windowSize.isOnMobile && <Grid item sm={4} alignItems="center" display="flex">
                                    <AsOfDate />
                                    
                                    <IconButton onClick={() => ScheduleToastNotification(REFRESH_SCHEDULE)}> <InfoOutlinedIcon sx={{ color: "#0A4FB4" }} /></IconButton>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SalesToplinePerformance params={params} onToggleChange={onDataViewChange} dataView={dataView} /> 
            </Grid>  
            <Grid item xs={12}  marginY={2}>
                <SalesOverallPerformance filters={filters} params={params} period={period} onToggleChange={onTogglePeriodChange}  />
            </Grid>   
            <Grid item xs={12}>
                <SalesPerformancePerGroup filters={filters} params={params} onDropDownFilterChange={onDropdownFilterChange} onSelectRow={onSelectRow} selectedRows={selectedRows}
                    period={period} onToggleChange={onTogglePeriodChange}
                />
            </Grid>
            <Grid item xs={12}>
                <StoreSalesPerformance filters={filters} params={params} onDropDownFilterChange={onDropdownFilterChange} onSelectRow={onSelectRow} selectedRows={selectedRows}
                    period={period} onToggleChange={onTogglePeriodChange}
                />
            </Grid>
        </Grid>
    </>
}