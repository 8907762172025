import IframeResizer from "iframe-resizer-react";
import ApiClient from "../../../common/API";
import { useQuery} from "react-query";
import LoadingComponent from "../../../common/components/LoadingComponent";

export default function LookerStudioEmbed() {
    
    const { isLoading,  data} = useQuery('lookerStudio',()=> ApiClient().get('/custom/region').then(res => {
        // return res.data
        return {
            title : 'test',
            url: 'https://lookerstudio.google.com/embed/reporting/feb4e9cd-4c55-4335-a42d-3914b7d20c87/page/1M'
        }
    }), {
        refetchOnWindowFocus: false,
        staleTime: 600000 //10mins
    });

    
    function renderContent() {
        if(isLoading) {
            return <LoadingComponent/>
        } else {
            return  <IframeResizer
                src={data.url}
                frameBorder="0"
                width="100%"    
                height={1000}
                onLoad={(data)=> console.info(data)}
            />
        }
    }

    return <>
        { renderContent() }
    </>
}