import { getSelectedOption } from "../../../../../common/utils";
import DropdownCheckboxV3 from "../../../../../common/components/DropdownCheckboxV3";
import React from "react";
import { useStoreMetaData } from "../providers";

export default function StoresDisplayedSelector({ selectedStores, setSelectedStores }) {
    const { storeMeta: storeMeta, isLoading: isLoadingStoreData } = useStoreMetaData()

    const storeOptions = (storeMeta || [])
        .map((row) => {
            return {
                value: row?.store_parent_store_id?.toString(),
                label: row?.store_parent_store,
            }
        }

        )

        .filter((option) => option.value !== "" && option.label !== "")
        .sort((a, b) => a.label?.localeCompare(b.label));
    return (
        <DropdownCheckboxV3
            id="sales_store_filter"
            options={storeOptions}
            label="Store"
            placeholder="Select a store"
            limitTags={2}
            onChange={(event, newValue) => {
                setSelectedStores([...getSelectedOption(newValue, storeOptions).map(o => o.value)])
            }}
            value={getSelectedOption(selectedStores, storeOptions)}
        />
    )
}