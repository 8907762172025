import React, {useContext, useState} from 'react';
import PerGroupTable from './PerGroupTable';
import PerGroupFilter from './PerGroupFilter';
import ToggleMtdYtd from '../../Sales/toggleMtdYtd';
import {Box, Grid, Typography} from "@mui/material";
import {WindowSizeContext} from '../../../../common/context/WindowSizeContext';
import {useOpexPerGroup} from "../hooks";
import PieChart from './PieChartComponent';
import {pieChartPerGroupData} from '../util'
import LoadingComponent from '../../../../common/components/LoadingComponent';
import OperationTypeFilterDisplay from "../components/operationTypeFilterDisplay";

export default function OpexPerGroup({ params, filters, onDropDownFilterChange, period, onToggleChange, selectedRows, onSelectRow }) {

    const isMTD = period === 'mtd';

    const newParams = {
        ...params,
        group_by: filters?.group,
    }
    const paramsBasedOnPeriod = isMTD ? newParams : { ...newParams, month: [1, ...(newParams?.month || [])] }

    const {
        opexCurrentData,
        opexLastYearData,
        isLoadingOpexPerGroupData } = useOpexPerGroup({
            params: {
                ...paramsBasedOnPeriod,
                limit: 1000,
            },
        });

    const chartData = {
        'Salaries & Wages': 80,
        'Light & Water': 700,
        'Security': 60,
        'Repairs & Maintenance': 50,
        'Other Controllable': 40,
        'Rent': 30,
        'Other Non-controllable': 20,
    };


    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % chart.length);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + chart.length) % chart.length);
    };
    const option1 = pieChartPerGroupData(chartData);

    const windowSize = useContext(WindowSizeContext);
    const [activeStep, setActiveStep] = useState(0);

    const chart = [
        {
            component: <PieChart data={option1} />,
            label: 'Sample Chart 1',
        },
        {
            component: <PieChart data={option1} />,
            label: 'Sample Chart 2',
        },
        {
            component: <PieChart data={option1} />,
            label: 'Sample Chart 3',
        },
        {
            component: <PieChart data={option1} />,
            label: 'Sample Chart 4',
        },
        {
            component: <PieChart data={option1} />,
            label: 'Sample Chart 5',
        },
        {
            component: <PieChart data={option1} />,
            label: 'Sample Chart 6',
        },
    ];

    if (isLoadingOpexPerGroupData) {
        return <LoadingComponent />
    }

    return <>

        <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', borderRadius: ".5rem .5rem 0 0", top: `${!windowSize.isOnMobile ? '9rem' : ''}`, zIndex: 12 }}>
            <Grid container alignItems="center" >
                {
                    (windowSize.isOnMobile) && <>
                        <Grid container justifyContent='space-between' paddingY={.5}>
                            <Grid item >
                                <Typography variant="h6" ml={2} paddingY={.5}>
                                    OPEX by
                                </Typography>
                            </Grid>
                            <Grid item >
                                <ToggleMtdYtd
                                    value={period}
                                    onChange={onToggleChange} />
                            </Grid>
                        </Grid>
                        <Grid container sm xs={12} >
                            <PerGroupFilter
                                onDropdownChange={onDropDownFilterChange}
                                filters={filters} />
                        </Grid>
                    </>
                }
                {
                    (!windowSize.isOnMobile) &&
                    <Grid container mt={.5} mb={.5} >
                        <Grid item xs={5.5} sm={1.7} >
                            <Typography variant="h6" ml={2} mt={.5}>
                                OPEX by
                            </Typography>
                        </Grid>
                        <Grid item sm={6} >
                            <PerGroupFilter
                                onDropdownChange={onDropDownFilterChange}
                                filters={filters} />
                        </Grid>
                        <Grid item sm display="flex" justifyContent={'end'} >
                            <ToggleMtdYtd
                                value={period}
                                onChange={onToggleChange} />
                        </Grid>
                    </Grid>
                }
            </Grid >
        </Box>
        {/* {windowSize.isOnMobile &&
            <Grid sx={{ boxShadow: 1, }} >
                <Paper
                    square
                    elevation={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 45,
                        bgcolor: 'background.default',
                    }} >
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }} >{/*{[activeStep].[label]}/Typography>
                </Paper>
            </Grid>
        }
        <Grid container sx={{ backgroundColor: 'white', display: "flex", flexWrap: "wrap", justifyContent: "center", direction: 'row' }}>
            <Grid container spacing={2} justifyContent="center">
                {windowSize.isOnMobile &&
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center">
                            {chart[activeStep].label}
                        </Typography>
                        {chart[activeStep].component}
                    </Grid>
                }
                {!windowSize.isOnMobile &&
                    chart.map((item, index) => (
                        <Grid item xs={3} >
                            <div key={index}>
                                <Typography variant="h6" align="center">
                                    {item.label}
                                </Typography>
                                {item.component}
                            </div>
                        </Grid>
                    ))}
            </Grid>
        </Grid>
        {windowSize.isOnMobile &&
            <Grid sx={{ boxShadow: 1, mb: 2 }}>
                <MobileStepper
                    variant="dots"
                    steps={chart.length}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            Next
                            <ArrowForwardIosIcon />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            <ArrowBackIosIcon />
                            Back
                        </Button>
                    }
                />
            </Grid>
        } */}
        <Grid sx={{ backgroundColor: 'white' }}>
            <OperationTypeFilterDisplay/>
            <Grid item xs={12} marginBottom={3}>
                <PerGroupTable
                    params={paramsBasedOnPeriod} filters={filters} period={period} selectedRows={selectedRows} onSelectRow={onSelectRow}/>
            </Grid>
        </Grid>

    </>
}