import { Grid } from "@mui/material"
import LoadingComponent from "../../../../../common/components/LoadingComponent"
import { useSMACMembersByConsent, useSMACMembersBySubscription } from "../../hooks"
import DoughnutGraph from "../../../CustomerAnalytics/SMACCardStatus/DoughnutGraph"
import ReactEcharts from "echarts-for-react"
import { abbreviateNumber, sortArrObjectsBasedOnGivenArray, topLineDisplayNumber } from "../../../../../common/utils"
import {CHART_FONT_SIZE} from "../../constants";

export default function CampaignSubscription() {
    
    const {data : doughnutData, isLoading: doughnutDataIsLoading} = useSMACMembersByConsent()
    const {data : barData, isLoading: barIsLoading} = useSMACMembersBySubscription()

    const newDoughnutData = doughnutData?.map(data => {
        return {
            value: data.total_members,
            name: data.dimension_value,
            itemStyle: {color: data.dimension_value === 'With Consent' ? '#4E88DD'  : '#AFAFAF'}
        }
    })
    
    const arraySorting = ['No Info', 'Email + Mobile', 'Mobile', 'Email']
    const sortedBarData = sortArrObjectsBasedOnGivenArray(barData,arraySorting, 'dimension_value' )
    const total = sortedBarData?.reduce((acc, num) => acc + num.total_members, 0) || 0;
  
    const getPercentage = (value) => value / total * 100;  
    const formatLabel = (value) => abbreviateNumber(value);

    const barGraphOptions =  {
        tooltip: {},
        grid: {
            left: '5%',
            containLabel: true
        },
        animation: false,
        xAxis: {
            type: 'value',
            axisLabel: {
                show:false,
                fontSize: CHART_FONT_SIZE
            },
            splitLine: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick:{
                show:false
            }
        },
        yAxis: {
            type: 'category',            
            data: sortedBarData?.map(item => item.dimension_value),
            splitLine: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick:{
                show:false
            },
            axisLabel: {
                fontSize: CHART_FONT_SIZE,
            }
        },
        series: [{            
            type: 'bar',
            data: sortedBarData?.map(item => {
                const value = item.total_members
                const percent = getPercentage(value)
                const smallBar = percent < 10 // if less than 10 percent show labels at top
                return {
                    value,
                    label: {
                        show: true,
                        color: smallBar ? '#000' : '#fff',
                        position: smallBar ? 'right' : 'inside',
                        fontSize: CHART_FONT_SIZE,
                        formatter: () => `${formatLabel(value)}\n(${percent.toFixed()}%)`,
                    },
                    itemStyle : {
                        color: item.dimension_value === "No Info" ? '#AFAFAF' : '#4E88DD'
                    },
                }
            }),
            barMinHeight: 5,
        }]
    }

    const doughnutOption = {
        animation: false,
        tooltip: { show: false },        
        grid: {
            top: '15%',
            containLabel: true
        },
        title:{
            text: 'Campaign Subscription',
            textStyle: {
                color: '#000'
            },
            top:'20px',
            left: '25px'            
        },
        series: [
            // Inner label display
            {
                type: 'pie',
                emphasis: { disabled: true },
                radius: [25, 80],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                data: newDoughnutData,
                label: {
                    show: true,
                    position: 'inside',
                    color: '#ffffff',
                    formatter: params => `{value|${params.percent}}%`,
                    rich: { value: {  color: '#ffffff', fontSize: CHART_FONT_SIZE } },
                    z: 1000
                },
                labelLine: { show: true, lineStyle: { width: 0 } }
            },
            // Outer label display
            {
                type: 'pie',
                emphasis: { disabled: true },
                radius: [25, 80],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                data: newDoughnutData,
                label: {
                    show: true,
                    position: 'outside',
                    color: '#000000',
                    formatter: params => {
                        const formattedValue = topLineDisplayNumber(params.value);
                        const name = params.name;
                        const wrappedName = name.split(' ').join('\n');
                        
                        return `{name|${wrappedName}}:\n  {value|${formattedValue}}`;
                    },
                    rich: {
                        name: { color: '#000000', fontSize: CHART_FONT_SIZE },
                        value: { color: '#000000', fontSize: CHART_FONT_SIZE }
                    },
                    z: 1
                },
                labelLine: { show: true, lineStyle: { color: '#000000', width: 2 } }
            }
        ]
    }

    return <Grid container>
        <Grid item xs={12} sm={6}>
            { doughnutDataIsLoading ? <LoadingComponent /> :
                <DoughnutGraph  option={doughnutOption}  echartContainerStyle={{height:'20rem'}}/>
            }
        </Grid>
        <Grid item xs={12} sm={6}>
            { barIsLoading ? <LoadingComponent /> :
                <ReactEcharts option={barGraphOptions} style={{ height: '20rem'}}/>
            }
        </Grid>
    </Grid>
}