import React from 'react';
import {IconButton} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import CircularProgress from "@mui/material/CircularProgress";


export default function SubRowExpander({row, isLoadingSubRows=false}) {
    if (!row.original.subRows) {
        if (isLoadingSubRows) {
            return <CircularProgress size={'1em'} sx={{marginLeft: "1em"}}/>
        }
        return null
    }

    return <IconButton size='small' onClick={(event) => {
        event.stopPropagation(); // Stop the click event from propagating
        row.getToggleExpandedHandler()();
    }}
                        sx={{cursor: "pointer"}}
    >
        {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize='medium'/> :
            <ArrowRightRoundedIcon fontSize='medium' color='#e0e0e0'/>}
    </IconButton>
}