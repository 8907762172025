import React from "react";
import * as PropTypes from "prop-types";

function ConditionalWrapper({condition, wrapper, wrapperIfFalse, children}) {
    // inspired by https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
    return condition ?
        wrapper(children) :
        (
            !!wrapperIfFalse ?
                wrapperIfFalse(children) :
                children
        )
}

ConditionalWrapper.propTypes = {
    condition: PropTypes.bool.isRequired,
    wrapper: PropTypes.func.isRequired,
    wrapperIfFalse: PropTypes.func,
    children: PropTypes.node.isRequired
}

export default ConditionalWrapper;