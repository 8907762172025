import React, { useState } from "react";
import Section from "../../../../../common/components/Dashboard/Section";
import { useHomeBranchTableData } from "../../hooks";
import { Grid } from "@mui/material";
import CustomerActivityByHomeBranchTable from "./CustomerActivituByHomeBranchTable";
import SearchBar from "../../SearchBar";

export default function StoreCustomerActivityByHomeBranch() {
    const { data, isLoading } = useHomeBranchTableData({})
    const information =
        <div style={{ maxWidth: '50rem' }}>
            <span style={{ fontWeight: 'bold' }}>Customer Homebranch</span> is updated quarterly, with metrics based on the previous 12-month period.
            It is determined based on the number of visits, spending amount, and recency of the transactions of the members at the branches.
        </div>

    return <Section
        title={'Customer Activity by Homebranch'} information={information}
    >
        <CustomerActivityByHomeBranchTable data={data} isLoading={isLoading} />
    </Section >
}