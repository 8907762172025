// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
import deepmerge from "deepmerge";
import {combineMerge} from "../../utils/merge";
import ReactEcharts from "echarts-for-react";


export default function LineGraph({data, title, isLoading, chartOptions, chartProps, chartRef}) {
    const dataValues = Object.values(data)
    const dataKeys = Object.keys(data)

    const defaultChartOptions = {
        
        tooltip: {},
        xAxis: {
          type: 'category',
          data: dataKeys,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: dataValues,
            type: 'line'
          }
        ]
    };

    if (title) {
        defaultChartOptions.title = {
            text: title,
            left: 'center',
            textStyle: {
                color: '#000',
                fontSize: 14,
                fontWeight: 'normal'
            }
        }
    }

    const effectiveChartOptions = deepmerge(defaultChartOptions, (chartOptions || {}), {
        arrayMerge: combineMerge
    })
    const effectiveChartProps = deepmerge({style: { minHeight: "410px", height: "100%", width: "100%"}}, (chartProps || {}), {
        arrayMerge: combineMerge
    })

    return <>
			<ReactEcharts
        showLoading={isLoading}
				notMerge={true}
				lazyUpdate={true}
				opts={{renderer: 'svg'}}
        ref={chartRef}

        {...effectiveChartProps}
        option={effectiveChartOptions}
			/>
    </>
}