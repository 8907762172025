import React, { useContext, useMemo, useState } from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { Grid, Typography, Card, Box, Collapse } from '@mui/material';
import HorizontalBarGraph from './horizontalBarGraph';
import CollapsibleComponent from '../../../../common/components/CollapsibleComponent';
import { useFiltersContext, useTogglePeriodContext } from '../Provider/context';
import { getParamsBasedOnPeriod } from '../util';
import { useCustomerContactabilityV2 } from './hooks';
import LoadingComponent from '../../../../common/components/LoadingComponent';


export default function Contactability() {
    const windowSize = useContext(WindowSizeContext);
    const { filters } = useFiltersContext();
    const { period } = useTogglePeriodContext();
    const isMTD = period === 'mtd'
    const isQoQ = period ==='qoq'    
    
    const params = {  
        month: filters?.month,  
        year: filters?.year,  
        cm_channel_desc: filters?.channel  
    }  
    if (filters.hasOwnProperty('smac_consent') && filters.smac_consent !== undefined && filters.smac_consent !== '') {
        const paramsConsentKey = `smac_cdp_consent__is_${filters.smac_consent.toString()}`  // evaluates to smac_consent__is_true or smac_consent__is_false  
        params[paramsConsentKey] = 1  
    }  

    const paramsBasedOnPeriod = getParamsBasedOnPeriod(isMTD, isQoQ, params);

    const [isExpand, setExpand] = useState(true);

    const {contactData: data, isLoading} = useCustomerContactabilityV2({...paramsBasedOnPeriod})
        
    const contactabilityData = {
        'Email Only': data?.with_email_sum,
        'Mobile Only': data?.with_mobile_sum,
        'With Mobile and Email': data?.with_mobile_email_sum,
        'No Contact Info Provided': data?.not_contactable_sum
    };

    // const contactabilityData = {
    //     'Email Only': 252318,
    //     'Mobile Only': 1302547,
    //     'With Mobile and Email': 1482983,
    //     'Not Contactable': 120695,
    // };

    return (
        <Grid item xs={12} py={.5}>
            <Grid container marginTop={0.5}>
                <Grid item sm={12} xs={12}>
                    <Card sx={{borderRadius: ".5rem .5rem 0 0"}}>
                        <Box flexGrow={1} xs={12} sx={{backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0"}}>
                            <Grid container alignItems="center" py={.5}>
                                {
                                    windowSize.isOnMobile ? <>
                                        <Grid item xs={12} display="flex" justifyContent={'space-between'} alignItems={'center'}
                                                mx={.5}>
                                            <Typography variant="h6" ml={2.5}>
                                                Contactability
                                            </Typography>
                                            <CollapsibleComponent isExpand={isExpand} setExpand={setExpand}/>
                                        </Grid>
                                    </> : <>
                                        <Grid item xs={9}>
                                            <Typography variant="h6" ml={2.5}>
                                                Contactability
                                            </Typography>
                                        </Grid>
                                        <Grid item xs justifyContent={'end'}>
                                            <CollapsibleComponent isExpand={isExpand} setExpand={setExpand}/>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Box>
                        <Collapse in={isExpand}>
                            <Grid container spacing={1}>
                                <Grid item sm={12} xs={12} sx={{ overflowY:'hidden'}}>
                                    <Grid item container direction={windowSize?.isOnMobile ? 'column' : 'row'} sm={12} xs={12} padding="1rem" display="flex" justifyContent="space-between">
                                        <Card sx={{ minHeight: '20rem', width: '100%' }}>
                                            <HorizontalBarGraph data={contactabilityData} isLoading={isLoading} />
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}