import React, { useMemo, useContext } from 'react';
import { getSelectedOption } from '../../../../common/utils';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useSelectorContext, useSelectorValue } from "../../common/providers/selectorProvider";
import { InputAdornment } from '@mui/material';

export default function MetricFilter({ isMTD, filters, onFilterChange, store, business_unit, zone, optionsFilter }) {
    const windowSize = useContext(WindowSizeContext);

    const defaultOptionsFilter = (option, viewBy) => {
        // by default, zone view doesn't have actual_units_sold and actual_units_sold_per_txn
        if (viewBy === 'zone' && ['actual_units_sold', 'actual_units_sold_per_txn'].includes(option.value)) {
            return false;
        }

        return true;
    }
    const _optionsFilter = optionsFilter || defaultOptionsFilter;

    const legacySelectedViewBy = filters?.group;
    const selectorSelectedViewBy = useSelectorValue('viewBy');
    const selectedViewBy = selectorSelectedViewBy || legacySelectedViewBy;

    const legacySelectedMetric = filters?.metric_column;
    const selectorSelectedMetric = useSelectorValue('metric');
    const selectedMetric = selectorSelectedMetric || legacySelectedMetric;
    const useLegacyMetric = !selectorSelectedMetric;

    const { setValue: setSelectorValue } = useSelectorContext();

    const isGroupChannel = selectedViewBy === 'channel';
    const isGroupBU = selectedViewBy === 'business_unit' || selectedViewBy === 'bu_group_3';
    const isGroupZone = selectedViewBy === 'zone';
    const isGroupHigherMBU = selectedViewBy === 'higher_mbu';

    const options = useMemo(() => {
        const availableOptions = [
            { value: 'actual_sales', label: 'Sales' },
            { value: 'actual_transactions', label: 'Transactions' },
            { value: 'actual_basket_size', label: 'Basket Size' },
            { value: 'actual_catchment_rate', label: 'Catchment Rate', viewByBlacklist: ['channel', 'business_unit', 'bu_group_3', 'higher_mbu'] },
            { value: 'actual_conversion_rate', label: 'Conversion Rate', viewByBlacklist: ['channel', 'business_unit', 'bu_group_3', 'higher_mbu'] },
            { value: 'actual_smac_sales', label: 'SMAC Sales' },
            { value: 'actual_space_tfa', label: 'Space Productivity TFA', viewByBlacklist: ['channel', 'business_unit', 'bu_group_3'] },
            { value: 'actual_space_nsa', label: 'Space Productivity NSA', viewByBlacklist: ['channel'] },
            { value: 'actual_mall_foot_traffic', label: 'Mall Foot Traffic', viewByBlacklist: ['channel', 'business_unit', 'bu_group_3'] },
            { value: 'actual_sm_store_foot_traffic', label: 'Dept Store Foot Traffic', viewByBlacklist: ['channel', 'business_unit', 'bu_group_3'] },
            { value: 'actual_units_sold', label: 'Units Sold' },
            { value: 'actual_units_sold_per_txn', label: 'Units Sold Per Transaction' }
        ].filter(option => {
            if (option.viewByBlacklist) {
                return !option.viewByBlacklist?.includes(selectedViewBy)
            }
            return true;
        }).filter(option => {
            return _optionsFilter(option, selectedViewBy);
        });

        if (zone || store) {// Metrics for zone view ops or branch view ops
            const notMetricsForZoneView = ['actual_catchment_rate', 'actual_conversion_rate', 'actual_units_sold', 'actual_units_sold_per_txn'];
            return availableOptions?.filter(option => !notMetricsForZoneView?.includes(option.value));

        } else if (isGroupHigherMBU) {  // for BU view
            if (business_unit) {// Metrics for bu view merch
                const notMetricsForHigherMBU = [];
                return availableOptions?.filter(option => !notMetricsForHigherMBU?.includes(option.value));
            } else { // Metrics for bu view ops
                const notMetricsForHigherMBU = ['actual_space_nsa'];
                return availableOptions?.filter(option => !notMetricsForHigherMBU?.includes(option.value));
            }
        }

        return availableOptions;
    }, [isGroupChannel, isGroupBU, isGroupZone, isGroupHigherMBU, store, business_unit]);

    const selectedValue = getSelectedOption(selectedMetric, options)

    return (
        <>
            <ConditionalSelectComponent
                id="metric_filter"
                labelId="metric_filter-label"
                options={options}
                label="Metric"
                value={selectedValue}
                onSelectChange={(event, newValue) => {
                    const value = windowSize.isOnMobile ? event?.target?.value : newValue?.value;
                    if (useLegacyMetric) {
                        onFilterChange(value, 'metric_column');
                    } else {
                        setSelectorValue('metric', value);
                    }
                }}
                startAdornment={'Metric:'}
                endAdornment={false}
                clearSelected={() => onFilterChange('metric_column', '')}
                disableClearable={true}
            />
        </>
    );
}
