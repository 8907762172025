import React, { useContext, useMemo, useState } from "react";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import { useAvailableDateRange, useGraphInTableData, useStorePerformanceData } from "../hooks";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import {
    convertNumberToLocaleString,
    convertToValidNumber,
    displayNumber,
    getSelectedOption
} from "../../../../common/utils";
import BarCellDisplay from "../BarCellDisplay";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { Box, Grid, Typography } from '@mui/material';
import DropdownCheckboxV3 from "../../../../common/components/DropdownCheckboxV3";
import Item from "../../../../common/components/ItemCard";
import ToggleMtdYtd from "../toggleMtdYtd";
import { CATEGORY } from "../constants";
import { getDaysDifference, getPast12months, numberCellDisplay } from '../util';
import moment from "moment";
import BarTrends from "../../TmpTSSSales/BarTends";


export default function StorePerformance({ params, filters, selectedRows, orderArray, period, onToggleChange }) {

    const [storeFilters, setStoreFilters] = useState([])
    const [metricFilters, setMetricFilters] = useState({
        metric_column: '',
        condition: '',
        metric_value: ''
    })

    const isMTD = period === 'mtd';

    const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] }
    const filterByApiKeys = [
        'subdept_id',
        'subdept_code',
        'subdept_desc',
        'product_category'
    ]
    const apiFilterKey = filterByApiKeys.includes(filters?.group) ? filters?.group : false;

    const { data: storePerformanceData, isLoading: isLoadingStorePerformance } = useStorePerformanceData({
        params: {
            ...paramsBasedOnPeriod,
            group_by: 'store_id,ace_store_desc,store_format',
            limit: 1000,
            [apiFilterKey]: apiFilterKey ? selectedRows : null
        },
    });

    const dateParams = useMemo(() => {
        return getPast12months(params?.month?.[0], params?.year)
    }, [params?.month, params?.year])

    const { data: graphData, isLoading: isGraphDataLoading } = useGraphInTableData({
        params: {
            year: dateParams?.year,
            month: dateParams?.month,
            group_by: 'store_id',
            [apiFilterKey]: apiFilterKey ? selectedRows : null
        }
    });

    const { data: dateRange, isLoading: isLoadingDateRange } = useAvailableDateRange()
    const paramsPeriod = params?.month?.[0] && params?.year ? params?.year + '-' + params?.month?.[0] : moment().date(new Date()).format('YYYY-MM');
    const periodStartDate = isMTD ? moment(paramsPeriod).startOf('month').format('YYYY-MM-DD') : moment(paramsPeriod).startOf('year').format('YYYY-MM-DD');
    const periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
    const maxAvailableDate = !isLoadingDateRange && moment(dateRange?.max).format('YYYY-MM-DD');
    const isMonthNotEqualToMax = params?.month?.[0] !== moment(maxAvailableDate).month() + 1; //if selected params is not equal to max date.
    const maxDaysInPeriod = isMonthNotEqualToMax ? periodEndDate : maxAvailableDate;
    const numberOfDaysWithinThePeriod = getDaysDifference(periodStartDate, periodEndDate);
    const maxAvailableDaystheSelectedMonth = getDaysDifference(periodStartDate, maxDaysInPeriod)

    const getRunningDailyTarget = (target) => {
        return (target / numberOfDaysWithinThePeriod) * maxAvailableDaystheSelectedMonth
    };

    const windowSize = useContext(WindowSizeContext)

    const newStorePerformanceData = useMemo(() => {
        if (selectedRows?.length > 0 && !apiFilterKey) {
            return storePerformanceData?.filter(data => {
                return selectedRows.includes(data?.[filters?.group])
            })
        } else {
            return storePerformanceData
        }
    }, [storePerformanceData, selectedRows, filters?.group])
    const HeaderDisplay = (header) => {
        return <Box display='flex' justifyContent='center' width='100%'>{header}</Box>
    }


    const optionListStore = (newStorePerformanceData || [])
        .map((row) => ({
            value: row?.store_id,
            label: row?.ace_store_desc,
        }))
        .filter((option) => option.value !== "" && option.label !== "")
        .sort((a, b) => a.label?.localeCompare(b.label));

    function handleSelectStore(data) {
        let result = data.map(a => a.value);
        setStoreFilters(result)
        return result
    }

    function handleMetricFiltersChange(value, name) {
        setMetricFilters({
            ...metricFilters,
            [name]: value
        })
    }

    const dataToDisplay = storeFilters?.length > 0 ?
        newStorePerformanceData.filter(obj => storeFilters.includes(obj?.store_id)) :
        newStorePerformanceData

    // Function to calculate filteredDataSubtotal
    const calculateFilteredDataSubtotal = (data) => {
        const newSubtotal = {
            actual_sales: null,
            target_sales: null,
            vs_target: null,
            // last_year_actual_sales: null,
            vs_last_year_mtd_ytd_sales: null,
            vs_2019_actual_sales: null
        };

        // Iterate through data and push values into the corresponding arrays
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const newValues = data[key];

                let sum = 0;
                for (let i = 0; i < newValues.length; i++) {
                    if (typeof newValues[i] === 'string' || isNaN(parseFloat(newValues[i]))) {
                        newValues[i] = 0;
                    }
                    sum += parseFloat(newValues[i]);
                }

                if (String(key).startsWith('vs')) {
                    // Average values
                    newSubtotal[key] = (
                        sum !== 0 ?
                            <Box sx={{ width: '7rem', height: '2.5rem', marginX: 'auto' }}>
                                <BarCellDisplay value={convertToValidNumber(sum / (newValues.length || 1))?.toFixed()}
                                    isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </Box> : '-'
                    );
                } else {
                    // Sum values
                    newSubtotal[key] = (
                        <Box sx={{ whiteSpace: 'nowrap', width: '6.5rem' }}>
                            {sum !== 0 ? (key !== 'sales_per_sqm' ? '₱' : '') + displayNumber(sum, windowSize.isOnMobile) : '-'}
                        </Box>
                    );
                }
            }
        }

        return newSubtotal;
    };

    const filteredData = useMemo(() => {
        let filteredDataToDisplay = dataToDisplay;

        if (!!metricFilters?.metric_column && !!metricFilters?.condition && metricFilters?.metric_value !== "") {
            if (metricFilters?.condition === 'greater_than_equal') {
                filteredDataToDisplay = filteredDataToDisplay?.filter(data => {
                    return data?.[metricFilters?.metric_column] >= parseFloat(metricFilters?.metric_value);
                });
            } else if (metricFilters?.condition === 'less_than_equal') {
                filteredDataToDisplay = filteredDataToDisplay?.filter(data => {
                    return data[metricFilters?.metric_column] <= parseFloat(metricFilters?.metric_value);
                });
            }
        }

        return filteredDataToDisplay.sort((a, b) => {
            return b.actual_sales - a.actual_sales;  // FIXME: this is a hack for default sorting
        });

    }, [dataToDisplay, metricFilters])

    const hasFooterSubtotal = useMemo(() => {
        return filteredData.length !== 0 && filteredData.length <= 5;
    }, [filteredData])

    const filteredDataSubtotals = useMemo(() => {
        if (hasFooterSubtotal) {
            const newRowValues = {
                actual_sales: [],
                target_sales: [],
                vs_target: [],
                // last_year_actual_sales: [],
                vs_last_year_mtd_ytd_sales: [],
                vs_2019_actual_sales: []
            };

            // Iterate through dataToDisplay and push values into the corresponding arrays
            filteredData?.forEach(data => {
                newRowValues.actual_sales.push(parseFloat(data.actual_sales));
                newRowValues.target_sales.push(parseFloat(data.target_sales));
                newRowValues.vs_target.push(parseFloat((data?.actual_sales / getRunningDailyTarget(data?.target_sales)) * 100));
                // newRowValues.last_year_actual_sales.push(parseFloat(data.last_year_actual_sales));
                newRowValues.vs_last_year_mtd_ytd_sales.push(parseFloat(data.vs_last_year_mtd_ytd_sales));
                newRowValues.vs_2019_actual_sales.push(parseFloat(data.vs_2019_actual_sales));
            });

            return calculateFilteredDataSubtotal(newRowValues);
        }
        return {};
    }, [filteredData])


    const store_performance_col = useMemo(
        () => [
            {
                id: 'store',
                header: 'Store',
                accessorKey: 'ace_store_desc',
                cell: (row) => <Box sx={{ minWidth: '7.5rem', paddingLeft: "1rem" }}> {row?.getValue()} </Box>,
                customStyle: { width: '15rem' }
            },
            {

                header: isMTD ? 'MTD' : 'YTD',
                accessorKey: 'actual_sales',
                id: 'actual_sales',
                cell: (row) => <Box sx={{
                    width: { sm: '25%' },
                    marginX: 'auto'
                }}>{numberCellDisplay(row?.getValue(), windowSize?.isOnMobile)} </Box>,
                customStyle: { width: '22rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                id: 'target_sales',
                header: isMTD ? 'MTD Plan' : 'YTD Plan',
                accessorKey: 'target_sales',
                cell: (row) => <Box sx={{
                    width: { sm: '35%' },
                    marginX: 'auto'
                }}>{numberCellDisplay(row?.getValue(), windowSize?.isOnMobile)} </Box>,
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                header: 'vs Plan',
                id: 'vs_target',
                accessorFn: (row) => (row?.actual_sales / row?.target_sales) * 100,
                cell: (row) => {
                    const percentage = row?.getValue()
                    const value = convertNumberToLocaleString(percentage);
                    const value2 = convertToValidNumber(percentage)?.toFixed();
                    return <>
                        {
                            value !== 0 ?
                                <Box sx={{ width: '7rem', height: '2.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <BarCellDisplay value={value2}
                                        isValueColorCoded={true} isOnTable={true} label="vs Sales Plan" />
                                </Box> : '-'
                        }
                    </>
                },
                customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: 'vs Last Year',
                id: 'vs_last_year_mtd_sales',
                accessorKey: 'vs_last_year_mtd_ytd_sales',
                cell: (row) => {

                    const value = convertNumberToLocaleString(row?.getValue());
                    const value2 = convertToValidNumber(row?.getValue())?.toFixed();;

                    return <>
                        {
                            value !== 0 ?
                                <Box sx={{ width: '7rem', height: '2.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <BarCellDisplay value={value2}
                                        isValueColorCoded={true} isOnTable={true} label="vs Last Year" />
                                </Box> : '-'
                        }
                    </>
                },
                customStyle: { textAlign: 'center', verticalAlign: 'middle', width: '7rem' }
            },
            {
                header: 'vs 2019',
                id: 'vs_sales_2019',
                accessorKey: 'vs_2019_actual_sales',
                cell: (row) => {
                    const value = convertNumberToLocaleString(row?.getValue());
                    const value2 = convertToValidNumber(row?.getValue())?.toFixed();

                    return <>
                        {
                            value !== 0 ?
                                <Box sx={{ width: '7rem', height: '2.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <BarCellDisplay value={value2}
                                        isValueColorCoded={true} isOnTable={true} label="vs 2019" />
                                </Box> : '-'
                        }
                    </>
                },
                customStyle: { textAlign: 'center', verticalAlign: 'middle', width: '7rem' }
            },
            {
                header: '12 Mth Sales Trend vs Sales Plan',
                id: '12th_mth_sales_trend',
                accessorFn: (row) => (row?.actual_sales / row?.target_sales) * 100,
                cell: (row) => {
                    const groupValue = row?.row?.original?.['store_id'];
                    const barTrendData = graphData[groupValue];
                    const newGraphData = barTrendData?.filter(data => dateParams?.monthYear.includes(data?.month + '|' + data?.year))
                    return <>
                        {
                            isGraphDataLoading ? <LoadingComponent /> : <Box sx={{ width: '13rem', height: '2.5rem', marginX: 'auto' }}>
                                <BarTrends data={newGraphData} vsPlan={row?.getValue() ?convertNumberToLocaleString(row?.getValue()) : undefined}/>
                            </Box>
                        }
                    </>
                },
            }
        ],
        [newStorePerformanceData, filters?.group, period, getRunningDailyTarget]
    )

    if (isLoadingStorePerformance) {
        return <LoadingComponent />
    }

    function activeFiltersText() {
        let selectedCategory = CATEGORY[filters?.group];
        const withTopLabel = ['888', '4k']
        let filtersText = selectedRows && selectedRows.length > 0 ? (
            <span>
                - {selectedRows.map(item => {
                    if (withTopLabel.includes(item)) {
                        return 'Top ' + item
                    }
                    return item
                }).join(', ')}
            </span>
        ) : '';
        return (
            <span>
                Filters Selected | Grouping: {selectedCategory} {filtersText}
            </span>
        );
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }} mt={3}>
                <Grid container columns={16} columnSpacing={1} rowSpacing={.5} alignItems="center">
                    {
                        windowSize?.isOnMobile ? <>
                            <Grid item xs sm={2.5}>
                                <Typography variant='h6' ml={2}>Store Performance</Typography>
                            </Grid>
                            <Grid item xs display="flex" justifyContent={'end'}>
                                <ToggleMtdYtd
                                    value={period}
                                    onChange={onToggleChange}
                                />
                            </Grid>
                            <Grid item xs={16} sm={5} mb={.5} display="flex" alignItems="center">
                                <DropdownCheckboxV3
                                    id="sales_store_filter"
                                    options={optionListStore}
                                    label="Store"
                                    placeholder="Select a store"
                                    limitTags={2}
                                    onChange={(event, newValue) => {
                                        handleSelectStore(getSelectedOption(newValue, optionListStore));
                                    }}
                                    value={getSelectedOption(storeFilters, optionListStore)}
                                />
                            </Grid>
                        </> : <>
                            <Grid item xs={16} sm={2.5}>
                                <Typography variant='h6' ml={2}>Store Performance</Typography>
                            </Grid>
                            <Grid item xs={16} sm={5} display="flex" alignItems="center">
                                <DropdownCheckboxV3
                                    id="sales_store_filter"
                                    options={optionListStore}
                                    label="Store"
                                    placeholder="Select a store"
                                    limitTags={2}
                                    onChange={(event, newValue) => {
                                        handleSelectStore(getSelectedOption(newValue, optionListStore));
                                    }}
                                    value={getSelectedOption(storeFilters, optionListStore)}
                                />
                            </Grid>
                            {/*<Grid item  xs={16} sm={3}>*/}
                            {/*    <MetricFilter isMTD={isMTD} filter={metricFilters} onFilterChange={handleMetricFiltersChange} />*/}
                            {/*</Grid>*/}
                            {/*<Grid item  xs={16} sm={2}>*/}
                            {/*    <ConditionFilter filter={metricFilters} onFilterChange={handleMetricFiltersChange} />*/}
                            {/*</Grid>*/}
                            {/*<Grid item  xs={16} sm={3}>*/}
                            {/*    <MetricInputValueFilter filter={metricFilters} onFilterChange={handleMetricFiltersChange}/>*/}
                            {/*</Grid>*/}
                            <Grid item sm={8.5} xs={16} display="flex" justifyContent={'end'}>
                                <ToggleMtdYtd
                                    value={period}
                                    onChange={onToggleChange}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid item xs={12} marginBottom={2} sx={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>
                <Grid item xs={12} sm={12} sx={{
                    boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
                    border: '1px solid #e0e0e0',
                    backgroundColor: '#e1ebf2'
                }}>
                    <Typography ml={2} sx={{ borderRadius: '0', color: '#000' }}>{activeFiltersText()}</Typography>
                </Grid>
                <Item>
                    <TableComponentV2
                        customTableContainerStyle={{
                            borderRadius: "0",
                            boxShadow: 'none',
                        }}
                        customTableHeaderStyle={{
                            backgroundColor: '#caddeb'
                        }}
                        columns={store_performance_col}
                        data={filteredData}
                        tableId={'SalesStorePerformanceTable'}
                        hasPagination={true}
                        isCompact={true}
                        initialSort={{ id: 'actual_sales', desc: true }}
                        hasFooterSubtotal={hasFooterSubtotal}
                        subtotalRow={filteredDataSubtotals}
                        initialState={{
                            columnVisibility: { "12th_mth_sales_trend": !isMTD }
                        }} />
                </Item>
            </Grid>
        </>
    );
}