import DashboardContent from "./DashboardContent";
import DashboardList from "./DashboardList";
import './index.css';
import { useState } from "react";
import { useSaveDashboard } from "../../../common/API/hooks";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";

export default function DashboardEditor() {
    const [selectedDashboardData, setSelectedDashboardData] = useState(null)
    const [tmpDashboards, setTmpDashboards] = useState([]);

    const dashboardServerId = selectedDashboardData?.id.toString().startsWith("tmp-") ? null : selectedDashboardData?.id
    const { saveDashboardAsync } = useSaveDashboard(dashboardServerId)

    return <>
        <DashboardList
            dashboard={selectedDashboardData}
            onChangeDashboard={(newData) => {
                setSelectedDashboardData(newData)
            }}
            tmpDashboards={tmpDashboards}
            setTmpDashboards={setTmpDashboards}
        />


        <Divider sx={{ my: 3, borderBottom: 3 }} />

        <DashboardContent
            data={selectedDashboardData}
            onSave={(newData) => {
                const saveDsahboardPromise = saveDashboardAsync(newData).then((responseData) => {
                    // update the selected dashboard data
                    setSelectedDashboardData(responseData)
                })

                toast.promise(saveDsahboardPromise, {
                    pending: 'Saving Dashboard...',
                    success: 'Dashboard Saved Successfully',
                    error: 'Saving Dashboard failed'
                });

                // delete from tmp list
                if (newData.id.toString().startsWith("tmp-")) {
                    setTmpDashboards(tmpDashboards.filter(d => d.id !== newData.id))
                }

            }}
            key={selectedDashboardData?.id} />
    </>
}