import React from "react";
import { SELECTOR_TYPES_BY_CHART_TYPE } from "../../constants";
import LabelSelector from "./LabelSelector";
import ValueSelector from "./ValueSelector";
import ColumnSelector from "./ColumnSelector";
import AggregateSelector from "./AggregateSelector";
import AxisSelector from "./AxisSelector";


export default function SelectorComponents({ chartData, setChartData }) {
    // Depending on the chart type, we need to show different selectors

    return <>
        {
            SELECTOR_TYPES_BY_CHART_TYPE[chartData.chart_type]?.includes('label') &&
            <div className="row mb-2">
                <LabelSelector chartData={chartData} setChartData={setChartData} />
            </div>
        }

        {
            SELECTOR_TYPES_BY_CHART_TYPE[chartData.chart_type]?.includes('value') &&
            <div className="row mb-2">
                <ValueSelector chartData={chartData} setChartData={setChartData} />
            </div>
        }
        {
            SELECTOR_TYPES_BY_CHART_TYPE[chartData.chart_type]?.includes('column') &&

            <ColumnSelector
                onChangeColumns={(newColumns) => {
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            extra_data: {
                                ...prevState.extra_data,
                                columns: newColumns
                            }
                        }
                    })
                }}
                columns={chartData?.extra_data?.columns}
                tableId={chartData.table_source}
                showMultiValueComponent={true}
                label="Column"
            />
        }

        {
            SELECTOR_TYPES_BY_CHART_TYPE[chartData.chart_type]?.includes('aggregate') &&
            <div className="row mb-2">
                <AggregateSelector
                    onChangeColumns={(newAggregates) => {
                        setChartData(prevState => {
                            return {
                                ...prevState,
                                extra_data: {
                                    ...prevState.extra_data,
                                    aggregates: newAggregates
                                }
                            }
                        })
                    }}
                    columns={chartData?.extra_data?.aggregates}
                    tableId={chartData.table_source}
                />
            </div>
        }

        {
            SELECTOR_TYPES_BY_CHART_TYPE[chartData.chart_type]?.includes('x') &&
            <div className="row mb-2">
                <AxisSelector axisName="x_axis" axisLabel="X axis" chartData={chartData}
                    setChartData={setChartData} />
            </div>
        }

        {
            SELECTOR_TYPES_BY_CHART_TYPE[chartData.chart_type]?.includes('y') &&
            <div className="row mb-2">
                <AxisSelector axisName="y_axis" axisLabel="Y axis" chartData={chartData}
                    setChartData={setChartData} />
            </div>
        }
    </>
}