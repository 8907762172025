import React from 'react';
import {useModuleList} from "../../../common/API/hooks";
import {DASHBOARD_GROUPS} from "../../../Constants/customDashboards";
import {useSave} from "../utils";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import handleError from "../ErrorHandling";


export default function UnregisteredModules() {
    const {data: moduleList, isLoading: moduleLoading} = useModuleList();
    const {saveData: saveModule} = useSave()

    const dashboardPaths = DASHBOARD_GROUPS.flatMap(dashboardGroup => {
        return dashboardGroup.contents.filter(content => {
            return !!content.path
        }).map(content => {
            return content.path.split('?')[0].trim().replace(/^\/+|\/+$/g, '')  // remove trailing slashes and empty spaces
        })
    }).reduce((acc, val) => {
        if (!acc.includes(val)) {
            acc.push(val)
        }
        return acc
    }, [])

    if (!moduleList || !dashboardPaths || dashboardPaths.length === 0) {
        return null
    }

    const registeredPaths = moduleList.map(row => row.name)

    const unregisteredModules = dashboardPaths.filter(path => !registeredPaths.includes(path))

    return <>
        The following dashboards are detected but are not yet in the module list.

        <ul>
            {
                unregisteredModules.map(path => {
                    return <li key={path}>
                        {path}
                        <Button onClick={() => {
                            saveModule('module', {name: path}).then(() => {
                                return toast.success(`Module "${path}" saved!`);
                            }).catch(error => {
                                console.error(error);
                                const errorMessage = handleError(error, "Module", "save");
                                return toast.error(errorMessage);
                            });
                        }}>REGISTER</Button>
                    </li>
                })
            }

        </ul>
    </>
}