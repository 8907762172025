import React, { useContext } from "react";
import { Card, Grid, Typography, Box } from "@mui/material";
import groupIcon from '../../../../../../src/img/groupIcon.svg'
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext";
import { useSMACMembersDataV2, useSMACMembersDataV3 } from "./hooks";
import { abbreviateNumber } from "../../../../../common/utils";
import NumericValueDisplay from "../numericValueDisplay";
import BarIndicator from "../barIndicator";
import SMACStart from '../branchView/LocationsComponents/MapComponent/images/SMAC-Start.png';
import SMAC from '../branchView/LocationsComponents/MapComponent/images/SMAC.png';
import SMACPrestige from '../branchView/LocationsComponents/MapComponent/images/SMAC-Prestige.png';
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import CustomerCardType from "./SMACCardTypes";
import moment from "moment";

export default function SMACCustomerInformation({ params, period, viewBy, isOnDefaultView, isStickyCustomerCarousel, cardStyles }) {
    const windowSize = useContext(WindowSizeContext);

    // Fetch SMAC member data
    // const { data, isLoading } = useSMACMembersDataV2({ params, viewBy });

    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';
    const paramsBasedOnPeriod = isQoQ
        ? {
            ...params,
            month: Array.from({ length: 3 }, (_, i) =>
                moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month() + i + 1
            )
        }
        : isMTD ? { ...params } : { ...params, month: [1, ...(params?.month || [])] };

    const { totalData, cardTypeData, isLoading } = useSMACMembersDataV3({ params: { ...paramsBasedOnPeriod }, viewBy });

    const { totalData: totalDataLastYear, cardTypeData: cardTypeDataLastYear, isLoading: isLoadingLastYear } = useSMACMembersDataV3({
        params: {
            ...paramsBasedOnPeriod,
            year: params.year - 1
        },
        viewBy,
        queryOptions: {
            isQueryForVsLastYear: true
        }
    });

    // Calculate total SMAC members and members count by type
    const totalSMACMembers = totalData ? totalData[0]?.card_type_count : 0;
    const totalSMACMembersLastYear = totalDataLastYear ? totalDataLastYear[0]?.card_type_count : 0;

    const memberCountByType = cardTypeData ? cardTypeData.reduce((acc, cur) => {
        acc[cur.card_type] = cur.card_type_count;
        return acc;
    }, {}) : {};

    const memberCountByTypeLastYear = cardTypeDataLastYear ? cardTypeDataLastYear.reduce((acc, cur) => {
        acc[cur.card_type] = cur.card_type_count;
        return acc;
    }, {}) : {};


    const CardOptionValues = [
        { type: SMACStart, count: memberCountByType['START'], countLastYear: memberCountByTypeLastYear['START'], isLoading: isLoading },
        { type: SMAC, count: memberCountByType['SMAC'], countLastYear: memberCountByTypeLastYear['SMAC'], isLoading: isLoading },
        { type: SMACPrestige, count: memberCountByType['PRESTIGE'], countLastYear: memberCountByTypeLastYear['PRESTIGE'], isLoading: isLoading }]

    if (isOnDefaultView || isStickyCustomerCarousel) {
        return (
            <Card sx={cardStyles}>
                <Grid container xs={12} columnGap={3} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs sm >
                        <Grid item xs display={'flex'} columnGap={1} alignItems={'center'} justifyContent={'center'}>
                            <img src={groupIcon} alt={groupIcon} height='30rem' width='auto' />
                            <Typography variant='h6' fontWeight='bold'> SMAC Members: </Typography>
                            {isLoading ? <LoadingComponent sxClasses={{ py: .5 }} />
                                :
                                <Typography variant='h6' fontWeight={500} ><NumericValueDisplay value={totalSMACMembers} isAbbreviated={true} /></Typography>
                            }
                        </Grid>
                        {isOnDefaultView && <Grid container data-tag="allowRowEvents" my={1.5} justifyContent={'space-evenly'}>
                            <Grid item textAlign={'center'} display={'flex'} alignItems={'center'}>
                                {isLoading ? <LoadingComponent sxClasses={{ py: .5 }} />
                                    :
                                    <Box sx={{ width: '5rem', height: '1.8rem' }}>
                                        <BarIndicator
                                            value={(totalSMACMembers / totalSMACMembersLastYear * 100).toFixed(1)} isValueColorCoded={true} isOnTable={true}
                                            label={'vs last year'} />
                                    </Box>
                                }
                                <Typography variant="body2" ml={1}>{'vs last year'}</Typography>
                            </Grid>
                        </Grid>}
                    </Grid>
                    {CardOptionValues?.map((item) => (
                        <CustomerCardType
                            windowSize={windowSize}
                            totalSMACMembers={totalSMACMembers}
                            isStickyCustomerDataCardDisplay={isStickyCustomerCarousel}
                            isCustomerDataCardDisplay={!isStickyCustomerCarousel}
                            {...item} />
                    ))}
                </Grid>
            </Card>
        )
    }


    return (
        <Card sx={{ p: 2, width: '100%', borderRadius: ' 0 0 .5rem .5rem' }}>
            <Grid container xs={12} columnGap={3} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs sm >
                    <Grid item xs display={'flex'} columnGap={1} alignItems={'center'} justifyContent={'center'}>
                        <img src={groupIcon} alt={groupIcon} height='30rem' width='auto' />
                        <Typography variant='h6' fontWeight='bold'> SMAC Members: </Typography>
                        {isLoading ? <LoadingComponent sxClasses={{ py: .5 }} />
                            :
                            <Typography variant='h6' fontWeight={500} ><NumericValueDisplay value={totalSMACMembers} isAbbreviated={true} /></Typography>
                        }
                    </Grid>
                    <Grid container data-tag="allowRowEvents" my={1.5} justifyContent={'space-evenly'}>
                        <Grid item textAlign={'center'} display={'flex'} alignItems={'center'}>
                            {isLoading ? <LoadingComponent sxClasses={{ py: .5 }} />
                                :
                                <Box sx={{ width: '5rem', height: '1.8rem' }}>
                                    <BarIndicator
                                        value={(totalSMACMembers / totalSMACMembersLastYear * 100).toFixed(1)} isValueColorCoded={true} isOnTable={true}
                                        label={'vs last year'} />
                                </Box>
                            }
                            <Typography variant="body2" ml={1}>{'vs last year'}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {CardOptionValues?.map((item) => (
                    <CustomerCardType
                        windowSize={windowSize}
                        totalSMACMembers={totalSMACMembers}
                        isStickyCustomerDataCardDisplay={isStickyCustomerCarousel}
                        isCustomerDataCardDisplay={!isStickyCustomerCarousel}
                        {...item} />
                ))}
            </Grid>
        </Card>
    );
}