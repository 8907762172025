import React, { useMemo } from 'react';
import { useSelectorValue } from "../../../common/providers/selectorProvider";
import { LABEL_ALL_BRANCH, useSKUPerformanceTableData } from "../../hooks";
import { Box, Grid, Typography } from "@mui/material";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import SKUCellTooltip from './SKUCellTooltip';
import NoAvailableDataDisplay from '../../NoAvailableDataDisplay';


const thickBorder = '2px solid #C3C3C3!important'

export default function SKUPerformanceBySalesTable() {
    const filters = useSelectorValue('subDeptFilters');
    const branch = useSelectorValue('branch')

    const { data, isLoading } = useSKUPerformanceTableData({
        bu: filters?.bu, dept: filters?.dept, subdept: filters?.subdept
    });

    const sortRowsByRankPKFn = (rowA, rowB) => {
        return parseInt(rowA?.original?.pk) - parseInt(rowB?.original?.pk);
    }

    const tableColumns = useMemo(() => [
        {
            id: "rank",
            header: "Rank",
            accessorKey: "pk",
            cell: ({ getValue }) => (
                <Box sx={{ width: { sm: '20%' }, marginX: 'auto', my: .5 }}>
                    <NumericValueDisplay value={getValue()} />
                </Box>
            ),
            customStyle: { width: "3rem", textAlign: "left", verticalAlign: "middle", borderRight: thickBorder },
            sortingFn: sortRowsByRankPKFn
        },
        {
            id: "branch",
            header: branch === 'All Branch' ? LABEL_ALL_BRANCH : branch,
            columns: [
                {
                    id: "top_sku",
                    header: "Top SKU",
                    accessorKey: "topSKUs",
                    cell: ({ getValue }) => (
                        <Grid container>
                            <Grid item xs={12} sm={9} >
                                <Box sx={{ width: { sm: '65%' }, marginLeft:'auto', my: .5 }}>
                                    <Typography>{getValue()[0]}</Typography>
                                </Box>
                            </Grid>
                            
                            <Grid item xs={12} sm={3}>  <SKUCellTooltip skuData={getValue()} /> </Grid>
                        </Grid>
                    ),
                    customStyle: { width: "15rem", textAlign: "left", verticalAlign: "middle" },
                    headerStyle: { backgroundColor: "#E7F1F8" }
                },
                {
                    id: "bottom_sku",
                    header: "Bottom SKU",
                    accessorKey: "bottomSKUs",
                    cell: ({ getValue }) => (
                        <Grid container >
                            <Grid item xs={12} sm={9}>
                                <Box sx={{ width: { sm: '65%' },  marginLeft:'auto', my: .5 }}>
                                    <Typography>{getValue()[0]}</Typography>
                                </Box>
                            </Grid>
                            
                            <Grid item xs={12} sm={3}>  <SKUCellTooltip skuData={getValue()} /> </Grid>
                        </Grid>
                    ),
                    customStyle: { width: "15rem", textAlign: "left", verticalAlign: "middle" },
                    headerStyle: { backgroundColor: "#E7F1F8" }
                }
            ]
        }
    ], [branch]); 

    if (isLoading) {
        return <LoadingComponent />;
    }

    if (!data || data?.length === 0) {
        return <NoAvailableDataDisplay />
    }

    return (
        <TableComponentV2
            columns={tableColumns}
            initialSort={{ id: 'pk', asc: true }}
            data={data}
            tableId="skuPerformanceBySales"
            isCompact
            customTableHeaderStyle={{ backgroundColor: "#caddeb" }}
            customTableContainerStyle={{ borderRadius: 0, boxShadow: "none" }}
        />
    );
}