import React, { useState,useContext } from 'react';
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import ApiClient from '../../../common/API';
import { useQueryClient } from 'react-query';
import { WindowSizeContext } from '../../../common/context/WindowSizeContext';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import RefreshIcon from '@mui/icons-material/Refresh'
import { styled } from '@mui/material/styles';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 50,
    height: 30,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin:3,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(21px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#a6eb6f',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '8px solid #fff',
        
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 23,
      height: 23,
    },
    '& .MuiSwitch-track': {
      borderRadius: 15,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

export default function TableStatus({ tableData }) {
    const windowSize = useContext(WindowSizeContext);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const tableId = tableData?.id || ''
    const tableName = tableData?.name || ''
    const tableStatus = tableData?.is_active;
    const queryClient = useQueryClient();

    function changeStatus() {
        setIsLoading(true)
        ApiClient().post(`/data/table/${tableId}/toggle_is_active/`)
            .then(response => {
                // alert("Table status updated")
                setIsLoading(false);
                setOpenModal(!openModal)
                queryClient.invalidateQueries(['schema', 'list'])
                queryClient.invalidateQueries(['table', 'list'])
                queryClient.invalidateQueries(['data', 'table', tableId])
            })
            .catch(error => {
                console.error(error)
                setIsLoading(false)
                setOpenModal(!openModal)
            })
    }

    function RenderModal(modalStatus) {
        if (tableStatus) {
            return <ConfirmationModal
                id="deactivate_table"
                isOpen={modalStatus}
                icon={<WarningAmberRoundedIcon  sx={{fontSize:'100px', color:'#ef2b2b'}}/>}
                title="Deactivate Table"
                message={`You are about to deactivate ${tableName}. This will disable the execution of queries using the data within this table. Do you really want to deactivate this table?`}
                onClose={() => setOpenModal(false)}
                onConfirm={changeStatus}
                isLoading={isLoading}
            />
        } else {
            return <ConfirmationModal
                id="activate_table"
                isOpen={modalStatus}
                icon={<WarningAmberRoundedIcon  sx={{fontSize:'100px', color:'#ef2b2b'}}/>}
                title="Activate Table"
                message={`You are about to activate ${tableName}. This will enable the execution of queries using the data within this table. Do you really want to activate this table?`}
                onClose={() => setOpenModal(false)}
                onConfirm={changeStatus}
                isLoading={isLoading}
            />
        }
    }

    return (
        <Grid container height='50px'>
            <Grid item sx={{ ml:'auto'}} >
                <FormControlLabel sx={{justifyContent:'center'}}
                    label={<strong>Table Status </strong>}
                    labelPlacement='end'
                    control={
                    <IOSSwitch
                        sx={{mr:1}}
                        id="flexSwitchCheckReverse"
                        checked={tableStatus}
                        onChange={() => setOpenModal(true)}
                        disabled={!tableData}
                    />
                    } 
                />
            </Grid>
            {RenderModal(openModal)}
        </Grid>


    );
}