import { Grid, Typography, GlobalStyles, Box } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GroupSelector from '../selectors/groupSelector';
import ToggleSelectorWithAdditional from '../../../TmpTSSSales/Filters/ToggleSelectorWithAdditional';
import RenderZoneViewContent from './renderZoneViewContent';
import { WindowSizeContext } from '../../../../../common/context/WindowSizeContext';
import ZoneTopLineDataProvider from './DataProvider/zoneViewTopLineDataProvider.jsx';
import TopLine from '../TopLine';
import {useEnvironmentSetting} from "../../../../../common/providers/EnvironmentSettingsProvider";
import {ENABLE_TSS_ZONE_VIEW} from "../../../../../Constants/settings";
import TSSInformationToolTip from "../../../TmpTSSSales/TSSInformationToolTip";


const VIEW_BY = [
    { label: 'Operations', value: 'operations' },
    { label: 'Merchandise', value: 'merchandise', disabled: true },
    { label: 'Customer', value: 'customer', disabled: true },
    { label: 'Location', value: 'location', disabled: true },
    { label: 'Promotions', value: 'promotions', disabled: true },
]

const TOGGLE_OPTIONS = [
    { label: 'MTD', value: 'mtd' },
    { label: 'YTD', value: 'ytd' },
    { label: 'QoQ', value: 'qoq' },
]

export default function ZoneView() {
    const showZoneView = useEnvironmentSetting(ENABLE_TSS_ZONE_VIEW)
    const { zone, month, year, period } = useParams()
    const [viewBy, setViewBy] = useState('operations') // set operations as default
    const [togglePeriodState, setTogglePeriodState] = useState(period)
    const windowSize = useContext(WindowSizeContext)
    const [scrolling, setScrolling] = useState(false);
    const [prevScrollPos, setPrevScroll] = useState(0);

    const params = {
        month: [Number(month)],
        year: Number(year),
    }

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setTogglePeriodState(val);
        }
    };
    const [filters, setFilters] = useState({
        group: "channel",
        month: params?.month,
        year: params?.year,
        metric_column: 'actual_sales',
        zone: `${zone}`
    })

    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        setSelectedRows([])
    }, [filters.group, filters.metric_column])

    function onDropDownFilterChange(value, field) {
        setFilters({
            ...filters,
            [field]: value
        })
    }

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;

            // Set scrolling state based on scroll position and duration
            if (currentScrollPos <= 100) {
                setScrolling(false);
            } else {
                setScrolling(true);
            }

            setPrevScroll(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, windowSize.isOnMobile]);

    if (!showZoneView) {
        return <Typography variant='h5' fontWeight='bold' textAlign='center' paddingY={2}> Zone View is not enabled </Typography>
    }

    return <>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />

        <Grid container>
            <Grid container sx={{ position: `${!windowSize.isOnMobile ? 'sticky' : ''}`, top: `${!windowSize.isOnMobile ? '5rem' : ''}`, zIndex: 5, backgroundColor: '#e0e0e0' }}>
                <Grid item sm={12} xs={12} marginY={2} display={'flex'} alignItems={'center'}>
                    {/* TODO: use branch_id to get the actual name of store, currently branch_id is store name */}
                    <Typography variant='h5' fontWeight='bold'> {zone} </Typography>
                    <TSSInformationToolTip/>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: '#e0e0e0' }}>
                    <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem" }}>
                        <Grid container alignItems={'center'} columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} >
                            <Grid item xs sm={1} ml={2.5}>
                                <Typography variant="h6"> View By </Typography>
                            </Grid>
                            {viewBy !== 'location' && windowSize?.isOnMobile && <Grid item xs={6} display='flex' justifyContent='flex-end' >
                                <ToggleSelectorWithAdditional
                                    options={TOGGLE_OPTIONS}
                                    value={togglePeriodState}
                                    onChange={onTogglePeriodChange}
                                    additionalStyles={{ textTransform: 'none' }}
                                    lastIndexOfMain={2}
                                />
                            </Grid>}
                            <Grid item xs={12} sm={7} mx={windowSize.isOnMobile && 2}>
                                <GroupSelector options={VIEW_BY}
                                    onSelectGroupChange={(value) => setViewBy(value)} //strings value
                                    selected={viewBy} />
                            </Grid>
                            {viewBy !== 'location' && !windowSize?.isOnMobile && 
                                <Grid item sm display='flex' justifyContent='flex-end'>
                                    <ToggleSelectorWithAdditional
                                        options={TOGGLE_OPTIONS}
                                        value={togglePeriodState}
                                        onChange={onTogglePeriodChange}
                                        additionalStyles={{ textTransform: 'none' }}
                                        lastIndexOfMain={2}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
                {viewBy !== 'location' && 
                    <Grid item xs={12} mb={2} >
                        <ZoneTopLineDataProvider params={params} period={togglePeriodState} zone={zone} onTogglePeriodChange={onTogglePeriodChange} filters={filters}>
                            <TopLine topLineMetrics={!scrolling} isStickyForTopline={scrolling} params={params} period={togglePeriodState} onToggleChange={onTogglePeriodChange} filters={filters} />
                        </ZoneTopLineDataProvider>
                    </Grid>
                }
                {/* <Grid item xs={12}>
                    <BranchTopLineDataProvider params={params} period={togglePeriodState} storeID={branch_id} store={branch} onTogglePeriodChange={onTogglePeriodChange} filters={filters}>
                        <TopLine isStickyForTopline={scrolling} topLineMetrics={!scrolling} params={params} period={togglePeriodState} onToggleChange={onTogglePeriodChange} filters={filters} />
                    </BranchTopLineDataProvider>
                </Grid> */}
            </Grid>
            <RenderZoneViewContent viewBy={viewBy} zone={zone} params={params} period={togglePeriodState} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} />
        </Grid>
    </>
}