import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


const SORT_OPTIONS = [
    { value: 'created_at', label: 'Created At' },
    { value: 'title', label: 'Name' },
    { value: 'chart_type', label: 'Chart Type' }
];

const SortField = ({ sortField, setSortField }) => {

    return (
        <Autocomplete
        value={SORT_OPTIONS.find(option => option.value === sortField)}
            onChange={(event, newValue) => {
                setSortField(newValue.value);
            }}
            disableClearable
            id="chart_sort_options" size="small"
            options={SORT_OPTIONS}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Sort By" />}
        />
    );
}

export default SortField;
