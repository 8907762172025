export const getDecimalPointColor = (data) => {
    let color = '#000';
    if (data >= 0.50) {
        color = '#f2ac42'
    }
    else {
        color = '#00b38a'
    }
    return color

}

export const getDecimalPointZeroColor = (data) => {
    let color = '#000';
    if (data >= 0.05) {
        color = '#f2ac42'
    }
    else {
        color = '#00b38a'
    }
    return color

}

export const inventoryValidNumber = (val) => {
    if (Number.isNaN(val) || !Number.isFinite(val) || val?.toString().includes('-0.00')) {
        return 0;
    } else {
        return val;
    }
}


export const formatInventoryGroupOverviewData = (inventoryData, groupByKey) => {
    return inventoryData?.map(
        item => {
            return {
                ...(item || {}),
                [groupByKey]: item?.[groupByKey],
                branch_name: item?.branch_name,
                days: item?.inventory_days, 
                inventory_value: item?.inventory_value_sum,
                rtv: item?.rtv_value_sum,
                rtv_to_sales: (item?.rtv_value_sum / item?.actual_sales_sum) * 100,
                ds: item?.damaged_value_sum,
                ds_to_sales: (item?.damaged_value_sum / item?.actual_sales_sum) * 100
            };
        }
    )
}

export const formatInventoryStoreOverviewData = (inventoryData) => {
    return inventoryData?.map(
        item => {
            return {
                ...(item || {}),
                branch_id: item?.branch_id,
                branch_name: item?.branch_name,
                days: item?.inventory_days,
                inventory_value: item?.inventory_value_sum,
                rtv: item?.rtv_value_sum,
                rtv_to_sales: (item?.rtv_value_sum / item?.actual_sales_sum) * 100,
                ds: item?.damaged_value_sum,
                ds_to_sales: (item?.damaged_value_sum / item?.actual_sales_sum) * 100,
                store_tagging: item?.store_tagging
            };
        }
    )
}

export const formatInventoryProductData = (inventoryData, groupByKeys) => {
    // Create an object to store formatted data
    const formattedData = {};

    // Iterate over the inventory data
    inventoryData?.forEach(item => {
        // Generate a unique key based on the groupByKeys
        const groupKey = groupByKeys?.map(key => item[key]).join('_');
        const { food_group, inventory_value_sum, actual_sales_sum, sales_transaction_date_count_distinct, eight_weeks_transaction_date_count_distinct, inventory_days, sales_at_cost_sum } = item;

        // If the group key is not already a property in formattedData, initialize it
        if (!formattedData[groupKey]) {
            formattedData[groupKey] = {...(item || {})};
            // Include all groupByKeys in the formattedData
            groupByKeys?.forEach(key => {
                formattedData[groupKey][key] = item[key];
            });
        }

        // Add the inventory value and days for each food group
        formattedData[groupKey][`value_${food_group?.toLowerCase()}`] = inventory_value_sum;
        formattedData[groupKey][`days_${food_group?.toLowerCase()}`] = inventory_days;
        formattedData[groupKey][`sales_${food_group?.toLowerCase()}`] = actual_sales_sum;
        formattedData[groupKey][`sales_at_cost_${food_group?.toLowerCase()}`] = sales_at_cost_sum;
        formattedData[groupKey][`transaction_days_${food_group?.toLowerCase()}`] = sales_transaction_date_count_distinct;
        formattedData[groupKey][`eight_weeks_transaction_days_${food_group?.toLowerCase()}`] = eight_weeks_transaction_date_count_distinct;
    });

    // Transform formatted data into an array of objects
    const formattedArray = Object.values(formattedData);

    return formattedArray;
}

export const formatInventoryTop888Data = (inventoryData, groupByKeys) => {
    // Create an object to store formatted data
    const formattedData = {};

    // Iterate over the inventory data
    inventoryData?.forEach(item => {
        // Generate a unique key based on the groupByKeys
        const groupKey = groupByKeys?.map(key => item[key]).join('_');
        const {
            top_food_group,
            inventory_value_sum, actual_sales_sum,
            rtv_value_sum, damaged_value_sum,
            sales_transaction_date_count_distinct,
            eight_weeks_transaction_date_count_distinct, inventory_days,
            sales_at_cost_sum
        } = item;

        // If the group key is not already a property in formattedData, initialize it
        if (!formattedData[groupKey]) {
            formattedData[groupKey] = {...(item || {})};
            // Include all groupByKeys in the formattedData
            groupByKeys?.forEach(key => {
                formattedData[groupKey][key] = item[key];
            });
        }

        if (!top_food_group) {

        }

        // Add the inventory value and days for each must have
        formattedData[groupKey][`days_${top_food_group.toLowerCase()}`] = inventory_days;
        formattedData[groupKey][`value_${top_food_group.toLowerCase()}`] = inventory_value_sum;
        formattedData[groupKey][`rtv_to_sales_${top_food_group.toLowerCase()}`] = rtv_value_sum / actual_sales_sum * 100;
        formattedData[groupKey][`ds_to_sales_${top_food_group.toLowerCase()}`] = damaged_value_sum / actual_sales_sum * 100;
        formattedData[groupKey][`sales_${top_food_group?.toLowerCase()}`] = actual_sales_sum;
        formattedData[groupKey][`transaction_days_${top_food_group?.toLowerCase()}`] = sales_transaction_date_count_distinct;;
        formattedData[groupKey][`sales_at_cost_${top_food_group?.toLowerCase()}`] = sales_at_cost_sum;
        formattedData[groupKey][`eight_weeks_transaction_days_${top_food_group?.toLowerCase()}`] = eight_weeks_transaction_date_count_distinct;

    });

    // Transform formatted data into an array of objects
    const formattedArray = Object.values(formattedData);
    return formattedArray;
}

// data formatter function is used to restructure the data for the table display
// depending on the toggle state, different headers will be used thus  we need to change how the data is formatted
export const getDataFormatter = (toggleState) => {
    if (toggleState === 'top888') {
        return formatInventoryTop888Data
    }
    if (toggleState === 'product') {
        return formatInventoryProductData
    }
    return null
}

export const inventoryMetricFormulas =
    [
        "Inventory Values Data: Reflected as of the current Date",
        "RTV and Damaged Values Data: Reflected as of (Date –1)",
        "Inventory Value: Value of inventory in Peso",
        "Inventory Days: Inventory Value / Average Daily 8 Weeks Rolling Sales at Cost",
        "RTV: MTD Value Return To Vendor",
        "RTV % to Sales: MTD RTV / MTD Sales",
        "Damaged: MTD Value Damaged",
        "Damaged % to Sales: MTD Damaged / MTD Sales",
    ]

export const columnsToSum = [  
    'inventory_value',
    'rtv',
    'ds',
    'actual_sales',
    'actual_sales_sum',
    'sales_at_cost_sum',
    'inventory_value_sum',

    'value_fresh',
    'value_food1',
    'value_food2',
    'value_nonfood',
    'value_drygoods',
    'sales_fresh',
    'sales_food1',
    'sales_food2',
    'sales_nonfood',
    'sales_drygoods',
    'sales_at_cost_fresh',
    'sales_at_cost_food1',
    'sales_at_cost_food2',
    'sales_at_cost_nonfood',
    'sales_at_cost_drygoods',

    'value_888',
    'value_4k',
    'rtv_4k',
    'rtv_888',
    'ds_4k',
    'ds_888',
    'sales_888',
    'sales_4k',
    'sales_at_cost_888',
    'sales_at_cost_4k'
]