import {Box, GlobalStyles, Grid, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import GroupSelector from '../selectors/groupSelector';
import ToggleSelectorWithAdditional from '../../../TmpTSSSales/Filters/ToggleSelectorWithAdditional';
import RenderBranchViewContent from './renderBranchViewContent';
import {WindowSizeContext} from '../../../../../common/context/WindowSizeContext';
import BranchTopLineDataProvider from './DataProvider/branchViewTopLineDataProvider';
import TopLine from '../TopLine';
import {ENABLE_CUSTOMER_BRANCH_VIEW, ENABLE_LOCATIONS_BRANCH_VIEW, ENABLE_MERCHANDISE_BRANCH_VIEW} from '../../../../../Constants/settings';
import {useEnvironmentSetting} from '../../../../../common/providers/EnvironmentSettingsProvider';
import TSSInformationToolTip from "../../../TmpTSSSales/TSSInformationToolTip";

export default function BranchView() {
    const { branch, branch_id, month, year, period } = useParams()
    const [viewBy, setViewBy] = useState('operations') // set operations as default
    const enableCustomerBranchView = useEnvironmentSetting(ENABLE_CUSTOMER_BRANCH_VIEW)
    const enableLocationBranchView = useEnvironmentSetting(ENABLE_LOCATIONS_BRANCH_VIEW)
    const enableMerchandiseBranchView = useEnvironmentSetting(ENABLE_MERCHANDISE_BRANCH_VIEW)

    const VIEW_BY = [
        { label: 'Operations', value: 'operations' },
        { label: 'Location', value: 'location', disabled: !enableLocationBranchView },
        { label: 'Customer', value: 'customer', disabled: !enableCustomerBranchView },
        { label: 'Merchandise', value: 'merchandise', disabled: !enableMerchandiseBranchView },
        { label: 'Promotions', value: 'promotions', disabled: true },
    ]

    const TOGGLE_OPTIONS = [
        { label: 'MTD', value: 'mtd', disabled: false },
        { label: 'YTD', value: 'ytd', disabled: viewBy === 'customer' ? true : false },
        { label: 'QoQ', value: 'qoq', disabled: viewBy === 'customer' ? true : false },
    ]

    const [togglePeriodState, setTogglePeriodState] = useState(period)

    const [tempState, setTempState] = useState(togglePeriodState)

    const isMTD = togglePeriodState === 'mtd';
    const windowSize = useContext(WindowSizeContext)
    const [scrolling, setScrolling] = useState(false);
    const [prevScrollPos, setPrevScroll] = useState(0);

    const params = {
        month: [Number(month)],
        year: Number(year),
    }

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setTogglePeriodState(val);
        }
    };

    // FIXME: remove when YTD/QoQ are available for customers
    useEffect(() => {
        if (viewBy !== 'customer') {
            setTempState(togglePeriodState)
        }
        if (viewBy !== 'customer' && togglePeriodState === 'mtd') {
            setTogglePeriodState('mtd')
        }
        if (viewBy === 'customer') {
            setTogglePeriodState('mtd')
        }
    }, [viewBy, togglePeriodState])

    const [filters, setFilters] = useState({
        group: "channel",
        month: params?.month,
        year: params?.year,
        metric_column: 'actual_sales',
        standardized_business_unit: null,
        sbu_higher_mbu: null
    })

    const newParams = { ...params, sbu_higher_mbu: filters?.sbu_higher_mbu, standardized_business_unit: filters?.standardized_business_unit }

    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        setSelectedRows([])
    }, [filters.group, filters.metric_column])

    function onDropDownFilterChange(value, field) {
        setFilters({
            ...filters,
            [field]: value
        })
    }

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        setScrolling(prevScrollPos < currentScrollPos || currentScrollPos > 0);
        setPrevScroll(currentScrollPos);
    };


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    return <>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />

        <Grid container>
            <Grid container sx={{ position: `${!windowSize.isOnMobile ? 'sticky' : ''}`, top: `${!windowSize.isOnMobile ? '5rem' : ''}`, zIndex: 5, backgroundColor: '#e0e0e0' }}>
                <Grid item sm={12} xs={12} marginY={2} display={'flex'} alignItems={'center'}>
                    <Typography variant='h5' fontWeight='bold'> {branch} </Typography>
                    <TSSInformationToolTip/>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: '#e0e0e0' }}>
                    <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem" }}>
                        <Grid container alignItems={'center'} columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} >
                            <Grid item xs={12} sm={1} ml={2.5}>
                                <Typography variant="h6"> View By </Typography>
                            </Grid>
                            {viewBy !== 'location' && windowSize?.isOnMobile && <Grid item xs={12} sm display='flex' justifyContent='flex-end' mx={windowSize.isOnMobile && 2}>
                                <ToggleSelectorWithAdditional
                                    options={TOGGLE_OPTIONS}
                                    value={togglePeriodState}
                                    onChange={onTogglePeriodChange}
                                    additionalStyles={{ textTransform: 'none' }}
                                    lastIndexOfMain={2}
                                />
                            </Grid>}
                            <Grid item xs={12} sm={7} mx={windowSize.isOnMobile && 2}>
                                <GroupSelector options={VIEW_BY}
                                    onSelectGroupChange={(value) => setViewBy(value)} //strings value
                                    selected={viewBy} />
                            </Grid>
                            {viewBy !== 'location' && !windowSize?.isOnMobile &&
                                <Grid item xs={12} sm display='flex' justifyContent='flex-end' mx={windowSize.isOnMobile && 2}>
                                    <ToggleSelectorWithAdditional
                                        options={TOGGLE_OPTIONS}
                                        value={togglePeriodState}
                                        onChange={onTogglePeriodChange}
                                        additionalStyles={{ textTransform: 'none' }}
                                        lastIndexOfMain={2}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
                {(viewBy !== 'location' && viewBy !== 'customer') &&
                    <Grid item xs={12} mb={2}>
                        <BranchTopLineDataProvider params={params} period={togglePeriodState} storeID={branch_id} store={branch} onTogglePeriodChange={onTogglePeriodChange} filters={filters}>
                            <TopLine topLineMetrics={!scrolling} isStickyForTopline={scrolling} params={params} period={togglePeriodState} onToggleChange={onTogglePeriodChange} filters={filters} />
                        </BranchTopLineDataProvider>
                    </Grid>
                }
                {/* <Grid item xs={12}>
                    <BranchTopLineDataProvider params={params} period={togglePeriodState} storeID={branch_id} store={branch} onTogglePeriodChange={onTogglePeriodChange} filters={filters}>
                        <TopLine isStickyForTopline={scrolling} topLineMetrics={!scrolling} params={params} period={togglePeriodState} onToggleChange={onTogglePeriodChange} filters={filters} />
                    </BranchTopLineDataProvider>
                </Grid> */}
            </Grid>
            <RenderBranchViewContent viewBy={viewBy} store={branch} params={newParams} period={togglePeriodState} onTogglePeriodChange={onTogglePeriodChange} storeID={branch_id} filters={filters} onDropDownFilterChange={onDropDownFilterChange} />
        </Grid>
    </>
}