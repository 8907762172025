import React, { useRef, useState } from "react";
import ErrorFallbackComponent from "../ErrorFallbackComponent";
import {ErrorBoundary} from "react-error-boundary";
import * as PropTypes from "prop-types";
import SuspenseFallback from "./SuspenseFallback";
import {processFilterObjectArray} from "./util";
import InsightsModal from "./InsightsModal";

function GraphFactory(
    {
        title,
        chart_type,
        table_source,
        extra_data,
        filters,
        dataFromValue,
        chartRef,
        setSavedInsight,
    }
) {
    if (!chart_type) {
        return <ErrorFallbackComponent error={{message: "No chart type has been chosen. Please select a type to proceed."}}/>
    }

    if (!dataFromValue && !table_source) {
        return <ErrorFallbackComponent error={{message: "No table selected. Please select a table"}}/>
    }

    // if there are extra_data.filters (default chart filters),
    // apply it to the filters variable before sending to visualization

    const chartDefaultFilters = extra_data?.filters || [];
    const defaultFilters = processFilterObjectArray(chartDefaultFilters)

    const Graph = React.lazy(() => import(`./components/${chart_type}`));
    // React.Suspense is needed since actual component is still being loaded

    const SHOW_INSIGHTS = false // For now, dont show the insights. Can enable in future.

    return (
        <React.Suspense fallback={<SuspenseFallback/>}>
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                <Graph
                    title={title}
                    table_source={table_source}
                    extra_data={extra_data}
                    filters={{
                        ...filters,
                        ...defaultFilters  // default filters should be applied after user filters, they should override dashboard level filters
                    }}

                    dataFromValue={dataFromValue}
                    chartRef={chartRef}
                />
                 {
                    SHOW_INSIGHTS && <div>
                        <InsightsModal
                        savedInsight={extra_data?.savedInsight}
                        setSavedInsight={setSavedInsight}
                        table_source={table_source}
                        />
                    </div>
                }
            </ErrorBoundary>
        </React.Suspense>
    );

}

GraphFactory.propTypes = {
    title: PropTypes.string.isRequired,
    chart_type: PropTypes.string.isRequired,
    table_source: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    extra_data: PropTypes.object.isRequired,
    filters: PropTypes.object,
}

export default React.memo(GraphFactory);