import { useContext, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Button, Box, ClickAwayListener, Typography, Fade } from '@mui/material';
import { WindowSizeContext } from '../../../../../common/context/WindowSizeContext';


const SEGMENT_TYPE_RFM_DESCRIPTIONS = {
  "Major Contributors": "Most frequent, high-spending, most recent customers",
  "Regular Big-Spenders": "Consistent buyers with high spending",
 
  "Loyal Customers": "Bought very recently with moderate spending and frequency",
  "Shouldn't Lose": "Bought recently, with above average daily spend and frequency of purchase",
 
  "Potential Loyalist": "Bought very recently with mid frequency and mid monetary scores",
  "Needs Engagement": "Bought very recently and have not spent much",
  "Recent Low-Spenders": "Bought very recently but not often; Below average spending",
 
  "High-valued Outliers": "Bought in the past 2 weeks; Anomalously high spending and high frequency of visit", // TODO: remove when segment has been corrected to be "High-Value Outliers​" only
  "High-Value Outliers": "Bought in the past 2 weeks; Anomalously high spending and high frequency of visit",
  "Frequent Volume Buyers": "Bought in the past 2 weeks, have very high frequency of visit, spending, and qty per visit",
 
  "Promising Mid-Spenders": "Bought within the last 2 months; Above average monetary and daily spend",
  "Low-Spenders": "Bought within the last 2 months; Have not spent much",
  "Slipping Low-Value Customers": "Bought within the last 2 months; Have very low spending",
 
  "Hibernating Mid-Spenders": "Above avg spending; No re-purchase in the last 3 months",
  "Disengaged Low-Spenders": "Almost 4 months since last purchase, maybe lost, bought rarely, below average spending",
  "Dormant Low-Spenders": "More than 4 months since last purchase, bought very rarely, having lowest spending",
}
 

export default function RFMSegmentsTooltip({ value }) {
  const { isOnMobile } = useContext(WindowSizeContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleToggle = () => setTooltipOpen((prev) => !prev);

  const tooltipProps = useMemo(() => {
    if (isOnMobile) {
      return {
        open: tooltipOpen,
        disableTouchListener: true,
        disableHoverListener: true,
        onClose: () => setTooltipOpen(false),
      };
    }
    return {};
  }, [isOnMobile, tooltipOpen]);

  const LightTooltip = styled(({ className, ...props }) => (
    <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
        <Tooltip
            {...props} classes={{ popper: className }} />
    </ClickAwayListener>
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.white,
      },
      [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[2],
          fontSize: 8,
          maxWidth: "none",
      },
    }));


  const description = SEGMENT_TYPE_RFM_DESCRIPTIONS[value.trim()];

  return (
    <LightTooltip
      key={isOnMobile}
      title={
        <Box component="div" sx={{ maxHeight: '280px', overflow: 'auto' }}  px={1}>
            <Typography>
                { description }
            </Typography>
        </Box>
      }
      arrow
      placement="bottom-end"
      {...tooltipProps}
    >
    
      <Button
        size="small"
        onClick={handleToggle}  
        sx={{ textTransform: 'none',  color: 'black', fontWeight: 'normal' }}
      >
        {value}
      </Button>
    </LightTooltip>
  );
}
