import React, { useState } from 'react';
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import ApiClient from '../../../common/API';

import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function RefreshAll() {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const handleConfirmAction = () => {
        setIsLoading(true)
        ApiClient().post(`/data/hosts/refresh_all/`)
            .then(response => {
                // alert("Refresh all completed")
                setIsLoading(false)
                setShowModal(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false)
            })
    };

    return (
        <>
            <Button variant="outlined" size='medium' sx={{textTransform:'none', color:'blue', border:'solid 1px blue', fontWeight:'bold',borderRadius:'9px'
                    ,'&:hover': {
                        border:'solid 1px blue',
                        backgroundColor:'#fcfcfds'
                      }}}
                    onClick={openModal} >
                    Refresh All
            </Button>

            {showModal && (
                <ConfirmationModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={handleConfirmAction}
                    icon={<RefreshIcon sx={{fontSize:'100px', color:'#0f2feb'}}/>}
                    title="Refresh All"
                    message="You are about to refresh all of the schemas and its respective tables. This will update the tables of all the schemas and its corresponding columns. Do you really want to refresh all?"
                    isLoading={isLoading}
                    loadingMessage={`Refreshing All Data Source Host`}
                />
            )}

        </>
    );
}