import React from 'react';
import {useSelectorValue} from "../../../common/providers/selectorProvider";
import {getParamsBasedOnPeriod} from "../../../Customers/util";
import {useStoreMetaData} from "../providers";
import {reduceDistinct, rollupDataBy} from "../../../../../common/utils";
import { calculateMetricValuesL3 } from '../../DataProvider/util';

export function usePeriodBasedParams({params}) {
    const period = useSelectorValue('period');

    const isMTD = period === 'mtd';
    const isQoQ = period === 'qoq';

    return getParamsBasedOnPeriod(isMTD, isQoQ, params);
}


export function usePivotedDataWithMetricContext(data, rowKey, columnKey) {
    const selectedMetric = useSelectorValue('metric');


    return React.useMemo(() => {
        if (!data || data.length === 0) {
            return []
        }

        const rowIds = data.map(row => row[rowKey]).reduce(reduceDistinct, [])
        return rowIds.map(rowId => {
            const rowSubset = data.filter(row => row[rowKey] === rowId)
            const metricValuesByColumn = calculateMetricValuesL3(rowSubset, selectedMetric, columnKey)
            const rowProperties = {}
            const firstRow = rowSubset[0]
            if (firstRow.store_parent_store) {
                rowProperties.store_parent_store = firstRow.store_parent_store
            }
            if (firstRow.store_zone) {
                rowProperties.store_zone = firstRow.store_zone
            }
            if (firstRow.store_parent_store_id) {
                rowProperties.store_parent_store_id = firstRow.store_parent_store_id
            }

            return {
                ...rowProperties,
                ...metricValuesByColumn
            }
        })
    }, [data, rowKey, columnKey, selectedMetric])
}

export function createZoneDataFromStoreData(storeData, additionalUniqueKeys = []) {
    if (!storeData || storeData.length === 0) {
        return storeData
    }

    const keysToSum = Object.keys(storeData[0]).filter(key => {
        // anything we summed up
        // intentionally allowing sum up of sum_distinct (specially txn count) since txn are unique across branches
        return key.includes('_sum') ||
            key === 'store_parent_store_id_count_distinct' || 
            key === 'header_tran_key_count_distinct'
    })
    return rollupDataBy(
        storeData,
        ['store_zone', ...additionalUniqueKeys],
        keysToSum,
        {
            distinctValues: ['store_parent_store_id', 'store_parent_store']  // we want a single property that contains all of them
        }
    )
}

export function filterStoreDataByStoreID(storeData, storeIDWhitelist) {
    if (!storeIDWhitelist || storeIDWhitelist.length === 0) {
        return storeData
    }
    if (!storeData) {
        return []
    }
    return storeData.filter(row => storeIDWhitelist.includes(row.store_parent_store_id.toString()))
}

export function useStoreDataWithMeta({storeData}) {
    // add more store metadata to each row of storeData
    const {storeMeta: storeMetaData, isLoading, ...rest} = useStoreMetaData()

    const withMeta = React.useMemo(() => {
        if (!storeData || !storeMetaData || storeData.length === 0 || storeMetaData.length === 0) {
            return []
        }

        // create a lookup table for store_id -> meta
        const metaPerStore = storeMetaData.reduce((acc, row) => {
            acc[row.store_parent_store_id] = {...row}
            return acc
        }, {});

        return storeData.map(row => {
            return {
                ...row,
                ...metaPerStore[row.store_parent_store_id],
            }
        });

    }, [storeData, storeMetaData])

    return {data: withMeta, isLoading, ...rest}
}


