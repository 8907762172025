import React, { useState } from 'react';
import { useGroupList, useUserList } from "../../../common/API/hooks";
import { toast } from 'react-toastify';
import ApiClient from "../../../common/API";
import { useQueryClient } from "react-query";
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import { getSelectedOption } from '../../../common/utils';
import { Grid, Typography, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal'
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import DropdownCheckboxV3 from "../../../common/components/DropdownCheckboxV3";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import handleError from "../ErrorHandling"

export default function EditModule({ module, isOpen, setIsOpen }) {
    const queryClient = useQueryClient();
    const [hasChanges, setHasChanges] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const { data: userList, isLoading: userLoading } = useUserList();
    const { data: groupList, isLoading: groupLoading } = useGroupList();

    const [editedModule, setEditedModule] = useState({
        name: (module.row) ? module.row.name : module.name || '',
        allowed_users: (module.row) ? module.row.allowed_users : module.allowed_users || [],
        allowed_groups: (module.row) ? module.row.allowed_groups : module.allowed_groups|| [],
        is_public: (module.row) ? module?.row?.is_public : module.is_public ,
    });

    const userOptions = React.useMemo(() => {
        if (userLoading) {
            return []
        }
        if (!userList) {
            return []
        }
        return userList?.map((user) => {
            return {
                value: user.username,
                label: user.username,
                key: user.id,
            }
        })
    }, [userList, userLoading]);

    const groupOptions = React.useMemo(() => {
        if (groupLoading) {
            return []
        }
        if (!groupList) {
            return []
        }
        return groupList?.map((group) => {
            return {
                value: group.name,
                label: group.name,
                key: group.id,
            }
        })
    }, [groupList, groupLoading]);

    const closeModal = () => {
        if (hasChanges) {
            setShowWarningModal(true)
        }
        else {
            setIsOpen(false);
        }
    };

    const handleWarningConfirmation = () => {
        setEditedModule({
            name: (module.row) ? module.row.name : module.name || '',
            allowed_users: (module.row) ? module.row.allowed_users : module.allowed_users || [],
            allowed_groups: (module.row) ? module.row.allowed_groups : module.allowed_users || [],
            is_public: (module.row) ? module?.row?.is_public : module.is_public,
        })
        setShowWarningModal(false)
        setHasChanges(false)
        setIsOpen(false)
    }

    const handleChange = (e) => {
        setHasChanges(true);
        const { name, value } = e.target;
        setEditedModule((prevModule) => ({ ...prevModule, [name]: value }));
    };

    // TODO: When is_public is true, Users and Groups field should be all

    const handleGroupChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value);
        setEditedModule((prevModule) => ({ ...prevModule, allowed_groups: selectedValues }));
    };

    const handleUserChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value)
        setEditedModule((prevModule) => ({ ...prevModule, allowed_users: selectedValues }));
    };

    const handlePublicChange = () => {
        setHasChanges(true);
        setEditedModule((prevModule) => ({ ...prevModule, is_public: !prevModule.is_public }));
    };

    const handleSave = () => {
        ApiClient().patch(`module/${(module.row) ? module.row.id : module.id}/`, editedModule)
            .then(() => {
                queryClient.invalidateQueries(['module']);
                setIsOpen(false)
                toast.success('Module updated!');
            })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "Module", "edit");
                return toast.error(errorMessage);
            });
    };

    return (
        <>
            <Modal
                open={isOpen}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: 'auto',
                    justifyContent: 'center'
                }} >
                <Grid xs={10} md={4}>
                    <Card sx={{ minHeight: '45vh' }}>
                        <Grid container justifyContent="space-between" p={2} >
                            <Grid display="flex" columnGap={1}>
                                <EditNoteRoundedIcon fontSize="large" />
                                <Typography variant="h5" fontWeight="bold" >
                                    Edit Module
                                </Typography>
                            </Grid>
                            <IconButton aria-label="close" size="small" color="error"
                                onClick={closeModal}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid sx={{ p: 2 }}>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "400px" }}
                                    size="small"
                                    aria-label="name"
                                    id="name"
                                    name="name"
                                    label="Module Name"
                                    placeholder="Module Name"
                                    value={editedModule.name}
                                    onChange={handleChange}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={userOptions}
                                    aria-label="allowed_users"
                                    id="allowed_users"
                                    name="allowed_users"
                                    label="Select User(s)"
                                    placeholder="Select User(s)"
                                    limitTags={1}
                                    onChange={(event, newValue) => {
                                        handleUserChange(getSelectedOption(newValue, userOptions));
                                    }}
                                    value={getSelectedOption(editedModule?.allowed_users, userOptions)} />
                            </Grid>
                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={groupOptions}
                                    aria-label="allowed_groups"
                                    id="allowed_groups"
                                    name="allowed_groups"
                                    label="Select Group(s)"
                                    placeholder="Select Group(s)"
                                    // placeholder="Select Group(s)"
                                    isLoading={groupLoading}
                                    isDisabled={groupLoading}
                                    limitTags={1}
                                    onChange={(event, newValue) => {
                                        handleGroupChange(getSelectedOption(newValue, groupOptions));
                                    }}
                                    value={getSelectedOption(editedModule?.allowed_groups, groupOptions)} />
                            </Grid>
                            <Grid container justifyContent="flex-start" display="flex">
                                <FormControlLabel control={
                                    <Switch
                                        checked={editedModule.is_public}
                                        color="success"
                                        onChange={handlePublicChange}
                                    />} label="Is Public:" labelPlacement="start" />
                                <Typography mt={.8} ml={1}> {editedModule.is_public ? 'Yes' : 'No'}</Typography>
                            </Grid>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid container
                            p={1.5}
                            columnGap={{ sm: 'none', md: 4 }}
                            marginLeft={{ xs: 'none', md: -2.5 }}
                            justifyContent={{ xs: 'center', md: 'flex-end' }}>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ bgcolor: "#0F2FEB", width: "80px" }}
                                onClick={handleSave}
                            // disabled={!groupName}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Card>
                </Grid>
            </Modal>

            {showWarningModal && (
                <ConfirmationModal
                    isOpen={showWarningModal}
                    onClose={() => setShowWarningModal(false)}
                    onConfirm={handleWarningConfirmation}
                    icon={<WarningAmberIcon sx={{ fontSize: '60px' }} color='warning' />}
                    title="Warning"
                    message="There are unsaved changes. Are you sure you want to close the modal?"
                />
            )}
        </>
    )
}