import moment from "moment/moment";

const getGroupByPeriod = (groupBy) => {
    if (groupBy === "trans_week_end") {
        return "week"
    } else if (groupBy === "trans_month") {
        return "month"
    } else if (groupBy === "trans_year") {
        return "year"
    }
    return "day"
}

export function getPreviousDate(groupBy, selectedDate) {
    const previousDate = selectedDate ? moment(selectedDate).subtract(1, getGroupByPeriod(groupBy)).format("YYYY-MM-DD") : null
    return previousDate
}

export function getPreviousPeriodParams(dateFilter) {
    const groupBy = Object.keys(dateFilter).length === 0 ? 'trans_week_end' : Object.keys(dateFilter)[0]


    const selectedDate = dateFilter[groupBy] ? moment(dateFilter[groupBy]).format('YYYY-MM-DD') : null
    const previousDate = getPreviousDate(groupBy, selectedDate)

    return {[groupBy]: previousDate}
}

export function getDateDisplay (dateFilter) {
    const groupBy = Object.keys(dateFilter).length === 0 ? 'trans_week_end' : Object.keys(dateFilter)[0]


    const selectedDate = dateFilter[groupBy] ? moment(dateFilter[groupBy]).format('YYYY-MM-DD') : null

        if (groupBy === "trans_week_end") {
            const selectedWeekNumber = selectedDate ? moment(selectedDate).isoWeek() : null
            const selectedYear = dateFilter[groupBy] ? moment(dateFilter[groupBy]).year() : null
            return `W${selectedWeekNumber} ${selectedYear}`
        } else if (groupBy === "trans_month") {
            return moment(selectedDate).format('MMMM YYYY')
        } else if (groupBy === "trans_year") {
            return moment(selectedDate).format('YYYY')
        }
    }