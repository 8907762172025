import {useContext} from "react";
import {ModulePermissionsContext} from "../../../common/context/ModulePermissionsContext";
import {useLocation, useResolvedPath} from "react-router-dom";


export const hasAccessToModulePath = (modulePath, permittedModules) => {
    const trimmedModulePath = modulePath.split('?')[0].trim().replace(/^\/+|\/+$/g, '')  // remove trailing slashes and empty spaces
    const hasFullPathPermission = permittedModules.includes(modulePath) || permittedModules.includes(trimmedModulePath)
    if (hasFullPathPermission) {
        return hasFullPathPermission
    }

    return permittedModules.some(permittedModule => modulePath.indexOf(permittedModule) === 0 || trimmedModulePath.indexOf(permittedModule) === 0)
}

export function useCheckHasAccessToPath(modulePath) {
    const { moduleList: permittedModules } = useContext(ModulePermissionsContext)
    if (!permittedModules || permittedModules?.length === 0) {
        return false
    }
    return hasAccessToModulePath(modulePath, permittedModules)
}



export function useCheckHasAnyAccess(modulePaths) {
    const { moduleList: permittedModules } = useContext(ModulePermissionsContext)
    if (!permittedModules || permittedModules?.length === 0) {
        return false
    }

    if (!modulePaths || modulePaths.length === 0) {
        return false
    }

    return !!modulePaths.some(modulePath => hasAccessToModulePath(modulePath, permittedModules))
}

export function useCheckHasAccess() {
    const {pathname} = useLocation()
    return useCheckHasAccessToPath(pathname)
}