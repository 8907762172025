import { Grid, Card, Typography } from '@mui/material';
import React from 'react';
import Trendlines from './trendLines';
import AsOfDate from '../../../Filters/AsOfDate';

export default function TrendLinesContainer({params, period}) {

    return  <Grid container marginTop={1}>
        <Grid item sm={12} xs={12}>
            <Card>
                <Grid container sx={{ backgroundColor:'#f1f1f3', padding: 1.5}}> 
                    <Grid item sm={6} xs={6}>
                        <Typography variant="h6">Trendlines</Typography>
                    </Grid>
                    <Grid item sm={6} xs={6} display={'flex'} justifyContent={'end'}>
                        <AsOfDate />
                    </Grid>                    
                </Grid>
                <Grid container spacing={1} padding={1} >
                    <Grid item sm={6} xs={12}>
                        <Trendlines title='Total Sales' params={params} period={period} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Trendlines title='Total Transaction'  params={params} period={period}  />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Trendlines title='Basket Size'  params={params} period={period} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Trendlines title='Unit Sold per Transaction'  params={params} period={period}  />
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    </Grid>
}