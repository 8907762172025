import { Box } from "@mui/material";
import { useMemo } from "react";
import { IndicatorCellBox } from "../../Customers/Transactions/BUGroupShareOfWallet";
import BarIndicator from "../../common/presentation/barIndicator";
import { convertToValidNumber } from "../../../../common/utils";
import { useSMACPerformanceMetricTable } from "../hooks";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import NumericValueDisplay from "../../common/presentation/numericValueDisplay";
import CellWithSubRow from "../../../../common/components/DataTable/CellWithSubRow";


export default function SMACMetricsTable() {
    const {data, isLoading} = useSMACPerformanceMetricTable()
    function cellDisplay(value, percent, prefix) {
        return <><NumericValueDisplay value={value} isAbbreviated={true} prefix={prefix}/> ({percent}%) </>
    }

    const SMAC_PERF_METRIC_COLUMNS = useMemo(()=> {
        return [
            {
                header: 'Organization',
                accessorKey: 'pk',
                cell: ({row, getValue}) => {
                    const value = getValue();
                    const subRowLength = row.subRows?.length || 0;
                    return (
                        <CellWithSubRow row={row}>
                            {value} {subRowLength > 0 && `(${subRowLength})`}
                        </CellWithSubRow>
                    )
                },
            },
            {
                header: 'First-time Use of SMAC Card',
                accessorKey: 'new_members',
                cell: ({row, getValue}) => {
                    const value = getValue();
                    return <Box sx={{ width: { sm: '90%' }, marginX: 'auto' }}>{cellDisplay(value, row?.original?.new_perc)}</Box>
                },
                customStyle:{
                    width: '8rem',
                    textAlign: 'left',
                    borderLeft: '3px solid'
                }
            },
            {
                header: 'vs Last Year',
                id: 'vs_ly_new_members',
                accessorFn: (row) =>  (row?.new_members / row?.last_year_data.new_members) * 100,
                cell: ({getValue}) => {
                    return <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                </IndicatorCellBox>
                },
                customStyle:{
                    width: '5rem',
                }
            },
            {
                header: 'SMAC Active Members',
                accessorKey: 'active_members',
                cell: ({row, getValue}) => {
                    const value = getValue();
                    return  <Box sx={{ width: { sm: '90%' }, marginX: 'auto' }}>
                        {cellDisplay(value, row?.original?.active_perc)}
                    </Box>
                },
                customStyle:{
                    width: '8rem',
                    textAlign: 'left',
                    borderLeft: '3px solid'
                }
            },
            {
                header: 'vs Last Year',
                id: 'vs_ly_active_members',
                accessorFn: (row) =>  (row?.active_members / row?.last_year_data.active_members) * 100,
                cell: ({getValue}) => {
                    return <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                </IndicatorCellBox>
                },
                customStyle:{
                    width: '5rem',
                }
            },
            {
                header: 'SMAC Sales',
                accessorKey: 'smac_sales',
                cell: ({row, getValue}) => {
                    const value = getValue();
                    return  <Box sx={{ width: { sm: '90%' }, marginX: 'auto' }}>
                        {cellDisplay(value, row?.original?.smac_sales_perc, '₱')}
                    </Box>
                },
                customStyle:{
                    width: '10rem',
                    textAlign: 'left',
                    borderLeft: '3px solid'
                }
            },
            {
                header: 'vs Last Year',
                id: 'vs_ly_smac_sales',
                accessorFn: (row) =>  (row?.smac_sales / row?.last_year_data.smac_sales) * 100,
                cell: ({getValue}) => {
                    return <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                </IndicatorCellBox>
                },
                customStyle:{
                    width: '5rem',
                }
            },
            {
                header: 'SMAC Transactions',
                accessorKey: 'smac_txn',
                cell: ({row, getValue}) => {
                    const value = getValue();
                    return  <Box sx={{ width: { sm: '90%' }, marginX: 'auto' }}>
                        {cellDisplay(value, row?.original?.smac_txn_perc)}
                    </Box>
                },
                customStyle:{
                    width: '10rem',
                    textAlign: 'left',
                    borderLeft: '3px solid'
                }
            },
            {
                header: 'vs Last Year',
                id: 'vs_ly_smac_transactions',
                accessorFn: (row) =>  (row?.smac_txn / row?.last_year_data.smac_txn) * 100,
                cell: ({getValue}) => {
                    return <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                </IndicatorCellBox>
                },
                customStyle:{
                    width: '5rem',
                }
            },
            {
                header: 'SMAC Basket Size',
                accessorFn: (row) => parseFloat(row?.smac_sales) / row?.smac_txn,
                cell: ({getValue}) => {
                    return <Box sx={{ width: { sm: '50%' }, marginX: 'auto' }}>
                        <NumericValueDisplay value={getValue() } isAbbreviated={true} prefix= '₱'/>
                    </Box>
                },
                customStyle:{
                    width: '8rem',
                    textAlign: 'left',
                    borderLeft: '3px solid'
                }
            },
            {
                header: 'vs Last Year',
                id: 'vs_ly_smac_basket_size',
                accessorFn: (row) =>  {
                    const currentBasketSize = parseFloat(row?.smac_sales) / row?.smac_txn
                    const lyBasketSize = parseFloat(row?.last_year_data?.smac_sales) / row?.last_year_data?.smac_txn

                    return (currentBasketSize / lyBasketSize) * 100
                },
                cell: ({getValue}) => {
                    return <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                </IndicatorCellBox>
                },
                customStyle:{
                    width: '5rem',
                }
            },
            {
                header: 'SMAC Units Sold per Transaction',
                accessorKey: 'units_per_txn',
                cell: ({getValue}) => {
                    return <Box  sx={{ width: { sm: '50%' }, marginX: 'auto' }}>
                        <NumericValueDisplay value={getValue()} isAbbreviated={true}/>
                    </Box>
                },
                customStyle:{
                    width: '5rem',
                    textAlign: 'left',
                    borderLeft: '3px solid'
                }
            },
            {
                header: 'vs Last Year',
                id: 'vs_ly_smac_unit_sold_per_txn',
                accessorFn: (row) =>  (parseFloat(row?.units_per_txn) / parseFloat(row?.last_year_data.units_per_txn)) * 100,
                cell: ({getValue}) => {
                    return <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                </IndicatorCellBox>
                },
                customStyle:{
                    width: '5rem',
                }
            },
        ]
    },[])

    if (isLoading) {
        return <LoadingComponent/>
    }

    return (
        <TableComponentV2
            initialSort={{id: 'smac_sales', desc: true}}
            columns={SMAC_PERF_METRIC_COLUMNS}
            data={data}
            tableId={'SMACPerformance'}
            isCompact={true}
            customTableHeaderStyle={{
                backgroundColor: '#caddeb'
            }}
            hasPagination={true}
        />
    )
}