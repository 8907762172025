import React from 'react';
import { Typography } from '@mui/material';

export function DisclaimerSection() {
    return (
        <Typography variant="body2" sx={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
            <Typography variant="h6" sx={{ textAlign: 'left', marginBottom: '5px' }}><i>Notes:</i></Typography>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', textAlign: 'left' }}>
                <li><Typography variant="body2">Projected barangay population based on Census of Population and Housing 2020</Typography></li>
                <li><Typography variant="body2">Barangay Administrative Boundaries as of Apr 2023</Typography></li>
                <li><Typography variant="body2">SMAC Member Addresses as of Jan 2024</Typography></li>
                <li><Typography variant="body2">Catchment Area data as of Feb 2024</Typography></li>
            </ul>
        </Typography>
    );
}
