import React, {useState} from 'react'
import {Box, Grid} from "@mui/material";
import GroupSelector from "../selectors/groupSelector";
import CustomerBUView from "./Customer";
import CustomerLocation from "./CustomerLocation";
import SelectorProvider from "../../providers/selectorProvider";
import FiltersProvider from "./Provider/filtersProvider";
import CustomerFilters from './CustomerFilters';
import { TABLE_CUSTOMER_ATTRIBUTES, TABLE_CUSTOMER_ATTRIBUTES_WITH_BRGY } from '../../../Customers/constants';

const TAB_DEMOGRAPHICS = 'demographics';
const TAB_LOCATION = 'location';

const BU_VIEW_CUSTOMER_TABS = [
    {label: 'Demographics', value: TAB_DEMOGRAPHICS, disabled: false},
    {label: 'Location', value: TAB_LOCATION, disabled: false},
]

export default function CustomerWrapper() {
    const [selectedTab, setSelectedTab] = useState(TAB_DEMOGRAPHICS)
    const isDemographicsTab = selectedTab === TAB_DEMOGRAPHICS

    return (
        <SelectorProvider>
            <FiltersProvider>
                <Grid item sm={12} xs={12} mt={1.5}>
                    <Box flexGrow={1} xs={12} sx={{
                        backgroundColor: '#f6f6f8',
                        border: "1px solid #e0e0e0",
                        borderRadius: ".5rem",
                        paddingY: 1,
                        paddingLeft: 2.5
                    }}>
                        <GroupSelector
                            options={BU_VIEW_CUSTOMER_TABS}
                            onSelectGroupChange={(value) => setSelectedTab(value)}
                            selected={selectedTab}
                        />
                    </Box>
                </Grid>
                <CustomerFilters tableName={isDemographicsTab ? TABLE_CUSTOMER_ATTRIBUTES : TABLE_CUSTOMER_ATTRIBUTES_WITH_BRGY}/>
                {
                    isDemographicsTab ?
                        <CustomerBUView/> :
                        <CustomerLocation/>
                }
            </FiltersProvider>
        </SelectorProvider>
    )
}
