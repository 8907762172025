import React, {useMemo} from 'react';
import {useFilterValuesV2} from '../hooks';
import {ConvertToReactSelectOption, getSelectedOption} from '../../../../common/utils';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid'
import TextField  from '@mui/material/TextField';

export default function FilterCategory({onDropdownChange, filters}) {
    const {data: filterValues, isLoading} = useFilterValuesV2({
        column: "merchcat_dept_desc",
        filters: {
            company_key: filters?.company_key
        }
    });

    const categoryOptions = ConvertToReactSelectOption(filterValues)

    const selectedCategoryOption = useMemo(() => {
        if (!filters?.merchcat_dept_desc) {
            return null
        }

        const option = getSelectedOption(filters?.merchcat_dept_desc, categoryOptions)
        if (option) {
            return option
        } else {
            onDropdownChange(null, 'category')
            return null
        }
    }, [categoryOptions, filters?.merchcat_dept_desc])

    return (
        <>
            <Grid sx={{mt:1}}>
                <Autocomplete
                    size='small'
                    options={categoryOptions}
                    value={selectedCategoryOption}
                    onChange={(event, newValue) => onDropdownChange(newValue?.value, 'merchcat_dept_desc')}
                    loading={isLoading}
                    disabled={isLoading}
                    renderInput={(params) => <TextField {...params} placeholder={'All Categories'} label={'All Categories'} />}
                    />
            </Grid>
        </>
    );
}  
