import React, {useContext, useState} from "react";
import DataTable from "react-data-table-component";
import LoadingComponent from '../../../common/components/LoadingComponent';
import {useAssociationRulesData} from "./hooks";
import {useDebounce} from "use-debounce";
import {WindowSizeContext} from "../../../common/context/WindowSizeContext";
import FilterCombinationType from "./Filters/FilterCombinationType";
import HeaderWithTooltip from "../../../common/components/DataTable/HeaderWithTooltip";
import FilterCrossSellPrioritization from "./Filters/FilterCrossSellPrioritization";
import Download from "../../../common/components/Download";
import {TABLE_MBA_ASSOCIATION} from "./constants";

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography  from "@mui/material/Typography"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Slider from '@mui/material/Slider';

import Autocomplete  from "@mui/material/Autocomplete";
import Divider  from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Stack from '@mui/material/Stack';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';


export default function AssociationRules({filters, onInputChange, showDownloadModal,setShowDownloadModal}) {
    const windowSize = useContext(WindowSizeContext)
    const tooltipPlacement = windowSize.isOnMobile ? 'top' : 'right';

    // table related states
    const [sortField, setSortField] = useState("association_type");
    const [sortDirection, setSortDirection] = useState('asc');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    ;
    
    const [assocRulesFilters, setAssocRulesFilters] = useState({
        // association_type: 0,
        target_basket_size: 2
    })
    const [basketSize] = useDebounce(assocRulesFilters?.target_basket_size, 1000);

    const newAssocRulesFilters = {
        ...assocRulesFilters,
        target_basket_size: basketSize
    }

    const offset = (page - 1) * rowsPerPage;
    const limit = rowsPerPage;

    const {isLoading, data: associationRules} = useAssociationRulesData({
        filters,
        offset: offset,
        limit: limit,
        orderBy: sortField ? `${sortDirection === "asc" ? "" : "-"}${sortField}` : null,
        assocRulesFilters: newAssocRulesFilters
    });

    const possiblyArraySelector = (row, field) => {
        const value = rowValueToArray(row, field)
        if (!value) {
            return null
        }
        return Array.isArray(value) ? value.map((value, index) => {
            return [
                index > 0 && '\n',
                <Typography sx={{fontSize:'.8rem'}} key={index} title={value}>{value}</Typography>
            ]
        }) : value
    }
    const rowValueToArray = (row, field) => {
        const value = row[field]
        if (!value) {
            return null
        }
        if (typeof value === "string" && value.startsWith('["')) {
            // check if it is a stringified array
            return JSON.parse(value)
        }
        return Array.isArray(value) ? value : value.split(',')
    }

    const AR_columns = [

        {
            name: <HeaderWithTooltip name="Product Segments" tooltip="Segment(s) of the products inside the basket"/>,
            selector: (row) => {
                return possiblyArraySelector(row, 'target_basket_segment')
            },
            sortable: true,
            sortField: "target_basket_segment",
            wrap:'true',
        },
        {
            name: <HeaderWithTooltip name="Target Basket" tooltip="Contains derived product combinations"/>,
            selector: (row) => {
                return possiblyArraySelector(row, 'target_basket')
            },
            sortable: true,
            sortField: "target_basket",
            wrap:true,
            
        },
        {
            name: <HeaderWithTooltip name="Basket Brands" tooltip="Brand(s) of the products inside a basket"/>,
            selector: (row) => {
                return possiblyArraySelector(row, 'target_basket_brands')
            },
            sortable: true,
            sortField: "target_basket_brands",
            wrap: true,
            
        },
        {
            name: <HeaderWithTooltip name="Basket Category" tooltip="Categor(ies) of the products inside a basket"/>,
            selector: (row) => {
                return possiblyArraySelector(row, 'target_basket_cat')
            },
            sortable: true,
            sortField: "target_basket_cat",
            wrap: true,
            
        },

        {
            id: "association_type",
            name: <HeaderWithTooltip name="Cross-Sell Prioritization" tooltip="Pertains to the strength/prioritization of product combination"/>,
            selector: (row) => row.association_type,
            format: (row) => {
                switch (row.association_type.toString()) {
                    case "1":
                        return <Box sx={{color: "#FF455B",fontWeight:'bold'}}>High</Box>
                    case "2":
                        return <Box sx={{color: "#F6B714",fontWeight:'bold'}}>Medium</Box>
                    case "3":
                        return <Box sx={{color: "#50C878", fontWeight:'bold'}}>Low</Box>
                    default:
                        return null
                }
            },
            sortable: true,
            sortField: "association_type",
            wrap: true,
            center: true,
            
        },
    ];

    const handleSort = (column, direction) => {
        console.log(column, direction)
        setSortField(column.sortField);
        setSortDirection(direction);
    };

    function onFilterChange(field, val) {
        setAssocRulesFilters({
            ...assocRulesFilters,
            [field]: val
        })
    }

    function associationTypeDisplayString(value) {

        switch (value.toString()) {
            case "1":
                return "High"
            case "2":
                return "Medium"
            case "3":
                return "Low"
            default:
                return null
        }
    }

    const handleOnChange = (event, newValue) => {
        onFilterChange('target_basket_size',newValue.value)
    }

    const options = [
            {value: 2, label: 2},
            {value: 3, label: 3},
            {value: 4, label: 4},
            {value: 5, label: 5},
            {value: 6, label: 6},
            {value: 7, label: 7},
            {value: 8, label: 8},
            {value: 9, label: 9},
            {value: 10, label: 10},
        ]
    

    return (
        <Grid>
            <Grid container sx={{px:2}} spacing={2} justifyContent={{md:'flex-end'}} >
                <Grid item xs={12} md={3}>
                    <FilterCombinationType onDropdownChange={onInputChange} filters={filters}/>
                </Grid>

                
                <Grid item xs={12} md={3}>
                    <FilterCrossSellPrioritization onDropdownChange={onInputChange} filters={filters}/>
                </Grid>

                <Grid item xs={12} md={2.5} alignSelf={'flex-end'}>
                    <Stack direction='row' spacing={1} justifyContent={'flex-end'}>
                        <Typography variant='subtitle' sx={{whiteSpace:'nowrap', fontWeight:'medium'}} alignSelf={'center'}>
                            Product Combinations:   
                        </Typography>

                        <Autocomplete
                            disablePortal
                            size='small'
                            options={options}
                            sx={{width:{xs:'100%',md:'40%'}}}
                            defaultValue={assocRulesFilters?.target_basket_size}
                            value={options.find(data => data.value === basketSize)}
                            onChange={handleOnChange}
                            getOptionLabel={(option) => option.label.toString()}
                            renderInput={(params) => <TextField {...params} />}
                            disableClearable
                        />
                    
                    </Stack>
                </Grid>



                {
                    showDownloadModal && <Download
                        title={"MBA - Product Combinations"}    
                        apiDataPath={`data/table/${TABLE_MBA_ASSOCIATION}/query/`}
                        apiParams={{
                            ...filters,
                            ...assocRulesFilters,
                            columns: "target_basket,target_basket_brands,target_basket_cat,target_basket_segment,association_type",
                            order_by: `${sortField} ${sortDirection}`,
                        }}
                        isOpen={showDownloadModal}
                        toggle={() => {
                            setShowDownloadModal(!showDownloadModal)
                        }}
                        dataPostProcessor={(data) => {
                            return data.map((d) => {
                                const associationType = d.association_type.toString();
                                return {
                                    ...d,
                                    association_type: associationTypeDisplayString(associationType)
                                }
                            })
                        }}
                    />
                }
            </Grid>

            

        <Grid sx={{mt:3}}>
                <DataTable
                    columns={AR_columns}
                    data={associationRules?.data}
                    persistTableHead
                    sortServer
                    onSort={handleSort}
                    defaultSortFieldId="association_type"
                    defaultSortAsc={true}
                    
                    highlightOnHover
                    progressPending={isLoading}
                    progressComponent={<LoadingComponent/>}
                    
                    pagination
                    paginationServer
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(perPage) => {
                        setRowsPerPage(perPage);
                    }}
                    paginationTotalRows={associationRules?.count}
                />
            </Grid>
        </Grid>
    );
}
