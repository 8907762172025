
import { useContext } from "react"
import { Grid } from "@mui/material"
// import Index from "../../../../../Customers/Transactions/CustomerAverageDailySpend"
import BUGroupShareOfWallet from "../../../../../Customers/Transactions/BUGroupShareOfWallet";
import { getParamsBasedOnPeriod } from "../../../../../Customers/util";
import CustomerAverageDailySpend from "./CustomerAverageDailySpend";

export default function Transactions({ params, period, store, storeID, onTogglePeriodChange, filters, onDropDownFilterChange }) {

    const isMTD = period === 'mtd';
    const isQoQ = period === 'qoq';

    const paramsBasedOnPeriod = getParamsBasedOnPeriod(isMTD, isQoQ, params);

    return (<>
        <Grid container py={0.5}>
            <Grid item xs={12}>
                <CustomerAverageDailySpend params={paramsBasedOnPeriod} period={period} storeID={storeID} store={store} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} onFilterChange={onDropDownFilterChange} />
            </Grid>
            <Grid item xs={12}>
                <BUGroupShareOfWallet params={paramsBasedOnPeriod} period={period} storeID={storeID} store={store} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} isBranchView={true} />
            </Grid>
        </Grid>
    </>
    )
}