import React, {useContext} from "react";
import {Grid} from '@mui/material';
import TopLine from "../TopLine";
import {WindowSizeContext} from "../../../../../common/context/WindowSizeContext";
import SMACCustomerInformation from "./SMACCustomer"
import ToplineItemsProvider from "./ToplineItemsProvider";

export default function CustomerTopline({ params, period, viewBy, isOnDefaultView, isStickyCustomerCarousel }) {
    const windowSize = useContext(WindowSizeContext);
    // const { customerInformationData, isCustomerInformationDataLoading } = useCustomerInformationData({ params: { store_id: storeID } })

    return (<Grid>
        <SMACCustomerInformation params={params} period={period} viewBy={viewBy} isOnDefaultView={isOnDefaultView} isStickyCustomerCarousel={isStickyCustomerCarousel} 
            cardStyles={{ p: 2, width: '100%', borderRadius: ' 0 0 .5rem .5rem' }}/>
        <ToplineItemsProvider params={params} period={period} viewBy={viewBy}>
            <TopLine isOnDefaultView={isOnDefaultView} isStickyCustomerCarousel={isStickyCustomerCarousel} />
        </ToplineItemsProvider>

    </Grid>

    )
}