import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useUserList } from '../../../common/API/hooks';
import DeleteUser from './DeleteUser';
import EditUser from './EditUser';
import ResetPassword from './ResetPassword';
import EmailIcon from '@mui/icons-material/Email';
import LoadingComponent from '../../../common/components/LoadingComponent';
import { IconButton, Menu, Typography, MenuItem } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import PasswordIcon from '@mui/icons-material/Password';
import SendWelcomeEmail from './SendWelcomeEmail';
import Fuse from 'fuse.js';

const customStyles = {
    rows: {
        style: {
            minHeight: '3.5rem',
        },
    },
    headRow: {
        style: {
            fontWeight: "900",
            backgroundColor: '#F6F6F6',
        },
    },
    pagination: {
        style: {
            backgroundColor: '#ffff',
        },
    },
};

function MenuInTable({row}) {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [isSendWelcomeEmailModalOpen, setIsSendWelcomeEmailModalOpen] = useState(false);
    const { data: userList} = useUserList()

    const handleSendEmailClick = () => {
        setIsSendWelcomeEmailModalOpen(true);
    };

    const handleResetPassword = () => {
        setIsResetModalOpen(true);
    };
    
    const userForRow = userList.find(user => user.id === row.id);

    return <>
    <Divider orientation="vertical" flexItem />
    
    {userForRow && ( // Display only if user exists for this row
        <Tooltip 
            title={userForRow.welcome_email_sent ? 'Welcome Email Sent' : 'Send Welcome Email'}
            backgroundColor="white">
          <IconButton
            aria-label={`Send Welcome Email to ${userForRow.name}`}
            size="small"
            disabled={userForRow.welcome_email_sent}
            onClick={handleSendEmailClick}
          >
            <EmailIcon
              sx={{
                color: userForRow.welcome_email_sent ? 'light gray' : '#0F2FEB',
              }}
              fontSize="small"
            />
          </IconButton>
        </Tooltip>
      )}

    {userForRow && ( // Display only if user exists for this row
        <Tooltip 
            title={userForRow.can_change_password ? 'Allow Password Reset' : 'Password Reset Disabled'}
            backgroundColor="white">
          <IconButton
            aria-label={`Reset Password for ${userForRow.name}`}
            size="small"
            disabled={!userForRow.can_change_password}
            onClick={handleResetPassword}
          >
            <PasswordIcon
              sx={{
                color: userForRow.can_change_password ? '#0F2FEB' : 'light gray',
              }}
              fontSize="small"
            />
          </IconButton>
        </Tooltip>
      )}

    <Tooltip title="Edit">
        <IconButton aria-label= "Edit" size="small">
            <EditRoundedIcon 
            onClick={() => setIsEditModalOpen(true)}
            fontSize="small"
            />
        </IconButton>
    </Tooltip>

    <Tooltip title="Delete" backgroundColor="white">
        <IconButton aria-label="Delete" size="small">
            <DeleteRoundedIcon
            onClick={() => setIsDeleteModalOpen(true)} 
            sx={{ color: "red" }}
            fontSize="small"
            />
        </IconButton>
    </Tooltip>

    <ResetPassword user={row} isOpen={isResetModalOpen} setsetIsOpen={setIsResetModalOpen} />
    <SendWelcomeEmail user={userForRow} isOpen={isSendWelcomeEmailModalOpen} setIsOpen={setIsSendWelcomeEmailModalOpen} />
    <EditUser user={row} isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} />
    <DeleteUser user={row} isOpen={isDeleteModalOpen} setsetIsOpen={setIsDeleteModalOpen} />
    </>
    
}

export default function UserTable({ searchQuery }) {

    function formatDate(value) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(value).toLocaleString(undefined, options);
    }
    
    function formatGroups(groups) {
        return groups.join(', ');
    }
    
    const { data: userList, isLoading: userLoading } = useUserList();
    
    const filteredUsers = useMemo(() => {
        if (userLoading || !userList || !searchQuery) {
            return userList || [];
        }
    
        const options = {
            keys: ['last_name', 'first_name', 'adid', 'username', 'groups'],
            threshold: 0.3, // Adjust the threshold as needed
        };
    
        const fuse = new Fuse(userList, options);
        const query = searchQuery.toLowerCase();
        
        return fuse.search(query).map(result => result.item);
    }, [userLoading, userList, searchQuery]);

    const columns = [
        { name: 'LAST NAME', selector: (row) => row.last_name, sortable: true },
        { name: 'FIRST NAME', selector: (row) => row.first_name, sortable: true },
        { name: 'ADID', selector: (row) => row.adid, sortable: true,},
        {
            name: 'EMAIL',
            selector: (row) => row.email,
            sortable: true,
            sortFunction: (a, b) => a.email.localeCompare(b.email),
            cell: (row) => (
                <Typography noWrap={true} variant='inherit'
                    onMouseOver={(e) => {
                        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
                            e.currentTarget.dataset.tooltipId = "info_tooltip";
                            e.currentTarget.dataset.tooltipPlace = "top";
                            e.currentTarget.dataset.tooltipVariant = "info";
                            e.currentTarget.dataset.tooltipHtml = row.email;
                        }
                    }} > {row.email} </Typography>
            ),
        },
        { name: 'DATE ADDED', selector: (row) => row.date_added, sortable: true, cell: (row) => formatDate(row.date_added) },
        {
            name: 'GROUPS',
            selector: (row) => row.groups,
            sortable: true,
            maxWidth: '50ch',
            cell: (row) => (

                <Typography noWrap={true} variant='inherit'
                    onMouseOver={(e) => {
                        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
                            e.currentTarget.dataset.tooltipId = "info_tooltip";
                            e.currentTarget.dataset.tooltipPlace = "top";
                            e.currentTarget.dataset.tooltipVariant = "info";
                            e.currentTarget.dataset.tooltipHtml = formatGroups(row.groups);
                        }
                    }} > {formatGroups(row.groups)} </Typography>
            ),
        },
        {
            name: 'LAST LOGIN',
            selector: (row) => row.last_login,
            sortable: true,
            sortFunction: (a, b) => {
                const dateA = a.last_login ? new Date(a.last_login) : null;
                const dateB = b.last_login ? new Date(b.last_login) : null;

                if (dateA && dateB) {
                    return dateA - dateB;
                } else if (dateA) {
                    return -1;
                } else if (dateB) {
                    return 1;
                } else {
                    return 0;
                }
            },
            cell: (row) => (
                <span>
                    {row.last_login ? formatDate(row.last_login) : 'N/A'}
                </span>
            )
        },
        {
            cell: (row) => (

                <MenuInTable row={row} />

            ),
            ignoreRowClick: true,
            button: true,
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={filteredUsers}
            pagination
            progressPending={userLoading}
            progressComponent={<LoadingComponent />}
            highlightOnHover
            customStyles={customStyles}
        />
    );
}