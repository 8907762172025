import React, { useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Typography, IconButton, Divider, Grid } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import LoginLineChart from './LoginLineChart';
import LoginTable from "./LoginTable";



export default function LoginHistory() {

    const theme = createTheme();
    const screenSize = useMediaQuery(theme.breakpoints.down('md'));

    const [searchQuery, setSearchQuery] = useState('');
    
    return (
        <>
         <Grid sx={{ mt: 3 }} display={'flex'}></Grid>
            <Typography variant="h5">
                    Login History
                    <Tooltip title={<Typography variant="body2">Login History</Typography>}
                                    placement='right'> 
                    <IconButton>
                        <InfoIcon style={{ color: 'grey' }}/>
                    </IconButton>
                    </Tooltip>
                </Typography>
            <Divider sx={{ my: 2, borderBottom: 3 }} />
            <Grid sx={{ mt: 3 }} display={'flex'}>
                {/* Using the LineChart here */}
                <LoginLineChart/>
            </Grid>

            <Grid sx={{ border: 2, borderRadius: 3, borderColor: "#bdbdbd", backgroundColor: "#F3F3F3", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, p: 1 }}>
                <Grid container alignItems="center" justifyContent="space-between" display={{ xs: 'block', md: 'flex' }}>
                   
                    <Grid item m={1}>
                        {/* Content for the second grid item */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ border: 2, borderRadius: 2, borderColor: "#bdbdbd", borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 0, p: 1 }}>
                <LoginTable />
            </Grid>
        </>
    );
}
