import React, { useContext } from "react";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { Grid, Typography } from "@mui/material";


export default function SectionHeader({ collapseToggleComponent, sectionFilterComponents, children }) {
    const windowSize = useContext(WindowSizeContext)

    if (windowSize?.isOnMobile) {
        return (<>
            <Grid container >
                <Grid item xs={12}>
                    <Typography variant="h6" ml={2.5} mb={2}>
                        {children}
                    </Typography>
                </Grid>

                {
                    !!collapseToggleComponent && <Grid item xs={5} display="row" justifyContent="flex-end" mb={0.5}>
                        <Grid item ml={15}>
                            {collapseToggleComponent}
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12} mb={.5} mx={2} display="flex" >
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {sectionFilterComponents?.map(component => {
                        return <Grid item xs={12}>
                            {component}
                        </Grid>
                    })}

                </Grid>

            </Grid>
        </>)
    }

    return (<>
        <Grid item xs={12} sm={2.5} whiteSpace={'nowrap'}>
            <Typography variant='h6' ml={5}>
                {children}
            </Typography>
        </Grid>
        <Grid item xs={6} sm={8.5} ml={'auto'}>
            <Grid container justifyContent={'flex-end'}>
                {sectionFilterComponents?.map((component, index) => {
                    return <Grid item xs={4} sx={{ mr: 2 }}>
                        {component}
                    </Grid>
                })}

            </Grid>
        </Grid>
        {
            !!collapseToggleComponent && <Grid item xs sm={.5} ml={'auto'}>
                {collapseToggleComponent}
            </Grid>
        }

    </>)
}
