import { Card, Grid } from "@mui/material";
import SMACMemberCard from "./components/SMACMemberCard";
import { topLineDisplayNumber } from "../../../../common/utils";
import { useToplineSMACMemberCount } from "../hooks";
import AcquisitionSMACBarGraph from "./components/AcquisitionSMACBarGraph";
import ToplineDoughnutGraph from "./components/ToplineDoughnutGraph";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import SMACCardType from "./components/SMACCardType";
import { useContext } from "react";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";

export default function ToplinePerformanceCharts({ selectedTab }) {
    const { totalMembers, asRegistrationPieData, asUsagePieData, isLoading } = useToplineSMACMemberCount({})
    const { totalMembers: totalMembersLY } = useToplineSMACMemberCount({ lastYear: true })

    const vsLastYearTotalMembers = ((totalMembers / totalMembersLY) * 100).toFixed(0);

    const acquisition_smac_members = { header: "SMAC Members", overallvalue: topLineDisplayNumber(totalMembers), vsplan: "", vslastyear: vsLastYearTotalMembers }
    const registration_smac_members = { header: "SMAC Members", overallvalue: topLineDisplayNumber(totalMembers), vsplan: "", vslastyear: vsLastYearTotalMembers }
    const usage_smac_members = { header: "SMAC Active Members", overallvalue: topLineDisplayNumber(totalMembers), vsplan: "", vslastyear: vsLastYearTotalMembers, style: { variant: "body2" } }

    const windowSize = useContext(WindowSizeContext);

    switch (selectedTab) {
        case 'registration':
            return (
                <Grid container spacing={1} mt={-.1}>

                    {/* left content */}
                    <Grid item xs={12} sm={6}>
                        <Card>
                            {isLoading ? <LoadingComponent />

                                : <Grid container xs={12} sm={12} display={"flex"}>
                                    <Grid item xs={12} sm={4} p={1.5}>
                                        <SMACMemberCard data={registration_smac_members} />
                                    </Grid>
                                    <Grid item xs={12} sm={8} alignContent={"center"}>
                                        <ToplineDoughnutGraph data={asRegistrationPieData} label={'Registered'} />
                                    </Grid>
                                </Grid>
                            }
                        </Card>
                    </Grid>

                    {/* right content */}
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <SMACCardType />
                        </Card>
                    </Grid>
                </Grid>
            )

        case 'usage':
            return (
                <Grid container spacing={1} mt={-.1}>

                    {/* left content */}
                    <Grid item xs={12} sm={6}>
                        <Card>
                            {isLoading ? <LoadingComponent />
                                : <Grid container xs={12} sm={12} display={"flex"}>
                                    <Grid item xs={12} sm={4} p={1.5}>
                                        <SMACMemberCard data={usage_smac_members} />
                                    </Grid>
                                    <Grid item xs={12} sm={8} alignContent={"center"}>
                                        <ToplineDoughnutGraph data={asUsagePieData} label={'Active'} />
                                    </Grid>
                                </Grid>
                            }
                        </Card>
                    </Grid>

                    {/* right content */}
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <SMACCardType />
                        </Card>
                    </Grid>
                </Grid>
            )
        default:
            return (
                <Grid container spacing={1} mt={-.1}>

                    {/* left content */}
                    <Grid item xs={12} sm={6}>
                        <Card>
                            {isLoading ? <LoadingComponent />
                                : <Grid container xs={12} sm={12} display={"flex"}>
                                    <Grid item xs={12} sm={4} p={1.5}>
                                        <SMACMemberCard data={acquisition_smac_members} />
                                    </Grid>
                                    <Grid item xs={12} sm={8} display={'flex'} mx={windowSize.isOnMobile && 2}>
                                        <Grid item xs={12} sm>
                                            <AcquisitionSMACBarGraph />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Card>
                    </Grid >

                    {/* right content */}
                    < Grid item xs={12} sm={6} >
                        <Card>
                            <SMACCardType />
                        </Card>
                    </Grid >
                </Grid >
            )
    }
}