import { ToggleButtonGroup,ToggleButton,Box } from '@mui/material';
import React from 'react';
import { styled } from "@mui/material/styles";



export default function ToggleMtdYtd({value, onChange}) {
    const NewToggleButton = styled(ToggleButton)({
        backgroundColor: '#efefef',
        color: '#000',
        borderRadius: '1.5rem',
        border: 'none',
        height: '2rem',
        '&.Mui-selected, &.Mui-selected:hover': {
            border: '1px solid #e0e0e0',
            backgroundColor: '#fff'
        },
        '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            marginRight: '.25rem'
        },
        '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderLeft: 'none'
        },
        '&.MuiToggleButtonGroup-grouped:not(:last-of-type),&.MuiToggleButtonGroup-grouped:not(:first-of-type)':{
            borderRadius: '1.5rem',
          },
      });
      


    return (
        <Box sx={{
            backgroundColor: '#efefef',
            borderRadius: '1.5rem',
            padding: '.15rem',
            border: '1px solid #d8d8d8',
            marginRight: '.25rem'
        }}>
            <ToggleButtonGroup
                size="small"
                value={value}
                exclusive
                onChange={onChange}
                aria-label="mtd-ytd-toggle"
            >
                <NewToggleButton value="mtd" sx={{ px: 3 }}>MTD</NewToggleButton>
                <NewToggleButton value="ytd" sx={{ px: 3 }}>YTD</NewToggleButton>
            </ToggleButtonGroup>
        </Box>
    )
}