import React, { useContext } from 'react';
import ReactEcharts from "echarts-for-react";
import { Box, Grid, Typography } from '@mui/material';
import { abbreviateNumber } from '../../../../common/utils';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import LoadingComponent from '../../../../common/components/LoadingComponent';


export default function VerticalBarGraph({ data, isLoading, title, barWidthPercent = 50, chartWidth, isLabel = false, yAxisMax }) {
    
    const windowSize = useContext(WindowSizeContext);
    const isOnMobile = windowSize?.isOnMobile;

    const GROUP_KEYS = data?.map(row => row[0]);
    const values = data?.map(row => row[1]);
    const extraLabels = data?.map(row => row[2]);
    // const HEX_CODES = ['#CADDEB', '#7E95C7', '#A2B2D2', '#AFCDFC', '#84BFF7', '#4E88DD'];

    const BG_COLOR_MAPPING = {
        HIGH: '#60A3D9',
        MID: '#4E88DD',
        LOW: '#84BFF7',
        OTHERS: '#84BFF7'
    }

    const percentileInfo = {
        HIGH: '71st-100th Percentile',
        MID: '31st-70th Percentile',
        LOW: '0th-30th Percentile',
    }

    const barSegmentSeries = {
        data: values,
        type: 'bar',
        barWidth: `${barWidthPercent}%`,
        // barGap: isLabel ? '-100%' : '50%',
        inverse: false,
        itemStyle: {
            color: function (param) {
                return isLabel ? 'transparent' : (BG_COLOR_MAPPING[param.name] || '#00000');
            }
        },
        label: {
            show: true,
            fontSize: isOnMobile ? 13 : 16,
            color: 'black',
            fontFamily: "Henry Sans",
            position: isLabel ? (isOnMobile ? [8, '42%'] : 'right') : 'inside',
            // position: isLabel ? (isOnMobile ? [68, 175] : [95, 145]) : 'inside',
            // padding: [isLabel ? (isOnMobile ? -130 : -60) : 10, 0, 0, 0], //top, right, bottom, left
            formatter: function (params) {
                const label = isOnMobile ? 'SMAC\nMember\nCount' : 'SMAC Member Count' 
                const customerCountLabel = isLabel ? label : abbreviateNumber(values[params.dataIndex])
                return customerCountLabel;
            }
        }
    };

    const salesSegmentSeries = {
        data: values,
        type: 'custom',
        renderItem: function (params, api) {
            const itemIndex = params.dataIndex;
            const yValue = api.value(1);
            const start = api.coord([api.value(0), yValue]);
            const size = api.size([api.value(0), 1]);

            const width = size[0] * (barWidthPercent / 100);
            const paddingTop = 40;

            return {
            type: 'rect',
            shape: {
                x: start[0] - (width / 2),
                y: start[1] - paddingTop,
                width: width,
                height: 20,
                r: 10
            },
            style: api.style({
                fill: isLabel ? 'none' : 'lightgray'
            })
            };
        },
        label: {
            show: true,
            fontSize: isLabel ? (isOnMobile ? 13 : 16) : (isOnMobile ? 11 : 14),
            color: 'black',
            fontFamily: "Henry Sans",
            position: isLabel ? (isOnMobile ? [8, '95']: 'right') : 'inside',
            // position: isLabel ? (isOnMobile ? [68, 95] : [95, 16]) : 'inside',
            padding: [isLabel ? (isOnMobile ? -105: -60) : 10, 0, 0, 0], //top, right, bottom, left
            formatter: function (params) {
                const label = isOnMobile ? 'Total\nSMAC\nSales' : 'Total SMAC Sales'
                const totalSalesLabel = isLabel ? label : '₱' + abbreviateNumber(extraLabels[params.dataIndex])
                return totalSalesLabel;
            }
        }
    };

    const options = {
        animation: false,
        tooltip: {
            show: false
        },
        grid: {
            top: isOnMobile ? 50 : 50,
            bottom: isOnMobile ? 25 : 70,
            left: isLabel ? (isOnMobile ? -160 : -60) : (isOnMobile ? 5 : 60),
            right: isOnMobile ? 5 : 60,
        },
        xAxis: {
            type: 'category',
            data: GROUP_KEYS,
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: isLabel ? false : true, // TODO: make this line color: '#D9D9D9'
                width: '50%',
            },
            axisLabel: {
                show: true,
                fontSize: isOnMobile ? 11 : 16,
                color: 'black',
                fontFamily: "Henry Sans",
                formatter: function (value) {
                    const info = !isOnMobile && percentileInfo[value] ? `(${percentileInfo[value]})` : ' '
                    const formattedLabel = isOnMobile ? `${value}` : `${value}` + '{label|' + '\n' + info + '}';
                    return formattedLabel;
                },
                rich: {
                    label: {
                        // fontStyle: 'italic',
                        padding: 5,
                        fontSize: 11,
                    }
                }
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
        },
        series: [
            barSegmentSeries,
            salesSegmentSeries,
        ]
    };

    // set max for yAxis only if there's a SMAC member count value
    // yAxisMax => max passed from the active customers segments
    if (values && values.length > 0 && yAxisMax) {
        options.yAxis.max = yAxisMax; // ? yAxisMax : Math.max(...values);
    }

    return (
        // <Card sx={{ minHeight: '20rem', width: '100%' }}>
            <Grid item alignItems="center" 
                sx={{ 
                    flex: 1, 
                    height: '100%', 
                }}
            >
                <Grid container direction="column" sx={{ display:'flex', justifyContent:'center', }}>
                    <Grid item>
                        <ReactEcharts
                            notMerge={true}
                            lazyUpdate={false}
                            option={options}
                            style={{ 
                                height: isOnMobile ? '20rem' : '25rem' , 
                                width: chartWidth || undefined,
                                minWidth: isLabel ? '3rem' : (isOnMobile ? '5rem' : 'none'), 
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        // </Card>
    );
}
