import ApiClient from "../../../../common/API";
import { useQuery } from "react-query";
import { USE_QUERY_DEFAULT_OPTIONS } from "../../../../Constants/settings";
import moment from "moment/moment";
import { preProcessParams, convertParamsToTransactionDate } from "../util";
import { TABLE_NAME, STORE_TABLE_NAME, BU_TABLE_NAME, POS_TABLE_NAME, METRICS_TABLE_NAME, SBU_STORE_LOOKUP_TABLE } from "../hooks";
import { convertParamsToTranDate } from "../../Customers/util";
import {
    appendStoreName,
    calculateMetricPlanValues,
    calculateMetricValues,
    calculateMetricPlanValuesL3,
    calculateMetricValuesL3,
    convertValuesToNumeric,
    metricCalculations,
    metricPlanCalculations,
    metricCalculationsL3,
    metricPlanCalculationsL3,
    processData,
    processDataL3,
    processGRData,
    processGRDataL3,
    startsWithAny
} from "./util";
import { useSelectorValue } from "../../common/providers/selectorProvider";
import { useMemo } from "react";
import { MERCH_TABLE_NAME } from "../../common/presentation/businessUnitView/DataProvider/hooks";
import { rollupDataBy } from "../../../../common/utils";


export const BU_OTHERS_STRING1 = ["Fashion", "Home", "Kids", "Beauty", "Kultura", "Others"];

export const BU_FASHION_STRING = "Fashion";
export const BU_KIDS_STRING = "Kids";
export const BU_HOME_STRING = "Home";
export const BU_BEAUTY_STRING = "Beauty";
export const BU_OTHERS_STRING = "Others";



export function useProductSalesData() {
    return useQuery(
        [TABLE_NAME, "productSalesData"],
        () => {
            const columns = ["company_id", "year_month", "product_group"].join(",");
            return ApiClient()
                .get(`data/table/${TABLE_NAME}/query/`, {
                    params: {
                        columns: columns,
                        group_by: columns,
                        aggregates: "gross_sales_sum",
                        limit: 1000,
                    },
                })
                .then((res) => {
                    return res.data.data;
                });
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        }
    );
}

export function useCompanyLookupData() {
    return useQuery(
        [TABLE_NAME, "companyLookupData"],
        () => {
            const columns = ["company_id", "company_name"].join(",");
            return ApiClient()
                .get(`data/table/${TABLE_NAME}/query/`, {
                    params: {
                        columns: columns,
                        group_by: columns,
                    },
                })
                .then((res) => {
                    const companyLookup = {};
                    res.data.data.forEach((item) => {
                        companyLookup[item.company_id] = item.company_name;
                    });
                    return companyLookup;
                });
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        }
    );
}

export function useCompanySalesData() {
    return useQuery(
        [TABLE_NAME, "companySalesData"],
        () => {
            const columns = ["company_id", "year_month"].join(",");
            return ApiClient()
                .get(`data/table/${TABLE_NAME}/query/`, {
                    params: {
                        columns: columns,
                        group_by: columns,
                        aggregates: "gross_sales_sum",
                        product_group__not_in: "888,888 (Outright),4k",
                        limit: 1000,
                    },
                })
                .then((res) => {
                    return res.data.data;
                });
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        }
    );
}

export function useAvailableDateRange() {
    const monthAbbreviations = {
        JAN: '01',
        FEB: '02',
        MAR: '03',
        APR: '04',
        MAY: '05',
        JUN: '06',
        JUL: '07',
        AUG: '08',
        SEP: '09',
        OCT: '10',
        NOV: '11',
        DEC: '12',
    };
    const dates = useQuery(
        [STORE_TABLE_NAME, 'stores', "date_range"],
        () => ApiClient().get(`data/table/${STORE_TABLE_NAME}/query/`, {
            params: {
                columns: "year,month",
                group_by: "year,month",
                gross_sales__gt: 0,  // consider only months with sales

                limit: 1000
            }
        }).then(res => {
            const transactionDates = res?.data?.data?.map(row => {
                const formattedMonth = monthAbbreviations[row?.month]
                return [
                    moment(`${row?.year}-${formattedMonth}-01`).toDate(),  // start date
                    moment(`${row?.year}-${formattedMonth}-01`).endOf('month').toDate() // end date
                ]
            }).flat().sort((a, b) => a - b)
            return {
                "min": transactionDates[0],
                "max": transactionDates[transactionDates.length - 1]
            }
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
    return dates;
}


export function useAvailableDateRangeL3() {
    const dates = useQuery(
        [POS_TABLE_NAME, 'stores', "date_range"],
        () => ApiClient().get(`data/table/${POS_TABLE_NAME}/query/`, {
            params: {
                columns: "header_tran_date",
                group_by: "header_tran_date",
                pos_sku_gross_sales__gt: 0,  // consider only months with sales

                limit: 1000
            }
        }).then(res => {
            const transactionDates = res?.data?.data?.map(row => {
                return [
                    moment(`${row?.header_tran_date}`).toDate(),  // start date
                    moment(`${row?.header_tran_date}`).endOf('month').toDate() // end date
                ]
            }).flat().sort((a, b) => a - b)
            return {
                "min": transactionDates[0],
                "max": transactionDates[transactionDates.length - 1]
            }
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
    return dates;
}

export function useAvailableMaxDate() {
    return useQuery(
        [STORE_TABLE_NAME, 'stores', "as_of_date"],
        () => ApiClient().get(`data/table/${STORE_TABLE_NAME}/query/`, {
            params: {
                aggregates: "transaction_date_max",
                limit: 1000
            }
        }).then(res => {
            const transactionDates = res.data?.data?.map(row => {
                return moment(`${row?.transaction_date_max}`).toDate()  // max date
            })
            return transactionDates?.[0] ?? null;
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
}

// NOTE: used L3 dataset(s) for useAvailableMaxDate
export function useAvailableMaxDateL3() {
    return useQuery(
        [POS_TABLE_NAME, "stores", "as_of_date"],
        () => ApiClient().get(`data/table/${POS_TABLE_NAME}/query`, {
            params: {
                aggregates: "header_tran_date_max",
                limit: 1000
            }
        }).then(res => {
            const transactionDates = res.data?.data?.map(row => {
                return moment(`${row?.header_tran_date_max}`).toDate()  // max date
            })
            return transactionDates?.[0] ?? null;
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    )
}

export function useSBUData({ params, queryOptions = {} }) {
    const isGroupedByBusinessUnit = !!params && (
        params.group_by === "business_unit" || params.columns === "business_unit"
        || params.group_by === "higher_mbu" || params.columns === "higher_mbu"
        || (
            Array.isArray(params.group_by) && (
                params.group_by.includes("business_unit") || params.group_by.includes("higher_mbu")
            )
        ) || (
            Array.isArray(params.columns) && (
                params.columns.includes("business_unit") || params.columns.includes("higher_mbu")
            )
        )
    )
    const isFilteredByHigherMBU = !!params && (
        (params.higher_mbu && params.higher_mbu.length > 0)
        || (params.higher_mbu__in && params.higher_mbu__in.length > 0)
    )
    const isFilteredByBusinessUnit = !!params && (
        (params.business_unit && params.business_unit.length > 0) ||
        (params.business_unit__in && params.business_unit__in.length > 0) ||
        (params.standardized_business_unit && params.standardized_business_unit.length > 0) ||
        (params.standardized_business_unit__in && params.standardized_business_unit__in.length > 0)
    )

    const isFilteredByDept = !!params && (
        (params.standardized_department && params.standardized_department.length > 0) ||
        (params.standardized_department__in && params.standardized_department.length > 0)
    )

    const isFilteredBySubdept = !!params && (
        (params.standardized_subdept && params.standardized_subdept.length > 0) ||
        (params.standardized_subdept__in && params.standardized_subdept__in.length > 0)
    )

    const isFilteredByProductCategory = !!params && (
        (params.product_category && params.product_category.length > 0) ||
        (params.product_category__in && params.product_category__in.length > 0)
    )

    const isFilteredByDeptOrSubdept = isFilteredBySubdept || isFilteredByDept


    const SBU_TABLE_NAME = useMemo(() => {
        if (isFilteredByDeptOrSubdept || isFilteredByProductCategory) {
            return MERCH_TABLE_NAME
        }
        if (isGroupedByBusinessUnit || isFilteredByBusinessUnit || isFilteredByHigherMBU) {
            return BU_TABLE_NAME
        }
        return STORE_TABLE_NAME
    }, [isFilteredByDeptOrSubdept, isFilteredByProductCategory, isGroupedByBusinessUnit, isFilteredByBusinessUnit, isFilteredByHigherMBU]);

    const isGiftRegistryStoreView = (params?.group_by === 'store' && params?.channel && params?.channel[0] === 'Gift Registry') //params?.channel === 'store,store_id'
    const isGiftRegistryBUView = ((isGroupedByBusinessUnit || isFilteredByBusinessUnit || isFilteredByHigherMBU) && params?.channel && params?.channel[0] === 'Gift Registry') //params?.channel === 'store,store_id'


    const selectedViewBy = useSelectorValue('viewBy')
    const aggregates = useMemo(() => {
        const giftRegistryAggregates = ["gr_txn_count_sum", "gr_gross_sales_sum", "gross_sales_sum", "egr_plan_sum", "gr_smac_sales"]
        const commonAggregates = ["gross_sales_sum", "sales_plan_sum", "smac_sales_sum", "net_selling_area_sum", "basket_size_plan_sum", "store_id_count_distinct"]

        const aggregateByTable = {
            [STORE_TABLE_NAME]: {
                giftRegistry: giftRegistryAggregates,
                default: [
                    "store_txn_count_sum", "ds_foot_traffic_sum", "mall_foot_traffic_sum", "foot_traffic_txn_count_sum", "total_floor_selling_area_sum", "sales_units_sum",
                    ...commonAggregates
                ]
            },
            [BU_TABLE_NAME]: {
                giftRegistry: giftRegistryAggregates,
                default: ["bu_txn_count_sum_distinct", "sales_units_sum", ...commonAggregates]
            },
            [MERCH_TABLE_NAME]: {
                giftRegistry: giftRegistryAggregates,
                default: [...commonAggregates, 'sales_units_sum']
            }
        };

        if (params?.aggregates) {  // if aggregates are already provided, use them
            return params?.aggregates
        }

        const key = isGiftRegistryStoreView ? 'giftRegistry' : 'default'
        const suggestedAggregates = aggregateByTable[SBU_TABLE_NAME][key]

        if (isFilteredByHigherMBU) {
            const isTopLineData = !params.hasOwnProperty('group_by') || !params.group_by  // topline data has no grouping
            if (isTopLineData) {
                return [
                    ...suggestedAggregates,
                    "higher_mbu_txn_count_sum_distinct",
                ]
            }
            if (params.group_by === 'channel') {
                return [
                    ...suggestedAggregates,
                    "higher_mbu_txn_count_sum_distinct",
                ]
            } else if (params.group_by === 'zone') {
                return [
                    ...suggestedAggregates,
                    "store_txn_count_sum_distinct",
                ]
            }

            const isGroupedByStore = params.group_by === 'store' || params.columns === 'store' || params.group_by.includes('store')
            if (isGroupedByStore) { // performance by store data
                if (!isFilteredByBusinessUnit) {  // those filtered by business unit should use the default aggregates
                    return [
                        ...suggestedAggregates,
                        "store_txn_count_sum_distinct",
                    ]
                } else {
                }
            }
        }

        if (SBU_TABLE_NAME === MERCH_TABLE_NAME) {
            if (isFilteredBySubdept) {
                if (isFilteredByProductCategory) {
                    return [
                        ...suggestedAggregates,
                        'subdept_cat_txn_count_sum'
                    ]
                }
                return [
                    ...suggestedAggregates,
                    'subdept_txn_count_sum',
                ]
            } else if (isFilteredByDept) {
                if (params?.metric_column === 'actual_transactions' || params?.metric_column === 'actual_units_sold_per_txn' || params?.metric_column === 'actual_basket_size') {
                    if (isFilteredByProductCategory) {
                        return ['dept_cat_txn_count_sum_distinct', 'sales_units_sum', 'gross_sales_sum']
                    }
                    return [
                        'dept_txn_count_sum', 'sales_units_sum', 'gross_sales_sum'
                    ]
                }
            } else if (isFilteredByProductCategory) {
                if (params?.metric_column === 'actual_transactions' || params?.metric_column === 'actual_units_sold_per_txn' || params?.metric_column === 'actual_basket_size') {
                    return [
                        'bu_cat_txn_count_sum_distinct', 'sales_units_sum', 'gross_sales_sum'
                    ]
                }
            }
        }


        return suggestedAggregates

    }, [params, isGiftRegistryStoreView, isGiftRegistryBUView, SBU_TABLE_NAME, selectedViewBy, isFilteredByDept, isFilteredBySubdept]);

    const aggregatesAsString = Array.isArray(aggregates) ? aggregates.join(",") : aggregates

    const hasTransactionDate = params?.month?.length > 0 && !!params?.year

    const newParams = {
        ...params,
        aggregates: aggregatesAsString,
        columns: params?.group_by, // In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
    }
    if (
        aggregates.indexOf('dept_txn_count_sum') >= 0
    ) {
        newParams['view'] = 'dept_txns'
    }

    if ('metric_column' in params) {
        delete newParams.metric_column;
    }

    return useQuery(
        [SBU_TABLE_NAME, "stores", "overall", newParams, 'current'],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(newParams)
            )

            return ApiClient().get(`data/table/${SBU_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && queryOptions.enabled !== false
        }
    );
}


export function useSBUDataL3({ params, queryOptions = {} }) {
    const isGiftRegistryData = params?.header_gr_tag__is_true && params?.header_gr_tag__is_true === 1
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year;

    const { data: maxDate } = useAvailableMaxDateL3();
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForVsLY = queryOptions?.isQueryForVsLastYear;
    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && (moment(maxDate).year() === params.year || isQueryingForVsLY)
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const posAggregates = useMemo(() => {
        if (queryOptions.hasAggregates === false) { // if the intended result is not to yield any aggregate, set queryOptions: { ...queryOptions, hasAggregates: false}
            return null;
        }

        const posTableColumns = ["pos_sku_gross_sales", "header_tran_key", "pos_sku_sale_tot_qty", "pos_sku_smac_sales", "store_parent_store_id"];
        const defaultPosAggregates = ["pos_sku_gross_sales_sum", "header_tran_key_count_distinct", "pos_sku_sale_tot_qty_sum", "pos_sku_smac_sales_sum", "store_parent_store_id_count_distinct"];

        if (params?.aggregates) { // If aggregates are already provided, use them
            const aggregates = Array.isArray(params.aggregates) ? params.aggregates : params?.aggregates.split(',')
            console.warn("Aggregates declared have been segregated to the respective tables they exist in. If column of the aggregate is not in posTableColumns, it will not go through.");
            return aggregates?.filter(aggregate => defaultPosAggregates.includes(aggregate) || startsWithAny(aggregate, posTableColumns));
        }

        return defaultPosAggregates
    }, [params, queryOptions?.hasAggregates]);

    const metricsDailyAggregates = useMemo(() => {
        if (queryOptions?.hasAggregates === false) { // if the intended result is not to yield any aggregate, set queryOptions: { ...queryOptions, hasAggregates: false}
            return null;
        }

        const metricsTableColumns = ["ds_foot_traffic", "egr_sales_plan", "store_parent_store_id"];
        const defaultMetricsDailyAggregates = ["ds_foot_traffic_sum", "egr_sales_plan_sum", "store_parent_store_id_count_distinct"];

        if (params?.aggregates) { // If aggregates are already provided, use them
            const aggregates = Array.isArray(params.aggregates) ? params.aggregates : params?.aggregates.split(',')
            console.warn("Aggregates declared have been segregated to the respective tables they exist in. If column of the aggregate is not in metricsTableColumns, it will not go through.");
            return aggregates?.filter(aggregate => defaultMetricsDailyAggregates.includes(aggregate) || startsWithAny(aggregate, metricsTableColumns));
        }

        return defaultMetricsDailyAggregates;
    }, [params, queryOptions?.hasAggregates]);

    const metricsEOMAggregates = useMemo(() => {
        if (queryOptions?.hasAggregates === false) { // if the intended result is not to yield any aggregate, set queryOptions: { ...queryOptions, hasAggregates: false}
            return null;
        }

        const metricsTableColumns = ["mall_foot_traffic", "sales_plan", "total_floor_selling_area", "net_selling_area", "smac_sales_plan", "store_parent_store_id"];
        const defaultMetricEOMAggregates = ["mall_foot_traffic_sum", "sales_plan_sum", "total_floor_selling_area_sum",
            "net_selling_area_sum", "smac_sales_plan_sum", "store_parent_store_id_count_distinct"];

        if (params?.aggregates) { // If aggregates are already provided, use them
            const aggregates = Array.isArray(params.aggregates) ? params.aggregates : params?.aggregates.split(',')
            console.warn("Aggregates declared have been segregated to the respective tables they exist in. If column of the aggregate is not in metricsTableColumns, it will not go through.");
            return aggregates?.filter(aggregate => defaultMetricEOMAggregates.includes(aggregate) || startsWithAny(aggregate, metricsTableColumns));
        }

        return defaultMetricEOMAggregates;
    }, [params, queryOptions?.hasAggregates]);

    const columns = params?.group_by;

    const posParams = {
        ...params,
        aggregates: posAggregates && posAggregates.join(","),
        columns: columns,
        group_by: columns,
    };

    const metricsEOMParams = {
        ...params,
        aggregates: metricsEOMAggregates && metricsEOMAggregates.join(","),
        columns: columns,
        group_by: columns,
    };

    const metricsDailyParams = {
        ...params,
        aggregates: metricsDailyAggregates && metricsDailyAggregates.join(","),
        columns: columns,
        group_by: columns,
    };

    const checkIfHasProperParams = (params) => { 
        if(params?.columns && params?.group_by) {
            return true;
        } else if (params?.aggregates) {
            return true;
        } else {
            return false;
        }
    }

    const hasProperPosParams = checkIfHasProperParams(posParams);
    const hasProperMetricsDailyParams = checkIfHasProperParams(metricsDailyParams);
    const hasProperMetricsEOMParams = checkIfHasProperParams(metricsEOMParams);

    if (isGiftRegistryData) {
        // FIXME: should be done in 2nd layer (already inserted it there but will need to test if this ff if condition can be removed already) 
        if (params?.group_by === "channel" || params?.group_by === "cm_channel_desc") {
            delete posParams.group_by;
            delete posParams.columns;
            delete metricsEOMParams.group_by;
            delete metricsEOMParams.columns;
            delete metricsDailyParams.group_by;
            delete metricsDailyParams.columns;
        }
        posParams.header_gr_tag__is_true = 1;
        metricsEOMParams.header_gr_tag__is_true = null; // metrics table does not have column 'header_gr_tag'
        metricsDailyParams.header_gr_tag__is_true = null; // metrics table does not have column 'header_gr_tag'
    }

    if ("metric_column" in posParams) {
        delete posParams.metric_column;
    }
    if ("metric_column" in metricsEOMParams) {
        delete metricsEOMParams.metric_column;
    }
    if ("metric_column" in metricsDailyParams) {
        delete metricsDailyParams.metric_column;
    }

    // removal of additional parameters from BU > Merch > Topline
    if ("standardized_business_unit" in posParams) {
        delete posParams.standardized_business_unit
    }
    if ("standardized_business_unit" in metricsEOMParams) {
        delete metricsEOMParams.standardized_business_unit
    }
    if ("standardized_business_unit" in metricsDailyParams) {
        delete metricsDailyParams.standardized_business_unit;
    }

    // pos query
    const { data: posData, isLoading: isPosDataLoading } = useQuery(
        [POS_TABLE_NAME, "stores", "overall", posParams, queryOptions, dayOfMonthMaxDate, hasProperPosParams],
        () => {
            const params = queryOptions?.hasTranDateLteGte ? posParams : convertParamsToTranDate(posParams, dayOfMonthMaxDate)
            const processedParams = preProcessParams(params);
            return ApiClient().get(`data/table/${POS_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    limit: 10000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && queryOptions.enabled !== false && hasProperPosParams
        }
    );

    // metrics query (for daily updated metrics)
    const { data: metricsDailyData, isLoading: isMetricsDailyDataLoading } = useQuery(
        [METRICS_TABLE_NAME, "stores", "overall", metricsDailyParams, queryOptions, dayOfMonthMaxDate, hasProperMetricsDailyParams],
        () => {
            const params = queryOptions?.hasTranDateLteGte ? metricsDailyParams : convertParamsToTranDate(metricsDailyParams, dayOfMonthMaxDate)
            const processedParams = preProcessParams(params);
            return ApiClient().get(`data/table/${METRICS_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    limit: 10000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && queryOptions.enabled !== false && hasProperMetricsDailyParams
        }
    );

    // metrics query (for metrics that have end of month dates in 'header_tran_date')
    const { data: metricsEOMData, isLoading: isMetricsEOMDataLoading } = useQuery(
        [METRICS_TABLE_NAME, "stores", "overall", metricsEOMParams, queryOptions, hasProperMetricsEOMParams],
        () => {
            const params = queryOptions?.hasTranDateLteGte ? metricsEOMParams : convertParamsToTranDate(metricsEOMParams)
            const processedParams = preProcessParams(params);
            return ApiClient().get(`data/table/${METRICS_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    limit: 10000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && queryOptions.enabled !== false && hasProperMetricsEOMParams
        }
    );

    const isLoading = isPosDataLoading || isMetricsDailyDataLoading || isMetricsEOMDataLoading;
    const mergedMetricAggregates = (() => {
        if (metricsDailyAggregates && metricsEOMAggregates) {
            return metricsDailyAggregates.concat(metricsEOMAggregates);
        } else if (metricsDailyAggregates) {
            return metricsDailyAggregates;
        } else if (metricsEOMAggregates) {
            return metricsEOMAggregates;
        } else {
            return [];
        }
    })();

    const metricsData = useMemo(() => {
        if ((!metricsEOMData || metricsEOMData.length === 0) && (!metricsDailyData || metricsDailyData.length === 0)) {
            return [];
        }

        if ((!metricsEOMData || metricsEOMData.length === 0) && metricsDailyData && metricsDailyData.length > 0) {
            return metricsDailyData;
        }

        if (metricsEOMData && metricsEOMData.length > 0 && (!metricsDailyData || metricsDailyData.length === 0)) {
            return metricsEOMData;
        }

        const mergedMetricsData = metricsEOMData?.concat(metricsDailyData);

        const hasStoreIDCountForZone = params?.group_by === "store_zone" && mergedMetricAggregates.includes("store_parent_store_id_count_distinct")

        const columnsString = columns && hasStoreIDCountForZone ? columns.concat(",store_parent_store_id_count_distinct") : columns;
        const uniqueKeys = columnsString ? columnsString.split(",") : [];

        const summationKeys = hasStoreIDCountForZone
            ? mergedMetricAggregates?.filter(key => key !== 'store_parent_store_id_count_distinct')
            : mergedMetricAggregates

        const rolledUpMetricsData = rollupDataBy(mergedMetricsData, uniqueKeys, summationKeys);

        return rolledUpMetricsData;
    }, [columns, metricsEOMData, metricsDailyData, mergedMetricAggregates, params?.group_by])

    const rolledUpData = useMemo(() => {
        if ((!posData || posData.length === 0) && (!metricsData || metricsData.length === 0)) {
            return [];
        }

        if ((!posData || posData.length === 0) && metricsData && metricsData.length > 0) {
            return metricsData;
        }

        if (posData && posData.length > 0 && (!metricsData || metricsData.length === 0)) {
            return posData;
        }

        const mergedData = posData?.concat(metricsData);
        const mergedAggregates = (() => {
            if (posAggregates && mergedMetricAggregates) {
                return posAggregates.concat(mergedMetricAggregates);
            } else if (posAggregates) {
                return posAggregates;
            } else if (mergedMetricAggregates) {
                return mergedMetricAggregates;
            } else {
                return [];
            }
        })();

        const hasStoreIDCountForZone = params?.group_by === "store_zone" && mergedAggregates.includes("store_parent_store_id_count_distinct")

        const columnsString = columns && hasStoreIDCountForZone ? columns.concat(",store_parent_store_id_count_distinct") : columns;
        const uniqueKeys = columnsString ? columnsString.split(",") : [];

        const summationKeys = hasStoreIDCountForZone
            ? mergedAggregates?.filter(key => key !== 'store_parent_store_id_count_distinct')
            : mergedAggregates

        const rolledUpData = rollupDataBy(mergedData, uniqueKeys, summationKeys);

        return rolledUpData;
    }, [columns, posData, metricsData, posAggregates, mergedMetricAggregates, params?.group_by])

    return { data: rolledUpData, isLoading };
}


export function useSBUStoreData({ params, queryOptions = {} }) {

    const { data: currentStoreData, isLoading: isLoadingCurrentStoreData } = useSBUData({ params, queryOptions })
    const { data: lastYearStoreData, isLoading: isLoadingLastYearData } = useSBUData({
        params: {
            ...params,
            year: params.year - 1
        },
        queryOptions
    })

    return {
        currentStoreData,
        lastYearStoreData,
        isLoading: isLoadingCurrentStoreData || isLoadingLastYearData
    }
}

export function useSBUStoreDataL3({ params, queryOptions = {} }) {
    const { data: currentStoreData, isLoading: isLoadingCurrentStoreData } = useSBUDataL3({ params, queryOptions })
    const { data: lastYearStoreData, isLoading: isLoadingLastYearData } = useSBUDataL3({
        params: {
            ...params,
            year: params.year - 1
        },
        queryOptions: {
            ...queryOptions,
            isQueryForVsLastYear: true
        }
    })
    return {
        currentStoreData,
        lastYearStoreData,
        isLoading: isLoadingCurrentStoreData || isLoadingLastYearData
    }
}


export function useOverallBasketSizeData({ params }) {
    const { currentStoreData,
        lastYearStoreData,
        isLoading } = useSBUStoreData({
            params: {
                ...params,
            }
        });

    const isToplineOfStore = currentStoreData ? currentStoreData[0]?.store_id_count_distinct === 1 : false
    const isBUBasedData = params?.hasOwnProperty('business_unit') || params?.hasOwnProperty('higher_mbu')

    const txn_count_sum = currentStoreData && metricCalculations['actual_transactions'](currentStoreData[0])
    const txn_count_sum_ly = lastYearStoreData && metricCalculations['actual_transactions'](lastYearStoreData[0])

    const sales_sum = currentStoreData && metricCalculations['actual_sales'](currentStoreData[0])
    const sales_sum_ly = lastYearStoreData && metricCalculations['actual_sales'](lastYearStoreData[0])

    const actualBasketSizeTotal = currentStoreData && txn_count_sum ? sales_sum / txn_count_sum : 0;
    const basketSizePlan = currentStoreData ? (isToplineOfStore ? currentStoreData[0]?.basket_size_plan_sum : 1300) : 0; //MTD Plan
    const vsSalesPlanBasketSize = currentStoreData ? basketSizePlan : 0;
    const vsLastYearTotalBasketSize = lastYearStoreData && txn_count_sum_ly ? (sales_sum_ly / txn_count_sum_ly) : 0;

    return {
        actualBasketSizeTotal,
        targetBasketSize: basketSizePlan,
        vsSalesPlanBasketSize,
        vsLastYearTotalBasketSize,
        isLoading: isLoading
    }
}

// For Topline of other metrics (will have to check if works as intended with Transaction / Basket Size Topline)
export function useOverallToplineData({ params }) {

    const group = params?.group_by
    const selectedMetric = useSelectorValue('metric')  // this is from refactored metric which is moved to selectorProvider
    // the following metric uses whichever is available in the params or the selectedMetric
    const metric = params?.metric_column || selectedMetric// is deleted in useSBUStoreData()

    const { currentStoreData,
        lastYearStoreData,
        isLoading } = useSBUStoreData({ params });

    const actualTotal = calculateMetricValues(currentStoreData, metric, group)[undefined];
    const totalLastYear = calculateMetricValues(lastYearStoreData, metric, group)[undefined];
    const targetTotal = calculateMetricPlanValues(currentStoreData, metric, group)[undefined];

    return {
        actualTotal,
        targetTotal,
        totalLastYear,
        isLoading
    }
}

export function useOverallToplineDataL3({ params }) {

    const group = params?.group_by
    const selectedMetric = useSelectorValue('metric')  // this is from refactored metric which is moved to selectorProvider
    // the following metric uses whichever is available in the params or the selectedMetric
    const metric = params?.metric_column || selectedMetric// is deleted in useSBUStoreData()

    const { currentStoreData,
        lastYearStoreData,
        isLoading } = useSBUStoreDataL3({ params });

    const actualTotal = calculateMetricValuesL3(currentStoreData, metric, group)[undefined];
    const totalLastYear = calculateMetricValuesL3(lastYearStoreData, metric, group)[undefined];
    const targetTotal = calculateMetricPlanValuesL3(currentStoreData, metric, group)[undefined];

    return {
        actualTotal,
        targetTotal,
        totalLastYear,
        isLoading
    }
}

export function useOverallBasketSizeDataL3({ params }) {
    const { currentStoreData,
        lastYearStoreData,
        isLoading } = useSBUStoreDataL3({
            params: {
                ...params,
            }
        });


    const isToplineOfStore = currentStoreData ? currentStoreData[0]?.store_id_count_distinct === 1 : false
    const isBUBasedData = params?.hasOwnProperty('business_unit') || params?.hasOwnProperty('higher_mbu')

    const txn_count_sum = currentStoreData && metricCalculationsL3['actual_transactions'](currentStoreData[0] || 0)
    const txn_count_sum_ly = lastYearStoreData && metricCalculationsL3['actual_transactions'](lastYearStoreData[0] || 0)

    const sales_sum = currentStoreData && metricCalculationsL3['actual_sales'](currentStoreData[0] || 0)
    const sales_sum_ly = lastYearStoreData && metricCalculationsL3['actual_sales'](lastYearStoreData[0] || 0)

    const actualBasketSizeTotal = currentStoreData && txn_count_sum ? sales_sum / txn_count_sum : 0;
    const basketSizePlan = currentStoreData ? (isToplineOfStore ? currentStoreData[0]?.basket_size_plan_sum : 1300) : 0; //MTD Plan
    const vsSalesPlanBasketSize = currentStoreData ? basketSizePlan : 0;
    const vsLastYearTotalBasketSize = lastYearStoreData && txn_count_sum_ly ? (sales_sum_ly / txn_count_sum_ly) : 0;

    return {
        actualBasketSizeTotal,
        targetBasketSize: basketSizePlan,
        vsSalesPlanBasketSize,
        vsLastYearTotalBasketSize,
        isLoading: isLoading
    }
}

export function useGiftRegistryStoreData({ params, queryOptions = {} }) {
    const isGroupedByBusinessUnit = !!params && (
        params.group_by === "business_unit" || params.columns === "business_unit" || params.hasOwnProperty("business_unit")
        || params.group_by === "higher_mbu" || params.columns === "higher_mbu" || params.hasOwnProperty("higher_mbu")
    )
    const isGiftRegistryDisplayed = params?.group_by === 'channel' || (params?.channel && params?.channel[0] === 'Gift Registry') //params?.channel === 'store,store_id'
    const SBU_TABLE_NAME = (isGroupedByBusinessUnit || params?.business_unit) ? BU_TABLE_NAME : STORE_TABLE_NAME;
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year

    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const newParams = {
        ...params,
        columns: params?.group_by, // In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
    }
    if ('metric_column' in params) {
        delete newParams.metric_column;
        if (params?.group_by !== 'store,store_id') {
            delete newParams.group_by;
            delete newParams.columns;
        }
        if (isGiftRegistryDisplayed) {
            delete newParams.channel
        }
    }

    const { data: currentGRData, isLoading: isLoadingCurrentGRData } = useQuery(
        [SBU_TABLE_NAME, "gift_registry", "overall", newParams, 'current'],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(newParams, dayOfMonthMaxDate)
            )
            return ApiClient().get(`data/table/${SBU_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: ["gr_txn_count_sum", "gr_gross_sales_sum", "gross_sales_sum", "egr_plan_sum", "gr_smac_sales_sum"].join(","),
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange && isGiftRegistryDisplayed && queryOptions.enabled !== false
        }
    );

    // query for last year total using the same date range with only the year changed
    const { data: lastYearGRData, isLoading: isLoadingLastYearData } = useQuery(
        [SBU_TABLE_NAME, "gift_registry", "overall", params, 'last_year'],
        () => {
            const lastYearParams = {
                ...newParams,
            }
            if (lastYearParams.year) {
                lastYearParams.year = lastYearParams.year - 1;
            }

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(
                    lastYearParams, dayOfMonthMaxDate
                )
            )

            return ApiClient().get(`data/table/${SBU_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: ["gr_txn_count_sum", "gr_gross_sales_sum", "gross_sales_sum", "egr_plan_sum", "gr_smac_sales_sum"].join(","),
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && isGiftRegistryDisplayed && queryOptions.enabled !== false
        }
    );

    return {
        currentGRData,
        lastYearGRData,
        isLoading: isLoadingCurrentGRData || isLoadingLastYearData
    }
}

export function useGiftRegistryStoreDataL3({ params, queryOptions = {} }) {

    const isGiftRegistryDisplayed = params?.header_gr_tag__is_true && params?.header_gr_tag__is_true === 1;
    // const isGiftRegistryDisplayed = params?.group_by === 'channel' || (params?.channel && params?.channel[0] === 'Gift Registry') || (params?.cm_channel_desc && params?.cm_channel_desc[0] === 'Gift Registry')

    const newParams = {
        ...params,
        aggregates: ["pos_sku_gross_sales_sum", "header_tran_key_count_distinct", "pos_sku_sale_tot_qty_sum", "pos_sku_smac_sales_sum", "egr_sales_plan_sum"],
        columns: params?.group_by, // In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
        header_gr_tag__is_true: 1, // formerly cm_channel_desc: ["Gift Registry"],
    };

    if (newParams?.group_by === "channel" || newParams?.group_by === "cm_channel_desc") {
        delete newParams.group_by;
        delete newParams.columns;
    }

    if ('metric_column' in params) {
        delete newParams.metric_column;
        if (params?.group_by !== 'store_parent_store,store_parent_store_id') {
            delete newParams.group_by;
            delete newParams.columns;
        }
        if (isGiftRegistryDisplayed) {
            delete newParams.channel;
        }
    }

    const { data: currentGRData, isLoading: isLoadingCurrentGRData } = useSBUDataL3({
        params: {
            ...newParams,
        },
        queryOptions
    });

    // query for last year total using the same date range with only the year changed
    const { data: lastYearGRData, isLoading: isLoadingLastYearData } = useSBUDataL3({
        params: {
            ...newParams,
            year: params.year - 1,
        },
        queryOptions: {
            ...queryOptions,
            isQueryForVsLastYear: true
        }
    });

    return {
        currentGRData,
        lastYearGRData,
        isLoading: isLoadingCurrentGRData || isLoadingLastYearData,
    };
}

export function useStorePerformancePerGroupData({ params }) {

    const involvesHigherMBU = params?.group_by?.split(',').includes('higher_mbu')
    const newParams = params?.group_by === 'business_unit' ?
        { ...params, group_by: "business_unit,bu_group_3", columns: 'business_unit' } :
        involvesHigherMBU ?
            { ...params, group_by: "business_unit,bu_group_3,higher_mbu", columns: 'business_unit' } :
            params

    const {
        currentStoreData,
        lastYearStoreData,
        isLoading: isLoadingStoreData
    } = useSBUStoreData({ params: { ...newParams } });

    const { currentGRData,
        lastYearGRData,
        isLoading: isLoadingGRData } = useGiftRegistryStoreData({ params })

    const {
        currentStoreData: currentBUData,
        lastYearStoreData: lastYearBUData,
        isLoading: isLoadingBUData
    } = useSBUStoreData({
        params: {
            ...params,
            group_by: "business_unit",
            columns: "higher_mbu", // business_unit original
            higher_mbu: params.higher_mbu || [BU_FASHION_STRING, BU_KIDS_STRING, BU_HOME_STRING, BU_BEAUTY_STRING, BU_OTHERS_STRING], // bu_group_3 original
        }
    });


    const {
        currentStoreData: higherMBUCurrentData,
        lastYearStoreData: higherMBULastYearData,
        isLoading: isLoadingHigherMBUData
    } = useSBUStoreData({
        params: {
            ...params,
            group_by: "higher_mbu",
            columns: "higher_mbu", // business_unit original
        },
        queryOptions: {
            enabled: params?.group_by.includes('business_unit') && !params.hasOwnProperty('higher_mbu')
        }
    });

    const group = params?.group_by.split(',')[0]
    const selectedMetric = useSelectorValue('metric')  // this is from refactored metric which is moved to selectorProvider
    // the following metric uses whichever is available in the params or the selectedMetric
    const metric = params?.metric_column || selectedMetric// is deleted in useSBUStoreData()

    const excludeHigherMBUFilter = (data, groupBy) => {
        return groupBy !== 'business_unit'
            ? data
            : data?.filter(item =>
                item.higher_mbu !== BU_FASHION_STRING &&
                item.higher_mbu !== BU_KIDS_STRING &&
                item.higher_mbu !== BU_HOME_STRING &&
                item.higher_mbu !== BU_BEAUTY_STRING &&
                item.higher_mbu !== BU_OTHERS_STRING); // Corrected: Filtering for both BU_OTHERS_STRING and BU_FASHION_STRING
    };

    const filteredCurrentStoreData = excludeHigherMBUFilter(currentStoreData, group);
    const filteredLastYearStoreData = excludeHigherMBUFilter(lastYearStoreData, group);

    const metricTotal = calculateMetricValues(filteredCurrentStoreData, metric, group);
    const metricPlanTotal = calculateMetricPlanValues(filteredCurrentStoreData, metric, group);
    const lastYearTotal = calculateMetricValues(filteredLastYearStoreData, metric, group);

    let perGroupData = processData(metricTotal, metricPlanTotal, lastYearTotal, params, Object.keys(metricTotal), currentStoreData);

    if (group === 'channel' && currentGRData && lastYearGRData) {

        // Check if data is available before processing
        // api call for the gift registry data
        const grData = !isLoadingGRData
            ? processGRData(
                convertValuesToNumeric(currentGRData[0]),
                convertValuesToNumeric(lastYearGRData[0]),
                metric,
                currentStoreData
            )
            : {
                channel: 'Gift Registry',
                metricTotal: null,
                metricTotalPlan: null,
                contribution: null,
                vsPlan: null,
                vsLY: null,
            };
        // append result of said api call to perGroupData as another
        perGroupData.push(grData);
    }

    if (group === 'business_unit') {
        const buMetricTotal = calculateMetricValues(currentBUData, metric, 'business_unit');
        const buMetricPlanTotal = calculateMetricPlanValues(currentBUData, metric, 'business_unit');
        const buLastYearTotal = calculateMetricValues(lastYearBUData, metric, 'business_unit');

        const newParamsForBU = { ...params, group_by: 'business_unit' } //altering group_by only specifically since processData() relies on it
        const buGroupData = processData(buMetricTotal, buMetricPlanTotal, buLastYearTotal, newParamsForBU, Object.keys(buMetricTotal), currentStoreData);

        const updatedBuGroupData = buGroupData?.map(item => {
            // Retrieve the higher_mbu value corresponding to the business unit
            const higherMbu = currentStoreData?.find(data => data?.business_unit === item?.business_unit)?.higher_mbu || null;
            // Merge higher_mbu value with the business unit object
            return {
                ...item,
                higher_mbu: higherMbu
            };
        });

        const updatedBuGroupLYData = processData(buLastYearTotal, 1, 1, newParamsForBU, Object.keys(buMetricTotal), lastYearStoreData)?.map(item => {
            // Retrieve the higher_mbu value corresponding to the business unit
            const higherMbu = currentStoreData?.find(data => data?.business_unit === item?.business_unit)?.higher_mbu || null;
            // Merge higher_mbu value with the business unit object
            return {
                ...item,
                higher_mbu: higherMbu
            };
        });

        const grossSalesTYSum = currentBUData?.reduce((sum, item) => sum + (parseFloat(item.gross_sales_sum) ? parseFloat(item.gross_sales_sum) : 0), 0);
        const grossSalesLYSum = lastYearBUData?.reduce((sum, item) => sum + (parseFloat(item.gross_sales_sum) ? parseFloat(item.gross_sales_sum) : 0), 0);

        //transactions
        const grossTransactionsTYSum = currentBUData?.reduce((sum, item) => sum + (parseFloat(item.bu_txn_count_sum_distinct) ? parseFloat(item.bu_txn_count_sum_distinct) : 0), 0);
        const grossTransactionsLYSum = lastYearBUData?.reduce((sum, item) => sum + (parseFloat(item.bu_txn_count_sum_distinct) ? parseFloat(item.bu_txn_count_sum_distinct) : 0), 0);

        const salesPlanTYSum = currentBUData?.reduce((sum, item) => sum + (parseFloat(item.sales_plan_sum) ? parseFloat(item.sales_plan_sum) : 0), 0);
        const netSellingTYSum = currentBUData?.reduce((sum, item) => sum + (parseFloat(item.net_selling_area_sum) ? parseFloat(item.net_selling_area_sum) : 0), 0);
        const netSellingLYSum = lastYearBUData?.reduce((sum, item) => sum + (parseFloat(item.net_selling_area_sum) ? parseFloat(item.net_selling_area_sum) : 0), 0);

        const spaceNSASum = grossSalesTYSum / netSellingTYSum;
        const spaceNSAPlanSum = salesPlanTYSum / netSellingTYSum;
        const spaceNSALYSum = grossSalesLYSum / netSellingLYSum;


        //basket_size
        const basketSizeLYSum = grossSalesLYSum / grossTransactionsLYSum;

        const smacSalesLYSum = lastYearBUData?.reduce((sum, item) => sum + Number(item.smac_sales_sum), 0);

        // Calculate the sum of contributions from subRows
        const contributionSum = updatedBuGroupData?.reduce((sum, item) => sum + item.contribution, 0);
        const othersSum = metric === 'actual_space_nsa' ? spaceNSASum : updatedBuGroupData?.reduce((sum, item) => sum + item.metricTotal, 0);
        const othersPlanSum = metric === 'actual_space_nsa' ? spaceNSAPlanSum : updatedBuGroupData?.reduce((sum, item) => sum + item.metricPlanTotal, 0);
        const othersVsPlan = othersPlanSum ? (othersSum / othersPlanSum * 100) : null

        const othersVsLY = metric === 'actual_sales' ? (othersSum / grossSalesLYSum) * 100
            : metric === 'actual_space_nsa' ? (othersSum / spaceNSALYSum) * 100
                : metric === 'actual_smac_sales' ? (othersSum / smacSalesLYSum) * 100
                    : null

        const isNotTransactionOrBasketSize = metric !== 'actual_transactions' && metric !== 'actual_basket_size';

        const calculateMetricsForBU = (
            groupDataFiltered, lastYearData,
            parentData, parentDataLY, parentMetricTotal
        ) => {

            const contribution = groupDataFiltered?.reduce((sum, item) => sum + item.contribution, 0);

            // Check if any subRow has a value of "N/A" for specific attributes
            const hasNAMetricTotal = groupDataFiltered.some(item => item.metricTotal === "N/A");
            const hasNAMetricPlanTotal = groupDataFiltered.some(item => item.metricPlanTotal === "N/A");
            const hasNAContribution = groupDataFiltered.some(item => item.contribution === "N/A");
            const hasNAvsPlan = groupDataFiltered.some(item => item.vsPlan === "N/A");
            const hasNAvsLY = groupDataFiltered.some(item => item.vsLY === "N/A");

            if (parentData || parentDataLY) {
                const parentMetricValue = parentData ? metricCalculations[metric](parentData) : null;
                const metricLYTotal = parentDataLY ? metricCalculations[metric](parentDataLY) : null;
                const metricPlanTotal = parentData ? metricPlanCalculations[metric]({
                    ...parentData,
                    business_unit: 'All'  // adding this so business rule on metricPlanCalculations for business_units can be applied
                }) : null;

                const vsPlan = metricPlanTotal ? (parentMetricValue / metricPlanTotal * 100) : null;
                const vsLY = (parentMetricValue / metricLYTotal) * 100;
                const parentContribution = parentMetricTotal ? (parentMetricValue / parentMetricTotal * 100) : null;

                return {
                    metricTotal: hasNAMetricTotal ? "N/A" : parentMetricValue,
                    metricPlanTotal: hasNAMetricPlanTotal ? "N/A" : metricPlanTotal,
                    contribution: hasNAContribution ? "N/A" : parentContribution,
                    vsPlan: hasNAvsPlan ? "N/A" : vsPlan,
                    vsLY: hasNAvsLY ? "N/A" : vsLY,
                    subRows: groupDataFiltered
                }
            } else {


                const metricTotal = groupDataFiltered?.reduce((sum, item) => sum + item.metricTotal, 0);
                const metricLYTotal = lastYearData?.reduce((sum, item) => sum + item.metricTotal, 0);
                const metricPlanTotal = groupDataFiltered?.reduce((sum, item) => sum + item.metricPlanTotal, 0);
                const vsPlan = metricPlanTotal ? (metricTotal / metricPlanTotal * 100) : null;

                const vsLY = (metricTotal / metricLYTotal) * 100;

                // Update parent row's values to "N/A" for specific attributes if any subRow has "N/A"
                const metricTotalResult = hasNAMetricTotal ? "N/A" : metricTotal;
                const metricPlanTotalResult = hasNAMetricPlanTotal ? "N/A" : metricPlanTotal;
                const contributionResult = hasNAContribution ? "N/A" : contribution;
                const vsPlanResult = hasNAvsPlan ? "N/A" : vsPlan;
                const vsLYResult = hasNAvsLY ? "N/A" : vsLY;

                return {
                    metricTotal: metricTotalResult,
                    metricPlanTotal: metricPlanTotalResult,
                    contribution: contributionResult,
                    vsPlan: vsPlanResult,
                    vsLY: vsLYResult,
                    subRows: groupDataFiltered
                };

            }
        };

        const businessUnits = [BU_FASHION_STRING, BU_KIDS_STRING, BU_HOME_STRING, BU_BEAUTY_STRING, BU_OTHERS_STRING];

        for (const buString of businessUnits) {
            const groupDataFiltered = updatedBuGroupData.filter(item => item.higher_mbu === buString);
            const groupLyDataFiltered = updatedBuGroupLYData.filter(item => item.higher_mbu === buString);

            const groupHigherMBUCurrent = higherMBUCurrentData?.find(item => item.higher_mbu === buString);
            const groupHigherMBULastYear = higherMBULastYearData?.find(item => item.higher_mbu === buString);

            const metricTotalForHigherMBUCurrent = higherMBUCurrentData?.reduce((sum, item) => {
                return sum + metricCalculations[metric](item)
            }, 0);

            const buMetricsAttributes = calculateMetricsForBU(
                groupDataFiltered, groupLyDataFiltered,
                groupHigherMBUCurrent, groupHigherMBULastYear, metricTotalForHigherMBUCurrent
            );

            const buData = !isLoadingBUData ? {
                business_unit: buString,
                ...buMetricsAttributes
            } : {
                business_unit: buMetricsAttributes,
                metricTotal: null,
                metricPlanTotal: null,
                contribution: null,
                vsPlan: null,
                vsLY: null,
                subRows: groupDataFiltered
            };
            perGroupData.push(buData);
        }
    }

    if (group === 'zone') {
        perGroupData = perGroupData.map(item => {
            // Find the corresponding object in currentStoreData
            const correspondingData = currentStoreData.find(data => data.zone === item.zone);
            return {
                ...item,
                store_id_count_distinct: correspondingData ? correspondingData.store_id_count_distinct : null // If a corresponding object is found, append the 'store_id_count_distinct' property; if not, 'store_id_count_distinct' = null
            };
        });
    }

    return {
        perGroupData,
        isLoading: isLoadingStoreData || isLoadingGRData || isLoadingBUData
    }
}

export function useStorePerformancePerGroupDataL3({ params, queryOptions = {} }) {
    const involvesHigherMBU = params?.group_by?.split(",").includes("higher_mbu");
    const newParams =
        params?.group_by === "pm_business_unit_desc_standardized"
            ? {
                ...params,
                group_by: "pm_business_unit_desc_standardized,sbu_bu_group",
                columns: "pm_business_unit_desc_standardized,sbu_bu_group",
            }
            : params;

    const {
        currentStoreData,
        lastYearStoreData,
        isLoading: isLoadingStoreData,
    } = useSBUStoreDataL3({ params: { ...newParams } });


    const {
        currentGRData,
        lastYearGRData,
        isLoading: isLoadingGRData,
    } = useGiftRegistryStoreDataL3({ params: { ...newParams, group_by: null, columns: null } });

    const {
        currentStoreData: currentBUData,
        lastYearStoreData: lastYearBUData,
        isLoading: isLoadingBUData,
    } = useSBUStoreDataL3({
        params: {
            ...newParams,
            group_by: "pm_business_unit_desc_standardized,sbu_bu_group",
            columns: "pm_business_unit_desc_standardized,sbu_bu_group",
            sbu_bu_group: params.higher_mbu || [
                BU_FASHION_STRING,
                BU_KIDS_STRING,
                BU_HOME_STRING,
                BU_BEAUTY_STRING,
                BU_OTHERS_STRING,
            ],
        },
    });

    const {
        currentStoreData: higherMBUCurrentData,
        lastYearStoreData: higherMBULastYearData,
        isLoading: isLoadingHigherMBUData,
    } = useSBUStoreDataL3({
        params: {
            ...params,
            group_by: "sbu_bu_group",
            columns: "sbu_bu_group", // business_unit original
        },
        queryOptions: {
            enabled:
                params?.group_by.includes("pm_business_unit_desc_standardized") &&
                !params.hasOwnProperty("higher_mbu"),
        },
    });



    const group = params?.group_by.split(",")[0];
    const selectedMetric = useSelectorValue("metric"); // this is from refactored metric which is moved to selectorProvider
    // the following metric uses whichever is available in the params or the selectedMetric
    const metric = params?.metric_column || selectedMetric; // is deleted in useSBUStoreData()

    const excludeHigherMBUFilter = (data, groupBy) => {
        return groupBy !== "pm_business_unit_desc_standardized"
            ? data
            : data?.filter(
                (item) =>
                    item.sbu_bu_group !== BU_FASHION_STRING &&
                    item.sbu_bu_group !== BU_KIDS_STRING &&
                    item.sbu_bu_group !== BU_HOME_STRING &&
                    item.sbu_bu_group !== BU_BEAUTY_STRING &&
                    item.sbu_bu_group !== BU_OTHERS_STRING
            ); // Corrected: Filtering for both BU_OTHERS_STRING and BU_FASHION_STRING
    };

    const filteredCurrentStoreData = excludeHigherMBUFilter(currentStoreData, group);
    const filteredLastYearStoreData = excludeHigherMBUFilter(lastYearStoreData, group);


    const metricTotal = calculateMetricValuesL3(filteredCurrentStoreData, metric, group);
    const metricPlanTotal = calculateMetricPlanValuesL3(filteredCurrentStoreData, metric, group);
    const lastYearTotal = calculateMetricValuesL3(filteredLastYearStoreData, metric, group);

    let perGroupData = processDataL3(metricTotal, metricPlanTotal, lastYearTotal, params, Object.keys(metricTotal), currentStoreData);

    // const { data: storeZoneLookupData } = useTssStoreLookup({
    const { data: storeZoneLookupData } = useTssStoreLookup({
        params: {
            ...params,
            group_by: "zone",
            columns: "zone",
            aggregates: "store_code_count_distinct"
        },
        queryOptions: {
            ...queryOptions,
            enabled: group === "store_zone",
            hasAggregates: true,
            showLookup: true
        }
    });

    if (group === "cm_channel_desc" && currentGRData && lastYearGRData) {
        // && lastYearGRData) {
        // Check if data is available before processing
        // api call for the gift registry data

        const grData = !isLoadingGRData
            ? processGRDataL3(
                convertValuesToNumeric(currentGRData[0]),
                convertValuesToNumeric(lastYearGRData[0]),
                metric,
                currentStoreData
            )
            : {
                cm_channel_desc: "Gift Registry",
                metricTotal: null,
                metricTotalPlan: null,
                contribution: null,
                vsPlan: null,
                vsLY: null,
            };
        // append result of said api call to perGroupData as another
        perGroupData.push(grData);
    }

    if (group === "pm_business_unit_desc_standardized") {
        const buMetricTotal = calculateMetricValuesL3(currentBUData, metric, 'pm_business_unit_desc_standardized');
        const buMetricPlanTotal = calculateMetricPlanValuesL3(currentBUData, metric, 'pm_business_unit_desc_standardized');
        const buLastYearTotal = calculateMetricValuesL3(lastYearBUData, metric, 'pm_business_unit_desc_standardized');

        const newParamsForBU = { ...params, group_by: 'pm_business_unit_desc_standardized' } //altering group_by only specifically since processData() relies on it
        const buGroupData = processDataL3(buMetricTotal, buMetricPlanTotal, buLastYearTotal, newParamsForBU, Object.keys(buMetricTotal), currentStoreData);

        const updatedBuGroupData = buGroupData?.map(item => {
            // Retrieve the higher_mbu value corresponding to the business unit
            const higherMbu = currentStoreData?.find(data => data?.pm_business_unit_desc_standardized === item?.pm_business_unit_desc_standardized)?.sbu_bu_group || null;
            // Merge higher_mbu value with the business unit object
            return {
                ...item,
                sbu_bu_group: higherMbu
            };
        });

        const updatedBuGroupLYData = processData(buLastYearTotal, 1, 1, newParamsForBU, Object.keys(buMetricTotal), lastYearStoreData)?.map(item => {
            // Retrieve the higher_mbu value corresponding to the business unit
            const higherMbu = currentStoreData?.find(data => data?.pm_business_unit_desc_standardized === item?.pm_business_unit_desc_standardized)?.sbu_bu_group || null;
            // Merge higher_mbu value with the business unit object
            return {
                ...item,
                sbu_bu_group: higherMbu
            };
        });

        const grossSalesTYSum = currentBUData?.reduce((sum, item) => sum + (parseFloat(item.pos_sku_gross_sales_sum) ? parseFloat(item.pos_sku_gross_sales_sum) : 0), 0);
        const grossSalesLYSum = lastYearBUData?.reduce((sum, item) => sum + (parseFloat(item.pos_sku_gross_sales_sum) ? parseFloat(item.pos_sku_gross_sales_sum) : 0), 0);

        //transactions
        const grossTransactionsTYSum = currentBUData?.reduce((sum, item) => sum + (parseFloat(item.header_tran_key_count_distinct) ? parseFloat(item.header_tran_key_count_distinct) : 0), 0);
        const grossTransactionsLYSum = lastYearBUData?.reduce((sum, item) => sum + (parseFloat(item.header_tran_key_count_distinct) ? parseFloat(item.header_tran_key_count_distinct) : 0), 0);

        const salesPlanTYSum = currentBUData?.reduce((sum, item) => sum + (parseFloat(item.sales_plan_sum) ? parseFloat(item.sales_plan_sum) : 0), 0);
        const netSellingTYSum = currentBUData?.reduce((sum, item) => sum + (parseFloat(item.net_selling_area_sum) ? parseFloat(item.net_selling_area_sum) : 0), 0);
        const netSellingLYSum = lastYearBUData?.reduce((sum, item) => sum + (parseFloat(item.net_selling_area_sum) ? parseFloat(item.net_selling_area_sum) : 0), 0);

        const spaceNSASum = grossSalesTYSum / netSellingTYSum;
        const spaceNSAPlanSum = salesPlanTYSum / netSellingTYSum;
        const spaceNSALYSum = grossSalesLYSum / netSellingLYSum;


        //basket_size
        const basketSizeLYSum = grossSalesLYSum / grossTransactionsLYSum;

        const smacSalesLYSum = lastYearBUData?.reduce((sum, item) => sum + Number(item.smac_sales_sum), 0);

        // Calculate the sum of contributions from subRows
        const contributionSum = updatedBuGroupData?.reduce((sum, item) => sum + item.contribution, 0);
        const othersSum = metric === 'actual_space_nsa' ? spaceNSASum : updatedBuGroupData?.reduce((sum, item) => sum + item.metricTotal, 0);
        const othersPlanSum = metric === 'actual_space_nsa' ? spaceNSAPlanSum : updatedBuGroupData?.reduce((sum, item) => sum + item.metricPlanTotal, 0);
        const othersVsPlan = othersPlanSum ? (othersSum / othersPlanSum * 100) : null

        const othersVsLY = metric === 'actual_sales' ? (othersSum / grossSalesLYSum) * 100
            : metric === 'actual_space_nsa' ? (othersSum / spaceNSALYSum) * 100
                : metric === 'actual_smac_sales' ? (othersSum / smacSalesLYSum) * 100
                    : null

        const isTransactionOrBasketSize = metric === 'actual_transactions' || metric === 'actual_basket_size';
        const isSMACSales = metric == "actual_smac_sales"

        const calculateMetricsForBU = (groupDataFiltered, lastYearData, parentData, parentDataLY, parentMetricTotal) => {
            const contribution = groupDataFiltered?.reduce((sum, item) => sum + item.contribution, 0);

            // Check if any subRow has a value of "N/A" for specific attributes
            const hasNAMetricTotal = groupDataFiltered.some(item => item.metricTotal === "N/A");
            const hasNAMetricPlanTotal = isTransactionOrBasketSize || groupDataFiltered.some(item => item.metricPlanTotal === "N/A");
            const hasNAContribution = isTransactionOrBasketSize || groupDataFiltered.some(item => item.contribution === "N/A");
            const hasNAvsPlan = isTransactionOrBasketSize || groupDataFiltered.some(item => item.vsPlan === "N/A");
            const hasNAvsLY = groupDataFiltered.some(item => item.vsLY === "N/A");

            if (parentData || parentDataLY) {
                const parentMetricValue = parentData ? metricCalculationsL3[metric](parentData) : null;
                const metricLYTotal = parentDataLY ? metricCalculationsL3[metric](parentDataLY) : null;
                const metricPlanTotal = parentData ? metricPlanCalculationsL3[metric]({
                    ...parentData,
                    pm_business_unit_desc_standardized: 'All'  // adding this so business rule on metricPlanCalculations for business_units can be applied
                }) : null;

                const vsPlan = metricPlanTotal ? (parentMetricValue / metricPlanTotal * 100) : null;
                const vsLY = (parentMetricValue / metricLYTotal) * 100;
                const parentContribution = parentMetricTotal && !isSMACSales ? (parentMetricValue / parentMetricTotal * 100) : null;

                return {
                    metricTotal: hasNAMetricTotal ? "N/A" : parentMetricValue,
                    metricPlanTotal: hasNAMetricPlanTotal ? "N/A" : metricPlanTotal,
                    contribution: hasNAContribution ? "N/A" : parentContribution,
                    vsPlan: hasNAvsPlan ? "N/A" : vsPlan,
                    vsLY: hasNAvsLY ? "N/A" : vsLY,
                    subRows: groupDataFiltered
                }
            } else {
                const metricTotal = groupDataFiltered?.reduce((sum, item) => sum + item.metricTotal, 0);
                const metricLYTotal = lastYearData?.reduce((sum, item) => sum + item.metricTotal, 0);
                const metricPlanTotal = groupDataFiltered?.reduce((sum, item) => sum + item.metricPlanTotal, 0);
                const vsPlan = metricPlanTotal ? (metricTotal / metricPlanTotal * 100) : null;

                const vsLY = (metricTotal / metricLYTotal) * 100;

                // Update parent row's values to "N/A" for specific attributes if any subRow has "N/A"
                const metricTotalResult = hasNAMetricTotal ? "N/A" : metricTotal;
                const metricPlanTotalResult = hasNAMetricPlanTotal ? "N/A" : metricPlanTotal;
                const contributionResult = hasNAContribution ? "N/A" : contribution;
                const vsPlanResult = hasNAvsPlan ? "N/A" : vsPlan;
                const vsLYResult = hasNAvsLY ? "N/A" : vsLY;

                return {
                    metricTotal: metricTotalResult,
                    metricPlanTotal: metricPlanTotalResult,
                    contribution: contributionResult,
                    vsPlan: vsPlanResult,
                    vsLY: vsLYResult,
                    subRows: groupDataFiltered
                };

            }
        };

        const businessUnits = [BU_FASHION_STRING, BU_KIDS_STRING, BU_HOME_STRING, BU_BEAUTY_STRING, BU_OTHERS_STRING];

        for (const buString of businessUnits) {
            const groupDataFiltered = updatedBuGroupData.filter(item => item.sbu_bu_group === buString);
            const groupLyDataFiltered = updatedBuGroupLYData.filter(item => item.sbu_bu_group === buString);

            const groupHigherMBUCurrent = higherMBUCurrentData?.find(item => item.sbu_bu_group === buString);
            const groupHigherMBULastYear = higherMBULastYearData?.find(item => item.sbu_bu_group === buString);

            const metricTotalForHigherMBUCurrent = higherMBUCurrentData?.reduce((sum, item) => {
                return sum + metricCalculationsL3[metric](item);
            }, 0);

            const buMetricsAttributes = calculateMetricsForBU(
                groupDataFiltered, groupLyDataFiltered,
                groupHigherMBUCurrent, groupHigherMBULastYear, metricTotalForHigherMBUCurrent
            );

            const buData = !isLoadingBUData ? {
                pm_business_unit_desc_standardized: buString,
                ...buMetricsAttributes
            } : {
                business_unit: buMetricsAttributes,
                metricTotal: null,
                metricPlanTotal: null,
                contribution: null,
                vsPlan: null,
                vsLY: null,
                subRows: groupDataFiltered
            };
            perGroupData.push(buData);
        }
    }

    if (group === "store_zone") {
        perGroupData = perGroupData.map((item) => {
            // Find the corresponding object in currentStoreData
            const correspondingData = storeZoneLookupData?.find(
                (data) => data.zone === item.store_zone
            );
            return {
                ...item,
                store_parent_store_id_count_distinct: correspondingData ? correspondingData.store_code_count_distinct : null,
            };
        });
    }

    return {
        perGroupData,
        isLoading: isLoadingStoreData || isLoadingGRData || isLoadingBUData || isLoadingHigherMBUData,
    };
}

export function useSbuStoreList({ params }) {
    const TABLE_NAME = params?.business_unit ? BU_TABLE_NAME : STORE_TABLE_NAME;

    const queryKey = [TABLE_NAME, "stores", params, 'list'];

    return useQuery(
        queryKey,
        () => {
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...params,
                    group_by: "store,store_id,zone",
                    columns: "store,store_id,zone",
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        });
}

export function useSbuStoreListL3({ params, queryOptions = {} }) {
    const isGiftRegistryData = params?.cm_channel_desc && params?.cm_channel_desc[0] === 'Gift Registry';

    if (params.hasOwnProperty('cm_channel_desc') && params?.cm_channel_desc.includes('Gift Registry')) {
        delete params.cm_channel_desc
    }

    const { data, isLoading } = useTssStoreLookup({
        params: {
            ...params,
            group_by: 'store_code,store_description,zone'
        },
        queryOptions: {
            ...queryOptions,
            hasAggregates: false,
        }
    })

    const renamedData = data?.map(({ store_code, store_description, zone, ...rest }) => ({
        store_parent_store_id: store_code,
        store_parent_store: store_description,
        store_zone: zone,
    }));

    return { data: renamedData, isLoading };
}

export function useSBUStoreDataWithGrSwitchover({ params }) {
    const isGiftRegistryData = params?.cm_channel_desc && params?.cm_channel_desc[0] === 'Gift Registry';
    const perGroupBy = params?.group_by // should be the group by active in Performance Per Group section


    const appliedParams = {
        ...params,
        header_gr_tag__is_true: isGiftRegistryData ? 1 : null,
        group_by: "store_parent_store,store_parent_store_id",
        columns: perGroupBy, // will be switched to params.group_by inside main hook
        limit: 1000,
    }


    if (appliedParams.hasOwnProperty('cm_channel_desc') && appliedParams?.cm_channel_desc.includes('Gift Registry')) {
        delete appliedParams.cm_channel_desc
    }

    const {
        currentStoreData,
        lastYearStoreData,
        isLoading
    } = useSBUStoreDataL3({
        params: appliedParams,
    });

    return {
        storeDataCurrent: currentStoreData,
        storeDataLastYear: lastYearStoreData,
        isLoading: isLoading
    }
}

export function useSBUStorePerformanceData({ params }) {
    const {
        storeDataCurrent,
        storeDataLastYear,
        isLoading
    } = useSBUStoreDataWithGrSwitchover({ params })


    const group = 'store_id'
    const selectedMetric = useSelectorValue('metric')  // this is from refactored metric which is moved to selectorProvider
    // the following metric uses whichever is available in the params or the selectedMetric
    const metric = params?.metric_column || selectedMetric// is deleted in useSBUStoreData()

    const metricTotal = calculateMetricValues(storeDataCurrent, metric, group);
    const metricPlanTotal = calculateMetricPlanValues(storeDataCurrent, metric, group);
    const lastYearTotal = calculateMetricValues(storeDataLastYear, metric, group);

    const perStoreData = processData(
        metricTotal,
        metricPlanTotal,
        lastYearTotal,
        { ...params, group_by: group },
        Object.keys(metricTotal),
        storeDataCurrent
    );

    const storeData = appendStoreName(perStoreData, storeDataCurrent);
    return {
        data: storeData,
        isLoading
    }
}


/**
 * Hook to fetch and process market branch data.
 * @param {Object} queryOptions - Options for the useQuery hook.
 * @returns {Object} An object containing the query result and a lookup object.
 */
export function useTssStoreLookup({ params = {}, queryOptions = {} }) {
    // Define the columns to be fetched from the API
    const columns = params?.group_by
        ? params?.group_by
        : ["store_code",
            "store_description",
            "channel_desc",
            "basket_size_plan",
            "cluster",
            "zone",
            "short_zone_name",
            "short_store_name",
            "store_classification",
            "store_size",
        ];
    const columnsAsString = Array.isArray(columns) ? columns.join(",") : columns
    const aggregates = queryOptions?.hasAggregates && params?.aggregates ? params?.aggregates : null
    const aggregatesAsString = aggregates && Array.isArray(aggregates) ? aggregates.join(",") : aggregates

    // Define the query key for useQuery
    const queryKey = ['smStore', 'storeMetaData', params, queryOptions]

    // Define the query using useQuery
    const query = useQuery(
        queryKey,
        () => {
            // Fetch data from the API
            const preProcessedParams = preProcessParams({
                columns: columnsAsString,
                group_by: columnsAsString,
                aggregates: aggregatesAsString,
            })
            return ApiClient().get(`data/table/${SBU_STORE_LOOKUP_TABLE}/query/`, {
                params: {
                    ...preProcessedParams,
                    limit: 10000
                }
            }).then(res => {
                // Extract the data from the response
                return res.data.data;
            }).then(data => {
                // Map the data to a new format
                return data //.map(row => {
                //     return {
                //         ...row,
                //         store_id: row?.store_code,
                //         store: row?.store_description,
                //         zone: row?.zone
                //     }
                // })
            });
        },
        {
            // Merge the default options with the provided options
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: queryOptions?.enabled !== false,
            ...queryOptions
        }
    )

    const showLookup = queryOptions?.showLookup && columns.includes("store_code");
    // Create a lookup object from the query data
    const lookup = showLookup && query.data?.reduce((acc, row) => {
        acc[row.store_code] = row;
        return acc;
    }, {})

    // Return the query (and the lookup) object
    return showLookup ? { ...query, lookup } : query;
}