import React from "react";
import { Autocomplete, createFilterOptions, Checkbox, TextField, Chip ,CircularProgress   } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function DropdownCheckboxV3({ id, value, options, label, placeholder, limitTags, onChange,
    clearable, disabled, isOptionDisabled, tagsRenderer, allowSingleSelection, loading}) {
        
    if (clearable === undefined) {
        clearable = true
    }

    const allSelected = options?.length === value?.length;
    const clearOptions= ()=> {
        onChange(null,[])
    };
    
    const onSelectChange = (event, selectedOptions, reason) => {
        if (reason === "selectOption" || reason === "removeOption") {
            if (selectedOptions.find(option => option.value === "select-all")) {
                let result = [];
                if(!allSelected) {
                    result = options.filter(el => el.value !== "select-all");
                    return onChange(event,result?.map(obj => obj.value));
                } else {
                    return onChange(event,result);
                }
            } else {
                return onChange(event,selectedOptions?.map(obj => obj.value));
            }
        } else if (reason === "clear") {
            return clearOptions();
        }
    };

    const optionRenderer = (props,option, { selected }) => {
        const selectAllProps =
            option.value === "select-all" // To control the state of 'select-all' checkbox
            ? { checked: allSelected } : {};

        const newOptionProps = {
            ...props
        }

        // if allowSingleSelection is true, then clicking on the option will select that option only and deselect all other options
        // to multi-select, user must click on the checkbox
        // to achieve this, we need to preventDefault and stopPropagation of the onClick event
        // and then manually call onSelectChange with the selected option

        if (allowSingleSelection) {
            newOptionProps.onClick = (event) => {
                event.preventDefault();
                event.stopPropagation();
                console.log("newOptionProps.onClick",option)
                onSelectChange(event, [option], 'selectOption')
            }
        }
        const checkboxOnClick = (event) => {
            if (allowSingleSelection) {
                event.preventDefault();
                event.stopPropagation();
            }
            props.onClick(event)
        }
        return (
            <li {...newOptionProps}>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={selected}
                    {...props}
                    onClick={checkboxOnClick}
                    {...selectAllProps}
                />
                    {option.label}
            </li>
        );
    };

    if (!tagsRenderer) {
        // default tagsRenderer
        tagsRenderer = (value, getTagProps, ownerState) => {
            if (value?.length === options?.length) {
                return <Chip {...getTagProps} size="small" label="All" onDelete={() => clearOptions()}/>
            }

            return value?.map((tag, index) => {
                return <Chip {...getTagProps({index})} size="small" label={tag?.label}/>
            })
        }
    }


    const inputRenderer = params => <TextField {...params} label={label} placeholder={placeholder}
        InputProps={{
            ...params.InputProps,
            startAdornment: loading ?<CircularProgress size="1rem" sx={{marginLeft: '.50rem'}} /> : 
            params.InputProps.startAdornment
        }} 
    />;
    const getOptionSelected = (option, anotherOption) => option.value === anotherOption.value;
    const filter = createFilterOptions();

    const displayedOptions = loading ?
        [] :  // if we are still loading, then show nothing
        [{ label: 'All ', value: "select-all" }, ...options];

    return (
        <Autocomplete
            multiple
            disableClearable={!clearable}
            disabled={disabled || loading}
            getOptionDisabled={isOptionDisabled}
            size="small"
            id={id}
            limitTags={limitTags}
            options={displayedOptions}
            value={value}
            disableCloseOnSelect
            getOptionSelected={getOptionSelected}
            noOptionsText={'No Options Available'}
            filterOptions={(options, params) =>filter(options, params)}
            onChange={onSelectChange}
            renderOption={optionRenderer}
            renderInput={inputRenderer}
            renderTags={tagsRenderer}
            style={{ width: "100%" }}
        />
    );
}
