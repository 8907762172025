import RfmAnalytics from "./RfmAnayltics";
import TreeMaps from "../../../common/components/graphs/Treemaps";
import CardComponent from "../../../common/components/CardComponent";
import {TreemapsConfig} from '../../../GraphConfig/TreemapConfig'
import TREEMAP_DATA from '../../../mockdata/treemapData.json';
import FootTrafficAnalysis from "./FootTrafficAnalysis";
import GeoMap from "../../../common/components/graphs/GeoMap";

export default function CustomerAnalytics() {


    return <>
        <h3 className="mb-4">Customer Analytics</h3>
		<CardComponent title="Treemaps" id="collapseTreemapCard" >
            <TreeMaps config={TreemapsConfig.Treemaps} data={TREEMAP_DATA}/>
        </CardComponent>
        <RfmAnalytics/>
        <CardComponent title="Foot Traffic Analysis" id="collapseFootTrafficAnalysisCard" showCaret={true}>
            <FootTrafficAnalysis/>
        </CardComponent>
        <CardComponent title="Customer Location" id="customerLocationCard">
            <GeoMap/>
        </CardComponent>
    </>
}