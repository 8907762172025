import { Grid, Card, Typography } from '@mui/material';
import React, { useState } from 'react';
import GroupSelector from '../../../common/presentation/selectors/groupSelector';
import RankingCard from './rankingCard';
import { useRankByData } from '../../hooks';
import LoadingComponent from '../../../../../common/components/LoadingComponent'

export default function Ranking({period}) {

    //Temporary param
    const params = {
        month:[12],
        year: 2023,
    }

    const RANK_BY = [ 
        { label:'Sales', value: 'sales' }, 
        { label:'Transactions', value: 'transactions'},
        { label:'Unit Sold', value: 'unit_sold'},
        { label:'Basket Size', value: 'basket_size'},
        { label:'AT Volume', value: 'at_volume'}
    ]

    const [rankBy, setRankBy] = useState('sales')

    const {finalData, isLoading} = useRankByData({params, rankBy})

    if (isLoading) {
        return <LoadingComponent />
    }

    return <Grid container marginTop={1}>
        <Grid item sm={12} xs={12}>
            <Card  variant="outlined" sx={{ borderRadius:'.5rem'}}>
                <Grid container sx={{ backgroundColor:'#f6f6f8', padding: 1.5}}> 
                    <Grid item xs={12} sm={1}>
                        <Typography variant="h6"> Rank By </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <GroupSelector options={RANK_BY} selected={rankBy}  onSelectGroupChange={(value)=> setRankBy(value)}/>
                    </Grid>
                </Grid>
                <Grid container spacing={1} padding={2}>
                    <RankingCard title='Cluster' comparisonLabel='vs LY'/>
                    <RankingCard title='Zone' comparisonLabel='vs LY'/>
                    <RankingCard title='Store' comparisonLabel='vs LY'/>
                    <RankingCard title='Business Unit' comparisonLabel='vs LY'/>
                </Grid>
            </Card>
        </Grid>
    </Grid>
}