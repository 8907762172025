import React from "react";
import ColumnMapping from "./ColumnMapping";
import SuspenseFallback from "../../../../../common/components/GraphFactory/SuspenseFallback";
import ErrorFallbackComponent from "../../../../../common/components/ErrorFallbackComponent";
import {ErrorBoundary} from "react-error-boundary";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import HelpIcon from '@mui/icons-material/Help';
import SortIcon from '@mui/icons-material/Sort';
import Autocomplete from "@mui/material/Autocomplete";
import { ConvertToReactSelectOption, getSelectedOption } from "../../../../../common/utils";

export default function ChartConfigEditor({chartData, setChartData}) {

    return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="chart-config-content"
                    id="chart-config-header"
                >
                    <Typography fontWeight="bold" sx={{ color: "primary.main" }}>Chart Config</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {
                            chartData.chart_type === "bar" && <>
                        <Grid item md={12} >
                            <TextField type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} label="Label Width" fullWidth size="small"
                                value={chartData?.extra_data?.chartOptions?.yAxis?.axisLabel?.width}
                                onChange={(e) => {
                                    setChartData(prevState => {
                                        return {
                                            ...prevState,
                                            extra_data: {
                                                ...prevState.extra_data,
                                                chartOptions: {
                                                    ...prevState.extra_data.chartOptions,
                                                    yAxis: {
                                                        ...prevState.extra_data.chartOptions?.yAxis,
                                                        axisLabel: {
                                                            ...prevState.extra_data.chartOptions?.yAxis?.axisLabel,
                                                            width: e.target.value
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <Autocomplete
                                value={getSelectedOption(chartData?.extra_data?.chartOptions?.yAxis?.axisLabel?.overflow, ConvertToReactSelectOption(['truncate','break','none']))}
                                disableClearable
                                onChange={(event, newChartTypeOption) => {
                                    setChartData(prevState => {
                                        return {
                                            ...prevState,
                                            extra_data: {
                                                ...prevState.extra_data,
                                                chartOptions: {
                                                    ...prevState.extra_data.chartOptions,
                                                    yAxis: {
                                                        ...prevState.extra_data.chartOptions?.yAxis,
                                                        axisLabel: {
                                                            ...prevState.extra_data.chartOptions?.yAxis?.axisLabel,
                                                            overflow: event.target.value
                                                        }
                                                   }
                                                }
                                            }
                                        }
                                    })
                                }}
                                id="label_overflow_options" size="small"
                                options={ConvertToReactSelectOption(['truncate','break','none'])}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Label Overflow"/>}
                            />
                        </Grid>
                        </>
                    }

                    {
                        chartData.extra_data?.label?.value && <React.Suspense fallback={<SuspenseFallback/>}>
                            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                                <ColumnMapping
                                    extraData={chartData.extra_data}
                                    setExtraData={(newExtraData) => {
                                        setChartData(prevState => {
                                            return {
                                                ...prevState,
                                                extra_data: newExtraData
                                            }
                                        })
                                    }}
                                    table={chartData.table_source}
                                />
                            </ErrorBoundary>
                        </React.Suspense>
                    }
                    </Grid>
                </AccordionDetails>
            </Accordion>
    )
}