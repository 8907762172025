import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import InformationToolTip from "../../../common/components/InformationToolTip";


export default function TSSInformationToolTip() {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        const clearToolTipOnScroll = () => {
            // Close the tooltip when scrolling
            setTooltipOpen(false);
        };

        window.addEventListener('scroll', clearToolTipOnScroll);
        return () => {
            window.removeEventListener('scroll', clearToolTipOnScroll);
        };
    }, []);

    const infoForTooltip = [
        {
            title: "Metrics Formula",
            info: [
                "Sales: Gross Sales",
                "Transactions: Transaction Count",
                "Basket Size: Gross Sales / Transaction Count",
                "SMAC Sales: Gross Sales of SMAC members",
                "Catchment Rate: Dept Store Foot Traffic / Mall Foot Traffic",
                "Conversion Rate: (In store + Personal Shopper Pick-up Transactions) / Dept Store Foot Traffic",
                "Space Productivity NSA: Gross Sales / Net Selling Area in sqm",
                "Space Productivity TFA: Gross Sales / Total Floor Area in sqm",
                "Mall Foot Traffic: Number of Mall visitors",
                "Dept Store Foot Traffic: Number of Department Store visitors",
                "Units Sold Per Transaction: Units Sold / Transaction Count",
                "Unit Price: Gross Sales / Units Sold"
            ]
        },
        {
            title: "Table Columns Formula",
            info: [
                "Contribution: Proportion of the row's values compared to the total value of the chosen metric.",
                "vs Last Year: Proportion of Selected Year values compared to the Previous Year's values.",
                "vs 2019: Proportion of Selected Year values compared to 2019's values."
            ]
        },
    ]

    const informationContent = infoForTooltip.map((section, index) => (
        <Box key={index}>
            <Typography variant="body1" fontWeight="bold" my={0.5} mt={index === 0 ? 0 : 2}>{section.title}</Typography>
            <Divider sx={{ borderBottomWidth: '2px', backgroundColor: '#000' }} />
            <Typography variant="body2">
                {section.info.map((info, i) => {
                    const parts = info.split(":");
                    return (
                        <div key={index}>
                            <span style={{ fontWeight: 'bold' }}>{parts[0]}</span>: {parts[1]}
                        </div>
                    );
                })}
            </Typography>
        </Box>
    ))



    const handleTooltipChange = (value) => {
        setTooltipOpen(value);
    };

    return <InformationToolTip onChange={handleTooltipChange} open={tooltipOpen} information={informationContent} />
}