import Section from "../../../../../common/components/Dashboard/Section";
import SubDepartmentSelector from "./SubDepartmentSelector";
import SKUPerformanceBySalesTable from "./SKUPerformanceBySalesTable";

export default function StoreSKUPerformanceBySales() {
    return (
        <Section title="SKU Performance by Sales" selectorsOrFilters={<SubDepartmentSelector/>}>
            <SKUPerformanceBySalesTable/>
        </Section>
    );
}