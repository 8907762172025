import React, { useState } from "react";
import Modal from '@mui/material/Modal'
import MySQLConfig from "./MySQLConfig";
import SQLiteConfig from "./SQLiteConfig";
import BigQueryConfig from "./BigQueryConfig";
import DatabricksConfig from "./DatabricksConfig";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Grid version 1
import  Typography from '@mui/material/Typography' 
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Autocomplete from "@mui/material/Autocomplete";
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';


export default function AddHost() {
    //TODO: Functions for testing of connection for each host type (MySQL, SQLite)
    // Saving of connection configs for each host type (MySQL, SQLite)
    // Mobile view for the modal

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [dataSourceHostName, setDataSourceHostName] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedValue("- Select Host Type -");
        setDataSourceHostName('');
    };

    const [optionList] = useState([
        { value: "BigQuery", label: "BigQuery" },
        { value: "Databricks", label: "Databricks" },
        { value: "MySQL", label: "MySQL" },
        { value: "SQLite", label: "SQLite" }
    ])

    const handleChange = (event, newValue) => {
        setSelectedValue(newValue)
    }
    

    return (
        <>
            <Button variant="contained" size='medium'startIcon={<AddIcon />} sx={{textTransform:'none', backgroundColor:'blue',fontWeight:'bold',borderRadius:'9px'
                    ,'&:hover': {
                        backgroundColor: 'blue',
                        }}}   
                    onClick={openModal}>
                    Add Host
            </Button>
            
            <Modal open={isOpen} 
                   onClose={closeModal}
                   aria-labelledby="addhost-modal"
                   aria-describedby="addhost-modal-description"
                   style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

                <Grid xs={10} md={6} >
                    <Card sx={{pb:2,minHeight:'85vh'}}>
                        <Grid container justifyContent={'space-between'}>
                                <Typography variant="h6" sx={{ml:3, mt:2,  fontWeight:'bold'}}>Add Host</Typography>

                                <IconButton onClick={closeModal} sx={{ mr:1,mt:1,}} > 
                                    <CloseIcon sx={{fontSize:20}}/>
                                </IconButton>
                        </Grid>

                        <Divider sx={{my:1, borderBottom:1, }}/>

                        <Typography variant="h6" sx={{ml:3, mt:2,  fontWeight:'bold'}}>Add Data Source Host</Typography>
                    
                        <Grid item md={12} sx={{ml:6, mt:1}}>
                            <Typography variant="subtitle" sx={{ fontWeight:'bold',color:'#878787'}}>Host Type</Typography>

                            <Autocomplete 
                                id="addhost-options"
                                options={optionList}
                                size="small"
                                sx={{ width:'95%', mt:1}}
                                onChange={handleChange}
                                renderInput={(params) => <TextField {...params}  placeholder="Select Host Type"/>}
                            />
                        </Grid>

                        <Grid item md={12} sx={{ml:6, mt:1}}>
                            <Stack direction={'column'}>
                                <Typography variant="subtitle" sx={{fontWeight:'bold',color:'#878787'}}>Name</Typography>

                                <TextField 
                                id="nameInput" 
                                size={'small'} 
                                placeholder="Host Name" 
                                value={dataSourceHostName}
                                sx={{width:'95%', mt:1}}
                                onChange={(e) => setDataSourceHostName(e.target.value)}
                                disabled={isSubmitted}
                                />
                            </Stack>
                        </Grid>


                        {selectedValue?.value === "Databricks" &&
                            <DatabricksConfig
                                closeModal={closeModal}
                                dataSourceHostName={dataSourceHostName}
                                setIsSubmitted={setIsSubmitted}
                            />}
                        
                        {selectedValue?.value === "MySQL" &&
                            <MySQLConfig
                                closeModal={closeModal}
                                dataSourceHostName={dataSourceHostName}
                                setIsSubmitted={setIsSubmitted}
                            />}

                        {selectedValue?.value === "BigQuery" &&
                            <BigQueryConfig
                                closeModal={closeModal}
                                dataSourceHostName={dataSourceHostName}
                                setIsSubmitted={setIsSubmitted}
                            />}

                        {selectedValue?.value === "SQLite" &&
                            <SQLiteConfig
                                closeModal={closeModal}
                                dataSourceHostName={dataSourceHostName}
                                setIsSubmitted={setIsSubmitted}
                            />} 

                    
                    </Card>
                </Grid>
            </Modal>   
            
        </>
    );
}