import { useMemo, useState } from 'react';
import { useQuery } from "react-query";
import ApiClient from '../../../common/API';
import LoadingComponent from '../../../common/components/LoadingComponent';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Button from "@mui/material/Button";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';




export default function CuratedAnalytics() {

    const [number, setNumber] = React.useState('');

    const handleChange = (event) => {
    setNumber(event.target.value);
    };
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [searchText, setSearchText] = useState('')

    const paramValues = {
        search: searchText === '' ? undefined : searchText
    }
    const { isLoading, isError, isRefetching, data, error, isSuccess, refetch } = useQuery('pdfList', () => ApiClient().get('/pdf', { params: paramValues }).then(res => {
        return res.data
    }), {
        refetchOnWindowFocus: false
    });

    function RenderList({ currentItems }) {
    const list = currentItems.map(item => {
        return <Grid>
            <Grid item xs={12}>
                <Card sx={{ minWidth: 275, }}>
                <Grid container spacing={1} alignItems='center'display="flex" >
                    <Grid item xs={12} md={10} >
                    <Typography variant='h5' sx={12} pl={1.5}>{item?.name}</Typography>
                    <Typography paragraph="true" variant="bod2" sx={{ color: "#898989" }} xs={12} pl={1.5}>
                        {item?.description}
                        <br />
                        {item?.author}
                    </Typography>
                    </Grid>
                    <Typography sx={{ color: "#898989" }} >Number of pages:</Typography>
                    <Grid item xs={12} sm={.7} >
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            aria-label=".form-select-sm example" onChange={(e) => setItemsPerPage(parseInt(e.target.value))} value={itemsPerPage} >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems='center'  display="flex"  >
                    <Grid item xs={12} md={11} >
                    <Typography paragraph="true" variant="bod2" sx={{ color: "#898989" }} xs={12}  pl={1.5}>
                        Published at: {item?.datePublished}
                    </Typography>
                    </Grid>
                    <Grid item container justifyContent={'center'} xs={12} sm={.8}>
                    <Button variant="contained" sx={{ width: '100px', backgroundColor: '#0F2FEB', color: '#fff' }} onClick={(e) => { e.preventDefault(); window.open(item.path, '_blank') }}>
                        <FileDownloadIcon />
                    </Button>
                    </Grid>
                </Grid>

                </Card>
            </Grid>
        </Grid>
    })
    return <Grid sx={{ mt: 0 }}>
        {list}
    </Grid>
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };

    function RenderPage(data) {
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = data.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(data.length / itemsPerPage);

        return <>
        <RenderList currentItems={currentItems} />
        <Grid item p={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <Pagination
            count={3}
            renderOnZeroPageCount={null}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            disabled
            />
        </Grid>
        </>
    }

    return (
        <Grid>
            <Grid container spacing={1} display="flex" sx={{ justifyContent: 'space-between' }}>
                <Grid item xs={12} >
                    <Typography variant='h4' fontWeight="bold" >PDF Gallery</Typography>
                </Grid>

                <Grid item xs={12} sm={1} >
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 350 }} >
                        <InputBase
                        sx={{ ml: 1, flex: 1, fontWeight: 500, }}
                        placeholder="Enter text to search"
                        onChange={(e) => setSearchText(e.target.value)}
                        />
                        <IconButton type="button" onClick={() => refetch()} >
                        <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
            </Grid>
            <Divider sx={{ my: 3, borderBottom: 3 }} />

            <Grid item xs={12}>
                {
                (isLoading || isRefetching) && <LoadingComponent />
                }
                {
                isSuccess && !isRefetching && !isLoading && RenderPage(data)
                }
                {
                isError && !isRefetching && !isLoading && <div className="d-flex justify-content-center py-5">
                    <div >
                    <p>Encountered an error</p>
                    <p>Error Code: {error?.code}</p>
                    <p>Error Message: {error?.message}</p>
                    </div>
                </div>
                }

            </Grid>
        </Grid>
        )
}