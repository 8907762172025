import {useSelectorValue} from "../../../common/providers/selectorProvider";
import {useSBUDataL3} from "../../DataProvider/hooks";
import React from "react";
import {metricCalculationsL3} from "../../DataProvider/util";
import {reduceDistinct} from "../../../../../common/utils";
import {
    usePeriodBasedParams,
    usePivotedDataWithMetricContext,
    useStoreDataWithMeta,
    createZoneDataFromStoreData, filterStoreDataByStoreID
} from "./index";

export function useZoneStoreComparativeData({params, storeIDWhitelist}) {
    const pivotBy = useSelectorValue('storePerformancePivotKey') || 'sbu_bu_group'
    const selectedMetric = useSelectorValue('metric');

    const paramsBasedOnPeriod = usePeriodBasedParams({params})

    const {data: storeData, isLoading: isLoadingStoreData} = useSBUDataL3({
        params: {
            ...paramsBasedOnPeriod,
            columns: `store_parent_store_id,store_parent_store,${pivotBy}`,
            group_by: `store_parent_store_id,store_parent_store,${pivotBy}`,
            limit: 1000,
            metric_column: selectedMetric ? selectedMetric : 'actual_sales',
        },
    });

    const {data: storeDataWithMeta, isLoading: isLoadingStoreMetaData} = useStoreDataWithMeta({storeData})
    const isLoading = isLoadingStoreData || isLoadingStoreMetaData

    const filteredStoreData = filterStoreDataByStoreID(storeDataWithMeta, storeIDWhitelist)

    const zoneData = createZoneDataFromStoreData(filteredStoreData, [pivotBy]) // these will be used as headers for the columns

    // these will be used as headers for the columns
    const pivotValues = React.useMemo(() => {
        if (!filteredStoreData) {
            return []
        }

        const metricFn = metricCalculationsL3['actual_sales']  // we are using actual_sales to sort the pivot values so that bigger groups come first
        const tmpValues = filteredStoreData?.map(row => row[pivotBy]).reduce(reduceDistinct, [])

        // dumb sort: sum all values for each pivot key and sort by that
        return tmpValues.sort((a, b) => {
            const aTotal = filteredStoreData.filter(row => row[pivotBy] === a).reduce((acc, row) => acc + metricFn(row), 0)
            const bTotal = filteredStoreData.filter(row => row[pivotBy] === b).reduce((acc, row) => acc + metricFn(row), 0)
            return bTotal - aTotal
        })
    }, [filteredStoreData, pivotBy])

    const pivotedStoreData = usePivotedDataWithMetricContext(filteredStoreData, 'store_parent_store_id', pivotBy)
    const pivotedZoneData = usePivotedDataWithMetricContext(zoneData, 'store_zone', pivotBy)

    const displayRows = React.useMemo(() => {
        if (!pivotedZoneData || !pivotedStoreData || pivotedZoneData.length === 0 || pivotedStoreData.length === 0) {
            return []
        }

        return pivotedZoneData.map(zoneRow => {
            return {
                ...zoneRow,
                primary: zoneRow.store_zone,
                subRows: pivotedStoreData.filter(storeRow => {
                    return storeRow.store_zone === zoneRow.store_zone
                }).map(storeRow => {
                    return {
                        ...storeRow,
                        primary: storeRow.store_parent_store
                    }
                })
            }
        })

    }, [pivotedZoneData, pivotedStoreData])

    return {data: displayRows, isLoading, pivotHeaders: pivotValues}
}