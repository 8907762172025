import React, {useContext} from "react";
import {useQuery} from "react-query";
import ApiClient from "../../../../common/API";
import {StoreAnalyticsFilterContext} from "../contexts";
import BarChartVertical from "../../../../common/components/graphs/barChartVertical";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallbackComponent from "../../../../common/components/ErrorFallbackComponent";

import { Grid } from "@mui/material";


export default function SalesBarChartVertical({dateFilter, title, groupBy, limit, salesApiEndPointName, apiDefaultFilter}) {
    const {region, province, branch_name} = useContext(StoreAnalyticsFilterContext)

    if (!limit) {
        limit = 5;
    }

    const currentParams = {
        group_by: groupBy,
        ...dateFilter,
        limit: limit,
        region: region,
        province: province,
        store: branch_name?.toLowerCase(),
        ...apiDefaultFilter
    }

    const {isLoading, data} = useQuery([salesApiEndPointName, 'grouped_sales', currentParams], () => {
        return ApiClient().get(`/custom/${salesApiEndPointName}-map-sales/grouped_sales/`, {
            params: currentParams
        }).then(res => {
            return res.data
        })
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 15 * 60 * 1000,
        staleTime: 15 * 60 * 1000,
        retry: false
    })
    const salesData = (!isLoading && !!data) ? data.sort((a, b) => a.totalSales - b.totalSales).filter(row => row.v) : [];

    return (
            <Grid px={3} pb={1} boxShadow={1} borderRadius={2} mx={1}>
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>

                    <BarChartVertical
                        chartOptions={{
                            title : {
                                text: title,
                                textStyle: {
                                    color : 'black',
                                    fontSize: '1.25rem'
                                },
                                subtextStyle : {
                                    color : 'black',
                                    fontSize: '1rem'
                                },
                                left: 'center'

                            }
                        }}
                        data={salesData?.reduce((acc, val) => {
                            acc[val.v] = val.totalSales
                            return acc
                        }, {})}
                        isLoading={isLoading}
                        chartProps={{
                            style: {height: '20rem'}
                        }}
                    />
                </ErrorBoundary>
        </Grid>
    )
}