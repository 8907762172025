import { createLayerComponent } from '@react-leaflet/core';
import './leaflet.bing';
import L from 'leaflet';

const createLeafletElement = (props) => {
    
    const instance = L.bingLayer(props.bingkey, props);

    return { instance };
}

export default createLayerComponent(createLeafletElement);