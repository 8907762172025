import { useState } from 'react';
import { ToggleContext } from './context';

export default function ToggleProvider( {children} ) {
  const [toggleState, setToggleState] = useState('overview'); // Initial state

  const updateToggleState = (value) => {
    setToggleState(value);
  };

  return (
    <ToggleContext.Provider value={{ toggleState, updateToggleState }}>
      {children}
    </ToggleContext.Provider>
  );
};