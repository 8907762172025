import { TextField, Grid } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export default function Search({ searchQuery, setSearchQuery }) {

    return (
        <>
            <Grid>
                <TextField
                    sx={{ bgcolor: "white", minWidth: "100%" }}
                    size='small'
                    id="outlined-search"
                    label="Users by Name, ADID, or Email"
                    placeholder="Search Users by Name, ADID, or Email"
                    type="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                    }}
                />
            </Grid>
        </>
    )
}