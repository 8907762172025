import React from "react";
import TableSourceSelector from "./TableSourceSelector";
import SelectorComponents from "./SelectorComponents";
import { SELECTOR_TYPES_BY_CHART_TYPE } from "../constants";
import Filters from "../Filters";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import HelpIcon from '@mui/icons-material/Help';
import SortIcon from '@mui/icons-material/Sort';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, IconButton } from "@mui/material";

export default function DataSourceEditor({ tableId, chartData, setChartData, onInputChange }) {
    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="data-source-content"
                id="data-source--header"
            >
                <Typography fontWeight="bold" sx={{ color: "primary.main" }}>Data Source</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container >
                    {
                        !tableId && <Grid item md={12}>
                            <TableSourceSelector onChange={(newTableId) => {
                                setChartData(prevState => {
                                    if (prevState.table_source === newTableId) return prevState

                                    return {
                                        ...chartData,
                                        table_source: newTableId,
                                        extra_data: {
                                            ...chartData.extra_data,
                                            columns: [],  // reset columns
                                            filters: [],  // reset filters
                                            aggregates: []  // reset aggregates
                                        }
                                    }
                                })
                            }} tableId={chartData.table_source} />
                        </Grid>
                    }

                    <Grid item md={12}>
                        <SelectorComponents
                            chartData={chartData}
                            setChartData={setChartData}
                        />
                    </Grid>

                    <Grid item md={12} display="flex" justifyContent="flex-start">
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: "0" }} label={
                                <Box component="span" sx={{ display: 'flex' }}>
                                    <Typography fontWeight="bold" sx={{ color: "primary.main", mt: .6 }} marginRight={.5}>Distinct data only</Typography>
                                    <Tooltip title={<Typography variant="body2">If checked, duplicate records will be removed from the result.</Typography>}
                                        disableFocusListener
                                        disableTouchListener
                                        arrow
                                        placement='top'>
                                        <IconButton>
                                            <InfoOutlinedIcon style={{ color: 'grey' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>} labelPlacement="start" control={
                                    <Checkbox checked={chartData?.is_distinct} onChange={(e) => onInputChange('is_distinct', e.target.checked)} id="is_distinct" />}
                            />
                        </FormGroup>
                    </Grid>

                    {
                        SELECTOR_TYPES_BY_CHART_TYPE[chartData.chart_type]?.includes('label') && <>
                            <Grid item md={12} lg={4}>
                                <TextField type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} label="Max Row" placeholder="10" min={1} max={1000} size="small"
                                    value={chartData?.extra_data?.limit} helperText="Maximum number of values/data points to display in the visualization"
                                    onChange={(e) => {
                                        setChartData(prevState => {
                                            return {
                                                ...prevState,
                                                extra_data: {
                                                    ...prevState.extra_data,
                                                    limit: e.target.value
                                                }
                                            }
                                        });
                                    }} />
                            </Grid>
                        </>
                    }

                    {
                        SELECTOR_TYPES_BY_CHART_TYPE[chartData.chart_type]?.includes('label') &&
                        SELECTOR_TYPES_BY_CHART_TYPE[chartData.chart_type]?.includes('value') &&
                        <Grid item md={12} lg={8} mt={1} display="flex" alignItems="flex-start" >
                            <Typography marginRight={1} sx={{ ml: 4 }}>Sort output by: </Typography>
                            <ButtonGroup sx={{ mt: -.5 }} variant="contained" aria-label="text button group">
                                {
                                    chartData.extra_data.value && <Button variant={`${chartData.extra_data?.value?.value === chartData.extra_data?.order_by?.replace("-", "") ? "contained" : "outlined"}`} size="small"
                                        onClick={() => {
                                            setChartData(prevState => {
                                                return {
                                                    ...prevState,
                                                    extra_data: {
                                                        ...prevState.extra_data,
                                                        order_by: prevState.extra_data.order_by === prevState.extra_data?.value?.value ? "-" + prevState.extra_data?.value?.value : prevState.extra_data?.value?.value
                                                    }
                                                }
                                            })
                                        }}>
                                        {
                                            chartData.extra_data.value.value === chartData.extra_data?.order_by?.replace("-", "") &&
                                                chartData.extra_data.order_by[0] === "-" ? <SortIcon fontSize="small" /> : <SortIcon fontSize="small" sx={{ rotate: '180deg', transform: 'rotateY(180deg)' }} />
                                        }
                                        {" "}
                                        Value
                                    </Button>
                                }
                                {
                                    chartData.extra_data.label && <Button variant={`${chartData.extra_data?.value?.value === chartData.extra_data?.order_by?.replace("-", "") ? "outlined" : "contained"}`} size="small"
                                        onClick={() => {
                                            setChartData(prevState => {
                                                return {
                                                    ...prevState,
                                                    extra_data: {
                                                        ...prevState.extra_data,
                                                        order_by: prevState.extra_data.order_by === prevState.extra_data?.label?.value ? "-" + prevState.extra_data?.label?.value : prevState.extra_data?.label?.value
                                                    }
                                                }
                                            })
                                        }}>

                                        {
                                            chartData.extra_data.label.value === chartData.extra_data?.order_by?.replace("-", "") &&
                                                chartData.extra_data.order_by[0] === "-" ? <SortIcon fontSize="small" /> : <SortIcon fontSize="small" sx={{ rotate: '180deg', transform: 'rotateY(180deg)' }} />
                                        }
                                        {" "}
                                        Label
                                    </Button>
                                }
                            </ButtonGroup>
                        </Grid>
                    }

                    <Grid item md={12}>
                        <Filters sx={{ color: "primary.main" }}
                            onChange={(newFilters) => {
                                setChartData(prevState => {
                                    return {
                                        ...prevState,
                                        extra_data: {
                                            ...prevState.extra_data,
                                            filters: newFilters
                                        }
                                    }
                                }
                                )
                            }}
                            chartFilters={chartData?.extra_data?.filters}
                            tableId={chartData.table_source} />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}