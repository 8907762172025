import CustomerSegments from "./CustomerSegments";
import StoreCustomerActivityByBU from "./StoreCustomerActivityByBU";
import StoreCustomerActivityByHomeBranch from "./StoreCustomerActivityByHomeBranch";
import StoreCustomerRecency from "./StoreCustomerRecency";
import StoreSalesPerCustomer from "./StoreSalesPerCustomer";
import StoreSKUPerformanceBySales from "./StoreSKUPerformanceBySales";
import AverageSpendPerVisit from "./AverageSpendPerVisit";

export default function UsageContent() {

    return <>
        <StoreCustomerActivityByHomeBranch/>
        <StoreCustomerRecency/>
        <AverageSpendPerVisit/>
        <CustomerSegments/>
        <StoreCustomerActivityByBU/>
        <StoreSKUPerformanceBySales/>
        <StoreSalesPerCustomer/>
    </>
}