import React from "react";
import { useMemo } from "react";
import { useSelectorValue } from "../../../common/providers/selectorProvider";
import { getMetricColumnHeader } from "../../DataProvider/util";
import { apiFilterKeys, zoneOrderArray } from "../constants";
import { titleCaseString } from "../../../common/util";
import { Box } from "@mui/material";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import SubRowExpander from "../../../../../common/components/DataTable/SubRowExpander";
import ZoneAndBranchLink from "../components/ZoneAndBranchLink";
import { useZoneStoreComparativeData } from "../hooks/useZoneStoreComparativeData";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { sortArrayBasedOnGivenArray } from "../../../../../common/utils";
import { sortedDisplayRows, sortingFn } from "./utils";


export default function PivotTable(
    {
        params,
        selectedRows,
        enableBranchLink,
        selectedStores,
        sectionFilters
    }
) {
    const selectedMetric = useSelectorValue('metric');
    const period = useSelectorValue('period');
    const selectedViewBy = useSelectorValue('viewBy');
    const shouldFilterByAPI = apiFilterKeys.includes(selectedViewBy);
    const selectedBU = sectionFilters.higher_mbu

    //Dynamic header table
    const pivotByHeaderLabel = selectedBU ? selectedBU : 'Business Unit Group'
    const metricHeaderLabel = getMetricColumnHeader(period, selectedMetric);

    // Fix me: manually changes column name
    const selectedViewByToL3ColName = {
        'channel': 'cm_channel_desc',
        'business_unit': 'pm_business_unit_desc_standardized',
        'zone': 'store_zone',
    }
    const selectedViewByL3 = selectedViewByToL3ColName[selectedViewBy]

    const { data: displayRows, isLoading, pivotHeaders } = useZoneStoreComparativeData({
        params: {
            ...params,
            [selectedViewByL3]: shouldFilterByAPI ? selectedRows : null // note: when apiFilterKey = 'business_unit', 'higher_mbu' col values are put into selectedRows as well and not only 'business_unit' col values
        },
        storeIDWhitelist: selectedStores
    })

    const filteredDisplayRows = displayRows.filter((item) => item.store_zone !== 'Online')

    const sortedArray = useMemo(() => sortedDisplayRows(filteredDisplayRows, zoneOrderArray), [filteredDisplayRows, zoneOrderArray]);

    const tableColumns = React.useMemo(() => {
        return [
            {
                id: 'primary',
                header: 'Zone / Store',
                accessorKey: 'primary',
                cell: ({ row, getValue }) => {
                    const branchTitle = titleCaseString(getValue());
                    const hasSubRows = row?.original?.subRows?.length > 0

                    return <Box sx={{ minWidth: '9rem', paddingLeft: "1rem", textTransform: 'capitalize', fontWeight: hasSubRows && 'bold', marginLeft: `${row.depth * 1}rem` }}>
                        <ZoneAndBranchLink
                            store={row?.original?.store}
                            storeId={row?.original?.store_id}
                            year={params?.year}
                            month={params?.month}
                            period={period}
                            disable={!enableBranchLink}
                            zone={row?.original?.zone}>
                            {branchTitle}
                        </ZoneAndBranchLink>
                        <SubRowExpander row={row} />
                    </Box>
                }, sortingFn,
                customStyle: { width: '18rem' }
            },
            ...pivotHeaders.map(pivotValue => {
                return {
                    id: pivotValue,
                    header: pivotValue,
                    accessorKey: pivotValue,
                    cell: (row) => {
                        return (
                            <Box sx={{ width: { xs: '95%', sm: selectedBU === 'Others' ? '65%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={true} />
                            </Box>
                        )
                    },
                    customStyle: { width: '10rem', height: '2rem', textAlign: 'left', verticalAlign: 'middle' }
                }
            })
        ]
    }, [pivotHeaders, selectedMetric])


    if (isLoading) {
        return <LoadingComponent />
    }


    return (
        <>
            <Box sx={{ textAlign: 'center', alignContent: 'center', width: '100%', backgroundColor: '#0A4FB4', color: 'white', height: '2rem', fontWeight: 'bold' }}>
                {pivotByHeaderLabel} {metricHeaderLabel}
            </Box>
            <TableComponentV2
                customTableContainerStyle={{
                    borderRadius: "0",
                    boxShadow: 'none',
                }}
                customTableHeaderStyle={{
                    backgroundColor: '#caddeb'
                }}
                columns={tableColumns}
                data={sortArrayBasedOnGivenArray(sortedArray, zoneOrderArray, selectedViewBy)}
                tableId={'StorePerformanceComparativeTable'}
                hasPagination={false}
                isCompact={true}
                initialSort={{ id: 'store', desc: false }}
                hasFooterSubtotal={false}
                enableSortingRemoval={true}
            />

        </>
    );
}