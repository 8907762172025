import React from "react";
import {useQuery} from "react-query";
import ApiClient from "../../../common/API";


export default function SampleDbTargets() {
    const sampleQuery = useQuery('dbTargetsSample', () => {
        return ApiClient().get('targetDb/sample/').then(res => {
            return res.data
        })
    })

    return <>

        <div className="row">
            <div className="col-sm-6">
                Sample Targets from database
            </div>
        </div>
        <div className={"row"}>
            <div className="text-center col mb-4">
                {
                    sampleQuery.isLoading ?
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only"></span>
                        </div> :
                        <div style={{width: '100%', textAlign: "center", fontFamily: "monospace", maxHeight: "10em", overflowY: "scroll"}} className="row">
                            {
                                sampleQuery.data?.sort((a, b) => {
                                        return a.primary_phone_number.localeCompare(b.primary_phone_number)
                                    })
                                    .map((row, index) => {
                                    return <div className={"col-3 small"}>{row.primary_phone_number}</div>
                                })
                            }
                        </div>
                }
            </div>
        </div>
    </>
}