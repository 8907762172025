import React from "react";
// import Modal from "react-modal";
import Select from "react-select";
import { ConvertToReactSelectOption, getSelectedOption } from "../../../../common/utils";
import ChartSelector from "./chartSelector";
import EditorComponent from '../../../../common/components/EditorComponent';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Modal, Box } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function ContentSelectorModal({ isOpen, setIsOpen, content, setContent, tableId }) {

    // step 1: choose content type from chart, text, spacer
    // step 2: choose chart type if chart, input text if text
    const contentTypes = [
        "text",
        "chart",
    ]

    const contentTypeFromContent = content && Object.keys(content)[0]
    const contentValueFromContent = content && Object.values(content)[0]

    const [selectedContentType, setSelectedContentType] = React.useState(
        contentTypeFromContent ? contentTypeFromContent : contentTypes[0]
    )
    const [tempContent, setTempContent] = React.useState(contentValueFromContent)

    const contentTypeOptions = ConvertToReactSelectOption(contentTypes)

    const initialValue = React.useMemo(() => {
        return contentValueFromContent
    }, [contentValueFromContent])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 5,
        width: "90%",
        height: "80%",
        overflowY: "hidden",
        minWidth: "50%",
        overflowY: 'scroll'
    };

    return (<>

        <Modal
            open={isOpen}
            aria-labelledby="content-selector-modal"
        >

            <Box sx={style}>
                <Grid container>
                    <Grid item xs={6} marginBottom={2}>
                        <Typography variant="h6"> Select Content </Typography>
                    </Grid>

                    <Grid item xs={6} marginBottom={2} textAlign="end">
                        <IconButton onClick={() => { setIsOpen(!isOpen) }}> <Close /> </IconButton>
                    </Grid>

                    <Grid item xs={12} marginBottom={2}>
                        <Typography> Content Type </Typography>
                        <Select
                            id="contentTypeSelector"
                            options={contentTypeOptions}
                            value={getSelectedOption(selectedContentType, contentTypeOptions)}
                            onChange={(e) => {
                                console.log("content type: ", e)
                                setSelectedContentType(e.value)
                                setTempContent(null)
                            }}
                            styles={{
                                menu: provided => ({ ...provided, zIndex: 1000 }),
                            }}
                        />
                    </Grid>
                    {
                        selectedContentType === "text" &&
                        <>
                            <Grid item xs={12} >
                                <Typography>Text Content</Typography>
                                <EditorComponent
                                    onEditorChange={(value) => { setTempContent(value) }}
                                    initialValue={initialValue}
                                    init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                                            'searchreplace', 'table', 'wordcount', 'code'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} marginTop={3} textAlign="end">
                                <Button variant="contained" onClick={() => {
                                    setContent({
                                        [selectedContentType]: tempContent
                                    })
                                    setIsOpen(!isOpen)
                                }} disabled={!selectedContentType || !tempContent}> Save </Button>
                            </Grid>
                        </>
                    }
                </Grid>

                {
                    selectedContentType === "chart" && <ChartSelector
                        tableId={tableId}
                        chart={tempContent}
                        onChangeChart={(chart) => {
                            setContent({
                                [selectedContentType]: chart
                            })
                            setIsOpen(!isOpen)
                        }}
                    />
                }
            </Box>
        </Modal>
    </>)
}