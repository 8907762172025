import React from 'react'
import MerchandiseDetailed from '../../../merchandise/presentation/detailed'
import Operations from './Operations'



export default function RenderZoneViewContent({ viewBy, params, period, zone, onTogglePeriodChange, filters, onDropDownFilterChange }) {


    switch (viewBy) {
        case 'merchandise':
            return <MerchandiseDetailed isBranchView={true} filters={filters} params={params} period={period} zone={zone} onDropdownChange={onDropDownFilterChange} />
        case 'customer':
            return 'CUSTOMER HERE'
        // case 'location':
        //     return (
        //         <LocationsAnalysis store={store} params={params} storeID={storeID} />
        //     )
        // case 'promotions':
        //     return 'PROMOTIONS HERE'
        default:
            return <Operations params={params} period={period} zone={zone} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} />

    }
}