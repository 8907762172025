import React from "react";
import Grid from "@mui/material/Grid";


export default function CustomerLocationNotes({additionalNotes=[]}) {
    return (
        <Grid container>
            <Grid item px={{md: 10, sm: 4, xs: 2}} pb={4}>
                <strong>Notes:</strong>
                <ul>
                    <li>
                        Projected barangay population based on Census of Population and Housing 2020
                    </li>
                    <li>
                        Barangay Administrative Boundaries as of Apr 2023
                    </li>
                    <li>
                        Catchment Area data as of Feb 2024
                    </li>
                    {
                        additionalNotes.map((note, index) => (
                            <li key={index}>
                                {note}
                            </li>
                        ))
                    }
                </ul>
            </Grid>
        </Grid>
    )
}