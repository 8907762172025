import React from 'react';
import moment from "moment/moment";
import { TopLineContext } from "../../common/contexts";
import sales from "../../../../img/sales.svg"
import transactions from '../../../../img/transactions.svg'
import basket_size from "../../../../img/basket_size.svg"
import catchment_rate from "../../../../img/catchment_rate.svg"
import conversion_rate from "../../../../img/conversion_rate.svg"
import space_productivity_tfa from '../../../../img/space_productivity_tfa.svg'
import space_productivity_nsa from "../../../../img/space_productivity_nsa.svg"
import smac_sales from '../../../../img/smac_sales.svg'
import dept_store_foot_traffic from '../../../../img/dept_store_foot_traffic.svg'
import mall_foot_traffic from '../../../../img/mall_foot_traffic.svg'
import units_sold from '../../../../img/units_sold.svg'
import units_sold_per_txn from '../../../../img/units_sold_per_txn.svg'

import { useOverallBasketSizeData, useOverallToplineDataL3, useOverallBasketSizeDataL3 } from './hooks';
import { useSelectorValue } from "../../common/providers/selectorProvider";
import { useParamsBasedOnPeriodSelectorContext } from "../hooks";

export default function TopLineDataProvider({ params, topLineMetricItems, children, enabledVSPlan, enableMetricPlan, enabledVSLastYear }) {
    const period = useSelectorValue('period');

    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';

    const paramsBasedOnPeriod = useParamsBasedOnPeriodSelectorContext(params);

    const salesDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_sales'
        }
    });

    const transactionsDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_transactions'
        }
    });

    const basketSizeDataHook = useOverallBasketSizeDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_basket_size'
        }
    })

    const catchmentRateHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_catchment_rate'
        }
    })
    const conversionRateHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_conversion_rate'
        }
    })
    const spaceProdNSAHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_space_nsa'
        }
    })
    const spaceProdTFAHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_space_tfa'
        }
    })
    const smacSalesHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_smac_sales'
        }
    })
    const mallFootTraffic = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_mall_foot_traffic'
        }
    })
    const smStoreFootTraffic = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_sm_store_foot_traffic'
        }
    })
    const unitsSoldDataL3 = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_units_sold'
        }
    })
    const unitsSoldPerTransactionDataL3 = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_units_sold_per_txn'
        }
    })

    const planHeader = isQoQ ? 'QTR Plan' : isMTD ? 'MTD Plan' : 'YTD Plan';

    const transformTopLineData = (dataHookResponse, dataHookParams, item) => {
        const {
            // basket_size
            actualBasketSizeTotal,
            targetBasketSize,
            vsSalesPlanBasketSize,
            vsLastYearTotalBasketSize,
            // other metric
            actualTotal,
            targetTotal,
            totalLastYear,
            isLoading,
        } = dataHookResponse;

        let paramsPeriod = moment().format('YYYY-MM-DD');
        if (params?.month?.[0] && params?.year) {
            paramsPeriod = `${params?.year}-${params?.month?.[0].toString().padStart(2, '0')}-01`;
        }

        let periodStartDate = moment(paramsPeriod).startOf('year').format('YYYY-MM-DD');
        let periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
        if (isQoQ) {
            periodStartDate = moment(paramsPeriod, 'YYYY-MM-DD').startOf('quarter').format('YYYY-MM-DD');
            periodEndDate = moment(periodStartDate).endOf('quarter').format('YYYY-MM-DD');
        } else if (isMTD) {
            periodStartDate = moment(paramsPeriod).startOf('month').format('YYYY-MM-DD');
            periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
        }

        let comparisonValues;

        if ((enabledVSPlan?.includes(item)) && (enabledVSLastYear?.includes(item))) {
            comparisonValues = [
                {
                    label: 'vs Plan',
                    value: item === 'basket_size' ? vsSalesPlanBasketSize : targetTotal,
                },
                {
                    label: 'vs Last Year',
                    value: item === 'basket_size' ? vsLastYearTotalBasketSize : totalLastYear,
                },
            ];
        } else if (enabledVSPlan?.includes(item)) {
            comparisonValues = [
                {
                    label: 'vs Plan',
                    value: item === 'basket_size' ? vsSalesPlanBasketSize : targetTotal,
                },
            ];
        } else if (enabledVSLastYear?.includes(item)) {
            comparisonValues = [
                {
                    label: 'vs Last Year',
                    value: item === 'basket_size' ? vsLastYearTotalBasketSize : totalLastYear,
                },
            ];
        } else {
            comparisonValues = []
        }
        let targetValue;
        if (enableMetricPlan?.includes(item)) {
            targetValue = item === 'basket_size' ? targetBasketSize : targetTotal;
        } else {
            targetValue = null;
        }

        return {
            isLoading,
            periodFrom: periodStartDate,
            periodTo: periodEndDate,
            primaryValue: item === 'basket_size' ? actualBasketSizeTotal : actualTotal,
            targetValue: targetValue,
            comparisonValues: comparisonValues.length > 0 ? comparisonValues : [],
            textMap: {},
            itemKey: item,
        };
    }

    const items = [
        {
            ...transformTopLineData(salesDataHook, params, 'actual_sales'),
            textMap: {
                imgIcon: sales,
                primaryLabel: 'Sales',
                targetLabel: enableMetricPlan?.includes('actual_sales') ? planHeader : null,
            }
        },
        {
            ...transformTopLineData(transactionsDataHook, params, 'actual_transactions'),
            textMap: {
                imgIcon: transactions,
                primaryLabel: 'Transactions',
                targetLabel: enableMetricPlan?.includes('actual_transactions') ? planHeader : null,
            }
        },
        {
            ...transformTopLineData(basketSizeDataHook, params, 'basket_size'),
            textMap: {
                imgIcon: basket_size,
                primaryLabel: 'Basket Size',
                targetLabel: enableMetricPlan?.includes('basket_size') ? planHeader : null,
            }
        },
        {
            ...transformTopLineData(smacSalesHook, params, 'actual_smac_sales'),
            textMap: {
                imgIcon: smac_sales,
                primaryLabel: 'SMAC Sales',
                targetLabel: enableMetricPlan?.includes('actual_smac_sales') ? planHeader : null,
            }
        },
        {
            ...transformTopLineData(mallFootTraffic, params, 'actual_mall_foot_traffic'),
            textMap: {
                imgIcon: mall_foot_traffic,
                primaryLabel: 'Mall Foot Traffic',
                targetLabel: enableMetricPlan?.includes('actual_mall_foot_traffic') ? planHeader : null,
            }
        },
        {
            ...transformTopLineData(catchmentRateHook, params, 'actual_catchment_rate'),
            textMap: {
                imgIcon: catchment_rate,
                primaryLabel: 'Catchment Rate',
                targetLabel: enableMetricPlan?.includes('actual_catchment_rate') ? planHeader : null,
            }
        },
        {
            ...transformTopLineData(smStoreFootTraffic, params, 'actual_sm_store_foot_traffic'),
            textMap: {
                imgIcon: dept_store_foot_traffic,
                primaryLabel: 'Dept Store Foot Traffic',
                targetLabel: enableMetricPlan?.includes('actual_sm_store_foot_traffic') ? planHeader : null,
            }
        },
        {
            ...transformTopLineData(conversionRateHook, params, 'actual_conversion_rate'),
            textMap: {
                imgIcon: conversion_rate,
                primaryLabel: 'Conversion Rate',
                targetLabel: enableMetricPlan?.includes('actual_conversion_rate') ? planHeader : null,
            }
        },
        {
            ...transformTopLineData(spaceProdNSAHook, params, 'actual_space_nsa'),
            textMap: {
                imgIcon: space_productivity_nsa,
                primaryLabel: 'Space Productivity NSA',
                targetLabel: enableMetricPlan?.includes('actual_space_nsa') ? planHeader : null,
            }
        },
        {
            ...transformTopLineData(unitsSoldDataL3, params, 'actual_units_sold'),
            textMap: {
                imgIcon: units_sold,
                primaryLabel: 'Units Sold',
                targetLabel: enableMetricPlan?.includes('actual_units_sold') ? planHeader : null,
                prefix: ' ',
            }
        },
        {
            ...transformTopLineData(unitsSoldPerTransactionDataL3, params, 'actual_units_sold_per_txn'),
            textMap: {
                imgIcon: units_sold_per_txn,
                primaryLabel: 'Units Sold Per Txn',
                targetLabel: enableMetricPlan?.includes('actual_units_sold_per_txn') ? planHeader : null,
                prefix: ' ',
            }
        },
        {
            ...transformTopLineData(spaceProdTFAHook, params, 'actual_space_tfa'),
            textMap: {
                imgIcon: space_productivity_tfa,
                primaryLabel: 'Space Productivity TFA',
                targetLabel: enableMetricPlan?.includes('actual_space_tfa') ? planHeader : null,
            }
        },
    ].filter(item => {
        if (!topLineMetricItems || topLineMetricItems.length === 0) return true;
        return topLineMetricItems.indexOf(item.itemKey) > -1
    });

    return <TopLineContext.Provider value={{ items: items }}>
        {children}
    </TopLineContext.Provider>
}
