
import React from 'react';
import { useSelectorContext, useSelectorValue } from '../../common/providers/selectorProvider';
import ToggleSelector from '../../common/presentation/selectors/toggleSelector';
import { PERIOD_MONTHLY, PERIOD_QUARTERLY, PERIOD_WEEKLY } from '../../../../Constants/general';

export default function PeriodToggleSelector() {
    const period = useSelectorValue('period');
    const TOGGLE_OPTIONS = [
        { label: "Weekly", value: PERIOD_WEEKLY, disabled: true },
        { label: "Monthly", value: PERIOD_MONTHLY},
        { label: "Quarterly", value: PERIOD_QUARTERLY}
    ]
    
    const {setValue: setSelectorValue} = useSelectorContext()
    const setPeriod = (value) => setSelectorValue('period', value)

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setPeriod(val);
        }
    };

    return <ToggleSelector
        options={TOGGLE_OPTIONS}
        value={period}
        onChange={onTogglePeriodChange}
        additionalStyles={{ textTransform: 'none' }}
    />
}