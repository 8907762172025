import React from "react";
import StoreAnalytics from "../../index";
import chart from "../../../../../img/chart-savemore.png"
import {companyAcronymToName} from "../../../../../common/utils";

export default function SaveMoreAnalytics() {
    return (
        <StoreAnalytics
            title={"SaveMore"}
            locationApiEndPoint={'/custom/location/smc/'}
            chartViewImage={chart}
            salesApiEndPointName={"frg"}
            apiDefaultFilter={{company: companyAcronymToName("smc")}}
            company={"smc"}
        />
    );
}