import {Card, GlobalStyles, Grid, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {WindowSizeContext} from '../../../../../common/context/WindowSizeContext';
import RenderBUViewContent from './renderBUViewContent';
import {useEnvironmentSetting} from "../../../../../common/providers/EnvironmentSettingsProvider";
import {ENABLE_TSS_BUSINESS_UNIT_VIEW} from "../../../../../Constants/settings";
import TogglePeriodProvider from './Provider/togglePeriodProvider';
import LoadingComponent from '../../../../../common/components/LoadingComponent';
import {useCheckHasAccess} from "../../../Dashboard/hooks";
import {NoPermissionAccess} from "../../../../../common/components/NoPermissionAccess";
import MainViewBySelector from "./MainViewBySelector";
import SelectorProvider from "../../providers/selectorProvider";
import MainHeader from "./MainHeader";
import { useAvailableDateRangeL3 } from '../../../TmpTSSSales/DataProvider/hooks';


export default function BusinessUnitView({checkPermission}) {
    const hasAccess = useCheckHasAccess()
    const showBusinessUnitView = useEnvironmentSetting(ENABLE_TSS_BUSINESS_UNIT_VIEW)
    const windowSize = useContext(WindowSizeContext)
    const {data: dateRangeV2, isLoading: dateRangeIsLoading} = useAvailableDateRangeL3()


    if (dateRangeIsLoading) {
        return <LoadingComponent/>
    }

    if (checkPermission && !hasAccess) {
        return <NoPermissionAccess/>
    }

    if (!showBusinessUnitView) {
        return <Card>
            <Typography variant='h5' fontWeight='bold' textAlign='center' paddingY={2}> Business Unit View is not
                enabled </Typography>
        </Card>
    }

    return <>
        <GlobalStyles
            styles={{
                body: {backgroundColor: '#e0e0e0'},
            }}
        />
        <SelectorProvider>
            <TogglePeriodProvider>
                <Grid container>
                    <Grid container sx={{
                        position: `${!windowSize.isOnMobile ? 'sticky' : ''}`,
                        top: `${!windowSize.isOnMobile ? '5rem' : ''}`,
                        zIndex: 5,
                        backgroundColor: '#e0e0e0'
                    }}>
                        <MainHeader/>
                        <MainViewBySelector/>
                    </Grid>
                    <RenderBUViewContent/>
                </Grid>
            </TogglePeriodProvider>
        </SelectorProvider>
    </>
}