import { Box, Typography } from "@mui/material";
import { decimalDisplayNumber, topLineDisplayNumber } from "../../../../../common/utils";

export default function CustomerPurchaseIndicator ({value, bgColor, prefix, postfix}) {

    // Added condition for negative values including -0
    if (value < 1 && Math.sign(value) !== -1) {
        value = decimalDisplayNumber(value); // Format value to 3 decimal places if less than 1
    } else if (value.toString().includes('-0.000')) {
        value = 0; // Replace negative zero with zero
    }else {
        value = topLineDisplayNumber(value)
    }

    return (
        <Box
        sx={{
            width: 'inherit',
            height: 'inherit',
            borderRadius: 1,    
            color: 'black',
            bgcolor: bgColor,
            '&:hover': {
                bgcolor: bgColor,
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >

        <Typography variant='body2' fontSize='11px'>
            {prefix}{value}{postfix}
        </Typography>
    </Box>
    )

}