import {customAgeBinOrder} from "../constants";

export const sortByAgeGroupFn = (a, b) => {
    // age_bin is assigned to attribute `brand` in the data
    const brandAIndex = customAgeBinOrder.indexOf(a.brand)
    const brandBIndex = customAgeBinOrder.indexOf(b.brand)
    if (brandAIndex === -1 && brandBIndex === -1) {
        return 0; // If both brands are not found, keep their relative order
    } else if (brandAIndex === -1) {
        return 1; // Move brands not found in the custom order to the end
    } else if (brandBIndex === -1) {
        return -1; // Move brands not found in the custom order to the end
    }
    return brandAIndex - brandBIndex;

}
