import { Box } from "@mui/material";
import Section from "../../../../common/components/Dashboard/Section";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import { convertToValidNumber } from "../../../../common/utils";
import BarIndicator from "../../common/presentation/barIndicator";
import { IndicatorCellBox } from "../../Customers/Transactions/BUGroupShareOfWallet";
import NumericValueDisplay from "../../common/presentation/numericValueDisplay";
import CellWithSubRow from "../../../../common/components/DataTable/CellWithSubRow";
import { useMemo } from "react";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import { useSMACCardStatusTableData } from "../hooks";
import { sortByOrder } from "../../../../common/utils";


export default function SMACCardStatus() {

    const {data, isLoading} = useSMACCardStatusTableData({})
    const orderArray = ['Total', 'New', 'Existing', 'Renewal']
    const subRowOrderArray = ['Start', 'SMAC', 'Prestige']

    const customSort = (rowA, rowB) => {
        if (orderArray) {
            if (rowA.depth > 0 && rowB.depth > 0) {
                return sortByOrder(rowA.original, rowB.original, subRowOrderArray, 'pk'); // sort for sub row
            } else {
                return sortByOrder(rowA.original, rowB.original, orderArray, 'pk') // sort for parent row
            }
        } else {
            return rowA?.getValue() < rowB?.getValue() ? 1 : -1
        }
    }

    const sortSubRows = (rows) => {
        return rows.map(item => {
            return {
                ...item,
                subRows: item.subRows.sort((a, b) => {
                    return subRowOrderArray.indexOf(a.pk) - subRowOrderArray.indexOf(b.pk);
                })
            };
        });
    };

    const sortedData = sortSubRows(data);

    const SMAC_CARD_STATUS_COLUMNS = useMemo(()=> {
        return [
            {
                header: 'Status / Type',
                accessorKey: 'pk',
                id: 'smac_card_status',
                cell: ({row, getValue}) => {
                    const value = getValue() === 'SMAC' ? 'Blue' : getValue();
                    return (
                        <CellWithSubRow row={row} marginLeftMinimum={1}>
                            {value}
                        </CellWithSubRow>
                    )
                },
                sortingFn: customSort,
                customStyle:{
                    width: '12rem',
                    lineHeight: "2.5em"
                }
            },
            {
                header: 'Member Count',
                accessorKey: 'total_members',
                cell: ({row, getValue}) => {
                    return <Box sx={{marginX: 'auto', width: "4em", textWrap: "nowrap"}}>
                        <NumericValueDisplay value={getValue()} isAbbreviated={true}/>
                    </Box>
                },
                customStyle:{
                    width: '12rem',
                    textAlign: 'left',
                }
            },
            {
                header: '% Distribution',
                id: 'distribution',
                accessorKey: 'distribution',
                cell: ({row, getValue}) => {
                    return <Box sx={{marginX: 'auto', width: "4em", textWrap: "nowrap"}}>
                        <NumericValueDisplay value={getValue()} isAbbreviated={true} postfix={'%'}/>
                    </Box>
                },
                customStyle:{
                    width: '12rem',
                    textAlign: 'center',
                }
            },
            {
                header: 'vs Plan',
                id: 'vs_plan',
                accessorKey: 'vsPlan',
                cell: ({row, getValue}) => {
                    const value = getValue();
                    return value ? <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                </IndicatorCellBox> : '-'
                },
                customStyle:{
                    width: '12rem',
                    textAlign: 'center',
                }
            },
            {
                header: 'vs Last Year',
                id: 'vs_ly',
                accessorKey: 'vsLastYear',
                cell: ({row, getValue}) => {
                    const value = getValue();
                    return value ? <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(value)?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                </IndicatorCellBox> : '-'
                },
                customStyle:{
                    width: '12rem',
                    textAlign: 'center',
                }
            },
        ]
    },[])

    if (isLoading) {
        return <LoadingComponent/>
    }

    return <Section title={'SMAC Card Status'}>
        <TableComponentV2
            initialSort={{id: 'pk', asc: true}}
            columns={SMAC_CARD_STATUS_COLUMNS}
            data={sortedData}
            tableId={'SMACCardStatus'}
            isCompact={true}
            customTableHeaderStyle={{
                backgroundColor: '#caddeb'
            }}
            isOpexAccounts={true} //TODO: change prop name in TableComponentV2 to isExpandedAllDepthZeroRows after checking usage
        />
    </Section>

}