import { useQuery } from "react-query";
import { convertParamsToTransactionDate, preProcessParams, convertMerchParamsDate } from "./utils";
import ApiClient from "../../../common/API";
import moment from "moment/moment";
import { useMemo } from "react";
import { USE_QUERY_DEFAULT_OPTIONS } from "../../../Constants/settings";

export const MERCH_TABLE_NAME = "l4_sbu_agg_bu_txn_merch"

export function useAvailableDateRange() {
    return useQuery(
        [MERCH_TABLE_NAME, 'sales', "date_range"],
        () => ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
            params: {
                columns: "year,month",
                group_by: "year,month",
                gross_sales__gt: 0,  // consider only months with sales
                // FIXME: hardcoded limit to show complete data only
                limit: 1000
            }
        }).then(res => {
            const transactionDates = res.data.data.map(row => {
                return [
                    moment(`${row.year}-${row.month}-01`).toDate(),  // start date
                    moment(`${row.year}-${row.month}-01`).endOf('month').toDate() // end date
                ]
            }).flat().sort((a, b) => a - b)
            return {
                "min": transactionDates[0],
                "max": transactionDates[transactionDates.length - 1]
            }
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
}

export function useMerchAggCol() {
    return useQuery(
        [],
        () =>
            ApiClient().get(`data/table/${MERCH_TABLE_NAME}/`, {

            }).then((res) => res.data),
        {
            refetchOnWindowFocus: false,
            staleTime: 600000, // 10 mins
        }
    );
}

export function useMerchData({ params, aggregates, view }) {
    const hasMerchDate = params?.month?.length > 0 && !!params?.year

    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasMerchDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    //current / selected year data 
    const { data: merchData, isLoading: isMerchDataLoading } = useQuery(
        [MERCH_TABLE_NAME, "business_units", params, 'actual_sales'],

        () => {

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(params, dayOfMonthMaxDate)
            )

            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: aggregates || 'gross_sales_sum,bu_txn_count_sum,sales_units_sum,smac_sales_sum,net_selling_area_sum',
                    view: view || '',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data
            })

        }
    )

    const { data: lastYearMerchData, isLoading: isLoadingLastYear } = useQuery(
        [MERCH_TABLE_NAME, "business_units", params, 'last_year'],
        () => {
            const lastYearParams = {
                ...params,
            }
            if (lastYearParams.year) {
                lastYearParams.year = lastYearParams.year - 1;
            }

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(
                    lastYearParams, dayOfMonthMaxDate
                )
            )

            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: aggregates || 'gross_sales_sum,bu_txn_count_sum,sales_units_sum,smac_sales_sum,net_selling_area_sum',
                    view: view || '',
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasMerchDate
        }
    );

    const { data: data2019, isLoading: isLoading2019 } = useQuery(
        [MERCH_TABLE_NAME, "gross-sales", "business-unit", params, '2019'],
        () => {
            const lastYearParams = {
                ...params,
            }
            if (lastYearParams.year) {
                lastYearParams.year = 2019;
            }

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(
                    lastYearParams, dayOfMonthMaxDate
                )
            )

            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: aggregates || 'gross_sales_sum,bu_txn_count_sum,sales_units_sum,smac_sales_sum,net_selling_area_sum',
                    view: view || '',
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasMerchDate
        }
    );

    return {
        merchData,
        lastYearMerchData,
        data2019,
        isLoading: isLoadingLastYear || isMerchDataLoading || isLoadingDateRange || isLoading2019
    }
}

export function useMerchDataV2({ params }) {
    const hasMerchDate = params?.month?.length > 0 && !!params?.year

    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasMerchDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    //current / selected year data 
    const { data: merchData, isLoading: isMerchDataLoading } = useQuery(
        [MERCH_TABLE_NAME, "business_units", params, 'actual_sales'],

        () => {

            const processedParams = preProcessParams(
                convertMerchParamsDate(params, dayOfMonthMaxDate)
            )

            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: 'gross_sales_sum,bu_txn_count_sum,sales_units_sum,smac_sales_sum,net_selling_area_sum',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data
            })

        }
    )

    const { data: lastYearMerchData, isLoading: isLoadingLastYear } = useQuery(
        [MERCH_TABLE_NAME, "business_units", params, 'last_year'],
        () => {
            const lastYearParams = {
                ...params,
            }
            if (lastYearParams.year) {
                lastYearParams.year = lastYearParams.year - 1;
            }

            const processedParams = preProcessParams(
                convertMerchParamsDate(
                    lastYearParams, dayOfMonthMaxDate
                )
            )

            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: 'gross_sales_sum'
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasMerchDate
        }
    );

    const { data: data2019, isLoading: isLoading2019 } = useQuery(
        [MERCH_TABLE_NAME, "gross-sales", "business-unit", params, '2019'],
        () => {
            const lastYearParams = {
                ...params,
            }
            if (lastYearParams.year) {
                lastYearParams.year = 2019;
            }

            const processedParams = preProcessParams(
                convertMerchParamsDate(
                    lastYearParams, dayOfMonthMaxDate
                )
            )

            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: 'gross_sales_sum'
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasMerchDate
        }
    );

    return {
        merchData,
        lastYearMerchData,
        data2019,
        isLoading: isLoadingLastYear || isMerchDataLoading || isLoadingDateRange || isLoading2019
    }
}


//graphs 
export function useBUGroupOverallPerformance({ params }) {

    const { merchData, isLoading } = useMerchDataV2({
        params: {
            ...params,
            columns: 'sbu_higher_mbu,year',
            group_by: 'sbu_higher_mbu,year',
            limit: 100,
        },
    })

    const data = merchData && Object.values(merchData?.reduce((result, { year, sbu_higher_mbu, gross_sales_sum }) => {
        const key = sbu_higher_mbu?.toLowerCase();
        result[year] = { ...result[year], [key]: gross_sales_sum, year };
        return result;
    }, {}));

    const finalData = data?.map(({ year, others, beauty, kids, home, fashion }) => ({ year, others, beauty, kids, home, fashion }))

    return { finalData, isLoading }
}

//BU Group Table
export function useBUGroupPerformance({ params }) {

    const { merchData, isLoading: isTotalSalesLoading } = useMerchData({
        params: {
            ...params,
        },
    })

    const total_sales = merchData?.[0]?.gross_sales_sum

    const { merchData: buGroupData, lastYearMerchData: lastYearBUGroupData, data2019, isLoading: isBUDataLoading } = useMerchData({
        params: {
            ...params,
            columns: 'sbu_higher_mbu',
            group_by: 'sbu_higher_mbu',
            limit: 100,
        }
    })

    const isLoading = isBUDataLoading || isTotalSalesLoading

    const finalBUGroupData = useMemo(() => {

        if (isLoading) {
            return []
        }

        const createDataMap = (data) => {
            return new Map((data || []).map(item => [item.sbu_higher_mbu, { total_actual_sale: item.gross_sales_sum, total_transaction: item.bu_txn_count_sum, total_unit_sold: item.sales_units_sum, total_basket_size: item.basket_size_plan_sum, total_smac_sales: item.smac_sales_sum, total_net_selling_area: item.net_selling_area_sum }]));
        };

        const buGroupDataMap = createDataMap(buGroupData);
        const buGroupDataLastYearMap = createDataMap(lastYearBUGroupData);
        const buGroupData2019Map = createDataMap(data2019);

        const higherMbu = Array.from(new Set([...(buGroupData || []), ...(lastYearBUGroupData || []), ...(data2019 || [])].map(item => item.sbu_higher_mbu)));

        return higherMbu?.map(higher_mbu => {
            const actual_sales = buGroupDataMap?.get(higher_mbu)?.total_actual_sale || 0
            const last_year_sales = buGroupDataLastYearMap?.get(higher_mbu)?.total_actual_sale || 0
            const actual_sales_2019 = buGroupData2019Map?.get(higher_mbu)?.total_actual_sale || 0

            const actual_transaction = buGroupDataMap.get(higher_mbu)?.total_transaction || 0
            const last_year_transaction = buGroupDataLastYearMap?.get(higher_mbu)?.total_transaction || 0
            const actual_transaction_2019 = buGroupData2019Map.get(higher_mbu)?.total_transaction || 0

            const actual_units_sold = buGroupDataMap?.get(higher_mbu)?.total_unit_sold || 0
            const last_year_units_sold = buGroupDataLastYearMap?.get(higher_mbu)?.total_unit_sold || 0
            const actual_units_sold_2019 = buGroupData2019Map?.get(higher_mbu)?.total_unit_sold || 0

            const actual_basket_size = (actual_sales / actual_transaction)
            const last_year_basket_size = (last_year_sales / last_year_transaction)
            const actual_basket_size_2019 = (actual_sales_2019 / actual_transaction_2019)

            const unit_sold_per_txn = (actual_units_sold / actual_transaction)
            const ly_unit_sold_per_txn = (last_year_units_sold / last_year_transaction)
            const unit_sold_per_txn_2019 = (actual_units_sold_2019 / actual_transaction_2019)
            
            const actual_smac_sales = buGroupDataMap?.get(higher_mbu)?.total_smac_sales || 0
            const last_year_smac_sales = buGroupDataLastYearMap?.get(higher_mbu)?.total_smac_sales || 0
            const actual_smac_sales_2019 = buGroupData2019Map?.get(higher_mbu)?.total_smac_sales || 0

            const actual_net_selling_area = buGroupDataMap?.get(higher_mbu)?.total_net_selling_area || 0
            const last_year_net_selling_area = buGroupDataLastYearMap?.get(higher_mbu)?.total_net_selling_area || 0
            const actual_net_selling_2019 = buGroupData2019Map?.get(higher_mbu)?.total_net_selling_area || 0

            const actual_space_nsa = (actual_sales / actual_net_selling_area)
            const last_year_actual_space_nsa = (last_year_sales / last_year_net_selling_area)
            const actual_space_nsa_2019 = (actual_sales_2019 / actual_net_selling_2019)

            return {
                higher_mbu,
                sales_percent_contribution: (actual_sales / total_sales) * 100,
                sales_actual_sales: actual_sales,
                sales_vs_last_year: (((actual_sales - last_year_sales) / last_year_sales) * 100) + 100,
                sales_vs_2019: (((actual_sales - actual_sales_2019) / actual_sales_2019) * 100) + 100,

                transaction_actual_sales: actual_transaction,
                transaction_vs_last_year: (((actual_transaction - last_year_transaction) / last_year_transaction) * 100) + 100,
                transaction_vs_2019: (((actual_transaction - actual_transaction_2019) / actual_transaction_2019) * 100) + 100,

                units_sold_actual_sales: actual_units_sold,
                units_sold_vs_last_year: (((actual_units_sold - last_year_units_sold) / last_year_units_sold) * 100) + 100,
                units_sold_vs_2019: (((actual_units_sold - actual_units_sold_2019) / actual_units_sold_2019) * 100) + 100,

                basket_size_actual_sales: actual_basket_size,
                basket_size_vs_last_year: (((actual_basket_size - last_year_basket_size) / last_year_basket_size) * 100) + 100,
                basket_size_vs_2019: (((actual_basket_size - actual_basket_size_2019) / actual_basket_size_2019) * 100) + 100,

                units_sold_per_transaction_actual_sales: unit_sold_per_txn,
                units_sold_per_transaction_vs_last_year: (((unit_sold_per_txn - ly_unit_sold_per_txn) / ly_unit_sold_per_txn) * 100) + 100,
                units_sold_per_transaction_vs_2019: (((unit_sold_per_txn - unit_sold_per_txn_2019) / unit_sold_per_txn_2019) * 100) + 100,
                
                smac_sales_actual_sales: actual_smac_sales,
                smac_sales_vs_last_year: (((actual_smac_sales - last_year_smac_sales) / last_year_smac_sales) * 100) + 100,
                smac_sales_vs_2019: (((actual_smac_sales - actual_smac_sales_2019) / actual_smac_sales_2019) * 100) + 100,

                space_nsa_actual_sales: actual_space_nsa,
                space_nsa_actual_sales_vs_last_year: (((actual_space_nsa - last_year_actual_space_nsa) / last_year_actual_space_nsa) * 100) + 100,
                space_nsa_actual_sales_vs_2019: (((actual_space_nsa - actual_space_nsa_2019) / actual_space_nsa_2019) * 100) + 100,

            };
        });

    }, [buGroupData, lastYearBUGroupData, data2019, total_sales, isLoading])

    return {
        finalBUGroupData,
        isLoading
    }
}

//BU Table Performance
export function useBusinessUnitPerformance({ params, }) {

    const { merchData, isLoading: isTotalSalesLoading } = useMerchData({
        params: {
            ...params,
        },
    })

    const total_sales = merchData?.[0]?.gross_sales_sum

    const { merchData: businessUnitData, lastYearMerchData: lastYearBUData, data2019, isLoading: isBuDataLoading } = useMerchData({
        params: {
            ...params,
            columns: 'standardized_business_unit',
            group_by: 'standardized_business_unit',
            limit: 1000,
        },
    })

    const { merchData: departmentData, lastYearMerchData: lastYearDeptData, data2019: deptData2019, isLoading: isDeptDataLoading } = useMerchData({
        params: {
            ...params,
            columns: 'standardized_business_unit,standardized_department',
            group_by: 'standardized_business_unit,standardized_department',
            limit: 1000,
        },
        aggregates: 'gross_sales_sum,sales_units_sum,dept_txn_count_sum,smac_sales_sum,net_selling_area_sum',
        view: 'dept_txns'
    })

    const { merchData: subDepartmentData, lastYearMerchData: lastYearSubDeptData, data2019: subDeptData2019, isLoading: isSubDeptDataLoading } = useMerchData({
        params: {
            ...params,
            columns: 'standardized_business_unit,standardized_department,standardized_subdept',
            group_by: 'standardized_business_unit,standardized_department,standardized_subdept',
            limit: 1000,
        },
        aggregates: 'gross_sales_sum,sales_units_sum,subdept_txn_count_sum,smac_sales_sum,net_selling_area_sum'
    })


    const isLoading = isBuDataLoading || isTotalSalesLoading || isSubDeptDataLoading || isDeptDataLoading

    const finalMerchData = useMemo(() => {
        if (isLoading) {
            return [];
        }

        const createDataMap = (data) => {
            return new Map((data || [])?.map(item => [item.standardized_business_unit, {
                total_actual_sale: item.gross_sales_sum,
                total_transaction: item.bu_txn_count_sum,
                total_unit_sold: item.sales_units_sum,
                total_basket_size: item.basket_size_plan_sum,
                total_smac_sales: item.smac_sales_sum, 
                total_net_selling_area: item.net_selling_area_sum
            }]));
        };

        const merchDataMap = createDataMap(businessUnitData);
        const merchDataLastYearMap = createDataMap(lastYearBUData);
        const merchData2019Map = createDataMap(data2019);

        const businessUnits = Array.from(new Set([...(businessUnitData || []), ...(lastYearBUData || []), ...(data2019 || [])].map(item => item.standardized_business_unit)));

        return businessUnits?.map(business_unit => {

            const actual_sales = merchDataMap?.get(business_unit)?.total_actual_sale || 0
            const last_year_sales = merchDataLastYearMap?.get(business_unit)?.total_actual_sale || 0
            const actual_sales_2019 = merchData2019Map?.get(business_unit)?.total_actual_sale || 0

            const actual_transaction = merchDataMap?.get(business_unit)?.total_transaction || 0
            const last_year_transaction = merchDataLastYearMap?.get(business_unit)?.total_transaction || 0
            const actual_transaction_2019 = merchData2019Map?.get(business_unit)?.total_transaction || 0

            const actual_units_sold = merchDataMap?.get(business_unit)?.total_unit_sold || 0
            const last_year_units_sold = merchDataLastYearMap?.get(business_unit)?.total_unit_sold || 0
            const actual_units_sold_2019 = merchData2019Map?.get(business_unit)?.total_unit_sold || 0

            const actual_basket_size = (actual_sales / actual_transaction)
            const last_year_basket_size = (last_year_sales / last_year_transaction)
            const actual_basket_size_2019 = (actual_sales_2019 / actual_transaction_2019)

            const unit_sold_per_txn = (actual_units_sold / actual_transaction)
            const ly_unit_sold_per_txn = (last_year_units_sold / last_year_transaction)
            const unit_sold_per_txn_2019 = (actual_units_sold_2019 / actual_transaction_2019)
            
            const actual_smac_sales = merchDataMap?.get(business_unit)?.total_smac_sales || 0
            const last_year_smac_sales = merchDataLastYearMap?.get(business_unit)?.total_smac_sales || 0
            const actual_smac_sales_2019 = merchData2019Map?.get(business_unit)?.total_smac_sales || 0

            const actual_net_selling_area = merchDataMap?.get(business_unit)?.total_net_selling_area || 0
            const last_year_net_selling_area = merchDataLastYearMap?.get(business_unit)?.total_net_selling_area || 0
            const actual_net_selling_2019 = merchData2019Map?.get(business_unit)?.total_net_selling_area || 0

            const actual_space_nsa = (actual_sales / actual_net_selling_area)
            const last_year_actual_space_nsa = (last_year_sales / last_year_net_selling_area)
            const actual_space_nsa_2019 = (actual_sales_2019 / actual_net_selling_2019)

            //Dept data
            const deptData = departmentData?.filter(item => item.standardized_business_unit === business_unit);

            const deptRow = deptData?.map(department => {
                const dept_actual_sales = department.gross_sales_sum || 0;
                const dept_last_year_sales = lastYearDeptData?.find(item => item.standardized_department === department.standardized_department)?.gross_sales_sum || 0;
                const dept_actual_sales_2019 = deptData2019?.find(item => item.standardized_department === department.standardized_department)?.gross_sales_sum || 0;

                const dept_actual_txn = department.dept_txn_count_sum || 0;
                const dept_ly_txn = lastYearDeptData?.find(item => item.standardized_department === department.standardized_department)?.dept_txn_count_sum || 0;
                const dept_txn_2019 = deptData2019?.find(item => item.standardized_department === department.standardized_department)?.dept_txn_count_sum || 0;

                const dept_actual_units_sold = department.sales_units_sum || 0;
                const dept_ly_units_sold = lastYearDeptData?.find(item => item.standardized_department === department.standardized_department)?.sales_units_sum || 0;
                const dept_units_sold_2019 = deptData2019?.find(item => item.standardized_department === department.standardized_department)?.sales_units_sum || 0;

                const dept_actual_basket_size = (dept_actual_sales / dept_actual_txn)
                const dept_ly_basket_size = (dept_last_year_sales / dept_ly_txn)
                const dept_basket_size_2019 = (dept_actual_sales_2019 / dept_txn_2019)

                const dept_unit_sold_per_txn = (dept_actual_units_sold / dept_actual_txn)
                const dept_ly_unit_sold_per_txn = (dept_ly_units_sold / dept_ly_txn)
                const dept_unit_sold_per_txn_2019 = (dept_units_sold_2019 / dept_txn_2019)
                
                const dept_actual_smac_sales = department.smac_sales_sum || 0;
                const dept_last_year_actual_smac_sales = lastYearDeptData?.find(item => item.standardized_department === department.standardized_department)?.smac_sales_sum || 0;
                const dept_actual_smac_sales_2019 = deptData2019?.find(item => item.standardized_department === department.standardized_department)?.smac_sales_sum || 0;

                const dept_actual_net_selling_area = department.net_selling_area_sum || 0
                const dept_last_year_actual_net_selling_area = lastYearDeptData?.find(item => item.standardized_department === department.standardized_department)?.net_selling_area_sum || 0;
                const dept_actual_net_selling_area_2019 = deptData2019?.find(item => item.standardized_department === department.standardized_department)?.net_selling_area_sum || 0;

                const dept_actual_space_nsa_sales = (dept_actual_sales / dept_actual_net_selling_area)
                const dept_last_year_actual_space_nsa_sales = (dept_last_year_sales / dept_last_year_actual_net_selling_area)
                const dept_actual_space_nsa_sales_2019 = (dept_actual_sales_2019 / dept_actual_net_selling_area_2019)


                //Subdept Data
                const subDeptData = subDepartmentData?.filter(
                    item => item.standardized_business_unit === business_unit &&
                        item.standardized_department === department.standardized_department
                )

                const subDeptRow = subDeptData?.map(subdepartment => {

                    const sub_dept_actual_sales = subdepartment.gross_sales_sum || 0;
                    const sub_dept_last_year_sales = lastYearSubDeptData?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.gross_sales_sum || 0;
                    const sub_dept_actual_sales_2019 = subDeptData2019?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.gross_sales_sum || 0;

                    const sub_dept_actual_txn = subdepartment.subdept_txn_count_sum || 0;
                    const sub_dept_ly_txn = lastYearSubDeptData?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.subdept_txn_count_sum || 0;
                    const sub_dept_txn_2019 = subDeptData2019?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.subdept_txn_count_sum || 0;

                    const sub_dept_actual_units_sold = subdepartment.sales_units_sum || 0;
                    const sub_dept_ly_units_sold = lastYearDeptData?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.sales_units_sum || 0;
                    const sub_dept_units_sold_2019 = deptData2019?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.sales_units_sum || 0;

                    const sub_dept_actual_basket_size = (sub_dept_actual_sales / sub_dept_actual_txn)
                    const sub_dept_ly_basket_size = (sub_dept_last_year_sales / sub_dept_ly_txn)
                    const sub_dept_basket_size_2019 = (sub_dept_actual_sales_2019 / sub_dept_txn_2019)

                    const sub_dept_unit_sold_per_txn = (sub_dept_actual_units_sold / sub_dept_actual_txn)
                    const sub_dept_ly_unit_sold_per_txn = (sub_dept_ly_units_sold / sub_dept_ly_txn)
                    const sub_dept_unit_sold_per_txn_2019 = (sub_dept_units_sold_2019 / sub_dept_txn_2019)
                    
                    const sub_dept_actual_smac_sales = subdepartment.smac_sales_sum || 0
                    const sub_dept_last_year_actual_smac_sales = lastYearDeptData?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.smac_sales_sum || 0;
                    const sub_dept_actual_smac_sales_2019 = deptData2019?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.smac_sales_sum || 0;

                    const sub_dept_actual_net_selling_area = subdepartment.net_selling_area_sum || 0
                    const sub_dept_last_year_actual_net_selling_area = lastYearDeptData?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.net_selling_area_sum || 0;
                    const sub_dept_actual_net_selling_area_2019 = deptData2019?.find(item => item.standardized_subdepartment === subdepartment.standardized_subdepartment)?.net_selling_area_sum || 0

                    const sub_dept_actual_space_nsa = (sub_dept_actual_sales / sub_dept_actual_net_selling_area)
                    const sub_dept_last_year_actual_space_nsa = (sub_dept_last_year_sales / sub_dept_last_year_actual_net_selling_area)
                    const sub_dept_actual_space_nsa_2019 = (sub_dept_actual_sales_2019 / sub_dept_actual_net_selling_area_2019)


                    return {
                        business_unit: subdepartment.standardized_subdept,
                        sales_percent_contribution: (sub_dept_actual_sales / total_sales) * 100,
                        sales_actual_sales: sub_dept_actual_sales,
                        sales_vs_last_year: (((sub_dept_actual_sales - sub_dept_last_year_sales) / sub_dept_last_year_sales) * 100) + 100,
                        sales_vs_2019: (((sub_dept_actual_sales - sub_dept_actual_sales_2019) / sub_dept_actual_sales_2019) * 100) + 100,

                        transaction_actual_sales: sub_dept_actual_txn,
                        transaction_vs_last_year: (((sub_dept_actual_txn - sub_dept_ly_txn) / sub_dept_ly_txn) * 100) + 100,
                        transaction_vs_2019: (((sub_dept_actual_txn - sub_dept_txn_2019) / sub_dept_txn_2019) * 100) + 100,

                        units_sold_actual_sales: sub_dept_actual_units_sold,
                        units_sold_vs_last_year: (((sub_dept_actual_units_sold - sub_dept_ly_units_sold) / sub_dept_ly_units_sold) * 100) + 100,
                        units_sold_vs_2019: (((sub_dept_actual_units_sold - sub_dept_units_sold_2019) / sub_dept_units_sold_2019) * 100) + 100,

                        basket_size_actual_sales: sub_dept_actual_basket_size,
                        basket_size_vs_last_year: (((sub_dept_actual_basket_size - sub_dept_ly_basket_size) / sub_dept_ly_basket_size) * 100) + 100,
                        basket_size_vs_2019: (((sub_dept_actual_basket_size - sub_dept_basket_size_2019) / sub_dept_basket_size_2019) * 100) + 100,

                        units_sold_per_transaction_actual_sales: sub_dept_unit_sold_per_txn,
                        units_sold_per_transaction_vs_last_year: (((sub_dept_unit_sold_per_txn - sub_dept_ly_unit_sold_per_txn) / sub_dept_ly_unit_sold_per_txn) * 100) + 100,
                        units_sold_per_transaction_vs_2019: (((sub_dept_unit_sold_per_txn - sub_dept_unit_sold_per_txn_2019) / sub_dept_unit_sold_per_txn_2019) * 100) + 100,
                                         
                        smac_sales_actual_sales: sub_dept_actual_smac_sales,
                        smac_sales_actual_sales_vs_last_year: (((sub_dept_actual_smac_sales - sub_dept_last_year_actual_smac_sales) / sub_dept_last_year_actual_smac_sales) * 100) + 100,
                        smac_sales_actual_sales_vs_2019: (((sub_dept_actual_smac_sales - sub_dept_actual_smac_sales_2019) / sub_dept_actual_smac_sales_2019) * 100) + 100,

                        space_nsa_actual_sales: sub_dept_actual_space_nsa,
                        space_nsa_actual_sales_vs_last_year: (((sub_dept_actual_space_nsa - sub_dept_last_year_actual_space_nsa) / sub_dept_last_year_actual_space_nsa) * 100) + 100,
                        space_nsa_actual_sales_vs_2019: (((sub_dept_actual_space_nsa - sub_dept_actual_space_nsa_2019) / sub_dept_actual_space_nsa_2019) * 100) + 100,

                    }
                })


                return {
                    business_unit: department.standardized_department,
                    sales_percent_contribution: (dept_actual_sales / total_sales) * 100,
                    sales_actual_sales: dept_actual_sales,
                    sales_vs_last_year: (((dept_actual_sales - dept_last_year_sales) / dept_last_year_sales) * 100) + 100,
                    sales_vs_2019: (((dept_actual_sales - dept_actual_sales_2019) / dept_actual_sales_2019) * 100) + 100,

                    transaction_actual_sales: dept_actual_txn,
                    transaction_vs_last_year: (((dept_actual_txn - dept_ly_txn) / dept_ly_txn) * 100) + 100,
                    transaction_vs_2019: (((dept_actual_txn - dept_txn_2019) / dept_txn_2019) * 100) + 100,

                    units_sold_actual_sales: dept_actual_units_sold,
                    units_sold_vs_last_year: (((dept_actual_units_sold - dept_ly_units_sold) / dept_ly_units_sold) * 100) + 100,
                    units_sold_vs_2019: (((dept_actual_units_sold - dept_units_sold_2019) / dept_units_sold_2019) * 100) + 100,

                    basket_size_actual_sales: dept_actual_basket_size,
                    basket_size_vs_last_year: (((dept_actual_basket_size - dept_ly_basket_size) / dept_ly_basket_size) * 100) + 100,
                    basket_size_vs_2019: (((dept_actual_basket_size - dept_basket_size_2019) / dept_basket_size_2019) * 100) + 100,

                    units_sold_per_transaction_actual_sales: dept_unit_sold_per_txn,
                    units_sold_per_transaction_vs_last_year: (((dept_unit_sold_per_txn - dept_ly_unit_sold_per_txn) / dept_ly_unit_sold_per_txn) * 100) + 100,
                    units_sold_per_transaction_vs_2019: (((dept_unit_sold_per_txn - dept_unit_sold_per_txn_2019) / dept_unit_sold_per_txn_2019) * 100) + 100,
                    
                    smac_sales_actual_sales: dept_actual_smac_sales,
                    smac_sales_actual_sales_vs_last_year: (((dept_actual_smac_sales - dept_last_year_actual_smac_sales) / dept_last_year_actual_smac_sales) * 100) + 100,
                    smac_sales_actual_sales_vs_2019: (((dept_actual_smac_sales - dept_actual_smac_sales_2019) / dept_actual_smac_sales_2019) * 100) + 100,

                    space_nsa_actual_sales: dept_actual_space_nsa_sales,
                    space_nsa_actual_sales_vs_last_year: (((dept_actual_space_nsa_sales - dept_last_year_actual_space_nsa_sales) / dept_last_year_actual_space_nsa_sales) * 100) + 100,
                    space_nsa_actual_sales_vs_2019: (((dept_actual_space_nsa_sales - dept_actual_space_nsa_sales_2019) / dept_actual_space_nsa_sales_2019) * 100) + 100,

                    subRows: subDeptRow

                };
            });

            //BU Data
            return {
                business_unit,
                sales_percent_contribution: (actual_sales / total_sales) * 100,
                sales_actual_sales: actual_sales,
                sales_vs_last_year: (((actual_sales - last_year_sales) / last_year_sales) * 100) + 100,
                sales_vs_2019: (((actual_sales - actual_sales_2019) / actual_sales_2019) * 100) + 100,

                transaction_actual_sales: actual_transaction,
                transaction_vs_last_year: (((actual_transaction - last_year_transaction) / last_year_transaction) * 100) + 100,
                transaction_vs_2019: (((actual_transaction - actual_transaction_2019) / actual_transaction_2019) * 100) + 100,

                units_sold_actual_sales: actual_units_sold,
                units_sold_vs_last_year: (((actual_units_sold - last_year_units_sold) / last_year_units_sold) * 100) + 100,
                units_sold_vs_2019: (((actual_units_sold - actual_units_sold_2019) / actual_units_sold_2019) * 100) + 100,

                basket_size_actual_sales: actual_basket_size,
                basket_size_vs_last_year: (((actual_basket_size - last_year_basket_size) / last_year_basket_size) * 100) + 100,
                basket_size_vs_2019: (((actual_basket_size - actual_basket_size_2019) / actual_basket_size_2019) * 100) + 100,

                units_sold_per_transaction_actual_sales: (actual_units_sold / actual_transaction),
                units_sold_per_transaction_vs_last_year: (((unit_sold_per_txn - ly_unit_sold_per_txn) / ly_unit_sold_per_txn) * 100) + 100,
                units_sold_per_transaction_vs_2019: (((unit_sold_per_txn - unit_sold_per_txn_2019) / unit_sold_per_txn_2019) * 100) + 100,
                
                smac_sales_actual_sales: actual_smac_sales,
                smac_sales_actual_sales_vs_last_year: (((actual_smac_sales - last_year_smac_sales) / last_year_smac_sales) * 100) + 100,
                smac_sales_actual_sales_vs_2019: (((actual_smac_sales - actual_smac_sales_2019) / actual_smac_sales_2019) * 100) + 100,

                space_nsa_actual_sales: actual_space_nsa,
                space_nsa_actual_sales_vs_last_year: (((actual_space_nsa - last_year_actual_space_nsa) / last_year_actual_space_nsa) * 100) + 100,
                space_nsa_actual_sales_vs_2019: (((actual_space_nsa - actual_space_nsa_2019) / actual_space_nsa_2019) * 100) + 100,

                subRows: deptRow

            };
        });
    }, [businessUnitData, data2019, isLoading, lastYearBUData, total_sales, deptData2019, subDepartmentData, departmentData, subDeptData2019, lastYearDeptData, lastYearSubDeptData]);

    return {
        finalMerchData,
        isLoading
    }
}

//WIP: TopLineMetric Hook
export function useTopLineMetricsData({ params }) {

    const { merchData, lastYearMerchData, isLoading } = useMerchData({
        params: {
            ...params,
        },
    })

    const finalData = useMemo(() => {
        if (isLoading || !lastYearMerchData) {
            return [];
        }

        //Add vs last year data
        const total_sales = merchData[0]?.gross_sales_sum || 0
        const ly_sales = lastYearMerchData[0]?.gross_sales_sum || 0
        const vs_ly_sales = (total_sales / ly_sales) * 100 || 0

        const total_txn = merchData[0]?.bu_txn_count_sum || 0
        const ly_txn = lastYearMerchData[0]?.bu_txn_count_sum || 0
        const vs_ly_txn = (total_txn / ly_txn) * 100 || 0

        const total_units_sold = merchData[0]?.sales_units_sum || 0
        const ly_units_sold = lastYearMerchData[0]?.sales_units_sum || 0
        const vs_ly_units_sold = (total_units_sold / ly_units_sold) * 100 || 0

        const total_basket_size = (total_sales / total_txn) || 0
        const ly_basket_size = (ly_sales / ly_txn) || 0
        const vs_ly_basket_size = (total_basket_size / ly_basket_size) * 100

        const total_units_sold_per_txn = (total_units_sold / total_txn) || 0
        const ly_units_sold_per_txn = (ly_units_sold / ly_txn) || 0
        const vs_ly_units_sold_per_txn = (total_units_sold_per_txn / ly_units_sold_per_txn) * 100 || 0

        return [
            {
                icon: '',
                label: 'Total Sales',
                comparisonLabel: 'vs Last Year',
                value: total_sales,
                iconPath: '/svg/sales.svg',
                prefix: '₱ '
            },
            {
                icon: '',
                label: 'Total Transaction',
                comparisonLabel: 'vs Last Year',
                value: total_txn,
                iconPath: '/svg/transactions.svg'
            },
            {
                icon: '',
                label: 'Total Unit Sold',
                comparisonLabel: 'vs Last Year',
                value: total_units_sold,
                iconPath: '/svg/unit_sold.svg'
            },
            {
                icon: '',
                label: 'Basket Size',
                comparisonLabel: 'vs Last Year',
                value: total_basket_size,
                iconPath: '/svg/basket_size.svg',
                prefix: '₱ '
            },
            {
                icon: '',
                label: 'Unit Sold Per Transaction',
                comparisonLabel: 'vs Last Year',
                value: total_units_sold_per_txn,
                iconPath: '/svg/unit_sold_per_transactions.svg'
            }
        ];
    }, [merchData, lastYearMerchData, isLoading]);

    return {
        finalData,
        isLoading
    };
}

// WIP:Overview Trendline Hook
export function useOverviewTrendlineData({ params, title }) {

    const { merchData, isLoading } = useMerchDataV2({
        params: {
            ...params,
            columns: 'year',
            group_by: 'year'
        },
    })

    //Add indicator data 
    const finalData = merchData?.map((item) => {

        let val;

        if (title === 'Total Sales') {
            val = item.gross_sales_sum
        } else if (title === 'Total Transaction') {
            val = item.bu_txn_count_sum
        }
        else if (title === 'Basket Size') {
            val = (item.gross_sales_sum / item.bu_txn_count_sum)
        }
        else if (title === 'Unit Sold per Transaction') {
            val = (item.sales_units_sum / item.bu_txn_count_sum)
        }

        return {
            year: item.year,
            value: val,
            indicator: 0,
        }
    })

    return {
        finalData,
        isLoading
    }
}


export function useRankByData({ params, rankBy }) {

    const columnMapping = {
        sales: 'gross_sales_sum',
        transactions: 'bu_txn_count_sum',
        unit_sold: 'sales_units_sum',
        basket_size: 'Basket Size',
        at_volume: 'AT Volume'
    };

    const columnMap = columnMapping[rankBy];

    const { merchData: buData, lastYearMerchData: lyBuData, isLoading: buIsLoading } = useMerchData({
        params: {
            ...params,
            columns: 'standardized_business_unit',
            group_by: 'standardized_business_unit',
        },
    });

    const { merchData: clusterData, lastYearMerchData: lyClusterData, isLoading: clusterIsLoading } = useMerchData({
        params: {
            ...params,
            columns: 'cluster',
            group_by: 'cluster',
        },
    })

    const { merchData: zoneData, lastYearMerchData: lyZoneData, isLoading: zoneIsLoading } = useMerchData({
        params: {
            ...params,
            columns: 'zone',
            group_by: 'zone',
        },
    })

    const { merchData: storeData, lastYearMerchData: lyStoreData, isLoading: storeIsLoading } = useMerchData({
        params: {
            ...params,
            columns: 'store',
            group_by: 'store',
        },
    })

    const isLoading = buIsLoading || clusterIsLoading || zoneIsLoading || storeIsLoading

    const finalData = useMemo(() => {

        if (isLoading) {
            return [];
        }

        const createDataMap = (data, column) => {
            if (rankBy === 'sales' || rankBy === 'transactions' || rankBy === 'unit_sold') {
                return new Map((data || []).map(item => [item?.[column], {
                    total_value: item?.[columnMap],
                }]));
            }

            else if (rankBy === 'basket_size') {
                return new Map((data || []).map(item => [item?.[column], {
                    total_value: (item?.gross_sales_sum / item?.bu_txn_count_sum)
                }]))
            }

            else if (rankBy === 'at_volume') {
                return new Map((data || []).map(item => [item?.[column], {
                    total_value: (item?.sales_units_sum / item?.bu_txn_count_sum)
                }]));
            }
        };

        const buDataMap = createDataMap(buData, 'standardized_business_unit');
        const lyBuDataMap = createDataMap(lyBuData, 'standardized_business_unit');

        const clusterDataMap = createDataMap(clusterData, 'cluster');
        const lyClusterDataMap = createDataMap(lyClusterData, 'cluster');

        const zoneDataMap = createDataMap(zoneData, 'zone');
        const lyZoneDataMap = createDataMap(lyZoneData, 'zone');

        const storeDataMap = createDataMap(storeData, 'store');
        const lyStoreDataMap = createDataMap(lyStoreData, 'store');


        const businessUnits = Array.from(new Set([...(buData || []), ...(lyBuData || [])].map(item => item.standardized_business_unit)));
        const clusters = Array.from(new Set([...(clusterData || []), ...(lyClusterData || [])].map(item => item.cluster)));
        const zones = Array.from(new Set([...(zoneData || []), ...(lyZoneData || [])].map(item => item.zone)));
        const stores = Array.from(new Set([...(storeData || []), ...(lyStoreData || [])].map(item => item.store)));

        const buFinalData = businessUnits.map(bu => ({
            business_unit: bu,
            total_value: buDataMap.get(bu)?.total_value,
            vs_last_year: (buDataMap.get(bu)?.total_value / lyBuDataMap.get(bu)?.total_value) * 100

        }));

        const clusterFinalData = clusters.map(cluster => ({
            cluster,
            total_value: clusterDataMap.get(cluster)?.total_value,
            vs_last_year: (clusterDataMap.get(cluster)?.total_value / lyClusterDataMap.get(cluster)?.total_value) * 100
        }));

        const zoneFinalData = zones.map(zone => ({
            zone,
            total_value: zoneDataMap.get(zone)?.total_value,
            vs_last_year: (zoneDataMap.get(zone)?.total_value / lyZoneDataMap.get(zone)?.total_value) * 100
        }));

        const storeFinalData = stores.map(store => ({
            store,
            total_value: storeDataMap.get(store)?.total_value,
            vs_last_year: (storeDataMap.get(store)?.total_value / lyStoreDataMap.get(store)?.total_value) * 100
        }));


        return [...buFinalData, ...clusterFinalData, ...zoneFinalData, ...storeFinalData];

    }, [rankBy, buData, isLoading, lyBuData, clusterData, columnMap, lyClusterData]);


    return { finalData, isLoading };
}

export function useAllAvailableBusinessUnits({ params }) {
    const { merchData: businessUnit, isLoading } = useMerchData({
        params: {
            ...params,
            columns: 'standardized_business_unit',
            group_by: 'standardized_business_unit',
            limit: 1000,
        },
    })

    const businessUnits = businessUnit?.map(({ standardized_business_unit }) => ({ standardized_business_unit }))


    return { businessUnits, isLoading }
}

export function useAllAvailableBusinessUnitsGroup({ params }) {
    const { merchData: buGroup, isLoading } = useMerchData({
        params: {
            ...params,
            columns: 'sbu_higher_mbu',
            group_by: 'sbu_higher_mbu',
            limit: 1000,
        },
    })

    const buGroups = buGroup?.map(({ sbu_higher_mbu }) => ({ sbu_higher_mbu }))

    return { buGroups, isLoading }
}

export function useAllAvailableCluster({ params }) {

    const { merchData: cluster, isLoading } = useMerchData({
        params: {
            ...params,
            columns: 'cluster',
            group_by: 'cluster',
            limit: 1000,
        },
    })

    const clusters = cluster?.map(({ cluster }) => ({ cluster }))


    return { clusters, isLoading }
}


export function useAllAvailableZone({ params }) {
    const { merchData: zone, isLoading } = useMerchData({
        params: {
            ...params,
            columns: 'zone',
            group_by: 'zone',
            limit: 1000,
        },
    })

    const zones = zone?.map(({ zone }) => ({ zone }))


    return { zones, isLoading }
}

export function useAllAvailableBranch({ params }) {
    const { merchData: store, isLoading } = useMerchData({
        params: {
            ...params,
            columns: 'store,store_id',
            group_by: 'store,store_id',
            limit: 1000,
        },
    })

    const branches = store?.map(({ store, store_id }) => ({ store, store_id }))

    return { branches, isLoading }
}
