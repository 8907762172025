import React from 'react';
import { useSelectorContext, useSelectorState, useSelectorValue } from '../../../common/providers/selectorProvider';
import ToggleSelector from '../../../common/presentation/selectors/toggleSelector';
import { SEGMENT_TYPE_RFM, SEGMENT_TYPE_CARD_TYPE, SEGMENT_TYPE_DEMOGRAPHICS } from '../../../CustomerAnalytics/constants';
import { DIMENSION_CARD_TYPE, DIMENSION_GENERATION } from '../../hooks';

const TOGGLE_OPTIONS = [
    { label: "Card Type", value: DIMENSION_CARD_TYPE },
    { label: "Demographics", value: DIMENSION_GENERATION },
    { label: "RFM", value: SEGMENT_TYPE_RFM },
]

export default function SegmentToggleSelector() {
    const [segmentType, setSegmentType] = useSelectorState('segment_type', TOGGLE_OPTIONS[1].value)  

    // defaults
    React.useEffect(() => {
        if (!segmentType) {
            setSegmentType(TOGGLE_OPTIONS[1].value)
        }
    }, [segmentType]);

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setSegmentType(val);
        }
    };

    return <ToggleSelector
        options={TOGGLE_OPTIONS}
        value={segmentType}
        onChange={onTogglePeriodChange}
        additionalStyles={{ textTransform: 'none'}}
    />
}