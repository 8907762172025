import React, { useContext, useMemo} from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { getSelectedOption } from '../../../../common/utils';



export default function FilterMetric({ filters, onDropdownChange }) {
    const windowSize = useContext(WindowSizeContext);

    const options = [
            { value: 'actual_sales', label: 'Sales' },
            { value: 'actual_transactions', label: 'Transactions' },
            { value: 'actual_basket_size', label: 'Basket Size' },
            { value: 'actual_smac_sales', label: 'SMAC Sales' },
            { value: 'actual_space_nsa', label: 'Space Productivity NSA' },
            { value: 'actual_unit_sold', label: 'Units Sold' },
            { value: 'actual_unit_sold_per_txn', label: 'Units Sold Per Transaction' }
        ]
       
    return (
        <>
            <ConditionalSelectComponent
                    id="metric_filter"
                    labelId="metric_filter-label"
                    options={options}
                    label="Metric"
                    value={filters ? (windowSize.isOnMobile ? filters?.metric_column : `Metric: ${getSelectedOption(filters?.metric_column, options)?.label}`) : '' &&  null}
                    onSelectChange={(event,newValue)=>{
                        windowSize.isOnMobile ? onDropdownChange(event.target.value, 'metric_column')
                        : onDropdownChange(newValue?.value, 'metric_column')  
                    }}
                    endAdornment={false}
                    // isLoading={isLoading}
                    clearSelected={() => onDropdownChange('','metric_column')}
                    disableClearable={true}
            />
        </>
    );
}