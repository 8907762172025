import React, { useContext, useMemo, useState } from 'react';
import ViewBySelector from "../Filters/ViewBySelector";
import { Grid, Box, IconButton, Typography, Collapse, Link } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import TableComponentV2 from '../../../../common/components/TableComponentV2';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { useBUGrouping, useDynamicGraphInTableDataL3 } from '../hooks';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    convertNumberToLocaleString,
    convertToValidNumber,
    sortArrayBasedOnGivenArray,
    sortByOrder
} from '../../../../common/utils';
import { checkIfCompletePeriod, getDaysDifference, getPast12monthsL3, groupArrayBy } from '../util';
import moment from 'moment';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import BarTrends from '../BarTends';
import CollapsibleComponent from '../../../../common/components/CollapsibleComponent';
import BarIndicator from '../../common/presentation/barIndicator';
import { BU_FASHION_STRING, BU_KIDS_STRING, BU_HOME_STRING, BU_BEAUTY_STRING, BU_OTHERS_STRING, useStorePerformancePerGroupDataL3, useAvailableMaxDateL3, useAvailableDateRangeL3 } from '../DataProvider/hooks'
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import { getMetricColumnHeader, getBarTrendsColumnHeader } from '../DataProvider/util';
import { useEnvironmentSetting } from "../../../../common/providers/EnvironmentSettingsProvider";
import { ENABLE_TSS_BUSINESS_UNIT_VIEW, ENABLE_TSS_ZONE_VIEW } from "../../../../Constants/settings";
import { getParamsBasedOnPeriod } from '../../Customers/util';
import { useSelectorValue } from "../../common/providers/selectorProvider";
import { NUMERIC_VALUE_PROPS_BY_METRIC } from "../constants";


const headerLabelByViewBy = {
    'channel': 'Channel',
    'business_unit': 'Business Unit',
    'zone': 'Zone',
}
const orderArrayByViewBy = {
    'channel': ['In Store', 'Personal Shopper', 'Online'],
    'business_unit': ['Fashion', 'Home', 'Kids', 'Beauty', 'Kultura', 'Others'],
    'zone': ['NCR 1', 'NCR 2', 'NCR 3', 'North Luzon 1', 'North Luzon 2', 'North Luzon 3', 'South Luzon 1', 'South Luzon 2', 'Visayas 1', 'Visayas 2', 'Mindanao', 'Online']
}

export default function SalesPerformancePerGroup(
    {
        params, filters, selectedRows, setSelectedRows,
        enableZoneLink = true, enableBULink = true,
        componentProps = {}
    },
) {
    const windowSize = useContext(WindowSizeContext);

    const showZoneView = useEnvironmentSetting(ENABLE_TSS_ZONE_VIEW)
    const showBUView = useEnvironmentSetting(ENABLE_TSS_BUSINESS_UNIT_VIEW)

    const selectedViewBy = useSelectorValue('viewBy')
    const selectedMetric = useSelectorValue('metric')
    const period = useSelectorValue('period')

    const { buGroupings, isLoading } = useBUGrouping(); //get list of all distinct higher_mbus and business_units
    const HIGHER_MBU_COLS = !isLoading ? Object.keys(buGroupings) : []; //get list of all distinct higher_mbus

    const enableRowSelection = selectedMetric !== 'actual_space_nsa'
    
    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';

    const [isExpand, setExpand] = useState(true);
    const absoluteHeaderLabel = headerLabelByViewBy[selectedViewBy];
    const orderArray = orderArrayByViewBy[selectedViewBy] || null;

    const metricHeaderLabel = getMetricColumnHeader(period, selectedMetric);
    const barTrendsColumnHeaders = getBarTrendsColumnHeader(selectedMetric);

    const selectedViewByToL3ColName = {
        channel: "cm_channel_desc",
        business_unit: "pm_business_unit_desc_standardized",
        // 'business_unit': 'pm_business_unit_desc_standardized',
        zone: "store_zone",
    };

    const selectedViewByL3 = selectedViewByToL3ColName[selectedViewBy];

    function onSelectRow(row) {
        const rowValue = row[selectedViewByL3];  // get the value of the selected row
        const isChannel = selectedViewBy === 'channel';

        const isBusinessUnit = selectedViewBy === 'business_unit';
        const isHigherMBUSelected = HIGHER_MBU_COLS?.includes(rowValue)

        
        if (selectedRows?.includes(rowValue)) {
            //unselecting of row
            if (isBusinessUnit) {
                if (isHigherMBUSelected) {
                    // If a higher_mbu is deselected, all its corresponding business_units are removed as well
                    const higherMBU = rowValue
                    const correspondingBUs = buGroupings[row[selectedViewByL3]] || []; // get business_units values of the higher_mbu
                    const rowsToRemove = [higherMBU, ...correspondingBUs] // higher_mbu and its corresponding business_units should be removed
                    setSelectedRows(selectedRows?.filter(val => !rowsToRemove.includes(val)));
                } else {
                    // If a business_unit is deselected and its higher_mbu is within the selectedRows,
                    // remove said higher_mbu and the business_unit specifically deselected as well
                    const higherMBU = HIGHER_MBU_COLS.find(key => buGroupings[key].includes(rowValue));
                    const rowsToRemove = higherMBU ? [higherMBU, row[selectedViewByL3]] : [row[selectedViewByL3]];
                    setSelectedRows(selectedRows?.filter(val => !rowsToRemove.includes(val)));
                }
            } else {
                setSelectedRows(selectedRows?.filter(val => val !== rowValue))
            }
        } else {
            if (isChannel) {
                // gift registry and other values for channel are treated separately even if they are in the same column
                const giftRegistryColumns = ['Gift Registry'];
                const isSelectedRowGiftRegistry = giftRegistryColumns.includes(rowValue);

                // thus, if a gift registry value is selected, all other values in the channel column are removed
                // and vice versa
                const filteredRows = selectedRows.filter(row =>
                    isSelectedRowGiftRegistry ? giftRegistryColumns.includes(row) : !giftRegistryColumns.includes(row)
                );

                setSelectedRows([...filteredRows, rowValue]);
            } else if (isBusinessUnit) {
                if (isHigherMBUSelected) {
                    // along with the higher_mbu value, it will be added alongside the corresponding BUs that the selected higher_mbu has
                    const higherMBU = rowValue;
                    const existingSelectedBUs = selectedRows.filter(val => buGroupings[higherMBU]?.includes(val)); // get the corresponding BUs that's already in selectedRows
                    const newCorrespondingBUs = buGroupings[higherMBU]?.filter(childBU => !existingSelectedBUs.includes(childBU)) || []; // get only the corresponding BUs not in selectedRows yet
                    const rowsToAdd = [higherMBU, ...newCorrespondingBUs]; // have the higher_mbu and only the newly selected business_units be added
                    setSelectedRows([...(selectedRows || []), ...rowsToAdd]);
                } else {
                    // if the business_unit is not a higher_mbu value, proceed to input it within the selectedRows
                    const businessUnit = rowValue;
                    const higherMBU = HIGHER_MBU_COLS.find(key => buGroupings[key].includes(businessUnit))
                    // checksif adding that business_unit within the selectedRows means that all of it's higher_mbu values will be in the selectedRows,
                    const resultingSelectedRows = [...selectedRows, businessUnit]
                    const isAllBUGroupSelected = buGroupings[higherMBU]?.every(childBU => resultingSelectedRows.includes(childBU));
                    // if so, add higher_mbu's value to the selectedRows array as well; if not, add only the business_unit in selectedRows
                    const rowsToAdd = isAllBUGroupSelected ? [higherMBU, businessUnit] : [businessUnit];
                    setSelectedRows([...(selectedRows || []), ...rowsToAdd]);
                }
            }
            else {
                setSelectedRows([...(selectedRows || []), row[selectedViewByL3]])
            }
        }
    }

    const { data: maxDate, isLoading: isLoadingMaxdate } = useAvailableMaxDateL3();
    const paramsBasedOnPeriod = getParamsBasedOnPeriod(isMTD, isQoQ, params);
    const group_by_params_l3 = selectedViewByL3 // selectedViewBy === 'business_unit' ? 'pm_business_unit_desc_standardized,sbu_bu_group' : selectedViewByL3

    // const { monthYear, ...dateParamsForTrendBar } = getPast12months(params?.month?.[0], params?.year)
    const { monthYear, ...dateParamsForTrendBar } = getPast12monthsL3(params?.month?.[0], params?.year, maxDate)

    // const { data: graphData, isLoading: isGraphDataLoading } = useDynamicGraphInTableData({
    //     params: {
    //         ...params,
    //         ...dateParamsForTrendBar,
    //         group_by: selectedViewBy
    //     }
    // });

    const { data: graphDataL3, isLoading: isGraphDataL3Loading } = useDynamicGraphInTableDataL3({
        params: {
            ...params,
            ...dateParamsForTrendBar,
            group_by: selectedViewByL3,
            columns: selectedViewByL3,
        },
        queryOptions: {
            hasTranDateLteGte: dateParamsForTrendBar,
        }
    });

    // const { data: grGraphData, isLoading: isGRGraphDataLoading } = useDynamicGraphInTableData({
    //     params: {
    //         ...params,
    //         ...dateParamsForTrendBar,
    //         group_by: selectedViewBy,
    //         aggregates: ["gr_txn_count_sum", "gr_gross_sales_sum", "gross_sales_sum", "egr_plan_sum", "gr_smac_sales_sum"].join(","),
    //     }
    // });

    const { data: grGraphDataL3, isLoading: isGRGraphDataL3Loading } = useDynamicGraphInTableDataL3({
        params: {
            ...params,
            ...dateParamsForTrendBar,
            group_by: null,
            columns: null,
            aggregates: ["pos_sku_gross_sales_sum", "header_tran_key_count_distinct", "pos_sku_sale_tot_qty_sum", "pos_sku_smac_sales_sum", "egr_sales_plan_sum"],
            header_gr_tag__is_true: 1,
        },
        queryOptions: {
            hasTranDateLteGte: dateParamsForTrendBar,
        }
    });

    // const updatedGraphData = useMemo(() => {
    //     if (selectedViewBy === 'channel' && graphData && grGraphData[undefined]) {
    //         return {
    //             ...graphData,
    //             'Gift Registry': grGraphData[undefined],
    //         };
    //     } else {
    //         return graphData || [];
    //     }
    // }, [graphData, grGraphData, selectedViewBy]);

    const updatedGraphDataL3 = useMemo(() => {
        if (selectedViewBy === 'channel' && graphDataL3 && grGraphDataL3[undefined]) {
            return {
                ...graphDataL3,
                'Gift Registry': grGraphDataL3[undefined],
            };
        } else {
            return graphDataL3 || [];
        }
    }, [graphDataL3, grGraphDataL3, selectedViewBy]);

    // const { data: buGraphData, isLoading: isBUGraphDataLoading } = useDynamicGraphInTableData({
    //     params: {
    //         ...params,
    //         ...dateParamsForTrendBar,
    //         group_by: 'higher_mbu',
    //         higher_mbu: [BU_FASHION_STRING, BU_KIDS_STRING, BU_HOME_STRING, BU_BEAUTY_STRING, BU_OTHERS_STRING],
    //     }
    // });

    const { data: buGraphDataL3, isLoading: isBUGraphDataL3Loading } = useDynamicGraphInTableDataL3({
        params: {
            ...params,
            ...dateParamsForTrendBar,
            group_by: 'sbu_bu_group',
            sbu_bu_group: [BU_FASHION_STRING, BU_KIDS_STRING, BU_HOME_STRING, BU_BEAUTY_STRING, BU_OTHERS_STRING],
        },
        queryOptions: {
            hasTranDateLteGte: dateParamsForTrendBar,
        }
    });
    
    const combinedData = useMemo(() => {
        return {
            ...updatedGraphDataL3,
            ...(selectedViewBy === 'business_unit' ? buGraphDataL3 : {})
        }
    }, [updatedGraphDataL3, buGraphDataL3, selectedViewBy])

    const grMaxBarTrendValues = useMemo(() => {
        const allValues = grGraphDataL3 && grGraphDataL3[undefined] 
            ? Object.values(grGraphDataL3[undefined]).reduce((acc, val) => { return acc.concat(val) }, []).filter(data => monthYear?.includes(data?.year_month))  // filter only the selected month and year
            : []

        if(allValues.length === 0) {
            return null;
        }

        return allValues.reduce((acc, row) => {
            // this is hack. we are forcefully getting math max for all keys of each value
            // and then getting the max of all those values
            Object.keys(row).forEach((key) => {
                const val = parseFloat(row[key])
                if (!isNaN(val)) {
                    if (acc[key]) {
                        acc[key] = Math.max(acc[key], val)
                    } else {
                        acc[key] = val
                    }
                }
            })
            return acc
        }, {})
    }, [grGraphDataL3, monthYear])

    const maxBarTrendValues = useMemo(() => {
        const allValues = Object.values(combinedData)
            .reduce((acc, val) => {
                return acc.concat(val)
            }, [])
            .filter(data => monthYear?.includes(data?.year_month))  // filter only the selected month and year

        return allValues.reduce((acc, row) => {
            // this is hack. we are forcefully getting math max for all keys of each value
            // and then getting the max of all those values
            Object.keys(row).forEach((key) => {
                const val = parseFloat(row[key])
                if (!isNaN(val)) {
                    if (acc[key]) {
                        acc[key] = Math.max(acc[key], val)
                    } else {
                        acc[key] = val
                    }
                }
            })
            return acc
        }, {})
    }, [combinedData, monthYear])

    // const group_by_params = selectedViewBy === 'business_unit' ? 'business_unit,higher_mbu' : selectedViewBy
    // const { perGroupData, isLoading: isLoadingPerGroup } = useStorePerformancePerGroupData({
    //     params: {
    //         ...paramsBasedOnPeriod,
    //         group_by: group_by_params,
    //         columns: group_by_params,
    //         metric_column: selectedMetric ? selectedMetric : 'actual_sales',
    //     }
    // });

    const { perGroupData: perGroupDataL3, isLoading: isLoadingPerGroupL3 } = useStorePerformancePerGroupDataL3({
        params: {
            ...paramsBasedOnPeriod,
            group_by: group_by_params_l3,
            columns: group_by_params_l3,
            metric_column: selectedMetric ? selectedMetric : 'actual_sales',
        }
    });

    const displayMapping = {
        'business_unit': 'business_unit', // originally 'bu_group_3'
    }
    const displayKey = selectedViewByL3 || displayMapping[selectedViewBy] || selectedViewBy 
    const newPerformancePerGroupData = React.useMemo(() => {
        // special case for business unit; this is for bu group chain view, where we only need to show the subrows
        const groupedData = (displayKey === "pm_business_unit_desc_standardized" && params.hasOwnProperty('sbu_bu_group')) ?
            groupArrayBy(perGroupDataL3.find(row => row[displayKey] === params.sbu_bu_group)?.subRows, displayKey) :
            groupArrayBy(perGroupDataL3, displayKey)  // converts array to object with key as displayKey

        const sumKeys = ['metricTotal', 'metricPlanTotal', 'contribution', 'metricTotal','vsLY', 'vsPlan']
        return Object.keys(groupedData).map((key) => {
            if (groupedData[key].length > 1) {
                return {
                    ...groupedData[key][0],

                    [displayKey]: key,
                    ...groupedData[key].reduce((acc, val) => {
                        sumKeys.forEach((key) => {
                            acc[key] = (acc[key] || 0) + (parseFloat(val[key]) || 0)
                        })
                        return acc
                    }, {}),
                    subRows: groupedData[key].map((row) => {
                        return {
                            ...row,
                            [displayKey]: row[selectedViewByL3],
                        }
                    })
                }
            } else {
                return groupedData[key][0]
            }
        })
    }, [perGroupDataL3, selectedViewByL3, displayKey, params])

    const isGiftRegistry = selectedViewBy === 'channel';
    const CHANNEL_COLS = ['Gift Registry']
    const { data: dateRange, isLoading: isLoadingDateRange } = useAvailableDateRangeL3()

    let paramsPeriod, periodStartDate, periodEndDate;
    if (params?.month?.[0] && params?.year) {
        paramsPeriod = `${params?.year}-${params?.month?.[0].toString().padStart(2, '0')}-01`;
    } else {
        paramsPeriod = moment().format('YYYY-MM-DD');
    }

    if (isQoQ) {
        periodStartDate = moment(paramsPeriod, 'YYYY-MM-DD').startOf('quarter').format('YYYY-MM-DD');
        periodEndDate = moment(periodStartDate).endOf('quarter').format('YYYY-MM-DD');
    } else if (isMTD) {
        periodStartDate = moment(paramsPeriod).startOf('month').format('YYYY-MM-DD');
        periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
    } else {
        periodStartDate = moment(paramsPeriod).startOf('year').format('YYYY-MM-DD');
        periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
    }

    const maxAvailableDate = !isLoadingDateRange && moment(dateRange?.max).format('YYYY-MM-DD');
    const isMonthNotEqualToMax = params?.month?.[0] !== moment(maxAvailableDate).month() + 1; //if selected params is not equal to max date.
    const maxDaysInPeriod = isMonthNotEqualToMax ? periodEndDate : maxAvailableDate;
    const numberOfDaysWithinThePeriod = getDaysDifference(periodStartDate, periodEndDate);
    const maxAvailableDaystheSelectedMonth = getDaysDifference(periodStartDate, maxDaysInPeriod)
    const getRunningDailyTarget = (target) => {
        return (target / numberOfDaysWithinThePeriod) * maxAvailableDaystheSelectedMonth
    };

    const isBusinessUnitTab = selectedViewBy === 'business_unit'
    const isZoneTab = selectedViewBy === 'zone'

    const isCompletePeriod = checkIfCompletePeriod(params, maxDate, isQoQ)
    const hasLessColumns = !isCompletePeriod && (isMTD || isQoQ);

    const checkIfHasBarColor = (viewBy, group, metric) => {
        const isChannelTab = viewBy === "cm_channel_desc"
        const isInStoreRow = isChannelTab && group === "In Store"
        const isChannelTabNoPlan = !isInStoreRow && metric !== 'actual_sales'
        
        if(isChannelTab && !isInStoreRow && isChannelTabNoPlan) {
            return false;
        }

        const isBUTab = viewBy === 'pm_business_unit_desc_standardized'
        const buTabNoPlanMetrics = ['actual_transactions', 'actual_basket_size', 'actual_smac_sales', 'actual_units_sold', 'actual_units_sold_per_txn']
        const isBUTabNoPlan = isBUTab && buTabNoPlanMetrics.includes(metric)

        if(isBUTabNoPlan) {
            return false;
        }

        const isZoneTab = viewBy === 'store_zone'
        const zoneTabNoPlanMetrics = ['actual_catchment_rate', 'actual_conversion_rate', 'actual_mall_foot_traffic', 'actual_sm_store_foot_traffic']
        const isZoneTabNoPlan = isZoneTab && zoneTabNoPlanMetrics.includes(metric)

        if(isZoneTabNoPlan) {
            return false;
        }

        return true; // has bar color
    }

    const performance_per_group_columns = useMemo(
        () => [
            {
                id: 'displayKey',
                header: absoluteHeaderLabel,
                accessorKey: displayKey,
                cell: ({ row, getValue }) => {
                    const hasSubRows = row.original.subRows && row.original.subRows.length > 0;
                    const linkOrValue = (() => {
                        if (isBusinessUnitTab && !hasSubRows && !!getValue() && showBUView && enableBULink) {
                            ;
                            return (
                                <Link
                                    href={`#/sbu/sales/bu-view/${encodeURIComponent(row?.original?.pm_business_unit_desc_standardized)}/${filters?.month}/${filters?.year}/${period}`}
                                    underline="hover"
                                    color="inherit"
                                    target="_blank"
                                    rel="noopener"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                >
                                    {getValue()}
                                </Link>
                            );
                        } else if (isZoneTab) {
                            const zoneTitle = getValue() + " (" + row?.original?.store_parent_store_id_count_distinct + " stores)";
                            const zoneLink = `#/sbu/sales/zone-view/${row?.original?.store_zone}/${filters?.month}/${filters?.year}/${period}`
                            if (showZoneView && enableZoneLink) {
                                return (
                                    <Link
                                        href={zoneLink}
                                        underline="hover"
                                        color="inherit"
                                        target="_blank"
                                        rel="noopener"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                    >
                                        {zoneTitle}
                                    </Link>
                                );
                            } else {
                                return zoneTitle;
                            }

                        } else {
                            return getValue();
                        }
                    })();

                    const isBusinessUnitView = selectedViewBy === 'business_unit';

                    return <Box sx={{ marginLeft: `${row.depth * 1}rem`, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>

                        { enableRowSelection && < VisibilityIcon sx={{ marginRight: '.5rem' }} fontSize='small'
                            color={selectedRows?.includes(row?.original?.[selectedViewByL3]) ? 'primary' : 'disabled'} 
                        />}
                        {isGiftRegistry && CHANNEL_COLS.includes(row?.getValue()) ? row?.getValue() : row?.getValue()}

                        <Box fontWeight={row?.depth === 0 && hasSubRows && isBusinessUnitView ? 'bold' : 'normal'}>
                            {linkOrValue}
                        </Box>
                        {row.original.subRows && (
                            < IconButton size='small' onClick={(event) => {
                                event.stopPropagation(); // Stop the click event from propagating
                                row.getToggleExpandedHandler()();
                            }}
                                sx={{ cursor: "pointer" }}
                            >
                                {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize='medium' /> :
                                    <ArrowRightRoundedIcon fontSize='medium' color='#e0e0e0' />}
                            </IconButton>
                        )}
                    </Box>
                },
                sortingFn: (rowA, rowB) => {
                    if (orderArray) {
                        return sortByOrder(rowA.original, rowB.original, orderArray, selectedViewByL3)
                    } else {
                        return rowA?.getValue() < rowB?.getValue() ? 1 : -1
                    }
                },
                customStyle: { width: '17rem' },
            },
            {
                id: 'actual_sales',
                header: metricHeaderLabel,
                accessorKey: 'metricTotal',
                cell: (row) => {

                    const numericValueProps = NUMERIC_VALUE_PROPS_BY_METRIC[selectedMetric] || NUMERIC_VALUE_PROPS_BY_METRIC['DEFAULT'];
                    if (selectedMetric === 'actual_catchment_rate' || selectedMetric === 'actual_conversion_rate') {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '10%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} isAbbreviated={true} {...numericValueProps} />
                            </Box>
                        );
                    } else {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} isAbbreviated={true}  {...numericValueProps} />
                            </Box>
                        );
                    }
                },
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                id: 'contribution',
                header: '% cont.',
                accessorKey: 'contribution',
                cell: (row) => {
                    const value = row?.getValue()
                    const postFix = value ? '%' : ''
                    return <NumericValueDisplay value={value} postfix={postFix} />
                },
                customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                id: 'actual_plan',
                header: isQoQ ? 'QTR Plan' : isMTD ? 'MTD Plan' : 'YTD Plan',
                accessorKey: 'metricPlanTotal',
                cell: (row) => {
                    if (selectedMetric === 'actual_transactions') {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} isAbbreviated={true} />
                            </Box>
                        );
                    } else if (selectedMetric === 'actual_catchment_rate' || selectedMetric === 'actual_conversion_rate') {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '10%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} postfix={'%'} isAbbreviated={true} />
                            </Box>
                        );
                    } else {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={true} />
                            </Box>
                        );
                    }
                },
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                id: 'vs_plan',
                header: 'vs Plan',
                accessorKey: 'vsPlan',
                cell: (row) => {
                    const value = row?.getValue();
                    if (value === 'N/A') {
                        return (
                            <Box sx={{ width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                                <BarIndicator value={'N/A'} isValueColorCoded={true} />
                            </Box>
                        );
                    } else if (!isCompletePeriod) {
                        return (<>
                            {convertNumberToLocaleString(value) !== 0 ?
                                <Box sx={{ width: '4rem', height: '1.5rem', marginX: 'auto' }}>
                                    <BarIndicator componentType={'progress_bar'} value={convertToValidNumber(value)?.toFixed()} />
                                </Box> : '-'
                            }
                        </>);
                    } else {
                        return (<>
                            {convertNumberToLocaleString(value) !== 0 ?
                                <Box sx={{ width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <BarIndicator value={convertToValidNumber(value)?.toFixed()} isValueColorCoded={true} />
                                </Box> : '-'
                            }
                        </>);
                    }
                },
                customStyle: { width: '7rem', height: '2rem', textAlign: 'center', verticalAlign: 'middle' }
            },

            {
                id: 'vs_last_year',
                header: 'vs Last Year',
                accessorKey: 'vsLY',
                cell: (row) => <>
                    {convertNumberToLocaleString(row?.getValue()) !== 0 ?
                        <Box sx={{ width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                            <BarIndicator value={convertToValidNumber(row?.getValue())?.toFixed()} isValueColorCoded={true} />
                        </Box> : '-'
                    }
                </>,
                customStyle: { width: '7rem', height: '2rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: barTrendsColumnHeaders,
                id: '12th_mth_sales_trend',
                accessorKey: 'vsPlan',
                cell: (row) => {
                    const isGraphLoading = isGraphDataL3Loading && isGRGraphDataL3Loading && isBUGraphDataL3Loading
                    const group = selectedViewByL3;
                    const groupValue = row?.row?.original?.[group];
                    const barTrendData = combinedData[groupValue];
                    const newGraphData = barTrendData?.filter(data => monthYear?.includes(data?.year_month))
                    const isGiftRegistry = groupValue === "Gift Registry"                    
                    const maxValues = isGiftRegistry && grMaxBarTrendValues ? grMaxBarTrendValues : maxBarTrendValues
                    const hasNoBarColor = !checkIfHasBarColor(group, groupValue, selectedMetric)

                    // FIXME: temporary fix for bartrends appearing in GR row when metric is units sold & units sold per txn when there are no metric values
                    const isGRNoBarTrends = isGiftRegistry && ['actual_units_sold', 'actual_units_sold_per_txn'].includes(selectedMetric)
                    if(isGRNoBarTrends) {
                        return isGraphLoading ? <LoadingComponent /> : <Box sx={{ width: '13rem', height: '2.5rem', marginX: 'auto' }}></Box>
                    }
                    
                    return <>
                        {
                            isGraphLoading ? <LoadingComponent /> : <Box sx={{ width: '13rem', height: '2.5rem', marginX: 'auto' }}>
                                <BarTrends data={newGraphData} maxValues={maxValues} metric={selectedMetric} hasNoBarColor={hasNoBarColor} />
                            </Box>
                        }
                    </>
                }
            }
        ],
        [selectedViewBy, selectedRows, isGiftRegistry, period, getRunningDailyTarget, isMTD, isGraphDataL3Loading, updatedGraphDataL3, buGraphDataL3]
    )

    const TopSalesTableData = isGiftRegistry && newPerformancePerGroupData?.filter(val => CHANNEL_COLS.includes(val['cm_channel_desc'])) // 888 and 4k data
    const filteredData = isGiftRegistry ? newPerformancePerGroupData?.filter(val => !CHANNEL_COLS.includes(val['cm_channel_desc'])) : newPerformancePerGroupData // remove 888 and 4k when on product group
    const noNullRowData = filteredData?.filter(val => val[selectedViewByL3] !== 'null' && val[selectedViewByL3] !== null)
    const newOrderArray = isGiftRegistry ? ['Gift Registry', ...orderArray] : orderArray

    if (isLoadingPerGroupL3 || isGraphDataL3Loading || isGRGraphDataL3Loading || isBUGraphDataL3Loading) {
        return <LoadingComponent />
    }

    const columnVisibility = {
        // "vs_last_year": isCompletePeriod,
        "12th_mth_sales_trend": !isMTD && !isQoQ
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container alignItems="center" py={.5}>
                    {
                        windowSize?.isOnMobile ? <>
                            <Grid item xs display="flex" justifyContent={'space-between'} alignItems={"center"}>
                                <Typography variant="h6" ml={2.5}>
                                    Performance per
                                </Typography>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                            <Grid item xs={12} mx={2} py={.5}>
                                <ViewBySelector {...componentProps.viewBySelector} />
                            </Grid>
                        </> : <>
                            <Grid item sm={8} xs={12} md={8} display={'flex'}>
                                <ViewBySelector {...componentProps.viewBySelector} />
                            </Grid>
                            <Grid item xs justifyContent={'end'}>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={12} mt={0} >
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Collapse in={isExpand}>
                        <TableComponentV2
                            columns={performance_per_group_columns}
                            data={sortArrayBasedOnGivenArray(noNullRowData, newOrderArray, selectedViewByL3)}
                            tableId={'performancePerGroupTable'}
                            enableRowSelection={enableRowSelection}
                            rowSelectionDepth={1}
                            onSelectRow={onSelectRow}
                            isCompact={true}
                            customTableHeaderStyle={{
                                backgroundColor: '#caddeb'
                            }}
                            initialState={{ columnVisibility }}
                        />
                    </Collapse>
                    {
                        isGiftRegistry &&
                        <Grid item mt={3}>
                            <Collapse in={isExpand}>
                                <TableComponentV2
                                    columns={performance_per_group_columns}
                                    data={sortArrayBasedOnGivenArray(TopSalesTableData, newOrderArray, selectedViewByL3)}
                                    tableId={'performancePerGroupTopTable'}
                                    enableRowSelection={true}
                                    onSelectRow={onSelectRow}
                                    isCompact={true}
                                    customTableHeaderStyle={{
                                        backgroundColor: '#caddeb'
                                    }}
                                    customTableContainerStyle={{
                                        borderRadius: "0",
                                        boxShadow: 'none',
                                    }}
                                    initialState={{ columnVisibility }}
                                />
                            </Collapse>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
}
