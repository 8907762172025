import {Box, Typography} from "@mui/material";
import LoadingComponent from "../../../common/components/LoadingComponent";
import {useLatestLoadDateMoment} from "./hooks.js";

export default function AsOfDate({textOnly = false}) {

    const { data: latestLoadDateMoment, isLoading } = useLatestLoadDateMoment()

    if (isLoading) {
        if (textOnly) {
            return "Loading..."
        }
        return <Box md={2} sx={{ marginY: -1, paddingX: 2, display: 'flex', justifyContent: 'end', width: "100%" }} >
            <LoadingComponent />
        </Box>
    }

    const displayText =  `Data as of ${latestLoadDateMoment?.format('MMMM DD, YYYY')}`;

    if (textOnly) {
        return displayText
    }

    return <Box textAlign="end" width={"100%"} >
        <Typography variant="body1" >
            {displayText}
        </Typography>
    </Box>
}