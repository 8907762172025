import { useState } from "react";
import Search from "./Search";
import AddGroup from "./AddGroup";
import GroupTable from "./GroupTable";
import { Typography, Grid, Divider, Box, IconButton } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Back from '../../../common/components/BackNavigateComponent';

export default function GroupManagement() {
    const [searchQuery, setSearchQuery] = useState('');

    return (
        <>
            <Grid sx={{ mt: 3 }} display={'flex'}>
                <Back  />
                <Typography variant="h5" style={{ marginLeft: '16px' }}>
                    Group Management
                    <Tooltip title={<Typography variant="body2">Group Management</Typography>}
                                    placement='right'>
                    <IconButton>
                        <InfoIcon style={{ color: 'grey' }}/>
                    </IconButton>
                    </Tooltip>
                </Typography>
            </Grid>
            <Divider sx={{ my: 2, borderBottom: 3 }} />

            <Grid sx={{ border: 2, borderRadius: 3, borderColor: "#bdbdbd", backgroundColor: "#F3F3F3", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, p: 1 }}>
                <Grid container alignItems="center" justifyContent="space-between" display={{ xs: 'block', md: 'flex' }}>
                    <Grid item md={3} m={1} >
                        <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Grid>
                    <Grid item m={1} >
                        <AddGroup />
                    </Grid>
                </Grid>
            </Grid>
            {/* Table Display Component */}
            <Grid sx={{ border: 2, borderRadius: 3, borderColor: "#bdbdbd", borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 0, p: 1 }}>
                <GroupTable searchQuery={searchQuery} />
            </Grid>
        </>
    )
}