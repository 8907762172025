import {createContext} from "react";


export const StoreAnalyticsFilterContextDefaultValue = {
    region: null,
    province: null,
    branch_id: null,
    branch_name: null
}

export const StoreAnalyticsFilterContext = createContext({
    ...StoreAnalyticsFilterContextDefaultValue,
    setFilter: (key, value) => {}
})
