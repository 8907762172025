import { useState } from 'react';
import { FeatureGroup, MapContainer, Pane, TileLayer, useMapEvent, ZoomControl } from 'react-leaflet';
import LayerControl from './LeafletElements/LayerControl';
import CompanyLogoMarkers from "./CompanyLogoMarkers";
import PopulationMetricPolygons from './PopulationMetricPolygons';
import SalesMetricPolygons from './SalesMetricPolygons';

import { Grid } from '@mui/material';

const DEFAULT_ZOOM_LVL = 6;

export default function Map({
    searchTerms, filters, locationData,
    selectedLocations,
    populationFilters, groupRef, mapRef,
    dateFilter, salesApiEndPointName, apiDefaultFilter,
    polygonLevel, setPolygonLevel
}) {
    const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LVL)
    const [visibleBounds, setVisibleBounds] = useState({ north: null, south: null, east: null, west: null })

    const [mapLayersToDisplay, setMapLayersToDisplay] = useState(['Sales', 'Population', 'Location'])


    function MapEvent() {
        const map = useMapEvent({
            'zoom': () => {
                setZoomLevel(map.getZoom())
                setVisibleBounds({
                    north: map.getBounds().getNorth(),
                    south: map.getBounds().getSouth(),
                    east: map.getBounds().getEast(),
                    west: map.getBounds().getWest(),
                })
            },
            'moveend': () => {
                setVisibleBounds({
                    north: map.getBounds().getNorth(),
                    south: map.getBounds().getSouth(),
                    east: map.getBounds().getEast(),
                    west: map.getBounds().getWest(),
                })
            }
        })
        return null
    }

    return <Grid borderRadius={2} boxShadow={1} my={2} style={{ height: "90vh" }}>
        {/* center long and lat is: SM MOA */}
        <MapContainer center={[12.8797, 121.7740]} zoomControl={false} zoom={DEFAULT_ZOOM_LVL} minZoom={5} style={{ height: '100%', borderRadius: 10 }}
            ref={mapRef}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ZoomControl position="bottomright" />
            <LayerControl
                position="topleft"
                setMapLayersToDisplay={setMapLayersToDisplay}
                mapLayersToDisplay={mapLayersToDisplay}

                onSelectPolygonLevel={setPolygonLevel}
                polygonLevel={polygonLevel} />

            <MapEvent />
            <FeatureGroup ref={groupRef}>
                <CompanyLogoMarkers
                    searchTerms={searchTerms}
                    filters={filters}
                    locationData={locationData}
                    zoomLevel={zoomLevel}
                    displayLocationMarkers={mapLayersToDisplay.includes('Location')}
                    selectedLocations={selectedLocations}
                />
            </FeatureGroup>
            {mapLayersToDisplay.includes('Population') && <FeatureGroup>
                <PopulationMetricPolygons
                    zoomLevel={zoomLevel}
                    visibleBounds={visibleBounds}
                    populationFilters={populationFilters}
                    polygonLevel={polygonLevel}
                />
            </FeatureGroup>
            }

            {mapLayersToDisplay.includes('Sales') && <Pane name={'salesPane'} style={{ zIndex: 900 }}>
                <SalesMetricPolygons
                    visibleBounds={visibleBounds}
                    salesApiEndPointName={salesApiEndPointName}
                    apiDefaultFilter={apiDefaultFilter}
                    zoomLevel={zoomLevel}
                    polygonLevel={polygonLevel}
                    dateFilter={dateFilter}
                />
            </Pane>
            }
        </MapContainer>
    </Grid>
}