import React from "react";
import { useLabelOnlyFetch } from "../../../../../common/components/GraphFactory/dataProcessingHooks";
import DataTable from "react-data-table-component";
import { processFilterObjectArray } from "../../../../../common/components/GraphFactory/util";
import { Typography, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


export default function ColumnMapping({ table, extraData, setExtraData }) {
    // this component is used to rename/map columns to a more user friendly name
    const { data, isSuccess, isLoading } = useLabelOnlyFetch(table, extraData, processFilterObjectArray(extraData.filters))
    const label = extraData?.label?.value
    const values = React.useMemo(() => {

        if (!!data?.data && isSuccess && !isLoading) {
            return data.data.map(row => {
                return row[label]
            })
        }

    }, [data?.data, isSuccess, isLoading, label])

    const dataAsRows = React.useMemo(() => {
        const newMapping = {
            ...(extraData.labelMapping || {})
        }
        if (values) {
            values.forEach(value => {
                if (!newMapping[value]) {
                    newMapping[value] = null
                }
            })
        }
        // if (!newMapping[""]) {
        //     newMapping[""] = null
        // }
        return Object.keys(newMapping).reduce((acc, key) => {
            acc.push({
                original: key,
                mapped: newMapping[key]
            })
            return acc
        }, [])
    }, [extraData.labelMapping, values])

    return (
        <>
            <Typography fontWeight="bold" ml={2} mt={2}> Label Mapping/Replacement
                <Tooltip title={<Typography variant="body2">Used to replace labels that are coded or for making labels more readable.</Typography>}
                    disableFocusListener
                    disableTouchListener
                    arrow
                    placement='top'>
                    <IconButton>
                        <HelpOutlineIcon style={{ color: 'grey' }} />
                    </IconButton>
                </Tooltip>
            </Typography>

            <DataTable columns={[
                {
                    name: "Original Label",
                    selector: row => row.original,
                    sortable: true,
                },
                {
                    name: "Replace With",
                    selector: row => row.mapped || "",
                    sortable: true,
                    cell: (row) => {

                        return <TextField id="replace_with" type="text" size="small" variant="outlined" value={row.mapped || ""}
                            onChange={(e) => {
                                const updatedMapping = {
                                    ...extraData.labelMapping,
                                    [row.original]: e.target.value
                                }
                                const updatedExtraData = {
                                    ...extraData,
                                    labelMapping: updatedMapping
                                }
                                setExtraData(updatedExtraData)
                            }}
                        />
                    }
                },
                {
                    grow: 0,
                    cell: (row) => {
                        if (row.original === "") {
                            return ""
                        }
                        return <IconButton aria-label="delete" size="small" color="error" onClick={() => {
                            const updatedMapping = {
                                ...extraData.labelMapping,
                            }
                            delete updatedMapping[row.original]
                            const updatedExtraData = {
                                ...extraData,
                                labelMapping: updatedMapping
                            }
                            setExtraData(updatedExtraData)
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    }
                }
            ]} data={dataAsRows}
            />
        </>
    )

}