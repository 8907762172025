import React, {useContext, useState} from "react";
import DonutChartFromApi from "./donutChartFromApi";
import TableFromApi from "./tableFromApi";
import {abbreviateNumber} from "../../../../common/utils";
import {StoreAnalyticsFilterContext} from "../contexts";

import { Grid, IconButton, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function CustomerSegment() {
    const [expanded, setExpanded] = useState(true)
    const {region: region, province, branch_name} = useContext(StoreAnalyticsFilterContext)

    const filterParams = {
        region: region,
        province: province,
        store: branch_name?.toLowerCase()
    }

    return (<>
        <Grid container direction={'row'} mt={3}>
            {/* TODO: change this to percentage mode and fix floating labels */}
            <Grid item xs={12} sm={4} mb={2} borderRadius={2} boxShadow={1}>
                    <Box sx={{ textAlign: 'right', display: { sm:'none', xs: 'block'} }}>
                    <IconButton
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            type="button"
                            style={{ zIndex: 100000 }}
                        >
                            {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </Box>
                    <DonutChartFromApi
                        apiEndpoint={"/custom/tss-customer-segments/query/"}
                        params={{
                            columns: ["customer_segment"].join(','),
                            group_by: "customer_segment",
                            aggregates: "total_sales",
                            ...filterParams
                        }}
                        dataReducer={(data) => {
                            return data.reduce((acc, item) => {
                                acc[item.customerSegment] = item.totalSales
                                return acc;
                            }, {});
                        }}
                        title='Customer Segment Sales'
                        subTitle='All Time'
                    />
            </Grid>

            <Grid item xs={12} sm={8} style={{ display: expanded ? 'block' : 'none', marginBottom: '16px' }}>

                <TableFromApi
                    apiEndpoint={"/custom/tss-customer-segments/query/"}
                    params={{
                        columns: ["customer_segment"].join(','),
                        aggregates: ["customer_count", "atv", "monthly_atv", "sku", "months_present", "qty"].join(','),
                        group_by: "customer_segment",
                        ...filterParams
                    }}
                    columns={[
                        {name: "Customer Segment", selector: row => row.customerSegment, sortable: true},
                        {
                            name: "Customer Count",
                            selector: row => row.customerCount,
                            sortable: true,
                            format: (row) => abbreviateNumber(row.customerCount)
                        },
                        {
                            name: "ATV",
                            selector: row => row.atv,
                            sortable: true,
                            format: (row) => abbreviateNumber(row.atv)
                        },
                        {
                            name: "Monthly ATV",
                            selector: row => row.monthlyAtv,
                            sortable: true,
                            format: (row) => abbreviateNumber(row.monthlyAtv)
                        },
                        {
                            name: "SKU",
                            selector: row => row.sku,
                            sortable: true,
                            format: (row) => abbreviateNumber(row.sku, 2)
                        },
                        {
                            name: "Months Present", selector: row => row.monthsPresent, sortable: true,
                            format: (row) => abbreviateNumber(row.monthsPresent, 1)
                        },
                        {
                            name: "Qty", selector: row => row.qty, sortable: true,
                            format: (row) => abbreviateNumber(row.qty, 2)
                        },
                    ]}
                />
            </Grid>
        </Grid>
    </>)
}