import { useState } from 'react';
import { FiltersContext } from './context';

export default function FiltersProvider({ children }) {
    const [filters, setFilters] = useState({
        group: "channel",
        month: null,
        year: null,
        business_unit: null,
        department: '',
        subdept: '',
        brand: [],
        age_group: [],
        product_category: '',
        metric_column: 'actual_sales'
    });

    const setFilter = (key, value) => {
        setFilters({
            ...filters,
            [key]: value,
        });
    };

    return (
        <FiltersContext.Provider
            value={{
                filters,
                setFilters,
                setFilter,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
}
