import React, {useContext, useState} from 'react';
import {Autocomplete, Box, Collapse, Grid, TextField, Typography} from '@mui/material';
import {WindowSizeContext} from '../../../../../common/context/WindowSizeContext';
import TableComponentV2 from '../../../../../common/components/TableComponentV2';
import CollapsibleComponent from '../../../../../common/components/CollapsibleComponent';
import {useBuSoWDataV2, useBUSoWFiltersData} from './hooks';
import LoadingComponent from '../../../../../common/components/LoadingComponent';
import {SoWColumns} from './SoWColumns';
import NestedDropdownCheckbox from '../../../../../common/components/NestedDropdownCheckbox';

export const IndicatorCellBox = (props) => (
    <Box sx={{my:.5, width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);


export default function BUGroupShareOfWallet({ params, period, store, storeID, onTogglePeriodChange, filters, onDropDownFilterChange, isBranchView=false }) {

    const windowSize = useContext(WindowSizeContext);

    const groupByOptions = [
        {value: 'Business Unit Group', label: 'Business Unit Group', depth: 0},
        {value: 'Business Unit', label: 'Business Unit', depth: 1},
        {value: 'Department', label: 'Department', depth: 2},
        {value: 'Sub-department', label: 'Sub-department', depth: 3}
    ]

    const [selectedGroupBy, setSelectedGroupBy] = useState(groupByOptions[0]);
    const [selectedNested, setSelectedNested ] = useState([])
    const [isExpand, setExpand] = useState(true)

    const handleGroupBy = (event, newValue) => {
        setSelectedGroupBy(newValue);
        setSelectedNested([])  // reset nested selection when group by changes since the nested options will change
    };
    const handleNestedCheckboxChange = (newSelection) => {
        setSelectedNested(newSelection)
    }

    const newParams = {  
        ...params,  
        cm_channel_desc: filters?.channel  
    }  

    if (filters.hasOwnProperty('smac_consent') && filters.smac_consent !== undefined && filters.smac_consent !== '') {
        const paramsConsentKey = `smac_cdp_consent__is_${filters.smac_consent.toString()}`  // evaluates to smac_consent__is_true or smac_consent__is_false  
        newParams[paramsConsentKey] = 1  
    }  

    const { filteredData: customerData, data: customerDataOriginal, isLoading: isLoadingCustomerData } = useBuSoWDataV2(selectedGroupBy.label, newParams, selectedNested)
    const {nestedOptions, isLoading: isLoadingNestedOptions} = useBUSoWFiltersData()

    const truncatedNestedOptions = React.useMemo(() => {
        if (!nestedOptions) return []

        // limit the depth of nested options according to the selected group by
        const removeChildren = (options, depth, maxDepth) => {
            if (depth === maxDepth) {
                return options.map(option => {
                    const newOption = {
                        ...option,
                    }
                    delete newOption.children
                    return newOption
                })
            }
            return options.map(option => {
                return {
                    ...option,
                    children: removeChildren(option.children, depth + 1, maxDepth)
                }
            })
        }
        return removeChildren(nestedOptions, 0, selectedGroupBy.depth)
    }, [nestedOptions, selectedGroupBy])

    const filteredCustomerData = {value: customerData}


    const {data: revisedCustomerData, columns: columns} = SoWColumns({
        selectedColumn: selectedGroupBy.value, 
        data: filteredCustomerData.value,
        isBranchView: isBranchView,
        selectedNested: selectedNested })


    if (customerDataOriginal?.length === 0) {
        return (
            <>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} paddingY={0.5} mt={3}>
                        <Typography variant='subtitle1' align='center'>No data available for Business Unit Group Share of Wallet.</Typography>
                    </Grid>
                </Grid>
            </>
        )
    }

    if (columns?.length<=1 && selectedNested?.length===0 && truncatedNestedOptions?.length===0) {
        return (
            <>
            <Grid container >
                <Grid item xs={12}>
                    <LoadingComponent />
                </Grid>
            </Grid>
            </>
        )
    }

    return (
        <Grid container >
            <Box xs={12} sx={{ width: '100%', backgroundColor:"#f6f6f8", borderRadius:".5rem .5rem 0 0"}} mt={.7}>
                <Grid container alignItems="center" py={.5}>
                    <Grid item xs={2} sm>                            
                        <Typography variant='h6' ml={2} >Business Unit Group Share of Wallet</Typography>
                    </Grid>
                    <Grid item xs={2} justifyContent={'end'}>
                        <Autocomplete
                            id='groupby-bu-wallet'
                            disableClearable
                            value={selectedGroupBy}
                            onChange={handleGroupBy}
                            options={groupByOptions}
                            blurOnSelect
                            size="small"
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`Group by: ${selectedGroupBy ? selectedGroupBy?.label : ''}`}
                                />
                              )}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                        />
                    </Grid>
                    <Grid item xs={2} sm={3} justifyContent={'end'} ml={3}>
                        <NestedDropdownCheckbox
                            options={truncatedNestedOptions}
                            label="All Business Unit Group"
                            onSelectionChange={handleNestedCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={1} justifyContent={'end'}>
                        <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                    </Grid>
                </Grid>
            </Box>
            
            <Grid item xs={12} sm={12}>
            <Collapse in={isExpand}>
                {isLoadingCustomerData &&
                    <LoadingComponent />
                }
                
                <Box sx={{ textAlign: 'center',  width: '100%', backgroundColor: '#caddeb', fontWeight: 'bold', display: isLoadingCustomerData && 'none'}}>
                    {selectedGroupBy.value} Share Of Wallet
                </Box>
                <TableComponentV2
                    id="bu_sow_table"
                    columns={columns}
                    data={revisedCustomerData}
                    tableId={'customerAverageDailySpendTable'}
                    isCompact={true}
                    customTableHeaderStyle={{
                        backgroundColor: '#caddeb'
                    }}
                />
                </Collapse>
            </Grid>
        </Grid>
    )
}