import { useQuery } from "react-query";
import ApiClient from "../../../../../common/API";
import { USE_QUERY_DEFAULT_OPTIONS } from "../../../../../Constants/settings";
import moment from "moment";
import { TABLE_CUSTOMER_ATTRIBUTES, TABLE_CUSTOMER_METRICS } from "../../../Customers/constants";
import {
    convertParamsToTranDate,
    convertParamsToTransactionDate,
    getParamsBasedOnPeriod,
    preProcessParams
} from "../../../Customers/util";
import { useAvailableDateRangeV2 } from "../../../Customers/hooks";

export function useSMACCustomerAgg() {
    return useQuery(
        [],
        () =>
            ApiClient().get(`data/table/${TABLE_CUSTOMER_METRICS}/`, {

            }).then((res) => res.data),
        {
            refetchOnWindowFocus: false,
            staleTime: 600000, // 10 mins
        }
    );
}

export function useCustomerData({ params }) {
    const hasCustomerDate = params?.month?.length > 0 && !!params?.year

    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRangeV2();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const { data, isLoading } = useQuery(
        [TABLE_CUSTOMER_METRICS, "customer_data", params, 'count'],
        () => {

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(params, dayOfMonthMaxDate)
            )

            return ApiClient().get(`data/table/${TABLE_CUSTOMER_METRICS}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: 'store_customer_count_sum',
                    view: 'smac_members,customer_information',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data;
            });
        }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        enabled: hasCustomerDate
    }
    );

    return {
        data,
        isLoading
    }
}

export function useCustomerInformationData({ params }) {
    const { data: customerInformationData, isLoading: isCustomerInformationDataLoading } = useQuery(
        [TABLE_CUSTOMER_METRICS, "customer_information_data", params, 'count'],

        () => {

            return ApiClient().get(`data/table/${TABLE_CUSTOMER_METRICS}/query/`, {
                params: {
                    ...params,
                    columns: 'store_id,year,month,card_type',
                    group_by: 'store_id,year,month,card_type',
                    aggregates: 'store_customer_count_sum',
                    view: 'customer_information',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data
            })
        }
    )
    return {
        customerInformationData,
        isCustomerInformationDataLoading
    }
}

export function useSMACMembersData({ params }) {
    const { data: smacData, isLoading: isSMACMemberDataLoading } = useQuery(
        [TABLE_CUSTOMER_METRICS, "smac_member_data", params, 'count'],

        () => {

            return ApiClient().get(`data/table/${TABLE_CUSTOMER_METRICS}/query/`, {
                params: {
                    ...params,
                    columns: 'store_id,year,month,card_type',
                    group_by: 'store_id,year,month,card_type',
                    aggregates: 'store_card_type_count_sum',
                    view: 'smac_members',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data
            })
        }
    )
    return {
        smacData,
        isSMACMemberDataLoading
    }
}

export function useSMACMembersDataV2({ params, viewBy }) {

    const aggregates = [
    ]

    if (viewBy === 'branch') {
        aggregates.push('store_card_type_count_max')
    } else if (viewBy === 'bu') {
        aggregates.push('bu_card_type_count_max')  // from bu_group_card_type_count_max to bu_card_type_count_max
    } else if (viewBy === 'chain') {
        aggregates.push('chain_card_type_count_max')
    }


    const queryKey = [TABLE_CUSTOMER_METRICS, "smacMembersData", params, viewBy]
    const hasCustomerDate = params?.month?.length > 0 && !!params?.year
    return useQuery(
        queryKey,

        () => {
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_METRICS}/query/`, {
                params: {
                    ...params,
                    columns: 'card_type',
                    group_by: 'card_type',
                    aggregates: aggregates.join(','),

                    card_type__in: 'Start,SMAC,Prestige',
                    limit: 1000
                }
            }).then(res => {
                const data = res.data.data
                return data.map(row => {
                    row['card_type_count'] = row[aggregates[aggregates.length - 1]]
                    return row
                })
            })
        }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        enabled: hasCustomerDate
    }
    )
}

// for l3 table usage
export function useSMACMembersDataV3({ params, viewBy, queryOptions = {} }) {
    const hasCustomerDate = (params?.month?.length > 0 && !!params?.year) ||
        params.hasOwnProperty('header_tran_date__gte') || params.hasOwnProperty('header_tran_date__lte') || params.hasOwnProperty('header_tran_date__in');
    const { data: availableDateRange } = useAvailableDateRangeV2();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;
    const isQueryingForVsLY = queryOptions?.isQueryForVsLastYear;
    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && (moment(maxDate).year() === params.year || isQueryingForVsLY)
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const aggregateList = [
        'gcr_persistent_id_count_distinct'
    ]

    const queryKeyTotals = [TABLE_CUSTOMER_ATTRIBUTES, "smacMembersTotalData", aggregateList, params, viewBy]
    const { data: totalMembersData, isLoading: isLoadingTotalMembersData } = useQuery(
        queryKeyTotals,
        () => {
            const processedParams = preProcessParams(
                convertParamsToTranDate(params, dayOfMonthMaxDate)
            )
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: aggregateList.join(','),
                    limit: 1000
                }
            }).then(res => {
                const data = res.data.data
                return data.map(row => {
                    row['card_type'] = 'Total SMAC Members'
                    row['card_type_count'] = row['gcr_persistent_id_count_distinct']
                    return row
                })
            })
        }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        enabled: hasCustomerDate
    }
    )

    const columnListPerCardType = [
        'smac_card_type'
    ]

    const queryKeyPerType = [TABLE_CUSTOMER_ATTRIBUTES, "smacMembersTotalData", aggregateList, columnListPerCardType, params, viewBy]
    const { data: totalMembersPerTypeData, isLoading: isLoadingTotalMembersPerTypeData } = useQuery(
        queryKeyPerType,
        () => {
            const processedParams = preProcessParams(
                convertParamsToTranDate(params, dayOfMonthMaxDate)
            )
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
                params: {
                    ...processedParams,
                    columns: columnListPerCardType.join(','),
                    group_by: columnListPerCardType.join(','),
                    aggregates: aggregateList.join(','),
                    // card_type__in: 'Start,SMAC,Prestige',
                    limit: 1000
                }
            }).then(res => {
                const data = res.data.data
                return data.map(row => {
                    const indexOfAggregate = aggregateList.length - 1;
                    row['card_type'] = row['smac_card_type']
                    row['card_type_count'] = row[aggregateList[indexOfAggregate]]
                    return row
                })
            })
        }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        enabled: hasCustomerDate
    }
    )

    return {
        totalData: totalMembersData,
        cardTypeData: totalMembersPerTypeData,
        isLoading: isLoadingTotalMembersData || isLoadingTotalMembersPerTypeData
    }
}

export function useSMACToplineMetrics({ params, viewBy }) {

    const aggregates = [
        'gross_sales_sum',
        'units_sold_sum',
    ]

    let avg_frequency_avg;

    if (viewBy === 'branch') {
        aggregates.push('store_txn_count_max', 'avg_store_frequency_avg')
        avg_frequency_avg = 'avg_store_frequency_avg';
    } else if (viewBy === 'bu') {
        aggregates.push('bu_txn_count_max', 'avg_bu_frequency_avg')
        avg_frequency_avg = 'avg_bu_frequency_avg';
    } else if (viewBy === 'chain') {
        aggregates.push('chain_txn_count_sum', 'avg_chain_frequency_avg')
        avg_frequency_avg = 'avg_chain_frequency_avg';
    }

    const hasCustomerDate = params?.month?.length > 0 && !!params?.year

    const effectiveParams = {
        ...params,
        aggregates: aggregates.join(','),
        // card_type__in: 'SMAC,Start,Prestige',
        limit: 1000
    }


    if (viewBy === 'chain') {
        effectiveParams.view = 'chain_topline'
    }  // FIXME: there is no view defined for branch and bu view

    const queryKey = [TABLE_CUSTOMER_METRICS, "topline_metrics", effectiveParams, viewBy]

    return useQuery(
        queryKey,
        () => {
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_METRICS}/query/`, {
                params: effectiveParams
            }).then(res => {
                const data = res.data.data[0]
                const originalKey = aggregates[aggregates.length - 2]
                data['txn_count'] = data[originalKey]
                data['avg_frequency'] = data[avg_frequency_avg];
                return data
            })
        }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        enabled: hasCustomerDate
    })
}

// for l3 table usage
export function useSMACToplineMetricsV2({ params, viewBy, period, queryOptions = {} }) {
    const hasCustomerDate = (params?.month?.length > 0 && !!params?.year) ||
        params.hasOwnProperty('header_tran_date__gte') || params.hasOwnProperty('header_tran_date__lte') || params.hasOwnProperty('header_tran_date__in');

    const { data: availableDateRange } = useAvailableDateRangeV2();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;
    const isQueryingForVsLY = queryOptions?.isQueryForVsLastYear;
    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && (moment(maxDate).year() === params.year || isQueryingForVsLY)
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const processedParams = preProcessParams(
        convertParamsToTranDate(
            getParamsBasedOnPeriod(period === 'mtd', period === 'qoq', params),
            dayOfMonthMaxDate
        )
    )

    const aggregates = [
        'header_tran_date_avg',
        'basket_size_avg',
        'header_tran_key_sum',
        'pos_sku_gross_sales_sum', // 'pos_line_gross_sales_sum',
        'pos_sku_tot_qty_sum', //'pos_line_sale_tot_qty_sum',
    ]

    const effectiveParams = {
        ...processedParams,
        aggregates: aggregates.join(','),
        // card_type__in: 'SMAC,Start,Prestige',
        view:'chain_topline',
        limit: 1000
    }

    // if (viewBy === 'chain') {
    //     effectiveParams.view = 'chain_topline'
    // }  // FIXME: there is no view defined for branch and bu view

    const { data, isLoading } = useQuery(
        [TABLE_CUSTOMER_ATTRIBUTES, "topline_metrics", effectiveParams, viewBy],
        () => {
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
                params: effectiveParams
            }).then(res => {
                const data = res.data.data[0]
                return data
            })
        }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        enabled: hasCustomerDate
    })

    const collatedData = {
        gross_sales_sum: data?.pos_sku_gross_sales_sum,
        units_sold_sum: data?.pos_sku_tot_qty_sum,
        txn_count: data?.header_tran_key_sum,
        avg_frequency: data?.header_tran_date_avg,
        basket_size: data?.basket_size_avg,
    }

    return {
        data: collatedData,
        isLoading //: isLoading1 || isLoading2 || isLoading3
    }
}