import React from "react";
import * as PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import { abbreviateNumber } from "../../utils";
import deepmerge from "deepmerge";
import { combineMerge } from "../../utils/merge";

function Donut({ data, title, colors, seriesName, option, seriesOptions, chartProps, chartRef }) {
    if (!seriesOptions) {
        seriesOptions = {}
    }

    const defaultChartOption = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'horizontal',
            left: 'center',
            top: 'bottom'
        },
        series: [
            {
                name: seriesName,
                type: 'pie',
                radius: ['35%', '80%'],
                // width: '200',
                data: data ?[
                    ...Object.keys(data).sort().map(key => ({
                        value: data[key],
                        name: key
                    }))
                ] : [],
        emphasis: {
        itemStyle: {
            shadowBlur: 10,
                shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
    },
    left: 'center',
        label: {
        formatter: function (val) {
            return abbreviateNumber(val.value);
        },
        position: 'inside',
                }
}
        ]
    }

if (colors) {
    if (Array.isArray(colors)) {
        defaultChartOption.color = colors
    } else if (typeof colors === "object") {
        defaultChartOption.color = [
            ...Object.keys(colors).sort().map(key => colors[key])
        ]
    }
}

const effectiveChartOptions = deepmerge(defaultChartOption, (option || {}), {
    arrayMerge: combineMerge
})

const effectiveChartProps = deepmerge({ style: { height: "410px", width: "100%" }}, (chartProps || {}), {
    arrayMerge: combineMerge
})

return <ReactEcharts
    ref={chartRef}
    option={effectiveChartOptions}
    {...effectiveChartProps
    } />
}

Donut.propTypes = {
    data: PropTypes.objectOf(PropTypes.number).isRequired,
    colors: PropTypes.arrayOf(PropTypes.string),
    seriesName: PropTypes.string.isRequired
}

export default Donut;