import React from 'react';
import { useTableList } from "../../../../common/API/hooks";
import { getSelectedOption } from "../../../../common/utils";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';


export default function TableSourceSelector({ onChange, tableId }) {

    const { data: tableList, isLoading } = useTableList()

    const options = tableList?.map(table => {
        return { label: table.name, value: table.id }
    })

    return <Grid item md={12} marginBottom={2}>
        <Autocomplete
            value={getSelectedOption(tableId, options)}
            disableClearable
            onChange={(event, newOption) => onChange(newOption ? newOption.value : null)}
            id="table_source_options" size="small"
            options={options || []}
            getOptionLabel={(option) => option?.label || ""}
            disabled={isLoading}
            //TODO: add loading
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Table Source"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {isLoading ? <CircularProgress color="secondary" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
                required />
            }
        />
    </Grid>
}