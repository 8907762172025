import { useState } from 'react';
import { CategoryContext } from './context';

export default function CategoryProvider({ children }) {
    const [category, setCategory] = useState('top_food_group');
    
    return (
        <CategoryContext.Provider
            value={{
                category,
                setCategory,
            }}
        >
            {children}
        </CategoryContext.Provider>
    );
}
