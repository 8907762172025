import React, { useState, useContext } from "react"
import { Grid, Card, Typography, Box } from "@mui/material"
import RenderBUViewMerchContent from "./renderBUViewMerchContent";
import GroupSelector from "../../selectors/groupSelector";
import { useEnvironmentSetting } from "../../../../../../common/providers/EnvironmentSettingsProvider";
import { ENABLE_CUSTOMER_BU_VIEW } from "../../../../../../Constants/settings";
import {useTogglePeriodContext} from "../Provider/context";

export default function Merchandise({ params, business_unit, filters, onDropdownFilterChange }) {
    const {period} = useTogglePeriodContext()
    const [viewBy, setViewBy] = useState('department') // set operations as default
    const enableCustomerBuView = useEnvironmentSetting(ENABLE_CUSTOMER_BU_VIEW);

    const {showToggle, setShowToggle} = useTogglePeriodContext()

    React.useEffect(() => {
        setShowToggle(true)
    }, [showToggle]);

    const VIEW_BY = [
        { label: 'Department', value: 'department', disabled: false },
        // { label: 'Customer', value: 'customer', disabled: !enableCustomerBuView },
        { label: 'Brand', value: 'brand', disabled: true },
        { label: 'Vendor', value: 'vendor', disabled: true },
        { label: 'Price', value: 'price', disabled: true },
    ]

    return (<>
        <Grid container>
            <Grid item xs={12} py={.5}>
                {/* MOVE INSIDE RenderBUViewMerchContent  */}
                {/* <DepartmentPerformanceTrends params={params} period={period} filters={filters} onDropdownFilterChange={onDropdownFilterChange} /> */}

                <Grid item sm={12} xs={12} sx={{ marginTop: '1rem' }} >
                    <Card variant="outlined" sx={{ borderRadius: '.5rem' }}>
                        <Grid container sx={{ backgroundColor: '#f6f6f8', padding: 1.5 }}>
                            <Grid item xs={12} sm>
                                <GroupSelector options={VIEW_BY}
                                    onSelectGroupChange={(value) => setViewBy(value)} //strings value
                                    selected={viewBy}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={2} display='flex' justifyContent='end'>
                                <ToggleSelectorWithAdditional 
                                    options={toggleOptions}
                                    value={period}
                                    onChange={onTogglePeriodChange}
                                    additionalStyles={{ textTransform: 'none' }}
                                    lastIndexOfMain={2}
                                />
                            </Grid> */}
                        </Grid>
                    </Card>
                </Grid>
                <RenderBUViewMerchContent viewBy={viewBy} business_unit={business_unit} params={params} period={period} filters={filters} onDropDownFilterChange={onDropdownFilterChange} />
            </Grid>
        </Grid>
    </>
    )
}