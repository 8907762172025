import Section from "../../../../common/components/Dashboard/Section";
import CustomerDemography from "./CustomerDemography";

export default function RegistrationContent() {
    const customStyle = {
        backgroundColor: "#f6f6f8",
        borderRadius: ".5rem",
        pb:1,
        mb:-1
    }

    const generations = [
        { name: 'Gen Z', years: '1997 – 2006' },
        { name: 'Millennial', years: '1982 – 1996' },
        { name: 'Gen X', years: '1965 – 1981' },
        { name: 'Boomers', years: '1964 and earlier' }
    ];
    
    const information = (
        <div>
            <strong>Generation</strong> – members are classified based on their birth year:
            {generations.map((generation, index) => (
                <div key={index} style={{ paddingLeft: '10px' }}>
                    {generation.name} – {generation.years}
                </div>
            ))}
        </div>
    );

    return <> 
        <Section title={'Customer Demographics'} isCollapsible={false} customStyle={customStyle} information={information}></Section>
        <CustomerDemography />
    </>
}