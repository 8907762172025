import LoadingComponent from "../../../../../common/components/LoadingComponent"
import ReactEcharts from "echarts-for-react"
import { useSMACMemberByContactInformation } from "../../hooks"
import { abbreviateNumber, sortArrObjectsBasedOnGivenArray, topLineDisplayNumber } from "../../../../../common/utils"
import {CHART_FONT_SIZE} from "../../constants";

export default function ContactInformation() {
    const { data, isLoading } = useSMACMemberByContactInformation()
    const arraySorting = ['City Address', 'Mobile', 'Email']
    const sortedData = sortArrObjectsBasedOnGivenArray(data, arraySorting, 'dimension')
    const graphLabel = [...new Set(sortedData?.map(item => item.dimension))]
    const contactInformationWithData = sortedData?.filter(item => item.dimension_value === 'With Data').map(item => item.total_members)
    const contactInformationNoData = sortedData?.filter(item => item.dimension_value === 'Without Data').map(item => item.total_members)
    
    const totalMembers = sortedData?.filter(data => data.dimension === 'Email').reduce((acc, curr) => acc + curr.total_members, 0); //can get any from Email, Mobile or City Address dimension since the total must be the same for all
    const getPercentage = (value, total) => value / total * 100;
    const formatLabel = (value) => abbreviateNumber(value);
    const options = {
        title: {
            text: 'Contact Information',
            textStyle: {
                color: '#000'
            },
            top: '20px',
            left: '25px'
        },
        tooltip: {},
        legend: {
            textStyle: {
                fontSize: CHART_FONT_SIZE,
            },
            bottom: 25, // Move the legend to the bottom
        },
        animation: false,
        grid: {
            left: '5%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            axisLabel: {
                show: false,
                fontSize: CHART_FONT_SIZE
            },
            splitLine: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            }
        },
        yAxis: {
            type: 'category',
            data: graphLabel,
            splitLine: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                fontSize: CHART_FONT_SIZE
            }
        },
        series: [
            {
                name: 'With Data',
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'none'
                },
                data: contactInformationWithData,
                barWidth: '75%',
                color: '#4E88DD',
                label: {
                    show: true,
                    color: '#FFFFFF',
                    position: 'inside',
                    fontSize: CHART_FONT_SIZE,
                    formatter: (params) => {
                        const percent = getPercentage(params?.value, totalMembers)
                        return `${formatLabel(params?.value)}\n(${percent.toFixed()}%)`
                    }
                }
            },
            {
                name: 'No Data',
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'none'
                },
                data: contactInformationNoData,
                barWidth: '75%',
                color: '#AFAFAF',
                label: {
                    show: true,
                    color: '#FFFFFF',
                    position: 'inside',
                    fontSize: CHART_FONT_SIZE,
                    formatter: (params) => {
                        const percent = getPercentage(params?.value, totalMembers)
                        return `${formatLabel(params?.value)}\n(${percent.toFixed()}%)`
                    }
                }
            },
        ]
    };


    if (isLoading) {
        return <LoadingComponent />
    }

    return <ReactEcharts
        notMerge={true}
        lazyUpdate={false}
        option={options}
        style={{
            height: '20rem',
        }}
    />
}