import React from 'react'
import DepartmentPerformance from './DepartmentPerformance'
import CustomerBUView from '../Customer'
import SelectorProvider from "../../../providers/selectorProvider";


export default function RenderBUViewMerchContent({ viewBy, params, period, business_unit}) {

    switch (viewBy) {
        case 'brand':
            return 'BRAND HERE'
        case 'vendor':
            return 'VENDOR HERE'
        case 'price':
            return 'PRICE HERE'
        case 'customer':
            return <CustomerBUView params={params} togglePeriodValue={period} business_unit={business_unit} />
        default: 
            return <SelectorProvider defaults={{
                metric: 'actual_sales',
                viewBy: 'business_unit'
            }}>
                <DepartmentPerformance period={period}/>
            </SelectorProvider>
    }
}