import React from 'react';
import Grid from '@mui/material/Grid'; // Grid version 1
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import  Typography from '@mui/material/Typography' 
import Stack from '@mui/material/Stack'




export default function DataExplorerBreadcrumb({ schemaList, tableData }) {

    if (!tableData || !schemaList) {
        return null
    }
    const selectedSchemaName = schemaList?.filter(schema => schema.id === tableData?.schema_id)[0]?.name

    let outputString;
    let backgroundColor;

    switch (tableData?.host_type.toLowerCase()) {
        case 'databricks':
            outputString = "Databricks";
            backgroundColor = "blue";
            break;
        case 'sqlite':
            outputString = "SQLite";
            backgroundColor = "green";
            break;
        case 'mysql':
            outputString = "MySQL";
            backgroundColor = "darkmagenta";
            break;
        case 'bigquery':
            outputString = "BigQuery";
            backgroundColor = "darkorange";
            break;
        default:
            outputString = 'UNKNOWN';
            backgroundColor = 'gray';
            break;
    }

    
    return (
        <>
            <Grid container spacing={2}>
                <Grid item sx={{mt:.6}}>   
                    <Stack direction={'row'} sx={{alignItems: 'center'}} >
                        <FolderCopyOutlinedIcon sx={{ fontSize: 28, mr:1}}/>
                        <Typography style={{ fontSize: 18,fontWeight: 'bold', }}> {selectedSchemaName}</Typography> 
                    </Stack>
                          
                </Grid>
            
                <Grid item sx={{mt:.6}}>
                    <Stack direction={'row'} sx={{alignItems:'center'}}>
                        <ChevronRightOutlinedIcon sx={{ fontSize: 30}}/>
                        <Typography style={{ fontSize: 18,fontWeight: 'bold', }}> {tableData?.name}</Typography>
                    </Stack> 
                </Grid>
        
                <Grid item  sx={{ml:{xs:'0', sm:'0',md:'auto'}}}>
                    <Stack direction={'row'} spacing={.5}
                            sx={{color:'white', 
                            backgroundColor:{backgroundColor},
                            borderRadius:'5px',
                            alignItems: 'center',
                            p:.6,
                            px:2
                            }}>
                        <StorageRoundedIcon sx={{fontSize: 20}}/>
                        <Typography sx={{ fontSize: 16,fontWeight: 'bold',}}> {outputString}</Typography>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}