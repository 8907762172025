import {useMemo} from "react";
import CellWithSubRow from "../../../../../common/components/DataTable/CellWithSubRow";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import {LABEL_ALL_BRANCH, useActivityByBusinessUnitTableData} from "../../hooks";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import {useSelectorValue} from "../../../common/providers/selectorProvider";
import {CellDisplay} from "./CellDisplay";
import {CellIndicatorVsLastYear} from "./CellIndicatorVsLastYear";
import NoAvailableDataDisplay from "../../NoAvailableDataDisplay";
import { convertToValidNumber } from "../../../../../common/utils";
import { Grid } from "@mui/material";


const thickBorder = '2px solid #C3C3C3!important'
const customCellStyle = {
    textAlign: "left",
    verticalAlign: "middle",
}

export default function CustomerActivityByBUTable() {
    const {data, isLoading} = useActivityByBusinessUnitTableData()
    const branch = useSelectorValue('branch')


    const tableColumns = useMemo(
        () => {
            return [
                {
                    id: "pk",
                    header: "Business Unit/ Department/ Sub-department",
                    accessorKey: "pk",
                    cell: ({row, getValue}) => {
                        const value = getValue();
                        return (
                            <CellWithSubRow row={row} marginLeftMinimum={1}>
                                {value}
                            </CellWithSubRow>
                        )
                    },
                    customStyle: {
                        width: "15rem",
                        textAlign: "left",
                        verticalAlign: "middle",
                        borderRight: thickBorder
                    },
                    headerStyle: {
                        textAlign: "center",
                        borderRight: thickBorder,
                    }
                },
                {
                    id: "branch",
                    header: branch === 'All Branch' ? LABEL_ALL_BRANCH : branch,
                    columns: [
                        {
                            id: "active_member_contribution",
                            header: "Active Member (% cont.)",
                            accessorKey: "active_member",
                            cell: (row) => {
                                return <CellDisplay
                                    mainValue={row?.getValue()}
                                    subValuePerc={row?.row?.original?.member_perc}
                                />
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },
                        {
                            id: "active_member_last_year",
                            header: "vs Last Year",
                            accessorKey: "active_member_ly",
                            cell: (row) => {
                                const lastYear = row?.getValue();
                                const thisYear = row?.row?.original?.active_member;
                                return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                            },
                            customStyle: {
                                ...customCellStyle,
                                borderRight: thickBorder
                            },
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                                borderRight: thickBorder
                            }
                        },
                        {
                            id: "sales",
                            header: <>
                                Sales (% cont.)
                            </>,
                            accessorKey: "sales",
                            cell: (row) => {
                                return <CellDisplay
                                    mainValue={row?.getValue()}
                                    subValuePerc={row?.row?.original?.sales_perc}
                                />
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },
                        {
                            id: "sales_last_year",
                            header: "vs Last Year",
                            accessorKey: "sales_ly",
                            cell: (row) => {
                                const lastYear = row?.getValue();
                                const thisYear = row?.row?.original?.sales;
                                return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                            },
                            customStyle: {
                                ...customCellStyle,
                                borderRight: thickBorder
                            },
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                                borderRight: thickBorder
                            }
                        },
                        {
                            id: "transactions",
                            header: "Transactions",
                            accessorKey: "txn",
                            cell: (row) => {
                                return <CellDisplay
                                    mainValue={row?.getValue()}
                                />
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },
                        {
                            id: "transactions_last_year",
                            header: "vs Last Year",
                            accessorKey: "txn_ly",
                            cell: (row) => {
                                const lastYear = row?.getValue();
                                const thisYear = row?.row?.original?.txn;
                                return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                            },
                            customStyle: {
                                ...customCellStyle,
                                borderRight: thickBorder
                            },
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                                borderRight: thickBorder
                            }
                        },

                        {
                            id: "avg_trx_value",
                            header: "Avg. Trx Value",
                            accessorKey: "atv",
                            cell: (row) => {
                                return <CellDisplay
                                    mainValue={row?.getValue()}
                                />
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },
                        {
                            id: "avg_trx_value_last_year",
                            header: "vs Last Year",
                            accessorKey: "atv_ly",
                            cell: (row) => {
                                const lastYear = row?.getValue();
                                const thisYear = row?.row?.original?.atv;
                                return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                            },
                            customStyle: {
                                ...customCellStyle,
                                borderRight: thickBorder
                            },
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                                borderRight: thickBorder
                            }
                        },
                        {
                            id: "visit_frequency",
                            header: "Freq. of Visit",
                            accessorKey: "visit_freq",
                            cell: (row) => {
                                return <CellDisplay
                                    mainValue={convertToValidNumber(row?.getValue()).toFixed(0)}
                                />
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },
                        {
                            id: "visit_frequency_last_year",
                            header: "vs Last Year",
                            accessorKey: "visit_freq_ly",
                            cell: (row) => {
                                const lastYear = row?.getValue();
                                const thisYear = row?.row?.original?.visit_freq;
                                return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                            },
                            customStyle: {
                                ...customCellStyle,
                                borderRight: thickBorder
                            },
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                                borderRight: thickBorder
                            }
                        },
                        {
                            id: "avg_spend_per_visit",
                            header: "Avg. Spend / Visit",
                            accessorKey: "avg_spend_visit",
                            cell: (row) => {
                                return <CellDisplay
                                    mainValue={row?.getValue()}
                                />
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },
                        {
                            id: "avg_spend_per_visit_last_year",
                            header: "vs Last Year",
                            accessorKey: "avg_spend_visit_ly",
                            cell: (row) => {
                                const lastYear = row?.getValue();
                                const thisYear = row?.row?.original?.avg_spend_visit;
                                return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                            }
                        },
                    ]
                }
            ]
        },
        [branch]
    );

    if (isLoading) {
        return <LoadingComponent/>
    }

    if (!data || data?.length === 0) {
        return <NoAvailableDataDisplay />
    }

    return <TableComponentV2
            columns={tableColumns}
            data={data || []}
            tableId="customerActivityByBusinessUnit"
            isCompact
            customTableHeaderStyle={{backgroundColor: "#caddeb", textAlign: "center", height: "3em"}}
            customTableContainerStyle={{borderRadius: 0, boxShadow: "none", overflowY: 'auto'}}
            customTableStyle={{ width: '125rem'}}
            hasPagination={false}
        />
}