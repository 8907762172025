import AddUser from "./AddUser";
import UserTable from "./UserTable";
import Search from "./Search";
import { useState } from "react";
import { Grid, Typography, Box, Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from "@mui/material/IconButton";
import Back from '../../../common/components/BackNavigateComponent';

export default function UserManagement() {
    const [searchQuery, setSearchQuery] = useState('');

    return (
        <>
            <Grid sx={{ mt: 3 }} display={'flex'}>
                <Back />
                <Typography variant="h5" style={{ marginLeft: '16px' }}>
                    User Management
                    <Tooltip title={<Typography variant="body2">User Management</Typography>}
                    placement='right'> 
                    <IconButton>
                        <InfoIcon style={{ color: 'grey' }} />
                    </IconButton>    
                    </Tooltip>               
                </Typography>
            </Grid>

            <Divider sx={{ my: 2, borderBottom: 3 }} />

            <Grid sx={{ border: 2, borderRadius: 3, borderColor: "#bdbdbd", backgroundColor: "#F3F3F3", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, p: 1 }}>
                <Grid container alignItems="center" justifyContent="space-between" display={{ xs: 'block', md: 'flex' }} >
                    <Grid item md={3} m={1}>
                        <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Grid>
                    <Grid item m={1}>
                        <AddUser />
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ border: 2, borderRadius: 3, borderColor: "#bdbdbd", borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 0, p: 1 }}>
                <Grid item sm={12}>
                    <UserTable searchQuery={searchQuery} />
                </Grid>
            </Grid>


        </>
    )
}