import React, { useContext, useMemo } from 'react';
import { useSalesPerformanceData } from '../hooks';
import { Grid, Box, Typography } from '@mui/material';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { convertToValidNumber } from '../../../../common/utils';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext'
import TableComponentV2 from '../../../../common/components/TableComponentV2';
import { SHORTEN_SALES_COMPANY } from '../constants';
import BarIndicator from '../../common/presentation/barIndicator';
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import ToggleSelector from '../../common/presentation/selectors/toggleSelector';

const IndicatorCellBox = (props) => (
    <Box sx={{ my: 1, width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);

export default function SalesOverallPerformance({ params, period, onToggleChange }) {

    const windowSize = useContext(WindowSizeContext);
    const isMTD = period === 'mtd';
    const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] }
    const { data, isLoading } = useSalesPerformanceData({
        params: {
            ...paramsBasedOnPeriod,
            group_by: ["company_id", "company"].join(",")
        }
    });

    const company_view_cols = useMemo(
        () => [
            {
                header: 'Company',
                accessorKey: 'company_id',
                cell: (row) => <Box sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                    {SHORTEN_SALES_COMPANY[row?.getValue()]}
                </Box>,
                customStyle: { width: '15rem' }
            },
            {
                header: isMTD ? 'MTD' : 'YTD',
                accessorKey: 'actual_sales',
                cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}><NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /></Box>,
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                header: '% cont.',
                accessorKey: 'percent_contribution',
                cell: (row) => <Box sx={{ width: { sm: '50%' }, marginX: 'auto' }}><NumericValueDisplay value={row?.getValue()} postfix={'%'} /></Box>,
                customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: isMTD ? 'MTD Target' : 'YTD Target',
                accessorKey: 'target_sales',
                cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                header: 'vs Running Daily Target',
                accessorFn: (row) => (row?.actual_sales / row?.daily_target_sales) * 100,
                cell: (row) => <>
                    <IndicatorCellBox>
                        <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                    </IndicatorCellBox>
                </>,
                customStyle: { width: '15rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: isMTD ? 'Last Year MTD' : 'Last Year YTD',
                accessorKey: 'last_year_sales',
                cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /></Box>,
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                header: 'vs Last Year',
                accessorFn: (row) => (row?.actual_sales / row?.last_year_sales) * 100,
                cell: (row) => <>

                    <IndicatorCellBox>
                        <BarIndicator colorFunctionFor={'Sales'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                    </IndicatorCellBox>
                </>,
                customStyle: { width: '15rem', textAlign: 'center', verticalAlign: 'middle' }
            },
        ],
        [period]
    )

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <Grid container>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container alignItems="center" py={.5}>
                    <Grid item xs={6} sm>
                        <Typography variant='h6' ml={2} >Overall Performance</Typography>
                    </Grid>
                    <Grid item sm xs display='flex' justifyContent='end'>
                        <ToggleSelector
                            options={[
                                { label: 'MTD', value: 'mtd' },
                                { label: 'YTD', value: 'ytd' }
                            ]}
                            value={period}
                            onChange={onToggleChange}/>
                    </Grid>
                </Grid>
            </Box>

            <Grid item xs={12} sm={12}>
                <TableComponentV2
                    columns={company_view_cols}
                    data={data}
                    tableId={'companyViewTable'}
                    isCompact={true}
                    customTableHeaderStyle={{
                        backgroundColor: '#caddeb'
                    }} />
            </Grid>
        </Grid>
    )
}