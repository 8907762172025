import React, {useContext, useMemo} from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useSelectorContext, useSelectorValue } from '../../common/providers/selectorProvider';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import FilterMonth from '../../../../common/components/FilterMonth';
import moment from 'moment';
import { PERIOD_MONTHLY, PERIOD_QUARTERLY, PERIOD_WEEKLY } from '../../../../Constants/general';
import {Grid} from "@mui/material";

const BASE_QUARTER_OPTIONS = [
    {label: '1st Quarter', value: 'Q1'},
    {label: '2nd Quarter', value: 'Q2'},
    {label: '3rd Quarter', value: 'Q3'},
    {label: '4th Quarter', value: 'Q4'},
]


export default function PeriodicitySelector({isLoading, dateRange,isSuccess,rawData}) {
    const windowSize = useContext(WindowSizeContext);
    const period = useSelectorValue('period');
    const year = useSelectorValue('year');
    const periodicityFromContext = useSelectorValue('periodicity');
    const periodicity = periodicityFromContext;
    const {setValue: setSelectorValue} = useSelectorContext()
    const setPeriodicity  = (value) => setSelectorValue('periodicity', value)

    //reset periodicity when period is changed
    React.useEffect(() => {
        if(periodicity) {
            setPeriodicity()
        }
    }, [period])
    React.useEffect(() => {
        if (!periodicityFromContext && !!dateRange) {
            if(period === PERIOD_QUARTERLY) {
                setPeriodicity(`Q${moment(dateRange?.to).quarter()}`)
            }

            if(period === PERIOD_MONTHLY){
                setPeriodicity([moment(dateRange?.to).month()+1])
            }
        }
    }, [periodicityFromContext,dateRange,period]);

    const quarterOptions = useMemo(()=>{
        if(period === PERIOD_QUARTERLY && rawData) {
            const availableQuarters = rawData?.filter(dataObj => dataObj.year === year).reduce((acc, curr) => { //used reduce function to remove duplicates
                const quarterExists = acc.some(quarter => quarter.quarter === curr.quarter);
                return quarterExists ? acc : [...acc, curr];
            }, [])

            const NEW_QUARTER_OPTIONS = BASE_QUARTER_OPTIONS.map(dataObj => { //disable quarter options that are not avaible in data
                if(availableQuarters.find(data => data.quarter === dataObj.value)) {
                    return dataObj
                } else {
                    return {...dataObj, isDisabled: true}
                }
            })
            return NEW_QUARTER_OPTIONS
        } else {
            return []
        }
    },[period, year, rawData])

    if(period === PERIOD_WEEKLY) {
        return <></>
    } else if (period === PERIOD_QUARTERLY) {
        const selectedOption = quarterOptions?.find(option => option.value === periodicity);
        return <Grid item sm={2} xs={12} >
            <ConditionalSelectComponent
                id="periodicity_selector"
                labelId="periodicity_selector-label"
                options={quarterOptions}
                label="Quarter"
                value={selectedOption || ''}
                onSelectChange={(event, newValue) => {
                    const value = windowSize.isOnMobile ? event?.target?.value : newValue?.value;
                    setPeriodicity(value);
                }}
                clearSelected={() => setPeriodicity()}
                disableClearable={true}
                isLoading={isLoading}
            />
        </Grid>
    } else if (period === PERIOD_MONTHLY) {
        return <Grid item sm={2} xs={12}>
            <FilterMonth
                onDropdownChange={(value) => setPeriodicity(value)}
                filters={{month: periodicity, year}}
                dateRangeData={dateRange}
                isLoading={isLoading}
                isSuccess={isSuccess}
            />
        </Grid>
    }
}