import React from "react";
import StoreAnalytics from "../../index"
import chart from "../../../../../img/chart-hypermarket.png"
import {companyAcronymToName} from "../../../../../common/utils";

export default function HyperMarketAnalytics() {
    return (
        <StoreAnalytics
            title={"HyperMarket"}
            locationApiEndPoint={'/custom/location/ssmi/'}
            chartViewImage={chart}
            salesApiEndPointName={"frg"}
            apiDefaultFilter={{company: companyAcronymToName("ssmi")}}
            company={"ssmi"}
        />
    );
}