import {useQuery} from "react-query";
import ApiClient from "../../../common/API";
import {TABLE_MBA_ASSOCIATION} from "./constants";

export function useFilterValues({model, company_key, brand}) {
    // returns a list of values for each default filter fields for market basket
    const params = {
        model: model,
        company_key: company_key,
        brand: brand,
        limit: 1000
    }

    return useQuery(['marketbasket', 'filter-values', model, company_key, brand], () => {
        return ApiClient().get('/custom/market-basket/frequent-items/filters/', {params: params}).then(response => {
            const filters = {
                ...response.data
            }
            // remove "All" from each filter
            Object.keys(filters).forEach(key => {
                filters[key] = filters[key].filter(item => item !== "All")
            })
            return filters
        })
    }, {
        cacheTime: 15 * 60 * 1000, // 15 minutes
        staleTime: 15 * 60 * 1000, // 15 minutes
        refetchOnWindowFocus: false
    })
}

export function useFilterValuesV2({column, filters}) {
    // returns a list of values for each default filter fields for market basket
    const params = {
        ...filters,
        columns: column,
        limit: 1000
    }

    return useQuery(['marketbasket', 'filter-values', params], () => {
        return ApiClient()
            .get(`/data/table/${TABLE_MBA_ASSOCIATION}/filter_values/`, {params: params})
            .then(response => {
                const rows = {
                    ...response.data[column]
                }
                const values = Object.values(rows).map(row => row[column]).map(item => {
                    if (typeof item === "string" && item.startsWith('["')) {
                        return JSON.parse(item)
                    } else if (Array.isArray(item)) {
                        return item
                    } else if (typeof item === "string" && item.includes(",")) {
                        return item.split(",")
                    } else {
                        return [item]
                    }
                }).reduce((acc, vals) => {
                    vals.forEach(val => {
                        if (acc.indexOf(val) === -1) {
                            acc.push(val)
                        }
                    })
                    return acc
                }, []).sort()

                return values
            })
    }, {
        cacheTime: 15 * 60 * 1000, // 15 minutes
        staleTime: 15 * 60 * 1000, // 15 minutes
        refetchOnWindowFocus: false
    })
}


export function useModelValues({companyKey}) {
    return useQuery(['marketbasket', 'model-values', [companyKey]], () => {
        return ApiClient().get('/custom/market-basket/frequent-items/filters/', {
            params: {
                columns: "model",
                company_key: companyKey
            }
        }).then(response => {
            return response.data
        })
    }, {
        cacheTime: 15 * 60 * 1000, // 15 minutes
        staleTime: 15 * 60 * 1000, // 15 minutes
        refetchOnWindowFocus: false,
    })
}

export function useProductValues({filters}) {
    // TODO: simplify/remove code duplicate for applied filters
    const defaultFilters = {
        // "model": "Baseline",
    }
    const appliedFilters = {
        ...defaultFilters,
        ...Object.fromEntries(Object.entries(filters).filter(([_, v]) => { // remove null and empty values
            if (Array.isArray(v)) {
                return v.length > 0
            }
            return !!v
        }).filter(([k, _]) => {
            return k !== 'merch_article_desc'
        }))
    }

    return useQuery(['marketbasket', 'product-values', filters], () => {
        return ApiClient().get('/custom/market-basket/frequent-items/filters/', {
            params: {
                columns: "merch_article_desc",
                ...appliedFilters
            }
        }).then(response => {
            return response.data
        })
    }, {
        cacheTime: 15 * 60 * 1000, // 15 minutes
        staleTime: 15 * 60 * 1000, // 15 minutes
        refetchOnWindowFocus: false,
        // FIXME: maybe enable only if there is a category/brand selected? currently receiving 3K+ results
    })
}


export function useFilterValuesByField({fields, filters, table}) {
    // returns a list of values for each field specified
    // usage examples:
    // useFilterValuesByField({fields: 'product_label'})
    // useFilterValuesByField({fields: ['product_label', 'combination_label']})
    // useFilterValuesByField({fields: ['brand', 'category'], filters: {model: 'Baseline'}})
    // useFilterValuesByField({fields: ['brand', 'category'], filters: {model: 'Baseline', company_key: 'company1'}})

    // filters are not required
    if (!filters) {
        filters = {}
    }
    //fields are required
    if (!fields) {
        throw new Error("fields is required")
    }
    // fields can be a string or an array
    if (typeof fields === 'string') {
        fields = [fields]
    }

    // table is optional, default is association-rules
    if (!table) {
        table = 'association-rules'
    }

    // table value should either be 'association-rules' or 'frequent-items'
    if (table !== 'association-rules' && table !== 'frequent-items') {
        throw new Error("table value should either be 'association-rules' or 'frequent-items'")
    }

    return useQuery(['marketbasket', 'product-values', table, fields, filters], () => {
        return ApiClient().get(`/custom/market-basket/${table}/filters/`, {
            params: {
                columns: fields.join(","),
                ...filters
            }
        }).then(response => {
            return response.data
        })
    }, {
        cacheTime: 15 * 60 * 1000, // 15 minutes
        staleTime: 15 * 60 * 1000, // 15 minutes
        refetchOnWindowFocus: false,
        // FIXME: maybe enable only if there is a category/brand selected? currently receiving 3K+ results
    })
}

export function useTopProducts({filters, limit, sortDirection}) {
    // sortDirection should be either 'asc' or 'desc'
    if (!sortDirection) {
        sortDirection = 'desc'
    }
    if (sortDirection !== 'asc' && sortDirection !== 'desc') {
        throw new Error("sortDirection value should either be 'asc' or 'desc'")
    }
    if (!limit) {
        limit = 20
    }

    const defaultFilters = {
        // "model": "Baseline",
    }
    const appliedFilters = {
        ...defaultFilters,
        ...Object.fromEntries(Object.entries(filters).filter(([_, v]) => {
            if (Array.isArray(v)) {
                return v.length > 0
            }
            return !!v
        }))  // remove null and empty values
    }

    if (appliedFilters.merch_article_desc) {
        appliedFilters.merch_article_desc__ilike = `%${appliedFilters.merch_article_desc}%`
        delete appliedFilters.merch_article_desc
    }


    const params = {
        ...appliedFilters,
        limit: limit,
        columns: 'items,frequency',
        order_by: sortDirection === 'asc' ? 'frequency' : '-frequency',
        distinct: true
    }
    return useQuery(['marketbasket', 'top-products', params], () => {
        return ApiClient().get('/custom/market-basket/frequent-items/query/', {
            params: params
        }).then(response => response.data)
    }, {

        cacheTime: 15 * 60 * 1000, // 15 minutes
        staleTime: 15 * 60 * 1000, // 15 minutes
        refetchOnWindowFocus: false,
    })
}

export function useAssociationRulesData({filters, offset, limit, orderBy, assocRulesFilters}) {
    if (!limit) {
        limit = 20
    }

    const defaultFilters = {
        // "model": "Baseline",
    }
    const appliedFilters = {
        ...defaultFilters,
        ...Object.fromEntries(Object.entries(filters).filter(([_, v]) => {
            if (Array.isArray(v)) {
                return v.length > 0
            }
            return !!v
        }))  // remove null and empty values
    }

    const params = {
        ...appliedFilters,
        ...assocRulesFilters,
        show_count: true,
        limit: limit,
        offset: offset,
        columns: 'target_basket,target_basket_brands,target_basket_cat,target_basket_segment,association_type',
        // group_by: 'antecedent,consequent,lift',
        order_by: orderBy,
        distinct: true
    }
    return useQuery(['marketbasket', 'association-rules', params], () => {
        return ApiClient().get(`/data/table/${TABLE_MBA_ASSOCIATION}/query/`, {
            params: params
        }).then(response => response.data)
    }, {

        cacheTime: 15 * 60 * 1000, // 15 minutes
        staleTime: 15 * 60 * 1000, // 15 minutes
        refetchOnWindowFocus: false,
    })
}