import React from "react";
import { useTableDetail } from "../../../../../common/API/hooks";
import Select from "../../../../../common/components/SelectComponent";
import { getSelectedOption } from "../../../../../common/utils";
import { components } from "react-select";
import ColumnOption from "./ColumnOption";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';

export default function AggregateSelector({onChangeColumns, columns, tableId, maxValueCount}) {
    const {data: columnList, isLoading: isLoadingFilters} = useTableDetail(tableId, {
        enabled: !!tableId
    })

    const options = columnList?.aggregates.map(tableAggregate => {
        return {
            label: tableAggregate.name,
            value: tableAggregate.name,
            json_type: tableAggregate.json_type
        }
     })

     const MoreSelectedItems = ({ items }) => {
        const style = {
            marginLeft: "auto",
            background: "#d4eefa",
            borderRadius: "4px",
            fontFamily: "Henry Sans",
            fontSize: "11px",
            padding: "2px",
            order: 99,
            fontWeight: '500'
        };
        const title = items.join(",");
        const length = items.length;
        const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

        return (
            <div style={style} title={title}>
            {label}
            </div>
        );
    };

    const MultiValue = ({ index, getValue, ...props }) => {
        const maxToShow = 2;
        const overflow = getValue()
            .slice(maxToShow)
            .map((x) => x.label);

        return index < maxToShow ? (
            <components.MultiValue {...props} />
        ) : index === maxToShow ? (
            <MoreSelectedItems items={overflow} />
        ) : null;
    };

    const isMulti = maxValueCount > 1 || !maxValueCount;
    
    return <Grid item md={12} marginBottom={2}>
        <Autocomplete
            multiple
            limitTags={3}
            options={options|| []}
            getOptionLabel={(option) => option?.label || ""}
            value={getSelectedOption(columns, options)}
            onChange={(event,e)=> {
                if (isMulti) {
                    onChangeColumns(e ? e.map(option => option.value) : null)
                } else {
                    onChangeColumns(e ? [e.value] : null)
                }
            }}
            id="aggregate_options" size="small"
            disabled={isLoadingFilters || !tableId}
            loading={isLoadingFilters}
            sx={{ width: "100%" }}
            // TODO: RENDER OPTION, COLUMNOPTION
            renderInput={(params) => <TextField {...params} label={"Aggregations"}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {isLoadingFilters ? <CircularProgress color="secondary" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                required/>
            }
        />
    </Grid>
}