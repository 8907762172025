import React, { useContext,useMemo } from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useAllAvailableBranch } from '../hooks';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { getSelectedOption } from '../../../../common/utils';

export default function FilterBranch ({filters, params,onDropdownChange}) {

    const windowSize = useContext(WindowSizeContext)
    const { branches, isLoading } = useAllAvailableBranch({ params:{...params, store_id:null} });

    const options = useMemo(()=> {
        let branchOptions = Array.isArray(branches)
            ? branches
                .filter(item => item && item.branch!== null)
                .map(item => ({
                    value: item.store_id,
                    label: item.store,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
            : [];

            if (filters && filters.store_id) {
                branchOptions = branchOptions.filter(option => option.value !== filters.store_id);
            }

        return branchOptions

    },[branches, filters])


    return (
        <ConditionalSelectComponent
                    id="store_filter"
                    labelId="store_filter-label"
                    options={options}
                    label="Branch"
                    value={filters ? (windowSize.isOnMobile ? filters?.store_id : getSelectedOption(filters?.store_id, options)) : '' || null}
                    onSelectChange={(event,newValue)=>{
                        windowSize.isOnMobile ? onDropdownChange(event.target.value, 'store_id')
                        : onDropdownChange(newValue?.value, 'store_id')  
                    }}
                    endAdornment={true}
                    isLoading={isLoading}
                    clearSelected={() => onDropdownChange('','store_id')}
                    disableClearable={false}
            />
    );

}