import { useChartList } from "../../../../common/API/hooks"
import {useState} from 'react';
import GraphFactory from "../../../../common/components/GraphFactory"
import LoadingComponent from "../../../../common/components/LoadingComponent"
import EditChartModal from "../EditChartModal"
import CardItem from "./Card";
import chartData from "../../../../mockdata/chartData.json"
import ChartSelector from "../ChartSelector";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";

export default function ChartList({sortField, sortOrder, selectedChartType, showAllCharts}) {
    const {data: chartList, isLoading: isLoadingChartList} = useChartList();

    // Handle the sorting here
    let sortedChartList = chartList;

    // Check if chartList is an array before sorting
    if (Array.isArray(chartList) && sortField && sortOrder) {
        sortedChartList = [...chartList].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortField] > b[sortField] ? 1 : -1;
            } else {
                return a[sortField] < b[sortField] ? 1 : -1;
            }
        });
    }

    if (!showAllCharts) {
        sortedChartList = sortedChartList?.filter(chartObj => chartObj.is_mine);
    }
    
    // Filters and maps the card list based on the selected chart type from ChartSelector
    const CardListDisplay = Array.isArray(sortedChartList) && sortedChartList.length > 0 && sortedChartList
        .filter(chartObj => {
            if (!selectedChartType) {
                return chartObj.is_public || chartObj.is_mine;
            } else {
                return (
                    (chartObj.is_public || chartObj.is_mine) &&
                    chartObj.chart_type === selectedChartType.value
                );
            }
        })
        .map(chartObj => (
            <Grid item xs={12} md={4} p={3} key={chartObj?.id}>
                <CardItem chartObj={chartObj}/>
            </Grid>
        ));

    if(isLoadingChartList) {
        return <LoadingComponent/>
    }

    return (
        <>
        <Grid container id="visualization-gallery-main" direction="row"  spacing={2} marginTop={3}>
            {CardListDisplay}
        </Grid>
        <Fab size="medium" color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 25, right: 25}}>
            <EditChartModal isCreateNewChart={true} />
        </Fab>
        </>
        )
}