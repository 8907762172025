import React, { useMemo, useEffect } from "react";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import { useStoreZeroSalesOverView } from "../hooks";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

export default function ZeroSalesOverViewTable({ filters, searchQuery, handleRadioChange, setOverviewTableIsEmpty }) {
    const { zeroSalesData, isLoadingZeroSalesData } = useStoreZeroSalesOverView({
        params: { ...filters },
    });

    useEffect(() => {
        setOverviewTableIsEmpty(!zeroSalesData || zeroSalesData?.length === 0);
    }, [zeroSalesData, setOverviewTableIsEmpty]);

    const filteredData = useMemo(() => {
        if (!searchQuery) return zeroSalesData;
        return zeroSalesData?.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchQuery?.toLowerCase())
            )
        );
    }, [zeroSalesData, searchQuery]);

    const store_zero_sales_columns = useMemo(
        () => [
            {
                id: 'store_name',
                header: 'Store Name',
                accessorKey: "store_name",
                cell: (row) => {
                    const storeId = row?.row?.original?.store_id;
                    const storeName = row?.row?.original?.store_name;
                    return (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mx: 1 }}>
                            <IconButton
                                size="small"
                                onClick={() => handleRadioChange(storeId, storeName)}
                            >
                                {filters?.store_id === storeId ? (
                                    <RadioButtonCheckedIcon sx={{ color: "#1976D2" }} />
                                ) : (
                                    <RadioButtonUncheckedIcon sx={{ color: "#1976D2" }} />
                                )}
                            </IconButton>
                            <Typography>{storeName}</Typography>
                        </Box>
                    );
                },
                customStyle: { width: '15rem' }
            },
            {
                id: 'top_888_article_count',
                header: 'Top 888 Article Count',
                accessorKey: "top_8k_count",
                cell: (row) => {
                    return <Box sx={{ width: { sm: '10%' }, marginX: 'auto' }}>
                        <Typography>{row?.getValue()}</Typography>
                    </Box>
                },
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                id: 'top_4k_article_count',
                header: 'Top 4k Article Count',
                accessorKey: "top_4k_count",
                cell: (row) => {
                    return <Box sx={{ width: { sm: '10%' }, marginX: 'auto' }}>
                        <Typography>{row?.getValue()}</Typography>
                    </Box>
                },
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
        ],
        [filters?.store_id]
    );

    if (isLoadingZeroSalesData) {
        return <LoadingComponent />;
    }

    if (!zeroSalesData || zeroSalesData?.length === 0) {
        return (
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#ffffff", borderRadius: "0 0 .5rem .5rem" }} >
                <Grid container justifyContent="center" alignItems="center" py={6}>
                    <Typography
                        sx={{ fontSize: "1.5rem", color: "black", textAlign: "center" }}
                    >
                        There’s no available data.
                    </Typography>
                </Grid>
            </Box>
        );
    }

    return (
        <Grid container xs={12} >
            <TableComponentV2
                columns={store_zero_sales_columns}
                data={filteredData || []}
                tableId={'storeZeroSalesTable'}
                isCompact={true}
                customTableHeaderStyle={{
                    backgroundColor: '#caddeb',
                }}
                customTableContainerStyle={{
                    borderRadius: "0",
                    boxShadow: 'none',
                }}
                hasPagination={true}
                initialSort={{ id: 'top_888_article_count', desc: true }}
            />
        </Grid>
    );
}