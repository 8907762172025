import {useQuery} from "react-query";
import ApiClient from "../../../common/API";

export const useLocationData = (apiEndpoint) => {

    const {
        isLoading,
        isError,
        data,
        error,
        isSuccess,
    } = useQuery(['competitionCharting', apiEndpoint], () => ApiClient().get(apiEndpoint).then(res => {
        return res.data
    }), {
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000
    });

    return {isLoading, data, isError, error, isSuccess}
}
