import React, { useState } from 'react';
import { useQueryClient } from "react-query";
import ApiClient from "../../../common/API";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import handleError from "../ErrorHandling";
import { toast } from 'react-toastify';

export default function ResetPassword({ user, isOpen, setsetIsOpen }) {
    const queryClient = useQueryClient();
    const openModal = () => {
        setsetIsOpen(true);
    };

    const closeModal = () => {
        setsetIsOpen(false);
    };

    const handleReset = () => {
        ApiClient().post(`user/${(user.row) ? user?.row?.id : user.id}/reset_password/`, {can_change_password: !user.can_change_password})
            .then(() => {
                queryClient.invalidateQueries(['user'])
                closeModal();
                toast.success('Password Reset is sent!');
            })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "User", "password reset");
                return toast.error(errorMessage);
            });
    };

    return (
        <>
            {isOpen && (
                <ConfirmationModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    onConfirm={handleReset}
                    title="Password Reset"
                    message={`Allow the user ${user?.can_change_password ? 'to reset' : 'not to reset'} their password?`}
                />
            )}
        </>
    )
}