import { Grid, styled, ClickAwayListener, IconButton, Box, Typography, Fade } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export default function InformationToolTip({ onChange, open, information }) {

    const LightTooltip = styled(({ className, ...props }) => (
        <ClickAwayListener onClickAway={() => onChange(false)}>
            <Tooltip
                {...props} classes={{ popper: className }} />
        </ClickAwayListener>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.white,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[2],
            fontSize: 11,
            maxWidth: "none",
        },
    }));

    return (
        <Grid>
            <LightTooltip
                onClose={() => onChange(!open)}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                TransitionComponent={Fade}
                arrow
                slotProps={{
                    popper: {
                        sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                            {
                                marginTop: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                            {
                                marginBottom: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                            {
                                marginLeft: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                            {
                                marginRight: '0px',
                            },
                        },
                    },
                }}
                title={
                    <Box component="div" sx={{ maxHeight: '280px', overflow: 'auto' }}  px={1}>
                        <Typography>
                            { information }
                        </Typography>
                    </Box>
                }
            >
                <IconButton onClick={() => onChange(!open)}> <InfoOutlinedIcon sx={{ color: "#0A4FB4" }} /></IconButton>
            </LightTooltip >
        </Grid >
    )
}
