import { useContext, useRef, useState } from "react";
import SideItems from "./SideItems";
import { companyAcronymToName, SwitchCoordinate } from "../../../common/utils";
import RenderChartView from "./ChartView";
import Map from "./Map"
import LoadingComponent from "../../../common/components/LoadingComponent";
import TrendIndicator from "./CustomComponents/trendIndicator";
import { useLocationData } from "./apiHooks";
import { StoreAnalyticsFilterContext, StoreAnalyticsFilterContextDefaultValue } from "./contexts";
import { useProvincePolygonData, useRegionPolygonData } from "./Map/util";
import { WindowSizeContext } from '../../../common/context/WindowSizeContext.js';
import Sales from "../Sales";
import TopIndicators from "./topIndicators";
import { getDateDisplay } from "./SideItems/util";
import { Divider, Grid, Typography, Tab, Box, Tabs, Stack } from "@mui/material";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import BarChartIcon from '@mui/icons-material/BarChart';

import SalesByRegionChart from "./SideItems/StoreSideItems/salesByRegionChart";
import Back from "../../../common/components/BackNavigateComponent";
import Indicators from "./SideItems/StoreSideItems/indicators";

const PH_BOUNDS = [
    [
        23.704894502324912,
        132.84667968750003
    ],
    [
        1.5818302639606454,
        110.69824218750001
    ]
]


// TODO:All store analytics pages MUi implementation or revamp. All styling of page will be destroyed completely when import of bootstrap is remove.
export default function StoreAnalytics(
    {
        title, locationApiEndPoint, chartViewImage,
        salesApiEndPointName,
        apiDefaultFilter,
        isLocationMapping,
        company
    }
) {

    const hasCharts = !isLocationMapping;

    const windowSize = useContext(WindowSizeContext);
    const IS_LONG_LAT = true;
    const DEFAULT_ZOOM_LVL = 6;
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [filters, setFilters] = useState({
        "category": [],
        "company": []
    })
    const [searchTerms, setSearchTerms] = useState([]);
    const [dateFilter, setDateFilter] = useState({})

    const [populationFilters, setPopulationFilters] = useState({ gender: [], ageGroup: [] })
    //const [activeTab, setActiveTab] = useState(windowSize.isOnMobile && hasCharts ? 'chart' : 'map')
    const [activeTab, setActiveTab] = useState('map-view'); // Initialize activeTab

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const isMapView = activeTab === 'map'

    const showTopIndicators = isMapView && !isLocationMapping;
    const isChartView = activeTab === 'chart'

    const groupRef = useRef()
    const mapRef = useRef()

    const [filter, setFilter] = useState(StoreAnalyticsFilterContextDefaultValue)
    const [polygonLevel, setPolygonLevel] = useState('region')

    const { isLoading: isLoadingRegionPolygon, data: regionPolygonData } = useRegionPolygonData()
    const { isLoading: isLoadingProvincePolygon, data: provincePolygonData } = useProvincePolygonData()

    const {
        isLoading: isLocationLoading,
        isError,
        data: locationData,
        error,
    } = useLocationData(locationApiEndPoint)

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, id } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={id}
                aria-labelledby={id}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    function getFilteredBounds(newFilters) {
        if (!newFilters.region) {
            return PH_BOUNDS
        }
        if (!newFilters.province) {
            const positions = regionPolygonData.filter(data => data.region === newFilters.region).map(val => val.geometry)
            return !IS_LONG_LAT ? positions : positions?.map(SwitchCoordinate)
        } else {
            const positions = provincePolygonData.filter(data => data.province === newFilters.province).map(val => val.geometry)
            console.log("Change bounds for province", newFilters.province)
            return !IS_LONG_LAT ? positions : positions?.map(SwitchCoordinate)
        }
    }


    function changeBounds(newFilters) {
        const map = mapRef.current;
        map?.fitBounds(getFilteredBounds(newFilters))
    }

    function setView(lat, long) {
        const map = mapRef.current;
        map.setView([lat, long], 12)
    }

    const setFilterWithEffects = (key, value) => {
        setFilter(prevState => {
            const newFilters = { ...prevState, [key]: value }

            if (key === 'region') {
                if (value) {
                    setPolygonLevel("province")
                } else {
                    setPolygonLevel("region")
                }
                if (newFilters.province) {  // reset this if region is changed
                    newFilters.province = null
                }
                changeBounds(newFilters)
            } else if (key === 'province') {
                if (value) {
                    setPolygonLevel("city")
                } else {
                    setPolygonLevel("province")
                }
                changeBounds(newFilters)
            } else if (key === 'branch_id') {
                if (value) {
                    const selectedBranch = locationData.find(row => row.id === value)
                    setSelectedLocations([selectedBranch])
                    setView(selectedBranch?.latitude, selectedBranch?.longitude)
                } else {
                    setSelectedLocations([])
                    changeBounds(newFilters)
                }

            }
            return newFilters
        })
    }

    const fitMap = () => {
        window.setTimeout(() => {
            const group = groupRef.current;
            const map = mapRef.current;
            map.fitBounds(group.getBounds())
        }, 200)

    }

    function onChangePopulation(demographicName, value, isChecked) {
        if (isChecked) {
            setPopulationFilters({
                ...populationFilters,
                [demographicName]: [...(populationFilters[demographicName] || []), value]
            })
        } else {
            setPopulationFilters({
                ...populationFilters,
                [demographicName]: populationFilters[demographicName].filter(val => val !== value)
            })
        }
    }

    if (isLocationLoading || isLoadingRegionPolygon) return <LoadingComponent />

    if (isError) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Box>
                    <Typography variant="h6" align="center" gutterBottom>
                        Encountered an error
                    </Typography>
                    <Typography variant="body1" align="center">
                        Error Code: {error?.code}
                    </Typography>
                    <Typography variant="body1" align="center">
                        Error Message: {error?.message}
                    </Typography>
                </Box>
            </Box>
        )
    }

    const frgCompanyName = companyAcronymToName(company);


    return (
        <StoreAnalyticsFilterContext.Provider value={{
            ...filter,
            setFilter: setFilterWithEffects
        }}>

            <Grid container sx={{ mt: 3 }} alignItems="center">
                <Grid item>
                    <Back />
                </Grid>
                <Grid item direction="row" sx={{ ml: 1, display: 'flex', alignItems:'center'}} >
                        <Typography variant="h5"> {title} </Typography>
                        <Typography style={{ marginLeft: '5px' }}>(as of {getDateDisplay(dateFilter)}) </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={windowSize.isOnMobile ? 12 : 9} >
                    <Grid>
                        <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="Navigation Tabs"
                            >

                                <Tab id="nav-map-view-tab" label={<Stack direction='row' alignItems={'center'} spacing={.5}>
                                    <FmdGoodIcon />
                                    <Typography sx={{ fontWeight: `${activeTab === 'map' ? 'bold' : ''}` }}>
                                        MAP VIEW
                                    </Typography>
                                </Stack>} sx={{ textTransform: 'none', '&.Mui-selected': { color: 'primary' } }} />

                                <Tab id="nav-map-view-tab" label={<Stack direction='row' alignItems={'center'} spacing={.5}>
                                    <BarChartIcon />
                                    <Typography sx={{ fontWeight: `${activeTab === 'chart' ? 'bold' : ''}` }}>
                                        CHART VIEW
                                    </Typography>
                                </Stack>} sx={{ textTransform: 'none', '&.Mui-selected': { color: 'primary' } }} />
                                {frgCompanyName && <Tab label="Sales View" id="nav-sales-view-tab" />}
                            </Tabs>
                        </Grid>

                        {/* Map View, Mobile View */}
                        {
                            windowSize.isOnMobile && <SideItems
                                regionData={regionPolygonData}
                                onChangePopulation={onChangePopulation}
                                populationFilters={populationFilters}
                                isMapView={isMapView}
                                title={title}
                                isLocationMapping={isLocationMapping}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                salesApiEndPointName={salesApiEndPointName}
                                apiDefaultFilter={apiDefaultFilter}
                                locationApiEndPoint={locationApiEndPoint}
                            />
                        }

                        <TabPanel value={value} index={0} id="nav-map-view-panel">

                            {
                                !windowSize.isOnMobile &&
                                <Grid container spacing={2} direction={'row'}>
                                    <TrendIndicator setDateFilter={setDateFilter} dateFilter={dateFilter} indicatorName={"Total Sales"}
                                        salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter} />
                                    <TrendIndicator setDateFilter={setDateFilter} dateFilter={dateFilter} aggregate={'total_transactions'}
                                        salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter}
                                        indicatorName={"Total Transactions"} />

                                    <TrendIndicator setDateFilter={setDateFilter} dateFilter={dateFilter} aggregate={'atv'}
                                        salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter}
                                        indicatorName={"ATV"} />
                                </Grid>
                            }
                            {
                                windowSize.isOnMobile &&
                                <TopIndicators
                                    dateFilter={dateFilter}
                                    setDateFilter={setDateFilter}
                                    salesApiEndPointName={salesApiEndPointName}
                                    apiDefaultFilter={apiDefaultFilter}
                                />
                            }   
                            {/* (Indicators) Map View, Mobile View */}
                            {
                                windowSize.isOnMobile &&
                                <Indicators
                                    salesApiEndPointName={salesApiEndPointName}
                                    apiDefaultFilter={apiDefaultFilter}
                                    dateFilter={dateFilter}
                                />
                            }
                            <Map groupRef={groupRef} mapRef={mapRef}
                                salesApiEndPointName={salesApiEndPointName}
                                apiDefaultFilter={apiDefaultFilter}
                                searchTerms={searchTerms}
                                filters={filters}

                                locationData={locationData}
                                selectedLocations={selectedLocations}
                                regionalPolygonData={regionPolygonData}
                                populationFilters={populationFilters}
                                isLocationMapping={isLocationMapping}
                                dateFilter={dateFilter}
                                setPolygonLevel={setPolygonLevel}
                                polygonLevel={polygonLevel}
                            />

                            {/* (Bargraph) Map View */}
                            <SalesByRegionChart
                                salesApiEndPointName={salesApiEndPointName}
                                apiDefaultFilter={apiDefaultFilter}
                                dateFilter={dateFilter}
                                isMapView={isMapView}
                            />

                            


                            {/* (Carousel)  */}
                            {
                                (windowSize.isOnMobile && showTopIndicators) && <TopIndicators
                                    dateFilter={dateFilter}
                                    setDateFilter={setDateFilter}
                                    salesApiEndPointName={salesApiEndPointName}
                                    apiDefaultFilter={apiDefaultFilter}
                                />
                            }

                        </TabPanel>

                        <TabPanel value={value} index={1} id="nav-chart-view-panel">
                            {
                                windowSize.isOnMobile &&
                                <TopIndicators
                                    dateFilter={dateFilter}
                                    setDateFilter={setDateFilter}
                                    salesApiEndPointName={salesApiEndPointName}
                                    apiDefaultFilter={apiDefaultFilter}
                                />
                            }

                            {/* (Indicators) Map View, Mobile View */}
                            {
                                (windowSize.isOnMobile) && <Indicators
                                    salesApiEndPointName={salesApiEndPointName}
                                    apiDefaultFilter={apiDefaultFilter}
                                    dateFilter={dateFilter}
                                />
                            }

                            <RenderChartView
                                placeholderImage={chartViewImage}
                                dateFilter={dateFilter}
                                apiDefaultFilter={apiDefaultFilter}
                                salesApiEndPointName={salesApiEndPointName}
                                setDateFilter={setDateFilter}
                            />

                        </TabPanel>
                        {frgCompanyName && (
                            <TabPanel value={value} index={2} id="nav-sales-view-panel">
                                <Sales company={frgCompanyName} />
                            </TabPanel>
                        )}
                    </Grid>
                </Grid>


                {/* (Filter and Indicators) Web View */}
                {
                    !windowSize.isOnMobile &&
                    <Grid item xs={12} md={3} px={2}>
                        <SideItems
                            regionData={regionPolygonData}
                            onChangePopulation={onChangePopulation}
                            populationFilters={populationFilters}
                            isMapView={isMapView}
                            title={title}
                            isLocationMapping={isLocationMapping}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            salesApiEndPointName={salesApiEndPointName}
                            apiDefaultFilter={apiDefaultFilter}
                            locationApiEndPoint={locationApiEndPoint}
                        />
                    </Grid>
                }

                {
                    !windowSize.isOnMobile && isMapView &&
                    <Grid container spacing={1} direction={'row'}>
                        <Grid item my={1}>
                            <TrendIndicator setDateFilter={setDateFilter} dateFilter={dateFilter} indicatorName={"Total Sales"}
                                salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter} />
                        </Grid>
                        <Grid item my={1}>
                            <TrendIndicator setDateFilter={setDateFilter} dateFilter={dateFilter} aggregate={'total_transactions'}
                                salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter}
                                indicatorName={"Total Transactions"} />
                        </Grid>
                        <Grid item my={1}>
                            <TrendIndicator setDateFilter={setDateFilter} dateFilter={dateFilter} aggregate={'atv'}
                                salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter}
                                indicatorName={"ATV"} />
                        </Grid>

                    </Grid>
                }
            </Grid>
        </StoreAnalyticsFilterContext.Provider>
    )
}