import {useState} from 'react';
import SkuListPerSegment from "./SkuListPerSegment";
import ToggleSort from "../Filters/ToggleSort";
import RowCountSelector from "../Filters/RowCountSelector";
import {SEGMENT_BY_COLUMN, SEGMENT_COLOR_MAP} from "../constants";

import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography'
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';


export default function TransactionSales ({filters}) {
    const [selectedValue, setSelectedValue] = useState('total_sales_sum');
    const [activeTab,setActiveTab] = useState(0)
    const [sortDirection, setSortDirection] = useState("desc");
    const [rowCount, setRowCount] = useState(5);

    const handleTabChange = (event, tabValue) =>{
        setActiveTab(tabValue);
        setSelectedValue(tabValue === 0 ? 'total_sales_sum' : 'total_transactions_sum')
    }

    const originalKeyToNewKey = {
        "brand": "merch_brand_name",
        "category": "merchcat_dept_desc",
    }

    const cleanedFilters = filters ? Object.keys(filters).filter(filterKey => {
        // if value is string and it is empty string, exclude it
        if (typeof filters[filterKey] === "string" && filters[filterKey].length === 0) {
            return false
        }
        // if value is array and it is empty array, exclude it
        if (Array.isArray(filters[filterKey]) && filters[filterKey].length === 0) {
            return false
        }
        return filterKey !== "model"
    }).reduce((acc, curr) => {
        if (originalKeyToNewKey[curr]) {
            acc[originalKeyToNewKey[curr]] = filters[curr]
        } else {
            acc[curr] = filters[curr]
        }

        return acc
    }, {}) : filters;


    return (
        <>
            <Grid container>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="Total Sales Total Transactions" 
                    TabIndicatorProps={{sx:{backgroundColor:'blue'}}}>
                    <Tab label={<Stack direction='row' alignItems={'center'} spacing={.5}>
                                    <BarChartRoundedIcon />

                                    <Typography sx={{fontWeight:`${activeTab === 0 ? 'bold': ''}`}}>
                                        Total Sales(In PHP)
                                    </Typography>
                            </Stack>} sx={{textTransform:'none','&.Mui-selected': { color: 'blue'}}}/>

                    <Tab label={<Stack direction='row' alignItems={'center'} spacing={.5}>
                                    <TimelineRoundedIcon />

                                    <Typography sx={{fontWeight:`${activeTab === 1 ? 'bold': ''}`}}>
                                        Total Transactions
                                    </Typography>
                            </Stack>} sx={{textTransform:'none', '&.Mui-selected': { color: 'blue'}}}/>
                </Tabs>
            </Grid>
            
            <Card sx={{p:3,mb:4, mt:2}}>
                <Grid  container justifyContent="center" spacing={1}>
                    <Grid item container justifyContent={{xs:'center', md:'flex-end'}} xs={12} sm={9} md={11}>
                        <RowCountSelector rowCount={rowCount} setRowCount={setRowCount}/>
                    </Grid>

                    <Grid item container justifyContent={{md:'flex-end'}} xs={12} sm={3} md={1}>
                        <ToggleSort sortDirection={sortDirection} setSortDirection={setSortDirection} />
                    </Grid>
                </Grid>


                <Grid sx={{mx:3, my:2}}>
                    <Grid xs={12}>
                        <Grid container direction={'row'}>
                            {
                                SEGMENT_BY_COLUMN[selectedValue].map(segment =>(
                                    <Grid xs={12} sm={6} lg={3} sx={{mb:4}}>
                                        <SkuListPerSegment
                                            title={segment}
                                            filters={{
                                                ...cleanedFilters,
                                                product_segment_desc: segment
                                            }}
                                            sortDirection={sortDirection}
                                            valueColumn={selectedValue}
                                            color={SEGMENT_COLOR_MAP[segment]}
                                            rowCount={rowCount}
                                        />
                                    </Grid>
                                ))
                            }   
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}