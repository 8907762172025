import React from "react";
import {useChartDetail} from "../../API/hooks";
import GraphFactory from "./index";
import {processFilterObjectArray} from "./util";


export default function GraphById({graphId, filters}) {
    const {data, isLoading, isError} = useChartDetail(graphId)


    if (isLoading) {
        return <div className={"d-flex justify-content-center align-items-center h-100"}>
                <span className="spinner-border  text-primary" role="status" aria-hidden="true"/>
        </div>
    }

    if (isError) {
        return <div className={"d-flex justify-content-center align-items-center h-100"}>
            <span className="text-danger">
                Error loading graph
            </span>
        </div>
    }

    const processedFilters = processFilterObjectArray(filters, data.filter_fields)
    
    return <GraphFactory
        chart_type={data.chart_type}
        table_source={data.table_source}
        extra_data={data.extra_data}
        title={data.title}
        filters={processedFilters}
        />
}