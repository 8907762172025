import React from "react";
import { CHART_TYPE_OPTIONS } from "../constants";
import { getSelectedOption } from "../../../../common/utils";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from "@mui/material/Typography";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from "@mui/material";

export default function BasicDetailEditor({ chartData, setChartData, onInputChange }) {
    return (
        <Box marginTop={2}>
            <Grid item md={12} marginBottom={2}>
                <TextField label="Title" id="title" placeholder="Chart Title" size="small" required fullWidth
                    value={chartData?.title} onChange={(e) => onInputChange('title', e.target.value)} />
            </Grid>

            <Grid item md={12} marginBottom={2}>
                <TextField label="Description" id="description" placeholder="Chart Description" size="small" fullWidth
                    multiline
                    rows={2}
                    value={chartData?.description}
                    onChange={(e) => onInputChange('description', e.target.value)}
                />
            </Grid>

            <Grid item md={12} marginBottom={2}>
                <Autocomplete
                    value={getSelectedOption(chartData?.chart_type, CHART_TYPE_OPTIONS)}
                    disableClearable
                    onChange={(event, newChartTypeOption) => {
                        setChartData(prevState => {
                            const newData = {
                                ...prevState,
                                chart_type: newChartTypeOption ? newChartTypeOption.value : null,
                            }
                            return newData
                        })
                    }}
                    id="chart_type_options" size="small"
                    options={CHART_TYPE_OPTIONS}
                    sx={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params} label="Chart Type" required />}
                />
            </Grid>

            <Grid item md={12} display="flex" justifyContent="flex-start">
                <FormGroup>
                    <FormControlLabel sx={{ marginLeft: "0" }} label={
                        <Box component="span" sx={{ display: 'flex' }}>
                            <Typography fontWeight="bold" marginRight={.5} marginTop={1}>
                                Make Chart Public
                            </Typography>
                            <Tooltip title={<Typography variant="body2">Make this chart visible to everyone</Typography>}
                                disableFocusListener
                                disableTouchListener
                                arrow
                                placement='top'>
                                <IconButton>
                                    <InfoOutlinedIcon style={{ color: 'grey' }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    } labelPlacement="start"
                        control={
                            <Checkbox
                                checked={chartData?.is_public}
                                onChange={(e) => onInputChange('is_public', e.target.checked)}
                                id="is_public" />}
                    />
                </FormGroup>
            </Grid>
        </Box>
    )
}