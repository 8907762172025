import ReactECharts from 'echarts-for-react';
import {abbreviateNumber, topLineDisplayNumber} from '../../../../common/utils';
import {CHART_FONT_SIZE} from "../constants";


export default function HorizontalBarGroupGraph({ title, seriesData, xAxisData }) {


    const option = {
        title: {
            text: title,
            textStyle: {
                color: '#000000'
            },
            top: '20px',
            left: '25px',
        },
        animation: false,
        legend: {
            bottom: 0,
            textStyle: {
                fontSize: CHART_FONT_SIZE
            }
        },
        grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, .2],
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#000000'
                }
            },
            axisLabel: {
                formatter: function (value) {
                    return abbreviateNumber(value);
                },
                textStyle: {
                    fontSize: CHART_FONT_SIZE
                }
            }
        },
        yAxis: {
            type: 'category',
            data: xAxisData,
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#000000'
                }
            },
            axisTick: { show: false },
            axisLabel: {
                textStyle: {
                    fontSize: CHART_FONT_SIZE
                }
            }
        },
        series: [
            ...seriesData.map(item => ({
                name: item?.name,
                type: 'bar',
                data: item?.dataLabel.map((dataPoint, index) => ({
                    value: dataPoint,
                    label: {
                        color: item?.textColor[index],
                        formatter: (params) => {
                            const currentData = item?.dataLabel[params.dataIndex];
                            const subdataLabelValue = item?.subdataLabel[params.dataIndex]?.toFixed(0);
                            const percentagePoint = params && item?.dataLabelPP
                                ? item.dataLabelPP[params.dataIndex] !== undefined
                                    ? item.dataLabelPP[params.dataIndex] + "pp"
                                    : null // or any default value you prefer
                                : [];
                            return `{default_color|${topLineDisplayNumber(currentData)} (${subdataLabelValue}%)} ${percentagePoint}`;
                        },
                        rich: {
                            default_color: {
                                color: '#000000',
                                fontSize: CHART_FONT_SIZE
                            }
                        },
                        textStyle: {
                            fontSize: CHART_FONT_SIZE
                        }
                    }
                })),
                itemStyle: { color: item?.color },
                label: {
                    show: true,
                    position: 'right'
                },
                barGap: '75%'
            })),
        ]
    };


    return <ReactECharts option={option} notMerge={true} lazyUpdate={true} style={{ height: '300%' }} />
}