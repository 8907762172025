import { useState,useEffect } from "react";
import ApiClient from "../API";
import { useQuery  } from 'react-query'

export default function useChannelOptions(optionFor) {
    const { isLoading, isError, data, error, isSuccess } = useQuery('vibersmsOptions',()=> ApiClient().get('/provider').then(res => {
        return res.data
    }), {
        refetchOnWindowFocus: false,
        staleTime: 60 * 15000 // 15 minutes
    });

    let options = []

    function getOptions(data,fieldName,) {
        return data?.map(optionObj => {
            return optionObj?.[fieldName] ? optionObj[fieldName] : []
        })?.flat()?.map(option=> {
            return { 'label' : option.name, 'value' : option.id }
        })
    }

    if(isSuccess) {
        if(optionFor === 'viber') {
            options = getOptions(data.results, 'FromViber')
        }
    
        if(optionFor === 'sms') {
            options = getOptions(data.results, 'FromSms')
        }

        if(optionFor === 'email') {
            options = getOptions(data.results, 'FromEmail')
        }
    }

    return { isLoading, options};
}