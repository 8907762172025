import React from "react";
import {useTableDetail} from "../../../../../common/API/hooks";
import Select from 'react-select'
import {getSelectedOption} from "../../../../../common/utils";
import ColumnOption from "./ColumnOption";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';

export default function AxisSelector({axisName, axisLabel, setChartData, chartData}) {

    const {data: tableData, isLoading: isLoadingFilters} = useTableDetail(chartData.table_source, {
        enabled: !!chartData.table_source
    })

    const columnOptions = tableData?.columns.map(column => {
        return {
            label: column.name,
            value: column.name,
            get_param: "columns",
            json_type: column.json_type
        }
    })

    const aggregateOptions = tableData?.aggregates.map(agg => {
        return {
            label: agg.name,
            value: agg.name,
            get_param: "aggregates",
            json_type: agg.json_type
        }
    })

    const options = [...(columnOptions || []), ...(aggregateOptions || [])].sort((a, b) => {
        return a.label.localeCompare(b.label)
    })

    let tooltipContent;
    if (axisName === 'x_axis') {
        tooltipContent = 'Cause of the data';
    } else if (axisName === 'y_axis') {
        tooltipContent = 'Effect of the data';
    }
    
    return <Grid item md={12} marginBottom={2}>
        <Autocomplete
            value={chartData.extra_data[axisName]}
            onChange={(event,onChangeValue) => {
                if (!onChangeValue) {
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            extra_data: {
                                ...prevState.extra_data,
                                [axisName]: {}
                            }
                        }
                    })
                } else {
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            extra_data: {
                                ...prevState.extra_data,
                                [axisName]: onChangeValue
                            }
                        }
                    })
                }
            }}
            id="axis_options" size="small"
            options={options|| []}
            getOptionLabel={(option) => option?.label || ""}
            disabled={isLoadingFilters || !chartData.table_source}
            loading={isLoadingFilters}
            sx={{ width: "100%" }}
            // TODO: RENDER OPTION, COLUMNOPTION
            renderInput={(params) => <TextField {...params} label={axisLabel} helperText={tooltipContent}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {isLoadingFilters ? <CircularProgress color="secondary" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
            }
        />
    </Grid>
}
