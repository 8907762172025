import CollapsibleComponent from "../../../../../common/components/CollapsibleComponent";
import { Grid, Box, Typography, Collapse } from "@mui/material"
import React, { useState, useContext, useMemo } from "react";
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import { useTogglePeriodContext } from "../../Provider/context";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { sortByBasketSize } from "../../util";


export default function CustomerAverageDailySpendTable({ customerData, isLoading, period }) {

    const [isExpand, setExpand] = useState(true);
    const windowSize = useContext(WindowSizeContext);

    const customer_avg_daily_spend_columns = useMemo(
        () => [
            {
                header: 'Basket Size Tiers',
                id: 'basket_size_range',
                accessorKey: 'basket_size',
                // rowSpan: 2,
                cell: (row) => {
                    return <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> {row?.getValue()} </Box>
                },
                customStyle: { width: '10rem', height: '2rem', textAlign: 'left', verticalAlign: 'middle' },
                sortingFn: sortByBasketSize
            },
            {
                header: 'Customer Count',
                accessorKey: 'customer_count',
                cell: (row) => <Box sx={{ width: { sm: '%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay
                    value={row?.getValue()} isAbbreviated={true} /> </Box>,
                customStyle: { width: '12rem', height: '2rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                header: 'Total Transaction Count',
                accessorKey: 'total_txn_count',
                cell: (row) => <Box sx={{ width: { sm: '%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay
                    value={row?.getValue()} isAbbreviated={true} /> </Box>,
                customStyle: { width: '12rem', height: '2rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                header: 'Total Sales',
                accessorKey: 'total_sales',
                cell: (row) => <Box sx={{ width: { sm: '%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay
                    prefix={'₱'} value={row?.getValue()} isAbbreviated={true} /> </Box>,
                customStyle: { width: '12rem', height: '2rem', textAlign: 'left', verticalAlign: 'middle', }
            },
            {
                header: '% Sales Distribution',
                accessorKey: 'distribution',
                cell: (row) => <Box sx={{ width: { sm: '%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay
                    value={row?.getValue()} postfix={'%'} isAbbreviated={true} /> </Box>,
                customStyle: { width: '12rem', height: '2rem', textAlign: 'center', verticalAlign: 'middle' }
            },
        ],
        [period, customerData, windowSize?.isOnMobile]
    )

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container alignItems="center" py={.5}>
                    {
                        windowSize.isOnMobile ? <>
                            <Grid item xs={12} display="flex" justifyContent={'space-between'} alignItems={'center'}
                                mx={.5}>
                                <Typography variant="h6" ml={2.5}>
                                    Customer Average Spend per Visit
                                </Typography>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                        </> : <>
                            <Grid item xs={9}>
                                <Typography variant="h6" ml={2.5}>
                                    Customer Average Spend per Visit
                                </Typography>
                            </Grid>
                            <Grid item xs justifyContent={'end'}>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Collapse in={isExpand}>
                        <TableComponentV2
                            columns={customer_avg_daily_spend_columns}
                            data={customerData}
                            // data={sortArrayBasedOnGivenArray(noNullRowData, newOrderArray, filters?.group)}
                            tableId={'customerAverageDailySpendTable'}
                            isCompact={true}
                            customTableHeaderStyle={{
                                backgroundColor: '#caddeb'
                            }}
                        // initialState={{
                        //     columnVisibility: { "12th_mth_sales_trend": !isMTD && !isQoQ }
                        // }}
                        />
                    </Collapse>
                </Grid>
            </Grid>
        </>
    );
}   