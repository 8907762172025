import React, {useEffect} from 'react'
import {Grid, Typography} from '@mui/material'
import {useFiltersContext, useTogglePeriodContext} from '../Provider/context';
import {useParams} from 'react-router-dom';
import CustomerBrandPerformance from './CustomerBrandPerformance';
import TopLine from "../../TopLine";
import ToplineItemsProvider from "../../CustomerTopline/ToplineItemsProvider";
import {CUSTOMER_FILTER_DEFINITIONS} from "../constants";
import {createPortal} from "react-dom";
import {MONTH} from "../../../../../../Constants/general";
import {Edit} from "@mui/icons-material";
import Button from "@mui/material/Button";
import AsOfDate from "../../../../Customers/AsOfDate";
import SMACCustomerInformation from '../../CustomerTopline/SMACCustomer';
import Demographics from './Demographics';
import {useSelectorState, useSelectorValue} from "../../../providers/selectorProvider";
import CustomerFilters from "../CustomerFilters";
import {useFiltersContextAsParams} from "../CustomerLocation/hooks";


export default function CustomerBUView() {
    // TODO: refactor and simplify filter handling
    // TODO: implement centralized debounce on filters after simplification
    const {business_unit} = useParams()
    const [isFiltersOpen, setIsFiltersOpen] = useSelectorState('isFiltersOpen', false);

    const {showToggle, setShowToggle} = useTogglePeriodContext()

    React.useEffect(() => {
        setShowToggle(true)
    }, [showToggle]);


    const year = useSelectorValue('year');
    const month = useSelectorValue('month');

    const {period} = useTogglePeriodContext();

    const params = {
        month: [Number(month)],
        year: Number(year),
        pm_business_unit_desc_standardized: business_unit,
    }

    const filtersAsParams = useFiltersContextAsParams(CUSTOMER_FILTER_DEFINITIONS);

    const selectedYearMonthText = React.useMemo(() => {
        if (!month || !month.length) {
            return '';
        }
        const maxMonth = Math.max(...month)
        return `${MONTH.find(o => o.value === maxMonth)?.label} ${year}`
    }, [month, year])

    return (
        <Grid container>

            {
                document.getElementById('header-extra-info') && createPortal(<>
                    <Grid container>
                        {selectedYearMonthText && <Grid item xs={12} textAlign="right">
                            <Typography ml={5} display="inline">Showing Data for </Typography>
                            <Button
                                onClick={() => {
                                    setIsFiltersOpen(true)
                                }}
                                sx={{
                                    fontSize: "1rem",
                                    verticalAlign: "unset",
                                    padding: 0,
                                    // textDecoration: "underline",
                                    textTransform: "capitalize",
                                }}
                            >
                                {selectedYearMonthText}
                                <Edit/>
                            </Button>
                        </Grid>}
                        <Grid item xs={12} textAlign="right">
                            <Typography ml={5}>
                                <AsOfDate textOnly={true}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </>, document.getElementById('header-extra-info'))
            }

            <Grid item xs={12} sm={12} mt={1} px={1}>
                <SMACCustomerInformation
                    params={{...params, ...filtersAsParams}} period={period} viewBy={'chain'} isOnDefaultView={true}
                    isStickyCustomerCarousel={false}
                    cardStyles={{p: 2, width: '100%', borderRadius: '.5rem'}}/>
            </Grid>

            <Grid item xs={12} sm={12}>
                <ToplineItemsProvider
                    params={{...params, ...filtersAsParams}} period={period} viewBy={'bu'}
                >
                    <TopLine isOnDefaultView={true} isStickyCustomerCarousel={false}/>
                </ToplineItemsProvider>
            </Grid>

            <Grid item xs={12} sm={12} mt={2.5}>
                <Demographics params={{...params, ...filtersAsParams}} period={period}/>
            </Grid>

            <Grid item xs={12}>
                <CustomerBrandPerformance
                    params={params} period={period}
                    business_unit={business_unit}/>
            </Grid>
        </Grid>
    )
}