import React from "react";
import {useQuery} from "react-query";
import ApiClient from "../../../../common/API";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import DataTable from 'react-data-table-component';

import { Grid } from "@mui/material";


export default function TableFromApi({apiEndpoint, params, columns, rowFilter}) {

    const {isLoading, data, isError} = useQuery([apiEndpoint, params],()=> {
        return ApiClient().get(apiEndpoint, {params: params}).then(res => {
            return res.data
        })
    });

    if (isLoading) {
        return <LoadingComponent/>
    }

    if (isError) {
        return null
    }

    const style = {
        height: '410px',
    };

    return (
        <Grid container boxShadow={1} borderRadius={2} px={3} pb={1} style={{...style}}>
            <DataTable
                data={data.filter(row => {
                    if (rowFilter) return rowFilter(row)
                    return true
                })}
                columns={columns}
                className="h-100"
            />
        </Grid>
    )
}