import React, { useContext } from 'react';
import ReactECharts from 'echarts-for-react';
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { topLineDisplayNumber } from '../../../../common/utils';

const DoughnutGraph = ({ data, option, echartContainerStyle }) => {
    const { isOnMobile } = useContext(WindowSizeContext);

    const commonLabelStyle = {
        fontSize: isOnMobile ? 10 : 14,
    };
    const defaultEchartContainerStyle = { height: '220%', width: '100%' }
    const defaultOption = {
        animation: false,
        tooltip: { show: false },
        series: [
            // Inner label display
            {
                type: 'pie',
                emphasis: { disabled: true },
                radius: isOnMobile ? [20, 50] : [35, 80],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                data,
                label: {
                    show: true,
                    position: 'inside',
                    color: '#ffffff',
                    fontSize: commonLabelStyle.fontSize,
                    formatter: params => `{value|${params.percent}}%`,
                    rich: { value: { ...commonLabelStyle, color: '#ffffff' } },
                    z: 1000
                },
                labelLine: { show: true, lineStyle: { width: 0 } }
            },
            // Outer label display
            {
                type: 'pie',
                emphasis: { disabled: true },
                radius: isOnMobile ? [20, 65] : [35, 90],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                data,
                label: {
                    show: true,
                    position: 'outside',
                    color: '#000000',
                    fontSize: commonLabelStyle.fontSize,
                    fontWeight: 'bold',
                    formatter: params => {
                        const formattedValue = topLineDisplayNumber(params.value);
                        return isOnMobile
                            ? `{name|${params.name}}: \n {value|${formattedValue}}`
                            : `{name|${params.name}}: {value|${formattedValue}}`;
                    },
                    rich: {
                        name: { ...commonLabelStyle, fontWeight: 'bold', color: '#000000' },
                        value: { ...commonLabelStyle, fontWeight: 'bold', color: '#000000' }
                    },
                    z: 1
                },
                labelLine: { show: true, lineStyle: { color: '#000000', width: 2 } }
            }
        ]
    };

    return (
        <div className="chart-container">
            <ReactECharts option={option ? option : defaultOption} style={ echartContainerStyle ? echartContainerStyle : defaultEchartContainerStyle} />
        </div>
    );
};

export default DoughnutGraph;
