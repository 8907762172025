import { useEffect } from "react";
import {useDashboardList, useSaveDashboard} from "../../../common/API/hooks";
import LoadingComponent from "../../../common/components/LoadingComponent";
import {getSelectedOption} from "../../../common/utils";
import Select from 'react-select';
import { toast } from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';


const DEFAULT_DASHBOARD_VALUE = {
    "title": "",
    "description": "",
    "is_active": true,
    "table_source": null,
    "filter_fields": [],
    "extra_data": null,
    "is_public": false,
    "layout": null,
    "is_editable": true
}


export default function DashboardList({onChangeDashboard, dashboard, tmpDashboards, setTmpDashboards}) {
    const {saveDashboardAsync} = useSaveDashboard();
    const copyDashboard = () => {
        const copiedDashboard = {...dashboard, id: null, title: dashboard.title + " (copy)", is_public: false};
            const savePromise = saveDashboardAsync(copiedDashboard);
            toast.promise(savePromise, { 
                pending: 'Copying dashboard...', 
                success: 'Dashboard Duplicated Successfully', 
                error: 'Dashboard Duplication Failed'
            });
    };
    const {data: dashboardList, isLoading: isLoadingDashboardList} = useDashboardList();

    const dashboards =  [
        ...tmpDashboards,
        ...( dashboardList || [])
    ]

    const ownedDashboardList = dashboards?.filter(dashboardObj => dashboardObj.is_editable)
    const notOwnedDashboardList = dashboards?.filter(dashboardObj => !dashboardObj.is_editable);

    const dashboardListToUse = ownedDashboardList?.length > 0 ? ownedDashboardList : notOwnedDashboardList;
    const lastestDashboardCreateDate  = new Date(Math.max(...dashboardListToUse.map(obj => new Date(obj.created_at))));
    const defaultDashboard = dashboardListToUse.filter(obj => new Date(obj.created_at).getTime() === lastestDashboardCreateDate.getTime())?.[0] //get the latest dashboard based on created date
    
    //setting the default dashboard
    useEffect(()=>{
        if(defaultDashboard) {
            onChangeDashboard(defaultDashboard)
        }
    },[defaultDashboard])


    const notOwnedDashboardOptions = notOwnedDashboardList.map(dashboardObj => {
        return {
            value: dashboardObj.id,
            label: dashboardObj.title
        }
    })
    const ownedDashboardOptions = ownedDashboardList.map(dashboardObj => {
        return {
            value: dashboardObj.id,
            label: (<>
                {dashboardObj.title ? dashboardObj.title : <i className="text-black-50">UNNAMED DASHBOARD</i>}{" "}
                {
                    dashboardObj.is_public && <span className="badge badge-info bg-info">PUBLIC</span>
                }{" "}
            </>)
        }
    })
    const dashboardDropdownOptions = [
        {label: "My Dashboards", options: ownedDashboardOptions},
        {label: "Public Dashboards", options: notOwnedDashboardOptions}
    ]

    // TODO: set a default dashboard

    const createNewDashboard = () => {
        const newDashboard = JSON.parse(JSON.stringify(DEFAULT_DASHBOARD_VALUE))
        newDashboard.id = `tmp-${Math.floor(Math.random() * 1000) + 1}`  // we need an ID for the react-select component
        // TODO: insert it into the current list of dashboards
        setTmpDashboards([...tmpDashboards, newDashboard])
        // update the selected
        onChangeDashboard(newDashboard)
    }


    if (isLoadingDashboardList) {
        return <LoadingComponent/>
    }

    const selectedDashboardOption = getSelectedOption(dashboard?.id, [...ownedDashboardOptions, ...notOwnedDashboardOptions])

    // return <>
    //     <div className="row">
    //         <div className="col-md-6">
    //             <Select
    //                 options={dashboardDropdownOptions}
    //                 placeholder="Select Dashboard"
    //                 value={selectedDashboardOption}
    //                 onChange={(e) => {
    //                     const dashboardData = dashboards.find(data => data.id === e.value)
    //                     onChangeDashboard(dashboardData)
    //                 }}
    //             />
    //         </div>
    //         <div className="col-md-6 text-end">
            // {selectedDashboardOption &&
            // notOwnedDashboardOptions.some(
            //   (option) => option.value === selectedDashboardOption.value
            // ) && (
            //   <button
            //     type="button"
            //     className="btn btn-primary mr-2"
            //     onClick={copyDashboard}
            //   >
            //     Copy Dashboard
            //   </button>
            // )}
            //     <button type="button" className="btn btn-primary ms-3" onClick={createNewDashboard}>Create New Dashboard</button>
    //         </div>
    //     </div>
    // </>

    return (
        <Grid container spacing={2} sx={{marginTop:2}}>
            <Grid item md={6}>
                <Select
                    options={dashboardDropdownOptions}
                    placeholder="Select Dashboard"
                    value={selectedDashboardOption}
                    onChange={(e) => {
                    const dashboardData = dashboards.find(data => data.id === e.value)
                        onChangeDashboard(dashboardData)
                    }}
                />
            </Grid>
            <Grid item md={6}>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Stack direction="row" spacing={2}>
                        {selectedDashboardOption &&
                        notOwnedDashboardOptions.some(
                        (option) => option.value === selectedDashboardOption.value
                        ) && (
                        <Button variant="contained" onClick={copyDashboard}> Copy Dashboard </Button>
                        )}
                        <Button variant="contained" onClick={createNewDashboard}>Create New Dashboard</Button>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    )
}