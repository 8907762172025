import React, {useMemo} from 'react';
import { getSelectedOption } from '../../../../common/utils';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function FilterCombinationType({onDropdownChange, filters}){
    const modelOptions = [
        {label: "Combinations to cross-sell", value: 1},
        {label: "Combinations to uplift transactions", value: 2},
        {label: "Combinations to uplift sales", value: 3},
    ]

    const selectedModelOption = useMemo(() => {
        if (!modelOptions || modelOptions?.length === 0) {
            return null
        }

        const option = getSelectedOption(filters?.model, modelOptions)
        if (option) {
            return option
        } else {
            const defaultOptionValue = (modelOptions && modelOptions.length > 0) ? modelOptions[0].value : null
            onDropdownChange( defaultOptionValue, 'model')
            return null
        }
    }, [modelOptions,filters?.model])

    return(
    <>
        <Grid sx={{mt:1}}>
            <Autocomplete   
                size='small'
                options={modelOptions}
                value={selectedModelOption}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue)=> onDropdownChange(newValue ? newValue.value : null, 'model')}
                renderInput={(params) => 
                <TextField {...params} placeholder={'Combinations to Cross Sell'} label={'Combinations to Cross Sell'} />}
            />
        </Grid>
    </>
    );
}   