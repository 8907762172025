import React, {useContext, useMemo} from "react";
import {abbreviateNumber, setIndicatorBgColorClass, setPrefixForIndicator} from "../../../../common/utils";
import ReactEcharts from "echarts-for-react";
import {useQuery} from "react-query";
import ApiClient from "../../../../common/API";
import moment from "moment";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import { getPreviousDate} from "../SideItems/util";
import {StoreAnalyticsFilterContext} from "../contexts";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";

import { Grid, Typography, Box, Chip } from "@mui/material";

export default function TrendIndicator({dateFilter, aggregate, indicatorName, salesApiEndPointName, apiDefaultFilter, setDateFilter, isOnMapView}) {
    const {region, province, branch_name} = useContext(StoreAnalyticsFilterContext)
    const windowSize = useContext(WindowSizeContext)
    const groupBy = Object.keys(dateFilter).length === 0 ? 'trans_week_end' : Object.keys(dateFilter)[0]

    const params = {
        region: region,
        province: province,
        store: branch_name?.toString().toLowerCase(),
        group_by: groupBy,
        ...apiDefaultFilter
    }

    if (aggregate) {
        params.aggregate = aggregate
    }

    const {isLoading, data} = useQuery([salesApiEndPointName, 'sales_trend', params], () => {
        return ApiClient().get(`/custom/${salesApiEndPointName}-map-sales/trend/`, {
            params: params
        }).then(res => {
            return res.data
        })
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 15 * 60 * 1000,
        staleTime: 15 * 60 * 1000,
        retry: false
    })

    const getGroupByKey = () => {
        if (groupBy === "trans_week_end") {
            return "transWeekEnd"
        } else if (groupBy === "trans_month") {
            return "transMonth"
        } else if (groupBy === "trans_year") {
            return "transYear"
        }
    }
    const comparisonTitle = () => {
        if (groupBy === "trans_week_end") {
            return "WoW"
        } else if (groupBy === "trans_month") {
            return "MoM"
        } else if (groupBy === "trans_year") {
            return "YoY"
        }
    }
    const groupByKey = getGroupByKey()

    const selectedDate = dateFilter[groupBy] ? moment(dateFilter[groupBy]).format('YYYY-MM-DD') : null
    const previousDate = getPreviousDate(groupBy, selectedDate)

    const dataByDate = data?.reduce((acc, val) => {
        acc[moment(val[groupByKey]).format('YYYY-MM-DD')] = val.v
        return acc
    }, {})

    const selectedDateSales = dataByDate ? dataByDate[selectedDate] : null;
    const previousDateSales = dataByDate ? dataByDate[previousDate] : null;
    const changeInsales = ((selectedDateSales / previousDateSales - 1) * 100).toFixed(2);

    const trendlineData = useMemo(() => {
        if (!data) return []

        return [...data.sort((a, b) => {
            if (a[groupByKey] > b[groupByKey]) return 1
            if (a[groupByKey] < b[groupByKey]) return -1
            return 0
        }).map(val => [new Date(val[groupByKey]), val.v])]
    }, [data])

    const startOfTrendLineData = trendlineData?.length > 0 ? trendlineData[0] : []

    const markerFormatter = (params) => {
        if (groupBy === "trans_week_end") {
            return 'W' + moment(params.data.coord[0]).isoWeek() + ' ' + (new Date(params.data.coord[0])).getFullYear();

        } else if (groupBy === "trans_month") {
            return moment(params.data.coord[0]).format('MMMM YYYY')
        } else if (groupBy === "trans_year") {
            return moment(params.data.coord[0]).format('YYYY')
        }
    }

    const markPointData = trendlineData?.length > 0 ? [
        {
            coord: [startOfTrendLineData[0], startOfTrendLineData[1]],
            symbol: "circle",
            symbolSize: 15,
            itemStyle: {
                color: "blue",
                opacity: 1
            },
            label: {
                position: "bottom",
                color: "#000",
                fontSize: 11,
                formatter: markerFormatter
            }
        },
        {
            coord: [new Date(selectedDate), selectedDateSales],
            symbol: "circle",
            symbolSize: 15,
            itemStyle: {
                color: "blue",
                opacity: 1
            },
            label: {
                position: "bottom",
                color: "#000",
                fontSize: 11,
                formatter: markerFormatter
            }
        }
    ] : []


    const storesLineChart = {
        grid: {
            left: '10%',
            top: "10%",
            right: '10%',
            bottom: 0,
            height: 50,
        },
        tooltip: {
            trigger: 'axis',
            position: function (pt) {
                return [pt[0], '10%'];
            },
            valueFormatter: function (value) {
                return abbreviateNumber(value)
            },
            axisPointer: {
                label: {
                    formatter: function (params) {
                        if (groupBy === "trans_week_end") {
                            return 'Week ' + moment(params.value).format("W YYYY");
                        } else if (groupBy === "trans_month") {
                            return moment(params.value).format('MMMM YYYY')
                        } else if (groupBy === "trans_year") {
                            return moment(params.value).format('YYYY')
                        }

                    }
                }
            }
        },
        xAxis: {
            type: 'time',
            axisLabel: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            scale: true,
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            },

        },
        series: [
            {
                data: trendlineData,
                type: 'line',
                showSymbol: false,
                markPoint: {
                    data: markPointData
                },


            }
        ]
    }

    if (isLoading) {
        return <Grid md={4}> 
            <LoadingComponent/>
        </Grid>
    }

    if (!data || data.length === 0) {
        return <Box sx={{ display: 'flex',justifyContent: 'center', py:2 }}>
            <Typography variant="h5">No Data</Typography>
        </Box>
    }
    return (
        <Grid item md={4} sm={4} xs={12}>  
            <Grid container direction={'row'} boxShadow={1} spacing={1} borderRadius={2} >
                    <Grid item sm={5} xs={5} textAlign={'end'}>
                        <Typography> {!!selectedDateSales && abbreviateNumber(selectedDateSales)} </Typography>
                    </Grid>
                    <Grid item sm={7} xs={7}>
                        <Typography> {indicatorName} </Typography>
                        <Chip label={setPrefixForIndicator(changeInsales) + '%'} size="small" sx={{backgroundColor: setIndicatorBgColorClass(changeInsales), color: '#fff' }} />
                        <span> Δ {comparisonTitle()}</span>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <ReactEcharts option={storesLineChart} style={{height: '100%', width:'100%'}} onEvents={{
                            "click": params => {
                                setDateFilter({[groupBy]: moment(params.data[0]).format('YYYY-MM-DD')})
                            }
                        }}/>
                    </Grid>
            </Grid>
        </Grid>
    )
}