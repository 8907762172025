import React, { useEffect } from "react";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import ApiClient from "../../../common/API";
import { useQueryClient } from "react-query";
import { toast } from 'react-toastify';
import EmailIcon from '@mui/icons-material/Email';
import handleError from "../ErrorHandling"

export default function SendWelcomeEmail({ user, isOpen, setIsOpen }) {
    const queryClient = useQueryClient();
    
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleSendEmail = () => {
        ApiClient().post(`user/${(user.row) ? user?.row.id : user.id}/send_welcome_email/`)
            .then(() => {
                closeModal();
                queryClient.invalidateQueries(['user'])
                setIsOpen(false)
                toast.success('Welcome Email Sent!');
            })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "User", "send_welcome_email");
                return toast.error(errorMessage);
            });
    };

    useEffect(() => {
        if (isOpen) {
            openModal();
        }
    }, [user, isOpen, setIsOpen]);

    return (
        <>
            {isOpen && (
                <ConfirmationModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    onConfirm={handleSendEmail}
                    icon={<EmailIcon sx={{ fontSize: '60px' }} color="primary" />}
                    title="Send Welcome Email"
                    message="Are you sure you want to send a welcome email to this user?"
                />
            )}
        </>
    )
}