import React, {useMemo} from 'react';
import {getSelectedOption} from '../../../../common/utils';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function FilterCrossSellPrioritization({onDropdownChange, filters}){
    const options = [
        {label: "High", value: 1},
        {label: "Medium", value: 2},
        {label: "Low", value: 3},
    ]

    const selectedModelOption = useMemo(() => {
        if (!options || options.length === 0) {
            return null
        }

        const option = getSelectedOption(filters?.association_type, options)
        if (option) {
            return option
        }
    }, [options,filters?.association_type])

    return(
    <>
        <Grid sx={{mt:1}}>
                <Autocomplete 
                size={'small'}
                options={options}
                value={selectedModelOption }
                getOptionLabel={(option) => option.label}
                onChange={(event,newValue)=> onDropdownChange(newValue?.value , 'association_type')}
                renderInput={(params) => 
                    <TextField {...params} placeholder={'Select a Cross Sell Prioritization'} label={'Select a Cross Sell Prioritization'} />}
                />
        </Grid>
    </>
    );
}   