import React, { useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ApiClient from '../../../common/API';
import { useTableDetail } from '../../../common/API/hooks';
import LoadingComponent from '../../../common/components/LoadingComponent';
import { useTableRefresh } from "./utils";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanelComponent from '../../../common/components/TabPanelComponent';
import Box from '@mui/material/Box'
import DataTable from 'react-data-table-component';
import Card from '@mui/material/Card';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import StorageIcon from '@mui/icons-material/Storage';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function ColumnSampleData({ tableId }) {
    const { refresh: tableRefresh, isLoading:isRefreshLoading } = useTableRefresh()
    const queryClient = useQueryClient()

    const { data: tableDetails, isLoading: isTableDetailsLoading } = useTableDetail(tableId, {
        enabled: !!tableId,
        onSuccess: (data) => {
            if (!data.columns || data.columns.length === 0) {
                tableRefresh(tableId).then(() => {
                    queryClient.invalidateQueries(['data', 'table', tableId, 'detail'])
                })
            }
        }
    })

    const { data: columnSampleData, isLoading: isTableQueryLoading } = useQuery(['data', 'table', tableId], () =>
        ApiClient().get(`data/table/${tableId}/query`).then((res) => {
            return res.data;
        }),
        {
            enabled: !!tableId,
            refetchOnWindowFocus: false,
            staleTime: 600000, //10mins
        }
    );

    const columns = [
        { name: 'Name', selector: (row) => row.name },
        { name: 'Type', selector: (row) => row.column_type },
        { name: 'Nullable', selector: (row) => String(row.nullable), cell: (row) => String(row.nullable) },
        { name: 'Filterable', selector: (row) => String(row.filterable), cell: (row) => String(row.filterable) }
    ];
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event,tab) => {
        setActiveTab(tab);
    };

    const COLUMNS = Array.isArray(columnSampleData?.data) && columnSampleData.data.length > 0
        ? Object.keys(columnSampleData.data[0]).map(val => ({
            name: val,
            selector: (row) => row[val],
        }))
        : [];

    function RenderContent() {
        return (
            <>
                {tableDetails && (
                    <>

                    <TabPanelComponent value={activeTab} index={0}>
                        <DataTable
                            columns={columns}
                            data={tableDetails?.columns}
                            noDataComponent={<div>You have to reload the table to display content.</div>}
                            progressPending={isTableDetailsLoading || isRefreshLoading}
                            progressComponent={<LoadingComponent/>}
                            pagination />
                    </TabPanelComponent>

                    <TabPanelComponent value={activeTab} index={1}>
                        <DataTable
                                columns={COLUMNS}
                                data={columnSampleData?.data}
                                noDataComponent={<div>The table does not contain any data.</div>}
                                progressPending={isTableQueryLoading}
                                progressComponent={<LoadingComponent/>}
                                pagination />
                    </TabPanelComponent>
                        
                    </>
                )}
            </>
        );
    }
    
    return (
        <>
            <Card sx={{borderRadius:'15px', minHeight:'80vh'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="Column Sample Data"  
                          TabIndicatorProps={{ style: { background:'none'} }} >

                        <Tab label={<Stack direction='row' alignItems={'center'} spacing={.5}>
                                        <ViewAgendaIcon sx={{transform:'rotate(90deg)'}}/> 

                                        <Typography >
                                            Columns
                                        </Typography>
                            
                                    </Stack>} sx={{textTransform:'none', '&.Mui-selected': { color: 'blue'}}} />

                        <Tab label={<Stack direction='row' alignItems={'center'} spacing={.5}>
                                        <StorageIcon />

                                        <Typography >
                                            Sample Data
                                        </Typography>
                                    </Stack>}  sx={{textTransform:'none', '&.Mui-selected': { color: 'blue'}}}/>
                    </Tabs>
                </Box>

                { RenderContent() }
            </Card>
        </>
    );
}