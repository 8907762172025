import React, {useContext} from "react";
import {StoreAnalyticsFilterContext} from "../../contexts";
import { usePolygon} from "../../Map/util";
import {ConvertToReactSelectOption} from "../../../../../common/utils";
import Select from "react-select";


export default function ProvinceSelector({storeItemsSelectOverridesStyles}) {
    const {region, province, setFilter} = useContext(StoreAnalyticsFilterContext)

    const {polygonData: provincePolygonData, isLevelLoading} = usePolygon('province')
    const filteredProvinces = provincePolygonData?.filter(p => !region || p.region === region)
    const provinceNames = Array.from(new Set(filteredProvinces?.map(p => p.province)))

    const allProvinceName = region ? `All Provinces in ${region}` : "All Provinces"
    const provinceOptions = ConvertToReactSelectOption([allProvinceName, ...provinceNames]).map(option => {
        return {
            ...option,
            value: option.value === allProvinceName ? null : option.value
        }
    })

    return (
        <Select
            isDisabled={!region || isLevelLoading}
            value={provinceOptions.find(r => r.value === province)}
            onChange={(e) => setFilter('province', e?.value)}
            options={provinceOptions}
            styles={storeItemsSelectOverridesStyles}
        />
    )
}