import React from "react";
import * as PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import {abbreviateNumber} from "../../utils";
import deepmerge from "deepmerge";
import {combineMerge} from "../../utils/merge";

function BarChartVertical({data, title, isLoading, chartOptions, chartProps, colors, chartRef}) {
    if (!colors) {
        colors = ['#0A1172']
    }

    const dataValues = Object.values(data)
    const dataKeys = Object.keys(data)

    const defaultChartOptions = {
        grid: {
            left: '20%',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {}
        },
        yAxis: {
            type: 'category',
            data: dataKeys,
            axisLabel: {
                interval: 0,
                color: "#000",
                fontSize: '11px'
            },
        },
        xAxis: {
            type: 'value',
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            },
        },
        series: [
            {
                color: colors,
                data: dataValues, // Insert actual data per age range
                type: 'bar',
                barWidth: '25',
                label: {
                    position: 'inside',
                    show: true,
                    formatter: function (params) {
                        return abbreviateNumber(params.value);
                    }
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.15)'
                }
            }
        ],
    }

    if (title) {
        defaultChartOptions.title = {
            text: title,
            left: 'center',
            textStyle: {
                color: '#000',
                fontSize: 14,
                fontWeight: 'normal'
            }
        }
    }

    const effectiveChartOptions = deepmerge(defaultChartOptions, (chartOptions || {}), {
        arrayMerge: combineMerge
    })

    const effectiveChartProps = deepmerge({style: {height: "410px", width: "100%",}}, (chartProps || {}), {
        arrayMerge: combineMerge
    })


    return <ReactEcharts
        ref={chartRef}
        {...effectiveChartProps}
        option={effectiveChartOptions}
        showLoading={isLoading}/>
}


BarChartVertical.propTypes = {
    data: PropTypes.objectOf(PropTypes.number).isRequired,
    title: PropTypes.string,
    isLoading: PropTypes.bool
};

export default BarChartVertical;
