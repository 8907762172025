
import { useContext } from "react"
import { Grid } from "@mui/material"
import CustomerAverageDailySpend from "./CustomerAverageDailySpend"
import BUGroupShareOfWallet from "./BUGroupShareOfWallet"


export default function Transactions({ params, period, onTogglePeriodChange, filters, onDropDownFilterChange }) {

    return (<>
        <Grid container >
            <Grid item xs={12} py={.5}>
                <CustomerAverageDailySpend params={params} period={period} />
            </Grid>
                <BUGroupShareOfWallet params={params} period={period} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} onFilterChange={onDropDownFilterChange} />
        </Grid>
    </>
    )
}