import React, { useMemo } from "react";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import { useDynamicGraphInTableDataL3 } from "../../hooks";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { convertNumberToLocaleString, convertToValidNumber, sortArrayBasedOnGivenArray } from "../../../../../common/utils";
import { Box, Link } from '@mui/material';
import { NUMERIC_VALUE_PROPS_BY_METRIC } from "../../constants";
import { checkIfCompletePeriod, getPast12monthsL3 } from '../../util';
import BarTrends from "../../BarTends";
import BarIndicator from "../../../common/presentation/barIndicator";
import { useAvailableMaxDateL3 } from "../../DataProvider/hooks";
import { titleCaseString } from "../../../common/util";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import { getBarTrendsColumnHeader, getMetricColumnHeader } from "../../DataProvider/util";
import { useSelectorValue } from "../../../common/providers/selectorProvider";
import { apiFilterKeys, zoneOrderArray } from "../constants";
import { usePeriodBasedParams } from "../hooks";
import ZoneAndBranchLink from "../components/ZoneAndBranchLink";
import SubRowExpander from "../../../../../common/components/DataTable/SubRowExpander";
import { useZoneStoreOverTimeData } from "../hooks/useZoneStoreOverTimeData";
import { sortedDisplayRows, sortingFn } from "./utils";

const IndicatorCellBox = ({ children, style }) => (
    <Box sx={{ my: 1, width: '3.5rem', height: '1.5rem', marginX: 'auto', ...style }}>
        {children}
    </Box>
);

export default function MetricOverTimeTable(
    {
        params,
        selectedRows,
        enableBranchLink = true,
        selectedStores
    }
) {

    const period = useSelectorValue('period');

    const isMTD = period === 'mtd';
    const isYTD = period === 'ytd';
    const isQoQ = period === 'qoq';

    const selectedViewBy = useSelectorValue('viewBy');
    const selectedMetric = useSelectorValue('metric');

    const paramsBasedOnPeriod = usePeriodBasedParams({ params })

    const metricHeaderLabel = getMetricColumnHeader(period, selectedMetric);
    const barTrendsColumnHeaders = getBarTrendsColumnHeader(selectedMetric);

    const shouldFilterByAPI = apiFilterKeys.includes(selectedViewBy);

    // Fix me: manually changes column name
    const selectedViewByToL3ColName = {
        'channel': 'cm_channel_desc',
        'business_unit': 'pm_business_unit_desc_standardized',
        'zone': 'store_zone',
    }
    const selectedViewByL3 = selectedViewByToL3ColName[selectedViewBy]

    const { data: storeData, isLoading: isLoadingStoreData } = useZoneStoreOverTimeData({
        params: {
            ...paramsBasedOnPeriod,
            // will switch group_by within hooks, but need to pass info to know which table to use (l4_sbu_agg_stores or l4_sbu_agg_bu_txn)
            group_by: selectedViewByL3,
            limit: 1000,
            metric_column: selectedMetric ? selectedMetric : 'actual_sales',
            [selectedViewByL3]: shouldFilterByAPI ? selectedRows : null // note: when apiFilterKey = 'business_unit', 'higher_mbu' col values are put into selectedRows as well and not only 'business_unit' col values
        },
        storeIDWhitelist: selectedStores
    })

    const { data: maxDate, isLoading: isLoadingMaxdate } = useAvailableMaxDateL3();

    const { monthYear, ...dateParamsForTrendBar } = getPast12monthsL3(params?.month?.[0], params?.year, maxDate)

    const rowFilters = {[selectedViewByL3]: shouldFilterByAPI ? selectedRows : null}

    const isGiftRegistryData = rowFilters?.cm_channel_desc && rowFilters?.cm_channel_desc?.[0] === 'Gift Registry'

    const newParams = {
        ...params,
        ...dateParamsForTrendBar,
        ...rowFilters,
        header_gr_tag__is_true: isGiftRegistryData ? 1 : null,
    }

    if(newParams.hasOwnProperty('cm_channel_desc') && newParams?.cm_channel_desc.includes('Gift Registry')) {
        delete newParams.cm_channel_desc
    }   

    const { data: graphDataL3, isLoading: isGraphDataLoadingL3 } = useDynamicGraphInTableDataL3({
        params: {
            ...newParams,
            group_by: 'store_parent_store',
        },
        queryOptions: {
            hasTranDateLteGte: dateParamsForTrendBar,
        }
    });

    const { data: graphDataZoneL3, isLoading: isGraphDataLoadingZoneL3 } = useDynamicGraphInTableDataL3({
        params: {
            ...newParams,
            group_by: 'store_zone',
        },
        queryOptions: {
            hasTranDateLteGte: dateParamsForTrendBar,
        }
    });

    const newStorePerformanceData = useMemo(() => {
        if (selectedRows?.length > 0 && !shouldFilterByAPI) {
            return storeData?.filter(data => {
                return selectedRows.includes(data?.[selectedViewBy])
            })
        } else {
            return storeData
        }
    }, [storeData, selectedRows, selectedViewBy])

    const dataToDisplay = newStorePerformanceData
    const isCompletePeriod = checkIfCompletePeriod(params, maxDate, isQoQ)
    const hasLessColumns = !isCompletePeriod && (isMTD || isQoQ || isYTD);

    // Function to calculate filteredDataSubtotal
    const calculateFilteredDataSubtotal = (data) => {
        const newSubtotal = {
            metricTotal: null,
            metricPlanTotal: null,
            vsPlan: null,
        };

        // Iterate through data and push values into the corresponding arrays
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const newValues = data[key];
                let sum = 0;
                for (let i = 0; i < newValues.length; i++) {
                    if (typeof newValues[i] === 'string' || isNaN(parseFloat(newValues[i])) || !isFinite(parseFloat(newValues[i]))) {
                        newValues[i] = 0;
                    }
                    sum += parseFloat(newValues[i]);
                }
                if ((String(key).includes('vsPlan')) && hasLessColumns) {
                    newSubtotal[key] =
                        <IndicatorCellBox>
                            <BarIndicator componentType={'progress_bar'}
                                value={convertToValidNumber(sum / (newValues.length || 1))?.toFixed()} />
                        </IndicatorCellBox>

                } else if (String(key).startsWith('vs')) {
                    newSubtotal[key] =
                        <IndicatorCellBox style={{ display: 'flex', justifyContent: 'center' }}>
                            <BarIndicator colorFunctionFor={'SBU'}
                                value={convertToValidNumber(sum / (newValues.length || 1))?.toFixed()}
                                isValueColorCoded={true} />
                        </IndicatorCellBox>

                } else {
                    // Sum values
                    newSubtotal[key] = (
                        <Box sx={{ whiteSpace: 'nowrap', marginX: 'auto' }}>
                            <NumericValueDisplay value={sum} prefix={key === 'sales_per_sqm' ? '' : '₱'}
                                isAbbreviated={true} />
                        </Box>
                    );
                }
            }
        }

        return newSubtotal;
    };

    const sortedData = dataToDisplay?.sort((a, b) => {
        return b.actual_sales - a.actual_sales;  // FIXME: this is a hack for default sorting
    });

    const hasFooterSubtotal = sortedData?.length !== 0 && sortedData?.length <= 5;

    const isAvailableForYTD = (isCompletePeriod && (isYTD)) || (!isCompletePeriod && (isYTD))
    const filteredDataSubtotals = useMemo(() => {
        if (hasFooterSubtotal) {
            const newRowValues = {
                metricTotal: [],
                metricPlanTotal: [],
                vsPlan: [],
                vsLY: isCompletePeriod ? [] : undefined,
                last_year_mtd_ytd_sales: isAvailableForYTD ? [] : undefined,
            };

            // Iterate through dataToDisplay and push values into the corresponding arrays
            sortedData?.forEach(data => {
                newRowValues.metricTotal.push(parseFloat(data.metricTotal));
                newRowValues.metricPlanTotal.push(parseFloat(data.metricPlanTotal));
                newRowValues.vsPlan.push((data?.metricTotal / data?.metricPlanTotal) * 100);

                if (isCompletePeriod) {
                    newRowValues.vsLY.push(parseFloat(data.vsLY));
                    // newRowValues.last_year_mtd_ytd_sales.push(parseFloat(data.last_year_mtd_ytd_sales));
                }
            });
            // Filter out undefined keys in newRowValues
            const filteredNewRowValues = Object.fromEntries(Object.entries(newRowValues).filter(([key, value]) => value !== undefined));

            return calculateFilteredDataSubtotal(filteredNewRowValues);
        }
        return {};
    }, [sortedData, isCompletePeriod]);

    const maxBarTrendValues = (data) => {
        return data?.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                const val = parseFloat(row[key])
                if (!isNaN(val)) {
                    if (acc[key]) {
                        acc[key] = Math.max(acc[key], val)
                    } else {
                        acc[key] = val
                    }
                }
            })
            return acc
        }, {})
    }

    const sortedArray = useMemo(() => sortedDisplayRows(sortedData, zoneOrderArray), [sortedData, zoneOrderArray]);

    const shouldHideBarColor = (selectedViewByL3, selectedMetric, isGiftRegistryData) => {
        const isActualMetric = ['actual_transactions', 'actual_basket_size', 'actual_smac_sales'].includes(selectedMetric);
        
        if (selectedViewByL3 === 'pm_business_unit_desc_standardized' && isActualMetric) {
            return true;
        }
        
        if (selectedViewByL3 === 'cm_channel_desc' && isGiftRegistryData && selectedMetric !== 'actual_sales') {
            return true;
        }
    
        return false;
    }

    const store_performance_col = useMemo(
        () => [
            {
                id: 'store',
                header: 'Zone / Store',
                accessorKey: 'primary',
                cell: ({ row, getValue }) => {
                    const branchTitle = titleCaseString(getValue());
                    const hasSubRows = row.original.subRows && row.original.subRows.length > 0;
                    return <Box sx={{ minWidth: '9rem', paddingLeft: "1rem", textTransform: 'capitalize', fontWeight: hasSubRows && 'bold', marginLeft: `${row.depth * 1}rem` }}>
                        <ZoneAndBranchLink
                            store={row?.original?.store_parent_store}
                            storeId={row?.original?.store_parent_store_id}
                            year={params?.year}
                            month={params?.month}
                            period={period}
                            disable={!enableBranchLink}
                            zone={row?.original?.store_zone}>
                            {branchTitle}
                        </ZoneAndBranchLink>
                        <SubRowExpander row={row} />
                    </Box>
                }, sortingFn,
                customStyle: { width: '15rem' }
            },
            {
                header: metricHeaderLabel,
                accessorKey: 'metricTotal',
                id: 'actual_sales',
                cell: (row) => {
                    const numericValueProps = NUMERIC_VALUE_PROPS_BY_METRIC[selectedMetric] || NUMERIC_VALUE_PROPS_BY_METRIC['DEFAULT'];

                    if (selectedMetric === 'actual_sales' || selectedMetric === 'actual_smac_sales') {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '25%' }, marginX: 'auto' }}>
                                <NumericValueDisplay
                                    value={row?.getValue()}
                                    isAbbreviated={true} {...numericValueProps} />
                            </Box>
                        );
                    } else if (selectedMetric === 'actual_catchment_rate' || selectedMetric === 'actual_conversion_rate') {
                        return (
                            <Box sx={{ width: { sm: '15%' }, marginX: 'auto' }}>
                                <NumericValueDisplay
                                    value={row?.getValue()}
                                    isAbbreviated={true} {...numericValueProps} />
                            </Box>
                        );
                    } else {
                        return (
                            <Box sx={{ width: { sm: '20%' }, marginX: 'auto' }}>
                                <NumericValueDisplay
                                    value={row?.getValue()}
                                    isAbbreviated={true} {...numericValueProps} />
                            </Box>
                        );
                    }
                },
                customStyle: { width: '17rem', textAlign: 'left', verticalAlign: 'middle',whiteSpace:'nowrap'}
            },
            {
                id: 'target_sales',
                header: isQoQ ? 'QTR Plan' : isMTD ? 'MTD Plan' : 'YTD Plan',
                accessorKey: 'metricPlanTotal',
                cell: (row) => {
                    if (selectedMetric === 'actual_sales' || selectedMetric === 'actual_smac_sales') {
                        return (
                            <Box sx={{ width: { sm: '25%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={true} />
                            </Box>
                        );
                    } else if (selectedMetric === 'actual_transactions') {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '15%' : '20%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} isAbbreviated={true} />
                            </Box>
                        );
                    } else if (selectedMetric === 'actual_catchment_rate' || selectedMetric === 'actual_conversion_rate') {
                        return (
                            <Box sx={{ width: { sm: '25%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} postfix={'%'} isAbbreviated={true} />
                            </Box>
                        );
                    } else { // Space Productivity TFA and NSA
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '15%' : '20%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={true} />
                            </Box>
                        );
                    }
                },
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle', whiteSpace:'nowrap' }
            },
            {
                header: 'vs Plan',
                id: 'vs_target',
                accessorKey: 'vsPlan',
                cell: (row) => {
                    const value = row?.getValue();
                    if (value === 'N/A') {
                        return (
                            <Box sx={{
                                width: '3rem',
                                height: '1.5rem',
                                marginX: 'auto',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <BarIndicator colorFunctionFor={'SBU'} value={'N/A'} isValueColorCoded={true} />
                            </Box>
                        );
                    } else if (!isCompletePeriod) {
                        return <>{convertNumberToLocaleString(row?.getValue()) !== 0 ?
                            <Box sx={{ width: '4rem', height: '1.5rem', marginX: 'auto' }}>
                                <BarIndicator
                                    componentType={'progress_bar'}
                                    value={convertToValidNumber(row?.getValue()).toFixed()} />
                            </Box> : '-'
                        }</>
                    }
                    else {
                        return (<>
                            {convertNumberToLocaleString(value) !== 0 ?
                                <Box sx={{
                                    width: '3rem',
                                    height: '1.5rem',
                                    marginX: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <BarIndicator
                                        colorFunctionFor={'SBU'}
                                        value={convertToValidNumber(value)?.toFixed()}
                                        isValueColorCoded={true} />
                                </Box> : '-'
                            }
                        </>);
                    }
                },
                customStyle: { width: '8rem', height: '2rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: 'vs Last Year',
                id: 'vs_last_year',
                accessorKey: 'vsLY',
                cell: (row) => <>
                    {convertNumberToLocaleString(row?.getValue()) !== 0 ?
                        <Box sx={{
                            width: '3rem',
                            height: '1.5rem',
                            marginX: 'auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <BarIndicator
                                colorFunctionFor={'SBU'}
                                value={convertToValidNumber(row?.getValue())?.toFixed()}
                                isValueColorCoded={true} />
                        </Box> : '-'
                    }
                </>,
                customStyle: { width: '8rem', height: '2rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: barTrendsColumnHeaders,
                id: 'last_year_mtd_ytd_sales',
                accessorKey: 'vsPlan',
                cell: (row) => {
                   
                    const storeGroupValue = row?.row?.original?.['store_parent_store'];
                    const zoneGroupValue = row?.row?.original?.['store_zone'];

                    const storeBarTrendData = graphDataL3[storeGroupValue];
                    const zoneBarTrendData = graphDataZoneL3[zoneGroupValue]

                    const storeNewGraphData = storeBarTrendData?.filter(data => monthYear?.includes(data?.year_month))
                    const zoneNewGraphData = zoneBarTrendData?.filter(data => monthYear?.includes(data?.year_month))

                    const hasNoBarColor = shouldHideBarColor(selectedViewByL3, selectedMetric, isGiftRegistryData);

                    if (isGraphDataLoadingL3 && isGraphDataLoadingZoneL3) {
                        return <LoadingComponent />;
                    }

                    if (storeGroupValue) {
                        return (
                            <Box sx={{ width: '13rem', height: '2.5rem', marginX: 'auto' }}>
                                <BarTrends
                                    data={storeNewGraphData} 
                                    maxValues={maxBarTrendValues(storeNewGraphData)}
                                    metric={selectedMetric} 
                                    hasNoBarColor={hasNoBarColor}
                                />
                            </Box>
                        );
                    }

                    if (zoneGroupValue) {
                        return (
                            <Box sx={{ width: '13rem', height: '2.5rem', marginX: 'auto' }}>
                                <BarTrends
                                    data={zoneNewGraphData} 
                                    maxValues={maxBarTrendValues(zoneNewGraphData)}
                                    metric={selectedMetric} 
                                    hasNoBarColor={hasNoBarColor}
                                />
                            </Box>
                        );
                    }
                },
                customStyle: { width: isCompletePeriod ? '13rem' : '15rem', verticalAlign: 'middle' }
            }
        ],
        [newStorePerformanceData, selectedViewBy, period, graphDataL3,graphDataZoneL3, dateParamsForTrendBar, sortedDisplayRows, selectedMetric]
    )

    if (isLoadingStoreData) {
        return <LoadingComponent />
    }

    return (

        <TableComponentV2
            customTableContainerStyle={{
                borderRadius: "0",
                boxShadow: 'none',
            }}
            customTableHeaderStyle={{
                backgroundColor: '#caddeb'
            }}
            columns={store_performance_col}
            data={sortArrayBasedOnGivenArray(sortedArray, zoneOrderArray, selectedViewBy)}
            tableId={'SalesStorePerformanceTable'}
            hasPagination={false}
            isCompact={true}
            hasFooterSubtotal={hasFooterSubtotal}
            subtotalRow={filteredDataSubtotals}
            initialState={{
                columnVisibility: {
                    // "vs_last_year": isCompletePeriod,
                    "last_year_mtd_ytd_sales": !isMTD && !isQoQ
                }
            }}
            enableSortingRemoval={true}
        />
    );
}