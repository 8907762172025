import moment from "moment";
import { abbreviateNumber, convertNumberToLocaleString, convertToValidNumber, displayNumber } from "../../../common/utils";
import { Box } from "@mui/material";

export const monthYearToTransactionDate = (months, year, dayOfMonthMaxDate) => {
    if (!months || months.length === 0 || !year) {
        return {}
    }
    // months is an array of numbers, 1=January, 2=February, etc.
    const minMonth = Math.min(...months)
    const maxMonth = Math.max(...months)

    const startDate = new Date(year, minMonth - 1, 1)  // since minMonth is 1-based, we need to subtract 1 from month to get the first day of the month
    const endDate = dayOfMonthMaxDate ?
        new Date(year, maxMonth - 1, dayOfMonthMaxDate) :  // since maxMonth is 1-based, we need to subtract 1 from month to get the last day of the month
        new Date(year, maxMonth, 0);  // since maxMonth is 1-based, we use the 0th day of the next month to get the last day of the month

    return {
        "transaction_date__gte": moment(startDate).format("YYYY-MM-DD"),
        "transaction_date__lte": moment(endDate).format("YYYY-MM-DD")
    }

}

export const convertParamsToTransactionDate = (params, dayOfMonthMaxDate) => {
    return {
        ...params,
        ...monthYearToTransactionDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const preProcessParams = (params) => {
    const newParams = {
        ...params,
    }

    // delete "month" and "year" parameters if they exist
    // because we don't want to send them to the backend,
    // we want to send "transaction_date__gte" and "transaction_date__lte" instead
    if (newParams.month) {
        delete newParams.month
    }
    if (newParams.year) {
        delete newParams.year
    }

    // remove empty arrays or empty strings or null values
    Object.keys(newParams).forEach(key => {
        if (Array.isArray(newParams[key]) && newParams[key].length === 0) {
            delete newParams[key]
        }
        if (newParams[key] === "") {
            delete newParams[key]
        }
        if (newParams[key] === null) {
            delete newParams[key]
        }
    });
    return newParams
}

export const barGraphData = (rowData) => {
    return {
        // height: "20em",
        grid: {
            top: 45,
            left: 75
        },
        yAxis: {
            data: ["Target", "Last Month", "Last Year"].reverse(),
            axisLabel: {
                fontSize: 12,
                color: '#000'
            }
        },

        xAxis: {
            type: "value",
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            }
        },

        series: [
            //1st object is to create another bar to display another label inside.
            {
                data: [
                    // {value: rowData.actual_sales, itemStyle: { color: '#073e92'}}, // Current
                    { value: rowData.target_sales, itemStyle: { color: '#59a14f' }, currentVal: rowData.actual_sales }, // Target
                    { value: rowData.last_month_sales, itemStyle: { color: '#d3d3d3' }, currentVal: rowData.actual_sales }, // Last Month
                    { value: rowData.last_year_sales, itemStyle: { color: '#767f8b' }, currentVal: rowData.actual_sales } // Last Year
                ].reverse(),
                type: "bar",
                silent: true,
                barGap: '-100%',
                label: {
                    position: "insideLeft",
                    distance: 75,
                    show: true,
                    fontSize: 15,
                    fontWeight: 'bold',
                    formatter: function (params) {
                        const percentage = Math.round((params?.data?.currentVal / params.value) * 100) + "%"
                        return percentage
                    }
                },
            },
            {
                data: [
                    // {value: rowData.actual_sales, itemStyle: { color: '#073e92'}}, // Current
                    { value: rowData.target_sales, itemStyle: { color: '#59a14f' }, currentVal: rowData.actual_sales }, // Target
                    { value: rowData.last_month_sales, itemStyle: { color: '#d3d3d3' }, currentVal: rowData.actual_sales }, // Last Month
                    { value: rowData.last_year_sales, itemStyle: { color: '#767f8b' }, currentVal: rowData.actual_sales } // Last Year
                ].reverse(),
                type: "bar",
                barGap: '-100%',
                silent: true,
                label: {
                    position: "right",
                    show: true,
                    fontSize: 15,
                    fontWeight: 'bold',
                    formatter: function (params) {
                        return abbreviateNumber(params.value, true);
                    }
                },
                markLine: {
                    silent: 'true',
                    lineStyle: {
                        type: 'solid',
                        width: 5,
                        color: 'black'
                    },
                    symbol: 'none',
                    data: [[
                        {
                            value: rowData.actual_sales, xAxis: rowData?.actual_sales, y: 30,
                            label: {
                                position: "start",
                                show: true,
                                fontSize: 15,
                                fontWeight: 'bold',
                                formatter: function (params) {
                                    return `Current (₱ ${abbreviateNumber(params?.value, true)})`
                                }
                            },
                        },
                        { value: rowData.actual_sales, xAxis: rowData?.actual_sales, y: '80%' },
                    ]],
                }
            }
        ]
    };
}

export const getColorBasedOnPercentage = (data, isInverted) => {
    let color = '#000';
    if (isInverted === true) {
        color = data <= 100 ? '#00b38a' : '#f2ac42';
    } else {
        if (data >= 0 && data < 50) {
            color = '#ea324c';
        } else if (data >= 50 && data < 100) {
            color = '#f2ac42';
        } else {
            color = '#00b38a';
        }
    }
    return color
}

export const regroupDataBy = (data, groupBy, value) => {
    if (!data) {
        return {}
    }

    return data.reduce((acc, curr) => {
        const key = curr[groupBy];
        if (!acc[key]) {
            acc[key] = 0;
        }
        if (value) {
            acc[key] = curr[value];
        } else {
            acc[key] = curr;
        }
        return acc;
    }, {});
}


export const numberCellDisplay = (value, isOnMobile, noPrefix) => {
    const prefix = noPrefix ? '' : '₱';
    const showDash = convertNumberToLocaleString(value) < 1 || !isFinite(value) || isNaN(value)
    const conditionalStyles = !showDash ?  {} : { width: '100%', textAlign: 'center' }

    return <Box sx={{ whiteSpace: 'nowrap', ...conditionalStyles }}> { !showDash ? prefix + displayNumber(value, isOnMobile) : '-'} </Box>
}

export const percentCellDisplay = (value) => {
    return <Box sx={{ whiteSpace: 'nowrap' }}> {convertToValidNumber(value)?.toFixed() + '%'} </Box>
}

//Getting the days present in a month.
//data: the params/filters selected
export const getDaysInMonth = (date) => {
    return moment(date, "YYYY-MM").daysInMonth();
}

export const getDaysDifference = (start, end) => {
    const dateOne = moment(start);
    const dateTwo = moment(end);

    //plus 1 since moment diff gives a day less. Needs to double check.
    return dateTwo.diff(dateOne, 'days') + 1
}