import React from "react";
import StoreAnalytics from "../../index"
import chart from "../../../../../img/chart-hypermarket.png"
import {companyAcronymToName} from "../../../../../common/utils";

export default function SuperMarketAnalytics() {
    return (
        <StoreAnalytics
            title={"Supermarket"}
            locationApiEndPoint={'/custom/location/svi/'}
            chartViewImage={chart}
            apiDefaultFilter={{company: companyAcronymToName("svi")}}
            salesApiEndPointName={"frg"}
            company={"svi"}
        />
    );
}