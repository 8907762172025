import React, { useState, useEffect, useContext } from "react";
import {
    abbreviateNumber,
} from "../../../../../common/utils";
import ReactEcharts from "echarts-for-react";
import { getDateDisplay } from "../util";
import { useQuery } from "react-query";
import ApiClient from "../../../../../common/API";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { Grid, Typography, Button } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext";

export default function SalesByRegionChart({ dateFilter, salesApiEndPointName, apiDefaultFilter }) {

    const windowSize = useContext(WindowSizeContext);

    const [ascending, setAscending] = useState(true);
    const [storesBarAgeGroup, setStoresBarAgeGroup] = useState(null);

    const handleSort = () => {
        setAscending((prevAscending) => !prevAscending);
    };

    const currentParams = {
        group_by: "region",
        ...dateFilter,
        ...apiDefaultFilter
    };

    const { isLoading, data } = useQuery(
        [salesApiEndPointName, "grouped_sales", currentParams],
        () => {
            return ApiClient()
                .get(`/custom/${salesApiEndPointName}-map-sales/grouped_sales`, {
                    params: currentParams
                })
                .then((res) => {
                    return res.data;
                });
        },
        {
            refetchOnWindowFocus: false,
            cacheTime: 15 * 60 * 1000,
            staleTime: 15 * 60 * 1000
        }
    );

    useEffect(() => {
        if (data && data.length > 0) {
            const sortedData = sortBySales(data, ascending);
            const keys = sortedData.map((val) => val.v);
            const values = sortedData.map((val) => val.totalSales);

            setStoresBarAgeGroup({
                grid: {
                    containLabel: true,
                    width: '80%',
                    left: '10%'
                },

                tooltip: {
                    trigger: "axis",
                    axisPointer: {}
                },
                yAxis: {
                    type: "category",
                    data: keys,
                    axisLabel: {
                        interval: 0,
                        color: "#000",
                        fontSize: "14px"
                    }
                },
                xAxis: {
                    type: "value",
                    boundaryGap: true,
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                series: [
                    {
                        color: ["#0000ff"],
                        data: values,
                        type: "bar",
                        label: {
                            position: "right",
                            show: true,
                            formatter: function (params) {
                                return abbreviateNumber(params.value);
                            },
                            textStyle: {
                                color: 'black',
                                fontSize: 14,
                            },
                        },
                        showBackground: true,
                        backgroundStyle: {
                            color: "rgba(180, 180, 180, 0.15)"
                        },
                        itemStyle: {
                            borderRadius: [0, 5, 5, 0]
                        },
                        barWidth: "70%",
                    }
                ],

            });
        }
    }, [data, ascending]);

    const sortBySales = (data, ascending) => {
        const sortedData = [...data];
        sortedData.sort((a, b) => {
            if (ascending) {
                return a.totalSales - b.totalSales;
            } else {
                return b.totalSales - a.totalSales;
            }
        });
        return sortedData;
    };

    if (isLoading) {
        return <LoadingComponent />;
    }

    return (
        <>
            {storesBarAgeGroup && (
                <Grid container boxShadow={1} borderRadius={2} sx={{ py: 3 }}>
                    <Grid item container>
                        <Grid container justifyContent="space-between" px={2} alignContent="center">
                            <Typography variant={windowSize.isOnMobile ? 'h6' : 'h4'} sx={{ fontWeight: "medium" }}> {getDateDisplay(dateFilter)} Sales by Region</Typography>
                            <Button variant="contained" color="primary" onClick={handleSort}
                                startIcon={ascending ? <SortIcon /> : <SortIcon sx={{ rotate: '180deg', transform: 'rotateY(180deg)' }} />}>
                            </Button>
                        </Grid>
                    </Grid>
                    <ReactEcharts option={storesBarAgeGroup} style={{ width: '100%', height: '450px' }} />
                </Grid>
            )
            }
        </>
    );
}
