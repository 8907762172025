import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ApiClient from "../../../common/API";
import { useQueryClient } from "react-query";
import { usePermissionList, useUserList } from "../../../common/API/hooks";
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import { getSelectedOption } from '../../../common/utils';
import { Grid, Typography, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal'
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import DropdownCheckboxV3 from "../../../common/components/DropdownCheckboxV3";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import handleError from "../ErrorHandling"

export default function EditGroup({ group, isOpen, setIsOpen }) {
    const queryClient = useQueryClient();
    const [hasChanges, setHasChanges] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const { data: permissionList, isLoading: permissionLoading } = usePermissionList()
    const { data: userList, isLoading: userLoading } = useUserList()

    const [editedGroup, setEditedGroup] = useState({
        name: group?.row?.name || '',
        permissions: group?.row?.permissions,
        users: group?.row?.users
    });

    const permissionOptions = React.useMemo(() => {
        if (permissionLoading) {
            return []
        }
        if (!permissionList) {
            return []
        }
        return permissionList?.map((permission) => {
            return {
                value: permission.name,
                label: permission.name,
                key: permission.id,
            }
        })
    }, [permissionList, permissionLoading]);

    const userOptions = React.useMemo(() => {
        if (userLoading) {
            return []
        }
        if (!userList) {
            return []
        }
        return userList?.map((user) => {
            return {
                value: user.username,
                label: user.username,
                key: user.id,
            }
        })
    }, [userList, userLoading]);

    const closeModal = () => {
        if (hasChanges) {
            setShowWarningModal(true)
        }
        else {
            setIsOpen(false);
        }
    };

    const handleWarningConfirmation = () => {
        setEditedGroup({
            name: group?.row?.name || '',
            permissions: group?.row?.permissions,
            users: group?.row?.users
        })
        setShowWarningModal(false)
        setHasChanges(false)
        setIsOpen(false)
    }

    const handleChange = (e) => {
        setHasChanges(true);
        const { name, value } = e.target;
        setEditedGroup((prevGroup) => ({ ...prevGroup, [name]: value }));
    };

    const handlePermissionChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value);
        setEditedGroup((prevGroup) => ({ ...prevGroup, permissions: selectedValues }));
    };

    const handleUserChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value)
        setEditedGroup((prevUserData) => ({
            ...prevUserData,
            users: selectedValues
        }));
    };

    const handleSave = () => {
        ApiClient().patch(`group/${group.row.id}/`, editedGroup)
            .then(() => {
                queryClient.invalidateQueries(['group']);
                queryClient.invalidateQueries(['user']);
                setIsOpen(false)
                toast.success('Group updated!');
            })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "Group", "edit");
                return toast.error(errorMessage);
            });
    };

    return (
        <>
            <Modal
                open={isOpen}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: 'auto',
                    justifyContent: 'center'
                }} >
                <Grid xs={10} md={4}>
                    <Card sx={{ minHeight: '45vh' }}>
                        <Grid container justifyContent="space-between" p={2} >
                            <Grid display="flex" columnGap={1}>
                                <EditNoteRoundedIcon fontSize="large" />
                                <Typography variant="h5" fontWeight="bold" >
                                    Edit Group
                                </Typography>
                            </Grid>
                            <IconButton aria-label="close" size="small" color="error"
                                onClick={closeModal}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid sx={{ p: 2 }}>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "400px" }}
                                    size="small"
                                    aria-label="name"
                                    id="name"
                                    name="name"
                                    label="Group Name"
                                    placeholder="Group Name"
                                    value={editedGroup.name}
                                    onChange={handleChange}>
                                </TextField>
                            </Grid>

                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={permissionOptions}
                                    label="Select Permission(s)"
                                    placeholder="Select Permission(s)"
                                    isLoading={permissionLoading}
                                    isDisabled={permissionLoading}
                                    limitTags={1}
                                    onChange={(event, newValue) => {
                                        handlePermissionChange(getSelectedOption(newValue, permissionOptions));
                                    }}
                                    value={getSelectedOption(editedGroup?.permissions, permissionOptions)} />
                            </Grid>
                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={userOptions}
                                    label="Select User(s)"
                                    limitTags={1}
                                    isLoading={userLoading}
                                    isDisabled={userLoading}
                                    onChange={(event, newValue) => {
                                        handleUserChange(getSelectedOption(newValue, userOptions));
                                    }}
                                    value={getSelectedOption(editedGroup?.users, userOptions)} />
                            </Grid>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid container
                            p={1.5}
                            columnGap={{ sm: 'none', md: 4 }}
                            marginLeft={{ xs: 'none', md: -2.5 }}
                            justifyContent={{ xs: 'center', md: 'flex-end' }}>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ bgcolor: "#0F2FEB", width: "80px" }}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Card>
                </Grid>
            </Modal>

            {showWarningModal && (
                <ConfirmationModal
                    isOpen={showWarningModal}
                    onClose={() => setShowWarningModal(false)}
                    onConfirm={handleWarningConfirmation}
                    icon={<WarningAmberIcon sx={{ fontSize: '60px' }} color='warning' />}
                    title="Warning"
                    message="There are unsaved changes. Are you sure you want to close the modal?"
                />
            )}
        </>
    )
};