import React, { useContext } from 'react';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { Box } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { getSelectedOption } from '../../../../common/utils';

export default function NumOfDaysFilter({ filters, onFilterChange, isLoading }) {
    const windowSize = useContext(WindowSizeContext);

    const availableOptions = [
        { value: 3, label: '3 Days' },
        { value: 7, label: '7 Days' },
        { value: 15, label: '15 Days' },
        { value: 30, label: '30 Days' }
    ]

    if (isLoading) {
        return <Box md={2} sx={{ marginY: -1, paddingX: 2, display: 'flex', justifyContent: 'end', width: "100%" }} >
            <LoadingComponent />
        </Box>
    }

    return (
        <>
            <ConditionalSelectComponent
                id="no_of_days_filter"
                labelId="no_of_days_filter-label"
                options={availableOptions}
                label="No of Days"
                value={filters ? (windowSize.isOnMobile ? filters?.days : getSelectedOption(filters?.days, availableOptions)) : '' &&  null}
                onSelectChange={(event, newValue) => {
                    windowSize.isOnMobile ? onFilterChange(event.target.value, 'days')
                    : onFilterChange(newValue?.value, 'days')
                }}
                endAdornment={false}
                disableClearable={true}
            />
        </>
    );
}
