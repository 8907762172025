import React, { useMemo, useState } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import ApiClient from "../../../../../common/API";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import moment from "moment";

import { Grid, Button } from "@mui/material";

export default function DateSelector({ selectedPeriodFilter, setSelectedPeriodFilter, salesApiEndPointName, apiDefaultFilter, storeItemsSelectOverridesStyles }) {
    const [selectedPeriod, setSelectedPeriod] = useState('isoWeek')

    const { isLoading, data } = useQuery([salesApiEndPointName, 'transaction-dates', apiDefaultFilter], () => {
        return ApiClient().get(`/custom/${salesApiEndPointName}-map-sales/dates_from_to/`, {
            params: apiDefaultFilter
        }).then(res => {
            return res.data
        })
    }, {
        onSuccess: data => {
            // this is assuming that the selected period is isoWeek
            setSelectedPeriodFilter({
                trans_week_end: moment(data.max).format('YYYY-MM-DD')
            })  // use latest data
        },
        refetchOnWindowFocus: false
    })


    function getPeriodClassName(val) {
        if (val === selectedPeriod) {
            return  { color: '#000', fontWeight: 'bold', textDecoration: 'none' }
        }

        return  { color: '#000', textDecoration: 'none', fontWeight:'light' }
    }

    const periodAsFilterKey = (period) => {
        if (period === "isoWeek") {
            return "trans_week_end"
        } else if (period === "month") {
            return "trans_month"
        } else if (period === "year") {
            return "trans_year"
        }
    }

    const asFilter = (period, value) => {
        return {
            [periodAsFilterKey(period)]: value
        }
    }
    const selectedValue = selectedPeriodFilter ? selectedPeriodFilter[periodAsFilterKey(selectedPeriod)] : null

    const options = useMemo(() => {
        if (!data) {
            return []
        }
        const fromString = data.min;
        const toString = data.max;

        const fromDate = new Date(fromString);
        const toDate = new Date(toString);

        let fromMoment = moment(fromDate);
        let toMoment = moment(toDate);

        if (selectedPeriod === "month") {
            toMoment = moment(toMoment).endOf('month');
        } else if (selectedPeriod === "year") {
            toMoment = moment(toMoment).endOf('year');
        }

        const dates = [];
        while (fromMoment.isBefore(toMoment) || fromMoment.isSame(toMoment)) {
            if (selectedPeriod === 'isoWeek') {
                dates.unshift({
                    value: fromMoment.format('YYYY-MM-DD'),
                    label: "Week " + fromMoment.isoWeek().toString() + ", " + fromMoment.format(' YYYY'),
                });
                fromMoment = fromMoment.add(7, 'days');
            } else if (selectedPeriod === "month") {
                dates.unshift({
                    value: fromMoment.startOf('month').format('YYYY-MM-DD'),
                    label: fromMoment.format('MMMM YYYY'),
                })
                fromMoment = fromMoment.add(1, 'month');
            } else if (selectedPeriod === "year") {
                dates.unshift({
                    value: fromMoment.startOf('year').format('YYYY-MM-DD'),
                    label: fromMoment.format('YYYY'),
                })
                fromMoment = fromMoment.add(1, 'year');
            } else {
                throw new Error("Invalid period: " + selectedPeriod)
            }
        }

        setSelectedPeriodFilter(asFilter(selectedPeriod, dates[0].value))
        return dates
    }, [data, selectedPeriod]);

    const selectedOption = selectedValue ? options.find(option => option.value === selectedValue) : options[0];

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <>
            <Grid container spacing={2} mb={2}>
                <Grid item xs={3} md={3}>
                    <Button
                        sx={getPeriodClassName('daily')}
                        onClick={() => setSelectedPeriod('daily')}
                        disabled
                    >
                        YTD
                    </Button>
                </Grid>
                <Grid item xs={3} md={3}>
                    <Button
                        sx={getPeriodClassName('isoWeek')}
                        onClick={() => setSelectedPeriod('isoWeek')}
                    >
                        Weekly
                    </Button>
                </Grid>
                <Grid item xs={3} md={3}>
                    <Button
                        sx={getPeriodClassName('month')}
                        onClick={() => setSelectedPeriod('month')}
                    >
                        Monthly
                    </Button>
                </Grid>
                <Grid item xs={3} md={3}>
                    <Button
                        sx={getPeriodClassName('year')}
                        onClick={() => setSelectedPeriod('year')}
                    >
                        Yearly
                    </Button>
                </Grid>
            </Grid>
            <Grid mb={1}>
                    <Select
                        value={selectedOption}
                        onChange={newSelectedOption => {
                            setSelectedPeriodFilter(asFilter(selectedPeriod, newSelectedOption.value))
                        }}
                        options={options}
                        styles={storeItemsSelectOverridesStyles}
                    />
            </Grid>
        </>
    )
}