import React from "react";
import { useBranchShareOfWallet } from "../../../../../Customers/Transactions/CustomerAverageDailySpend/hooks";
import { forceParseFloat } from "../../../../util";
import CustomerAverageDailySpendTable from "../../../../../Customers/Transactions/CustomerAverageDailySpend/CustomerAverageDailySpendTable";
import { sortByBasketSize } from "../../../../../Customers/util";


export default function CustomerAverageDailySpend({ params, period, storeID, onTogglePeriodChange, filters, onDropDownFilterChange }) {

    const {data, isLoading} = useBranchShareOfWallet({
        store_id: storeID,
        ...params,
        // ...filters
    })

    const customerData = React.useMemo(() => {
        if (isLoading || !data || !data.data || !data.data.length) {
            return []
        }

        const totalSales = data.data.reduce((acc, row) => acc + forceParseFloat(row.gross_sales_sum), 0)

        return data.data.map(row => {
            return {
                basket_size: row.store_basket_size,
                customer_count: row.store_customer_count_sum,
                total_txn_count: row.store_txn_count_sum,
                total_sales: row.gross_sales_sum,
                distribution: row.gross_sales_sum / totalSales * 100
            }
        }).sort(sortByBasketSize)
    }, [data, isLoading])
    
    return (<>
        <CustomerAverageDailySpendTable customerData={customerData} isLoading={isLoading} period={period} />
    </>);
}