import React, {useMemo} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {useFilterValuesV2} from '../hooks';
import {ConvertToReactSelectOption, getSelectedOption} from '../../../../common/utils';
import { TextField, CircularProgress   } from '@mui/material';
import Grid from '@mui/material/Grid';



export default function FilterBrand({onDropdownChange, filters}) {
    const {data: filterValues, isLoading} = useFilterValuesV2({
        column: "merch_brand_name",
        filters: {
            company_key: filters?.company_key,
            merchcat_dept_desc: filters?.merchcat_dept_desc
        }
    });

    const brandOptions = ConvertToReactSelectOption(filterValues)

    const selectedBrandOption = useMemo(() => {
        if (!filters?.merch_brand_name || !brandOptions || brandOptions.length === 0) {
            return null
        }

        const option = getSelectedOption(filters?.merch_brand_name, brandOptions)
        if (option) {
            return option
        } else {
            onDropdownChange(null, 'merch_brand_name')
            return null
        }
    }, [brandOptions, filters?.merch_brand_name])

    return (
        <>
            <Grid sx={{mt:1}}>
                <Autocomplete 
                    size='small'
                    options={brandOptions}
                    value={selectedBrandOption}
                    loading={isLoading}
                    disabled={isLoading}
                    onChange={(event,newValue) => onDropdownChange(newValue?.value, 'merch_brand_name')}
                    renderInput={(params) => <TextField {...params} placeholder={'All Brands'} label={'All Brands'} 
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: isLoading ? <CircularProgress size="1rem" sx={{marginLeft: '.50rem'}} /> : 
                    params.InputProps.startAdornment}}/>}
                        />    
            </Grid>
        </>
    );
}   