import React from "react";
import EditChartModal from "../EditChartModal";
import { useSaveChart } from '../../../../common/API/hooks';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from "react-modal";
import { useState } from "react";
import InsightModal from "./InsightModal";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { createTheme } from "react-data-table-component";
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


export default function CardItem({ chartObj }) {
    const SHOW_INSIGHTS = false // For now, dont show the insights. Can enable in future.
    const { saveChartAsync } = useSaveChart();
    const [showInsights, setShowInsights] = useState(false);

    const copyTemplate = () => {
        const copiedChart = { ...chartObj, id: null, title: chartObj.title + " (copy)", is_public: false };
        const savePromise = saveChartAsync(copiedChart);
        toast.promise(savePromise, {
            pending: 'Copying Chart...',
            success: 'Chart Duplicated Successfully',
            error: 'Chart Duplication Failed'
        });
    };

    // const deleteTemplate = () => {
    //     const updateChart = { ...chartObj, is_active: false };
    //     const savePromise = saveChartAsync(updateChart);
    //     toast.promise(savePromise, {
    //         pending: 'Deleting Chart...',
    //         success: 'Chart Deleted Successfully',
    //         error: 'Chart Deletion Failed'

    //     });
    // };

    return (
        <Card key={chartObj?.id} sx={{ height: "100%", borderRadius: 4 }}>
            <CardContent>
                <Box sx={{ flexDirection: "column" }}>
                    <Box sx={{ borderBottom: 3, borderColor: "divider" }}>
                        <Grid sx={{ display: 'grid', gridAutoColumns: '1fr', gap: .2 }}>
                            <Grid  sx={{ gridRow: "1" }} gridColumn={{ xs:'span 100', md: 'span 10' }}>
                                <Typography variant="h6" sx={{ fontWeight: "500" }}> {chartObj?.title}
                                    {
                                        chartObj?.is_public &&
                                        <Chip color="primary" label="Public" size="small" sx={{ ml: 1, mt: -.5, borderRadius: 2.3 }} />
                                    }
                                </Typography>
                            </Grid>
                            {/* description under Title */}
                            <Grid sx={{ gridRow: "2", mb: .5, mt: -.2 }} gridColumn={{ xs:'span 100', md: 'span 10' }}>
                                {
                                    !!chartObj?.description &&
                                    <Typography variant="body2" >{chartObj?.description}</Typography>
                                }
                            </Grid>
                            <Grid sx={{ gridRow: "1/3", gridColumn: "span 4" }}>
                                <Stack direction="row" display={{ xs: 'none', md: 'flex'}}  spacing={.5} alignItems="center" justifyContent="flex-end">
                                    {
                                        chartObj?.is_public &&
                                        <IconButton data-tooltip-id="info_tooltip" data-tooltip-place="top"
                                            data-tooltip-variant="info"
                                            data-tooltip-content={'Copy Template'}
                                            onClick={copyTemplate}
                                        >
                                            <ContentCopyIcon fontSize="small" sx={{ color: "primary.main" }} />
                                        </IconButton>
                                    }
                                    {
                                        SHOW_INSIGHTS && chartObj?.extra_data.savedInsight && chartObj?.extra_data.savedInsight !== '' && <InsightModal
                                            insight={chartObj.extra_data.savedInsight}
                                        />
                                    }
                                    {
                                        chartObj?.is_editable && <EditChartModal chartObjValues={chartObj} />
                                    }
                                    {/* For Checking API callback for deletion of chart card */}
                                    {/* {
                                        chartObj?.is_mine &&
                                        <IconButton
                                            data-tooltip-id="info_tooltip" data-tooltip-place="top"
                                            data-tooltip-variant="error"
                                            data-tooltip-content={'Delete Template'}
                                            onClick={deleteTemplate}
                                        >
                                            <DeleteOutlineIcon fontSize="small" sx={{ color: "red" }} />
                                        </IconButton>
                                    } */}

                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid item xs={12}>
                        {
                            !chartObj?.chart_type && <Typography class="text-capitalize">Chart Type: {chartObj?.chart_type}  </Typography>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        {
                            !chartObj?.image_url && <Typography>No Preview Available</Typography>
                        }
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "15rem" }}>
                        {chartObj?.image_url &&
                            <Box component="img" src={chartObj.image_url}
                                sx={{ width: "100%", height: "100%" }} alt={chartObj.title + ' preview'} />}
                    </Grid>
                </Box>
            </CardContent>
        </Card >
    )
}