import { TABLE_CUSTOMER_ATTRIBUTES, TABLE_CUSTOMER_METRICS, TABLE_CUSTOMER_V2, TABLE_VIEW_CUSTOMER_CONTACTABILITY } from "../constants";
import { USE_QUERY_DEFAULT_OPTIONS } from "../../../../Constants/settings";
import ApiClient from "../../../../common/API";
import { useQuery } from "react-query";
import { convertParamsToTranDate, convertParamsToTransactionDate, preProcessParams } from "../util";
import moment from "moment";
import { useAvailableDateRangeV2 } from "../hooks";


export const useCustomerContactability = (params, useQueryOptions) => {
    const hasConsent = params?.smac_consent
    const columnList = hasConsent ? [ 'smac_consent', 'cm_channel_desc' ] : [ 'cm_channel_desc' ]
    
    const aggregateList = [
        'with_email_sum',
        'with_mobile_sum',
        'with_mobile_email_sum',
        'not_contactable_sum',
    ]

    const queryKey = [TABLE_CUSTOMER_METRICS, TABLE_VIEW_CUSTOMER_CONTACTABILITY, params, columnList, aggregateList]

    return useQuery(queryKey, () => {
        const processedParams = preProcessParams(
            convertParamsToTransactionDate(params)
        );
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_METRICS}/query/`, {
            params: {
                view: TABLE_VIEW_CUSTOMER_CONTACTABILITY,
                ...processedParams,
                columns: columnList.join(','),
                group_by: columnList.join(','),
                aggregates: aggregateList.join(',')
            }
        }).then(res => {
            return res.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
}

export const useCustomerContactabilityV2 = (params, useQueryOptions) => {
    const hasCustomerDate = params?.month?.length > 0 && !!params?.year;
    const {data: availableDateRange, isLoading: isLoadingDateRange} = useAvailableDateRangeV2();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;
    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const columnList = [
        'gcr_customer_phone_flag', //'gcr_customer_number_flag',
        'gcr_customer_email_flag',
    ]
    
    const aggregateList = [
        'gcr_persistent_id_count_distinct',
    ]

    const queryKey = [TABLE_CUSTOMER_ATTRIBUTES, params, columnList, aggregateList]

    const useQueryResult = useQuery(queryKey, () => {
        const processedParams = preProcessParams(
            convertParamsToTranDate(params, dayOfMonthMaxDate), 
        );
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...processedParams,
                columns: columnList.join(','),
                group_by: columnList.join(','),
                aggregates: aggregateList.join(','),
            }
        }).then(res => {
            return res.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })

    const contactData = useQueryResult?.data?.data?.reduce((acc, row) => {
        if (row.gcr_customer_email_flag && row.gcr_customer_phone_flag) {
            acc.with_mobile_email_sum += row.gcr_persistent_id_count_distinct;
        } else if (row.gcr_customer_email_flag) {
            acc.with_email_sum += row.gcr_persistent_id_count_distinct;
        } else if (row.gcr_customer_phone_flag) {
            acc.with_mobile_sum += row.gcr_persistent_id_count_distinct;
        } else {
            acc.not_contactable_sum += row.gcr_persistent_id_count_distinct;
        }
        return acc;
    }, 
    {
        with_email_sum: 0,
        with_mobile_sum: 0,
        with_mobile_email_sum: 0,
        not_contactable_sum: 0,
    });

    return {
        ...useQueryResult,
        contactData
    }
}