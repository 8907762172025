import { getColorBasedOnPercentage } from "./util";


export default function IndicatorFromValue({data}) {
    let icon = null;
    if(data > 1 &&  data < 100) {
        icon = <i className="bi bi-arrow-down"></i>
    } else if( data >= 100) {
        icon = <i className="bi bi-arrow-up"></i>
    } 

    const dataDisplay = data !== 0 ? data+'%' : '--'
    return <div className={`text-center text-white py-1 fs-6`} style={{backgroundColor: getColorBasedOnPercentage(data)}}>
        {icon} {dataDisplay}
    </div>
}