import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import 'react-calendar/dist/Calendar.css';

export default function DeploymentContent(props) {
    return <>
        <i className="bi bi-calendar-event"></i>
        <label className="mx-2"><strong>Date of Blasting *</strong></label>
        <Calendar 
            onChange={(val)=> props.onChange('date', val)} 
            value={props.values.date} 
            className="calendar-style-overrides"
        />

        
        <div className="d-flex align-items-center">
            <i className="bi bi-clock"></i>
            <label className="mx-2"><strong>Time of Blasting *</strong></label>
            <TimePicker 
                onChange={(val)=> props.onChange('time', val)} 
                value={props.values.time}
                clearIcon={null}
                disableClock={true}
                className="clock-style-overrides" 
                hourPlaceholder="hh"
                minutePlaceholder="mm"
                />
        </div>
    </>
}