import { createContext, useContext } from 'react';

export const TogglePeriodContext = createContext(
    { 
        period: '', 
        updatePeriod: (value) => { } 
    }
);
export const useTogglePeriodContext = () => useContext(TogglePeriodContext);

export const FiltersContext = createContext(
    {
        filters: { company_id: [], month: '', year: '' },
        setFilters: (newFilters) => { },
        setFilter: (key, value) => { }
    }
);
export const useFiltersContext = () => useContext(FiltersContext);

// export const CategoryContext = createContext(
//     {
//         category:'',
//         setCategory: (newCategory) => { },
//     }
// )
// export const useCategoryContext = () => useContext(CategoryContext)