import TrendIndicator from "../CustomComponents/trendIndicator";
import SalesBarChartVertical from "./salesBarChartVertical";
import { getDateDisplay } from "../SideItems/util";
import CustomerSegment from "./customerSegment";
import StoreSegment from "./storeSegment";

import { useContext } from "react";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { Grid, } from "@mui/material";

export default function RenderChartView({
    placeholderImage,
    dateFilter,
    setDateFilter,
    salesApiEndPointName,
    apiDefaultFilter
}) {
    const windowSize = useContext(WindowSizeContext);

    return <>

        {
            !windowSize.isOnMobile &&
            <Grid container spacing={2} direction={'row'}>
                <TrendIndicator setDateFilter={setDateFilter} dateFilter={dateFilter} indicatorName={"Total Sales"}
                    salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter} />
                <TrendIndicator setDateFilter={setDateFilter} dateFilter={dateFilter} aggregate={'total_transactions'}
                    salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter}
                    indicatorName={"Total Transactions"} />

                <TrendIndicator setDateFilter={setDateFilter} dateFilter={dateFilter} aggregate={'atv'}
                    salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter}
                    indicatorName={"ATV"} />
            </Grid>
        }


        <Grid container direction={'row'} mt={4}>
            {
                salesApiEndPointName === "tss" ?
                    <>
                        <Grid container my={2} rowSpacing={{ xs: 1, sm: 1}} columnSpacing={{ xs: 1, sm: 1}}>
                            <Grid item xs={12} sm={6}>
                                <SalesBarChartVertical
                                    dateFilter={dateFilter}
                                    salesApiEndPointName={salesApiEndPointName}
                                    apiDefaultFilter={apiDefaultFilter}
                                    title={`${getDateDisplay(dateFilter)} Top 5 Stores by Sales`}
                                    groupBy="store"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SalesBarChartVertical
                                    salesApiEndPointName={salesApiEndPointName}
                                    apiDefaultFilter={apiDefaultFilter}
                                    dateFilter={dateFilter}
                                    limit={6}
                                    title={`${getDateDisplay(dateFilter)} Top 5 MBU by Sales`}
                                    groupBy="mbu"
                                />
                            </Grid>
                        </Grid>
                    </> : <>
                        {/* FRG Vertical bar chart*/}
                        <Grid>
                            <SalesBarChartVertical
                                salesApiEndPointName={salesApiEndPointName}
                                apiDefaultFilter={apiDefaultFilter}
                                dateFilter={dateFilter}
                                limit={6}
                                title={`${getDateDisplay(dateFilter)} Top 5 Product Group by Sales`}
                                groupBy={"product_group"}
                            />
                        </Grid>
                    </>

            }


        </Grid>
        {
            salesApiEndPointName === "tss" ?
                <>
                    <StoreSegment dateFilter={dateFilter} salesApiEndPointName={salesApiEndPointName} />
                    <CustomerSegment />
                </> : null
        }

    </>
}