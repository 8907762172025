import React from 'react';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export default function Search({ searchQuery, setSearchQuery }) {

    return (
        <TextField
            sx={{ bgcolor: "white", minWidth: "100%" }}
            size='small'
            id="outlined-search"
            label="Search Group"
            placeholder="Search Group"
            type="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
            }}
        />
    )
}