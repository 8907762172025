import Section from "../../../../../common/components/Dashboard/Section";
import AverageSpendPerVisitTable from "./AverageSpendPerVisitTable";

export default function AverageSpendPerVisit(){

    return <Section
        title={'Customer ATV by Basket Tier'}>

        <AverageSpendPerVisitTable/>
    </Section>
}