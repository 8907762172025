import React, {useMemo} from 'react';
import NestedDropdownCheckbox from "../../../../../common/components/NestedDropdownCheckbox";
import {Grid} from "@mui/material";
import {useSelectorState} from "../../../common/providers/selectorProvider";
import {useSubDeptDictionary} from "../../hooks";


export default function SubDepartmentSelector() {
    const {data: subdeptDictionary, isLoading: isSubDeptDictLoading} = useSubDeptDictionary();
    const defaultSubDeptFilters = {bu: 'Overall', dept: 'Overall', subdept: 'Overall'}

    const [filters, setFilters] = useSelectorState('subDeptFilters', defaultSubDeptFilters);

    const onSelectFilterChange = (subDept) => {
        if (!subDept?.length) {
            setFilters(defaultSubDeptFilters);
            return;
        }
        const parseSubdept = subDept[0].split('|')[0];

        if (parseSubdept !== JSON.stringify(filters)) {
            setFilters(JSON.parse(parseSubdept));
        }
    };

    const transformedOptions = useMemo(() => (
        Object.entries(subdeptDictionary).map(([bu, deptObj]) => ({
            label: bu,
            value: bu,
            children: Object.entries(deptObj).map(([dept, subdepts]) => ({
                label: dept,
                value: dept,
                children: subdepts.map(subdept => ({
                    label: subdept,
                    value: JSON.stringify({dept, subdept, bu}) + '|' + subdept  // PIPE split/append is a hack
                }))
            }))
        }))
    ), [subdeptDictionary]);

    return <Grid item xs={12} sm={3}>
        <NestedDropdownCheckbox
            label="All Sub-Departments"
            options={transformedOptions}
            disabled={isSubDeptDictLoading}
            onSelectionChange={onSelectFilterChange}
            hasCheckboxAtLevel={depth => depth === 2}
            singleSelect
        />
    </Grid>
}