import React from "react";
import Transactions from "./Transactions";
import Demographics from "../../Demographics";
import { useTogglePeriodContext, useFiltersContext } from "../Provider/context";
export function RenderCustomerViewContent({ viewBy, params, period, store, storeID, onTogglePeriodChange, filters, onDropDownFilterChange,genAgeValue }) {
    // const { filters, setFilters, setFilter } = useFiltersContext();
    // const { period, updatePeriod } = useTogglePeriodContext();

    switch(viewBy) {
        case 'transactions':
            return <Transactions params={params} period={period} store={store} storeID={storeID} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} />
        case 'segments':
            return 'Segments is placed here'
        case 'cross-shopping':
            return 'Cross-shopping is placed here'
        default:
            return <Demographics params={params} storeID={storeID} genAgeValue={genAgeValue} period={period}/>
    }
}