
import Section from "../../../../../common/components/Dashboard/Section";
import { useDayTimeSalesTableData } from "../../hooks";
import StoreSalesPerCustomerTable from "./StoreSalesPerCustomerTable";

export default function StoreSalesPerCustomer() {
    const { data, isLoading } = useDayTimeSalesTableData({})

    return <Section
        title={'Sales per Customer by Day of Week and Time of Day'}>
        <StoreSalesPerCustomerTable data={data} isLoading={isLoading} />
    </Section>
}