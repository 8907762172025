import { useState } from 'react';
import { FiltersContext } from './context';

export default function FiltersProvider({ children }) {
    const [filters, setFilters] = useState({
        company_id: [], // Initial company IDs
        month: null,
        year: null,
        smac_consent: true,
        channel: "In Store",
    });

    const setFilter = (key, value) => {
        setFilters({
            ...filters,
            [key]: value,
        });
    };

    return (
        <FiltersContext.Provider
            value={{
                filters,
                setFilters,
                setFilter,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
}
