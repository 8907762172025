import mobilePreviewImg from '../../../img/mobile_device.png';
import useChannelOptions from '../../../common/hooks/useChannelOptions';
import { useEffect,useState } from 'react';
import Iframe from '../../../common/components/Iframe';

export default function MobilePreview(props) {
    // TODO: Revisit Implementation if correct
    const { options: viberOptions } = useChannelOptions('viber');
    const { options: smsOptions } = useChannelOptions('sms'); 
    const { options: emailOptions } = useChannelOptions('email'); 

    const [imageSrc, setImageSrc] = useState('')
    useEffect(()=>{
        if(props.values.imageSrc && props.values.imageSrc !== '') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(props.values.imageSrc)
        } else {
            setImageSrc('');
        }
        
    }, [props.values.imageSrc])
    
    function renderViberPreview() {
        return <>
            <div className="d-flex mx-4 border-bottom">
                <div className="me-auto p-2">
                    <i className="bi bi-arrow-left mx-2 viber-color" style={{ fontSize: '1.5rem' }}/>
                    <label className="ms-2"> {props.values.fromViber ? viberOptions.filter(option => option.value === props.values.fromViber)[0].label : 'FROM:'} </label>
                </div>
                <div className="p-2">
                    <i className="bi bi-info-circle-fill me-1 viber-color" style={{ fontSize: '1.5rem' }}/>
                    <i className="bi bi-three-dots-vertical ms-1 viber-color" style={{ fontSize: '1.5rem' }}/>
                </div>
            </div>
            <div className="d-flex mx-4">
                <div className="p-2">
                    <i className="fs-3 bi bi-person-circle"/>
                </div>
                <div className="viber-parent-content-div col">
                    <div className="viber-content-div">
                        <div className="p-2" style={{ whiteSpace: 'pre-line' }}>{props.values.contentViber}</div>    
                        <div className="viber-image">
                            <img src={imageSrc} alt="" style={{width: '100%', border: '1px solid lightgray'}}/>
                        </div>
                        <div className={props.values.imageSrc ? "d-grid py-3 px-4" : "d-grid px-4 pb-4"} >
                            { props.values.actionCaption && <span className="text-white text-center py-1 rounded-pill viber-bg-color">{props.values.actionCaption}</span> }
                        </div>
                    </div>
            </div>
            </div>

            <div className="position-absolute bottom-0 w-100">
                <div className="d-flex  mx-4 border-top">
                    <div className="p-3 pb-0">
                        <label className="text-muted"><small>Type a messsage...</small></label>
                    </div>
                </div>

                <div className="d-flex  mx-4">
                    <div className="me-auto p-3 pt-2">
                        <i className="bi bi-image me-2 text-muted" style={{ fontSize: '1.5rem' }}/>
                        <i className="bi bi-camera mx-2 text-muted" style={{ fontSize: '1.5rem' }}/>
                        <i className="bi bi-file-earmark-text mx-2 text-muted" style={{ fontSize: '1.5rem' }}/>
                    </div>
                    <div className="p-0 pe-3">
                            <div className="badge viber-bg-color rounded-circle p-2">
                                <i className="bi bi-send" style={{ fontSize: '1.5rem'}}/>
                            </div>
                    </div>
                </div>
            </div>
        </>
    }

    function renderSmsPreview() {
        return <>
            <div className="d-flex justify-content-between  mx-4 border-bottom">
                <div className="p-2">
                    <i className="bi bi-chevron-left mx-2 text-primary" style={{ fontSize: '1.5rem' }}/>
                </div>
                <div className="p-2 text-center">
                    <label className="text-uppercase pt-1">  {props.values.fromSms ? smsOptions.filter(option => option.value === props.values.fromSms)[0].label : 'FROM:'} </label>
                </div>
                <div className="p-2">
                    <i className="bi bi-camera-video me-1 text-primary" style={{ fontSize: '1.5rem' }}/>
                </div>
            </div>

            
            <div className="sms-parent-content-div me-4">
                <div className="sms-content-div mx-4 ms-5">
                    <div className="p-2"  style={{ whiteSpace: 'pre-line' }}>{props.values.contentSms}</div> 
                </div>
            </div>

            <div className="position-absolute bottom-0 w-100">

                <div className="d-flex  mx-4">
                    <div className="p-3 pt-2 pe-0">
                        <i className="bi  bi-camera-fill me-2 text-muted" style={{ fontSize: '1.5rem' }}/>
                    </div>
                    
                    <div className="p-2 flex-fill">
                        <div className="border rounded-pill text-muted">
                            <label className="py-1 ps-3"><small>Message</small></label>
                            <i className="bi bi-soundwave mx-2 text-muted float-end" style={{ fontSize: '1.5rem' }}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    function renderEmailPreview() {
        return <>
            <div className="d-flex flex-column mx-4">
                <div className="p-2 fs-5">
                    {props.values.subjectEmail ? props.values.subjectEmail : 'Email Subject here'}
                </div>
                <div className="d-flex p-2">
                    <div className="d-flex align-items-center w-100">
                        <i className="bi bi-person-circle" style={{ fontSize: '1.5rem' }}/>
                        <label className="ms-2"> {props.values.FromEmailId ? emailOptions.filter(option => option.value === props.values.FromEmailId)[0].label : 'From' } </label>
                    </div>
                    
                    

                    <div className="d-flex flex-shrink-1 p-2 fs-5">
                        <i className="bi bi-reply pe-2"></i>
                        <i className="bi bi-three-dots-vertical"></i>
                    </div>
                </div>
            </div>
            <div className="email-parent-content-div d-block mx-4">    
                <div className="iframe-embed-mobile-wrapper">
                    <Iframe title="email_mobile_preview_iframe" width={'100%'} height={'100%'}>
                        <div dangerouslySetInnerHTML={{ __html: props.values.contentEmail }} />
                    </Iframe> 
                </div> 
            </div>
        </>
    }   

    function renderMobilePreview() {
        switch(props.values.channel) {
            case 'sms' :
                return renderSmsPreview()
            case 'email' :
                return renderEmailPreview()
             default : 
                return renderViberPreview()   
        }
    }

    return <div className="col-sm-4">
        <div className="position-relative"> 
            <img src={mobilePreviewImg} className="mobile-img" alt="mobile_device.png"/>
            <div className="mobile-content">
                {
                    renderMobilePreview()
                }
            </div>
        </div>
    </div>
}