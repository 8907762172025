import { Grid, styled, ClickAwayListener, IconButton, Box, Typography, Divider, Fade } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { inventoryMetricFormulas } from "../utils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export default function InventoryInformationToolTip({ onChange, open }) {
    const handleTooltipClose = () => {
        onChange(false);
    };

    const handleTooltipToggle = () => {
        onChange(!open);
    };

    const LightTooltip = styled(({ className, ...props }) => (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                {...props} classes={{ popper: className }} />
        </ClickAwayListener>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.white,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[2],
            fontSize: 11,
            maxWidth: "none",
        },
    }));

    return (
        <Grid>
            <LightTooltip
                onClose={handleTooltipToggle}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                TransitionComponent={Fade}
                arrow
                slotProps={{
                    popper: {
                        sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                            {
                                marginTop: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                            {
                                marginBottom: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                            {
                                marginLeft: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                            {
                                marginRight: '0px',
                            },
                        },
                    },
                }}
                title={
                    < Box component="div" sx={{ height: '220px', overflow: 'auto' }}  px={1}>
                        <Typography variant="body1" fontWeight={'bold'} my={.5} > Metrics Formula</Typography>
                        <Divider sx={{ borderBottomWidth: '2px', backgroundColor: '#000' }} />
                        <Typography variant="body2">
                            {inventoryMetricFormulas.map((value, index) => {
                                const parts = value.split(':');
                                return (
                                    <div key={index}>
                                        <span style={{ fontWeight: 'bold' }}>{parts[0]}</span>: {parts[1]}
                                    </div>
                                );
                            })}
                        </Typography>
                    </Box>
                }
            >
                <IconButton onClick={handleTooltipToggle}> <InfoOutlinedIcon sx={{ color: "#0A4FB4" }} /></IconButton>
            </LightTooltip >

        </Grid >
    )
}
