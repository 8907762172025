import * as React from 'react';
import { Typography, Box, styled, LinearProgress, linearProgressClasses } from '@mui/material';

export default function ProgressBarIndicator({ value }) {

    const ProgressLinearBar = styled(LinearProgress)(({ theme }) => ({
        height: 'inherit',
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 100 : 800]
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#c4e0f5' : '#bed7e9',
        }
    }));

    return (
        <Box sx={{ height: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
            <ProgressLinearBar variant={'determinate'} value={Math.min(value, 100)} style={{ width: '100%' }} />
            <Typography variant={'body2'} fontSize={'12px'} fontWeight={500} color={'#000000'} style={{ position: 'absolute' }}>
                {`${value}%`}
            </Typography>
        </Box>

    )
}