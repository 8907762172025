import React, {useMemo} from 'react';
import {useFilterValuesV2} from '../hooks';
import {ConvertToReactSelectOption, getSelectedOption} from '../../../../common/utils';


import Grid from '@mui/material/Grid';
import DropdownCheckboxV3 from '../../../../common/components/DropdownCheckboxV3';

export default function FilterProduct({onDropdownChange, filters}) {
    const {data: filterValues, isLoading} = useFilterValuesV2({
        column: "merch_article_desc",
        filters: {
            company_key: filters?.company_key,
            merchcat_dept_desc: filters?.merchcat_dept_desc,
            merch_brand_name: filters?.merch_brand_name
        }
    });
    
    const productOptions = ConvertToReactSelectOption(filterValues) 

    const selectedProductOption = useMemo(() => {
        if (!filters?.merch_article_desc  || !productOptions || productOptions.length === 0) {
            return null
        }
        
        const option = getSelectedOption(filters?.merch_article_desc, productOptions)
        if (option) {
            return option
        } else {
            onDropdownChange( [], 'merch_article_desc')
            return null
        }
    }, [productOptions,filters?.merch_article_desc])

    return (
        <>
            <Grid sx={{mt:1}}>
                    <DropdownCheckboxV3
                        options={productOptions }
                        value={selectedProductOption || [] } 
                        label="All Product"
                        placeholder="All Product"
                        limitTags={1}
                        loading={isLoading}
                        onChange={(event, newValue) => onDropdownChange(newValue, 'merch_article_desc')}
                    />
            </Grid>
        </>
    );
}