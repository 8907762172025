import React, {useContext, useEffect} from "react";
import {useFilterValues} from "../../../../../common/hooks/commonDataHooks";
import {getSelectedOption, isArraysEqual} from "../../../../../common/utils";
import ConditionalSelectComponent from "../../../../../common/components/ConditionalSelectComponent";
import {WindowSizeContext} from "../../../../../common/context/WindowSizeContext";
import DropdownCheckboxV3 from "../../../../../common/components/DropdownCheckboxV3";


export default function Filter(
    {tableName, definition, filters, setFilter, params, filterKeysUsed, setFilterKeysUsed, filterDefinitions}
) {
    const windowSize = useContext(WindowSizeContext)

    const filterParams = React.useMemo(() => {
        const tmp = {
            ...params
        }

        const indexOfThisFilter = filterKeysUsed.indexOf(definition.key)
        const applicableFilterKeys = filterKeysUsed.filter(
            (key, index) => index <= indexOfThisFilter || indexOfThisFilter === -1
        ).filter(key => key !== definition.key).filter(key => {
            return definition.crossFilterSources?.includes(key)
        })

        applicableFilterKeys.forEach(source => {
            if (filters[source]) {
                const sourceColumn = filterDefinitions[source].column
                tmp[sourceColumn] = filters[source]
            }
        })

        return tmp
    }, [definition, filters, params, filterKeysUsed])

    const dependencyFiltersHaveValues = !definition.dependencyFilterSource ||
        definition.dependencyFilterSource.length === 0 ||
        definition.dependencyFilterSource?.every(source => {
            const dependencyDefinition = filterDefinitions[source]
            const key = dependencyDefinition['key']
            return !!filters[key] && filters[key].length > 0
        })

    const {data, isLoading} = useFilterValues(
        tableName,
        definition.column,
        filterParams
    )

    const values = data?.[definition.column] || []

    const options = values.map(row => {
        return row[definition.column]
    }).map(value => {
        const label = definition.labelFormatter ? definition.labelFormatter(value) : value
        return {
            value,
            label: label
        }
    });

    const selectedValues = filters?.[definition.key] || (definition.multiSelect ? [] : null)
    const selectedOptions = selectedValues ?
            getSelectedOption(selectedValues, options) :
        null;

    useEffect(() => {
        if (isLoading) {
            return  // don't do anything if we are still loading
        }
        // remove value from filter if it is not in the options
        if (!dependencyFiltersHaveValues && selectedValues) {
            setFilterValue(null)
        }
        if (Array.isArray(selectedValues)) {
            if (selectedValues.length !== selectedOptions.length) {  // meaning we can't find the options for those values
                const newValues = selectedOptions.map(o => o.value)
                setFilterValue(newValues)
            }
        } else {
            if (!selectedOptions || selectedOptions.length === 0) {
                setFilterValue(null)
            }
        }
    }, [selectedOptions, selectedValues, dependencyFiltersHaveValues, isLoading]);


    const componentId = `${tableName}-${definition.column}-filter`

    const setFilterValue = (newValue) => {
        if (Array.isArray(newValue)) {
            // check if newValue array != selectedValues
            if (!isArraysEqual(newValue, selectedValues)) {
                setFilter(definition.key, newValue);
            }
        } else {
            // check if the 2 values are equal
            if (newValue !== selectedValues) {
                setFilter(definition.key, newValue);
            }
        }

        setFilterKeysUsed(prev => {
            if (!newValue || newValue.length === 0) {
                return prev.filter(key => key !== definition.key)
            } else {
                // ensure that this key is in the used fitlers list
                if (!prev.includes(definition.key)) {
                    return [...prev, definition.key]
                } else {
                    return prev
                }
            }
        });
    }

    if (definition.multiSelect) {
        return <DropdownCheckboxV3
            id={componentId}
            options={options}
            loading={isLoading}
            {...definition}
            onChange={(event, newValue) => {
                setFilterValue(newValue)
            }}
            value={selectedOptions}
            disabled={!dependencyFiltersHaveValues}
            // value={getSelectedOption(brandFilters, options)}
        />
    }
    return <ConditionalSelectComponent
        id={componentId}
        labelId={`${tableName}-${definition.column}-filter-label`}
        options={options}
        value={selectedOptions}
        onSelectChange={(event, newValue) => {
            const rawNewValue = windowSize.isOnMobile ?
                event?.target?.value :
                newValue?.value;
            setFilterValue(rawNewValue)
        }}
        startAdornment={false}
        endAdornment={true}
        isLoading={isLoading}
        clearSelected={() => {
            setFilterValue('')
        }}
        disabled={!dependencyFiltersHaveValues}
        disableClearable={false}
        {...definition}
    />;
}