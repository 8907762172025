import { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query'
import { Outlet, useLocation, useNavigate  } from 'react-router-dom'
import ApiClient from './common/API';
import LoadingComponent from './common/components/LoadingComponent';
import { ModulePermissionsContext } from './common/context/ModulePermissionsContext';
import { Tooltip } from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Drawer, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuButton from './common/components/MenuButton';
import Typography from '@mui/material/Typography';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { LoginContext } from './common/context/LoginContext';
import { toast } from 'react-toastify';

function hasAccess(strModule, isParent, data) {
	if (isParent) {
		const parentLinks = data.map(module => module?.split('/')?.[0])
		return parentLinks?.length > 0 && parentLinks.includes(strModule)
	} else {
		return data?.length > 0 && data.includes(strModule)
	}
}

export default function Main() {
	const location = useLocation();
	const navigate = useNavigate();
	const theme = createTheme({
		typography: {
			fontFamily: [
				'"Henry Sans"',
				'"sans-serif"'
			].join(',')
		},
		components: {
			MuiAutocomplete: {
				styleOverrides: {
					root: {
						backgroundColor: '#fff',
						borderRadius: '.25rem'
					}
				}
			}
		}

	});

	const screenSize = useMediaQuery(theme.breakpoints.down('md'));
	const hideNavBar = location.pathname.includes('/markets-dashboard/') && screenSize;
	const { setModuleList } = useContext(ModulePermissionsContext)

	const { loginDetails } = useContext(LoginContext)

	if (loginDetails?.requirePasswordChange) {
		toast.warn("Kindly Change your password", {
			toastId: 'change_password',
			autoClose: false,
			position: "top-center"
		})
	}

	const { isLoading, data: permittedModulesData } = useQuery('userPermissions', () => ApiClient().get('/module/my_modules').then(res => {
		setModuleList(res.data.modules)
		return res.data.modules
	}), {
		refetchOnWindowFocus: false
	});

	const { data: authProfileData } = useQuery('authProfile', () => ApiClient().get('/auth/my_profile').then(res => {
		return res.data
	}), {
		refetchOnWindowFocus: false
	});

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	const [dashboardCardModules, setDashboardCardModules] = useState([])

	useEffect(() => {
		if (permittedModulesData && Array.isArray(permittedModulesData)) {
			
			const clonedPermittedModulesData = permittedModulesData?.filter(data => data !== 'dashboard') //remove the public module, dashboard

			if(clonedPermittedModulesData?.length === 1) {
				navigate(clonedPermittedModulesData[0])
			}
			const dashboardCardModules = [];

			permittedModulesData.forEach((modulePath) => {
				if (modulePath.startsWith('location-analytics/') ||
					modulePath.startsWith('market-basket-analysis/') ||
					modulePath.startsWith('markets-dashboard/')) {
					dashboardCardModules.push(modulePath);
				}
			});

			setDashboardCardModules(dashboardCardModules)
		}
	}, [permittedModulesData]);


	if (isLoading) {
		return <LoadingComponent />
	}

	return <>
		<ThemeProvider theme={theme}>
			<ToastContainer />
			{!hideNavBar && (
				<AppBar position="sticky" component="nav" sx={{ backgroundColor: '#ffffff', py: 1 }}>
					<Toolbar disableGutters>
						{screenSize && (
							<Grid container xs={1} justifyContent={'flex-start'} alignContent={'center'}>
								<IconButton
									aria-label="menu"
									onClick={toggleDrawer}
								>
									<MenuIcon sx={{ color: 'blue', fontSize: 26 }} />
								</IconButton>
							</Grid>
						)}
						<Grid container sx={{ color: '#1976d2', ml: `${screenSize ? "0px" : "20px"}` }} >
							<Typography variant="h5" component="div" fontFamily={'Henry Sans'} letterSpacing={1}>
								<span style={{ fontWeight: 'bold' }}>SM</span>Intelligence
							</Typography>
						</Grid>


						<Grid container justifyContent={'flex-end'}>
							<Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', mr: 1 }}>
								<MenuButton name="Dashboard" path='/dashboard' access={hasAccess('dashboard', false, permittedModulesData)} items={null} handleDrawer={() => { }} dashboardCards={dashboardCardModules} screenSize={screenSize} />
								<MenuButton name="Chart Gallery" path="/chart-gallery" access={hasAccess('chart-gallery', false, permittedModulesData)} items={null} handleDrawer={() => { }} dashboardCards={dashboardCardModules} />
								<MenuButton name="Dashboard Editor" path="/dashboard-editor" access={hasAccess('dashboard-editor', false, permittedModulesData)} items={null} handleDrawer={() => { }} dashboardCards={dashboardCardModules} />
								<MenuButton name="Data Explorer" path="/data-explorer" access={hasAccess('data-explorer', false, permittedModulesData)} items={null} handleDrawer={() => { }} dashboardCards={dashboardCardModules} />
								<MenuButton name="Campaign Creation" path="/campaign-creation" access={hasAccess('campaign-creation', false, permittedModulesData)} items={null} handleDrawer={() => { }} dashboardCards={dashboardCardModules} />
								<MenuButton name="Curated Analytics" path="/others/curated-analytics" access={hasAccess('others/curated-analytics', false, permittedModulesData)} handleDrawer={() => { }} dashboardCards={dashboardCardModules} />
							</Box>
						</Grid>

						{/* Notification Icon */}
						{/* <Box sx={{display: { md: 'block'}, alignItems:'center', mr:1}}>
						<MenuButton name="Profile" isIconButton={true} icon={<NotificationsRoundedIcon sx={{fontSize:30, color:'black'}}/>} isUserMenu={true}
								items={ null }/>
				</Box>	 */}

						<Box sx={{ display: { md: 'block' }, alignItems: 'center', mr: 1 }}>
							<Grid container alignItems="center">
							<MenuButton name="Profile" isIconButton={true} icon={<AccountCircle sx={{ fontSize: 38, color: 'black' }} />} isUserMenu={true} loginDetails={{ ...loginDetails, ...authProfileData }}
								items={
									[
										{ name: authProfileData?.username, access: true, displayOnly: true },
										{ name: 'Admin Management', path: "admin-management", access: hasAccess('admin-management', false, permittedModulesData), itemIcon: <PersonRoundedIcon /> },
										{ name: 'Manage this module', path: null, access: hasAccess('module-permission', false, permittedModulesData), itemIcon: <GroupsRoundedIcon /> },
									]
								}
							/>
							</Grid>
						</Box>

						<Drawer
							open={screenSize && isDrawerOpen}
							anchor={'left'}
							ModalProps={{ onBackdropClick: toggleDrawer }}>
							<Box sx={{ display: { md: 'block' }, width: '350px' }}>
								<MenuButton name="Dashboard" path='/dashboard' access={hasAccess('dashboard', false, permittedModulesData)} items={null} handleDrawer={toggleDrawer} dashboardCards={dashboardCardModules} />
								<MenuButton name="Chart Gallery" path="/chart-gallery" access={hasAccess('chart-gallery', false, permittedModulesData)} items={null} handleDrawer={toggleDrawer} dashboardCards={dashboardCardModules} />
								<MenuButton name="Dashboard Editor" path="/dashboard-editor" access={hasAccess('dashboard-editor', false, permittedModulesData)} items={null} handleDrawer={toggleDrawer} dashboardCards={dashboardCardModules} />
								<MenuButton name="Data Explorer" path="/data-explorer" access={hasAccess('data-explorer', false, permittedModulesData)} items={null} handleDrawer={toggleDrawer} dashboardCards={dashboardCardModules} />
								<MenuButton name="Campaign Creation" path="/campaign-creation" access={hasAccess('campaign-creation', false, permittedModulesData)} items={null} handleDrawer={toggleDrawer} dashboardCards={dashboardCardModules} />
								<MenuButton name="Curated Analytics" path="/others/curated-analytics" access={hasAccess('others/curated-analytics', false, permittedModulesData)} handleDrawer={toggleDrawer} dashboardCards={dashboardCardModules} />
							</Box>
						</Drawer>
					</Toolbar>
				</AppBar>
			)}
			<Container maxWidth="xl" id="outletContainer" >

				<Outlet />

				<Tooltip id="info_tooltip" className='tooltip-zIndex' />
			</Container>
		</ThemeProvider>
	</>
}
