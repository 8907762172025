import React, {useContext, useMemo} from "react";
import {useLocationData} from "../../apiHooks";
import {StoreAnalyticsFilterContext} from "../../contexts";
import Select from "react-select";

export default function BranchSelector({locationApiEndPoint,storeItemsSelectOverridesStyles}) {

    const {
        isLoading: isLocationLoading,
        isError,
        data: locationData,
        error,
    } = useLocationData(locationApiEndPoint)
    const {region, province, branch_id, setFilter} = useContext(StoreAnalyticsFilterContext)

    const companyPrefix = useMemo(() => {
        switch(locationApiEndPoint) {
            case "/custom/location/":
                return ""
            case "/custom/location/ssmi/":
                return "SM Hypermarket "
            case "/custom/location/smc/":
                return "Savemore "
            case "/custom/location/svi/":
                return "SM Supermarket "
            default:
                return ""
        }
    }, [locationApiEndPoint])

    const branchOptions = useMemo(() => {
        const smLocations = locationData?.filter(
            row => row.company.toLowerCase() === "sm" || row.company.toLowerCase().includes('(sm)')
        )
        const mallLocations = smLocations?.filter(row => {
            if (locationApiEndPoint === "/custom/location/") {
                // special case. We only want the malls for TSS lcoations
                return row.category === "Mall"
            }
            return true
        })

        // filtered based on selected region and branch
        const filteredLocations = mallLocations?.filter(row => {
            if (region && province) {
                return row.region === region && row.province === province
            } else if (region) {
                return row.region === region
            } else if (province) {
                return row.province === province
            } else {
                return true
            }
        })

        const branches = filteredLocations?.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else if (a.name === b.name) {
                return 0
            } else {
                return 1
            }
        })
        
        const allBranchLabel = province ? `All Branches in ${province}` : (
            region ? `All Branches in ${region}` : "All Branches"
        )
        
        const newBranches = branches ? [...branches?.map(row => {
            return {
                label: companyPrefix + row.name,
                value: row.id
            }
        })] : []

        return [
            {label: allBranchLabel, value: null},
            ...newBranches
        ]
    }, [region, province, locationData, companyPrefix])

    const selectedBranchOption = useMemo(() => {
        const option = branchOptions.find(r => r.value === branch_id)
        if (option) {
            return option
        } else {
            setFilter('branch', null)
            setFilter('branch_name', null)
            return branchOptions[0]
        }
    }, [branchOptions, branch_id])

    return (

        <Select
            isDisabled={isLocationLoading}
            value={selectedBranchOption}
            onChange={(e) => {
                setFilter('branch_id', e?.value)
                setFilter('branch_name', e?.value ? e?.label : null)
            }}
            options={branchOptions}
            styles={storeItemsSelectOverridesStyles}
        />
    )
}