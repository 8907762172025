import React from "react";
import {useSegmentsBusinessUnitOptions} from "../../hooks";
import ContextBasedDropdownSelector from "../../../common/presentation/selectors/ContextBasedDropdownSelector";


export const SEGMENT_BU_OVERALL = 'Overall';


export default function BusinessUnitSelector() {
    const { data: options, isLoading } = useSegmentsBusinessUnitOptions()
    return (
        <ContextBasedDropdownSelector
            isLoading={isLoading}
            autoSelectFirstOption={false}
            options={options}
            label="Business Unit Group"
            contextKey="segment_bu"
            defaultValue={SEGMENT_BU_OVERALL}
            hideIfEmpty={true}
            disableClearable={true}
            />
    )
}