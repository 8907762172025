import { useMemo } from "react";
import {useQuery} from "react-query";
import ApiClient from "../../../../../common/API";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../../../Constants/settings";
import {TABLE_CUSTOMER_ATTRIBUTES, TABLE_CUSTOMER_METRICS} from '../../../Customers/constants'
import moment from "moment/moment";
import {convertParamsToTranDate, convertParamsToTransactionDate, preProcessParams} from "../../../Customers/util";
import {useAvailableDateRangeV2} from "../../../Customers/hooks";

export function useCustomerAgg() {
    return useQuery(
        [],
        () =>
            ApiClient().get(`data/table/${TABLE_CUSTOMER_METRICS}/`, {

            }).then((res) => res.data),
        {
            refetchOnWindowFocus: false,
            staleTime: 600000, // 10 mins
        }
    );
}

//Base Hook
export function useDemographicsData({params, view, aggregates}) {
    const hasCustomerDate = params?.month?.length > 0 && !!params?.year

    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRangeV2();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const { data, isLoading } = useQuery(
        [TABLE_CUSTOMER_METRICS, params, 'count'],
        () => {

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(params, dayOfMonthMaxDate)
            )
            
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_METRICS}/query/`, {
                params: {
                    ...processedParams,
                    aggregates:aggregates,
                    view: view,
                    limit: 1000
                }
            }).then(res => {
                return res.data.data;
            });
        }, {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasCustomerDate
        }
    );

    return{
        data,
        isLoading
    }
}

export function useGenderAgeInteractionData({params}){
    const { genAgeValue, ...restParams } = params;

    const hasStoreID = params?.store_id;
    const hasBU = params?.pm_business_unit_desc_standardized;

    const view = hasStoreID ? 'branch_demographics' : hasBU ? 'bu_demographics' : ''
    const aggregates = hasStoreID ? 'store_customer_count_sum' : hasBU ? 'dept_customer_count_sum' : ''

    const commonColumns = `year,month,${genAgeValue},gender_bin`;

    const columns = hasStoreID ? `${commonColumns},store_id` :
                    hasBU ? `${commonColumns},pm_business_unit_desc_standardized,pm_department_desc_standardized,pm_subdept_desc_standardized` :
                    commonColumns;

    const groupBy = columns;

    const { data: ageGenderInteractionData, isLoading: isAgeGenderInteractionDataLoading } = useDemographicsData({
        params: {
            ...restParams,
            columns:columns,
            group_by:groupBy,
        },
        view:view,
        aggregates: aggregates
    })
    return {
         ageGenderInteractionData,
         isAgeGenderInteractionDataLoading
     };
}

export function useGenderData({ params }) {
    const { genAgeValue, ...restParams } = params;

    const hasStoreID = params?.store_id;
    const hasBU = params?.pm_business_unit_desc_standardized;

    const view = hasStoreID ? 'branch_demographics' : hasBU ? 'bu_demographics' : ''
    const aggregates = hasStoreID ? 'store_customer_count_sum' : hasBU ? 'dept_customer_count_sum' : ''


    const commonColumns =  ['year','month','gender_bin'].join(',');
    const columns = hasStoreID ? [commonColumns,'store_id'].join(',') : 
                    hasBU ? [commonColumns,'pm_business_unit_desc_standardized','pm_department_desc_standardized','pm_subdept_desc_standardized'].join(',') :
                    commonColumns;

    const groupBy = columns;

    const { data: genderData, isLoading: isGenderDataLoading } = useDemographicsData({
        params: {
            ...restParams,
            columns: columns,
            group_by: groupBy
        },
        view:view,
        aggregates:aggregates
    })

    return {
        genderData,
        isGenderDataLoading
    }
}



export function useAgeGenerationData({ params }) {
    const { genAgeValue, ...restParams } = params;

    const hasStoreID = params?.store_id;
    const hasBU = params?.pm_business_unit_desc_standardized;

    const view = hasStoreID ? 'branch_demographics' : hasBU ? 'bu_demographics' : ''
    const aggregates = hasStoreID ? 'store_customer_count_sum' : hasBU ? 'dept_customer_count_sum' : ''

    const commonColumns =  ['year','month',`${genAgeValue}`].join(',');
    const columns = hasStoreID ? [commonColumns,'store_id'].join(',') : 
                    hasBU ? [commonColumns,'pm_business_unit_desc_standardized','pm_department_desc_standardized','pm_subdept_desc_standardized'].join(',') :
                    commonColumns;

    const groupBy = columns;

    const { data: ageGenerationData, isLoading: isAgeGenerationDataLoading } = useDemographicsData({
        params: {
            ...restParams,
            columns: columns,
            group_by: groupBy,
        },
        view: view,
        aggregates:aggregates
    })

    return {
        ageGenerationData,
        isAgeGenerationDataLoading
    }
}

//// Using L3 
export function useDemographicsDataL3({params}) {
    const hasCustomerDate = params?.month?.length > 0 && !!params?.year

    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRangeV2();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const { data, isLoading } = useQuery(
        [TABLE_CUSTOMER_ATTRIBUTES, params, 'count', 'l3_queries'],
        () => {

            const processedParams = preProcessParams(
                convertParamsToTranDate(params, dayOfMonthMaxDate)
            )
            
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
                params: {
                    ...processedParams,
                    aggregates:'gcr_persistent_id_count_distinct',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data;
            });
        }, {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasCustomerDate
        }
    );

    return{
        data,
        isLoading
    }
}

export function useGenderDataL3({params}) {

    const {customerView, ...restParams} = params

    const columnList = customerView === 'buView' ? "gender_bin"
    : ["smac_cdp_consent", "cm_channel_desc", "gender_bin"].join(',');

    const { data: genderData, isLoading: isGenderDataLoading } = useDemographicsDataL3({
        params: {
            ...restParams,
            columns: columnList,
            group_by: columnList,
        },
    })

    const groupedGenderData = useMemo(() => {
        // If no data is available, return an empty array
        if (!genderData) return [];
    
        // Calculate the sum of all store_customer_count_sum
        const overAllStoreCustomerCountSum = Object.values(genderData).reduce((sum, entry) => {
            return sum + entry.gcr_persistent_id_count_distinct;
        }, 0);
    
        // Group and sum the data by gender bin
        const formattedData = Object.entries(genderData).reduce((groupedData, [key, value]) => {
            const gender_bin = value.gender_bin;
            // Aggregate the store_customer_count_sum by gender bin
            groupedData[gender_bin] = (groupedData[gender_bin] || 0) + value.gcr_persistent_id_count_distinct;
            return groupedData;
        }, {});
    
        // Calculate the percentage for each gender bin and include the actual value
        const percentageData = Object.keys(formattedData).map(gender_bin => ({
            [gender_bin]: ((formattedData[gender_bin] / overAllStoreCustomerCountSum) * 100).toFixed(1),
            actual_value: formattedData[gender_bin]
        }));
    
        return percentageData;
    }, [genderData]);

    return {
        genderData: groupedGenderData,
        isGenderDataLoading
    }
}

export function useAgeGenerationDataL3({params}) {

    const {customerView, columns, ...restParams} = params
    
    const columnList = customerView === 'buView' ? columns
    : ["smac_cdp_consent", "cm_channel_desc", ...params.columns].join(',');

    const { data: ageGenerationData, isLoading: isAgeGenerationDataLoading } = useDemographicsDataL3({
        params: {
            ...restParams,
            columns: columnList,
            group_by: columnList,
        },
    })

    const bin_type = params?.columns;

    const groupedAgeData = useMemo(() => {

        if (!ageGenerationData) return [];

        // Filter and modify data
        const modifiedData = ageGenerationData
            .filter(item => item.age_bin !== 'Below 18' && item.generation_bin !== 'Gen Alpha')
            .map(item => ({
                ...item,
                age_bin: item.age_bin === '65-120' ? '65 and Above' : item.age_bin,
                generation_bin: item.generation_bin === 'Gen-Zs' ? 'Gen Z' : item.generation_bin
            }));

    
        // Initialize grouped data object and overall customer count sum
        const groupedData = modifiedData.reduce((acc, value) => {
            const bin = value[bin_type];
            acc[bin] = (acc[bin] || 0) +  value.gcr_persistent_id_count_distinct;
            return acc;
        }, {});
    
        // Calculate overall store_customer_count_sum for percentage calculation
        const overAllStoreCustomerCountSum = modifiedData.reduce((acc, value) => {
            return acc +  value.gcr_persistent_id_count_distinct;
        }, 0);
    
        // Get the keys of grouped data
        const keys = Object.keys(groupedData);
    
        // Calculate and append the percentage for each bin
        const formattedData = keys.map(bin => ({
            [bin]: ((groupedData[bin] / overAllStoreCustomerCountSum) * 100).toFixed(1),
            actual_value: groupedData[bin]
        }));
    
        return formattedData;
    }, [ageGenerationData, bin_type]);

    return {
        ageGenerationData: groupedAgeData,
        isAgeGenerationDataLoading
    }
}

export function useGenderAgeInteractionDataL3({params}) {

    const {customerView, columns, ...restParams} = params
    
    const columnList = customerView === 'buView' ? ['gender_bin', columns].join(',')
    : ["smac_cdp_consent", "cm_channel_desc","gender_bin", columns].join(',')

    const { data: genderAgeInteractionData, isLoading: isGenderAgeInteractionDataLoading } = useDemographicsDataL3({
        params: {
            ...restParams,
            columns: columnList,
            group_by: columnList,
        },
    })

    const bin_type = params?.columns;

    const transformedData = genderAgeInteractionData?.reduce((acc, entry) => {
        const ageGenGroup = entry[bin_type];
        const { gender_bin, gcr_persistent_id_count_distinct} = entry;
        const categoryGrouping = ageGenGroup;
    
        const gender = (() => {
            if (gender_bin === 'Male') {
                return 'male';
            } else if (gender_bin === 'Female') {
                return 'female';
            } else {
                return 'noData';
            }
        })();
    
        if (!acc[categoryGrouping]) {
            acc[categoryGrouping] = {
                categoryGrouping: categoryGrouping,
                male: 0,
                female: 0
            };
        }
    
        acc[categoryGrouping][gender] += gcr_persistent_id_count_distinct;
    
        return acc;
    }, {});
    
    
    const formattedData = transformedData && Object.values(transformedData).map(entry => ({
      categoryGrouping: entry.categoryGrouping,
      male: entry.male,
      female: entry.female
    }));

    const finalData = formattedData?.filter(item => item.categoryGrouping !== 'Below 18' && item.categoryGrouping !== 'Gen Alpha')
    .map(item => ({
        categoryGrouping: item.categoryGrouping === 'Gen-Zs' ? 'Gen Z' : item.categoryGrouping === '65-120' ? '65 and Above' : item.categoryGrouping,
        male: item.male,
        female: item.female
    }));

    return {
        genderAgeInteractionData: finalData, 
        isGenderAgeInteractionDataLoading
    }
}