import React from "react";

export default function UserMessage({children: message}) {
    return (
        <div className="balon1 p-2 m-0 clearfix">
            <a className="float-end">
                {message}
            </a>
        </div>
    )
}