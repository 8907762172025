import { Box, Grid, Typography } from "@mui/material";

export default function NoAvailableDataDisplay() {

    return <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#ffffff", borderRadius: "0 0 .5rem .5rem" }} >
        <Grid container justifyContent="center" alignItems="center" py={2} borderRadius={0}>
            <Typography
                sx={{ fontSize: "1rem", color: "black", textAlign: "center" }}
            >
                There is no available data.
            </Typography>
        </Grid>
    </Box>
}