import React, { useContext, useState } from "react";
import DonutChartFromApi from "./donutChartFromApi";
import TableFromApi from "./tableFromApi";
import { abbreviateNumber } from "../../../../common/utils";
import { getDateDisplay } from "../SideItems/util";
import { StoreAnalyticsFilterContext } from "../contexts";

import { Grid, IconButton, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function StoreSegment({ dateFilter, salesApiEndPointName }) {
    const [expanded, setExpanded] = useState(true)
    const { region, province, branch_name } = useContext(StoreAnalyticsFilterContext)
    const paramFilters = {
        region: region,
        province: province,
        store: branch_name?.toLowerCase(),
        ...dateFilter
    }

    return (<>
        <Grid container direction={'row'} mt={3}>
            {/* TODO: change this to percentage mode and fix floating labels */}
            <Grid item xs={12} sm={4} mb={2} borderRadius={2} boxShadow={1}>
                <Box sx={{ textAlign: 'right', display: { sm:'none', xs: 'block'} }}>
                    <IconButton
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            type="button"
                            style={{ zIndex: 100000 }}
                        >
                            {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </IconButton>
                </Box>                        
                <DonutChartFromApi
                    apiEndpoint={`/custom/${salesApiEndPointName}-map-sales/query/`}
                    params={{
                        columns: ["store_segment"].join(','),
                        group_by: "store_segment",
                        aggregates: "total_sales",
                        ...paramFilters
                    }}
                    dataReducer={(data) => {
                        return data.reduce((acc, item) => {
                            acc[item.storeSegment] = item.totalSales
                            return acc;
                        }, {});
                    }}
                    title={'Store Segment Sales'}
                    subTitle={'As of ' + getDateDisplay(dateFilter)}
                    rowFilter={(row) => { return row.storeSegment !== "" && row.storeSegment !== null }}
                />
            </Grid>

            <Grid item xs={12} sm={8} style={{ display: expanded ? 'block' : 'none', marginBottom: '16px' }}>

                <TableFromApi
                    apiEndpoint={`/custom/${salesApiEndPointName}-map-sales/query/`}
                    params={{
                        columns: ["store_segment"].join(','),
                        aggregates: ["store_count", "atv", "avg_age_years", "vip_count", "regular_count", "core_count", "loyal_count"].join(','),
                        group_by: "store_segment",
                        ...paramFilters
                    }}
                    rowFilter={(row) => { return row.storeSegment !== "" && row.storeSegment !== null }}
                    columns={[
                        { name: "Store Segment", selector: row => row.storeSegment, sortable: true },
                        {
                            name: "Store Count",
                            selector: row => row.storeCount,
                            sortable: true,
                            format: (row) => row.storeCount,

                        },
                        {
                            name: "ATV",
                            selector: row => row.atv,
                            sortable: true,
                            format: (row) => abbreviateNumber(row.atv)
                        },
                        {
                            name: "Ave. Age (Years)",
                            selector: row => row.avgAgeYears,
                            sortable: true,
                            format: (row) => row.avgAgeYears ? abbreviateNumber(row.avgAgeYears) : "No Data"
                        },
                        {
                            name: "VIPs",
                            selector: row => row.vipCount,
                            sortable: true,
                            format: (row) => {
                                const totalCustomers = row.vipCount + row.regularCount + row.coreCount + row.loyalCount;
                                return `${((row.vipCount / totalCustomers) * 100).toFixed(2)}%`
                            }
                        },
                        {
                            name: "Regulars",
                            selector: row => row.regularCount,
                            sortable: true,
                            format: (row) => {
                                const totalCustomers = row.vipCount + row.regularCount + row.coreCount + row.loyalCount;
                                return `${((row.regularCount / totalCustomers) * 100).toFixed(2)}%`
                            }
                        },
                        {
                            name: "Cores",
                            selector: row => row.coreCount,
                            sortable: true,
                            format: (row) => {
                                const totalCustomers = row.vipCount + row.regularCount + row.coreCount + row.loyalCount;
                                return `${((row.coreCount / totalCustomers) * 100).toFixed(2)}%`
                            }
                        },
                        {
                            name: "Loyals",
                            selector: row => row.loyalCount,
                            sortable: true,
                            format: (row) => {
                                const totalCustomers = row.vipCount + row.regularCount + row.coreCount + row.loyalCount;
                                return `${((row.loyalCount / totalCustomers) * 100).toFixed(2)}%`
                            }
                        },
                    ]}
                />
            </Grid>
        </Grid>
    </>)
}