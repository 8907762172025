import React, { useContext, useMemo } from 'react';
import ReactEcharts from "echarts-for-react";
import { titleCaseString } from '../../../private/customDashboards/common/util';

const DEFAULT_OPTIONS = {
    grid: {
        left: '75%',
    },
    animation: false,
    tooltip: {
        show: false,
    },
    yAxis: {
        type: "value",
        show: false,
    },
    xAxis: {
        type: "category",
        show: false,
    },
}

/**
 * SingleStackedBarWithDottedLabel component renders a single stacked bar chart with dotted labels.
 *
 * @param {object} options - ECharts options for the chart.
 * @param {array} data - An array of objects containing the data for the chart. Each object should have a key and a value. The key will be used as the label for the bar, and the value will be used as the value for the bar.
 * @param {object} echartStyle - CSS styles for the chart.
 *
 * @returns {ReactElement} A React element.
 */

export default function SingleStackedBarWithDottedLabel({ options = DEFAULT_OPTIONS, data = [], echartStyle = {}, colors = [] }) {

    const keys = data && data?.map(obj => Object.keys(obj)[0]);

    const commonLabelStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 13,
        color: '#000',
    }

    const series = keys?.map((key, index) => {

        const filteredData = data?.filter(obj => Object.keys(obj)[0] === key)[0];
        const percentage = filteredData?.[key];
        const actualValue = filteredData?.actual_value;

        const newValue = percentage < 5 ? 5 : percentage
        const adjuestedPadding = percentage.toString().length <= 3 ? 58 : 50 // checking is <=3 for 0.1 up to 9 values, short string need to adjust padding
        const color = colors[index % colors.length];
        return {
            animation: false,
            name: titleCaseString(key),
            type: "bar",
            barWidth: '50',
            stack: "genderTotal",
            label: {
                show: true,
                position: 'insideRight',
                ...commonLabelStyle,
                offSet: [100, 0],
                formatter: (params) => {
                    return `{nameStyle|${params?.seriesName}}....................{valueStyle|${params?.data?.percentageValue}%}`
                },
                rich: {
                    nameStyle: {
                        ...commonLabelStyle,
                        padding: [0, 50, 0, 0]
                    },
                    valueStyle: {
                        ...commonLabelStyle,
                        padding: [0, 0, 0, adjuestedPadding]
                    }
                }
            },
            //setting value to new value to set a min height, percentageValue is the actual value and will be the one to be displayed in label.
            data: [{
                value: newValue,
                name: key,
                percentageValue: percentage,
                actualValue: actualValue,
                itemStyle: {
                    color: color // Apply color here
                }
            }]
        }
    })

    const MemoizedOption = useMemo(() => {
        const option = {
            ...options,
            tooltip: {
                trigger: 'item',
                formatter: function (param) {
                    const formattedActualValue = new Intl.NumberFormat().format(param.data.actualValue);
                    return `${param.marker} ${param.seriesName}: ${formattedActualValue}`;
                },
                position: 'left'
            },
            series: series
        };

        return option;
    }, [data, options, series]);


    return <ReactEcharts
        notMerge={true}
        lazyUpdate={false} // resolves getRawIndex undefined error
        option={MemoizedOption}
        style={echartStyle} />;

}