import React, { useContext } from 'react';
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext";
import { Box, Grid, Typography } from "@mui/material";
import Item from "../../../../../common/components/ItemCard";
import PeriodTopLine from "./periodTopLine";
import { TopLineContext } from "../../contexts";
import MetricFilter from '../../../TmpTSSSales/Filters/MetricFilter'
import ToggleSelectorWithAdditional from '../../../TmpTSSSales/Filters/ToggleSelectorWithAdditional';
import Slider from 'react-slick';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAvailableMaxDateL3 } from '../../../TmpTSSSales/DataProvider/hooks';
import { checkIfCompletePeriod } from '../../../TmpTSSSales/util';

const DEFAULT_TEXT_MAP = {
    title: "Topline Performance"
}

export default function TopLine(
    {
        textMap = DEFAULT_TEXT_MAP,
        period, topLineMetrics, params, filters, onDropDownFilterChange,
        onTogglePeriodChange, isStickyForTopline, isWithTopLineHeader, isOnDefaultView, isStickyCustomerCarousel,
        componentProps = {
            metricFilter: {}
        }
    }
) {
    const _textMap = {
        ...DEFAULT_TEXT_MAP,
        ...textMap,
    }


    const { data: maxDate, isLoading: isLoadingMaxdate } = useAvailableMaxDateL3();
    const isQoQ = period === 'qoq';
    const isCompletePeriod = checkIfCompletePeriod(params, maxDate, isQoQ)

    const { items } = useContext(TopLineContext)
    const windowSize = useContext(WindowSizeContext)
    const isMTD = period === 'mtd';
    const TOGGLE_OPTIONS = [
        { label: 'MTD', value: 'mtd' },
        { label: 'YTD', value: 'ytd' },
        { label: 'QoQ', value: 'qoq' },
    ]

    const CustomArrow = (props) => {
        const { className, onClick, style, direction } = props;
        const ArrowIcon = direction === 'prev' ? ChevronLeftRoundedIcon : ChevronRightRoundedIcon;
        const IconStyle = direction === 'prev' ?
            { ...style, display: "block", left: '15px' } :
            {
                ...style,
                display: "block",
                right: '15px'
            };

        return (
            <div
                className={'slick-arrow'}
                onClick={onClick}
            >
                <ArrowIcon
                    className={className}
                    style={{ ...IconStyle, zIndex: 1, color: '#0077b6', fontSize: '40px' }}
                />
            </div>
        );
    };

    const slickCarouselSettings = {
        dots: true,
        infinite: true,
        slidesToShow: windowSize?.isOnMobile ? 1 : 5,
        slidesToScroll: 1,
        prevArrow: <CustomArrow direction="prev" />,
        nextArrow: <CustomArrow direction="next" />,
        customStyles: `
            .slick-dots li button:before {
                color: #0077b6;
                font-size: 8px;
            }
            .slick-dots li.slick-active button:before {
                color: #0077b6;
                font-size: 12px;
                opacity: 1;
            }`
    };

    const comparisonItems = items?.map((item) => item?.comparisonValues?.length)
    //added special case for personal shopper scorecard height
    const personalShopperCardHeight = params?.cm_channel_desc === 'Personal Shopper' && !isStickyForTopline

    let Height;
    if (!isLoadingMaxdate) {
        Height = "auto"
    } else if (personalShopperCardHeight) {
        Height = '142px';
    } else if ((isStickyForTopline && comparisonItems > 1) || (isStickyForTopline && !isCompletePeriod)) {
        Height = '70px';
    } else if (!isStickyForTopline && comparisonItems < 1) {
        Height = '142px';
    }

    if (topLineMetrics || isStickyForTopline) {
        return (
            <Grid container>
                {
                    isWithTopLineHeader &&
                    <Box flexGrow={1} xs sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem" }}>
                        <Grid container alignItems="center" py={.5}>
                            <Grid item xs={12} sm={7.5}>
                                <Typography variant='h6' ml={2}>
                                    {_textMap.title}
                                </Typography>
                            </Grid>
                            {
                                windowSize?.isOnMobile &&
                                <Grid item xs={12} sm mx={1} display={'flex'} justifyContent={'flex-end'}>
                                    <ToggleSelectorWithAdditional
                                        options={TOGGLE_OPTIONS}
                                        value={period}
                                        onChange={onTogglePeriodChange}
                                        additionalStyles={{ textTransform: 'none' }}
                                        lastIndexOfMain={2}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={2} mx={2} justifyContent={'flex-end'}
                                py={windowSize?.isOnMobile ? .75 : ''}>
                                <MetricFilter
                                    isMTD={isMTD}
                                    filters={filters}
                                    onFilterChange={onDropDownFilterChange}
                                    params={params}
                                    {...componentProps.metricFilter}
                                />
                            </Grid>
                            {
                                !windowSize?.isOnMobile &&
                                <Grid item xs={12} sm display={'flex'} justifyContent={'flex-end'}>
                                    <ToggleSelectorWithAdditional
                                        options={TOGGLE_OPTIONS}
                                        value={period}
                                        onChange={onTogglePeriodChange}
                                        additionalStyles={{ textTransform: 'none' }}
                                        lastIndexOfMain={2}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Box>
                }
                <Grid item xs={12} sm={12} md={12} py={'.5rem'}>
                    <style>{slickCarouselSettings.customStyles}</style>
                    <Slider {...slickCarouselSettings}>
                        {items?.map(item => {
                            return <>
                                <Grid item xs={12} mx={1} sm md>
                                    <Item sx={{ minHeight: `${Height}` }}>
                                        <PeriodTopLine isBU={true} isBUTopLine={!isStickyForTopline} isCompletePeriod={isCompletePeriod}
                                            isStickyDataCardDisplay={isStickyForTopline} params={params} period={period} {...item} />
                                    </Item>
                                </Grid>
                            </>
                        })}
                    </Slider>
                </Grid>
            </Grid>
        );
    }

    if (isOnDefaultView || isStickyCustomerCarousel) {
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} py={'.5rem'}>
                    <style>{slickCarouselSettings.customStyles}</style>
                    <Slider {...slickCarouselSettings}>
                        {items?.map(item => {
                            return <>
                                <Grid item xs={12} mx={1} sm md>
                                    <Item>
                                        <PeriodTopLine isBU={true} isStickyCustomerDataCardDisplay={isStickyCustomerCarousel} isCustomerDataCardDisplay={!isStickyCustomerCarousel} params={params} period={period} {...item} />
                                    </Item>
                                </Grid>
                            </>
                        })}
                    </Slider>
                </Grid>

            </Grid>
        )
    }

    return (

        <Grid container sx={{ marginBottom: "1rem" }}>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid item xs sm paddingY={.75}>
                    <Typography variant='h6' ml={2}>
                        {_textMap.title}
                    </Typography>
                </Grid>
            </Box>
            <Grid item xs={12} sm={12} md={12}>
                <Item>
                    <Grid
                        container
                        spacing={0}>
                        {
                            items.map(item => {
                                return <Grid item xs={12} sm>
                                    <PeriodTopLine {...item} />
                                </Grid>
                            })
                        }
                    </Grid>
                </Item>
            </Grid>
        </Grid>
    );
}