import React, { useMemo } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { useSystemLogsDailyCount } from '../../../common/API/hooks';

const LoadingSpinner = () => (
  <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', left: '720px', height: '100vh' }}>
    <div className="spinner-border" role="status">
      <span className="sr-only"></span>
    </div>
  </div>
);

const LoginLineChart = () => {
  const { data: successLoginCount, isLoading: successLoginCountLoading } = useSystemLogsDailyCount({ action: 'login_success' });
  const { data: failedLoginCount, isLoading: failedLoginCountLoading } = useSystemLogsDailyCount({ action: 'login_failed' });

  const { successCounts, failedCounts, dates } = useMemo(() => {
    if (!successLoginCount || !failedLoginCount) return {};

    const allDates = Array.from(new Set([...Object.keys(successLoginCount), ...Object.keys(failedLoginCount)]))
      .sort((a, b) => new Date(a) - new Date(b));

    const successCounts = allDates.map(date => successLoginCount[date] || 0);
    const failedCounts = allDates.map(date => failedLoginCount[date] || 0);
    console.log("successLoginCount", successLoginCount)
    return { successCounts, failedCounts, dates: allDates };
  }, [successLoginCount, failedLoginCount]);

  if (successLoginCountLoading || failedLoginCountLoading) {
    return <LoadingSpinner />;
  }

  // TODO: double-check display the data on the line chart.

  return (
    <LineChart
      width={1000}
      height={400}
      series={[
        { data: successCounts, label: 'Login Successful', color: 'green' },
        { data: failedCounts, label: 'Login Failed', color: 'red' },
      ]}
      xAxis={[{ scaleType: 'point', data: dates }]}
    />
  );
};

export default LoginLineChart;