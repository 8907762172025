import {
    TABLE_CUSTOMER_ATTRIBUTES,
    TABLE_CUSTOMER_BU_SOW,
    TABLE_VIEW_BRANCH_SOW,
    TABLE_VIEW_CHAIN_CADS_CUSTOMER_BIN,
    TABLE_VIEW_CHAIN_CADS_TXN_BIN
} from "../../constants";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../../../Constants/settings";
import ApiClient from "../../../../../common/API";
import {useQuery} from "react-query";
import {convertParamsToTranDate, convertParamsToTransactionDate, preProcessParams} from "../../util";
import moment from "moment";
import {useAvailableDateRangeV2} from "../../hooks";


export const useBranchShareOfWallet = (params, useQueryOptions) => {
    const columnList = [
        'store_basket_size', // 'bu_basket_size'
    ]
    const aggregateList = [
        'store_customer_count_sum',
        'store_txn_count_sum', // 'store_customer_txn_count_sum',
        'gross_sales_sum',
    ]

    const queryKey = [TABLE_CUSTOMER_BU_SOW, TABLE_VIEW_BRANCH_SOW, params, columnList, aggregateList]

    return useQuery(queryKey, () => {
        const processedParams = preProcessParams(
            convertParamsToTransactionDate(params)
        );
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_BU_SOW}/query/`, {
            params: {
                view: TABLE_VIEW_BRANCH_SOW,
                ...processedParams,
                columns: columnList.join(','),
                group_by: columnList.join(','),
                aggregates: aggregateList.join(',')
            }
        }).then(res => {
            return res.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
}

export const useChainShareOfWallet = (params, useQueryOptions) => {
    const columnList = [
        'chain_basket_size' //'total_basket_size'
    ]
    const aggregateList = [
        'chain_customer_count_max', // 'chain_customer_count_sum',
        'chain_txn_count_max', // 'chain_txn_count_sum',
        'gross_sales_sum',
    ]

    const queryKey = [TABLE_CUSTOMER_BU_SOW, TABLE_VIEW_BRANCH_SOW, params, columnList, aggregateList]

    return useQuery(queryKey, () => {
        const processedParams = preProcessParams(
            convertParamsToTransactionDate(params)
        );
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_BU_SOW}/query/`, {
            params: {
                ...processedParams,
                columns: columnList.join(','),
                group_by: columnList.join(','),
                aggregates: aggregateList.join(',')
            }
        }).then(res => {
            return res.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
}

export const useChainShareOfWalletV2 = (params, useQueryOptions) => {

    const hasCustomerDate = params?.month?.length > 0 && !!params?.year;
    const {data: availableDateRange, isLoading: isLoadingDateRange} = useAvailableDateRangeV2();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;
    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const columnList = [
        'basket_size_bin'
    ]
    const aggregateList1 = [
        'gcr_persistent_id_count_distinct',
    ]

    const queryKey1 = [TABLE_CUSTOMER_ATTRIBUTES, TABLE_VIEW_CHAIN_CADS_CUSTOMER_BIN, params, columnList, aggregateList1]

    const { data: customerBinData, isLoading: isLoadingCustomerBinData } = useQuery(
        queryKey1,
        () => {
        const processedParams = preProcessParams(
            convertParamsToTranDate(params, dayOfMonthMaxDate)
        );
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...processedParams,
                view: TABLE_VIEW_CHAIN_CADS_CUSTOMER_BIN,
                columns: columnList.join(','),
                group_by: columnList.join(','),
                aggregates: aggregateList1.join(','),
                limit: 1000
            }
        }).then(res => {
            return res.data.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
        enabled: hasCustomerDate && !isLoadingDateRange
    })

    const aggregateList2 = [
        'header_tran_key_count_distinct',
        'pos_sku_gross_sales_sum',
    ]

    const queryKey2 = [TABLE_CUSTOMER_ATTRIBUTES, TABLE_VIEW_CHAIN_CADS_TXN_BIN, params, columnList, aggregateList2]

    const { data: txnBinData, isLoading: isLoadingTxnBinData } = useQuery(
        queryKey2,
        () => {
        const processedParams = preProcessParams(
            convertParamsToTranDate(params, dayOfMonthMaxDate)
        );
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...processedParams,
                view: TABLE_VIEW_CHAIN_CADS_TXN_BIN,
                columns: columnList.join(','),
                group_by: columnList.join(','),
                aggregates: aggregateList2.join(','),
                limit: 1000
            }
        }).then(res => {
            return res.data.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
        enabled: hasCustomerDate && !isLoadingDateRange
    })

    const isLoading = isLoadingCustomerBinData || isLoadingTxnBinData

    const appendedData = (() => {
        if (isLoading || (!txnBinData && !customerBinData)) {
            return [];
        }

        if (!txnBinData || txnBinData.length === 0) {
            return customerBinData && customerBinData.length > 0 ? customerBinData : [];
        }

        const mainData = [...txnBinData];

        const customerBinMap = new Map();
        customerBinData?.forEach(item => {
            customerBinMap.set(item.basket_size_bin, item.gcr_persistent_id_count_distinct);
        });

        // Insert gcr_persistent_id_count_distinct values from customerBinData into txnBinData
        const mergedData = mainData.map(txnItem => {
            const customerCount = customerBinMap.get(txnItem.basket_size_bin);
            return customerCount !== undefined
                ? { ...txnItem, gcr_persistent_id_count_distinct: customerCount }
                : txnItem;
        });

        return mergedData;
    })();

    return {
        data: appendedData,
        isLoading,
    }
}