import { Grid } from '@mui/material';
import React from 'react';

export default function ComponentWithIndicator({variant, component, comparisonLabelStyles}) {

    switch(variant) {
        case 'comparisonIndicator': 
            return <Grid container>
                <Grid item sm={6} xs={12} sx={{...comparisonLabelStyles}}>
                    {component}
                </Grid>
                <Grid item sm={6} xs={12}>
                    INDICATOR HERE
                </Grid>
            </Grid>
        case 'rankingComparisonIndicator':
            return <Grid container  paddingY={1}>
                <Grid item sm={6} xs={6} sx={{...comparisonLabelStyles}}>
                    {component}
                </Grid>
                <Grid item sm={6} xs={6}>
                    INDICATOR HERE
                </Grid>
            </Grid>
        default:
            return <Grid container>
                <Grid item sm={6} xs={12}>
                    {component}
                </Grid>
                <Grid item sm={6} xs={12}>
                    INDICATOR HERE
                </Grid>
            </Grid>
    }
    
}