function getLevelOption() {
    return [
      {
        itemStyle: {
          borderWidth: 5,
          gapWidth: 1
        }
      },
      {
        colorSaturation: [0.5, 0.4],
        itemStyle: {
          borderWidth: 5,
          gapWidth: 1,
          borderColorSaturation: 0.6
        }
      }
    ];
}
  
export const TreemapsConfig = {
    'Default' : {
        tooltip: {},
		series: {
            type: 'treemap',
            breadcrumb: {
              show: false
            },
            levels: getLevelOption(),
            label : {
                formatter: (params)=> {
                    return '{nameStyle|'+params.name + '}\n\n {valueStyle|' + params.value.toLocaleString() + '}';
                },
                align: 'center',  
                position: ['50%', '25%'],
                rich: {
                    valueStyle: {
                        fontSize: 16,
                        fontWeight: 'bold'
                    }
                }
            },
        }
    },
    'Treemaps' : {
        tooltip: {},
		series: {
                type: 'treemap',
                upperLabel: {
                  show: true,
                  height: 50,
                  formatter: (params)=> {
                    if(params.name) {
                        return params.name + ' :    ' + params.value.toLocaleString();
                    } else {
                        return 
                    }
                  },
                  fontSize: 14,
                  fontWeight: 'bold',
                },
                label : {
                    formatter: (params)=> {
                        return '{nameStyle|'+params.name + '}\n\n {valueStyle|' + params.value.toLocaleString() + '}';
                    },
                    align: 'center',  
                    position: ['50%', '25%'],
                    rich: {
                        valueStyle: {
                            fontSize: 16,
                            fontWeight: 'bold'
                        }
                    }
                },
                tooltip: {},
                width: '100%',
                height: '100%',
                breadcrumb: {
                  show: false
                },
                levels: getLevelOption(),
        }
    }
}