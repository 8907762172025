import * as React from "react";
import { Grid, Box } from "@mui/material";
import Item from "../../../../common/components/ItemCard";
import SectionHeader from "../components/SectionHeader"
import ProductCategoryFilter from "../components/ProductCategoryFilter";
import MustHaveTypeFilter from "../components/MustHaveTypeFilter";
import StoreArticleZeroSalesTable from "./StoreArticleZeroSalesTable";

export default function StoreArticleZeroSales({ filters, onFilterChange, selectedStore, overviewTableIsEmpty }) {
    const setFilter = (key, value) => {
        onFilterChange({ ...filters, [key]: value });
    };

    const sectionFilterComponents = [
        <ProductCategoryFilter
            filters={filters}
            onFilterChange={setFilter}
            overviewTableIsEmpty={overviewTableIsEmpty}
        />,
        <MustHaveTypeFilter
            filters={filters}
            onFilterChange={setFilter}
            overviewTableIsEmpty={overviewTableIsEmpty}
        />,
    ];

    return (
        <>
            <Box flexGrow={1} xs={12} mt={1} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }} >
                <Grid container columns={12} alignItems="center" margin={.5}>
                    <SectionHeader sectionFilterComponents={sectionFilterComponents}>
                        {selectedStore || 'By Store'} - Zero Sales Articles
                    </SectionHeader>
                </Grid>
            </Box>
            <Grid item xs={12} marginBottom={2} sx={{ boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)" }} >
                <Item>
                    <StoreArticleZeroSalesTable filters={filters} overviewTableIsEmpty={overviewTableIsEmpty} />
                </Item>
            </Grid >
        </>
    );
}
