import { useQuery } from "react-query";
import ApiClient from "../../../common/API";
import { USE_QUERY_DEFAULT_OPTIONS } from "../../../Constants/settings";
import moment from "moment";

export const STORE_ZERO_SALES_TABLE = "l3_frg_sap_zero_sales";

export function useStoreZeroSalesOverView({ params }) {
    const { store_id, days, startDate, endDate, article_category, article_sub_category, ...restParams} = params

    if (startDate && endDate) {
        restParams['transaction_date__gte'] = moment(startDate).format('YYYY-MM-DD');
        restParams['transaction_date__lte'] = moment(endDate).format('YYYY-MM-DD');
    }

    // TODO: for the meantime this API maintain 4 views due to can't handle HAVING clause in query
    const view_name = `zero_sales_overview_${days}_days`

    const { data: zeroSalesData, isLoading: isLoadingZeroSalesData } = useQuery(
        [STORE_ZERO_SALES_TABLE, "article_number", restParams, startDate, endDate,
        ],
        () => ApiClient().get(`data/table/${STORE_ZERO_SALES_TABLE}/query/`, {
            params: {
                ...restParams,
                view: view_name,
                limit: 1000
            }
        }).then(res => {
            return res.data.data

        }), {
        ...USE_QUERY_DEFAULT_OPTIONS,
        enabled: !!params.company_id && !!startDate && !!endDate // Only fetch when company_id and dates are available
    });
    return {
        zeroSalesData,
        isLoadingZeroSalesData,
    };
}

export function useStoresList() {
    const { data, isLoading } = useQuery(
        [STORE_ZERO_SALES_TABLE, 'stores', 'stores_list'],
        () => {


            return ApiClient().get(`data/table/${STORE_ZERO_SALES_TABLE}/query/`, {
                params: {
                    columns: "store_id,store_name,article_sub_category",
                    group_by: "store_id,store_name,article_sub_category",
                    limit: 1000
                },
            }).then((res) => res.data.data);
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        }
    );

    return {
        data,
        isLoading,
    };
}

export function useAvailableDateRange() {
    return useQuery(
        [STORE_ZERO_SALES_TABLE, 'company_name', 'store_name'],
        () => ApiClient().get(`data/table/${STORE_ZERO_SALES_TABLE}/filter_values/`, {
            params: {
                columns: "transaction_date"
            }
        }).then(res => {
            return res.data.transaction_date
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
}

export function useZeroSalesData({ params }) {
    const {days, startDate, endDate, ...restParams} = params

    if (startDate && endDate) {
        restParams['transaction_date__gte'] = moment(startDate).format('YYYY-MM-DD');
        restParams['transaction_date__lte'] = moment(endDate).format('YYYY-MM-DD');
    }



    const { data: zeroSalesData, isLoading: isLoadingZeroSalesData } = useQuery(
        [STORE_ZERO_SALES_TABLE, "article_number", "store_id", restParams, startDate, endDate],
        () => ApiClient().get(`data/table/${STORE_ZERO_SALES_TABLE}/query/`, {
            params: {
                ...restParams,
                view: "zero_sales",
                limit: 5000
            }
        }).then(res => {
            return res.data.data

        }), {
        ...USE_QUERY_DEFAULT_OPTIONS,
        enabled: !!params.company_id && !!startDate && !!endDate && !!params.store_id 
    });

    return {
        zeroSalesData,
        isLoadingZeroSalesData,
    };
}
