import LoadingComponent from "../../../../../common/components/LoadingComponent"
import { useToplineSMACMemberCount } from "../../hooks"
import VerticalBarGraph from "../VerticalBarGraph"

export default function AcquisitionSMACBarGraph() {
    const { asAcquisitionBarData, isLoading } = useToplineSMACMemberCount({})

    // Acquisition - Bar Graph Data
    const acquisition_registered_xAxisLabel = ['New', 'Existing', 'Renewal', 'Expired']
    const acquisition_registered_seriesData = [asAcquisitionBarData?.New, asAcquisitionBarData?.Existing, asAcquisitionBarData?.Renewal, asAcquisitionBarData?.Expired]
    const acquisition_registered_barColor = ["#84BFF7", "#7E95C7", "#CADDEB", "#AFAFAF"]

    if (isLoading) {
        return <LoadingComponent />
    }
    return <VerticalBarGraph xAxisLabel={acquisition_registered_xAxisLabel} seriesData={acquisition_registered_seriesData} barColor={acquisition_registered_barColor} />
}