import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DropdownCheckboxV3 from '../../../../../../../common/components/DropdownCheckboxV3';
import { getSelectedOption } from '../../../../../../../common/utils';

export const CatchmentAccordion = ({
    isochroneIsLoading,
    handleisochrones,
    checkedIsochrones
}) => {

    const options = [
        { label: '5-Minute Catchment', value: 300 },
        { label: '10-Minute Catchment', value: 600 },
    ];
    // console.log(options)
    return (
        <Accordion sx={{ width: '100%' }} defaultExpanded>
            <AccordionSummary
                sx={{ backgroundColor: '#F6F6F8', color: '#00000', fontWeight: 700 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                Catchment
            </AccordionSummary>
            <AccordionDetails>
                <DropdownCheckboxV3
                    id='isochrone_selection'
                    label="Catchment"
                    limitTags={2}
                    value={getSelectedOption(checkedIsochrones, options)}
                    options={options}
                    onChange={(event, newValue) => {
                        handleisochrones(getSelectedOption(newValue, options))
                    }}
                    disabled={isochroneIsLoading}
                    loading={isochroneIsLoading}
                />
            </AccordionDetails>
        </Accordion>
    );
};