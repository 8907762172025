import React from "react";
import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {Edit} from "@mui/icons-material";
import {MONTH} from "../../../../../../Constants/general";
import AsOfDate from "./AsOfDate";


export default function ExtraInfo({showFilter, month, year}) {

    const selectedYearMonthText = React.useMemo(() => {
        if (!month || !month.length) {
            return '';
        }
        const maxMonth = Math.max(...month)
        return `${MONTH.find(o => o.value === maxMonth)?.label} ${year}`
    }, [month, year])



    return <Grid container>
            {selectedYearMonthText && <Grid item xs={12} textAlign="right">
                <Typography ml={5} display="inline">Showing Data for </Typography>
                <Button
                    onClick={showFilter}
                    sx={{
                        fontSize: "1rem",
                        verticalAlign: "unset",
                        padding: 0,
                        // textDecoration: "underline",
                        textTransform: "capitalize",
                    }}
                >
                    {selectedYearMonthText}
                    <Edit/>
                </Button>
            </Grid>}
            <Grid item xs={12} textAlign="right">
                <Typography ml={5}>
                    <AsOfDate textOnly={true}/>
                </Typography>
            </Grid>
        </Grid>
}