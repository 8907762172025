import React, { useContext } from 'react';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { getSelectedOption } from '../../../../common/utils';

export default function ProductCategoryFilter({ filters, onFilterChange, overviewTableIsEmpty}) {
    const windowSize = useContext(WindowSizeContext);

    const availableOptions = [
        { value: 'Drygoods', label: 'Drygoods' },
        { value: 'Food1', label: 'Food1' },
        { value: 'Food2', label: 'Food2' },
        { value: 'Fresh', label: 'Fresh' },
        { value: 'Nonfood', label: 'Nonfood' }
    ]
       
    return (
        <>
            <ConditionalSelectComponent
                id="product_category_filter"
                labelId="product-category"
                options={availableOptions}
                label="Product Category"
                value={filters ? (windowSize.isOnMobile ? filters?.article_category : getSelectedOption(filters?.article_category, availableOptions)) : '' &&  null}
                onSelectChange={(event, newValue) => {
                    windowSize.isOnMobile ? onFilterChange('article_category', event.target.value)
                        : onFilterChange('article_category', newValue?.value)
                }}
                disableClearable={false}
                disabled={!filters?.store_id || overviewTableIsEmpty}
                endAdornment={true}
                startAdornment={false}
                clearSelected={() => onFilterChange('article_category', null)}
            />
        </>
    );
}
