import Select, { components, OptionProps } from 'react-select';

export default function SelectWrapper(props) {
    
    const value = props?.options?.filter(option => option.value === props.value)

    switch(props.optionStyleFor) {
        case 'targetFile' :
            const Option = (props) => {
                return (
                    <components.Option {...props}>
                        {props.data.label}
                        <br/>
                        <small>
                            Target Count: {
                            props.data.isLoading ? <span className="spinner-border text-primary" style={{width: "1em", height: "1em"}}/> : props.data?.targetCount?.toLocaleString()
                        }

                        </small>
                    </components.Option>
                );
            };
            return <Select components={{Option}} options={props.options} onChange={props.onChange} value={value?.[0]} isClearable={true} isLoading={props.isLoading} isDisabled={props.isDisabled}/>
        
        default :
            return <Select options={props.options} onChange={props.onChange} classNamePrefix={props.classNamePrefix} value={value?.[0]} isClearable={true} isLoading={props.isLoading} isDisabled={props.isDisabled}/>
    }
}
