import SelectWrapper from '../../../common/components/SelectWrapper';
import useChannelOptions from '../../../common/hooks/useChannelOptions';

export default function SmsContent(props) {
    const {isLoading, options} = useChannelOptions('sms');

    const textLength = props.values.contentSms.length;
    const parts = textLength > 160 ? Math.ceil(textLength/153) : 1;
    const textLimit = parts * (textLength > 160 ? 153 : 160);

    const remaining = textLimit - textLength;

    return <>
                <div className="row mb-2">
                    <label className="col-sm-2 ">From <small>*</small></label>
                    <div className="col-sm-10">              
                        <SelectWrapper options={options} isLoading={isLoading} onChange={(e) => props.onChange('fromSms', e ? e.value : null)} value={props.values.fromSms} isDisabled={!props.isRequired && props.isOnViber} />
                    </div>
                </div>

                <div className="row mb-2">
                    <label className="col-sm-2 fw-semibold">Content <small>*</small></label>
                    <div className="col-sm-10">
                        <textarea className="form-control" style={{resize: 'none'}} type="text" rows="5"   onChange={(e)=> {props.onChange(e.target.name, e.target.value)}} name="contentSms" value={props.values.contentSms} disabled={!props.isRequired && props.isOnViber}/>
                    </div>
                    <div className="text-end">
                        <small>Remaining Characters: <strong className="pe-3">{remaining}</strong>SMS Parts: <strong>{parts} </strong></small>
                    </div>
                </div>
    </>
}