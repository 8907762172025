import React, { useContext,useMemo } from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useAllAvailableZone } from '../hooks';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { getSelectedOption } from '../../../../common/utils';

export default function FilterZone ({filters, params, onDropdownChange}) {

    const windowSize = useContext(WindowSizeContext)

    const { zones, isLoading } = useAllAvailableZone({ params: {...params, zone: null} });

    const options = useMemo(()=> {
        let zoneOptions = Array.isArray(zones)
        ? zones
            .filter(item => item && item.zone!== null)
            .map(item => ({
                    value: item.zone,
                    label: item.zone,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
            : [];

        if (filters && filters.zone) {
            zoneOptions = zoneOptions.filter(option => option.value !== filters.zone);
        }

        return zoneOptions

    },[zones, filters])

    return (
        <ConditionalSelectComponent
                    id="zone_filter"
                    labelId="zone_filter-label"
                    options={options}
                    label="Zone"
                    value={filters ? (windowSize.isOnMobile ? filters?.zone : getSelectedOption(filters?.zone, options)) : '' || null}
                    onSelectChange={(event,newValue)=>{
                        windowSize.isOnMobile ? onDropdownChange(event.target.value, 'zone')
                        : onDropdownChange(newValue?.value, 'zone')  
                    }}
                    isLoading={isLoading}
                    endAdornment={true}
                    clearSelected={() => onDropdownChange('','zone')}
                    disableClearable={false}
            />
    );

}