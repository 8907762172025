import React from "react";
import Transactions from "./Transactions";
import { useFiltersContext, useTogglePeriodContext } from "./Provider/context";
import { getParamsBasedOnPeriod } from "./util";
import ValueSegmentation from "./ValueSegmentation";

export function RenderCustomerChainViewContent({ viewBy }) {
    
    const { filters, setFilter } = useFiltersContext();
    const { period, updatePeriod } = useTogglePeriodContext();
    const isMTD = period === 'mtd'
    const isQoQ = period ==='qoq'    
    
    const params = {
        month: filters?.month,
        year: filters?.year,
    }
    const paramsBasedOnPeriod = getParamsBasedOnPeriod(isMTD, isQoQ, params);

    switch(viewBy) {
        case 'segments':
            return <ValueSegmentation params={paramsBasedOnPeriod} />;
        case 'location':
            return 'Location here'
        default: // transactions
            return <Transactions params={paramsBasedOnPeriod} period={period} onTogglePeriodChange={updatePeriod} filters={filters} onDropDownFilterChange={setFilter} />
    }
}