import moment from "moment";
import {prepareParams} from "../../../common/utils";


export const getParamsBasedOnPeriod = (isMTD, isQoQ, params) => {
    if(isQoQ) {
        const quarterStartMonth = moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month()
        return {
            ...params,
            month: Array.from({ length: 3 }, (_, i) => quarterStartMonth + i + 1)
        }
    } else if (isMTD) {
        return { ...params };
    } else { // YTD
        return { 
            ...params, 
            month: [1, ...(params?.month || [])] 
        };
    }
}

export const convertParamsToTransactionDate = (params, dayOfMonthMaxDate) => {
    return {
        ...params,
        ...monthYearToTransactionDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const monthYearToTransactionDate = (months, year, dayOfMonthMaxDate) => {
    if (!months || months.length === 0 || !year) {
        return {}
    }
    // months is an array of numbers, 1=January, 2=February, etc.
    const minMonth = Math.min(...months)
    const maxMonth = Math.max(...months)

    const minDate = moment(`${year}-${minMonth}-01`)
    const maxDate = moment(`${year}-${maxMonth}-01`)

    // create a list of months between minDate and maxDate
    const monthsBetween = []
    let currentDate = minDate
    while (currentDate <= maxDate) {
        monthsBetween.push(currentDate.format("MM"))
        currentDate = currentDate.add(1, 'month')
    }

    const newParams = {
        'year__in': year
    }
    newParams['month__in'] = monthsBetween.map(month => month.toUpperCase()).join(',')

    return newParams

}

export const monthYearToTranDate = (months, year, dayOfMonthMaxDate) => {
    if (!months || months.length === 0 || !year) {
        return {}
    }
    // months is an array of numbers, 1=January, 2=February, etc.
    const minMonth = Math.min(...months)
    const maxMonth = Math.max(...months)

    const startDate = new Date(year, minMonth - 1, 1)  // since minMonth is 1-based, we need to subtract 1 from month to get the first day of the month
    const endDate = dayOfMonthMaxDate ?
        new Date(year, maxMonth - 1, dayOfMonthMaxDate) :  // since maxMonth is 1-based, we need to subtract 1 from month to get the last day of the month
        new Date(year, maxMonth, 0);  // since maxMonth is 1-based, we use the 0th day of the next month to get the last day of the month

    return {
        "header_tran_date__gte": moment(startDate).format("YYYY-MM-DD"),
        "header_tran_date__lte": moment(endDate).format("YYYY-MM-DD")
    }

}

export const convertParamsToTranDate = (params, dayOfMonthMaxDate) => {
    return {
        ...params,
        ...monthYearToTranDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const preProcessParams = (params) => {
    const newParams = {
        ...params,
    }

    // delete "month" and "year" parameters if they exist
    // because we don't want to send them to the backend,
    // we want to send "year_month__gte" and "year_month__lte" instead
    if (newParams.month) {
        delete newParams.month
    }
    if (newParams.year) {
        delete newParams.year
    }

    // remove empty arrays or empty strings or null values
    Object.keys(newParams).forEach(key => {
        if (Array.isArray(newParams[key]) && newParams[key].length === 0) {
            delete newParams[key]
        }
        if (newParams[key] === "") {
            delete newParams[key]
        }
        if (newParams[key] === null) {
            delete newParams[key]
        }
    });

    return prepareParams(newParams)
}

export function sortByBasketSize(a, b) {
    const sortingOrders = [
        '500 Below',
        '500 and below', 
        '501-1000', 
        '1001-3000', 
        '3001-5000', 
        '5001-10k',
        '10k - 15k',
        '15k - 20k',
        '20k - 50k',
        "50k and above",
    ]

    // Handle null basket sizes by moving them to the end
    if (a.basket_size === null) return 1;
    if (b.basket_size === null) return -1;

    // Get the index of basket sizes in the sortingOrders array
    const indexA = sortingOrders.indexOf(a.basket_size);
    const indexB = sortingOrders.indexOf(b.basket_size);

    // Handle basket sizes not found in sortingOrders
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    // Compare basket sizes based on their index in sortingOrders
    return indexA - indexB;
}


export const getMetricColumnHeader = (period, metric) => {
    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';
    const periodLabel = isQoQ ? 'QTR' : isMTD ? 'MTD' : 'YTD';

    const metricLabels = {
        actual_sales: 'Sales',
        actual_transactions: 'Transactions',
        actual_basket_size: 'Basket Size',
        actual_units_sold_per_txn: 'Units Sold per Transaction',
        actual_frequency_of_visit: 'Frequency of Visit'
    };

    const metricLabel = metricLabels[metric] || '';

    return `${periodLabel} ${metricLabel}`
}