import React from "react";
import {useTableDataFilterColumns, useTableDetail} from "../../../../common/API/hooks";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import FilterFactory from "../../../../common/components/FilterFactory";
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add';


export default function DashboardFilters({tableId, usableFilterIds, filters, onChangeFilter}) {
    const {data: filterColumns, isLoading: isLoadingFilterColumns} = useTableDataFilterColumns(tableId)

    if (isLoadingFilterColumns) {
        return <LoadingComponent/>
    }

    const allowedFilterColumns = filterColumns?.filter(filterColumn => usableFilterIds.includes(filterColumn.id))
    function handleAddRow() {
        onChangeFilter([
            ...filters,
            { filterName: null, operator: "eq", value: null }
        ])
    }
    return (<>
        {
            // filters?.map((filterRow, index) => <div className="row mt-2">
            //     <FilterFactory
            //         tableId={tableId}
            //         availableFilterFields={allowedFilterColumns}
            //         filterData={filterRow}
            //         onChange={(newFilterRow) => {
            //             const newChartFilters = [...filters]
            //             newChartFilters[index] = {
            //                 ...newChartFilters[index],
            //                 ...newFilterRow
            //             }
            //             onChangeFilter(newChartFilters)
            //         }}/>
            // </div>)

            filters?.map((filterRow, index) => <Box sx={{mt:1}}>
                <Grid container direction="row" spacing={2} >
                    <FilterFactory
                        tableId={tableId}
                        availableFilterFields={allowedFilterColumns}
                        filterData={filterRow}
                        onChange={(newFilterRow) => {
                            const newChartFilters = [...filters]
                            newChartFilters[index] = {
                                ...newChartFilters[index],
                                ...newFilterRow
                            }
                            onChangeFilter(newChartFilters)
                        }}/>
                </Grid>
            </Box>)

        }
        {/* <div className="row my-2">
            <div className="col-sm-4">
                <button type="button" className="btn btn-link p-0 text-secondary text-decoration-none text-center border border-info-subtle rounded w-100" onClick={()=>handleAddRow()}>
                    <i className="bi bi-plus-lg text-secondary"></i> Add filters
                </button>
            </div>
        </div> */}
        <Grid container direction="row" spacing={2} sx={{my:1}}>    
            <Grid item xs={4}>
            <Button   variant="outlined" color="secondary" onClick={()=>handleAddRow()} startIcon={<AddIcon />} >Add filters</Button>
            </Grid>
        </Grid>
    </>)
}