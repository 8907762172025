import React from "react";
import Filter from "./filter";
import Fab from "@mui/material/Fab";
import {Grow, useMediaQuery} from "@mui/material";
import {FilterAlt} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import FilterTextDisplay from "./filterTextDisplay";
import {useTheme} from "@mui/material/styles";


export default function Filters({tableName, filterDefinitions = {}, filters, setFilter, params, children, isOpen, setIsOpen}) {
    // if component is uncontrolled, use internal state to keep track of whether the filters are open or not
    const [showFilters, setShowFilters] = React.useState(false);

    if (isOpen === undefined) {
        isOpen = showFilters;
    }
    if (setIsOpen === undefined) {
        setIsOpen = setShowFilters;
    }


    const theme = useTheme();
    const mobileThemeSize = useMediaQuery(theme.breakpoints.down('sm'));
    // this is used to keep track of which filters are being used
    // and the order in which they are being used
    // for cross-filtering purposes, this is used to determine the order in which filters are applied
    const [filterIdsUsed, setFilterIdsUsed] = React.useState([]);

    const filtersToDisplay = React.useMemo(() => {
        const tmp = [];
        filterIdsUsed.forEach(key => {
            if (filterDefinitions[key]) {
                tmp.push(filterDefinitions[key]);
            }
        });
        Object.values(filterDefinitions).forEach(filter => {
            if (!tmp.includes(filter)) {
                tmp.push(filter);
            }
        })
        return tmp;
    }, [isOpen]);  // showFilters is used to trigger a re-render when the filters are shown or hidden
    // we do not want a re-render every time the user changes the filter
    // re-render orders only when the filters are shown or hidden



    return <>
        {
            !isOpen &&
            <Fab
                color="primary"
                variant="extended"
                style={{
                    position: 'fixed',
                    bottom: mobileThemeSize ? '1rem': "2.5rem",
                    right: "2rem",
                }}
                onClick={() => setIsOpen(!isOpen)}>
                <FilterAlt xs={{mr: 1}}/>
                Filter
            </Fab>
        }
        <FilterTextDisplay
            filters={filters} filterDefinitions={filterDefinitions} filterIdsUsed={filterIdsUsed}
            onClick={() => setIsOpen(!isOpen)}
        />

        <Grow in={isOpen}>
            <Box
                flexGrow={1} xs={12}
                p={2}
                pb={4}
                sx={{
                    backgroundColor: "#E1EBF2", borderRadius: ".5rem",
                    boxShadow: "0 0 10px 0 rgba(0,0,0,0.4)",
                    position: 'fixed',
                    bottom: mobileThemeSize ? '0' : "2.5rem",
                    right: mobileThemeSize ? '0' : "2rem",
                    zIndex: 1000,
                    width: mobileThemeSize ? "100vw" : "600px",
                    maxHeight: "calc(100vh - 8rem)",
                    overflowY: "auto",
                }}>
                <Grid pb={1} container>
                    <Grid item sm={8} xs={6}>
                        <Typography variant="h6">Filters</Typography>
                    </Grid>
                    <Grid item sm={4} xs={6} display="flex" justifyContent="flex-end">
                        <Button
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <CloseIcon/>
                        </Button>

                    </Grid>
                </Grid>
                <Grid container columns={12} spacing={1} sx={"mb"}>


                    {children}
                    {
                        filtersToDisplay.map((filter, index) => {
                            return <Grid item xs={12}>
                                <Filter
                                    key={index}
                                    tableName={tableName} definition={filter}
                                    filters={filters} setFilter={setFilter}
                                    params={params}
                                    filterKeysUsed={filterIdsUsed}
                                    setFilterKeysUsed={setFilterIdsUsed}
                                    filterDefinitions={filterDefinitions}
                                /></Grid>
                        })
                    }
                </Grid>
            </Box>
        </Grow>

    </>;
}