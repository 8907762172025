import React from 'react';
import { Grid, Typography, ToggleButton } from '@mui/material';
import { useQuery } from "react-query";
import ApiClient from '../API';
import { styled } from "@mui/material/styles";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../Constants/settings";
// import { TABLE_NAME } from "../hooks";

const shortenCompanyName = {
    7: "SVI", 
    2: "SSM",
    142: "SMCO"
}

const NewToggleButton = styled(ToggleButton)(({
    color: '#0a4fb4',
    backgroundColor: '#fff',
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: '#0a4fb4',
      color: '#fff'
    }
}))

export default function FilterCompany({ tableName, filters, onPillSelectChange }) {
    const { data: companyData, isLoading: isLoadingCompanyData } = useQuery(
        ["markets", "sales", "company"],
        () => ApiClient().get(`data/table/${tableName}/query/`, {
            params: {
                columns: 'company_id,company',
                distinct: true,
                company__gte: '0'  // this is a hack to filter out the null value; ideally, we should be able to use company__isnull=false
            }
        }).then(res => {
            return res.data
        }), {
            ...USE_QUERY_DEFAULT_OPTIONS
        }
    );
    
    const options = React.useMemo(() => {
        if (isLoadingCompanyData) {
            return []
        }
        if (!companyData) {
            return []
        }

        const uniqueCompanyIdList = companyData.data.filter((obj, index) =>
            companyData.data.findIndex((item) => item.company_id === obj.company_id) === index
        );

        return uniqueCompanyIdList.map((row) => {
            return {
                value: row.company_id,
                label: shortenCompanyName?.[row.company_id] ? shortenCompanyName[row.company_id] : row.company
            }
        })
    }, [companyData, isLoadingCompanyData]);

    const companyFilters = (value) => {
        if (filters?.company_id.includes(value)) {
            // Deselect company if it's already selected
            return filters?.company_id.filter(id => id !== value);
        } else {
            // Select company if it's not yet selected
            return [...filters?.company_id, value];
        }
    };

    const pillButtons = options.map((option) => {
        const isSelected = filters?.company_id?.includes(option.value);
        return (
            <Grid item key={option.value} sm xs>
                <NewToggleButton 
                        value={option.value}
                        onClick={() => onPillSelectChange(companyFilters(option.value), 'company_id')}
                        selected={ isSelected }
                        sx={{
                            width: '100%',
                            height: '2.5rem',
                            borderRadius: '1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {option.label}
                </NewToggleButton>
            </Grid>
        );
    })

    return (
        <>
            <Grid container spacing={1} direction="horizontal" justifyContent="center" alignItems="baseline">            
                <Grid item xs={12} sm> <Typography variant="body1">Select Company:</Typography></Grid>
                {pillButtons}
            </Grid>
        </>
    );
    
}   