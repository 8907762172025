
import { useEffect, useState, useContext } from "react"
import { Grid } from "@mui/material"
import BranchTopLineDataProvider from "./DataProvider/branchViewTopLineDataProvider"
import TopLine from "../TopLine"
import SpaceProductivity from "./Operations/SpaceProductivity"
import CounterProductivity from "./Operations/CounterProductivity/indexy"
import HeadcountProductivity from "./Operations/HeadcountProductivity"
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext"
import PerformancePerChannel from "./Operations/PerformancePerChannel"
import FootTrafficConversion from "./Operations/FootTrafficConversion"

export default function Operations({ params, store, storeID, period, onTogglePeriodChange, onDropDownFilterChange, filters }) {

    const windowSize = useContext(WindowSizeContext);



    return (<>
        <Grid container >
            {/* <Grid item xs={12} mb={2}>
                <BranchTopLineDataProvider params={params} period={period} storeID={storeID} store={store} onTogglePeriodChange={onTogglePeriodChange} filters={filters}>
                    <TopLine topLineMetrics={true} params={params} period={period} onToggleChange={onTogglePeriodChange} filters={filters} />
                </BranchTopLineDataProvider>
            </Grid> */}

            {/* NOTE: This commented lines will be using for feb 12 deliverables */}

            <Grid item xs={12} py={.5}>
                <PerformancePerChannel params={params} period={period} storeID={storeID} store={store} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} onFilterChange={onDropDownFilterChange}>
                </PerformancePerChannel>
            </Grid>
            <Grid item xs={12} py={.5}>
                <FootTrafficConversion params={params} period={period} storeID={storeID} store={store} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} onFilterChange={onDropDownFilterChange}>
                </FootTrafficConversion>
            </Grid>
            {/* <Grid item xs={12} py={.5}>
                <SpaceProductivity>
                </SpaceProductivity>
            </Grid>
            <Grid item xs={12} py={.5}>
                <CounterProductivity>
                </CounterProductivity>
            </Grid>
            <Grid item xs={12} py={.5}>
                <HeadcountProductivity>
                </HeadcountProductivity>
            </Grid> */}
        </Grid>
    </>
    )
}