import React, {useState, useContext} from 'react'
import {Grid, Box, Typography, Collapse, Card} from '@mui/material'
import CollapsibleComponent from '../../../../../../../common/components/CollapsibleComponent'
import { WindowSizeContext } from '../../../../../../../common/context/WindowSizeContext'
import GenderDemographics from '../../../Demographics/Gender'
import AgeDemographics from '../../../Demographics/Age'
import  GenderAgeInteractionComponent  from '../../../Demographics/GenderAgeInteraction'
import { useGenderDataL3, useAgeGenerationDataL3, useGenderAgeInteractionDataL3 } from '../../../Demographics/hooks'
import { getParamsBasedOnPeriod } from '../../../../../Customers/util'
import ToggleSelector from '../../../selectors/toggleSelector'
import { DEMOGRAPHIC_TOGGLE_OPTIONS } from '../../../../../Customers/DemographicsChainView'
import { useFiltersContext } from '../../Provider/context'


export default function Demographics({params, period}) {

    const windowSize = useContext(WindowSizeContext) 
    const [isExpand, setExpand] = useState(true)
    const [selector, setSelector] = useState(DEMOGRAPHIC_TOGGLE_OPTIONS[0].value)
    
    const { filters } = useFiltersContext();
    const isMTD = period === 'mtd'
    const isQoQ = period ==='qoq'  

    const paramsBasedOnPeriod = getParamsBasedOnPeriod(isMTD, isQoQ, params);

    const { genderData, 
        isGenderDataLoading 
    } = useGenderDataL3(
        { 
            params: { 
                ...paramsBasedOnPeriod, 
                pm_product_category_desc: filters?.product_category,
                pm_department_desc_standardized: filters?.department,
                pm_subdept_desc_standardized: filters?.subdept,
                final_brand: filters?.brand,
                customerView: 'buView'
            } 
        }
    );

    const { ageGenerationData, 
        isAgeGenerationDataLoading } 
    = useAgeGenerationDataL3(
        { 
            params: { 
                ...paramsBasedOnPeriod, 
                pm_product_category_desc: filters?.product_category,
                pm_department_desc_standardized: filters?.department,
                pm_subdept_desc_standardized: filters?.subdept,
                final_brand: filters?.brand,
                columns:[selector],
                customerView: 'buView'
            } 
        }
    );

    const {genderAgeInteractionData, 
        isGenderAgeInteractionDataLoading
    } = useGenderAgeInteractionDataL3(
        {
            params: {
                ...paramsBasedOnPeriod,
                pm_product_category_desc: filters?.product_category,
                pm_department_desc_standardized: filters?.department,
                pm_subdept_desc_standardized: filters?.subdept,
                final_brand: filters?.brand,
                columns:[selector],
                customerView: 'buView'
            }
        }
    );
    return <>
        <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
            <Grid container columns={12} alignItems="center" p={.5}>
                {
                    windowSize?.isOnMobile ? <>
                        <Grid item xs={12} display="flex" justifyContent={'space-between'} alignItems={'center'} >
                            <Typography variant="h6" ml={1}>
                                Customer Demographics
                            </Typography>
                            <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='flex-end' >
                            <ToggleSelector
                                options={DEMOGRAPHIC_TOGGLE_OPTIONS}
                                value={selector}
                                onChange={(e)=> setSelector(e.target.value)}
                                additionalStyles={{ textTransform: 'none' }}
                                lastIndexOfMain={2}
                            />
                        </Grid>
                    </> : <>
                        <Grid item xs={12} sm={7}>
                            <Typography variant='h6' ml={2}>Customer Demographics </Typography>
                        </Grid>

                        <Grid item xs={1.5} sm display='flex' justifyContent='flex-end' >
                            <ToggleSelector
                                options={DEMOGRAPHIC_TOGGLE_OPTIONS}
                                value={selector}
                                onChange={(e)=> setSelector(e.target.value)}
                                additionalStyles={{ textTransform: 'none' }}
                                lastIndexOfMain={2}
                            />
                        </Grid>
                        <Grid item xs sm={.5}>
                            <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
        <Collapse in={isExpand}>
            <Grid item xs={12} marginBottom={2} sx={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>
                <Card sx={{p:2, width: '100%', borderRadius:' 0 0 .5rem .5rem'  }}>
                    <Grid container xs={12} sx={{ justifyContent: 'space-evenly' }} flexDirection={windowSize.isOnMobile ? 'column' : ''}>
                        <Grid item xs sm={3.5} px={.5} my={.5}>
                            <GenderDemographics data={genderData} isLoading={isGenderDataLoading} isOnMobile={windowSize.isOnMobile} />
                        </Grid>
                        <Grid item xs sm={3.5} px={.5} my={.5}>
                            <AgeDemographics data={ageGenerationData} isLoading={isAgeGenerationDataLoading} genAgeValue={selector} isOnMobile={windowSize.isOnMobile}/>
                        </Grid>
                        <Grid item xs sm={5} px={.5} my={.5}>
                            <GenderAgeInteractionComponent data={genderAgeInteractionData} isLoading={isGenderAgeInteractionDataLoading} genAgeValue={selector} isOnMobile={windowSize.isOnMobile} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Collapse>
    </>
}