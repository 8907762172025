import React from "react";
import {EnvironmentSettingsContext} from "../context/EnvironmentSettingsContext";
import {useQuery} from "react-query";
import ApiClient from "../API";
import {DEFAULT_SETTINGS, USE_QUERY_DEFAULT_OPTIONS} from "../../Constants/settings";
import {isLoggedIn} from "../utils";



export function useEnvironmentSettings() {
    return React.useContext(EnvironmentSettingsContext);
}

export function useEnvironmentSetting(key, defaultValue=undefined) {
    const {getSetting} = useEnvironmentSettings();
    return getSetting(key, defaultValue);
}


export default function EnvironmentSettingsProvider({children}) {
    const {data, isLoading} = useQuery('environmentSettings', () => {
        return ApiClient().get('/system/environment/').then(res => {
            return res.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        enabled: isLoggedIn()
    });

    const settings = React.useMemo(() => {
        if (isLoading || !data) {
            return {};
        }
        return data.reduce((acc, setting) => {
            acc[setting.key] = setting.value;
            return acc;
        }, {})
    }, [data, isLoading])

    const getSetting = (key, defaultValue=undefined) => {
        return settings[key] || defaultValue || DEFAULT_SETTINGS[key]
    }

    return (
        <EnvironmentSettingsContext.Provider value={{
            settings,
            getSetting
        }}>
            {children}
        </EnvironmentSettingsContext.Provider>
    )
}
