import React, {useContext} from 'react';
import CompanyLogoMarker from "./LeafletElements/CompanyLogoMarker";
import {StoreAnalyticsFilterContext} from "../contexts";


export default function CompanyLogoMarkers({searchTerms, filters, locationData, selectedLocations, zoomLevel, displayLocationMarkers}) {
    const {region} = useContext(StoreAnalyticsFilterContext)

    let iconSize= [8, 12];
    if(zoomLevel > 5 && zoomLevel <= 7) {
        iconSize = [8, 12]
    } else if(zoomLevel > 8 && zoomLevel <= 9) {
        iconSize = [17, 24]
    } else if (zoomLevel >= 10) {
        iconSize =  [34, 48]
    }

    function getFilteredMarkers() {
        const searchTermIds = searchTerms.map(searchTerm => searchTerm.values.map(val => val.id)).flat()
        const filteredIds = Object.keys(filters).filter(key => filters[key].length > 0).map(key => {
            return {
                [key]: locationData.filter(row => filters[key].includes(row[key])).map(row => row.id)
            }
        }).reduce((acc, val) => {
            return {...acc, ...val}  // flatten the dictionary/map
        }, {})

        if (searchTerms.length > 0) filteredIds["search"] = searchTermIds

        // get intersection of all filtered IDs
        const markerIds = Object.keys(filteredIds).reduce((acc, val) => {
            if (acc === null) return filteredIds[val]
            return acc.filter(id => filteredIds[val].includes(id))
        }, null)

        if (markerIds === null) return []
        return markerIds
    }

    function getMarkers() {
        const markerIds = [
            ...getFilteredMarkers(),
            ...selectedLocations.map(val => val.id),
        ]

        const hasSearchTerms = searchTerms.length > 0
        const hasFilters = Object.keys(filters).filter(key => filters[key].length > 0).length > 0

        if (!hasSearchTerms && !hasFilters && selectedLocations.length === 0) return []

        return locationData.filter(data => markerIds.includes(data.id))
    }


    const markers = getMarkers().filter(row => {
        if (!region) return true
        return row.region.toLowerCase() === region.toLowerCase();
        // const regionBounds = getSelectedRegionBounds()
        // const point = new Point(row.latitude, row.longitude);
        // return new Bounds(regionBounds).contains(point)
    }).map(point => {
        //condition for location display

        if (displayLocationMarkers) {
            return <CompanyLogoMarker
                iconSize={iconSize}
                key={point.id} mallName={point.name} lat={point.latitude} long={point.longitude}
                company={point.company} note={point.note}
            />
        }

    })

    return (
        <>
            {markers}
        </>
    )
}