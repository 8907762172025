import React, {useMemo} from "react";
import ApiClient from "../../../../common/API";
import {useQuery} from "react-query";
import Donut from "../../../../common/components/graphs/donut";
import LoadingComponent from "../../../../common/components/LoadingComponent";


export default function DonutChartFromApi({apiEndpoint, params, dataReducer, rowFilter, title, subTitle}) {
    const {isLoading, data, isError} = useQuery([apiEndpoint, params],()=> {
        return ApiClient().get(apiEndpoint, {params: params}).then(res => {
            return res.data
        })
    });

    const donutData = useMemo(() => {
        if (!isLoading && !!data) {
            if (!!dataReducer) {
                return dataReducer(data.filter(row => {
                    return !!rowFilter ? rowFilter(row) : true
                }))
            }
            return data
        }
    }, [isLoading, data, !!dataReducer])

    if (isLoading) {
        return <LoadingComponent/>
    }
    if (isError) {
        return null;
    }
    return (
            <Donut seriesName={"Customer Segment Sales"} data={donutData} colors={["#203864", "#2f5597", "#2e75b6", "#9dc3e6"]}
                option={{
                    title : {
                        text: title,
                        subtext: subTitle,
                        textStyle: {
                            color : 'black',
                            fontSize: '1.25rem'
                        },
                        subtextStyle : {
                            color : 'black',
                            fontSize: '1rem'
                        },
                        left: 'center'

                    }
                }}
            />
    )
}