//FIXME: remove conditionals pertaining to detection of 'channel', 'zone', and 'business_unit' since the metrics will be limited on the view side; just retain detection of the properties used in the calculations
// const premiumStores = ['SM MAKATI', 'SM NORTH EDSA', 'SM MEGAMALL', 'SM SOUTHMALL', 'SM MALL OF ASIA', 'SM LANANG', 'SM AURA', 'SM SEASIDE CEBU', 'SM ESTANCIA PASIG']
const premiumStores = ['SM Makati', 'SM North Edsa', 'SM Megamall', 'SM Southmall', 'SM Mall Of Asia', 'SM Lanang', 'SM Aura', 'SM Seaside Cebu', 'SM Estancia Pasig']

export const metricCalculations = {
    'actual_sales': (item) => item.hasOwnProperty('gr_gross_sales_sum')
        ? Number(item.gr_gross_sales_sum)
        : Number(item.gross_sales_sum),
    'actual_transactions': (item) => {
        if (item.hasOwnProperty('gr_txn_count_sum')) {
            return item.gr_txn_count_sum;
        }
        // grouped data
        //  grouped by business unit
        if (item.hasOwnProperty('business_unit')) {
            if (item.hasOwnProperty('bu_txn_count_sum_distinct')) {
                return item.bu_txn_count_sum_distinct;
            } else if (item.hasOwnProperty('bu_txn_count_sum')) {
                return item.bu_txn_count_sum;
            }
        }
        // grouped by store or zone
        if ((item.hasOwnProperty('zone') || item.hasOwnProperty('store')) && item.hasOwnProperty('store_txn_count_sum_distinct')) {
            return item.store_txn_count_sum_distinct;
        }

        // ungrouped data
        if (item.hasOwnProperty('higher_mbu_txn_count_sum_distinct')) {
            // for ungrouped data or topline data
            return item.higher_mbu_txn_count_sum_distinct
        }

        if (item.hasOwnProperty('bu_txn_count_sum_distinct')) {
            return item.bu_txn_count_sum_distinct;
        }
        if (item.hasOwnProperty('bu_txn_count_sum')) {
            return item.bu_txn_count_sum;
        }
        if (item.hasOwnProperty('dept_txn_count_sum')) {
            return item.dept_txn_count_sum
        }
        if (item.hasOwnProperty('subdept_txn_count_sum')) {
            return item.subdept_txn_count_sum
        }
        if (item.hasOwnProperty('bu_cat_txn_count_sum_distinct')) {
            return item.bu_cat_txn_count_sum_distinct
        }
        if (item.hasOwnProperty('dept_cat_txn_count_sum_distinct')) {
            return item.dept_cat_txn_count_sum_distinct
        }
        if (item.hasOwnProperty('subdept_cat_txn_count_sum')) {
            return item.subdept_cat_txn_count_sum
        }
        return item.store_txn_count_sum;
    },
    'actual_basket_size': (item) => {
        /* original content
        item.hasOwnProperty('gr_txn_count_sum')
        ? item.gr_gross_sales_sum / item.gr_txn_count_sum
        : ((item.hasOwnProperty('business_unit') || item.hasOwnProperty('bu_txn_count_sum'))
            ? item.gross_sales_sum / item.bu_txn_count_sum
            : ((item.hasOwnProperty('channel') || item.hasOwnProperty('zone') || (item.hasOwnProperty('store_txn_count_sum') && item.hasOwnProperty('store_id')))
                ? item.gross_sales_sum / item.store_txn_count_sum
                : null))
         */
        const actualSales = metricCalculations['actual_sales'](item);
        const actualTransactions = metricCalculations['actual_transactions'](item);
        if (!actualTransactions || !actualSales) {
            return null;
        }

        return actualSales / actualTransactions;
    },
    'actual_catchment_rate': (item) => {
        if (
            item.hasOwnProperty('gr_gross_sales_sum') ||
            item.hasOwnProperty('channel') ||
            item.hasOwnProperty('bu_txn_count_sum') || item.hasOwnProperty('bu_txn_count_sum_distinct')
        ) {
            return 'N/A';
        }
        return (Number(item.ds_foot_traffic_sum) / Number(item.mall_foot_traffic_sum)) * 100
    },
    'actual_conversion_rate': (item) => {
        if (
            item.hasOwnProperty('gr_gross_sales_sum') ||
            item.hasOwnProperty('channel') ||
            item.hasOwnProperty('bu_txn_count_sum') || item.hasOwnProperty('bu_txn_count_sum_distinct')
        ) {
            return 'N/A';
        }       
        return (Number(item.foot_traffic_txn_count_sum) / Number(item.ds_foot_traffic_sum)) * 100
    },
    'actual_space_tfa': (item) => item.hasOwnProperty('gr_gross_sales_sum')
        ? 'N/A'
        : (item.hasOwnProperty('zone') || item.hasOwnProperty('total_floor_selling_area_sum')
            ? Number(item.gross_sales_sum) / Number(item.total_floor_selling_area_sum)
            : null),
    'actual_space_nsa': (item) => {
        if (item.hasOwnProperty('gr_gross_sales_sum')) {
            return 'N/A';
        }
        if (
            item.hasOwnProperty('business_unit') ||
            item.hasOwnProperty('bu_txn_count_sum') || item.hasOwnProperty('bu_txn_count_sum_distinct') ||
            item.hasOwnProperty('zone') ||
            item.hasOwnProperty('net_selling_area_sum')) {
            return Number(item.gross_sales_sum) / Number(item.net_selling_area_sum)
        }

        return null
    },
    'actual_smac_sales': (item) => item.hasOwnProperty('gr_smac_sales_sum')
        ? item.gr_smac_sales_sum
        : item.hasOwnProperty('smac_sales_sum') ? item.smac_sales_sum : null,
    'actual_mall_foot_traffic': (item) => item.mall_foot_traffic_sum,
    'actual_sm_store_foot_traffic': (item) => item.ds_foot_traffic_sum,
    'actual_units_sold_per_txn': (item) => {
        /* original content
        item.hasOwnProperty('bu_txn_count_sum')
        ? Number(item.sales_units_sum) / Number(item.bu_txn_count_sum)
        : Number(item.sales_units_sum) / Number(item.store_txn_count_sum),
         */
        const actualTransactions = metricCalculations['actual_transactions'](item);
        if (!actualTransactions) {
            return null;
        }
        const actualUnitsSold = metricCalculations['actual_units_sold'](item)
        if (!actualUnitsSold) {
            return null
        }

        return actualUnitsSold / actualTransactions;
    },
    'actual_units_price': (item) => Number(item.gross_sales_sum) / Number(item.sales_units_sum),
    'actual_units_sold': (item) => Number(item.sales_units_sum)
};

export const calculateMetricValues = (data, metricColumn, group_by) => {

    const result = {};
    data?.forEach(item => {

        const group = item[group_by];
        if (metricCalculations[metricColumn]) {
            result[group] = (metricCalculations[metricColumn](item) === 'N/A')
                ? 'N/A'
                : isFinite(metricCalculations[metricColumn](item))
                    ? metricCalculations[metricColumn](item)
                    : null;
        }
    });
    return result;
};

//FIXME: remove unnecessary conditionals pertaining to detection of 'channel', 'zone', and 'business_unit' (some will be retained)
// since the metrics will be limited on the view side; just retain detection of the properties used in the calculations
export const metricPlanCalculations = {
    'actual_sales': (item) => item.hasOwnProperty('egr_plan_sum')
        ? Number(item.egr_plan_sum)
        : Number(item.sales_plan_sum),
    'actual_transactions': (item) => item.hasOwnProperty('gr_gross_sales_sum')
        ? null
        : (item.hasOwnProperty('store') && premiumStores.includes(item.store)) || (item.hasOwnProperty('channel') && item.store_id_count_distinct === 1)
            ? item.sales_plan_sum / item.basket_size_plan_sum
            : (item.hasOwnProperty('channel') && item.channel !== 'In Store'
                ? null
                : item.sales_plan_sum / 1300),
    //TODO: for BU tab selected Store View, MTD Basket Size Plan should be null (done)
    'actual_basket_size': (item) => (item.hasOwnProperty('gr_gross_sales_sum'))
        ? null
        : (item.hasOwnProperty('channel') && item.channel !== 'In Store'
            ? null
            : ((item.hasOwnProperty('store') && premiumStores.includes(item.store)) || (item.hasOwnProperty('channel') && item.store_id_count_distinct === 1) // for operations store view
                ? item.basket_size_plan_sum
                : 1300)),
    'actual_catchment_rate': (item) => 'N/A',
    'actual_conversion_rate': (item) => 'N/A',
    'actual_space_tfa': (item) => item.hasOwnProperty('zone') || item.hasOwnProperty('total_floor_selling_area_sum')
        ? (item.total_floor_selling_area_sum ? (Number(item.sales_plan_sum) / Number(item.total_floor_selling_area_sum)) : 0)
        : 'N/A', // GR data automatically becomes 'N/A'
    'actual_space_nsa': (item) => (
        item.hasOwnProperty('business_unit') || item.hasOwnProperty('bu_txn_count_sum') || item.hasOwnProperty('bu_txn_count_sum_distinct') || item.hasOwnProperty('net_selling_area_sum')
    )
        ? (parseFloat(item.sales_plan_sum) / parseFloat(item.net_selling_area_sum))
        : 'N/A', // GR data automatically becomes N/A'
    // TODO: smac sales for BU has no MTD and YTD plan
    'actual_smac_sales': (item) => (item.hasOwnProperty('channel') && item.channel !== 'In Store')
        ? null
        : ((item.hasOwnProperty('zone') || !item.hasOwnProperty('business_unit') || item.hasOwnProperty('channel') || item.hasOwnProperty('store_id'))
            ? Number(item.sales_plan_sum) * 0.70
            : null),
    'actual_mall_foot_traffic': (item) => 'N/A',
    'actual_sm_store_foot_traffic': (item) => 'N/A',
    'actual_units_sold_per_txn': (item) => 'N/A',
    'actual_units_price': (item) => 'N/A',
    'actual_units_sold': (item) => 'N/A',

};

export const calculateMetricPlanValues = (data, metricColumn, group_by) => {
    const result = {};
    data?.forEach(item => {
        const group = item[group_by];
        if (metricPlanCalculations[metricColumn]) {
            result[group] = (metricPlanCalculations[metricColumn](item) === 'N/A')
                ? 'N/A'
                : isFinite(metricPlanCalculations[metricColumn](item))
                    ? metricPlanCalculations[metricColumn](item)
                    : null;
        }
    });
    return result;
};

export function convertValuesToNumeric(obj) {
    const result = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            result[key] = Number(obj[key]);
        }
    }
    return result;
}

export const processData = (metricTotal, metricPlanTotal, metricLYTotal, params, groupKeys, storeData = []) => {

    const { group_by, metric_column } = params;
    const isStoreViewData = storeData.length > 0 ? storeData.some(obj => obj?.hasOwnProperty('store_id')) : false;
    const perGroupTab = params.hasOwnProperty("channel")
        ? "channel"
        : (params.hasOwnProperty("zone")
            ? "zone"
            : (params.hasOwnProperty("business_unit")
                ? "business_unit"
                : null));

    const noMetricPlanGroups = isStoreViewData && perGroupTab === "channel" && (metric_column === 'actual_basket_size' || metric_column === 'actual_transactions' || metric_column === 'actual_smac_sales')
        ? ['Personal Shopper', 'Online', 'Gift Registry']
        : (isStoreViewData && perGroupTab === "business_unit" && (metric_column === 'actual_transactions' || metric_column === 'actual_smac_sales')
            ? ["SHOES", "ACCESSORIES MANAGEMENT", "LADIES' FASHION", "MEN'S FASHION", "CHILDREN'S FASHION", "SM HOME", "ACE HARDWARE/WORKSHOP", "TOYWORLD", "BABY COMPANY", "SUPPLIES STATION", "WATSONS", "KULTURA", "SIGNATURE LINES", "SPORTS CENTRAL", "SMART BUY", "OTHERS",
                "BATA", "LOBBY", "SERVICES", "SURPLUS SHOP", "FLOATING", "OUR HOME", "PREMIUM FASHION RETAIL DESIGNS", "SFERA", "THE BODY SHOP", "FOREVER 21", "ECCO PHILIPPINES", "UPTREND FASHION DESIGN CORP", "MODERN BODY LUXE STORES, INC.", "WHITE PLANET, INC.", "CROCS", "MINISO", "SHOEMART, INC."]
            : []
        );

    // checks whether the eye filter toggled should have no MTD/YTD Plan
    const hasNoMetricPlanForGroup = (key) => noMetricPlanGroups.some(group => params?.[key]?.map(v => v.toLowerCase()).includes(group?.toLowerCase()));
    const hasNoMetricPlan = (perGroupTab === "channel" && hasNoMetricPlanForGroup('channel') && !params?.channel?.includes('In Store')) ||
        (perGroupTab === "business_unit" && hasNoMetricPlanForGroup('business_unit')) ||
        false;

    const isBUNoMetricPlan = ((perGroupTab === 'business_unit' || group_by === 'business_unit') && (metric_column === 'actual_transactions' || metric_column === 'actual_basket_size' || metric_column === 'actual_smac_sales'))
    const zoneMetricsWithNAContribution = ['actual_basket_size', 'actual_catchment_rate', 'actual_conversion_rate', 'actual_space_tfa', 'actual_space_nsa']
    const hasNotApplicableContribution = (metric_column === 'actual_basket_size') || (group_by === 'business_unit' && metric_column === 'actual_transactions') || (group_by === 'zone' && zoneMetricsWithNAContribution.includes(metric_column))

    const totalAllGroups = (function () {
        if (metric_column === 'actual_smac_sales') {
            if (storeData.length <= 0) {
                return 0
            }
            return storeData.reduce((acc, store) => {
                const value = isNaN(parseFloat(store.smac_sales_sum)) ? 0 : parseFloat(store.smac_sales_sum)
                return acc + value
            }, 0)
        }
        if ((group_by === "business_unit" && metric_column === "actual_sales")) {
            if (storeData.length <= 0) {
                return 0
            }
            return storeData.reduce((acc, store) => {
                const value = isNaN(parseFloat(store.gross_sales_sum)) ? 0 : parseFloat(store.gross_sales_sum)
                return acc + value
            }, 0)
        }

        return groupKeys.reduce((acc, group) => acc + (isNaN(parseFloat(metricTotal[group])) ? 0 : parseFloat(metricTotal[group])), 0)
    })()

    const perGroupData = groupKeys.map(group => {
        const total = (metricTotal[group] === 'N/A')
            ? 'N/A'
            : Number(metricTotal[group]);
        const planTotal = (isBUNoMetricPlan || (hasNoMetricPlan && isStoreViewData) || (metricPlanTotal[group] === 'N/A'))
            ? 'N/A'
            : Number(metricPlanTotal[group]);
        const totalLastYear = Number(metricLYTotal[group]);

        // TODO: for BU tab Transactions metrics, contribution column will be complicated formula that involves ~bu_txn and ~stores table
        const contribution = hasNotApplicableContribution
            ? 'N/A'
            : (totalAllGroups ? ((total / totalAllGroups) * 100) : null);
        const vsPlan = (planTotal === 'N/A') || (group_by === 'business_unit' && metric_column === 'actual_transactions')
            ? 'N/A'
            : (planTotal
                ? (total / planTotal) * 100
                : null);
        // Code below might be reverted if (total - totalPreviousYear) will be used for catchment and conversion rate again as requested by the PO
        // const vsLY = (metric_column === 'actual_catchment_rate' || metric_column === 'actual_conversion_rate') 
        //     ? (total - totalLastYear) // for catchment and conversion rate, (total - totalLastYear)
        //     : (totalLastYear ? (total / totalLastYear) * 100 : null); 
        // const vs2019 = (metric_column === 'actual_catchment_rate' || metric_column === 'actual_conversion_rate') 
        //     ? (total - total2019) // for catchment and conversion rate, (total - total2019)
        //     : total2019 ? (total / total2019) * 100 : null; 
        const vsLY = totalLastYear ? (total / totalLastYear) * 100 : null;
        return {
            [group_by]: group,
            metricTotal: total,
            metricPlanTotal: planTotal,
            contribution: contribution === 'N/A' ? contribution : ((isNaN(contribution) || !isFinite(contribution)) ? null : contribution),
            vsPlan: vsPlan === 'N/A' ? vsPlan : ((isNaN(vsPlan) || !isFinite(vsPlan)) ? null : vsPlan),
            vsLY: (isNaN(vsLY) || !isFinite(vsLY)) ? null : vsLY,
        };
    });

    return perGroupData;
};

export const processGRData = (currentGRData, lastYearGRData, metric_column, storeData = {}) => {
    switch (metric_column) {
        case 'actual_sales':
            return {
                channel: 'Gift Registry',
                metricTotal: currentGRData ? currentGRData?.gr_gross_sales_sum : null,
                metricPlanTotal: currentGRData ? currentGRData?.egr_plan_sum : null,
                contribution: currentGRData ? (currentGRData?.gr_gross_sales_sum / currentGRData?.gross_sales_sum) * 100 : null,
                vsPlan: currentGRData ? (currentGRData?.gr_gross_sales_sum / currentGRData?.egr_plan_sum) * 100 : null,
                vsLY: (currentGRData || lastYearGRData) ? (currentGRData?.gr_gross_sales_sum / lastYearGRData?.gr_gross_sales_sum) * 100 : null,
            }
        case 'actual_transactions':
            return {
                channel: 'Gift Registry',
                metricTotal: currentGRData ? currentGRData?.gr_txn_count_sum : null,
                metricPlanTotal: null,
                contribution: null,
                vsPlan: null,
                vsLY: (currentGRData || lastYearGRData) ? (currentGRData?.gr_txn_count_sum / lastYearGRData?.gr_txn_count_sum) * 100 : null,
            }
        case 'actual_basket_size':
            return {
                channel: 'Gift Registry',
                metricTotal: currentGRData ? (currentGRData?.gr_gross_sales_sum / currentGRData?.gr_txn_count_sum) : null,
                metricPlanTotal: null,
                contribution: 'N/A',
                vsPlan: null,
                vsLY: (currentGRData || lastYearGRData) ? ((currentGRData?.gr_gross_sales_sum / currentGRData?.gr_txn_count_sum) / (lastYearGRData?.gr_gross_sales_sum / lastYearGRData?.gr_txn_count_sum)) * 100 : null,
            }
        case 'actual_smac_sales':
            return {
                channel: 'Gift Registry',
                metricTotal: currentGRData ? currentGRData?.gr_smac_sales_sum : null,
                metricPlanTotal: null,
                contribution: currentGRData ? (currentGRData?.gr_smac_sales_sum / currentGRData?.gross_sales_sum) * 100 : null,
                vsPlan: null, // currentGRData ? (currentGRData?.gr_smac_sales_sum / (currentGRData?.sales_plan_sum * 0.70)) * 100 : null,
                vsLY: (currentGRData || lastYearGRData) ? (currentGRData?.gr_smac_sales_sum / lastYearGRData?.gr_smac_sales_sum) * 100 : null,
            }
        default:
            return {
                channel: 'Gift Registry',
                metricTotal: null,
                metricPlanTotal: null,
                contribution: null,
                vsPlan: null,
                vsLY: null,
            }
    }
};

export function appendStoreCount(perGroupData, currentStoreViewData) {
    return perGroupData.map(item => {
        const matchingStoreData = currentStoreViewData?.find(storeData => storeData.store_id?.toString() === item?.store_id);
        return {
            ...item,
            store: matchingStoreData ? matchingStoreData.store : null
        };
    });
}

export function appendStoreName(perStoreData, currentStoreViewData) {
    return perStoreData
        .filter(item => item.store_id !== "null")
        .map(item => {
            const matchingStoreData = currentStoreViewData?.find(storeData => storeData?.store_id?.toString() === item.store_id);
            return {
                ...item,
                store: matchingStoreData ? matchingStoreData.store : null
            };
        });
}

const metricLabels = {
    'actual_sales': 'Sales',
    'actual_transactions': 'Transactions',
    'actual_basket_size': 'Basket Size',
    'actual_catchment_rate': 'Catchment Rate',
    'actual_conversion_rate': 'Conversion Rate',
    'actual_space_tfa': 'Space Productivity TFA',
    'actual_space_nsa': 'Space Productivity NSA',
    'actual_smac_sales': 'SMAC Sales',
    'actual_mall_foot_traffic': 'Mall Foot Traffic',
    'actual_sm_store_foot_traffic': 'Dept Store Foot Traffic',
    'actual_units_sold': 'Units Sold',
    'actual_units_sold_per_txn': 'Units Sold Per Transaction',
};

const periodToLabel = {
    'mtd': 'MTD',
    'qoq': 'QTR',
    'ytd': 'YTD'
}

export const getMetricColumnHeader = (period, metric) => {
    const periodLabel = periodToLabel[period] || '';
    const metricLabel = metricLabels[metric] || '';

    return `${periodLabel} ${metricLabel}`
}

export const getBarTrendsColumnHeader = (metric) => {
    const barTrendsHeader = metricLabels[metric] || '';

    return `12 Mth ${barTrendsHeader} Trend vs Plan`;
}

export const getMetricSectionName = (metric) => {
    const metricLabel = metricLabels[metric] || '';
    return `${metricLabel}`
}

export const metricFormulas =
    [
        "Sales: Gross Sales",
        "Transactions: Transaction Count",
        "Basket Size: Gross Sales / Transaction Count",
        "SMAC Sales: Gross Sales of SMAC members",
        "Catchment Rate: Dept Store Foot Traffic / Mall Foot Traffic",
        "Conversion Rate: (In store + Personal Shopper Pick-up Transactions) / Dept Store Foot Traffic",
        "Space Productivity NSA: Gross Sales / Net Selling Area",
        "Space Productivity TFA: Gross Sales / Total Floor Area",
        "Mall Foot Traffic: Number of Mall visitors",
        "Dept Store Foot Traffic: Number of Department Store visitors",
        "Units Sold: Count of Sales Units",
        "Units Sold Per Transaction: Units Sold / Transaction Count",
        "Unit Price: Gross Sales / Units Sold"
    ]

export const computationFormulas =
    [
        "Contribution: Proportion of the row's values compared to the total value of the chosen metric.",
        "vs Last Year: Proportion of Selected Year values compared to the Previous Year's values.",
        "vs 2019: Proportion of Selected Year values compared to 2019's values."
    ]

export const startsWithAny = (string, prefixes) => {
    return prefixes.some(prefix => string.startsWith(prefix));
};


export const metricCalculationsL3 = {
    actual_sales: (item) => Number(item.pos_sku_gross_sales_sum),
    actual_transactions: (item) => Number(item.header_tran_key_count_distinct),
    actual_basket_size: (item) => {
        const actualSales = metricCalculationsL3["actual_sales"](item);
        const actualTransactions = metricCalculationsL3["actual_transactions"](item);
        if (!actualTransactions || !actualSales) {
            return null;
        }

        return actualSales / actualTransactions;
    },
    actual_catchment_rate: (item) => {
        if (
            !item.hasOwnProperty("sales_plan_sum") || // items without "sales_plan_sum" usually relies on "egr_sales_plan_sum"; meaning it's for Gift Registry
            item.hasOwnProperty("cm_channel_desc") ||
            item.hasOwnProperty("pm_business_unit_desc_standardized") 
        ) {
            return "N/A";
        }
            return ((Number(item.ds_foot_traffic_sum) / Number(item.mall_foot_traffic_sum)) * 100);
    },
    actual_conversion_rate: (item) => {
        if (
            !item.hasOwnProperty("sales_plan_sum") || // items without "sales_plan_sum" usually relies on "egr_sales_plan_sum"; meaning it's for Gift Registry
            item.hasOwnProperty("cm_channel_desc") ||
            item.hasOwnProperty("pm_business_unit_desc_standardized")
        ) {
            return "N/A";
        }
        return ((Number(item.header_tran_key_count_distinct) / Number(item.ds_foot_traffic_sum)) * 100);
    },
    actual_space_tfa: (item) => {
        const isGiftRegistry = !item.hasOwnProperty("sales_plan_sum") && item.hasOwnProperty("egr_sales_plan_sum"); // items without "sales_plan_sum" usually relies on "egr_sales_plan_sum"; meaning it's for Gift Registry
        
        if(isGiftRegistry) {
            return "N/A";
        }

        if(item.hasOwnProperty("total_floor_selling_area_sum")) {
            return Number(item.pos_sku_gross_sales_sum) / Number(item.total_floor_selling_area_sum);
        }
        return null;
    },
    actual_space_nsa: (item) => {
        const isGiftRegistry = !item.hasOwnProperty("sales_plan_sum") && item.hasOwnProperty("egr_sales_plan_sum"); // items without "sales_plan_sum" usually relies on "egr_sales_plan_sum"; meaning it's for Gift Registry
        if (isGiftRegistry) {
            return "N/A";
        }

        if(item.hasOwnProperty("net_selling_area_sum")) {
            return (Number(item.pos_sku_gross_sales_sum) / Number(item.net_selling_area_sum));
        }

        return null;
    },
    actual_smac_sales: (item) => Number(item.pos_sku_smac_sales_sum),
    actual_mall_foot_traffic: (item) => item.mall_foot_traffic_sum,
    actual_sm_store_foot_traffic: (item) => item.ds_foot_traffic_sum,
    actual_units_sold_per_txn: (item) => {
        const actualTransactions = metricCalculationsL3["actual_transactions"](item);
        if (!actualTransactions) {
            return null;
        }
        const actualUnitsSold = metricCalculationsL3["actual_units_sold"](item);
        if (!actualUnitsSold) {
            return null;
        }

        return actualUnitsSold / actualTransactions;
    },
    actual_units_price: (item) => item.pos_sku_sale_tot_qty_sum && (Number(item.pos_sku_gross_sales_sum) / Number(item.pos_sku_sale_tot_qty_sum)),
    actual_units_sold: (item) => Number(item.pos_sku_sale_tot_qty_sum),
};

export const calculateMetricValuesL3 = (data, metricColumn, group_by) => {

    const result = {};
    data?.forEach(item => {

        const group = item[group_by];
        if (metricCalculationsL3[metricColumn]) {
            result[group] = (metricCalculationsL3[metricColumn](item) === 'N/A')
                ? 'N/A'
                : isFinite(metricCalculationsL3[metricColumn](item))
                    ? metricCalculationsL3[metricColumn](item)
                    : null;
        }
    });
    return result;
};

export const metricPlanCalculationsL3 = {
    'actual_sales': (item) => {
        const isGiftRegistry = !item.hasOwnProperty("sales_plan_sum") && item.hasOwnProperty("egr_sales_plan_sum")

        if(isGiftRegistry) {
            return Number(item.egr_sales_plan_sum);
        }

        return item.sales_plan_sum;
    },
    'actual_transactions': (item) => {
        const isGiftRegistry = !item.hasOwnProperty("sales_plan_sum") && item.hasOwnProperty("egr_sales_plan_sum")
        const isBUBasedData = item.hasOwnProperty("pm_business_unit_desc_standardized") || item.hasOwnProperty("sbu_bu_group")

        if(isGiftRegistry || isBUBasedData) {
            return null; // NOTE: Even if null is returned, make sure it gets displayed as N/A; BU groupings have N/A plan and vsPlan
        }

        if(item.hasOwnProperty('cm_channel_desc') && item.cm_channel_desc !== 'In Store') { // FIXME: should be in function that handles null or N/A business rules
            return null;
        }

        const basketSizePlan = metricPlanCalculationsL3["actual_basket_size"](item);
        const salesPlan = metricPlanCalculationsL3["actual_sales"](item);
        if (!basketSizePlan || !salesPlan) {
            return null;
        } 
        
        if((item.hasOwnProperty('store_parent_store') && premiumStores.includes(item.store_parent_store)) || (item.hasOwnProperty('cm_channel_desc') && item.store_parent_store_id_count_distinct === 1)) {
            return salesPlan / basketSizePlan;
        }
        
        return salesPlan / 1300;
    },
    //TODO: for BU tab selected Store View, MTD Basket Size Plan should be null (done)
    'actual_basket_size': (item) => {
        const isGiftRegistry = !item.hasOwnProperty("sales_plan_sum") && item.hasOwnProperty("egr_sales_plan_sum")
        const isBUBasedData = item.hasOwnProperty("pm_business_unit_desc_standardized") || item.hasOwnProperty("sbu_bu_group")
        const isPremiumStore = (item.hasOwnProperty('store_parent_store') && premiumStores.includes(item.store_parent_store))

        if((isGiftRegistry && item.hasOwnProperty('store_parent_store')) || isBUBasedData) {
            return null;
        }

        if(isPremiumStore) {
            if(item.hasOwnProperty('cm_channel_desc') && (item.cm_channel_desc !== 'In Store')) { // for Branch View Performance Per Channel
                return null;
            }
            return 1900;
        }

        if(item.hasOwnProperty('cm_channel_desc') && (item.cm_channel_desc !== 'In Store')) {
            return null;
        }

        if( (item.hasOwnProperty('store_parent_store') && premiumStores.includes(item.store_parent_store)) || (item.hasOwnProperty('channel') && item.store_parent_store_id_count_distinct === 1) ) { // for Operations - Store(Branch) View
            return item.store_basket_size_plan
        }

        return 1300; // default Basket Size Plan is usually 1300
    },
    'actual_catchment_rate': (item) => 'N/A',
    'actual_conversion_rate': (item) => 'N/A',
    'actual_space_tfa': (item) => {
        const isGiftRegistry = !item.hasOwnProperty("sales_plan_sum") && item.hasOwnProperty("egr_sales_plan_sum")

        if(isGiftRegistry) {
            return null; // Note: GR for Space Prod TFA must be "N/A"
        }

        if(item.hasOwnProperty('total_floor_selling_area_sum') && item.total_floor_selling_area_sum) {
            return (Number(item.sales_plan_sum) / Number(item.total_floor_selling_area_sum))
        } else {
            return null; // originally 0
        }
    },
    'actual_space_nsa': (item) => {
        const isGiftRegistry = !item.hasOwnProperty("sales_plan_sum") && item.hasOwnProperty("egr_sales_plan_sum")

        if(isGiftRegistry) {
            return null; // Note: GR for Space Prod NSA must be "N/A"
        }

        if(item.hasOwnProperty('sbu_bu_group') || item.hasOwnProperty('pm_business_unit_desc_standardized') || item.hasOwnProperty('store_zone') || item.hasOwnProperty('store_parent_store') || item.hasOwnProperty('store_parent_store_id_count_distinct')) {
            if(item.hasOwnProperty('net_selling_area_sum') && item.net_selling_area_sum) {
                return (parseFloat(item.sales_plan_sum) / parseFloat(item.net_selling_area_sum));
            }
        } else {
            return null;
        }  
    },
    // TODO: smac sales for BU has no MTD and YTD plan
    'actual_smac_sales': (item) => {
        const isGiftRegistry = !item.hasOwnProperty("sales_plan_sum") && item.hasOwnProperty("egr_sales_plan_sum")

        if(isGiftRegistry) {
            return null; // Note: GR for Space Prod NSA must be "N/A"
        }
        
        if(item.hasOwnProperty('cm_channel_desc') && item.cm_channel_desc !== 'In Store') {
            return null;
        }

        if(item.hasOwnProperty('store_zone') || !item.hasOwnProperty('pm_business_unit_desc_standardized') || item.hasOwnProperty('cm_channel_desc') || item.hasOwnProperty('store_parent_store')) {
            const salesPlan = metricPlanCalculationsL3["actual_sales"](item);
            return salesPlan * 0.70;
        }

        return null;
    },
    'actual_mall_foot_traffic': (item) => 'N/A',
    'actual_sm_store_foot_traffic': (item) => 'N/A',
    'actual_units_sold_per_txn': (item) => 'N/A',
    'actual_units_price': (item) => 'N/A',
    'actual_units_sold': (item) => 'N/A',

};

export const calculateMetricPlanValuesL3 = (data, metricColumn, group_by) => {
    const result = {};
    data?.forEach(item => {
        const group = item[group_by];
        if (metricPlanCalculationsL3[metricColumn]) {
            result[group] = (metricPlanCalculationsL3[metricColumn](item) === 'N/A')
                ? 'N/A'
                : isFinite(metricPlanCalculationsL3[metricColumn](item))
                    ? metricPlanCalculationsL3[metricColumn](item)
                    : null;
        }
    });
    return result;
};

export const processDataL3 = (metricTotal, metricPlanTotal, metricLYTotal, params, groupKeys, storeData = []) => {
    const { group_by, metric_column } = params;
    const isStoreViewData = storeData.length > 0 ? storeData.some(obj => obj?.hasOwnProperty('store_parent_store_id')) : false;
    const perGroupTab = params.hasOwnProperty("cm_channel_desc")
        ? "channel"
        : (params.hasOwnProperty("store_zone")
            ? "zone"
            : (params.hasOwnProperty("pm_business_unit_desc_standardized")
                ? "business_unit"
                : null));

    const noMetricPlanGroups = isStoreViewData && perGroupTab === "channel" && (metric_column === 'actual_basket_size' || metric_column === 'actual_transactions' || metric_column === 'actual_smac_sales')
        ? ['Personal Shopper', 'Online', 'Gift Registry']
        : (isStoreViewData && perGroupTab === "business_unit" && (metric_column === 'actual_transactions' || metric_column === 'actual_smac_sales')
            ? ["SHOES", "ACCESSORIES MANAGEMENT", "LADIES' FASHION", "MEN'S FASHION", "CHILDREN'S FASHION", "SM HOME", "ACE HARDWARE/WORKSHOP", "TOYWORLD", "BABY COMPANY", "SUPPLIES STATION", "WATSONS", "KULTURA", "SIGNATURE LINES", "SPORTS CENTRAL", "SMART BUY", "OTHERS",
                "BATA", "LOBBY", "SERVICES", "SURPLUS SHOP", "FLOATING", "OUR HOME", "PREMIUM FASHION RETAIL DESIGNS", "SFERA", "THE BODY SHOP", "FOREVER 21", "ECCO PHILIPPINES", "UPTREND FASHION DESIGN CORP", "MODERN BODY LUXE STORES, INC.", "WHITE PLANET, INC.", "CROCS", "MINISO", "SHOEMART, INC."]
            : []
        );

    // checks whether the eye filter toggled should have no MTD/YTD Plan
    const hasNoMetricPlanForGroup = (key) => noMetricPlanGroups.some(group => params?.[key]?.map(v => v.toLowerCase()).includes(group?.toLowerCase()));
    const hasNoMetricPlan = (perGroupTab === "channel" && hasNoMetricPlanForGroup('channel') && !params?.channel?.includes('In Store')) ||
        (perGroupTab === "business_unit" && hasNoMetricPlanForGroup('business_unit')) ||
        false;

    const isBUNoMetricPlan = ((perGroupTab === 'business_unit' || group_by === 'pm_business_unit_desc_standardized') && (metric_column === 'actual_transactions' || metric_column === 'actual_basket_size' || metric_column === 'actual_smac_sales'))
    const zoneMetricsWithNAContribution = ['actual_basket_size', 'actual_catchment_rate', 'actual_conversion_rate', 'actual_space_tfa', 'actual_space_nsa']
    const hasNotApplicableContribution = (metric_column === 'actual_basket_size') || (group_by === 'pm_business_unit_desc_standardized' && metric_column === 'actual_transactions') || (group_by === 'store_zone' && zoneMetricsWithNAContribution.includes(metric_column))

    const totalAllGroups = (function () {
        if (metric_column === 'actual_smac_sales') {
            if (storeData.length <= 0) {
                return 0
            }
            return storeData.reduce((acc, store) => {
                const value = isNaN(parseFloat(store.pos_sku_smac_sales_sum)) ? 0 : parseFloat(store.pos_sku_smac_sales_sum)
                return acc + value
            }, 0)
        }
        if ((group_by === "business_unit" && metric_column === "actual_sales")) {
            if (storeData.length <= 0) {
                return 0
            }
            return storeData.reduce((acc, store) => {
                const value = isNaN(parseFloat(store.pos_sku_gross_sales_sum)) ? 0 : parseFloat(store.pos_sku_gross_sales_sum)
                return acc + value
            }, 0)
        }

        return groupKeys.reduce((acc, group) => acc + (isNaN(parseFloat(metricTotal[group])) ? 0 : parseFloat(metricTotal[group])), 0)
    })()

    const perGroupData = groupKeys.map(group => {
        const total = (metricTotal[group] === 'N/A')
            ? 'N/A'
            : Number(metricTotal[group]);
        const planTotal = (isBUNoMetricPlan || (hasNoMetricPlan && isStoreViewData) || (metricPlanTotal[group] === 'N/A'))
            ? 'N/A'
            : Number(metricPlanTotal[group]);
        const totalLastYear = Number(metricLYTotal[group]);

        // TODO: for BU tab Transactions metrics, contribution column will be complicated formula that involves ~bu_txn and ~stores table
        const contribution = hasNotApplicableContribution
            ? 'N/A'
            : (totalAllGroups ? ((total / totalAllGroups) * 100) : null);
        const vsPlan = (planTotal === 'N/A') || (group_by === 'pm_business_unit_desc_standardized' && metric_column === 'actual_transactions')
            ? 'N/A'
            : (planTotal
                ? (total / planTotal) * 100
                : null);
        // Code below might be reverted if (total - totalPreviousYear) will be used for catchment and conversion rate again as requested by the PO
        // const vsLY = (metric_column === 'actual_catchment_rate' || metric_column === 'actual_conversion_rate') 
        //     ? (total - totalLastYear) // for catchment and conversion rate, (total - totalLastYear)
        //     : (totalLastYear ? (total / totalLastYear) * 100 : null); 
        // const vs2019 = (metric_column === 'actual_catchment_rate' || metric_column === 'actual_conversion_rate') 
        //     ? (total - total2019) // for catchment and conversion rate, (total - total2019)
        //     : total2019 ? (total / total2019) * 100 : null; 
        const vsLY = totalLastYear ? (total / totalLastYear) * 100 : null;
        return {
            [group_by]: group,
            metricTotal: total,
            metricPlanTotal: planTotal,
            contribution: contribution === 'N/A' ? contribution : ((isNaN(contribution) || !isFinite(contribution)) ? null : contribution),
            vsPlan: vsPlan === 'N/A' ? vsPlan : ((isNaN(vsPlan) || !isFinite(vsPlan)) ? null : vsPlan),
            vsLY: (isNaN(vsLY) || !isFinite(vsLY)) ? null : vsLY,
        };
    });

    return perGroupData;
};

export const processGRDataL3 = (currentGRData, lastYearGRData, metric_column, storeData) => {
    const overallTYSalesSum = storeData?.reduce((accumulator, item) => {
        const grossSales = parseFloat(item.pos_sku_gross_sales_sum) || 0;
        return accumulator + grossSales;
    }, 0);

    switch (metric_column) {
        case 'actual_sales':
            return {
                cm_channel_desc: 'Gift Registry',
                metricTotal: currentGRData ? currentGRData?.pos_sku_gross_sales_sum : null,
                metricPlanTotal: currentGRData ? currentGRData?.egr_sales_plan_sum : null,
                contribution: currentGRData && overallTYSalesSum !== 0 ? (currentGRData?.pos_sku_gross_sales_sum / overallTYSalesSum) * 100 : null,
                vsPlan: currentGRData ? (currentGRData?.pos_sku_gross_sales_sum / currentGRData?.egr_sales_plan_sum) * 100 : null,
                vsLY: (currentGRData || lastYearGRData) ? (currentGRData?.pos_sku_gross_sales_sum / lastYearGRData?.pos_sku_gross_sales_sum) * 100 : null,
            }
        case 'actual_transactions':
            return {
                cm_channel_desc: 'Gift Registry',
                metricTotal: currentGRData ? currentGRData?.header_tran_key_count_distinct : null,
                metricPlanTotal: null,
                contribution: null,
                vsPlan: null,
                vsLY: (currentGRData && lastYearGRData) ? (currentGRData?.header_tran_key_count_distinct / lastYearGRData?.header_tran_key_count_distinct) * 100 : null,
            }
        case 'actual_basket_size':
            return {
                cm_channel_desc: 'Gift Registry',
                metricTotal: currentGRData ? (currentGRData?.pos_sku_gross_sales_sum / currentGRData?.header_tran_key_count_distinct) : null,
                metricPlanTotal: null,
                contribution: 'N/A',
                vsPlan: null,
                vsLY: (currentGRData && lastYearGRData) ? ((currentGRData?.pos_sku_gross_sales_sum / currentGRData?.header_tran_key_count_distinct) / (lastYearGRData?.pos_sku_gross_sales_sum / lastYearGRData?.header_tran_key_count_distinct)) * 100 : null,
            }
        case 'actual_smac_sales':
            return {
                cm_channel_desc: 'Gift Registry',
                metricTotal: currentGRData ? currentGRData?.pos_sku_smac_sales_sum : null,
                metricPlanTotal: null,
                contribution: currentGRData && overallTYSalesSum !== 0  ? (currentGRData?.pos_sku_smac_sales_sum / overallTYSalesSum) * 100 : null,
                vsPlan: null, // currentGRData ? (currentGRData?.gr_smac_sales_sum / (currentGRData?.sales_plan_sum * 0.70)) * 100 : null,
                vsLY: (currentGRData && lastYearGRData) ? (currentGRData?.pos_sku_smac_sales_sum / lastYearGRData?.pos_sku_smac_sales_sum) * 100 : null,
            }
        default:
            return {
                cm_channel_desc: 'Gift Registry',
                metricTotal: null,
                metricPlanTotal: null,
                contribution: null,
                vsPlan: null,
                vsLY: null,
            }
    }
};