import React, { useContext } from 'react';
import { getSelectedOption } from '../../../../common/utils';
import { Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { styled } from "@mui/material/styles";


export default function FilterGroup({ onDropdownChange, filters }) {
    const windowSize = useContext(WindowSizeContext);
    const NewToggleButton = styled(ToggleButton)({
        width: windowSize?.isOnMobile ? '5rem' : '7rem',
        textTransform: 'capitalize',
        color: '#0a4fb4',
        border: 0,
        borderRadius: '.25rem',
        backgroundColor: '#fff',
        borderBottom: 'none',
        '&:hover': {
            color: "#fff",
            backgroundColor: '#0a4fb4'
        },
        '&.Mui-selected, &.Mui-selected:hover': {
            color: "#fff",
            backgroundColor: '#0a4fb4'
        }, '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            borderRight: '.05rem solid lightgray',
            borderLeft: 'none'
        }, '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            marginLeft: 0
        },
    });


    const optionListGroup = [
        // NOTE: url value is for experimenting
        { value: "product_group", label: "Product Category" },
        { value: "region", label: "Region" },
        { value: "store_size", label: "Store Size" },
        { value: "mall_type", label: "Store Type" },
        { value: "mall_category", label: "Store Subtype" }
    ];

    return (
        <Grid item xs={12} sm={9}>
            <Grid container xs={12} spacing={0}>
                {!windowSize.isOnMobile &&
                    <Grid item xs sm={2.5}>
                        <Typography variant="h6" ml={2.5}>
                            Performance per
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12} sm>
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        exclusive
                        sx={{ flexWrap: "wrap"}}
                        value={getSelectedOption(filters?.group, optionListGroup)?.value}
                        onChange={(event, value) => {
                            if (value !== null) {
                                onDropdownChange(value, 'group')
                            }
                        }}
                    >
                        {
                            optionListGroup.map(option => {
                                return <NewToggleButton value={option.value}>{option.label}</NewToggleButton>
                            })
                        }
                    </ToggleButtonGroup>
                    {/* <Autocomplete
                        value={getSelectedOption(filters?.group, optionListGroup)}
                        onChange={(event,newValue)=>onDropdownChange(newValue.value,'group')}
                        disableClearable
                        defaultValue={optionListGroup[0]}
                        id="filter_group_options"
                        size="small"
                        options={optionListGroup}
                        renderInput={(params) => <TextField  {...params} onKeyDown={windowSize.isOnMobile ? (e) => e.preventDefault(): null}/>}
                    /> */}
                </Grid>
            </Grid>
        </Grid>
    );
}