import React from "react";
import {OperationTypeFilterContext} from "../contexts";
import {Button, Grid, Typography} from "@mui/material";
import {CONTROLLABLE_OPERATION_TYPES, NON_CONTROLLABLE_OPERATION_TYPES} from "../constants";


export default function OperationTypeFilterDisplay() {
    const {selected, setSelected} = React.useContext(OperationTypeFilterContext);
    if (!selected || selected.length === 0) {
        return null;
    }

    const displayItems = [];
    const allControllableSelected = CONTROLLABLE_OPERATION_TYPES.every((type) => selected.includes(type))
    const allNonControllableSelected = NON_CONTROLLABLE_OPERATION_TYPES.every((type) => selected.includes(type))

    if (allNonControllableSelected && allControllableSelected) {
        return null  // show nothing if all are selected
    }

    if (allControllableSelected) {
        displayItems.push("All Controllable");
    } else {
        const selectedControllables = CONTROLLABLE_OPERATION_TYPES.filter((type) => selected.includes(type));
        displayItems.push(...selectedControllables);
    }
    if (allNonControllableSelected) {
        displayItems.push("All Non-controllable");
    } else {
        const selectedNonControllables = selected.filter((type) => NON_CONTROLLABLE_OPERATION_TYPES.includes(type));
        displayItems.push(...selectedNonControllables);
    }

    return (
        <Grid item xs={12} sm={12} sx={{
            boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
            border: '1px solid #e0e0e0',
            backgroundColor: '#e1ebf2'
        }}>
            <Typography ml={2} sx={{borderRadius: '0', color: '#000'}}>
                Showing data for: {displayItems.join(", ")} | <Button variant="text" sx={{paddingTop: '3px'}} onClick={() => setSelected([])}>RESET</Button>
            </Typography>
        </Grid>

    )
}