import React, { useContext } from "react";
import { setIndicatorBgColorClass, setPrefixForIndicator } from "../../../../../common/utils";
import { useQuery } from "react-query";
import ApiClient from "../../../../../common/API";
import { getPreviousPeriodParams } from "../util";
import { StoreAnalyticsFilterContext } from "../../contexts";
import { Grid, Typography, CircularProgress, Chip } from "@mui/material";

export default function Indicators({ dateFilter, salesApiEndPointName, apiDefaultFilter }) {
    const { region, province } = useContext(StoreAnalyticsFilterContext)

    const staticParams = {
        region: region,
        province: province,
        ...apiDefaultFilter
    }

    const currentParams = {
        ...dateFilter,
        ...staticParams
    }
    const previousParams = {
        ...getPreviousPeriodParams(dateFilter),
        ...staticParams
    }
    const { isLoading: isLoadingCurrentData, data: currentData } = useQuery([salesApiEndPointName, 'sales_trend', currentParams], () => {
        return ApiClient().get(`/custom/${salesApiEndPointName}-map-sales/indicators`, {
            params: currentParams
        }).then(res => {
            return res.data
        })
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 15 * 60 * 1000,
        staleTime: 15 * 60 * 1000
    })
    const {
        isLoading: isLoadingPreviousData,
        data: previousData
    } = useQuery([salesApiEndPointName, 'sales_trend', previousParams], () => {
        return ApiClient().get(`/custom/${salesApiEndPointName}-map-sales/indicators`, {
            params: previousParams
        }).then(res => {
            return res.data
        })
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 15 * 60 * 1000,
        staleTime: 15 * 60 * 1000
    })


    const regionIndicators = [
        {
            title: "Regional Footprints",
            main_count: currentData?.regionCount,
            indicator: currentData?.regionCount - previousData?.regionCount
        },
        {
            title: "Provinces Covered",
            main_count: currentData?.provinceCount,
            indicator: currentData?.provinceCount - previousData?.provinceCount
        },
        {
            title: "Cities Covered",
            main_count: currentData?.cityCount,
            indicator: currentData?.cityCount - previousData?.cityCount
        },
        {
            title: "Active Stores",
            main_count: currentData?.storeCount,
            indicator: currentData?.storeCount - previousData?.storeCount
        }
    ]

    return (
        <>
            {
                regionIndicators && regionIndicators.map(data => {
                    return (
                        <Grid container borderRadius={2} boxShadow={1} p={2} my={1} key={data.title} spacing={1}>
                                {
                                    isLoadingCurrentData ?
                                        <Grid item sx={{ textAlign: 'right', alignItems: 'left' }}>
                                            <CircularProgress color="primary" size={16} role="status" aria-hidden="true" />
                                        </Grid> :
                                        <Grid item xs={4} textAlign='end' justifyContent='end'>
                                            <Typography variant="h3" fontWeight={'bold'}>
                                                {data.main_count}
                                            </Typography>
                                            <Chip label={
                                                    isLoadingPreviousData || isLoadingCurrentData ? (
                                                        <CircularProgress size={16} color="primary" />
                                                    ) : (
                                                        setPrefixForIndicator(data.indicator)
                                                    )
                                                } 
                                                size="small" sx={{backgroundColor: setIndicatorBgColorClass(data.indicator), color: '#fff' }} />
                                        </Grid>
                                }
                                <Grid item xs={8}>
                                    <Typography variant="h6" style={{ whiteSpace: 'pre' }}>
                                        {data.title}
                                    </Typography>
                                </Grid>
                        </Grid>
                    )
                })
            }</>
    )
}