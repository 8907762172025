import { Grid, Card, Typography, Collapse } from '@mui/material';
import React, { useState, useContext} from 'react';
import PerformanceTrends from './PerformanceTrends';
import CollapsibleComponent from '../../../../../../common/components/CollapsibleComponent';
import { useBUGroupOverallPerformance} from '../../../hooks';
import LoadingComponent from '../../../../../../common/components/LoadingComponent';
import FilterBusinessUnitsGroup from '../../../Filters/BUGroup';
import { WindowSizeContext } from '../../../../../../common/context/WindowSizeContext';
import moment from 'moment';

export default function BUGraphGroupingPerformance({filters, params, period, onDropdownChange, storeID}) {
    const [isExpand, setExpand] = useState(true);

    const windowSize = useContext(WindowSizeContext);
    const isMTD = period === 'mtd'
    const isQoQ = period ==='qoq'
    const paramsBasedOnPeriod = isQoQ
        ? { ...params,
            month: Array.from({ length: 3 }, (_, i) =>
                moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month() + i + 1
            )
        }
        : isMTD ? { ...params } : { ...params, month: [1, ...(params?.month || [])] };

    const {finalData, isLoading} = useBUGroupOverallPerformance({params: {...paramsBasedOnPeriod, store_id: storeID}})

    // Filter out undefined properties
    const filteredData = finalData?.map(item => {
        const cleanedItem = Object.fromEntries(
            Object.entries(item).filter(([key, value]) => value !== undefined)
        );
        return cleanedItem;
    });

    if(isLoading) {
        return <Grid container sx={{justifyContent:'center'}}>
                <LoadingComponent />
            </Grid>
    }

    return  <Grid container marginTop={1}>
        <Grid item sm={12} xs={12}>
            <Card sx={{borderRadius: ".5rem .5rem 0 0"}}>
                <Grid container sx={{ backgroundColor:'#f6f6f8', padding: 1.5}}  display="flex" alignItems="center"> 
                    <Grid item sm={8} xs={11}>
                        <Typography variant="h6">Business Unit Group Performance</Typography>
                    </Grid> 
                    {
                        windowSize.isOnMobile ? 
                        <> 
                            <Grid item sm={1} xs={1}>
                                    <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                            <Grid item sm={3} xs={12} display={'flex'} justifyContent={'end'}>
                                <FilterBusinessUnitsGroup filters={filters} params={params} onDropdownChange={onDropdownChange} storeID={storeID}/>
                            </Grid> 
                        </> :
                        <>
                            <Grid item sm={3} xs={3} display={'flex'} justifyContent={'end'}>
                                <FilterBusinessUnitsGroup filters={filters} params={params} onDropdownChange={onDropdownChange} storeID={storeID}/>
                            </Grid> 
                            <Grid item sm={1} xs={1}>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>  
                        </>
                    }
                </Grid>
                <Collapse in={isExpand}>
                    <Grid container spacing={1}>
                        <Grid item sm={12} xs={12} sx={{ overflowY:'hidden'}}>
                            <Grid item container direction={windowSize?.isOnMobile ? 'column' : 'row'} sm={12} xs={12} padding="1rem" display="flex" justifyContent="space-between">
                                <PerformanceTrends title='Total Sales' params={params} data={filteredData} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Collapse>
            </Card>
        </Grid>
    </Grid>
}