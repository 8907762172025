import React from "react";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { Grid, IconButton } from '@mui/material';

export default function CollapsibleComponent({ isExpand, setExpand }) {

    return (
        <>
            <Grid item display="flex" justifyContent="end">
                <IconButton size='small' onClick={() => setExpand(!isExpand)}>
                    {!isExpand ? (
                        <ExpandMoreRoundedIcon fontSize='large' />
                    ) : (
                        <ExpandLessRoundedIcon fontSize='large' />
                    )}
                </IconButton>
            </Grid>
        </>
    );
}
