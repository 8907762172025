import { useState } from 'react';
import { TogglePeriodContext } from './context';

export default function TogglePeriodProvider( {children} ) {
  const [period, setPeriod] = useState('mtd');
  const [showToggle, setShowToggle] = useState(true);

  const updatePeriod = (value) => {
    if (value !== null) {
      setPeriod(value);
    }
  };

  return (
    <TogglePeriodContext.Provider value={{ period, updatePeriod, showToggle, setShowToggle }}>
      {children}
    </TogglePeriodContext.Provider>
  );
};