import React, { useContext, useMemo } from 'react';
import TableComponentV2 from '../../../../common/components/TableComponentV2';
import { Grid, Box, Typography } from '@mui/material/'
import { convertToValidNumber } from '../../../../common/utils';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useOpexCompanyData } from '../hooks';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import ToggleMtdYtd from '../../Sales/toggleMtdYtd';
import BarIndicator from '../../common/presentation/barIndicator';
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import BaseNumericDisplay from "../../common/presentation/numericDisplays/baseNumericDisplay";
import { decimalToString } from "../../../../common/utils/numbers";

const IndicatorCellBox = (props) => (
    <Box sx={{ my: 1, width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);

export default function OpexCompanyTable({ params, period, onToggleChange }) {

    const windowSize = useContext(WindowSizeContext)

    const { finalData, isLoading } = useOpexCompanyData({ params, period })

    const opex_company_data = useMemo(() => [
        {
            id: 'metrics',
            header: 'Company',
            accessorKey: 'company_name',
            cell: (row) => (
                <Box sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                    {row?.getValue()}
                </Box>
            ),
            customStyle: { width: '15rem' }
        },
        {
            header: period === 'mtd' ? "MTD OPEX" : "YTD OPEX",
            accessorKey: 'current',
            cell: ({ row, getValue }) => {
                return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
            },
            customStyle: { width: '8rem', textAlign: 'left' }
        },
        {
            header: 'Last Year',
            accessorKey: 'ly',
            cell: ({ row, getValue }) => {
                return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
            },
            customStyle: { width: '8rem', textAlign: 'left' }
        },
        {
            header: 'vs Last Year',
            accessorKey: 'vsLy',
            cell: (row) => <>
                <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'opex'} value={row?.getValue()?.toFixed(1)} label="vs last year" isInverted={true} isValueColorCoded={true} isOnTable={true} />
                </IndicatorCellBox>
            </>,
            customStyle: { width: '8rem', textAlign: 'left', verticalAlign: 'middle', whiteSpace: 'nowrap' }
        },
        {
            header: 'Budget',
            accessorKey: 'budget',
            cell: ({ row, getValue }) => {
                return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
            },
            customStyle: { width: '8rem', textAlign: 'left' }
        },
        {
            header: 'vs Budget',
            accessorKey: 'vsBudget',
            cell: (row) => <>
                <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'opex'} value={row?.getValue()?.toFixed(1)} label="vs last year" isInverted={true} isValueColorCoded={true} isOnTable={true} />
                </IndicatorCellBox>
            </>,
            customStyle: { width: '8rem', textAlign: 'left', verticalAlign: 'middle', whiteSpace: 'nowrap' }
        },
        {
            header: period === 'mtd' ? "MTD Sales" : "YTD Sales",
            accessorKey: 'actualSales',
            cell: ({ row, getValue }) => {
                return <Box sx={{ mx: 3 }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
            },
            customStyle: { width: '8rem', textAlign: 'left', whiteSpace: 'nowrap' }
        },
        {
            header: '% to Sales',
            accessorKey: 'percentageToSales',
            cell: ({ row, getValue }) => {
                const value = decimalToString(getValue(), { fixedDigits: 2 })
                return <Box sx={{ whiteSpace: 'nowrap' }}>
                    <BaseNumericDisplay value={value} postfix={'%'} />
                </Box>
            },
            customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
        },
    ], [period, windowSize])

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <Grid xs={12} sx={{ py: 2 }}>
            <Grid xs={12}>
                <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                    <Grid container alignItems="center" py={.5}>
                        <Grid item xs={6} sm>
                            <Typography variant='h6' ml={2} >OPEX Overview</Typography>
                        </Grid>
                        <Grid item sm xs display='flex' justifyContent='end'>
                            <ToggleMtdYtd
                                value={period}
                                onChange={onToggleChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            <Grid xs={12}>
                <TableComponentV2
                    data={finalData}
                    columns={opex_company_data}
                    isCompact={true}
                    customTableHeaderStyle={{
                        backgroundColor: '#caddeb'
                    }}
                />
            </Grid>
        </Grid>
    )
}