import { Grid } from "@mui/material";
import Section from "../../../../common/components/Dashboard/Section";
import PeriodicitySelector from "../Selectors/PeriodicitySelector";
import YearSelector from "../Selectors/YearSelector";
import PeriodToggleSelector from "../Selectors/PeriodToggleSelector";
import { useContext } from "react";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { usePeriodValues } from "../hooks";
import SMACMetricsTable from "./SMACMetricsTable";

export default function SMACMetrics(){
    
    const windowSize = useContext(WindowSizeContext)
    const { isLoading, isSuccess, asDateRange: dateRangeData, data: rawData } = usePeriodValues(); 
    return <Section
        title={"SMAC Performance Metrics"}
        isCollapsible={false}
        selectorsOrFilters={<>
            <Grid item sm={2} xs={12} >
                <PeriodicitySelector
                    isLoading={isLoading}
                    dateRange={dateRangeData}
                    isSuccess={isSuccess}
                    rawData={rawData}
                />
            </Grid>
            <Grid item sm={2} xs={12} marginRight={!windowSize.isOnMobile && 15}>
                <YearSelector
                    isLoadingDateRange={isLoading}
                    dateRange={dateRangeData}
                />
            </Grid>
            <Grid item sm={2} xs={12}>
                <PeriodToggleSelector />
            </Grid>
        </>}
    >
        <SMACMetricsTable/>
    </Section>
}