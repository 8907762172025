import { Box,IconButton, Popper, Paper, Fade, Typography, Divider, Grid } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './index.css'
import { WindowSizeContext } from "../../../../../../../common/context/WindowSizeContext";
import { LightTooltip } from "../MapSideContainer/LightTooltip";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import MinimizeRoundedIcon from '@mui/icons-material/MinimizeRounded';
import { SMACMembersTooltipContent, PopulationTooltipContent } from "..";

export default function LegendControl({ cityPopulation, cityName }) {
    const windowSize = useContext(WindowSizeContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(true);
    const [placement, setPlacement] = useState(undefined);

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => { // required to set anchorEl on load
        const iconButton = document.getElementById('leaflet-control-menu-button');
        if (iconButton) {
            handleClick('right-start')({ currentTarget: iconButton });
        }
    }, []);

    const highestRate = cityPopulation.filter(row => row.city_name===cityName).reduce((max, obj) => {
        return obj.smac_penetration_rate > max ? obj.smac_penetration_rate : max;
    }, -Infinity);

    return (
        <Box className={'leaflet-bottom leaflet-left'}>
        <Box className="leaflet-control leaflet-bar">
            {/* BUTTON COMPONENT */}
            <Box className="float-start" 
                sx={{
                    '&:focus': {
                        outline: 'none',
                    },}}>
            <IconButton
                id="leaflet-control-menu-button"
                sx={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    border: 'none',
                    '&.MuiButtonBase-root:hover': {
                        bgcolor: '#fff',
                    },
                    '&:focus': {
                        outline: 'none',
                    },
                }}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick('right-start')}
                color="primary"
            >
                <InfoOutlinedIcon fontSize="medium" />
            </IconButton>
            </Box>

            <Popper
                id="legendpopper"
                key='keylegendpopper'
                open={open}
                anchorEl={anchorEl}
                placement={placement || 'right-start'}
                transition
                PaperProps={{
                    style: {
                        boxShadow: 'none',
                    },
                }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                    <Paper className="legend-container" elevation={3}>
                        <Box sx={{ display: 'flex', p:.5 , alignItems: 'end'}}>
                            <Typography variant='h6'>Legend</Typography>
                            <IconButton
                                onClick={handleClose}
                                aria-label="minimize"
                                size="small"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                }}
                            >
                                <MinimizeRoundedIcon />
                            </IconButton>
                        </Box>
                        <Divider/>
                        {/* Circle marker legend */}
                        {/* <Box sx={{ p:1 }}>
                            <Grid container alignItems="end" spacing={1}>
                                <Grid item>
                                    <Typography variant='subtitle2'>SMAC Members:</Typography>
                                </Grid>
                                <Grid item>
                                    <LightTooltip title={SMACMembersTooltipContent} placement='top'>
                                    <InfoRoundedIcon color='info'/>
                                    </LightTooltip>
                                </Grid>
                            </Grid>
                            <div className="legend-grid" style={{gridTemplateColumns: windowSize.isOnMobile ? 'auto' : 'auto auto 1fr'}}>
                                {legendData.map((item, index) => (
                                <div key={index} className="legend-item">
                                    <div className="circle" style={{ backgroundColor: item.color }}></div>
                                    <Typography className="text">{item.range}</Typography>
                                </div>
                                ))}
                            </div>
                        </Box> */}

                        <Divider/>

                        {/* City Population */}
                        <Box sx={{ p:1 }}>
                            <Grid container alignItems="end" spacing={1}>
                                <Grid item>
                                    <Typography variant='subtitle2'>City Population (% Penetration):</Typography>
                                </Grid>
                                <Grid item>
                                    <LightTooltip title={PopulationTooltipContent} placement='top'>
                                    <InfoRoundedIcon color='info'/>
                                    </LightTooltip>
                                </Grid>
                            </Grid>
                            <div className="gradient-map" style={{width: windowSize.isOnMobile ? '200px' : '365px'}}></div>
                            <div className="legend-labels">
                                <Typography>0%</Typography>
                                {/* <FormatPercentage number={highestRate} decimalPlaces={2} /> */}
                                <Typography>100%</Typography>
                            </div>
                        </Box>


                    </Paper>
                    </Fade>
                    )}
            </Popper>
        </Box>
        </Box>
    );
}
