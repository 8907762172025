import React from "react";


export default function ErrorFallbackComponent({error, componentStack, resetErrorBoundary}) {
    return (
        <div>
            <h6>Fill in the necessary fields</h6>
            <p className="text-muted">
                {error.message}
            </p>
            <button className="btn" onClick={resetErrorBoundary}>
                <i className="bi bi-arrow-clockwise"></i>
                Try again
            </button>
        </div>
    );
};