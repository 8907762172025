import { useContext, useState, useEffect } from 'react';
import { WindowSizeContext } from '../../../common/context/WindowSizeContext';
import { Box, GlobalStyles, Grid, Typography, } from '@mui/material';
import Back from '../../../common/components/BackNavigateComponent';
import { RenderCustomerChainViewContent } from './renderCustomerChainViewContent';
import DemographicsChainView from './DemographicsChainView';
import Contactability from './Contactability';
import GroupSelector from '../common/presentation/selectors/groupSelector';
import Filters from './Filters';
import TogglePeriodProvider from './Provider/togglePeriodProvider';
import FiltersProvider from './Provider/filtersProvider';
import ToplineInformation from './ToplineInformation';
import InformationToolTip from '../../../common/components/InformationToolTip';
import AsOfDate from "./AsOfDate";

const VIEW_BY = [
    { label: 'Transactions', value: 'transactions', disabled: false },
    { label: 'Segments', value: 'segments', disabled: false },
    { label: 'Location', value: 'location', disabled: true },
]

const informationContent = <>
    <div>

        <strong>SMAC Members</strong> - count of unique customers with SMAC membership (Start,
        SMAC, Prestige) with transactions on SM Store that<br/>are active from 2023
        <div style={{ paddingLeft: "2em" }}>
            * Members with multiple card types linked or members with identical account
            information are counted as one
        </div>

    </div>
    <div>
        <strong>Generation</strong> - SMAC members are classified based on their birth year:
        <div style={{ paddingLeft: "2em" }}>
            <div>Gen Z – 1997 - 2006</div>
            <div>Millennial – 1982 - 1996</div>
            <div>Gen X – 1965 - 1981</div>
            <div>Boomers – 1964 and earlier</div>
        </div>
    </div>

    <div>
        <strong>Sales</strong> - SMAC Members' Gross Sales
    </div>
    <div>
        <strong>Transactions</strong> - SMAC Members' Transaction Count
    </div>
    <div>
        <strong>Basket Size</strong> - SMAC Members' Gross Sales / Transaction Count of SMAC
        members
    </div>
    <div>
        <strong>Frequency of Visit</strong>{" "}
        - average number of times a SMAC member visits an SM Store
        <div style={{ paddingLeft: "2em" }}>
            * Multiple visits per day are counted as one
        </div>
    </div>
    <div>
        <strong>Units Sold Per Transaction</strong> - Units Sold / Transaction Count of SMAC
        members
    </div>
    <div>
        <strong>Customer Value Segmentation</strong> - classification of customers based on their spending. Segmentation period is past 12 months,
        <br />specifically calculated at the end of each quarter. <i>(Ex. Selected month: May 2024, Segmentation Period: April 2023 - March 2024)</i>
        <div style={{ paddingLeft: "2em" }}>
            These segments categorize customers by their percentile rank in spending during that period.
        </div>
        <div style={{ paddingLeft: "3.5em" }}>
            <div>• High - 71st - 100th Percentile</div>
            <div>• Mid - 31st - 70th Percentile</div>
            <div>• Low - 0th - 30th Percentile</div>
            <div>• Others - other customers that fall on the following categories:</div>
        </div>
        <div style={{ paddingLeft: "5em" }}>
            <div>New - new customers with transactions in the current quarter</div>
            <div>Lapsers - no transactions within the segmentation period</div>
            <div>Returning - lapsers with transactions in the current quarter</div>
        </div>
        <div style={{ paddingLeft: "2em" }}>
            * These are updated quarterly. The customer segment is based on the tagging of the previous quarter regardless of the period
            <br />of observation. Customers can only have one segment tag.
        </div>
    </div>
</>

export default function Customers() {
    const windowSize = useContext(WindowSizeContext)
    const [viewBy, setViewBy] = useState('transactions') // set operations as default
    const [toggleGenerationAge, setToggleGenerationAge] = useState('age_bin')
    const [scrolling, setScrolling] = useState(false);
    const [prevScrollPos, setPrevScroll] = useState(0);

    const onToggleGenerationAgeChange = (event, val) => {
        if (val !== null) {
            setToggleGenerationAge(val);
        }
    };

    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;

            // Set scrolling state based on scroll position and duration
            if (currentScrollPos <= 100) {
                setScrolling(false);
            } else {
                setScrolling(true);
            }

            setPrevScroll(currentScrollPos);
            // Close the tooltip when scrolling
            setTooltipOpen(false);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, windowSize.isOnMobile]);

    const handleTooltipChange = (value) => {
        setTooltipOpen(value);
    };

    return <>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />
        <Grid container marginTop={0.5} marginBottom={2}>
            <Grid item xs={12} marginY={1} display="flex" alignItems={'center'}>
                <Back />
                <Typography variant='h5' ml={3} >
                    SM Store Customer Analytics
                </Typography>
                <InformationToolTip onChange={handleTooltipChange} open={tooltipOpen} information={informationContent} />
            </Grid>
            <FiltersProvider>
                <TogglePeriodProvider>
                    <Grid container sx={{ position: `${!windowSize.isOnMobile ? 'sticky' : ''}`, top: `${!windowSize.isOnMobile ? '5rem' : ''}`, zIndex: 5, backgroundColor: '#e0e0e0' }}>
                        <Box flexGrow={1} xs={12} paddingX={1} mb={0.5} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: '.5rem' }}>
                            <Grid container columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} paddingX={windowSize.isOnMobile && 1}>
                                {
                                    windowSize.isOnMobile && <Grid item xs={12}>
                                        <AsOfDate />
                                    </Grid>
                                }
                                <Filters />
                                {
                                    !windowSize.isOnMobile && <Grid item sm={4} alignItems="center" display="flex">
                                        <AsOfDate />
                                    </Grid>
                                }
                            </Grid>
                        </Box>

                        <Grid item xs={12} paddingY={0.5} >
                            <ToplineInformation />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <DemographicsChainView toggleGenerationAge={toggleGenerationAge} onToggleGenerationAgeChange={onToggleGenerationAgeChange} />

                        <Contactability />
                    </Grid>

                    <Grid item xs={12} sx={{ backgroundColor: '#e0e0e0' }}>
                        <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem" }} mt={0.5}>
                            <Grid container alignItems={'center'} columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} >
                                <Grid item xs sm={1} ml={2.5}>
                                    <Typography variant="h6"> View By </Typography>
                                </Grid>
                                <Grid item xs={12} sm={7} mx={windowSize.isOnMobile && 2}>
                                    <GroupSelector options={VIEW_BY}
                                        onSelectGroupChange={(value) => setViewBy(value)} //strings value
                                        selected={viewBy} />
                                </Grid>
                            </Grid>
                        </Box>
                        <RenderCustomerChainViewContent viewBy={viewBy} />
                    </Grid>
                </TogglePeriodProvider>
            </FiltersProvider>
        </Grid>
    </>
}