import { useQuery, useInfiniteQuery } from 'react-query';
import * as turf from '@turf/turf';
import ApiClient from '../../../../../../common/API';
import { USE_QUERY_DEFAULT_OPTIONS } from '../../../../../../Constants/settings';
import { parsePolygon, pointInPolygon } from './utils';

export const SMAC_LOCATIONS_TABLE = 'smip_customer_locations';
export const ISOCHRONE_TABLE = 'smip_tss_isochrones';
export const BARANGAY_TABLE_V1 = 'smip_barangay_geometries';
export const TABLE_BARANGAY_INTERSECTIONS = 'smip_isochrone_barangay_intersections'

const colors = [];
const barangayRows = [];
const barangays_ids = [];
const barangays = []
const cities = [];
const regions = [];

export const allRegions = ['CAR', 'Central Visayas', 'BARMM', 'Western Visayas', 'Bicol Region', 'Eastern Visayas', 'CALABARZON', 'Central Luzon', 'Cagayan Valley', 'MIMAROPA', 'Northern Mindanao', 'SOCCSKSARGEN', 'Ilocos Region', 'Davao Region', 'Caraga']; // no zamboanga peninsula - region 9 and NCR (intentional)

export const mall_colors = new Map();

export function useLocations({ cityName, provinceName }) {
    const { data: locations_data, isLoading: is_loading_locations_data } = useInfiniteQuery(
        ['customer_locations', cityName],
        ({ pageParam = 1 }) => {
            if (cityName===undefined || provinceName===undefined) {
                return Promise.resolve({ data: undefined, isLoading: true });
            }

            return ApiClient().get(`data/table/${SMAC_LOCATIONS_TABLE}/query/`, {
                params: {
                    limit: 1,
                    offset: (pageParam - 1) * 5000,
                },
            }).then((res) => res.data.data);
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            getNextPageParam: (lastPage, allPages) => (lastPage.length > 0 ? allPages.length + 1 : null),
        },
    );

    const filtered_data = locations_data?.pages.flat()

    if (filtered_data?.length === 1) {
        return filtered_data[0];
    }

    return { data: filtered_data, isLoading: is_loading_locations_data };
}

export function useFilteredHomebranchLocations(mall_names, cityName) {
    const { data: locations_data, isLoading: smac_members_is_loading } = useLocations({ column_name: 'city_name', value: cityName });

    const filtered_homebranch_data = locations_data?.filter((row) => mall_names.includes(row.home_branch));

    const colored_filtered_homebranch_data = filtered_homebranch_data?.map((row) => {
        const new_row = { ...row };
        if (mall_names.includes(row.home_branch)) {
            if (!mall_colors.has(row.home_branch)) {
                mall_colors.set(row.home_branch, generateDistinctColor());
            }

            const color = mall_colors.get(row.home_branch);
            new_row.color = color;
        }
        return new_row;
    });

    // generate unique colors for each encompassed mall
    mall_names?.forEach((mall) => {
        if (!mall_colors.has(mall)) {
            mall_colors.set(mall, generateDistinctColor());
        }
    });
    return { data: colored_filtered_homebranch_data, isLoading: smac_members_is_loading };
}

export function useCrossFilteredLocations({ mall_names, card_types, store }) {
    // disabled as markers are not required
    const { data: locations_data, isLoading: is_loading_locations_data } = useInfiniteQuery(
        ['cross_filtered_data', barangayRows, mall_names],
        ({ pageParam = 1 }) => {
            if ((cities?.length===0 && regions?.length===0) && mall_names===undefined) {
                return Promise.resolve({ data: undefined, isLoading: true });
            }

            return ApiClient().get(`data/table/${SMAC_LOCATIONS_TABLE}/query/`, {
                params: {
                    limit: 1,
                    offset: (pageParam - 1) * 5000,
                },
            }).then((res) => res.data.data);
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.length > 0 ? allPages.length + 1 : null;
            }            
        },
    );
    const barangaysLoading = barangayRows.length===0 || barangays_ids.length===0

    const joinedData = locations_data?.pages.flat().map(otherRow => {
        if(barangayRows.length > 0) {
            const matchingRow = barangayRows?.find(row =>
                row.city_name === otherRow.city_name &&
                row.barangay_name === otherRow.barangay_name &&
                row.region_name === otherRow.region_name
            );
        
            if (matchingRow) {
                return {
                    ...otherRow,
                    barangay_id: matchingRow.barangay_id
                };
            } else {
                return {
                    ...otherRow,
                    barangay_id: -999
                };
            }
        }
    })?.filter(row => barangays_ids?.includes(row?.barangay_id));

    const filtered_data = joinedData

    const updatedFilteredData = filtered_data //card_types.length > 0 ? filtered_data?.filter((row) => card_types.includes(row.smac_cdp_card_type)) : filtered_data;

    const updatedFilteredDataWithColors = mall_names && mall_names?.length > 0 && card_types && card_types?.length === 0 ?
        updatedFilteredData?.map((row) => {
            const new_row = { ...row };
            if (mall_names.includes(row.home_branch)) {
                if (!mall_colors.has(row.home_branch)) {
                    mall_colors.set(row.home_branch, generateDistinctColor());
                }

                const color = mall_colors.get(row.home_branch);
                new_row.color = color;
            }
            return new_row;
        }) : updatedFilteredData

    if (mall_names && mall_names?.length > 0) {
        mall_names.forEach((mall) => {
            if (!mall_colors.has(mall)) {
                mall_colors.set(mall, generateDistinctColor());
            }
        });
    }

    return { data: undefined, isLoading: undefined, barangaysIsLoading: undefined };
}


export function useCityPopulationV1({ city_name, store, regionName, defaultIsochrone }) {
    const isochroneTime = defaultIsochrone?.length === 0 ? 0 :(defaultIsochrone.includes(600) ? 600 : 300);

    // Get merged barangay and isochrone tables
    const { data: intersectingBarangays, isLoading: intersectingBarangaysIsLoading } = useInfiniteQuery(
        ['intersecting_barangays', store, isochroneTime],
        ({ pageParam = 1 }) => {

            return ApiClient().get(`data/table/${TABLE_BARANGAY_INTERSECTIONS}/query/`, {
                params: {
                    columns: 'barangay_key,travel_time',
                    store_name: store,
                    travel_time: isochroneTime,
                    limit: 10000,
                    offset: (pageParam - 1) * 1000,
                },
            }).then((res) => res.data.data);
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            getNextPageParam: (lastPage, allPages) => (lastPage.length === 1000 ? allPages.length + 1 : null),
        },
    );
    
    const intersectingBarangaysArr = intersectingBarangays?.pages?.flat()

    // Add the barangay_keys to the global barangays_ids list, 
    if (barangays_ids.length === 0 && intersectingBarangaysArr !== undefined) {
        const barangayIDs = intersectingBarangaysArr?.map(row => row.barangay_key).filter(row => row !== undefined)
        if (barangayIDs?.length !== 0) { 
            barangays_ids.push(...barangayIDs)
        }
    }

    // filter the barangay table which are in the barangays_ids list 
    const { data: city_pop_data, isLoading: is_loading } = useQuery(
        ['city_population', regionName, barangays_ids, isochroneTime],
        () => {
            const barangayColumns = ['geometry', 'city_name', 'barangay_name', 'region_name', 'barangay_key', 'province_name', 'smac_active_p6m', 'smac_member_count', 'smac_penetration_rate', 'population_projected_2024']

            if (barangays_ids?.length === 0 || isochroneTime === 0) {
                 return undefined
            }

            return ApiClient().get(`data/table/${BARANGAY_TABLE_V1}/query/`, {
                params: {
                    columns: barangayColumns.join(','),
                    barangay_key__in: barangays_ids.join(','),                    
                    limit: 700,
                },
            }).then((res) => res.data.data);
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        },
    );
    
    // add new columns to the table, barangay_id, percentage of P6M data, and fixed smac_penetration_rate contents
    const city_pop_data_with_ids = city_pop_data?.flat().map((row, index) => {
        const p6mRate = row.smac_active_p6m / row.smac_member_count
        const p6mRateFixed = isNaN(row.smac_active_p6m / row.smac_member_count) ? 0 : p6mRate;
        return {...row, 
                barangay_id: index + 1, 
                '%P6M': p6mRateFixed, 
                'smac_penetration_rate': (parseFloat(row.smac_penetration_rate) > 1 ? 1 : parseFloat(row.smac_penetration_rate))};
    });
    
    if (barangayRows.length===0 && city_pop_data_with_ids!==undefined) {
        barangayRows.push(...city_pop_data_with_ids)
    }
    
    const params = {
        'store_name': store,
        limit: 10,
        'travel_time': isochroneTime,
    }
    const { data: isochrone, isLoading: isochrone_is_loading } = useQuery(
        ['isochrone', store, isochroneTime],
        () => ApiClient().get(`data/table/${ISOCHRONE_TABLE}/query/`, {
            params: params
        }).then((res) => res.data.data),
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        },
    );

    const loadingAllTables = is_loading || intersectingBarangaysIsLoading || isochrone_is_loading

    if (isochrone === undefined || isochrone?.length === 0 || city_pop_data===undefined) {
        return { data: undefined, 
                filtered_table: undefined, 
                isLoading: loadingAllTables, 
                isochroneGeometry: undefined };
    }
    
    // Parse isochrone to get actual polygon
    const isochroneGeometry = isochrone !== undefined ? turf.polygon([parsePolygon(isochrone[0].geometry)]) : []
    
    const city_pop_promise = city_pop_data_with_ids?.flat();
    if (city_pop_promise?.length === 1) {
        return city_pop_promise[0];
    }

    const filtered_data = city_pop_data_with_ids?.flat()

    // filter the data based on whether the city polygon intersects with the isochroneGeometry
    const smac_penetration_table = filtered_data?.filter((row) => {
        const newGeometry = turf.polygon([parsePolygon(row.geometry)]);

        return turf.booleanIntersects(isochroneGeometry, newGeometry)
    });

    // Percentiles are needed to classify polygons with respective colors
    // calculate the percentile of an array
    Math.percentile = function (arr, q) {
        const sorted = arr.slice().sort((a, b) => a - b);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
            return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
            return sorted[base];
        }
    };

    function calculateQuantiles(data, numQuantiles) {
        if (data === undefined) return [];
        const sortedData = data?.slice().sort((a, b) => a - b);

        const quantileSize = Math.floor(sortedData.length / numQuantiles);

        const quantiles = [];

        for (let i = 1; i < numQuantiles; i++) {
            quantiles.push(sortedData[i * quantileSize]);
        }

        quantiles.push(sortedData[sortedData.length - 1]);

        return quantiles;
    }
    // filters the data based on whether the geometry intersects with the isochroneGeometry
    // It then maps the smac_penetration_rate values to a new array
    const penetrationRates = filtered_data?.filter(row => {
        const newGeometry = turf.polygon([parsePolygon(row.geometry)]);

        if (turf.booleanIntersects(isochroneGeometry, newGeometry)) {
            return row.city_name === city_name
        }
    }).map(row => row.smac_penetration_rate);

    const quantiles = calculateQuantiles(penetrationRates, 4);

    // map over the filtered_data and updates the polygon_color, polygon_stroke, fillOpacity, and weight properties based on the quantile range the smac_penetration_rate falls into
    const updated_filtered_data = filtered_data?.map(row => {
        const new_row = { ...row };
        const newGeometry = turf.polygon([parsePolygon(row.geometry)]);

        if (turf.booleanIntersects(isochroneGeometry, newGeometry)) {
            const quantile = quantiles.findIndex(q => row.smac_penetration_rate <= q);
            
            const darkness = Math.floor(((3 - quantile) / 3) * 255);
            const lightGreen = `#A0FF9A`;
            const darkGreen = `#033800`;

            const r = Math.floor((darkness / 255) * parseInt(lightGreen.substring(1, 3), 16) + ((255 - darkness) / 255) * parseInt(darkGreen.substring(1, 3), 16));
            const g = Math.floor((darkness / 255) * parseInt(lightGreen.substring(3, 5), 16) + ((255 - darkness) / 255) * parseInt(darkGreen.substring(3, 5), 16));
            const b = Math.floor((darkness / 255) * parseInt(lightGreen.substring(5, 7), 16) + ((255 - darkness) / 255) * parseInt(darkGreen.substring(5, 7), 16));

            const hexColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

            new_row.polygon_color = hexColor;
            new_row.polygon_stroke = '#FFFFFF';
            new_row.fillOpacity = 0.7;
            new_row.weight = 1.4;
        } else {
            new_row.polygon_color = 'transparent';
            new_row.polygon_stroke = '#EAEAEA';
            new_row.fillOpacity = 0;
            new_row.weight = 0.8;
        }
        return new_row;
    });

    // select which columns to display
    const updated_smac_penetration_table = filtered_data !== undefined ? (() => {
        return smac_penetration_table?.map((row) => {
            const columnsToDisplay = ['barangay_name', 'smac_member_count', 'population_projected_2024', 'smac_penetration_rate', '%P6M'];
            const selectedColumns = {};

            for (const key in row) {
                if (columnsToDisplay.includes(key)) {
                    selectedColumns[key] = row[key];
                }
            }

            return selectedColumns;
        });
    })() : smac_penetration_table;

    // fill cities, and region arrays for `smac_customer_locations` query
    // Unneeded for now
    if (barangays?.length === 0 && updated_filtered_data !== undefined) {
        const regionArray = updated_filtered_data?.filter(row => {
            return row.polygon_color !=='#FFFFFF'
    })?.map(obj => obj['barangay_name'])
        const set = new Set(regionArray);
        const uniqueArray = Array.from(set);
        barangays.push(...uniqueArray)
    }

    if (cities?.length === 0 && updated_filtered_data !== undefined) {
        const regionArray = updated_filtered_data?.filter(row => {
            return row.polygon_color !=='#FFFFFF'
    })?.map(obj => obj['city_name'])
        const set = new Set(regionArray);
        const uniqueArray = Array.from(set);
        cities.push(...uniqueArray)
    }
    
    if (regions?.length === 0 && updated_filtered_data !== undefined) {
        const regionArray = updated_filtered_data?.filter(row => {
            return row.polygon_color !=='#FFFFFF'
    })?.map(obj => obj['region_name'])
        const set = new Set(regionArray);
        const uniqueArray = Array.from(set);
        regions.push(...uniqueArray)
    }
    
    return { data: updated_filtered_data, 
        filtered_table: updated_smac_penetration_table, 
        isLoading: loadingAllTables, 
        isochroneGeometry: isochroneGeometry };
}

export function useIsochrones({ mall_names, times }) {
    const { data: isochrone, isLoading: isochrone_is_loading } = useQuery(
        ['isochrone'],
        () => {
            return ApiClient().get(`data/table/${ISOCHRONE_TABLE}/query/`, {
                params: {
                    limit: 200,
                },
            }).then((res) => res.data.data)},
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        },
    );
    const filtered_isochrones = isochrone?.filter((row) => mall_names?.includes(row.store_name));

    const updatedFilteredIsochrones = times?.length > 0 ?
        filtered_isochrones?.filter((row) => times?.includes(row.travel_time)) :
        filtered_isochrones;

    const colored_filtered_isochrones = updatedFilteredIsochrones?.map((row) => {
        const new_row = { ...row };
        if (row.travel_time === 600) {
            new_row.polygon_color = 'white';
            new_row.polygon_stroke = 'orange';
            new_row.fillOpacity = 0.0;
        } else {
            new_row.polygon_color = 'white';
            new_row.polygon_stroke = 'yellow'; // CECFFF
            new_row.fillOpacity = 0.0;
        }
        return new_row;
    });

    return { data: colored_filtered_isochrones, isLoading: isochrone_is_loading };
}


export function useMallCoordinates(mall_names, cityName) {
    useFilteredHomebranchLocations(mall_names, cityName);
    const { data: isochrone, isLoading: isochrone_is_loading } = useQuery(
        ['isochrone'],
        () => ApiClient().get(`data/table/${ISOCHRONE_TABLE}/query/`, {
            params: {
                limit: 200,
            },
        }).then((res) => res.data.data),
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        },
    );
    const filtered_isochrones = isochrone?.flat()
        .filter((row, index) => {
            const mallName = row.store_name;

            return mall_names?.includes(mallName)
            && (row.travel_time === 300 || row.travel_time === 600);
        });

    const mergedFilteredIsochrones = filtered_isochrones?.map((filteredSet) => {
        const mallName = filteredSet.store_name;

        if (mall_colors.size > 0) {
            const additionalSet = mall_colors.get(mallName);

            if (additionalSet) {
                filteredSet.color = additionalSet;
                return filteredSet;
            }
        }
    }).filter((item) => item !== undefined);

    const finalMergedFilteredIsochrones = mall_names?.length === 1 && mergedFilteredIsochrones !== undefined ?
        [mergedFilteredIsochrones[0]] :
        mergedFilteredIsochrones;

    return { data: finalMergedFilteredIsochrones, isLoading: isochrone_is_loading };
}


export function useEncompassingMalls(mall_name) {
    const { data: isochrone, isLoading: isochrone_is_loading } = useQuery(
        ['isochrone'],
        () => ApiClient().get(`data/table/${ISOCHRONE_TABLE}/query/`, {
            params: {
                limit: 200,
            },
        }).then((res) => res.data.data),
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        },
    );

    const target_mall = isochrone?.find((row) => row.store_name === mall_name && row.travel_time === 900);

    const intersecting_malls = target_mall !== undefined ? (() => {
        return isochrone?.map((row) => {
            const point = turf.point([target_mall.longitude, target_mall.latitude]).geometry.coordinates;

            if (row.travel_time === 900) {
                const polygon = parsePolygon(row.geometry, true);

                if (pointInPolygon(point, polygon)) {
                    return row;
                }
            }
        }).filter((item) => item !== undefined);
    })() : undefined;

    const list_intersecting_malls = intersecting_malls?.map((row) => row.store_name)
        .filter((item) => item !== undefined)
        .filter((value, index, self) => self.indexOf(value) === index);

    return list_intersecting_malls;
}


export function useCityAndProvince(mall_name) {
    const { data: isochrone, isLoading: isochrone_is_loading } = useQuery(
        ['isochrone'],
        () => ApiClient().get(`data/table/${ISOCHRONE_TABLE}/query/`, {
            params: {
                limit: 200,
            },
        }).then((res) => res.data.data),
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        },
    );

    const target_mall = isochrone?.find((row) => row.store_name === mall_name);

    const POINTcoordinates = `POINT(${target_mall?.longitude} ${target_mall?.latitude})`
    // sample output: POINT(121.057239 14.584488)

    const { data: storeLocation, isLoading: storeLocationIsLoading } = useQuery(
        ['all_city_population', POINTcoordinates],
        () => { 
            if (target_mall === undefined) {
                return undefined
            }
            return ApiClient().get(`data/table/${BARANGAY_TABLE_V1}/query/`, {
            params: {
                geometry__st_contains: POINTcoordinates,
            },
        }).then((res) => res.data.data)},
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
        },
    );

    return { intersecting_location: storeLocation?.length > 0 ? storeLocation[0] : undefined, 
             mall_coordinates: { latitude: target_mall?.latitude, longitude: target_mall?.longitude },
             isLoading: storeLocationIsLoading }
}


// COLOR UTILS
function getRandomColor() {
    const getRandomComponent = () => Math.floor(Math.random() * 256);

    function isGrayScale(color) {
        const hex = color.substring(1);
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        return r === g && g === b;
    }

    let color;
    do {
        const red = getRandomComponent();
        const green = getRandomComponent();
        const blue = getRandomComponent();

        color = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
    } while (colors.includes(color) || isGrayScale(color));

    colors.push(color);
    return color;
}

function isColorCloseToMap(color) {
    const threshold = 300; // increase for bigger color difference
    for (const mallColor of mall_colors.values()) {
        const colorDiff = Math.abs(parseInt(color, 16) - parseInt(mallColor, 16));
        if (colorDiff < threshold) {
            return true;
        }
    }
    return false;
}

function generateDistinctColor() {
    let newColor;
    do {
        newColor = getRandomColor();
    } while (isColorCloseToMap(newColor));
    return newColor;
}
