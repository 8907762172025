import React from 'react';
import { Grid } from '@mui/material';
import FilterCompany from '../../../../common/components/FilterCompany';
import FilterMonth from '../../../../common/components/FilterMonth';
import FilterYear from '../../../../common/components/FilterYear';
import { useAvailableDateRange } from '../hooks';
import { TABLE_NAME as SALES_TABLE_NAME } from '../../Sales/hooks';
import { useFiltersContext } from '../provider/context';


export default function Filters() {
    const { filters, setFilters, handleCompanyFilterChange } = useFiltersContext();
    const { isLoading, isSuccess, data: dateRangeData } = useAvailableDateRange();

    function onDropdownFilterChange(key, value) {
        if (key === 'company_id') {
            // Handle company_id separately
            const selectedCompanies = Array.isArray(value) ? value : [value];

            handleCompanyFilterChange(selectedCompanies);
        } else {

            setFilters((prevFilters) => {
                const updatedFilters = {
                    ...prevFilters,
                    [key]: value,
                };

                return updatedFilters;
            });
        }
    }

    const handleCompanyChange = (selectedCompanies) => {
        // Update filters if necessary
        setFilters((prevFilters) => {
            const updatedCompanyIds = Array.isArray(selectedCompanies) ? selectedCompanies : [selectedCompanies];
            return {
                ...prevFilters,
                company_id: updatedCompanyIds,
            };
        });
    };

    return (
        <>
            <Grid item xs={12} sm={5}>
                <FilterCompany
                    tableName={SALES_TABLE_NAME}
                    filters={filters}
                    onPillSelectChange={(value) => onDropdownFilterChange('company_id', value)}
                    handleCompanyChange={handleCompanyChange}
                />
            </Grid>
            <Grid item xs={12} sm={1.5}>
                <FilterMonth
                    onDropdownChange={(value) => onDropdownFilterChange('month', value)}
                    filters={filters}
                    dateRangeData={{
                        from: dateRangeData?.transaction_date_min,
                        to: dateRangeData?.transaction_date_max,
                    }}
                    isSuccess={isSuccess}
                    dateRangeIsLoading={isLoading}
                />
            </Grid>
            <Grid item xs={12} sm={1.5}>
                <FilterYear
                    onDropdownChange={(value) => onDropdownFilterChange('year', value)}
                    filters={filters}
                    dateRangeData={{
                        from: dateRangeData?.transaction_date_min,
                        to: dateRangeData?.transaction_date_max,
                    }}
                    isSuccess={isSuccess}
                    dateRangeIsLoading={isLoading}
                />
            </Grid>
        </>
    );
}
