import { useContext, useState } from "react";
import GroupSelector from "../common/presentation/selectors/groupSelector";
import { Grid, Box, Collapse } from "@mui/material";
import CollapsibleComponent from "../../../common/components/CollapsibleComponent";
import TabContent from "./TabContent";
import ToplinePerformanceCharts from "./ToplinePerformanceCharts";
import {VIEW_BY, VIEW_BY_ACQUISITION, VIEW_BY_REGISTRATION, VIEW_BY_USAGE} from "./constants";
import BranchSelector from "./Selectors/BranchSelector";
import OrgranizationSelector from "./Selectors/OrganizationSelector";
import {useSelectorState} from "../common/providers/selectorProvider";
import { WindowSizeContext } from "../../../common/context/WindowSizeContext";

export default function TabSection({fixedTabValue, tabBlackList}) {
    const { isOnMobile } = useContext(WindowSizeContext);
    const [isExpanded, setIsExpanded] = useState(true);
    const [tabContentBy, setTabContentBy] = useSelectorState('mainTab', fixedTabValue || VIEW_BY_ACQUISITION) // set operations as default
    const isUsageTab = tabContentBy === VIEW_BY_USAGE
    const isMobileAndUsageTab = isOnMobile && isUsageTab

    return <Grid item xs={12} sx={{ backgroundColor: '#e0e0e0' }}>
        {
            !fixedTabValue && <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem", position: {sm: 'sticky', xs: 'static'}, top: '10rem', zIndex: 5 }}>
                <Grid container alignItems="center" columnSpacing={1} sx={{ paddingY: {xs: 1, md: 0.5}, paddingLeft: {xs: 2, md: 3} }}>

                    {/* Group Selector */}
                    <Grid item xs={11} sm={ isUsageTab ? 6.5 : 11.5 } sx={{ paddingBottom: {xs: isUsageTab && 2, md: 0} }}>
                        <GroupSelector
                            options={VIEW_BY.filter(tab => !tabBlackList.includes(tab.value))}
                            onSelectGroupChange={(value) => setTabContentBy(value)}
                            selected={tabContentBy}
                        />
                    </Grid>

                    {/* Collapsible Component for Mobile Usage Tab */
                        isMobileAndUsageTab && <>
                            <Grid item xs={1} sm sx={{ paddingBottom: 2, paddingX: 0.5 }}>
                                <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded} />
                            </Grid>
                        </>
                    }

                    {/* Organization and Branch Selectors for Usage Tab */
                        isUsageTab && <>
                            <Grid item xs={12} sm={2.5} sx={{ paddingX: {xs: 2, md: 0}, paddingBottom: {xs: 1, md: 0} }}>
                                <OrgranizationSelector />
                            </Grid>

                            <Grid item xs={12} sm={2.5} sx={{ paddingX: {xs: 2, md: 0}}}>
                                <BranchSelector />
                            </Grid>
                        </>
                    }

                    {/* Collapsible Component when not on Mobile AND Usage Tab */
                        !(isMobileAndUsageTab) && <>
                            <Grid item xs={1} sm justifyContent="flex-end" sx={{ paddingX: 0.5 }}>
                                <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        }
        
        <Collapse in={isExpanded}>
            <Grid container>                
                <ToplinePerformanceCharts selectedTab={tabContentBy}/>
            </Grid>
        </Collapse>

        <TabContent selectedTab={tabContentBy}/>
    </Grid>
    
}