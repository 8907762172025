import React, { useContext, useMemo } from 'react';
import TableComponentV2 from '../../../../common/components/TableComponentV2';
import { Box, Grid, IconButton, Typography } from '@mui/material/'
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { useOpexSummaryDataV2 } from '../hooks';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import ToggleMtdYtd from '../../Sales/toggleMtdYtd';
import BarIndicator from '../../common/presentation/barIndicator';
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { OperationTypeFilterContext } from "../contexts";
import {
    OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
    OPERATION_TYPE_OTHER_CONTROLLABLE,
    OPERATION_TYPE_OTHER_NON_CONTROLLABLE,
    OPERATION_TYPE_SYNONYMS,
    OPERATION_TYPE_TOTAL_CONTROLLABLE,
    OPERATION_TYPE_TOTAL_NON_CONTROLLABLE,
    OPERATION_TYPE_TOTAL_OPERATING_EXPENSES,
    TOTAL_OPEX_OPERATION_TYPES
} from "../constants";
import BaseNumericDisplay from "../../common/presentation/numericDisplays/baseNumericDisplay";
import { decimalToString } from "../../../../common/utils/numbers";

const IndicatorCellBox = (props) => (
    <Box sx={{ my: 1, width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
        {props.children}
    </Box>
);

const controllable = ['Salaries & Wages', 'Light & Water', 'Security', 'Repairs & Maintenance', 'Other Controllable Expense']
const nonControllable = ['Rent', 'Other Non-Controllable Expense'];

export default function OpexSummaryTable({ params, period, onToggleChange }) {

    const isMTD = period === 'mtd';

    const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] }

    const windowSize = useContext(WindowSizeContext)
    const {
        selected: selectedOperationType,
        toggle: toggleOperationType,
        setSelected: setSelectedOperationTypes
    } = useContext(OperationTypeFilterContext)

    const isOperationTypeSelected = (operationType) => {
        if (operationType in OPERATION_TYPE_SYNONYMS) {
            return selectedOperationType.includes(OPERATION_TYPE_SYNONYMS[operationType])
        } else {
            return selectedOperationType.includes(operationType)
        }
    }

    const toggleRowWithSubrows = (row) => {
        const subrowMetrics = row.subRows.map(subrow => subrow.metrics).map(metric => OPERATION_TYPE_SYNONYMS[metric] || metric)
        if (subrowMetrics.every(metric => isOperationTypeSelected(metric))) {
            // all items in subrow is selected, remove them all from the selection
            setSelectedOperationTypes(selectedOperationType.filter(opType => !subrowMetrics.includes(opType)))
        } else {
            const tmp = subrowMetrics.reduce((acc, metric) => {
                if (!isOperationTypeSelected(metric)) {
                    acc.push(metric)
                }
                return acc
            }, [...selectedOperationType])  // accumulator must be the array of already selected operation types

            setSelectedOperationTypes(tmp)
        }
    }

    const { finalData, ytdLossShrinkageValue, isLoading } = useOpexSummaryDataV2({ params: { ...paramsBasedOnPeriod } })

    const formatData = (data) => {

        const filteredData = data?.filter(item => item?.operation_type === OPERATION_TYPE_TOTAL_CONTROLLABLE || item?.operation_type === OPERATION_TYPE_TOTAL_NON_CONTROLLABLE
        ).sort((a, b) => (a?.operation_type === OPERATION_TYPE_TOTAL_CONTROLLABLE ? -1 : 0) + (b?.operation_type === OPERATION_TYPE_TOTAL_CONTROLLABLE ? 1 : 0));

        const filteredControllableData = data?.filter((item) => controllable.includes(item.operation_type))
        const filteredNonControllableData = data?.filter((item) => nonControllable.includes(item.operation_type))

        const mapSubData = row => {
            return {
                metrics: row.operation_type,
                current: row.opex_current,
                ly: row.opex_last_year,
                vsLy: row.opex_vs_last_year,
                budget: row.opex_budget,
                vsBudget: row.opex_vs_budget,
                percentToSales: row.opex_percent_to_sales,
            };
        };

        const controllableSubData = filteredControllableData?.sort((a, b) => controllable.indexOf(a.operation_type) - controllable.indexOf(b.operation_type)).map(mapSubData)
            .map((row) => ({
                ...row,
                metrics: row.metrics === OPERATION_TYPE_OTHER_CONTROLLABLE ? "Other Controllable" : row.metrics
            }))
        const nonControllableSubData = filteredNonControllableData?.map(mapSubData).sort((a, b) => {
            if (a.metrics === 'Rent') {
                return -1;
            } else if (b.metrics === 'Rent') {
                return 1;
            } else {
                return a.metrics.localeCompare(b.metrics);
            }
        }).map((row) => ({
            ...row,
            metrics: row.metrics === OPERATION_TYPE_OTHER_NON_CONTROLLABLE ? "Other Non Controllable" : "Rent"
        }))

        const firstSubData = filteredData?.map((row) => {
            const subData = {
                metrics: row.operation_type === OPERATION_TYPE_TOTAL_CONTROLLABLE ? "Controllable" : "Non Controllable",
                current: row.opex_current,
                ly: row.opex_last_year,
                vsLy: row.opex_vs_last_year,
                budget: row.opex_budget,
                vsBudget: row.opex_vs_budget,
                percentToSales: row.opex_percent_to_sales,
                subRows: row.operation_type === OPERATION_TYPE_TOTAL_CONTROLLABLE ? controllableSubData : nonControllableSubData
            }

            return subData;
        })

        const totalOpexData = data?.filter((item) => item.operation_type === OPERATION_TYPE_TOTAL_OPERATING_EXPENSES).map((row) => {
            const totalOpex = {
                metrics: 'Total OPEX',
                current: row.opex_current,
                actualSales: row.actual_sales,
                ly: row.opex_last_year,
                vsLy: row.opex_vs_last_year,
                budget: row.opex_budget,
                vsBudget: row.opex_vs_budget,
                percentToSales: row.opex_percent_to_sales,
                subRows: firstSubData || []
            }

            return totalOpex
        })

        const filteredLossAndShrink = data?.filter((item) => item.operation_type === OPERATION_TYPE_LOSSES_AND_SHRINKAGE)

        const lossesShrinkage = filteredLossAndShrink?.length > 0
            ? filteredLossAndShrink.map((row) => ({
                metrics: OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
                current: row.opex_current,
                actualSales: row.actual_sales,
                ly: row.opex_last_year,
                vsLy: row.opex_vs_last_year,
                budget: isMTD ? null : ytdLossShrinkageValue,
                vsBudget: isMTD ? null : (row.opex_current / ytdLossShrinkageValue) * 100,
                percentToSales: row.opex_percent_to_sales,
            }))
            : [{
                metrics: OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
                current: 0,
                actualSales: 0,
                ly: 0,
                vsLy: null,
                budget: 0,
                vsBudget: null,
                percentToSales: 0,
            }];

        const tableData = [...totalOpexData, ...lossesShrinkage]


        return tableData;
    }

    const opex_data = useMemo(() => [
        {
            id: 'metrics',
            header: 'Metrics',
            accessorKey: 'metrics',
            cell: ({ row, getValue }) => {
                const visibilityIconSelected = row.originalSubRows?.length > 0 ?
                    row.originalSubRows.map(subrow => subrow.metrics).every(metric => isOperationTypeSelected(metric)) :
                    isOperationTypeSelected(getValue())

                return <Box sx={{
                    marginLeft: `${row.depth * 2}rem`,
                    whiteSpace: 'nowrap',
                    fontWeight: row.depth === 0 || row.depth === 1 ? 'bold' : 'normal'
                }}>
                    {
                        row.depth === 1 ?
                            <VisibilityIcon
                                onClick={() => {
                                    toggleRowWithSubrows(row.original)
                                }}
                                sx={{ marginRight: '.5rem' }} fontSize='small'
                                color={visibilityIconSelected ? 'primary' : 'disabled'} /> : null
                    }
                    {
                        row.depth === 2 ?
                            <VisibilityIcon
                                sx={{ marginRight: '.5rem' }} fontSize='small'
                                color={visibilityIconSelected ? 'primary' : 'disabled'} />
                            : null
                    }
                    {getValue()}
                    {row.getCanExpand() ?
                        <IconButton
                            size='small'
                            onClick={(e) => {
                                row.getToggleExpandedHandler()()
                                e.stopPropagation()
                            }}
                            sx={{ cursor: "pointer" }}>
                            {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize='small' /> :
                                <ArrowRightRoundedIcon fontSize='small' />}
                        </IconButton>
                        : ''}
                </Box>
            },
            customStyle: { width: '15rem' }
        },
        {
            header: period === 'mtd' ? 'MTD OPEX' : 'YTD OPEX',
            accessorKey: 'current',
            cell: ({ row, getValue }) => {
                return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}>
                    <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} />
                </Box>
            },
            customStyle: { width: '8rem', textAlign: 'left' }
        },
        {
            header: 'Last Year',
            accessorKey: 'ly',
            cell: ({ row, getValue }) => {
                return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}>
                    <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} />
                </Box>
            },
            customStyle: { width: '8rem', textAlign: 'left' }
        },
        {
            header: 'vs Last Year',
            accessorKey: 'vsLy',
            cell: (row) => <>
                <IndicatorCellBox>
                    <BarIndicator
                        colorFunctionFor={'opex'} value={row?.getValue()?.toFixed(1)} label="vs last year"
                        isInverted={true} isValueColorCoded={true} isOnTable={true} />
                </IndicatorCellBox>
            </>,
            customStyle: { width: '8rem', textAlign: 'left', verticalAlign: 'middle', whiteSpace: 'nowrap' }
        },
        {
            header: 'Budget',
            accessorKey: 'budget',
            cell: ({ row, getValue }) => {
                return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}>
                    <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} />
                </Box>
            },
            customStyle: { width: '8rem', textAlign: 'left' }
        },
        {
            header: 'vs Budget',
            accessorKey: 'vsBudget',
            cell: (row) => <>
                <IndicatorCellBox>
                    <BarIndicator
                        colorFunctionFor={'opex'} value={row?.getValue()?.toFixed(1)} label="vs last year"
                        isInverted={true} isValueColorCoded={true} isOnTable={true} />
                </IndicatorCellBox>
            </>,
            customStyle: { width: '8rem', textAlign: 'left', verticalAlign: 'middle', whiteSpace: 'nowrap' }
        },
        {
            header: period === 'mtd' ? 'MTD Sales' : 'YTD Sales',
            accessorKey: 'actualSales',
            cell: ({ row, getValue }) => {
                return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}>
                    <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} />
                </Box>
            },
            customStyle: { width: '8rem', textAlign: 'left' }
        },
        {
            header: '% to Sales',
            accessorKey: 'percentToSales',
            cell: ({ row, getValue }) => {
                const value = decimalToString(getValue(), { fixedDigits: 2 })
                return <Box sx={{ whiteSpace: 'nowrap' }}>
                    <BaseNumericDisplay value={value} postfix={'%'} />
                </Box>
            },
            customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
        },
    ], [period, windowSize, selectedOperationType])

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <Grid xs={12} sx={{ pb: 2 }}>
            <Grid xs={12}>
                <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                    <Grid container alignItems="center" py={.5}>
                        <Grid item xs={6} sm>
                            <Typography variant='h6' ml={2}>OPEX Accounts</Typography>
                        </Grid>
                        <Grid item sm xs display='flex' justifyContent='end'>
                            <ToggleMtdYtd
                                value={period}
                                onChange={onToggleChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            <Grid xs={12}>
                <TableComponentV2
                    data={formatData(finalData)}
                    columns={opex_data}
                    isCompact={true}
                    customTableHeaderStyle={{
                        backgroundColor: '#caddeb'
                    }}
                    isOpexAccounts={true}
                    enableRowSelection={true}
                    rowSelectionDepth={2}
                    onSelectRow={(row) => {
                        if (!row.subRows) {  // if row has no subrows, it is a leaf node
                            const metricName = OPERATION_TYPE_SYNONYMS[row.metrics] || row.metrics
                            if (TOTAL_OPEX_OPERATION_TYPES.includes(metricName)) {
                                toggleOperationType(metricName)
                            }
                        } else {
                            toggleRowWithSubrows(row)
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}