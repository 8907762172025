import ApiClient from "../../../../../../common/API";
import { useQuery } from "react-query";
import { USE_QUERY_DEFAULT_OPTIONS } from "../../../../../../Constants/settings";
import moment, { max } from "moment/moment";
import { preProcessParams, convertParamsToTransactionDate } from "../../../../TmpTSSSales/util";
import { preProcessParams as preProcessCustomerParams, convertParamsToTransactionDate as convertParamsToCustomerDate, convertParamsToTranDate } from "../../../../Customers/util";
import { useAvailableDateRange, useAvailableDateRangeL3, useSBUDataL3 } from "../../../../TmpTSSSales/DataProvider/hooks";
import {
    applyDeptAndSubdeptFilters,
    calculateBUMetricValues, calculateBUMetricValuesL3,
    calculateContributionOnNestedDeptSubdeptData,
    processBUData, processBUDataL3,
    transformData, transformDataL3
} from "./util.js";
import { rollupDataBy, titleCase } from "../../../../../../common/utils";
import { titleCaseString } from "../../../util";
import { TABLE_CUSTOMER_ATTRIBUTES, TABLE_CUSTOMER_METRICS, TABLE_VIEW_CUSTOMER_BEHAVIOR } from "../../../../Customers/constants.js";
import { useMemo } from "react";
import { METRICS_TABLE_NAME, POS_TABLE_NAME } from "../../../../TmpTSSSales/hooks.js";
import { startsWithAny } from "../../../../TmpTSSSales/DataProvider/util.js";

export const MERCH_TABLE_NAME = "l4_sbu_agg_bu_txn_merch";

export function useMerchData({ params }) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year;
    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;
    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const isFilteredByProductCategory = !!params && (
        (params.product_category && params.product_category.length > 0) ||
        (params.product_category__in && params.product_category__in.length > 0)
    )

    const aggregates = useMemo(() => {
        if (params?.aggregates) {
            return params?.aggregates
        }
        const generalAggregates = [
            "gross_sales_sum",
            "sales_plan_sum",
            "sales_units_sum",
            "smac_sales_sum",
            "ds_foot_traffic_sum",
            "mall_foot_traffic_sum",
            "foot_traffic_txn_count_sum",
            "net_selling_area_sum",
            "total_floor_selling_area_sum"
        ]

        if (isFilteredByProductCategory) {
            return [
                ...generalAggregates,
                'subdept_cat_txn_count_sum'
            ]
        }

        return [
            ...generalAggregates,
            "subdept_txn_count_sum",
        ]
    }, [params?.aggregates, isFilteredByProductCategory])

    const aggregatesAsString = Array.isArray(aggregates) ? aggregates.join(',') : aggregates


    const newParams = {
        ...params,
        columns: params?.group_by, // In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
    }
    if ('metric_column' in params) {
        delete newParams.metric_column;
    }

    const { data: currentBUData, isLoading: isLoadingCurrentBUData } = useQuery(
        [MERCH_TABLE_NAME, "stores", "overall", newParams, 'current'],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(newParams, dayOfMonthMaxDate)
            )
            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: aggregatesAsString,
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange
        }
    );

    const { data: lastYearBUData, isLoading: isLoadingLastYearBUData } = useQuery(
        [MERCH_TABLE_NAME, "stores", "overall", newParams, 'last_year'],
        () => {
            const lastYearParams = {
                ...newParams,
            }
            if (lastYearParams.year) {
                lastYearParams.year = lastYearParams.year - 1;
            }

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(
                    lastYearParams, dayOfMonthMaxDate
                )
            )
            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: aggregatesAsString,
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange
        }
    );

    return {
        currentBUData,
        lastYearBUData,
        isLoading: isLoadingCurrentBUData || isLoadingLastYearBUData
    }
}


export function useMerchDataL3({ params, queryOptions = {} }) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year;

    const isFilteredByProductCategory = !!params && (
        (params.product_category && params.product_category.length > 0) ||
        (params.product_category__in && params.product_category__in.length > 0)
    )

    // idea is to initialized default aggregates for each dataset table (pos and metrics)
    const aggregates = useMemo(() => {
        if (params?.aggregates) {
            return params?.aggregates
        }

        const posGeneralAggregates = [
            "pos_sku_gross_sales_sum",
            "pos_sku_sale_tot_qty",
            "pos_sku_smac_sales_sum",
        ]

        const metricsGeneralAggregates = [
            "sales_plan_sum",
            "ds_foot_traffic_sum",
            "mall_foot_traffic_sum",
            "foot_traffic_txn_count_sum",
            "net_selling_area_sum",
            "total_floor_selling_area_sum"
        ]


        if (isFilteredByProductCategory) {
            return [
                ...posGeneralAggregates,
                ...metricsGeneralAggregates,
                'subdept_cat_txn_count_sum'
            ]
        }

        return [
            ...posGeneralAggregates,
            ...metricsGeneralAggregates,
            "subdept_txn_count_sum",
        ]
    }, [params?.aggregates, isFilteredByProductCategory])

    const aggregatesAsString = Array.isArray(aggregates) ? aggregates.join(',') : aggregates


    const newParams = {
        ...params,
        columns: params?.group_by, // In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
    }
    if ('metric_column' in params) {
        delete newParams.metric_column;
    }

    const { data: currentBUData, isLoading: isLoadingCurrentBUData } = useSBUDataL3({
        params: {
            ...newParams,
            limit: 1000
        },
        queryOptions: {
            enabled: hasTransactionDate
        }
    })

    const lastYearParams = {
        ...newParams,
    }
    if (lastYearParams.year) {
        lastYearParams.year = lastYearParams.year - 1;
    }

    const { data: lastYearBUData, isLoading: isLoadingLastYearBUData } = useSBUDataL3({
        params: {
            ...lastYearParams,
            limit: 1000
        },
        queryOptions: {
            isQueryForVsLastYear: true,
            enabled: hasTransactionDate
        }
    })

    return {
        currentBUData,
        lastYearBUData,
        isLoading: isLoadingCurrentBUData || isLoadingLastYearBUData
    }
}

export function useMerchDeptTransactionsData({ params }) {
    // only runs when there is a metric_column set in the params (fixme: metric is not a GET param)
    // and if the metric_column has a transaction data involved

    const hasTransactionDate = params?.month?.length > 0 && !!params?.year;
    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;
    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const isTransactionInvolvedMetric = (
        params?.metric_column === 'actual_transactions' ||
        params?.metric_column === 'actual_basket_size' ||
        params?.metric_column === 'actual_units_sold_per_txn'
    );

    const isFilteredBySubdept = !!params && (
        (params.standardized_subdept && params.standardized_subdept.length > 0) ||
        (params.standardized_subdept__in && params.standardized_subdept__in.length > 0)
    )
    const isFilteredByProductCategory = !!params && (
        (params.product_category && params.product_category.length > 0) ||
        (params.product_category__in && params.product_category__in.length > 0)
    )

    // might need to create conditional set of aggregates for metric = Transactions
    const aggregates = useMemo(() => {
        if (params?.aggregates) {
            return params?.aggregates
        }
        if (isFilteredBySubdept) {
            if (isFilteredByProductCategory) {
                return ["gross_sales_sum", "sales_units_sum", "subdept_cat_txn_count_sum"] // aggregates if we are looking at both subdept and product category
            }
            return ["gross_sales_sum", "sales_units_sum", "subdept_txn_count_sum"] // aggregates if we are only looking at one subdept
        }
        if (isFilteredByProductCategory) {
            return ["gross_sales_sum", "sales_units_sum", "dept_cat_txn_count_sum_distinct"]   // aggregates if we are only looking at product category
        }
        return ["gross_sales_sum", "sales_units_sum", "dept_txn_count_sum"]   // this is the default for department level

    }, [params?.aggregates, isFilteredByProductCategory, isFilteredBySubdept])

    const aggregatesAsString = Array.isArray(aggregates) ? aggregates.join(',') : aggregates

    const viewName = useMemo(() => {
        if (params?.view) {
            return params?.view
        }
        if (isFilteredBySubdept || isFilteredByProductCategory) {
            return null
        }
        return 'dept_txns'
    }, [params?.view, isFilteredByProductCategory, isFilteredByProductCategory])


    const newParams = {
        ...params,
        columns: params?.group_by, // In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
    }
    if ('metric_column' in params) {
        delete newParams.metric_column;
    }

    const { data: currentDeptTxnData, isLoading: isLoadingCurrentDeptData } = useQuery(
        [MERCH_TABLE_NAME, "transactions", "overall", newParams, aggregatesAsString, 'current'],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(newParams, dayOfMonthMaxDate)
            )

            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    view: viewName,
                    columns: 'standardized_department',
                    group_by: 'standardized_department',
                    aggregates: aggregatesAsString,
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange && isTransactionInvolvedMetric
        }
    );

    const { data: lastYearDeptTxnData, isLoading: isLoadingLastYearDeptData } = useQuery(
        [MERCH_TABLE_NAME, "transactions", "overall", newParams, 'last_year'],
        () => {
            const lastYearParams = {
                ...newParams,
            }
            if (lastYearParams.year) {
                lastYearParams.year = lastYearParams.year - 1;
            }

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(
                    lastYearParams, dayOfMonthMaxDate
                )
            )
            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    view: viewName,
                    columns: 'standardized_department',
                    group_by: 'standardized_department',
                    aggregates: aggregatesAsString,
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange && isTransactionInvolvedMetric
        }
    );

    return {
        currentDeptTxnData,
        lastYearDeptTxnData,
        isLoadingDeptTxnData: isLoadingCurrentDeptData || isLoadingLastYearDeptData
    }
}


export function useMerchDeptTransactionsDataL3({ params }) {
    // only runs when there is a metric_column set in the params (fixme: metric is not a GET param)
    // and if the metric_column has a transaction data involved

    const hasTransactionDate = params?.month?.length > 0 && !!params?.year;

    const isTransactionInvolvedMetric = (
        params?.metric_column === 'actual_transactions' ||
        params?.metric_column === 'actual_basket_size' ||
        params?.metric_column === 'actual_units_sold_per_txn'
    );

    const isFilteredBySubdept = !!params && (
        (params.pm_subdept_desc_standardized && params.pm_subdept_desc_standardized.length > 0) ||
        (params.pm_subdept_desc_standardized__in && params.pm_subdept_desc_standardized__in.length > 0)
    )
    const isFilteredByProductCategory = !!params && (
        (params.pm_product_category_desc && params.pm_product_category_desc.length > 0) ||
        (params.pm_product_category_desc__in && params.pm_product_category_desc__in.length > 0)
    )

    // might need to create conditional set of aggregates for metric = Transactions
    const aggregates = useMemo(() => {
        if (params?.aggregates) {
            return params?.aggregates
        }
        if (isFilteredBySubdept) {
            if (isFilteredByProductCategory) {
                return ["pos_sku_gross_sales_sum", "pos_sku_sale_tot_qty_sum", "subdept_cat_txn_count_sum"] // aggregates if we are looking at both subdept and product category
            }
            return ["pos_sku_gross_sales_sum", "pos_sku_sale_tot_qty_sum", "subdept_txn_count_sum"] // aggregates if we are only looking at one subdept
        }
        if (isFilteredByProductCategory) {
            return ["pos_sku_gross_sales_sum", "pos_sku_sale_tot_qty_sum", "dept_cat_txn_count_sum_distinct"]   // aggregates if we are only looking at product category
        }
        return ["pos_sku_gross_sales_sum", "pos_sku_sale_tot_qty_sum", "dept_txn_count_sum"]   // this is the default for department level

    }, [params?.aggregates, isFilteredByProductCategory, isFilteredBySubdept])

    const aggregatesAsString = Array.isArray(aggregates) ? aggregates.join(',') : aggregates

    const viewName = useMemo(() => {
        if (params?.view) {
            return params?.view
        }
        if (isFilteredBySubdept || isFilteredByProductCategory) {
            return null
        }
        return 'dept_txns'
    }, [params?.view, isFilteredByProductCategory, isFilteredByProductCategory])


    const newParams = {
        ...params,
        columns: params?.group_by, // In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
    }
    if ('metric_column' in params) {
        delete newParams.metric_column;
    }

    const { data: currentDeptTxnData, isLoading: isLoadingCurrentDeptData } = useSBUDataL3({
        params: {
            ...newParams,
            columns: COLUMN_L3_DEPARTMENT,
            group_by: COLUMN_L3_DEPARTMENT,
            limit: 1000
        },
        queryOptions: {
            enabled: hasTransactionDate && isTransactionInvolvedMetric
        }
    });


    const lastYearParams = {
        ...newParams,
    }
    if (lastYearParams.year) {
        lastYearParams.year = lastYearParams.year - 1;
    }
    const { data: lastYearDeptTxnData, isLoading: isLoadingLastYearDeptData } = useSBUDataL3({
        params: {
            ...lastYearParams,
            columns: COLUMN_L3_DEPARTMENT,
            group_by: COLUMN_L3_DEPARTMENT,
            limit: 1000
        },
        queryOptions: {
            isQueryForVsLastYear: true,
            enabled: hasTransactionDate && isTransactionInvolvedMetric
        }
    });

    return {
        currentDeptTxnData,
        lastYearDeptTxnData,
        isLoadingDeptTxnData: isLoadingCurrentDeptData || isLoadingLastYearDeptData
    }
}


export function useBUPerDepartmentData({ params }) {
    const group1 = params?.group_by?.split(',')[0]
    const group2 = params?.group_by?.split(',')[1]
    const metric = params?.metric_column
    const isTransactionInvolvedMetric = (
        params?.metric_column === 'actual_transactions' || params?.metric_column === 'actual_basket_size' || params?.metric_column === 'actual_units_sold_per_txn'
    );

    // the following queries only works since we are using single select for both department and subdepartment filters
    // for future use, if we allow multi select, specially for transaction related metrics, we may need to adjust
    const cleanedParamsForDeptOrSubdeptQuery = {
        ...params
    }
    delete cleanedParamsForDeptOrSubdeptQuery['standardized_department']
    delete cleanedParamsForDeptOrSubdeptQuery['standardized_subdept']

    const {
        currentBUData,
        lastYearBUData,
        isLoading
    } = useMerchData({ params: cleanedParamsForDeptOrSubdeptQuery })

    const {
        currentDeptTxnData,
        lastYearDeptTxnData,
        isLoadingDeptTxnData
    } = useMerchDeptTransactionsData({ params: cleanedParamsForDeptOrSubdeptQuery })

    const metricTotal = calculateBUMetricValues(currentBUData, metric, group1, group2);
    const lastYearTotal = calculateBUMetricValues(lastYearBUData, metric, group1, group2);

    const processedBUData = processBUData(metricTotal, lastYearTotal, metric, currentBUData)

    const keysToSum = ['ds_foot_traffic_sum', 'gross_sales_sum', 'mall_foot_traffic_sum', 'sales_plan_sum', 'sales_units_sum', 'smac_sales_sum']
    // prioritize using the data fetched from the API for each department
    // however, the useMerchDeptTransactionsData only fetches data from the API if there is transaction involved in the metric selected
    const departmentDataCurrentYear = isTransactionInvolvedMetric ? currentDeptTxnData : rollupDataBy(currentBUData, [group1], keysToSum)
    const departmentDataLastYear = isTransactionInvolvedMetric ? lastYearDeptTxnData : rollupDataBy(lastYearBUData, [group1], keysToSum)

    const metricDeptTxnTotal = calculateBUMetricValues(departmentDataCurrentYear, metric, group1);
    const lastYearDeptTxnTotal = calculateBUMetricValues(departmentDataLastYear, metric, group1);

    const processedDeptTxnData = processBUData(metricDeptTxnTotal, lastYearDeptTxnTotal, metric, currentDeptTxnData)

    const spaceProdNSAPerDept = useMemo(() => {
        if (!currentBUData) {
            return []
        }

        const departments = currentBUData.reduce((acc, row) => {
            if (acc.includes(row.standardized_department)) {
                return acc
            }
            return [
                ...acc,
                row.standardized_department
            ]
        }, [])

        const summarizeDeptData = (data) => {
            if (!data) {
                return []
            }
            const summedData = departments.reduce((acc, dept) => {
                const subrows = data.filter(row => row.standardized_department === dept)
                const rolledUp = rollupDataBy(
                    subrows,
                    ['standardized_department'],
                    ['net_selling_area_sum', 'sales_plan_sum', 'gross_sales_sum', 'sales_units_sum']
                )
                return [
                    ...acc,
                    ...rolledUp
                ]

            }, [])
            return summedData
        }
        const current = summarizeDeptData(currentBUData)
        const lastYear = summarizeDeptData(lastYearBUData)

        const calculatedCurrent = calculateBUMetricValues(current, 'actual_space_nsa', 'standardized_department')
        const calculatedLastYear = calculateBUMetricValues(lastYear, 'actual_space_nsa', 'standardized_department')

        return processBUData(calculatedCurrent, calculatedLastYear, 'actual_space_nsa', currentBUData)
        // return {current, lastYear}
    }, [currentBUData, lastYearDeptTxnData])

    const departmentLevelData = useMemo(() => {
        if (metric === 'actual_space_nsa') {
            return spaceProdNSAPerDept  // space productivity is formula based. Return the calculated data
        }
        return processedDeptTxnData  // allow the transformData function to sum up the data
    }, [metric, processedDeptTxnData, spaceProdNSAPerDept])

    const unitPricePerDept = spaceProdNSAPerDept.reduce((acc, row) => {
        acc[row.standardized_department] = row.original.gross_sales_sum / row.original.sales_units_sum
        return acc
    }, {})

    const transformedData = transformData(processedBUData, departmentLevelData, unitPricePerDept)
    const perDeptDataWithContribution = calculateContributionOnNestedDeptSubdeptData(transformedData, metric)
    const filteredData = applyDeptAndSubdeptFilters(perDeptDataWithContribution, params.standardized_department, params.standardized_subdept)


    return {
        perDeptData: filteredData,
        isLoading: isLoading || isLoadingDeptTxnData
    }
}


export const COLUMN_L3_DEPARTMENT = 'pm_department_desc_standardized';
export const COLUMN_L3_SUBDEPT = 'pm_subdept_desc_standardized';

export function useBUPerDepartmentDataL3({ params }) {
    const group1 = params?.group_by?.split(',')[0]
    const group2 = params?.group_by?.split(',')[1]
    const metric = params?.metric_column
    const isTransactionInvolvedMetric = (
        params?.metric_column === 'actual_transactions' || params?.metric_column === 'actual_basket_size' || params?.metric_column === 'actual_units_sold_per_txn'
    );

    // the following queries only works since we are using single select for both department and subdepartment filters
    // for future use, if we allow multi select, specially for transaction related metrics, we may need to adjust
    const cleanedParamsForDeptOrSubdeptQuery = {
        ...params
    }
    delete cleanedParamsForDeptOrSubdeptQuery[COLUMN_L3_DEPARTMENT]
    delete cleanedParamsForDeptOrSubdeptQuery[COLUMN_L3_SUBDEPT]

    const {
        currentBUData,
        lastYearBUData,
        isLoading
    } = useMerchDataL3({ params: cleanedParamsForDeptOrSubdeptQuery })

    const {
        currentDeptTxnData,
        lastYearDeptTxnData,
        isLoadingDeptTxnData
    } = useMerchDeptTransactionsDataL3({ params: cleanedParamsForDeptOrSubdeptQuery })

    const metricTotal = calculateBUMetricValuesL3(currentBUData, metric, group1, group2);
    const lastYearTotal = calculateBUMetricValuesL3(lastYearBUData, metric, group1, group2);

    const processedBUData = processBUDataL3(metricTotal, lastYearTotal, metric, currentBUData)

    const keysToSum = ['ds_foot_traffic_sum', 'pos_sku_gross_sales_sum', 'mall_foot_traffic_sum', 'sales_plan_sum', 'pos_sku_sale_tot_qty_sum', 'pos_sku_smac_sales_sum']
    // prioritize using the data fetched from the API for each department
    // however, the useMerchDeptTransactionsData only fetches data from the API if there is transaction involved in the metric selected
    const departmentDataCurrentYear = isTransactionInvolvedMetric ? currentDeptTxnData : rollupDataBy(currentBUData, [group1], keysToSum)
    const departmentDataLastYear = isTransactionInvolvedMetric ? lastYearDeptTxnData : rollupDataBy(lastYearBUData, [group1], keysToSum)

    const metricDeptTxnTotal = calculateBUMetricValuesL3(departmentDataCurrentYear, metric, group1);
    const lastYearDeptTxnTotal = calculateBUMetricValuesL3(departmentDataLastYear, metric, group1);

    const processedDeptTxnData = processBUDataL3(metricDeptTxnTotal, lastYearDeptTxnTotal, metric, currentDeptTxnData)

    const spaceProdNSAPerDept = useMemo(() => {
        if (!currentBUData) {
            return []
        }

        const departments = currentBUData.reduce((acc, row) => {
            if (acc.includes(row[COLUMN_L3_DEPARTMENT])) {
                return acc
            }
            return [
                ...acc,
                row[COLUMN_L3_DEPARTMENT]
            ]
        }, [])

        const summarizeDeptData = (data) => {
            if (!data) {
                return []
            }
            const summedData = departments.reduce((acc, dept) => {
                const subrows = data.filter(row => row[COLUMN_L3_DEPARTMENT] === dept)
                const rolledUp = rollupDataBy(
                    subrows,
                    [COLUMN_L3_DEPARTMENT],
                    ['net_selling_area_sum', 'sales_plan_sum', 'pos_sku_gross_sales', 'pos_sku_sale_tot_qty']
                )
                return [
                    ...acc,
                    ...rolledUp
                ]

            }, [])
            return summedData
        }
        const current = summarizeDeptData(currentBUData)
        const lastYear = summarizeDeptData(lastYearBUData)

        const calculatedCurrent = calculateBUMetricValues(current, 'actual_space_nsa', COLUMN_L3_DEPARTMENT)
        const calculatedLastYear = calculateBUMetricValues(lastYear, 'actual_space_nsa', COLUMN_L3_DEPARTMENT)

        return processBUDataL3(calculatedCurrent, calculatedLastYear, 'actual_space_nsa', currentBUData)
        // return {current, lastYear}
    }, [currentBUData, lastYearDeptTxnData])

    const departmentLevelData = useMemo(() => {
        if (metric === 'actual_space_nsa') {
            return spaceProdNSAPerDept  // space productivity is formula based. Return the calculated data
        }
        return processedDeptTxnData  // allow the transformData function to sum up the data
    }, [metric, processedDeptTxnData, spaceProdNSAPerDept])

    const unitPricePerDept = spaceProdNSAPerDept.reduce((acc, row) => {
        acc[row[COLUMN_L3_DEPARTMENT]] = row.original.pos_sku_gross_sales / row.original.pos_sku_sale_tot_qty
        return acc
    }, {})

    const transformedData = transformDataL3(processedBUData, departmentLevelData, unitPricePerDept)
    const perDeptDataWithContribution = calculateContributionOnNestedDeptSubdeptData(transformedData, metric)
    const filteredData = applyDeptAndSubdeptFilters(perDeptDataWithContribution, params.pm_department_desc_standardized, params.pm_subdept_desc_standardized)

    return {
        perDeptData: filteredData,
        isLoading: isLoading || isLoadingDeptTxnData
    }
}


export function useAvailableMaxDate() {
    return useQuery(
        [MERCH_TABLE_NAME, 'stores', "as_of_date"],
        () => ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
            params: {
                aggregates: "transaction_date_max",
                limit: 1000
            }
        }).then(res => {
            const transactionDates = res.data?.data?.map(row => {
                return moment(`${row?.transaction_date_max}`).toDate()  // max date
            })
            return transactionDates?.[0] ?? null;
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
}

// useAvailableMaxDateL3 from L3_rsa_tss_pos dataset
export function useAvailableMaxDateL3() {
    return useQuery(
        [POS_TABLE_NAME, "stores", "as_of_date"],
        () => ApiClient().get(`data/table/${POS_TABLE_NAME}/query`, {
            params: {
                aggregates: "header_tran_date_max",
                limit: 1000
            }
        }).then(res => {
            const transactionDates = res.data?.data?.map(row => {
                return moment(`${row?.header_tran_date_max}`).toDate()  // max date
            })
            return transactionDates?.[0] ?? null;
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    )
}

export function useDepartmentPerformanceTrends({ params }) {

    const hasTransactionDate = params?.month?.length > 0 && !!params?.year;
    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;
    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const newParams = {
        ...params,
    }

    if ('metric_column' in params) {
        delete newParams.metric_column;
    }

    const { data: currentDeptData, isLoading } = useQuery(
        [MERCH_TABLE_NAME, "stores", "overall", newParams, 'trends'],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(newParams, dayOfMonthMaxDate)
            )
            return ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    year__in: [`${params?.year}`, `${params?.year - 1}`],
                    columns: 'year,standardized_department',
                    group_by: 'year,standardized_department',
                    aggregates: ["gross_sales_sum", "store_id_count_distinct"].join(','),
                    limit: 1000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange
        }
    );

    const data = currentDeptData && Object.values(currentDeptData?.reduce((result, { year, standardized_department, store_id_count_distinct, gross_sales_sum }) => {
        result[year] = { ...result[year], [standardized_department]: gross_sales_sum, year, store_id_count_distinct };
        return result;
    }, {}));

    const finalData = data?.map(({ year, ...rest }) => ({ year, ...rest }))

    // const currentAndLastYearData = finalData?.filter((data) => (data.year === `${params?.year}`) || (data.year === `${params?.year - 1}`))

    return {
        allData: finalData,
        // currentAndLastYearData,
        isLoading
    }
}

export function useAvailableProductCategories() {

    const { data: productCategoryData, isLoading } = useQuery(
        [MERCH_TABLE_NAME, 'stores', "product_categories"],
        () => ApiClient().get(`data/table/${MERCH_TABLE_NAME}/query/`, {
            params: {
                columns: "product_category",
                group_by: "product_category",
                gross_sales__gt: 0,  // consider only months with sales
                limit: 1000
            }
        }).then(res => { return res.data.data }),
        {
            ...USE_QUERY_DEFAULT_OPTIONS
        }
    );
    const productCategories = productCategoryData?.filter(item => item && item.standardized_business_unit !== null)
        .map(item => ({
            value: item.product_category,
            label: titleCaseString(item.product_category),
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

    return { productCategories, isLoading };
}

// useAvailableProductCategories implementation to L3 datasets
export function useAvailableProductCategoriesL3() {

    const { data: productCategoryData, isLoading } = useQuery(
        [POS_TABLE_NAME, 'stores', "product_categories"],
        () => ApiClient().get(`data/table/${POS_TABLE_NAME}/query/`, {
            params: {
                columns: "pm_product_category_desc",
                group_by: "pm_product_category_desc",
                gross_sales__gt: 0,  // consider only months with sales
                limit: 1000
            }
        }).then(res => { return res.data.data }),
        {
            ...USE_QUERY_DEFAULT_OPTIONS
        }
    );
    const productCategories = productCategoryData?.filter(item => item && item.pm_business_unit_desc_standardized !== null)
        .map(item => ({
            value: item.pm_product_category_desc,
            label: titleCaseString(item.pm_product_category_desc),
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

    return { productCategories, isLoading };
}

// default L3 dataset
export function useAvailableDepartment({ params }) {
    const { data, isLoading } = useQuery(
        [TABLE_CUSTOMER_ATTRIBUTES, "available_department", params],
        () => {
            const processedParams = preProcessCustomerParams(
                convertParamsToTranDate(params)
            )
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
                params: {
                    ...processedParams,
                    columns: 'pm_department_desc_standardized',
                    group_by: 'pm_department_desc_standardized',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data

            })
        }
    )
    return {
        data,
        isLoading
    }
}
// default L3 dataset
export function useAvailableSubDepartment({ params }) {
    const { data, isLoading } = useQuery(
        [TABLE_CUSTOMER_ATTRIBUTES, "available_sub_dept", params],
        () => {
            const processedParams = preProcessCustomerParams(
                convertParamsToTranDate(params)
            )
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
                params: {
                    ...processedParams,
                    columns: 'pm_subdept_desc_standardized',
                    group_by: 'pm_subdept_desc_standardized',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data

            })
        }
    )
    return {
        data,
        isLoading
    }
}

// default L3 dataset
export function useAvailableBrands({ params }) {
    const { data, isLoading } = useQuery(
        [TABLE_CUSTOMER_ATTRIBUTES, "available_brands", params],
        () => {
            const processedParams = preProcessCustomerParams(
                convertParamsToTranDate(params)
            )
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
                params: {
                    ...processedParams,
                    columns: 'final_brand',
                    group_by: 'final_brand',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data

            })
        }
    )
    return {
        data,
        isLoading
    }
}
// default L3 dataset
export function useAvailableAge({ params }) {
    const { data, isLoading } = useQuery(
        [TABLE_CUSTOMER_ATTRIBUTES, "available_age_groups", params],
        () => {
            const processedParams = preProcessCustomerParams(
                convertParamsToTranDate(params)
            )
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
                params: {
                    ...processedParams,
                    columns: 'age_bin',
                    group_by: 'age_bin',
                    limit: 1000
                }
            }).then(res => {
                return res.data.data

            })
        }
    )
    return {
        data,
        isLoading
    }
}
