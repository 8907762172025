import React from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField  from '@mui/material/TextField';


export default function RowCountSelector({rowCount, setRowCount}) {

    const options = [
        {value: 3, label: 3},
        {value: 5, label: 5},
        {value: 10, label: 10},
        {value: 20, label: 20}
    ]

    return (
        <>
            <Stack direction='row' spacing={1} width={{xs:'100%', md:'15%'}}>
                <Typography variant="subtitle2" sx={{alignSelf:'center',whiteSpace:'nowrap' }}> 
                    # of Rows:
                </Typography>

                <Autocomplete 
                    size='small'
                    options={options}
                    fullWidth
                    defaultValue={rowCount}
                    value={options.find(data => data.value === rowCount)}
                    onChange={(e,newValue) => setRowCount(newValue?.value)}
                    getOptionLabel={(option) => option.label.toString()}
                    renderInput={(params) => <TextField {...params} placeholder={'# of Rows'} />}
                />
            </Stack>
        </>
    )
}