import React from "react";
import * as PropTypes from "prop-types";
import UserMessage from "./UserMessage";
import Response from "./Response";
import CardComponent from "../../../../common/components/CardComponent";
import TreeMaps from "../../../../common/components/graphs/Treemaps";
import {TreemapsConfig} from "../../../../GraphConfig/TreemapConfig";
import TREEMAP_DATA from "../../../../mockdata/treemapData.json";
import RfmAnalytics from "../../CustomerAnalytics/RfmAnayltics";
import FootTrafficAnalysis from "../../CustomerAnalytics/FootTrafficAnalysis";
import GeoMap from "../../../../common/components/graphs/GeoMap";


export default function Conversation({conversationHistory}) {

    const buildResponseText = (message) => {
        if (!!message.response.message) {
            return <Response>{message.response.message}</Response>
        }
    }
    const buildResponseChart = (message) => {

        if (!!message.response.chart) {
            if (message.response.chart === "treemap") {
                return <CardComponent title={message.response.chartTitle} id="collapseTreemapCard">
                    <TreeMaps config={TreemapsConfig.Treemaps} data={TREEMAP_DATA}/>
                </CardComponent>
            } else if (message.response.chart === "rfm") {
                return <RfmAnalytics/>
            } else if (message.response.chart === "foot-traffic") {
                return <CardComponent title={message.response.chartTitle}
                                      id="collapseFootTrafficAnalysisCard" showCaret={true}>
                    <FootTrafficAnalysis/>
                </CardComponent>
            } else if (message.response.chart === "location") {
                return <CardComponent title={message.response.chartTitle} id="customerLocationCard">
                    <GeoMap/>
                </CardComponent>
            } else {
                return <i>Cannot render chart</i>
            }

        }
    }
    const buildResponse = (message) => {
        return <>
            {buildResponseText(message)}
            {buildResponseChart(message)}
        </>
    }

    return <div id="chatContainer">
        <h3>Conversation</h3>
        <div className="row">
            <div className="col">
                {
                    conversationHistory.map((message, index) => {
                        if (message.userMessage) {
                            return <UserMessage key={index}>{message.userMessage}</UserMessage>
                        } else {
                            return buildResponse(message)

                        }
                    })
                }


            </div>
        </div>

    </div>
}

Conversation.propTypes = {
    conversationHistory: PropTypes.array.isRequired,
}