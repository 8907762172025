import { useMemo, useState } from 'react';
import { useTable,useExpanded } from 'react-table'
import LineGraph from '../../../common/components/graphs/LineGraph';
import HorizontalStackedBarGraph from '../../../common/components/graphs/HorizontalStackedBarGraph';
import MockData from '../../../mockdata/footTrafficData.json';

function showColumns(selectedView,zoneTrend, setZoneTrend) {
	
	const MallsColumn = {
		id:'mall',
		Header: 'Malls',
		accessor: 'mall',
		Cell: ({row}) => {
			const caret = row.isExpanded ? <i className="bi bi-caret-down-fill me-2"></i> :  <i className="bi bi-caret-right-fill me-2"></i>
			const rowDisplay = <div className='d-flex '>  {caret} {row.values.mall } </div>
			return  row.canExpand ? (
				<span
				  {...row.getToggleRowExpandedProps()}
				>
				  { rowDisplay  }
				</span>
			  ) : <span >{ row.values.mall }</span>
		},
	}
	  
    const footTrafficColumns = {
		Header: 'Foot Traffic',
		columns: [
			// { id: "Unique", Header: "", columns: [ { Header: "Unique", accessor: "unique"}] },
			{ Header: "% Change",  columns: [
				{ Header: "1YA", accessor: "1ya" },
				{ Header: "2YA", accessor: "2ya" }
			]},
			// { id: "New", Header: "", columns: [ { Header: "New", accessor: "new"}] },
			// { id: "Repeat", Header: "", columns: [ { Header: "Repeat", accessor: "repeat"}] }
			{
				id: "New/Repeat", Header: "", columns: [
					{ Header: "New", accessor: "new"},
					{ Header: "Repeat", accessor: "repeat"}
				]
			}
		]
	  };
  
	  const dwellTimeColumns = {
		  Header: 'Dwell Time',
		  columns: [
			  { id:"Dwell Time", Header: "", columns: [
				  { Header :"x < 15mins", accessor: "15mins"  },
				  { Header :"15mins <= x < 1hr", accessor: "1hr" },
				  { Header :"1hr <= x < 2hrs", accessor: "2hrs" },
				  { Header :"2hr <= x < 3hrs", accessor: "3hrs" },
				  { Header :"3hrs <= x < 4hrs", accessor: "4hrs" },
				  { Header :"4hrs <= x < 5hrs", accessor: "5hrs" },
				  { Header :"6hrs <= x < 7hrs", accessor: "6hrs" },
				  { Header :"7hrs <= x < 8hrs", accessor: "7hrs" },
				  { Header :"=> 8hrs", accessor: "8hrs" }
				  ]
			  }
		  ]
	  }
	  
	  const demographicsColumns = {
		Header: 'Demographics',
		columns: [
			{ Header :"Gender", columns: [
					{ Header :"M", accessor: "male" },
					{ Header :"F", accessor: "female" },
					{ Header :"U", accessor: "gender_undefined" }
				]
			},
			{ Header :"Generation", columns: [
					{ Header :"Z", accessor: "z" },
					{ Header :"Millenial", accessor: "millenial" },
					{ Header :"X", accessor: "x" },
					{ Header :"Boomer", accessor: "boomer" },
					{ Header :"U", accessor: "generation_undefined" },
				]
			},
			{ Header :"Civil Status", columns: [
					{ Header :"S", accessor: "single" },
					{ Header :"M", accessor: "married" },
					{ Header :"W", accessor: "widow" },
					{ Header :"U", accessor: "civil_status_undefined" }
				]
			}
		]
	  };

	  const defaultBtnClass = "btn btn-secondary btn-sm rounded-pill px-3 border border-white";
	  const selectedBtnClass = "btn btn-danger btn-sm rounded-pill px-3 border border-white";
	  const trendsColumns = {
		Header: 'Trends',
		columns: [
			{
				Header: "",
				id:"trends",
				columns: [
					{
						id: "lineGraph",
						Header: ()=> {
							return <div className="d-flex justify-content-around">
								<button type="button" className={ zoneTrend === 'mall' ? "btn btn-success btn-sm rounded-pill px-3 border border-white" : defaultBtnClass } onClick={()=>setZoneTrend('mall')}>Mall</button>
								{/* <span className="badge bg-success rounded-pill px-3 fs-6 border border-white">Mall</span> */}
								<button type="button" className={ zoneTrend === 'zone1' ? selectedBtnClass : defaultBtnClass } onClick={()=>setZoneTrend('zone1')}>Zone 1</button>
								<button type="button" className={ zoneTrend === 'zone2' ? selectedBtnClass : defaultBtnClass } onClick={()=>setZoneTrend('zone2')}>Zone 2</button>
								<button type="button" className={ zoneTrend === 'zone3' ? selectedBtnClass : defaultBtnClass } onClick={()=>setZoneTrend('zone3')}>Zone 3</button>
							</div>
						},
						Cell: ({row}) => {
							return <LineGraph/>
						}

					},
					{
						Header: "Tenant Category",
						id: "tenantCategory",
						Cell: ({row}) => {
							return <HorizontalStackedBarGraph/>
						}
					}
				]
			}
		]
	  }

	  let columns = [
		{...MallsColumn},
		{...footTrafficColumns}
	  ]

	  if(selectedView === 'dwelltime') {
			columns = [
				...columns,
				{...dwellTimeColumns}

			]
	  }

	  if(selectedView === 'demographics') {
			columns = [
				...columns,
				{...demographicsColumns}

			]
	  }

	  if(selectedView === 'trends') {
		columns = [
			...columns,
			{...trendsColumns}

		]
	  }

	  return columns

}

export default function FootTrafficAnalysis() {


	const [selectedColumn, setSelectedColumn] = useState('dwelltime');
	const [granularity, setGranularity] = useState('day');
	const [zoneTrend, setZoneTrend]= useState('mall');
	
    const columnsToDisplay = useMemo(
        () => showColumns(selectedColumn,zoneTrend, setZoneTrend),
        [selectedColumn,zoneTrend]
      )

    const malls = [
        {  'Premiere' : ["SM City North Edsa", "SM Cherry Shaw", "SM Megamall", "SM Center Pasig", "The Podium", "SMAISON", "SM Mall of Asia", "SM Aura Premier", "SM Southmall", "MOA Square"] },
        {  'Central' : ["SM Cherry Congressional", "SM City Fairview", "SM City Novaliches", "SM City San Jose Del Monte", "SM City Manila", "SM City San Lazaro", "SM City Sta Mesa", "SM Center Angono", "SM City East Ortigas", "SM City Taytay", "SM Cherry Antipolo", "SM City Marikina", "SM City Masinag", "SM City San Mateo", "SM City Bicutan", "SM Center Las Pinas", "SM Center Muntinlupa", "SM City BF Paranaque", "SM City Sucat"] },
        {  'South' : ["SM City Dasmarinas", "SM City Molino", "SM City Bacoor", "SM Marketmall Dasmarinas", "SM Center Imus", "SM City Rosario", "SM City Trece Martires", "SM City Calamba", "SM City San Pablo", "SM City Sta Rosa", "SM Center Lemery", "SM City Lipa", "SM City Lucena", "SM City Batangas", "SM City Legazpi", "SM City Naga", "SM City Daet"] },
        {  'North' : ["SM City Baguio", "SM Center Dagupan", "SM City Rosales", "SM City Urdaneta Central", "SM City Cabanatuan", "SM City Cauayan", "SM Center Tuguegarao Downtown", "SM Megacenter Cabanatuan", "SM City Olongapo", "SM City Tarlac", "SM City Olongapo Central", "SM City Telabastagan", "SM City Clark", "SM City Pampanga", "SM City San Fernando Downtown", "SM City Baliwag", "SM City Marilao", "SM Center Pulilan", "SM Center Sangandaan", "SM Center Valenzuela", "Savemore Malabon", "SM City Grand Central"] },
        {  'Visayas' : ["SM Center Ormoc", "SM City Consolacion", "SM City Cebu", "SM Seaside City Cebu", "SM City Bacolod", "SM City Iloilo", "SM City Puerto Princesa"] },   
        {  'Mindanao' : ["SM CDO Uptown", "SM CDO Downtown Premier", "SM City Butuan", "SM City Davao", "SM Lanang Premier", "SM City General Santos", "SM Mindpro Citimall"] }   
    ];


    const data = useMemo(
        ()=> MockData, []
    )
    
    const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			rows,
			prepareRow,
		} = useTable(
		{	
			columns: columnsToDisplay,
			data,
		},
			useExpanded // Use the useExpanded plugin hook
		)


    return (
		<>
			<div className="d-flex">
				<div className="form-check form-check-inline">
					<input className="form-check-input" type="radio" name="columnViewOption" id="dwelltime" value="dwelltime" onChange={ (e)=> setSelectedColumn(e.target.value) }   checked={selectedColumn === 'dwelltime'}/>
					<label className="form-check-label" htmlFor="dwelltime">Dwell Time</label>
				</div>
				<div className="form-check form-check-inline">
					<input className="form-check-input" type="radio" name="columnViewOption" id="demographics" value="demographics" onChange={ (e)=> setSelectedColumn(e.target.value) } checked={selectedColumn === 'demographics'}/>
					<label className="form-check-label" htmlFor="demographics">Demographics</label>
				</div>
				<div className="form-check form-check-inline">
					<input className="form-check-input" type="radio" name="columnViewOption" id="trends" value="trends"  onChange={ (e)=> setSelectedColumn(e.target.value) } checked={selectedColumn === 'trends'}/>
					<label className="form-check-label" htmlFor="trends">Trends</label>
				</div>	
				
				{/* <div className="btn-group ms-auto"  role="group">
					<input type="radio" className="btn-check" name="dayOption" id="day" value="day" onChange= {(e)=> setGranularity(e.target.value) } checked={ granularity === 'day'}/>
					<label className="btn btn-sm btn-outline-primary px-3" htmlFor="day">Day</label>

					<input type="radio" className="btn-check" name="weekOption" id="week" value="week" onChange= {(e)=> setGranularity(e.target.value) } checked={ granularity === 'week'}/>
					<label className="btn btn-sm btn-outline-primary px-3" htmlFor="week">Week</label>

					<input type="radio" className="btn-check" name="monthOption" id="month" value="month" onChange= {(e)=> setGranularity(e.target.value) } checked={ granularity === 'month'}/>
					<label className="btn btn-sm btn-outline-primary px-3" htmlFor="month">Month</label>

					<input type="radio" className="btn-check" name="quarterOption" id="quarter" value="quarter" onChange= {(e)=> setGranularity(e.target.value) } checked={ granularity === 'quarter'}/>
					<label className="btn btn-sm btn-outline-primary px-3" htmlFor="quarter">Quarter</label>

					<input type="radio" className="btn-check" name="yearOption" id="year" value="year" onChange= {(e)=> setGranularity(e.target.value) } checked={ granularity === 'year'}/>
					<label className="btn btn-sm btn-outline-primary px-3" htmlFor="year">Year</label>

					<input type="radio" className="btn-check" name="dayOfWeekOption" id="dayOfWeek" value="dayOfWeek" onChange= {(e)=> setGranularity(e.target.value) } checked={ granularity === 'dayOfWeek'} disabled={ selectedColumn !== 'trends'}/>
					<label className="btn btn-sm btn-outline-primary px-3" htmlFor="dayOfWeek">Day of week</label>
				</div>				 */}
			</div>
			

			<div className="d-flex flex-row-reverse">
				<div className="py-2"> { new Date().toLocaleDateString('en-us', {  year: 'numeric', month: 'long', day: 'numeric' }) } </div>
			</div>

			<div className="table-responsive">
				<table className="rfm-analysis table table-sm align-middle" {...getTableProps()}>
					<thead className='bg-primary text-white'>
					{// Loop over the header rows
					headerGroups.map(headerGroup => (
						// Apply the header row props
						<tr {...headerGroup.getHeaderGroupProps()}>
						{// Loop over the headers in each row
						headerGroup.headers.map(column => (
							// Apply the header cell props
							<th className='text-center align-middle py-2' {...column.getHeaderProps()}>
							{// Render the header
							column.render('Header')}
							</th>
						))}
						</tr>
					))}
					</thead>
					{/* Apply the table body props */}
					<tbody  {...getTableBodyProps()}>
					{// Loop over the table rows
					rows.map(row => {
						// Prepare the row for display
						prepareRow(row)
						return (
						// Apply the row props
						<tr {...row.getRowProps()} className={row.canExpand ? 'parent-rows' : 'child-rows'}>
							{// Loop over the rows cells
							row.cells.map(cell => {
							// Apply the cell props
							return (
								<td  className={cell.column.id === 'mall' ? 'align-middle py-3' : 'default-cell'} {...cell.getCellProps()}>
								{// Render the cell contents
								cell.render('Cell')}
								</td>
							)
							})}
						</tr>
						)
					})}
					</tbody>
				</table>
			</div>
		</>
     
    )
}



 