import {useSBUStoreDataWithGrSwitchover} from "../../DataProvider/hooks";
import {useSelectorValue} from "../../../common/providers/selectorProvider";
import {calculateMetricPlanValuesL3, calculateMetricValuesL3, processDataL3} from "../../DataProvider/util";
import React, {useMemo} from "react";
import {createZoneDataFromStoreData, filterStoreDataByStoreID, useStoreDataWithMeta} from "./index";

export function useZoneStoreOverTimeData({params, storeIDWhitelist}) {

    const {
        storeDataCurrent,
        storeDataLastYear,
        isLoading
    } = useSBUStoreDataWithGrSwitchover({params})

    const {
        filteredDataCurrent,
        filteredDataLastYear,
    } = React.useMemo(() => {
        return {
            filteredDataCurrent: filterStoreDataByStoreID(storeDataCurrent, storeIDWhitelist),
            filteredDataLastYear: filterStoreDataByStoreID(storeDataLastYear, storeIDWhitelist),
        }
    }, [storeDataCurrent, storeDataLastYear, storeIDWhitelist])

    // regroup data by zone
    const {
        data: storeDataCurrentWithMeta,
        isLoading: isLoadingStoreDataCurrentWithMeta
    } = useStoreDataWithMeta({storeData: filteredDataCurrent})
    const {
        data: storeDataLastYearWithMeta,
        isLoading: isLoadingStoreDataLastYearWithMeta
    } = useStoreDataWithMeta({storeData: filteredDataLastYear})

    const zoneDataCurrent = createZoneDataFromStoreData(storeDataCurrentWithMeta)
    const zoneDataLastYear = createZoneDataFromStoreData(storeDataLastYearWithMeta)

    const selectedMetric = useSelectorValue('metric')  // this is from refactored metric which is moved to selectorProvider
    // the following metric uses whichever is available in the params or the selectedMetric
    const metric = params?.metric_column || selectedMetric// is deleted in useSBUStoreData()

    // calculate metric across time for store
    const storeMetricCurrentData = calculateMetricValuesL3(filteredDataCurrent, metric, 'store_parent_store_id');
    const storeMetricPlanData = calculateMetricPlanValuesL3(filteredDataCurrent, metric, 'store_parent_store_id');
    const storeMetricLastYearData = calculateMetricValuesL3(filteredDataLastYear, metric, 'store_parent_store_id');

    const perStoreData = processDataL3(
        storeMetricCurrentData,
        storeMetricPlanData,
        storeMetricLastYearData,
        {...params, group_by: 'store_parent_store_id'},
        Object.keys(storeMetricCurrentData),
        filteredDataCurrent
    );

    // calculate metric across time for zone
    const zoneMetricCurrentData = calculateMetricValuesL3(zoneDataCurrent, metric, 'store_zone');
    const zoneMetricPlanData = calculateMetricPlanValuesL3(zoneDataCurrent, metric, 'store_zone');
    const zoneMetricLastYearData = calculateMetricValuesL3(zoneDataLastYear, metric, 'store_zone');

    const perZoneData = processDataL3(
        zoneMetricCurrentData,
        zoneMetricPlanData,
        zoneMetricLastYearData,
        {...params, group_by: 'store_zone'},
        Object.keys(zoneMetricCurrentData),
        zoneDataCurrent
    );

    const shouldUpdate = useMemo( ()=> {
        if(params?.group_by === 'cm_channel_desc'){
            if ((params?.cm_channel_desc && params?.cm_channel_desc?.[0] === 'Gift Registry') && selectedMetric === 'actual_smac_sales' )  {
                return true
             }
         
             if ((params?.cm_channel_desc?.length === 1 && params?.cm_channel_desc?.[0] === 'Personal Shopper') && selectedMetric === 'actual_smac_sales'){
                return true
             }
         
             if ((params?.cm_channel_desc?.length === 1 && params?.cm_channel_desc?.[0] === 'Online') && selectedMetric === 'actual_smac_sales'){
                 return true
             }
     
             if ((params?.cm_channel_desc?.length === 1 && params?.cm_channel_desc?.[0] === 'Personal Shopper') && (selectedMetric === 'actual_transactions' || selectedMetric === 'actual_basket_size')){
                 return true
             }

             if ((params?.cm_channel_desc?.length === 1 && params?.cm_channel_desc?.[0] === 'Online') && (selectedMetric === 'actual_transactions' || selectedMetric === 'actual_basket_size')){
                return true
            }
        }
        
        return false
    },[params, selectedMetric])

    if (shouldUpdate) {
        const updateData = (data) => {
            data.forEach(obj => {
                obj.metricPlanTotal = 'N/A';
                obj.vsPlan = 'N/A';
            });
        };

        updateData(perStoreData);
        updateData(perZoneData);
    }

    const {
        data: perStoreDataWithMeta,
        isLoading: isLoadingPerStoreDataWithMeta
    } = useStoreDataWithMeta({storeData: perStoreData})

    const displayRows = React.useMemo(() => {
        if (!perStoreDataWithMeta || !perZoneData || perStoreDataWithMeta.length === 0 || perZoneData.length === 0) {
            return []
        }

        return perZoneData.map(zoneRow => {
            return {
                ...zoneRow,
                primary: zoneRow.store_zone,
                subRows: perStoreDataWithMeta.filter(storeRow => {
                    return storeRow.store_zone === zoneRow.store_zone
                }).map(storeRow => {
                    return {
                        ...storeRow,
                        primary: storeRow.store_parent_store
                    }
                })
            }
        })

    }, [perZoneData, perStoreDataWithMeta])

    return {data: displayRows, isLoading}
}