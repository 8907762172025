import React, {useContext} from "react";
import ConditionalWrapper from "../../../../common/components/ConditionalWrapper";
import FilterModel from "./FilterModel";
import FilterBrand from "./FilterBrand";
import FilterCategory from "./FilterCategory";
import FilterProduct from "./FilterProduct";
import {WindowSizeContext} from "../../../../common/context/WindowSizeContext";

import Grid from '@mui/material/Grid'
import Drawer from '@mui/material/Drawer'
import IconButton  from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close'


export default function Filters({onInputChange, filters, useProductFilter, isOpen, toggleDrawer}) {
    const windowSize = useContext(WindowSizeContext);

    return (

        <ConditionalWrapper condition={windowSize.isOnMobile} wrapper={
            children => (
                <Drawer 
                    anchor={'right'} 
                    open={isOpen}
                    ModalProps={{onBackdropClick: toggleDrawer}}
                    > 
                    <Grid sx={{width:'300px'}}>
                        <Grid container justifyContent={'flex-end'}>
                            <IconButton onClick={toggleDrawer}>
                                <CloseIcon sx={{fontSize:20}}/>
                            </IconButton>
                        </Grid>

                        <Grid sx={{px:'1rem', py:'1rem'}}>
                            {children}
                        </Grid>
                    </Grid>
                </Drawer>
            )
        }>

            <Grid container spacing={2}  justifyContent={`${useProductFilter ? 'flex-end' : ''}`}>
                    <Grid item xs={12} md={`${useProductFilter ? 3 : 6}`}>
                        <FilterCategory onDropdownChange={onInputChange} filters={filters}/>
                    </Grid>

                    <Grid item xs={12}  md={`${useProductFilter ? 3 : 6}`}>
                        <FilterBrand onDropdownChange={onInputChange} filters={filters}/>
                    </Grid>


                {
                    useProductFilter && 
                    <Grid item xs={12} md={3}>
                        <FilterProduct onDropdownChange={onInputChange} filters={filters}/>
                    </Grid>
                }
            </Grid>
        </ConditionalWrapper>

    )

}