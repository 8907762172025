import { GlobalStyles, Grid, Typography, Box} from '@mui/material'
import React, { useState, useContext, useEffect, useRef} from 'react'
import MerchandiseOveriew from './presentation/overview'
import Back from '../../../common/components/BackNavigateComponent'
import ToggleSelector from '../common/presentation/selectors/toggleSelector'
import MerchandiseDetailed from './presentation/detailed'
import { WindowSizeContext } from '../../../common/context/WindowSizeContext'
import FilterMonth from '../../../common/components/FilterMonth'
import FilterYear from '../../../common/components/FilterYear'
import FilterBusinessUnits from './Filters/BusinessUnit'
import FilterCluster from './Filters/Cluster'
import FilterZone from './Filters/Zone'
import FilterBranch from './Filters/Branch'
import ToggleMtdYtd from './ToggleMtdYtd'
import { useAvailableDateRange} from './hooks'

const ToggleOptions = [
    {label: 'Overview', value: 'overview'},
    {label: 'Detailed', value: 'detailed'}
]

export default function Merchandise() {

    const windowSize = useContext(WindowSizeContext);

    const [filters, setFilters] = useState({
        month: null,
        year: null,
        standardized_business_unit: null,
        store_id: null,
        zone: null,
        cluster: null,
        metric_column: 'actual_sales',
        sbu_higher_mbu: null
    })

    const params = {
        month: filters?.month,
        year: filters?.year,
        standardized_business_unit: filters?.standardized_business_unit,
        store_id: filters?.store_id,
        zone:filters?.zone,
        cluster: filters?.cluster,
        sbu_higher_mbu: filters?.sbu_higher_mbu,
    }

    const [period, setPeriod] = useState('mtd');

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setPeriod(val);
        }
    };

    const [toggleState, setToggleState] = useState('overview')

    const Content = toggleState === 'overview' ? <MerchandiseOveriew filters={filters} params={params} period={period}/> : 
    <MerchandiseDetailed filters={filters} params={params} period={period} onDropdownChange={onDropdownFilterChange} />;

    const { data: dateRangeV2, dateRangeIsLoading, isSuccess } = useAvailableDateRange()

    const dateRange = (dateRangeV2 && !dateRangeIsLoading) ? {
        from: dateRangeV2.min,
        to: dateRangeV2.max
    } : {};

    function onDropdownFilterChange(value, field) {
        setFilters({
            ...filters,
            [field]: value
        })
    }
    
    return <>
        <GlobalStyles
                styles={{
                    body: { backgroundColor: '#e0e0e0' },
                }}
        />
        <Grid container>
            <Grid item xs={12} marginY={2} mb={1}>
                <Grid container>
                    <Grid item sm={.75}> <Back /> </Grid>
                    <Grid item sm={5.25}>
                        <Typography variant='h5' >
                            Merchandise Analytics
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12} display='flex' justifyContent='end'>
                        <ToggleSelector 
                            options={ToggleOptions}
                            value={toggleState}
                            onChange={(e)=> setToggleState(e.target.value)}
                            additionalStyles={{ textTransform: 'capitalize' }}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container sx={{ position: `${!windowSize.isOnMobile ? 'sticky' : ''}`, top: `${!windowSize.isOnMobile ? '5rem' : ''}`, zIndex: 5, backgroundColor: 'transparent' }}>
                <Grid item xs={12} marginBottom={2}>
                    <Box flexGrow={1} flexDirection={'row'} xs={12} paddingX={1} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem" }}>
                        <Grid container columnSpacing={1}  rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} paddingX={windowSize.isOnMobile && 1}>
                            { toggleState === 'overview' && 
                            <Grid item xs={12} sm={2}>
                                <FilterBusinessUnits
                                    filters={filters}
                                    params={params}
                                    onDropdownChange={onDropdownFilterChange}
                                    />
                            </Grid>
                            }
                            <Grid item xs={12} sm={1.5}>
                                <FilterCluster
                                   filters={filters}
                                   params={params}
                                   onDropdownChange={onDropdownFilterChange}
                                    />
                            </Grid>

                            <Grid item xs={12} sm={1.5}>
                                <FilterZone
                                   filters={filters}
                                   params={params}
                                   onDropdownChange={onDropdownFilterChange}
                                    />
                            </Grid>

                            <Grid item xs={12} sm={1.5}>
                                <FilterBranch
                                   filters={filters}
                                   params={params}
                                   onDropdownChange={onDropdownFilterChange}
                                    />
                            </Grid>
                            
                            <Grid item xs={12} sm={1.5}>
                                <FilterMonth
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={isSuccess} dateRangeIsLoading={dateRangeIsLoading} 
                                    />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <FilterYear
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={isSuccess} dateRangeIsLoading={dateRangeIsLoading} 
                                    
                                    />
                            </Grid>

                            <Grid item sx={{marginLeft:'auto'}}>
                                <ToggleMtdYtd 
                                    value={period}
                                    onChange={onTogglePeriodChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            { Content }
        </Grid>
    </>
}