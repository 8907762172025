import React, { useContext, useState, useEffect } from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import MonthToDateTotalOpex from "./MonthToDateTotalOpex";
import YearToDateTotalOpex from "./YearToDateTotalOpex";
import OpexLegends from "../OpexLegends";
import { Grid, Typography, Box, Tab, Tabs } from '@mui/material';
import OpexSummaryTable from './OpexSummaryTable'
import Item from '../../../../common/components/ItemCard';
import AsOfDate from '../../Sales/Filters/AsOfDate';
import OpexCompanyTable from './OpexCompanyTable';

export default function OpexSummary({ params, period, onToggleChange }) {

    const windowSize = useContext(WindowSizeContext)

    return <>
        <Grid container>
            <Grid item xs={12}  >
                <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                    <Grid container alignItems="center" py={.75}>
                        <Grid item xs={12} sm>
                            <Typography variant="h6" ml={2.5} >OPEX Topline</Typography>
                        </Grid>
                        <Grid item xs={12} sm mx={2}>
                            <Box width={"100%"} sx={{ fontStyle: 'italic' }}>
                                <Typography variant="body2" textAlign={'end'} >
                                    Note: OPEX % relative to Net Sales (91% of Gross Sales).
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} >
                <Grid container  >
                    <Grid item xs={12} sm={6}>
                        <Item sx={{ borderRadius: `${windowSize.isOnMobile ? '0 0 .5rem.5rem' : '0 0 0 .5rem'}` }}>
                            <MonthToDateTotalOpex params={params} />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} my={windowSize.isOnMobile && 2}>
                        <Item sx={{ borderRadius: `${windowSize.isOnMobile ? '.5rem' : '0 0 .5rem 0'}` }}>
                            <YearToDateTotalOpex params={params} />
                        </Item>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <OpexCompanyTable params={params} period={period} onToggleChange={onToggleChange} />
            </Grid>

            <Grid item xs={12}>
                <OpexSummaryTable params={params} period={period} onToggleChange={onToggleChange} />
            </Grid>
        </Grid>
    </>
}