import React from "react";
import { useChainShareOfWalletV2 } from "./hooks";
import { forceParseFloat } from "../../../common/util";
import CustomerAverageDailySpendTable from "./CustomerAverageDailySpendTable";
import { sortByBasketSize } from "../../util";
import { useFiltersContext } from "../../Provider/context";


export default function CustomerAverageDailySpend({ params, period }) {

    const { filters } = useFiltersContext()

    const newParams = {  
        ...params,  
        cm_channel_desc: filters?.channel  
    }  

    if (filters.hasOwnProperty('smac_consent') && filters.smac_consent !== undefined && filters.smac_consent !== '') {
        const paramsConsentKey = `smac_cdp_consent__is_${filters.smac_consent.toString()}`  // evaluates to smac_consent__is_true or smac_consent__is_false  
        newParams[paramsConsentKey] = 1  
    }  

    const {data, isLoading} = useChainShareOfWalletV2({
        ...newParams,
    })

    const customerData = React.useMemo(() => {
        if (isLoading || !data || !data.length) {
            return []
        }

        const totalSales = data.reduce((acc, row) => acc + forceParseFloat(row.pos_sku_gross_sales_sum), 0)

        return data.map(row => {
            return {
                basket_size: row.basket_size_bin, // chain_basket_size,
                customer_count: row.gcr_persistent_id_count_distinct ,// chain_customer_count_max,
                total_txn_count: row.header_tran_key_count_distinct, // chain_txn_count_max,
                total_sales: row.pos_sku_gross_sales_sum,
                distribution: row.pos_sku_gross_sales_sum / totalSales * 100
            }
        }).sort(sortByBasketSize);
    }, [data, isLoading])

    return (<>
        <CustomerAverageDailySpendTable customerData={customerData} isLoading={isLoading} period={period} />
    </>);
}