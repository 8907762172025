import React, { } from 'react';
import { CHART_TYPE_OPTIONS } from './constants';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function ChartSelector({ selectedChartType, setSelectedChartType }) {

    return (
        <Autocomplete
            value={selectedChartType}
            onChange={(event, newValue) => {
                setSelectedChartType(newValue);
            }}
            id="chart_type_options" size="small"
            options={CHART_TYPE_OPTIONS}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Filter By" placeholder="All Charts"/>}
        />
    )
}
