import React, {useState} from "react"
import StoreSideItems from "./StoreSideItems";
import LocationSideItems from "./LocationSideItems";

export default function SideItems(props) {
    // FIXME: checkboxes are inverted to quickly implement default selected is all.
    // current implementation, when no items are in each population filter, selected is all
    // when an item is added to the population filter, it is considered as excluded from the count

    const [isMenuOpen, setIsMenuOpen] = useState(true)
    const regionNames = ['All Region', ...props.regionData?.map(data => data.region)];

    return (
        <>
            {
                props.isLocationMapping ? <LocationSideItems
                    {...props}
                    regionNames={regionNames}
                    toggleMenuOpen={() => setIsMenuOpen(!isMenuOpen)}
                /> : <StoreSideItems
                    isMapView={props.isMapView}
                    title={props.title}
                    regionNames={regionNames}
                    toggleMenuOpen={() => setIsMenuOpen(!isMenuOpen)}
                    dateFilter={props.dateFilter}
                    setDateFilter={props.setDateFilter}
                    salesApiEndPointName={props.salesApiEndPointName}
                    apiDefaultFilter={props.apiDefaultFilter}
                    locationApiEndPoint={props.locationApiEndPoint}
                />
            }
        </>
    )
}