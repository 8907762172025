export const normalizeData = (data, total) => {
    return data.map((value, index) => {
        const normalizedValue = (value / total[index]) * 100 || 0;
        if (normalizedValue < 10) {
            return 20;
        } else if (normalizedValue > 90) {
            return 80;
        } return normalizedValue
    });
};

export const getLabelData = (data, total) => {
    return data.map((value, index) => {
        return (value / total[index]) * 100 || 0;
    });
};

export const getPercentageData = (data, legendData, sortingOrders) => {
    return legendData.reduce((acc, type) => {
        acc[type] = sortingOrders.map(category => data?.[category]?.[type] || 0);
        return acc;
    }, {});
};

export const getDefaultPercentagePointColor = (data) => {
    let color = '#000000';
    if (data < 0) {
        return color = '#EA324C'
    } else if (data > 0) {
        return color = '#00B38A'
    }
    return color
}

export const calculatePercentagePoint = (current, previous) =>
    current.map((value, index) => {
        const result = (value - previous[index]).toFixed(0);
        if (result === '-0') {
            return '0';
        } else if (result >= 1) {
            return '+' + result;
        }
        return result;
    });

export const mapColors = (data) => data.map(value => getDefaultPercentagePointColor(parseFloat(value)));
