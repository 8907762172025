import React, {useRef} from "react";
import CardComponent from "../../../common/components/CardComponent";
import TreeMaps from "../../../common/components/graphs/Treemaps";
import {TreemapsConfig} from "../../../GraphConfig/TreemapConfig";
import TREEMAP_DATA from "../../../mockdata/treemapData.json";
import RfmAnalytics from "../CustomerAnalytics/RfmAnayltics";
import FootTrafficAnalysis from "../CustomerAnalytics/FootTrafficAnalysis";
import GeoMap from "../../../common/components/graphs/GeoMap";
import ChatBox from "./ChatBox";
import Conversation from "./Conversation";
import debounce from "@popperjs/core/lib/utils/debounce";
import {useDebounce} from "use-debounce";
import {useQuery} from "react-query";
import ApiClient from "../../../common/API";

export default function ChatEnabledCustomerAnalytics() {

    const [chatHistory, setChatHistory] = React.useState([]);
    const [chatBoxEnabled, setChatBoxEnabled] = React.useState(true);

    const userMessages = chatHistory.filter(message => !!message.userMessage);
    const systemMessages = chatHistory.filter(message => !!message.response)

    const lastUserMessage = userMessages.length > 0 ? userMessages[userMessages.length - 1].userMessage : null;
    const lastParentId = systemMessages.length > 0 ? systemMessages[systemMessages.length - 1].response.parentMessageId : null;


    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }

    const responseQuery = useQuery(["chat", lastUserMessage], () => {
        return ApiClient().post('/chat', {message: lastUserMessage, parentMessageId: lastParentId}).then(res => {
            return res.data
        })
    }, {
        enabled: !!lastUserMessage,
        onSuccess: (data) => {
            setChatHistory([...chatHistory, {"response": data}]);
            setChatBoxEnabled(true)
        },
        onError: (error) => {
            console.log("error", error)
            setChatBoxEnabled(true)
            setChatHistory([...chatHistory, {"response": {message: <i>Something went wrong</i>}}])
        },
        onSettled: () => {
            //scroll to bottom
            scrollToBottom()
        },
        refetchOnWindowFocus: false
    })

    return <>
        <h3 className="mb-4">
            Customer Analytics
            <small>
                <span className={"badge rounded-pill bg-danger ms-2"}>PREVIEW</span>
            </small>
        </h3>

        <Conversation conversationHistory={chatHistory}/>

        <ChatBox onNewMessage={(newMessage) => {
            setChatHistory([...chatHistory, {"userMessage": newMessage}]);
            setChatBoxEnabled(false)
        }} disabled={!chatBoxEnabled}/>

    </>
}