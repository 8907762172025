import React from 'react'
import BUGraphGroupingPerformance from './trendLine'
import BUGroupPerformance from './BUGroupPerformance'
import BUPerformance from './BUPerformance'

export default function MerchandiseDetailed({filters, params, storeID, period, onDropdownChange,}) {

    return <>
        <BUGraphGroupingPerformance  filters={filters} params={params} period={period} onDropdownChange={onDropdownChange} storeID={storeID}/>

        <BUGroupPerformance filters={filters} params={params} period={period} onDropdownChange={onDropdownChange} storeID={storeID}/>

        <BUPerformance filters={filters} params={params} storeID={storeID} period={period}/>
    </>
}