import ApiClient from "../../../../../../common/API";
import {useQuery} from "react-query";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../../../../Constants/settings";
import {SBU_STORE_LOOKUP_TABLE} from "../../../../TmpTSSSales/hooks";
import {BARANGAY_TABLE_V1, TABLE_BARANGAY_INTERSECTIONS} from "../../branchView/LocationsComponents/hooks";
import moment from "moment";
import {useSelectorValue} from "../../../providers/selectorProvider";
import {useFiltersContext} from "../Provider/context";
import {CUSTOMER_FILTER_DEFINITIONS} from "../constants";
import {TABLE_CUSTOMER_ATTRIBUTES_WITH_BRGY} from "../../../../Customers/constants";
import {useTableQuery} from "../../../../../../common/API/hooks";



/* lowest level queries, basically just a wrapper for tableQuery */

export function useCustomerAttributesQuery(props) {
    return useTableQuery({
        tableName: TABLE_CUSTOMER_ATTRIBUTES_WITH_BRGY,
        ...props
    })
}


export function useZoneStoreMetaQuery(props) {
    return useTableQuery({
        tableName: SBU_STORE_LOOKUP_TABLE,
        ...props
    })
}

export function useStoreBarangayIntersectionsQuery(props) {
    return useTableQuery({
        tableName: TABLE_BARANGAY_INTERSECTIONS,
        ...props
    })
}

export function useBarangayMetaQuery(props) {
    return useTableQuery({
        tableName: BARANGAY_TABLE_V1,
        ...props
    })
}

/* end of lowest level queries */


/* Lookup generators and meta datasources */

export function useZoneStoreLookup({enabled = true}) {
    const query = useZoneStoreMetaQuery({
        params: {
            columns: ['zone', 'store_code', 'store_description'],
        },
        useQueryOptions: {
            enabled: enabled
        }
    })

    const storesByZone = query.data?.reduce((acc, row) => {
        if (!acc[row.zone]) {
            acc[row.zone] = []
        }
        acc[row.zone].push(row)
        return acc
    }, {})

    return {
        ...query,
        storesByZone
    }
}

export function useStoreBarangayLookup({enabled = true}) {
    const query = useStoreBarangayIntersectionsQuery({
        params: {
            columns: ['store_name', 'travel_time', 'barangay_key'],
            limit: 40000,   // we want to cache all this in the front-end
        }
    })

    // create a lookup with the following structure: store_name.travel_time = [barangay_keys]
    const storeBarangayLookup = query.data?.reduce((acc, row) => {
        if (!acc[row.store_name]) {
            acc[row.store_name] = {}
        }
        if (!acc[row.store_name][row.travel_time]) {
            acc[row.store_name][row.travel_time] = []
        }
        acc[row.store_name][row.travel_time].push(row.barangay_key)
        return acc
    }, {});

    const findBarangayIDsByStoreName = (storeName, travelTime) => {
        if (!storeBarangayLookup) {
            return []
        }
        const upperStoreName = storeName.toUpperCase();
        const result = storeBarangayLookup?.[upperStoreName]?.[travelTime];

        if (result) {
            return result
        }

        // fallback if upperStoreName is SM TANZA
        // this is a special case from previous data versions where the store name is SM TANZA CITY
        // if data is already fixed, this fallback will no longer be run through
        if (upperStoreName === 'SM TANZA') {
            return storeBarangayLookup?.['SM CITY TANZA']?.[travelTime] || []
        }

        return []
    }

    return {
        ...query,
        storeBarangayLookup,
        findBarangayIDsByStoreName
    }
}


export function useBarangayMetaLookup({enabled = true}) {
    const query = useBarangayMetaQuery({
        params: {
            columns: ['barangay_name', 'barangay_key', 'city_name', 'province_name', 'region_name', 'population_projected_2024'],  // excluded geometry since that would be heavy
            limit: 50000
        }
    })
    const barangayLookupByKey = query
        .data?.map(row => ({...row, population: row.population_projected_2024}))
        .reduce((acc, row) => {
            acc[row.barangay_key] = row
            return acc
        }, {})

    return {
        ...query,
        barangayLookupByKey: barangayLookupByKey || {},
    }
}

/* end of lookup generators */


export function useCustomerMetricsPerBarangay({params, useQueryOptions}) {
    const aggregates = [
        'gcr_persistent_id_count_distinct',
        'pos_sku_gross_sales_sum',
    ]
    const groupBy = ['barangay_key']

    const query = useCustomerAttributesQuery({
        params: {
            aggregates,
            group_by: groupBy,
            limit: 50000,
            ...params,
        },
        useQueryOptions
    })

    const propertyStandardizationFn = (row) => {
        return {
            ...row,
            smacMembers: row.gcr_persistent_id_count_distinct,
            smacSales: row.pos_sku_gross_sales_sum
        }
    }

    const metricLookupByBarangayKey = query
        .data?.map(propertyStandardizationFn)
        .reduce((acc, row) => {
            acc[row.barangay_key] = row;
            return acc
        }, {})

    return {
        metricLookupByBarangayKey: metricLookupByBarangayKey || {},
        ...query
    }
}

export function useCustomerCountPerBarangay({params, useQueryOptions}) {
    const aggregates = [
        'gcr_persistent_id_count_distinct',
    ]
    const groupBy = ['barangay_key']

    const query = useCustomerAttributesQuery({
        params: {
            aggregates,
            group_by: groupBy,
            limit: 50000,
            ...params,
        },
        useQueryOptions
    })

    const membersPerBarangay = query
        .data?.reduce((acc, row) => {
            acc[row.barangay_key] = row.gcr_persistent_id_count_distinct;
            return acc
        }, {})

    return {
        membersPerBarangay: membersPerBarangay || {},
        ...query
    }
}


export function useCustomerCountLastXMonthsPerBarangay({params, useQueryOptions, startOfCurrentPeriod, includedMonthCount=3, monthAdjustment=0}) {
    const newParams = {
        ...params,
    }
    const newStartMoment = moment(startOfCurrentPeriod)
        .subtract(monthAdjustment, 'months')
        .startOf('month')
    const newEndMoment = moment(startOfCurrentPeriod)
        .subtract(monthAdjustment, 'months')
        .add(includedMonthCount, 'months')
        .endOf('month')

    newParams.header_tran_date__gte = newStartMoment.format('YYYY-MM-DD')
    newParams.header_tran_date__lt = newEndMoment.format('YYYY-MM-DD')

    return useCustomerCountPerBarangay({
        params: newParams,
        useQueryOptions
    })

}

export function useAdjustedDateRange() {
    const {data: minMaxDateRange, isLoading: isLoadingMinMaxDateRange} = useAvailableDateRange()

    const monthSelected = useSelectorValue('month');
    const yearSelected = useSelectorValue('year');

    const hasYearMonth = !!yearSelected && !!monthSelected
    if (!hasYearMonth || isLoadingMinMaxDateRange) return undefined

    // for location use case, we only have MTD
    const startOfCurrentPeriodMoment = moment(`${yearSelected}-${monthSelected.toString().padStart(2, '0')}-01`)
        .startOf('month')
    const startOfCurrentPeriod = startOfCurrentPeriodMoment.format('YYYY-MM-DD')

    const endOfCurrentPeriodMoment = moment(startOfCurrentPeriod).endOf('month');
    const endOfCurrentPeriod = endOfCurrentPeriodMoment.format('YYYY-MM-DD')

    const maxAllowedMoment = moment(minMaxDateRange.max)
    if (endOfCurrentPeriodMoment > maxAllowedMoment) {
        return {
            from: startOfCurrentPeriod,
            to: maxAllowedMoment.format('YYYY-MM-DD')
        }
    }

    return {
        from: startOfCurrentPeriod,
        to: endOfCurrentPeriod
    }
}


export function useCustomerLocationTableData({filters}) {
    const fromToDates = useAdjustedDateRange()

    const filtersAsParams = useFiltersContextAsParams(CUSTOMER_FILTER_DEFINITIONS);


    const {storesByZone, isLoading: isLoadingZoneStoreLookup} = useZoneStoreLookup({})
    const {findBarangayIDsByStoreName, isLoading: isLoadingStoreBarangayLookup} = useStoreBarangayLookup({})
    const {barangayLookupByKey, isLoading: isLoadingBarangayMetaLookup} = useBarangayMetaLookup({})

    const isLoadingLookups = isLoadingZoneStoreLookup || isLoadingStoreBarangayLookup || isLoadingBarangayMetaLookup;

    // const {membersPerBarangay: p3mMembersPerBarangay, isLoading: isLoadingP3MMembersPerBarangay} = useCustomerCountLastXMonthsPerBarangay({
    //     params: {},
    //     startOfCurrentPeriod: startOfCurrentPeriod,
    //     monthAdjustment: 3,
    // })
    const {membersPerBarangay: p6mMembersPerBarangay, isLoading: isLoadingP6MMembersPerBarangay} = useCustomerCountLastXMonthsPerBarangay({
        params: {...filtersAsParams, ...filters},
        useQueryOptions: {
            enabled: !!fromToDates && false
        },
        startOfCurrentPeriod: fromToDates?.from,
        monthAdjustment: 7,  // 6 months excluding the current month
        includedMonthCount: 6,
    })
    // const {membersPerBarangay: p6mMembersPerBarangay, isLoading: isLoadingP9MMembersPerBarangay} = useCustomerCountLastXMonthsPerBarangay({
    //     params: {},
    //     startOfCurrentPeriod: startOfCurrentPeriod,
    //     monthAdjustment: 9,
    // })
    // const {membersPerBarangay: p12mMembersPerBarangay, isLoading: isLoadingP12MMembersPerBarangay} = useCustomerCountLastXMonthsPerBarangay({
    //     params: {},
    //     startOfCurrentPeriod: startOfCurrentPeriod,
    //     monthAdjustment: 12,
    // })

    const {metricLookupByBarangayKey, isLoading: isLoadingMetricLookupByBarangayKey} = useCustomerMetricsPerBarangay({
        params: {
            header_tran_date__gte: fromToDates?.from,
            header_tran_date__lte: fromToDates?.to,
            ...filtersAsParams,
            ...filters
        },
        useQueryOptions: {
            enabled: !!fromToDates
        },
    })

    const isLoadingCustomerData = isLoadingP6MMembersPerBarangay || isLoadingMetricLookupByBarangayKey

    const zones = !!storesByZone ? Object.keys(storesByZone).filter(zone => zone != 'Online') : []
    const catchmentRate = useSelectorValue('catchmentRate')

    /* formulas and reducers */
    const populationSumReduceFn = (acc, row) => acc + (row.population ? parseInt(row.population) : 0)
    const smacMembersSumReduceFn = (acc, row) => acc + (row.smacMembers? parseInt(row.smacMembers) : 0)
    const smacSalesSumReduceFn = (acc, row) => acc + (row.smacSales ? parseFloat(row.smacSales) : 0)
    const smacP6mShoppersSumReduceFn = (acc, row) => acc + (row.p6mShoppers ? parseInt(row.p6mShoppers) : 0)

    const percPenetrationFn = (smacMembers, population) => smacMembers / population
    const percPxmShoppersFn = (pxmShoppers, smacMembers) => pxmShoppers / smacMembers
    const percSalesFn = (subtotal, total) => subtotal / total;

    // curry function
    const percSalesMapFnCreator = (totalSales) => {
        return (row) => ({...row, percSales: percSalesFn(row.smacSales, totalSales)})
    }


    const data = zones.map(zone => {
        const storeSubRows = storesByZone[zone].map(storeRow => {
            const barangaySubRows = findBarangayIDsByStoreName(storeRow.store_description, catchmentRate).map(barangayKey => {
                const barangayMeta = barangayLookupByKey[barangayKey];
                const barangayMetrics = metricLookupByBarangayKey[barangayKey];

                const barangayPopulation = barangayMeta?.population || 0;
                const barangaySmacMembers = barangayMetrics?.smacMembers || 0;

                // const p3mShoppers = p3mMembersPerBarangay[barangayKey] || 0;
                const p6mShoppers = p6mMembersPerBarangay[barangayKey] || 0;
                // const p9mShoppers = p6mMembersPerBarangay[barangayKey] || 0;
                // const p12mShoppers = p12mMembersPerBarangay[barangayKey] || 0

                return {
                    barangayKey,
                    pk: barangayMeta?.barangay_name || barangayKey,
                    population: barangayMeta?.population,
                    smacMembers: barangaySmacMembers,
                    // p3mShoppers: p3mShoppers,
                    // percP3mShoppers: p3mShoppers / barangaySmacMembers,
                    p6mShoppers: p6mShoppers,
                    percP6mShoppers: percPxmShoppersFn(p6mShoppers, barangaySmacMembers),
                    // p9mShoppers: p9mShoppers,
                    // percP9mShoppers: p9mShoppers / barangaySmacMembers,
                    // p12mShoppers: p12mShoppers,
                    // percP12mShoppers: p12mShoppers / barangaySmacMembers,
                    smacSales: barangayMetrics?.smacSales,
                    percSales: 88,
                    percPenetration: percPenetrationFn(barangaySmacMembers, barangayPopulation)
                }
            });

            const storePopulation = barangaySubRows.reduce(populationSumReduceFn, 0);
            const storeSmacMembers = barangaySubRows.reduce(smacMembersSumReduceFn, 0);
            const storeP6mShoppers = barangaySubRows.reduce(smacP6mShoppersSumReduceFn, 0);
            const storeSmacSales = barangaySubRows.reduce(smacSalesSumReduceFn, 0)

            const storePercSalesMapFn = percSalesMapFnCreator(storeSmacSales)

            return {
                pk: storeRow.store_description,
                population: storePopulation,
                smacMembers: storeSmacMembers,
                p6mShoppers: storeP6mShoppers,
                percP6mShoppers: percPxmShoppersFn(storeP6mShoppers, storeSmacMembers),
                smacSales: storeSmacSales,
                percSales: 88,
                percPenetration: percPenetrationFn(storeSmacMembers, storePopulation),
                subRows: barangaySubRows.map(storePercSalesMapFn)
            }
        });

        const uniqueBarangaySubRowsWithinZone = Object.values(
            storeSubRows
                .map(storeSubRow => storeSubRow.subRows)  // get all subrows of each store
                .reduce((acc, subrowsOfStore) => {  // flatten the subrows to get barangay subrows
                    return [...acc, ...subrowsOfStore]
                }, [])
                .reduce((acc, barangaySubrow) => {  // create a unique map of barangays
                    acc[barangaySubrow.barangayKey] = barangaySubrow;
                    return acc
                }, {})
        )

        const zonePopulation = uniqueBarangaySubRowsWithinZone.reduce(populationSumReduceFn, 0)
        const zoneSmacMembers = uniqueBarangaySubRowsWithinZone.reduce(smacMembersSumReduceFn, 0)
        const zoneP6mShoppers = uniqueBarangaySubRowsWithinZone.reduce(smacP6mShoppersSumReduceFn, 0)
        const zoneSmacSales = uniqueBarangaySubRowsWithinZone.reduce(smacSalesSumReduceFn, 0)

        const zonePercSalesMapFn = percSalesMapFnCreator(zoneSmacSales)

        return {
            pk: zone,
            population: zonePopulation,
            smacMembers: zoneSmacMembers,
            p6mShoppers: zoneP6mShoppers,
            percP6mShoppers: percPxmShoppersFn(zoneP6mShoppers, zoneSmacMembers),
            smacSales: zoneSmacSales,
            percSales: 88,
            percPenetration: percPenetrationFn(zoneSmacMembers, zonePopulation),
            subRows: storeSubRows.map(zonePercSalesMapFn),
        }
    });

    const totalSmacSales = data.reduce(smacSalesSumReduceFn, 0)
    const percSalesMapFn = percSalesMapFnCreator(totalSmacSales)


    return {
        data: data.map(percSalesMapFn),
        isLoading: isLoadingLookups || isLoadingCustomerData,

        isLoadingZoneStoreLookup,
        isLoadingBarangayMetaLookup,
        isLoadingStoreBarangayLookup,
        isLoadingLookups,

        isLoadingP6MMembersPerBarangay,
        isLoadingMetricLookupByBarangayKey,
        isLoadingCustomerData,
    }
}


export const useFiltersContextAsParams = (filterDefinition) => {
    const {filters} = useFiltersContext()

    // generate a map of filterKey -> column
    const filterKeyTranslation = Object.values(filterDefinition).reduce((acc, filter) => {
        acc[filter.key] = filter.column
        return acc
    }, {})

    return Object.keys(filters).filter(filterKey => {
        // only filters defined in the definition can be used
        return filterDefinition.hasOwnProperty(filterKey)
    }).filter(filterKey => {
        // remove empty values
        return !!filters[filterKey] || (Array.isArray(filters[filterKey]) && filters[filterKey].length > 0)
    }).reduce((acc, filterKey) => {
        const column = filterKeyTranslation[filterKey] || filterKey
        acc[column] = filters[filterKey]
        return acc
    }, {})

}


export function useAvailableDateRange() {
    return useQuery(
        [TABLE_CUSTOMER_ATTRIBUTES_WITH_BRGY, 'customers', "date_range"],
        () => ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES_WITH_BRGY}/filter_values/`, {
            params: {
                columns: "header_tran_date"
            }
        }).then(res => {
            return res.data.header_tran_date
        }), {
            ...USE_QUERY_DEFAULT_OPTIONS
        }
    );
}