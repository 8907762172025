import React, { useContext, useMemo } from 'react';
import FilterGroup from "../Filters/FilterGroup";
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import TableComponentV2 from '../../../../common/components/TableComponentV2';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { useAvailableDateRange, useGraphInTableData, useSalesPerformancePerGroupData } from '../hooks';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    convertNumberToLocaleString,
    convertToValidNumber,
    sortArrayBasedOnGivenArray,
    sortByOrder,
    titleCase
} from '../../../../common/utils';
import BarCellDisplay from '../BarCellDisplay';
import { getDaysDifference, getPast12months, groupArrayBy, numberCellDisplay, percentCellDisplay } from '../util';
import ToggleMtdYtd from '../toggleMtdYtd';
import moment from 'moment';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import BarTrends from "../../TmpTSSSales/BarTends";


const chooseGroup = {
    'store_format': 'store_format',
    'subdept_id': 'subdept_id',
    'product_category': 'product_category',
}

export default function SalesPerformancePerGroup({
    params, filters, onDropDownFilterChange, selectedRows, onSelectRow,
    period, onToggleChange
}) {

    let absoluteHeaderLabel = '';
    let orderArray;
    let subRowBy = '';
    let subRowDisplay = '';
    let groupByApi = filters?.group;
    const secondaryGroupByApi = 'subdept_id,subdept_desc,product_category'
    const headers = [];
    let defaultSortBy = filters?.group;

    const windowSize = useContext(WindowSizeContext);
    const subDeptOrderArray = [
        'ELECTRICALS',
        'AUTOMOTIVE',
        'CHEMICALS & BATTERIES',
        'PAINTS & SUNDRIES',
        'HAND & POWER TOOLS',
        'ELECTRICAL',
        'PLUMBING',
        'HOME HARDWARE',
        'HOUSEWARE',  //DUP
        'LAWN, GARDEN & OUTDOOR',
        'AUTOMOTIVE',  //DUP
        'SMALL APPLIANCES',
        'CHEMICALS',
        'PETS',
        'HARDWARE ITEMS',
        'WORKSHOP PROMOTIONS',

        'PLUMBING & HEATING',
        'HOWE,LUMBER & BLDG MAT\'L',
        'HOUSEWARE',  //DUP
    ]
    switch (chooseGroup[filters?.group]) {
        case 'store_format':
            orderArray = ['EXPRESS', 'MALL', 'COMMUNITY', 'BUILDERS'];
            absoluteHeaderLabel = "Format"
            headers.push({ header: "Format", accessor: 'store_format' })
            break;
        case 'subdept_id':
            orderArray = null;
            absoluteHeaderLabel = "Subdept"
            groupByApi = secondaryGroupByApi
            headers.push({ header: "Subdept ID", accessor: 'subdept_id' })
            headers.push({ header: "Subdept", accessor: 'subdept_desc' })
            // defaultSortBy = 'subdept_desc'
            break;
        case 'product_category':
            orderArray = [
                'OUTRIGHT',
                'CONSIGNOR',
            ]
            absoluteHeaderLabel = "Product Category";
            subRowBy = 'subdept_id'
            subRowDisplay = 'subdept_id,subdept_desc'
            groupByApi = `${filters?.group}`
            headers.push({ header: "Product Category", accessor: 'product_category' })
            break;
        default:
            headers.push({ header: titleCase(filters?.group), accessor: filters?.group })
            orderArray = null;
            break
    }

    const isMTD = period === 'mtd';
    const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] }
    const { data: performancePerGroupData, isLoading: isLoadingPerformancePerGroup } = useSalesPerformancePerGroupData({
        params: {
            ...paramsBasedOnPeriod,
            group_by: groupByApi,
        }
    });

    const dateParams = useMemo(() => {
        return getPast12months(params?.month?.[0], params?.year)
    }, [params?.month, params?.year])

    const { data: graphData, isLoading: isGraphDataLoading } = useGraphInTableData({
        params: {
            year: dateParams?.year,
            month: dateParams?.month,
            group_by: filters?.group,
        }
    });


    // FIXME: not ideal but just load it again for now
    const {
        data: performancePerGroupDataSecondary,
        isLoading: isLoadingPerformancePerGroupSecondary
    } = useSalesPerformancePerGroupData({
        params: {
            ...paramsBasedOnPeriod,
            group_by: secondaryGroupByApi,
        }
    });
    const { data: graphDataSecondary, isLoading: isGraphDataLoadingSecondary } = useGraphInTableData({
        params: {
            year: params?.year,
            month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            group_by: secondaryGroupByApi,
        }
    });

    const sortByValue = (a, b) => {
        if (!b.actual_sales && a.actual_sales) return -1
        if (!a.actual_sales && b.actual_sales) return 1
        if (!a.actual_sales && !b.actual_sales) return parseFloat(b.target_sales) - parseFloat(a.target_sales)

        return parseFloat(b.actual_sales) - parseFloat(a.actual_sales)
    }


    const newPerformancePerGroupData = React.useMemo(() => {
        if (subRowBy) {
            const groupedData = groupArrayBy(performancePerGroupDataSecondary, filters?.group)

            return performancePerGroupData.map(row => {
                const sortedSubRows = groupedData[row[filters?.group]].sort(sortByValue)
                return {
                    ...row,
                    subRows: sortedSubRows?.map(subRow => {
                        const subRowDisplayKeys = subRowDisplay.split(',')
                        const subRowDisplayValue = subRowDisplayKeys.map(key => subRow[key]).join(' - ')
                        return {
                            ...subRow,
                            hideEye: true,
                            [filters?.group]: subRowDisplayValue,
                            barTrendData: graphDataSecondary[subRow[subRowBy]]
                        }
                    }),
                    barTrendData: graphData[row[filters?.group]]
                }
            })
        }
        return performancePerGroupData.map((row) => {
            return {
                ...row,
                barTrendData: graphData[row[filters?.group]]
            };
        })
    }, [performancePerGroupData, performancePerGroupDataSecondary, filters?.group, graphDataSecondary, graphData])

    const isProductGroup = filters?.group === 'product_group';
    const PRODUCT_CAT_COLS = ['888', '4k']
    const { data: dateRange, isLoading: isLoadingDateRange } = useAvailableDateRange()
    const paramsPeriod = params?.month?.[0] && params?.year ? params?.year + '-' + params?.month?.[0] : moment().date(new Date()).format('YYYY-MM');
    const periodStartDate = isMTD ? moment(paramsPeriod).startOf('month').format('YYYY-MM-DD') : moment(paramsPeriod).startOf('year').format('YYYY-MM-DD');
    const periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
    const maxAvailableDate = !isLoadingDateRange && moment(dateRange?.max).format('YYYY-MM-DD');
    const isMonthNotEqualToMax = params?.month?.[0] !== moment(maxAvailableDate).month() + 1; //if selected params is not equal to max date.
    const maxDaysInPeriod = isMonthNotEqualToMax ? periodEndDate : maxAvailableDate;
    const numberOfDaysWithinThePeriod = getDaysDifference(periodStartDate, periodEndDate);
    const maxAvailableDaystheSelectedMonth = getDaysDifference(periodStartDate, maxDaysInPeriod)
    const getRunningDailyTarget = (target) => {
        return (target / numberOfDaysWithinThePeriod) * maxAvailableDaystheSelectedMonth
    };

    const performance_per_group_columns = useMemo(
        () => {
            const columns = [
                ...headers.map((header, index) => {
                    return {
                        header: header.header,
                        accessorKey: header.accessor,
                        cell: ({ row, getValue }) => (
                            <Box sx={{
                                marginLeft: `${row.depth * 1}rem`,
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {
                                    (index === 0 && !row.original.hideEye) && <VisibilityIcon
                                        sx={{ marginRight: '.5rem' }} fontSize='small'
                                        color={selectedRows?.includes(row?.original?.[filters?.group]) ? 'primary' : 'disabled'}
                                    />
                                }

                                {isProductGroup && PRODUCT_CAT_COLS.includes(row?.getValue()) ? 'Top ' + row?.getValue() : row?.getValue()}
                                {getValue()}
                                {chooseGroup[filters?.group] === 'zone' ? ' (' + row?.original?.store_count + ' stores)' : ''}
                                {(row.original.subRows && index === 0) && (
                                    < IconButton size='small' onClick={(event) => {
                                        event.stopPropagation(); // Stop the click event from propagating
                                        row.getToggleExpandedHandler()();
                                    }}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize='medium' /> :
                                            <ArrowRightRoundedIcon fontSize='medium' color='#e0e0e0' />}
                                    </IconButton>
                                )}
                            </Box>
                        ),
                        sortingFn: (rowA, rowB) => {
                            if (orderArray) {
                                return sortByOrder(rowA.original, rowB.original, orderArray, filters?.group)
                            } else {
                                return rowA?.getValue() < rowB?.getValue() ? 1 : -1
                            }
                        },
                        customStyle: { width: '15rem' },
                    }
                }),

                {
                    header: isMTD ? 'MTD' : 'YTD',
                    accessorKey: 'actual_sales',
                    cell: (row) => <Box sx={{
                        width: { sm: '45%' },
                        marginX: 'auto'
                    }}> {numberCellDisplay(row?.getValue(), windowSize?.isOnMobile)} </Box>,
                    customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
                },
                {
                    header: '% cont.',
                    accessorKey: 'percent_contribution',
                    cell: (row) => percentCellDisplay(row?.getValue()),
                    customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
                },
                {
                    header: isMTD ? 'MTD Plan' : 'YTD Plan',
                    accessorKey: 'target_sales',
                    cell: (row) => <Box sx={{
                        width: { sm: '45%' },
                        marginX: 'auto'
                    }}> {numberCellDisplay(row?.getValue(), windowSize?.isOnMobile)} </Box>,
                    customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
                },
                {
                    header: 'vs Plan',
                    accessorFn: (row) => (row?.actual_sales / row?.target_sales) * 100,
                    cell: (row) => <>
                        {convertNumberToLocaleString(row?.getValue()) !== 0 ?
                            <Box sx={{ width: '7rem', height: '2.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                                <BarCellDisplay value={convertToValidNumber(row?.getValue())?.toFixed()}
                                    isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </Box> : '-'
                        }
                    </>,
                    customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
                },
                // {
                //     header: isMTD ? 'Last Year MTD' : 'Last Year YTD',
                //     accessorKey: 'last_year_sales',
                //     cell : (row)=>  <Box  sx={{  width: { sm: '50%'} ,marginX: 'auto' }}> { numberCellDisplay(row?.getValue(),windowSize?.isOnMobile) } </Box>,
                //     customStyle : { width: '15rem' , textAlign: 'left' , verticalAlign: 'middle'}
                // },
                {
                    header: 'vs Last Year',
                    accessorFn: (row) => (row?.actual_sales / row?.last_year_sales) * 100,
                    cell: (row) => <>
                        {convertNumberToLocaleString(row?.getValue()) !== 0 ?
                            <Box sx={{ width: '7rem', height: '2.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                                <BarCellDisplay value={convertToValidNumber(row?.getValue())?.toFixed()}
                                    isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </Box> : '-'
                        }
                    </>,
                    customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
                },
                // {
                //     header: isMTD ? '2019 MTD' : '2019 YTD',
                //     accessorKey: 'sales_2019',
                //     cell : (row)=>  <Box  sx={{  width: { sm: '50%'} ,marginX: 'auto' }}> { numberCellDisplay(row?.getValue(),windowSize?.isOnMobile) } </Box>,
                //     customStyle : { width: '15rem' , textAlign: 'left' , verticalAlign: 'middle'}
                // },
                {
                    header: 'vs 2019',
                    accessorFn: (row) => (row?.actual_sales / row?.sales_2019) * 100,
                    cell: (row) => <>
                        {convertNumberToLocaleString(row?.getValue()) !== 0 ?
                            <Box sx={{ width: '7rem', height: '2.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                                <BarCellDisplay value={convertToValidNumber(row?.getValue())?.toFixed()}
                                    isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </Box> : '-'
                        }
                    </>,
                    customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
                },
                {
                    header: '12 Mth Sales Trend vs Sales Plan',
                    id: '12th_mth_sales_trend',
                    accessorFn: (row) => (row?.actual_sales / row?.target_sales) * 100,
                    cell: (row) => {
                        const group = filters?.group;
                        const barTrendData = row?.row?.original.barTrendData
                        const newGraphData = barTrendData?.filter(data => dateParams?.monthYear.includes(data?.month + '|' + data?.year))
                        return <>
                            {

                                isGraphDataLoading ? <LoadingComponent /> : <Box sx={{ width: '13rem', height: '2.5rem', marginX: 'auto' }}>
                                    <BarTrends data={newGraphData} vsPlan={row?.getValue() ?convertNumberToLocaleString(row?.getValue()) : undefined} />
                                </Box>
                            }
                        </>
                    }
                }
            ]


            return columns
        },
        [filters?.group, isProductGroup, selectedRows, period, getRunningDailyTarget, isMTD, isGraphDataLoading, graphData]
    )

    const TopSalesTableData = isProductGroup && newPerformancePerGroupData?.filter(val => PRODUCT_CAT_COLS.includes(val['product_group'])) // 888 and 4k data
    const filteredData = isProductGroup ? newPerformancePerGroupData?.filter(val => !PRODUCT_CAT_COLS.includes(val['product_group'])) : newPerformancePerGroupData // remove 888 and 4k when on product group
    const noNullRowData = filteredData?.filter(val => val[filters?.group] !== 'null' && val[filters?.group] !== null)
    const newOrderArray = isProductGroup ? ['888', '4k', ...orderArray] : orderArray

    if (isLoadingPerformancePerGroup) {
        return <LoadingComponent />
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container alignItems="center" py={.5}>
                    {
                        windowSize?.isOnMobile ? <>
                            <Grid item xs sm={2.5}>
                                <Typography variant="h6" ml={2.5}>
                                    Performance per
                                </Typography>
                            </Grid>
                            <Grid item xs display="flex" justifyContent={'end'}
                                paddingY={windowSize?.isOnMobile && .75}>
                                <ToggleMtdYtd
                                    value={period}
                                    onChange={onToggleChange}
                                />
                            </Grid>
                            <FilterGroup
                                onDropdownChange={onDropDownFilterChange}
                                filters={filters} />
                        </> : <>
                            <FilterGroup
                                onDropdownChange={onDropDownFilterChange}
                                filters={filters} />
                            <Grid item sm={3} xs={12} display="flex" justifyContent={'end'}
                                paddingY={windowSize?.isOnMobile && .75}>
                                <ToggleMtdYtd
                                    value={period}
                                    onChange={onToggleChange}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={12} mt={0}>
                    {
                        isProductGroup && <TableComponentV2
                            columns={performance_per_group_columns}
                            data={sortArrayBasedOnGivenArray(TopSalesTableData, newOrderArray, defaultSortBy)}
                            tableId={'salesPerformancePerGroupTopTable'}
                            enableRowSelection={true}
                            onSelectRow={onSelectRow}
                            isCompact={true}
                            customTableHeaderStyle={{
                                backgroundColor: '#caddeb'
                            }}
                            customTableContainerStyle={{
                                borderRadius: "0",
                                boxShadow: 'none',
                            }} />
                    }
                </Grid>
                <Grid item xs={12} sm={12} mt={isProductGroup ? .75 : 0}>
                    <TableComponentV2
                        columns={performance_per_group_columns}
                        data={
                            newOrderArray ?
                                sortArrayBasedOnGivenArray(noNullRowData, newOrderArray, defaultSortBy) :
                                noNullRowData.sort(sortByValue)
                        }
                        tableId={'salesPerformancePerGroupTable'}
                        enableRowSelection={true}
                        onSelectRow={onSelectRow}
                        isCompact={true}
                        customTableHeaderStyle={{
                            backgroundColor: '#caddeb'
                        }}

                        initialState={{
                            columnVisibility: { "12th_mth_sales_trend": !isMTD }
                        }}
                    />

                </Grid>
            </Grid>
        </>
    );
}
