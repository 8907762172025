import * as React from 'react';
import { Typography, Box } from '@mui/material';


export default function ShareOfWalletIndicator({ value }) {

    function generateTextColor(GivenSoWColor) {
        const greenComponent = parseInt(GivenSoWColor.substring(3, 5), 16);
        const isLightGreen = greenComponent > 190;
        return isLightGreen ? '#000000' : '#ffffff';
    }
    const intValue = parseInt(value)
    const SoWColor = () => {
        if (intValue < 3) {
            return '#e7f1f8';
        } else if (intValue < 10) {
            return '#caddeb';
        } else if (intValue < 20) {
            return '#dce0e8';
        } else if (intValue < 40) {
            return '#acc3c2';
        } else {
            return '#3f8282';
        }
    };

    return (
        <Box
            sx={{
                width: 'inherit',
                height: 'inherit',
                borderRadius: 1,
                color: generateTextColor(SoWColor()),
                bgcolor: SoWColor(),
                '&:hover': {
                    bgcolor: SoWColor(),
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Typography variant='body2' fontSize='11px' >
                {value}%
            </Typography>
        </Box>
    )
}