import React, { useContext } from 'react';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { getSelectedOption } from '../../../../common/utils';

export default function MustHaveTypeFilter({ filters, onFilterChange, overviewTableIsEmpty }) {
    const windowSize = useContext(WindowSizeContext);

    const availableOptions = [
        { value: '888', label: 'Top 888' },
        { value: '4k', label: 'Top 4k' },
    ]

    return (
        <>
            <ConditionalSelectComponent
                id="must-have-type-filter"
                labelId="must-have-type-filter"
                options={availableOptions}
                label="Must-Have Type"
                value={filters ? (windowSize.isOnMobile ? filters?.article_sub_category : getSelectedOption(filters?.article_sub_category, availableOptions)) : '' && null}
                onSelectChange={(event, newValue) => {
                    windowSize.isOnMobile ? onFilterChange('article_sub_category', event.target.value)
                        : onFilterChange('article_sub_category', newValue?.value)
                }}
                disableClearable={false}
                disabled={!filters?.store_id || overviewTableIsEmpty}
                endAdornment={true}
                startAdornment={false}
                clearSelected={() => onFilterChange('article_sub_category', null)}
            />
        </>
    );
}
