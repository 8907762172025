import React, { useMemo, useState } from "react";
import TreeNode from "./TreeNode";
import { useQuery } from "react-query";
import ApiClient from "../../../../common/API";
import { useSchemaList } from "../../../../common/API/hooks";
import Grid from '@mui/material/Grid'
import LoadingComponent from "../../../../common/components/LoadingComponent";

export default function TreeNodes(
    {
        tableId, setTableId,
        searchQuery
    }) {

    const [expandedNodes, setExpandedNodes] = useState([]);

    const { data: tableList } = useQuery(['table', 'list'], () =>
        ApiClient().get('/data/table/').then((res) => res.data),
        {
            refetchOnWindowFocus: false,
            staleTime: 600000, // 10 minutes
        }
    );

    const { data: schemaList, isLoading: schemasLoading } = useSchemaList()

    const transformedData = useMemo(() => {
        if (schemaList && tableList) {
            return schemaList.map((schema) => {
                const schemaData = {
                    label: schema.name,
                    id: schema.id,
                    database_type: schema.database_type,
                    children: [],
                    is_parent: true
                };

                if (schema.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                    schemaData.children = tableList
                        .filter((table) => table.schema_id === schema.id)
                        .map((table) => {
                            const tableData = {
                                label: table.name,
                                id: table.id,
                                activeStatus: table.is_active,
                            };
                            return tableData;
                        });
                } else {
                    schemaData.children = tableList.filter((table) =>
                        table.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                        table.schema_id === schema.id).map((child) => {
                            const tableData = {
                                label: child.name,
                                id: child.id,
                                activeStatus: child.is_active,
                            };
                            return tableData;
                        })
                }
                return schemaData;
            });
        }

        return [];
    }, [schemaList, tableList, searchQuery]);

    const filterNodes = (nodes, query, parentExpanded) => {
        return nodes.filter((node) => {
            const isMatched = node.label.toLowerCase().includes(query.toLowerCase());
            let isExpanded = parentExpanded || expandedNodes.includes(node.id);

            if (isMatched) {
                isExpanded = true;
                return isMatched;
            }

            const filteredChildren = filterNodes(node.children, query, isExpanded);
            if (filteredChildren.length > 0) {
                isExpanded = true;
                return filteredChildren.length > 0;
            }
            return isMatched || filteredChildren.length > 0;
        });
    };

    const filteredNodes = filterNodes(transformedData, searchQuery, false);

    return (
        <>

            <Grid sx={{mt:1,height: '65vh', overflowY:'auto', overflowX:'hidden'}}>
                {
                    schemasLoading ? <>
                        <Grid direction={'row'}>
                            <LoadingComponent />
                        </Grid> 
                    </> :
                    filteredNodes.map((node) => {
                        return (
                            <TreeNode
                                expandedNodes={expandedNodes}
                                setExpandedNodes={setExpandedNodes}
                                key={node.id}
                                node={node}
                                level={0}
                                parentExpanded={false}
                                setTableId={setTableId}
                                tableId={tableId}
                            />
                        )
                    })
                }
            </Grid>
        </>
    )
}