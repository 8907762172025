import React from "react";
import {useBranchOptions} from "../hooks";
import ContextBasedDropdownSelector from "../../common/presentation/selectors/ContextBasedDropdownSelector";

export default function BranchSelector() {
    const { data: options, isLoading } = useBranchOptions()

    return (
        <ContextBasedDropdownSelector
        isLoading={isLoading}
        options={options}
        label="Branch"
        contextKey="branch"
        defaultValue="All Branch"
        />
    )
}