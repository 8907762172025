import { useState } from "react";
import ApiClient from "../API";
import { useQuery  } from 'react-query'

function useDbTargetOption() {
    const {isLoading, isError, data, error, isSuccess} = useQuery('getDbTargetCount', () => {
        return ApiClient().get('/targetDb').then(res => {
            return res.data
        })
    }, {
        refetchOnWindowFocus: false,
        staleTime: 60 * 15000 // 15 minutes
    });

    return { 'label': "CDP Golden Customer Records", 'value': 'db', 'targetCount': data?.count, isLoading: isLoading }
}


export default function useTargetOptions(url) {
    let options = []
    const { isLoading, isError, data, error, isSuccess } = useQuery('getTargetOptions',()=> ApiClient().get(url).then(res => {
        return res.data
    }), {
        refetchOnWindowFocus: false,
        staleTime: 60 * 15000 // 15 minutes
    });

    if(!isLoading && isSuccess) {
        
        const newTargetOptions = data?.results?.map(file => {
            return { 'label': file.title, 'value': file.id, 'targetCount': file.targetCount }
        })

        options=newTargetOptions;
    }

    const dbTargetOption = useDbTargetOption()
    const newOptions = options ? [...options] : []
    return { isLoading, options: [newOptions, dbTargetOption] }
}