import React, {useContext} from 'react';
import { getSelectedOption } from '../../../../common/utils';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';

export default function ConditionFilter({filter ,onFilterChange}) {
    const windowSize = useContext(WindowSizeContext);

    const options = [
        { value: 'greater_than_equal', label: '>='},
        { value: 'less_than_equal', label: '<='}
    ]

    return (
        <>
            <ConditionalSelectComponent
                id="condition_filter"
                labelId="condition_filter-label"
                options={options}
                label="Condition"
                value={filter ? (windowSize.isOnMobile ? filter?.condition : getSelectedOption(filter?.condition, options)) : '' && null}
                onSelectChange={(event,newValue)=>{
                    windowSize.isOnMobile ? onFilterChange(event.target.value, 'condition')
                    : onFilterChange(newValue?.value, 'condition')  
                }}
                endAdornment={true}
                startAdornment={false}
                clearSelected={() => onFilterChange('','condition')}
                disableClearable={false}
            />
        </>
        
    )
}