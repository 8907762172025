import React, { useMemo, useState, useContext } from "react";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import { getSelectedOption } from "../../../../common/utils";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { Box, Grid, Typography } from '@mui/material';
import DropdownCheckboxV3 from "../../../../common/components/DropdownCheckboxV3";
import Item from "../../../../common/components/ItemCard";
import BarIndicator from "../../common/presentation/barIndicator";
import NumericValueDisplay from "../../common/presentation/numericValueDisplay";
import { getDataFormatter, inventoryValidNumber } from '../utils';
import { useCategoryContext, useFiltersContext, useToggleContext } from "../provider/context";
import { useInventoryStoreView } from "../hooks";
import { STORE_DATA_COLUMNS } from "../constants";
import {useMktBranchLookup} from "../../../../common/hooks/branchLookup";
import { CATEGORY } from "../constants";

const IndicatorCellBox = (props) => (
    <Box sx={{ my: '.2rem', width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);
export default function InventoryStoreView({ period }) {

    const { filters: dashboardFilters } = useFiltersContext()
    
    const {category} = useCategoryContext()
    const selectedRows = dashboardFilters[category]
    const [storeFilters, setStoreFilters] = useState([])
    const { toggleState } = useToggleContext();

    const usedGroupByAndColumns = useMemo(() => {
        const standardColumns = 'branch_id,branch_name'
        if (toggleState === 'overview') {
            return standardColumns
        }
        if (toggleState === 'product') {
            return `${standardColumns},food_group`
        }
        if (toggleState === 'top888') {
            return `${standardColumns},top_food_group`
        }
        throw Error(`Invalid toggle state: [${toggleState}]`)
    }, [toggleState])

    const dataFormatterFn = useMemo(() => {
        return getDataFormatter(toggleState)
    }, [toggleState])

    const { inventoryDataStoreView, isLoading } = useInventoryStoreView({
        params: {
            group_by: usedGroupByAndColumns,
            columns: usedGroupByAndColumns,
        }, dataFormatterFn: dataFormatterFn
    })

    
    const { data: branchMetaData, lookup: branchLookup, isLoading: isLoadingBranchMetaData } = useMktBranchLookup({})

    const filteredInventoryDataStoreView = useMemo(() => {

        const storeMetaFilterKeys = Object.keys(dashboardFilters).filter(filterKey => {
            return STORE_DATA_COLUMNS.includes(filterKey) && dashboardFilters[filterKey] && (!Array.isArray(dashboardFilters[filterKey]) || dashboardFilters[filterKey].length > 0)
        })
        const hasFiltersForMetaData = storeMetaFilterKeys.length > 0
        if (!hasFiltersForMetaData) {
            return inventoryDataStoreView
        }
        if (!branchMetaData || !inventoryDataStoreView) {
            return []
        }
        const branchIdsPassingFilters = branchMetaData.filter(branchInfo => {
            return storeMetaFilterKeys.every(filterKey => {
                const branchValue = branchInfo[filterKey]
                const filterValues = Array.isArray(dashboardFilters[filterKey]) ? dashboardFilters[filterKey] : [dashboardFilters[filterKey]]  // force array
                return filterValues.includes(branchValue)
            })
        }).map(branchInfo => branchInfo.branch_id)

        return inventoryDataStoreView.filter(row => branchIdsPassingFilters.includes(row.branch_id))
    }, [inventoryDataStoreView, branchMetaData, dashboardFilters])


    const windowSize = useContext(WindowSizeContext)
    const newInventoryDataStoreView = useMemo(() => {
        // todo: remove this memo
        return filteredInventoryDataStoreView
    }, [filteredInventoryDataStoreView])

    //Joining meta data and actual store data
    const storeData = useMemo(() => {
        if (!newInventoryDataStoreView) {
            return []
        }
        if (!branchLookup) {
            return newInventoryDataStoreView
        }
        return newInventoryDataStoreView.map(row => {
            return {
                ...row,
                branch_name: branchLookup[row.branch_id]?.store || row.branch_name,
                store_tagging: branchLookup[row.branch_id]?.store_tagging || 'Store'
            }
        })
    }, [branchLookup, newInventoryDataStoreView])

    const activeFiltersText = useMemo(() => {
        const withTopLabel = ['888', '4k'];
        const filtersText = (selectedRows && selectedRows.length > 0) ? (
            <span>
                - {selectedRows.map(item => {
                    if (withTopLabel.includes(item)) {
                        return 'Top ' + item;
                    }
                    return item;
                }).join(', ')}
            </span>
        ) : '';


        return (
            <span>
                Filters Selected | Grouping: {CATEGORY[category]} {filtersText}
            </span>
        );
    }, [selectedRows, category]);

    const optionListStore = (storeData || [])
        .map((row) => ({
            value: row?.branch_id,
            label: row?.branch_name,
        }))
        .filter((option) => option.value !== "" && option.label !== "")
        .sort((a, b) => a.label?.localeCompare(b.label));

    function handleSelectStore(data) {
        let result = data.map(a => a.value);
        setStoreFilters(result)
        return result
    }

    const filteredData = useMemo(() => {
        return storeFilters?.length > 0 ? storeData.filter(obj => storeFilters.includes(obj?.branch_id)) : storeData;
    }, [storeFilters, storeData]);
    const showNAForPercentToSales = category === 'top_food_group' && selectedRows?.length > 0;
    
    const store_overview_col = useMemo(
        () => [
            {
                header: 'Store',
                accessorKey: 'branch_name',
                cell: (row) => <Box  sx={{paddingY: '.25rem'}}> {row?.getValue()} </Box>,
                customStyle: { width: '15rem' },
            },
            {
                header: 'Total Inventory',
                id: 'overview_total',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.row?.original?.store_tagging?.toLowerCase() === 'store' ? row?.getValue() : 'N/A'} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'inventory_value',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },
                ]
            },

            {
                header: 'Return To Vendor',
                id: 'overview_return_to_vender',
                columns: [
                    {
                        header: 'Value',
                        accessorKey: 'rtv',
                        cell: (row) => <Box sx={{ width: { sm: '15%', xs: '4rem' }, marginX: 'auto'}}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },
                    {
                        header: '% to Sales',
                        accessorKey: 'rtv_to_sales',
                        cell: (row) => <>
                            {showNAForPercentToSales ? 'N/A' :
                                <IndicatorCellBox>
                                    <BarIndicator colorFunctionFor={'inventory_rtv'} value={inventoryValidNumber(row?.getValue())?.toFixed(2)} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                                </IndicatorCellBox>
                            }
                        </>,
                        customStyle: { textAlign: 'center', verticalAlign: 'middle' }
                    },
                ]
            },

            {
                header: 'Damaged',
                id: 'overview_damaged',
                columns: [
                    {
                        header: 'Value',
                        accessorKey: 'ds',
                        cell: (row) => <Box sx={{ width: { sm: '15%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },
                    {
                        header: '% to Sales',
                        accessorKey: 'ds_to_sales',
                        cell: (row) => <>
                             {showNAForPercentToSales ? 'N/A' :
                                <IndicatorCellBox>
                                    <BarIndicator colorFunctionFor={'inventory_ds'} value={inventoryValidNumber(row?.getValue())?.toFixed(2)} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                                </IndicatorCellBox>
                            }
                        </>,
                        customStyle: { textAlign: 'center', verticalAlign: 'middle' }
                    }
                ]
            },
        ],
        [period,category,toggleState,selectedRows]
    )

    const store_product_col = useMemo(
        () => [
            {
                header: 'Store',
                id: 'store',
                accessorKey: 'branch_name',
                cell: (row) => <Box sx={{ marginY: '.3rem' }}> {row?.getValue()} </Box>,
                customStyle: { width: '15rem', },
            },
            {
                header: 'Fresh',
                id: 'product_fresh',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_fresh',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_fresh',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },

                ]
            },
            {
                header: 'Food 1',
                id: 'product_food1',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_food1',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_food1',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },

                ]
            },
            {
                header: 'Food 2',
                id: 'product_food2',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_food2',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_food2',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },

                ]
            },
            {
                header: 'Non-food',
                id: 'product_non_food',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_nonfood',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_nonfood',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },

                ]
            },
            {
                header: 'Dry Goods',
                id: 'product_dry_goods',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_drygoods',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_drygoods',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },

                ]
            },
        ],
        [period,category,toggleState,selectedRows]
    )

    const store_top888_col = useMemo(
        () => [
            {
                header: 'Store',
                id: 'store',
                accessorKey: 'branch_name',
                cell: (row) => <Box sx={{ marginY: '.3rem' }}> {row?.getValue()} </Box>,
                customStyle: { width: '15rem', },
            },
            {
                header: 'Top 888',
                id: 'top888',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_888',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_888',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },

                ]
            },
            {
                header: 'Top 4k',
                id: 'top4k',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_4k',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_4k',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },
                ]
            },
        ],
        [period,category,toggleState,selectedRows]
    )

    if (isLoading) {
        return <LoadingComponent />
    }

    const columnsByToggleState = {
        overview: store_overview_col,
        product: store_product_col,
        top888: store_top888_col
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }} mt={1}>
                <Grid container alignItems="center" py={.5}>
                    {
                        windowSize?.isOnMobile ? <>
                            <Grid item xs sm={2.5}>
                                <Typography variant='h6' ml={2} >Store Performance</Typography>
                            </Grid>
                            <Grid item xs={16} sm={3} display="flex" alignItems="center">
                                <DropdownCheckboxV3
                                    id="sales_store_filter"
                                    options={optionListStore}
                                    label="Store"
                                    placeholder="Select a store"
                                    limitTags={2}
                                    onChange={(event, newValue) => {
                                        handleSelectStore(getSelectedOption(newValue, optionListStore));
                                    }}
                                    value={getSelectedOption(storeFilters, optionListStore)}
                                />
                            </Grid>
                        </> : <>

                            <Grid item xs={14} sm={2}>
                                <Typography variant='h6' ml={2} >Inventory Store View</Typography>
                            </Grid>
                            <Grid item xs={16} sm={3} display="flex" alignItems="center">
                                <DropdownCheckboxV3
                                    id="sales_store_filter"
                                    options={optionListStore}
                                    label="Store"
                                    placeholder="Select a store"
                                    limitTags={2}
                                    onChange={(event, newValue) => {
                                        handleSelectStore(getSelectedOption(newValue, optionListStore));
                                    }}
                                    value={getSelectedOption(storeFilters, optionListStore)}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid item xs={12} marginBottom={2} sx={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>
                <Grid item xs={12} sm={12} sx={{ boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)', border: '1px solid #e0e0e0', backgroundColor: '#e1ebf2' }}>
                    <Typography ml={2} sx={{ borderRadius: '0', color: '#000' }}>{activeFiltersText}</Typography>
                </Grid>
                <Item>
                    <TableComponentV2
                        customTableContainerStyle={{
                            borderRadius: "0",
                            boxShadow: 'none',
                        }}
                        customTableHeaderStyle={{
                            backgroundColor: '#caddeb'
                        }}
                        columns={columnsByToggleState[toggleState] }
                        data={filteredData}
                        initialSort={{ id: 'inventory_value', desc: true }}
                        hasPagination={true}
                        isInventoryTable={true}
                        isCompact={true}
                    />
                </Item>
            </Grid>
        </>
    );
}