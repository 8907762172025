import {useQuery} from "react-query";
import ApiClient from "../../../common/API";
import {TABLE_CUSTOMER_ATTRIBUTES, TABLE_CUSTOMER_METRICS} from "./constants";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../Constants/settings";
import moment from "moment";

export function useAvailableDateRange() {
    const monthAbbreviations = {
        1: '01',
        2: '02',
        3: '03',
        4: '04',
        5: '05',
        6: '06',
        7: '07',
        8: '08',
        9: '09',
        10: '10',
        11: '11',
        12: '12',
    };
    const dates = useQuery(
        [TABLE_CUSTOMER_METRICS, 'customers', "date_range"],
        () => ApiClient().get(`data/table/${TABLE_CUSTOMER_METRICS}/query/`, {
            params: {
                columns: "year,month",
                group_by: "year,month",
                limit: 1000
            }
        }).then(res => {
            const transactionDates = res?.data?.data?.map(row => {
                const formattedMonth = monthAbbreviations[row?.month]
                return [
                    moment(`${row?.year}-${formattedMonth}-01`).toDate(),  // start date
                    moment(`${row?.year}-${formattedMonth}-01`).endOf('month').toDate() // end date
                ]
            }).flat().sort((a, b) => a - b)
            return {
                "min": transactionDates[0],
                "max": transactionDates[transactionDates.length - 1]
            }
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
    return dates;
}

// uses header_tran_date from l3_sbu_customer_v2
export function useAvailableDateRangeV2() {
    return useQuery(
        [TABLE_CUSTOMER_ATTRIBUTES, 'customers', "date_range"],
        () => ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/filter_values/`, {
            params: {
                columns: "header_tran_date"
            }
        }).then(res => {
            return res.data.header_tran_date
        }), {
            ...USE_QUERY_DEFAULT_OPTIONS
        }
    );
}

export const useAvailableChannels = (useQueryOptions) => {
    const columnList = [
        'cm_channel_desc',
    ]

    const queryKey = [TABLE_CUSTOMER_ATTRIBUTES, columnList]

    return useQuery(queryKey, () => {
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                columns: columnList.join(','),
                group_by: columnList.join(','),
            }
        }).then(res => {
            return res.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
}

