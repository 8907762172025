import React, { useState } from 'react';
import ChartList from "./ChartList";
import ChartSelector from "./ChartSelector";
import {
    useTableDataFilterColumns,
    useTableDetail,
    useTableList
} from "../../../common/API/hooks";
import Sort from './Sort';
import { Grid, FormGroup, FormControlLabel, Typography, Box, Checkbox, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

export default function ChartGallery() {
    const [selectedChartType, setSelectedChartType] = useState(null);
    const { data: tableList, isLoading } = useTableList();

    const selectedTableId = isLoading || !tableList || tableList.length === 0 ? null : tableList[0].id;
    const { data: filterList, isLoading: isLoadingFilters } = useTableDataFilterColumns(selectedTableId, {
        enabled: !!selectedTableId,
    });

    const [sortField, setSortField] = useState('created_at'); // Default sort field
    const [sortOrder, setSortOrder] = useState('desc'); // Default sort order: 'asc' for ascending, 'desc' for descending
    const [showAllCharts, setShowAllCharts] = useState(false);

    return (
        <>
            <Grid sx={{ mt: 4 }}>
                <Grid container>
                    <Grid item md={6} xs={12}>
                        <Box display={'flex'}>
                            <Typography variant='h5'>
                                Chart Gallery
                                {/* TODO: to apply when info message is available */}
                                {/* <Tooltip title={<Typography variant="body2">Chart Gallery</Typography>}
                                    placement='right'> */}
                                <IconButton>
                                    <InfoIcon style={{ color: 'grey' }} />
                                </IconButton>
                                {/* </Tooltip> */}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Stack display={'flex'} direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'}>
                            <FormControlLabel
                                label="Show All Charts"
                                labelPlacement="end"
                                sx={{ width: 'auto', minWidth: '150px'}}
                                // sx={{ maxWidth: '100%', width: '60%' }}
                                control={<Checkbox
                                    checked={showAllCharts}
                                    onChange={(e) => setShowAllCharts(e.target.checked)}
                                    id="show_all_charts"
                                />
                                }
                            />
                            <ChartSelector
                                selectedChartType={selectedChartType}
                                setSelectedChartType={setSelectedChartType}
                            />
                            <Sort
                                sortField={sortField}
                                setSortField={setSortField}
                                sortOrder={sortOrder}
                                setSortOrder={setSortOrder}
                            />
                        </Stack>
                    </Grid>
                </Grid >
            </Grid >
            <Divider sx={{ my: 3, borderBottom: 3 }} />
            < Grid sx={{ mt: 3, ml: 3 }
            }>
                <ChartList sortField={sortField} sortOrder={sortOrder} selectedChartType={selectedChartType} showAllCharts={showAllCharts} />
            </Grid >
        </>
    );
}
export { SELECTOR_TYPES_BY_CHART_TYPE } from "./constants";
export { DEFAULT_CHART_DATA } from "./constants";