import React, {useContext, useMemo} from "react";
import MetricPolygon from "./LeafletElements/MetricPolygon";
import {
    filterPolygons,
    getMaxMetricValue,
    getMinMetricValue,
    getPolygonColor,
    markOutsideBoundPolygons,
    usePolygon
} from "./util";
import {StoreAnalyticsFilterContext} from "../contexts";


function fillDisplayMetric(polygonData, populationFilters) {
    if (!polygonData.hasOwnProperty('demographic') && polygonData.hasOwnProperty('population2020')) {
        return {
            ...polygonData,
            _displayMetric: polygonData.population2020
        }
    }
    if (!polygonData.hasOwnProperty('demographic') || !polygonData.demographic) {
        return {
            ...polygonData,
            _displayMetric: 0
        }
    }
    // FIXME: population filters are inverted
    const demographicsFilteredByGender = (!populationFilters.gender || populationFilters.gender.length === 0) ?
        polygonData.demographic : polygonData.demographic.filter(row => !populationFilters.gender.includes(row.gender))
    const demographicsFilteredByAgeGroup = (!populationFilters.ageGroup || populationFilters.ageGroup.length === 0) ?
        demographicsFilteredByGender : demographicsFilteredByGender.filter(row => !populationFilters.ageGroup.includes(row.ageGroup))
    const sumPopulation = demographicsFilteredByAgeGroup.reduce((acc, val) => acc + val.population, 0)
    return {
        ...polygonData,
        _displayMetric: sumPopulation,
        "Filtered Population": sumPopulation,
    }
}

export default function PopulationMetricPolygons(
    {
        visibleBounds,
        populationFilters,
        polygonLevel,
        selectedProvince
    }
) {
    const {region} = useContext(StoreAnalyticsFilterContext)

    const {polygonData, isLevelLoading: isPolygonLoading} = usePolygon(polygonLevel,region,selectedProvince)

    const dataWithDisplayMetric = useMemo(() => {
        if (!polygonData) return []
        return polygonData?.map(val => fillDisplayMetric(val, populationFilters))
    }, [polygonData, populationFilters])

    const maxMetricValue = getMaxMetricValue(dataWithDisplayMetric)
    const minMetricValue = getMinMetricValue(dataWithDisplayMetric)

    const shownPolygonData = useMemo(() => {
        const visiblePolygons = markOutsideBoundPolygons(dataWithDisplayMetric, visibleBounds, polygonLevel)
        return filterPolygons(visiblePolygons, region)
    }, [polygonLevel, region, dataWithDisplayMetric]);

    if (isPolygonLoading) {
        return null
    }

    return shownPolygonData.map(data => {
        const opacityVal = (data._displayMetric - minMetricValue) / (maxMetricValue - minMetricValue);
        data['_opacity'] = opacityVal

        return <MetricPolygon
            key={`${data.id}-${opacityVal}`}
            color={getPolygonColor(opacityVal * 100)}
            IS_LONG_LAT={true}
            data={data}
        />
    })

}