import SelectWrapper from '../../../common/components/SelectWrapper';
import useTargetOptions from '../../../common/hooks/useTargetOptions';

export default function TargetSelectionContent(props) {

   
    const { isLoading, options } = useTargetOptions('/targetFile');
    function handleTargetBy(name, value) {
        props.onChange(name, value)
        // props.onChange('targetCount', '')
        // props.onChange('budget', '')
    }

    return <>
        {/* <label className="fw-semibold me-4 pb-2"> Mall location </label>
        <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" onChange={(e)=> props.onChange(e,'radio')} name="mall_location" id="national" value={'national'} checked={props.values.mall_location === 'national'}/>
            <label className="form-check-label" htmlFor="national">National</label>
            </div>
            <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" onChange={(e)=> props.onChange(e,'radio')} name="mall_location" id="specific" value={'mall_specific'} checked={props.values.mall_location === 'mall_specific'}/>
            <label className="form-check-label" htmlFor="specific">Specific Mall</label>
        </div> */}

        <div className="row mb-2">
            <label className="fw-semibold col-sm-2">Targets <small>*</small></label>
            <div className="col-sm-10">
                <SelectWrapper options={options} isLoading={isLoading} onChange={(e) => props.onChange('targetFile', e ? e.value : null)} value={props.values.targetFile} optionStyleFor='targetFile'/>
            </div>
        </div>



        {/* <div className="row mb-2">
            <label className="fw-semibold col-sm-2">Limit target by</label>
            <div className="col-sm-10">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" onChange={(e)=> handleTargetBy(e.target.name, e.target.value) } name="targetBy" id="budget" value="budget" checked={props.values.targetBy === 'budget'} />
                    <label className="form-check-label" htmlFor="budget">Budget</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" onChange={(e)=> handleTargetBy(e.target.name, e.target.value) } name="targetBy" id="targetCount" value="count" checked={props.values.targetBy === 'count'} />
                    <label className="form-check-label" htmlFor="targetCount">Target Count</label>
                </div>
            </div>
        </div> */}

        {/* 1 target = 0.25 budget  */}
        {/* <div className="row mb-2">
            <label className="fw-semibold col-sm-2">Budget <small>*</small></label>
            <div className="col-sm-10">
                <input className="form-control" type="number"  onChange={ (e)=> props.onChange(e.target.name,e.target.value)  } name="budget" 
                    value={props.values.targetBy === 'count'  ?  Math.ceil(props.values.targetCount / 4) : props.values.budget} 
                    disabled={ props.values.targetBy === 'count' }/>
            </div>
        </div>

        <div className="row mb-2">
            <label className="fw-semibold col-sm-2">Target count <small>*</small></label>
            <div className="col-sm-10">
                <input className="form-control" type="number"  onChange={ (e)=> props.onChange(e.target.name,e.target.value) } name="targetCount" 
                    value={props.values.targetBy === 'budget'  ?  props.values.budget * 4 : props.values.targetCount} 
                    disabled={ props.values.targetBy === 'budget' }/>
            </div>
        </div> */}
    </>
}