import { createContext, useContext } from 'react';

export const ToggleContext = createContext({ toggleState: '', updateToggleState: (value) => { } });
export const useToggleContext = () => useContext(ToggleContext);

export const FiltersContext = createContext(
    {
        filters: { company_id: [], month: '', year: '' },
        setFilters: (newFilters) => { },
        setFilter: (key, value) => { }
    }
);

export const CategoryContext = createContext(
    {
        category:'',
        setCategory: (newCategory) => { },
    }
)

export const useFiltersContext = () => useContext(FiltersContext);

export const useCategoryContext = () => useContext(CategoryContext)