import React from "react";
import Content from "./content";
import { Responsive, WidthProvider } from "react-grid-layout";
import  Box from "@mui/material/Box";

const Layout = ({ dashboardLayoutValue, content, filters }) => {
  const ResponsiveReactGridLayout = WidthProvider(Responsive);

  // for each viewport, make everything static
  const staticLayout = {
    lg: dashboardLayoutValue.layouts.lg.map((layoutItem) => {
      return {
        ...layoutItem,
        static: true,
        isDraggable: false,
        isResizable: false,
      };
    }),
  };

  return (
    <>
      <ResponsiveReactGridLayout
        className="bg-transparent border-0 shadow-none"
        layouts={staticLayout}
        rowHeight= {50}
        measureBeforeMount={false}
        useCSSTransforms={false}
        compactType={dashboardLayoutValue.compact_type}
        isDraggable={false}
        isResizable={false}
      >
        {dashboardLayoutValue.layouts.lg?.map((layoutItem) => {
          const layoutItemContent = (content || {})[layoutItem.i];
          return (
            // <div
            //   key={layoutItem.i}
            // className={
            //   "view-mode border-0 shadow-none " +
            //   (layoutItem.resizable ? "" : " react-resizable-handle-hidden")
            // }
            // >
            //   <Content contentObject={layoutItemContent} filters={filters} />
            // </div>
            <Box  key={layoutItem.i} class={
                "view-mode border-0 shadow-none " +
                (layoutItem.resizable ? "" : " react-resizable-handle-hidden")
              }>
                <Content contentObject={layoutItemContent} filters={filters} />
            </Box>
          );
        })}
        
      </ResponsiveReactGridLayout>
    </>
  );
};

export default Layout;
