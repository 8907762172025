import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import DeleteGroup from './DeleteGroup'
import EditGroup from './EditGroup'
import { useGroupList } from '../../../common/API/hooks';
import LoadingComponent from '../../../common/components/LoadingComponent';
import { IconButton, Menu, Typography, MenuItem, Grid } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Fuse from 'fuse.js';

const customStyles = {
    rows: {
        style: {
            minHeight: '3.5rem',
        },
    },
    headRow: {
        style: {
            fontWeight: "900",
            backgroundColor: '#F6F6F6',
        },
    },
    pagination: {
        style: {
            backgroundColor: '#ffff',
        },
    },
};

function MenuInTable(row) {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    return <>
    <Divider orientation="vertical" flexItem />

    <Tooltip title="Edit">
        <IconButton aria-label= "Edit" size="small">
            <EditRoundedIcon 
            onClick={() => setIsEditModalOpen(true)}
            fontSize="small"
            />
        </IconButton>
    </Tooltip>

    <Tooltip title="Delete" backgroundColor="white">
        <IconButton aria-label="Delete" size="small">
            <DeleteRoundedIcon
            onClick={() => setIsDeleteModalOpen(true)} 
            sx={{ color: "red" }}
            fontSize="small"
            />
        </IconButton>
    </Tooltip>

    <EditGroup group={row} isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} />
    <DeleteGroup group={row} isOpen={isDeleteModalOpen} setsetIsOpen={setIsDeleteModalOpen} />
    </>
    
}

export default function GroupTable({ searchQuery }) {

    function formatArray(values) {
        return values.join(', ');
    }
    
    const { data: groupList, isLoading: groupLoading } = useGroupList();
    
    const filteredGroups = useMemo(() => {
        if (!groupList || groupLoading || !searchQuery) {
            return groupList || [];
        }
    
        const options = {
            keys: ['name'],
            threshold: 0.3, // Adjust the threshold as needed
        };
    
        const fuse = new Fuse(groupList, options);
        const query = searchQuery.toLowerCase();
    
        return fuse.search(query).map(result => result.item);
    }, [groupLoading, groupList, searchQuery]);

    const columns = [
        {
            name: 'GROUP NAME',
            selector: (row) => row.name,
            sortable: true,
            sortFunction: (a, b) => a.name.localeCompare(b.name),
        },
        {
            name: 'PERMISSIONS',
            selector: (row) => row.permissions,
            sortable: true,
            maxWidth: '100ch',
            sortFunction: (a, b) => {
              const permissionsA = formatArray(a.permissions).toLowerCase();
              const permissionsB = formatArray(b.permissions).toLowerCase();
              return permissionsA.localeCompare(permissionsB);
            },
            cell: (row) => (
              <div className="text-truncate" data-tag="allowRowEvents">
                {formatArray(row.permissions)}
              </div>
            ),
          },
          
        {
            name: 'USERS',
            selector: (row) => row.users,
            sortable: true,
            format: (row) => {
                if (row.is_public) {
                    return "All Users";
                } else {
                    return `${row.users.length} User${row.users.length > 1 ? "s" : ""}`;
                }
            },
            grow: 1,
            sortFunction: (a, b) => {
                if (a.is_public && !b.is_public) {
                    return -1;
                } else if (!a.is_public && b.is_public) {
                    return 1;
                } else {
                    
                    return a.users.length - b.users.length;
                }
            },
        },
        {
            cell: (row) => (

                <MenuInTable row={row} />

            ),
            grow: 1,
            ignoreRowClick: true,
            button: true,
        },
    ];

    const ExpandableComponent = ({ data }) => {
        return (
            <>
                <Grid container ml={3}>
                    <Typography variant='inherit' fontWeight='bold'>Permissions Allowed:</Typography>
                    <Grid container >
                        {[0, 1, 2].map((columnIndex) => (
                            <Grid item xs={6} sm={6} md={4} key={columnIndex}>
                                <ul>
                                    {data.permissions.map((permission, index) => {
                                        if (index % 3 === columnIndex) {
                                            return <li key={index}>{permission}</li>;
                                        }
                                    })}
                                </ul>
                            </Grid>
                        ))}
                    </Grid>
                    
                    <Typography variant='inherit' fontWeight='bold'>User List:</Typography>
                    <Grid className="row" container >
                        {[0, 1, 2].map((columnIndex) => (
                            <Grid item xs={6} sm={6} md={4} component='div' key={columnIndex} container>
                                <ul>
                                    {data.users.map((users, index) => {
                                        if (index % 3 === columnIndex) {
                                            return <li key={index}>{users}</li>;
                                        }
                                    })}
                                </ul>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <DataTable
            columns={columns}
            data={filteredGroups}
            pagination
            highlightOnHover
            customStyles={customStyles}
            progressPending={groupLoading}
            progressComponent={<LoadingComponent />}
            expandableRows
            expandOnRowClicked
            expandableRowsComponent={ExpandableComponent}
        />
    );
}