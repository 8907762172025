// these are the keys that when used, will be used to filter the data from the API
// if the key is not in this list, the filter is applied to the frontend data after it is fetched
export const apiFilterKeys = [
    'business_units',
    'channel',
    'higher_mbu',
    'business_unit',
    'zone',
    'cm_channel_desc',
    'pm_business_unit_desc_standardized',
    'store_zone'

]

export const zoneOrderArray = ['NCR 1', 'NCR 2', 'NCR 3', 'North Luzon 1', 'North Luzon 2', 'North Luzon 3', 'South Luzon 1', 'South Luzon 2', 'Visayas 1', 'Visayas 2', 'Mindanao', 'Online']
