import CollapsibleComponent from "../../../../../../../common/components/CollapsibleComponent"
import { useStoreFootTrafficConversionL3 } from "../../DataProvider/hooks";
import { Grid, Box, Typography, Collapse } from "@mui/material"
import React, { useState, useContext, useMemo } from "react";
import { WindowSizeContext } from "../../../../../../../common/context/WindowSizeContext";
import LoadingComponent from "../../../../../../../common/components/LoadingComponent";
import TableComponentV2 from "../../../../../../../common/components/TableComponentV2";
import { convertNumberToLocaleString, convertToValidNumber } from "../../../../../../../common/utils";
import BarIndicator from "../../../barIndicator";
import NumericValueDisplay from "../../../numericValueDisplay";
import BarTrends from "../../../../../TmpTSSSales/BarTends";
import { checkIfCompletePeriod, getPast12monthsL3 } from "../../../../../TmpTSSSales/util";
import { useDynamicGraphInTableDataL3 } from "../../../../../TmpTSSSales/hooks";
import moment from "moment/moment";
import { getBarTrendsColumnHeader } from "../../../../../TmpTSSSales/DataProvider/util";
import { useAvailableMaxDateL3 } from "../../../../../TmpTSSSales/DataProvider/hooks";


export default function FootTrafficConversion({ params, filters, onDropDownFilterChange, storeID, store, zone, selectedRows, onSelectRow,
    period, onTogglePeriodChange }) {
    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';
    const [isExpand, setExpand] = useState(true);
    const barTrendsColumnHeaders = getBarTrendsColumnHeader(filters?.metric_column);
    const windowSize = useContext(WindowSizeContext);

    const TOGGLE_OPTIONS = [
        { label: 'MTD', value: 'mtd' },
        { label: 'YTD', value: 'ytd' },
    ]

    const paramsBasedOnPeriod = isQoQ
        ? { ...params,
            month: Array.from({ length: 3 }, (_, i) =>
                moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month() + i + 1
            )
        }
        : isMTD ? { ...params } : { ...params, month: [1, ...(params?.month || [])] };    


    const { data: maxDate, isLoading: isLoadingMaxdateL3 } = useAvailableMaxDateL3();

    const { monthYear, ...dateParamsForTrendBar } = getPast12monthsL3(params?.month?.[0], params?.year, maxDate)

    const paramsForPerGroupL3 = (() => {
        if(storeID) {
            return {
                ...paramsBasedOnPeriod,
                group_by:'store_parent_store_id',
                columns: 'store_parent_store_id' ,
                store_parent_store_id: `${storeID}`,
            }
        } else {
            return{
                ...paramsBasedOnPeriod,
                group_by:'store_zone',
                columns:'store_zone',
                store_zone: `${zone}`
            }
        }
    })()

    const { data: storeFootTrafficDataL3, isLoading: isLoadingStoreFootTrafficDataL3} = useStoreFootTrafficConversionL3({
        params: {
            ...paramsForPerGroupL3,
            metric_column: filters?.metric_column ? filters?.metric_column : 'actual_sales',
        }
    });

    const { data: footTrafficGraphDataL3, isLoading: isFootTrafficGraphDataLoadingL3 } = useDynamicGraphInTableDataL3({
        params: {
            ...paramsForPerGroupL3,
           ...dateParamsForTrendBar
        },
        queryOptions: {
            hasTranDateLteGte: dateParamsForTrendBar,
        }
    })

    const formatData = (data) => {
        if (!data) return [];

        return data.map(row => ({
            ...row,
            subRows: row.subRrows.map(subRow => ({
                ...subRow,
                isExpanded: true // Ensure subRows are expanded by default
            }))
        }));
    };

    const isCompletePeriod = checkIfCompletePeriod(params, maxDate, isQoQ)

    const performance_per_group_columns = useMemo(
        () => [
            {
                id: 'key_metrics',
                header: 'Key Metrics',
                accessorKey: 'keyMetrics',
                cell: ({ row, getValue }) => (
                    <Box sx={{ marginLeft: `${row.depth * 2}rem`, whiteSpace: 'nowrap', fontWeight: row.depth === 0 ? 'bold' : 'normal' }}>
                        {getValue()}
                    </Box>
                ),
                customStyle: { width: '18rem' }
            },
            {
                id: 'actual',
                header: 'Actual',
                accessorKey: 'metricTotal',
                cell: (row) => {
                    const isMallOrInStoreFootTraffic = row.row.original.keyMetrics === 'Mall Foot Traffic' || row.row.original.keyMetrics === 'Dept Store Foot Traffic';

                    return (
                        <Box sx={{ marginX: 'auto', fontWeight: isMallOrInStoreFootTraffic ? 'bold' : 'normal' }}>
                            <NumericValueDisplay value={row?.getValue()} postfix={isMallOrInStoreFootTraffic ? '' : '%'} isAbbreviated={isMallOrInStoreFootTraffic ? true : false} />
                        </Box>
                    );
                },
                customStyle: { width: '15rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                id: 'vs_last_year',
                header: 'vs Last Year',
                accessorKey: 'vsLY',
                cell: (row) => <>
                    {convertNumberToLocaleString(row?.getValue()) !== 0 ?
                        <Box sx={{ width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }}>
                            <BarIndicator value={convertToValidNumber(row?.getValue())?.toFixed()} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                        </Box> : '-'
                    }
                </>,
                customStyle: { width: '15rem', height: '2rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                id: '12th_mth_sales_trend',
                header: '12 Mth Foot Traffic Trend vs Plan',
                cell: (row) => {
                    const keyMetrics = row?.row?.original?.keyMetrics;
                    const metric = keyMetrics === 'Mall Foot Traffic' ? 'actual_mall_foot_traffic'
                        : keyMetrics === 'Catchment Rate' ? 'actual_catchment_rate'
                            : keyMetrics === 'Dept Store Foot Traffic' ? 'actual_sm_store_foot_traffic'
                                : keyMetrics === 'Conversion Rate' ? 'actual_conversion_rate' : '';
                    const newGraphData = footTrafficGraphDataL3[storeID || zone]?.filter(data => monthYear.includes(data?.year_month))

                    return <>
                        {
                            isFootTrafficGraphDataLoadingL3 ? <LoadingComponent /> : <Box sx={{ width: '13rem', height: '2.5rem', marginX: 'auto' }}>
                                <BarTrends data={newGraphData} metric={metric} />

                            </Box>
                        }
                    </>
                }
            }
        ],
        [formatData, isMTD, storeFootTrafficDataL3, footTrafficGraphDataL3]
    )

    if (isLoadingStoreFootTrafficDataL3 || isFootTrafficGraphDataLoadingL3) {
        return <LoadingComponent />
    }


    return (
        <>
            <Box flexGrow={1} xs={12} mt={2} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container alignItems="center" py={.5}>
                    {
                        windowSize.isOnMobile ? <>
                            <Grid item xs display="flex" >
                                <Typography variant="h6" ml={2.5}>
                                    Foot Traffic, Conversion & Catchment
                                </Typography>
                            </Grid>
                            <Grid item justifyContent={'end'}>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                        </> : <>
                            <Grid item xs={8} >
                                <Typography variant="h6" ml={2.5}>
                                    Foot Traffic, Conversion & Catchment
                                </Typography>
                            </Grid>
                            <Grid item xs={4} justifyContent={'end'}>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Collapse in={isExpand}>
                        <TableComponentV2
                            columns={performance_per_group_columns}
                            data={formatData(storeFootTrafficDataL3)}
                            tableId={'footTrafficConversion'}
                            isCompact={true}
                            customTableHeaderStyle={{
                                backgroundColor: '#caddeb'
                            }}

                            initialState={{
                                columnVisibility: { 
                                    // "vs_last_year": isCompletePeriod, 
                                    "12th_mth_sales_trend": !isMTD && !isQoQ 
                                }
                            }}
                            isOpexAccounts={true}
                        />
                    </Collapse>
                </Grid>
            </Grid>
        </>
    );
}   