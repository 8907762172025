import { useContext } from 'react';
import { ModulePermissionsContext } from '../../../common/context/ModulePermissionsContext';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from './CardContents';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import KeyIcon from '@mui/icons-material/Key';
import RegistrationIcon from '@mui/icons-material/HowToReg';
import TimelineIcon from '@mui/icons-material/Timeline';

function hasAccess(strModule, isParent, data) {
    if (isParent) {
        const parentLinks = data.map(module => module?.split('/')?.[0])
        return parentLinks?.length > 0 && parentLinks.includes(strModule)
    } else {
        return data?.length > 0 && data.includes(strModule)
    }
}

export default function Dashboard() {
    const { moduleList } = useContext(ModulePermissionsContext)

    const theme = createTheme();
    const screenSize = useMediaQuery(theme.breakpoints.down('md'));

    console.log("moduleList", moduleList)

    return (
        <Grid container spacing={2} sx={{ mt: 5 }}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h5' ml={1} mb={1}>
                                Admin Management
                            </Typography>

                            <Card style={{ border: "none", boxShadow: "none" }}>
                                <Grid container spacing={2} p={2} direction={'row'}>
                                    <CardContent
                                        name={'User Management'}
                                        icon={<PersonIcon sx={{ width: '2em', height: 'auto', marginTop: "12px"}}/>}
                                        path={'/user-management'}
                                        access={hasAccess('user-management', false, moduleList)}
                                    />
                                    <CardContent
                                        name={'Group Management'}
                                        icon={<GroupsIcon sx={{ width: '2em', height: 'auto', marginTop: "12px" }}/>}
                                        path={'/group-management'}
                                        access={hasAccess('group-management', false, moduleList)}
                                    />
                                    <CardContent
                                        name={'Module Permission'}
                                        icon={<KeyIcon sx={{ width: '2em', height: 'auto', marginTop: "12px" }}/>}
                                        path={'/module-permission'}
                                        access={hasAccess('module-permission', false, moduleList)}
                                    />
                                    <CardContent
                                        name={'Registrations'}
                                        icon={<RegistrationIcon sx={{ width: '2em', height: 'auto', marginTop: "12px" }}/>}
                                        path={'/registration-management'}
                                        access={hasAccess('user-management', false, moduleList)}
                                    />
                                    <CardContent
                                        name={'Login History'}
                                        icon={<TimelineIcon sx={{ width: '2em', height: 'auto', marginTop: "12px" }}/>}
                                        path={'/login-history'}
                                        access={hasAccess('user-management', false, moduleList)}
                                    />
                                </Grid>
                            </Card>
                        </Grid>
        </Grid>
    )
}