import CardComponent from "../../../common/components/CardComponent";
import { useState } from "react";
import TargetSelectionContent from "./targetSelectionContent";
import CampaignCreationContent from "./campaignCreationContent";
import DeploymentContent from "./deploymentContent";
import MobilePreview from "./mobilePreview";
import CampaignOverview from "./campaignOverview";
import CampaignResult from "./campaignResult";

function RenderContent(props) {
    switch(props.step) {
        case 1 :
            return <>
                <CardComponent title="Target Selection" id="collapseTargetSelectionCard" size="small">
                    <TargetSelectionContent values={props.values} onChange={props.onChange} />
                </CardComponent>
            </>
        
        case 2 :
            return <CampaignCreationContent values={props.values} onChange={props.onChange} isFallbackSmsRequired={props.isFallbackSmsRequired} setIsFallbackSmsRequired={props.setIsFallbackSmsRequired}/>

        case 3 : 
            return <DeploymentContent values={props.values} onChange={props.onChange} />
        
        case 4 : 
            return <CampaignOverview values={props.values} />
        
        case 5 : 
            return <CampaignResult values={props.values} setCampaignStatus={props.setCampaignStatus} />

        default : 
            // do nothing
    }
}

export default function CampaignCreation() {

    const [step, SetStep] = useState(1);
    const [campaignData, setCampaignData] = useState({
        mall_location : '',
        mall : '',
        targetFile: '',
        targetBy : 'budget',
        budget : '',
        targetCount : '',
        channel : 'viber',
        targets : '',
        fromSms: null,
        fromViber: null,
        FromEmailId: null,
        imageSrc : '',
        title : '',
        contentViber: '',
        contentSms : '',
        actionUrl : '',
        actionCaption : '',
        date: new Date(),
        time: '',
        contentEmail: '', //email body
        subjectEmail: '' //email subjectEmail
    });
    const [isStatusDone, setIsStatusDone] = useState(false);
    const [isFallbackSmsRequired, setIsFallbackSmsRequired] = useState(false);
    
    function handleInputChange(name, value) {        
        setCampaignData({...campaignData, 
            [name] : value
        })
    }

    function stepItemClassName(val) {
        if(val === step) {
            return "btn btn-primary btn-sm rounded-circle"
        } else if(val < step) {
            return "btn btn-primary done-step btn-sm rounded-circle"
        } else {
            return "btn btn-secondary btn-sm rounded-circle"
        }
    }

    function newCampaign() {
        SetStep(1)
        setCampaignData({
            mall_location : '',
            mall : '',
            targetFile: '',
            targetBy : 'budget',
            budget : '',
            targetCount : '',
            channel : 'viber',
            targets : '',
            fromSms: null,
            fromViber: null,
            FromEmailId: null,
            imageSrc : '',
            title : '',
            contentViber: '',
            contentSms : '',
            actionUrl : '',
            actionCaption : '',
            date: new Date(),
            time: '',
            contentEmail: '', //email body
            subjectEmail: '' //email subjectEmail
        })
    }
    
    function disableContinueBtn() {
        switch(step) {
            case 1 : 
                // return  campaignData.targetFile === "" || campaignData.targetBy === "" || ((campaignData.budget === "" || campaignData.targetCount === 0) && (campaignData.targetCount === "" || campaignData.budget === 0) )
                return  campaignData.targetFile === "" 
            case 2 : 
                if(campaignData.channel === "viber") {
                    return campaignData.title === "" || campaignData.fromViber === null || campaignData.contentViber === "" || (isFallbackSmsRequired && (campaignData.fromSms === null || campaignData.contentSms === ""))
                } else if(campaignData.channel === "sms") {
                    return campaignData.title === "" || campaignData.fromSms === null || campaignData.contentSms === ""
                } else {
                    return campaignData.title === "" || campaignData.FromEmailId === null || campaignData.subjectEmail === ""
                }
            case 3 :
                return campaignData.date === "" || campaignData.time === ""
                
            default:
                return true
        }
    }

    const renderButton = step === 4 ? <button className="btn btn-success" type="button" onClick={ ()=>SetStep(step + 1)}>Create Campaign</button> : <button className="btn btn-primary" type="button"onClick={ ()=>SetStep(step + 1)}  disabled={disableContinueBtn()}>Continue</button>

    return (
        
        <>        
            <CardComponent title="Campaign Creation" id="collapseCampaignCreationCard" showCaret={false}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="d-flex justify-content-between mb-3">
                            <div className="text-center align-items-center">
                                <button type="button" className={stepItemClassName(1)} style={{ width:"32px", height:"32px"}} >1</button>
                                <div><small>Selecting Targets</small></div>
                            </div>
                            <hr className="w-100"/>
                            <div className="text-center align-items-center">
                                <button type="button" className={stepItemClassName(2)} style={{ width:"32px", height:"32px"}} disabled={step < 2}>2</button>
                                <div><small>Creating Campaign</small></div>
                            </div>
                            
                            <hr className="w-100"/>
                            <div className="text-center align-items-center">
                                <button type="button" className={stepItemClassName(3)} style={{ width:"32px", height:"32px"}} disabled={step < 3}>3</button>
                                <div><small>Blasting Schedule</small></div>
                            </div>

                            <hr className="w-100"/>
                            <div className="text-center align-items-center">
                                <button type="button" className={stepItemClassName(4)} style={{ width:"32px", height:"32px"}} disabled={step < 4}>4</button>
                                <div><small>Campaign Overview</small></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <RenderContent step={step} values={ campaignData } setCampaignStatus={setIsStatusDone} onChange={handleInputChange} isFallbackSmsRequired={isFallbackSmsRequired} setIsFallbackSmsRequired={setIsFallbackSmsRequired}/>

                        
                        {
                            step <= 4 ? <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button className="btn btn-secondary me-md-2" type="button" onClick={ ()=>SetStep(step - 1)} disabled={step === 1}>Cancel</button>
                                { renderButton } 
                            </div> :  <div className="d-grid gap-2  justify-content-center">
                                            <button className="btn btn-primary me-md-2" type="button"  onClick={ ()=> newCampaign() } disabled={ !isStatusDone}>Create New Campaign</button>
                                        </div> 
                        }

                    </div>
                    
                    { step === 2 && <MobilePreview values={ campaignData } /> }
                </div>
            </CardComponent>
        </>
    )
}