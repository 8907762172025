import React, { useState } from "react"
import { toast } from 'react-toastify';
import { usePermissionList, useUserList } from "../../../common/API/hooks";
import { useSave } from "../utils";
import { getSelectedOption } from "../../../common/utils";
import { Button, Grid, TextField, Typography, Divider } from "@mui/material";
import { IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DropdownCheckboxV3 from "../../../common/components/DropdownCheckboxV3";
import Modal from '@mui/material/Modal'
import Card from '@mui/material/Card';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import handleError from "../ErrorHandling"

export default function AddGroup() {
    const [isOpen, setIsOpen] = useState(false);
    const { data: permissionList, isLoading: permissionLoading } = usePermissionList()
    const { data: userList, isLoading: userLoading } = useUserList()

    const [groupData, setGroupData] = useState({
        groupName: '',
        permissions: [],
        users: [],
    });

    const { groupName, permissions, users } = groupData

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setGroupData({
            groupName: '',
            permissions: [],
            users: [],
        })
        setIsOpen(false);
    };

    const { saveData: saveGroups } = useSave()

    const permissionOptions = React.useMemo(() => {
        if (permissionLoading) {
            return []
        }
        if (!permissions) {
            return []
        }
        return permissionList?.map((permission) => {
            return {
                value: permission.name,
                label: permission.name,
                key: permission.id,
            }
        })
    }, [permissions, permissionLoading]);

    const userOptions = React.useMemo(() => {
        if (userLoading) {
            return []
        }
        if (!users) {
            return []
        }
        return userList?.map((user) => {
            return {
                value: user.username,
                label: user.username,
                key: user.id,
            }
        })
    }, [users, userLoading]);

    const handleNameChange = (e) => {
        const { name, value } = e.target;
        setGroupData((prevUserData) => ({
            ...prevUserData,
            groupName: value
        }));
    };

    const handlePermissionChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value)
        setGroupData((prevUserData) => ({
            ...prevUserData,
            permissions: selectedValues
        }));
    };

    const handleUserChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value)
        setGroupData((prevUserData) => ({
            ...prevUserData,
            users: selectedValues
        }));
    };

    const handleSave = () => {
        const groupData = {
            name: groupName,
            permissions: permissions,
            users: users,
        }
        saveGroups('group', groupData).then(() => {
            setGroupData({
                groupName: '',
                permissions: [],
                users: [],
            })
            closeModal()
            return toast.success("Group saved!");
        })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "Group", "save");
                return toast.error(errorMessage);
            });
    }

    return (
        <>
            <Button
                variant="contained"
                sx={{ bgcolor: "#0F2FEB" }}
                size="medium"
                onClick={openModal}
                startIcon={<AddIcon />}>
                ADD GROUP
            </Button>
            <Modal
                open={isOpen}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: 'auto',
                    justifyContent: 'center'
                }} >
                <Grid xs={10} md={4}>
                    <Card sx={{ minHeight: '45vh' }}>
                        <Grid container justifyContent="space-between" p={2} >
                            <Grid display="flex" columnGap={1}>
                                <GroupAddRoundedIcon fontSize="large" />
                                <Typography variant="h5" fontWeight="bold" >
                                    Add Group
                                </Typography>
                            </Grid>
                            <IconButton aria-label="close" size="small" color="error"
                                onClick={closeModal}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid sx={{ p: 2 }}>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "400px" }}
                                    size="small"
                                    label="Group Name"
                                    placeholder="Group Name"
                                    value={groupName}
                                    onChange={handleNameChange}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={permissionOptions}
                                    label="Select Permission(s)"
                                    placeholder="Select Permission(s)"
                                    limitTags={1}
                                    onChange={(event, newValue) => {
                                        handlePermissionChange(getSelectedOption(newValue, permissionOptions));
                                    }}
                                    value={getSelectedOption(permissions, permissionOptions)} />
                            </Grid>
                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={userOptions}
                                    label="Select User(s)"
                                    placeholder="Select User(s)"
                                    limitTags={1}
                                    onChange={(event, newValue) => {
                                        handleUserChange(getSelectedOption(newValue, userOptions));
                                    }}
                                    value={getSelectedOption(users, userOptions)} />
                            </Grid>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid container
                            p={1.5}
                            columnGap={{ sm: 'none', md: 4 }}
                            marginLeft={{ xs: 'none', md: -2.5 }}
                            justifyContent={{ xs: 'space-between', md: 'flex-end' }}>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{ color: "#0F2FEB", width: "80px" }}
                                onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ bgcolor: "#0F2FEB", width: "80px" }}
                                onClick={handleSave}
                                disabled={!groupName}>
                                Save
                            </Button>
                        </Grid>
                    </Card>
                </Grid>
            </Modal >
        </>
    )
}