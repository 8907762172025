import {LatLng, LatLngBounds} from "leaflet/src/geo";
import ApiClient from "../../../../common/API";
import {BLACKLISTEDKEYS} from "./constants";
import {useInfiniteQuery, useQuery} from "react-query";

export function getBoundsFromGeometry(bounds, geometry) {
        if (!bounds) bounds = new LatLngBounds();
        if (!geometry) return null;
        geometry.forEach((point) => {
            if (point.length === 2 && !isNaN(point[0]) && !isNaN(point[1])) {
                bounds.extend(point);
            } else {
                bounds = getBoundsFromGeometry(bounds, point);
            }
        });
        return bounds;
    }

export const SelectOverridesStyles = {
    control: (baseStyles) => ({
        ...baseStyles,
        fontSize: '1rem',
        minHeight: '35px',
        height: '35px',
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        fontSize: '1rem'
    }),
    container: (baseStyles) => ({
        ...baseStyles,
        width: '12rem'
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
}

export function getPolygonColor(val) {
    if( val > 0 && val <= 20) {
        return "#52B2BF"
    } else if( val > 20 && val <= 40) {
        return "#0492C2"
    } else if( val > 40 && val <= 60) {
        return "#1338BE"
    } else if( val > 60 && val <= 80) {
        return "#051094"
    } else if( val > 80 && val <= 100) {
        return "#0A1172"
    } else {
        return "#CCE0DE"
    }
}


export function useInfiniteFetchPolygons(level, options) {
    const fetchData = ({pageParam = 1}) => {
        return ApiClient().get(
            `/custom/${level}/`,
            {params: {page: pageParam}}
        ).then(res => {
            return res.data
        })
    }

    const {
        isLoading,
        data,
        fetchNextPage
    } = useInfiniteQuery(
        ['polygon', level],
        fetchData, {
            getNextPageParam: (lastPage, pages) => {
                return lastPage.nextPage
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onSuccess: (data) => {

                const lastPage = data.pages[data.pages.length - 1]
                if (lastPage.nextPage) {
                    fetchNextPage()
                }
            },
            cacheTime: 15 * 60 * 1000,
            staleTime: 15 * 60 * 1000,
            ...options
        });


    const allData = data?.pages?.map(page => {
        if (page?.result?.length > 0) {
            return page.result
        }
        return []

    }).flat()

    return {
        isLoading,
        data: allData
    }
}

export function getMaxMetricValue(metricData) {
    return  Math.max(...metricData.filter(row => !isNaN(row._displayMetric)).map(row => row._displayMetric))
}

export function getMinMetricValue(metricData) {
    return  Math.min(...metricData.filter(row => !isNaN(row._displayMetric)).map(row => row._displayMetric))
}

export function getFirstKey(data) {
    return  Object.keys(data).filter(key => !BLACKLISTEDKEYS.includes(key) && !key.startsWith('_'))[0];
}

export function usePolygon(polygonLevel) {
    const {
        data: regionalPolygonData,
        isLoading: isLoadingRegional,
        isSuccess: isSuccessRegional
    } = useRegionPolygonData()

    const {data: barangayPolygonData, isLoading: isLoadingBarangay} = useInfiniteFetchPolygons('barangay', {disabled: isLoadingRegional && !isSuccessRegional})
    const {data: cityPolygonData, isLoading: isLoadingCity} = useInfiniteFetchPolygons('city', {disabled: isLoadingRegional && !isSuccessRegional})

    const {
        data: provincialPolygonData,
        isLoading: isLoadingProvincial
    } = useProvincePolygonData()

    function getPolygonData() {
        switch (polygonLevel) {
            case 'province':
                return provincialPolygonData
            case 'city':
                return cityPolygonData
            case 'barangay':
                return barangayPolygonData
            default:
                
                return regionalPolygonData
        }
    }

    function getIsLevelLoading() {
        switch (polygonLevel) {
            case 'province':
                return isLoadingProvincial
            case 'city':
                return isLoadingCity
            case 'barangay':
                return isLoadingBarangay
            default:
                return isLoadingRegional
        }
    }

    return {
        polygonData: getPolygonData(),
        isAnyLoading: isLoadingBarangay || isLoadingCity || isLoadingProvincial || isLoadingRegional,
        isLevelLoading: getIsLevelLoading()
    }
}

export function markOutsideBoundPolygons(polygonData, visibleBounds, polygonLevel) {
    if (polygonLevel === "barangay" || polygonLevel === "city") {
        const visibleLatLongBounds = new LatLngBounds(new LatLng(visibleBounds.west, visibleBounds.south), new LatLng(visibleBounds.east, visibleBounds.north))
        const visibleBarangaysData = polygonData.map(brngy => {
            const barangayBounds = getBoundsFromGeometry(null, brngy.geometry)
            return {
                ...brngy,
                _isShown: (!!barangayBounds && (visibleLatLongBounds.overlaps(barangayBounds) || visibleLatLongBounds.contains(barangayBounds.getCenter())))
            }
        })
        return visibleBarangaysData
    } else {
        return polygonData
    }
}

export function filterPolygons(dataWithDisplayMetric, region) {
    // filter by selected region
    const filteredPolygonsWithDisplayMetric = !region ?
        dataWithDisplayMetric :
        dataWithDisplayMetric.filter(data => data.region === region);

    // filter out hidden polygons
    return filteredPolygonsWithDisplayMetric?.filter(data => {
        if (data.hasOwnProperty('_isShown')) return data._isShown
        return true
    })
}



export function standardizeProvince(province) {
    if (province === "Metropolitan Manila") {
        return "Metro Manila"
    }
    return province;
}

export const useRegionPolygonData = () => {
    return useQuery('regionPolygons', () => ApiClient().get('/custom/region/').then(res => {
        return res.data
        // return MOCK_DATA
    }), {
        refetchOnWindowFocus: false,
        staleTime: 600000 //10mins
    });
}

export const useProvincePolygonData = () => {
    return useQuery('provincialPolygons', () => ApiClient().get('/custom/province/').then(res => {
        return res.data
    }), {
        refetchOnWindowFocus: false,
        staleTime: 600000,  //10mins
    });
}