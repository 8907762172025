import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import NoAvailableDataDisplay from "../../NoAvailableDataDisplay";
import { useSelectorValue } from "../../../common/providers/selectorProvider";
import { LABEL_ALL_BRANCH } from "../../hooks";


const thickBorder = '2px solid #C3C3C3!important'

export default function StoreSalesPerCustomerTable({ data, isLoading }) {
    const branch = useSelectorValue('branch')

    const sortDataByHourPkFn = (a, b) => {
        if (a.pk === 'Total') return 1;
        if (b.pk === 'Total') return -1;
        return parseInt(a.pk) - parseInt(b.pk);
    }

    const sortedData = useMemo(() => {
        if (!data) return [];

        data.sort(sortDataByHourPkFn);

        return data.map(row => {
            if (row.pk === "Total") {
                return {
                    ...row,
                    _isFooter: true
                }
            }
            return row
        });
    }, [data]);

    const sortRowsByHourPKFn = (rowA, rowB) => {
        return sortDataByHourPkFn(rowA.original, rowB.original)
    }

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const tableColumns = useMemo(() => {
        return [
            {
                id: "hour",
                header: "Hour",
                accessorKey: "hour",
                cell: (row) => {
                    const value = row?.getValue()

                    return <Box sx={{ width: { sm: '30%' }, marginX: 'auto', }}>
                        <Typography sx={{ fontWeight: value === 'Total' && 'bold' }}>{value}</Typography>
                    </Box>
                },
                customStyle: {
                    width: "7rem",
                    textAlign: "left",
                    verticalAlign: "middle",
                    borderRight: thickBorder,
                },
                sortingFn: sortRowsByHourPKFn
            },
            {
                id: "branch",
                header: branch === 'All Branch' ? LABEL_ALL_BRANCH : branch,
                columns: [
                    ...daysOfWeek.map(day => ({
                        id: day,
                        header: day,
                        accessorKey: day,
                        cell: (row) => {
                            const perc = row?.row?.original?.[`${day}_max_perc`] || 0;
                            const isTotalValue = row?.row?.original?.hour
            
                            return (
                                <Box sx={{ backgroundColor: `rgba(174, 213, 241, ${perc})` }}>
                                    <Box sx={{ width: {sm: '3em', xs: "2em"}, marginX: 'auto', fontWeight: isTotalValue === 'Total' ? 'bold' : 'regular'}}>
                                        <NumericValueDisplay value={row?.getValue()} />
                                    </Box>
                                </Box>
                            )
                        },
                        customStyle: {
                            width: "15rem",
                            textAlign: "left",
                            verticalAlign: "middle",
                        },
                        headerStyle: { backgroundColor: "#E7F1F8" }
                    })),
                ]
            }
        ]
    },
        [data, sortedData, branch]
    );

    if (isLoading) {
        return <LoadingComponent />
    }

    if (!data || data?.length === 0) {
        return <NoAvailableDataDisplay />
    }

    return <TableComponentV2
        columns={tableColumns}
        data={sortedData || []}
        tableId="salesPerCustomer"
        isCompact
        customTableHeaderStyle={{ backgroundColor: "#caddeb" }}
        customTableContainerStyle={{ borderRadius: 0, boxShadow: "none" }}
    />
}