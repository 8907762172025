import React from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid'

export default function Search({ searchQuery, setSearchQuery }) {
    const handleClear = () => {
      setSearchQuery('');
    };

    return (
        <>

        <Grid container sx={{mt:1, mb:1}}>
            <TextField
                fullWidth
                variant='filled'
                id="hl"
                placeholder="Search Schema or Table..."
                value={searchQuery}
                size="small"
                onChange={(e) => setSearchQuery(e.target.value)}
               
                InputProps={{
                    disableUnderline: true,
                    style:{
                     borderRadius:'10px',
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchQuery ? (
                                <IconButton onClick={handleClear} edge="end">
                                    <ClearIcon />
                                </IconButton>
                            ) : (
                                <IconButton edge="end">
                                    <SearchIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                    sx: {
                        '& input': {
                          padding: '10px', 
                        },
                      },            
                }}  
            />
        </Grid>

        </>
    )
}