import AcquisitionContent from "./Acquisition";
import RegistrationContent from "./Registration";
import UsageContent from "./Usage";

export default function TabContent({selectedTab}){

    switch(selectedTab){
        case 'registration':
            return <RegistrationContent  />;
        case 'usage':
            return <UsageContent />;
        default: 
            return <AcquisitionContent />;
    }
 
}