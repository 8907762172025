import React, { useContext, useMemo, useState } from 'react';
import ViewBySelector from "../Filters/viewBySelector";
import { Box, Grid, Typography } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import TableComponentV2 from '../../../../common/components/TableComponentV2';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BarIndicator from '../../common/presentation/barIndicator';
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import ToggleSelector from '../../common/presentation/selectors/toggleSelector';
import { getDataFormatter, inventoryValidNumber, columnsToSum } from '../utils';
import { useCategoryContext, useFiltersContext, useToggleContext } from '../provider/context';
import { useInventoryByGroup } from '../hooks';
import InventoryNumericValueDisplay from '../inventoryNumericValDisplay';
import { rollupDataBy, sortArrayBasedOnGivenArray } from '../../../../common/utils';


const IndicatorCellBox = (props) => (
    <Box sx={{ my: '.2rem', width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);

const chooseGroup = {
    'mall_type': 'mallType',
    'store_size': 'storeSize',
    'mall_category': 'mallCategory',
    'region': 'region',
    'top_food_group': 'productGroup'
}

const ToggleOptions = [
    { label: 'Total', value: 'overview' },
    { label: 'Group', value: 'product' },
    { label: 'Must-have', value: 'top888' }
]

export default function InventoryByGroup({ params, period, }) {
    const { toggleState, updateToggleState } = useToggleContext();
    const windowSize = useContext(WindowSizeContext);
    const { category, setCategory } = useCategoryContext();
    const { filters, setFilters } = useFiltersContext();

    const usedGroupByAndColumns = useMemo(() => {
        if (toggleState === 'overview') {
            return category
        }
        if (toggleState === 'product') {
            return `${category},food_group`
        }
        if (toggleState === 'top888') {
            if (category === 'top_food_group') {
                return 'top_food_group'
            } else {
                return `${category},top_food_group`
            }
        }
        throw Error(`Invalid toggle state: [${toggleState}]`)
    }, [toggleState, category])

    const dataFormatterFn = useMemo(() => {
        return getDataFormatter(toggleState)
    }, [toggleState])

    const { inventoryDataByGroup, isLoading } = useInventoryByGroup({
        params: {
            ...params,
            group_by: usedGroupByAndColumns,
            columns: usedGroupByAndColumns,
        }, dataFormatterFn: dataFormatterFn
    })

    const selectRow = row => {
        const value = row[category]
        setFilters(prevState => {
            const tmpSelectedRows = prevState[category] || []
            if (prevState[category]?.includes(value)) {
                return {
                    ...prevState,
                    [category]: tmpSelectedRows.filter(val => val !== value)
                }
            }

            return {
                ...prevState,
                [category]: [
                    ...tmpSelectedRows,
                    value
                ]
            }
        })
    }

    const switchViewBy = (newViewBy) => {
        setCategory(newViewBy);

        setFilters((prevState) => {
            const tmpFilters = { ...prevState };
            Object.keys(chooseGroup).forEach((viewByName) => {
                delete tmpFilters[viewByName];
            });
            return tmpFilters;
        });
    };

    const selectedRows = filters[category]

    const { orderArray, absoluteHeaderLabel } = useMemo(() => {
        switch (chooseGroup[category]) {
            case 'productGroup':
                return {
                    orderArray: ['888', 'Top 888', '4k', 'Top 4k', 'Others'],
                    absoluteHeaderLabel: "Category"
                };
            case 'region':
                return {
                    orderArray: ['NCR', 'NORTH LUZON', 'SOUTH LUZON', 'VISAYAS', 'NORTH EAST', 'MINDANAO'],
                    absoluteHeaderLabel: "Region"
                };
            case 'storeSize':
                return {
                    orderArray: ['EXTRA SMALL', 'SMALL', 'MEDIUM', 'LARGE'],
                    absoluteHeaderLabel: "Store Size"
                };
            case 'mallType':
                return { absoluteHeaderLabel: "Store Type" };
            case 'mallCategory':
                return { absoluteHeaderLabel: "Store Subtype" };
            default:
                return { orderArray: [] };
        }
    }, [category]);

    const isProductGroup = category === 'top_food_group';
    const PRODUCT_CAT_COLS = ['888', '4k']

    const mainColumnDefinition = React.useMemo(() => {
        return {
            header: absoluteHeaderLabel,
            accessorKey: category,
            id: 'category',
            cell: (row) => {
                const isSelected = selectedRows?.includes(row?.row?.original?.[category])
                const prefix = (isProductGroup && PRODUCT_CAT_COLS.includes(row?.getValue())) ?
                    'Top ' : ''
                const postfix = !isProductGroup && row.row.original.branch_id ?
                    ` (${row.row.original.branch_id.size} stores)` : ''

                return <Box sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', marginY: '.3rem' }}>
                    <VisibilityIcon
                        sx={{ marginRight: '.5rem' }} fontSize='small' color={isSelected ? 'primary' : 'disabled'} />
                    {prefix} {row?.getValue()} {postfix}
                </Box>
            },
            customStyle: { width: '15rem' }
        }
    }, [category, selectedRows, absoluteHeaderLabel, isProductGroup, PRODUCT_CAT_COLS])


    const byGroup_overview_col = useMemo(
        () => [
            mainColumnDefinition,
            {
                header: 'Total Inventory',
                id: 'total_inventory',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}>
                            <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} />
                        </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'inventory_value',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },
                ]
            },

            {
                header: 'Return To Vendor',
                id: 'return_to_vendor',
                columns: [
                    {
                        header: 'Value',
                        accessorKey: 'rtv',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },
                    {
                        header: '% to Sales',
                        accessorKey: 'rtv_to_sales',
                        cell: (row) => <>

                            {category !== 'top_food_group' ? (
                                <IndicatorCellBox>
                                    <BarIndicator
                                        colorFunctionFor={'inventory_rtv'}
                                        value={inventoryValidNumber(row?.getValue())?.toFixed(2)}
                                        isValueColorCoded={true}
                                        isOnTable={true}
                                        label="vs last year"
                                    />
                                </IndicatorCellBox>
                            ) : (
                                'N/A'
                            )}

                        </>,
                        customStyle: { textAlign: 'center', verticalAlign: 'middle' }
                    },
                ]
            },

            {
                header: 'Damaged',
                id: 'damaged',
                columns: [
                    {
                        header: 'Value',
                        accessorKey: 'ds',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },
                    {
                        header: '% to Sales',
                        accessorKey: 'ds_to_sales',
                        cell: (row) => <>

                            {category !== 'top_food_group' ? (
                                <IndicatorCellBox>
                                    <BarIndicator
                                        colorFunctionFor={'inventory_ds'}
                                        value={inventoryValidNumber(row?.getValue())?.toFixed(2)}
                                        isValueColorCoded={true}
                                        isOnTable={true}
                                        label="vs last year"
                                    />
                                </IndicatorCellBox>
                            ) : (
                                'N/A'
                            )}

                        </>,
                        customStyle: { textAlign: 'center', verticalAlign: 'middle' }
                    }
                ]
            },
        ],
        [category, isProductGroup, selectedRows, period, inventoryDataByGroup, isLoading]
    )

    const byGroup_product_col = useMemo(
        () => [
            mainColumnDefinition,
            {
                header: 'Fresh',
                id: 'product_fresh',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_fresh',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_fresh',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },

                ]
            },
            {
                header: 'Food 1',
                id: 'product_food1',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_food1',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_food1',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },

                ]
            },
            {
                header: 'Food 2',
                id: 'product_food2',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_food2',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_food2',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },

                ]
            },
            {
                header: 'Non-food',
                id: 'product_non_food',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_nonfood',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_nonfood',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },

                ]
            },
            {
                header: 'Dry Goods',
                id: 'product_dry_goods',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_drygoods',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_drygoods',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}> <NumericValueDisplay value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },

                ]
            },
        ],
        [category, isProductGroup, selectedRows, period, inventoryDataByGroup, isLoading]
    )

    const byGroup_top888_col = useMemo(
        () => [
            mainColumnDefinition,
            {
                header: 'Top 888',
                id: 'top888',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_888',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}>
                            <InventoryNumericValueDisplay
                                undefinedDisplay={'-'}
                                value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} />
                        </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_888',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}>
                            <InventoryNumericValueDisplay
                                undefinedDisplay={'-'}
                                value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} />
                        </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },
                ]
            },
            {
                header: 'Top 4k',
                id: 'top4k',
                columns: [
                    {
                        header: 'Days',
                        accessorKey: 'days_4k',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}>
                            <InventoryNumericValueDisplay
                                undefinedDisplay={'-'}
                                value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} />
                        </Box>,
                        customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'Value',
                        accessorKey: 'value_4k',
                        cell: (row) => <Box sx={{ width: { sm: '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}>
                            <InventoryNumericValueDisplay
                                undefinedDisplay={'-'}
                                value={row?.getValue()} isAbbreviated={windowSize?.isOnMobile} />
                        </Box>,
                        customStyle: { width: '12rem', verticalAlign: 'middle' }
                    },
                ]
            },
        ],
        [category, isProductGroup, selectedRows, period, inventoryDataByGroup, isLoading]
    )

    const columnSums = useMemo(() => {  // this is the subtotal row computation
        if (!inventoryDataByGroup || inventoryDataByGroup.length === 0) {
            return {};
        }

        const rolledUpData = rollupDataBy(inventoryDataByGroup, [], columnsToSum)[0]
        const getColumnValue = (accessorKey) => {
            if (accessorKey === 'days') {
                return <>
                    <Box sx={{ whiteSpace: 'nowrap', marginX: 'auto' }}>
                        <InventoryNumericValueDisplay value={ rolledUpData['inventory_value_sum'] / (rolledUpData['sales_at_cost_sum'] / rolledUpData['eight_weeks_transaction_date_count_distinct']) } isAbbreviated={windowSize?.isOnMobile} />
                    </Box>
                </>
            }

            else if (accessorKey.indexOf('days_') === 0) {
                // GROUP and MUST HAVE toggle for days
                const inventory_value = rolledUpData[accessorKey.replace('days_', 'value_')];
                const eight_weeks_transaction_days_key = accessorKey.replace('days_', 'eight_weeks_transaction_days_')
                const sales_at_cost_key = accessorKey.replace('days_', 'sales_at_cost_')

                const inventory_days = inventory_value / (rolledUpData[sales_at_cost_key] / rolledUpData[eight_weeks_transaction_days_key])
                return <>
                    <Box sx={{ whiteSpace: 'nowrap', marginX: 'auto' }}>
                        <InventoryNumericValueDisplay value={inventory_days} isAbbreviated={windowSize?.isOnMobile} />
                    </Box>
                </>
            }

            else if (accessorKey === 'rtv_to_sales') {
                return <>
                    {category !== 'top_food_group' ? (
                        <IndicatorCellBox>
                            <BarIndicator
                                colorFunctionFor={'inventory_rtv'}
                                value={(rolledUpData['rtv'] / rolledUpData['actual_sales_sum'] * 100).toFixed(2)}
                                isValueColorCoded={true}
                                isOnTable={true}
                                label="vs last year"
                            />
                        </IndicatorCellBox>
                    ) : (
                        <Box sx={{ whiteSpace: 'nowrap', marginX: 'auto' }}>N/A</Box>
                    )}
                </>
            }

            else if (accessorKey === 'ds_to_sales') {
                return <>
                    {category !== 'top_food_group' ? (
                        <IndicatorCellBox>
                            <BarIndicator
                                colorFunctionFor={'inventory_ds'}
                                value={(rolledUpData['ds'] / rolledUpData['actual_sales_sum'] * 100).toFixed(2)}
                                isValueColorCoded={true}
                                isOnTable={true}
                                label="vs last year"
                            />
                        </IndicatorCellBox>
                    ) : (
                        <Box sx={{ whiteSpace: 'nowrap', marginX: 'auto' }}>N/A</Box>
                    )}
                </>
            }
            return <>
                {rolledUpData[accessorKey] === 0 ? (
                    <Box sx={{ whiteSpace: 'nowrap', marginX: 'auto' }}>N/A</Box>
                ) : <Box sx={{ width: { sm: toggleState === 'overview'  && Math.round(rolledUpData[accessorKey])?.toString()?.length < 6 ? '2rem' : '5rem', xs: '4rem' }, marginX: 'auto', textAlign: 'left' }}>
                        <InventoryNumericValueDisplay value={rolledUpData[accessorKey]} isAbbreviated={windowSize?.isOnMobile} />
                    </Box>
                }
            </>
        }

        const reduceColumns = (acc, columnDefinition) => {
            if (columnDefinition.accessorKey) {
                acc[columnDefinition.accessorKey] = getColumnValue(columnDefinition.accessorKey)
            } else if (columnDefinition.columns) {
                columnDefinition.columns.forEach(subColumn => {
                    acc[subColumn.accessorKey] = getColumnValue(subColumn.accessorKey)
                })
            }
            return acc
        }

        if (toggleState === 'overview') {
            return byGroup_overview_col
                .filter(columnDefinition => columnDefinition.accessorKey !== category)
                .reduce(reduceColumns, {})
        } else if (toggleState === 'product') {
            return byGroup_product_col
                .filter(columnDefinition => columnDefinition.accessorKey !== category)
                .reduce(reduceColumns, {})
        } else {
            return byGroup_top888_col
                .filter(columnDefinition => columnDefinition.accessorKey !== category)
                .reduce(reduceColumns, {})
        }


    }, [inventoryDataByGroup, toggleState, byGroup_overview_col, byGroup_product_col, byGroup_top888_col, category]);

    if (isLoading) {
        return <LoadingComponent />
    }
     
    const columnsByToggleState = {
        overview: byGroup_overview_col,
        product: byGroup_product_col,
        top888: byGroup_top888_col
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ mt: 1, backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container alignItems="center" py={.5}>
                    {
                        windowSize.isOnMobile ? <>
                            <Grid item xs sm={2.5}>
                                <Typography variant="h6" ml={2.5}>
                                    Inventory by
                                </Typography>
                            </Grid>
                            <Grid item xs display="flex" justifyContent={'end'} paddingY={windowSize?.isOnMobile && .75}>
                                <ToggleSelector
                                    options={ToggleOptions}
                                    value={toggleState}
                                    onChange={(e) => updateToggleState(e.target.value)}
                                    additionalStyles={{ textTransform: 'capitalize' }} />
                            </Grid>
                            <ViewBySelector
                                setSelectedViewBy={switchViewBy}
                                selectedViewBy={category} />
                        </> : <>
                            <ViewBySelector
                                setSelectedViewBy={switchViewBy}
                                selectedViewBy={category} />
                            <Grid item sm={3} xs={12} display="flex" justifyContent={'end'} paddingY={windowSize?.isOnMobile && .75}>
                                <ToggleSelector
                                    options={ToggleOptions}
                                    value={toggleState}
                                    onChange={(e) => updateToggleState(e.target.value)}
                                    additionalStyles={{ textTransform: 'capitalize' }} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <TableComponentV2
                        columns={columnsByToggleState[toggleState] }
                        data={sortArrayBasedOnGivenArray(inventoryDataByGroup, orderArray, category)}
                        tableId={'inventoryByGroupTable'}
                        enableRowSelection={true}
                        onSelectRow={selectRow}
                        isCompact={true}
                        top_food_group
                        hasFooterSubtotal={!!columnSums}
                        subtotalRow={columnSums}
                        isInventoryTable={true}
                        customTableHeaderStyle={{
                            backgroundColor: '#caddeb'
                        }} />
                </Grid>
            </Grid>
        </>
    );
}