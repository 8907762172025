import ModuleTable from "./ModuleTable";
import React, {useState} from "react";
import Search from "./Search";
import AddModule from "./AddModule";
import {Divider, Grid, IconButton, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Back from '../../../common/components/BackNavigateComponent';
import {getSelectedOption} from "../../../common/utils";
import DropdownCheckboxV3 from "../../../common/components/DropdownCheckboxV3";
import {useUserList} from "../../../common/API/hooks";
import UnregisteredModules from "./UnregisteredModules";


export default function ModulePermission() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([])
    const {data: userList, isLoading: userLoading} = useUserList()

    const userOptions = React.useMemo(() => {
        if (userLoading) {
            return []
        }
        if (!userList) {
            return []
        }
        return userList?.map((user) => {
            return {
                value: user.username,
                label: user.username,
                key: user.id,
            }
        })
    }, [userList, userLoading]);


    return (
        <>
            <Grid sx={{mt: 3}} display={'flex'}>
                <Back/>
                <Typography variant="h5" style={{marginLeft: '16px'}}>
                    Module Permission
                    <Tooltip title={<Typography variant="body2">Module Permission</Typography>}
                             placement='right'>
                        <IconButton>
                            <InfoIcon style={{color: 'grey'}}/>
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Grid>

            <Divider sx={{my: 2, borderBottom: 3}}/>

            <Grid sx={{
                border: 2,
                borderRadius: 3,
                borderColor: "#bdbdbd",
                backgroundColor: "#F3F3F3",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                p: 1
            }}>
                <Grid container alignItems="center" justifyContent="flex-start" display={{xs: 'block', md: 'flex'}}>
                    <Grid item md={3} padding={1}>
                        <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
                    </Grid>
                    <Grid item md={6} padding={1}>
                        <DropdownCheckboxV3
                            options={userOptions}
                            label="Visible To User"
                            allowSingleSelection={true}
                            limitTags={1}
                            isLoading={userLoading}
                            isDisabled={userLoading}
                            onChange={(event, newValue) => {
                                setSelectedUsers(newValue);
                            }}
                            value={getSelectedOption(selectedUsers, userOptions)}
                        />
                    </Grid>

                    <Grid  item  padding={1} textAlign={"right"} flexGrow={1}>
                        <AddModule/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{
                border: 2,
                borderRadius: 3,
                borderColor: "#bdbdbd",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: 0,
                p: 1
            }}>
                <ModuleTable searchQuery={searchQuery} filterUsers={selectedUsers}/>

            <Divider sx={{my: 2, borderBottom: 3}}/>

            <UnregisteredModules/>
            </Grid>
        </>
    )
}