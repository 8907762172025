import Grid from '@mui/material/Grid'; // Grid version 1
import Box from '@mui/material/Box';
import  Typography from '@mui/material/Typography' 

import AddHost from "./AddHost";
import { useState, useContext} from 'react';
import { WindowSizeContext } from '../../../common/context/WindowSizeContext';
import ColumnSampleData from "./ColumnSampleData";
import TreeView from "./TreeView";
import RefreshAll from "./RefreshAll";
import TableStatus from "./TableStatus";
import { useSchemaList, useTableDetail } from "../../../common/API/hooks";
import DataExplorerBreadcrumb from "./DataExplorerBreadcrumb";
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import InfoIcon from '@mui/icons-material/Info';

export function DataExplorer() {
    const windowSize = useContext(WindowSizeContext);
    const tooltipPlacement = windowSize.isOnMobile ? 'top' : 'right';

    const iSuperUser = true; // ojt.smic.datalabs@sminvestment.com -USER ACCESS LEVEL 
    const [tableId, setTableId] = useState();
    const { data: tableDetails} = useTableDetail(tableId, { enabled: !!tableId })
    const { data: schemaList} = useSchemaList()

    return <>
        <Grid sx={{mt:4}}>

            <Grid container>
                <Grid item md={6}>
                    <Box display={'flex'}>
                        <Typography variant="h5">
                            Data Explorer
                            <InfoIcon
                                size='small'
                                sx={{ml:1, color:'#acacac'}}
                                // data-tooltip-id="info_tooltip"
                                // data-tooltip-place={tooltipPlacement}
                                // data-tooltip-variant="info"
                                // data-tooltip-content="Data Explorer."
                            />
                        </Typography>
                    </Box>
                </Grid>

                {iSuperUser === true && (
                <Grid item md={6} >
                    <Stack display={'flex'} direction={'row'} spacing={2} justifyContent={'flex-end'} alignItems={'flex-end'} height={'40px'}>
                        <AddHost/>
                        <RefreshAll/>
                    </Stack>
                </Grid> 
                )}
            </Grid>
            
            <Divider sx={{ my: 3, borderBottom: 3 }} />
    
            <Grid container sx={{mb:2,mt:1}}>
                <Grid md={3}></Grid>

                <Grid item xs={12} md={7}>
                    <DataExplorerBreadcrumb
                        schemaList={schemaList}
                        tableData={tableDetails}
                    />
                </Grid>

                <Grid item xs={12} md={2} whiteSpace={'no'}>
                    {!!iSuperUser && (
                        <TableStatus
                            tableData={tableDetails}
                        />   
                    )}
                </Grid>
            </Grid>

            <Grid container direction={'row'} spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <TreeView
                        tableId={tableId}
                        setTableId={setTableId}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={9} sx={{zIndex:0, position:'relative'}}>
                    <ColumnSampleData tableId={tableId} />
                </Grid>
            </Grid>

        </Grid>
    </>
}

export default DataExplorer;