import React from "react";
import { useTableQuery } from "../../../common/API/hooks";
import { useSelectorValue } from "../common/providers/selectorProvider";
import { SEGMENT_TYPE_RFM, SEGMENT_ORG_SM_STORE, SEGMENT_ORG_SM_MARKETS, SEGMENT_BRANCH_ALL_BRANCH, SEGMENT_PERIOD_PREVIOUS, SEGMENT_PERIOD_LATEST } from "./constants";
import moment from "moment";
import { PERIOD_MONTHLY, PERIOD_QUARTERLY, PERIOD_YEARLY } from "../../../Constants/general";

export const TABLE_SMAC_PERF_MEMBER_BASE_MONTHLY = "l4_smac_perf_member_base_monthly";
export const TABLE_SMAC_PERF_MEMBER_BASE_QUARTERLY = "l4_smac_perf_member_base_quarterly";

export const TABLE_CUSTOMER_ANALYTICS_DEMOGRAPHICS_QUARTERLY = "l4_customer_analytics_demogs_quarterly";
export const TABLE_CUSTOMER_ANALYTICS_DEMOGRAPHICS_MONTHLY = "l4_customer_analytics_demogs_monthly";

export const TABLE_CUSTOMER_ANALYTICS_LOCATIONS_QUARTERLY = "l4_customer_analytics_locations_quarterly";
export const TABLE_CUSTOMER_ANALYTICS_LOCATIONS_MONTHLY = "l4_customer_analytics_locations_monthly";

export const TABLE_CUSTOMER_ANALYTICS_SEGMENTS_YEARLY = "l4_tss_customer_analytics_segments_yearly";


/*******************************************************************
 BASE TABLE HOOKS
 Processing needed for all tables
 *******************************************************************/
export function useTableQueryWithDateFilters({ tableName, params, useQueryOptions = {}, applyDateFilters = true, lastYear = false }) {
    // to use this hook, the table structure must follow the following format
    // year, (quarter or month), ...
    const period = useSelectorValue('period');
    const periodicity = useSelectorValue('periodicity');
    const year = useSelectorValue('year');

    const newParams = {
        ...params
    }

    const newQueryOptions = {
        ...useQueryOptions
    }
    if (applyDateFilters) {
        if (!year || (!periodicity && period !== PERIOD_YEARLY)) {
            newQueryOptions.enabled = false;
            console.warn("Year or Periodicity not set, disabling query")
        }

        if (lastYear) {
            newParams.year = year - 1;
        } else {
            newParams.year = year;
        }
        if (period === PERIOD_QUARTERLY) {
            newParams.quarter = periodicity;
        } else if (period === PERIOD_MONTHLY) {
            newParams.month = periodicity?.toString().padStart(2, '0');
        }
    }
    return useTableQuery({ tableName, params: newParams, useQueryOptions: newQueryOptions })
}


export function useTableQueryWithQuarterFilters({ tableName, params, useQueryOptions = {}, applyDateFilters = true, lastYear = false }) {

    const period = useSelectorValue('period');
    const periodicity = useSelectorValue('periodicity');
    const year =  useSelectorValue('year');

    const newParams = {
        ...params
    }

    const newQueryOptions = {
        ...useQueryOptions
    }

    if (applyDateFilters) {
        if (!year || (!periodicity && period !== PERIOD_YEARLY)) {
            newQueryOptions.enabled = false;
            console.warn("Year or Period not set, disabling query");
        }

        if (lastYear) {
            newParams.year = year - 1;
        } else {
            newParams.year = year;
        }

        if (period === PERIOD_MONTHLY) {
            const selectedDate = moment().month(periodicity - 1);

            // Get the current quarter of the selected month
            const currentQuarter = selectedDate.quarter();

            newParams.month = moment()
                .quarter(currentQuarter - 1)
                .endOf('quarter')
                .month() + 1;

            // if Quarter === 1, year subracted to 1
            if (currentQuarter === 1) {
                newParams.year = year - 1;
            } else {
                // Otherwise, keep the selected year (no change)
                newParams.year = year;
            }
        }
    }
    return useTableQuery({ tableName, params: newParams, useQueryOptions: newQueryOptions })
}


export function useTableQueryWithYearFilter({ tableName, params, useQueryOptions = {}, applyYearFilters = true, lastYear = false }) {
    const year = useSelectorValue('year');

    const newParams = {
        ...params
    }
    const newQueryOptions = {
        ...useQueryOptions
    }
    if (applyYearFilters) {
        if (!year) {
            newQueryOptions.enabled = false;
            console.warn("Year not set, disabling query")
        }
        if (lastYear) {
            newParams.year = year - 1;
        } else {
            newParams.year = year;
        }
    }
    return useTableQuery({ tableName, params: newParams, useQueryOptions: newQueryOptions })
}

/*******************************************************************
 BASIC TABLE HOOKS
 per table hooks without any fancy processing
 *******************************************************************/

export function useSMACPerfMemberBaseTableQuery({ params, useQueryOptions, applyDateFilters = true, lastYear = false }) {
    const period = useSelectorValue('period');
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return TABLE_SMAC_PERF_MEMBER_BASE_MONTHLY;
        } else if (period === PERIOD_QUARTERLY) {
            return TABLE_SMAC_PERF_MEMBER_BASE_QUARTERLY;
        }
        throw new Error("Invalid period");
    }, [period])

    return useTableQueryWithDateFilters({
        tableName,
        params,
        useQueryOptions,
        applyDateFilters,
        lastYear
    })
}


export function useCustomerAnalyticsDemographicsTableQuery({ params, useQueryOptions, applyDateFilters = true, lastYear = false }) {
    const period = useSelectorValue('period');
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return TABLE_CUSTOMER_ANALYTICS_DEMOGRAPHICS_MONTHLY;
        } else if (period === PERIOD_QUARTERLY) {
            return TABLE_CUSTOMER_ANALYTICS_DEMOGRAPHICS_QUARTERLY;
        }
        throw new Error("Invalid period");
    }, [period])

    return useTableQueryWithDateFilters({
        tableName,
        params,
        useQueryOptions,
        applyDateFilters,
        lastYear
    })
}


export function useCustomerLocationTableQuery({ params, useQueryOptions, applyDateFilters = true, lastYear = false }) {
    const period = useSelectorValue('period');
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return TABLE_CUSTOMER_ANALYTICS_LOCATIONS_MONTHLY;
        } else if (period === PERIOD_QUARTERLY) {
            return TABLE_CUSTOMER_ANALYTICS_LOCATIONS_QUARTERLY;
        }
        throw new Error("Invalid period");
    }, [period])

    return useTableQueryWithDateFilters({
        tableName,
        params,
        useQueryOptions,
        applyDateFilters,
        lastYear
    })
}


export function useCustomerSegmentsTableQuery({ params, useQueryOptions, applyDateFilters = true, lastYear = false }) {
    // placeholder hook layer, when needed to switch between yearly and monthly add logic here
    return useTableQueryWithYearFilter({
        tableName: TABLE_CUSTOMER_ANALYTICS_SEGMENTS_YEARLY,
        params,
        useQueryOptions,
        applyDateFilters,
        lastYear
    })
}

/*******************************************************************
 LOOKUP, META HOOKS
 *******************************************************************/

export function usePeriodValues() {
    const { data, isLoading, ...rest } = useSMACPerfMemberBaseTableQuery({
        params: { group_by: ['year', 'quarter', 'month'] },
        applyDateFilters: false
    });

    const asDates = React.useMemo(() => {
        if (isLoading || !data) {
            return [];
        }
        return data.map((row) => {
            if (row.quarter) {
                return moment(`${row.year} Q${row.quarter}`, "YYYY Q").toDate()
            } else if (row.month) {
                return moment(`${row.year}-${row.month}`, "YYYY-MM").toDate()
            }
            return null;  // Add return statement for non-matching conditions
        }).sort((a, b) => a - b);  // Add sort function to sort dates
    }, [data, isLoading]);

    const asDateRange = React.useMemo(() => {
        if (!asDates || asDates.length === 0) {
            return null;
        }
        return {
            from: asDates[0],
            to: asDates[asDates.length - 1]
        }

    }, [asDates])


    return {
        data,  // raw data
        asDates,  // as javascript dates
        asDateRange,  // date range from the first to the last date
        isLoading,
        ...rest
    }
}


export function useLatestLoadDateMoment() {
    const { data, isLoading, ...rest } = useSMACPerfMemberBaseTableQuery({
        params: {
            aggregates: ['load_date_max'],
        },
        applyDateFilters: false,
    })

    const latestLoadDate = data?.[0]?.load_date_max ? moment(data[0]?.load_date_max) : null;

    return {
        data: latestLoadDate,
        isLoading,
        rawData: data,
        ...rest
    }
}


/*******************************************************************
 FETCH HOOKS
 Minor processing of data
 *******************************************************************/

export function useSMACMemberCounts({ lastYear = false }) {
    return useSMACPerfMemberBaseTableQuery({
        lastYear
    })
}

export function useDemographics({ lastYear = false }) {
    return useCustomerAnalyticsDemographicsTableQuery({
        params: {
            columns: ['gender_bin', 'age_bin', 'generation_bin', 'gender_count', 'age_count', 'generation_count', 'marital_status_bin', 'marital_status_count'],
            limit: 1000
        },
        lastYear
    })
}


export function useCustomerSegments({ segmentationType = SEGMENT_TYPE_RFM, store = SEGMENT_BRANCH_ALL_BRANCH, organization = SEGMENT_ORG_SM_STORE, lastYear = false, useQueryOptions = {} }) {
    const { data, ...rest } = useCustomerSegmentsTableQuery({
        params: {
            store: store,
            org: organization,  // not sure if we should do organization filtering in API or FrontEnd. If there are 3 or less organizations, we can do it here
            segment_period: organization === SEGMENT_ORG_SM_MARKETS ? SEGMENT_PERIOD_PREVIOUS : SEGMENT_PERIOD_LATEST, // TODO: clarify how the values will change with time (`segment_period` naming TBD later)
            limit: 5000,
        },
        lastYear,
        useQueryOptions: useQueryOptions,
    })

    const filteredBySegmentationType = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return data.filter(row => row.segment_type === segmentationType);
    }, [data, segmentationType])

    return {
        data: filteredBySegmentationType,
        ...rest
    }
}


export function useAvailableOrganizations({ segmentationType = SEGMENT_TYPE_RFM, store = SEGMENT_BRANCH_ALL_BRANCH }) {
    const { data, ...rest } = useCustomerSegmentsTableQuery({
        params: {
            columns: 'segment_type,organization',
            group_by: 'segment_type,organization',
            store: store,
            limit: 5000,
        }
    })

    const filteredBySegmentationType = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return data.filter(row => row.segment_type === segmentationType);
    }, [data, segmentationType])

    return {
        data: filteredBySegmentationType,
        ...rest
    }
}


export function useCustomerLocations({ lastYear = false, catchment }) {
    // let's process catchment filtering in the front-end

    const { data, ...rest } = useCustomerLocationTableQuery({
        params: {
            limit: 1000
        },
        lastYear
    })

    const filteredData = React.useMemo(() => {
        if (!data) {
            return null;
        }
        return data.filter(row => row.catchment === catchment);
    }, [data, catchment]);

    return {
        data: filteredData,
        ...rest
    }
}

/*******************************************************************
 HIGHER LEVEL HOOKS
 Hooks using multiple tables, fancy processing or reformatting
 *******************************************************************/

function smacMemberCountsToDict(data) {
    if (!data) {
        return null;
    }
    return data.reduce((acc, row) => {
        acc[row.business_value] = row.total_members;
        return acc;
    }, {})
}

const DIMENSION_SMAC_MEMBER_CARD_TYPE = 'Card Type';
const DIMENSION_SMAC_MEMBER_REGISTERED = 'Registered';
const DIMENSION_SMAC_MEMBER_UNREGISTERED = 'Unregistered';
const DIMENSION_SMAC_MEMBER_CARD_STATUS = 'Card Status';
const DIMENSION_SMAC_MEMBER_CONTACTABILITY = 'Contactability';
const DIMENSION_SMAC_MEMBER_TOTAL_SMAC_BASE = 'Total SMAC Base';
const DIMENSION_GEOLOCATED_MEMBERS = 'Geo-Located Members';

export function useSMACMembersByDimension({ dimension, lastYear = false }) {
    const { data, ...rest } = useSMACMemberCounts({ lastYear });
    const totalSMACMembers = React.useMemo(() => {
        if (!data) {
            return null;
        }
        return data.filter(row => row.business_dimension === dimension);
    }, [data])

    const asDict = React.useMemo(() => {
        return smacMemberCountsToDict(totalSMACMembers);
    }, [totalSMACMembers])

    return {
        data: totalSMACMembers,
        asDict,
        ...rest
    }
}

// SMAC Members Scorecards
export function useTotalSMACMembers({ lastYear = false }) {
    return useSMACMembersByDimension({ dimension: DIMENSION_SMAC_MEMBER_TOTAL_SMAC_BASE, lastYear })
}

export function useSmacMembersByCardType({ lastYear = false }) {
    return useSMACMembersByDimension({ dimension: DIMENSION_SMAC_MEMBER_CARD_TYPE, lastYear })
}

export function useSmacMembersByRegistered({ lastYear = false }) {
    return useSMACMembersByDimension({ dimension: DIMENSION_SMAC_MEMBER_REGISTERED, lastYear })
}

export function useSmacMembersByUnregistered({ lastYear = false }) {
    return useSMACMembersByDimension({ dimension: DIMENSION_SMAC_MEMBER_UNREGISTERED, lastYear })
}

export function useSmacMembersByCardStatus({ lastYear = false }) {
    return useSMACMembersByDimension({ dimension: DIMENSION_SMAC_MEMBER_CARD_STATUS, lastYear })
}

export function useGeoLocatedMembersCount() {
    const { data, isLoading, ...rest } = useSMACMembersByDimension({ dimension: DIMENSION_GEOLOCATED_MEMBERS });

    return {
        data: data?.[0]?.total_members,
        asOfDate: data?.[0]?.load_date,
        isLoading,
        ...rest
    }
}

export function useCustomerLocationPreparedForTable({ catchment }) {
    const { data, ...rest } = useCustomerLocations({ catchment });

    const preparedData = React.useMemo(() => {
        if (!data) {
            return [];
        }
        const zoneLevelData = data.filter(row => row.store === 'Overall');
        return zoneLevelData.map(row => {
            const zoneStoresData = data.filter(subRow => subRow.store_zone === row.store_zone && subRow.store !== 'Overall');
            return {
                pk: row.store_zone,
                ...row,
                subRows: zoneStoresData.map(subRow => {
                    return {
                        pk: subRow.store,
                        ...subRow
                    }
                })
            }
        })

    }, [data])

    return {
        data: preparedData,
        ...rest
    }
}

export function useOverallLocationStats() {
    // we use the data prepared for table since we can sum up the members for each zone
    const { data: totalGeoLocated, asOfDate, ...rest } = useGeoLocatedMembersCount();

    return {
        latestMoment: asOfDate ? moment(asOfDate) : null,
        totalGeoLocated: totalGeoLocated || 0,
        ...rest
    }
}

// TODO: add more Demographics hooks here
export function useDemographicsByGender({ lastYear = false }) {
    const { data, ...rest } = useDemographics({ lastYear });

    const asDict = React.useMemo(() => {
        if (!data) {
            return null;
        }
        return data.reduce((acc, row) => {
            acc[row.gender_bin] = row.gender_count
            return acc;
        }, {})
    }, [data])

    return {
        data: asDict,
        asDict,
        ...rest
    }
}

export function useDemographicsByAge({ lastYear = false }) {
    const { data, ...rest } = useDemographics({ lastYear });

    const asDict = React.useMemo(() => {
        if (!data) {
            return null;
        }
        return data.reduce((acc, row) => {
            acc[row.age_bin] = row.age_count
            return acc;
        }, {})
    }, [data])

    return {
        data: asDict,
        asDict,
        ...rest
    }
}

export function useDemographicsByGeneration({ lastYear = false }) {
    const { data, ...rest } = useDemographics({ lastYear });

    const asDict = React.useMemo(() => {
        if (!data) {
            return null;
        }
        return data.reduce((acc, row) => {
            acc[row.generation_bin] = row.generation_count
            return acc;
        }, {})
    }, [data])

    return {
        data: asDict,
        asDict,
        ...rest
    }
}

export function useDemographicsByMaritalStatus({ lastYear = false }) {
    const { data, ...rest } = useDemographics({ lastYear })

    const asDict = React.useMemo(() => {
        if (!data) {
            return null;
        }
        return data.reduce((acc, row) => {
            acc[row.marital_status_bin] = row.marital_status_count
            return acc;
        }, {})
    }, [data])

    return {
        data: asDict,
        asDict,
        ...rest
    }
}

