import { useRef, useState } from 'react';
import EditorComponent from '../../../common/components/EditorComponent';
import useChannelOptions from '../../../common/hooks/useChannelOptions';
import SelectWrapper from '../../../common/components/SelectWrapper';
import Iframe from '../../../common/components/Iframe';

export default function EmailContent(props) {
    const editorRef = useRef(null);
    const log = () => {
      if (editorRef.current) {
        console.log(editorRef.current.getContent());
      }
    };

    const { initialValue} = useState(props.values.contentEmail)

    const {isLoading, options} = useChannelOptions('email');

    return <>
        <div className="row mb-2">
            <label className="col-sm-2 ">From <small>*</small></label>
            <div className="col-sm-10">              
                <SelectWrapper options={options} isLoading={isLoading} classNamePrefix="react-select" onChange={(e) => props.onChange('FromEmailId', e ? e.value : null)} value={props.values.FromEmailId} />
            </div>
        </div>
        <div className="row mb-2">
            <label className="fw-semibold col-sm-2">Subject <small>*</small></label>
            <div className="col-sm-10">
                <input className="form-control" type="text"   onChange={(e)=> props.onChange(e.target.name, e.target.value)} name="subjectEmail" value={props.values.subjectEmail}/>
            </div>
        </div>

        <EditorComponent
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={initialValue}
            onEditorChange={(value, editor) => props.onChange('contentEmail', value)}
            init={{                
                height: 550,
                menubar: true,
                plugins: [
                    'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                    'searchreplace', 'table', 'wordcount', 'code'
                  ],
                toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat',
                content_style:  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                
            }}
        />

        
        <div className="d-flex my-3 justify-content-md-end">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#emailWebPreview">
                    Web Preview
                </button>
        </div>       
        <div className="modal fade" id="emailWebPreview" tabIndex="-1" aria-labelledby="emailWebPreviewLabel" aria-hidden="true">
            <div className="modal-dialog  modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-modal-body pt-0">
                        <div className="d-flex flex-column">
                            <div className="ps-4 ms-2 fs-5">
                                {props.values.subjectEmail ? props.values.subjectEmail : 'Email Subject here'}
                            </div>
                            <div className="ps-4 d-flex">
                                <div className="d-flex align-items-center w-100">
                                    <i className="bi bi-person-circle" style={{ fontSize: '1.5rem' }}/>
                                    <label className="ms-2"> {props.values.FromEmailId ? options.filter(option => option.value === props.values.FromEmailId)[0].label : 'From' } </label>
                                </div>
                                
                                

                                <div className="d-flex flex-shrink-1 p-2 fs-5">
                                    <i className="bi bi-star pe-2"></i>
                                    <i className="bi bi-reply pe-2"></i>
                                    <i className="bi bi-three-dots-vertical"></i>
                                </div>
                            </div>
                        </div>
                        {
                             <div className="iframe-embed-web-wrapper ps-4">
                             <Iframe title="web" width={'100%'} height={'100%'}>
                                <div dangerouslySetInnerHTML={{ __html: props.values.contentEmail }} />
                             </Iframe> 
                         </div> 
                        }
                    </div>
                </div>
        </div>
        </div>
        <button onClick={log}>Log editor content</button>
    </>
}