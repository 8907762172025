import {useQuery} from "react-query";
import {convertParamsToTransactionDate, groupArrayBy, preProcessParams, regroupDataBy} from "./util";
import ApiClient from "../../../common/API";
import moment from "moment/moment";
import {useMemo} from "react";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../Constants/settings";
import { sortArrayBasedOnGivenArray } from "../../../common/utils";


export const TABLE_NAME = "l4_ace_agg";

export function useSalesData({ params }) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year

    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const { data: actualSalesData, isLoading: isLoadingSalesData } = useQuery(
        [TABLE_NAME, "sales", "overall", params, 'actual_sales'],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(params, dayOfMonthMaxDate)
            )

            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: "gross_sales_sum,sales_plan_sum,store_count_distinct"
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange
        }
    );


    // query for last year total using the same date range with only the year changed
    const { data: lastYearData, isLoading: isLoadingYearData } = useQuery(
        [TABLE_NAME, "sales", "overall", params, 'last_year'],
        () => {
            const lastYearParams = {
                ...params,
            }
            if (lastYearParams.year) {
                lastYearParams.year = lastYearParams.year - 1;
            }

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(
                    lastYearParams, dayOfMonthMaxDate
                )
            )

            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: "gross_sales_sum"
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate
        }
    );

    const { data: data2019, isLoading: isLoading2019 } = useQuery(
        [TABLE_NAME, "sales", "overall", params, '2019'],
        () => {
            const lastYearParams = {
                ...params,
            }
            if (lastYearParams.year) {
                lastYearParams.year = 2019;
            }

            const processedParams = preProcessParams(
                convertParamsToTransactionDate(
                    lastYearParams, dayOfMonthMaxDate
                )
            )

            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: "gross_sales_sum"
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate
        }
    );

    return {
        actualSalesData,
        targetData: actualSalesData,
        lastYearData,
        data2019,
        isLoading: isLoadingSalesData || isLoadingYearData || isLoading2019
    }
}

export function useSalesOverallPerformanceData({ params }) {
    const {
        actualSalesData,
        targetData,
        lastYearData,
        data2019,
        isLoading
    } = useSalesData({ params });


    const actualSalesTotal = actualSalesData ? actualSalesData[0]?.gross_sales_sum : 0;
    const targetTotal = targetData ? targetData[0]?.sales_plan_sum : 0;
    const lastYearTotal = lastYearData ? lastYearData[0]?.gross_sales_sum : 0;
    const lastYear2019Total = data2019 ? data2019[0]?.gross_sales_sum : 0;

    return {
        actualSales: actualSalesTotal,
        targetSales: targetTotal,
        lastYearSales: lastYearTotal,
        sales2019: lastYear2019Total,
        isLoading: isLoading
    }
}

export function useSalesPerformanceData({ params }) {
    // require group_by
    if (!params || !params.group_by) {
        throw new Error("group_by is required")
    }

    const {
        actualSalesData,
        targetData,
        lastYearData,
        data2019,
        isLoading: isSalesLoading
    } = useSalesData({
        params: {
            columns: params.group_by,
            limit: 100,
            ...params,
        }
    });

    const { group_by, ...rest } = params  //remove group_by key in the params

    const {
        actualSales: actualTotalSalesData,
        isLoading: isLoadingTotalSalesData
    } = useSalesOverallPerformanceData({ params: rest })
    const isLoading = isSalesLoading || isLoadingTotalSalesData
    const data = useMemo(() => {
        let groupBy = params.group_by;
        let otherGroupByKeys = [];
        if (!groupBy) {
            return []
        }

        if (groupBy?.includes(',')) {
            const multiGroupBy = groupBy.split(',')
            groupBy = multiGroupBy[0]
            multiGroupBy.shift();
            otherGroupByKeys = [...multiGroupBy]

        }
        const actualSalesGrouped = regroupDataBy(actualSalesData, groupBy, "gross_sales_sum");
        const storeCountGrouped = regroupDataBy(actualSalesData, groupBy, "store_count_distinct");
        const targetGrouped = regroupDataBy(targetData, groupBy, "sales_plan_sum");
        const lastYearGrouped = regroupDataBy(lastYearData, groupBy, "gross_sales_sum");
        const data2019Grouped = regroupDataBy(data2019, groupBy, "gross_sales_sum");

        const actualSalesGroupedData = regroupDataBy(actualSalesData, groupBy)
        const targetGroupedData = regroupDataBy(targetData, groupBy)
        const lastYearGroupedData = regroupDataBy(lastYearData, groupBy)

        const allKeys = new Set([
            ...Object.keys(actualSalesGrouped),
            ...Object.keys(targetGrouped),
            ...Object.keys(lastYearGrouped),
            ...Object.keys(storeCountGrouped)
        ]);

        return [...allKeys].map(key => {
            // consolidate all groupedData into 1 object
            const actualSalesGroupedDataObj = actualSalesGroupedData?.[key]
            const targetGroupedDataObj = targetGroupedData?.[key]
            const lastYearGroupedDataObj = lastYearGroupedData?.[key]

            const tempObj = {}
            otherGroupByKeys?.forEach(grpKey => {
                tempObj[grpKey] = actualSalesGroupedDataObj?.[grpKey]
                if (!tempObj[grpKey]) {
                    tempObj[grpKey] = targetGroupedDataObj?.[grpKey]
                }
                if (!tempObj[grpKey]) {
                    tempObj[grpKey] = lastYearGroupedDataObj?.[grpKey]
                }
            });
            return {
                [groupBy]: key,
                ...tempObj,
                actual_sales: actualSalesGrouped[key],
                target_sales: targetGrouped[key],
                last_year_sales: lastYearGrouped[key],
                sales_2019: data2019Grouped[key],
                store_count: storeCountGrouped[key],
                percent_contribution: actualSalesGrouped[key] && actualTotalSalesData ? (actualSalesGrouped[key] / actualTotalSalesData) * 100 : null
            }
        })

    }, [params, actualSalesData, targetData, lastYearData, actualTotalSalesData, isLoading])

    return {
        data,
        isLoading
    }
}

export function useSalesPerformancePerGroupData({ params }) {
    

    const { data, isLoading } = useSalesPerformanceData({
        params: {
            ...params,
            group_by: params.group_by
        }
    });

    return {
        data,
        isLoading: isLoading
    }
}

function useAveragePerformanceDataInternal({ params, dayOfMonthMaxDate, enabled }) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year
    const isNotGroupedByProductGroup = !(params && params.group_by === "product_group")
    if (!params || !params.group_by) {
        throw new Error("group_by is required")
    }


    const query = useQuery(
        [TABLE_NAME, "sales", "average", params, "current"],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(params, dayOfMonthMaxDate)
            );
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    columns: params.group_by,
                    aggregates: ["gross_sales_sum", "branch_id_count_distinct", "transaction_date_count_distinct", "transaction_count_sum"].join(","),
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && (!isNotGroupedByProductGroup) && enabled
        }
    );

    const numberOfMonths = params?.month?.length;

    const data = useMemo(() => {
        if (query.isLoading || !query.data) {
            return []
        }

        return query.data.map(item => {
            return {
                ...item,
                average_monthly_store_sales: item.gross_sales_sum / item.branch_id_count_distinct / numberOfMonths,
                average_daily_store_sales: item.gross_sales_sum / item.branch_id_count_distinct / item.transaction_date_count_distinct,
                average_daily_store_transactions: item.transaction_count_sum / item.branch_id_count_distinct / item.transaction_date_count_distinct,
                average_basket_size: item.gross_sales_sum / item.transaction_count_sum,
            }
        })

    }, [params.group_by, query.isLoading, numberOfMonths])

    return {
        ...query,
        data: data,
        isLoading: query.isLoading
    }
}


export function useAvailableDateRange() {
    return useQuery(
        [TABLE_NAME, 'sales', "date_range"],
        () => ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
            params: {
                columns: "year,month",
                group_by: "year,month",
                gross_sales__gt: 0,  // consider only months with sales
                year__lte: 2023,  // FIXME: hardcoded limit to show complete data only
                limit: 1000
            }
        }).then(res => {
            const transactionDates = res.data.data.map(row => {
                return [
                    moment(`${row.year}-${row.month}-01`).toDate(),  // start date
                    moment(`${row.year}-${row.month}-01`).endOf('month').toDate() // end date
                ]
            }).flat().sort((a, b) => a - b)
            return {
                "min": transactionDates[0],
                "max": transactionDates[transactionDates.length - 1]
            }
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
}


function useStorePerformanceDataInternal({ params, dayOfMonthMaxDate, enabled }) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year

    if (!params || !params.group_by) {
        throw new Error("group_by is required")
    }


    const query = useQuery(
        [TABLE_NAME, "sales", "average", params, "current"],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(params, dayOfMonthMaxDate)
            )

            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    columns: params.group_by,
                    aggregates: ["gross_sales_sum", "branch_id_count_distinct", "transaction_date_count_distinct", "transaction_count_sum"].join(","),
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && enabled
        }
    );

    const numberOfMonths = params?.month?.length;
    const data = useMemo(() => {
        if (query.isLoading || !query.data) {
            return []
        }

        const groupBy = params.group_by.split(',')[0];

        return query.data.map(item => {


            return {
                ...item,
                average_daily_store_sales: item.gross_sales_sum / item.branch_id_count_distinct / item.transaction_date_count_distinct,
            }
        })

    }, [params.group_by, query.isLoading, numberOfMonths])

    return {
        ...query,
        data: data,
        isLoading: query.isLoading
    }
}

export function useStorePerformanceData({ params }) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year
    const groupBy = params.group_by
    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const {
        targetData,
        actualSalesData,
        isLoading: isLoadingStoreSalesData,
    } = useSalesData({
        params: {
            columns: groupBy,
            ...params,
        }
    });

    const {
        data: averageCurrentSalesData,
        isLoading: isLoadingCurrentSalesData
    } = useStorePerformanceDataInternal({
        params,
        dayOfMonthMaxDate,
        enabled: hasTransactionDate && !isLoadingDateRange
    });
    const {
        data: averageLastYearSalesData,
        isLoading: isLoadingLastYearSalesData
    } = useStorePerformanceDataInternal({
        params: {
            ...params,
            year: params.year ? params.year - 1 : null
        },
        dayOfMonthMaxDate,
        enabled: hasTransactionDate && !isLoadingDateRange
    });

    const {
        data: average2019SalesData,
        isLoading: isLoading2019SalesData
    } = useStorePerformanceDataInternal({
        params: {
            ...params,
            year: 2019
        },
        dayOfMonthMaxDate,
        enabled: hasTransactionDate && !isLoadingDateRange
    });

    const isLoading = isLoadingCurrentSalesData || isLoadingStoreSalesData || isLoadingLastYearSalesData || isLoading2019SalesData;

    const data = useMemo(() => {
        if (isLoading) {
            return []
        }
        // TODO: handle errors
        let groupBy = params.group_by;
        if (!groupBy) {
            return []
        }

        if (groupBy?.includes(',')) {
            const multiGroupBy = groupBy.split(',')
            groupBy = multiGroupBy[0]
        }
        const averageCurrentSalesGrouped = regroupDataBy(averageCurrentSalesData, groupBy);
        const averageLastYearSalesGrouped = regroupDataBy(averageLastYearSalesData, groupBy);
        const average2019SalesGrouped = regroupDataBy(average2019SalesData, groupBy);
        const targetFullObjectGrouped = regroupDataBy(targetData, groupBy);
        const targetGrouped = regroupDataBy(targetData, groupBy, "sales_plan_sum");
        const actualSalesGrouped = regroupDataBy(actualSalesData, groupBy, "gross_sales_sum");

        const allKeys = new Set([
            ...Object.keys(averageCurrentSalesGrouped),
            ...Object.keys(averageLastYearSalesGrouped),
            ...Object.keys(targetGrouped),
            ...Object.keys(actualSalesGrouped)
        ])


        return [...allKeys].map(key => {
            const mergedSalesGrouped = { ...averageCurrentSalesGrouped[key], ...averageLastYearSalesGrouped[key], ...targetFullObjectGrouped[key] }

            return {
                [groupBy]: key,
                ...mergedSalesGrouped,
                ads: averageCurrentSalesGrouped[key]?.average_daily_store_sales ?
                    averageCurrentSalesGrouped[key]?.average_daily_store_sales :
                    null,
                target_sales: targetGrouped[key] ?
                    targetGrouped[key] :
                    null,
                actual_sales: actualSalesGrouped[key] ?
                    actualSalesGrouped[key] :
                    null,
                last_year_actual_sales: averageLastYearSalesGrouped[key]?.gross_sales_sum ?
                    averageLastYearSalesGrouped[key]?.gross_sales_sum :
                    null,
                vs_target: (actualSalesGrouped[key] && targetGrouped[key]) ?
                    (actualSalesGrouped[key] / targetGrouped[key]) * 100 :
                    null,
                vs_last_year_mtd_ytd_sales: (averageCurrentSalesGrouped[key]?.gross_sales_sum && averageLastYearSalesGrouped[key]?.gross_sales_sum) ?
                    (averageCurrentSalesGrouped[key]?.gross_sales_sum / averageLastYearSalesGrouped[key]?.gross_sales_sum) * 100 :
                    null,
                sales_2019_actual_sales: average2019SalesGrouped[key]?.gross_sales_sum ?
                    average2019SalesGrouped[key]?.gross_sales_sum :
                    null,
                vs_2019_actual_sales: (average2019SalesGrouped[key]?.gross_sales_sum && averageCurrentSalesGrouped[key]?.gross_sales_sum) ?
                    (averageCurrentSalesGrouped[key]?.gross_sales_sum / average2019SalesGrouped[key]?.gross_sales_sum) * 100 :
                    null,
                dayOfMonthMaxDate: dayOfMonthMaxDate,
                original: {
                    current: averageCurrentSalesGrouped[key],
                    last_year: averageLastYearSalesGrouped[key],
                }
            }
        })

    }, [params.group_by, averageCurrentSalesData, averageLastYearSalesData, targetData, actualSalesData, isLoading])

    return {
        data,
        isLoading
    }
}


export function useSalesPerformanceMtdYtdData({ params }) {
    // require group_by
    if (!params || !params.group_by) {
        throw new Error("group_by is required")
    }

    const {
        actualSalesData: mtdActualSalesData,
        targetData: mtdTargetData,
        lastYearData: mtdLastYearData,
        isLoading: mtdIsLoading
    } = useSalesData({
        params: {
            ...params,
            columns: params.group_by,
            limit: 100,
        }
    });

    const {
        actualSalesData: ytdActualSalesData,
        targetData: ytdTargetData,
        lastYearData: ytdLastYearData,
        isLoading: ytdIsLoading
    } = useSalesData({
        params: {
            ...params,
            columns: params.group_by,
            limit: 100,
            month: [1, ...(params?.month || [])],
        }
    });

    const { group_by, ...rest } = params //remove group_by key in the params
    const {
        actualSales: mtdActualTotalSalesData,
        isLoading: isLoadingMtdTotalSalesData
    } = useSalesOverallPerformanceData({ params: rest })
    const {
        actualSales: ytdActualTotalSalesData,
        isLoading: isLoadingYtdTotalSalesData
    } = useSalesOverallPerformanceData({
        params: {
            ...rest,
            month: [1, ...(params?.month || [])],
        }
    })
    const isLoading = mtdIsLoading || ytdIsLoading || isLoadingMtdTotalSalesData || isLoadingYtdTotalSalesData;
    const data = useMemo(() => {
        let groupBy = params.group_by;
        let otherGroupByKeys = [];
        if (!groupBy) {
            return []
        }

        if (groupBy?.includes(',')) {
            const multiGroupBy = groupBy.split(',')
            groupBy = multiGroupBy[0]
            multiGroupBy.shift();
            otherGroupByKeys = [...multiGroupBy]

        }
        const mtdActualSalesGrouped = regroupDataBy(mtdActualSalesData, groupBy, "gross_sales_sum");
        const mtdTargetGrouped = regroupDataBy(mtdTargetData, groupBy, "sales_plan_sum");
        const mtdLastYearGrouped = regroupDataBy(mtdLastYearData, groupBy, "gross_sales_sum");
        const ytdActualSalesGrouped = regroupDataBy(ytdActualSalesData, groupBy, "gross_sales_sum");
        const ytdTargetGrouped = regroupDataBy(ytdTargetData, groupBy, "sales_plan_sum");
        const ytdLastYearGrouped = regroupDataBy(ytdLastYearData, groupBy, "gross_sales_sum");

        //used only mtdData since this is for getting the group_by fields in params
        const actualSalesGroupedData = regroupDataBy(mtdActualSalesData, groupBy);
        const targetGroupedData = regroupDataBy(mtdTargetData, groupBy);
        const lastYearGroupedData = regroupDataBy(mtdLastYearData, groupBy);

        const allKeys = new Set([
            ...Object.keys(mtdActualSalesGrouped),
            ...Object.keys(mtdTargetGrouped),
            ...Object.keys(mtdLastYearGrouped),
            ...Object.keys(ytdActualSalesGrouped),
            ...Object.keys(ytdTargetGrouped),
            ...Object.keys(ytdLastYearGrouped)
        ]);

        return [...allKeys].map(key => {
            // consolidate all groupedData into 1 object
            const actualSalesGroupedDataObj = actualSalesGroupedData?.[key]
            const targetGroupedDataObj = targetGroupedData?.[key]
            const lastYearGroupedDataObj = lastYearGroupedData?.[key]

            const tempObj = {}
            otherGroupByKeys?.forEach(grpKey => {
                tempObj[grpKey] = actualSalesGroupedDataObj?.[grpKey]
                if (!tempObj[grpKey]) {
                    tempObj[grpKey] = targetGroupedDataObj?.[grpKey]
                }
                if (!tempObj[grpKey]) {
                    tempObj[grpKey] = lastYearGroupedDataObj?.[grpKey]
                }
            });

            return {
                [groupBy]: key,
                ...tempObj,
                mtd_actual_sales: mtdActualSalesGrouped[key],
                mtd_percent_contribution: mtdActualSalesGrouped[key] && mtdActualTotalSalesData ? (mtdActualSalesGrouped[key] / mtdActualTotalSalesData) * 100 : null,
                mtd_target_sales: mtdTargetGrouped[key],
                mtd_last_year_sales: mtdLastYearGrouped[key],
                mtd_vs_target: (mtdActualSalesGrouped[key] / mtdTargetGrouped[key]) * 100,
                mtd_vs_last_year: (mtdActualSalesGrouped[key] / mtdLastYearGrouped[key]) * 100,
                ytd_actual_sales: ytdActualSalesGrouped[key],
                ytd_target_sales: ytdTargetGrouped[key],
                ytd_last_year_sales: ytdLastYearGrouped[key],
                ytd_vs_target: (ytdActualSalesGrouped[key] / ytdTargetGrouped[key]) * 100,
                ytd_vs_last_year: (ytdActualSalesGrouped[key] / ytdLastYearGrouped[key]) * 100,
                ytd_percent_contribution: ytdActualSalesGrouped[key] && ytdActualTotalSalesData ? (ytdActualSalesGrouped[key] / ytdActualTotalSalesData) * 100 : null,
            }
        })

    }, [params, mtdActualSalesData, mtdTargetData, mtdLastYearData, ytdActualSalesData, ytdTargetData, ytdLastYearData, mtdActualTotalSalesData, ytdActualTotalSalesData, isLoading])
    return {
        data,
        isLoading
    }
}


export function useGraphInTableData({params}) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year

    const {data: availableDateRange, isLoading: isLoadingDateRange} = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const {data: graphData , isLoading } = useQuery(
        [TABLE_NAME, "sales", params, 'sparkline'],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(params, dayOfMonthMaxDate)
            )

            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,  // fixme: base it on the selected year-month. currently, it's based on the latest month
                    aggregates: "gross_sales_sum,sales_plan_sum",
                    columns:`${params?.group_by},year,month`,
                    group_by: [params?.group_by, 'year', 'month'].join(','),
                    limit: 10000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange
        }
    );
    const data = useMemo(() => {
        if (isLoading) {
            return []
        }

        let groupBy = params.group_by.split(',')[0];

        const orderArray = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
        
        const sortedData = sortArrayBasedOnGivenArray(graphData, orderArray, 'month')
        const graphGroupedData = groupArrayBy(sortedData, groupBy);
        return graphGroupedData;
    },[params, graphData, isLoading])

    return {
        data,
        isLoading
    }
}