
export default function CampaignOverview(props) {

    function convert24hrTime(hrs) {
        const splitHrs = hrs.split(":")
        const suffix = splitHrs[0] >= 12 ? "PM" : "AM"
        const hours = splitHrs[0] > 12 ? splitHrs[0] - 12 : splitHrs[0];
        
        return hours + ":" + splitHrs[1]+ " " + suffix;
    }

    return <>
        {/* <div className="row mb-3">
            <div className="col-sm-4">Selected Mall:</div>
            <div className="col-sm-8 text-capitalize"> { MALL_OPTIONS.filter(mall => mall.mallCode === props.values.mall)[0].name  }</div>
        </div> */}

        {/* <div className="row mb-3">
            <div className="col-sm-4">Limit Target by:</div>
            <div className="col-sm-8 text-capitalize"> { props.values.targetBy} ,  <span className="ps-2">{ props.values.targetBy === 'budget' ? '₱' + props.values.budget : props.values.targetCount + ' members'} </span></div>
        </div> */}

        <div className="row mb-3">
            <div className="col-sm-4">Communcication Channel:</div>
            <div className="col-sm-8 text-capitalize"> { props.values.channel }</div>
        </div>

        <div className="row mb-3">
            <div className="col-sm-4">Schedule of Blasting:</div>
            <div className="col-sm-8"> { props.values.date.toDateString() + ' , ' + convert24hrTime(props.values.time) } </div>
        </div>

        
    </>
}