import React, { useState } from "react"
import { toast } from 'react-toastify';
import { useSave } from "../utils";
import { useGroupList } from "../../../common/API/hooks";
import { getSelectedOption } from "../../../common/utils";
import { Button, Grid, IconButton, Typography, TextField, Divider, Stack, Autocomplete } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DropdownCheckboxV3 from "../../../common/components/DropdownCheckboxV3";
import Modal from '@mui/material/Modal'
import Card from '@mui/material/Card';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import handleError from "../ErrorHandling"

export default function AddUser() {
    const [isOpen, setIsOpen] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const { data: groupList, isLoading: groupLoading } = useGroupList()
    const [userData, setUserData] = useState({
        adid: '',
        firstName: '',
        lastName: '',
        email: '',
        groups: []
    });

    const { adid, firstName, lastName, email, groups } = userData;

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setUserData({
            adid: '',
            firstName: '',
            lastName: '',
            email: '',
            groups: []
        })
        setIsOpen(false);
        setIsValidEmail(true)
    };

    const { saveData: saveUsers } = useSave()

    const groupOptions = React.useMemo(() => {
        if (groupLoading) {
            return []
        }
        if (!groups) {
            return []
        }
        return groupList?.map((group) => {
            return {
                value: group.name,
                label: group.name,
                key: group.id,
            }
        })
    }, [groups, groupLoading]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value
        }));
        if (name === 'email') {
            setIsValidEmail(validateEmail(value));
        }
    };

    const handleGroupsChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value)
        setUserData((prevUserData) => ({
            ...prevUserData,
            groups: selectedValues
        }));
    };

    const validateEmail = (input) => {
        // Regular expression for validating email address
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(input);
    };

    const handleSave = () => {
        const userData = {
            adid: adid,
            first_name: firstName,
            last_name: lastName,
            username: email,
            groups: groups,

        }
        saveUsers('user', userData).then(() => {
            setUserData({
                adid: '',
                firstName: '',
                lastName: '',
                email: '',
                groups: []
            })
            setIsOpen(false)
            return toast.success('User saved!');
        })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "User", "save");
                return toast.error(errorMessage);
            });
    }

    const isSubmitDisabled = !adid || !firstName || !lastName || !email || !isValidEmail;

    return (
        <>
            <Button
                variant="contained"
                sx={{ bgcolor: "#0F2FEB" }}
                size="medium"
                onClick={openModal}
                startIcon={<AddIcon />}>
                ADD USER
            </Button>
            <Modal
                open={isOpen}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: 'auto',
                    justifyContent: 'center'
                }} >
                <Grid xs={10} md={4}>
                    <Card sx={{ minHeight: '45vh' }}>
                        <Grid container justifyContent="space-between" p={2} >
                            <Grid display="flex" columnGap={1}>
                                <PersonAddAltRoundedIcon fontSize="large" />
                                <Typography variant="h5" fontWeight="bold" >
                                    Add User
                                </Typography>
                            </Grid>
                            <IconButton aria-label="close" size="small" color="error"
                                onClick={closeModal}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid sx={{ p: 2 }}>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "400px" }}
                                    size="small"
                                    aria-label="adid"
                                    id="adid"
                                    name="adid"
                                    label="ADID / Network Username"
                                    placeholder="ADID / Network Username"
                                    value={adid}
                                    onChange={handleInputChange}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "400px" }}
                                    size="small"
                                    aria-label="firstName"
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={handleInputChange}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "100%" }}
                                    size="small"
                                    aria-label="lastName"
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={handleInputChange}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    type="email"
                                    error={!isValidEmail}
                                    sx={{ width: "400px" }}
                                    size="small"
                                    aria-label="emailInput"
                                    id="emailInput"
                                    name="email"
                                    label="Email Address"
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={handleInputChange}
                                    helperText={!isValidEmail && "Invalid email address"}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={groupOptions}
                                    label="Select Group(s)"
                                    // placeholder="Select Group(s)"
                                    limitTags={1}
                                    onChange={(event, newValue) => {
                                        handleGroupsChange(getSelectedOption(newValue, groupOptions));
                                    }}
                                    value={getSelectedOption(groups, groupOptions)} />
                            </Grid>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid container
                            p={1.5}
                            columnGap={{ sm: 'none', md: 4 }}
                            marginLeft={{ xs: 'none', md: -2.5 }}
                            justifyContent={{ xs: 'space-between', md: 'flex-end' }}>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{ color: "#0F2FEB", width: "80px" }}
                                onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ bgcolor: "#0F2FEB", width: "80px" }}
                                onClick={handleSave}
                                disabled={isSubmitDisabled}>
                                Save
                            </Button>
                        </Grid>
                    </Card>
                </Grid >
            </Modal >
        </>
    )
}