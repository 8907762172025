import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PhotoIcon from '@mui/icons-material/Photo';

export default function ChartPlaceholder() {

    return(
            <Box textAlign="center" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection={'column'}>
                <PhotoIcon sx={{ fontSize:'25rem'}} color="disabled"/>
                <Typography>Chart preview will be shown here.</Typography>
            </Box>
    )
}