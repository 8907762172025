import React from "react";
import StoreAnalytics from "../../index"
import chart from "../../../../../img/chart-hypermarket.png"

export default function LocationAndMarketAnalytics() {
    return (
        <StoreAnalytics
            title={"Location and Market Analysis"}
            locationApiEndPoint={'/custom/location/'}
            chartViewImage={chart}
            isLocationMapping={true}
        />
    );
}