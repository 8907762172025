import { Box,IconButton, MenuItem, Select, FormControl, FormGroup, FormControlLabel, Checkbox, Popper, Paper, Fade} from "@mui/material";
import { useState } from "react";
import { useMap } from "react-leaflet";
import MenuIcon from '@mui/icons-material/Menu';



const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}



export default function LayerControl(props) {
    const checkboxItems = ['Sales', 'Population', 'Location']

    const map = useMap();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
  
    const handleClick = (newPlacement) => (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

    function onSelectionDropdown(val){
        switch(val) {
            case 'province':
                map.setMinZoom(8)
                map.setZoom(8)
                break;
            case 'city':
                map.setMinZoom(10)
                map.setZoom(10)
                break;
            case 'barangay':  
                map.setMinZoom(12)  
                map.setZoom(12)
                break;
            default:         
                map.setMinZoom(5)
                map.setZoom(5)
                break;
        }
    }
    
    function onSelectLayerToDisplay(e) {
        const { value } = e.target

       if(props.mapLayersToDisplay.includes(value)) {
            props.setMapLayersToDisplay(props.mapLayersToDisplay.filter(item=> item !== value))
       } else {
            props.setMapLayersToDisplay([...props.mapLayersToDisplay, value])
       }
    }
    function renderCheckboxes() {
        const checkboxes = checkboxItems.map(item => {
            return <FormControlLabel key={item} control={<Checkbox value={item} id={item} onChange={onSelectLayerToDisplay} size="small" checked={props.mapLayersToDisplay.includes(item)}  />} label={item} />
        })

        return <FormGroup>
            {checkboxes}
        </FormGroup>
    }
    return (
        <Box className={POSITION_CLASSES[props.position]} >
            <Box className="leaflet-control leaflet-bar" >
                {/* BUTTON COMPONENTS  */}
                <Box className="float-start">
                    <IconButton id="leaftet-control-menu-button"
                        sx={{ backgroundColor: '#fff', borderRadius: 0,
                            "&.MuiButtonBase-root:hover": {
                                bgcolor: "#fff"
                            }
                        }}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick('bottom-end')}
                        color="primary">
                        < MenuIcon/>
                    </IconButton>                    
                </Box>
                {/* COLLAPSE CARD COMPONENTS */}
                <Popper  id={'menuPopper'} open={open} anchorEl={anchorEl} placement={placement} transition sx={{ zIndex: 1000}}>
                    {({ TransitionProps }) =>  (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            sx={{ width: '10rem', padding: '1rem'}}>
                            { renderCheckboxes() }
                            <FormControl size="small">
                                <Select onChange={(e)=> {props.onSelectPolygonLevel(e.target.value); onSelectionDropdown(e.target.value)}} value={props.polygonLevel}>
                                    <MenuItem value="region" >Regional</MenuItem>
                                    <MenuItem value="province">Province</MenuItem>
                                    <MenuItem value="city">City</MenuItem>
                                    <MenuItem value="barangay">Barangay</MenuItem>
                                </Select>
                            </FormControl>
                        </Paper>
                    </Fade>
                    )}
                </Popper>
            </Box>
        </Box>
    );
}
