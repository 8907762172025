import { useState, useRef, useEffect } from 'react';
import AuthCode from 'react-auth-code-input';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TabPanelComponent from '../../common/components/TabPanelComponent';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { FormControlLabel, Checkbox } from '@mui/material';

export default function Login() {

    const [email, setEmail] = useState('');
    const [adid, setAdid] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [registerMessage, setRegisterMessage] = useState('');
    const [otp, setOtp] = useState();
    const [activeTab, setActiveTab] = useState('login');
    const [rememberMe, setRememberMe] = useState(false)
    const AuthInputRef = useRef(null);

    const resetFields = () => {
        setEmail('')
        setAdid('')
        setPassword('')
        setErrorMessage('')
        setRegisterMessage('')
        AuthInputRef.current?.clear() //clearing OTP
    }

    const resetRegisterForm = () => {
        setEmail('')
        setAdid('')
    }

    // added con
    const switchToRegisterForm = () => {
        setActiveTab('register'); // Switch to the "RegisterForm" tab (index 1)
        resetFields(); // Reset the form fields
    };

    const switchToLoginForm = () => {
        setActiveTab('login'); // Switch back to the "LoginForm" tab
        resetFields(); // Reset the form fields
    };

    //check only when mounted

    useEffect(() => {
        if (localStorage.getItem('is_session_expired')) {
            toast.error("Please login again", {
                autoClose: false
            })
            localStorage.removeItem('is_session_expired');
        }
    }, [])

    return (
        <>
            <ToastContainer />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                sx={{
                    minHeight: "95vh",
                }}>
                <Box
                    sx={{
                        color: "primary.main",
                        typography: "h4",
                        textAlign: "center",
                        mb: "40px",
                        fontFamily: "Henry Sans"
                    }}>
                    <span style={{ fontWeight: 900 }}>SM</span>Intelligence
                </Box>
                <Grid item sx={{ minHeight: '100%' }} xs={3}>
                    <Grid
                        sx={{
                            maxHeight: "40%",
                            // maxWidth: 500,
                            width: 400,
                            border: 1,
                            borderColor: 'divider',
                            borderRadius: 1,
                            boxShadow: 3,
                            p: 4,

                        }}>
                        <TabPanelComponent value={activeTab} index="login">
                            <LoginForm
                                email={email}
                                setEmail={setEmail}
                                password={password}
                                setPassword={setPassword}
                                otp={otp}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                disabled={email === '' || password === ''}
                                rememberMe={rememberMe}
                            />
                            <Box sx={{ width: '100%', display:"flex", justifyContent:"end" }}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                             onChange={()=>setRememberMe(!rememberMe)}
                                             checked={rememberMe}
                                        />}
                                    label="Remember me"
                                    labelPlacement="start"
                                />
                            </Box>
                            <Box
                                sx={{
                                    typography: "subtitle1",
                                    textAlign: "center",
                                    mt: "10px"
                                }}
                                style={{
                                    fontFamily: "Henry Sans",
                                }}
                            >
                                Don't have an account?
                                <Button
                                    size="large"
                                    variant="button"
                                    label="Login"
                                    onClick={switchToRegisterForm}// Switch back to registration form*** function set at index
                                    style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                        color: "#1976d2",
                                        textTransform: "none",
                                    }}>{'Register Here'}
                                </Button>
                            </Box>
                        </TabPanelComponent>


                        <TabPanelComponent value={activeTab} index="register">
                            <Grid container spacing={2} columns={16}>
                                <Grid item xs={5.5}>
                                    <Button
                                        size="small "
                                        fontFamily="Henry Sans"
                                        startIcon={<NavigateBeforeIcon />}
                                        style={{ textTransform: "none", fontWeight: "bold" }}
                                        onClick={switchToLoginForm} // Switch back to login form*** function set at index
                                    > Back
                                    </Button>
                                </Grid>
                                <Grid item xs={10.5}>
                                    <Box
                                        sx={{
                                            mt: 3,
                                            typography: "h5",
                                            textAlign: "left"
                                        }}
                                        style={{
                                            fontFamily: "Henry Sans",
                                            fontWeight: "bold"
                                        }}
                                    >Register
                                    </Box>
                                </Grid>
                            </Grid>
                            <RegisterForm
                                email={email}
                                setEmail={setEmail}
                                adid={adid}
                                setAdid={setAdid}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                registerMessage={registerMessage}
                                setRegisterMessage={setRegisterMessage}
                                disabled={email === '' || adid === ''}
                                clearFields={resetRegisterForm}
                            />
                        </TabPanelComponent>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}