import React from "react";
import JSONInput from "react-json-editor-ajrm";
import {isObjectEqual} from "../../../../common/utils";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';

export default function ExtraDataEditor({extraData, setExtraData}) {
    const [localExtraData, setLocalExtraData] = React.useState(extraData)
    const [placeholderExtraData, setPlaceholderExtraData] = React.useState(extraData)
    console.log("Loaded extra data editor")
    const onChangeExtraData = (e) => {
        if (!e.error) {
            setLocalExtraData(e.jsObject)
            setExtraData(e.jsObject)
        }
    }

    React.useEffect(() => {
        if (!isObjectEqual(extraData, localExtraData)) {
            // update the local states and placeholder
            setLocalExtraData(extraData)
            setPlaceholderExtraData(extraData)
            console.log("Extra data changed from outside")
        }
    }, [extraData])

    return (
        <>
            <Accordion>
                <AccordionSummary borderRadius
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="advanced-content"
                    id="advanced-editor-header"
                >
                    <Typography fontWeight="bold" sx={{ color: "primary.main" }}>Advanced Editor</Typography>
                </AccordionSummary> 
                <AccordionDetails>
                    <Box sx={{ color: "#664d03", backgroundColor: "#fff3cd", padding: ".5rem", marginBottom: ".5rem", borderRadius: "1rem", borderColor: "#ffecb5" }}>
                        <Typography display="block" fontWeight="bold" sx={{ color: "red" }}>
                            <WarningIcon fontSize="small" sx={{marginRight:".5rem", mb: .5, color:"red", ml: 1}}/>Warning! This feature is for advanced users.</Typography>
                            <Typography  align="justify" >
                            Make sure that you know what you are doing, and that you have a backup of your chart config.
                            This will directly edit the config passed to the graph generator. It will accept well structured JSON only.</Typography>
                        
                    </Box>
                    <JSONInput
                                id="extra-data-editor"
                                placeholder={placeholderExtraData}
                                locale="en"
                                onChange={onChangeExtraData}/>
                </AccordionDetails>
            </Accordion>
        </>
    )
}
