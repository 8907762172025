import React from 'react';
import moment from "moment/moment";
import { Grid, Typography, Card, CardMedia } from "@mui/material";
import Index from "./comparisonIndicator";
import NumericValueDisplay from "../numericValueDisplay";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import sales from "../../../../../img/sales.svg";
import transactions from "../../../../../img/transactions.svg";
import basket_size from "../../../../../img/basket_size.svg"
import catchment_rate from "../../../../../img/catchment_rate.svg";
import conversion_rate from "../../../../../img/conversion_rate.svg";
import mall_foot_traffic from "../../../../../img/mall_foot_traffic.svg";
import dept_store_foot_traffic from "../../../../../img/dept_store_foot_traffic.svg";
import units_sold_per_txn from "../../../../../img/units_sold_per_txn.svg";
import unit_price from "../../../../../img/unit_price.svg"
import units_sold from "../../../../../img/units_sold.svg";
import frequency_visit from "../../../../../img/frequency_visit.svg";
import { WindowSizeContext } from '../../../../../common/context/WindowSizeContext';
import { useContext } from 'react';

const DEFAULT_TEXT_MAP = {
    primaryLabel: 'Sales',
    targetLabel: 'Target',
    noValue: <i>(Not Applicable)</i>,  // not necessary to add in terminologyMap since no dashboard is currently
    // changing this, but for future and testing purposes
    dateFormatYearMonth: 'MMM YYYY',
    dateFormatMonth: 'MMM',
    imgIcon: sales,
    prefix: '₱ ' // default prefix
}


export default function PeriodTopLine(
    {
        periodFrom = new Date(),
        periodTo = new Date(),
        periodDisplayGranularity = 'month',
        primaryValue = 0,
        targetValue = 0,
        comparisonValues = [],
        textMap = DEFAULT_TEXT_MAP,
        isLoading = true,
        isBUTopLine = false,
        isStickyDataCardDisplay = false,
        isCustomerDataCardDisplay = false,
        isStickyCustomerDataCardDisplay = false,
        params,
        period,
        isCompletePeriod
    }) {

    const _textMap = {
        ...DEFAULT_TEXT_MAP,
        ...textMap,
    }

    const windowSize = useContext(WindowSizeContext);
    const periodDisplayText = React.useMemo(() => {
        if (periodDisplayGranularity === 'month') {
            // check if same month and year
            const isSameMonthAndYear = moment(periodFrom).isSame(periodTo, 'month') &&
                moment(periodFrom).isSame(periodTo, 'year');
            if (isSameMonthAndYear) {
                return moment(periodFrom).format(_textMap.dateFormatYearMonth);
            }
            // check if same year
            const isSameYear = moment(periodFrom).isSame(periodTo, 'year');
            if (isSameYear) {
                return `${moment(periodFrom).format(_textMap.dateFormatMonth)} - ${moment(periodTo).format(_textMap.dateFormatYearMonth)}`;
            }
            return `${moment(periodFrom).format(_textMap.dateFormatYearMonth)} - ${moment(periodTo).format(_textMap.dateFormatYearMonth)}`;
        }
    }, [periodFrom, periodTo, periodDisplayGranularity]);

    if (isLoading) {
        // TODO: instead of full component is replaced by loading, each value should be loading
        return <LoadingComponent />
    }
    const imgIcon = _textMap['imgIcon'];

    const postFix = (imgIcon === catchment_rate || imgIcon === conversion_rate) ? ' %' : '';
    const preFix = (imgIcon === transactions || imgIcon === units_sold_per_txn || imgIcon === catchment_rate || imgIcon === conversion_rate || imgIcon === mall_foot_traffic || imgIcon === dept_store_foot_traffic || imgIcon === units_sold || imgIcon === frequency_visit) ? ' ' : _textMap['prefix'];

    if (isBUTopLine) {
        return <>
            <Grid container >
                <Grid item xs={12} sm={12} paddingY={.5} textAlign={'center'} >
                    <Grid item sm={12} xs={12} alignItems={"center"} display={"flex"} justifyContent={'center'}>
                        <img src={_textMap['imgIcon']} alt={_textMap['imgIcon']} height="30rem" width="auto" />
                        <Typography variant='h7' fontWeight='500' marginLeft={'.5rem'} style={{ color: '#000' }}>{_textMap['primaryLabel']}</Typography>
                        <Typography variant='h7' fontWeight='500' marginLeft={'.5rem'} alignItems='center'> ({periodDisplayText}) </Typography>
                    </Grid>
                    {(comparisonValues?.length > 1) || (params.cm_channel_desc === 'Personal Shopper') ? <>
                        <Grid item xs={12} >
                            <Typography variant="h5" fontWeight="bold" style={{ color: '#000' }}>
                                <Grid item>
                                    <NumericValueDisplay isTopLine={true} value={primaryValue} postfix={postFix} prefix={preFix} isThousandsOnly={_textMap['isThousandsOnly']} />
                                </Grid>
                            </Typography>
                        </Grid>
                        {
                            _textMap['targetLabel'] && (
                                <Grid item >
                                    <Typography variant='h7'>
                                        <Grid item display={'inline'}>
                                            {_textMap['targetLabel']}:{" "}
                                            <NumericValueDisplay
                                                isTopLine={true} value={targetValue} postfix={postFix}
                                                prefix={preFix}
                                                isOnScoreCard={true}
                                                isThousandsOnly={_textMap['isThousandsOnly']} />
                                        </Grid>
                                    </Typography>
                                </Grid>
                            )
                        }
                        {
                            comparisonValues?.length > 0 && (
                                <>
                                    <Grid item xs display={'flex'}>
                                        {comparisonValues.map((comparisonValue, index) => {
                                            const { label, value } = comparisonValue;
                                            let renderedValue;
                                            if (value === 'N/A') {
                                                renderedValue = 'N/A';
                                            } else {
                                                renderedValue = Math?.round(primaryValue / value * 100);
                                            }
                                            return <Index key={index} isBUindicator={true} labelValue={value} label={label} value={renderedValue} params={params} period={period} />;
                                        })}
                                    </Grid>
                                </>
                            )
                        }
                    </> : <>
                        <Grid container xs={12} alignItems={'center'} justifyContent={'center'} gap={2}>
                            <Grid item >
                                <Grid item >
                                    <Typography variant="h5" fontWeight="bold" style={{ color: '#000' }}>
                                        <NumericValueDisplay isTopLine={true} value={primaryValue} postfix={postFix} prefix={preFix} isThousandsOnly={_textMap['isThousandsOnly']} />
                                    </Typography>
                                </Grid>
                                {
                                    _textMap['targetLabel'] && (
                                        <Grid item >
                                            <Typography variant='h7'>
                                                <Grid item display={'inline'}>
                                                    {_textMap['targetLabel']}:{" "}
                                                    <NumericValueDisplay
                                                        isTopLine={true} value={targetValue} postfix={postFix}
                                                        prefix={preFix}
                                                        isOnScoreCard={true}
                                                        isThousandsOnly={_textMap['isThousandsOnly']} />
                                                </Grid>
                                            </Typography>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            {
                                comparisonValues?.length > 0 && (
                                    <>
                                        <Grid item display={'flex'}>
                                            {comparisonValues?.map((comparisonValue, index) => {
                                                const { label, value } = comparisonValue;
                                                let renderedValue;
                                                if (value === 'N/A') {
                                                    renderedValue = 'N/A';
                                                } else {
                                                    renderedValue = Math.round(primaryValue / value * 100);
                                                }
                                                return <Index key={index} isBUindicator={true} labelValue={value} label={label} value={renderedValue} params={params} period={period} />;
                                            })}
                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>
                    </>
                    }
                </Grid >
            </Grid >
        </>
    }

    if (isStickyDataCardDisplay) {
        return <Grid container >
            <Grid item xs={12} sm={12} paddingY={.5} textAlign={'center'} >
                <Grid item sm={12} xs={12} alignItems={"center"} display={"flex"} justifyContent={'center'}>
                    <img src={_textMap['imgIcon']} alt={_textMap['imgIcon']} height="30rem" width="auto" />
                    <Typography variant='h7' fontWeight='500' marginLeft={'.5rem'} style={{ color: '#000' }}>{_textMap['primaryLabel']}</Typography>
                    <Typography variant='h7' fontWeight='500' marginLeft={'.5rem'} alignItems='center'> ({periodDisplayText}) </Typography>
                </Grid>
                <Grid item xs={12} sm={12} >
                    <Typography variant="h5" fontWeight="bold" style={{ color: '#000' }}>
                        <Grid item display={'inline'}>
                            <NumericValueDisplay isTopLine={true} value={primaryValue} postfix={postFix} prefix={preFix} />
                        </Grid>
                    </Typography>
                </Grid>
            </Grid >
        </Grid >
    }

    if (isCustomerDataCardDisplay) {
        return <Grid container>
            <Grid item xs={12} sm={12} paddingY={.5} textAlign={'center'} >
                <Grid item sm={12} xs={12} alignItems={"center"} display={"flex"} justifyContent={'center'}>
                    <img src={_textMap['imgIcon']} alt={_textMap['imgIcon']} height="30rem" width="auto" />
                    <Typography variant='h7' fontWeight='500' marginLeft={'.5rem'} style={{ color: '#000' }}>{_textMap['primaryLabel']}</Typography>
                    <Typography variant='h7' fontWeight='500' marginLeft={'.5rem'} alignItems='center'> ({periodDisplayText}) </Typography>
                </Grid>
                <Grid item xs={12} sm={12} >
                    <Typography variant="h5" fontWeight="bold" style={{ color: '#000' }}>
                        <Grid item display={'inline'}>
                            <NumericValueDisplay isTopLine={true} value={primaryValue} postfix={postFix} prefix={preFix} isThousandsOnly={_textMap['isThousandsOnly']} />
                        </Grid>
                    </Typography>
                </Grid>
            </Grid >
            {
                comparisonValues?.length > 0 && (
                    <>
                        <Grid item xs={1} />
                        <Grid item xs={10} display={'flex'}>
                            {comparisonValues?.map((comparisonValue, index) => {
                                const { label, value } = comparisonValue;
                                let renderedValue;
                                if (value === 'N/A') {
                                    renderedValue = 'N/A';
                                } else {
                                    renderedValue = Math?.round(primaryValue / value * 100);
                                }
                                return <Index key={index} isCustomerIndicator={true} labelValue={value} label={label} value={renderedValue} params={params} period={period} />;
                            })}
                        </Grid>
                        <Grid item xs={1} />
                    </>
                )
            }
        </Grid>
    }

    if (isStickyCustomerDataCardDisplay) {
        return <Grid container>
            <Grid item xs={12} sm={12} paddingY={.5} textAlign={'center'} >
                <Grid item sm={12} xs={12} alignItems={"center"} display={"flex"} justifyContent={'center'}>
                    <img src={_textMap['imgIcon']} alt={_textMap['imgIcon']} height="30rem" width="auto" />
                    <Typography variant='h7' fontWeight='500' marginLeft={'.5rem'} style={{ color: '#000' }}>{_textMap['primaryLabel']}</Typography>
                    <Typography variant='h7' fontWeight='500' marginLeft={'.5rem'} alignItems='center'> ({periodDisplayText}) </Typography>
                </Grid>
                <Grid item xs={12} sm={12} >
                    <Typography variant="h5" fontWeight="bold" style={{ color: '#000' }}>
                        <Grid item display={'inline'}>
                            <NumericValueDisplay isTopLine={true} value={primaryValue} postfix={postFix} prefix={preFix} />
                        </Grid>
                    </Typography>
                </Grid>
            </Grid >
        </Grid>
    }

    return (
        <Grid container spacing={.25} paddingX={5} paddingY={.5} mt={0}>
            <Grid item sm={12} xs={12} display={"flex"}>
                <Typography variant='h6' style={{ color: '#000' }}>{_textMap['primaryLabel']}</Typography>
                <Typography variant='h6' ml={2} alignItems='center'> ({periodDisplayText}) </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Typography variant='h5' fontWeight='500' style={{ color: '#000' }}>
                    <NumericValueDisplay value={primaryValue} prefix={'₱ '} />
                </Typography>
                <Typography>
                    {_textMap['targetLabel']}:
                    {" "}
                    <NumericValueDisplay value={targetValue} prefix={'₱ '} />
                </Typography>
            </Grid>
            {
                comparisonValues?.length > 0 && <Grid item sm={12} xs={12}>
                    {
                        comparisonValues?.map((comparisonValue, index) => {
                            const { label, value } = comparisonValue;
                            return <Index
                                labelValue={value} label={label} labelPosition="right"
                                value={Math?.round(primaryValue / value * 100)} />
                        })
                    }
                </Grid>
            }
        </Grid>
    );
}