import { useContext } from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import '../../../../index.css'
import { Grid, Typography, Box, Card } from '@mui/material';
import { useTopLineInventory } from '../hooks';
import InventoryNumericValueDisplay from '../inventoryNumericValDisplay';

export default function InventoryTopline() {
    const windowSize = useContext(WindowSizeContext)
    const { finalData } = useTopLineInventory({})

    return <>
        <Box flexGrow={1} xs mb={1} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem" }}>
            <Grid container alignItems="center" py={1} >
                <Grid item xs={windowSize?.isOnMobile ? 8 : 12} sm={8.5}>
                    <Typography variant='h6' ml={2}>
                        Inventory Topline
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        <Grid container spacing={1} >
            {
                finalData?.map(obj => {
                    return <Grid item sm xs={12} >
                        <Card variant="outlined" sx={{ padding: 1, borderRadius: '.5rem', height: '11rem' }}>
                            <Grid item sm={12} xs={12} display={'flex'} justifyContent={'start'} alignItems={'start'} marginLeft={2} marginY={1}> <Typography variant="h6" alignItems='center' display='flex' fontSize={24}> <img src={obj?.iconPath} alt={obj?.iconPath} height="45rem" width="auto" style={{ marginRight: '.8rem' }} /> {obj?.label} </Typography></Grid>
                            <Grid item sm={12} xs={12} textAlign='center'>
                                <Typography variant="h5" fontWeight='700' fontSize={52}>
                                    <InventoryNumericValueDisplay colorFunctionFor='inventory' value={obj?.value} prefix={obj?.prefix} postfix={obj?.postfix} isAbbreviated={true} />
                                </Typography>
                            </Grid>
                        </Card>
                    </Grid>
                })
            }
        </Grid>
    </>
}
