import {useMemo} from "react";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import {Box} from "@mui/material";
import {RECENCY_BY_BRANCH, RECENCY_BY_BU, useLatestLoadDateMoment} from "../../hooks";
import NoAvailableDataDisplay from "../../NoAvailableDataDisplay";
import {abbreviateNumber} from "../../../../../common/utils";
import moment from "moment/moment";
import { CellIndicatorVsLastYear } from "../StoreCustomerActivityByBU/CellIndicatorVsLastYear";
import CellWithSubRow from "../../../../../common/components/DataTable/CellWithSubRow";
import { useSelectorValue } from "../../../common/providers/selectorProvider";


const thickBorder = '2px solid #C3C3C3!important'

export default function StoreRecencyTable({ data, isLoading, recencyBy }) {

    const {data: asOfMoment} = useLatestLoadDateMoment(); // use as of date, instead of last available date
    const organization = useSelectorValue('organization') 
    const isRecencyByBranch = recencyBy === RECENCY_BY_BRANCH
    const isRecencyByBUWithOrgSSM = recencyBy === RECENCY_BY_BU && organization === 'SM Store'


    function getPastMonthsLabel(monthsAgo) {
        if (!asOfMoment) return '...'
        const previousQuarter = moment(asOfMoment).subtract(monthsAgo, 'months');
        return previousQuarter.format("[Q]Q YYYY");
    }
  

    function settotalStyle(dimension) {
        if (dimension === 'total' || dimension === 'Total') {
            return {
                fontWeight: 'bold',
            }
        }
        return {}
    }
    
    function setBoxStyles(value, dimension) {
        const baseBoxStyles = {
            marginX: 'auto',
            width: "5em",
            textWrap: "nowrap",
            fontWeight: settotalStyle(dimension),
        };
    
        return !value
            ? { ...baseBoxStyles, textAlign: 'center' }
            : baseBoxStyles;
    }



    const storeRecencyColumns = useMemo(() => {

        const recencyHeader = (() => {
            if (isRecencyByBranch) {
                return 'Branch';
            }
            
            if (organization === 'SM Markets') {
                return 'Business Unit';
            } 
            
            if (organization === 'SM Store') {
                return <Box>Business Unit Group /<br />Business Unit</Box>;
            }
        })();

        return [
            {
                header: recencyHeader,
                accessorKey: 'pk',
                customStyle: {
                    width: "15rem",
                    lineHeight: "2.5em", 
                    borderRight: thickBorder
                },
                headerStyle: { lineHeight: "1.5em" },
                cell: ({ row, getValue }) => {
                    const value = getValue();
                    return (
                        <CellWithSubRow row={row} marginLeftMinimum={1} boxStyle={isRecencyByBUWithOrgSSM ? {} : { fontWeight: settotalStyle(row?.original?.dimension_value) }}>
                            {value}
                        </CellWithSubRow>
                    )
                }
            },
            {
                header: <Box> Past 3 Months (P3M)%<br />({getPastMonthsLabel(3)})</Box>,
                accessorKey: 'p3m_perc',
                cell: ({ row, getValue }) => {
                    const absoluteValue = row?.original?.p3m_members;
                    const dimension = row?.original?.dimension_value;
                    const value = getValue();
                    return (
                        <Box sx={setBoxStyles(value, dimension)}>
                            { !absoluteValue ? "-" : <>{abbreviateNumber(absoluteValue)} (<NumericValueDisplay value={value} isAbbreviated postfix="%" />)</> }
                        </Box>
                    );
                },
            },
            {
                id: "p3m_perc_ly",
                header: "vs Last Year",
                accessorKey: "p3m_members_ly",
                cell: (row) => {
                    const lastYear = row?.getValue();
                    const thisYear = row?.row?.original?.p3m_members;
                    return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                },
                customStyle: {
                    borderRight: thickBorder
                },
            },
            {
                header: <Box> Past 6 Months (P6M)%<br />({getPastMonthsLabel(6)})</Box>,
                accessorKey: 'p6m_perc',
                cell: ({ row, getValue }) => {
                    const absoluteValue = row?.original?.p6m_members;
                    const dimension = row?.original?.dimension_value;
                    const value = getValue();
                    return (
                        <Box sx={setBoxStyles(value, dimension)}>
                            { !absoluteValue ? "-" : <>{abbreviateNumber(absoluteValue)} (<NumericValueDisplay value={value} isAbbreviated postfix="%" />)</> }
                        </Box>
                    );
                },
                customStyle: { borderLeft: thickBorder },
            },
            {
                id: "p6m_perc_ly",
                header: "vs Last Year",
                accessorKey: "p6m_members_ly",
                cell: (row) => {
                    const lastYear = row?.getValue();
                    const thisYear = row?.row?.original?.p6m_members;
                    return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                },
            },
            {
                header: <Box> Past 9 Months (P9M)%<br />({getPastMonthsLabel(9)})</Box>,
                accessorKey: 'p9m_perc',
                cell: ({ row, getValue }) => {
                    const absoluteValue = row?.original?.p9m_members;
                    const dimension = row?.original?.dimension_value;
                    const value = getValue();
                    return (
                        <Box sx={setBoxStyles(value, dimension)}>
                            { !absoluteValue ? "-" : <>{abbreviateNumber(absoluteValue)} (<NumericValueDisplay value={value} isAbbreviated postfix="%" />)</> }
                        </Box>
                    );
                },
                customStyle: { borderLeft: thickBorder },
            },
            {
                id: "p9m_perc_ly",
                header: "vs Last Year",
                accessorKey: "p9m_members_ly",
                cell: (row) => {
                    const lastYear = row?.getValue();
                    const thisYear = row?.row?.original?.p9m_members;
                    return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                },
            },
            {
                header: <Box> Past 12 Months (P12M)%<br />({getPastMonthsLabel(12)})</Box>,
                accessorKey: 'p12m_perc',
                cell: ({ row, getValue }) => {
                    const absoluteValue = row?.original?.p12m_members;
                    const dimension = row?.original?.dimension_value;
                    const value = getValue();
                    return (
                        <Box sx={setBoxStyles(value, dimension)}>
                            { !absoluteValue ? "-" : <>{abbreviateNumber(absoluteValue)} (<NumericValueDisplay value={value} isAbbreviated postfix="%" />)</> }
                        </Box>
                    );
                },
                customStyle: { borderLeft: thickBorder },
            },
            {
                id: "p12m_perc_ly",
                header: "vs Last Year",
                accessorKey: "p12m_members_ly",
                cell: (row) => {
                    const lastYear = row?.getValue();
                    const thisYear = row?.row?.original?.p12m_members;
                    return <CellIndicatorVsLastYear thisYear={thisYear} lastYear={lastYear}/>
                },
            },
        ]
    }, [recencyBy, isRecencyByBranch, isRecencyByBUWithOrgSSM, setBoxStyles, organization]);

    const dataWithTotalFooter = useMemo(() => {
        if (!data) return [];

        return data.map(row => {
            if (row.pk === "Total") {
                return {
                    ...row,
                    _isFooter: true
                }
            }
            return row;
        });
    }, [data]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    if (!data || data?.length === 0) {
        return <NoAvailableDataDisplay />;
    }

    return (
        <TableComponentV2
            initialSort={{}}
            columns={storeRecencyColumns}
            data={dataWithTotalFooter}
            tableId={'storeRecencyTable'}
            isCompact={true}
            customTableHeaderStyle={{
                backgroundColor: '#caddeb',
                textAlign:'center'
            }}
            hasPagination={isRecencyByBranch}
            propPageSize={10}
        />
    );
}
