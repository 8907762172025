import React from 'react';
import SortField from './SortField';
import SortOrder from './SortOrder';
import { Grid, Stack } from '@mui/material';

const Sort = ({ sortField, setSortField, sortOrder, setSortOrder }) => {
    return (
        <>
            <Stack sx={{ width: '100%'}} direction={'row'} spacing={1}>
                <SortField sortField={sortField} setSortField={setSortField} />
                <SortOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
            </Stack>
        </>
    );
}

export default Sort;
