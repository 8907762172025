import React, { useState } from 'react';
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import { useQueryClient } from 'react-query';
import ApiClient from '../../../common/API';

import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh';

import Box from '@mui/material/Box'

export default function RefreshSchema({ parentNode }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshHovered, setIsRefreshHovered] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const queryClient = useQueryClient();

    const openModal = () => {
        setShowModal(true);
    };

    const handleConfirmAction = () => {
        setIsLoading(true)
        ApiClient().post(`/data/schemas/${parentNode.id}/refresh_schema/`)
            .then(response => {
                queryClient.invalidateQueries(['table', 'list']);
                // queryClient.invalidateQueries(['data', 'table', parentNode.id, 'detail']); NOTE: Comment for now, double check if this causes not to load the table details properly
            }).then(() => {
                // alert("Schema refreshed successfully")
                setIsLoading(false)
                setShowModal(false);
            })
            .catch(error => {
                console.error(error);
            })
    };

    return (
        <>
            <Box display={'flex'} justifyContent={'flex-end'}>
                <IconButton 
                    onMouseEnter={()=> setIsRefreshHovered(true)}
                    onMouseLeave={()=> setIsRefreshHovered(false)}
                    onClick={openModal} 
                    disableRipple
                    sx={{color: isRefreshHovered ? "blue" : "grey",'&:hover': { backgroundColor: 'transparent'}}}>
                    
                    <RefreshIcon sx={{fontSize:20}}/>
                </IconButton>
            </Box>
            
            {showModal && (
                <ConfirmationModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={handleConfirmAction}
                    icon={<RefreshIcon sx={{fontSize:'100px', color:'#0f2feb'}}/>}
                    title="Refresh Schema"
                    message={`You are about to refresh ${parentNode.label}. This will update the tables under this schema. Do you really want to refresh this schema?`}
                    isLoading={isLoading}
                    loadingMessage={`Refreshing ${parentNode.label}`}
                />
            )}
        </>
    );
}