import { ToggleButtonGroup, ToggleButton, Box } from '@mui/material';
import React from 'react';
import { styled } from "@mui/material/styles";
import ToggleSelector from '../../common/presentation/selectors/toggleSelector';


export default function ToggleSelectorWithAdditional({ value, onChange, options, additionalStyles, lastIndexOfMain }) {
  // lastIndexOfMain is the basis of which options will be split, 
  // wherein the first part will be the main options that will simply be passed to the original ToggleSelector
  // and the second part will be the additional options which will look partitioned from the main options
  if (!lastIndexOfMain) {
    lastIndexOfMain = options.length;
  }

  // Split options based on lastIndexOfMain
  const mainOptions = options.slice(0, lastIndexOfMain);
  const additionalOptions = options.slice(lastIndexOfMain);

  const AdditionalToggleButton = styled(ToggleButton)({
    backgroundColor: '#efefef',
    color: '#0A4FB4',
    borderRadius: '1.5rem',
    border: 'none',
    height: '2rem',
    marginTop: '.23rem',
    '&.Mui-selected, &.Mui-selected:hover': {
        border: '1px solid #0A4FB4',
        backgroundColor: '#0A4FB4',
        color: '#fff'
    },
    '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
        marginRight: '.25rem'
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderLeft: 'none'
    },
    '&.MuiToggleButtonGroup-grouped:not(:last-of-type),&.MuiToggleButtonGroup-grouped:not(:first-of-type)':{
        borderRadius: '1.5rem',
    },
    '&.Mui-disabled, &.Mui-disabled:hover': {
        color: '#00000042',
        backgroundColor: '#efefef',
        border: 'none',
        pointerEvents: 'none',
        cursor: 'not-allowed'
    }
  });

  return (
    <Box sx={{
      display: 'flex',
      backgroundColor: '#efefef',
      borderRadius: '1.5rem',
      paddingRight: '.15rem',
      border: '1px solid #d8d8d8',
      marginRight: '.25rem'
    }}>
      <ToggleSelector value={value} onChange={onChange} options={mainOptions} additionalStyles={additionalStyles} />
      <ToggleButtonGroup
        size="small"
        value={value}
        exclusive
        onChange={onChange}
        aria-label="toggle-selector"
      >
      {
        additionalOptions?.map(option => {
          return <AdditionalToggleButton 
              value={option?.value} 
              onChange={onChange} 
              disabled={option.disabled || false} // If disabled is not provided, default to false
              sx={{ ...additionalStyles, px: 2 }}
            > 
            {option?.label} 
          </AdditionalToggleButton>
        })
      }
      </ToggleButtonGroup>

    </Box>
  );
}