import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import ComponentWithIndicator from './componentWithIndicator'
import NumericValueDisplay from '../../../common/presentation/numericValueDisplay'
import { forceParseFloat } from '../../../common/util'
import { useTopLineMetricsData } from '../../hooks';
import LoadingComponent from '../../../../../common/components/LoadingComponent'

export default function ToplineMetrics({params, period,  iconUrl, metricLabel}) {

    const isMTD = period === 'mtd'

    const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] }

    const {finalData, isLoading } = useTopLineMetricsData({params: {...paramsBasedOnPeriod }})

    if(isLoading) {
        return <Grid container sx={{justifyContent:'center'}}>
                <LoadingComponent />
            </Grid>
    }

    return <>
        <Grid container spacing={1} >
            {
                finalData?.map((obj, index)=> {
                    return <Grid key={index} item sm xs={12} >
                        <Card variant="outlined" sx={{ padding:1, borderRadius:'.5rem'}}>
                            <Grid item sm={12} xs={12}> <Typography variant="h6" alignItems='center' display='flex'> <img src={ obj?.iconPath } alt={obj?.iconPath} height="25rem" width="auto" style={{ marginRight: '.5rem'}}/> { obj?.label } </Typography></Grid>
                            <Grid item sm={12} xs={12} textAlign='center'> <Typography variant="h5" fontWeight='500'> <NumericValueDisplay value={obj?.value} prefix={obj?.prefix} postfix={obj?.postfix} isAbbreviated={true}/></Typography></Grid>
                            <Grid item sm={12} xs={12} textAlign='center' backgroundColor='#f6f6f6' sx={{ borderRadius: '.5rem' }}> 
                                <ComponentWithIndicator
                                    variant='comparisonIndicator'
                                    component={<Typography style={{marginBottom: 0}}> { obj?.comparisonLabel } </Typography>}
                                    comparisonLabelStyles={{ textAlign:'left', paddingLeft:'.5rem'}}
                                    indicatorStyles={{}}
                                />
                            </Grid>
                        </Card>
                    </Grid>
                })
            }
        </Grid>
    </>
}