import React from "react";
import ReactEcharts from "echarts-for-react";
import Donut from "../../../../../common/components/graphs/donut";

import { Grid, Paper } from "@mui/material";


export default function GenderPieChart({ genderData }) {

    const countByGenderMap = genderData?.reduce((acc, row) => {
        if (!acc.hasOwnProperty(row.gender)) {
            acc[row.gender] = 0
        }
        acc[row.gender] = row.population
        return acc
    }, {});


    return (
        <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
            <Grid container>
                <Grid item xs={12}>
                    <Donut seriesName="Population" data={countByGenderMap} colors={['#203864', '#2e75b6']} />
                </Grid>
            </Grid>
        </Paper>
    )
}