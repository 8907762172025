import React, {useContext} from 'react';
import {Card, Grid, Typography} from '@mui/material';
import {WindowSizeContext} from '../../../../../../common/context/WindowSizeContext';
import StackedTrendBar from "./StackedTrendBar";


const cleanDataKeys = (data) => {
    if (!data) {
        return [];
    }

    // get all keys from all rows
    const keys = data.reduce((acc, row) => {
        Object.keys(row).forEach(key => {
            if (!acc.includes(key)) {
                acc.push(key);
            }
        });
        return acc;
    }, []);

    // filter out keys with null values for all rows
    const keysToRemove = keys.filter(key => {
        return data.every(row => row[key] === null || row[key] === undefined);
    });


    return data.map(row => {
        const tmpRow = {...row};
        keysToRemove.forEach(key => {
            delete tmpRow[key];
        });
        return tmpRow;
    })
}

export default function PerformanceTrends({params, data}) {

    const windowSize = useContext(WindowSizeContext);
    const isOnMobile = windowSize?.isOnMobile;
    const currentYear = params?.year;


    // Sum up the values for all years and keys
    const dataVsLY = cleanDataKeys(
        data?.filter((data) => (data.year === `${currentYear}`) || (data.year === `${currentYear - 1}`))
    )
    const dataVs2019 = cleanDataKeys(
        data?.filter((data) => (data.year === `${currentYear}`) || (data.year === `2019`))
    )


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} >
            <Card sx={{minHeight: '25rem'}}>
                <Grid container xs={12} sm={12} sx={{backgroundColor: '#CADDEB', padding: 1.5}} display="flex"
                      alignItems="center">
                    <Grid item sm={12} xs={12}>
                        <Typography variant="h6">vs. Last Year</Typography>
                    </Grid>
                </Grid>
                <Grid item sm={12} xs={12} alignItems="center"
                      sx={{
                          flex: 1,
                          height: '95%',
                      }}
                >
                    {dataVsLY?.length === 0 ?
                        <Grid sx={{display: 'flex', justifyContent: 'center', mt: 18}}>
                            No Data Available
                        </Grid> : <StackedTrendBar
                            data={dataVsLY}
                            params={params}
                        />
                    }
                </Grid>
            </Card>
                </Grid>

            <Grid item xs={12} sm={12} md={6} >
            <Card sx={{minHeight: '25rem'}}>
                <Grid container xs={12} sm={12} sx={{backgroundColor: '#CADDEB', padding: 1.5}} display="flex"
                      alignItems="center">
                    <Grid item sm={12} xs={12}>
                        <Typography variant="h6">vs. 2019</Typography>
                    </Grid>
                </Grid>
                <Grid item sm={12} xs={12} alignItems="center"
                      sx={{
                          flex: 1,
                          height: '95%',
                      }}
                >
                    {
                        dataVs2019?.length === 0 ?
                            <Grid sx={{display: 'flex', justifyContent: 'center', mt: 18}}>
                                No Data Available
                            </Grid> : <StackedTrendBar
                                data={dataVs2019}
                                params={params}
                            />
                    }

                </Grid>
            </Card>

                </Grid>
        </Grid>
    );
}
