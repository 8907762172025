import React, {useContext} from 'react';
import ReactEcharts from "echarts-for-react";
import {abbreviateNumber, rollupDataBy} from '../../../../../../common/utils';
import {titleCaseString} from '../../../../common/util';
import {WindowSizeContext} from '../../../../../../common/context/WindowSizeContext';


const HEX_CODES = ['#CADDEB', '#7E95C7', '#A2B2D2', '#AFCDFC', '#84BFF7', '#4E88DD'];
const MULTIPLIER = 100


/** DATA PREPARATION / MANIPULATION HELPDERS */
// normalizes the data to percentage
const convertValuesToPercentage = (data, groupKeys, totalSum) => {
    return data?.map(obj => {
        const normalizedObj = {year: obj.year};

        groupKeys.forEach((key) => {
            if (obj[key] !== null && obj[key] !== undefined) {
                const percentage = ((parseFloat(obj[key]) / totalSum) * MULTIPLIER);
                normalizedObj[key] = percentage;
            } else {
                normalizedObj[key] = 0;
            }

        });

        return normalizedObj;
    })?.sort((a, b) => parseInt(a.year) - parseInt(b.year));
};


// sets the minimum height of each bar in the stacked bar graph
export const resizeDataForStackedBar = (data) => {
    const minValue = 2.5; // relevant to the lowest height of a bar on the stacked bar graph
    const minNonZeroValue = Math.min(...data.filter(value => value > 0)); // gets the minimum value excluding 0

    const maxValue = Math.max(...data.filter(value => value > 0));
    const diffValue = (maxValue - minNonZeroValue)
    const percentageDifference = (diffValue / minNonZeroValue) * 100;

    return data.map((value) => {
        if (value === 0) {
            return 2;
        } else if (value <= minValue) {
            // Linear scaling for values below 3
            // return minValue + (value - minNonZeroValue) * (minValue - 1) / (minNonZeroValue - 1);

            //ADDED: Temporary fix
            //TODO: Fix for bar sizes with big difference issue that causes smaller value not as visibile.
            if (percentageDifference > 10000) {
                return 8 + (value - minNonZeroValue);
            }

            return minValue + (value - minNonZeroValue);
        }
        return value
    });
}


const convertToSeriesData = (data, keys) => {
    return keys?.map(key => {
        const dataForKey = data.map(data => data[key]);
        return resizeDataForStackedBar(dataForKey)
    })
}

function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    if (font) {
        context.font = font;
    }
    const metrics = context.measureText(text);
    return metrics.width;
}

const generateColorMapping = (seriesLabels) => {
    const lastDeptIndex = seriesLabels.length - 1;
    const firstHexIndex = HEX_CODES.length - (lastDeptIndex % HEX_CODES.length) - 1;
    const rotatedHEX_CODES = [...HEX_CODES.slice(firstHexIndex), ...HEX_CODES.slice(0, firstHexIndex)];

    // Dynamic BG_COLOR_MAPPING that cycles through the only available colors in the mockup
    return seriesLabels.reduce((mapping, dept, index) => {
        mapping[dept] = rotatedHEX_CODES[index % rotatedHEX_CODES.length];
        return mapping;
    }, {});
}


const generateSeriesV2 = (data, color, seriesType, barWidth, dataLabels) => {
    const series = {
        type: seriesType,
        data: data,
        barWidth: barWidth,
        itemStyle: {
            color: color,
        },
        stack: 'bu_stacked_' + seriesType,
        label: {
            show: seriesType === 'bar', // label only shows if bar series
            position: 'inside',
            formatter: function (obj) {
                return dataLabels[obj.dataIndex];
            }
        },
    }

    if (seriesType === 'line') {
        series.symbol = 'none';
        series.lineStyle = {
            type: 'dashed',
            width: 1,
            color: color
        };
        series.silent = true; // disable mouse events
    }

    return series;
}


function generateGrowthRateSeries(data, seriesLabels, seriesDataArray, colorMap) {
    // custom render to show growth rate percentage as circular label
    const latestSeriesData = seriesDataArray.map((seriesData) => {
        return seriesData[1]
    })

    const growthRateData = seriesLabels?.map((key, idx) => {
        const newValue = data?.[1]?.[key] 
        const oldValue = data?.[0]?.[key] 

        // if oldValue is 0, then growth rate is null
        // if newValue is 0, then growth rate is -100%
        const growthRate = (!!oldValue) ? 
            ( isNaN(((newValue / oldValue) - 1) * 100) ? null : ((newValue / oldValue) - 1) * 100 ) :
            null;

        return {
            rate: growthRate,
            latestValue: latestSeriesData[idx],
            key: key
        }
    }).reduce((acc, val, idx) => {
        acc.push({
            ...val,
            stackedLatestValue: acc.reduce((a, v) => a + v.latestValue, 0) + val.latestValue  // sum of all previous values
        })
        return acc
    }, []);

    return {
        type: 'custom',
        data: growthRateData.map(row => row.latestValue),

        renderItem: function (params, api) {
            const index = params.dataIndex;
            const size = api.size([2, api.value(1)]);

            const stackedValue = growthRateData[index].stackedLatestValue;
            const start = api.coord([2, stackedValue]);

            const width = 50;
            const matchingBarHeight = size[1]
            const key = seriesLabels[index];

            const centerY = start[1] + (matchingBarHeight / 2)

            // limit the max height of the pill
            const height = matchingBarHeight > 30 ? 30 : matchingBarHeight;

            return {
                type: 'rect',
                shape: {
                    x: start[0] - (width / 2),
                    y: centerY - (height / 2),
                    // y: start[1], // - (matchingBarHeight / 2),
                    width: width,
                    height: height,
                    r: 100,
                },
                style: api.style({
                    fill: colorMap[key],
                }),
            };
        },

        label: {
            show: true,
            // fontSize: 12,
            color: 'black',
            // fontFamily: "Henry Sans",
            position: 'inside',
            formatter: function (params) {
                const index = params.dataIndex;
                const growthRate = growthRateData[index].rate;
                if (growthRate !== null) {
                    return `${growthRate.toFixed(0)}%`;
                }
                return 'N/A';
            }
        }
    }
}


const generateSeriesLabels = (data) => {
    // Get the object of the current year's data
    const maxYear = Math.max(...data.map(item => parseInt(item.year)));
    const allKeys = data.reduce((acc, obj) => {
        Object.keys(obj).forEach(key => {
            if (key !== "year" && key !== "store_id_count_distinct") {
                acc.add(key);
            }
        });
        return acc;
    }, new Set())
    const currentData = data?.find(item => item.year === `${maxYear}`);

    // Extract properties and values from the current data's object
    return [...allKeys]
        .filter((key) => key !== "year" && key !== "store_id_count_distinct")
        .sort((keyA, keyB) => {
            const valA = currentData[keyA] ? parseFloat(currentData[keyA]) : 0;
            const valB = currentData[keyB] ? parseFloat(currentData[keyB]) : 0;
            return valA - valB;
        })
}


export default function StackedTrendBar({params, data, showGrowthRate = true, showGrowthLine = false}) {
    const windowSize = useContext(WindowSizeContext);
    const isOnMobile = windowSize?.isOnMobile;

    /** RENDER OPTIONS */
    const maxGridLeft = isOnMobile ? 120 : 200
    const gridXPadding = isOnMobile ? 0 : 10;

    /** DATA BASED RENDER OPTIONS */
    const seriesLabels = (data && data.length > 0) ? generateSeriesLabels(data) : [];
    const xAxisLabels = data?.map(dataObj => dataObj['year']) //array of keys
    const colorMap = generateColorMapping(seriesLabels);

    const categories = xAxisLabels || []
    if (showGrowthRate) {
        categories.push('Growth Rate')
    }

    const maxLabelWidth = seriesLabels?.reduce((max, seriesName, currentIndex) => {
        const labelWidth = getTextWidth(seriesName, '14px "Henry Sans"');
        return Math.max(max, labelWidth);
    }, 0);
    const gridLeft = (maxLabelWidth + gridXPadding) > maxGridLeft ?
        maxGridLeft :
        (maxLabelWidth + gridXPadding);
    const barWidth = isOnMobile ? '50%' : 60;
    const labelWidth = gridLeft - gridXPadding


    /** DATA MANIPULATION */
    const seriesData = React.useMemo(() => {
        // compute all series options needed to show the chart
        if (!data || data.length === 0) {
            return [];
        }

        const _totals = rollupDataBy(data, [], seriesLabels)[0];
        const totalsByKey = seriesLabels.reduce((acc, key) => {
            acc[key] = _totals[key] || 0;
            return acc;
        }, {})

        // Sum up the values for all years and keys
        const totalSum = seriesLabels.reduce((sum, key) => sum + (totalsByKey[key] || 0), 0);

        // convert data to percentage then resize the data for the stacked bar
        const seriesDataArray = convertToSeriesData(
            convertValuesToPercentage(data, seriesLabels, totalSum),
            seriesLabels
        );


        const labelSeries = seriesDataArray.map(data => {
            return [data[0]]  // we only need the first bar
        }).map((data, idx) => {
            // labels are the series names
            const dataLabels = [titleCaseString(seriesLabels[idx])]
            return generateSeriesV2(data, colorMap[seriesLabels[idx]], 'bar', 1, dataLabels)
        })?.map((bar, idx) => {
            return {
                ...bar,
                silent: true,
                barGap: '-100%',
                stack: 'bu_stacked_bar_fake',
                label: {
                    ...bar.label,
                    show: true,
                    position: 'left',
                    distance: isOnMobile ? 20 : 40,
                    width: labelWidth + 5,
                    overflow: bar.data[0] < 3 ? 'truncate' : 'break'
                }
            }
        });

        const stackedBarSeries = seriesDataArray.map((seriesData, idx) => {
            // labels are the actual values
            const seriesLabel = seriesLabels[idx]
            const dataLabels = seriesData.map((value, valueIndex) => {
                const originalValue = data[valueIndex][seriesLabel]
                if (originalValue === null || originalValue === undefined || originalValue === 0) {
                    return 'N/A';
                }
                return '₱ ' + abbreviateNumber(originalValue);
            })
            return generateSeriesV2(seriesData, colorMap[seriesLabel], 'bar', barWidth, dataLabels)
        })


        const storeCountByYear = data?.reduce((acc, obj) => {
            const year = obj?.year;
            const store_id_count = obj?.store_id_count_distinct || 0;

            if (acc[year] === undefined) {
                acc[year] = 0;
            }
            acc[year] += store_id_count;
            return acc;
        }, {});


        const lineSeries = showGrowthLine ? seriesDataArray.map((data, idx) => {
            return generateSeriesV2(data, colorMap[seriesLabels[idx]], 'line', barWidth, totalSum)
        }) : []

        const growthRateSeries = showGrowthRate ?
            [generateGrowthRateSeries(data, seriesLabels, seriesDataArray, colorMap)] :
            [];

        return [
            ...labelSeries,
            ...stackedBarSeries,
            ...lineSeries,
            ...growthRateSeries,
        ]

    }, [data, seriesLabels, showGrowthRate, showGrowthLine, isOnMobile, labelWidth, barWidth, colorMap])

    return (
        <ReactEcharts
            notMerge={true}
            lazyUpdate={false} // resolves getRawIndex undefined error
            option={{
                animation: false,
                tooltip: {
                    show: false
                },
                grid: {
                    top: 50, // TODO: check react echarts docs for other params of grid (i.e. left, right, bottom)
                    left: gridLeft,
                    right: gridXPadding
                },
                xAxis: {
                    type: 'category',
                    data: [...categories],
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        interval: 0,
                    }
                },
                yAxis: {
                    show: false,
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    }
                },

                series: seriesData
            }}
            style={{
                height: isOnMobile ? '25rem' : '30rem',
                minWidth: isOnMobile ? '10vw' : 'none',
            }}
        />
    );
}
