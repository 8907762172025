import moment from "moment";
import { abbreviateNumber, convertNumberToLocaleString, convertToValidNumber, displayNumber } from "../../../common/utils";

export const monthYearToYearMonthDate = (months, year, dayOfMonthMaxDate) => {
    if (!months || months.length === 0 || !year) {
        return {}
    }
    // months is an array of numbers, 1=January, 2=February, etc.
    const minMonth = Math.min(...months)
    const maxMonth = Math.max(...months)

    const startDate = new Date(year, minMonth - 1, 1)  // since minMonth is 1-based, we need to subtract 1 from month to get the first day of the month
    const endDate = dayOfMonthMaxDate ?
        new Date(year, maxMonth - 1, dayOfMonthMaxDate) :  // since maxMonth is 1-based, we need to subtract 1 from month to get the last day of the month
        new Date(year, maxMonth, 0);

    const yearMonthFilters = {
        "year_month__gte": moment(startDate).format("YYYY-MM"),
        "year_month__lte": moment(endDate).format("YYYY-MM")
    };

    return yearMonthFilters;
}

export const monthYearToTransactionDate = (months, year, dayOfMonthMaxDate) => {
    if (!months || months.length === 0 || !year) {
        return {}
    }
    // months is an array of numbers, 1=January, 2=February, etc.
    const minMonth = Math.min(...months)
    const maxMonth = Math.max(...months)

    const minDate = moment(`${year}-${minMonth}-01`)
    const maxDate = moment(`${year}-${maxMonth}-01`)

    // create a list of months between minDate and maxDate
    const monthsBetween = []
    let currentDate = minDate
    while (currentDate <= maxDate) {
        monthsBetween.push(currentDate.format("MMM"))
        currentDate = currentDate.add(1, 'month')
    }

    const newParams = {
        'year__in': year
    }
    newParams['month__in'] = monthsBetween.map(month => month.toUpperCase()).join(',')

    return newParams

}

export const monthYearToMerchTransactionDate = (months, year, dayOfMonthMaxDate) => {
    if (!months || months.length === 0 || !year) {
        return {}
    }
    // months is an array of numbers, 1=January, 2=February, etc.
    const minMonth = Math.min(...months)
    const maxMonth = Math.max(...months)

    const minDate = moment(`${year}-${minMonth}-01`)
    const maxDate = moment(`${year}-${maxMonth}-01`)

    // create a list of months between minDate and maxDate
    const monthsBetween = []
    let currentDate = minDate
    while (currentDate <= maxDate) {
        monthsBetween.push(currentDate.format("MMM"))
        currentDate = currentDate.add(1, 'month')
    }

    const newParams = {
        'year__lte': year
    }
    newParams['month__in'] = monthsBetween.map(month => month.toUpperCase()).join(',')

    return newParams

}



export const convertParamsToYearMonthDate = (params, dayOfMonthMaxDate) => {
    // params can NOT be edited in-line of function call
    return {
        ...params,
        ...monthYearToYearMonthDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const convertStoreParamsToYearMonthDate = ({ params, dayOfMonthMaxDate }) => {
    // params CAN be edited in-line of function call
    // Store View params involve inserting updated params values, hence the need for {} in function parameters
    return {
        ...params,
        ...monthYearToYearMonthDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const convertParamsToTransactionDate = (params, dayOfMonthMaxDate) => {
    return {
        ...params,
        ...monthYearToTransactionDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const convertMerchParamsDate = (params, dayOfMonthMaxDate) => {
    return {
        ...params,
        ...monthYearToMerchTransactionDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const preProcessParams = (params) => {
    const newParams = {
        ...params,
    }

    // delete "month" and "year" parameters if they exist
    // because we don't want to send them to the backend,
    // we want to send "year_month__gte" and "year_month__lte" instead
    if (newParams.month) {
        delete newParams.month
    }
    if (newParams.year) {
        delete newParams.year
    }

    // remove empty arrays or empty strings or null values
    Object.keys(newParams).forEach(key => {
        if (Array.isArray(newParams[key]) && newParams[key].length === 0) {
            delete newParams[key]
        }
        if (newParams[key] === "") {
            delete newParams[key]
        }
        if (newParams[key] === null) {
            delete newParams[key]
        }
    });

    return newParams
}