import React from 'react';
import { useSMACMembersByCardType } from "../../hooks";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { orderArray, xAxisLabels, xAxisValues } from "../../constants";
import { getDefaultPercentagePointColor } from "../util";
import HorizontalBarGroupGraph from '../HorizontalBarGroupGraph';


const LEGEND_COLOR_MAP = {
    'Renewal': '#B0C5D4',
    'Existing': '#7E95C7',
    'New': '#84BFF7',
    'Inactive': '#AFAFAF',
    'Active': '#4E88DD',
    'Registered': '#4E88DD',
    'Unregistered': '#AFAFAF',
}

const MIN_BAR_HEIGHT = 25;


export default function SMACCardType() {
    const { asBarData, isLoading } = useSMACMembersByCardType({});
    const { asBarData: asBarDataLY, isLoading: isLoadingLY } = useSMACMembersByCardType({ lastYear: true });

    const chartData = React.useMemo(() => {
        if (!asBarData) {
            return {}
        }

        const legends = xAxisValues.reduce((acc, key) => {
            if (!asBarData[key]) {
                return acc
            }
            Object.keys(asBarData[key]).forEach((legend) => {
                if (!acc.includes(legend)) {
                    acc.push(legend)
                }
            })
            return acc
        }, [])
        const filteredLegend = orderArray.filter(legend => legends.includes(legend));
        const totalByAxis = xAxisValues.reduce((acc, key) => {
            if (!asBarData[key]) {
                return acc
            }
            Object.keys(asBarData[key]).forEach((legend) => {
                if (!acc[key]) {
                    acc[key] = 0
                }
                acc[key] += asBarData[key][legend]
            })
            return acc

        }, {})

        const totalByAxisLastYear = xAxisValues.reduce((acc, key) => {
            if (!asBarDataLY?.[key]) {
                return acc
            }
            Object.keys(asBarDataLY[key]).forEach((legend) => {
                if (!acc[key]) {
                    acc[key] = 0
                }
                acc[key] += asBarDataLY[key][legend]
            })
            return acc
        }, {});

        const maxAxis = Math.max(...Object.values(totalByAxis))

        const firstSeriesData = filteredLegend.map(legend => {
            const percentages = xAxisValues.map(key => {
                const total = totalByAxis[key]
                const val = asBarData[key][legend]
                return (total && val) ? (val / total * 100) : 0
            })
            const axisPercentages = xAxisValues.map(key => {
                const val = asBarData[key][legend]
                const height = (maxAxis && val) ? (val / maxAxis * 100) : 0
                return height < MIN_BAR_HEIGHT ? MIN_BAR_HEIGHT : height
            })
            return {
                name: legend,
                data: percentages,
                dataLabel: xAxisValues.map(key => asBarData[key]).map(data => data[legend] || 0),
                subdataLabel: percentages,
                color: LEGEND_COLOR_MAP[legend],
                textColor: '#000000'
            }
        })

        const secondSeriesData = firstSeriesData.map(series => {
            if (!asBarDataLY || Object.keys(asBarDataLY).length === 0) {
                return []
            }
            const currentPercentages = series.subdataLabel
            const previousPercentages = xAxisValues.map(key => {
                const total = totalByAxisLastYear[key]
                const val = asBarDataLY?.[key]?.[series.name]
                return (total && val) ? (val / total * 100) : 0
            })
            const pp = currentPercentages.map((val, index) => {
                return val - previousPercentages[index]
            })
            return {
                dataLabelPP: pp.map((val, index) => {
                    const result = val.toFixed(0);
                    if (result === '-0') {
                        return '0';
                    } else if (result >= 1) {
                        return '+' + result;
                    }
                    return result;
                }),
                textColor: pp.map(val => {
                    return getDefaultPercentagePointColor(val)
                }),
            }
        })

        const combinedSeriesData = firstSeriesData?.map((series, index) => ({
            ...series,
            ...secondSeriesData[index]
        }));


        return {
            seriesData: combinedSeriesData,
            legendData: filteredLegend
        }
    }, [asBarData, asBarDataLY]);

    if (isLoading || isLoadingLY || !asBarData) {
        return <LoadingComponent />
    }

    return <HorizontalBarGroupGraph
        title={'SMAC Card Types'}
        xAxisData={xAxisLabels}
        {...chartData}
    />
}