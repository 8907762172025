import {useQuery} from "react-query";
import {formatInventoryGroupOverviewData, formatInventoryStoreOverviewData} from "./utils";
import ApiClient from "../../../common/API";
import moment from "moment/moment";
import {useMemo} from "react";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../Constants/settings";
import {useFiltersContext} from "./provider/context";

import inventory_Days from "../../../img/inventory_Days.svg"
import inventory_Value from "../../../img/inventory_Value.svg"
import rtv_to_Sales from "../../../img/rtv_to_Sales.svg"
import ds_to_Sales from "../../../img/ds_to_Sales.svg"

import {STORE_DATA_COLUMNS, TABLE_NAME} from "./constants";
import {rollupDataBy} from "../../../common/utils";
import {useMktBranchLookup} from "../../../common/hooks/branchLookup";

const TOP_FOOD_GRP_MAP = {
    'top 4k': '4k',
    'top 888': '888',
}

const getEightWeekRollingTransactionDates=(endOfMonth, maxDate) => {
    const date = endOfMonth < maxDate ? endOfMonth : maxDate; 
    return {
        transaction_date__gte : moment(date).subtract(8, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
        transaction_date__lte : moment(date).subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
    }
}

const removeMonthYearFilters = (filters, isEightWeeksRolling, maxDate) => {

    const newFilters = {
        ...filters
    }
    if (newFilters.month && newFilters.month.length > 0 && newFilters.year) {
        const month = filters.month[0].toString().padStart(2, '0')
        const endOfMonth = moment(`${filters.year}-${month}-01`, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
        const latestTransactionDate = maxDate?.data?.transaction_date_max
        if(isEightWeeksRolling) {
            // get the past 8 weeks from the given week, used isoWeek to set Monday as the first day of the week always
            newFilters.transaction_date__gte= getEightWeekRollingTransactionDates(endOfMonth, latestTransactionDate)?.transaction_date__gte
            newFilters.transaction_date__lte= getEightWeekRollingTransactionDates(endOfMonth, latestTransactionDate)?.transaction_date__lte
        } else {
            newFilters.transaction_date__gte = moment(`${filters.year}-${month}-01`, 'YYYY-MM-DD').format('YYYY-MM-DD')
            newFilters.transaction_date__lte = endOfMonth
        }

    }

    delete newFilters.month
    delete newFilters.year

    return newFilters
}

const applyWhitelistBlacklistToFilters = (filters, whitelist, blacklist) => {
    return Object.keys(filters).filter(filterKey => {
        if (whitelist === null) return true
        return whitelist.includes(filterKey)
    }).filter(filterKey => {
        if (blacklist === null) return true
        return !blacklist.includes(filterKey)
    }).reduce((acc, filterKey) => {
        acc[filterKey] = filters[filterKey]
        return acc
    }, {})
}


export function useInventoryData({ params, allowedFilters=null, blockedFilters=null, queryOptions={} }) {
    const { filters: originalFilters } = useFiltersContext();
    const filters = applyWhitelistBlacklistToFilters(originalFilters, allowedFilters, blockedFilters)
    const aggregates = ['sales_transaction_date_count_distinct', 'actual_sales_sum'];
    const columnNames = (params?.columns || '').split(',')
        .filter(columnName => !!columnName) 
    const maxDate = useAvailableDateRange();
    
    const queryKey = ['markets', "inventory", "overall", params, filters, 'inventoryData']
    const TOP_FOOD_GRP_KEY = 'top_food_group'
    const primaryQuery = useQuery(
        queryKey,
        () => {

            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    aggregates: aggregates.join(','),
                    ...removeMonthYearFilters(filters, false, maxDate),
                    ...params, // Merge params and filters into the params object
                    actual_sales__gt: 0,  // Only include rows with actual sales; rtv, damaged and inventory value will be fetched separately
                    limit: 10000
                }
            }).then(res => {
                return res.data.data;
            }).then(data => {
                const newData = data?.map(obj =>{
                    if(obj.hasOwnProperty(TOP_FOOD_GRP_KEY)) {
                        const originalValue = obj[TOP_FOOD_GRP_KEY]
                        const newValue = TOP_FOOD_GRP_MAP[originalValue.toLowerCase()] || originalValue;
	                        return {
	                            ...obj,
                            original_top_food_group : originalValue,
                            [TOP_FOOD_GRP_KEY] : newValue
                        }
                    }
                    return obj
                })
                return newData
            });
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: true,
            ...queryOptions
        }
    );
    const inventoryValueQuery = useQuery(
        [...queryKey, "inventoryValue"],
        () => {
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    aggregates: "inventory_value_sum",
                    ...removeMonthYearFilters(filters, false, maxDate),
                    ...params, // Merge params and filters into the params object
                    view: 'inventory_value',
                    limit: 10000
                }
            }).then(res => {
                return res.data.data;
            });
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: true
        }
    );

    const allowed_columns = [
        'company_id',
        'branch_id',
        'branch_name',
        'transaction_date'
    ]

    const hasInvalidRtvDamagedColumns = columnNames.some(columnName => !allowed_columns.includes(columnName))
    const hasInvalidRtvDamagedFilters = Object.keys(filters).some(
        filterKey => !allowed_columns.includes(filterKey) && filters[filterKey]?.length > 0 && filterKey !== 'month' && filterKey !== 'year'
    )
    const rtvAndDamangedQuery = useQuery(
        [...queryKey, "rtvAndDamaged"],
        () => {

            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    aggregates: "rtv_value_sum,damaged_value_sum",
                    ...removeMonthYearFilters(filters, false, maxDate),
                    ...params, // Merge params and filters into the params object
                    view: 'rtv_damaged',
                    limit: 10000
                }
            }).then(res => {
                return res.data.data;
            });
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: !hasInvalidRtvDamagedColumns && !hasInvalidRtvDamagedFilters
        }
    );

    const preFillRtvAndDamaged = hasInvalidRtvDamagedFilters || hasInvalidRtvDamagedColumns
    
    const eightWeeksRollingSalesAtCostQuery =  useQuery(
        [...queryKey, "salesAtCost"],
        ()=>  ApiClient().get(`data/table/${TABLE_NAME}/query`, {
            params: {
                aggregates: "sales_transaction_date_count_distinct,sales_at_cost_sum",
                ...params,
                ...removeMonthYearFilters(filters,true, maxDate),
                limit: 10000
            }
        }).then(res => {
            return res.data.data
        }).then(data => {
            const EIGHT_WEEKS_ROLLING_TRANSACTION_DATE = 'sales_transaction_date_count_distinct'
            const newData = data?.map(obj =>{
                if(obj.hasOwnProperty(EIGHT_WEEKS_ROLLING_TRANSACTION_DATE)) {
                        return {
                            ...obj,
                            eight_weeks_transaction_date_count_distinct : obj[EIGHT_WEEKS_ROLLING_TRANSACTION_DATE]
                    }
                }
                return obj
            })

            return newData
        }), {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: true
        }
    )
    const data = useMemo(() => {
        if (primaryQuery.isLoading || inventoryValueQuery.isLoading || rtvAndDamangedQuery.isLoading || eightWeeksRollingSalesAtCostQuery.isLoading) {
            return [];
        }
        
        const allQueriesData = [
            ...(primaryQuery.data || []),
            ...(inventoryValueQuery.data || []),
            ...(rtvAndDamangedQuery.data || []),
            ...(eightWeeksRollingSalesAtCostQuery.data || [])
        ]

        return rollupDataBy(
            allQueriesData,
            columnNames,
            [
                ...aggregates,
                'inventory_value_sum',
                'rtv_value_sum',
                'damaged_value_sum',
                'sales_at_cost_sum'
            ]
        ).map(row => {
            row.ads = row.actual_sales_sum / row.sales_transaction_date_count_distinct 
            row.inventory_days = row.inventory_value_sum / (row.sales_at_cost_sum  / row.eight_weeks_transaction_date_count_distinct)
            if (preFillRtvAndDamaged) {
                row.rtv_value_sum = 'N/A'
                row.damaged_value_sum = 'N/A'
            }

            return row
        })
    }, [primaryQuery, inventoryValueQuery, rtvAndDamangedQuery, preFillRtvAndDamaged, eightWeeksRollingSalesAtCostQuery]);
    return {
        data,
        isLoading: primaryQuery.isLoading || inventoryValueQuery.isLoading || rtvAndDamangedQuery.isLoading || eightWeeksRollingSalesAtCostQuery.isLoading
    }

}

export function useAvailableDateRange() {
    return useQuery(
        ['markets', 'inventory', "overall"],
        () => ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
            params: {
                aggregates: "transaction_date_min,transaction_date_max",
                inventory_value__gt: 0 //get only those with inventory value
            }
        }).then(res => {
            return res.data.data[0]
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
}

// TODO: Compute data
export function useTopLineInventory({ params }) {
    const { data: inventoryData, isLoading } = useInventoryData({
        params,
        allowedFilters: ['year', 'month', 'company_id']
    });

    const finalData = useMemo(() => {
        if (isLoading) {
            return [];
        }

        const inventory_days = inventoryData[0]?.inventory_days || 0;
        const inventory_value = inventoryData[0]?.inventory_value_sum || 0;
        const inventory_rtv_to_sales = inventoryData[0]?.rtv_value_sum / inventoryData[0]?.actual_sales_sum * 100 || 0;
        const inventory_ds_to_sales = inventoryData[0]?.damaged_value_sum / inventoryData[0]?.actual_sales_sum * 100 || 0;

        return [
            {
                icon: '',
                label: 'Inventory Days',
                value: inventory_days,
                iconPath: inventory_Days
            },
            {
                icon: '',
                label: 'Inventory Value',
                value: inventory_value,
                iconPath: inventory_Value,
                prefix: '₱ '
            },
            {
                icon: '',
                label: 'RTV % to Sales',
                value: inventory_rtv_to_sales,
                iconPath: rtv_to_Sales,
                postfix: '%'
            },
            {
                icon: '',
                label: 'Damaged % to Sales',
                value: inventory_ds_to_sales,
                iconPath: ds_to_Sales,
                postfix: '%'
            },
        ];
    }, [inventoryData, isLoading]);

    return {
        finalData,
    };

}

// TODO: Compute data
export function useInventoryByGroup({ params, dataFormatterFn }) {
    const categories = params?.group_by.split(',');
    const category = categories[0];

    const useStoreData = STORE_DATA_COLUMNS.includes(category)

    const columnsAndGroupBy = useMemo(() => {
        if (useStoreData) {
            return [
                'branch_id',
                'branch_name',
                ...categories.slice(1)
            ]
        }
        return categories
    }, [useStoreData, categories])


    const { data: inventoryDataByGroup, isLoading } = useInventoryData({
        params: {
            ...params,
            group_by: columnsAndGroupBy.join(','),
            columns: columnsAndGroupBy.join(',')
        },
        blockedFilters: [category]
    });

    const {lookup: branchLookup, isLoading: isLoadingLookup} = useMktBranchLookup({
        queryOptions: {
            enabled: useStoreData
        }
    })

    const mergedData = useMemo(() => {
        if (!useStoreData) {
            return inventoryDataByGroup
        }

        if (isLoading || isLoadingLookup) {
            return []
        }
        if (!inventoryDataByGroup) {
            return []
        }
        if (!branchLookup) {
            return inventoryDataByGroup
        }

        return inventoryDataByGroup.map(row => {
            return {
                ...row,
                ...branchLookup[row.branch_id]
            }
        })

    }, [branchLookup, inventoryDataByGroup, useStoreData, isLoading, isLoadingLookup])


    const rolledUpData = useMemo(() => {
        if (!useStoreData) {
            return mergedData
        }
        return rollupDataBy(mergedData, categories, [
            'actual_sales_sum',
            'inventory_value_sum',
            'damaged_value_sum',
            'rtv_value_sum',
            'sales_at_cost_sum'
        ], {
            distinctValues: ['branch_id']
        }).map(row => {
            row.ads = row.actual_sales_sum / row.sales_transaction_date_count_distinct         
            row.inventory_days =  row.inventory_value_sum / (row.sales_at_cost_sum  / row.eight_weeks_transaction_date_count_distinct)
            return row
        })
    }, [mergedData, useStoreData, categories])

    if (dataFormatterFn) {
        return {
            inventoryDataByGroup: dataFormatterFn(rolledUpData, [`${category}`]),
            isLoading
        }
    }
    return {
        inventoryDataByGroup: formatInventoryGroupOverviewData(rolledUpData, category),
        isLoading
    }
}

export function useInventoryStoreView({ params, dataFormatterFn }) {
    const isProductToggled = params?.group_by.split(',').includes('food_group');

    const { data: inventoryDataStoreView, isLoading } = useInventoryData({
        params: {
            ...params,
            // top_food_group__not_in: 'Others',
            limit: 10000,
        },
        blockedFilters: STORE_DATA_COLUMNS
    });

    if (dataFormatterFn) {
        return {
            inventoryDataStoreView: dataFormatterFn(inventoryDataStoreView, ['branch_id', 'branch_name']),
            isLoading
        }
    } else if (dataFormatterFn === false) {
        return {
            inventoryDataStoreView: inventoryDataStoreView,
            // inventoryDataStoreViewProduct,
            isLoading
        }
    }

    return {
        inventoryDataStoreView: formatInventoryStoreOverviewData(inventoryDataStoreView),
        // inventoryDataStoreViewProduct, 
        isLoading
    }
}


