import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ApiClient from "../../../common/API";
import { useQueryClient } from "react-query";
import { useGroupList } from "../../../common/API/hooks";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { getSelectedOption } from '../../../common/utils';
import { Button, Grid, IconButton, Typography, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Modal from '@mui/material/Modal'
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import DropdownCheckboxV3 from "../../../common/components/DropdownCheckboxV3";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import handleError from "../ErrorHandling"

export default function EditUser({ user, isOpen, setIsOpen }) {
    const queryClient = useQueryClient();
    const { data: groupList, isLoading: groupLoading } = useGroupList();
    const [hasChanges, setHasChanges] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [editedUser, setEditedUser] = useState({
        adid: (user.row) ? user?.row?.adid : user.adid || '',
        first_name: (user.row) ? user?.row?.first_name : user.first_name || '',
        last_name: (user.row) ? user?.row?.last_name : user.last_name || '',
        email: (user.row) ? user?.row?.email : user.email || '',
        groups: (user.row) ? user?.row?.groups : user.groups || [],
    });

    const closeModal = () => {
        if (hasChanges) {
            setShowWarningModal(true)
        }
        else {
            setIsOpen(false);
        }
    };

    const handleWarningConfirmation = () => {
        setEditedUser({
            adid: (user.row) ? user?.row?.adid : user.adid || '',
            first_name: (user.row) ? user?.row?.first_name : user.first_name || '',
            last_name: (user.row) ? user?.row?.last_name : user.last_name || '',
            email: (user.row) ? user?.row?.email : user.email || '',
            groups: (user.row) ? user?.row?.groups : user.groups || [],
        })
        setShowWarningModal(false)
        setHasChanges(false)
        setIsOpen(false)
    }

    const handleChange = (e) => {
        setHasChanges(true);
        const { name, value } = e.target;
        setEditedUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    const handleGroupChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value);
        setEditedUser((prevUser) => ({ ...prevUser, groups: selectedValues }));
    };

    const handleSave = () => {
        ApiClient().patch(`user/${(user.row) ? user?.row?.id : user.id}/`, editedUser)
            .then(() => {
                queryClient.invalidateQueries(['user']);
                setIsOpen(false)
                toast.success('User updated!');
            })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "User", "edit");
                return toast.error(errorMessage);
            });
    };

    const groupOptions = React.useMemo(() => {
        if (groupLoading) {
            return []
        }
        if (!groupList) {
            return []
        }
        return groupList?.map((group) => {
            return {
                value: group.name,
                label: group.name,
                key: group.id,
            }
        })
    }, [groupList, groupLoading]);

    return (
        <>
            <Modal
                open={isOpen}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: 'auto',
                    justifyContent: 'center'
                }} >
                <Grid xs={10} md={4}>
                    <Card sx={{ minHeight: '45vh' }}>
                        <Grid container justifyContent="space-between" p={2} >
                            <Grid display="flex" columnGap={1}>
                                <EditNoteRoundedIcon fontSize="large" />
                                <Typography variant="h5" fontWeight="bold" >
                                    Edit User
                                </Typography>
                            </Grid>
                            <IconButton aria-label="close" size="small" color="error"
                                onClick={closeModal}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid sx={{ p: 2 }}>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "400px" }}
                                    size="small"
                                    aria-label="adid"
                                    id="adid"
                                    name="adid"
                                    label="ADID / Network Username"
                                    placeholder="ADID / Network Username"
                                    value={editedUser.adid}
                                    onChange={handleChange}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "400px" }}
                                    size="small"
                                    aria-label="first_name"
                                    id="first_name"
                                    name="first_name"
                                    label="First Name"
                                    placeholder="First Name"
                                    value={editedUser.first_name}
                                    onChange={handleChange}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "400px" }}
                                    size="small"
                                    aria-label="last_name"
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    value={editedUser.last_name}
                                    onChange={handleChange}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    type="email"
                                    // error={!isValidEmail}
                                    sx={{ width: "400px" }}
                                    size="small"
                                    aria-label="emailInput"
                                    id="emailInput"
                                    name="email"
                                    label="Email Address"
                                    placeholder="Email Address"
                                    value={editedUser.email}
                                    disabled={true}
                                // onChange={handleChange}
                                >
                                </TextField>
                            </Grid>
                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={groupOptions || []}
                                    aria-label="groups"
                                    id="groups"
                                    name="groups"
                                    label="Select Group(s)"
                                    placeholder="Select Group(s)"
                                    isLoading={groupLoading}
                                    isDisabled={groupLoading}
                                    limitTags={1}
                                    onChange={(event, newValue) => {
                                        handleGroupChange(getSelectedOption(newValue, groupOptions));
                                    }}
                                    value={getSelectedOption(editedUser.groups, groupOptions)} />
                            </Grid>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid container
                            p={1.5}
                            columnGap={{ sm: 'none', md: 4 }}
                            marginLeft={{ xs: 'none', md: -2.5 }}
                            justifyContent={{ xs: 'center', md: 'flex-end' }}>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ bgcolor: "#0F2FEB", width: "80px" }}
                                onClick={handleSave}
                            // disabled={isSubmitDisabled}
                            > Save
                            </Button>
                        </Grid>
                    </Card>
                </Grid>
            </Modal>
            {showWarningModal && (
                <ConfirmationModal
                    isOpen={showWarningModal}
                    onClose={() => setShowWarningModal(false)}
                    onConfirm={handleWarningConfirmation}
                    icon={<WarningAmberIcon sx={{ fontSize: '60px' }} color='warning' />}
                    title="Warning"
                    message="There are unsaved changes. Are you sure you want to close the modal?"
                />
            )}
        </>
    )
};