import React from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';


export default function HeaderWithTooltip({name, tooltip}) {
    return (
        <>
            <Typography sx={{fontWeight:'medium',fontSize:'1rem', p:{xs:2,md:0}}}>
                {name} {""}
            </Typography>

            <Tooltip title={tooltip} placement="right">
                <InfoOutlinedIcon
                    sx={{ mb:.5, mt:.6, ml:.5,fontSize:18, alignItems:'center'}}
                />
            </Tooltip>
        </>
    )
} 