import React, { useState, useContext } from 'react'
import { MenuItem, Box,ListItemIcon, IconButton, Grid, TextField, InputAdornment, Typography, Button} from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import PasswordStrengthBar from 'react-password-strength-bar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ApiClient from '../../API';
import { toast } from 'react-toastify';
import LoadingComponent from '../LoadingComponent';
import { LoginContext } from '../../context/LoginContext';
import { clearAuthTokens } from '../../utils';

function PasswordInputField({label, id, value, onChange, helperTextValue, error, autoFocus}) {
    const [showPassword, setShowPassword] = React.useState(false);
    
    return <TextField  autoFocus={autoFocus} onKeyDown={(e) => e.stopPropagation()} type={showPassword ? "text" : "password"} id={id} label={label} variant="outlined"size="small" onChange={onChange} value={value} fullWidth
        InputProps={{
            endAdornment:
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>setShowPassword(!showPassword)}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                    </IconButton>
                </InputAdornment>,
        }}
        error={error}
        helperText={helperTextValue}
    />
}

export default function ChangePassword({closeMenu}) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 5,
        borderRadius: 3,
        minHeight: '20rem',
        width: '25rem'
    };

    const [passwordDetails, setPasswordDetails] = useState({
        old_password: '',
        password: '',
        confirm_password: ''
    })

    const resetFields = () => {
        setPasswordDetails({
            old_password: '',
            password: '',
            confirm_password: ''
        })
    }

    const handleFieldChange=(name,value)=> {
        setPasswordDetails({
            ...passwordDetails,
            [name] : value
        })
    }

    const onSubmit = () => {
        setIsLoading(true)
        ApiClient().put('/auth/change-password/',passwordDetails).then(response => {
            handleClose()
            setIsLoading(false)
            toast.success('Password Successfully Changed, You will be forced logout',{
                autoClose: 3000,
                onClose: ()=>{clearAuthTokens(); window.location.reload(); }
            });
        }).catch(error => {
            setIsLoading(false)
            const errorMsg = error?.response?.data ? Object.values(error.response.data) : 'Error in changing password';

            toast.error(errorMsg?.toString());
        });
    }
    
    const isRetypeMatch =  passwordDetails?.confirm_password === passwordDetails?.password;
    const isSubmitDisabled = passwordDetails?.old_password === '' || passwordDetails?.password === '' || passwordDetails?.confirm_password === '';

    return (
        <>
            <MenuItem onClick={handleOpen}> 
                <ListItemIcon>
                    <ManageAccountsIcon/>
                </ListItemIcon>
                    Change Password 
            </MenuItem>
            <Modal 
                open={open}
                onClose={()=> { handleClose()}}
                aria-labelledby="change-password"
                aria-describedby="modal fields for changing passowrd"
                >
                <Box sx={style}>
                    <IconButton aria-label="close" onClick={()=>{handleClose(); resetFields()} } sx={{ position: 'fixed', top: 5, right: 5 }}>
                        < CloseIcon />
                    </IconButton>
                    <Grid container spacing={2} marginTop={1} paddingX={2} >
                        <Typography variant='h6'>Change Password:</Typography>
                        <Grid item xs={12} sm={12}>
                            <PasswordInputField autoFocus={true} id="old_password" label="Current Password" onChange={(e) => handleFieldChange('old_password', e.target.value) } value={passwordDetails?.old_password} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <PasswordInputField id="password" label="New Password" onChange={(e) => handleFieldChange('password', e.target.value) } value={passwordDetails?.password}/>
                        </Grid>
                            {
                                passwordDetails?.password !== '' && <Grid item xs={12} sm={12}>
                                    <PasswordStrengthBar password={passwordDetails?.password}/>
                                </Grid>
                            }
                        <Grid item xs={12} sm={12}>
                            <PasswordInputField id="confirm_password" label="Re-type New Password" onChange={(e) => handleFieldChange('confirm_password', e.target.value) } value={passwordDetails?.confirm_password}
                                 helperTextValue={ !isRetypeMatch && passwordDetails?.confirm_password !== '' ? 'Not the same with your new password' : ''} error={!isRetypeMatch && passwordDetails?.confirm_password !== ''} />
                        </Grid>
                        <Grid item xs={12} sm={12} marginTop={1} display="flex" justifyContent="end">
                            {
                                isLoading ? <LoadingComponent/> : <Button variant='contained' color='primary' onClick={onSubmit} disabled={ !isRetypeMatch || isSubmitDisabled  }>Submit</Button>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
    </>
    )
}