const generateErrorMessage = (error, moduleName, action) => {
    if (error.response) {
      const { status } = error.response;
        if (status === 400){ //Message returned when the user types an invalid character/input
            return `${moduleName} ${action} failed! HTTP ${status} Error. Invalid values in the input.`
        } else if (status === 422){ //Message returned when a duplicate is accidentally created
            return `${moduleName} ${action} failed! HTTP ${status} Error. Duplicate Entry on Create.`
        } else if (status === 403){ //Message returned when the user is trying to access Forbidden URLs
            return `${moduleName} ${action} failed! HTTP ${status} Error. No permission.`
        } else if (status === 404){ //Message returned when the user is trying to access a file that doesn't exist
            return `${moduleName} ${action} failed! HTTP ${status} Error. Entry not found.`
        } else { //Message returned when other HTTP status codes happen
            return `${moduleName} ${action} failed! HTTP ${status} Error.`
        }
    } else { //Message returned when an error doesn't have a status code
      return `An Unknown Error occurred.`;
    }
  };
  
export default generateErrorMessage
