import {titleCaseString} from "../../../../util";

export const FILTERS_DEFINITIONS = {
    department: {
        key: 'department',
        label: 'Department',
        column: 'pm_department_desc_standardized',
        crossFilterSources: [
        ],
        multiSelect: false
    },
    subdept: {
        key: 'subdept',
        label: 'Sub Department',
        column: 'pm_subdept_desc_standardized',
        crossFilterSources: [
            'department',
            'product_category',
        ],
        dependencyFilterSource: [
            'department'
        ],
        multiSelect: false
    },
    product_category: {
        key: 'product_category',
        label: 'Product Category',
        column: 'pm_product_category_desc',
        crossFilterSources: [],
        multiSelect: false,
        labelFormatter: (label) => {
            if (!label) return label;

            return titleCaseString(label)
        }
    }
}


export const metricDataSelector = {
    'actual_sales': row => row.pos_sku_gross_sales,
    'actual_units_sold_per_txn': row => row.pos_sku_tot_qty / row.header_tran_key,
    'actual_transactions': row => row.header_tran_key,
    'actual_basket_size': row => row.basket_size,
    'actual_frequency_of_visit': row => row.frequency_of_visit
}
export const customAgeBinOrder = [
    'Below 18',
    '18-19',
    '20-24',
    '25-29',
    '30-34',
    '35-39',
    '40-44',
    '45-49',
    '50-54',
    '55-59',
    '60-64',
    '65 and Above',
    '65-120',
    'No Data'
];