import React from "react";
import GraphFactory from "../../../../common/components/GraphFactory";
import {useQuery} from "react-query";
import ApiClient from "../../../../common/API";
import SuspenseFallback from "../../../../common/components/GraphFactory/SuspenseFallback";
import {titleCase} from "../../../../common/utils";
import {TABLE_MBA_SEGMENTS} from "../constants";


export default function SkuListPerSegment({title, filters, sortDirection, valueColumn, color, rowCount}) {
    // sortDirection should be asc or desc
    if (!sortDirection) {
        sortDirection = 'desc'
    }
    if (sortDirection !== 'asc' && sortDirection !== 'desc') {
        throw new Error("sortDirection value should either be 'asc' or 'desc'")
    }

    // get max value for x-axis
    const maxDataParams = {
        ...filters,
        aggregates: valueColumn.replace("_sum", "_max"),
    }

    const table_source = TABLE_MBA_SEGMENTS

    const {data: maxValue, isLoading} = useQuery([table_source, maxDataParams], () => {
        return ApiClient().get(`/data/table/${table_source}/query/`, {
            params: maxDataParams
        }).then((res) => res.data.data[0][valueColumn.replace("_sum", "_max")])
    }, {
        // keepPreviousData: true,
        cacheTime: 15 * 60 * 1000,
        staleTime: 15 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: !!table_source
    })

    if (isLoading) {
        return <SuspenseFallback/>
    }

    const chartHeightEm = rowCount >= 10 ? rowCount * 3 : 20

    const extraData = {
        "limit": 5,
        "filters": [],
        "order_by": `${sortDirection === "desc" ? "-" : ""}${valueColumn}`,
        "columns": [],
        "aggregates": [],
        "label": {"label": "merch_article_desc", "value": "merch_article_desc", "get_param": "columns"},
        "value": {
            "label": valueColumn,
            "value": valueColumn,
            "get_param": "aggregates"
        },
        chartOptions: {
            yAxis: {
                type: 'category',
                axisLabel: {
                    width: 100,
                    overflow: 'break'
                },
            },
            xAxis: {
                max: maxValue,
                type: "log",
            },
            series: [
                {name: titleCase(valueColumn.replace("_sum", ""))}
            ],

            grid: {
                left: '110px',
                top: '24px',
                right: '0%',
                bottom: '0%',
            },

        },
        chartProps: {
            style: {"height": `${chartHeightEm}em`},
        },
        "colorMap": [color]
    }

    return <GraphFactory
        filters={{
            ...filters,
            limit: rowCount
        }}
        chart_type={"bar"}
        table_source={TABLE_MBA_SEGMENTS}
        extra_data={extraData}
        title={title}
    />
}