import React, { useContext } from 'react';
import MonthToDateSalesOverallPerf from './MonthToDateSalesOverallPerf';
import YearToDateSalesOverallPerf from './YearToDateSalesOverallPerf';
import { Grid, Box, Typography } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import Item from '../../../../common/components/ItemCard';
import ToggleSelector from '../../common/presentation/selectors/toggleSelector';
import { STORE_TYPE_OPTIONS } from '../constants';

export default function SalesToplinePerformance({ params, onToggleChange, dataView }) {

    const windowSize = useContext(WindowSizeContext)
    return (
        <Grid container>
            <Box display='flex' flexGrow={1} xs={12} sx={{backgroundColor:"#f6f6f8", borderRadius:".5rem .5rem 0 0"}}>
                <Grid item xs sm paddingY={.75} >                            
                    <Typography variant='h6' ml={2} >Topline Performance</Typography>
                </Grid>
                <Grid item paddingY={.75}>
                    <ToggleSelector 
                        options={STORE_TYPE_OPTIONS}
                        value={dataView}
                        onChange={onToggleChange}/>
                </Grid>
            </Box>

            <Grid item  xs={12} sm={12}>
                <Grid container
                    spacing={0}>
                    <Grid item xs={12} sm >
                        <Item sx={{borderRadius:`${windowSize.isOnMobile ? '0 0 .5rem .5rem' : '0 0 0 .5rem'}`}}>
                            <MonthToDateSalesOverallPerf params={params} />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm my={windowSize.isOnMobile && 2}>
                        <Item sx={{borderRadius:`${windowSize.isOnMobile ? '.5rem' : '0 0 .5rem 0'}`}}>
                            <YearToDateSalesOverallPerf params={params} />
                        </Item>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

