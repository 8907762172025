import React, { useState } from 'react';
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { useTableRefresh } from "./utils";
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh';

export default function RefreshTable({ node }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshHovered, setIsRefreshHovered] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { refresh: tableRefresh } = useTableRefresh()

    const openModal = () => {
        setShowModal(true);
    };

    const handleConfirmAction = () => {
        setIsLoading(true);
        tableRefresh(node.id).then((data) => {
            // alert("Table refreshed successfully")
            setIsLoading(false)
            setShowModal(false);
        }).catch((error) => {
            console.error(error)
            setIsLoading(false)
        })
    };

    return (
        <>
            <IconButton 
                onMouseEnter={()=> setIsRefreshHovered(true)} 
                onMouseLeave={()=>setIsRefreshHovered(false)} 
                onClick={openModal}
                disableRipple
                sx={{color: isRefreshHovered ? "blue" : "grey", transtition:"none", '&:hover': { backgroundColor: 'transparent'},p:0}}>

                    <RefreshIcon sx={{fontSize:20}}/>

            </IconButton>

            {showModal && (
                <ConfirmationModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={handleConfirmAction}
                    icon={<RefreshIcon sx={{fontSize:'100px', color:'#0f2feb'}}/>}
                    title="Refresh Table"
                    message={`You are about to refresh ${node.label}. This will update the columns of the selected table. Do you really want to refresh this table?`}
                    isLoading={isLoading}
                    loadingMessage={`Refreshing ${node.label}`}
                />
            )}
        </>
    );
};