import { Grid } from "@mui/material"
import PerformancePerChannel from "../../branchView/Operations/PerformancePerChannel"
import FootTrafficConversion from "../../branchView/Operations/FootTrafficConversion"


export default function Operations({ params, store, zone, period, onTogglePeriodChange, onDropDownFilterChange, filters }) {

    return (
        <Grid container >

            <Grid item xs={12} py={.5}>
                <PerformancePerChannel params={params} period={period} zone={zone} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} onFilterChange={onDropDownFilterChange}>
                </PerformancePerChannel>
            </Grid>

            <Grid item xs={12} py={.5}>
                <FootTrafficConversion params={params} period={period} zone={zone} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} onFilterChange={onDropDownFilterChange}>
                </FootTrafficConversion>
            </Grid>
        </Grid>
    )
}