import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'


export default function CardContents({ name, image, path, access, icon }) {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        padding: theme.spacing(1),
        height: 'auto',

    }));

    return (
        <Grid item xs={4} display={!access ? 'none' : ''}>
            <CardActionArea LinkComponent={RouterLink} to={path}  >
                <Item>
                    <Stack direction={'column'} >
                        {image &&
                            <Box component="img" src={image} alt="SM Logo"
                                sx={{ width: '3em', height: 'auto' }} alignSelf={'center'}
                            />
                        }
                        {icon && 
                            <div align={'center'}>
                                {icon}
                            </div>
                        }
                        <Typography align={'center'} variant='subtitle2' sx={{ width: 'auto', height: '3em', marginTop: "5px" }} fontFamily={'Henry Sans'} color={'#1976d2'}  >
                            {name}
                        </Typography>
                    </Stack>
                </Item>
            </CardActionArea>
        </Grid>
    )
}