import React, {useContext} from 'react';
import {useSelectorContext, useSelectorValue} from "../../../providers/selectorProvider";
import ConditionalSelectComponent from "../../../../../../common/components/ConditionalSelectComponent";
import {WindowSizeContext} from "../../../../../../common/context/WindowSizeContext";

const CATCHMENT_5_MINUTES = 300;
const CATCHMENT_10_MINUTES = 600;
const CATCHMENT_OPTIONS = [
    {label: '5-Minute Catchment', value: CATCHMENT_5_MINUTES},
    {label: '10-Minute Catchment', value: CATCHMENT_10_MINUTES},
]


export default function CatchmentRateSelector() {
    const windowSize = useContext(WindowSizeContext)
    const catchmentRateFromContext = useSelectorValue('catchmentRate');
    const catchmentRate = catchmentRateFromContext || CATCHMENT_5_MINUTES;
    const {setValue: setSelectorValue} = useSelectorContext()
    const setCatchmentRate = (value) => setSelectorValue('catchmentRate', value)

    // defaults
    React.useEffect(() => {
        if (!catchmentRateFromContext) {
            setCatchmentRate(CATCHMENT_5_MINUTES)
        }
    }, [catchmentRateFromContext]);

    const selectedOption = CATCHMENT_OPTIONS.find(option => option.value === catchmentRate);

    return (
        <ConditionalSelectComponent
            id="catchment_selector"
            labelId="catchment_selector-label"
            options={CATCHMENT_OPTIONS}
            label="Catchment Rate"
            value={selectedOption}
            onSelectChange={(event, newValue) => {
                const value = windowSize.isOnMobile ? event?.target?.value : newValue?.value;
                setCatchmentRate(value);
            }}
            clearSelected={() => setCatchmentRate(CATCHMENT_5_MINUTES)}
            disableClearable={true}
        />
    )
}