import {CATEGORY} from "../../constants";
import React from "react";
import {useSelectorValue} from "../../../common/providers/selectorProvider";
import {Typography} from "@mui/material";

export default function FiltersTextDisplay({filterSelectedRows}) {
    const selectedViewBy = useSelectorValue('viewBy');
    const selectedCategory = CATEGORY[selectedViewBy];

    const filtersText = filterSelectedRows && filterSelectedRows.length > 0 ? (
        <span>- {filterSelectedRows.join(', ')}</span>
    ) : '';

    return (
        <Typography ml={2} sx={{borderRadius: '0', color: '#000'}}>
            Filters Selected | Grouping: {selectedCategory} {filtersText}
        </Typography>
    );
}