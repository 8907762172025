import React, { useMemo, useState } from "react";
import Modal from '@mui/material/Modal';
import { useQuery, useQueryClient } from "react-query";
import ApiClient from '../../../common/API';
import DataTable from 'react-data-table-component';
import { useTableDetail } from "../../../common/API/hooks";
import LoadingComponent from "../../../common/components/LoadingComponent";
import { getObjectDiff, isObjectEqual } from "../../../common/utils";

import  Typography from '@mui/material/Typography' 
import  IconButton  from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider'

export default function EditTable({ tableId, onToggle, isOpen: parentIsOpen }) {
    const queryClient = useQueryClient()
    const { data: tableDetails, isLoading: isTableDetailsLoading } = useTableDetail(tableId, {
        enabled: !!tableId,

        onSuccess: data => {
            setEditableData(data.columns)
            setTableName(data.name)
        }
    })

    const { data: schemasData, isLoading: schemasLoading } = useQuery('Schemas Data', () =>
        ApiClient().get('data/schemas').then(res => {
            return res.data
        }),
        {
            refetchOnWindowFocus: false,
            staleTime: 600000
        });

    const isLoading = isTableDetailsLoading || schemasLoading;
    const [isOpen, setIsOpen] = useState(true);
    const [editableData, setEditableData] = useState(tableDetails?.columns || []);
    const [tableName, setTableName] = useState(tableDetails?.name || '');
    const isModalOpen = isOpen || parentIsOpen;  // TODO: This is a hack to make the modal open when the parent is open

    const closeModal = () => {
        setIsOpen(false);
        onToggle(false)
    };

    const originalColumnsById = useMemo(() => {
        if (isTableDetailsLoading || !tableDetails?.columns) {
            return {};  // default if not yet loaded
        }
        // restructure the columns array into a dictionary with the ID as the key
        return tableDetails.columns.reduce((acc, val) => {
            acc[val.id] = val;
            return acc;
        }, {});
    }, [tableDetails?.columns, isTableDetailsLoading]);

    const changedData = editableData.filter((val, index) => {
        const original = originalColumnsById[val.id];
        return !isObjectEqual(original, val);
    });

    const columns = useMemo(() => {
        let baseColumns = [
          {
            name: 'Name',
            selector: (row) => row.name,
            sortable: false,
            editable: false,
          },
          {
            name: 'Type',
            selector: (row) => row.column_type,
            sortable: false,
            editable: true,
            cell: (row, rowIndex) => (
              <select
                value={row.column_type}
                onChange={(e) => handleCellChange(rowIndex, 'column_type', e.target.value)}
              >
                <option value="int">int</option>
                <option value="float">float</option>
                <option value="timestamp">timestamp</option>
                <option value="date">date</option>
                <option value="boolean">boolean</option>
                <option value="string">string</option>
              </select>
            ),
          },
          {
            name: 'Nullable',
            selector: (row) => row.nullable,
            sortable: false,
            editable: true,
            cell: (row, rowIndex) => (
              <select
                value={row.nullable}
                onChange={(e) => handleCellChange(rowIndex, 'nullable', e.target.value === 'true')}
              >
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            ),
          },
          {
            name: 'Filterable',
            selector: (row) => row.filterable,
            sortable: false,
            editable: true,
            cell: (row, rowIndex) => (
              <select
                value={row.filterable}
                onChange={(e) => handleCellChange(rowIndex, 'filterable', e.target.value === 'true')}
              >
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            ),
          },
        ];
      
        if (tableDetails?.host_type === 'BigQuery') {
            baseColumns = [
                {
                    name: 'Name',
                    selector: (row) => row.name,
                    sortable: false,
                    editable: false,
                },
                {
                    name: 'Type',
                    selector: (row) => row.column_type,
                    sortable: false,
                    editable: true,
                    cell: (row, rowIndex) => (
                        <select
                        value={row.column_type}
                        onChange={(e) => handleCellChange(rowIndex, 'column_type', e.target.value)}
                        >
                        <option value="INT64">INT64</option>
                        <option value="STRING">STRING</option>
                        <option value="FLOAT64">FLOAT64</option>
                        <option value="DATE">DATE</option>
                        <option value="BOOLEAN">BOOLEAN</option>
                        <option value="TIMESTAMP">TIMESTAMP</option>
                        </select>
                    ),
                },
                ...baseColumns.slice(2),
            ];
        }
      
        return baseColumns;
    }, [tableDetails]);  

    const handleCellChange = (rowIndex, columnName, newValue) => {
        setEditableData((prevData) => {
            const newData = [...prevData];
            newData[rowIndex] = { ...newData[rowIndex], [columnName]: newValue };
            return newData;
        });
    };

    const saveTable = () => {
        // wait for all change promises to resolve before invalidating the query and closing the modal
        return Promise.all(changedData.map((val) => {
            const changedValues = getObjectDiff(val, originalColumnsById[val.id]);
            return ApiClient().patch(`data/column/${val.id}/`, changedValues).then(res => {
                return res.data
            })
        })
        ).then(() => {
            queryClient.invalidateQueries(['data', 'table', tableId, 'detail'])
        }).then(() => {
            setIsOpen(false);
            onToggle(false)
        });
    };

    return (
        <>
            <Modal open={isModalOpen} onClose={closeModal}
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

                <Grid xs={10} md={7}>
                    <Card sx={{pb:1,}}>
                        <Grid container justifyContent={'space-between'}>
                                <Typography variant="h6" sx={{ml:3, mt:2, fontWeight:'bold',color:'#0f2feb' }}>
                                    Edit Table: &nbsp; {tableName}
                                </Typography>

                                <IconButton onClick={closeModal} sx={{ mr:1,mt:1,}} > 
                                    <CloseIcon sx={{fontSize:20}}/>
                                </IconButton>
                        </Grid>

                        <Divider sx={{my:1, borderBottom:1, }}/>

                        {
                            isLoading ? 
                            <Grid direction={'row'}>
                                <LoadingComponent />
                            </Grid> : 
                        <>
                            <Grid md={12} position={'relative'} zIndex={1} sx={{px:3,maxHeight:'70vh',  overflowY:'auto', overflowX:'hidden'}} >
                                <DataTable
                                    noDataComponent="No data available"
                                    striped
                                    columns={columns}
                                    data={editableData}
                                    onRowUpdate={(updatedRow, rowIndex) => {
                                        Object.keys(updatedRow).forEach((columnId) => {
                                            if (columns.find((column) => column.selector === columnId)?.editable) {
                                                handleCellChange(rowIndex, columnId, updatedRow[columnId]);
                                            }
                                        });
                                    }}
                                />
                            </Grid>

                            <Grid container justifyContent={'flex-end'} sx={{mt:1,pr:3}}>
                                <Button 
                                    variant="contained"
                                    size='small' 
                                    onClick={saveTable}
                                    sx={{textTransform:'none', px:4, backgroundColor:'#0f2feb', fontWeight:'bold',
                                        '&:hover': {
                                            backgroundColor:'#0f2feb',
                                            }}}
                                        >  
                                    Save
                                </Button>
                            </Grid>
                        </>
                        }   
                    </Card>
                </Grid>
            </Modal>
        </>
    );
}