import React, { useContext } from 'react';
import { WindowSizeContext } from '../../../common/context/WindowSizeContext';
import { topLineDisplayNumber, displayNumber } from "../../../common/utils";
import { Box } from '@mui/material';


export default function InventoryNumericValueDisplay({ value, prefix, postfix, isAbbreviated, undefinedDisplay = 'N/A', isTopLine, isOnScoreCard }) {
    const windowSize = useContext(WindowSizeContext);

    if (value === 'N/A') {
        if (isOnScoreCard) {
            return value;
        }
        return <Box textAlign={'center'}>
            {value}
        </Box>;
    }

    if (!value || !isFinite(value) || isNaN(value)) {
        if (isOnScoreCard) {
            return undefinedDisplay;
        }
        return <Box textAlign={'center'}>
            {undefinedDisplay}
        </Box>;
    }

    let display;

    const decimalDisplayNumber = (value, isWindowSizeMobile) => {
        if (typeof value === 'number') {
            return value.toFixed(2);
        } else {
            return value; // Return the value unchanged if it's not a number
        }
    }

    // Added condition for negative values including -0
    if (value < 1 && Math.sign(value) !== -1) {
        display = decimalDisplayNumber(value); // Format value to 3 decimal places if less than 1
    } else if (value.toString().includes('-0.000')) {
        display = 0; // Replace negative zero with zero
    } else {
        const numberDisplay = displayNumber(value);
        const numberDisplayTop = topLineDisplayNumber(value);
        display = isTopLine || isAbbreviated ? numberDisplayTop : numberDisplay;
    }
    return <>{prefix}{display}{postfix}</>;
}
