import React, { useMemo, useState, useContext, useEffect } from "react";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import { useAvailableDateRange, useStorePerformanceData } from "../hooks";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import { convertToValidNumber, getSelectedOption, displayNumber } from "../../../../common/utils";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { Box, Grid, Typography } from '@mui/material';
import DropdownCheckboxV3 from "../../../../common/components/DropdownCheckboxV3";
import Item from "../../../../common/components/ItemCard";
import MetricFilter from "../Filters/MetricFilter";
import ConditionFilter from "../Filters/ConditionFilter";
import MetricInputValueFilter from "../Filters/MetricInputValueFilter";
import { CATEGORY, PERIOD_OPTIONS } from "../constants";
import { getDaysDifference } from '../util';
import moment from "moment";
import BarIndicator from "../../common/presentation/barIndicator";
import NumericValueDisplay from "../../common/presentation/numericValueDisplay";
import ToggleSelector from "../../common/presentation/selectors/toggleSelector";

const IndicatorCellBox = (props) => (
    <Box sx={{ my: 1, width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);

export default function StoreSalesPerformance({ params, filters, selectedRows, orderArray,
    period, onToggleChange }) {

    const [storeFilters, setStoreFilters] = useState([])
    const [metricFilters, setMetricFilters] = useState({
        metric_column: '',
        condition: '',
        metric_value: ''
    })

    const isMTD = period === 'mtd';

    const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] }
    const isProductGroup = filters?.group === 'product_group';

    const { data: storePerformanceData, isLoading: isLoadingStorePerformance } = useStorePerformanceData({
        params: {
            ...paramsBasedOnPeriod,
            group_by: 'branch_id,store,mall_type,store_size,mall_category,region',
            limit: 1000,
            product_group: isProductGroup ? selectedRows : null
        },
    });

    const { data: dateRange, isLoading: isLoadingDateRange } = useAvailableDateRange()
    const paramsPeriod = params?.month?.[0] && params?.year ? params?.year + '-' + params?.month?.[0] : moment().date(new Date()).format('YYYY-MM');
    const periodStartDate = isMTD ? moment(paramsPeriod).startOf('month').format('YYYY-MM-DD') : moment(paramsPeriod).startOf('year').format('YYYY-MM-DD');
    const periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
    const maxAvailableDate = !isLoadingDateRange && moment(dateRange?.max).format('YYYY-MM-DD');
    const isMonthNotEqualToMax = params?.month?.[0] !== moment(maxAvailableDate).month() + 1; //if selected params is not equal to max date.
    const maxDaysInPeriod = isMonthNotEqualToMax ? periodEndDate : maxAvailableDate;
    const numberOfDaysWithinThePeriod = getDaysDifference(periodStartDate, periodEndDate);
    const maxAvailableDaystheSelectedMonth = getDaysDifference(periodStartDate, maxDaysInPeriod)

    const getRunningDailyTarget = (target) => {
        return (target / numberOfDaysWithinThePeriod) * maxAvailableDaystheSelectedMonth
    };

    const windowSize = useContext(WindowSizeContext)

    const newStorePerformanceData = useMemo(() => {
        if (selectedRows?.length > 0 && !isProductGroup) {
            return storePerformanceData?.filter(data => {
                return selectedRows.includes(data?.[filters?.group])
            })
        } else {
            return storePerformanceData
        }
    }, [storePerformanceData, selectedRows, filters?.group])
    const HeaderDisplay = (header) => {
        return <Box display='flex' justifyContent='center' width='100%'>{header}</Box>
    }



    const optionListStore = (newStorePerformanceData || [])
        .map((row) => ({
            value: row?.branch_id,
            label: row?.store,
        }))
        .filter((option) => option.value !== "" && option.label !== "")
        .sort((a, b) => a.label?.localeCompare(b.label));

    function handleSelectStore(data) {
        let result = data.map(a => a.value);
        setStoreFilters(result)
        return result
    }

    function handleMetricFiltersChange(value, name) {
        setMetricFilters({
            ...metricFilters,
            [name]: value
        })
    }

    const dataToDisplay = storeFilters?.length > 0 ? newStorePerformanceData.filter(obj => storeFilters.includes(obj?.branch_id)) : newStorePerformanceData

    let filteredDataSubtotals = {};

    // Function to calculate filteredDataSubtotal
    const calculateFilteredDataSubtotal = (data) => {
        const newSubtotal = {
            ads: null,
            actual_sales: null,
            target_sales: null,
            vs_running_daily_target: null,
            last_year_actual_sales: null,
            vs_last_year_mtd_ytd_sales: null,
            sales_per_sqm: null,
            vs_last_year_sqm_sales: null,
        };


        // Iterate through data and push values into the corresponding arrays
        for (const key in data) {

            if (data.hasOwnProperty(key)) {
                const newValues = data[key];
                let sum = 0;
                for (let i = 0; i < newValues.length; i++) {
                    if (typeof newValues[i] === 'string' || isNaN(parseFloat(newValues[i])) || !isFinite(parseFloat(newValues[i]))) {
                        newValues[i] = 0;
                    }
                    sum += parseFloat(newValues[i]);
                }

                if (String(key).startsWith('vs')) {
                    // Average values
                    newSubtotal[key] =
                        <IndicatorCellBox>
                            <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(sum / (newValues.length || 1))?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                        </IndicatorCellBox>

                } else {
                    // Sum values
                    newSubtotal[key] = (
                        <Box sx={{ whiteSpace: 'nowrap', marginX: 'auto' }}>
                            <NumericValueDisplay value={sum} prefix={key === 'sales_per_sqm' ? '' : '₱'} isAbbreviated={windowSize?.isOnMobile} />
                        </Box>
                    );
                }
            }
        }

        filteredDataSubtotals = newSubtotal;
    };

    let hasFooterSubtotal = dataToDisplay.length <= 5;

    const filteredData = useMemo(() => {
        let filteredDataToDisplay = dataToDisplay;

        if (!!metricFilters?.metric_column && !!metricFilters?.condition && metricFilters?.metric_value !== "") {
            if (metricFilters?.condition === 'greater_than_equal') {
                filteredDataToDisplay = filteredDataToDisplay?.filter(data => {
                    return data?.[metricFilters?.metric_column] >= parseFloat(metricFilters?.metric_value);
                });
            } else if (metricFilters?.condition === 'less_than_equal') {
                filteredDataToDisplay = filteredDataToDisplay?.filter(data => {
                    return data[metricFilters?.metric_column] <= parseFloat(metricFilters?.metric_value);
                });
            }
        }

        hasFooterSubtotal = filteredDataToDisplay.length !== 0 && filteredDataToDisplay.length <= 5; // This comes after filteredData is defined

        if (hasFooterSubtotal) {
            const newRowValues = {
                ads: [],
                actual_sales: [],
                target_sales: [],
                vs_running_daily_target: [],
                last_year_actual_sales: [],
                vs_last_year_mtd_ytd_sales: [],
                sales_per_sqm: [],
                vs_last_year_sqm_sales: []
            };

            // Iterate through dataToDisplay and push values into the corresponding arrays
            filteredDataToDisplay?.forEach(data => {
                newRowValues.ads.push(parseFloat(data.ads));
                newRowValues.actual_sales.push(parseFloat(data.actual_sales));
                newRowValues.target_sales.push(parseFloat(data.target_sales));
                newRowValues.vs_running_daily_target.push(parseFloat(data.vs_running_daily_target));
                newRowValues.last_year_actual_sales.push(parseFloat(data.last_year_actual_sales));
                newRowValues.vs_last_year_mtd_ytd_sales.push(parseFloat(data.vs_last_year_mtd_ytd_sales));
                newRowValues.sales_per_sqm.push(parseFloat(data.sales_per_sqm));
                newRowValues.vs_last_year_sqm_sales.push(parseFloat(data.vs_last_year_sqm_sales));
            });

            calculateFilteredDataSubtotal(newRowValues);
        }

        return filteredDataToDisplay;

    }, [dataToDisplay, metricFilters])

    const store_performance_col = useMemo(
        () => [
            {
                header: 'Store',
                accessorKey: 'store',
                cell: (row) => <Box sx={{ width: '7.5rem' }}> {row?.getValue()} </Box>,
            },
            {
                header: isMTD ? 'MTD Sales' : 'YTD Sales',
                id: 'sales',
                columns: [
                    {
                        header: 'ADS',
                        accessorKey: 'ads',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '10rem', textAlign: 'left', verticalAlign: 'middle' }
                    },
                    {
                        header: isMTD ? 'MTD' : 'YTD',
                        accessorKey: 'actual_sales',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    }
                ]
            },
            {
                header: isMTD ? 'MTD Target' : 'YTD Target',
                columns: [
                    {
                        header: isMTD ? 'MTD Target' : 'YTD Target',
                        accessorKey: 'target_sales',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },
                    {
                        header: 'vs Running Daily Target',
                        accessorKey: 'vs_running_daily_target',
                        cell: (row) => {
                            const value = row?.getValue();
                            if (value === null || value === undefined) {
                                return "-";
                            } return <IndicatorCellBox additionalStyles={{ my: 1 }}>
                                <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </IndicatorCellBox>
                        },
                        customStyle: { textAlign: 'center', verticalAlign: 'middle' }
                    }
                ]
            },
            {
                header: isMTD ? 'Last Year MTD Sales' : 'Last Year YTD Sales',
                columns: [
                    {
                        id: 'last_year_sales',
                        header: isMTD ? 'Last Year MTD' : 'Last Year YTD',
                        accessorKey: 'last_year_actual_sales',
                        cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                        customStyle: { width: '12rem', textAlign: 'left', verticalAlign: 'middle' }
                    },
                    {
                        header: 'vs Last Year',
                        id: 'vs_last_year_mtd_sales',
                        accessorKey: 'vs_last_year_mtd_ytd_sales',
                        cell: (row) => <>

                            <IndicatorCellBox><BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" /></IndicatorCellBox>

                        </>,
                        customStyle: { textAlign: 'center', verticalAlign: 'middle' }
                    }
                ]
            },
            {
                header: 'ADS per SQM',
                id: 'ads_per_sqm',
                columns: [
                    {
                        header: 'Sales per SQM',
                        id: 'sales_per_sqm',
                        accessorKey: 'sales_per_sqm',
                        cell: (row) => <Box sx={{ width: { sm: '50%' }, marginX: 'auto' }}><NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /></Box>,
                        customStyle: { width: '8rem', textAlign: 'center', verticalAlign: 'middle' }
                    },
                    {
                        header: 'vs Last Year',
                        id: 'vs_last_sales_per_sqm',
                        accessorKey: 'vs_last_year_sqm_sales',
                        cell: (row) => {
                            const value = row?.getValue();
                            if (value === null || value === undefined) {
                                return "-";
                            } return <IndicatorCellBox><BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" /></IndicatorCellBox>
                        },
                        customStyle: { textAlign: 'center', verticalAlign: 'middle', mx: 'auto' }
                    }
                ]
            }
        ],
        [newStorePerformanceData, filters?.group, period]
    )

    if (isLoadingStorePerformance) {
        return <LoadingComponent />
    }

    function activeFiltersText() {
        let selectedCategory = CATEGORY[filters?.group];
        const withTopLabel = ['888', '888 (Outright)', '4k']
        let filtersText = selectedRows && selectedRows.length > 0 ? (
            <span>
                - {selectedRows.map(item => {
                    if (withTopLabel.includes(item)) {
                        return 'Top ' + item
                    }
                    return item
                }).join(', ')}
            </span>
        ) : '';
        return (
            <span>
                Filters Selected | Grouping: {selectedCategory} {filtersText}
            </span>
        );
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }} mt={3}>
                <Grid container columns={16} columnSpacing={1} rowSpacing={.5} alignItems="center">
                    {
                        windowSize?.isOnMobile ? <>
                            <Grid item xs sm={3}>
                                <Typography variant='h6' ml={2} >Store Performance</Typography>
                            </Grid>
                            <Grid item xs display="flex" justifyContent={'end'}>
                                <ToggleSelector
                                    options={PERIOD_OPTIONS}
                                    value={period}
                                    onChange={onToggleChange} />
                            </Grid>
                            <Grid item xs={16} sm={3} display="flex" alignItems="center">
                                <DropdownCheckboxV3
                                    id="sales_store_filter"
                                    options={optionListStore}
                                    label="Store"
                                    placeholder="Select a store"
                                    limitTags={2}
                                    onChange={(event, newValue) => {
                                        handleSelectStore(getSelectedOption(newValue, optionListStore));
                                    }}
                                    value={getSelectedOption(storeFilters, optionListStore)}
                                />
                            </Grid>
                            <Grid item xs={16} sm={3}>
                                <MetricFilter isMTD={isMTD} filter={metricFilters} onFilterChange={handleMetricFiltersChange} />
                            </Grid>
                            <Grid item xs={16} sm={2}>
                                <ConditionFilter filter={metricFilters} onFilterChange={handleMetricFiltersChange} />
                            </Grid>
                            <Grid item xs={16} sm={3} mb={.5}>
                                <MetricInputValueFilter filter={metricFilters} onFilterChange={handleMetricFiltersChange} />
                            </Grid>
                        </> : <>

                            <Grid item xs={16} sm={3}>
                                <Typography variant='h6' ml={2} >Store Performance</Typography>
                            </Grid>
                            <Grid item xs={16} sm={3} display="flex" alignItems="center">
                                <DropdownCheckboxV3
                                    id="sales_store_filter"
                                    options={optionListStore}
                                    label="Store"
                                    placeholder="Select a store"
                                    limitTags={2}
                                    onChange={(event, newValue) => {
                                        handleSelectStore(getSelectedOption(newValue, optionListStore));
                                    }}
                                    value={getSelectedOption(storeFilters, optionListStore)}
                                />
                            </Grid>
                            <Grid item xs={16} sm={3}>
                                <MetricFilter isMTD={isMTD} filter={metricFilters} onFilterChange={handleMetricFiltersChange} />
                            </Grid>
                            <Grid item xs={16} sm={2}>
                                <ConditionFilter filter={metricFilters} onFilterChange={handleMetricFiltersChange} />
                            </Grid>
                            <Grid item xs={16} sm={3}>
                                <MetricInputValueFilter filter={metricFilters} onFilterChange={handleMetricFiltersChange} />
                            </Grid>
                            <Grid item sm={2} xs={16} display="flex" justifyContent={'end'}>
                                <ToggleSelector
                                    options={PERIOD_OPTIONS}
                                    value={period}
                                    onChange={onToggleChange} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid item xs={12} marginBottom={2} sx={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>
                <Grid item xs={12} sm={12} sx={{ boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)', border: '1px solid #e0e0e0', backgroundColor: '#e1ebf2' }}>
                    <Typography ml={2} sx={{ borderRadius: '0', color: '#000' }}>{activeFiltersText()}</Typography>
                </Grid>
                <Item>
                    <TableComponentV2
                        customTableContainerStyle={{
                            borderRadius: "0",
                            boxShadow: 'none',
                        }}
                        customTableHeaderStyle={{
                            backgroundColor: '#caddeb'
                        }}
                        columns={store_performance_col}
                        data={filteredData}
                        tableId={'SalesStorePerformanceTable'}
                        hasPagination={true}
                        isCompact={true}
                        initialSort={{ id: 'ads', desc: true }}
                        hasFooterSubtotal={hasFooterSubtotal}
                        subtotalRow={filteredDataSubtotals} />
                </Item>
            </Grid>
        </>
    );
}