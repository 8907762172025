import {useQuery} from "react-query";
import {convertParamsToTransactionDate, groupArrayBy, preProcessParams, regroupDataBy} from "./util";
import ApiClient from "../../../common/API";
import moment from "moment/moment";
import {useMemo} from "react";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../Constants/settings";
import { sortArrayBasedOnGivenArray } from "../../../common/utils";
import {useSelectorValue} from "../common/providers/selectorProvider";
import { useSBUDataL3 } from "./DataProvider/hooks";

export const TABLE_NAME = "l4_sbu_agg";
export const STORE_TABLE_NAME = "l4_sbu_agg_stores";
export const BU_TABLE_NAME = "l4_sbu_agg_bu_txn";

export const SBU_STORE_LOOKUP_TABLE = "l2_sbu_store_mapping_v2";

// new L3 tables:
export const POS_TABLE_NAME = "l3_rsa_tss_pos";
export const METRICS_TABLE_NAME = "l3_rsa_tss_metrics";

export function useAvailableDateRange() {
    const monthAbbreviations = {
        JAN: '01',
        FEB: '02',
        MAR: '03',
        APR: '04',
        MAY: '05',
        JUN: '06',
        JUL: '07',
        AUG: '08',
        SEP: '09',
        OCT: '10',
        NOV: '11',
        DEC: '12',
    };
    return useQuery(
        [STORE_TABLE_NAME, 'sales', "date_range"],
        () => ApiClient().get(`data/table/${STORE_TABLE_NAME}/query/`, {
            params: {
                columns: "year,month",
                group_by: "year,month",
                gross_sales__gt: 0,  // consider only months with sales

                limit: 1000
            }
        }).then(res => {
            const transactionDates = res.data.data.map(row => {
                const formattedMonth = monthAbbreviations[row?.month]
                return [
                    moment(`${row?.year}-${formattedMonth}-01`).toDate(),  // start date
                    moment(`${row?.year}-${formattedMonth}-01`).endOf('month').toDate() // end date
                ]
            }).flat().sort((a, b) => a - b)
            return {
                "min": transactionDates[0],
                "max": transactionDates[transactionDates.length - 1]
            }
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );
}

export function useGraphInTableData({params}) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year

    const {data: availableDateRange, isLoading: isLoadingDateRange} = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const {data: graphData , isLoading } = useQuery(
        [TABLE_NAME, "sales", params, 'sparkline'],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(params, dayOfMonthMaxDate)
            )

            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,  // fixme: base it on the selected year-month. currently, it's based on the latest month
                    aggregates: "gross_sales_sum,sales_plan_sum",
                    columns:`${params?.group_by},year,month`,
                    group_by: [params?.group_by, 'year', 'month'].join(','),
                    limit: 10000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange
        }
    );
    const data = useMemo(() => {
        if (isLoading) {
            return []
        }

        let groupBy = params.group_by;

        const orderArray = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
        
        const sortedData = sortArrayBasedOnGivenArray(graphData, orderArray, 'month')
        const graphGroupedData = groupArrayBy(sortedData, groupBy);
        return graphGroupedData;
    },[params, graphData, isLoading])

    return {
        data,
        isLoading
    }
}

export function useDynamicGraphInTableData({params}) {
    const isGroupedByBusinessUnit = !!params && (
        params.group_by === "business_unit" ||  params.columns === "business_unit" || params.hasOwnProperty("business_unit")
        || params.group_by === "higher_mbu" || params.columns === "higher_mbu" || params.hasOwnProperty("higher_mbu")
    )
    const involvesBusinessUnit = (isGroupedByBusinessUnit || params?.business_unit)
    const SBU_TABLE_NAME = involvesBusinessUnit ? BU_TABLE_NAME : STORE_TABLE_NAME;
    const isGiftRegistryStoreView = (params?.group_by === 'store' && params?.channel && params?.channel[0] === 'Gift Registry') //params?.channel === 'store,store_id'
    const isGiftRegistryBUView = (involvesBusinessUnit && params?.channel && params?.channel[0] === 'Gift Registry') //params?.channel === 'store,store_id'
    const isGiftRegistryGroup = params?.aggregates === ["gr_txn_count_sum", "gr_gross_sales_sum", "gross_sales_sum", "egr_plan_sum", "gr_smac_sales_sum"].join(",")
    const giftRegistryAggregates = ["gr_txn_count_sum", "gr_gross_sales_sum", "gross_sales_sum", "egr_plan_sum", "gr_smac_sales"]
    const aggregateOptions = {
        storeView: {
            giftRegistry: giftRegistryAggregates,
            default: [
                "store_txn_count_sum", "gross_sales_sum", "sales_plan_sum", "basket_size_plan_sum",
                "ds_foot_traffic_sum", "mall_foot_traffic_sum", "foot_traffic_txn_count_sum",
                "net_selling_area_sum", "total_floor_selling_area_sum", "smac_sales_sum",
                "store_id_count_distinct", "sales_units_sum"]
        },
        buView: {
            giftRegistry: giftRegistryAggregates,
            default: ["bu_txn_count_sum_distinct", "gross_sales_sum", "sales_plan_sum", "smac_sales_sum", "net_selling_area_sum", "sales_units_sum"]
        }
    };
    
    const aggregatesPerTable = params?.aggregates || (
        SBU_TABLE_NAME === STORE_TABLE_NAME
            ? aggregateOptions.storeView[isGiftRegistryStoreView ? 'giftRegistry' : 'default'].join(',')
            : aggregateOptions.buView[isGiftRegistryBUView ? 'giftRegistry' : 'default'].join(',')
    );

    const hasTransactionDate = params?.month?.length > 0 && !!params?.year

    const {data: availableDateRange, isLoading: isLoadingDateRange} = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const newParams = useMemo(() => { 
        return {
            ...params, 
            metric_column: null,
            columns: params?.group_by,// In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
        } 
    },[params])

    const columns = isGiftRegistryStoreView 
        ? `store,year,month`
        : isGiftRegistryGroup // Per Group Data Gift Registry Row only
            ? `year,month` 
            : `${newParams?.group_by},year,month`

    const {data: graphData , isLoading } = useQuery(
        [SBU_TABLE_NAME, "sales", newParams, 'sparkline'],
        () => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(newParams, dayOfMonthMaxDate)
            )
            return ApiClient().get(`data/table/${SBU_TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,  // fixme: base it on the selected year-month. currently, it's based on the latest month
                    aggregates: aggregatesPerTable,
                    // columns: isGiftRegistryStoreView ? `store,year,month`: ( isGiftRegistryGroup ? `year,month` : `${newParams?.group_by},year,month`),
                    // group_by: isGiftRegistryStoreView ? `store,year,month`: ( isGiftRegistryGroup ? `year,month` : `${newParams?.group_by},year,month`),
                    columns: columns,
                    group_by: columns,
                    limit: 10000,
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange
        }
    );

    const data = useMemo(() => {
        if (isLoading) {
            return []
        }

        let groupBy = newParams.group_by;

        const orderArray = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
        
        const sortedData = sortArrayBasedOnGivenArray(graphData, orderArray, 'month')
        const graphGroupedData = groupArrayBy(sortedData, groupBy);
        return graphGroupedData;
    
    },[newParams, graphData, isLoading])

    return {
        data,
        isLoading: isLoading
    }
}

export function useDynamicGraphInTableDataL3({ params, queryOptions = {}}) {
   
    const newParams = useMemo(() => { 
        return {
          ...params,
          metric_column: null,
          group_by: params?.group_by ? `${params.group_by},year_month` : `year_month`, // In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
          columns: params?.group_by ? `${params.group_by},year_month` : `year_month`, // In the case of Store View, the 'columns' value inserted will be the group_by of Per Group data
        }; 
    },[params])

    const { data: graphData, isLoading } = useSBUDataL3({ params: newParams, queryOptions })    
    
    const data = useMemo(() => {
        if (isLoading) {
            return []
        }

        let groupBy = params?.group_by;
        
        const sortedData = graphData.sort((a, b) => new Date(b.year_month) - new Date(a.year_month));
        const graphGroupedData = groupArrayBy(sortedData, groupBy);
        return graphGroupedData;
    
    },[params?.group_by, graphData, isLoading])

    return {
        data: data,
        isLoading: isLoading
    }
}

// converted to use L3 data (didn't make another hook since returned data doesn't have column names from the BQ tables)
export function useBUGrouping() {

    const { data: buGroupingData, isLoading } = useQuery(
        [POS_TABLE_NAME, 'stores', "sbu_bu_group", "pm_business_unit_desc_standardized"],
        () => ApiClient().get(`data/table/${POS_TABLE_NAME}/query/`, {
            params: {
                columns: "sbu_bu_group,pm_business_unit_desc_standardized",
                group_by: "sbu_bu_group,pm_business_unit_desc_standardized",
                pos_sku_gross_sales__gt: 0,  // consider only months with sales
                limit: 1000
            }
        }).then(res => {
            return res.data.data.filter(item => item && item.sbu_bu_group !== null && item.business_unit !== null).reduce((result, item) => {
                const { sbu_bu_group, pm_business_unit_desc_standardized } = item;
                // Check if the sbu_bu_group (formerly 'higher_mbu') key already exists in the result object
                if (result[sbu_bu_group]) {
                    // Add the business_unit to the existing array
                    result[sbu_bu_group].push(pm_business_unit_desc_standardized);
                } else {
                    // Create a new array with the business_unit for the sbu_bu_group (formerly 'higher_mbu') key
                    result[sbu_bu_group] = [pm_business_unit_desc_standardized];
                }
                return result;
            }, {});
        }), 
        {
            ...USE_QUERY_DEFAULT_OPTIONS
        }
    );

    return { buGroupings: buGroupingData, isLoading };
}

export function useParamsBasedOnPeriodSelectorContext(params) {
    const period = useSelectorValue('period');

    const isQoQ = period === 'qoq';
    const isYTD = period === 'ytd';

    if (isQoQ) {
        const monthString = params?.month?.[0]?.toString().padStart(2, '0')
        const monthNumberStartOfQuarter = moment(`${params?.year}-${monthString}-01`, 'YYYY-MM-DD').startOf('quarter').month()
        return {
            ...params,
            month: Array.from({length: 3}, (_, i) => {
                    // return all months in the quarter
                    return monthNumberStartOfQuarter + i + 1  // we want 1-indexed months
                }
            )
        }
    } else if (isYTD) {
        return { ...params, month: [1, ...(params?.month || [])] }  // include January
    } else {
        return { ...params }
    }
}
