import React from 'react'
import VerticalBarGraph from './verticalBarGraph'
import { Grid } from '@mui/material'
import { useSMACMembersByAge, useSMACMembersByMaritalStatus } from '../../hooks'
import LoadingComponent from '../../../../../common/components/LoadingComponent'

export default function AgeGraph() {
    const {data, isLoading} = useSMACMembersByAge()

    const ageLabel = data?.filter(item => item.dimension_value !== 'Below 18')?.map(item => item.dimension_value);
    const ageData = data?.filter(item => item.dimension_value !== 'Below 18')?.map(item => item.total_members);

    if (isLoading) {
        return <LoadingComponent />
    }

    return <Grid> <VerticalBarGraph title={'Age'} xAxisLabel={ageLabel} seriesData={ageData} /> </Grid>
}