import React, { useState } from 'react';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { useTestConnect, useSave } from '../utils';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

export default function MySQLConfig({ closeModal, dataSourceHostName, setIsSubmitted }) {
    // TODO WIP 
    const hostType = 'mysql';
    const [username, setUsername] = useState('');
    const [port, setPort] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // Custom hooks
    const { testConnect: testConnection } = useTestConnect()
    const { saveHost: saveHosts } = useSave()

    // Function that uses useTestConnect custom hook
    const handleTestConnect = () => {
        const data = {
            host_type: hostType,
            username: username,
            server_port: port,
            password: password
        }
        setIsSubmitted(true);
        setIsLoading(true);
        testConnection(data).finally((data) => { // calls the useTestConnect custom hook
            setIsLoading(false);
            setIsSubmitted(false);
        });
    }
    // Function that uses useSave custom hook
    const handleSave = () => {
        const connectionConfig = {
            username: username,
            server_port: port,
            password: password
        };

        const hostData = {
            name: dataSourceHostName,
            host_type: hostType,
            connection_config: connectionConfig,
            is_active: true
        };
        setIsSubmitted(true);
        setIsLoading(true);
        saveHosts(hostData).then(() => { // calls the useSave custom hook
                closeModal();
                setIsSubmitted(false);
                setIsLoading(false);
                return toast.success('Data source host saved!');
            })
            .catch(error => {
                console.error(error);
                return toast.error("Save host failed!"); 
            });
    }

    const isSubmitDisabled = !username || !port || !password;

    function RenderContent() {
        return (
            <>
                <Typography variant="h6" sx={{ml:3, mt:2,fontWeight:'bold'}}>Connection Configuration</Typography>

                <Grid item md={12} sx={{ml:6, mt:1}}>
                    <Stack direction={'column'}>
                        <Typography variant="subtitle" sx={{fontWeight:'bold',color:'#878787'}}>Username:</Typography>

                        <TextField
                           id="usernameInput"
                           size={'small'} 
                           placeholder="Enter Username" 
                           value={username}
                           onChange={(e) => setUsername(e.target.value)}
                           sx={{width:'95%', mt:1}}
                        />
                    </Stack>
                </Grid>

                <Grid item md={12} sx={{ml:6, mt:1}}>
                    <Stack direction={'column'}>
                        <Typography variant="subtitle" sx={{ fontWeight:'bold',color:'#878787'}}>Port:</Typography>

                        <TextField
                           id="pathInput"
                           size={'small'} 
                           placeholder="Enter Port" 
                           value={port}
                           onChange={(e) => setPort(e.target.value)}
                           sx={{width:'95%', mt:1}}
                        />
                    </Stack>
                </Grid>

                <Grid item md={12} sx={{ml:6, mt:1}}>
                    <Stack direction={'column'}>
                        <Typography variant="subtitle" sx={{ fontWeight:'bold',color:'#878787'}}>Password:</Typography>

                        <TextField
                           id="tokenInput"
                           size={'small'} 
                           type='password'
                           placeholder="Enter Password" 
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                           sx={{width:'95%', mt:1}}
                        />
                    </Stack>
                </Grid>

                <Grid container justifyContent={'flex-end'} sx={{mt:2, pr:4.5}}>
                    <Button 
                    variant="contained" 
                    size='small'
                    disabled={isSubmitDisabled}
                    onClick={handleTestConnect}
                    sx={{borderRadius:'5px',px:1.6, textTransform:'none', backgroundColor:'#66e472', fontWeight:'bold'
                    ,'&:hover': {
                        backgroundColor: `${!isSubmitDisabled ? '#66e472' : ''}`,
                        }}}> 
                    Test Connect</Button>
                </Grid>

                <Grid container justifyContent={'flex-end'} sx={{mt:2, pr:4.5}}>
                    <Button 
                        variant="contained" 
                        size='small'
                        disabled={isSubmitDisabled}
                        onClick={handleSave}
                        sx={{borderRadius:'5px', mr:2,px:5, textTransform:'none', backgroundColor:'blue',fontWeight:'bold'
                        ,'&:hover': {
                            backgroundColor: `${!isSubmitDisabled ? 'blue' : ''}`,
                            }}}
                        color="success"> 
                        Save
                    </Button>

                    <Button 
                        variant="outlined" 
                        size='small'
                        onClick={closeModal}
                        sx={{borderRadius:'5px' , textTransform:'none',color:'blue', border:'solid 1px blue', fontWeight:'bold', px:4,
                            '&:hover': {
                            border:'solid 1px blue',
                            backgroundColor:'#fcfcfds'
                          }}}
                        > 
                        Cancel
                    </Button>
                </Grid>
     
            </>
        )

    }
    return (
        <>
            {isLoading ? <LoadingComponent /> : RenderContent()}
        </>
    )
}