import React, {useContext} from "react";
import Select from "react-select";
import {ConvertToReactSelectOption} from "../../../../../common/utils";
import {SelectOverridesStyles} from "../../Map/util";
import AgeGroupBarChart from "./ageGroupBarChart";
import GenderPieChart from "./genderPieChart";
import {StoreAnalyticsFilterContext} from "../../contexts";

export default function LocationSideItems({regionData, populationFilters, setPopulationFilters,  toggleMenuOpen, onChangePopulation, regionNames, title}) {
    const {region, setFilter} = useContext(StoreAnalyticsFilterContext)


    const genderOptions = Array.from(new Set(regionData?.map(data => data?.demographic?.map(row => row.gender)).flat()))
    const ageGroupOptions = Array.from(new Set(regionData?.map(data => data?.demographic?.map(row => row.ageGroup)).flat()))

    const selectedRegionDemographics = !region ?
        regionData?.map(data => data?.demographic).flat() :
        regionData?.filter(data => data.region === region).map(data => data?.demographic).flat()

    const filteredByGender = (!populationFilters.gender || populationFilters.gender.length === 0) ?
        selectedRegionDemographics : selectedRegionDemographics.filter(row => !populationFilters.gender.includes(row.gender))
    const filteredByAgeGroup = (!populationFilters.ageGroup || populationFilters.ageGroup.length === 0) ?
        filteredByGender : filteredByGender.filter(row => !populationFilters.ageGroup.includes(row.ageGroup))

    const regionOptions = ConvertToReactSelectOption(regionNames).map(option => {
        return {
            ...option,
            value: option.value === "All Region" ? null: option.value
        }
    })
    return (
        <div className="bg-white">
            {/* <div className="d-flex flex-column" style={{minWidth: '20rem', maxWidth: '20rem'}}> */}
                <div className="shadow-sm p-3 mt-2">
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <label style={{fontSize: '.75rem'}}>Region:</label>
                            <Select
                                value={regionOptions.find(option => option.value === region)}
                                onChange={(e) => setFilter('region', e?.value)}
                                options={regionOptions}
                                styles={{...SelectOverridesStyles,
                                    container: (baseStyles) => ({
                                    ...baseStyles,
                                    width: '100%'
                                })}}
                            />
                        </div>
                        <div className="col-md-12">
                            <label style={{fontSize: '.75rem'}}>Metric:</label>
                            <Select
                                defaultValue={ConvertToReactSelectOption('Population')}
                                options={[ConvertToReactSelectOption('Population')]}
                                styles={{...SelectOverridesStyles,
                                    container: (baseStyles) => ({
                                    ...baseStyles,
                                    width: '100%'
                                })}}
                            />
                        </div>
                    </div>
                </div>

                <div className="shadow-sm p-3 mt-2">
                    <div className="row">
                        <div className="col-md-12 text-center side-items-cb-container ">
                            {
                                genderOptions && Object.values(genderOptions).map(gender => {
                                    return <div>
                                        <input
                                            type="checkbox" id={`gender-${gender}`} name="gender" value={gender}
                                            checked={!populationFilters.gender?.includes(gender)}
                                            onChange={e => onChangePopulation('gender', gender, !e.target.checked)}
                                        />
                                        {" "}
                                        <label className="fs-5">{gender}</label>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                
                <div className="shadow-sm p-3 mt-2">
                    <div className="row">
                        <div className="col-md-12">
                            <label className="fw-semibold">Age Bracket:</label>
                            <div className="side-items-cb-container ">

                                {
                                    ageGroupOptions && Object.values(ageGroupOptions).map(ageGroup => {
                                        return <div>
                                            <input
                                                type="checkbox" id={`ageGroup-${ageGroup}`} name="ageGroup" value={ageGroup}
                                                checked={!populationFilters.ageGroup?.includes(ageGroup)}
                                                onChange={e => onChangePopulation('ageGroup', ageGroup, !e.target.checked)}
                                            />
                                            {" "}
                                            <label>{ageGroup}</label>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <GenderPieChart genderData={filteredByAgeGroup}/>
                <AgeGroupBarChart ageGroupData={filteredByAgeGroup}/>
        </div>
    )
}