import React from "react";
import {useTableQuery} from "../../../common/API/hooks.js";
import {useSelectorValue} from "../common/providers/selectorProvider.jsx";
import moment from "moment";
import { PERIOD_QUARTERLY, PERIOD_MONTHLY } from "../../../Constants/general.js";
export const TABLE_SMAC_PERF_MEMBER_BASE_MONTHLY = "l4_smac_perf_monthly";
export const TABLE_SMAC_PERF_MEMBER_BASE_QUARTERLY = "l4_smac_perf_quarterly";

export function useTableQueryWithDateFilters({ tableName, params, useQueryOptions = {}, applyDateFilters = true}) {
    // to use this hook, the table structure must follow the following format
    // year, (quarter or month), ...

    const period = useSelectorValue('period');
    const periodicity = useSelectorValue('periodicity');
    const year = useSelectorValue('year');
    
    const newParams = {
        ...params
    }
    const newQueryOptions = {
        ...useQueryOptions
    }
    if (applyDateFilters) {
        if (!year || !periodicity) {
            newQueryOptions.enabled = false;
            console.warn("Year or Periodicity not set, disabling query")
        }
        
        if (period === PERIOD_QUARTERLY) {
            newParams.quarter = periodicity;
        } else if (period === PERIOD_MONTHLY) {
            newParams.month = periodicity?.toString().padStart(2, '0');
        }
    }
    return useTableQuery({ tableName, params: newParams, useQueryOptions: newQueryOptions })
}

export function useSMACPerformanceBaseTableQuery({ params, useQueryOptions, applyDateFilters = true}) {
    const period = useSelectorValue('period');
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return TABLE_SMAC_PERF_MEMBER_BASE_MONTHLY;
        } else if (period === PERIOD_QUARTERLY) {
            return TABLE_SMAC_PERF_MEMBER_BASE_QUARTERLY;
        }
        throw new Error("Invalid period");
    }, [period])

    return useTableQueryWithDateFilters({
        tableName,
        params,
        useQueryOptions,
        applyDateFilters
    })
}

export function usePeriodValues() {
    const { data, isLoading, ...rest } = useSMACPerformanceBaseTableQuery({
        params: { group_by: ['year', 'quarter', 'month'] },
        applyDateFilters: false
    });

    const asDates = React.useMemo(() => {
        if (isLoading || !data) {
            return [];
        }
        return data.map((row) => {
            if (row.quarter) {
                return moment(`${row.year} Q${row.quarter}`, "YYYY Q").toDate()
            } else if (row.month) {
                return moment(`${row.year}-${row.month}`, "YYYY-MM").toDate()
            }
            return null;  // Add return statement for non-matching conditions
        }).sort((a, b) => a - b);  // Add sort function to sort dates
    }, [data, isLoading]);

    const asDateRange = React.useMemo(() => {
        if (!asDates || asDates.length === 0) {
            return null;
        }
        return {
            from: asDates[0],
            to: asDates[asDates.length - 1]
        }

    }, [asDates])


    return {
        data,  // raw data
        asDates,  // as javascript dates
        asDateRange,  // date range from the first to the last date
        isLoading,
        ...rest
    }
}


export function useLatestLoadDateMoment() {
    const { data, isLoading, ...rest } = useSMACPerformanceBaseTableQuery({
        params :{
            aggregates: ['load_date_max'],
        },
        applyDateFilters: false,
    })

    const latestLoadDate = data?.[0]?.load_date_max ? moment(data[0]?.load_date_max) : null;

    return {
        data: latestLoadDate,
        isLoading,
        rawData: data,
        ...rest
    }
}

export function useSMACPerformanceMetricTable() {
    const year = useSelectorValue('year');
    const { data, isLoading, ...rest } = useSMACPerformanceBaseTableQuery({
        params :{   
            year: [`${year}`, `${year - 1}`],
            limit: 3000
        },
    })

    
    const preparedData = React.useMemo(() => {
        if (!data) {
            return [];
        }
        
        const currentYearData = data?.filter(row => row.year === year)
        const lastYearData = data?.filter(row => row.year === year -1)

        const combinedData = currentYearData.map(row => {
            return {
                ...row,
                last_year_data: lastYearData?.find(lyRow => lyRow.organization === row.organization && lyRow.bu_group === row.bu_group && lyRow.business_unit === row.business_unit) || {}  // Add last year data if available, otherwise an empty object
            }
        })
        const organizationLevelData = combinedData.filter(row => row.business_unit === 'Overall' && row.bu_group === 'Overall');

        return  organizationLevelData.map(row => {
            const buGroupData = combinedData.filter(secondLevel => secondLevel.organization === row.organization && secondLevel.bu_group !== 'Overall'  && secondLevel.business_unit === 'Overall');
            return {
                pk: row.organization,
                ...row,
                subRows: buGroupData.map(thirdLevel => {
                    const buData = combinedData.filter(subRow => subRow.bu_group === thirdLevel.bu_group && thirdLevel.business_unit === 'Overall')?.filter(buRow => buRow.business_unit !== 'Overall')?.map(buRow => {
                        return { pk: buRow.business_unit, ...buRow}
                    });
                    return {
                        pk: thirdLevel.bu_group,
                        ...thirdLevel,
                        subRows: buData
                    }
                })
            }
        })
    }, [data])
    return {
        data: preparedData,
        isLoading,
        ...rest
    }
}
