import React, {useCallback, useState} from "react";
import {DEFAULT_DASHBOARD_LAYOUT} from "../constants";
import {arrayOfObjectsEqual} from "../../../../common/utils";
import Layout from "./layout";
import DashboardOptions from "./dashboardOptions";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

export default function Editor({data, onChange}) {

    const dashboardLayoutValue = data.extra_data?.layout ? data.extra_data?.layout : DEFAULT_DASHBOARD_LAYOUT;
    const content = data.extra_data?.content || {}
    const [isDashboardOptionOpen, setIsDashboardOptionOpen] = useState(true)

    const onChangeLayout = useCallback((layout) => {
        if (arrayOfObjectsEqual(layout, dashboardLayoutValue.layouts?.lg)) {
            return
        }
        onChange(prevData => {
            return {
                ...prevData,
                extra_data: {
                    ...prevData.extra_data,
                    layout: {
                        ...prevData.layout,
                        layouts: {
                            ...prevData.layout?.layouts,
                            lg: [
                                ...layout
                            ]
                        }
                    }
                }
            }
        })
    }, [dashboardLayoutValue.layouts?.lg])

    const updateContent = useCallback((contentId, newContent) => {
        onChange(prevData => {
            const newContents = {...(prevData.extra_data?.content || {})}

            // if not, add it
            newContents[contentId] = newContent
            
            return {
                ...prevData,
                extra_data: {
                    ...prevData.extra_data,
                    content: newContents,
                    layout: {
                        ...prevData.extra_data.layout,
                        layouts: {
                            ...prevData.extra_data.layout.layouts,
                            lg: [
                                ...prevData.extra_data.layout.layouts.lg.map(obj =>{
                                    if(obj.i === contentId && newContent.hasOwnProperty('chart')) {
                                        //set min h and w for charts container 
                                        //TODO: fix dynamically
                                        return {
                                            ...obj,
                                            h: 7,
                                            w: 5
                                        }
                                    } else {    
                                        return obj
                                    }
                                })
                            ]
                        }
                    }
                }
            }
        })
    }, []);

    return (<>    
        {/* <div className="row pointer" onClick={()=> setIsDashboardOptionOpen(!isDashboardOptionOpen)} >
            <div className="col-md-11">
                <h4>Dashboard Details</h4>
            </div>
            <div className="col-md-1 fs-4 text-end">
                {
                    isDashboardOptionOpen ? <i className="bi bi-chevron-down"></i> : <i className="bi bi-chevron-right"></i>
                }
            </div>
        </div> */}


        {/* <Collapse in={isDashboardOptionOpen}>
            <div>
                <DashboardOptions data={data} onChange={onChange} isOpen={isDashboardOptionOpen}/>
            </div>
        </Collapse> */}

        <Accordion expanded={isDashboardOptionOpen} onChange={()=>setIsDashboardOptionOpen(!isDashboardOptionOpen)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography variant="h5" >Dashboard Details</Typography>
            </AccordionSummary>
            <AccordionDetails
                expandIcon={<ExpandMoreIcon />}
            >
                <DashboardOptions data={data} onChange={onChange} isOpen={isDashboardOptionOpen}/>
            </AccordionDetails>
        </Accordion>

        <Layout
            tableId={data.table_source}
            dashboardLayoutValue={dashboardLayoutValue}
            onChangeLayout={onChangeLayout}
            content={content}
            onChangeContent={updateContent}
        />
    </>)
}