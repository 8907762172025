import { useState } from "react"


export default function CaretComponent(props) {
    const [isOpen, setIsOpen]= useState(true);

    const caret = isOpen ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"
    return <>
        <i className={caret} style={{fontSize: props.size === "small" ? '1em': '1.75em'}} type="button" data-bs-toggle="collapse" data-bs-target={'#' + props.id} aria-expanded="false" aria-controls={props.id} onClick={()=>setIsOpen(!isOpen)}></i>
    </>
}