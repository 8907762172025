import {useContext, useEffect, useRef, useState} from "react";
import {WindowSizeContext} from "../context/WindowSizeContext";

export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}


export function useHasScrolledDownDetector(positionTrigger = 100) {
    const [scrolling, setScrolling] = useState(false);
    const [prevScrollPos, setPrevScroll] = useState(0);
    const windowSize = useContext(WindowSizeContext);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;

            // Set scrolling state based on scroll position and duration
            if (currentScrollPos <= positionTrigger) {
                setScrolling(false);
            } else {
                setScrolling(true);
            }

            setPrevScroll(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // FIXME: prevScrollPos and windowSize.isOnMobile are not used in the function
        // according to original author, these are needed for functionality
        // but it is not clear how they are used, and if they are actually needed
    }, [prevScrollPos, windowSize.isOnMobile, positionTrigger]);

    return scrolling;
}
