import React, { useContext, useState } from 'react';
import { WindowSizeContext } from "../../../common/context/WindowSizeContext";
import TrendIndicator from "./CustomComponents/trendIndicator";
import { Grid, Button, MobileStepper } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function TopIndicators({ setDateFilter, dateFilter, salesApiEndPointName, apiDefaultFilter }) {
    const windowSize = useContext(WindowSizeContext);
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % indicators.length);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + indicators.length) % indicators.length);
    };

    const indicators = [
        {
            component: <TrendIndicator
                key="TotalSales"
                setDateFilter={setDateFilter}
                dateFilter={dateFilter}
                indicatorName={"Total Sales"}
                salesApiEndPointName={salesApiEndPointName}
                apiDefaultFilter={apiDefaultFilter}
                isOnMapView={true}
            />,
            label: 'Total Sales',
        },
        {
            component: <TrendIndicator
                key="TotalTransactions"
                setDateFilter={setDateFilter}
                dateFilter={dateFilter}
                aggregate={'total_transactions'}
                salesApiEndPointName={salesApiEndPointName}
                apiDefaultFilter={apiDefaultFilter}
                indicatorName={"Total Transactions"}
                isOnMapView={true}
            />,
            label: 'Total Transactions',
        },
        {
            component: <TrendIndicator
                key="ATV"
                setDateFilter={setDateFilter}
                dateFilter={dateFilter}
                aggregate={'atv'}
                salesApiEndPointName={salesApiEndPointName}
                apiDefaultFilter={apiDefaultFilter}
                indicatorName={"ATV"}
                isOnMapView={true}
            />,
            label: 'ATV',
        },
    ];

    return (
        <Grid>
            <Grid container direction="row">
                {indicators[activeStep].component}
            </Grid>
            {windowSize.isOnMobile && (
                <Grid>
                    <MobileStepper
                        variant="dots"
                        steps={indicators.length}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" onClick={handleNext}>
                                Next
                                <ArrowForwardIosIcon />
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack}>
                                <ArrowBackIosIcon />
                                Back
                            </Button>
                        }
                    />
                </Grid>
            )}
        </Grid>
    );
}
