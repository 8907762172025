import { useMemo, useRef, useState, useContext } from 'react';
// import Modal from 'react-modal';
import { DEFAULT_CHART_DATA } from '../constants';
import GraphFactory from "../../../../common/components/GraphFactory";
import { useSaveChart } from '../../../../common/API/hooks';
import { useToPng } from '@hugocxl/react-to-image'
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { useDebounce } from "use-debounce";
import ExtraDataEditor from "./ExtraDataEditor";
import DataSourceEditor from "./DataSourceEditor";
import ChartConfigEditor from "./ChartConfigEditor";
import BasicDetailEditor from "./BasicDetailEditor";
import ChartPlaceholder from './ChartPlaceholder';
import { InsightParamsContext } from '../../../../common/context/InsightParamsContext';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import Fab from "@mui/material/Fab";


export default function EditChartModal({ isCreateNewChart, chartObjValues, tableId, onCreate }) {

    const chartRef = useRef(null)
    const [modalIsOpen, setIsOpen] = useState(false);
    const newChartData = JSON.parse(JSON.stringify(DEFAULT_CHART_DATA));  // clone the default chart data
    const [insightParams, setInsightParams] = useState({})
    const [savedInsight, setSavedInsight] = useState('')
    if (tableId) {
        newChartData.table_source = tableId;
    }

    const closeModal = () => {
        setIsOpen(!modalIsOpen)
    }

    const [chartData, setChartData] = useState(isCreateNewChart ? newChartData : chartObjValues)
    const { saveChartAsync, isLoading } = useSaveChart(chartObjValues?.id)

    const [debouncedChartData] = useDebounce(chartData, 1000);

    const onSavePreview = (previewUrl) => {
        const newChartData = {
            ...chartData,
            image_url: previewUrl,
            extra_data: {
                ...chartData.extra_data,
                savedInsight: savedInsight,
            },
        };

        const savePromise = saveChartAsync(newChartData).then((newData) => {
            closeModal();
            if (isCreateNewChart && onCreate) {
                onCreate(newData);
            }

            // You can also update the state with the newChartData if needed
            setChartData(newChartData);
        });

        toast.promise(savePromise, {
            pending: 'Saving Chart...',
            success: 'Chart Saved Successfully',
            error: 'Saving chart failed'
        });

    }
    const [_, convert, __] = useToPng({
        selector: '.table-container .table-content',
        pixelRatio: .7,
        onSuccess: data => {
            onSavePreview(data); // Work around fix for now,.
        }
    });

    // Modal.setAppElement('#root');

    function onInputChange(field, value) {
        setChartData({
            ...chartData,
            [field]: value
        })
    }

    const buttonToDisplay = useMemo(() => {
        if (isCreateNewChart) {
            return <Fab size="medium" color="primary">
                <AddIcon />
            </Fab>

        } else if (chartData?.is_editable) {
            return <EditNoteSharpIcon
                data-tooltip-id="info_tooltip"
                data-tooltip-place="top"
                data-tooltip-variant="info"
                data-tooltip-content={'Edit Chart'}
                fontSize="small" sx={{ color: "primary.main" }} />

        } else if (chartData?.is_public) {
            return <EditNoteSharpIcon
                data-tooltip-id="info_tooltip"
                data-tooltip-place="top"
                data-tooltip-variant="info"
                data-tooltip-content={'Edit Chart'}
                fontSize="small" sx={{ color: "primary.main" }} />
        }
    }, [isCreateNewChart, chartData?.is_editable])

    const saveWithPreview = () => {
        let base64Str;
        if (chartRef && chartRef.current instanceof HTMLDivElement) { // Get image url of table
            convert(chartRef.current);
        } else if (chartRef) {
            const echartInstance = chartRef.current.getEchartsInstance();
            base64Str = echartInstance.getDataURL({
                pixelRatio: 0.7, // TODO: SMALLER IMAGE
            });
            onSavePreview(base64Str)
        }
    };

    if (!buttonToDisplay) {
        return null
    }

    let IsExtraDataRequiredFieldsEmpty
    if (debouncedChartData?.chart_type === 'table') {
        IsExtraDataRequiredFieldsEmpty = debouncedChartData?.extra_data?.columns?.length === 0
    } else if (debouncedChartData?.chart_type === 'indicator') {
        IsExtraDataRequiredFieldsEmpty = !debouncedChartData?.extra_data?.value
    } else if (debouncedChartData?.chart_type === 'scatter') {
        IsExtraDataRequiredFieldsEmpty = !debouncedChartData?.extra_data?.x_axis || !debouncedChartData?.extra_data?.y_axis || !debouncedChartData?.extra_data?.label
    } else {
        IsExtraDataRequiredFieldsEmpty = !debouncedChartData?.extra_data?.label || !debouncedChartData?.extra_data?.value
    }
    const isRequiredFieldsEmpty = !debouncedChartData?.title || !debouncedChartData?.chart_type || !debouncedChartData?.table_source || IsExtraDataRequiredFieldsEmpty
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        width: "80%",
        height: "80%",
        overflowY: "hidden",
        borderRadius: 3

    };
    return (
        <>
            <InsightParamsContext.Provider value={{ insightParams, setInsightParams }}>
                <IconButton variant="contained" sx={{ color: isCreateNewChart && "#fff" }} onClick={() => {
                    setIsOpen(!modalIsOpen);
                    if (isCreateNewChart) {
                        setChartData(newChartData)
                    }
                }}>
                    {buttonToDisplay}
                </IconButton>
                <Modal
                    open={modalIsOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid container direction="row" height={"100%"} sx={{ borderRadius: 4 }}>
                            <Grid item md={6} paddingX={1.5} paddingBottom={1.5}
                                sx={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: "100%" }}>
                                <BasicDetailEditor
                                    chartData={chartData}
                                    setChartData={setChartData}
                                    onInputChange={onInputChange}
                                />
                                <Box>
                                    <DataSourceEditor
                                        tableId={tableId}
                                        chartData={chartData}
                                        setChartData={setChartData}
                                        onInputChange={onInputChange}
                                    />
                                    <ChartConfigEditor
                                        chartData={chartData}
                                        setChartData={setChartData}
                                    />
                                    <ExtraDataEditor
                                        extraData={chartData.extra_data}
                                        setExtraData={(newExtraData) => {
                                            setChartData(prevState => {
                                                return {
                                                    ...prevState,
                                                    extra_data: newExtraData
                                                }
                                            })
                                        }}
                                    />
                                </Box>

                            </Grid>
                            <Grid item md={6} paddingBottom={1.5} marginTop={3}
                                sx={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: "90%" }}>
                                <Grid alignContent="center" alignItems="center">
                                    <Typography variant="h5"
                                        sx={{ fontWeight: "bold", color: "grey" }}
                                        align="center"> Preview</Typography>
                                    {
                                        isRequiredFieldsEmpty ? <ChartPlaceholder /> :
                                            <Grid sx={{ mt: 5 }}>
                                                <GraphFactory
                                                    setSavedInsight={setSavedInsight}
                                                    chartRef={chartRef}
                                                    {...debouncedChartData}
                                                />
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Button variant="contained" size="small" sx={{ position: 'fixed', bottom: 20, right: 75 }} onClick={() => { saveWithPreview(); }} disabled={isLoading || isRequiredFieldsEmpty}>
                            <SaveAltOutlinedIcon fontSize="small" sx={{ mr: .5 }} /> {isLoading ? 'Saving ...' : 'Save'}
                        </Button>
                        <IconButton aria-label="close" onClick={() => { setIsOpen(!modalIsOpen) }} sx={{ position: 'fixed', top: 5, right: 5 }}>
                            < CloseIcon />
                        </IconButton>
                    </Box>
                </Modal>
            </InsightParamsContext.Provider>
        </>
    )
}