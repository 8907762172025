import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
import {  ScatterChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
} from 'echarts/components';
import {  SVGRenderer } from 'echarts/renderers';

export default function ScatterPlot() {
    echarts.use(
		[TitleComponent, TooltipComponent, GridComponent, ScatterChart, SVGRenderer, DatasetComponent]
	);

    const data = [
        [675, 9500, 27624, 'Champions'],
        [500, 5500, 27602, 'Loyal'],
        [75, 2000, 11563, 'Potential Loyalist'],
        [275, 7500, 10522, 'Promising'],
        [100, 3500, 49670, 'New Customer'],
        [200, 2000, 56999, 'Need Attention'],
        [50, 1500, 28300, 'Cannot Lose Them'],
        [175, 5500, 25830, 'About to Sleep'],
        [250, 1900, 34777, 'At Risk'],
        [20, 200, 12295, 'Hibernating'],
        [550, 4000, 20954, 'Lost'],
        
        [100, 5550, 27602, 'Champions'],
        [10, 2750, 27624, 'Loyal'],
        [55, 750, 20954, 'Potential Loyalist'],
        [75, 7500, 34777, 'Promising'],
        [100, 1750, 28300, 'New Customer'],
        [100, 1000, 16999, 'Need Attention'],
        [50, 1500, 49670, 'Cannot Lose Them'],
        [175, 500, 25830, 'About to Sleep'],
        [50, 2900, 20522, 'At Risk'],
        [20, 200, 12295, 'Hibernating'],
        [400, 70, 11563, 'Lost'],
        
        [500, 1250, 27602, 'Champions'],
        [450, 2750, 27624, 'Loyal'],
        [300, 750, 20954, 'Potential Loyalist'],
        [175, 2500, 34777, 'Promising'],
        [80, 750, 28300, 'New Customer'],
        [150, 1000, 18999, 'Need Attention'],
        [175, 1500, 42670, 'Cannot Lose Them'],
        [120, 500, 25830, 'About to Sleep'],
        [500, 2900, 20522, 'At Risk'],
        [200, 1000, 12295, 'Hibernating'],
        [230, 70, 11563, 'Lost'],
    ];
    
    const titleColors =  {
        'Champions': '#2EB62C' , 
        'Loyal': '#57C84D', 
        'Potential Loyalist': '#83D475', 
        'Promising': '#ABE098', 
        'New Customer' : '#DADEDF',
        'Need Attention': '#E97452',
        'Cannot Lose Them': '#D65D42', 
        'About to Sleep': '#C34632',
        'At Risk': '#B12E21',
        'Hibernating' : '#9E1711',
        'Lost' : '#8B0001' 
    }

    const scatterplot_option = {
		xAxis: {
			name: 'Cohort Average Recency',
			nameGap: 40,
			nameLocation: 'middle',
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
        },
        yAxis: {			
			name: 'Cohort Average Monetary',
			nameGap: 45,
			nameLocation: 'middle',
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
          },
          series: [
            {
              data: data,
              type: 'scatter',
              symbolSize: function (data) {
                return data[2] * .001;
              },
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return param.data[3] + ' : ' + param.data[2].toLocaleString();
                  },
                  position: 'top'
                }
              },
              itemStyle: {
                color: function(param) {
                  const title = param.data[3]
                     return titleColors[title];
                }
              }
            }
          ]
	}
    

    return <>
			<ReactEChartsCore
				echarts={echarts}
				option={scatterplot_option}
				notMerge={true}
				lazyUpdate={true}
				opts={{renderer: 'svg'}}
				style={{height: "40rem", minWidth: "100%"}}

			/>
    </>
}