import React from "react";
import {useOrganizationOptions} from "../hooks";
import ContextBasedDropdownSelector from "../../common/presentation/selectors/ContextBasedDropdownSelector";

export default function OrgranizationSelector() {
    const { data: options, isLoading } = useOrganizationOptions()

    return (
        <ContextBasedDropdownSelector
        isLoading={isLoading}
        autoSelectFirstOption={false}
        options={options}
        label={"Organization"}
        contextKey={"organization"}
        defaultValue={"SM Store"}
        />
    )
}