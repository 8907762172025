import React from 'react';
import { useSelectorValue } from "../../../common/providers/selectorProvider";
import PivotTable from "./PivotTable";
import MetricOverTimeTable from "./MetricOverTimeTable";


export default function StorePerformanceTable({ useComparativeTable, ...props }) {

    if (useComparativeTable) {
        return <PivotTable {...props} />

    }

    return <MetricOverTimeTable {...props} />
}