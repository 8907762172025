import React, { useContext, useMemo } from 'react';
import FilterGroup from "../Filters/FilterGroup";
import { Grid, Box, Typography } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import TableComponentV2 from '../../../../common/components/TableComponentV2';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { useSalesPerformanceData } from '../hooks';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { convertToValidNumber, sortArrayBasedOnGivenArray } from '../../../../common/utils';
import BarIndicator from '../../common/presentation/barIndicator';
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import ToggleSelector from '../../common/presentation/selectors/toggleSelector';
import { PERIOD_OPTIONS } from '../constants';

const IndicatorCellBox = (props) => (
    <Box sx={{ my: 1, width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);

const chooseGroup = {
    'mall_type': 'mallType',
    'store_size': 'storeSize',
    'mall_category': 'mallCategory',
    'region': 'region',
    'product_group': 'productGroup'
}

export default function SalesPerformancePerGroup({ params, filters, onDropDownFilterChange, selectedRows, onSelectRow,
    period, onToggleChange }) {

    let absoluteHeaderLabel = '';
    let orderArray;
    const windowSize = useContext(WindowSizeContext);
    switch (chooseGroup[filters?.group]) {
        case 'productGroup':
            orderArray = ['Fresh', 'Food1', 'Food2', 'Nonfood', 'Drygoods', 'MBU'];
            absoluteHeaderLabel = "Category"
            break;
        case 'region':
            orderArray = ['NCR', 'NORTH LUZON', 'SOUTH LUZON', 'VISAYAS', 'NORTH EAST', 'MINDANAO'];
            absoluteHeaderLabel = "Region"
            break;
        case 'storeSize':
            orderArray = ['EXTRA SMALL', 'SMALL', 'MEDIUM', 'LARGE'];
            absoluteHeaderLabel = "Store Size"
            break;
        case 'mallType':
            absoluteHeaderLabel = "Store Type"
            break;
        case 'mallCategory':
            absoluteHeaderLabel = "Store Subtype"
            break;
        default:
            orderArray = [];
            break
    }

    const isMTD = period === 'mtd';
    const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] }
    const { data: performancePerGroupData, isLoading: isLoadingPerformancePerGroup } = useSalesPerformanceData({
        params: {
            ...paramsBasedOnPeriod,
            group_by: filters?.group
        }
    });
    const isProductGroup = filters?.group === 'product_group';
    const PRODUCT_CAT_COLS = ['888', '888 (Outright)', '4k']

    const performance_per_group_columns = useMemo(
        () => [
            {
                header: absoluteHeaderLabel,
                accessorKey: filters?.group,
                cell: (row) => <Box sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                    <VisibilityIcon sx={{ marginRight: '.5rem' }} fontSize='small' color={selectedRows?.includes(row?.row?.original?.[filters?.group]) ? 'primary' : 'disabled'} />
                    {isProductGroup && PRODUCT_CAT_COLS.includes(row?.getValue()) ? 'Top ' + row?.getValue() : row?.getValue()}
                </Box>,
                customStyle: { width: '15rem' }
            },
            {
                header: isMTD ? 'MTD' : 'YTD',
                accessorKey: 'actual_sales',
                cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /></Box>,
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                header: '% cont.',
                accessorKey: 'percent_contribution',
                cell: (row) => <Box sx={{ width: { sm: '50%' }, marginX: 'auto' }}>
                    <NumericValueDisplay value={row?.getValue()} postfix={'%'} />
                </Box>,
                customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: isMTD ? 'MTD Target' : 'YTD Target',
                accessorKey: 'target_sales',
                cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}>
                    <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} />
                </Box>,
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                header: 'vs Running Daily Target',
                accessorFn: (row) => (row?.actual_sales / row?.daily_target_sales) * 100,
                cell: (row) => {
                    if (row.row.original.daily_target_sales === undefined) {
                        return '-'
                    }
                    return <IndicatorCellBox>
                        <BarIndicator
                            colorFunctionFor={'sales'}
                            value={convertToValidNumber(row?.getValue())?.toFixed(1)}
                            isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                    </IndicatorCellBox>
                },
                customStyle: { width: '15rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: isMTD ? 'Last Year MTD' : 'Last Year YTD',
                accessorKey: 'last_year_sales',
                cell: (row) => <Box sx={{ width: { sm: '50%', xs: '4rem' }, marginX: 'auto' }}> <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={windowSize?.isOnMobile} /> </Box>,
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                header: 'vs Last Year',
                accessorFn: (row) => (row?.actual_sales / row?.last_year_sales) * 100,
                cell: (row) => <IndicatorCellBox>
                    <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                </IndicatorCellBox>,
                customStyle: { width: '15rem' }
            },
        ],
        [filters?.group, isProductGroup, selectedRows, period]
    )

    const TopSalesTableData = isProductGroup && performancePerGroupData?.filter(val => PRODUCT_CAT_COLS.includes(val['product_group'])) // 888 and 4k data
    const filteredData = isProductGroup ? performancePerGroupData?.filter(val => !PRODUCT_CAT_COLS.includes(val['product_group'])) : performancePerGroupData // remove 888 and 4k when on product group
    const noNullRowData = filteredData?.filter(val => val[filters?.group] !== 'null' && val[filters?.group] !== null)
    const newOrderArray = isProductGroup ? ['888', 'Top 888', '888 (Outright)', 'Top 888 (Outright)' ,'4k', 'Top 4k', ...orderArray] : orderArray

    if (isLoadingPerformancePerGroup) {
        return <LoadingComponent />
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container alignItems="center" py={.5}>
                    {
                        windowSize.isOnMobile ? <>
                            <Grid item xs sm={2.5}>
                                <Typography variant="h6" ml={2.5}>
                                    Performance per
                                </Typography>
                            </Grid>
                            <Grid item xs display="flex" justifyContent={'end'} paddingY={windowSize?.isOnMobile && .75}>
                                <ToggleSelector
                                    options={PERIOD_OPTIONS}
                                    value={period}
                                    onChange={onToggleChange}/>
                            </Grid>
                            <FilterGroup
                                onDropdownChange={onDropDownFilterChange}
                                filters={filters} />
                        </> : <>
                            <FilterGroup
                                onDropdownChange={onDropDownFilterChange}
                                filters={filters} />
                            <Grid item sm={3} xs={12} display="flex" justifyContent={'end'} paddingY={windowSize?.isOnMobile && .75}>
                                <ToggleSelector
                                    options={PERIOD_OPTIONS}
                                    value={period}
                                    onChange={onToggleChange}/>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={12} mt={0}>
                    {
                        isProductGroup && <TableComponentV2
                            columns={performance_per_group_columns}
                            data={sortArrayBasedOnGivenArray(TopSalesTableData, newOrderArray, filters?.group)}
                            tableId={'salesPerformancePerGroupTopTable'}
                            enableRowSelection={true}
                            onSelectRow={onSelectRow}
                            isCompact={true}
                            customTableHeaderStyle={{
                                backgroundColor: '#caddeb'
                            }}
                            customTableContainerStyle={{
                                borderRadius: "0",
                                boxShadow: 'none',
                            }} />
                    }
                </Grid>
                <Grid item xs={12} sm={12} mt={isProductGroup ? .75 : 0}>
                    <TableComponentV2
                        columns={performance_per_group_columns}
                        data={sortArrayBasedOnGivenArray(noNullRowData, newOrderArray, filters?.group)}
                        tableId={'salesPerformancePerGroupTable'}
                        enableRowSelection={true}
                        onSelectRow={onSelectRow}
                        isCompact={true}
                        customTableHeaderStyle={{
                            backgroundColor: '#caddeb'
                        }} />
                </Grid>
            </Grid>
        </>
    );
}
