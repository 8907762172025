import React, {useEffect, useState} from 'react'
import Operations from './Operations'
import Merchandise from './Merchandise'
import CustomerWrapper from "./CustomerWrapper";
import SelectorProvider, {useSelectorValue} from "../../providers/selectorProvider";
import FiltersProvider from "./Provider/filtersProvider";
import moment from "moment/moment";
import {useParams} from "react-router-dom";
import {useTogglePeriodContext} from "./Provider/context";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { useAvailableDateRangeL3 } from '../../../TmpTSSSales/DataProvider/hooks';


export default function RenderBUViewContent() {
    // TODO: can we move this a router?
    const { business_unit, month, year, period: periodFromParams } = useParams()
    const {period, updatePeriod} = useTogglePeriodContext()
    const viewBy = useSelectorValue('mainBUViewBy')

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            updatePeriod(val);
        }
    };

    const { data: dateRangeV2, isLoading: dateRangeIsLoading } = useAvailableDateRangeL3()
    const dateRange = (dateRangeV2 && !dateRangeIsLoading) ? {
        from: dateRangeV2.min,
        to: dateRangeV2.max
    } : {};
    const date = new Date(dateRange?.to)
    const params = {
        month: Number(month) ? [Number(month)] : [date?.getMonth() + 1],
        year: Number(year) ? Number(year) : date?.getFullYear(),
    }

    const [filters, setFilters] = useState({
        group: "channel",
        month: params?.month,
        year: params?.year,
        business_unit: `${business_unit}`,
        product_category: '',
        metric_column: 'actual_sales'
    })

    function onDropdownFilterChange(value, field) {
        setFilters({
            ...filters,
            [field]: value
        })
    }

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            month: params?.month,
            year: params?.year,
        }))
    }, [dateRangeIsLoading])

    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';

    const paramsBasedOnPeriod = isQoQ
        ? {
            ...params,
            month: Array.from({ length: 3 }, (_, i) =>
                moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month() + i + 1
            )
        }
        : isMTD ? { ...params } : { ...params, month: [1, ...(params?.month || [])] };


    if (isNaN(filters?.month[0])) {
        return <LoadingComponent/>
    }

    switch (viewBy) {
        case 'customer':
            return <CustomerWrapper/>
        case 'merchandise':
            return <SelectorProvider>
                <FiltersProvider>
                    <Merchandise
                        params={paramsBasedOnPeriod}
                        business_unit={business_unit} filters={filters}
                        onDropdownFilterChange={onDropdownFilterChange}/>
                </FiltersProvider>
            </SelectorProvider>
        // return <MerchandiseDetailed isBranchView={true} params={params} period={period} storeID={storeID} />
        case 'store':
            return 'STORE HERE'
        case 'operations':
            return <Operations
                params={paramsBasedOnPeriod} business_unit={business_unit}
                onTogglePeriodChange={onTogglePeriodChange}/>
        default:
            return <LoadingComponent/>
    }
}