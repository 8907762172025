export const OPEX_TABLE = "l4_frg_opex";
export const LOOKUP_TABLE = "l2_frg_branch"

export const OPERATION_TYPE_TOTAL_CONTROLLABLE = 'Total Controllable Expense'
export const OPERATION_TYPE_SALARIES_AND_WAGES = 'Salaries & Wages'
export const OPERATION_TYPE_LIGHT_AND_WATER = 'Light & Water'
export const OPERATION_TYPE_REPAIR_AND_MAINTENANCE = 'Repairs & Maintenance'
export const OPERATION_TYPE_OTHER_CONTROLLABLE = 'Other Controllable Expense'
export const OPERATION_TYPE_TOTAL_NON_CONTROLLABLE = 'Total Non-Controllable Expense'
export const OPERATION_TYPE_OTHER_NON_CONTROLLABLE = 'Other Non-Controllable Expense'
export const OPERATION_TYPE_LOSSES_AND_SHRINKAGE = 'Losses & Shrinkages'
export const OPERATION_TYPE_TOTAL_OPERATING_EXPENSES = 'Total Operating Expense'
export const OPERATION_TYPE_RENT = 'Rent'
export const OPERATION_TYPE_SECURITY = 'Security'

export const CONTROLLABLE_OPERATION_TYPES = [
    OPERATION_TYPE_SALARIES_AND_WAGES,
    OPERATION_TYPE_LIGHT_AND_WATER,
    OPERATION_TYPE_SECURITY,
    OPERATION_TYPE_REPAIR_AND_MAINTENANCE,
    OPERATION_TYPE_OTHER_CONTROLLABLE
]
export const NON_CONTROLLABLE_OPERATION_TYPES = [
    OPERATION_TYPE_RENT,
    OPERATION_TYPE_OTHER_NON_CONTROLLABLE
]

export const TOTAL_OPEX_OPERATION_TYPES = [
    ...CONTROLLABLE_OPERATION_TYPES,
    ...NON_CONTROLLABLE_OPERATION_TYPES
]

export const OPERATION_TYPE_SYNONYMS = {
    'Other Non-Controllable': OPERATION_TYPE_OTHER_NON_CONTROLLABLE,
    'Other Non Controllable': OPERATION_TYPE_OTHER_NON_CONTROLLABLE,
    'Other Controllable': OPERATION_TYPE_OTHER_CONTROLLABLE
}

export const PERCENT_TO_SALES_MULTIPLIER = 0.91