import SalesPerformancePerGroup from './PerGroupPerformance';
import {useContext, useEffect, useState} from 'react';
import {WindowSizeContext} from '../../../common/context/WindowSizeContext';
import FilterMonth from '../../../common/components/FilterMonth'
import FilterYear from '../../../common/components/FilterYear';
import AsOfDate from './Filters/AsOfDate';
import {TABLE_NAME, useAvailableDateRange} from "./hooks";
import {Box, GlobalStyles, Grid, Typography} from '@mui/material';
import Back from '../../../common/components/BackNavigateComponent';
import StorePerformance from './StorePerformance';
import FilterCompany from "./Filters/FilterCompany";
import TopLineDataProvider from "./DataProvider/topLineDataProvider";
import TopLine from "../common/presentation/TopLine";


export default function TmpAceSales() {

    const windowSize = useContext(WindowSizeContext);
    const [filters, setFilters] = useState({
        // company_id: [2, 7, 142],
        group: "store_format",
        // transactionDates: [null,null],
        month: null,
        year: null
    })
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        //reset selectrow only when filter group change
        setSelectedRows([])
    }, [filters.group])

    const { data: dateRangeV2, dateRangeIsLoading, error, isSuccess } = useAvailableDateRange()

    const dateRange = (dateRangeV2 && !dateRangeIsLoading) ? {
        from: dateRangeV2.min,
        to: dateRangeV2.max
    } : {};

    const params = {
        company_id: filters?.company_id,
        month: filters?.month,
        year: filters?.year,
        // transaction_date__gte: filters.transactionDates[0] ? moment(filters.transactionDates[0]).format('YYYY-MM-DD'): filters.transactionDates[0],
        // transaction_date__lte: filters.transactionDates[1] ? moment(filters.transactionDates[1]).format('YYYY-MM-DD'): filters.transactionDates[1]
    }

    const [period, setPeriod] = useState('mtd');

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setPeriod(val);
        }
    };

    function onDropdownFilterChange(value, field) {
        setFilters({
            ...filters,
            [field]: value
        })
    }

    function onSelectRow(row) {
        const isProductGroup = filters?.group === 'product_group';
        const PRODUCT_CAT_COLS = ['888', '4k']
        const isSelectedTopSale = PRODUCT_CAT_COLS?.includes(row[filters?.group])
        if (selectedRows?.includes(row[filters?.group])) {
            //unselecting of row
            setSelectedRows(selectedRows?.filter(val => val !== row[filters?.group]))
        } else {
            if (isProductGroup) {
                if (isSelectedTopSale) {
                    setSelectedRows([...(selectedRows?.filter(row => PRODUCT_CAT_COLS?.includes(row)) || []), row[filters?.group]])
                } else {
                    setSelectedRows([...(selectedRows?.filter(row => !PRODUCT_CAT_COLS?.includes(row)) || []), row[filters?.group]])
                }
            } else {
                setSelectedRows([...(selectedRows || []), row[filters?.group]])
            }
        }
    }

    return <>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />
        <Grid container>
            <Grid item xs={12} marginY={1} display="flex">
                <Back />
                <Typography variant='h5' ml={3} > Ace Hardware Sales Performance</Typography>
            </Grid>

            <Grid container sx={{ position: `${!windowSize.isOnMobile ? 'sticky' : ''}`, top: `${!windowSize.isOnMobile ? '5rem' : ''}`, zIndex: 5, backgroundColor: 'transparent' }}>
                <Grid item xs={12} marginBottom={2}>
                    <Box flexGrow={1} xs={12} paddingX={1} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem" }}>
                        <Grid container columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} paddingX={windowSize.isOnMobile && 1}>
                            {
                                windowSize.isOnMobile && <Grid item xs={12}>
                                    <AsOfDate />
                                </Grid>
                            }
                            <Grid item xs={12} sm={5}>
                                <FilterCompany
                                    tableName={TABLE_NAME}
                                    filters={filters}
                                    onPillSelectChange={onDropdownFilterChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <FilterMonth
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={isSuccess} dateRangeIsLoading={dateRangeIsLoading} />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <FilterYear
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={isSuccess} dateRangeIsLoading={dateRangeIsLoading} />
                            </Grid>
                            {
                                !windowSize.isOnMobile && <Grid item sm={4} alignItems="center" display="flex">
                                    <AsOfDate />
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TopLineDataProvider params={params}>
                    <TopLine textMap={{title: "Topline Performance"}} />
                </TopLineDataProvider>
            </Grid>
            {/*<Grid item xs={12}  marginY={2}>*/}
            {/*    <SalesOverallPerformance filters={filters} params={params} period={period} onToggleChange={onTogglePeriodChange}  />*/}
            {/*</Grid>   */}
            <Grid item xs={12}>
                <SalesPerformancePerGroup filters={filters} params={params} onDropDownFilterChange={onDropdownFilterChange} onSelectRow={onSelectRow} selectedRows={selectedRows}
                    period={period} onToggleChange={onTogglePeriodChange}
                />
            </Grid>
            <Grid item xs={12} >
                <StorePerformance filters={filters} params={params} onDropDownFilterChange={onDropdownFilterChange} onSelectRow={onSelectRow} selectedRows={selectedRows}
                    period={period} onToggleChange={onTogglePeriodChange}
                />
            </Grid>
        </Grid>
    </>
}