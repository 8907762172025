import React, { useState } from 'react';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { useQueryClient } from 'react-query';
import { useTestConnect, useSave } from '../utils';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import  Typography from '@mui/material/Typography' 
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

export default function DatabricksConfig({ closeModal, dataSourceHostName, setIsSubmitted }) {

    const host_type = "Databricks";
    const [host, setHost] = useState('');
    const [httpPath, setHttpPath] = useState('');
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();
    // Custom hooks
    const { testConnect: testConnection } = useTestConnect()
    const { saveHost: saveHosts } = useSave()


    // Callback function that uses useTestConnect custom hook
    const handleTestConnect = () => {
        const data = {
            host_type: host_type,
            server_hostname: host,
            http_path: httpPath,
            access_token: token,
        };
        setIsSubmitted(true);
        setIsLoading(true);
        testConnection(data).finally((data) => {
            setIsLoading(false);
            setIsSubmitted(false);
        });
    }
    // Callback function that uses useSave custom hook
    const handleSave = () => {
        const connectionConfig = {
            server_hostname: host,
            http_path: httpPath,
            access_token: token,
        };

        const hostData = {
            name: dataSourceHostName,
            host_type: host_type,
            connection_config: connectionConfig,
            is_active: true,
        };
        setIsSubmitted(true);
        setIsLoading(true);
        saveHosts(hostData).then(() => { // calls the useSave custom hook
                closeModal();
                setIsSubmitted(false);
                setIsLoading(false);
                return toast.success('Data source host saved!');
            })
            .catch(error => {
                console.error(error);
                return toast.error("Save host failed!"); 
            });
    }

    const isSubmitDisabled = !host || !httpPath || !dataSourceHostName || !token;

    function RenderContent() {
        return (
            <>
                <Typography variant="h6" sx={{ml:3, mt:2, fontWeight:'bold'}}>Connection Configuration</Typography>

                <Grid item md={12} sx={{ml:6, mt:1}}>
                    <Stack direction={'column'}>
                        <Typography variant="subtitle" sx={{fontWeight:'bold',color:'#878787'}}>Server Host Name</Typography>

                        <TextField
                            id="pathInput"
                            size={'small'} 
                            placeholder="Enter Host Name" 
                            value={host}
                            onChange={(e) => setHost(e.target.value)}
                            sx={{width:'95%', mt:1}}
                        />
                    </Stack>
                </Grid>

                <Grid item md={12} sx={{ml:6, mt:1}}>
                    <Stack direction={'column'}>
                        <Typography variant="subtitle" sx={{ fontWeight:'bold',color:'#878787'}}>Http Path:</Typography>

                        <TextField
                           id="pathInput"
                           size={'small'} 
                           placeholder="Enter Path" 
                           value={httpPath}
                           onChange={(e) => setHttpPath(e.target.value)}
                           sx={{width:'95%', mt:1}}
                        />
                    </Stack>
                </Grid>

                <Grid item md={12} sx={{ml:6, mt:1}}>
                    <Stack direction={'column'}>
                        <Typography variant="subtitle" sx={{ fontWeight:'bold',color:'#878787'}}>Access Token:</Typography>

                        <TextField
                           id="tokenInput"
                           size={'small'} 
                           placeholder="Enter Token" 
                           value={token}
                           onChange={(e) => setToken(e.target.value)}
                           sx={{width:'95%', mt:1}}
                        />
                    </Stack>
                </Grid>

                <Grid container justifyContent={'flex-end'} sx={{mt:2, pr:4.5}}>
                    <Button 
                    variant="contained" 
                    size='small'
                    disabled={isSubmitDisabled}
                    onClick={handleTestConnect}
                    sx={{borderRadius:'5px',px:1.6, textTransform:'none', backgroundColor:'#66e472', fontWeight:'bold'
                    ,'&:hover': {
                        backgroundColor:`${!isSubmitDisabled ? '#66e472' : ''}`,
                        }}}> 
                    Test Connect</Button>
                </Grid>

                <Grid container justifyContent={'flex-end'} sx={{mt:2, pr:4.5}}>
                    <Button 
                        variant="contained" 
                        size='small'
                        disabled={isSubmitDisabled}
                        onClick={handleSave}
                        sx={{borderRadius:'5px', mr:2,px:5, textTransform:'none', backgroundColor:'blue',fontWeight:'bold'
                        ,'&:hover': {
                            backgroundColor: `${!isSubmitDisabled ? 'blue' : ''}`,
                            }}}
                        color="success"> 
                        Save
                    </Button>

                    <Button 
                        variant="outlined" 
                        size='small'
                        onClick={closeModal}
                        sx={{borderRadius:'5px' , textTransform:'none',color:'blue', border:'solid 1px blue', fontWeight:'bold', px:4,
                            '&:hover': {
                            border:'solid 1px blue',
                            backgroundColor:'#fcfcfds'
                          }}}
                        > 
                        Cancel
                    </Button>
                </Grid>
            </>
        )

    }
    return (
        <>
            {isLoading ? <LoadingComponent /> : RenderContent()}
        </>
    )
}