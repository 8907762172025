import React from "react";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import ApiClient from "../../../common/API";
import { useQueryClient } from "react-query";
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import handleError from "../ErrorHandling"

export default function DeleteModule({ module, isOpen, setsetIsOpen }) {
    const queryClient = useQueryClient();
    const openModal = () => {
        setsetIsOpen(true);
    };

    const closeModal = () => {
        setsetIsOpen(false);
    };

    const handleDelete = () => {
        ApiClient().delete(`module/${module.row.id}/`)
            .then(() => {
                queryClient.invalidateQueries(['module'])
                closeModal();
                return toast.success('Module deleted!');
            })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "Module", "delete");
                return toast.error(errorMessage);
            });
    };

    return (
        <>
            {isOpen && (
                <ConfirmationModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    onConfirm={handleDelete}
                    icon={<DeleteIcon sx={{ fontSize: '60px' }} color="error" />}
                    title="Delete Module"
                    message="Are you sure you want to delete this module?"
                />
            )}
        </>
    )
}