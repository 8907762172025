import React from 'react'
import MerchandiseDetailed from '../../../merchandise/presentation/detailed'
import Operations from './OperationsBranchView';
import LocationsAnalysis from './LocationsComponents';
import Customer from './Customer';


export default function RenderBranchViewContent({ viewBy, params, period, store, storeID, onTogglePeriodChange, filters, onDropDownFilterChange }) {


    switch (viewBy) {
        case 'merchandise':
            return <MerchandiseDetailed isBranchView={true} filters={filters} params={params} period={period} storeID={storeID} onDropdownChange={onDropDownFilterChange} />
        case 'customer':
            return <Customer filters={filters} params={params} togglePeriodValue={period} onTogglePeriodChange={onTogglePeriodChange} />
        case 'location':
            return (
                <LocationsAnalysis store={store} params={params} storeID={storeID} />
            )
        case 'promotions':
            return 'PROMOTIONS HERE'
        default:
            return <Operations params={params} period={period} store={store} storeID={storeID} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropDownFilterChange} />

    }
}
