import React from 'react';
import ChartComponent from '../../../common/components/ChartComponent';
import { getColorBasedOnPercentage2} from './util';

export default function BarCellDisplay({ value, isValueColorCoded, isOnTable, label, isInverted, }) {
    if (isInverted === null) {
        isInverted = false;
    }

    let labelColor = isInverted ? "#000" : "#fff";

    if ((value < 50) || value >= 101) {
        labelColor = isInverted ? "#000" : "#fff";
    }

    const width = value <= 100 ? '75%' : '75%';
    const option = {
        animation: false,
        height: '100%',
        width: width,
        padding: 5,
        paddingLeft: 10,
        grid: {
            top: 'middle',
            left: 'center',  // Set to 'center' for horizontal center alignment
        },
        xAxis: {
            type: 'value',
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            },
            max: '100'
        },
        yAxis: {
            type: 'category',
            show: false,
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLine: {
                show: false
            }
        },
        series: [
            {
                type: 'bar',
                data: [100],
                barWidth: 25,
                itemStyle: {
                    color: isValueColorCoded ? getColorBasedOnPercentage2(value, isInverted) : '#4472c4',
                    borderRadius: 5,
                },
                showBackground: false,
                backgroundStyle: {
                    color: '#f4f4f4',
                    borderRadius: 5,
                },
                label: {
                    fontSize: 10,
                    fontStyle: "bold",
                    position: "inside",
                    color: labelColor,
                    show: true,
                    formatter: function (params) {
                        return value + '%'
                    }
                },
            },
        ]
    };


    return <ChartComponent option={option} />;
}
