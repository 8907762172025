import React from "react";
import Layout from "./layout";
import {DEFAULT_DASHBOARD_LAYOUT} from "../constants";
import DashboardFilters from "./dashboardFilters";


export default function Viewer({data}) {
    const dashboardLayoutValue = data.extra_data?.layout

    const [filters, setFilters] = React.useState([{
        operator: null,
        value: null,
        filterName: null
    }])

    // TODO: this may not be the best part of the code to put the TODO, but we need to handle IN values where the value have a comma

    return (
        <>
            {
                (data.filter_fields && data.filter_fields.length > 0) && <DashboardFilters
                    tableId={data.table_source}
                    usableFilterIds={data.filter_fields}
                    filters={filters}
                    onChangeFilter={setFilters}
                />
            }
            {
                !!dashboardLayoutValue && <Layout
                    dashboardLayoutValue={dashboardLayoutValue}
                    content={data.extra_data?.content}
                    filters={filters}
                />
            }
        </>
    )
}