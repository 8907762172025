import React from "react";
import {Grid, Typography} from "@mui/material";
import TSSInformationToolTip from "../../../TmpTSSSales/TSSInformationToolTip";
import {useParams} from "react-router-dom";


export default function MainHeader() {
    const {business_unit} = useParams()

    return (
        <Grid item sm={12} xs={12} marginY={2} display={'flex'} alignItems={'center'}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} paddingX={2}
                  paddingY={1}>
                <Grid item sm={"auto"} xs={12} display={'flex'} alignItems={'center'}>
                    <Typography variant='h5' fontWeight='bold'>
                        {business_unit}
                    </Typography>
                    <TSSInformationToolTip/>
                </Grid>
                <Grid item sm={"auto"} xs={12} display={'flex'} alignItems={'center'}
                      id={'header-extra-info'}>
                    {/*
                            This is used as a portal target for as of date and other items.
                            Since as of date should be known by the dashboard itself, the BU View wrapper should not know any info about it.
                            Thus, we create a portal to it from the underlying/child dashboard.
                            */}
                </Grid>
            </Grid>
        </Grid>
    )
}