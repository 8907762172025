import React, { useContext, useEffect, useState } from "react";
import { POS_TABLE_NAME } from "../../../../../TmpTSSSales/hooks";
import LoadingComponent from "../../../../../../../common/components/LoadingComponent";
import { WindowSizeContext } from "../../../../../../../common/context/WindowSizeContext";
import { Box, Collapse, Grid, Typography } from '@mui/material';
import Item from "../../../../../../../common/components/ItemCard";
import { convertParamsToTransactionDate, preProcessParams } from "../../../../../TmpTSSSales/util";
import CollapsibleComponent from "../../../../../../../common/components/CollapsibleComponent";
import MetricFilter from "../../../../../TmpTSSSales/Filters/MetricFilter";
import { MERCH_TABLE_NAME } from "../../DataProvider/hooks";
import { FILTERS_DEFINITIONS } from "./constants";
import Filters from "../../../filters";
import { useFiltersContext } from "../../Provider/context";
import { useParams } from "react-router-dom";
import FilterMonth from "../../../../../../../common/components/FilterMonth";
import FilterYear from "../../../../../../../common/components/FilterYear";
import DepartmentPerformanceTable from "./departmentPerformanceTable";
import BUToplineDataProvider from "../../DataProvider/BUToplineDataProvider";
import TopLine from "../../../TopLine";
import { createPortal } from "react-dom";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import { MONTH } from "../../../../../../../Constants/general";
import AsOfDate from "../asOfDate";
import { convertParamsToTranDate, getParamsBasedOnPeriod } from "../../../../../Customers/util";
import { useAvailableDateRangeL3 } from "../../../../../TmpTSSSales/DataProvider/hooks";

export default function DepartmentPerformance({ period }) {
    const [isExpand, setExpand] = useState(true)
    const [isFiltersOpen, setIsFiltersOpen] = useState(false)
    const { business_unit, month: monthFromUrl, year: yearFromUrl, period: periodFromUrl } = useParams()

    const [year, setYear] = useState(yearFromUrl ? Number(yearFromUrl) : new Date().getFullYear())
    const [month, setMonth] = useState(monthFromUrl ? [Number(monthFromUrl)] : [])
    // const [period, setPeriod] = useState(periodFromUrl ? periodFromUrl : 'mtd')

    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';

    const basicAPIParams = getParamsBasedOnPeriod(isMTD, isQoQ, {
        year,
        month,
        pm_business_unit_desc_standardized: business_unit
    })

    const { setFilter, filters } = useFiltersContext()

    // const {data: dateRange, isLoading: isLoadingDateRange, isSuccess: isSuccessDateRange} = useAvailableDateRange()
    const { data: dateRange, isLoading: isLoadingDateRange, isSuccess: isSuccessDateRange } = useAvailableDateRangeL3();


    const params = {
        month: [Number(month)],
        year: Number(year),
        pm_business_unit_desc_standardized: business_unit,
    }


    const filterQueryParams = preProcessParams(
        convertParamsToTranDate(getParamsBasedOnPeriod(isMTD, isQoQ, {
            ...params
        }))
    );

    useEffect(() => {
        if (isLoadingDateRange) {
            return
        }
        if (!month || !month.length) {
            if (dateRange.max) {
                setMonth([new Date(dateRange.max).getMonth() + 1])
                setYear(new Date(dateRange.max).getFullYear())
            }
        }
    }, [year, month, dateRange, isLoadingDateRange])


    const windowSize = useContext(WindowSizeContext)

    const selectedYearMonthText = React.useMemo(() => {
        if (!month || !month.length) {
            return '';
        }
        const maxMonth = Math.max(...month)
        return `${MONTH.find(o => o.value === maxMonth)?.label} ${year}`
    }, [month, year])


    if (isLoadingDateRange) {
        return <LoadingComponent />
    }

    const blacklistedFilterKeys = [
        "group", "metric_column",   // TODO: move this into selector context, not filter context
    ]
    const filterKeyTranslation = Object.values(FILTERS_DEFINITIONS).reduce((acc, filter) => {
        acc[filter.key] = filter.column
        return acc
    }, {})
    const filterAsParams = Object.keys(filters).filter(filterKey => {
        if (blacklistedFilterKeys.includes(filterKey)) {
            return false
        }
        return true
    }).filter(filterKey => {
        return !!filters[filterKey]
    }).reduce((acc, filterKey) => {
        const column = filterKeyTranslation[filterKey] || filterKey
        acc[column] = filters[filterKey]
        return acc
    }, {})

    return (
        <>

            {
                document.getElementById('header-extra-info') && createPortal(<>
                    <Grid container>
                        {selectedYearMonthText && <Grid item xs={12} textAlign="right">
                            <Typography ml={5} display="inline">Showing Data for </Typography>
                            <Button
                                onClick={() => {
                                    setIsFiltersOpen(true)
                                }}
                                sx={{
                                    fontSize: "1rem",
                                    verticalAlign: "unset",
                                    padding: 0,
                                    // textDecoration: "underline",
                                    textTransform: "capitalize",
                                }}
                            >
                                {selectedYearMonthText}
                                <Edit />
                            </Button>
                        </Grid>}
                        <Grid item xs={12} textAlign="right">
                            <Typography ml={5}>
                                <AsOfDate textOnly={true} />
                            </Typography>
                        </Grid>
                    </Grid>
                </>, document.getElementById('header-extra-info'))
            }

            {/*<DepartmentPerformanceTrends params={params} period={period} filters={filters} onDropdownFilterChange={onDropDownFilterChange} />*/}
            <Filters
                isOpen={isFiltersOpen} setIsOpen={setIsFiltersOpen}
                filters={filters} setFilter={setFilter} params={filterQueryParams}
                tableName={POS_TABLE_NAME}
                filterDefinitions={FILTERS_DEFINITIONS}>

                <Grid item xs={12} sm={6}>
                    <FilterMonth
                        onDropdownChange={(value) => {  // ignore the second argument
                            setMonth(value)
                        }}
                        filters={{ year, month }}
                        dateRangeData={{
                            from: dateRange.min,
                            to: dateRange.max
                        }}
                        dateRangeIsLoading={isLoadingDateRange}
                        isSuccess={isSuccessDateRange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FilterYear
                        onDropdownChange={(value) => {  // ignore the second argument
                            setYear(value)
                        }}
                        filters={{ year, month }}
                        dateRangeData={{
                            from: dateRange.min,
                            to: dateRange.max
                        }}
                        isLoading={isLoadingDateRange}
                        isSuccess={isSuccessDateRange}
                    />
                </Grid>
            </Filters>

            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }} mt={2}>
                <Grid container columns={12} alignItems="center" p={.5}>
                    {
                        windowSize?.isOnMobile ? <>
                            <Grid item xs={12} display="flex" justifyContent={'space-between'} alignItems={'center'}>
                                <Typography variant="h6" ml={1}>
                                    Department Performance
                                </Typography>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>

                            <Grid item xs={12} m={1}>
                                <MetricFilter
                                    isMTD={isMTD}
                                    params={basicAPIParams}
                                    business_unit={business_unit}
                                />
                            </Grid>

                        </> : <>
                            <Grid item xs={12} sm={7}>
                                <Typography variant='h6' ml={2}>Department Performance</Typography>
                            </Grid>
                            <Grid xs sm={4.5} py={windowSize?.isOnMobile ? .75 : ''} display={'flex'}
                                justifyContent='end'>
                                <Grid xs sm={6.5} display={'flex'} justifyContent='end'>
                                    <MetricFilter
                                        isMTD={isMTD}
                                        params={basicAPIParams}
                                        business_unit={business_unit}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs justifyContent={'end'}>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Collapse in={isExpand}>
                <Grid item xs={12} marginBottom={2} sx={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>
                    <Item>
                        <DepartmentPerformanceTable
                            params={{
                                ...basicAPIParams,
                                ...filterAsParams
                            }}
                            period={period}
                        />
                    </Item>
                </Grid>
            </Collapse>


            <Grid item xs={12} mb={2}>
                <BUToplineDataProvider
                    params={{
                        ...basicAPIParams,
                        ...filterAsParams
                    }}
                    period={period} business_unit={business_unit}>
                    <TopLine
                        topLineMetrics={true} isStickyForTopline={false} params={{
                            ...basicAPIParams,
                            ...filterAsParams
                        }}
                        period={period}
                        filters={filters} />
                </BUToplineDataProvider>

            </Grid>
        </>
    );
}