import TableCellPercentageBox from "./TableCellPercentageBox";
import React from "react";

export const viewNameByGroupBy = {
    'Business Unit': 'chain_bu_group_sow_bu',
    'Department': 'chain_bu_group_sow_dept',
    'Sub-department': 'chain_bu_group_sow_subdept',
    'Business Unit Group': 'chain_bu_group_sow_sbu_bu_group'
}

export const columnValueDictionary = {
    'Business Unit': 'pm_business_unit_desc_standardized',
    'Department': 'category',
    'Sub-department': 'category',
    'default': 'sbu_bu_group'
};

export function getColumnValue(groupBySelection) {
    return columnValueDictionary[groupBySelection] || columnValueDictionary['default'];
}

export const buBasketSizeBranchViewDictionary = {
    'Business Unit': 'store_bu_basket_size',
    'Department': 'store_dept_basket_size',
    'Sub-department': 'store_subdept_basket_size',
    'default': 'store_bu_group_basket_size'
};

export function getBUBasketSizeBranchView(groupBySelection) {
    return buBasketSizeBranchViewDictionary[groupBySelection] || buBasketSizeBranchViewDictionary['default'];
}


/************************************************************************
 * Helper functions for dynamic column generation
 * These helper functions are primarliy specific to the Share of Wallet table
 ************************************************************************/
export const generateColumnDefinition = (header, accessor) => {
    // creates a column object based from header and fullkey
    // fullkey is the key that will be used to access the data

    return {
        id: accessor?.toLowerCase(),
        header: header==='Ace Hardware/Workshop' ? 'Ace Hardware/ Workshop' : header,
        accessorKey: accessor,
        cell: ({ row, getValue }) => (<TableCellPercentageBox value={getValue()}/>),
        customStyle: { width: '20rem', height: '2.5rem', verticalAlign: 'middle' },
    }
}

export const recursiveColumnPlacer = (accumulator, columnName, fullKey) => {
    // recursively place columns based on the column name
    // column name is a string that will be split by '|'
    // if the column name has more than one '|', it will be nested
    // if the column name has only one '|', it will be a single column

    const split = columnName.split('|');
    const key = split[0];

    if (!accumulator.hasOwnProperty('columns')) {
        accumulator.columns = {};
    }
    if (split.length > 1) {
        const rest = split.slice(1).join('|');
        accumulator.columns[key] = recursiveColumnPlacer(
            accumulator.columns[key] ?? {
                header: key,
                headerStyle: {backgroundColor:'#0A4FB4', color: 'white'}
            },
            rest,
            fullKey
        );
    } else {
        accumulator.columns[key] = generateColumnDefinition(key, fullKey);
    }

    return accumulator;
}

export const recursiveColumnsObjToArr = (columnObj) => {
    // recursively convert columns object to array
    if (columnObj.columns) {
        return {
            ...columnObj,
            columns: Object.values(columnObj.columns).map(recursiveColumnsObjToArr)
        }
    } else {
        return columnObj;
    }
}

export const recursiveCountDepth = (columnObj, depth=1) => {
    // recursively count the depth of the column object
    if (columnObj?.columns) {
        return recursiveCountDepth(columnObj.columns[0], depth + 1)
    } else {
        return depth;
    }
}

export const recursiveWrapColumns = (columnObj, depth=1) => {
    // recursively wrap columns based on the depth
    // this is mainly used for formatting the columns
    if (depth === 0) {
        return columnObj
    } else {
        return {
            id: `nested-${columnObj.id}`,
            header: "",
            headerStyle: {backgroundColor:'#0A4FB4', color: 'white'},
            columns: [recursiveWrapColumns(columnObj, depth - 1)]
        }
    }
}