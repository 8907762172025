import {IndicatorCellBox} from "../../../Customers/Transactions/BUGroupShareOfWallet";
import BarIndicator from "../../../common/presentation/barIndicator";
import {convertToValidNumber} from "../../../../../common/utils";

export function CellIndicatorVsLastYear({thisYear, lastYear}) {
    const perc = (thisYear / lastYear) * 100;

    const showIndicator = perc !== 0 && perc !== Infinity && perc !== -Infinity && !isNaN(perc);
    return <IndicatorCellBox>
        {
            showIndicator ?
                <BarIndicator
                    colorFunctionFor={'SBU'} value={convertToValidNumber(perc)?.toFixed(1)}
                    isValueColorCoded={true} isOnTable={true} label="vs last year"/> :
                '-'
        }
    </IndicatorCellBox>
}