import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
import 'echarts-extension-gmap';
import {  EffectScatterChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
} from 'echarts/components';
import {  SVGRenderer } from 'echarts/renderers';
import { useState } from 'react';
import DATA from '../../../mockdata/geomapData.json';

export default function GeoMap() {
    echarts.use(
		[TitleComponent, TooltipComponent, GridComponent, EffectScatterChart, SVGRenderer, DatasetComponent]
	);

    const data = [
        { name: 'SM City FairView', value: 250 },
        { name: 'SM Seaside City Cebu', value: 170},
        { name: 'SM Megamall', value: 300 },
        { name: 'SM City North EDSA', value: 175 },
        { name: 'SM Mall of Asia', value: 500 },
        { name: 'SM City Bacoor', value: 273 },
        { name: 'SM City Taytay', value: 279 }
      ];
      const geoCoordMap = {
       "SM City FairView" : [121.058495,14.733691],
        "SM Seaside City Cebu" : [123.881836,10.280389],
        "SM Megamall" : [121.056770,14.584447],
        "SM City North EDSA" : [121.030479,14.657027],
        "SM Mall of Asia" : [120.982155,14.535067],
        "SM City Bacoor" : [120.950851,14.444626],
        "SM City Taytay" : [121.139905,14.565071]
      };
      const convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value)
            });
          }
        }
        return res;
      };
      const chartOption = {
        tooltip: {
          trigger: 'item'
        },
        gmap: {
            center: [120.982155,14.535067],
            zoom: 10,
            roam: true,
            renderOnMoving: true,
            echartsLayerZIndex: 2019,
            // mapStyle: {
            //     styleJson: [
            //     {
            //         featureType: 'highway',
            //         elementType: 'all',
            //         stylers: {
            //         color: '#fdfdfd'
            //         }
            //     },
            //     {
            //         featureType: 'arterial',
            //         elementType: 'geometry',
            //         stylers: {
            //         color: '#fefefe'
            //         }
            //     },
            //     {
            //         featureType: 'arterial',
            //         elementType: 'geometry.fill',
            //         stylers: {
            //         color: '#fefefe'
            //         }
            //     },
            //     {
            //         featureType: 'poi',
            //         elementType: 'all',
            //         stylers: {
            //         visibility: 'off'
            //         }
            //     },
            //     {
            //         featureType: 'green',
            //         elementType: 'all',
            //         stylers: {
            //         visibility: 'off'
            //         }
            //     },
            //     {
            //         featureType: 'subway',
            //         elementType: 'all',
            //         stylers: {
            //         visibility: 'off'
            //         }
            //     },
            //     {
            //         featureType: 'local',
            //         elementType: 'all',
            //         stylers: {
            //         color: '#d1d1d1'
            //         }
            //     },
            //     {
            //         featureType: 'arterial',
            //         elementType: 'labels',
            //         stylers: {
            //         visibility: 'off'
            //         }
            //     },
            //     ]
            // }
        },
        series: [
            {
                type: 'effectScatter',
                coordinateSystem: 'gmap',
                // data: data,
                data: convertData(data),
                // data: convertData(data.sort(function (a, b) {
                //     return b.value - a.value;
                // }).slice(0, 6)),
                symbolSize: function (val) {
                    return val[2] / 10;
                },
                encode: {
                //   value: 2
                    value: 2,
                    lng: 0,
                    lat: 1
                },
                showEffectOn: 'render',
                rippleEffect: {
                    brushType: 'stroke'
                },
                label: {
                    formatter: '{b}',
                    position: 'right',
                    show: false
                },
                emphasis: {
                    scale: true
                },
                zlevel: 1
            },
        ]
    };
      
    return <>
			<ReactEChartsCore
				echarts={echarts}
				option={chartOption}
				notMerge={true}
				lazyUpdate={true}
				opts={{renderer: 'svg'}}
				style={{height: '35rem'}}
			/>
    </>
}