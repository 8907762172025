import {useQuery} from "react-query"; // Importing useQuery from react-query
import ApiClient from "../API"; // Importing ApiClient from API file
import {USE_QUERY_DEFAULT_OPTIONS} from "../../Constants/settings"; // Importing default options for useQuery from settings

const MKT_LOOKUP_TABLE = 'l2_mkt_store'; // Defining a constant variable MKT_LOOKUP_TABLE and assigning it a value 'l2_mkt_store


/**
 * Hook to fetch and process market branch data.
 * @param {Object} queryOptions - Options for the useQuery hook.
 * @returns {Object} An object containing the query result and a lookup object.
 */
export function useMktBranchLookup({queryOptions = {}}) {
    // Define the columns to be fetched from the API
    const columns = [
        'store_id',
        'store_name_title',
        'mall_type',
        'mall_category',
        'new_size_segment',
        'region',
        'area',
        'selling_floor',
        'store_tagging'
    ].join(',')

    // Define the query key for useQuery
    const queryKey = ['markets', 'inventory', 'branchMetaData']

    // Define the query using useQuery
    const query = useQuery(
        queryKey,
        () => {
            // Fetch data from the API
            return ApiClient().get(`data/table/${MKT_LOOKUP_TABLE}/query/`, {
                params: {
                    columns: columns,
                    group_by: columns,
                    limit: 10000
                }
            }).then(res => {
                // Extract the data from the response
                return res.data.data;
            }).then(data => {
                // Map the data to a new format
                return data.map(row => {
                    return {
                        ...row,
                        branch_id: row?.store_id,
                        store_size: row?.new_size_segment,
                        store: row?.store_name_title
                    }
                })
            });
        },
        {
            // Merge the default options with the provided options
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: true,
            ...queryOptions
        }
    )

    // Create a lookup object from the query data
    const lookup = query.data?.reduce((acc, row) => {
        acc[row.store_id] = row;
        return acc;
    }, {})

    // Return the query and the lookup object
    return {
        ...query,
        lookup
    }
}