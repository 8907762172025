import {COLUMN_L3_DEPARTMENT, COLUMN_L3_SUBDEPT, useBUPerDepartmentData} from "./hooks";
import {metricCalculationsL3} from "../../../../TmpTSSSales/DataProvider/util";

const metricCalculations = {
    'actual_sales': (item) => item.hasOwnProperty('pos_sku_smac_sales_sum') ? item.pos_sku_smac_sales_sum : null,
    'actual_transactions': (item) => {
        if (item.hasOwnProperty('subdept_txn_count_sum')) {
            return item.subdept_txn_count_sum
        }
        if (item.hasOwnProperty('dept_txn_count_sum')) {
            return item.dept_txn_count_sum
        }
        if (item.hasOwnProperty('subdept_cat_txn_count_sum')) {
            return item.subdept_cat_txn_count_sum
        }
        if (item.hasOwnProperty('dept_cat_txn_count_sum_distinct')) {
            return item.dept_cat_txn_count_sum_distinct
        }
        return null
    },
    'actual_basket_size': (item) => {
        const transactions = metricCalculations['actual_transactions'](item)
        if (transactions === null) {
            return null
        }
        const sales = metricCalculations['actual_sales'](item)
        if (sales === null) {
            return null
        }
        return sales / transactions
    },
    'actual_space_nsa': (item) => item.hasOwnProperty('pos_sku_gross_sales_sum') && item.hasOwnProperty('net_selling_area_sum')
        ? Number(item.pos_sku_gross_sales_sum) / Number(item.net_selling_area_sum)
        : null,
    'actual_smac_sales': (item) => item.hasOwnProperty('pos_sku_smac_sales_sum') ? item.pos_sku_smac_sales_sum : null,
    'actual_units_sold' : (item) => item.hasOwnProperty('pos_sku_sale_tot_qty_sum') ? item.pos_sku_sale_tot_qty_sum : null,
    'actual_units_sold_per_txn': (item) => {
        const transactions = metricCalculations['actual_transactions'](item)
        if (transactions === null) {
            return null
        }
        const unitsSold = metricCalculations['actual_units_sold'](item)
        if (unitsSold === null) {
            return null
        }
        return unitsSold / transactions
    },
};

export const calculateBUMetricValues = (data, metricColumn, groupBy1, groupBy2) => {
    const result = [];

    data?.forEach(item => {
        const group1 = item[groupBy1];
        const group2 = groupBy2 ? item[groupBy2] : null; // Add null check for groupBy2
        let metricTotal //, metricPlanTotal;

        if (metricCalculations[metricColumn]) {
            metricTotal = (metricCalculations[metricColumn](item) === 'N/A')
                ?  'N/A'
                : isFinite(metricCalculations[metricColumn](item)) 
                    ? metricCalculations[metricColumn](item) 
                    : null;
        }

        // in case they want to include Plan in the columns:
        // if (metricPlanCalculations[metricColumn]) {
        //     metricPlanTotal = (metricPlanCalculations[metricColumn](item) === 'N/A')
        //         ?  'N/A'
        //         : isFinite(metricPlanCalculations[metricColumn](item)) 
        //             ? metricPlanCalculations[metricColumn](item) 
        //             : null;
        // }

        result.push({
            original: {...item},
            [groupBy1]: group1,
            [groupBy2]: group2,
            metricTotal: metricTotal,
            // metricPlanTotal: metricPlanTotal,
            units_sold: item?.sales_units_sum,
            unit_price: item?.gross_sales_sum / item?.sales_units_sum,
        });
    });

    return result;
};


export const calculateBUMetricValuesL3 = (data, metricColumn, groupBy1, groupBy2) => {
    const result = [];

    data?.forEach(item => {
        const group1 = item[groupBy1];
        const group2 = groupBy2 ? item[groupBy2] : null; // Add null check for groupBy2
        let metricTotal //, metricPlanTotal;

        if (metricCalculationsL3[metricColumn]) {
            metricTotal = (metricCalculationsL3[metricColumn](item) === 'N/A')
                ?  'N/A'
                : isFinite(metricCalculationsL3[metricColumn](item))
                    ? metricCalculationsL3[metricColumn](item)
                    : null;
        }

        // in case they want to include Plan in the columns:
        // if (metricPlanCalculations[metricColumn]) {
        //     metricPlanTotal = (metricPlanCalculations[metricColumn](item) === 'N/A')
        //         ?  'N/A'
        //         : isFinite(metricPlanCalculations[metricColumn](item))
        //             ? metricPlanCalculations[metricColumn](item)
        //             : null;
        // }

        result.push({
            original: {...item},
            [groupBy1]: group1,
            [groupBy2]: group2,
            metricTotal: metricTotal,
            // metricPlanTotal: metricPlanTotal,
            units_sold: item?.pos_sku_sale_tot_qty_sum,
            unit_price: item?.pos_sku_gross_sales_sum / item?.pos_sku_sale_tot_qty_sum,
        });
    });

    return result;
};


export const processBUData = (currentMetricTotal, lastYearMetricTotal, metric, buData = []) => {
    const result = [];

    currentMetricTotal?.forEach(currentItem => {
        const { standardized_department, standardized_subdept, metricTotal } = currentItem;
        // Use null as the default value for standardized_subdept if it's missing
        const subdept = standardized_subdept !== undefined ? standardized_subdept : null;

        const lastYearItem = lastYearMetricTotal.find(item => item.standardized_department === standardized_department && item.standardized_subdept === standardized_subdept);

        const vsLY = calculateVs(metricTotal, lastYearItem?.metricTotal);

        result.push({
            ...currentItem,
            standardized_department,
            standardized_subdept: subdept,
            metricTotal,
            metricLYTotal: lastYearItem?.metricTotal,
            vsLY,
            units_sold: currentItem.units_sold,
            unit_price: currentItem.unit_price
        });
    });

    return result;
};


export const processBUDataL3 = (currentMetricTotal, lastYearMetricTotal, metric, buData = []) => {
    const result = [];

    currentMetricTotal?.forEach(currentItem => {
        const { pm_department_desc_standardized, pm_subdept_desc_standardized, metricTotal } = currentItem;
        // Use null as the default value for standardized_subdept if it's missing
        const subdept = pm_subdept_desc_standardized !== undefined ? pm_subdept_desc_standardized : null;

        const lastYearItem = lastYearMetricTotal.find(
            item => item.pm_department_desc_standardized === pm_department_desc_standardized &&
                item.pm_subdept_desc_standardized === pm_subdept_desc_standardized);

        const vsLY = calculateVs(metricTotal, lastYearItem?.metricTotal);

        result.push({
            ...currentItem,
            pm_department_desc_standardized,
            pm_subdept_desc_standardized: subdept,
            metricTotal,
            metricLYTotal: lastYearItem?.metricTotal,
            vsLY,
            units_sold: currentItem.units_sold,
            unit_price: currentItem.unit_price
        });
    });

    return result;
};

const calculateVs = (current, comparison) => {
    if (current === null || comparison === null || isNaN(current) || isNaN(comparison)) {
        return null;
    }
    if (current === 'N/A' || comparison === 'N/A') {
        return 'N/A';
    }

    const percentageChange = (Number(current) / Number(comparison)) * 100;
    return parseFloat(percentageChange);
};

const calculateSum = (array, property) =>
  array.reduce((sum, item) => sum + (Number(item[property]) || 0), 0);

export const transformData = (currentBUData, departmentLevelData = [], unitPricePerDept) => {
    const structuredBUData = [];

    currentBUData.forEach((item) => {
        const departmentIndex = structuredBUData.findIndex(
            (dept) => dept.department === item.standardized_department
        );

        const subRow = {
            department: item.standardized_subdept || '',
            subdept: item.standardized_subdept,
            metricTotal: Number(item.metricTotal) || 0,
            metricLYTotal: Number(item.metricLYTotal) || 0,
            vsLY: item.metricLYTotal ? (Number(item.vsLY) || 0) : null,
            units_sold: Number(item.units_sold) || 0,
            unit_price: Number(item.unit_price) || 0,
        };

        if (departmentIndex === -1) {
            structuredBUData.push({
                department: item.standardized_department || '',
                subRows: [subRow],
            });
        } else {
            structuredBUData[departmentIndex].subRows.push(subRow);
        }
    });

    if(departmentLevelData?.length >= 1) { // when metric is Transaction or Basket Size, it should pass through here
        structuredBUData.forEach((dept) => {
            const deptMatch = departmentLevelData?.find((deptWithTxn) => dept.department === deptWithTxn.standardized_department)

            if (dept.subRows.length >= 1) {
                dept.metricTotal = Number(deptMatch?.metricTotal);
                dept.metricLYTotal = Number(deptMatch?.metricLYTotal);
                dept.vsLY = deptMatch?.metricLYTotal ? (Number(deptMatch?.metricTotal) / Number(deptMatch?.metricLYTotal)  * 100) : null;
                dept.units_sold = Number(deptMatch?.units_sold);
                dept.unit_price = Number(deptMatch?.unit_price);
            }
        });
    } else {
        structuredBUData.forEach((dept) => {
            if (dept.subRows.length >= 1) {
                dept.metricTotal = calculateSum(dept.subRows, 'metricTotal');
                dept.metricLYTotal = calculateSum(dept.subRows, 'metricLYTotal');
                dept.vsLY = dept.metricLYTotal ? (dept.metricTotal / dept.metricLYTotal) * 100 : null;
                dept.units_sold = calculateSum(dept.subRows, 'units_sold');
                dept.unit_price = unitPricePerDept?.[dept.department] || 0;
            }
        });
    }

    return structuredBUData;
};



export const transformDataL3 = (currentBUData, departmentLevelData = [], unitPricePerDept) => {
    const structuredBUData = [];

    currentBUData.forEach((item) => {
        const departmentIndex = structuredBUData.findIndex(
            (dept) => dept.department === item[COLUMN_L3_DEPARTMENT]
        );

        const subRow = {
            department: item[COLUMN_L3_SUBDEPT] || '',
            subdept: item[COLUMN_L3_SUBDEPT],
            metricTotal: Number(item.metricTotal) || 0,
            metricLYTotal: Number(item.metricLYTotal) || 0,
            vsLY: item.metricLYTotal ? (Number(item.vsLY) || 0) : null,
            units_sold: Number(item.units_sold) || 0,
            unit_price: Number(item.unit_price) || 0,
        };

        if (departmentIndex === -1) {
            structuredBUData.push({
                department: item[COLUMN_L3_DEPARTMENT] || '',
                subRows: [subRow],
            });
        } else {
            structuredBUData[departmentIndex].subRows.push(subRow);
        }
    });

    if(departmentLevelData?.length >= 1) { // when metric is Transaction or Basket Size, it should pass through here
        structuredBUData.forEach((dept) => {
            const deptMatch = departmentLevelData?.find((deptWithTxn) => dept.department === deptWithTxn[COLUMN_L3_DEPARTMENT])

            if (dept.subRows.length >= 1) {
                dept.metricTotal = Number(deptMatch?.metricTotal);
                dept.metricLYTotal = Number(deptMatch?.metricLYTotal);
                dept.vsLY = deptMatch?.metricLYTotal ? (Number(deptMatch?.metricTotal) / Number(deptMatch?.metricLYTotal)  * 100) : null;
                dept.units_sold = Number(deptMatch?.units_sold);
                dept.unit_price = Number(deptMatch?.unit_price);
            }
        });
    } else {
        structuredBUData.forEach((dept) => {
            if (dept.subRows.length >= 1) {
                dept.metricTotal = calculateSum(dept.subRows, 'metricTotal');
                dept.metricLYTotal = calculateSum(dept.subRows, 'metricLYTotal');
                dept.vsLY = dept.metricLYTotal ? (dept.metricTotal / dept.metricLYTotal) * 100 : null;
                dept.units_sold = calculateSum(dept.subRows, 'units_sold');
                dept.unit_price = unitPricePerDept?.[dept.department] || 0;
            }
        });
    }

    return structuredBUData;
};



export const calculateContributionOnNestedDeptSubdeptData = (perDepartmentData, metric) => {
    // this function expects the output of transform data
    if (!perDepartmentData) {
        return perDepartmentData
    }

    const metricsWithoutContribution = ['actual_basket_size', 'actual_transactions', 'actual_space_nsa']
    const hasNotApplicableContribution = metricsWithoutContribution.includes(metric)

    const overallTotal = perDepartmentData.reduce((acc, departmentData) => {
        acc += departmentData.metricTotal
        return acc
    }, 0)

    return perDepartmentData.map(departmentData => {
        const newSubrows = calculateContributionOnNestedDeptSubdeptData(departmentData.subRows, metric)
        const contribution = departmentData.metricTotal / overallTotal * 100
        return {
            ...departmentData,
            subRows: newSubrows,
            contribution: hasNotApplicableContribution ? 'N/A' : contribution
        }
    })
}


export const applyDeptAndSubdeptFilters = (perDepartmentData, deptValue, subDeptValue) => {
    // we are applying it here since the values do not generally change, only the shown rows
    if ((!deptValue && !subDeptValue) || !perDepartmentData) {
        return perDepartmentData
    }

    return perDepartmentData.filter(departmentData => {
        if (!deptValue) {
            if (!!subDeptValue) {
                // has subdepartment filter, use it instead
                return perDepartmentData.subRows.some(subdeptData => subdeptData.subdept === subdeptData)
            }
            return true
        }

        return departmentData.department === deptValue
    }).map(departmentData => {
        if (!subDeptValue) {
            return departmentData
        }

        // since we are using single select for sub-department, find the match immediately
        // and since we do not want to re-fetch department level data, apply values of the single selected subdept on the department level data
        const matchedSubdept = departmentData.subRows.find(subdeptData => subdeptData.subdept === subDeptValue)
        return {
            ...matchedSubdept,
            department: departmentData.department,
            contribution: "N/A",  // since we are showing values for a single subdepartment under this department, contribution across different departments doesn't make sense
            subRows: [matchedSubdept]
        }
    })
}