import React, { useState } from 'react';
import {
    Grid, 
    Container, 
    SwipeableDrawer,
} from '@mui/material';

import { PopulationAccordion } from './PopulationAccordion';
import { SMACTypeAccordion } from './SMACTypeAccordion';
import { SMACPenetrationAccordion } from './SMACPenetrationAccordion';
import { HomebranchAccordion } from './HomebranchAccordion';
import { CatchmentAccordion } from './CatchmentAccordion';
import IconButton from '@mui/material/IconButton';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { DisclaimerSection } from './DisclaimerSection';
// Future sections:
    // import { CompetitorsAccordion } from './CompetitorsAccordion';
    // import { PointsOfInterestAccordion } from './PointsOfInterestAccordion';
    // import { InfrastructureAccordion } from './InfrastructureAccordion';

export default function MapSideContainer({
    windowSize,
    mallNames,
    smacPenetrationTable,
    smac_members,
    cross_filtered_data,

    showSMACMembers,
    showCityPopulation,

    smacMembersIsLoading,
    cityPopDataIsLoading,
    crossFilteredIsLoading,
    isochroneIsLoading,
    barangaysIsLoading,

    toggleShowSMACMembers,
    toggleShowCityPopulation,
    handleSMACTypes,
    handleHomebranches,
    handleisochrones,

    setSelectedBarangay,
    setCheckedIsochrones,
    checkedIsochrones
}) {
    const [openDrawer, setOpenDrawer] = useState(false);
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen);
    };

    return (
        <>
        {/* MOBILE VIEW */}
            {windowSize.isOnMobile && (
                <IconButton
                    style={{
                        position: 'fixed',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'white',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        zIndex: 1000,
                        height: 60,
                        width: 25,
                        borderRadius: 2
                    }}
                    onClick={toggleDrawer(true)}
                >
                    <ArrowRightRoundedIcon fontSize='large' color='#000000' />
                </IconButton>
            )}
            
            {windowSize.isOnMobile && (
                <SwipeableDrawer
                    anchor="right"
                    open={openDrawer}
                    disableSwipeToOpen={true}
                    swipeAreaWidth={40}
                    onOpen={toggleDrawer(true)}
                    onClose={toggleDrawer(false)}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    style={{
                        width: 75,
                    }}
                >
                    <Container
                        component="div"
                        style={{
                            backgroundColor: 'white',
                            border: '1px solid lightgray',
                            overflowY: 'auto',
                            borderRadius: 0,
                            paddingLeft: 1,
                            paddingRight: 0,
                            overflowX: 'hidden',
                            width: `${(windowSize.width)-70}px`,
                            justifyContent: 'center',
                            display: 'flex',
                            visibility: 'visible',
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>

                                {/* <PopulationAccordion 
                                    smacMembersIsLoading={smacMembersIsLoading}
                                    smac_members={smac_members}
                                    showSMACMembers={showSMACMembers}
                                    toggleShowSMACMembers={toggleShowSMACMembers}
                                    cityPopDataIsLoading={cityPopDataIsLoading}
                                    showCityPopulation={showCityPopulation}
                                    toggleShowCityPopulation={toggleShowCityPopulation}
                                /> */}
                                <CatchmentAccordion
                                    handleisochrones={handleisochrones}
                                    isochroneIsLoading={isochroneIsLoading}
                                    setCheckedIsochrones={setCheckedIsochrones}
                                    checkedIsochrones={checkedIsochrones}
                                />

                                <SMACPenetrationAccordion
                                    cityPopDataIsLoading={cityPopDataIsLoading}
                                    smacPenetrationTable={smacPenetrationTable}
                                    setSelectedBarangay={setSelectedBarangay}
                                />

                                {/* <SMACTypeAccordion
                                    handleSMACTypes={handleSMACTypes}
                                    crossFilteredIsLoading={crossFilteredIsLoading}
                                    cross_filtered_data={cross_filtered_data && barangaysIsLoading}
                                /> */}

                                {/* <HomebranchAccordion
                                    mallNames={mallNames}
                                    handleHomebranches={handleHomebranches}
                                    crossFilteredIsLoading={crossFilteredIsLoading}
                                    cross_filtered_data={cross_filtered_data}
                                /> */}

                                {/* <CompetitorsAccordion
                                    listOfMalls={listOfMalls}
                                /> */}

                                {/* <PointsOfInterestAccordion 
                                    listOfMalls={listOfMalls}
                                /> */}

                                {/* <InfrastructureAccordion 
                                    smacTypes={smacTypes}
                                /> */}

                                <DisclaimerSection />
                          </Grid>
                      </Grid>
                  </Container>
            </SwipeableDrawer>
            )}

        {/* PC VIEW */}
            {!windowSize.isOnMobile && (
                <Container
                    component="div"
                    fixed={false}
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid lightgray',
                        overflowY: 'auto',
                        borderRadius: 10,
                        paddingLeft: 1,
                        paddingRight: 0,
                        overflowX: 'hidden',
                        justifyContent: 'center',
                        display: 'flex',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        maxHeight: '685px'
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>

                        {/* <PopulationAccordion 
                                smacMembersIsLoading={smacMembersIsLoading}
                                smac_members={smac_members}
                                showSMACMembers={showSMACMembers}
                                toggleShowSMACMembers={toggleShowSMACMembers}
                                cityPopDataIsLoading={cityPopDataIsLoading}
                                showCityPopulation={showCityPopulation}
                                toggleShowCityPopulation={toggleShowCityPopulation}
                        /> */}
                        <CatchmentAccordion
                            handleisochrones={handleisochrones}
                            isochroneIsLoading={isochroneIsLoading}
                            setCheckedIsochrones={setCheckedIsochrones}
                            checkedIsochrones={checkedIsochrones}
                        />

                        <SMACPenetrationAccordion
                            cityPopDataIsLoading={cityPopDataIsLoading}
                            smacPenetrationTable={smacPenetrationTable}
                            setSelectedBarangay={setSelectedBarangay}
                        />

                        {/* <SMACTypeAccordion
                            handleSMACTypes={handleSMACTypes}
                            crossFilteredIsLoading={crossFilteredIsLoading}
                            cross_filtered_data={cross_filtered_data && barangaysIsLoading}
                        /> */}

                        {/* <HomebranchAccordion
                            mallNames={mallNames}
                            handleHomebranches={handleHomebranches}
                            crossFilteredIsLoading={crossFilteredIsLoading}
                            cross_filtered_data={cross_filtered_data}
                        /> */}

                    
                        {/* <CompetitorsAccordion
                            listOfMalls={listOfMalls}
                        /> */}

                        {/* <PointsOfInterestAccordion 
                            listOfMalls={listOfMalls}
                        /> */}

                        {/* <InfrastructureAccordion 
                            smacTypes={smacTypes}
                        /> */}

                        <DisclaimerSection />
                      </Grid>
                    </Grid>
                </Container>
            )}
        </>
    );
}
