import { useMemo } from "react";
import { Grid, Box, Typography } from "@mui/material";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import { useZeroSalesData } from "../hooks";
import LoadingComponent from "../../../../common/components/LoadingComponent";

export default function StoreArticleZeroSalesTable({ filters, overviewTableIsEmpty}) {
    const { zeroSalesData, isLoadingZeroSalesData } = useZeroSalesData({
        params: { ...filters },
    });

    const storeZeroSalesColumns = useMemo(
        () => [
            {
                id: "article_number",
                header: "Article Number",
                accessorKey: "article_number",
                cell: (row) => (
                    <Box sx={{ width: { sm: '30%' }, marginX: 'auto', my: .5 }}>
                        <Typography>{row?.getValue()}</Typography>
                    </Box>
                ),
                customStyle: {
                    width: "15rem",
                    textAlign: "left",
                    verticalAlign: "middle",
                },
            },
            {
                id: "article_description",
                header: "Article Description",
                accessorKey: "article_description",
                cell: (row) => (
                    <Box sx={{ marginX: 'auto' }}>
                        <Typography>{row?.getValue()}</Typography>
                    </Box>
                ),
                customStyle: { width: "25rem", verticalAlign: "middle" },
            },
            {
                id: "product_category",
                header: "Product Category",
                accessorKey: "article_category",
                cell: (row) => (
                    <Box sx={{ width: { sm: '20%' }, marginX: 'auto' }}>
                        <Typography>{row?.getValue()}</Typography>
                    </Box>
                ),
                customStyle: {
                    width: "15rem",
                    textAlign: "left",
                    verticalAlign: "middle",
                },
            },
            {
                id: "must_have_type",
                header: "Must-Have Type",
                accessorKey: "article_sub_category",
                cell: (row) => (
                    <Box sx={{ width: { sm: '20%' }, marginX: 'auto', display: "flex", gap: .5 }}>
                        <Typography>Top </Typography>
                        <Typography>{row?.getValue()}</Typography>
                    </Box>
                ),
                customStyle: {
                    width: "15rem",
                    textAlign: "left",
                    verticalAlign: "middle",
                },
            },
        ],
        []
    );

    if (isLoadingZeroSalesData) {
        return <LoadingComponent />;
    }

    if(!filters?.store_id && !overviewTableIsEmpty){
        return (
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#ffffff", borderRadius: "0 0 .5rem .5rem" }} >
                <Grid container justifyContent="center" alignItems="center" py={6} borderRadius={0}>
                    <Typography
                        sx={{ fontSize: "1.5rem", color: "black", textAlign: "center" }}
                    >
                        Please select a store to view the article(s)
                    </Typography>
                </Grid>
            </Box>
        );
    }

    if ( !zeroSalesData || zeroSalesData?.length === 0 || overviewTableIsEmpty || (!filters?.store_id && overviewTableIsEmpty) ) {
        return (
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#ffffff", borderRadius: "0 0 .5rem .5rem" }} >
                <Grid container justifyContent="center" alignItems="center" py={6} borderRadius={0}>
                    <Typography
                        sx={{ fontSize: "1.5rem", color: "black", textAlign: "center" }}
                    >
                        There’s no available data.
                    </Typography>
                </Grid>
            </Box>
        );
    }

    return (
        <Grid container xs={12}>
            <TableComponentV2
                columns={storeZeroSalesColumns}
                data={zeroSalesData || []}
                tableId="storeZeroSalesTable"
                isCompact
                customTableHeaderStyle={{ backgroundColor: "#caddeb" }}
                customTableContainerStyle={{ borderRadius: 0, boxShadow: "none" }}
                hasPagination
            />
        </Grid>
    );
}
