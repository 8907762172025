import React from 'react'
import {useState} from 'react';
import {useModuleList} from "../API/hooks";
import {NavLink, useLocation} from "react-router-dom";
import {ListItemIcon, MenuItem} from "@mui/material";
import EditModule from "../../private/admin/ModulePermission/EditModule";

export default function ManageThisModuleMenuItem({item}) {
    const location = useLocation();

    // Gets the list of existing modules from the API
    const {data: moduleList} = useModuleList();

    // Gets the end of the URL instead of the whole URL
    const usePathname = () => {
        return location.pathname;
    }
    const currentPathname = usePathname();

    // Returns a boolean if the currentPathname matches the moduleName
    const isModuleMatched = (moduleName) => {
        return currentPathname.includes(moduleName);
    };

    // Searches for the module with the matching name as the path/url
    const matchedModule = moduleList?.find((module) => isModuleMatched(module.name));

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const handleManageModuleClick = () => {
        setIsEditModalOpen(true);
    };

    return (
        <>
            {
                isEditModalOpen && (
                    <EditModule module={matchedModule} isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen}/>
                )
            }
            <MenuItem
                component={NavLink}
                onClick={handleManageModuleClick}
                to={item.path}
                sx={{
                    p: 1,
                    ml: 1,
                    mr: 1,
                    display: `${!item.access ? 'none' : 'block'}`,
                    minWidth: '180px',
                    fontWeight: `${location.pathname === item.path ? 'bold' : 'regular'}`,
                }}>
                <ListItemIcon>
                    {item.itemIcon}
                </ListItemIcon>
                {item.name}
            </MenuItem>
        </>
    )
};