import SelectWrapper from '../../../common/components/SelectWrapper';
import SmsContent from "./smsContent";
import useChannelOptions from '../../../common/hooks/useChannelOptions';

export default function ViberContent(props) {    
    function toggleFallbackSmsRequiredStatus() {
        setSmsDetailsEmpty()
        props.setIsFallbackSmsRequired(!props.isFallbackSmsRequired)
    }

    function setSmsDetailsEmpty() {
        props.onChange('fromSms',  null)
        props.onChange('contentSms', '')
    }

    const {isLoading, options} = useChannelOptions('viber');
    const viberContentLength = props.values.contentViber.length;
    const remaining = 1000 - viberContentLength;

    return <>
            <div className="row mb-2">
                <label className="col-sm-2">From <small>*</small></label>
                <div className="col-sm-10">
                     {/* <Select options={options} onChange={(e) => props.onChange(e,'dropdown','fromViber')} value={props.values.fromViber}/> */}
                     
                     <SelectWrapper options={options} isLoading={isLoading} onChange={(e) => props.onChange('fromViber', e ? e.value : null)} value={props.values.fromViber} />
                </div>
            </div>

            <div className="row mb-2">
                <label className="fw-semibold col-sm-2">Image</label>
                <div className="col-sm-10">
                    <input className="form-control" type="file"   name="imageSrc" onChange={(e)=> props.onChange('imageSrc', e.target.files[0])} />
                </div>
            </div>

            <div className="row mb-2">
                <label className="fw-semibold col-sm-2">Action URL</label>
                <div className="col-sm-10">
                    <input className="form-control" type="text"   onChange={(e)=> props.onChange(e.target.name, e.target.value)} name="actionUrl" value={props.values.actionUrl}/>
                </div>
            </div>

            <div className="row mb-2">
                <label className="fw-semibold col-sm-2">Caption</label>
                <div className="col-sm-10">
                    <input className="form-control" type="text" maxLength="15" onChange={(e)=> props.onChange(e.target.name, e.target.value)} name="actionCaption" value={props.values.actionCaption}/>
                </div>
            </div>            

            <div className="row mb-2">
                <label className="fw-semibold col-sm-2">Content <small>*</small></label>
                <div className="col-sm-10">
                    <textarea className="form-control" style={{resize: 'none'}} type="text" rows="5" maxLength={1000} onChange={(e)=> props.onChange(e.target.name, e.target.value)} name="contentViber" value={props.values.contentViber}/>
                </div>
                <div className="text-end">
                    <small>Remaining Characters: <strong className="pe-3">{remaining}</strong></small>
                </div>
            </div>

            <hr/>
            <div className="form-check form-switch float-end">
                <label className="form-check-label" htmlFor="smsFallbackToggle">Use SMS Fallback</label>
                <input className="form-check-input" type="checkbox"  id="smsFallbackToggle" onChange={(e)=>toggleFallbackSmsRequiredStatus(e.target.value)} checked={  props.isFallbackSmsRequired } />
            </div>
            
            <label className="fs-semibold mb-2">Fallback SMS </label>
            <SmsContent values={props.values} onChange={props.onChange} isRequired= { props.isFallbackSmsRequired} isOnViber={true}/>
    </>
}