import { Grid, Card, Typography, IconButton, Box, Collapse } from '@mui/material'
import React from 'react'
import { useMemo, useState } from 'react'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import TableComponentV2 from '../../../../../common/components/TableComponentV2';
import NumericValueDisplay from '../../../common/presentation/numericValueDisplay';
import BarIndicator from '../../../common/presentation/barIndicator';
import CollapsibleComponent from '../../../../../common/components/CollapsibleComponent';
import { useBusinessUnitPerformance } from '../../hooks';
import LoadingComponent from '../../../../../common/components/LoadingComponent';
import moment from 'moment/moment';
import { useAvailableMaxDate } from '../../../TmpTSSSales/DataProvider/hooks';
import { checkIfCompletePeriod } from '../../../TmpTSSSales/util';

const IndicatorCellBox = (props) => (
    <Box sx={{ width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);

export default function BUPerformance({ filters, params, storeID, period }) {
    const isMTD = period === 'mtd'
    const isQoQ = period === 'qoq'

    // const paramsBasedOnPeriod = isMTD ? params : { ...params, month: [1, ...(params?.month || [])] }
    const paramsBasedOnPeriod = isQoQ
        ? {
            ...params,
            month: Array.from({ length: 3 }, (_, i) =>
                moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month() + i + 1
            )
        }
        : isMTD ? { ...params } : { ...params, month: [1, ...(params?.month || [])] };

    const [isExpand, setExpand] = useState(true);

    const { finalMerchData, isLoading } = useBusinessUnitPerformance({ params: { ...paramsBasedOnPeriod, store_id: storeID } })

    const selectedMetric = filters?.metric_column

    const metricHeader = {
        'actual_sales': 'Sales',
        'actual_transactions': 'Transactions',
        'actual_basket_size': 'Basket Size',
        'actual_unit_sold': 'Unit Sold',
        'actual_unit_sold_per_txn': 'Unit Sold per Transaction',
        'actual_smac_sales': 'SMAC Sales',
        'actual_space_nsa': 'Space Productivity NSA',
    }

    const metricTotals = {
        'actual_sales': 'sales_actual_sales',
        'actual_transactions': 'transaction_actual_sales',
        'actual_basket_size': 'basket_size_actual_sales',
        'actual_unit_sold': 'units_sold_actual_sales',
        'actual_unit_sold_per_txn': 'units_sold_per_transaction_actual_sales',
        'actual_smac_sales': 'smac_sales_actual_sales',
        'actual_space_nsa': 'space_nsa_actual_sales',
    }

    const metricVsLy = {
        'actual_sales': 'sales_vs_last_year',
        'actual_transactions': 'transaction_vs_last_year',
        'actual_basket_size': 'basket_size_vs_last_year',
        'actual_unit_sold': 'units_sold_vs_last_year',
        'actual_unit_sold_per_txn': 'units_sold_per_transaction_vs_last_year',
        'actual_smac_sales': 'smac_sales_actual_sales_vs_last_year',
        'actual_space_nsa': 'space_nsa_actual_sales_vs_last_year',
    }

    const metricVs2019 = {
        'actual_sales': 'sales_vs_2019',
        'actual_transactions': 'transaction_vs_2019',
        'actual_basket_size': 'basket_size_vs_2019',
        'actual_unit_sold': 'basket_size_vs_2019',
        'actual_unit_sold_per_txn': 'units_sold_per_transaction_vs_2019',
        'actual_smac_sales': 'smac_sales_actual_sales_vs_2019',
        'actual_space_nsa': 'space_nsa_actual_sales_vs_2019',
    }

    const metricPrefix = {
        'actual_sales': '₱',
        'actual_transactions': '',
        'actual_basket_size': '₱',
        'actual_unit_sold': '',
        'actual_unit_sold_per_txn': '',
        'actual_smac_sales': '₱',
        'actual_space_nsa': '₱',
    }

    const { data: maxDate, isLoading: isLoadingMaxdate } = useAvailableMaxDate();
    const isCompletePeriod = checkIfCompletePeriod(params, maxDate, isQoQ)
    const hasLessColumns = !isCompletePeriod && (isMTD || isQoQ);

    const COLUMNS = useMemo(
        () => [
            {
                header: 'Business Unit',
                accessorKey: 'business_unit',
                cell: ({ row, getValue }) => {
                    return <Box sx={{ marginLeft: `${row.depth * 1}rem`, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                        {getValue() === 'Floating' || getValue() === null ? 'Others' : getValue()}
                        {row.original.subRows && (
                            <IconButton size='small' onClick={(event) => {
                                event.stopPropagation(); // Stop the click event from propagating
                                row.getToggleExpandedHandler()();
                            }}
                                sx={{ cursor: "pointer" }}
                            >
                                {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize='medium' /> :
                                    <ArrowRightRoundedIcon fontSize='medium' color='#e0e0e0' />}
                            </IconButton>
                        )}
                    </Box>
                },
                customStyle: { height: '2rem', width: '10rem' },
            },
            {
                header: '% cont.',
                accessorKey: 'sales_percent_contribution',
                // accessorKey: 'sales.percent_contribution', if data structure === option 2
                cell: ({ row, getValue }) => {
                    return <Box sx={{ width: { sm: '25%' }, marginX: 'auto' }}> <NumericValueDisplay value={getValue()} postfix={'%'} /> </Box>
                },
                customStyle: { width: '5rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: `${isMTD ? 'MTD' : isQoQ ? 'QoQ' : 'YTD'} ${metricHeader[selectedMetric]}`,
                accessorKey: metricTotals[selectedMetric],
                cell: ({ row, getValue }) => {
                    return <Box sx={{ width: { sm: hasLessColumns ? '20%' : '25%' }, marginX: 'auto', whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={metricPrefix[selectedMetric]} isAbbreviated={true} /> </Box>
                },
                customStyle: { width: '8rem', textAlign: 'left' }
            },
            {
                header: 'vs Last Year',
                id: 'vs_last_year',
                accessorKey: metricVsLy[selectedMetric],
                cell: ({ row, getValue }) => <IndicatorCellBox><BarIndicator value={getValue()?.toFixed()} isValueColorCoded={true} /> </IndicatorCellBox>,
                customStyle: { width: '8rem' }
            },
            {
                header: 'vs 2019',
                id: 'vs_2019',
                accessorKey: metricVs2019[selectedMetric],
                cell: ({ row, getValue }) => <IndicatorCellBox><BarIndicator value={getValue()?.toFixed()} isValueColorCoded={true} /> </IndicatorCellBox>,
                customStyle: { width: '8rem' }
            }
        ],
        [isMTD, selectedMetric, isQoQ]
    )

    if (isLoading) {
        return <Grid container sx={{ justifyContent: 'center' }}>
            <LoadingComponent />
        </Grid>
    }

    return <Grid container marginTop={1}>
        <Grid item sm={12} xs={12}>
            <Card sx={{ borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container sx={{ backgroundColor: '#f6f6f8', padding: 1.5 }}>
                    <Grid item sm={11} xs={11} display="flex" alignItems="center">
                        <Typography variant="h6">Business Unit Performance</Typography>
                    </Grid>
                    <Grid item sm={1} xs={1} >
                        <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                    </Grid>
                </Grid>
                <Collapse in={isExpand}>
                    <Grid container spacing={1}>
                        <Grid item sm={12} xs={12}>
                            <TableComponentV2
                                customTableContainerStyle={{
                                    borderRadius: "0",
                                    boxShadow: 'none',
                                }}
                                customTableHeaderStyle={{
                                    backgroundColor: '#caddeb'
                                }}
                                columns={COLUMNS}
                                data={finalMerchData}
                                tableId={'bu_performance'}
                                hasPagination={true}
                                isCompact={true}
                                initialState={{
                                    columnVisibility: {
                                        "vs_last_year": isCompletePeriod,
                                        "vs_2019": isCompletePeriod,
                                    }
                                }} />
                        </Grid>
                    </Grid>
                </Collapse>
            </Card>
        </Grid>
    </Grid>
}