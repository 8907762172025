import React from 'react';
import {Box} from "@mui/material";
import SubRowExpander from "./SubRowExpander";


export default function CellWithSubRow({row, children, isLoadingSubRows=false, boxStyle={}, marginLeftMinimum=0}) {
    const subRowLength = row.subRows?.length;
    return (
        <Box sx={{
            fontWeight: row.depth === 0 ? 'bold' : 'regular',
            marginLeft: `${(row.depth * 1) + marginLeftMinimum}rem`,
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            ...boxStyle,
        }}>
            {children}
            { subRowLength > 0 && <SubRowExpander row={row} isLoadingSubRows={isLoadingSubRows}/> }
        </Box>
    );
}