import IconIndicator from "./iconIndicator"
import { convertToValidNumber } from "../../../../../../common/utils"
import { WindowSizeContext } from "../../../../../../common/context/WindowSizeContext";
import { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import BarCellDisplay from "../../../../TmpAceSales/BarCellDisplay";
import BarIndicator from "../../barIndicator";
import NumericValueDisplay from "../../numericValueDisplay";
import { forceParseFloat } from "../../../util";
import { checkIfCompletePeriod } from "../../../../TmpTSSSales/util";
import { useAvailableMaxDateL3 } from "../../../../TmpTSSSales/DataProvider/hooks";


export default function Index({ value, label, labelValue, labelPosition, isBUindicator, params, period, isCustomerIndicator }) {
    const windowSize = useContext(WindowSizeContext);
    const { data: maxDate, isLoading: isLoadingMaxdate } = useAvailableMaxDateL3();
    const isMTD = period === 'mtd';
    const isQoQ = period === 'qoq';
    const isCompletePeriod = checkIfCompletePeriod(params, maxDate, isQoQ)

    if (labelPosition === 'bottom') {
        return <Grid container data-tag="allowRowEvents" xs={12}>
            <Grid item sm={12} xs={12} justifyContent="center" alignItems="center" sx={{ height: '2.5rem' }}>
                <BarCellDisplay
                    value={convertToValidNumber(value)} isValueColorCoded={true} />
            </Grid>

            <Grid item sm={12} xs={12} textAlign="center">
                ( <NumericValueDisplay value={forceParseFloat(labelValue)} prefix={'₱ '}
                    undefinedDisplay={'Not Applicable'} /> )
            </Grid>
        </Grid>
    } else if (labelPosition === 'right') {
        return <Grid container data-tag="allowRowEvents" xs={12}>
            <Grid item md={2.5} sm={2.5} xs={5} alignSelf={"center"} display={"flex"} justifyContent={"center"}>
                <Box sx={{ width: '100%', height: '2.5rem' }}>
                    <BarCellDisplay
                        value={convertToValidNumber(value)} isValueColorCoded={true} />
                </Box>
            </Grid>
            <Grid item md={9.5} sm={9.5} xs={7} alignSelf={"center"}>
                <Typography display={!windowSize.isOnMobile && 'flex'}>
                    {label}
                    {windowSize.isOnMobile && <br />}
                    <Typography sx={{ ml: 1, fontStyle: 'italic' }}>
                        (<NumericValueDisplay value={forceParseFloat(labelValue)} prefix={'₱ '}
                            undefinedDisplay={'Not Applicable'} />)
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    }

    if (isBUindicator) {
        const newValue = value === 'N/A' ? 'N/A' : convertToValidNumber(value);
        const isVsPlanIncomplete = label === 'vs Plan' && !isCompletePeriod;

        let indicatorComponent = null;

        if (newValue === 'N/A' || newValue === 0) {
            indicatorComponent = (
                <BarIndicator value={newValue === 'N/A' ? 'N/A' : '-'} isValueColorCoded={true} />
            );
        } else if (isVsPlanIncomplete) {
            indicatorComponent = (
                <BarIndicator componentType={'progress_bar'} value={newValue} />
            );
        } else {
            indicatorComponent = (
                <BarIndicator value={newValue} isValueColorCoded={true} />
            );
        }

        return <Grid container data-tag="allowRowEvents" my={0.5} justifyContent="space-around">
                <Grid item textAlign="center">
                    <Box sx={{ width: '4.5rem', height: '1.5rem' }}>
                        {indicatorComponent}
                    </Box>
                    <Typography variant="body2">{label}</Typography>
                </Grid>
            </Grid>
    }

    //vs periods (vs Last year & vs 2019) for customer topline scorecards
    if (isCustomerIndicator) {
        const newValue = value === 'N/A' ? 'N/A' : convertToValidNumber(value);

        return (
            <Grid container data-tag="allowRowEvents" my={0.5} justifyContent={'space-around'}>
                <Grid item textAlign={'center'} >
                    <Box sx={{ width: '4.5rem', height: '1.5rem' }}>
                        <BarIndicator
                            value={newValue} isValueColorCoded={true} />
                    </Box>
                    <Typography variant="body2">
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    return <Grid container data-tag="allowRowEvents" xs={12}>
        <Grid item alignSelf={"center"} height={"4rem"}>
            <IconIndicator data={convertToValidNumber(value)} />
        </Grid>
    </Grid>

}

