import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import InformationToolTip from "../../../../common/components/InformationToolTip";


export default function ZeroSalesToolTip() {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        const clearToolTipOnScroll = () => {
            // Close the tooltip when scrolling
            setTooltipOpen(false);
        };

        window.addEventListener('scroll', clearToolTipOnScroll);
        return () => {
            window.removeEventListener('scroll', clearToolTipOnScroll);
        };
    }, []);

    const infoForTooltip = [
        {
            title: "Zero Sales (Top 888 and Top 4k) (cont.)",
            info: [
                "Top 888 and Top 4k articles with zero sales for the selected number of consecutive days will be listed here.",
            ]
        },
    ]

    const informationContent = infoForTooltip.map((section, index) => (
        <Box key={index}>
            <Typography variant="body1" fontWeight="bold" my={0.5} mt={index === 0 ? 0 : 2}>{section.title}</Typography>
            <Divider sx={{ borderBottomWidth: '2px', backgroundColor: '#000' }} />
            <Typography variant="body2"> {section.info} </Typography>
        </Box>
    ))

    const handleTooltipChange = (value) => {
        setTooltipOpen(value);
    };

    return <InformationToolTip onChange={handleTooltipChange} open={tooltipOpen} information={informationContent} />
}