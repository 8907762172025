import React from 'react';


export default function BaseNumericDisplay({value, prefix, postfix, invalidDisplay = '-', notApplicableDisplay = 'N/A'}) {
    if (value === 'N/A') {
        return notApplicableDisplay;
    }
    if (value === undefined || value === null || isNaN(value) || !isFinite(value) || value === '') {
        return invalidDisplay;
    }

    return <>{prefix}{value}{postfix}</>
}