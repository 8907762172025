import React, {useContext, useMemo} from 'react';
import {Box} from '@mui/material'
import {WindowSizeContext} from '../../../../../common/context/WindowSizeContext';
import {
    generateColumnDefinition,
    getBUBasketSizeBranchView,
    getColumnValue,
    recursiveColumnPlacer,
    recursiveColumnsObjToArr, recursiveCountDepth, recursiveWrapColumns
} from './utils';
import {sortByBasketSize} from '../../util';
import TableCellPercentageBox from "./TableCellPercentageBox";

export const SoWColumns = ({ selectedColumn, data, isBranchView, selectedNested }) => {
    const windowSize = useContext(WindowSizeContext);

    const columnValue = getColumnValue(selectedColumn)
    const basketSizeBinKey = isBranchView ?
        getBUBasketSizeBranchView(selectedColumn) :
        'txn_basket_size_bin'

    const customSortOrder = [
        '500 and below',
        '501-1000',
        '1001-3000',
        '3001-5000',
        '5001-10k',
        '10k-15k',
        '15k-20k',
        '20k-50k',
        '50k and above',
    ]

    const columnNames = useMemo(() => {
        // get unique column names for display
        // if selectedNested is not empty, add them to the column names

        if (!data) return [];

        const detectedColumns = data.reduce((acc, cur) => {
            const key = cur[columnValue];
            if (!acc.includes(key)) {
                acc.push(key);
            }
            return acc;
        }, []);

        const filteredColumns = selectedNested || []

        return [
            ...detectedColumns,
            ...filteredColumns
        ].sort()
    }, [data])

    const transformedData = useMemo(() => {
        // transform the data from the api to the format required by the table
        // source data is in the format [{"Basket Size": "500 and below", "<category1>": "<value>", "perc_sales": 0.1}, ...]
        // transformed data would be in the format [{"Basket Size": "500 and below", "<category1>": 0.1, ...}, ...]
        if (data === undefined) return [];

        const intermediateData = customSortOrder.reduce((acc, basketSize) => {
            const dataForBasketSize = data.filter(entry => entry[basketSizeBinKey] === basketSize);
            acc[basketSize] = columnNames.reduce((acc2, key) => {
                acc2[key] = dataForBasketSize.find(entry => entry[columnValue] === key)?.perc_sales * 100 ?? null;
                return acc2;
            }, {});
            return acc
        }, {})

        return Object.keys(intermediateData).sort((a, b) => {
            return customSortOrder.indexOf(a) - customSortOrder.indexOf(b);
        }).reduce((acc, key) => {
            acc.push({
                ...intermediateData[key],
                "Basket Size": key
            });
            return acc;
        }, []);
    }, [data, columnValue, basketSizeBinKey])

    const columnSums = useMemo(() => {
        if (transformedData?.length === 0) return [];

        return columnNames.reduce((acc, cur) => {
            acc[cur] = transformedData.reduce((sum, entry) => {
                return sum + entry[cur];
            }, 0);
            return acc;
        }, {});
    }, [transformedData, columnNames]);

    const sow_columns = useMemo(() => {
        // based on the transformed data, column names and column sums, generate the columns for the table
        // if output column list is single level, sort the columns by sum of values (descending)
        // if output column list is nested, sort the columns by header name

        if (transformedData?.length === 0) return [];


        const nestedDynamicColumnNames = Object.values(columnNames.reduce((acc, cur) => {
            if (!cur) return acc;

            const split = cur.split('|');
            const key = split[0];
            if (split.length > 1) {
                const rest = split.slice(1).join('|');
                if (!acc.hasOwnProperty(key)) {
                    acc[key] = {
                        header: key,
                        headerStyle: {
                            backgroundColor:'#0A4FB4',
                            color: 'white',
                        }
                    };
                }
                acc[key] = recursiveColumnPlacer(acc[key], rest, cur);
            } else {
                acc[key] = generateColumnDefinition(key, cur);
            }
            return acc;
        }, {})).map(recursiveColumnsObjToArr).sort((a, b) => {
            // sort headers by sum of values if they are not nested
            if (!a.columns && !b.columns) {
                const aSum = columnSums[a.accessorKey];
                const bSum = columnSums[b.accessorKey];
                return bSum - aSum;

            }
            return a.header.localeCompare(b.header);
        });

        const basketSizeColumn = recursiveWrapColumns(
            {
                id: 'basketSize',
                header: 'Basket Size',
                accessorKey: 'Basket Size',
                cell: ({ row, getValue }) => (
                    <Box sx={{width: {sm: '7rem', xs: windowSize.isOnMobile? '5rem' : '20rem'}, marginX: '10px', justifyContent: 'center', alignItems: 'center',}}>{getValue()}</Box>
                ),
                customStyle: { width: '10rem', height: '2rem', textAlign: 'left', verticalAlign: 'middle', alignItems: 'center' },
                sortingFn: sortByBasketSize,
            },
            recursiveCountDepth(nestedDynamicColumnNames[0], 0)
        )

        return [
            basketSizeColumn,
            ...nestedDynamicColumnNames
        ]
    }, [transformedData, columnNames, columnSums]);

    return {data: transformedData, columns: sow_columns};
};
