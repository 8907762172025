import React, { useMemo } from "react";
import TableComponentV2 from "../../../../../../../common/components/TableComponentV2";
import { Box, IconButton } from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import NumericValueDisplay from "../../../numericValueDisplay";
import { convertNumberToLocaleString, convertToValidNumber } from "../../../../../../../common/utils";
import BarIndicator from "../../../barIndicator";
import { checkIfCompletePeriod, getMaxMetricTotalLength, groupArrayBy } from "../../../../../TmpTSSSales/util";
import { COLUMN_L3_DEPARTMENT, COLUMN_L3_SUBDEPT, useBUPerDepartmentDataL3 } from "../../DataProvider/hooks";
import { getParamsBasedOnPeriod } from "../../../../../Customers/util";
import { getMetricColumnHeader } from "../../../../../TmpTSSSales/DataProvider/util";
import { useSelectorValue } from "../../../../providers/selectorProvider";
import LoadingComponent from "../../../../../../../common/components/LoadingComponent";
import { useAvailableMaxDateL3 } from "../../../../../TmpTSSSales/DataProvider/hooks";


const calculateSmWidth = (maxLength, minWidth = 15) => {
    const baseWidth = minWidth; // Base width for smallest values
    const widthFactor = 1.6; // Factor to scale the width, adjust as needed
    const width = `${baseWidth + maxLength * widthFactor}%`;
    return width === 'NaN%' ? '15%' : width
};


export default function DepartmentPerformanceTable({ period, params, filters }) {
    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';
    const paramsBasedOnPeriod = getParamsBasedOnPeriod(isMTD, isQoQ, params)

    const selectorSelectedMetric = useSelectorValue('metric');

    const {
        perDeptData,
        isLoading: isLoadingBUData
    } = useBUPerDepartmentDataL3({
        params: {
            ...paramsBasedOnPeriod,
            group_by: `${COLUMN_L3_DEPARTMENT},${COLUMN_L3_SUBDEPT}`,
            columns: `${COLUMN_L3_DEPARTMENT},${COLUMN_L3_SUBDEPT}`,
            metric_column: selectorSelectedMetric ? selectorSelectedMetric : 'actual_sales'
        }
    });

    const displayMapping = {
        'department': 'department',
    }
    const displayKey = displayMapping['department'] || 'department'
    const newBUPerformanceData = React.useMemo(() => {
        const groupedData = groupArrayBy(perDeptData, displayKey)
        const sumKeys = ['metricTotal', 'contribution', 'metricTotal', 'vsLY', 'units_sold', 'unit_price']
        return Object.keys(groupedData).map((key) => {
            return groupedData[key][0]
        })
    }, [perDeptData, displayKey])

    const { data: maxDate, isLoading: isLoadingMaxdate } = useAvailableMaxDateL3();
    const isCompletePeriod = checkIfCompletePeriod(params, maxDate, isQoQ)
    const maxMetricCountLength = getMaxMetricTotalLength(newBUPerformanceData, 'metricTotal', selectorSelectedMetric);
    const maxUnitPriceCountLength = getMaxMetricTotalLength(newBUPerformanceData, 'unit_price', 'actual_unit_price');

    const countBoxMetricWidth = calculateSmWidth(maxMetricCountLength, isCompletePeriod ? 13 : 10);
    const countBoxUnitPriceWidth = calculateSmWidth(maxUnitPriceCountLength, isCompletePeriod ? 15 : 10);
    const metricHeaderLabel = getMetricColumnHeader(period, selectorSelectedMetric)

    const store_performance_col = useMemo(() => {
        return [
            {
                id: 'department',
                header: 'Department',
                accessorKey: 'department',
                cell: ({ row, getValue }) => {
                    return (
                        <Box sx={{
                            marginLeft: `${row.depth * 1}rem`,
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                            lineHeight: "2.1rem"
                        }}>
                            {getValue()}
                            {row?.original?.subRows && (
                                < IconButton size='small' onClick={(event) => {
                                    event.stopPropagation(); // Stop the click event from propagating
                                    row.getToggleExpandedHandler()();
                                }}
                                    sx={{ cursor: "pointer" }}
                                >
                                    {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize='medium' /> :
                                        <ArrowRightRoundedIcon fontSize='medium' color='#e0e0e0' />}
                                </IconButton>
                            )}
                        </Box>
                    )
                },
                // sortingFn: (rowA, rowB) => {
                //     if (orderArray) {
                //         return sortByOrder(rowA.original, rowB.original, orderArray, filters?.group)
                //     } else {
                //         return rowA?.getValue() < rowB?.getValue() ? 1 : -1
                //     }
                // },
                customStyle: { width: '16rem' }
            },
            {
                id: 'metricTotal',
                header: metricHeaderLabel,
                accessorKey: 'metricTotal',
                cell: (row) => {
                    const hasPesoSign = ['actual_sales', 'actual_basket_size', 'actual_space_nsa', 'actual_smac_sales']
                    const metricFilters = selectorSelectedMetric;
                    const prefix = hasPesoSign?.includes(metricFilters) ? '₱' : '';
                    return (
                        <Box sx={{ width: { sm: `${countBoxMetricWidth}` }, marginX: 'auto', textWrap: 'nowrap' }}>
                            <NumericValueDisplay value={row?.getValue()} isAbbreviated={true} prefix={prefix} isThousandsOnly={true} />
                        </Box>
                    )
                },
                customStyle: { width: '16rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                id: 'contribution',
                header: '% Contribution',
                accessorKey: 'contribution',
                cell: (row) => {
                    const metricWithNAContribution = ['actual_basket_size', 'actual_transactions', 'actual_space_nsa']
                    const metricIsNA = metricWithNAContribution?.includes(selectorSelectedMetric)
                    const value = metricIsNA ? 'N/A' : row?.getValue()
                    const postFix = value ? '%' : ''
                    return <NumericValueDisplay value={value} postfix={postFix} />
                },
                customStyle: { width: '10rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                id: 'vs_last_year',
                header: 'vs Last Year',
                accessorKey: 'vsLY',
                cell: (row) => <>
                    {convertNumberToLocaleString(row?.getValue()) !== 0 ?
                        <Box sx={{
                            width: '3rem',
                            height: '1.5rem',
                            marginX: 'auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <BarIndicator value={convertToValidNumber(row?.getValue())?.toFixed()}
                                isValueColorCoded={true} isOnTable={true} label="vs last year" />
                        </Box> : '-'
                    }
                </>,
                customStyle: { width: '8rem', height: '2rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: 'Unit Price',
                accessorKey: 'unit_price',
                id: 'unit_price',
                cell: (row) => {
                    return (
                        <Box sx={{ width: { sm: countBoxUnitPriceWidth }, marginX: 'auto' }}>
                            <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={true} />
                        </Box>
                    );
                },
                customStyle: { width: '16rem', textAlign: 'left', verticalAlign: 'middle' }
            },
        ]
    },
        [period, countBoxMetricWidth, countBoxUnitPriceWidth, metricHeaderLabel]
    )

    if (isLoadingBUData) {
        return <LoadingComponent />
    }

    return (
        <TableComponentV2
            customTableContainerStyle={{
                borderRadius: "0",
                boxShadow: 'none',
            }}
            customTableHeaderStyle={{
                backgroundColor: '#caddeb'
            }}
            columns={store_performance_col}
            data={newBUPerformanceData}
            tableId={'DepartmentPerformanceTable'}
            // hasPagination={true}
            isCompact={true}
            initialSort={{ id: 'metricTotal', desc: true }}
            initialState={{
                columnVisibility: {
                    // "vs_last_year": isCompletePeriod,
                    // "vs_2019": isCompletePeriod,
                    "12th_mth_sales_trend": !isMTD && !isQoQ
                }
            }}
            enableSortingRemoval={true}
        />
    )
}