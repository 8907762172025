import React, {useEffect} from "react";
import Filters from "../filters";
import {TABLE_CUSTOMER_ATTRIBUTES} from "../../../Customers/constants";
import {CUSTOMER_FILTER_DEFINITIONS} from "./constants";
import {Grid} from "@mui/material";
import FilterMonth from "../../../../../common/components/FilterMonth";
import FilterYear from "../../../../../common/components/FilterYear";
import {useFilterValues} from "../../../../../common/hooks/commonDataHooks";
import {convertParamsToTranDate, getParamsBasedOnPeriod, preProcessParams} from "../../../Customers/util";
import {useSelectorState} from "../../providers/selectorProvider";
import {useFiltersContext, useTogglePeriodContext} from "./Provider/context";
import {useParams} from "react-router-dom";


export default function CustomerFilters(
    {tableName=TABLE_CUSTOMER_ATTRIBUTES}
) {
    const {business_unit} = useParams()
    const {period, updatePeriod} = useTogglePeriodContext();

    const [year, setYear] = useSelectorState('year');
    const [month, setMonth] = useSelectorState('month');
    const [isFiltersOpen, setIsFiltersOpen] = useSelectorState('isFiltersOpen', false);

    const {filters, setFilter} = useFiltersContext();

    const {data: dateRangeRaw, isLoading: isLoadingDateRange, isSuccess: isSuccessDateRange} = useFilterValues(
        tableName,
        "header_tran_date",
        {
            pm_business_unit_desc_standardized: business_unit
        }
    )


    const dateRange = dateRangeRaw?.header_tran_date ?
        {
            ...dateRangeRaw?.header_tran_date,
            from: dateRangeRaw?.header_tran_date?.min,
            to: dateRangeRaw?.header_tran_date?.max
        } :
        {}


    useEffect(() => {
        if (!month || !month.length) {
            if (dateRange.max) {
                setMonth([new Date(dateRange.max).getMonth() + 1])
                setYear(new Date(dateRange.max).getFullYear())
            }
        }
    }, [year, month, dateRange])


    const isMTD = period === 'mtd';
    const isQoQ = period === 'qoq';

    const params = {
        month: [Number(month)],
        year: Number(year),
        pm_business_unit_desc_standardized: business_unit,
    }
    // parameters for the filters
    const filterQueryParams = preProcessParams(
        convertParamsToTranDate(getParamsBasedOnPeriod(isMTD, isQoQ, {
            ...params
        }))
    );

    if (!params.year || !params.month) {
        return null;
    }


    return (
        <Grid item xs={12}>
            <Filters
                isOpen={isFiltersOpen} setIsOpen={setIsFiltersOpen}
                filters={filters} setFilter={setFilter} params={filterQueryParams}
                tableName={tableName}
                filterDefinitions={CUSTOMER_FILTER_DEFINITIONS}>

                <Grid item xs={12} sm={6}>
                    <FilterMonth
                        onDropdownChange={(value) => {  // ignore the second argument
                            setMonth(value)
                        }}
                        filters={{year, month}}
                        dateRangeData={dateRange}
                        dateRangeIsLoading={isLoadingDateRange}
                        isSuccess={isSuccessDateRange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FilterYear
                        onDropdownChange={(value) => {  // ignore the second argument
                            setYear(value)
                        }}
                        filters={{year, month}}
                        dateRangeData={dateRange}
                        isLoading={isLoadingDateRange}
                        isSuccess={isSuccessDateRange}
                    />
                </Grid>
            </Filters>
        </Grid>
    )
}