import React, {useMemo} from "react";
import CellWithSubRow from "../../../../../common/components/DataTable/CellWithSubRow";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import {LABEL_ALL_BRANCH, useBasketSizeTableData} from "../../hooks";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import { Box } from "@mui/material";
import {sortByBasketSize} from "../../../Customers/util";
import NoAvailableDataDisplay from "../../NoAvailableDataDisplay";
import { useSelectorValue } from "../../../common/providers/selectorProvider";
import {IndicatorCellBox} from "../../../Customers/Transactions/BUGroupShareOfWallet";
import BarIndicator from "../../../common/presentation/barIndicator";
import {convertToValidNumber} from "../../../../../common/utils";


const thickBorder = '2px solid #C3C3C3!important'
const customCellStyle = {
    width: "10%",
    textAlign: "left",
    verticalAlign: "middle",
}

export default function AverageSpendPerVisitTable() {
    const {data, isLoading} = useBasketSizeTableData()
    const branch = useSelectorValue('branch')
    const sortedData = useMemo(() => {
        if (!data) return [];

        return data.sort(sortByBasketSize)
    }, [data]);

    const tableColumns = useMemo(
        () => {

            const vsLyCellFn = (row) => {
                const val = row?.getValue();
                return <IndicatorCellBox>
                    {(val !== null && val !== Infinity && !isNaN(val)) ?
                        <BarIndicator colorFunctionFor={'SBU'}
                                      value={convertToValidNumber(val)?.toFixed(1)}
                                      isValueColorCoded={true} isOnTable={true} label="vs last year"/>
                        : '-'
                    }
                </IndicatorCellBox>
            }


            return [
                {
                    id: "pk",
                    header: "Basket Size Tiers",
                    accessorKey: "pk",
                    cell: ({row, getValue}) => {
                        const value = getValue();
                        const boxStyle = { fontWeight:'regular'}
                        return (
                            <CellWithSubRow boxStyle={boxStyle} row={row} marginLeftMinimum={1}>
                                {value}
                            </CellWithSubRow>
                        )
                    },
                    customStyle: {
                        width: "15rem",
                        textAlign: "left",
                        verticalAlign: "middle",
                        borderRight: thickBorder
                    },
                    headerStyle: {
                        textAlign: "center",
                        borderRight: thickBorder,
                    },
                    sortingFn: (a, b) => {
                        return sortByBasketSize(a.original, b.original)
                    }
                },

                {
                    id: "branch",
                    header: branch === 'All Branch' ? LABEL_ALL_BRANCH : branch,
                    columns: [
                        {
                            id: "customer_count",
                            header: "Customer Count",
                            accessorKey: "member_count",
                            cell: (row) => {
                                return <Box sx={{width: {sm: '5em'}, marginX: 'auto', my: .5}}>
                                    <span style={{textWrap: 'nowrap'}}>
                                        <NumericValueDisplay value={row.getValue()} isAbbreviated={true}/>
                                    </span>
                                </Box>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },

                        {
                            id: "customer_count_vs_ly",
                            header: "vs LY",
                            accessorFn: (row) => {
                                return row.member_count / row.member_count_ly * 100
                            },
                            cell: vsLyCellFn,
                            customStyle: {
                                ...customCellStyle,
                                borderRight: thickBorder
                            },
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                                borderRight: thickBorder,
                            }
                        },

                        {
                            id: "total_transaction_count",
                            header: "Total Transaction Count",
                            accessorKey: "txn",
                            cell: (row) => {
                                return <Box sx={{width: {sm: '5em'}, marginX: 'auto', my: .5}}>
                                    <span style={{textWrap: 'nowrap'}}>
                                        <NumericValueDisplay value={row.getValue()} isAbbreviated={true}/>
                                    </span>
                                </Box>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },
                        {
                            id: "total_transaction_count_ly",
                            header: "vs LY",
                            accessorFn: (row) => {
                                return row.txn / row.txn_ly * 100
                            },
                            cell: vsLyCellFn,
                            customStyle: {
                                ...customCellStyle,
                                borderRight: thickBorder
                            },
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                                borderRight: thickBorder,
                            }
                        },
                        {
                            id: "total_sales",
                            header: "Total Sales",
                            accessorKey: "sales",
                            cell: (row) => {
                                return <Box sx={{width: {sm: '5em'}, marginX: 'auto', my: .5}}>
                                    <span style={{textWrap: 'nowrap'}}>
                                        <NumericValueDisplay value={row.getValue()} isAbbreviated={true}/>
                                    </span>
                                </Box>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },
                        {
                            id: "total_sales_ly",
                            header: "vs LY",
                            accessorFn: (row) => {
                                return row.sales / row.sales_ly * 100
                            },
                            cell: vsLyCellFn,
                            customStyle: {
                                ...customCellStyle,
                                borderRight: thickBorder
                            },
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                                borderRight: thickBorder,
                            }
                        },
                        {
                            id: "perc_sales",
                            header: "% Sales Distribution",
                            accessorKey: "sales_perc",
                            cell: (row) => {
                                return <Box sx={{width: {sm: '3em'}, marginX: 'auto', my: .5}}>
                                    <span style={{textWrap: 'nowrap'}}>
                                        <NumericValueDisplay value={row.getValue()} isAbbreviated={false} postfix={"%"}/>
                                    </span>
                                </Box>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            }
                        },
                        {
                            id: "perc_sales_ly",
                            header: "vs LY",
                            accessorFn: (row) => {
                                return row.sales_perc / row.sales_perc_ly * 100
                            },
                            cell: vsLyCellFn,
                            customStyle: {
                                ...customCellStyle,
                            },
                            headerStyle: {
                                backgroundColor: "#E7F1F8",
                            }
                        },
                    ]
                }
            ]
        },
        [branch]
    );

    if (isLoading) {
        return <LoadingComponent/>
    }

    if (!data || data?.length === 0) {
        return <NoAvailableDataDisplay />
    }

    return <TableComponentV2
        columns={tableColumns}
        data={sortedData || []}
        tableId="averageSpendPerVisit"
        isCompact
        customTableHeaderStyle={{backgroundColor: "#caddeb", textAlign: "center", height: "3em"}}
        customTableContainerStyle={{borderRadius: 0, boxShadow: "none"}}
        hasPagination={false}
    />

}