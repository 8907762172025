import {useQuery} from "react-query";
import {convertParamsToTransactionDate, preProcessParams, regroupDataBy} from "./util";
import ApiClient from "../../../common/API";
import moment from "moment/moment";
import {useMemo} from "react";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../Constants/settings";


export const TABLE_NAME = "l4_frg_sap_sales";

function exclude888And4k(params) {
    const isNotGroupedByProductGroup = !(params && params.group_by === "product_group")
    if (isNotGroupedByProductGroup) {
        return {
            ...params,
            product_group__not_in: params?.product_group?.length > 0 ? null : "888,888 (Outright),4k"
        }
    } else {
        return params
    }
}


export function useSalesData({params}) {
    const isNotGroupedByProductGroup = !(params && params.group_by === "product_group")
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year

    const {data: availableDateRange, isLoading: isLoadingDateRange} = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const {data: actualSalesData, isLoading: isLoadingSalesData} = useQuery(
        ['markets', "sales", "overall", params, 'actual_sales'],
        () => {
            const processedParams = exclude888And4k(
                preProcessParams(
                    convertParamsToTransactionDate(params, dayOfMonthMaxDate)
                )
            );
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: "actual_sales_sum"
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange
        }
    );

    const monthRangeTransactionDates = useMemo(() => {
        const tmpParams = {}
        if (params.month && params.year && params?.month?.length > 0) {

            const maxMonth = Math.max(...params.month)
            const minMonth = Math.min(...params.month)

            const monthRange = Array.from({length: maxMonth - minMonth + 1}, (_, i) => minMonth + i)

            // get the first date of each month selected
            tmpParams.transaction_date__in = monthRange.map(month => {
                const monthString = month.toString().padStart(2, '0');
                const dateString = `${params.year}-${monthString}-01`;
                return moment(dateString).format('YYYY-MM-DD')
            })
        } else {
            // if the range is not specified, then get the first date of current month
            tmpParams.transaction_date__in = [moment().startOf('month').format('YYYY-MM-DD')]
        }
        return tmpParams
    }, [isQueryingForLatestMonth, params?.month, params?.year])

    const {data: targetData, isLoading: isLoadingTargetData} = useQuery(
        ['markets', "sales", "overall", params, 'target_sales', monthRangeTransactionDates],
        () => {
            const newParams = {
                ...params,
                ...monthRangeTransactionDates,
                limit: 10000
            }

            // add group by transaction_date, checking first if group_by is already added
            if (newParams.group_by) {
                newParams.group_by = `${newParams.group_by},transaction_date`
            } else {
                newParams.group_by = "transaction_date"
            }
            if (newParams.columns) {
                newParams.columns = `${newParams.columns},transaction_date`
            } else {
                newParams.columns = "transaction_date"
            }

            const processedParams = preProcessParams(newParams);
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    view: "target_sales",
                    aggregates: "target_sales_sum"
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: isNotGroupedByProductGroup
                && hasTransactionDate
                && (params?.product_group?.length === 0 || !params?.product_group) // only query if group_by is not product_group
        }
    )

    const targetData2 = useMemo(() => {
        // remove the transaction_date from the group_by
        if (targetData && targetData.length > 0) {
            const keys = Object.keys(targetData[0]).filter(key => key !== "transaction_date" && key !== "target_sales_sum")
            // aggregate up the target_sales_sum
            return targetData.reduce((acc, row) => {
                const existsInAcc = acc.some(accRow => {
                    return keys.every(key => accRow[key] === row[key])
                })
                if (!existsInAcc) {
                    const newRow = {
                        ...row
                    }
                    const sumOfTargetSalesSum = targetData.filter(row2 => {
                        return keys.every(key => row2[key] === row[key])
                    }).reduce((acc, row2) => {
                        return acc + row2.target_sales_sum
                    }, 0)
                    newRow.target_sales_sum = sumOfTargetSalesSum
                    acc.push(newRow)
                }
                return acc
            }, [])
        }
    }, [targetData])


    // query for last year total using the same date range with only the year changed
    const {data: lastYearData, isLoading: isLoadingYearData} = useQuery(
        ['markets', "sales", "overall", params, 'last_year', dayOfMonthMaxDate],
        () => {
            const lastYearParams = {
                ...params,
            }
            if (lastYearParams.year) {
                lastYearParams.year = lastYearParams.year - 1;
            }

            const processedParams = exclude888And4k(
                preProcessParams(
                    convertParamsToTransactionDate(
                        lastYearParams, dayOfMonthMaxDate
                    )
                )
            );
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: "actual_sales_sum"
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate
        }
    );

    // Daily Target
    const {data: dailyTargetData, isLoading: isLoadingDailyTargetData} = useQuery(
        ['markets', "sales", "overall", params, 'daily_target',dayOfMonthMaxDate],
        () => {
            const processedParams = preProcessParams(
                    convertParamsToTransactionDate(params, dayOfMonthMaxDate)
                )
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    aggregates: "target_sales_daily_sum",
                    view: "target_sales_daily"
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && !isLoadingDateRange && isNotGroupedByProductGroup && isQueryingForLatestMonth
        }
    );

    const dailyTargetData2 = useMemo(() => {
        if (isQueryingForLatestMonth) {
            if (!dailyTargetData || dailyTargetData.length === 0) {
                return null
            }

            if (monthRangeTransactionDates?.transaction_date__in?.length === 1) {
                return dailyTargetData
            } else {
                if (!targetData || targetData.length === 0) {
                    return null
                }

                const maxTransactionDate = Math.max(...monthRangeTransactionDates?.transaction_date__in?.map(date => moment(date).date()))
                const filteredTargetData = targetData?.filter(row => {
                    return moment(row.transaction_date).date() === maxTransactionDate
                });

                const keys = Object.keys(dailyTargetData[0])
                return dailyTargetData.map(row => {
                    const filteredSum = filteredTargetData.filter(filteredRow => {
                        return keys.every(key => filteredRow[key] === row[key])
                    }).reduce((acc, filteredRow) => {
                        return acc + filteredRow.target_sales_sum
                    }, 0)
                    return {
                        ...row,
                        target_sales_daily_sum: filteredSum + row.target_sales_daily_sum
                    }
                })
            }
        } else {
            if (!targetData || targetData.length === 0) {
                return null
            }
            // use the targetData2 instead
            return targetData2.map(row => {
                return {
                    ...row,
                    target_sales_daily_sum: row.target_sales_sum
                }
            })
        }

    }, [dailyTargetData, targetData2, monthRangeTransactionDates, isQueryingForLatestMonth])

    return {
        actualSalesData,
        targetData: targetData2,
        lastYearData,
        dailyTargetData: dailyTargetData2,
        isLoading: isLoadingSalesData || isLoadingTargetData || isLoadingYearData || isLoadingDailyTargetData
    }
}

export function useSalesOverallPerformanceData({params}) {
    const {
        actualSalesData,
        targetData,
        lastMonthData,
        lastYearData,
        dailyTargetData,
        isLoading
    } = useSalesData({params});

    const actualSalesTotal = actualSalesData ? actualSalesData[0]?.actual_sales_sum : 0;
    const targetTotal = targetData ? targetData[0]?.target_sales_sum : 0;
    const lastMonthTotal = lastMonthData ? lastMonthData[0]?.actual_sales_sum : 0;
    const lastYearTotal = lastYearData ? lastYearData[0]?.actual_sales_sum : 0;
    const dailyTargetTotal = dailyTargetData ? dailyTargetData[0]?.target_sales_daily_sum : 0;

    return {
        actualSales: actualSalesTotal,
        targetSales: targetTotal,
        lastMonthSales: lastMonthTotal,
        lastYearSales: lastYearTotal,
        dailyTargetSales: dailyTargetTotal,
        isLoading: isLoading
    }
}

export function useSalesPerformanceData({params}) {
    // require group_by
    if (!params || !params.group_by) {
        throw new Error("group_by is required")
    }

    const {
        actualSalesData,
        targetData,
        lastYearData,
        dailyTargetData,
        isLoading: isSalesLoading
    } = useSalesData({
        params: {
            columns: params.group_by,
            limit: 100,
            ...params,
        }
    });

    const {group_by, ...rest} = params  //remove group_by key in the params

    const {
        actualSales: actualTotalSalesData,
        isLoading: isLoadingTotalSalesData
    } = useSalesOverallPerformanceData({params: rest})
    const isLoading = isSalesLoading || isLoadingTotalSalesData
    const data = useMemo(() => {
        let groupBy = params.group_by;
        let otherGroupByKeys = [];
        if (!groupBy) {
            return []
        }

        if (groupBy?.includes(',')) {
            const multiGroupBy = groupBy.split(',')
            groupBy = multiGroupBy[0]
            multiGroupBy.shift();
            otherGroupByKeys = [...multiGroupBy]

        }
        const actualSalesGrouped = regroupDataBy(actualSalesData, groupBy, "actual_sales_sum");
        const targetGrouped = regroupDataBy(targetData, groupBy, "target_sales_sum");
        const lastYearGrouped = regroupDataBy(lastYearData, groupBy, "actual_sales_sum");
        const dailyTargetGrouped = regroupDataBy(dailyTargetData, groupBy, "target_sales_daily_sum");

        const actualSalesGroupedData = regroupDataBy(actualSalesData, groupBy)
        const targetGroupedData = regroupDataBy(targetData, groupBy)
        const lastYearGroupedData = regroupDataBy(lastYearData, groupBy)
        const dailyTargetGroupedData = regroupDataBy(dailyTargetData, groupBy)

        const allKeys = new Set([
            ...Object.keys(actualSalesGrouped),
            ...Object.keys(targetGrouped),
            ...Object.keys(lastYearGrouped),
            ...Object.keys(dailyTargetGrouped)
        ]);

        return [...allKeys].map(key => {
            // consolidate all groupedData into 1 object
            const actualSalesGroupedDataObj = actualSalesGroupedData?.[key]
            const targetGroupedDataObj = targetGroupedData?.[key]
            const lastYearGroupedDataObj = lastYearGroupedData?.[key]
            const dailyTargetGroupedDataObj = dailyTargetGroupedData?.[key]

            const tempObj = {}
            otherGroupByKeys?.forEach(grpKey => {
                tempObj[grpKey] = actualSalesGroupedDataObj?.[grpKey]
                if (!tempObj[grpKey]) {
                    tempObj[grpKey] = targetGroupedDataObj?.[grpKey]
                }
                if (!tempObj[grpKey]) {
                    tempObj[grpKey] = lastYearGroupedDataObj?.[grpKey]
                }
                if (!tempObj[grpKey]) {
                    tempObj[grpKey] = dailyTargetGroupedDataObj?.[grpKey]
                }
            });
            return {
                [groupBy]: key,
                ...tempObj,
                actual_sales: actualSalesGrouped[key],
                target_sales: targetGrouped[key],
                last_year_sales: lastYearGrouped[key],
                daily_target_sales: dailyTargetGrouped[key],
                percent_contribution: actualSalesGrouped[key] && actualTotalSalesData ? (actualSalesGrouped[key] / actualTotalSalesData) * 100 : null
            }
        })

    }, [params, actualSalesData, targetData, lastYearData, actualTotalSalesData, isLoading])

    return {
        data,
        isLoading
    }
}


const useSellingFloorData = ({params, options}) => {
    return useQuery(
        ['markets', "sales", "overall", params, 'selling_floor'],
        () => {

            const newParams = {
                ...params,
            }
            if (params.month && params.year && params.month?.length > 0) {
                const maxMonth = Math.max(...params.month)
                const monthString = maxMonth.toString().padStart(2, '0');
                const dateString = `${params.year}-${monthString}-01`;
                newParams.transaction_date__in = [moment(dateString).format('YYYY-MM-DD')]
            } else {
                // if the range is not specified, then get the first date of current month
                newParams.transaction_date__in = [moment().startOf('month').format('YYYY-MM-DD')]
            }

            const processedParams = preProcessParams(newParams)
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    columns: params.group_by,
                    aggregates: ["selling_floor_sum"].join(","),
                    view: 'selling_floor'
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            ...options
        }
    );
}

function useAveragePerformanceDataInternal({params, dayOfMonthMaxDate, enabled}) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year
    const isNotGroupedByProductGroup = !(params && params.group_by === "product_group")
    if (!params || !params.group_by) {
        throw new Error("group_by is required")
    }

    const {
        data: sellingFloorData, isLoading: isLoadingSellingFloor
    } = useSellingFloorData({
        params,
        options: {
            enabled: isNotGroupedByProductGroup && hasTransactionDate && enabled,
        }
    });


    const query = useQuery(
        ['markets', "sales", "average", params, "current"],
        () => {
            const processedParams = exclude888And4k(
                preProcessParams(
                    convertParamsToTransactionDate(params, dayOfMonthMaxDate)
                )
            );
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    columns: params.group_by,
                    aggregates: ["actual_sales_sum", "branch_id_count_distinct", "transaction_date_count_distinct", "transaction_count_sum"].join(","),
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && (!isNotGroupedByProductGroup || sellingFloorData?.length > 0) && enabled
        }
    );

    const numberOfMonths = params?.month?.length;

    const data = useMemo(() => {
        if (isLoadingSellingFloor || query.isLoading || !query.data) {
            return []
        }

        const selling_floor_map = regroupDataBy(sellingFloorData, params.group_by, 'selling_floor_sum');
        return query.data.map(item => {
            const selling_floor = selling_floor_map[item[params.group_by]];
            return {
                ...item,
                average_monthly_store_sales: item.actual_sales_sum / item.branch_id_count_distinct / numberOfMonths,
                average_daily_store_sales: item.actual_sales_sum / item.branch_id_count_distinct / item.transaction_date_count_distinct,
                average_daily_store_transactions: item.transaction_count_sum / item.branch_id_count_distinct / item.transaction_date_count_distinct,
                average_basket_size: item.actual_sales_sum / item.transaction_count_sum,
                average_monthly_sales_per_sqm: item.actual_sales_sum / selling_floor / numberOfMonths,
            }
        })

    }, [params.group_by, sellingFloorData, query.data, isLoadingSellingFloor, query.isLoading, numberOfMonths])

    return {
        ...query,
        data: data,
        isLoading: query.isLoading || (isNotGroupedByProductGroup && isLoadingSellingFloor)
    }
}


export function useAveragePerformanceData({params}) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year

    const {data: availableDateRange, isLoading: isLoadingDateRange} = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const {
        data: averageCurrentSalesData,
        isLoading: isLoadingCurrentSalesData
    } = useAveragePerformanceDataInternal({
        params,
        dayOfMonthMaxDate,
        enabled: hasTransactionDate && !isLoadingDateRange
    });
    const {
        data: averageLastYearSalesData,
        isLoading: isLoadingLastYearSalesData
    } = useAveragePerformanceDataInternal({
        params: {
            ...params,
            year: params.year ? params.year - 1 : null
        },
        dayOfMonthMaxDate,
        enabled: hasTransactionDate && !isLoadingDateRange
    });

    const isLoading = isLoadingCurrentSalesData || isLoadingLastYearSalesData;

    const data = useMemo(() => {
        if (isLoading) {
            return []
        }
        // TODO: handle errors
        let groupBy = params.group_by;
        if (!groupBy) {
            return []
        }

        if (groupBy?.includes(',')) {
            const multiGroupBy = groupBy.split(',')
            groupBy = multiGroupBy[0]
        }
        const averageCurrentSalesGrouped = regroupDataBy(averageCurrentSalesData, groupBy);
        const averageLastYearSalesGrouped = regroupDataBy(averageLastYearSalesData, groupBy);

        const allKeys = new Set([
            ...Object.keys(averageCurrentSalesGrouped),
            ...Object.keys(averageLastYearSalesGrouped)
        ])

        return [...allKeys].map(key => {
            return {
                [groupBy]: key,
                current: averageCurrentSalesGrouped[key],
                last_year: averageLastYearSalesGrouped[key]
            }
        })

    }, [params.group_by, averageCurrentSalesData, averageLastYearSalesData, isLoading])

    return {
        data,
        isLoading
    }
}


export function useAvailableDateRange() {
    return useQuery(
        ['markets', 'sales', "date_range"],
        () => ApiClient().get(`data/table/${TABLE_NAME}/filter_values/`, {
            params: {
                columns: "transaction_date"
            }
        }).then(res => {
            return res.data.transaction_date
        }), {
            ...USE_QUERY_DEFAULT_OPTIONS
        }
    );
}


function useStorePerformanceDataInternal({params, dayOfMonthMaxDate, enabled}) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year
    const isNotGroupedByProductGroup = !(params && params.group_by === "product_group")
    const sellingFloorQueryEnabled = isNotGroupedByProductGroup && hasTransactionDate && (params?.product_group?.length === 0 || !params?.product_group) && enabled;
    if (!params || !params.group_by) {
        throw new Error("group_by is required")
    }
    const {
        data: sellingFloorData, isLoading: isLoadingSellingFloor
    } = useSellingFloorData({
        params,
        options: {
            enabled: sellingFloorQueryEnabled,
        }
    });


    const query = useQuery(
        ['markets', "sales", "average", params, "current"],
        () => {
            const processedParams = exclude888And4k(
                preProcessParams(
                    convertParamsToTransactionDate(params, dayOfMonthMaxDate)
                )
            );
            return ApiClient().get(`data/table/${TABLE_NAME}/query/`, {
                params: {
                    ...processedParams,
                    columns: params.group_by,
                    aggregates: ["actual_sales_sum", "branch_id_count_distinct", "transaction_date_count_distinct", "transaction_count_sum"].join(","),
                }
            }).then(res => {
                return res.data.data
            })
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            enabled: hasTransactionDate && (!isNotGroupedByProductGroup || sellingFloorData?.length > 0 || !sellingFloorQueryEnabled) && enabled
        }
    );

    const numberOfMonths = params?.month?.length;

    const data = useMemo(() => {
        if (query.isLoading || !query.data) {
            return []
        }
        if (isLoadingSellingFloor) {
            return query.data
        }

        const groupBy = params.group_by.split(',')[0];
        const selling_floor_map = regroupDataBy(sellingFloorData, groupBy, 'selling_floor_sum');

        return query.data.map(item => {

            const selling_floor = selling_floor_map[item[groupBy]];

            return {
                ...item,
                average_daily_store_sales: item.actual_sales_sum / item.branch_id_count_distinct / item.transaction_date_count_distinct,
                average_monthly_sales_per_sqm: item.actual_sales_sum / selling_floor / numberOfMonths,
                ads_sales_per_sqm: item.actual_sales_sum / selling_floor / item.transaction_date_count_distinct,
                selling_floor: selling_floor
            }
        })

    }, [params.group_by, sellingFloorData, query.data, isLoadingSellingFloor, query.isLoading, numberOfMonths])

    return {
        ...query,
        data: data,
        isLoading: query.isLoading || (isNotGroupedByProductGroup && isLoadingSellingFloor)
    }
}

export function useStorePerformanceData({params}) {
    const hasTransactionDate = params?.month?.length > 0 && !!params?.year
    const groupBy = params.group_by
    const {data: availableDateRange, isLoading: isLoadingDateRange} = useAvailableDateRange();
    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasTransactionDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const {
        targetData,
        actualSalesData,
        dailyTargetData,
        isLoading: isLoadingStoreSalesData,
    } = useSalesData({
        params: {
            columns: groupBy,
            ...params,
        }
    });

    const {
        data: averageCurrentSalesData,
        isLoading: isLoadingCurrentSalesData
    } = useStorePerformanceDataInternal({
        params,
        dayOfMonthMaxDate,
        enabled: hasTransactionDate && !isLoadingDateRange
    });
    const {
        data: averageLastYearSalesData,
        isLoading: isLoadingLastYearSalesData
    } = useStorePerformanceDataInternal({
        params: {
            ...params,
            year: params.year ? params.year - 1 : null
        },
        dayOfMonthMaxDate,
        enabled: hasTransactionDate && !isLoadingDateRange
    });

    const isLoading = isLoadingCurrentSalesData || isLoadingStoreSalesData || isLoadingLastYearSalesData;

    const data = useMemo(() => {
        if (isLoading) {
            return []
        }
        // TODO: handle errors
        let groupBy = params.group_by;
        if (!groupBy) {
            return []
        }

        if (groupBy?.includes(',')) {
            const multiGroupBy = groupBy.split(',')
            groupBy = multiGroupBy[0]
        }
        const averageCurrentSalesGrouped = regroupDataBy(averageCurrentSalesData, groupBy);
        const averageLastYearSalesGrouped = regroupDataBy(averageLastYearSalesData, groupBy);
        const targetFullObjectGrouped = regroupDataBy(targetData, groupBy);
        const targetGrouped = regroupDataBy(targetData, groupBy, "target_sales_sum");
        const actualSalesGrouped = regroupDataBy(actualSalesData, groupBy, "actual_sales_sum");
        const dailyTargetSalesGrouped = regroupDataBy(dailyTargetData, groupBy, "target_sales_daily_sum");

        const allKeys = new Set([
            ...Object.keys(averageCurrentSalesGrouped),
            ...Object.keys(averageLastYearSalesGrouped),
            ...Object.keys(targetGrouped),
            ...Object.keys(actualSalesGrouped),
            ...Object.keys(dailyTargetSalesGrouped)
        ])

        return [...allKeys].map(key => {
            const mergedSalesGrouped = {...averageCurrentSalesGrouped[key], ...averageLastYearSalesGrouped[key], ...targetFullObjectGrouped[key]}
            return {
                [groupBy]: key,
                ...mergedSalesGrouped,
                ads: averageCurrentSalesGrouped[key]?.average_daily_store_sales ?
                    averageCurrentSalesGrouped[key]?.average_daily_store_sales :
                    null,
                target_sales: targetGrouped[key] ?
                    targetGrouped[key] :
                    null,
                actual_sales: actualSalesGrouped[key] ?
                    actualSalesGrouped[key] :
                    null,
                last_year_actual_sales: averageLastYearSalesGrouped[key]?.actual_sales_sum ?
                    averageLastYearSalesGrouped[key]?.actual_sales_sum :
                    null,
                vs_target: (actualSalesGrouped[key] && targetGrouped[key]) ?
                    (actualSalesGrouped[key] / targetGrouped[key]) * 100 :
                    null,
                vs_last_year_mtd_ytd_sales: (averageCurrentSalesGrouped[key]?.actual_sales_sum && averageLastYearSalesGrouped[key]?.actual_sales_sum) ?
                    (averageCurrentSalesGrouped[key]?.actual_sales_sum / averageLastYearSalesGrouped[key]?.actual_sales_sum) * 100 :
                    null,
                sales_per_sqm: averageCurrentSalesGrouped[key]?.ads_sales_per_sqm ?
                    averageCurrentSalesGrouped[key]?.ads_sales_per_sqm :
                    null,
                vs_last_year_sqm_sales: (averageCurrentSalesGrouped[key]?.ads_sales_per_sqm && averageLastYearSalesGrouped[key]?.ads_sales_per_sqm) ?
                    (averageCurrentSalesGrouped[key]?.ads_sales_per_sqm / averageLastYearSalesGrouped[key]?.ads_sales_per_sqm) * 100 :
                    null,
                dayOfMonthMaxDate: dayOfMonthMaxDate,
                original: {
                    current: averageCurrentSalesGrouped[key],
                    last_year: averageLastYearSalesGrouped[key],
                },
                daily_target_sales: dailyTargetSalesGrouped[key] ? dailyTargetSalesGrouped[key] : null,
                vs_running_daily_target: (actualSalesGrouped[key] && dailyTargetSalesGrouped[key]) ?
                (actualSalesGrouped[key]/dailyTargetSalesGrouped[key]) * 100 :
                null,
            }
        })

    }, [params.group_by, averageCurrentSalesData, averageLastYearSalesData, targetData, actualSalesData, dailyTargetData, isLoading])

    return {
        data,
        isLoading
    }
}


export function useSalesPerformanceMtdYtdData({params}) {
    // require group_by
    if (!params || !params.group_by) {
        throw new Error("group_by is required")
    }

    const {
        actualSalesData: mtdActualSalesData,
        targetData: mtdTargetData,
        lastYearData: mtdLastYearData,
        isLoading: mtdIsLoading
    } = useSalesData({
        params: {
            ...params,
            columns: params.group_by,
            limit: 100,
        }
    });

    const {
        actualSalesData: ytdActualSalesData,
        targetData: ytdTargetData,
        lastYearData: ytdLastYearData,
        isLoading: ytdIsLoading
    } = useSalesData({
        params: {
            ...params,
            columns: params.group_by,
            limit: 100,
            month: [1, ...(params?.month || [])],
        }
    });

    const {group_by, ...rest} = params //remove group_by key in the params
    const {
        actualSales: mtdActualTotalSalesData,
        isLoading: isLoadingMtdTotalSalesData
    } = useSalesOverallPerformanceData({params: rest})
    const {
        actualSales: ytdActualTotalSalesData,
        isLoading: isLoadingYtdTotalSalesData
    } = useSalesOverallPerformanceData({
        params: {
            ...rest,
            month: [1, ...(params?.month || [])],
        }
    })
    const isLoading = mtdIsLoading || ytdIsLoading || isLoadingMtdTotalSalesData || isLoadingYtdTotalSalesData;
    const data = useMemo(() => {
        let groupBy = params.group_by;
        let otherGroupByKeys = [];
        if (!groupBy) {
            return []
        }

        if (groupBy?.includes(',')) {
            const multiGroupBy = groupBy.split(',')
            groupBy = multiGroupBy[0]
            multiGroupBy.shift();
            otherGroupByKeys = [...multiGroupBy]

        }
        const mtdActualSalesGrouped = regroupDataBy(mtdActualSalesData, groupBy, "actual_sales_sum");
        const mtdTargetGrouped = regroupDataBy(mtdTargetData, groupBy, "target_sales_sum");
        const mtdLastYearGrouped = regroupDataBy(mtdLastYearData, groupBy, "actual_sales_sum");
        const ytdActualSalesGrouped = regroupDataBy(ytdActualSalesData, groupBy, "actual_sales_sum");
        const ytdTargetGrouped = regroupDataBy(ytdTargetData, groupBy, "target_sales_sum");
        const ytdLastYearGrouped = regroupDataBy(ytdLastYearData, groupBy, "actual_sales_sum");

        //used only mtdData since this is for getting the group_by fields in params
        const actualSalesGroupedData = regroupDataBy(mtdActualSalesData, groupBy);
        const targetGroupedData = regroupDataBy(mtdTargetData, groupBy);
        const lastYearGroupedData = regroupDataBy(mtdLastYearData, groupBy);

        const allKeys = new Set([
            ...Object.keys(mtdActualSalesGrouped),
            ...Object.keys(mtdTargetGrouped),
            ...Object.keys(mtdLastYearGrouped),
            ...Object.keys(ytdActualSalesGrouped),
            ...Object.keys(ytdTargetGrouped),
            ...Object.keys(ytdLastYearGrouped)
        ]);

        return [...allKeys].map(key => {
            // consolidate all groupedData into 1 object
            const actualSalesGroupedDataObj = actualSalesGroupedData?.[key]
            const targetGroupedDataObj = targetGroupedData?.[key]
            const lastYearGroupedDataObj = lastYearGroupedData?.[key]

            const tempObj = {}
            otherGroupByKeys?.forEach(grpKey => {
                tempObj[grpKey] = actualSalesGroupedDataObj?.[grpKey]
                if (!tempObj[grpKey]) {
                    tempObj[grpKey] = targetGroupedDataObj?.[grpKey]
                }
                if (!tempObj[grpKey]) {
                    tempObj[grpKey] = lastYearGroupedDataObj?.[grpKey]
                }
            });

            return {
                [groupBy]: key,
                ...tempObj,
                mtd_actual_sales: mtdActualSalesGrouped[key],
                mtd_percent_contribution: mtdActualSalesGrouped[key] && mtdActualTotalSalesData ? (mtdActualSalesGrouped[key] / mtdActualTotalSalesData) * 100 : null,
                mtd_target_sales: mtdTargetGrouped[key],
                mtd_last_year_sales: mtdLastYearGrouped[key],
                mtd_vs_target: (mtdActualSalesGrouped[key] / mtdTargetGrouped[key]) * 100,
                mtd_vs_last_year: (mtdActualSalesGrouped[key] / mtdLastYearGrouped[key]) * 100,
                ytd_actual_sales: ytdActualSalesGrouped[key],
                ytd_target_sales: ytdTargetGrouped[key],
                ytd_last_year_sales: ytdLastYearGrouped[key],
                ytd_vs_target: (ytdActualSalesGrouped[key] / ytdTargetGrouped[key]) * 100,
                ytd_vs_last_year: (ytdActualSalesGrouped[key] / ytdLastYearGrouped[key]) * 100,
                ytd_percent_contribution: ytdActualSalesGrouped[key] && ytdActualTotalSalesData ? (ytdActualSalesGrouped[key] / ytdActualTotalSalesData) * 100 : null,
            }
        })

    }, [params, mtdActualSalesData, mtdTargetData, mtdLastYearData, ytdActualSalesData, ytdTargetData, ytdLastYearData, mtdActualTotalSalesData, ytdActualTotalSalesData, isLoading])
    return {
        data,
        isLoading
    }
}