import Section from "../../../../../common/components/Dashboard/Section";
import {useActivityByBusinessUnitTableData} from "../../hooks";
import CustomerActivityByBUTable from "./CustomerActivityByBUTable";

export default function StoreCustomerActivityByBU(){

    return <Section
        title={'Customer Activity by Business Unit'}>

        <CustomerActivityByBUTable/>
    </Section>
}