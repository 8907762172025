import React, {useContext} from 'react'
import {Box, Grid, Typography} from "@mui/material";
import ToggleSelectorWithAdditional from "../../../TmpTSSSales/Filters/ToggleSelectorWithAdditional";
import GroupSelector from "../selectors/groupSelector";
import {useSelectorState} from "../../providers/selectorProvider";
import {WindowSizeContext} from "../../../../../common/context/WindowSizeContext";
import {useTogglePeriodContext} from "./Provider/context";


const VIEW_BY = [
    {label: 'Customer', value: 'customer', disabled: false},
    {label: 'Merchandise', value: 'merchandise', disabled: false},
    {label: 'Operations', value: 'operations', disabled: true},
    // { label: 'Store', value: 'customer', disabled: true },
]

const TOGGLE_OPTIONS = [
    {label: 'MTD', value: 'mtd'},
    {label: 'YTD', value: 'ytd'},
    {label: 'QoQ', value: 'qoq'},
]


export default function MainViewBySelector({}) {
    const [viewBy, setViewBy] = useSelectorState('mainBUViewBy', 'customer')
    const {period, updatePeriod, showToggle} = useTogglePeriodContext();
    const windowSize = useContext(WindowSizeContext)

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            updatePeriod(val);
        }
    };

    return (
        <Grid item sm={12} xs={12}>
            <Box flexGrow={1} xs={12}
                 sx={{backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem"}}>

                <Grid
                    container
                    alignItems={'center'}
                    columnSpacing={1}
                    rowSpacing={windowSize.isOnMobile ? 1.5 : .5}
                    paddingY={1}
                    paddingLeft={2.5}
                >
                    <Grid item xs sm={'auto'}>
                        <Typography variant="h6"> View By </Typography>
                    </Grid>

                    {
                        windowSize?.isOnMobile && showToggle && <Grid item xs={6} display='flex' justifyContent='end'>
                            <ToggleSelectorWithAdditional
                                options={TOGGLE_OPTIONS}
                                value={period}
                                onChange={onTogglePeriodChange}
                                additionalStyles={{textTransform: 'none'}}
                                lastIndexOfMain={2}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={8} mx={windowSize.isOnMobile && 2} display={"flex"}>
                        <GroupSelector options={VIEW_BY}
                                       onSelectGroupChange={(value) => setViewBy(value)} //strings value
                                       selected={viewBy}/>
                    </Grid>
                    {
                        !windowSize?.isOnMobile && showToggle &&
                        <Grid item xs={12} sm display='flex' justifyContent='end'>
                            <ToggleSelectorWithAdditional
                                options={TOGGLE_OPTIONS}
                                value={period}
                                onChange={onTogglePeriodChange}
                                additionalStyles={{textTransform: 'none'}}
                                lastIndexOfMain={2}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid
                    item id={'viewby-extra-info'}
                    sm={12} xs={12} display={'flex'} alignItems={'center'}
                    sx={{
                        backgroundColor: "#caddeb",
                        borderBottomRightRadius: ".5rem",
                        borderBottomLeftRadius: ".5rem",
                    }}>
                    {/*
                                This is used as a portal target for any items added below the view by section.
                                Usually this is used to display filter texts. Filters are scoped to the underlying/child dashboard.
                                */}
                </Grid>
            </Box>
        </Grid>
    )
}