import React from "react";
import DateSelector from "./dateSelector";
import Indicators from "./indicators";
import ProvinceSelector from "./provinceSelector";
import RegionSelector from "./regionSelector";
import BranchSelector from "./branchSelector";
import { SelectOverridesStyles } from "../../Map/util";
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext";
import { useContext } from "react";
import { Grid, Typography, Divider } from "@mui/material";


const StoreItemsSelectOverridesStyles = {
    ...SelectOverridesStyles,
    container: (baseStyles) => ({
        ...baseStyles,
        width: '100%'
    })
}

export default function StoreSideItems(
    {
        title,
        dateFilter, setDateFilter,
        toggleMenuOpen,
        isMapView,
        salesApiEndPointName,
        apiDefaultFilter,
        locationApiEndPoint
    }
) {
    const windowSize = useContext(WindowSizeContext);

    return (
        <Grid >
            <Grid borderRadius={2} boxShadow={1} p={2} mt={1}>
                <Grid container direction={'row'} mb={2}>
                    <Grid sx={12}>
                        <Grid container alignItems="center" spacing={1}>
                            <Typography sx={{ fontWeight: 'medium' }}>
                                As of April 1, 2023
                            </Typography>

                            <Divider md={1} sx={{ py:1 , mx: 1, borderRight: 2 }} />

                            <Typography sx={{ fontWeight: 'medium' }}>
                                Sources
                            </Typography>

                        </Grid>
                    </Grid>
                </Grid>


                <DateSelector selectedPeriodFilter={dateFilter} setSelectedPeriodFilter={setDateFilter}
                    salesApiEndPointName={salesApiEndPointName} apiDefaultFilter={apiDefaultFilter} />
                
                <Grid container>
                    <Grid item xs={12} sx={{ alignSelf: 'center', mb: 1 }}>
                        <RegionSelector />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sx={{ alignSelf: 'center', mb: 1 }}>
                        <ProvinceSelector />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sx={{ alignSelf: 'center', mb: 1 }}>
                        <BranchSelector locationApiEndPoint={locationApiEndPoint} />
                    </Grid>
                </Grid>
            </Grid>
            {
                !windowSize.isOnMobile && <Indicators
                    salesApiEndPointName={salesApiEndPointName}
                    apiDefaultFilter={apiDefaultFilter}
                    dateFilter={dateFilter}
                />
            }
        </Grid>)
}