export function processFilterObjectArray(filterObjectArray) {
    // if filterObjectArray is already an object, just return it
    if (typeof filterObjectArray === 'object' && !Array.isArray(filterObjectArray)) {
        return filterObjectArray;
    }

    if (!Array.isArray(filterObjectArray) || filterObjectArray.length === 0) {
        return {};
    }
    if (!filterObjectArray.every(obj => obj.hasOwnProperty('operator') && obj.hasOwnProperty('filterName') && obj.hasOwnProperty('value'))) {
        throw new Error('Invalid filter object array' + JSON.stringify(filterObjectArray));
    }

    return filterObjectArray.filter(row => {
        // make sure that filter object has operator, field, and value
        return !!row.operator && !!row.filterName && !!row.value;
    }).reduce((acc, filter) => {
        if (filter.operator === "between") {
            acc[filter.filterName + '__gte'] = filter.value[0];
            acc[filter.filterName + '__lte'] = filter.value[1];
            return acc;
        }

        const filterKey = filter.filterName + '__' + filter.operator;
        acc[filterKey] = Array.isArray(filter.value) ? filter.value.join(',') : filter.value;
        return acc;
    }, [])

}