import { getInvertedPercentageColor } from "../../../../TmpTSSSales/util";


export default function IconIndicator({ data }) {
    const getBoostrapIconName = (value) => {
        if (value > 1 && data < 100) {
            return 'arrow-down'
        } else if (value >= 100) {
            return 'bi-arrow-up'
        }
        return ''
    }

    const iconName = getBoostrapIconName(data);

    const dataDisplay = data !== 0 ? data + '%' : '--'
    return <div className={`text-center text-white py-1 fs-6`} style={{ backgroundColor: getInvertedPercentageColor(data) }}>
        {iconName ? <i className={`bi bi-${iconName}`}></i> : ''} {dataDisplay}
    </div>
}