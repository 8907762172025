import React, { useContext, useMemo} from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useAllAvailableBusinessUnitsGroup } from '../hooks';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { getSelectedOption } from '../../../../common/utils';


export default function FilterBusinessUnitsGroup({ filters, params, onDropdownChange, storeID}) {
    const windowSize = useContext(WindowSizeContext);

    const { buGroups, isLoading } = useAllAvailableBusinessUnitsGroup({ params: {...params, sbu_higher_mbu: null, store_id:storeID} });

    const options = useMemo(() => {
        let buOptions = Array.isArray(buGroups)
            ? buGroups
                .filter(item => item && item.sbu_higher_mbu !== null)
                .map(item => ({
                    value: item.sbu_higher_mbu,
                    label: item.sbu_higher_mbu,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
            : [];
    
        return buOptions;
    }, [buGroups]);


    return (
        <ConditionalSelectComponent
                    id="buGroup_filter"
                    labelId="buGroup_filter-label"
                    options={options}
                    label="All Business Unit Groups"
                    value={filters ? (windowSize.isOnMobile ? filters?.sbu_higher_mbu : getSelectedOption(filters?.sbu_higher_mbu, options)) : '' &&  null}
                    onSelectChange={(event,newValue)=>{
                        windowSize.isOnMobile ? onDropdownChange(event.target.value, 'sbu_higher_mbu')
                        : onDropdownChange(newValue?.value, 'sbu_higher_mbu')  
                    }}
                    endAdornment={true}
                    isLoading={isLoading}
                    clearSelected={() => onDropdownChange('','sbu_higher_mbu')}
                    disableClearable={false}
            />
    );
}