
export const  CHART_TYPE_OPTIONS = [
    { label: "Bar", value: "bar" },
    { label: "Pie", value: "pie" },
    { label: "Line", value: "line" },
    { label: "Table", value: "table" },
    { label: "Indicator", value: "indicator" },
    { label: 'ScatterPlot', value: 'scatter' }
    // { label: "Timeline", value: "timeline" } 
] // this will decide what kind of selector to provide

export const SELECTOR_TYPES_BY_CHART_TYPE = {
    'pie': ['label', 'value'],
    'bar': ['label', 'value'],
    'line': ['label', 'value'],
    'table': ['column', 'aggregate'],
    'indicator': ['value'],
    'scatter': ['label', 'x', 'y'],
}

export const DEFAULT_CHART_DATA = {
    title: '',
    chart_type: null,
    description: '',
    is_active: true,
    is_public: false,
    is_distinct: false,
    table_source: null,
    extra_data: {
        filters: [],
        columns: [],
        aggregates: [],
        x_axis: null,
        y_axis: null,
    }
}