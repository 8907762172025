import React from 'react';
import { Grid, Card } from '@mui/material';
import GenderGraph from './Gender';
import AgeGraph from './Age';
import GenerationGraph from './Generation';
import MaritalStatus from './MaritalStatus';
import ContactInformation from './ContactInformation';
import CampaignSubscription from './CampaignSubscription';


export default function CustomerDemography() {

    return <>
        <Grid container spacing={1} marginBottom={2}>
            <Grid item xs={12} sm={6} >
               <Card>
                 <GenerationGraph />
               </Card>
            </Grid>

            <Grid item xs={12} sm={6} >
               <Card>
                 <AgeGraph />
               </Card>
            </Grid>

            <Grid item xs={12} sm={6} >
               <Card>
                 <GenderGraph />
               </Card>
            </Grid>

            <Grid item xs={12} sm={6} >
               <Card>
                 <MaritalStatus />
               </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
               <Card>
                 <ContactInformation/>
               </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
               <Card>
                 <CampaignSubscription/>
               </Card>
            </Grid>
        </Grid>
    </>
}