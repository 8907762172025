import React, { useContext } from 'react';
import { getSelectedOption } from '../../../../common/utils';
import { Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { styled } from "@mui/material/styles";
import {useSelectorContext, useSelectorValue} from "../../common/providers/selectorProvider";


export default function ViewBySelector({optionWhiteList=[]}) {
    const selectedViewBy = useSelectorValue('viewBy');
    const {setValue: setSelectorValue} = useSelectorContext();

    const windowSize = useContext(WindowSizeContext);
    const NewToggleButton = styled(ToggleButton)({
        width: windowSize?.isOnMobile ? '5rem' : '7rem',
        textTransform: 'capitalize',
        color: '#0a4fb4',
        border: 0,
        borderRadius: '.25rem',
        backgroundColor: '#fff',
        borderBottom: 'none',
        '&:hover': {
            color: "#fff",
            backgroundColor: '#0a4fb4'
        },
        '&.Mui-selected, &.Mui-selected:hover': {
            color: "#fff",
            backgroundColor: '#0a4fb4'
        }, '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            borderRight: '.05rem solid lightgray',
            borderLeft: 'none'
        }, '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            marginLeft: 0
        },
    });


    const optionListGroup = [
        // NOTE: url value is for experimenting
        { value: "channel", label: "Channel" },
        { value: "business_unit" || "higher_mbu", label: "BU" },
        { value: "zone", label: "Zone" },
    ].filter(row => {
        if (!optionWhiteList.length) {
            return true
        }
        return optionWhiteList.includes(row.value)
    });

    return (
        <Grid item xs={12} sm={9}>
            <Grid container xs={12} spacing={0}>
                {!windowSize?.isOnMobile &&
                    <Grid item xs sm={4}>
                        <Typography variant="h6" ml={2.5}>
                            Performance per
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12} sm>
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        sx={{ flexWrap: "wrap"}}
                        exclusive
                        value={getSelectedOption(selectedViewBy, optionListGroup)?.value}
                        onChange={(event, value) => {
                            if (value !== null) {
                                setSelectorValue('viewBy', value)
                            }
                        }}
                    >
                        {
                            optionListGroup.map(option => {
                                return <NewToggleButton value={option.value}>{option.label}</NewToggleButton>
                            })
                        }
                    </ToggleButtonGroup>
                    {/* <Autocomplete
                        value={getSelectedOption(filters?.group, optionListGroup)}
                        onChange={(event,newValue)=>onDropdownChange(newValue.value,'group')}
                        disableClearable
                        defaultValue={optionListGroup[0]}
                        id="filter_group_options"
                        size="small"
                        options={optionListGroup}
                        renderInput={(params) => <TextField  {...params} onKeyDown={windowSize.isOnMobile ? (e) => e.preventDefault(): null}/>}
                    /> */}
                </Grid>
            </Grid>
        </Grid>
    );
}