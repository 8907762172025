import { IconButton, InputAdornment, TextField} from '@mui/material';
import React from 'react';
import { NumericFormatField } from '../../../../common/components/NumericFormatField';
import ClearIcon from '@mui/icons-material/Clear';
export default function MetricInputValueFilter({filter,onFilterChange}) {


    const percentageFields = ['vs_target', 'vs_last_year_mtd_ytd_sales', 'vs_last_year_sqm_sales']
    
    const showPercentage = percentageFields.includes(filter?.metric_column)

    return (
        <TextField
            value={filter?.metric_value}
            onChange={(event) => {
                    onFilterChange(event.target.value, 'metric_value');
            }}
            label="Value"
            placeholder="Input numeric value"
            id="metric_filter_value"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#FFF', borderRadius: '.25rem'}}
            InputProps={{
                inputComponent: NumericFormatField,
                endAdornment: <>
                    {
                        filter?.metric_value ?
                            <IconButton
                                aria-label="clear input metric value"
                                onClick={()=>onFilterChange('', 'metric_value')}
                                edge="end"
                            > 
                                <ClearIcon fontSize='small'/>
                            </IconButton> : null
                    }
                    { showPercentage ? <InputAdornment position="end">%</InputAdornment> : null}
                </>
            }}
      />
    )
}