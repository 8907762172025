import CardComponent from "../../../common/components/CardComponent";
import ScaleRangeDisplay from "./ScaleRangeDisplay";
import TreeMaps from "../../../common/components/graphs/Treemaps";
import ScatterPlot from "../../../common/components/graphs/ScatterPlot";
import {TreemapsConfig} from '../../../GraphConfig/TreemapConfig';
import TREEMAP_DATA from '../../../mockdata/treemapData2.json';


export default function RfmAnalytics() {

    return <>
        <CardComponent title="RFM Analysis" id="collapseRfmCard">
            <ScaleRangeDisplay/>
            <div className="row">
                <div className="col-sm-6 ps-0">
                    <ScatterPlot/>
                </div>
                <div className="col-sm-6 pe-0">
                    <TreeMaps config={TreemapsConfig.Default} data={TREEMAP_DATA}/>
                </div>
            </div>
        </CardComponent>
    </>
}