import React, {useContext} from "react";
import {StoreAnalyticsFilterContext} from "../../contexts";
import {ConvertToReactSelectOption} from "../../../../../common/utils";
import { usePolygon} from "../../Map/util";
import Select from "react-select";


export default function RegionSelector({storeItemsSelectOverridesStyles}) {

    const {region, setFilter} = useContext(StoreAnalyticsFilterContext)
    const {polygonData: regionPolygonData, isLevelLoading} = usePolygon('region')
    const regionNames = regionPolygonData?.map(row => row.region).sort()

    const allRegionLabel = "All Region"

    const regionOptions = regionNames?.length > 0 && ConvertToReactSelectOption([allRegionLabel, ...regionNames]).map(option => {
        return {
            ...option,
            value: option.value === "All Region" ? null : option.value
        }
    })

    return (
        <Select
            isDisabled={isLevelLoading}
            value={regionOptions?.length > 0 && regionOptions.find(r => r.value === region)}
            onChange={(e) => setFilter('region', e?.value)}
            options={regionOptions}
            styles={storeItemsSelectOverridesStyles}
        />
    )
}