import React from 'react';
import { Typography } from '@mui/material';
import { polygon } from 'leaflet';


export function FormatPercentage({ number, decimalPlaces = 2 }) {
    if (typeof number !== 'number' || isNaN(number) || !isFinite(number)) {
        return 'Invalid Number';
    }

    const percentage = (parseFloat(number) * 100).toFixed(decimalPlaces);
    return (
        <Typography style={{ display: 'inline' }}>{percentage}%</Typography>
    );
};

export function getBlueColor(cityPopulation) {
    if (cityPopulation <= 150) {
        return "#89CFF0";
    } else if (cityPopulation <= 500) {
        return "#7A84FF";
    } else if (cityPopulation <= 1000) {
        return "#3B49F0";
    } else if (cityPopulation <= 2000) {
        return "#0000CD";
    } else {
        return "#00008B";
    }
}

export const parsePolygon = (polygonString, switch_coords=false) => {
    const coordinatesString = polygonString
      .replace('POLYGON((', '')
      .replace('))', '');
  
    const coordinatesArray = coordinatesString.split(', ')
      .map(coords => {
        const [ lng, lat ] = coords.split(' ').map(parseFloat)
        if (!isNaN(lng) && !isNaN(lat)) {
            if (!switch_coords) {
                return [lat, lng]
            } else {
                return [lng, lat]
            }
        } else {
            return null;
        }
    }).filter(pair => pair !== null);

    coordinatesArray.push(coordinatesArray[0]);
    
    return coordinatesArray;
};

export const getPolygonCenter = (polygonString) => {
    if (polygonString === undefined) {
        return undefined
    }
    const coordinatesArray = parsePolygon(polygonString);
  
    if (coordinatesArray.length === 0) {
        return null;
    }
  
    const center = coordinatesArray.reduce((acc, [lat, lng]) => {
        acc[0] += lat;
        acc[1] += lng;
        return acc;
    }, [0, 0]);
  
    const centerLat = center[0] / coordinatesArray.length;
    const centerLng = center[1] / coordinatesArray.length;
  
    return [centerLat, centerLng];
};

function area(poly){
    let s = 0.0;
    for (let i = 0; i < poly.length - 1; i++) {
        s += (poly[i][0] * poly[i + 1][1] - poly[i + 1][0] * poly[i][1]);
    }
    return 0.5 * s;
}

export function centroid(polygonString){
    const poly = parsePolygon(polygonString)
    const c = [0, 0];
    for (let i = 0; i < poly.length - 1; i++) {
        c[0] += (poly[i][0] + poly[i + 1][0]) * (poly[i][0] * poly[i + 1][1] - poly[i + 1][0] * poly[i][1]);
        c[1] += (poly[i][1] + poly[i + 1][1]) * (poly[i][0] * poly[i + 1][1] - poly[i + 1][0] * poly[i][1]);
    }
    const a = area(poly);
    c[0] /= a * 6;
    c[1] /= a * 6;
    return c;
}

  
export const getSideCoordinates = (center, radius, where) => {
    const [lat, lng] = center;
    const dx = radius / (111 * Math.cos((lat * Math.PI) / 180));
    const dy = radius / 111;
    const newCenter = [0, 0]
    newCenter[0] = lat + dy;

    if (where === "top right")
        newCenter[1] = lng + dx;
    else if (where === "top left")
        newCenter[1] = lng - dx;

    return newCenter;
};
  

export function pointInPolygon(point, polygon) {
    const x = point[1]; // Lon
    const y = point[0]; // Lat

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][1]; // Lon
        const yi = polygon[i][0]; // Lat
        const xj = polygon[j][1]; // Lon
        const yj = polygon[j][0]; // Lat

        const intersect =
            ((yi > y) !== (yj > y)) &&
            (x < ((xj - xi) * (y - yi)) / (yj - yi) + xi);

        if (intersect) inside = !inside;
    }

    return inside;
}

export function isColorLight(color) {
    if (color === undefined) {
        return true;
    }

    if (/^#[0-9A-F]{6}$/i.test(color)) {
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);

        const luminance = 0.2126 * r / 255 + 0.7152 * g / 255 + 0.0722 * b / 255;

        return luminance > 0.7;
    } else {
        const lightColors = ['white', 'lightgray', 'yellow'];
        return lightColors.includes(color.toLowerCase());
    }
}
