import React from "react";
import ContentSelectorModal from "./contentSelectorModal";
import GraphById from "../../../../common/components/GraphFactory/graphById";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import parse from 'html-react-parser';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Stack from '@mui/material/Stack';


export default function Content({contentObject, onChangeContent, tableId, onDelete}) {
    const [selectorOpen, setSelectorOpen] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)
    const openModal = () => {
        setShowModal(true);
    }
    return (
        <>
            {showModal && (
                <ConfirmationModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={onDelete}
                    icon={<i className="bi bi-trash text-primary"></i>}
                    title="Delete Container"
                    message={'Are you sure you want to remove this content?'}
                />
            )}
            {
                selectorOpen && <ContentSelectorModal
                    isOpen={selectorOpen}
                    setIsOpen={setSelectorOpen}
                    tableId={tableId}
                    content={contentObject}
                    setContent={onChangeContent}
                />
            }
            {
                (!contentObject) ?
                // <div>
                //     <div className={"row"}>
                //             <div className="col text-end pe-3">
                //                 <button type="button" className="btn btn-link text-decoration-none p-0 m-0"
                //                     onClick={openModal}>
                //                     <i className="bi bi-trash fs-5"/>
                //                 </button>
                //             </div>
                //     </div>
                //     <span className="text">
                //         <button type="button" className="btn btn-link text-decoration-none p-0 m-0"
                //                 onClick={() => setSelectorOpen(true)}>
                //             <i className="bi bi-plus-square-dotted fs-3"/>
                //         </button>
                //     </span>
                // </div> 
                <Box>
                    <Grid container>
                        <Grid item sx={{textAlign:"end"}} xs={12}>
                            <IconButton aria-label="delete" color="primary"  onClick={openModal}> <DeleteIcon/> </IconButton>
                        </Grid>
                        <Box component="span" class="text">
                            <IconButton aria-label="add" color="primary" onClick={() => setSelectorOpen(true)}> <AddIcon/> </IconButton>
                        </Box>
                    </Grid>
                </Box>
                : (
                    !!contentObject && <>
                        {/* <div className="row h-100">
                            <div className="col">
                                {
                                    contentObject.hasOwnProperty("text") &&
                                    <span>{parse(contentObject.text)}</span>
                                }
                                {
                                    contentObject.hasOwnProperty("chart") &&
                                    <GraphById graphId={contentObject.chart}/>
                                }
                            </div>
                        </div> */}
                        <Box sx={{ height: '100%' }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="flex-end">
                                        <IconButton aria-label="edit" color="primary"  onClick={() => setSelectorOpen(true)}> <EditIcon /> </IconButton>
                                        <IconButton aria-label="delete" color="primary" onClick={openModal}> <DeleteIcon /> </IconButton>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        contentObject.hasOwnProperty("text") &&
                                        <Box component="span">{parse(contentObject.text)}</Box>
                                    }
                                    {
                                        contentObject.hasOwnProperty("chart") &&
                                        <GraphById graphId={contentObject.chart}/>
                                    }
                                </Grid>
                            </Grid>
                        </Box>

                        {/* <div className={"row position-absolute top-0 end-0"}>
                            <div className="col text-end pe-3">
                                <button type="button" className="btn btn-link text-decoration-none pe-1 m-0"
                                        onClick={() => setSelectorOpen(true)}>
                                    <i className="bi bi-pencil-square fs-5"/>
                                </button>
                                <button type="button" className="btn btn-link text-decoration-none p-0 m-0"
                                    onClick={openModal}>
                                    <i className="bi bi-trash fs-5"/>
                                </button>
                            </div>
                        </div> */}
                    </>
                )
            }
        </>

    )

}
