export const TABLE_NAME = 'l4_mktsap_inv'

export const STORE_DATA_COLUMNS = ['store_size', 'region', 'mall_type', 'mall_category', 'area', 'selling_floor']

export const CATEGORY = {
    'mall_type': 'Store Type',
    'store_size': 'Store Size',
    'mall_category': 'Store Subtype',
    'region': 'Region',
    'top_food_group': 'Product Category',
    'storeSize': 'Store Size',
    'storeType': 'Store Type',
}