import {useContext, useEffect, useMemo, useState} from 'react';
import AssociationRules from "./AssociationRules";
import {WindowSizeContext} from "../../../common/context/WindowSizeContext";
import {useDebounce} from "use-debounce";
import Filters from "./Filters";
import ProductSegments from "./ProductSegments";

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanelComponent from '../../../common/components/TabPanelComponent';
import InfoIcon from '@mui/icons-material/Info';
import Back from '../../../common/components/BackNavigateComponent';
import Divider from '@mui/material/Divider';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import Card from '@mui/material/Card';
import TransactionSales from './ProductSegments/TransactionSales';
import Button from '@mui/material/Button';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import Tooltip from '@mui/material/Tooltip';

export const SelectOverrideStylesV2 = {
    control: (baseStyles) => ({
        ...baseStyles,'&:hover': {
        borderColor: 'blue'},
        border: '2px solid blue',
        height: '1rem'
    })
}

export default function MarketBasket({title, companyKey}) {
    const windowSize = useContext(WindowSizeContext);
    const tooltipPlacement = windowSize.isOnMobile ? 'top' : 'right';
    const [rawFilters, setRawFilters] =useState ({
        company_key: companyKey,
        category: null,
        target_basket_cat: null,
        merch_article_desc: null,
    })
    // const [selectedTab, setSelectedTab] = useState('segments')
    const [showDownloadModal, setShowDownloadModal] = useState(false)


    useEffect(()=> {
        // update the company key in the filters if the company key props changes
        setRawFilters({
            ...rawFilters,
            company_key: companyKey
        })
    },[companyKey])

    function onInputChange(value,field) {
        setRawFilters({
            ...rawFilters,
            [field]: value
        })
    }

    const [activeTab, setActiveTab] = useState(0);

    const showProductFilter = activeTab !== 0;

    const filters = useMemo(() => {
        if (!showProductFilter) {
            const tmpFilters = {...rawFilters}
            delete tmpFilters.merch_article_desc
            return tmpFilters
        }
        return rawFilters
    }, [rawFilters, showProductFilter])

    const handleTabChange = (event,tab) => {
        setActiveTab(tab);
    };

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    
    const handleDrawer = () =>{
        setIsDrawerOpen(!isDrawerOpen)
    }


    return (
        <Grid sx={{mt:4}}>
            <Grid container spacing={2}>
                <Grid item xs={10} >
                   <Stack direction='row' spacing={2} alignItems={'center'}>
                        <Grid  >
                            <Back />
                        </Grid>
            
                        <Grid>
                            <Typography variant="h5" sx={{ fontWeight:'medium'}}>
                                Market Basket Analysis
                                <Tooltip title={"Data coverage is past three (3) months. Uses FP Growth Algorithm with at least 10% Confidence."} 
                                        placement={tooltipPlacement}>
                                    <InfoIcon
                                        size='small'
                                        sx={{ml:1, mb:.5, color:'#acacac'}}
                                    />
                                </Tooltip>
                            </Typography>
                            <Typography variant="h6" sx={{color:'#808080', fontWeight:'bold'}}>{title}</Typography>
                        </Grid>
                   </Stack>
                </Grid>

                {
                    (windowSize.isOnMobile) && 
                    <Grid item xs={2} >
                        <IconButton onClick={handleDrawer}>
                            <MoreHorizIcon style={{fontSize:'2rem', color:'blue'}} focused/>
                        </IconButton>
                    </Grid>
                }
            </Grid>

            <Divider sx={{my:2}}/>

            <Grid container xs={12} direction={'row'} sx={{mx:2}}> 
                <Grid xs= {11} md={8}>
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="Segments Analytics Product Combination"
                            TabIndicatorProps={{sx:{backgroundColor:'blue'}}}>
                        <Tab label={<Stack direction='row' alignItems={'center'} spacing={.5}>
                                            <BarChartRoundedIcon />

                                            <Typography sx={{fontWeight:`${activeTab === 0 ? 'bold': ''}`}}>
                                                Segment Analytics
                                            </Typography>
                                    </Stack>} sx={{textTransform:'none', '&.Mui-selected': { color: 'blue'}}}/>

                        <Tab label={<Stack direction='row' alignItems={'center'} spacing={.5}>
                                            <TimelineRoundedIcon />

                                            <Typography sx={{fontWeight:`${activeTab === 1 ? 'bold': ''}`}}>
                                                Product Combination
                                            </Typography>
                                    </Stack>} sx={{textTransform:'none', '&.Mui-selected': { color: 'blue'}}}/>
                    </Tabs>
                </Grid>
               
                { activeTab === 1 ?
                    <Grid xs={12} md={4} container justifyContent={{xs:'flex-start',md:'flex-end'}} sx={{mt:1, px:{xs:0,md:4}}} >
                        <Button variant='contained' 
                                sx={{textTransform:"none", 
                                    backgroundColor:'#0F2FEB',
                                    alignSelf:'center',
                                    whiteSpace:'nowrap',
                                    '&:hover': {
                                        backgroundColor: '#0F2FEB',
                                    }}} 
                                size='small' endIcon={<GetAppOutlinedIcon />} onClick={()=> {
                            setShowDownloadModal(true)
                        }}> Download Data
                        </Button>
                    </Grid> : <></>
                }
            </Grid>

            

            <Grid md={12}>
                <Grid sx={{mx:2,my:2}}>
                    {
                        <TabPanelComponent value={activeTab} index={0} >
                            <Card sx={{mb:4,p:3}}>
                                <Grid container >
                                    <Grid item xs={12} md={7} container justifyContent={{xs:'flex-start',md:'flex-end'}} >
                                        <Button variant='contained' 
                                                sx={{textTransform:"none", 
                                                        mt:1,
                                                        mr:2,
                                                        backgroundColor:'#0F2FEB', 
                                                        alignSelf:'center', 
                                                        whiteSpace:'nowrap',
                                                        '&:hover': {
                                                        backgroundColor: '#0F2FEB',
                                                    }}} 
                                                size='small' endIcon={<GetAppOutlinedIcon />} onClick={()=> {
                                            setShowDownloadModal(true)
                                        }}> Download Data
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} md={5}>
                                        <Filters onInputChange={onInputChange} filters={filters} useProductFilter={showProductFilter}
                                            isOpen={isDrawerOpen} toggleDrawer={handleDrawer}/>
                                    </Grid>
                                </Grid> 
                                
                                <ProductSegments filters={filters} setShowDownloadModal={setShowDownloadModal} showDownloadModal={showDownloadModal}/>
                            </Card>
                            
                                <TransactionSales filters={filters}/>
                        </TabPanelComponent>
                    }
                
                   
                    {
                        <TabPanelComponent value={activeTab} index={1}> 
                            <Grid container>
                                <Grid item xs={12} >
                                    <Card sx={{mb:4}}>
                                        
                                        <Grid sx={{px:2, pt:1}}>
                                            <Filters onInputChange={onInputChange} filters={filters} useProductFilter={showProductFilter}
                                            isOpen={isDrawerOpen} toggleDrawer={handleDrawer}/>
                                        </Grid>
                
                                        <Divider sx={{my:3}}/>

                                        <AssociationRules filters={filters} onInputChange={onInputChange} showDownloadModal={showDownloadModal} setShowDownloadModal={setShowDownloadModal}/>
                                    </Card>
                                </Grid >
                            </Grid> 
                        </TabPanelComponent>
                    } 
                </Grid>
            </Grid>
        </Grid>
    )
}




            
            