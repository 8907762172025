import React from 'react';
import { Link } from "@mui/material";


export default function ZoneAndBranchLink({ store, storeId, year, month, period, children, disable = false, zone }) {
    const linkToBranchView = `#/sbu/sales/branch-view/${store}/${storeId}/${month}/${year}/${period}`
    const linkToZoneView = `#/sbu/sales/zone-view/${zone}/${month}/${year}/${period}`

    if (disable === true || !year || !month || !period) {
        return children
    } else if (disable || typeof store !== 'string' || typeof storeId !== 'number') {
        return <Link href={linkToZoneView} underline="hover" color="inherit" target="_blank" rel="noopener">
            {children}
        </Link>;
    }
    return <Link href={linkToBranchView} underline="hover" color="inherit" target="_blank" rel="noopener">
        {children}
    </Link>;
}