import {useEffect, useState} from "react";
import {Responsive, WidthProvider} from "react-grid-layout";
import _ from "lodash";
import Editor from "./Editor";
import ActionButtons from "./actionButtons";
import Viewer from "./Viewer";
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function DashboardContent({data, onSave}) {
    const dashboardServerId = data?.id.toString().startsWith("tmp-") ? null : data?.id
    const [isEditing, setIsEditing] = useState(!dashboardServerId)  // default to edit if this is a new dashboard
    const [localData, setLocalData] = useState(data)

    if (!data) {
        return <div>Please select a dashboard</div>
    }

    return (
        <>
            {/* <div className={"row"}>
                <div className={"col"}>
                    <h1 className={""}>{data.title}</h1>
                </div>
                <div className={"col text-end"}>
                    <ActionButtons
                        isEditing={isEditing}
                        toggleEdit={() => {
                            if (isEditing) {
                                setIsEditing(!isEditing)
                            } else {
                                // update the local data
                                setLocalData(data)
                                setIsEditing(!isEditing)
                            }
                        }}
                        onSaveClicked={() => {
                            onSave(localData)
                            setIsEditing(!isEditing)
                        }}
                        isEditable={data.is_editable}
                    />

                </div>
            </div> */}

            <Grid container spacing={2} sx={{marginTop:2}}>
                <Grid item md={6}>
                    <Typography variant="h5">{data.title}</Typography>
                </Grid>
                <Grid item md={6}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <ActionButtons
                            isEditing={isEditing}
                            toggleEdit={() => {
                                if (isEditing) {
                                    setIsEditing(!isEditing)
                                } else {
                                    // update the local data
                                    setLocalData(data)
                                    setIsEditing(!isEditing)
                                }
                            }}
                            onSaveClicked={() => {
                                onSave(localData)
                                setIsEditing(!isEditing)
                            }}
                            isEditable={data.is_editable}
                        />
                    </Box>
                </Grid>
            </Grid>

            {
                !!data.description && <div className={"row"}>
                    <div className={"col"}>
                        <p className={""}>{data.description}</p>
                    </div>
                </div>
            }

            {
                isEditing ? <Editor data={localData} onChange={setLocalData}/> : <Viewer data={data}/>
            }


        </>
    )
}