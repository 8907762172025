import { Grid, Card, Typography, Box } from '@mui/material'
import React from 'react'
import ComponentWithIndicator from './componentWithIndicator'

export const DisplayValue = () => {
    return <>
        <Typography variant='subtitle2' sx={{ paddingLeft: '.5rem'}}> NCR</Typography>
        <Box sx={{ background: '#c9dae7', height: '2rem', display: 'flex', alignItems: 'center', paddingLeft: '.5rem'}}>
            <Typography variant='subtitle2'> VALUE HERE</Typography>
        </Box>
    </>
}

export default function RankingCard({title, comparisonLabel}){

    return <Grid item sm={3} xs={12}> 
        <Card  variant="outlined" sx={{ borderRadius:'.5rem'}}>
            <Grid container sx={{ backgroundColor:'#caddeb', padding: 1 }} >
                <Grid item sm={6} xs={6}> 
                    <Typography variant="h6">{ title }</Typography>
                </Grid>
                <Grid item sm={6} xs={6}>
                    <Typography variant="h6">{ comparisonLabel }</Typography>
                </Grid>
            </Grid>
            <ComponentWithIndicator
                variant='rankingComparisonIndicator'
                component={ <DisplayValue />}
                comparisonLabelStyles={{ textAlign:'left', paddingLeft:'.5rem'}}
                indicatorStyles={{}}
            />
        </Card>
    </Grid>

}