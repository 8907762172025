import React from 'react';
import { getDefaultPercentageColor, toTitleCase } from './util';
import { abbreviateNumber } from '../../../common/utils';
import moment from "moment";
import ReactEcharts from "echarts-for-react";
import { valueCheckerIfValid } from '../../../common/utils';
import { metricCalculationsL3, metricPlanCalculationsL3 } from './DataProvider/util';


const metricConfig = {
    'actual_sales': {
        plan: (item) => {
            return item?.hasOwnProperty('egr_plan_sum') ? item.egr_plan_sum : item?.sales_plan_sum
        },
        value: (item) => item.hasOwnProperty('gr_gross_sales_sum') ? item.gr_gross_sales_sum : item?.gross_sales_sum,
        itemStyle: (item) => ({
            color: item?.hasOwnProperty('egr_plan_sum')
                ? getDefaultPercentageColor((item?.gr_gross_sales_sum / item?.egr_plan_sum) * 100)
                : (item?.sales_plan_sum
                    ? getDefaultPercentageColor((item?.gross_sales_sum / item?.sales_plan_sum) * 100)
                    : '#B4B4B4'),
        }),
    },
    'actual_transactions': {
        plan: (item) => {
            if (!item?.hasOwnProperty('gr_gross_sales_sum') && (!item.hasOwnProperty('channel') || item.channel === 'In Store')) {
                return item.sales_plan_sum / 1300
            }
        },
        value: (item) => {
            if (item.hasOwnProperty('gr_txn_count_sum')) {
                return item.gr_txn_count_sum
            }
            if (item.hasOwnProperty('store_txn_count_sum')) {
                return item.store_txn_count_sum
            }
            if (
                item.hasOwnProperty('business_unit') || item.hasOwnProperty('higher_mbu')
                || item.hasOwnProperty('bu_txn_count_sum') || item.hasOwnProperty('bu_txn_count_sum_distinct')
            ) {
                if (item.hasOwnProperty('bu_txn_count_sum_distinct')) {
                    return item.bu_txn_count_sum_distinct
                }
                return item.bu_txn_count_sum
            }
        },
        itemStyle: (item) => ({
            color: item?.hasOwnProperty('gr_gross_sales_sum')
                ? '#B4B4B4'
                : item.hasOwnProperty('channel') && item.channel !== 'In Store'
                    ? '#B4B4B4'
                    : getDefaultPercentageColor(item.sales_plan_sum / 1300),  // FIXME: this may not be the correct formula. Color should vs plan, not the plan itself
        }),
    },
    'actual_basket_size': {
        plan: (item) => {
            if (!item?.hasOwnProperty('gr_gross_sales_sum') && (!item.hasOwnProperty('channel') || item.channel === 'In Store')) {
                return item.sales_plan_sum / 1300
            }
        },
        value: (item) => {
            const sales = metricConfig['actual_sales'].value(item);
            const transactions = metricConfig['actual_transactions'].value(item);
            return sales / transactions;
        },
        itemStyle: (item) => ({
            color: item?.hasOwnProperty('gr_gross_sales_sum')
                ? '#B4B4B4'
                : item.hasOwnProperty('channel') && item.channel !== 'In Store'
                    ? '#B4B4B4'
                    : getDefaultPercentageColor(item.sales_plan_sum / 1300),
        }),
    },
    'actual_catchment_rate': {
        plan: (item) => { },
        value: (item) => {
            if (
                item.hasOwnProperty('gr_gross_sales_sum') ||
                item.hasOwnProperty('channel') ||
                item.hasOwnProperty('bu_txn_count_sum') ||
                item.hasOwnProperty('bu_txn_count_sum_distinct')
            ) {
                return null
            }
            return (Number(item.ds_foot_traffic_sum) / Number(item.mall_foot_traffic_sum) * 100)
        },
        itemStyle: (item) => ({ color: '#B4B4B4' }),
    },
    'actual_conversion_rate': {
        plan: (item) => { },
        value: (item) => {

            if (
                item.hasOwnProperty('gr_gross_sales_sum') ||
                item.hasOwnProperty('channel') ||
                item.hasOwnProperty('bu_txn_count_sum') ||
                item.hasOwnProperty('bu_txn_count_sum_distinct')
            ) {
                return null
            }
            return Number(item.foot_traffic_txn_count_sum) / Number(item.ds_foot_traffic_sum) * 100
        },
        itemStyle: (item) => ({ color: '#B4B4B4' }),
    },
    'actual_space_tfa': {
        plan: (item) => {
            if (item.hasOwnProperty('total_floor_selling_area_sum')) { //fixme: should be item.hasOwnProperty('zone') but zone doesn't exist in per store data
                return Number(item.sales_plan_sum) / Number(item.total_floor_selling_area_sum)
            }
        },
        value: (item) => item.hasOwnProperty('gr_gross_sales_sum')
            ? null
            : (item.hasOwnProperty('total_floor_selling_area_sum') //fixme: should be item.hasOwnProperty('zone') but zone doesn't exist in per store data
                ? Number(item.gross_sales_sum) / Number(item.total_floor_selling_area_sum)
                : null),
        itemStyle: (item) => ({
            color: item.hasOwnProperty('total_floor_selling_area_sum') //fixme: should be item.hasOwnProperty('zone') but zone doesn't exist in per store data
                ? getDefaultPercentageColor(Number(item.sales_plan_sum) / Number(item.total_floor_selling_area_sum))
                : '#B4B4B4',
        }),
    },
    'actual_space_nsa': {
        plan: (item) => {
            if ((item.hasOwnProperty('bu_txn_count_sum') || item.hasOwnProperty('bu_txn_count_sum_distinct') || item.hasOwnProperty('business_unit') || item.hasOwnProperty('zone') || item.hasOwnProperty('net_selling_area_sum'))) {
                return Number(item.sales_plan_sum) / Number(item.net_selling_area_sum)
            }
        },
        value: (item) => item.hasOwnProperty('gr_gross_sales_sum')
            ? null
            : ((item.hasOwnProperty('bu_txn_count_sum')  || item.hasOwnProperty('bu_txn_count_sum_distinct') || item.hasOwnProperty('business_unit') || item.hasOwnProperty('zone') || item.hasOwnProperty('net_selling_area_sum')) //fixme: should be item.hasOwnProperty('zone') but zone doesn't exist in per store data
                ? Number(item.gross_sales_sum) / Number(item.net_selling_area_sum)
                : null),
        itemStyle: (item) => ({
            color: (item.hasOwnProperty('bu_txn_count_sum')  || item.hasOwnProperty('bu_txn_count_sum_distinct') || item.hasOwnProperty('business_unit') || item.hasOwnProperty('zone') || item.hasOwnProperty('net_selling_area_sum'))
                ? getDefaultPercentageColor(Number(item.sales_plan_sum) / Number(item.net_selling_area_sum))
                : '#B4B4B4',
        }),
    },
    'actual_smac_sales': {
        plan: (item) => {
            if ((item.hasOwnProperty('channel') && item.channel !== 'In Store')) {
                return
            }
            if (item.hasOwnProperty('zone') || !item.hasOwnProperty('business_unit')) { // latter 2 conditionals just means the data is from BU
                return item.gross_sales_sum * 0.70
            }
        },
        value: (item) => item.hasOwnProperty('gr_smac_sales_sum')
            ? item.gr_smac_sales_sum
            : item.hasOwnProperty('smac_sales_sum') ? item.smac_sales_sum : null,
        itemStyle: (item) => ({
            color: (item.hasOwnProperty('channel') && item.channel !== 'In Store')
                ? '#B4B4B4'
                : ((item.hasOwnProperty('zone') || !item.hasOwnProperty('business_unit')) // latter 2 conditionals just means the data is from BU
                    ? getDefaultPercentageColor(item.gross_sales_sum * 0.70)
                    : '#B4B4B4'),
        }),
    },
    'actual_mall_foot_traffic': {
        plan: (item) => { },
        value: (item) => item.hasOwnProperty('mall_foot_traffic_sum') ? item.mall_foot_traffic_sum : null,
        itemStyle: (item) => ({
            color: '#B4B4B4',
        }),
    },
    'actual_sm_store_foot_traffic': {
        plan: (item) => { },
        value: (item) => item.hasOwnProperty('ds_foot_traffic_sum') ? item.ds_foot_traffic_sum : null,
        itemStyle: (item) => ({
            color: '#B4B4B4',
        }),
    },
    'actual_units_sold': {
        plan: (item) => { },
        value: (item) => item.hasOwnProperty('sales_units_sum') ? item.sales_units_sum : item?.gross_sales_sum,
        itemStyle: (item) => ({
            color: item?.sales_units_sum
                ? getDefaultPercentageColor((item?.sales_units_sum / item?.sales_units_sum) * 100)
                : '#B4B4B4'
        }),
    },
    'actual_units_sold_per_txn': {
        plan: (item) => { },
        value: (item) => {
            const transactions = metricConfig['actual_transactions'].value(item);
            return transactions ? item.sales_units_sum / transactions : null
        },
        itemStyle: (item) => ({
            color: '#B4B4B4',
        }),
    },
};

const metricConfigL3 = {
    'actual_sales': {
        plan: (item) => metricPlanCalculationsL3["actual_sales"](item),
        value: (item) => metricCalculationsL3["actual_sales"](item),
        itemStyle: (item) => ({
            color: metricPlanCalculationsL3["actual_sales"](item)
                ? getDefaultPercentageColor((metricCalculationsL3["actual_sales"](item) / metricPlanCalculationsL3["actual_sales"](item)) * 100)
                : '#B4B4B4',
        }),
    },
    'actual_transactions': {
        plan: (item) => metricPlanCalculationsL3["actual_transactions"](item),
        value: (item) => metricCalculationsL3["actual_transactions"](item),
        itemStyle: (item) => ({
            color: metricPlanCalculationsL3["actual_transactions"](item)
                ? getDefaultPercentageColor((metricCalculationsL3["actual_transactions"](item) / metricPlanCalculationsL3["actual_transactions"](item)) * 100)
                : '#B4B4B4'
        }),
    },
    'actual_basket_size': {
        plan: (item) => metricPlanCalculationsL3["actual_basket_size"](item),
        value: (item) => metricCalculationsL3["actual_basket_size"](item),
        itemStyle: (item) => ({
            color: metricPlanCalculationsL3["actual_basket_size"](item)
                ? getDefaultPercentageColor((metricCalculationsL3["actual_basket_size"](item) / metricPlanCalculationsL3["actual_basket_size"](item)) * 100)
                : '#B4B4B4'
        }),
    },
    'actual_catchment_rate': {
        plan: (item) => { },
        value: (item) => metricCalculationsL3["actual_catchment_rate"](item),
        itemStyle: (item) => ({ color: '#B4B4B4' }),
    },
    'actual_conversion_rate': {
        plan: (item) => { },
        value: (item) => metricCalculationsL3["actual_conversion_rate"](item),
        itemStyle: (item) => ({ color: '#B4B4B4' }),
    },
    'actual_space_tfa': {
        plan: (item) => metricPlanCalculationsL3["actual_space_tfa"](item),
        value: (item) => metricCalculationsL3["actual_space_tfa"](item),
        itemStyle: (item) => ({
            color: metricPlanCalculationsL3["actual_space_tfa"](item)
                ? getDefaultPercentageColor((metricCalculationsL3["actual_space_tfa"](item) / metricPlanCalculationsL3["actual_space_tfa"](item)) * 100)
                : '#B4B4B4',
        }),
    },
    'actual_space_nsa': {
        plan: (item) => metricPlanCalculationsL3["actual_space_nsa"](item),
        value: (item) => metricCalculationsL3["actual_space_nsa"](item),
        itemStyle: (item) => ({
            color: metricPlanCalculationsL3["actual_space_nsa"](item)
                ? getDefaultPercentageColor(metricCalculationsL3["actual_space_nsa"](item) / metricPlanCalculationsL3["actual_space_nsa"](item) * 100)
                : '#B4B4B4',
        }),
    },
    'actual_smac_sales': {
        plan: (item) => metricPlanCalculationsL3["actual_smac_sales"](item),
        value: (item) => metricCalculationsL3["actual_smac_sales"](item),
        itemStyle: (item) => ({
            color: (metricPlanCalculationsL3["actual_smac_sales"](item)
                ? getDefaultPercentageColor((metricCalculationsL3["actual_smac_sales"](item) / metricPlanCalculationsL3["actual_smac_sales"](item)) * 100)
                : '#B4B4B4'),
        }),
    },
    'actual_mall_foot_traffic': {
        plan: (item) => { },
        value: (item) => metricCalculationsL3["actual_mall_foot_traffic"](item),
        itemStyle: (item) => ({
            color: '#B4B4B4',
        }),
    },
    'actual_sm_store_foot_traffic': {
        plan: (item) => { },
        value: (item) => metricCalculationsL3["actual_sm_store_foot_traffic"](item),
        itemStyle: (item) => ({
            color: '#B4B4B4',
        }),
    },
    'actual_units_sold': {
        plan: (item) => { },
        value: (item) => metricCalculationsL3["actual_units_sold"](item),
        itemStyle: (item) => ({
            color: '#B4B4B4'
        }),
    },
    'actual_units_sold_per_txn': {
        plan: (item) => { },
        value: (item) => metricCalculationsL3["actual_units_sold_per_txn"](item),
        itemStyle: (item) => ({
            color: '#B4B4B4',
        }),
    },
};


export default function BarTrends({ data, metric = 'actual_sales', maxValues = {}, hasNoBarColor = false }) {
    const sortedData = data ? data.sort((a, b) => {
        const dateA = moment(`${a?.year_month}`, "YYYY-MM").toDate();
        const dateB = moment(`${b?.year_month}`, "YYYY-MM").toDate();
        return dateA - dateB;
    }) : [];

    // const metricHandler = metricConfig[metric];    
    const metricHandler = metricConfigL3[metric];    

    const tooltipValueDisplay = (value) => {
        const checkValue = valueCheckerIfValid(value)
        const newValue = abbreviateNumber(checkValue)

        if (['actual_transactions', 'actual_mall_foot_traffic', 'actual_sm_store_foot_traffic'].includes(metric)) {
            return newValue
        }

        if (['actual_catchment_rate', 'actual_conversion_rate'].includes(metric)) {
            return `${newValue}%`
        }

        return `₱${newValue}`
    }

    const maxValue = React.useMemo(() => {
        if (!maxValues || !Object.keys(maxValues).length || !data || !data.length) {
            return null;
        }
        if (!metricHandler) {
            return null
        }


        const restructuredMaxValue = JSON.parse(JSON.stringify(data[0]));
        Object.keys(restructuredMaxValue).forEach(key => {
            if (maxValues.hasOwnProperty(key)) {
                restructuredMaxValue[key] = maxValues[key];
            }
        });
        return metricHandler.value(restructuredMaxValue);
    }, [data, maxValues, metricHandler])

    if (!metricHandler) {
        console.error(`Invalid metric: ${metric}`);
        return null;
    }

    const noColorItemStyle = {
        color: '#B4B4B4',
    }
    const option = {
        animation: false,
        height: '100%',
        grid: {
            top: 'middle',
        },
        xAxis: {
            type: 'category',
            show: false,
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLine: {
                show: false
            },
            data: sortedData.map(data => `${data?.year_month}`)
        },
        yAxis: {
            type: 'value',
            show: false,
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLine: {
                show: false
            },
            max: maxValue,
            min: 0
        },
        tooltip: {
            trigger: 'axis',
            appendToBody: true, //fix for tooltip not showing all, and triggering scroll
            position: function (pos, params, dom, rect, size) {
                const tooltipWidth = dom.clientWidth;
                const tooltipHeight = dom.clientHeight;

                const cursorX = pos[0];
                const cursorY = pos[1];

                const spaceRight = size.contentSize[0] - cursorX;
                const spaceBelow = size.contentSize[1] - cursorY;

                const newPosition = { top: 0, left: 0 };

                if (tooltipWidth > spaceRight) {
                    newPosition.left = cursorX - tooltipWidth;
                } else {
                    newPosition.left = cursorX;
                }

                if (tooltipHeight > spaceBelow) {
                    newPosition.top = cursorY - tooltipHeight;
                } else {
                    newPosition.top = cursorY;
                }

                return newPosition;
            },
            formatter: function (params) {
                const dataIndex = params[0]?.dataIndex;
                const item = sortedData[dataIndex];
                const value = metricHandler.value(item);
                const planValue = metricHandler.plan(item);
                const vsPlan = (planValue && value) ? ((value / planValue) * 100).toFixed(2) : null;
                const date = moment(`${item?.year_month}`, "YYYY-MM");
                const month = date.format("MMM");
                const year = date.format("YYYY");
                
                return `
                    ${toTitleCase(month)} ${year}<br>
                    Value: ${tooltipValueDisplay(value)}<br> 
                    ${vsPlan ? "vs Plan: " + valueCheckerIfValid(vsPlan) + "%" : ""}
                `;
            }
        },
        series: [
            {
                type: 'bar',
                data: sortedData.map(item => {
                    const value = metricHandler.value(item);
                    return {
                        value,
                        itemStyle: hasNoBarColor ? noColorItemStyle : metricHandler.itemStyle(item),
                        markLine: {
                            data: [
                                { type: 'min', value: value },
                                { type: 'max', value: value }
                            ]
                        }
                    };
                }),
                barMaxWidth: 10
            }
        ]

    };


    return <ReactEcharts
        notMerge={true}
        option={option}
        style={{ height: '100%' }} />
}