
import React from 'react';
import { RECENCY_BY_BRANCH, RECENCY_BY_BU } from '../../hooks';
import ToggleSelector from '../../../common/presentation/selectors/toggleSelector';
import { useSelectorValue } from '../../../common/providers/selectorProvider';

export default function RecencyByToggleSelector({recencyBy, setRecencyBy}) {
    
    const TOGGLE_OPTIONS = [
        { label: "Business Unit Group", value: RECENCY_BY_BU },
        { label: "Branch", value: RECENCY_BY_BRANCH},
    ]
    
    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setRecencyBy(val);
        }
    };

    return <ToggleSelector
        options={TOGGLE_OPTIONS}
        value={recencyBy}
        onChange={onTogglePeriodChange}
        additionalStyles={{ textTransform: 'none' }}
    />
}