import ApiClient from "../../common/API";
import { useState } from 'react';
import LoadingComponent from "../../common/components/LoadingComponent";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import * as React from 'react';
import BadgeIcon from '@mui/icons-material/Badge';
import { toast } from 'react-toastify';

export default function RegisterForm(props) {
    const [isLoading, setIsLoading] = useState(false);

    const params = {
        email: props.email,
        active_directory_id: props.adid
    }

    const signup = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsLoading(true);

        ApiClient().post('auth/register/', params).then(response => {
            setIsLoading(false);
            props.clearFields()
            toast.success("Kindly wait for the approval of your request. Thank you.");

        }).catch(error => {
            const errorMsg = error?.response?.data ? error.response.data?.email : error.message;
            setIsLoading(false);
            toast.error(errorMsg?.toString());
        })
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off">
            <Box sx={{
                typography: "body1",
                textAlign: "center",
                mb: "40px",
                mt: "10px"
            }}
                style={{
                    fontFamily: "Henry Sans",
                }}>
                Fill Up The Blank Fields To Create Your Account
            </Box>
            <TextField
                InputProps={{
                    startAdornment: <EmailIcon sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />,
                }}
                required
                id="outlined"
                label="Email"
                placeholder="Enter Your Email"
                type="email"
                fullWidth
                sx={{ my: 1, width: "100%" }}
                onChange={(e) => props.setEmail(e.target.value)} value={props.email}
            />
            <TextField
                required
                id="outlined"
                label="ADID / Network Username"
                placeholder="Enter Your ADID / Network Username"
                InputProps={{
                    startAdornment:
                        < BadgeIcon sx={{ color: "primary.main", mr: 1, my: 0.5 }} />,
                }}
                fullWidth
                sx={{ my: 1, width: "100%", mt: "15px" }}
                onChange={(e) => props.setAdid(e.target.value)} value={props.adid}
            />
            <Box textAlign='center' sx={{ my: 3 }}>
                {
                    isLoading ? <LoadingComponent /> :
                        <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            disabled={props.disabled}
                            onClick={() => { signup() }} sx={{ my: 0.5, width: "100%", fontFamily: "Henry Sans", varian: "h6" }}>SIGN UP</Button>
                }
            </Box>
        </Box >
    )

}