import {useQuery} from "react-query";
import ApiClient from "../../API";
import {useContext} from "react";
import { InsightParamsContext } from "../../context/InsightParamsContext";

export function useFetch(columns, aggregates, extra_data, table_source, filters) {
    const apiParams = {
        columns: columns.join(','),
        group_by: columns.join(','),
        aggregates: aggregates?.join(','),
        distinct:  extra_data?.distinct,
        order_by: extra_data?.order_by,
        limit: extra_data?.limit,
        ...filters,
    }
    
    const { insightParams, setInsightParams } = useContext(InsightParamsContext)

    return useQuery([table_source, apiParams], () => {
        setInsightParams(apiParams)
        return ApiClient().get(`/data/table/${table_source}/query/`, {
            params: apiParams
        }).then((res) => res.data)
    }, {
        // keepPreviousData: true,
        cacheTime: 15 * 60 * 1000,
        staleTime: 15 * 60 * 1000,
        refetchOnWindowFocus: false,
        suspense: true,
        enabled: !!table_source
    })
}

export function useLabelValueFetch(table_source, extra_data, filters) {

    // require one label and one value
    if (!extra_data.label || !extra_data.value) {
        throw new Error("No label or value selected. Please select one label and one value")
    }

    const columns = [extra_data.label, extra_data.value].filter(col => col.get_param === 'columns').map(col => col.value)
    const aggregates = [extra_data.label, extra_data.value].filter(col => col.get_param === 'aggregates').map(col => col.value)

    return useFetch(columns, aggregates, extra_data, table_source, filters)
}

export function useValueOnlyFetch(table_source, extra_data, filters) {
    // require one value
    if (table_source && !extra_data?.value) {
        throw new Error("No label or value selected. Please select one label and one value")
    }

    const columns = [extra_data?.value].filter(col => col?.get_param === 'columns').map(col => col.value)
    const aggregates = [extra_data?.value].filter(col => col?.get_param === 'aggregates').map(col => col.value)

    return useFetch(columns, aggregates, extra_data, table_source, filters)

}

export function useLabelOnlyFetch(table_source, extra_data, filters) {
    // require one label
    if (!extra_data?.label) {
        throw new Error("No label selected. Please select one label")
    }

    const selectedFields = [
        extra_data?.x_axis,
        extra_data?.y_axis,
        extra_data?.label,
        extra_data?.value
    ]

    if (extra_data?.color_field) {
        selectedFields.push(extra_data?.color_field)
    }

    const applicableFields = selectedFields.filter(col => !!col)

    const columns = applicableFields.filter(col => col?.get_param === 'columns').map(col => col.value)
    const aggregates = applicableFields.filter(col => col?.get_param === 'aggregates').map(col => col.value)

    return useFetch(columns, aggregates, extra_data, table_source, filters)
}


export function useGridFetch(table_source, extra_data, filters) {
    // require x_axis and y_axis from extra_data
    if (!extra_data?.x_axis || !extra_data?.y_axis) {
        throw new Error("No x_axis or y_axis selected. Please select one x_axis and one y_axis")
    }

    // require label
    if (!extra_data?.label) {
        throw new Error("No label selected. Please select one label")
    }

    const selectedFields = [
        extra_data?.x_axis,
        extra_data?.y_axis,
        extra_data?.label,
    ]

    if (extra_data?.color_field) {
        selectedFields.push(extra_data?.color_field)
    }

    const columns = selectedFields.filter(col => col?.get_param === 'columns').map(col => col.value)
    const aggregates = selectedFields.filter(col => col?.get_param === 'aggregates').map(col => col.value)

    return useFetch(columns, aggregates, extra_data, table_source, filters)
}