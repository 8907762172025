import { useState, useEffect } from "react";
import ApiClient from "../../../common/API";
import axios from "axios";
import LoadingComponent from "../../../common/components/LoadingComponent";

export default function CampaignResult(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    
    function formatDataObj(obj) {
        //add targetDatetime params for API 
        const date = new Date(obj['date']);
        const time = obj['time'].split(':');
        obj['targetDatetime'] = new Date(date.setHours(time[0], time[1])).toISOString();

        //modify targets since value is a string separated by \n, field is textarea
        obj['targets'] = obj['targets'].split('\n').filter(val => val !== '');
        obj['fileName'] = obj['imageSrc'].name;
        obj['fileType'] = obj['imageSrc'].type;

        //for email
        obj['to'] = obj['targetFile']
        delete obj['imageSrc']
        return obj; 
    }

    useEffect(()=>{
        const options = {
            headers: {
              'Content-Type': props.values.imageSrc?.type
            }
          };
        ApiClient().post('/campaign', formatDataObj({...props.values})).then(response=> {
            return response
        }).then(response=> {
            if(response.data.signedUrl) {
                return axios.put(response.data.signedUrl, props.values.imageSrc,options).then(()=>{
                    setLoading(false)
                }).catch(error =>{
                    console.info("ERROR",error)
                })
            }
        }).catch(error=> {
            setLoading(false)
            setError(true)
        }).finally(()=>{
            setLoading(false)
            props.setCampaignStatus(true) 
        })
    },[])

    if(loading) {
        return <LoadingComponent/>
    }

    if(!loading && error) {
        return <div className="d-flex justify-content-center py-5">
            <div className="fw-bold fs-4">
                An error encountered while creating the campaign
                <i className="ms-2 bi bi-x-circle-fill text-danger"></i>
            </div>
        </div>
    }

    return <div className="d-flex justify-content-center py-5">
        <div className="fw-bold fs-4">
            Campaign was successfully created
            <i className="ms-2 bi bi-check-circle-fill text-success"></i>
        </div>
    </div>


}