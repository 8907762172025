import { useMemo, useState } from 'react';
import { useQuery } from "react-query";
import ApiClient from '../../../common/API';
import CardComponent from "../../../common/components/CardComponent";
import CampaignTable from './CampaignTable';
import LoadingComponent from '../../../common/components/LoadingComponent';

export default function CampaignList(props) {
    

    const { isLoading, isRefetching, isError, data, error, isSuccess } = useQuery('campaignList',()=> ApiClient().get('/campaign').then(res => {
        return res.data.result
    }), {
        refetchInterval: 60 * 1000 // 1 minute
        // refetchOnWindowFocus: false,
        // staleTime: 60 * 1000 // 1 minute
    });
    return (
        
        <>        
            <CardComponent title="Campaign List" id="collapseCampaignListCard" showCaret={false}>
                {
                    (isLoading || isRefetching) && <LoadingComponent/>
                }
                {
                    isSuccess && !isRefetching && !isLoading && <CampaignTable data={ data }/>
                }
                {
                    isError && <div className="d-flex justify-content-center py-5">
                    <div >
                        {error}
                    </div>
                </div>
                }
            </CardComponent>
        </>
    )
}