import React, { useState, useRef, useEffect} from 'react';
import EditTable from "../EditTable";
import RefreshTable from '../RefreshTable';
import RefreshSchema from '../RefreshSchema';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeIcon from '@mui/icons-material/Mode';
import  IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { color } from 'echarts';

import Collapse from '@mui/material/Collapse'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'




export default function TreeNode(
    {
        parentNode, node, level, parentExpanded,
        tableId, setTableId,
        expandedNodes, setExpandedNodes,
    }) {
    
    const [modalTableId, setModalTableId] = useState(null);
    const [isEditHovered, setIsEditHovered] = useState(false);
    const isExpanded = expandedNodes?.includes(node.id);
    
    const toggleNode = (node) => {
        const isExpanded = expandedNodes.includes(node.id);
        let updatedExpandedNodes = [];

        if (isExpanded) {
            updatedExpandedNodes = expandedNodes.filter((id) => id !== node.id);
        } else {
            updatedExpandedNodes = [...expandedNodes, node.id];
        }

        setExpandedNodes(updatedExpandedNodes);
    };

    const handleCollapsible = () => {
        toggleNode(node);
        const isCurrentlyExpanded = expandedNodes.includes(node.id);
        let updatedExpandedNodes = [];

        if (isCurrentlyExpanded) {
            updatedExpandedNodes = expandedNodes.filter((id) => id !== node.id);
        } else {
            updatedExpandedNodes = [...expandedNodes, node.id];
        }

        setExpandedNodes(updatedExpandedNodes);
    };

    const selectTable = () => {
        if (node.id) {
            setTableId(node.id)
        }
        else {
            setTableId(null)
        }
    }

    const handleEditClick = (event) => {
        event.stopPropagation();
        setModalTableId(node.id)
    };

    const isParentNode = node?.is_parent;
    const isChildNode = !isParentNode;
    const showArrow = isParentNode;
    const isActive = node.activeStatus;
    const isSelected = node.id === tableId;

    const [showTooltip, setShowTooltip] = useState(false)

    //Checks if the label is on Ellipsis state
    const typographyRef = useRef(null);
    const typographyElement = typographyRef.current;
    const showEllipsis= typographyElement && typographyElement.offsetWidth < typographyElement.scrollWidth;

    return (
        <>
            <Grid
                  onClick={isParentNode ? handleCollapsible : selectTable}
                  container sx={{flexDirection:'row', backgroundColor: isSelected ? "#d3ecff;" : " ",
                  marginRight: isParentNode ? 0 : ""}}>
                    
                <Grid container xs={9} flexWrap='nowrap'>
                    {showArrow && (
                        <IconButton sx={{ p:0}} disableRipple>
                            { isExpanded ? <ArrowDropDownOutlinedIcon sx={{fontSize: 20, color:'black'}} /> :
                                           <ArrowDropUpOutlinedIcon sx={{fontSize:20, color:'black',transform:'rotate(90deg)'}}/> 
                            }
                        </IconButton>
                    )}
                    
                    {
                        <Box component="span" sx={{display:'flex',overflow:'hidden'}}
                        onMouseOver={(e) => {
                            if (showEllipsis) {
                              setShowTooltip(true);
                            }
                          }}
                          onMouseOut={(e) => {
                            setShowTooltip(false);
                          }}>
                            <Tooltip title={node.label} arrow placement='right' open={showTooltip}>
                                <Typography noWrap sx={{fontSize:'14px',fontWeight: isParentNode ? 'bold' : '', alignSelf:'center'}} ref={typographyRef}>
                                    {node.label}
                                </Typography>
                            </Tooltip>
                        </Box>
                    }
                </Grid>

                {isParentNode && (
                <Grid container xs={3} justifyContent={'flex-end'} >
                        {/* Refresh Icon */}
                        <RefreshSchema parentNode={node}/>
                </Grid>
                    
                )}
                {isChildNode && (
                    <>
                    <Grid container xs={3} justifyContent={'flex-end'} pr={1}>
                        <Stack direction='row'>
                            <IconButton sx={{'&:hover': { backgroundColor: 'transparent'}, p:0}} disableRipple
                                onMouseEnter={()=>setIsEditHovered(true)}
                                onMouseLeave={()=>setIsEditHovered(false)}
                                onClick={(e) => handleEditClick(e)}>

                                <ModeIcon sx={{fontSize:20,color: isEditHovered ? "blue" : "grey"}}/>
                            </IconButton>

                            {!!modalTableId && (
                            <EditTable 
                                    tableId={modalTableId}
                                    isOpen={!modalTableId}
                                    onToggle={(isOpen) =>{
                                        if(!isOpen) setModalTableId(null)
                                    }}
                            />
                            )}
                           <RefreshTable node={node}/>
                        </Stack>
                    </Grid>
                    </>
                )}
            </Grid>


            <Collapse in={isExpanded || parentExpanded}>
                <Grid sx={{ml:'22px'}}>
                    {isExpanded && isParentNode && (!node.children || node.children.length === 0) ? (
                            <Grid sx={{color:'grey'}} >No tables found in this schema</Grid>
                        ) : (
                            node.children?.map((childNode) => (
                                <TreeNode
                                    key={childNode.id}
                                    parentNode={node}
                                    node={childNode}
                                    level={level + 1}
                                    parentExpanded={isExpanded || parentExpanded}
                                    expandedNodes={expandedNodes}
                                    setExpandedNodes={setExpandedNodes}
                                    setTableId={setTableId}
                                    tableId={tableId}
                                />
                            ))
                    )}
                </Grid>
            </Collapse>
        </>
    );
}