import React, { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { Button, Menu, MenuItem, Grid, Box, IconButton, Divider, ListItemIcon } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ChangePassword from './modal/ChangePassword';
import ManageThisModuleMenuItem from "./ManageThisModuleMenuItem"
import { clearAuthTokens } from '../utils';

export default function MenuButton({name, access, path, items, isIconButton, icon, isUserMenu, handleDrawer, dashboardCards, loginDetails}) {
	const [anchorEl, setAnchorEl] = useState(null);

	const location = useLocation();
	const open = Boolean(anchorEl);

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	}

	const handleClose = () => {
		setAnchorEl(null);
	};
	
	const arr = dashboardCards?.map(item => '/' + item)

	const isDashboardModule = arr?.includes(location.pathname)

	const MenuItems = items && items?.map((item, index) => {
		if (item.name === 'Manage this module') {
			if (!item.access) {
				return null
			}
			return <ManageThisModuleMenuItem item={item}/>
		}
		return ( <React.Fragment key={index}> <MenuItem component={item.displayOnly ? undefined : NavLink} onClick={!item.displayOnly ? handleClose : undefined} to={item.path}
			sx={{
				p: 1,
				ml: 1,
				mr: 1,
				display: `${!item.access ? 'none' : 'block'}`,
				minWidth: '180px',
				fontWeight: `${location.pathname === item.path ? 'bold' : 'regular'}`,
				pointerEvents: item.displayOnly ? 'none' : 'auto',
			}}
		>
				{item.itemIcon && (
					<ListItemIcon>
						{item.itemIcon}
					</ListItemIcon>
				)}
			{item.name}

				</MenuItem>
            {item.displayOnly && <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.5)', borderWidth: '1px'}} />}
        </React.Fragment>
    );
});

	if(!items) {
		return <Button size="small" variant="text" component={NavLink} onClick={()=>{handleClose(); handleDrawer();}} to={path} disableTouchRipple disableRipple
			sx={{  
				display: `${!access ? 'none' : 'block'}`, 
				textTransform:'none', 
				fontSize:'1rem', 
				fontFamily:'Henry Sans', 
				color:'black',
				fontWeight:`${location.pathname === path || (isDashboardModule && name === 'Dashboard') ? 'bold' : 'regular'}`,
				borderBottom: `${location.pathname === path || (isDashboardModule && name === 'Dashboard')  ? '4px solid #1976d2' : 'none'}`,
				borderRadius:'0px',
				backgroundColor:'transparent',"&:hover": {
					backgroundColor: "transparent"
				},
				px:2,
				transition: 'border-bottom 0.1s ease-in-out', 
			}} 
			>
				<Box sx={{whiteSpace:'nowrap'}}>
					{ name }
				</Box>	
		</Button>
	}

	let menuButtonDisplay;
	if (isIconButton) {
		menuButtonDisplay =
			<Box>
				<IconButton
					aria-label="account of current user"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={handleMenuClick}
					color="black"
				>
					{icon}
				</IconButton>
			</Box>
	}

	return (
		<>
			{menuButtonDisplay}
			<Menu
				disableScrollLock={true}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'menu',
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{MenuItems}
				{
					isUserMenu && <>
						{   
							(loginDetails?.canChangePassword || loginDetails?.can_change_password )&&
							<ChangePassword closeMenu={handleClose}/>
						}
			

						<MenuItem onClick={() => { clearAuthTokens(); window.location.reload(); }}>
							<ListItemIcon>
								<LogoutOutlinedIcon />
							</ListItemIcon>
							Logout
						</MenuItem>
					</>
				}
			</Menu>
		</>
	)
}