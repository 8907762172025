import React from "react";

export const TopLineContext = React.createContext({
    items: []
})


export const SelectorContext = React.createContext({
    setValue: (key, value) => { },
    getValue: (key) => { },
    getValues: () => { },
    setValues: (values) => { },
    resetValues: () => { }
})
