import React from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import { Typography } from '@mui/material';
import { parsePolygon } from '../utils';

export const SelectedBarangayPolygon = ({ selectedBarangayPolygon }) => {
    return (
        <Polygon
            key={'selectedbrgy'}
            positions={[parsePolygon(selectedBarangayPolygon.geometry)]}
            color={'white'}
            fillOpacity={1}
            weight={0.2}
            fillColor={'yellow'}
        >
            <Tooltip direction="bottom" offset={[0, 50]} opacity={1} permanent>
                <div>
                    <Typography variant="h6">
                        {selectedBarangayPolygon.barangay_name}
                    </Typography>
                    <Typography variant="subtitle2">
                        {selectedBarangayPolygon.city_name}, {selectedBarangayPolygon.province_name}, {selectedBarangayPolygon.region_name}
                    </Typography>
                </div>
            </Tooltip>
        </Polygon>
    );
};