import {useState} from "react"
import FilterRow from "./FilterRow";
import { Grid,Typography, Button } from "@mui/material";

export default function Filters({onChange, chartFilters, tableId}) {
    function handleAddRow() {
        onChange([
            ...chartFilters,
            { filterName: null, operator: "eq", value: null }
        ])
    }

    if (!tableId) {
        return null;
    }

    return <Grid container md={12} spacing={1}>
        <Grid item md={12}> <Typography fontWeight="bold" sx={{ color: "primary.main" }}>Filters</Typography> </Grid>
        {
            chartFilters?.map((filter, index) => {
                return <FilterRow
                    tableId={tableId}
                    filterData={filter}
                    onChange={(newFilterRow) => {
                        const newChartFilters = [...chartFilters]
                        newChartFilters[index] = {
                            ...newChartFilters[index],
                            ...newFilterRow
                        }
                        onChange(newChartFilters)
                    }}
                />
            })
        }

        <Grid item md={12}>
            <Button variant="contained" size="small"onClick={()=>handleAddRow()} >
                Add Filter
            </Button>
        </Grid>
    </Grid>
}