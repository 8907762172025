import React from "react";
import { Card, Grid, Typography, Box } from "@mui/material";
import NumericValueDisplay from "../numericValueDisplay";
import BarIndicator from "../barIndicator";
import LoadingComponent from "../../../../../common/components/LoadingComponent";

export default function CustomerCardType({
    type,
    count,
    countLastYear,
    windowSize,
    totalSMACMembers,
    isCustomerDataCardDisplay = false,
    isStickyCustomerDataCardDisplay = false,
    isLoading = false }) {

    const countPercentage = (count / totalSMACMembers) * 100;

    if (isCustomerDataCardDisplay) {
        return (
            <Grid item xs={12} sm={2.5} p={windowSize.isOnMobile && 1} key={type}>
                <Card sx={{ p: 1.5, borderRadius: '.5rem', backgroundColor: '#E1EBF2' }}>
                    <Grid container xs={12} display={'flex'}>
                        <Grid item xs={3.5} alignSelf={'center'}>
                            <img src={type} alt={type} height='45rem' width='auto' />
                        </Grid>
                        <Grid item xs={8.5}>
                            <Grid item xs={12} mx={2} display={'flex'}>
                                {isLoading ? <LoadingComponent size={'1rem'} sxClasses={{ py: .5 }} />
                                    : <>
                                        <Typography variant='body1' fontWeight='bold'> <NumericValueDisplay value={count} isAbbreviated={true} /></Typography>
                                        <Typography variant='body1' > {isNaN(countPercentage) ? " (-)" : ` (${countPercentage.toFixed(1)}%)`}</Typography>
                                    </>
                                }
                            </Grid>
                            <Grid container data-tag="allowRowEvents" my={0.5} justifyContent={'space-around'}>
                                <Grid item textAlign={'center'} alignItems={'center'} >
                                    {isLoading ? <LoadingComponent size={'1rem'} sxClasses={{ py: .5 }} />
                                        :
                                        <Box sx={{ width: '5rem', height: '1.8rem' }}>
                                            <BarIndicator
                                                value={(count / countLastYear * 100).toFixed(1)} isValueColorCoded={true} isOnTable={true}
                                                label={'vs last year'} />
                                        </Box>
                                    }
                                    <Typography variant="body2">{'vs last year'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    if (isStickyCustomerDataCardDisplay) {
        return (
            <Grid item xs={12} sm={2.5} p={windowSize.isOnMobile && 1} key={type}>
                <Card sx={{ p: 1, borderRadius: '.5rem', backgroundColor: '#E1EBF2' }}>
                    <Grid container xs={12} display={'flex'} alignItems={'center'}>
                        <Grid item xs={3.5} alignSelf={'center'}>
                            <img src={type} alt={type} height='35rem' width='auto' />
                        </Grid>
                        <Grid item xs={8.5}>
                            <Grid item xs={12} mx={2} display={'flex'}>
                                {isLoading ? <LoadingComponent size={'1rem'} py={.5} />
                                    : <>
                                        <Typography variant='body1' fontWeight='bold'> <NumericValueDisplay value={count} isAbbreviated={true} /></Typography>
                                        <Typography variant='body1' > {isNaN(countPercentage) ? " (-)" : ` (${countPercentage.toFixed(1)}%)`}</Typography>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    return (
        <Grid item xs={12} sm={2.5} p={windowSize.isOnMobile && 1} key={type}>
            <Card sx={{ p: 1.5, borderRadius: '.5rem', backgroundColor: '#E1EBF2' }}>
                <Grid container xs={12} display={'flex'}>
                    <Grid item xs={3.5} alignSelf={'center'}>
                        <img src={type} alt={type} height='45rem' width='auto' />
                    </Grid>
                    <Grid item xs={8.5}>
                        <Grid item xs={12} mx={2} display={'flex'}>
                            {isLoading ? <LoadingComponent size={'1rem'} py={.5} />
                                : <>
                                    <Typography variant='body1' fontWeight='bold'> <NumericValueDisplay value={count} isAbbreviated={true} /></Typography>
                                    <Typography variant='body1' > {isNaN(countPercentage) ? " (-)" : ` (${countPercentage.toFixed(1)}%)`}</Typography>
                                </>
                            }
                        </Grid>
                        <Grid container data-tag="allowRowEvents" my={0.5} justifyContent={'space-around'}>
                            <Grid item textAlign={'center'} >
                                {isLoading ? <LoadingComponent size={'1rem'} py={.5} />
                                    :
                                    <Box sx={{ width: '5rem', height: '1.8rem' }}>
                                        <BarIndicator
                                            value={(count / countLastYear * 100).toFixed(1)} isValueColorCoded={true} isOnTable={true}
                                            label={'vs last year'} />
                                    </Box>
                                }
                                <Typography variant="body2">{'vs last year'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};
