import moment from "moment";
import { useAvailableDateRange } from "../hooks";
import { Box, Typography } from "@mui/material";
import LoadingComponent from "../../../../common/components/LoadingComponent";

export default function AsOfDate() {

    const {data, isLoading} = useAvailableDateRange()
    if (isLoading) {
        return <LoadingComponent/>
    }
    return <Box textAlign="end" width={"100%"} >
        <Typography variant="body1" >
            Data as of {moment(data?.transaction_date_max).format('MMMM DD, YYYY')}
            </Typography>
    </Box>
}