import React, { useMemo, useContext} from 'react';
import { getSelectedOption } from '../../../../common/utils';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';

export default function MetricFilter({isMTD, filter, onFilterChange}) {
    const windowSize = useContext(WindowSizeContext);
    
    const options = useMemo(()=> {
        return [
            { value: 'ads', label: 'ADS'},
            { value: 'actual_sales', label: isMTD ? 'MTD' : 'YTD'},
            { value: 'target_sales', label: 'Target'},
            { value: 'vs_running_daily_target', label: 'vs running daily target'},
            { value: 'last_year_actual_sales', label: isMTD ? 'Last Year MTD' : 'Last Year YTD'},
            { value: 'vs_last_year_mtd_ytd_sales', label: isMTD ? 'Last Year MTD vs LY' : 'Last Year YTD vs LY'},
            { value: 'sales_per_sqm', label: 'Sales per SQM'},
            { value: 'vs_last_year_sqm_sales', label: 'Sales per SQM vs LY'},
        ]
    }, [isMTD])

    return (
        <>
            <ConditionalSelectComponent
                    id="metric_filter"
                    labelId="metric_filter-label"
                    options={options}
                    label="Metric"
                    value={filter ? (windowSize.isOnMobile ? filter?.metric_column : getSelectedOption(filter?.metric_column, options)) : '' || null}
                    onSelectChange={(event,newValue)=>{
                        windowSize.isOnMobile ? onFilterChange(event.target.value, 'metric_column')
                        : onFilterChange(newValue?.value, 'metric_column')  
                    }}
                    endAdornment={true}
                    startAdornment={false}
                    clearSelected={() => onFilterChange('','metric_column')}
                    disableClearable={false}
            />
        </>
    )
}