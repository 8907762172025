import React, { memo } from "react";
import Content from "./content";
import { Responsive, WidthProvider } from "react-grid-layout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

function Layout({ dashboardLayoutValue, onChangeLayout, content, onChangeContent, tableId }) {
	const ResponsiveReactGridLayout = WidthProvider(Responsive);
	const items = dashboardLayoutValue.layouts.lg;
	const addLayoutItem = () => {
		const defaultItemWidth = 3;
		const uniqueId = new Date().getTime(); // unique id so adding containers doesn't reflect old content
		let newItem;
		if (items.length === 0) {
			newItem = {
			i: uniqueId.toString(),
			x: 0,
			y: 0,
			w: defaultItemWidth,
			h: 2,
			};
		} else {
			newItem = {
			i: uniqueId.toString(),
			x: (items.length * defaultItemWidth) % 12,
			y: Infinity, // puts it at the bottom
			w: defaultItemWidth,
			h: 2,
			};
		}

		onChangeLayout([...items, newItem]);
	};

	const deleteLayoutItem = (itemId) => {
		onChangeLayout(dashboardLayoutValue.layouts.lg.filter((item) => item.i !== itemId));
	};

	return (
    <>
		{/* <hr className="my-5" />
		<div className="row">
			<div className="col-md-10">
				<h4>Layout and Content</h4>
			</div>
		</div> */}
		<Grid container xs>
			<Grid item md={10}>
				<Typography variant="h5">Layout and Content</Typography>
			</Grid>
		</Grid>
		
		<ResponsiveReactGridLayout
			rowHeight={50}
			layouts={dashboardLayoutValue.layouts}
			// onBreakpointChange={this.onBreakpointChange}TODO: add handler for on breakpoint change
			measureBeforeMount={false}
			useCSSTransforms={false}  // disable this
			compactType={dashboardLayoutValue.compact_type}
			onLayoutChange={onChangeLayout}
		>
			{
				dashboardLayoutValue.layouts.lg?.map((layoutItem) => {
					const layoutItemContent = (content || {})[layoutItem.i];
					return (
					// <div key={layoutItem.i} className="edit-mode shadow-sm hover:shadow-lg transition-all overflow-hidden">
						// <Content
						// 	tableId={tableId}
						// 	contentObject={layoutItemContent}
						// 	onChangeContent={(newContent) => {
						// 		onChangeContent(layoutItem.i, newContent);
						// 	}}
						// 	onDelete={() => deleteLayoutItem(layoutItem.i)}
						// />
					// </div>
					
					<Box  key={layoutItem.i}  >
						<Content
							tableId={tableId}
							contentObject={layoutItemContent}
							onChangeContent={(newContent) => {
								onChangeContent(layoutItem.i, newContent);
							}}
							onDelete={() => deleteLayoutItem(layoutItem.i)}
						/>
					</Box>
					);
				})
			}
		</ResponsiveReactGridLayout>
		{/* <div className="position-fixed bottom-0 end-0 me-4 mb-4">
			<button className="btn btn-link" onClick={addLayoutItem}>
			<i className="bi bi-plus-lg text-white rounded-circle bg-primary p-3 d-inline-flex justify-content-center align-items-center" />
			</button>
		</div> */}
		<Fab size="medium" color="primary" aria-label="add" onClick={addLayoutItem}sx={{ position: 'fixed', bottom: 25, right: 25}}>
			<AddIcon />
		</Fab>
 	</>
	);
}

export default memo(Layout);
