import React from 'react'
import VerticalBarGraph from './verticalBarGraph'
import { Grid } from '@mui/material'
import { useSMACMembersByGender } from '../../hooks'
import LoadingComponent from '../../../../../common/components/LoadingComponent'
import { sortArrayBasedOnGivenArray } from '../../../../../common/utils'

export default function GenderGraph() {
    const {data, isLoading} = useSMACMembersByGender()

    const sortingOrders = ['Female', 'Male', 'Other Gender', 'No Data']

    const sortedData = sortArrayBasedOnGivenArray(data, sortingOrders, 'dimension_value')

    const genderLabels = sortedData?.map(item => item.dimension_value)
    const genderData = sortedData?.map(item => item.total_members)

    if (isLoading) {
        return <LoadingComponent />
    }

    return <Grid> <VerticalBarGraph title={'Gender'} xAxisLabel={genderLabels} seriesData={genderData} /> </Grid>
}