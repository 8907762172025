import React, {useContext, useMemo} from "react";
import ApiClient from "../../../../common/API";
import {useQuery} from "react-query";
import SalesMetricPolygon from "./LeafletElements/SalesMetricPolygon";
import {
    filterPolygons,
    getMaxMetricValue,
    getMinMetricValue,
    markOutsideBoundPolygons,
    standardizeProvince,
    usePolygon
} from "./util";
import {StoreAnalyticsFilterContext} from "../contexts";

function fillDisplayMetric(polygonData, salesData) {
    if (polygonData.region && !polygonData.hasOwnProperty('province')) {
        // region only
        const hasValue = !!salesData?.find(data => data.region === polygonData.region)
        const value = hasValue ? salesData.find(row => row.region === polygonData.region).totalSales : 0

        return {
            ...polygonData,
            _displayMetric: hasValue ? value : 0,
            sales: hasValue ? value : <small><i>NO DATA</i></small>
        }
    } else if (polygonData.province && !polygonData.hasOwnProperty('cityMunicipality')) {
        // province only
        const hasValue = !!salesData?.find(row => row.region === polygonData.region && row.province === polygonData.province)
        const salesValue = hasValue ? salesData?.find(row => row.region === polygonData.region && row.province === polygonData.province).totalSales : 0

        return {
            ...polygonData,
            _displayMetric: salesValue,
            sales: hasValue ? salesValue : <small><i>NO DATA</i></small>
        }
    } else if (polygonData.cityMunicipality) {
        // cityMunicipality
        const findResult = salesData?.find(row => {
            const cleanedRowCity = row.city?.toString().replace(/(CITY|MUNICIPALITY)/gi, '').trim()
            const cleanedValCity = polygonData.cityMunicipality.toString().replace(/(CITY|MUNICIPALITY)/gi, '').trim()
            return standardizeProvince(row.province) === standardizeProvince(polygonData.province)
                && cleanedRowCity === cleanedValCity
        })
        const hasValue = !!findResult
        const salesValue = hasValue ? findResult.totalSales : 0

        return {
            ...polygonData,
            _displayMetric: salesValue,
            sales: hasValue ? salesValue : <small><i>NO DATA</i></small>
        }
    }
}

export default function SalesMetricPolygons(
    {
        polygonLevel,
        dateFilter,
        salesApiEndPointName,
        apiDefaultFilter,
        visibleBounds,
        selectedProvince
    }) {
    const {region} = useContext(StoreAnalyticsFilterContext)

    const {polygonData, isLevelLoading: isPolygonLoading} = usePolygon(polygonLevel,region, selectedProvince)

    const queryParams = {
        group_by: polygonLevel,
        region: region,
        ...dateFilter,
        ...apiDefaultFilter
    }
    const {
        data: salesData
    } = useQuery([salesApiEndPointName, 'grouped_sales', queryParams], () => {
        return ApiClient().get(`/custom/${salesApiEndPointName}-map-sales/grouped_sales`, {
            params: queryParams
        }).then(res => {
            return res.data
        })
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 15 * 60 * 1000,
        staleTime: 15 * 60 * 1000
    })

    const dataWithDisplayMetric = useMemo(() => {
        if (!polygonData || !salesData) return []

        return polygonData?.map(val => fillDisplayMetric(val, salesData))
    }, [polygonData, salesData])

    const maxMetricValue = getMaxMetricValue(dataWithDisplayMetric)
    const minMetricValue = getMinMetricValue(dataWithDisplayMetric)

    const shownPolygonData = useMemo(() => {
        if (!dataWithDisplayMetric) return []

        const withinBoundPolygons = markOutsideBoundPolygons(dataWithDisplayMetric, visibleBounds, polygonLevel)
        return filterPolygons(withinBoundPolygons, region).filter(row => {
            if (polygonLevel === "city" || polygonLevel === "barangay") {  // hide sales polygon if lower than city level
                return row._displayMetric > 0
            }
            return true
        })
    }, [polygonLevel, region, dataWithDisplayMetric])

    if (isPolygonLoading) {
        return null
    }

    return shownPolygonData.map(data => {
        const radius = (data._displayMetric - minMetricValue) / (maxMetricValue - minMetricValue);
        data['_opacity'] = radius

        return <SalesMetricPolygon
            data={data}
            radius={radius}
        />

    })

}