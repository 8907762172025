import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
import {  BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
} from 'echarts/components';
import {  SVGRenderer } from 'echarts/renderers';
import { useState } from 'react';


export default function HorizontalStackedBarGraph() {
    echarts.use(
		[TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, DatasetComponent]
	);

    const chartOption = {
        xAxis: {
            type: 'value',
            show: false
          },
          yAxis: {
            type: 'category',
            show: false,
            data: ['Mon']
          },
          series: [
            {
              name: 'Direct',
              type: 'bar',
              stack: 'total',
              label: {
                show: true
              },
              itemStyle :{
                borderColor: 'white',
                borderWidth: 1
              },
              barWidth: 100,
              data: [320]
            },
            {
              name: 'Mail Ad',
              type: 'bar',
              stack: 'total',
              label: {
                show: true
              },
              itemStyle :{
                borderColor: 'white',
                borderWidth: 1
              },
              barWidth: 100,
              data: [120]
            },
            {
              name: 'Affiliate Ad',
              type: 'bar',
              stack: 'total',
              label: {
                show: true
              },
              itemStyle :{
                borderColor: 'white',
                borderWidth: 1
              },
              barWidth: 100,
              data: [220]
            },
            {
              name: 'Video Ad',
              type: 'bar',
              stack: 'total',
              label: {
                show: true
              },
              itemStyle :{
                borderColor: 'white',
                borderWidth: 1
              },
              barWidth: 100,
              data: [150]
            },
            {
              name: 'Search Engine',
              type: 'bar',
              stack: 'total',
              label: {
                show: true
              },
              itemStyle :{
                borderColor: 'white',
                borderWidth: 1
              },
              barWidth: 100,
              data: [520]
            }
          ]
    };

    return <>
			<ReactEChartsCore
				echarts={echarts}
				option={chartOption}
				notMerge={true}
				lazyUpdate={true}
				opts={{renderer: 'svg'}}
				style={{height: '5rem', width: '25rem', display: 'block', margin: 'auto'}}
			/>
    </>
}