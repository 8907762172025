import React from "react";
import TableSourceSelector from "../../ChartGallery/EditChartModal/TableSourceSelector";
import ColumnSelector from "../../ChartGallery/EditChartModal/SelectorComponents/ColumnSelector";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import HelpIcon from '@mui/icons-material/Help';
import Typography from "@mui/material/Typography";

export default function DashboardOptions({data, onChange}) {
    const onChangeValue = (key, value) => {
        onChange(prevData => {
            return {
                ...prevData,
                [key]: value
            }
        })
    }

    return (<>
        <Grid container>
            <Grid item xs={12} marginBottom={3}>
                <TextField label="Title" id="title" placeholder="Dashboard Title" size="small" fullWidth              
                    value={data.title}
                    onChange={(e) => {
                        onChangeValue("title", e.target.value)
                    }}
                />
            </Grid>

            <Grid item xs={12} marginBottom={2}>
                <TextField label="Description" id="description" placeholder="Dashboard description" size="small" fullWidth     
                    multiline
                    rows={4} 
                    value={data.description}
                    onChange={(e) => {
                        onChangeValue("description", e.target.value)
                    }}
                />
            </Grid>

            <Grid item xs={12} marginBottom={1}>
                <FormGroup>
                    <FormControlLabel label={<Box component="span" sx={{display: 'flex'}}> <Typography marginRight={1}>Is Public Dashboard </Typography>
                        <HelpIcon data-tooltip-id="info_tooltip" data-tooltip-place="top" size="small"
                            data-tooltip-variant="info"
                            data-tooltip-content={'Setting the visibility of this dashboard either private or for everyone/other users'} /></Box> }
                        control={<Checkbox checked={data?.is_public} onChange={(e) => onChangeValue('is_public', e.target.checked)} id="is_public" />} 
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12} marginBottom={3}>
                <TableSourceSelector onChange={(newTableSource) => {
                    onChangeValue("table_source", newTableSource)
                }} tableId={data.table_source}/>
            </Grid>
            <Grid item xs={12} marginBottom={2}>
                {/* <label htmlFor="dashboardDescription">Allow filtering the dashboard by:</label> */}
                <ColumnSelector
                    tableId={data.table_source}
                    columns={data.filter_fields}
                    onChangeColumns={(newColumns) => {
                        onChangeValue("filter_fields", newColumns)
                    }}
                    columnPrimaryKey={"id"}
                    label="Allow filtering the dashboard by:"
                />
            </Grid>

        </Grid>
        {/* <div className="row mb-2">
            <div className="col">
                <label htmlFor="dashboardTitle">Title</label>
                <input
                    id="dashboardTitle"
                    className="form-control"
                    value={data.title}
                    onChange={(e) => {
                        onChangeValue("title", e.target.value)
                    }}
                />
            </div>
        </div>

        <div className="row mb-2">
            <div className="col">
                <label htmlFor="dashboardDescription">Description</label>
                <textarea
                    id="dashboardDescription"
                    className="form-control"
                    defaultValue={data.description}
                    onChange={(e) => {
                        onChangeValue("description", e.target.value)
                    }}/>
            </div>
        </div> 
        <div className="row mb-2">
            <div className="col-md-12">                                       
                <label className="form-label me-1" htmlFor="is_public">Is Public Dashboard</label> 
                <i className="bi bi-info-circle me-3"                               
                data-tooltip-id="info_tooltip" data-tooltip-place="top"
                data-tooltip-variant="info"
                data-tooltip-content={'Setting the visibility of this dashboard either private or for everyone/other users'} />
                <input className="form-check-input" type="checkbox" checked={data?.is_public} onChange={(e) => onChangeValue('is_public', e.target.checked)} id="is_public"/>
            </div>
        </div>


        <div className="row mb-2">
            <TableSourceSelector onChange={(newTableSource) => {
                onChangeValue("table_source", newTableSource)
            }} tableId={data.table_source}/>
        </div>

        <div className="row mb-2">
            <div className="col">
                <label htmlFor="dashboardDescription">Allow filtering the dashboard by:</label>
                <ColumnSelector
                    tableId={data.table_source}
                    columns={data.filter_fields}
                    onChangeColumns={(newColumns) => {
                        onChangeValue("filter_fields", newColumns)
                    }}
                    columnPrimaryKey={"id"}
                    />
            </div>
        </div>
        */}
    </>)
}