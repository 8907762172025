import React from "react";


export default function SuspenseFallback() {
    return (
        <div className="d-flex justify-content-center align-items-center h-full" style={{
            minHeight: "10em"
        }}>
            <span className="spinner-border text-primary"/>
        </div>
    )
}