import { useContext, useState } from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { Grid, Box, Typography, Card, Collapse } from '@mui/material';
import CollapsibleComponent from '../../../../common/components/CollapsibleComponent';
import VerticalBarGraph from './verticalBarGraph';
import { useValueSegmentationV2 } from './hooks';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { useFiltersContext } from '../Provider/context';


const transformData = (data) => {
    if (!data) {
        return [];
    }

    return data?.map(item => {
        const tag = item.value_segment_tag?.toUpperCase();
        const count = item.gcr_persistent_id_count_distinct;
        const sales = item.pos_sku_gross_sales_sum;
        return [tag, count, sales];
    });
}

export default function ValueSegmentation({ params }) {
    const windowSize = useContext(WindowSizeContext);
    const isOnMobile = windowSize?.isOnMobile;
    const [isExpand, setExpand] = useState(true);

    const { filters } = useFiltersContext()

    const newParams = {  
        ...params,  
        cm_channel_desc: filters?.channel  
    }  

    if (filters.hasOwnProperty('smac_consent') && filters.smac_consent !== undefined && filters.smac_consent !== '') {
        const paramsConsentKey = `smac_cdp_consent__is_${filters.smac_consent.toString()}`  // evaluates to smac_consent__is_true or smac_consent__is_false  
        newParams[paramsConsentKey] = 1  
    }  

    const { data, isLoading } = useValueSegmentationV2({
        ...newParams,
    });

    const transformedData = transformData(data)?.sort((a, b) => {
        const order = { HIGH: 0, MID: 1, LOW: 2, OTHERS: 3 };
        return order[a[0]] - order[b[0]];
    });

    // Get the max value among all customer counts
    const maxCustomerCount = transformedData ? Math.max(...transformedData.map(item => item[1])) : null;

    const labelData = transformedData ? [['', transformedData?.[0]?.[1], 'Total SMAC Sales']] : [['', 0, 'Total SMAC Sales']]

    const content = (() => {
        if (isLoading) {
            return <LoadingComponent />
        } else if (!isLoading && transformedData?.length === 0 && transformedData?.length === 0) {
            return (
                <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 18 }}>
                    No Data Available
                </Grid>
            );
        } else {
            return (
                <Box sx={{ overfloX: 'scroll', marginX: isOnMobile ? 0 : 1 }} >
                    <Grid container direction='row' padding="1rem" display="flex" justifyContent="center">
                        <Grid item xs={2} sm={2} >
                            <VerticalBarGraph
                                data={labelData}
                                isLoading={isLoading}
                                barWidthPercent={isOnMobile ? 20 : 25}
                                chartWidth='15rem'
                                isLabel={true}
                                yAxisMax={maxCustomerCount}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10}>
                            <VerticalBarGraph
                                data={transformedData}
                                isLoading={isLoading}
                                barWidthPercent={isOnMobile ? 80 : 70}
                                yAxisMax={maxCustomerCount}
                            />
                        </Grid>
                    </Grid>
                </Box>
            );
        }
    })();

    return (
        <Grid container marginTop={1}>
            <Grid item sm={12} xs={12}>
                <Card sx={{ borderRadius: ".5rem .5rem 0 0" }}>
                    <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem .5rem 0 0" }}>
                        <Grid container py={1} alignItems={'center'} display={'flex'} >
                            <Grid item xs={12} sm display={isOnMobile && 'flex'} justifyContent={'space-between'} alignItems={isOnMobile && 'center'}>
                                <Typography variant="h6" ml={2}>
                                    Customer Value Segments
                                </Typography>
                                {
                                    isOnMobile &&
                                    <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                                }
                            </Grid>
                            <Grid item xs={12} sm justifyContent={isOnMobile ? 'flex-start' : 'flex-end'} display={'flex'} alignItems={'center'} mx={isOnMobile && 2}>
                                <Box sx={{ fontStyle: 'italic' }}>
                                    <Typography variant="body1" >
                                        Note: Value Segments are updated quarterly
                                    </Typography>
                                </Box>
                                {
                                    !isOnMobile &&
                                    <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse in={isExpand}>
                        <Grid container spacing={1}>
                            <Grid item sm={12} xs={12} sx={{ overflowY: 'hidden' }}>
                                <Card sx={{ minHeight: '20rem', margin: 2 }}>
                                    {content}
                                </Card>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Card>
            </Grid>
        </Grid>
    );
}