import React, { useContext } from 'react';
import { getSelectedOption } from '../../../../../common/utils';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { WindowSizeContext } from '../../../../../common/context/WindowSizeContext';
import { styled } from "@mui/material/styles";


export default function GroupSelector({ options, onSelectGroupChange, selected }) {
    const windowSize = useContext(WindowSizeContext);
    const NewToggleButton = styled(ToggleButton)({
        width: windowSize?.isOnMobile ? '5rem' : '7rem',
        textTransform: 'capitalize',        
        color: '#0a4fb4',
        border: 0,
        borderRadius: '.25rem',
        backgroundColor: '#fff',
        borderBottom: 'none',
        '&:hover': {
            color: "#fff",
            backgroundColor: '#0a4fb4'
        },
        '&.Mui-selected, &.Mui-selected:hover': {
            color: "#fff",
            backgroundColor: '#0a4fb4'
        }, '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            borderRight: '.05rem solid lightgray',
            borderLeft: 'none'
        }, '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            marginLeft: 0
        },
        "&.Mui-disabled:hover": {
            backgroundColor: '#fff',
            color: '#00000042',
            cursor: 'not-allowed'
        }
    });

    return (
        <Grid item xs={12} sm={12}>
            <ToggleButtonGroup
                size="small"
                color="primary"
                exclusive
                sx={{ flexWrap: "wrap"}}
                value={getSelectedOption(selected, options)?.value}
                onChange={(event, value) => {
                    if (value !== null) {
                        onSelectGroupChange(value, 'group')
                    }
                }}
            >
                {
                    options.map(option => {
                        return <NewToggleButton value={option.value} disabled={option?.disabled}>{option.label}</NewToggleButton>
                    })
                }
            </ToggleButtonGroup>
        </Grid>
    );
}