import React from "react";
import SkuCountPerSegment from "./SkuCountPerSegment";
import TransactionXSalesXSegment from "./TransactionXSalesXSegment";
import {TABLE_MBA_SEGMENTS} from "../constants";
import Download from "../../../../common/components/Download";

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';

export default function ProductSegments({filters, setShowDownloadModal, showDownloadModal}) {
    

    const originalKeyToNewKey = {
        "brand": "merch_brand_name",
        "category": "merchcat_dept_desc",
    }

    const cleanedFilters = filters ? Object.keys(filters).filter(filterKey => {
        // if value is string and it is empty string, exclude it
        if (typeof filters[filterKey] === "string" && filters[filterKey].length === 0) {
            return false
        }
        // if value is array and it is empty array, exclude it
        if (Array.isArray(filters[filterKey]) && filters[filterKey].length === 0) {
            return false
        }
        return filterKey !== "model"
    }).reduce((acc, curr) => {
        if (originalKeyToNewKey[curr]) {
            acc[originalKeyToNewKey[curr]] = filters[curr]
        } else {
            acc[curr] = filters[curr]
        }

        return acc
    }, {}) : filters;

    return (<>
    
        {
            showDownloadModal && <Download
                filters={cleanedFilters}
                title={"MBA - Products"}
                apiDataPath={`data/table/${TABLE_MBA_SEGMENTS}/query/`}
                apiParams={{
                    ...cleanedFilters,
                    columns: "merch_article_desc,product_segment_desc",
                    aggregates: "total_sales_sum,total_transactions_sum",
                    group_by: "merch_article_desc,product_segment_desc",
                    order_by: "total_sales_sum DESC,total_transactions_sum DESC",
                }}
                isOpen={showDownloadModal}
                toggle={() => {
                    setShowDownloadModal(!showDownloadModal)
                }}
            />
        }

        <Grid container sx={{mt:4}}>
            <Grid item xs={12} md={8}>
                <TransactionXSalesXSegment filters={cleanedFilters} />
            </Grid>
            <Grid item xs={12} md={4}>
                <SkuCountPerSegment filters={cleanedFilters}/>
            </Grid>
        </Grid>
    </>)
}