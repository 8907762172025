import SalesPerformancePerGroup from './PerGroupPerformance';
import { useContext, useEffect, useMemo, useState } from 'react';
import { WindowSizeContext } from '../../../common/context/WindowSizeContext';
import FilterMonth from '../../../common/components/FilterMonth'
import FilterYear from '../../../common/components/FilterYear';
import AsOfDate from './Filters/AsOfDate';
import { TABLE_NAME, useBUGrouping } from "./hooks";
import { Box, GlobalStyles, Grid, Typography } from '@mui/material';
import Back from '../../../common/components/BackNavigateComponent';
import StorePerformance from './StorePerformance';
import FilterCompany from "./Filters/FilterCompany";
import TopLineDataProvider from "./DataProvider/topLineDataProvider";
import TopLine from "../common/presentation/TopLine";
import TSSInformationToolTip from "./TSSInformationToolTip";
import { useHasScrolledDownDetector } from "../../../common/utils/hooks";
import { useSelectorContext, useSelectorValue } from "../common/providers/selectorProvider";
import { useAvailableDateRangeL3 } from './DataProvider/hooks';


export default function TmpTSSSales(
    {
        additionalParams = {}, title = "SM Store Operations",
        componentProps = {
            topLine: {},
            topLineDataProvider: {},
            storePerformance: {},
            salesPerformancePerGroup: {
                enabled: true
            }
        }
    }
) {

    const windowSize = useContext(WindowSizeContext);
    const [filters, setFilters] = useState({
        month: null,
        year: null,
    })
    const [selectedRows, setSelectedRows] = useState([]);

    const selectedMetric = useSelectorValue('metric')
    const selectedViewBy = useSelectorValue('viewBy')
    const period = useSelectorValue('period');

    const { setValue: setSelectorValue } = useSelectorContext()

    const isBelowTheFold = useHasScrolledDownDetector();

    useEffect(() => {
        //reset selectrow only when filter group change
        setSelectedRows([])
    }, [selectedViewBy, selectedMetric])

    const { data: dateRangeV2, dateRangeIsLoading, error, isSuccess } = useAvailableDateRangeL3()

    const dateRange = (dateRangeV2 && !dateRangeIsLoading) ? {
        from: dateRangeV2.min,
        to: dateRangeV2.max
    } : {};

    const params = {
        month: filters?.month,
        year: filters?.year,
        ...additionalParams
    }

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setSelectorValue('period', val)
        }
    };

    function onDropdownFilterChange(value, field) {
        if (field === 'group') {
            setSelectorValue('viewBy', value)
        }
        if (field === 'metric_column') {
            setSelectorValue('metric', value)
        }

        setFilters({
            ...filters,
            [field]: value
        })
    }

    const { buGroupings, isLoading } = useBUGrouping(); //get list of all distinct higher_mbus and business_units

    const filterSelectedRows = useMemo(() => {
        const HIGHER_MBU_COLS = !isLoading ? Object.keys(buGroupings) : []; //get list of all distinct higher_mbus

        if (selectedViewBy === 'business_unit') {
            if (HIGHER_MBU_COLS && HIGHER_MBU_COLS.length > 0) {
                // Check if any higher_mbu values are in selectedRows
                const selectedHigherMBUs = selectedRows.filter(row => HIGHER_MBU_COLS.includes(row));
                // Get corresponding business_units of selected higher_mbu values
                const correspondingBUs = []
                selectedHigherMBUs.forEach(higherMBU => {
                    const buList = buGroupings[higherMBU] || [];
                    correspondingBUs.push(...buList);
                });
                // Remove corresponding business_units from selectedRows
                const filterSelectedRows = selectedHigherMBUs ? selectedRows.filter(row => !correspondingBUs.includes(row)) : selectedRows;
                // Add selected higher_mbu values to filteredSelectedRows
                return filterSelectedRows;
            } else {
                // If there are no higherMBUKeys provided, return selectedRows as is
                return selectedRows;
            }
        } else {
            return selectedRows;
        }
    }, [selectedViewBy, selectedRows, buGroupings])


    return <>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />
        <Grid container>
            <Grid item xs={12} marginY={1} display="flex" alignItems={'center'}>
                <Back />
                <Typography variant='h5' ml={3} >
                    {title}
                </Typography>
                <TSSInformationToolTip />
            </Grid>

            <Grid container sx={{ position: `${!windowSize.isOnMobile ? 'sticky' : ''}`, top: `${!windowSize.isOnMobile ? '5rem' : ''}`, zIndex: 5, backgroundColor: 'transparent' }}>
                <Grid item xs={12} sx={{ backgroundColor: '#e0e0e0' }}>
                    <Box flexGrow={1} xs={12} paddingX={1} mb={1} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem" }}>
                        <Grid container columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} paddingX={windowSize.isOnMobile && 1}>
                            {
                                windowSize.isOnMobile && <Grid item xs={12}>
                                    <AsOfDate />
                                </Grid>
                            }
                            <Grid item xs={12} sm={5}>
                                <FilterCompany
                                    tableName={TABLE_NAME}
                                    filters={filters}
                                    onPillSelectChange={onDropdownFilterChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <FilterMonth
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={isSuccess} dateRangeIsLoading={dateRangeIsLoading} />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <FilterYear
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={isSuccess} dateRangeIsLoading={dateRangeIsLoading} />
                            </Grid>
                            {
                                !windowSize.isOnMobile && <Grid item sm={4} alignItems="center" display="flex">
                                    <AsOfDate />
                                </Grid>
                            }
                        </Grid>
                    </Box>
                    <Grid item xs={12} py={.5}>
                        <TopLineDataProvider
                            params={params} period={period}
                            {...componentProps.topLineDataProvider}
                        >
                            <TopLine
                                textMap={{ title: "Topline Performance" }}
                                isStickyForTopline={isBelowTheFold}
                                topLineMetrics={!isBelowTheFold}
                                isWithTopLineHeader={true}
                                params={params} period={period}
                                onTogglePeriodChange={onTogglePeriodChange}
                                filters={filters}
                                onDropDownFilterChange={onDropdownFilterChange}
                                {...componentProps.topLine}
                            />
                        </TopLineDataProvider>
                    </Grid>
                </Grid>
            </Grid>
            {
                componentProps?.salesPerformancePerGroup?.enabled && <Grid item xs={12} mt={2.5}>
                    <SalesPerformancePerGroup
                        filters={filters} params={params}
                        onDropDownFilterChange={onDropdownFilterChange}
                        selectedRows={selectedRows} setSelectedRows={setSelectedRows}
                        {...componentProps.salesPerformancePerGroup}
                    />
                </Grid>
            }
            <Grid item xs={12} >
                <StorePerformance
                    params={params} onDropDownFilterChange={onDropdownFilterChange} selectedRows={selectedRows}
                    filterSelectedRows={filterSelectedRows}
                    {...componentProps.storePerformance}
                />
            </Grid>
        </Grid >
    </>
}