import { processDataL3, calculateMetricValuesL3 } from "../../../../TmpTSSSales/DataProvider/util.js";
import { useSBUStoreDataL3 } from "../../../../TmpTSSSales/DataProvider/hooks";

export function useStoreFootTrafficConversionL3({ params }) {
    const { currentStoreData, lastYearStoreData, isLoading: isLoadingStoreData } = useSBUStoreDataL3({ params });

    const storeID = params?.store_parent_store_id;
    const storeZone = params?.store_zone;

    const calculateAndProcessMetric = (currentData, lastYearData, metricColumn, params, groupBy, key) => {
        const total = calculateMetricValuesL3(currentData, metricColumn, key);
        const lastYearTotal = calculateMetricValuesL3(lastYearData, metricColumn, key);
    
        return processDataL3(total, { [groupBy]: null }, lastYearTotal, { ...params, metric_column: metricColumn }, [`${groupBy}`], currentData);
    };

    const getMetricsData = (groupBy, key) => ({
        mallFootTrafficData: calculateAndProcessMetric(currentStoreData, lastYearStoreData, "actual_mall_foot_traffic", params, groupBy, key),
        catchmentRateData: calculateAndProcessMetric(currentStoreData, lastYearStoreData, "actual_catchment_rate", params, groupBy, key),
        storeFootTrafficData: calculateAndProcessMetric(currentStoreData, lastYearStoreData, "actual_sm_store_foot_traffic", params, groupBy, key),
        conversionRateData: calculateAndProcessMetric(currentStoreData, lastYearStoreData, "actual_conversion_rate", params, groupBy, key)
    });

    const data = storeID ? getMetricsData(storeID, "store_parent_store_id") : storeZone ? getMetricsData(storeZone, "store_zone") : {};

    const createFootConversionRateData = (keyMetrics, data, subRrows = []) => ({
        keyMetrics,
        metricTotal: data[0].metricTotal,
        metricPlanTotal: null,
        contribution: data[0].contribution,
        vsPlan: data[0].vsPlan,
        vsLY: data[0].vsLY,
        vs2019: data[0].vs2019,
        subRrows: subRrows.map(subRow => ({
            keyMetrics: subRow.keyMetrics,
            metricTotal: subRow.metricTotal,
            metricPlanTotal: subRow.metricPlanTotal,
            contribution: subRow.contribution,
            vsPlan: subRow.vsPlan,
            vsLY: subRow.vsLY,
            vs2019: subRow.vs2019
        }))
    });

    const FootTrafficConversionData = data.mallFootTrafficData ? [
        createFootConversionRateData("Mall Foot Traffic", data.mallFootTrafficData, [
            createFootConversionRateData("Catchment Rate", data.catchmentRateData)
        ]),
        createFootConversionRateData("Dept Store Foot Traffic", data.storeFootTrafficData, [
            createFootConversionRateData("Conversion Rate", data.conversionRateData)
        ])
    ] : [];

    return {
        data: FootTrafficConversionData,
        isLoading: isLoadingStoreData
    };
}
