import React, { useState } from "react"
import { toast } from 'react-toastify';
import { useSave } from "../utils";
import { useGroupList, useUserList } from "../../../common/API/hooks";
import { getSelectedOption } from "../../../common/utils";
import { Button, Grid, IconButton, Typography, Divider, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DropdownCheckboxV3 from "../../../common/components/DropdownCheckboxV3";
import Modal from '@mui/material/Modal'
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AddToPhotosRoundedIcon from '@mui/icons-material/AddToPhotosRounded';
import handleError from "../ErrorHandling"

export default function AddModule() {
    const [isOpen, setIsOpen] = useState(false);

    const { data: groupList, isLoading: groupLoading } = useGroupList();
    const { data: userList, isLoading: userLoading } = useUserList();

    const [moduleData, setModuleData] = useState({
        name: '',
        allowed_users: [],
        allowed_groups: [],
        is_public: false
    });

    const { name, allowed_users, allowed_groups, is_public } = moduleData;

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setModuleData({
            name: '',
            allowed_users: [],
            allowed_groups: [],
            is_public: false
        })
        setIsOpen(false);
    };

    const { saveData: saveModule } = useSave()

    const userOptions = React.useMemo(() => {
        if (userLoading) {
            return []
        }
        if (!userList) {
            return []
        }
        return userList?.map((user) => {
            return {
                value: user.username,
                label: user.username,
                key: user.id,
            }
        })
    }, [userList, userLoading]);

    const groupOptions = React.useMemo(() => {
        if (groupLoading) {
            return []
        }
        if (!allowed_groups) {
            return []
        }
        return groupList?.map((group) => {
            return {
                value: group.name,
                label: group.name,
                key: group.id,
            }
        })
    }, [allowed_groups, groupLoading]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModuleData((prevModuleData) => ({
            ...prevModuleData,
            name: value
        }));
    };

    // TODO: When is_public is true, Users and Groups field should be all

    const handlePublicChange = (selectedOptions) => {
        setModuleData((prevModule) => ({ ...prevModule, is_public: !prevModule.is_public }));
    };

    const handleUserChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value)
        setModuleData((prevModule) => ({ ...prevModule, allowed_users: selectedValues }));
    };

    const handleGroupChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value)
        setModuleData((prevModule) => ({
            ...prevModule,
            allowed_groups: selectedValues
        }));
    };


    const handleSave = () => {
        const moduleData = {
            name: name,
            allowed_users: allowed_users,
            allowed_groups: allowed_groups,
            is_public: is_public,

        }
        saveModule('module', moduleData).then(() => {
            setModuleData({
                name: '',
                allowed_users: [],
                allowed_groups: [],
                is_public: false,
            })
            setIsOpen(false)
            return toast.success('Module saved!');
        })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "Module", "save");
                return toast.error(errorMessage);
            });
    }

    const isSubmitDisabled = !name;

    return (
        <>
            <Button
                variant="contained"
                sx={{ bgcolor: "#0F2FEB" }}
                size="medium"
                onClick={openModal}
                startIcon={<AddIcon />}>
                ADD MODULE
            </Button>
            <Modal
                open={isOpen}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: 'auto',
                    justifyContent: 'center'
                }} >
                <Grid xs={10} md={4}>
                    <Card sx={{ minHeight: '45vh' }}>
                        <Grid container justifyContent="space-between" p={2} >
                            <Grid display="flex" columnGap={1}>
                                <AddToPhotosRoundedIcon fontSize="large" />
                                <Typography variant="h5" fontWeight="bold" >
                                    Add Permission
                                </Typography>
                            </Grid>
                            <IconButton aria-label="close" size="small" color="error"
                                onClick={closeModal}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid sx={{ p: 2 }}>
                            <Grid item sx={{ p: 1.3 }}>
                                <TextField
                                    sx={{ width: "400px" }}
                                    size="small"
                                    label="Module Name"
                                    placeholder="Module Name"
                                    value={name}
                                    onChange={handleInputChange}>
                                </TextField>
                            </Grid>
                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={userOptions}
                                    label="Select User(s)"
                                    placeholder="Select User(s)"
                                    limitTags={1}
                                    onChange={(event, newValue) => {
                                        handleUserChange(getSelectedOption(newValue, userOptions));
                                    }}
                                    value={getSelectedOption(allowed_users, userOptions)} />
                            </Grid>
                            <Grid item sx={{ width: "420px", p: 1.3 }}>
                                <DropdownCheckboxV3
                                    options={groupOptions}
                                    label="Select Group(s)"
                                    placeholder="Select Group(s)"
                                    limitTags={1}
                                    onChange={(event, newValue) => {
                                        handleGroupChange(getSelectedOption(newValue, groupOptions));
                                    }}
                                    value={getSelectedOption(allowed_groups, groupOptions)} />
                            </Grid>
                            <Grid container justifyContent="flex-start" display="flex">
                                <FormControlLabel control={
                                    <Switch
                                        checked={is_public}
                                        color="success"
                                        onChange={handlePublicChange}
                                    />} label="Is Public:" labelPlacement="start" />
                                <Typography mt={.8} ml={1}> {is_public ? 'Yes' : 'No'}</Typography>
                            </Grid>
                        </Grid>

                        <Divider sx={{ borderBottom: 2 }} />

                        <Grid container
                            p={1.5}
                            columnGap={{ sm: 'none', md: 4 }}
                            marginLeft={{ xs: 'none', md: -2.5 }}
                            justifyContent={{ xs: 'space-between', md: 'flex-end' }}>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{ color: "#0F2FEB", width: "80px" }}
                                onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ bgcolor: "#0F2FEB", width: "80px" }}
                                onClick={handleSave}
                                disabled={isSubmitDisabled}>
                                Save
                            </Button>
                        </Grid>
                    </Card>
                </Grid>
            </Modal >
        </>
    )
}