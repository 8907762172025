import React from "react";
import Modal from '@mui/material/Modal';
import LoadingComponent from "./LoadingComponent";
import Button from '@mui/material/Button'
import Grid from "@mui/material/Grid";
import { Typography, Stack, Divider} from "@mui/material";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

export default function ConfirmationModal({ isOpen, onConfirm, icon, title, message, isLoading, onClose, loadingMessage }) {

    const handleConfirmAction = (event) => {
        event.stopPropagation()
        onConfirm();
    };

    return (
        <Modal open={isOpen} onClose={onClose}
               style={{display:'flex',alignItems:'center',justifyContent:'center'}} >

            <Grid xs={10} sm={7} md={5}>
                <Card sx={{pb:1}}>
                    {isLoading ? (
                         <Grid container alignContent={'center'}>
                            <Typography style={{ fontSize: 25, fontWeight: 600}}>{loadingMessage}</Typography>
                            <LoadingComponent/>
                        </Grid>
                    ): (
                        <>
                            <Grid container justifyContent={'space-between'}>
                                <Typography variant="h6" sx={{ml:3, mt:2,fontWeight:'bold'}}>{title}</Typography>

                                <IconButton onClick={onClose} sx={{ mr:1,mt:1,}} > 
                                    <CloseIcon sx={{fontSize:20}}/>
                                </IconButton>
                             </Grid>
                            
                            <Divider sx={{my:1, borderBottom:1, }}/>

                            <Stack direction={'row'} sx={{px:3,py:2}} spacing={2}>
                                {icon}
                                <Typography sx={{ fontSize: 15, alignSelf:'center'}}>{message}</Typography>
                            </Stack>

                            <Grid container justifyContent={'flex-end'} > 
                                <Stack direction='row' spacing={.5} sx={{mr:3,mt:2}}>
                                    <Button variant="contained" size="small"onClick={handleConfirmAction} disabled={isLoading}
                                            sx={{textTransform:'none', px:4, backgroundColor:'#0f2feb', fontWeight:'bold',
                                            '&:hover': {
                                                backgroundColor:'#0f2feb',
                                                }}}
                                            > 
                                            Confirm
                                    </Button>

                                    <Button variant="outlined" size="small" onClick={onClose} disabled={isLoading}
                                            sx={{textTransform:'none',px:4, color:'#0f2feb', fontWeight:'bold', border:'solid 1px #0f2feb',
                                            '&:hover': {
                                                border:'solid 1px #0f2feb',
                                                backgroundColor:'#fcfcfds'
                                              }}}
                                            > 
                                            Cancel
                                    </Button>
                                </Stack>        
                            </Grid>
                        </>
                    )
                    }     
                </Card>
            </Grid>
        </Modal>
    );
}