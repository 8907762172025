import React, { useContext } from 'react';
import ReactEcharts from "echarts-for-react";
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { Grid, Typography, Box } from '@mui/material';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import Item from '../../../../common/components/ItemCard';
import moment from 'moment';
import { useOpexTotals, useOpexSummaryDataV2 } from '../hooks';
import { controllableBarChartData, NonControllableBarChartData, generateGraphData } from '../util';
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import { PERCENT_TO_SALES_MULTIPLIER } from '../constants';


export default function YearToDateTotalOpex({ params }) {
    const windowSize = useContext(WindowSizeContext)

    const newParams = {
        ...params,
        month: [1, ...(params?.month || [])]
    }

    const { opexTotalsData: opexTotals, isLoadingOpexTotalsData: isLoadingOpexTotals } = useOpexTotals({ params: newParams });
    const { finalData, ytdLossShrinkageValue, isLoading } = useOpexSummaryDataV2({ params: newParams }) // opex account hooks

    const formattedGraphData = generateGraphData(finalData);
    const controllableOption = controllableBarChartData(formattedGraphData, windowSize.isOnMobile)
    const noncontrollableOption = NonControllableBarChartData(formattedGraphData, windowSize.isOnMobile)

    // Function to get dynamic item value
    const getItemValue = (data, operationType, valueKey) => {
        return data?.find(item => item.operation_type === operationType)?.[valueKey];
    };

    // Usage example:
    // Sum up all the budget_sum
    const totalBudgetSum = opexTotals?.filter(item => item.operation_type === 'Total Operating Expense').map(item => item.budget_sum);
    const totalOpexSum = opexTotals?.filter(item => item.operation_type === 'Total Operating Expense').map(item => item.opex_value_sum);
    // Values for Controllable and Non Controllable
    const totalSales = getItemValue(finalData, 'Total Operating Expense', 'actual_sales');
    const totalControllable = getItemValue(finalData, 'Total Controllable Expense', 'opex_current');
    const totalNonControllable = getItemValue(finalData, 'Total Non-Controllable Expense', 'opex_current');

    const controllablePercentage = finalData ? (totalControllable / (totalSales * PERCENT_TO_SALES_MULTIPLIER)) * 100 : 0 // update new formula
    const nonControllablePercentage = finalData ? (totalNonControllable / (totalSales * PERCENT_TO_SALES_MULTIPLIER)) * 100 : 0 // update new formula
    const year = params?.year
    let monthDisplay = params?.month?.[0] && moment().month(0).format('MMM') + '-' + moment().month(params?.month?.[0] - 1).format('MMM')

    if (params?.month?.length > 1) {
        const maxMonth = Math.max(...params?.month) - 1 //minus since to match the actual month since we're using start of index 1 as month value

        monthDisplay = moment().month(0).format('MMM') + '-' + moment().month(maxMonth).format('MMM')
    }

    if (isLoading) {
        return <LoadingComponent />
    } else if (!finalData || finalData === 'undefined') {
        <Box textAlign={'center'}>
            <Typography variant='body1'>
                No data
            </Typography>
        </Box>
    }

    return <>
        <Grid item xs={12} sm  >
            <Item sx={{ borderRadius: `${windowSize.isOnMobile ? '.5rem' : '0 0 .5rem 0'}` }} backgroundColor={'transparent'}>
                <Grid container spacing={.25} paddingX={5} paddingY={.5} mt={0}>
                    <Grid item sm={12} xs={12} display={"flex"}>
                        <Typography variant='h6' style={{ color: '#000' }}> YTD Total OPEX </Typography>
                        <Typography variant='h6' ml={2} > ({monthDisplay} {year}) </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='h5' fontWeight='500' style={{ color: '#000' }} >
                            {
                                finalData === undefined ?
                                    '--' :
                                    <>
                                        <NumericValueDisplay value={totalOpexSum} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} />
                                    </>
                            }
                        </Typography>
                        <Typography >
                            {finalData === undefined ? '--' : <> Budget: <NumericValueDisplay value={totalBudgetSum} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /></>}
                        </Typography>
                    </Grid>

                    <Grid item sm={12} xs={12} mt={1} display={'flex'} columnGap={windowSize.isOnMobile ? 2 : 4} sx={{ justifyContent: 'space-evenly' }}>
                        <Grid item xs={6} mx={1}>
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'end' }} style={{ color: '#000' }}>
                                {finalData === undefined ? '--' : <NumericValueDisplay value={totalControllable} isAbbreviated={true} />}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} mx={1}>
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'start' }} style={{ color: '#000' }}>
                                {finalData === undefined ? '--' : <NumericValueDisplay value={totalNonControllable} isAbbreviated={true} />}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item sm={12} xs={12} my={.5} display={'flex'}>
                        <Grid item xs={6}>
                            <ReactEcharts option={controllableOption} />
                        </Grid>
                        <Grid item xs={6}>
                            <ReactEcharts option={noncontrollableOption} />
                        </Grid>
                    </Grid>

                    <Grid item sm={12} xs={12} mb={1} display={'flex'} columnGap={windowSize.isOnMobile ? 2 : 4} style={{ color: '#000' }}>
                        <Grid item xs={6} >
                            <Typography sx={{ whiteSpace: 'nowrap', fontWeight: 'bold', textAlign: 'end' }}>
                                Controllable{windowSize.isOnMobile ? <br /> : ' '}({controllablePercentage.toFixed(2)}%)
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ whiteSpace: 'nowrap', fontWeight: 'bold', textAlign: 'start' }}>
                                Non-controllable{windowSize.isOnMobile ? <br /> : ' '}({nonControllablePercentage.toFixed(2)}%)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Item>
        </Grid>
    </>
}


