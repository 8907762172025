import React, { useState } from "react";
import Section from "../../../../../common/components/Dashboard/Section";
import {RECENCY_BY_BRANCH, RECENCY_BY_BU, useCustomerRecencyTableData} from "../../hooks";
import RecencyByToggleSelector from "./RecencyByToggleSelector";
import { useSelectorValue } from "../../../common/providers/selectorProvider";
import { Grid } from "@mui/material";
import StoreRecencyTable from "./StoreRecencyTable";


export default function StoreCustomerRecency(){
    const organization = useSelectorValue('organization')
    const branch = useSelectorValue('branch')

    const isMarkets = organization === 'SM Markets'
    const [recencyBy, setRecencyBy] = useState(isMarkets ? RECENCY_BY_BRANCH : RECENCY_BY_BU)
    const {data, isLoading} = useCustomerRecencyTableData({recencyBy: recencyBy})

    const filteredData = React.useMemo(() => {
        if (
            !data || data.length === 0 ||  // if no data
            recencyBy === RECENCY_BY_BU ||  // if recency by BU
            !branch || branch.toString().toLowerCase() === 'all branch'  // if branch is all branch or not selected
        ) {
            return data
        }

        return data.filter(row => row.pk === branch)
    }, [data, recencyBy, branch])

    return <Section
        title={'Customer Recency'}
        selectorsOrFilters={[
            <RecencyByToggleSelector recencyBy={recencyBy} setRecencyBy={setRecencyBy}/>
        ]}>
            <Grid container>
                <Grid item xs={12}>
                    <StoreRecencyTable data={filteredData} isLoading={isLoading} recencyBy={recencyBy}/>
                </Grid>
            </Grid>
    </Section>
}