import React from 'react';
import {Grid, Typography, ToggleButton} from '@mui/material';
import {styled} from "@mui/material/styles";


const NewToggleButton = styled(ToggleButton)(({
    color: '#0a4fb4',
    backgroundColor: '#fff',
    "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: '#0a4fb4',
        color: '#fff'
    }
}))

export default function FilterCompany({ filters }) {
    const isLoadingCompanyData = false;
    const companyData = {
        data: [
            {company_id: 0, company: "SM Store"}
        ]
    }

    const options = (() => {
        if (isLoadingCompanyData) {
            return []
        }
        if (!companyData) {
            return []
        }

        const uniqueCompanyIdList = companyData.data.filter((obj, index) =>
            companyData.data.findIndex((item) => item.company_id === obj.company_id) === index
        );

        return uniqueCompanyIdList.map((row) => {
            return {
                value: row.company_id,
                label: row.company
            }
        })
    })();

    const companyFilters = (value) => {
        if (filters?.company_id.includes(value)) {
            // Deselect company if it's already selected
            return filters?.company_id.filter(id => id !== value);
        } else {
            // Select company if it's not yet selected
            return [...filters?.company_id, value];
        }
    };

    const pillButtons = options.map((option) => {
        // const isSelected = filters?.company_id?.includes(option.value);
        return (
            <Grid item key={option.value} sm={4} xs>
                <NewToggleButton
                    value={option.value}
                    // onClick={() => onPillSelectChange(companyFilters(option.value), 'company_id')}
                    selected={true}
                    sx={{
                        width: '100%',
                        height: '2.5rem',
                        borderRadius: '1.5rem',
                        fontWeight: 'bold',
                    }}
                >
                    {option.label}
                </NewToggleButton>
            </Grid>
        );
    })

    return (
        <>
            <Grid container spacing={1} direction="horizontal" alignItems="baseline">
                <Grid item xs={12} sm={2.75}> <Typography variant="body1">Select Company:</Typography></Grid>
                {pillButtons}
            </Grid>
        </>
    );

}   