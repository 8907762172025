import React, { useMemo } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import SingleStackedBarWithDottedLabel from '../../../../../common/components/graphs/singleStackedBarWithDottedLabel';
import LoadingComponent from '../../../../../common/components/LoadingComponent';

export default function GenderDemographics({ data, filters, storeID, period, onDropdownChange, isOnMobile, isLoading }) {

    // Sorts the data according to the sorting order defined
    const sortedData = useMemo(() => {
        const sortingOrders = ['No Data', 'Male', 'Female'];
        
        return data?.sort((a, b) => {

            const keyA = Object.keys(a)[0];
            const keyB = Object.keys(b)[0];
            
            const indexA = sortingOrders.indexOf(keyA);
            const indexB = sortingOrders.indexOf(keyB);
            
            return  indexA - indexB
        });
    }, [data]);

    return (
        <>
            <Card sx={{ minHeight: '25rem' }}>
                <Grid container xs={12} sm={12} sx={{ backgroundColor: '#CADDEB', padding: 1.5 }} display="flex" alignItems="center">
                    <Grid item sm={12} xs={12}>
                        <Typography variant="h6" ml={3}>Gender</Typography>
                    </Grid>
                </Grid>
                <Grid item sm={12} xs={12} alignItems="center"
                    sx={{
                        flex: 1,
                        height: '95%',
                    }}
                >
                    {isLoading ? (
                        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: isOnMobile ? '25rem' : '30rem' }}>
                            <LoadingComponent />
                        </Grid>
                    ) : sortedData && sortedData.length === 0 ? (
                        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: isOnMobile ? '25rem' : '30rem' }}>
                            No Data Available
                        </Grid>
                    ) : (
                        <SingleStackedBarWithDottedLabel
                            data={sortedData}
                            colors={['#AFCDFC', '#84BFF7', '#4E88DD']}
                            echartStyle={{
                                height: isOnMobile ? '25rem' : '30rem',
                                minWidth: isOnMobile ? '10vw' : 'none',
                            }}
                        />
                    )}
                </Grid>
            </Card>
        </>
    );
}
