import React from 'react';
// import { useSalesOverallPerformanceData } from '../../../../TmpTSSSales/hooks';
import moment from "moment/moment";
import { TopLineContext } from '../../../contexts';
import sales from "../../../../../../img/sales.svg"
import transactions from '../../../../../../img/transactions.svg'
import basket_size from "../../../../../../img/basket_size.svg"
import catchment_rate from "../../../../../../img/catchment_rate.svg"
import conversion_rate from "../../../../../../img/conversion_rate.svg"
import space_productivity_tfa from '../../../../../../img/space_productivity_tfa.svg'
import space_productivity_nsa from "../../../../../../img/space_productivity_nsa.svg"
import smac_sales from '../../../../../../img/smac_sales.svg'
import dept_store_foot_traffic from '../../../../../../img/dept_store_foot_traffic.svg'
import mall_foot_traffic from '../../../../../../img/mall_foot_traffic.svg'
import { useOverallToplineDataL3, useOverallBasketSizeDataL3} from '../../../../TmpTSSSales/DataProvider/hooks';
import { useParamsBasedOnPeriodSelectorContext } from '../../../../TmpTSSSales/hooks';

export default function BranchTopLineDataProvider({ params, children, period, storeID, store }) {
    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';

    const paramsBasedOnPeriod = isQoQ
        ? {
            ...params,
            month: Array.from({ length: 3 }, (_, i) =>
                moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month() + i + 1
            )
        }
        : isMTD ? { ...params } : { ...params, month: [1, ...(params?.month || [])] };

    const salesDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_sales',
            store_parent_store_id: storeID
        }
    });

    const transactionsDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_transactions',
            store_parent_store_id: storeID
        }
    });

    const basketSizeDataHook = useOverallBasketSizeDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_basket_size',
            store_parent_store_id: storeID
        }
    })

    const catchmentRateHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_catchment_rate',
            store_parent_store_id: storeID
        }
    })
    const conversionRateHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_conversion_rate',
            store_parent_store_id: storeID
        }
    })
    const spaceProdNSAHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_space_nsa',
            store_parent_store_id: storeID
        }
    })
    const spaceProdTFAHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_space_tfa',
            store_parent_store_id: storeID
        }
    })
    const smacSalesHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_smac_sales',
            store_parent_store_id: storeID
        }
    })
    const mallFootTraffic = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_mall_foot_traffic',
            store_parent_store_id: storeID
        }
    })
    const smStoreFootTraffic = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_sm_store_foot_traffic',
            store_parent_store_id: storeID
        }
    })

    const planHeader = isQoQ ? 'QTR Plan' : isMTD ? 'MTD Plan' : 'YTD Plan';

    const transformTopLineData = (dataHookResponse, dataHookParams, item) => {
        const {
            // basket_size
            actualBasketSizeTotal,
            targetBasketSize,
            vsSalesPlanBasketSize,
            vsLastYearTotalBasketSize,
            // other metric
            actualTotal,
            targetTotal,
            totalLastYear,
            isLoading,
        } = dataHookResponse;

        let paramsPeriod = moment().format('YYYY-MM-DD');
        if (params?.month?.[0] && params?.year) {
            paramsPeriod = `${params?.year}-${params?.month?.[0].toString().padStart(2, '0')}-01`;
        }

        let periodStartDate = moment(paramsPeriod).startOf('year').format('YYYY-MM-DD');
        let periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
        if (isQoQ) {
            periodStartDate = moment(paramsPeriod, 'YYYY-MM-DD').startOf('quarter').format('YYYY-MM-DD');
            periodEndDate = moment(periodStartDate).endOf('quarter').format('YYYY-MM-DD');
        } else if (isMTD) {
            periodStartDate = moment(paramsPeriod).startOf('month').format('YYYY-MM-DD');
            periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
        }

        return {
            isLoading,
            periodFrom: periodStartDate,
            periodTo: periodEndDate,
            primaryValue: item === 'basket_size' ? actualBasketSizeTotal : actualTotal,
            targetValue: item === 'basket_size' ? targetBasketSize : targetTotal,
            comparisonValues: [
                {
                    label: 'vs Plan',
                    value: item === 'basket_size' ? vsSalesPlanBasketSize : targetTotal,
                },
                {
                    label: 'vs Last Year',
                    value: item === 'basket_size' ? vsLastYearTotalBasketSize : totalLastYear,
                },
            ],

            textMap: {},
            itemKey: item
        }
    }

    const items = [
        {
            ...transformTopLineData(salesDataHook, params, 'actual_sales'),
            textMap: {
                imgIcon: sales,
                primaryLabel: 'Sales',
                targetLabel: planHeader,
            }
        },
        {
            ...transformTopLineData(transactionsDataHook, params, 'actual_transactions'),
            textMap: {
                imgIcon: transactions,
                primaryLabel: 'Transactions',
                targetLabel: planHeader,
            }
        },
        {
            ...transformTopLineData(basketSizeDataHook, params, 'basket_size'),
            textMap: {
                imgIcon: basket_size,
                primaryLabel: 'Basket Size',
                targetLabel: planHeader,
            }
        },
        {
            ...transformTopLineData(smacSalesHook, params, 'actual_smac_sales'),
            textMap: {
                imgIcon: smac_sales,
                primaryLabel: 'SMAC Sales',
                targetLabel: planHeader,
            }
        },
        {
            ...transformTopLineData(mallFootTraffic, params, 'actual_mall_foot_traffic'),
            textMap: {
                imgIcon: mall_foot_traffic,
                primaryLabel: 'Mall Foot Traffic',
                targetLabel: planHeader,
            }
        },
        {
            ...transformTopLineData(catchmentRateHook, params, 'actual_catchment_rate'),
            textMap: {
                imgIcon: catchment_rate,
                primaryLabel: 'Catchment Rate',
                targetLabel: planHeader,
            }
        },
        {
            ...transformTopLineData(smStoreFootTraffic, params, 'actual_sm_store_foot_traffic'),
            textMap: {
                imgIcon: dept_store_foot_traffic,
                primaryLabel: 'Dept Store Foot Traffic',
                targetLabel: planHeader,
            }
        },
        {
            ...transformTopLineData(conversionRateHook, params, 'actual_conversion_rate'),
            textMap: {
                imgIcon: conversion_rate,
                primaryLabel: 'Conversion Rate',
                targetLabel: planHeader,
            }
        },
        {
            ...transformTopLineData(spaceProdNSAHook, params, 'actual_space_nsa'),
            textMap: {
                imgIcon: space_productivity_nsa,
                primaryLabel: 'Space Productivity NSA',
                targetLabel: planHeader,
            }
        },
        {
            ...transformTopLineData(spaceProdTFAHook, params, 'actual_space_tfa'),
            textMap: {
                imgIcon: space_productivity_tfa,
                primaryLabel: 'Space Productivity TFA',
                targetLabel: planHeader,
            }
        },
    ]

    return <TopLineContext.Provider value={{ items: items }}>
        {children}
    </TopLineContext.Provider>
}