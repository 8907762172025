import ReactEcharts from "echarts-for-react";
import { abbreviateNumber } from "../../../../common/utils";
import { useContext } from "react";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { CHART_FONT_SIZE } from "../constants";

export default function VerticalBarGraph({ xAxisLabel, seriesData, barColor }) {
    const minHeightPercentage = 0.8; // Minimum height
    const maxValue = Math.max(...seriesData);
    const minValue = maxValue * (1 - minHeightPercentage); // Calculate the minimum value based on maxValue
    const windowSize = useContext(WindowSizeContext);

    const seriesWithStyles = seriesData?.map((value, index) => {
        const adjustedValue = value < minValue && value !== 0 ? minValue : value; // Ensure the value is at least the minimum value
        return {
            value: adjustedValue,
            itemStyle: {
                color: barColor[index % barColor.length],
            },
            originalValue: value,
            labelOffset: value <= 0 ? [0, -10] : [0, 0],
        };
    }) || [];

    const options = {
        tooltip: {
            show: false,
        },
        animation: false,
        xAxis: {
            type: 'category',
            data: xAxisLabel,
            axisTick: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            axisLabel: {
                interval: 0,
                textStyle: {
                    color: '#000000',
                    fontSize: CHART_FONT_SIZE
                },
            },
        },
        yAxis: {
            type: 'value',
            position: 'right',
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            min: 0,
        },
        series: [
            {
                type: 'bar',
                data: seriesWithStyles.map(item => ({
                    value: item.value,
                    itemStyle: item.itemStyle,
                    originalValue: item.originalValue, // Original value will display
                    label: {
                        show: true,
                        position: 'inside',
                        color: item.value > 1 ? '#FFFFFF' : '#000000',
                        formatter: function (params) {

                            return abbreviateNumber(params.data.originalValue, false, false, false, 1, {
                                maximumFractionDigits: 1,
                            });
                            // return abbreviateNumber(params.data.originalValue);
                        },
                        offset: item.labelOffset,
                        fontSize: CHART_FONT_SIZE
                    },
                })),
                barWidth: "80%",
            },
        ],
        graphic: {
            elements: [
                {
                    type: 'group',
                    left: '75%',
                    top: 'center',
                    children: [
                        {
                            type: 'line',
                            x: 3,
                            shape: {
                                x1: 0,
                                y1: -50,
                                x2: 0,
                                y2: 175,
                            },
                        }
                    ]
                }
            ]
        },
        grid: {
            top: '15%',
            bottom: '12%',
            left: '0%',
            right: '0%',
        },
    };

    return (
        <ReactEcharts notMerge={true} option={options} style={{ height: windowSize.isOnMobile ? 260 : "115%" }} />
    );
}
