/**
 * Default settings for decimal digits.
 * @type {Object}
 * @property {number} fixedDigits - The number of decimal digits for numbers not near zero.
 * @property {number} nearZeroDigits - The number of decimal digits for numbers near zero.
 * @property {boolean} simplifyAllZeroes - If true, '-0.000' and '0.000' will be simplified to '0'.
 */
const defaultDecimalDigitsSettings = {
    fixedDigits: 1,
    nearZeroDigits: 3,
    simplifyAllZeroes: true,
}

/**
 * Converts a decimal number to a string with a specific number of decimal digits.
 * @param {number} value - The decimal number to convert.
 * @param {Object} options - The settings for decimal digits.
 * @param {number} options.fixedDigits - The number of decimal digits for numbers not near zero.
 * @param {number} options.nearZeroDigits - The number of decimal digits for numbers near zero.
 * @param {boolean} options.simplifyAllZeroes - If true, '-0.000' and '0.000' will be simplified to '0'.
 * @returns {string} The decimal number as a string with the specified number of decimal digits.
 */
export const decimalToString = (value, options = defaultDecimalDigitsSettings) => {
    if (Number.isNaN(value) || !isFinite(value)) {
        return ''
    }

    const appliedOptions = {
        ...defaultDecimalDigitsSettings,
        ...options
    }

    const isNearZero = Math.abs(value) < 0.1
    const decimalDigits = isNearZero ? appliedOptions.nearZeroDigits : appliedOptions.fixedDigits;

    const trimmedValue = value.toLocaleString(undefined, {
        maximumFractionDigits: decimalDigits,
        minimumFractionDigits: decimalDigits,
    })

    if (appliedOptions.simplifyAllZeroes && (trimmedValue === '-0.000' || trimmedValue === '0.000')) {
        return '0'
    }
    return trimmedValue
}