import CollapsibleComponent from "../../../../../../../common/components/CollapsibleComponent"
import { Grid, Box, Typography, Collapse } from "@mui/material"
import React, { useState, useContext, useMemo } from "react";
import { WindowSizeContext } from "../../../../../../../common/context/WindowSizeContext";
import LoadingComponent from "../../../../../../../common/components/LoadingComponent";
import TableComponentV2 from "../../../../../../../common/components/TableComponentV2";
import { convertNumberToLocaleString, convertToValidNumber, sortArrayBasedOnGivenArray, sortByOrder } from "../../../../../../../common/utils";
import { checkIfCompletePeriod, getDaysDifference, getPast12monthsL3, groupArrayBy } from "../../../../../TmpTSSSales/util";
import moment from "moment";
import BarTrends from "../../../../../TmpTSSSales/BarTends";
import BarIndicator from "../../../barIndicator";
import { useAvailableDateRangeL3, useStorePerformancePerGroupDataL3 } from "../../../../../TmpTSSSales/DataProvider/hooks";
import NumericValueDisplay from "../../../numericValueDisplay";
import { useDynamicGraphInTableDataL3 } from "../../../../../TmpTSSSales/hooks";
import { getMetricColumnHeader, getBarTrendsColumnHeader } from "../../../../../TmpTSSSales/DataProvider/util";
import MetricFilter from "../../../../../TmpTSSSales/Filters/MetricFilter";
import { useAvailableMaxDateL3 } from "../../../businessUnitView/DataProvider/hooks";


export default function PerformancePerChannel({ params, filters, onDropDownFilterChange, storeID, store, business_unit, zone, selectedRows, onSelectRow,
    period, onTogglePeriodChange }) {
    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';
    const [isExpand, setExpand] = useState(true);
    const metricHeaderLabel = getMetricColumnHeader(period, filters?.metric_column);
    const barTrendsColumnHeaders = getBarTrendsColumnHeader(filters?.metric_column);
    const windowSize = useContext(WindowSizeContext);
    const selectedMetric = filters?.metric_column;
    const orderArray = ['In Store', 'Personal Shopper', 'Online'];
    // const absoluteHeaderLabel = "Channel"

    const selectedViewByToL3ColName = {
        channel: "cm_channel_desc",
        business_unit: "pm_business_unit_desc_standardized",
        zone: "store_zone",
    };

    const groupByL3 = selectedViewByToL3ColName[filters?.group]

    const paramsBasedOnPeriod = isQoQ
        ? {
            ...params,
            month: Array.from({ length: 3 }, (_, i) =>
                moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month() + i + 1
            )
        }
        : isMTD ? { ...params } : { ...params, month: [1, ...(params?.month || [])] };

    const { monthYear, ...dateParamsForTrendBar } = useMemo(() => {
        return getPast12monthsL3(params?.month?.[0], params?.year)
    }, [params?.month, params?.year])

    const commonParamsPerGrp = {
        ...paramsBasedOnPeriod,
        group_by: "cm_channel_desc",
        columns: "cm_channel_desc",
        metric_column: filters?.metric_column ? filters.metric_column : 'actual_sales'
    }

    const paramsForPerGroup = (() => {
        if (storeID) {
            return {
                ...commonParamsPerGrp,
                group_by: `${commonParamsPerGrp?.group_by},store_parent_store`,
                columns: `${commonParamsPerGrp?.group_by},store_parent_store`,
                store_parent_store_id: `${storeID}`,
            };
        } else if (zone) {
            return {
                ...commonParamsPerGrp,
                group_by: `${commonParamsPerGrp?.group_by},store_zone`,
                columns: `${commonParamsPerGrp?.group_by},store_zone`,
                store_zone: `${zone}`,
            };
        } else {
            return {
                ...commonParamsPerGrp,
                group_by: `${commonParamsPerGrp?.group_by},pm_business_unit_desc_standardized`,
                columns: `${commonParamsPerGrp?.group_by},pm_business_unit_desc_standardized`,
                pm_business_unit_desc_standardized: `${business_unit}`,
            };
        }
    })();

    const { data: graphData, isLoading: isGraphDataLoading } = useDynamicGraphInTableDataL3({
        params: {
            ...paramsForPerGroup,
            ...dateParamsForTrendBar,
            group_by: groupByL3,
            columns: groupByL3,
        },
        queryOptions: {
            hasTranDateLteGte: dateParamsForTrendBar,
        }
    });

    const { data: grGraphData, isLoading: isGRGraphDataLoading } = useDynamicGraphInTableDataL3({
        params: {
            ...paramsForPerGroup,
            ...dateParamsForTrendBar,
            group_by: null,
            columns: null,
            aggregates: ["pos_sku_gross_sales_sum", "header_tran_key_count_distinct", "pos_sku_sale_tot_qty_sum", "pos_sku_smac_sales_sum", "egr_sales_plan_sum"],
            header_gr_tag__is_true: 1,
        },
        queryOptions: {
            hasTranDateLteGte: dateParamsForTrendBar,
        }
    });

    const updatedGraphData = useMemo(() => {
        if (groupByL3 === 'cm_channel_desc' && graphData && grGraphData[undefined]) {
            return {
                ...graphData,
                'Gift Registry': grGraphData[undefined],
            };
        } else {
            return graphData || [];
        }
    }, [graphData, grGraphData, groupByL3]);

    const displayKey = groupByL3 // displayMapping[filters?.group] || filters?.group

    const { perGroupData, isLoading: isLoadingPerGroup } = useStorePerformancePerGroupDataL3({
        params: paramsForPerGroup
    });
    
    const alteredGroupBy = (() => {
        if (storeID) {
            return `${groupByL3},store_parent_store`;
        } else if (zone) {
            return `${groupByL3},store_zone`;
        } else {
            return `${groupByL3},pm_business_unit_desc_standardized`;
        }
    })();

    perGroupData?.forEach(item => {
        Object.keys(item).forEach(key => {
            if (key === alteredGroupBy) {
                item[groupByL3] = item[key];
                delete item[key];
            }
        });
    });

    const newPerformancePerGroupData = React.useMemo(() => {
        const groupedData = groupArrayBy(perGroupData, displayKey)
        const sumKeys = ['metricTotal', 'metricPlanTotal', 'contribution', 'metricTotal', 'vsLY', 'vsPlan']
        return Object.keys(groupedData).map((key) => {
            if (groupedData[key].length > 1) {
                return {
                    ...groupedData[key][0],

                    [displayKey]: key,
                    ...groupedData[key].reduce((acc, val) => {
                        sumKeys.forEach((key) => {
                            acc[key] = (acc[key] || 0) + (parseFloat(val[key]) || 0)
                        })
                        return acc
                    }, {}),
                    subRows: groupedData[key].map((row) => {
                        return {
                            ...row,
                            [displayKey]: row[filters?.group],
                        }
                    })
                }
            } else {
                return groupedData[key][0]
            }
        })
    }, [perGroupData, filters?.group, displayKey])

    const combinedData = useMemo(() => {
        return {
            ...updatedGraphData,
            ...(groupByL3 === 'business_unit' ? graphData : {})
        }
    }, [updatedGraphData, graphData, groupByL3])

    const grMaxBarTrendValues = useMemo(() => {
        const allValues = grGraphData && grGraphData[undefined] 
            ? Object.values(grGraphData[undefined]).reduce((acc, val) => { return acc.concat(val) }, []).filter(data => monthYear?.includes(data?.year_month))  // filter only the selected month and year
            : []

        if(allValues.length === 0) {
            return null;
        }

        return allValues.reduce((acc, row) => {
            // this is hack. we are forcefully getting math max for all keys of each value
            // and then getting the max of all those values
            Object.keys(row).forEach((key) => {
                const val = parseFloat(row[key])
                if (!isNaN(val)) {
                    if (acc[key]) {
                        acc[key] = Math.max(acc[key], val)
                    } else {
                        acc[key] = val
                    }
                }
            })
            return acc
        }, {})
    }, [grGraphData, monthYear])

    const maxBarTrendValues = useMemo(() => {
        const allValues = Object.values(combinedData)
            .reduce((acc, val) => {
                return acc.concat(val)
            }, [])
            .filter(data => monthYear?.includes(data?.year_month))  // filter only the selected month and year

        return allValues.reduce((acc, row) => {
            // this is hack. we are forcefully getting math max for all keys of each value
            // and then getting the max of all those values
            Object.keys(row).forEach((key) => {
                const val = parseFloat(row[key])
                if (!isNaN(val)) {
                    if (acc[key]) {
                        acc[key] = Math.max(acc[key], val)
                    } else {
                        acc[key] = val
                    }
                }
            })
            return acc
        }, {})
    }, [combinedData, monthYear])

    const isGiftRegistry = filters?.group === 'channel' || groupByL3 === 'cm_channel_desc';
    const CHANNEL_COLS = ['Gift Registry']
    const { data: dateRange, isLoading: isLoadingDateRange } = useAvailableDateRangeL3()

    let paramsPeriod = moment().format('YYYY-MM-DD');
    if (params?.month?.[0] && params?.year) {
        paramsPeriod = `${params?.year}-${params?.month?.[0].toString().padStart(2, '0')}-01`;
    }

    let periodStartDate = moment(paramsPeriod).startOf('year').format('YYYY-MM-DD');
    let periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
    if (isQoQ) {
        periodStartDate = moment(paramsPeriod, 'YYYY-MM-DD').startOf('quarter').format('YYYY-MM-DD');
        periodEndDate = moment(periodStartDate).endOf('quarter').format('YYYY-MM-DD');
    } else if (isMTD) {
        periodStartDate = moment(paramsPeriod).startOf('month').format('YYYY-MM-DD');
        periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
    }

    const maxAvailableDate = !isLoadingDateRange && moment(dateRange?.max).format('YYYY-MM-DD');
    const isMonthNotEqualToMax = params?.month?.[0] !== moment(maxAvailableDate).month() + 1; //if selected params is not equal to max date.
    const maxDaysInPeriod = isMonthNotEqualToMax ? periodEndDate : maxAvailableDate;
    const numberOfDaysWithinThePeriod = getDaysDifference(periodStartDate, periodEndDate);
    const maxAvailableDaystheSelectedMonth = getDaysDifference(periodStartDate, maxDaysInPeriod)
    const getRunningDailyTarget = (target) => {
        return (target / numberOfDaysWithinThePeriod) * maxAvailableDaystheSelectedMonth
    };
    const metric_col_arr = ['actual_basket_size', 'actual_catchment_rate', 'actual_conversion_rate', 'actual_space_tfa', 'actual_space_nsa']

    const checkIfHasBarColor = (viewBy, group, metric) => {
        const isChannelTab = viewBy === "cm_channel_desc"
        const isInStoreRow = isChannelTab && group === "In Store"
        const isChannelTabNoPlan = !isInStoreRow && ['actual_transactions', 'actual_basket_size', 'actual_smac_sales', 'actual_space_tfa'].includes(metric)
        
        if(isChannelTab && !isInStoreRow && isChannelTabNoPlan) {
            return false;
        }

        const isBUTab = viewBy === 'pm_business_unit_desc_standardized'
        const buTabNoPlanMetrics = ['actual_transactions', 'actual_basket_size', 'actual_smac_sales', 'actual_units_sold', 'actual_units_sold_per_txn']
        const isBUTabNoPlan = isBUTab && buTabNoPlanMetrics.includes(metric)

        if(isBUTabNoPlan) {
            return false;
        }

        const isZoneTab = viewBy === 'store_zone'
        const zoneTabNoPlanMetrics = ['actual_catchment_rate', 'actual_conversion_rate', 'actual_mall_foot_traffic', 'actual_sm_store_foot_traffic']
        const isZoneTabNoPlan = isZoneTab && zoneTabNoPlanMetrics.includes(metric)

        if(isZoneTabNoPlan) {
            return false;
        }

        return true; // has bar color
    }
    
    const { data: maxDate } = useAvailableMaxDateL3();
    const isCompletePeriod = checkIfCompletePeriod(params, maxDate, isQoQ)
    const hasLessColumns = !isCompletePeriod && (isMTD || isQoQ);
    const performance_per_group_columns = useMemo(
        () => [
            {
                id: 'channel',
                header: 'Channel',
                accessorKey: displayKey,
                cell: ({ row, getValue }) => {
                    return <Box sx={{ marginLeft: `${row.depth * 1}rem`, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>

                        {getValue()}

                    </Box>
                },
                sortingFn: (rowA, rowB) => {
                    if (orderArray) {
                        return sortByOrder(rowA.original, rowB.original, orderArray, groupByL3);
                    } else {
                        return rowA?.getValue() < rowB?.getValue() ? 1 : -1
                    }
                },
                customStyle: { width: '15rem' },
            },
            {
                id: 'metric_header_label',
                header: metricHeaderLabel,
                accessorKey: 'metricTotal',
                cell: (row) => {
                    const hasNoPrefixPostfix = ['actual_transactions', 'actual_mall_foot_traffic', 'actual_sm_store_foot_traffic', 'actual_units_sold', 'actual_units_sold_per_txn']
                    if (hasNoPrefixPostfix?.includes(filters?.metric_column)) {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} isAbbreviated={true} />
                            </Box>
                        );
                    } else if (filters?.metric_column == 'actual_catchment_rate' || filters?.metric_column == 'actual_conversion_rate') {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} postfix={'%'} isAbbreviated={true} />
                            </Box>
                        );
                    } else {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={true} />
                            </Box>
                        );
                    }
                },
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                id: 'percent_contribution',
                header: '% cont.',
                accessorKey: 'contribution',
                cell: (row) => {
                    const isMetricColumn = metric_col_arr.includes(filters?.metric_column)
                    const value = isMetricColumn ? undefined : row?.getValue()
                    const postFix = isMetricColumn ? '' : '%'
                    return <NumericValueDisplay value={value} postfix={postFix} />
                },
                customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                id: 'mtd_ytd_plan',
                header: isQoQ ? 'QTR Plan' : isMTD ? 'MTD Plan' : 'YTD Plan',
                accessorKey: 'metricPlanTotal',
                cell: (row) => {
                    if (filters?.metric_column === 'actual_transactions') {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} isAbbreviated={true} />
                            </Box>
                        );
                    } else if (filters?.metric_column === 'actual_catchment_rate' || filters?.metric_column === 'actual_conversion_rate') {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} postfix={'%'} isAbbreviated={true} />
                            </Box>
                        );
                    } else {
                        return (
                            <Box sx={{ width: { sm: hasLessColumns ? '20%' : '30%' }, marginX: 'auto' }}>
                                <NumericValueDisplay value={row?.getValue()} prefix={'₱'} isAbbreviated={true} />
                            </Box>
                        );
                    }
                },
                customStyle: { width: '15rem', textAlign: 'left', verticalAlign: 'middle' }
            },
            {
                id: 'vs_plan',
                header: 'vs Plan',
                accessorKey: 'vsPlan',
                cell: (row) => {
                    const value = row?.getValue();
                    if (value === 'N/A') {
                        return (
                            <Box sx={{ width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <BarIndicator value={'N/A'} isValueColorCoded={true} isOnTable={true} label="vs plan" />
                            </Box>
                        );
                    } else if (!isCompletePeriod) {
                        return <>{convertNumberToLocaleString(row?.getValue()) !== 0 ?
                            <Box sx={{ width: '4rem', height: '1.5rem', marginX: 'auto' }}>
                                <BarIndicator componentType={'progress_bar'} value={convertToValidNumber(row?.getValue()).toFixed()} />
                            </Box> : '-'
                        }</>
                    } else {
                        return <>{convertNumberToLocaleString(row?.getValue()) !== 0 ?
                            <Box sx={{ width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <BarIndicator value={convertToValidNumber(row?.getValue()).toFixed()} isValueColorCoded={true} isOnTable={true} label="vs plan" />
                            </Box> : '-'
                        }</>
                    }
                },
                customStyle: { width: '7rem', height: '2rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                id: 'vs_last_year',
                header: 'vs Last Year',
                accessorKey: 'vsLY',
                cell: (row) => <>
                    {convertNumberToLocaleString(row?.getValue()) !== 0 ?
                        <Box sx={{ width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <BarIndicator value={convertToValidNumber(row?.getValue()) !== 'N/A' ? convertToValidNumber(row?.getValue()).toFixed() : 'N/A'} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                        </Box> : '-'
                    }
                </>,
                customStyle: { width: '7rem', height: '2rem', textAlign: 'center', verticalAlign: 'middle' }
            },
            {
                header: barTrendsColumnHeaders,
                id: '12th_mth_sales_trend',
                accessorKey: 'vsPlan',
                cell: (row) => {
                    const group = groupByL3
                    const groupValue = row?.row?.original?.[group];
                    const combinedData = { ...updatedGraphData }
                    const barTrendData = combinedData[groupValue];

                    const newGraphData = barTrendData?.filter(data => monthYear?.includes(data?.year_month))
                    const isGiftRegistry = groupValue === "Gift Registry"                    
                    const maxValues = isGiftRegistry && grMaxBarTrendValues ? grMaxBarTrendValues : maxBarTrendValues
                    const hasNoBarColor = !checkIfHasBarColor(group, groupValue, selectedMetric)

                    return <>
                        {

                            isGraphDataLoading ? <LoadingComponent /> : <Box sx={{ width: '13rem', height: '2.5rem', marginX: 'auto' }}>
                                <BarTrends data={newGraphData} maxValues={maxValues} vsPlan={row?.getValue() ? convertNumberToLocaleString(row?.getValue()) : undefined} metric={filters?.metric_column} hasNoBarColor={hasNoBarColor} />

                            </Box>
                        }
                    </>
                }
            }
        ],
        [filters?.group, selectedRows, isGiftRegistry, period, getRunningDailyTarget, isMTD, isGraphDataLoading, updatedGraphData]
    )

    const TopSalesTableData = isGiftRegistry && newPerformancePerGroupData?.filter(val => CHANNEL_COLS.includes(val['cm_channel_desc'])) // 888 and 4k data
    const filteredData = isGiftRegistry ? newPerformancePerGroupData?.filter(val => !CHANNEL_COLS.includes(val['cm_channel_desc'])) : newPerformancePerGroupData // remove 888 and 4k when on product group
    const noNullRowData = filteredData?.filter(val => val[filters?.group] !== 'null' && val[filters?.group] !== null)
    const newOrderArray = isGiftRegistry ? ['Gift Registry', ...orderArray] : orderArray

    if (isLoadingPerGroup || isGRGraphDataLoading) {
        return <LoadingComponent />
    }

    const columnVisibility = {
        // "vs_last_year": isCompletePeriod,
        "12th_mth_sales_trend": !isMTD && !isQoQ
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container alignItems="center" py={.5}>
                    {
                        windowSize.isOnMobile ? <>
                            <Grid item xs={12} display="flex" justifyContent={'space-between'} alignItems={'center'} mx={.5} >
                                <Typography variant="h6" ml={2.5}>
                                    Performance Per Channel
                                </Typography>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                            <Grid item xs={12} sm={2} mx={2}>
                                <MetricFilter
                                    isMTD={isMTD}
                                    filters={{ ...filters, group: !store && 'sbu_bu_group' }} // Helps determine whether to filter for ops or merch tab.
                                    onFilterChange={onDropDownFilterChange}
                                    params={params}
                                    store={store}
                                    zone={zone}
                                />
                            </Grid>
                        </> : <>
                            <Grid item xs={9} >
                                <Typography variant="h6" ml={2.5}>
                                    Performance Per Channel
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <MetricFilter
                                    isMTD={isMTD}
                                    filters={{ ...filters, group: !store && 'sbu_bu_group' }} // Helps determine whether to filter for ops or merch tab.
                                    onFilterChange={onDropDownFilterChange}
                                    params={params}
                                    store={store}
                                    zone={zone}
                                />
                            </Grid>
                            <Grid item xs justifyContent={'end'}>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Collapse in={isExpand}>
                        <TableComponentV2
                            columns={performance_per_group_columns}
                            data={sortArrayBasedOnGivenArray(noNullRowData, newOrderArray, groupByL3)}
                            tableId={'performancePerChannelTable'}
                            isCompact={true}
                            customTableHeaderStyle={{
                                backgroundColor: '#caddeb'
                            }}
                            initialState={{ columnVisibility }}
                        />
                    </Collapse>
                    {
                        isGiftRegistry &&
                        <Grid item mt={1}>
                            <Collapse in={isExpand}>
                                <TableComponentV2
                                    columns={performance_per_group_columns}
                                    data={sortArrayBasedOnGivenArray(TopSalesTableData, newOrderArray, groupByL3)}
                                    tableId={'performancePerChannelTopTable'}
                                    isCompact={true}
                                    customTableHeaderStyle={{
                                        backgroundColor: '#caddeb'
                                    }}
                                    customTableContainerStyle={{
                                        borderRadius: "0",
                                        boxShadow: 'none',
                                    }}
                                    initialState={{ columnVisibility }}
                                />
                            </Collapse>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
}   