import React, { useContext, useState, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import { Grid } from '@mui/material';

// Hooks
import {
    useCityAndProvince,
    useCityPopulationV1,
    useEncompassingMalls,
    useIsochrones,
    useLocations,
    useMallCoordinates,
    useCrossFilteredLocations,
    allRegions
} from './hooks';

// Misc
import LoadingComponent from '../../../../../../common/components/LoadingComponent';
import MapSideContainer from './MapSideContainer';
import { WindowSizeContext } from '../../../../../../common/context/WindowSizeContext';
import MapComponent from './MapComponent';



export const SMACMembersTooltipContent = "Count of unique members with transactions from the branch for the past 6 months as of March 31, 2024."
export const PopulationTooltipContent = "Color gradient is based on barangay % penetration."


export default function LocationsAnalysis({ store }) {
    const windowSize = useContext(WindowSizeContext);

    /*
     OVERVIEW
      We can only use `store` (mall name) to get the mall's information from tables (isochrones, coordinates, customer locations).
      
    */

    // Pre-reading data
    // NOTE: if SM Tanza is used, add CITY in between to make it SM CITY TANZA.
    // SM CITY TANZA is the name used in `smip_tss_isochrones`
    const targetMallName = store.toUpperCase()==='SM TANZA' ? 
        store.split(" ").join(" " + 'CITY' + " ").toUpperCase() : 
        store.toUpperCase();

    const mallNames = useEncompassingMalls(targetMallName);

    const {
        intersecting_location: location,
        mall_coordinates: targetMallCoordinates,
    } = useCityAndProvince(targetMallName);
    
    const cityName = location?.city_name;
    const provinceName = location?.province_name;
    const regionName = location?.region_name;

    const defaultIsochrone = regionName ? (allRegions.includes(regionName) ? [600] : [300]) : [600];

    const bingKey = 'AsfRERygL5Q0v9RVscFhuCeAIfxw4sIufP1FwBBUl-irqmsvp4o2J-o2aEqg0-Qd';

    // Selected Barangay from SMACPenetrationAccordion
    const [selectedBarangay, setSelectedBarangay] = useState(null);

    // Toggles and Autocompletes
    const [showSMACMembers, setShowSMACMembers] = useState(true);
    const [showCityPopulation, setShowCityPopulation] = useState(true);
    const [checkedSMACTypes, setCheckedSMACTypes] = useState([]);
    const [checkedHomebranches, setCheckedHomebranches] = useState([]);
    const [checkedIsochrones, setCheckedIsochrones] = useState([]);
    
    useEffect(() => {
        setCheckedIsochrones(defaultIsochrone);
    }, [regionName]);

    let select_malls = checkedHomebranches;
    if (checkedIsochrones?.length > 0) {
        select_malls = mallNames;
    } else {
        select_malls = checkedHomebranches;
    }

    // Custom hooks
    const { 
        data: smacMembers, 
        isLoading: smacMembersIsLoading 
    } = useLocations({ cityName: cityName, provinceName: provinceName });

    const { 
        data: crossFilteredData, 
        isLoading: crossFilteredIsLoading,
        barangaysIsLoading: barangaysIsLoading,
    } = useCrossFilteredLocations({ 
            mall_names: checkedHomebranches,
            card_types: ['smac', 'prestige', 'start'], // temporary; checkedSMACTypes
            store: targetMallName,
        });

    const { 
        data: cityPopulation, 
        filtered_table: smacPenetrationTable, 
        isLoading: cityPopDataIsLoading
    } = useCityPopulationV1({ city_name: cityName, province_name: provinceName, store: targetMallName, regionName: regionName, defaultIsochrone: checkedIsochrones });

    const { 
        data: isochroneTimes, 
        isLoading: isochroneIsLoading 
    } = useIsochrones({ 
            mall_names: mallNames, 
            times: checkedIsochrones 
        });
    
    const { 
        data: mallCoords
    } = useMallCoordinates(select_malls, cityName);

    const { 
        data: targetMallCoords
    } = useMallCoordinates([targetMallName]);

    // Handlers
    const toggleShowSMACMembers = () => {
        setShowSMACMembers(!showSMACMembers);
    };
    const toggleShowCityPopulation = () => {
        setShowCityPopulation(!showCityPopulation);
    };
    const handleSMACTypes = (event, values) => {
        const lowercasedValues = values?.length > 0 ? values.map((i) => i.toLowerCase()) : [];

        setCheckedSMACTypes(lowercasedValues);
        setShowSMACMembers(false);
    };
    const handleHomebranches = (event, values) => {
        const uppercasedValues = values?.length > 0 ? values.map((i) => i.toUpperCase()) : [];
        
        setCheckedHomebranches(uppercasedValues);
        setShowSMACMembers(false);
    };
    const handleisochrones = (times) => {
        let result = times.map(a => a.value);
        setCheckedIsochrones(result);
        return result
    };
    
    if (mallNames === undefined || location === undefined || isochroneIsLoading) {
        return (
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12}>
                    <LoadingComponent />
              </Grid>
          </Grid>
        );
    }

    return (
        <Grid
            container
            spacing={2}
            direction="row"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
                backgroundColor: '#e0e0e0',
                justifyContent: 'flex-start',
                overflowX: 'auto',
                overflowY: 'hidden',
                alignItems: 'flex-start',
                paddingTop: 1.5,
            }}
        >

            <Grid
                item
                xs={windowSize.isOnMobile ? 12 : 8}
                id="interactive_map"
                sx={{ zIndex: 0 }}
            >
                <MapComponent
                    windowSize={windowSize}

                    targetMallCoordinates={targetMallCoordinates}
                    targetMallCoords={targetMallCoords}
                    bingKey={bingKey}
                    cityPopulation={cityPopulation}
                    isochroneTimes={isochroneTimes}
                    crossFilteredData={crossFilteredData}
                    smacMembers={smacMembers}
                    mallCoords={mallCoords}
                    mallNames={mallNames}

                    showSMACMembers={showSMACMembers}
                    showCityPopulation={showCityPopulation}
                    checkedIsochrones={checkedIsochrones}
                    checkedHomebranches={checkedHomebranches}
                    checkedSMACTypes={checkedSMACTypes}

                    crossFilteredIsLoading={crossFilteredIsLoading}

                    selectedBarangay={selectedBarangay}
                    setSelectedBarangay={setSelectedBarangay}
                    cityName={cityName}

                    store={targetMallName}
                />
            </Grid>
            <Grid item 
                xs={windowSize.isOnMobile ? 12 : 4} 
                id="side_container" 
                sx={{ zIndex: 0 }}
            >
                <MapSideContainer
                    windowSize={windowSize}
                    mallNames={mallNames}
                    smac_members={smacMembers}
                    cross_filtered_data={crossFilteredData}
                    smacPenetrationTable={smacPenetrationTable}

                    showSMACMembers={showSMACMembers}
                    showCityPopulation={showCityPopulation}
                    smacMembersIsLoading={smacMembersIsLoading}
                    cityPopDataIsLoading={cityPopDataIsLoading}
                    crossFilteredIsLoading={crossFilteredIsLoading}
                    isochroneIsLoading={isochroneIsLoading}

                    toggleShowSMACMembers={toggleShowSMACMembers}
                    toggleShowCityPopulation={toggleShowCityPopulation}
                    handleSMACTypes={handleSMACTypes}
                    handleHomebranches={handleHomebranches}
                    handleisochrones={handleisochrones}

                    setSelectedBarangay={setSelectedBarangay}
                    barangaysIsLoading={barangaysIsLoading}

                    setCheckedIsochrones={setCheckedIsochrones}
                    checkedIsochrones={checkedIsochrones}
                />
            </Grid>
        </Grid>
    );
}
