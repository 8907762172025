import { Box, Grid, Typography } from "@mui/material";
import groupIcon from "../../../../../img/groupIcon.svg"
import BarIndicator from "../../../common/presentation/barIndicator";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { useContext } from "react";
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext";

export default function SMACMemberCard({ data }) {
    const windowSize = useContext(WindowSizeContext);

    return (
        <Box sx={{ backgroundColor: "#E1EBF2", borderRadius: 1, paddingY: windowSize?.isOnMobile ? 2 : 4, height: '100%', alignContent: 'center' }}>
            <Grid item xs={12} sm={12} gap={.5} sx={{ paddingX: 2.5, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {/* Icon */}
                <img src={groupIcon} height="30rem" width="auto" />
                {/* Header */}
                <Typography variant={data?.style ? data?.style?.variant : "h6"} sx={{ fontWeight: "bold", color: "#000000" }} >{data?.header}</Typography>
            </Grid>
            <Grid item xs={12} my={1}>
                {/* OverAll Value */}
                <Typography variant="h4" sx={{ textAlign: "center", fontWeight: "bold", color: "#000000" }}>{data?.overallvalue}</Typography>
            </Grid>

            {windowSize.isOnMobile ?
                <>
                    <Grid item xs={12} sx={{ display: "flex", pt: 1, justifyContent: "space-between" }}>
                        <Grid item xs={6} textAlign="center" gap={1} sx={{ display: "flex" }}>
                            <Box sx={{ width: '5rem', height: '1.8rem' }}>
                                <BarIndicator value={data?.vsplan} isValueColorCoded />
                            </Box>
                            <Typography>vs plan</Typography>
                        </Grid>

                        <Grid item xs={6} textAlign="center" gap={1} sx={{ display: "flex" }}>
                            <Box sx={{ width: '5rem', height: '1.8rem' }}>
                                <BarIndicator value={data?.vslastyear} isValueColorCoded />
                            </Box>
                            <Typography>vs last year</Typography>
                        </Grid>
                    </Grid>

                </>
                : <>
                    <Grid item xs={12} sm={12} sx={{ display: "flex", py: 1.5, px: 3, justifyContent: "space-between" }}>
                        <Grid item textAlign="center" gap={1} sx={{ display: "flex" }}>
                            <Box sx={{ width: '5rem', height: '1.8rem' }}>
                                <BarIndicator value={data?.vsplan} isValueColorCoded />
                            </Box>
                            <Typography>vs plan</Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ display: "flex", px: 3, justifyContent: "space-between" }}>
                        <Grid item textAlign="center" gap={1} sx={{ display: "flex" }}>
                            <Box sx={{ width: '5rem', height: '1.8rem' }}>
                                <BarIndicator value={data?.vslastyear} isValueColorCoded />
                            </Box>
                            <Typography>vs last year</Typography>
                        </Grid>
                    </Grid>
                </>
            }

        </Box>
    )
}