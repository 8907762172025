import React from 'react';
import * as PropTypes from 'prop-types';

export default function ChatBox({onNewMessage, disabled}) {
    const [message, setMessage] = React.useState('');
    return <textarea
        disabled={disabled}
        className="form-control fs-4 mb-4"
        style={{
            resize: 'none',
        }}
        placeholder={"Type your message here..."}
        onChange={(e) => {
            setMessage(e.target.value);
            console.log("Setting message")
        }}
        onKeyDown={(e) => {
            if (e.key === 'Enter') {

                e.preventDefault()
                onNewMessage(message);
                setMessage('');
            }
        }}
        value={message}
    ></textarea>
}

ChatBox.propTypes = {
    onNewMessage: PropTypes.func.isRequired,
}