import React, { useContext, useMemo } from "react";
import { Box, Grid, IconButton, Typography } from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import Item from "../../../../common/components/ItemCard";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import DropdownCheckboxV3 from "../../../../common/components/DropdownCheckboxV3";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import ToggleMtdYtd from "../../Sales/toggleMtdYtd";
import { useOpexPerStore } from "../hooks";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import { CATEGORY } from "../../Sales/constants";
import BarIndicator from "../../common/presentation/barIndicator";
import NumericValueDisplay from '../../common/presentation/numericValueDisplay';
import {
    CONTROLLABLE_OPERATION_TYPES,
    NON_CONTROLLABLE_OPERATION_TYPES,
    OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
    OPERATION_TYPE_OTHER_CONTROLLABLE,
    OPERATION_TYPE_OTHER_NON_CONTROLLABLE,
    OPERATION_TYPE_TOTAL_CONTROLLABLE,
    OPERATION_TYPE_TOTAL_NON_CONTROLLABLE,
    OPERATION_TYPE_TOTAL_OPERATING_EXPENSES
} from "../constants";
import { OperationTypeFilterContext } from "../contexts";
import OperationTypeFilterDisplay from "../components/operationTypeFilterDisplay";
import BaseNumericDisplay from "../../common/presentation/numericDisplays/baseNumericDisplay";
import { decimalToString } from "../../../../common/utils/numbers";

const IndicatorCellBox = (props) => (
    <Box sx={{ my: 1, width: '3rem', height: '1.5rem', marginX: 'auto', display: 'flex', justifyContent: 'center' }} >
        {props.children}
    </Box>
);

const getData = (data, category) => {
    return Object.values(Object.keys(data).map(key => ({
        [category]: data[key].branch_id,
        store_name: data[key].store,
        actual_sales: data[key].actual_sales,
        region: data[key].region,
        store_size: data[key].store_size,
        store_type: data[key].store_type,
        store_subtype: data[key].store_subtype,
        operation_types: [data[key].opex_values]
    })))
}


export default function OpexStoresPerformance({ params, filters, onDropDownFilterChange, period, onToggleChange, selectedRows }) {
    const { selected: selectedOperationTypes } = React.useContext(OperationTypeFilterContext)
    const [selectedStoreIds, setSelectedStoreIds] = React.useState([]);
    const isMTD = period === 'mtd';
    const isYTD = period === 'ytd';

    const eyeFilterGroup = filters?.group === 'storeSize' ? 'store_size' : (
        filters?.group === 'storeType' ? 'store_type' : (
            filters?.group === 'mall_category' ? 'store_subtype' : 'region'
        )
    )

    const newParams = {
        ...params,
        group_by: filters?.group,
    }
    const paramsBasedOnPeriod = isMTD ? newParams : { ...newParams, month: [1, ...(newParams?.month || [])] }

    const {
        mergedData,
        isLoadingOpexPerStore
    } = useOpexPerStore({
        params: {
            ...paramsBasedOnPeriod,
            limit: 1000
        }
    });

    const storeData = getData(mergedData, 'branch_id')

    const formatData = (data) => {
        const formattedData = data?.map((branch) => {
            const opexData = !!branch?.operation_types[0] ? branch.operation_types[0] : {}
            const totalOpexRow = opexData[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES] ? opexData[OPERATION_TYPE_TOTAL_OPERATING_EXPENSES] : {}

            const controllableSubRows = CONTROLLABLE_OPERATION_TYPES
                .filter(key => !selectedOperationTypes || selectedOperationTypes.length === 0 || selectedOperationTypes.includes(key))
                .map((key) => {
                    return {
                        branch: key === OPERATION_TYPE_OTHER_CONTROLLABLE ? 'Other Controllable' : key,
                        income: null, // So that '-' appears in UI for this subRow
                        current: opexData[key]?.opex_value_sum || 0,
                        ly: opexData[key]?.last_year_opex_value_sum || 0,
                        vsLy: opexData[key]?.vs_last_year || 0,
                        budget: opexData[key]?.budget_sum || 0,
                        vsBudget: opexData[key]?.vs_budget || 0,
                        opexPerSQM: 0, //opexData[key]?.opex_per_sqm
                        sales: 0, //branch?.actual_sales 
                        percentToSales: opexData[key]?.percent_to_sales || 0,
                    }
                })

            const controllableRow = {
                branch: 'Controllable',
                income: null, // So that '-' appears in UI for this subRow
                current: opexData[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.opex_value_sum || 0,
                ly: opexData[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.last_year_opex_value_sum || 0,
                vsLy: opexData[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.vs_last_year || 0,
                budget: opexData[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.budget_sum || 0,
                vsBudget: opexData[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.vs_budget || 0,
                opexPerSQM: opexData[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.opex_per_sqm || 0,
                sales: 0, //branch?.actual_sales 
                percentToSales: opexData[OPERATION_TYPE_TOTAL_CONTROLLABLE]?.percent_to_sales || 0,
                subRows: controllableSubRows // had to elaborate since it kept getting nested in a single array
            };

            const nonControllableSubRows = NON_CONTROLLABLE_OPERATION_TYPES
                .filter(key => !selectedOperationTypes || selectedOperationTypes.length === 0 || selectedOperationTypes.includes(key))
                .map((key) => {
                    return {
                        branch: key === OPERATION_TYPE_OTHER_NON_CONTROLLABLE ? 'Other Non-Controllable' : key,
                        income: null, // So that '-' appears in UI for this subRow
                        current: opexData[key]?.opex_value_sum || 0,
                        ly: opexData[key]?.last_year_opex_value_sum || 0,
                        vsLy: opexData[key]?.vs_last_year || 0,
                        budget: opexData[key]?.budget_sum || 0,
                        vsBudget: opexData[key]?.vs_budget || 0,
                        opexPerSQM: 0, //opexData[key]?.opex_per_sqm
                        sales: 0, //branch?.actual_sales
                        percentToSales: opexData[key]?.percent_to_sales || 0,
                    }
                })

            const nonControllableRow = {
                branch: 'Non Controllable',
                income: null,
                current: opexData[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.opex_value_sum || 0,
                ly: opexData[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.last_year_opex_value_sum || 0,
                vsLy: opexData[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.vs_last_year || 0,
                budget: opexData[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.budget_sum || 0,
                vsBudget: opexData[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.vs_budget || 0,
                opexPerSQM: opexData[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.opex_per_sqm || 0,
                sales: 0, //branch?.actual_sales 
                percentToSales: opexData[OPERATION_TYPE_TOTAL_NON_CONTROLLABLE]?.percent_to_sales || 0,
                subRows: nonControllableSubRows
            };

            const lossesShrinkageRow = {
                branch: OPERATION_TYPE_LOSSES_AND_SHRINKAGE,
                income: null,
                current: opexData[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.opex_value_sum || 0,
                ly: opexData[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.last_year_opex_value_sum || 0,
                vsLy: opexData[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.vs_last_year || 0,
                budget: isMTD ? opexData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.budget_sum : opexData?.['Losses & Shrinkages YTD']?.opex_value_sum,
                vsBudget: isMTD ? opexData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.vs_budget : (opexData?.[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.opex_value_sum / opexData?.['Losses & Shrinkages YTD']?.opex_value_sum) * 100,
                opexPerSQM: opexData[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.opex_per_sqm || 0,
                sales: 0, //branch?.actual_sales 
                percentToSales: opexData[OPERATION_TYPE_LOSSES_AND_SHRINKAGE]?.percent_to_sales || 0,
            }

            const subRows = []
            if (controllableRow.subRows.length > 0) {
                subRows.push(controllableRow)
            }
            if (nonControllableRow.subRows.length > 0) {
                subRows.push(nonControllableRow)
            }
            subRows.push(lossesShrinkageRow)

            const completeStoreData = {
                branch_id: branch?.branch_id,
                branch: branch?.store_name,
                region: branch?.region,
                store_size: branch?.store_size,
                store_type: branch?.store_type,
                store_subtype: branch?.store_subtype,
                income: isMTD ? opexData['remarks'] : opexData['ytd_remarks'] || null,
                current: totalOpexRow?.opex_value_sum || 0,
                ly: totalOpexRow?.last_year_opex_value_sum || 0,
                vsLy: totalOpexRow?.vs_last_year || 0,
                budget: totalOpexRow?.budget_sum || 0,
                vsBudget: totalOpexRow?.vs_budget || 0,
                opexPerSQM: totalOpexRow?.opex_per_sqm || 0,
                sales: branch?.actual_sales || 0,
                percentToSales: totalOpexRow?.percent_to_sales || 0,
                subRows: subRows,
            };
            return completeStoreData;
        })

        return formattedData;
    };

    const expandableStoreData = useMemo(() => formatData(storeData), [params, mergedData]);

    const filteredDataByGroup = useMemo(() => {
        if (!expandableStoreData) {
            return [];
        }
        if (!selectedRows || selectedRows.length === 0) {
            return expandableStoreData;
        }

        return expandableStoreData.filter((row) => selectedRows.includes(row[eyeFilterGroup]));
    }, [eyeFilterGroup, expandableStoreData, selectedRows]);

    const filteredData = useMemo(() => {
        if (!filteredDataByGroup) {
            return []
        }
        if (!selectedStoreIds || selectedStoreIds.length === 0) {
            return filteredDataByGroup
        }

        const filteredOnStoreId = filteredDataByGroup.filter((row) => selectedStoreIds.includes(row.branch_id))

        return filteredOnStoreId;
    }, [filteredDataByGroup, selectedStoreIds])

    const optionListStore = useMemo(() => {
        if (!mergedData) {
            return []
        }
        return mergedData?.map((row) => {
            if (!row) {
                console.log('row is undefined')
                return {}
            }
            return {
                label: row.store,
                value: row.branch_id,
            }
        })
    })

    const windowSize = useContext(WindowSizeContext)
    const STORE_OPEX_COLUMNS = useMemo(() => [
        {
            id: 'empty',
            columns: [
                {
                    id: 'branch_id',
                    header: 'Store',
                    accessorKey: 'branch',
                    cell: ({ row, getValue }) => (
                        <Box sx={{ justifyItems: 'center', marginLeft: `${row.depth * 2}rem`, whiteSpace: 'nowrap' }}>
                            <Grid alignItems="center" display="flex">
                                <Box sx={{ fontWeight: row.depth === 0 || row.depth === 1 ? 'bold' : 'normal' }}>
                                    {getValue()}
                                </Box>
                                {row.getCanExpand() ?
                                    <IconButton size='small' onClick={row.getToggleExpandedHandler()} sx={{ cursor: "pointer" }}>
                                        {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize='small' /> : <ArrowRightRoundedIcon fontSize='small' color='#e0e0e0' />}
                                    </IconButton>
                                    : ''}
                            </Grid>
                        </Box>
                    ),
                    customStyle: { width: '10rem', alignItems: 'center', mx: 'auto' }
                },
                {
                    header: isMTD ? 'MTD NOI' : 'YTD NOI',
                    accessorKey: 'income',
                    cell: (row) => <>
                        <Box
                            style={{
                                fontWeight: (row?.getValue() === null) ? "regular" : "bolder",
                                fontSize: (row?.getValue() === null) ? "14px3" : "30px",
                                color: (row?.getValue() === null) ? '#000000' : (row?.getValue() === 'INCOME' ? '#00b38a' : '#ea324c'),
                                textAlign: 'center',
                                verticalAlign: 'middle',
                            }}
                        >
                            {(!row?.getValue()) ? '-' : (row.getValue() === 'INCOME' ? '+' : '-')}
                        </Box>
                    </>,
                    customStyle: { width: '7rem', textAlign: 'center', verticalAlign: 'middle' }
                },
                {
                    header: isMTD ? 'MTD OPEX' : 'YTD OPEX',
                    accessorKey: 'current',
                    cell: ({ row, getValue }) => {
                        return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
                    },
                    customStyle: { width: '8rem', textAlign: 'left' }
                },
                {
                    header: 'Last Year',
                    accessorKey: 'ly',
                    cell: ({ row, getValue }) => {
                        return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
                    },
                    customStyle: { width: '8rem', textAlign: 'left' }
                },
                {
                    header: 'vs Last Year',
                    accessorKey: 'vsLy',
                    cell: (row) => <>
                        <IndicatorCellBox>
                            <BarIndicator colorFunctionFor={'opex'} value={row?.getValue()?.toFixed(1)} isInverted={true} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                        </IndicatorCellBox>
                    </>,

                    customStyle: { width: '7rem', margin: 'auto', textAlign: 'center', verticalAlign: 'middle', whiteSpace: 'nowrap' }
                },
                {
                    header: 'Budget',
                    accessorKey: 'budget',
                    cell: ({ row, getValue }) => {
                        return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
                    },
                    customStyle: { width: '8rem', textAlign: 'left' }
                },
                {
                    header: 'vs Budget',
                    accessorKey: 'vsBudget',
                    cell: (row) => <>
                        <IndicatorCellBox>
                            <BarIndicator colorFunctionFor={'opex'} value={row?.getValue()?.toFixed(1)} isInverted={true} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                        </IndicatorCellBox>
                    </>,
                    customStyle: { width: '7rem', margin: 'auto', textAlign: 'center', verticalAlign: 'middle', whiteSpace: 'nowrap' }
                },
                {
                    header: 'OPEX per SQM', //  'SQM',
                    accessorKey: 'opexPerSQM', // 'selling_floor',
                    cell: ({ row, getValue }) => {
                        return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} isAbbreviated={windowSize.isOnMobile} /> </Box>
                    },
                    customStyle: { width: '8rem', textAlign: 'left' }
                },
                {
                    header: isMTD ? 'MTD Sales' : 'YTD Sales',
                    accessorKey: 'sales',
                    cell: ({ row, getValue }) => {
                        return <Box sx={{ mx: 3, whiteSpace: 'nowrap' }}> <NumericValueDisplay value={getValue()} prefix={'₱'} isAbbreviated={windowSize.isOnMobile} /> </Box>
                    },
                    customStyle: { width: '8rem', textAlign: 'left' }
                },
                {
                    header: '% to Sales',
                    accessorKey: 'percentToSales',
                    cell: ({ row, getValue }) => {
                        const value = decimalToString(getValue(), { fixedDigits: 2 })
                        return <Box sx={{ whiteSpace: 'nowrap' }}>
                            <BaseNumericDisplay value={value} postfix={'%'} />
                        </Box>
                    },
                    customStyle: { width: '6rem', textAlign: 'center', verticalAlign: 'middle' }
                }
            ]
        }
    ], [filteredData, period]
    )

    const selectedStores = useMemo(() => {
        if (!optionListStore) {
            return []
        }
        return optionListStore.filter((option) => selectedStoreIds.includes(option.value))
    }, [selectedStoreIds, optionListStore])

    if (isLoadingOpexPerStore) {
        return <LoadingComponent />
    }

    function activeFiltersText() {
        let selectedCategory = CATEGORY[filters?.group];
        // const withTopLabel = ['888', '4k']
        let filtersText = selectedRows && selectedRows.length > 0 ? (
            <span>
                - {selectedRows.map(item => {
                    if (item) {
                        return item
                    }
                    return item
                }).join(', ')}
            </span>
        ) : '';
        return (
            <span>
                Filters Selected | Grouping: {selectedCategory} {filtersText}
            </span>
        );
    }

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0", top: `${!windowSize.isOnMobile ? '9rem' : ''}`, zIndex: 12 }}>
                <Grid container alignItems="center" >
                    {
                        (windowSize.isOnMobile) && <>
                            <Grid container justifyContent='space-between' py={.5}>
                                <Grid item >
                                    <Typography variant='h6' ml={2} py={.5}>
                                        OPEX Store View
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <ToggleMtdYtd
                                        value={period}
                                        onChange={onToggleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container sm xs={12} mb={.5} >
                                <DropdownCheckboxV3
                                    id="opex_store_filter"
                                    options={optionListStore}
                                    label="Store"
                                    placeholder="Select a store"
                                    limitTags={2}
                                    onChange={(e, selectedOptions) => {
                                        setSelectedStoreIds(selectedOptions)
                                    }}
                                    value={selectedStores}
                                />
                            </Grid>
                        </>
                    }
                    {
                        (!windowSize.isOnMobile) && <>
                            <Grid container py={.5}>
                                <Grid item xs={5.5} sm={2} py={.5}>
                                    <Typography variant='h6' ml={2} >
                                        OPEX Store View
                                    </Typography>
                                </Grid>
                                <Grid item sm={2}  >
                                    <DropdownCheckboxV3
                                        id="opex_store_filter"
                                        options={optionListStore}
                                        label="Store"
                                        placeholder="Select a store"
                                        limitTags={2}
                                        onChange={(e, selectedOptions) => {
                                            setSelectedStoreIds(selectedOptions)
                                        }}
                                        value={selectedStores}
                                    />
                                </Grid>
                                <Grid item sm display="flex" justifyContent={'end'} >
                                    <ToggleMtdYtd
                                        value={period}
                                        onChange={onToggleChange}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid item xs={12} marginBottom={2} sx={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>
                <Grid item xs={12} sm={12} sx={{ boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)', border: '1px solid #e0e0e0', backgroundColor: '#e1ebf2' }}>
                    <Typography ml={2} sx={{ borderRadius: '0', color: '#000' }}>{activeFiltersText()}</Typography>
                </Grid>
                <OperationTypeFilterDisplay />
                <Item>
                    <TableComponentV2
                        customTableContainerStyle={{
                            borderRadius: "0",
                            boxShadow: 'none',
                        }}
                        customTableHeaderStyle={{
                            backgroundColor: '#caddeb'
                        }}
                        columns={STORE_OPEX_COLUMNS}
                        data={filteredData}
                        tableId={'OpexStorePerformanceTable'}
                        hasPagination={true}
                        isCompact={true}
                        initialSort={{ id: 'sales', desc: true }}
                        paginateExpandedRows={false}
                    />
                </Item>
            </Grid>
        </>
    )
}