import React, { useContext, useMemo } from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useAllAvailableCluster } from '../hooks';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { getSelectedOption } from '../../../../common/utils';


export default function FilterCluster ({filters, params, onDropdownChange}) {
    const windowSize = useContext(WindowSizeContext);

    const { clusters, isLoading } = useAllAvailableCluster({ params:{...params, cluster: null} });

    const options = useMemo(() => {
        let clusterOptions = Array.isArray(clusters)
            ? clusters
                .filter(item => item && item.cluster!== null)
                .map(item => ({
                    value: item.cluster,
                    label: item.cluster,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
            : [];
    
        if (filters && filters.cluster) {
            clusterOptions = clusterOptions.filter(option => option.value !== filters.cluster);
        }
    
        return clusterOptions;
    }, [clusters, filters]);

    const selectedValue = useMemo(() => {
        if (filters && windowSize.isOnMobile) {
            return filters.cluster;
        } else if (filters && !windowSize.isOnMobile) {
            return getSelectedOption(filters.cluster, options);
        }
        return '';
    }, [filters, windowSize.isOnMobile, options]);

    return (
        <ConditionalSelectComponent
            id="cluster_filter"
            labelId="cluster_filter-label"
            options={options}
            label="Cluster"
            value={selectedValue}
            onSelectChange={(event, newValue) => {
                windowSize.isOnMobile ? onDropdownChange(event.target.value, 'cluster')
                : onDropdownChange(newValue?.value, 'cluster');
            }}
            endAdornment={true}
            isLoading={isLoading}
            clearSelected={() => onDropdownChange('', 'cluster')}
            disableClearable={false}
            isOptionEqualToValue={(option, value) => option.value === value}
        />
    );
}