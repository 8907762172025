import React, { useState } from "react";
import { Box, Collapse, Grid } from "@mui/material";
import ResponsiveSectionHeader from "./ResponsiveSectionHeader";
import Item from "../../ItemCard";


export default function Section({ title, selectorsOrFilters, isCollapsible, children, customStyle, information }) {
    const [isExpanded, setIsExpanded] = useState(true);
    const defaultStyle = { backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }
    return (

        <Grid item xs={12} pt={2}>
            <Box flexGrow={1} xs={12} sx={customStyle ? customStyle : defaultStyle}>
                <ResponsiveSectionHeader
                    title={title}
                    selectorsOrFilters={selectorsOrFilters}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    isCollapsible={isCollapsible}
                    information={information}
                />
            </Box>
            <Collapse in={isExpanded}>
                <Grid item xs={12} marginBottom={2} sx={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>
                    <Item>
                        {children}
                    </Item>
                </Grid>
            </Collapse>
        </Grid>
    )
}