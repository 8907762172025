import React, { useMemo } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import SingleStackedBarWithDottedLabel from '../../../../../common/components/graphs/singleStackedBarWithDottedLabel';
import LoadingComponent from '../../../../../common/components/LoadingComponent';

export default function AgeDemographics({ data, genAgeValue, filters, storeID, period, onDropdownChange, isOnMobile, isLoading }) {

    const header = genAgeValue === 'age_bin' ? 'Age' : 'Generation'

    // Sorts the data according to the sorting order defined
    const sortedAgeGenerationData = useMemo(() => {
        const sortingOrders = {
            age_bin: [
              'No Data',
              '65 and Above',
              '60-64',
              '55-59',
              '50-54',
              '45-49',
              '40-44',
              '35-39',
              '30-34',
              '25-29',
              '20-24',
              '18-19',
            ],
            generation_bin: [
              'No Data',
              'Boomers',
              'Gen X',
              'Millennials',
              'Gen Z',
            ]
          };

        return data?.sort((a, b) => {
            const keyA = Object.keys(a)[0];
            const keyB = Object.keys(b)[0];

            const indexA = sortingOrders[genAgeValue].indexOf(keyA);
            const indexB = sortingOrders[genAgeValue].indexOf(keyB);
            return indexA - indexB;
        });
    }, [data, genAgeValue]);

    return (
        <>
            <Card sx={{ minHeight: '25rem' }}>
                <Grid container xs={12} sm={12} sx={{ backgroundColor: '#CADDEB', padding: 1.5 }} display="flex" alignItems="center">
                    <Grid item sm={12} xs={12}>
                        <Typography variant="h6" ml={3}>{header}</Typography>
                    </Grid>
                </Grid>
                <Grid item sm={12} xs={12} alignItems="center"
                    sx={{
                        flex: 1,
                        height: '95%',
                    }}
                >
                    {(() => {
                        if (isLoading) {
                            return (
                                <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: isOnMobile ? '25rem' : '30rem' }}>
                                    <LoadingComponent />
                                </Grid>
                            );
                        }
                        else if (data && data?.length === 0) {
                            return (
                                <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: isOnMobile ? '25rem' : '30rem' }}>
                                    No Data Available
                                </Grid>
                            );
                        }
                        else {
                            return (
                                <SingleStackedBarWithDottedLabel
                                    data={sortedAgeGenerationData}
                                    colors={['#B9B7BD', '#60A3D9', '#C3E0E5', '#37759B', '#84BFF7', '#BFD1DE', '#CADDEB', '#7E95C7', '#AFCDFC', '#4E88DD', '#A2B2D2', '#E2EDF4']}
                                    echartStyle={{
                                        height: isOnMobile ? '25rem' : '30rem',
                                        minWidth: isOnMobile ? '10vw' : 'none',
                                    }}
                                />
                            )
                        }
                    })()}
                </Grid>
            </Card>
        </>
    );
}
