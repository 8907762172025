import {useInfiniteQuery, useQuery} from 'react-query';
import ApiClient from '../../../../../common/API';
import {USE_QUERY_DEFAULT_OPTIONS} from '../../../../../Constants/settings';
import {TABLE_CUSTOMER_ATTRIBUTES, TABLE_CUSTOMER_BU_SOW} from '../../constants';
import {convertParamsToTranDate, convertParamsToTransactionDate, preProcessParams} from '../../util';
import moment from 'moment/moment';
import {useAvailableDateRangeV2} from '../../hooks';
import {getBUBasketSizeBranchView, getColumnValue, viewNameByGroupBy} from './utils';
import {displayedOptions} from '.';
import {forceParseFloat} from "../../../common/util";
import React from "react";


export const useBuSoWDataV2 = (groupBySelection, params, selectedNested) => {
    const columnForFilter = getColumnValue(groupBySelection)

    const hasCustomerDate = params?.month?.length > 0 && !!params?.year
    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRangeV2();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const processedParams = preProcessParams(convertParamsToTranDate(params, dayOfMonthMaxDate))

    // this view has very limited number of columns as output, and already grouped by
    // no need to specify columns, group_by, aggregates
    const queryParams = {
        ...processedParams,
        limit: 5000,
        view: viewNameByGroupBy[groupBySelection],
    };

    const queryKey = ['bu-group-sow', groupBySelection, queryParams];

    const result = useQuery(queryKey, () => {
            return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
                params: queryParams,
            }).then((res) => res.data.data)
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS
    })

    const filteredData = React.useMemo(() => {
        return result.data?.filter(row => !selectedNested || selectedNested.length === 0 || selectedNested.includes(row[columnForFilter]))
    }, [result.data, selectedNested, columnForFilter])

    return {
        ...result,
        // we are filtering the data here, because the data is already grouped by the groupBySelection and filtering via query is not useful since we already have all the data
        filteredData
    }
}


export const useBUSoWFiltersData = () => {
    const columns = ['sbu_bu_group', 'pm_business_unit_desc_standardized', 'pm_department_desc_standardized', 'pm_subdept_desc_standardized']
    const queryParams = {
        limit: 5000,
        columns: columns.join(','),
        group_by: columns.join(','),
    };

    const queryKey = ['bu-group-sow-filters', queryParams];
    const result = useQuery(queryKey, () => {
        return ApiClient().get(`data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: queryParams,
        }).then((res) => res.data.data)
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS
    })

    const sortByLabel = (a, b) => {
        return a.label.localeCompare(b.label)
    }
    const sortChildrenRecursively = (option) => {
        if (option.children) {
            return {
                ...option,
                children: option.children.sort(sortByLabel).map(sortChildrenRecursively)
            }
        } else {
            return option
        }
    }

    const nestedOptions = result.data ? Object.values(result.data.reduce((acc, curr) => {
        // create a nested structure
        const buGroup = curr.sbu_bu_group;
        const bu = curr.pm_business_unit_desc_standardized;
        const dept = curr.pm_department_desc_standardized;
        const subdept = curr.pm_subdept_desc_standardized;

        if (!buGroup || !bu || !dept || !subdept) return acc

        if (!acc[buGroup]) {
            acc[buGroup] = {
                value: buGroup,
                label: buGroup,
                children: []
            }
        }
        if (!acc[buGroup].children.find(child => child.value === bu)) {
            acc[buGroup].children.push({
                value: bu,
                label: bu,
                children: []
            })
        }
        const deptKey = `${bu}|${dept}`
        if (!acc[buGroup].children.find(child => child.value === bu).children.find(child => child.value === deptKey)) {
            acc[buGroup].children.find(child => child.value === bu).children.push({
                value: deptKey,
                label: dept,
                children: []
            })
        }
        const subdeptKey = `${bu}|${dept}|${subdept}`
        if (!acc[buGroup].children.find(child => child.value === bu).children.find(child => child.value === deptKey).children.find(child => child.value === subdeptKey)) {
            acc[buGroup].children.find(child => child.value === bu).children.find(child => child.value === deptKey).children.push({
                value: subdeptKey,
                label: subdept
            })
        }
        return acc
    }, {})) : []

    const sortedNestedOptions = nestedOptions.sort(sortByLabel).map(sortChildrenRecursively)

    return {
        ...result,
        nestedOptions: sortedNestedOptions
    }
}


export const useBuSoWData = (groupBySelection, params, selectedNested) => {

    const basketSizeKeyByGroupBy = {
        'Business Unit': 'bu_basket_size',
        'Department': 'dept_basket_size',
        'Sub-department': 'bu_basket_size',  // fixme
        'Business Unit Group': 'bu_group_basket_size'
    }
    const basketSizeKey = basketSizeKeyByGroupBy[groupBySelection]

    const hasCustomerDate = params?.month?.length > 0 && !!params?.year
    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRangeV2();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const processedParams = preProcessParams(convertParamsToTransactionDate(params, dayOfMonthMaxDate))

    const columns = [basketSizeKey]
    if (groupBySelection === 'Business Unit') {
        columns.push('pm_business_unit_desc_standardized')
    } else if (groupBySelection === 'Department') {
        columns.push('pm_department_desc_standardized')
    } else if (groupBySelection === 'Sub-department') {
        columns.push('pm_subdept_desc_standardized')
    } else {
        columns.push('sbu_higher_mbu')
    }

    const queryParams = {
        ...processedParams,
        limit: 5000,
        'pm_department_desc_standardized': selectedNested,
        aggregates: ['gross_sales_sum'].join(','),
        columns: columns.join(','),
        group_by: columns.join(','),
    };

    const queryKey = ['share_of_wallet', groupBySelection, queryParams];

    return useQuery(queryKey, () => {

            // if (selectedNested?.length > 0)
            //     queryParams.columns = chainColumns().join(',')

            // if (queryParams.hasOwnProperty('columns') && selectedNested?.length === 0)
            //     delete queryParams['columns'];

            return ApiClient().get(`data/table/${TABLE_CUSTOMER_BU_SOW}/query/`, {
                params: queryParams,
            }).then((res) => res.data.data).then((data) => {
                // compute percentage across bu_basket_size
                const salesByBasketSize = data.reduce((acc, curr) => {
                    const basketSize = curr[basketSizeKey];
                    if (!acc[basketSize]) {
                        acc[basketSize] = 0;
                    }
                    acc[basketSize] += forceParseFloat(curr.gross_sales_sum);
                    return acc;
                }, {});

                return data.map((row) => ({
                    ...row,
                    percentage: forceParseFloat(row.gross_sales_sum) / salesByBasketSize[row[basketSizeKey]],
                }));
            }).then(data => {
                // rename bu_basket_size -> bu_group_basket_size
                return data.map(row => ({
                    ...row
                }));
            });
    })
}


export function useBUSoWBranchView(groupBySelection, params, storeID, selectedNested) {
    const isBranchView = true;

    const hasCustomerDate = params?.month?.length > 0 && !!params?.year

    const { data: availableDateRange, isLoading: isLoadingDateRange } = useAvailableDateRangeV2();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year

    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    const columnsReq = ['year', 
                        'month', 
                        'sbu_higher_mbu', 
                        'pm_business_unit_desc_standardized', 
                        'pm_department_desc_standardized', 
                        'pm_subdept_desc_standardized', 
                        'bu_basket_size', 
                        'store_id', 
                        'store', 
                        'gross_sales', 
                        'bu_group_basket_size', 
                        'store_bu_group_basket_size', 
                        'store_bu_basket_size', 
                        'store_dept_basket_size', 
                        'store_subdept_basket_size'
                    ]

    const { data: sow_data, isLoading: is_loading_sow_data } = useInfiniteQuery(
        ['shareOfWallet', params, storeID, 'branch_view', selectedNested],
        ({ pageParam=1 }) => {
            const processedParams = preProcessParams(
                convertParamsToTransactionDate(params, dayOfMonthMaxDate)
            );
            
            const queryParams = {
                ...processedParams,
                columns: columnsReq.join(','),
                store_id: storeID,
                limit: 30000,
                offset: (pageParam - 1) * 1000,
            };

            if (selectedNested?.length > 0)
                queryParams.pm_department_desc_standardized = selectedNested
            // CHANGE dept to subdept if g
            if (queryParams.hasOwnProperty('pm_department_desc_standardized') && selectedNested?.length === 0)
                delete queryParams['pm_department_desc_standardized'];

            return ApiClient().get(`data/table/${TABLE_CUSTOMER_BU_SOW}/query/`, {
                params: queryParams,
            }).then((res) => res.data.data);
        },
        {
            ...USE_QUERY_DEFAULT_OPTIONS,
            getNextPageParam: (lastPage, allPages) => (lastPage.length === 1000 ? allPages.length + 1 : null),
        }
    );    

    const filtered_data = sow_data?.pages.flat();

    const percentageFilteredData = calculatePercentages(filtered_data, getColumnValue(groupBySelection), groupBySelection, isBranchView)
    
    return { data: percentageFilteredData, isLoading: is_loading_sow_data, fullData: filtered_data };
}

// BRANCH VIEW Calculation
function calculatePercentages(data, columnName, groupBySelection, isBranchView=false) {
    if (data === undefined) return undefined;

    const SETTINGS = {buBasketSize: null, columnValue: null}
    if (isBranchView) {
        SETTINGS.buBasketSize = getBUBasketSizeBranchView(groupBySelection)
    }
    else {
        SETTINGS.buBasketSize = 'txn_basket_size_bin'
    }
    SETTINGS.columnValue = getColumnValue(groupBySelection)
    
    const groupedData = data.reduce((acc, curr) => {
        const key = `${curr.year}-${curr.month}-${curr[columnName]}-${curr[SETTINGS.buBasketSize]}`;
        if (!acc[key]) {
            acc[key] = {
                year: curr.year,
                month: curr.month,
                [columnName]: curr[columnName],
                [SETTINGS.buBasketSize]: curr[SETTINGS.buBasketSize],
                bu_group_mtd_sales: 0
            };
        }
        acc[key].bu_group_mtd_sales += parseFloat(curr.gross_sales);
        return acc;
    }, {});
// console.log('groupedData', groupedData)

    // calculate the total sum of gross_sales for each bu_basket_size
    const totalSalesByBasketSize = Object.values(groupedData)?.reduce((acc, curr) => {
        if (!acc[curr[SETTINGS.buBasketSize]]) {
            acc[curr[SETTINGS.buBasketSize]] = 0;
        }
        acc[curr[SETTINGS.buBasketSize]] += curr.bu_group_mtd_sales;
        return acc;
    }, {});
// console.log('totalSalesByBasketSize', totalSalesByBasketSize)

    // get percentage for each entry
    const result = Object.values(groupedData).map(entry => ({
        ...entry,
        percentage: entry.bu_group_mtd_sales / totalSalesByBasketSize[entry[SETTINGS.buBasketSize]]
    }));

// console.log('result', result)

    return result
}

function calculateYTDQoQPercentages(data, columnName) {

}