import React from "react";
import GraphFactory from "../../../../common/components/GraphFactory";
import {SEGMENT_COLOR_MAP,TABLE_MBA_SEGMENTS} from "../constants";


export default function SkuCountPerSegment({filters}) {

    const extraData = {
        "filters": [],
        "columns": [],
        "aggregates": [],
        "label": {"label": "product_segment_desc", "value": "product_segment_desc", "get_param": "columns"},
        "value": {
            "label": "merch_article_desc_count_distinct",
            "value": "merch_article_desc_count_distinct",
            "get_param": "aggregates"
        },
        "labelMapping": {
            "1": "Key Value",
            "2": "Foreground",
            "3": "Background"
        },
        "colorMap": SEGMENT_COLOR_MAP,

        chartProps: {
            style: {"height": `25em`},
        },
        chartOptions: {
            series: [
                {width: "75%"}
            ],
        }
    }

    return <GraphFactory
        filters={filters}
        chart_type={"pie"}
        table_source={TABLE_MBA_SEGMENTS}
        extra_data={extraData}
        title={"Number of Unique SKUs per Segment"}
    />
}