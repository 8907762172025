import React, { useContext, useMemo} from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useAllAvailableBusinessUnits } from '../hooks';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { getSelectedOption } from '../../../../common/utils';

export default function FilterBusinessUnits({ filters, params, onDropdownChange, storeID}) {
    const windowSize = useContext(WindowSizeContext);

    const { businessUnits, isLoading} = useAllAvailableBusinessUnits({ params: {...params,standardized_business_unit: null, store_id:storeID} });

    console.log(filters,'filters')
    
    const options = useMemo(() => {
        let buOptions = Array.isArray(businessUnits)
            ? businessUnits
                .filter(item => item && item.standardized_business_unit !== null)
                .map(item => ({
                    value: item.standardized_business_unit,
                    label: item.standardized_business_unit,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
            : [];
    
        
    
        return buOptions;
    }, [businessUnits]);


    return (
        <ConditionalSelectComponent
                    id="bu_filter"
                    labelId="bu_filter-label"
                    options={options}
                    label="All Business Unit"
                    value={filters ? (windowSize.isOnMobile ? filters?.standardized_business_unit : getSelectedOption(filters?.standardized_business_unit, options)) : '' &&  null}
                    onSelectChange={(event,newValue)=>{
                        windowSize.isOnMobile ? onDropdownChange(event.target.value, 'standardized_business_unit')
                        : onDropdownChange(newValue?.value, 'standardized_business_unit')  
                    }}
                    endAdornment={true}
                    isLoading={isLoading}
                    clearSelected={() => onDropdownChange('','standardized_business_unit')}
                    disableClearable={false}
            />
    );
}