import React from 'react';
import {useSalesOverallPerformanceData} from "../hooks";
import moment from "moment/moment";
import {TopLineContext} from "../../common/contexts";


export default function TopLineDataProvider({params, children}) {
    const mtdDataHook = useSalesOverallPerformanceData({params});

    const ytdParams = {
        ...params,
        month: [1, ...(params?.month || [])]
    }
    const ytdDataHook = useSalesOverallPerformanceData({params: ytdParams})

    const transformTopLineData = (dataHookResponse, dataHookParams) => {
        const {
            actualSales,
            targetSales,
            lastYearSales,
            sales2019,
            isLoading
        } = dataHookResponse;

        const monthsSelected = dataHookParams?.month || []
        const minMonthSelected = Math.min(...monthsSelected)
        const maxMonthSelected = Math.max(...monthsSelected)
        const fromDate = moment(`${dataHookParams?.year}-${minMonthSelected}`, 'YYYY-MM').toDate()
        const toDate = moment(`${dataHookParams?.year}-${maxMonthSelected}`, 'YYYY-MM').toDate()

        return {
            isLoading,
            periodFrom: fromDate,
            periodTo: toDate,
            primaryValue: actualSales,
            targetValue: targetSales,
            comparisonValues: [
                {
                    label: 'vs sales plan',
                    value: targetSales,
                },
                {
                    label: 'vs last year',
                    value: lastYearSales,
                },
                {
                    label: 'vs 2019',
                    value: sales2019,
                }
            ],

            textMap: {}
        }
    }


    const items = [
        {
            ...transformTopLineData(mtdDataHook, params),
            textMap: {
                primaryLabel: 'MTD Sales',
                targetLabel: 'MTD Target',
            }
        },
        {
            ...transformTopLineData(ytdDataHook, ytdParams),
            textMap: {
                primaryLabel: 'YTD Sales',
                targetLabel: 'YTD Target',
            }
        }
    ]

    return <TopLineContext.Provider value={{items: items}}>
        {children}
    </TopLineContext.Provider>

}