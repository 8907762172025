import React, {useMemo, useState} from 'react';
import DataTable from "react-data-table-component";
import LoadingComponent from "../../../common/components/LoadingComponent";
import {useGroupList, useModuleList, useUserList} from "../../../common/API/hooks";
import EditModule from "./EditModule";
import DeleteModule from "./DeleteModule";
import {Grid, IconButton, Typography} from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Fuse from 'fuse.js';

const customStyles = {
    rows: {
        style: {
            minHeight: '3.5rem',
        },
    },
    headRow: {
        style: {
            fontWeight: "900",
            backgroundColor: '#F6F6F6',
        },
    },
    pagination: {
        style: {
            backgroundColor: '#ffff',
        },
    },
};


function MenuInTable(row) {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    return <>

        <Divider orientation="vertical" flexItem/>

        <Tooltip title="Edit">
            <IconButton aria-label="Edit" size="small">
                <EditRoundedIcon
                    onClick={() => setIsEditModalOpen(true)}
                    fontSize="small"
                />
            </IconButton>
        </Tooltip>

        <Tooltip title="Delete" backgroundColor="white">
            <IconButton aria-label="Delete" size="small">
                <DeleteRoundedIcon
                    onClick={() => setIsDeleteModalOpen(true)}
                    sx={{color: "red"}}
                    fontSize="small"
                />
            </IconButton>
        </Tooltip>
        <EditModule module={row} isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen}/>
        <DeleteModule module={row} isOpen={isDeleteModalOpen} setsetIsOpen={setIsDeleteModalOpen}/>

    </>
}

const ModulePermissionExpandedComponent = ({data}) => {

    const {data: groupList, isLoading: groupLoading} = useGroupList();
    const allowedGroupsUserList = data.allowed_groups // List of Users under each allowed group (Might contain duplicates)

    const usersFromGroups = groupList?.filter(group => allowedGroupsUserList.includes(group.name)) // Filter only matched groups
        .flatMap(group => group.users); // Extract users from matched groups

    // Combine users from groups and allowed_users, then remove duplicates and sort
    const usersForModule = [
        ...new Set([...usersFromGroups, ...data.allowed_users])
    ].sort((a, b) => a.localeCompare(b));

    const [hoveredUser, setHoveredUser] = useState(null)
    const [hoveredGroup, setHoveredGroup] = useState(null)

    const highlightedUsers = useMemo(() => {
        if (!groupList) {
            return []
        }
        return groupList.find(group => group.name === hoveredGroup)?.users || []
    }, [hoveredUser, hoveredGroup, groupList])

    const highlightedGroups = useMemo(() => {
        if (!groupList) {
            return []
        }
        return groupList.filter(group => group.users.includes(hoveredUser)).map(group => group.name) || []
    }, [hoveredUser, hoveredGroup, groupList])


    return (
        <>
            <Grid container ml={3}>
                <Typography variant='inherit' fontWeight='bold'>
                    User List:
                </Typography>
                <Grid container> {/*Displays list of users with permission*/}
                    {[0, 1, 2].map((columnIndex) => (
                        <Grid item xs={6} sm={6} md={4} key={columnIndex}>
                            <ul>
                                {data.allowed_users.sort().map((user, index) => {
                                    if (index % 3 === columnIndex) {
                                        return <li
                                            onMouseEnter={() => {
                                                setHoveredUser(user)
                                            }}
                                            onClick={() => {
                                                setHoveredUser(user === hoveredUser ? null : user)
                                            }}
                                            onMouseLeave={() => {
                                                setHoveredUser(null)
                                            }}
                                            style={{
                                                backgroundColor: highlightedUsers.includes(user) ? "yellow" : null
                                            }}
                                            key={index}
                                        >{user}</li>;
                                    }
                                    return null;
                                })}
                            </ul>
                        </Grid>
                    ))}
                </Grid>

                <Typography variant='inherit' component='div' fontWeight='bold'>
                    Group List:
                </Typography>
                <Grid container>
                    {[0, 1, 2].map((columnIndex) => (
                        <Grid item xs={6} sm={6} md={4} component='div' key={columnIndex}>
                            <ul>
                                {data.allowed_groups.map((group, index) => {
                                    if (index % 3 === columnIndex) {
                                        return <li
                                            key={index}
                                            onMouseEnter={() => {
                                                setHoveredGroup(group)
                                            }}
                                            onClick={() => {
                                                setHoveredGroup(group === hoveredGroup ? null : group)
                                            }}
                                            onMouseLeave={() => {
                                                setHoveredGroup(null)
                                            }}
                                            style={{
                                                backgroundColor: highlightedGroups.includes(group) ? "yellow": null
                                            }}
                                        >
                                            {group}
                                        </li>;
                                    }
                                })}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Typography variant='inherit' fontWeight='bold'>Effectively visible to the following
                    Users:
                </Typography>
                <Grid container>
                    {[0, 1, 2].map((columnIndex) => (
                        <Grid item xs={6} sm={6} md={4} key={columnIndex}>
                            <ul>
                                {data.visibleToUsers.sort().map((user, index) => {
                                    if (index % 3 === columnIndex) {
                                        return <li
                                            onMouseEnter={() => {
                                                setHoveredUser(user)
                                            }}
                                            onClick={() => {
                                                setHoveredUser(user === hoveredUser ? null : user)
                                            }}
                                            onMouseLeave={() => {
                                                setHoveredUser(null)
                                            }}
                                            key={index}
                                            style={{
                                                backgroundColor: highlightedUsers.includes(user) ? "yellow" : null
                                            }}
                                        >{user}</li>;
                                    }
                                    return null;
                                })}
                            </ul>
                        </Grid>
                    ))}

                </Grid>
            </Grid>
            <Grid style={{borderTopColor: 'darkgray'}}/>
        </>
    );
};

export default function ModuleTable({searchQuery, filterUsers}) {

    const {data: moduleList, isLoading: moduleLoading} = useModuleList();
    const {data: groupList, isLoading: groupLoading} = useGroupList();
    const {data: userList} = useUserList();

    const moduleWithUser = useMemo(() => {
        if (moduleLoading || !moduleList) {
            return [];
        }
        if (!userList || !groupList) {
            return moduleList
        }
        return moduleList.map((module) => {
            const allowedGroupsUserList = module.allowed_groups // List of Users under each allowed group (Might contain duplicates)

            const usersFromGroups = groupList
                .filter(group => allowedGroupsUserList.includes(group.name)) // Filter only matched groups
                .flatMap(group => group.users); // Extract users from matched groups

            // Combine users from groups and allowed_users, then remove duplicates and sort
            const usersForModule = [
                ...new Set([...usersFromGroups, ...module.allowed_users])
            ].sort((a, b) => a.localeCompare(b));

            return {
                ...module,
                visibleToUsers: usersForModule
            }
        })
    }, [moduleLoading, moduleList, userList, groupList])

    const userFilteredModules = useMemo(() => {
        if (moduleLoading || !moduleWithUser || !filterUsers || filterUsers.length === 0) {
            return moduleWithUser || []; // Return an empty array if userList is not available or still loading
        }
        return moduleWithUser.filter(module => {
            return filterUsers.every(user => module.visibleToUsers.includes(user))
        })
    }, [moduleLoading, moduleWithUser, filterUsers]);

    const searchFilteredModules = useMemo(() => {
        if (moduleLoading || !userFilteredModules || !searchQuery) {
            return userFilteredModules || []; // Return an empty array if userList is not available or still loading
        }

        const options = {
            keys: ['name'],
            threshold: 0.3, // Adjust the threshold as needed
        };

        const fuse = new Fuse(userFilteredModules, options);
        const query = searchQuery.toLowerCase();

        return fuse.search(query).map(result => result.item);

    }, [moduleLoading, userFilteredModules, searchQuery]);


    const columns = [
        {
            name: 'MODULE NAME',
            selector: (row) => row.name,
            sortable: true,
            sortFunction: (a, b) => a.name.localeCompare(b.name),
        },
        {
            name: 'USERS',
            selector: (row) => row.users,
            sortable: true,
            format: (row) => {
                if (row.is_public) {
                    return "All Users";
                } else {
                    return `${row.allowed_users.length} User${row.allowed_users.length > 1 ? "s" : ""}`;
                }
            },
            grow: 1,
            sortFunction: (a, b) => {
                if (a.is_public && !b.is_public) {
                    return -1;
                } else if (!a.is_public && b.is_public) {
                    return 1;
                } else {

                    return a.allowed_users.length - b.allowed_users.length;
                }
            },
        },
        {
            name: 'GROUPS',
            sortable: true,
            selector: (row) => {
                if (row.is_public) {
                    return "All Groups";
                } else {
                    return `${row.allowed_groups.length} Group${row.allowed_groups.length > 1 ? "s" : ""}`;
                }
            }
            ,
        },
        {
            name: 'PUBLIC',
            selector: (row) => row.is_public,
            sortable: true,
            cell: (row) => row.is_public ? 'Yes' : 'No',
        },
        {
            name: 'VIEW COUNT',
            selector: (row) => row.view_count,
            sortable: true,
            sortFunction: (a, b) => a.view_count.localeCompare(b.view_count),
        },
        {   // Column for Edit and Delete
            cell: (row) => (
                <MenuInTable row={row}/>
            ),
            ignoreRowClick: true,
            button: true,
        },
    ]


    return (
        <>
            <DataTable
                columns={columns}
                data={searchFilteredModules}
                pagination
                progressPending={moduleLoading || groupLoading}
                progressComponent={<LoadingComponent/>}
                highlightOnHover
                customStyles={customStyles}
                expandableRows
                expandOnRowClicked
                expandableRowsComponent={ModulePermissionExpandedComponent}
            />
        </>
    )
}