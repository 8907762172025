import {useTableDataFilterColumns} from "../../../../common/API/hooks";
import FilterFactory from "../../../../common/components/FilterFactory";

export default function FilterRow({tableId, filterData, onChange}) {
    // retail filter row here. FilterFactory can be reused for other filter implementations
    const {data: filterList, isLoading: isLoadingFilters} = useTableDataFilterColumns(tableId, {
        enabled: !!tableId
    });

    if (isLoadingFilters) {
        return <div className="mb-2 text-center">
            <span className={"spinner-border  spinner-border-sm text-primary"} role="status" aria-hidden="true"/>
        </div>
    }


    return <FilterFactory
            availableFilterFields={filterList}
            filterData={filterData}
            tableId={tableId}
            onChange={onChange}
        />
}