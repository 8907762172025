import React, {useMemo} from "react";
import {useFilterValues} from "../../API/hooks";
import Select from "react-select";
import {getSelectedOption} from "../../../common/utils";
import Grid from "@mui/material/Grid";
import TextField  from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';



export default function FilterFactory({tableId, availableFilterFields, filterData, onChange}) {

    const filterName = filterData.filterName
    const operator = filterData.operator
    const value = filterData.value

    const filterFieldOptions = availableFilterFields?.map(filter => {
        return {label: filter.name, value: filter.name}
    })

    const selectedFilter = availableFilterFields?.find(filter => filter.name === filterData?.filterName)
    const fieldType = selectedFilter?.json_type

    const {data: filterValues, isLoading: filterValuesIsLoading} = useFilterValues(tableId, filterName, null, {
        enabled: !!filterName && (fieldType !== "boolean")
    })

    const operatorSelect = useMemo(() => {
        const operators = []
        if (fieldType === "string") {
            operators.push({label: "Equals", value: "eq"})
            operators.push({label: "Not Equals", value: "neq"})

            operators.push({label: "In", value: "in"})
            operators.push({label: "Not In", value: "not_in"})

            operators.push({label: "Starts With", value: "starts_with"})
            operators.push({label: "Ends With", value: "ends_with"})
            operators.push({label: "Contains", value: "contains"})

        } else if (fieldType === "date") {
            operators.push({label: "Equals", value: "eq"})
            operators.push({label: "Between", value: "between"})
            operators.push({label: "Before", value: "lt"})
            operators.push({label: "After", value: "gt"})
            operators.push({label: "On or Before", value: "lte"})
            operators.push({label: "On or After", value: "gte"})

        } else if (fieldType === "integer" || fieldType === "number") {
            operators.push({label: "Equals", value: "eq"})
            operators.push({label: "Not Equals", value: "neq"})
            operators.push({label: "Greater Than", value: "gt"})
            operators.push({label: "Less Than", value: "lt"})
            operators.push({label: "Between", value: "between"})
        }

        if (fieldType === "boolean") {
            return null
        }

        // return <div className={"col col-sm-4"}>
        return <Grid item md={4}>
            {/* <Select
                closeMenuOnSelect
                options={operators}
                value={operators.find(op => op.value === operator)}
                onChange={(newOption) => onChange({...filterData, operator: newOption.value})}
                isDisabled={!filterName}
            /> */}
            <Autocomplete
                disableClearable
                disabled={!filterName}
                value={operators.find(op => op.value === operator)}
                onChange={(event,newOption) => onChange({...filterData, operator: newOption.value})}
                id="filter_options" size="small"
                options={operators}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} />
                }
            />
        </Grid>
    }, [fieldType, operator, filterName]);

    const valueInput = useMemo(() => {
        if (fieldType === "boolean") {
            const options = [
                {label: "True", value: true},
                {label: "False", value: false}
            ];
            // return <Select
            //     closeMenuOnSelect
            //     options={options}
            //     value={options.find(op => op.value === value)}
            //     onChange={(newOption) => onChange({...filterData, value: newOption.value})}
            //     isClearable
            //     isDisabled={!filterName}
            // />
            return  <Autocomplete
                disabled={!filterName}
                value={options.find(op => op.value === value)}
                onChange={(event,newOption) => onChange({...filterData, value: newOption.value})}
                id="filter_options" size="small"
                options={options}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} />
                }
            />
        } else if (fieldType === "date") {
            // TODO: implement UI for date filters
            if (operator === "between") {
                const fromDate = value ? value[0] : null
                const toDate = value ? value[1] : null
                //  TODO: add validations
                // return <div className="col">
                return <Grid item xs>
                    {/* <input type="date" className="form-control" disabled={!filterName} onChange={e => {
                        onChange({...filterData, value: [e.target.value, toDate]})
                    }} value={fromDate}/>
                    <input type="date" className="form-control" disabled={!filterName} onChange={e => {
                        onChange({...filterData, value: [fromDate, e.target.value]})
                    }} value={toDate}/> */}
                    <TextField type="date" variant="outlined" size="small" fullWidth 
                        disabled={!filterName} onChange={e => {
                            onChange({...filterData, value: [e.target.value, toDate]})
                        }} value={fromDate}/>
                    <TextField type="date" variant="outlined" size="small" fullWidth 
                        disabled={!filterName} onChange={e => {
                            onChange({...filterData, value: [fromDate, e.target.value]})
                        }} value={toDate}/>
                </Grid>
            } else {
                // return <div className="col">
                return <Grid item xs>
                    {/* <input type="date" className="form-control" disabled={!filterName} onChange={e => {
                        onChange({...filterData, value: e.target.value})
                    }} value={value || null}/> */}
                    <TextField type="date" variant="outlined" size="small" fullWidth 
                        disabled={!filterName} onChange={e => {
                            onChange({...filterData, value: e.target.value})
                        }} value={value || null}/>
                </Grid>
            }
        } else if (fieldType === "integer" || fieldType === "number") {
            if (operator === "between") {
                // return <div className="col">
                return <Grid item xs>
                    {/* <input type="number" className="form-control" disabled={!filterName} onChange={e => {
                        onChange({...filterData, value: [e.target.value, filterData.value ? filterData.value[1] : null]})
                    }} value={filterData.value ? filterData.value[0] : null}/>
                    <input type="number" className="form-control" disabled={!filterName}  onChange={e => {
                        onChange({...filterData, value: [filterData.value ? filterData.value[0] : null, e.target.value]})
                    }} value={filterData.value ? filterData.value[1] : null}/> */}
                    <TextField type="number" variant="outlined" size="small" fullWidth 
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        disabled={!filterName}  onChange={e => {
                            onChange({...filterData, value: [e.target.value, filterData.value ? filterData.value[1] : null]})
                        }} value={filterData.value ? filterData.value[0] : null}/>
                    <TextField type="number" variant="outlined" size="small" fullWidth 
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        disabled={!filterName} onChange={e => {
                            onChange({...filterData, value: [filterData.value ? filterData.value[0] : null, e.target.value]})
                        }} value={filterData.value ? filterData.value[1] : null}/>
                        
                </Grid>
            } else {
                // return <div className="col">
                return <Grid item xs>
                    {/* <input type="number" className="form-control" disabled={!filterName} onChange={e => {
                        onChange({...filterData, value: e.target.value})
                    }}  value={filterData.value}/> */}
                    
                    <TextField type="number" variant="outlined" size="small" fullWidth 
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        disabled={!filterName} onChange={e => {
                            onChange({...filterData, value: e.target.value})
                        }}  value={filterData.value}/>
                </Grid>
            }
        } else {
            if (operator === "in" || operator === "not_in" || operator === "eq" || operator === "neq") {
                const isMulti = operator === "in" || operator === "not_in";
                const filterValueOptions = filterValues?.[filterName]?.map(row => {
                    const value = row[filterName]
                    return {label: value, value: value}
                }) || [];

                const value = isMulti ?
                    (filterData.value || []).map(row => {
                        return filterValueOptions.find(option => option.value === row)
                    }).filter(row => row) :
                    (filterValueOptions.find(option => filterData.value && option.value === filterData.value[0]) || null);

                // return <div className="col">
                 return <Grid item xs>
                    {/* <Select
                        closeMenuOnSelect
                        options={filterValueOptions}
                        isLoading={filterValuesIsLoading}
                        value={value}
                        onChange={(newOption) => {
                            if (!newOption) {
                                onChange({...filterData, value: null})
                            } else {
                                const newValue = isMulti ? newOption.map(option => option.value) : [newOption.value];
                                onChange({...filterData, value: newValue})
                            }
                        }}
                        isClearable
                        isMulti={operator === "in" || operator === "not_in"}
                        isDisabled={!filterName}
                    /> */}

                    <Autocomplete
                        multiple={operator === "in" || operator === "not_in"}
                        value={value}
                        onChange={(event,newOption) => {
                            if (!newOption) {
                                onChange({...filterData, value: null})
                            } else {
                                const newValue = isMulti ? newOption.map(option => option.value) : [newOption.value];
                                onChange({...filterData, value: newValue})
                            }
                        }}
                        loading={filterValuesIsLoading}
                        id="filter_options" size="small"
                        disabled={!filterName}
                        options={filterValueOptions}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params}  
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <React.Fragment>
                                    {filterValuesIsLoading ? <CircularProgress color="secondary" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                                ),
                            }} />
                        }
                    />
                </Grid>
            } else {
                // return <div className="col">
                return <Grid item xs>
                    {/* <input type="text" className="form-control"  disabled={!filterName}/> */}
                    <TextField type="text" variant="outlined" disabled={!filterName} size="small" fullWidth />
                </Grid>
            }
        }
    }, [fieldType, operator, value, filterValues, filterName])


    // return <>

    //     <div className="col-md-4">
    //         <Select
    //             closeMenuOnSelect={true}
    //             options={filterFieldOptions}
    //             isSearchable={true}
    //             value={getSelectedOption(filterData.filterName, filterFieldOptions)}
    //             onChange={(e) => onChange({
    //                 filterName: e ? e.value : null,
    //                 operator: "eq",  // reset operator and value when filter field changes
    //                 value: null
    //             })}
    //             isClearable
    //             placeholder={"Select filter"}
    //         />
    //     </div>
    //     {operatorSelect}
    //     {valueInput}
    // </>
    return <>
         <Grid item md={4}>
            <Autocomplete
                value={getSelectedOption(filterData.filterName, filterFieldOptions)}
                onChange={(event,e) => onChange({
                    filterName: e ? e.value : null,
                    operator: "eq",  // reset operator and value when filter field changes
                    value: null
                })}
                id="filter_options" size="small"
                options={filterFieldOptions}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params}  placeholder="Select Filter"/>
                }
            />
        </Grid>
        {operatorSelect}
        {valueInput}
    </>
}