import React from "react";
import ReactEcharts from "echarts-for-react";
import { abbreviateNumber } from "../../../../../common/utils";

import { Grid, Paper } from "@mui/material";


export default function AgeGroupBarChart({ ageGroupData }) {

    const countByAgeGroupMap = ageGroupData?.reduce((acc, row) => {
        if (!acc.hasOwnProperty(row.ageGroup)) {
            acc[row.ageGroup] = 0
        }
        acc[row.ageGroup] = row.population
        return acc;
    }, {});


    const locationMappingBarAgeGroup = {
        title: {
            text: 'Age Bracket:',
            textStyle: {
                color: 'black',
                fontSize: '1.25rem'
            }
        },
        grid: {
            left: '25%',
            width: '165'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {}
        },
        yAxis: {
            type: 'category',
            data: [...Object.keys(countByAgeGroupMap)],
            axisLabel: {
                interval: 0,
                textStyle: {
                    fontSize: 10
                }
            }
        },
        xAxis: {
            type: 'value',
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            },
        },
        series: [
            {
                color: ['#0A1172'],
                data: [...Object.values(countByAgeGroupMap)], // Insert actual data per age range
                type: 'bar',
                label: {
                    position: 'right',
                    show: true,
                    formatter: function (params) {
                        return abbreviateNumber(params.value)
                    }
                }
            }
        ]
    };


    return (
        <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
            <Grid container>
                <Grid item xs={12}>
                    <ReactEcharts option={locationMappingBarAgeGroup} />
                </Grid>
            </Grid>
        </Paper>
    )
}