export const MONTH = [
    {value: 1, label: "January"},
    {value: 2, label: "February"},
    {value: 3, label: "March"},
    {value: 4, label: "April"},
    {value: 5, label: "May"},
    {value: 6, label: "June"},
    {value: 7, label: "July"},
    {value: 8, label: "August"},
    {value: 9, label: "September"},
    {value: 10, label: "October"},
    {value: 11, label: "November"},
    {value: 12, label: "December"}
]



export const PERIOD_WEEKLY = "weekly";
export const PERIOD_MONTHLY = "monthly";
export const PERIOD_QUARTERLY = "quarterly";
export const PERIOD_YEARLY = "yearly";
