import React from 'react';
import {useCheckHasAccess} from "../../private/customDashboards/Dashboard/hooks";
import {NoPermissionAccess} from "./NoPermissionAccess";


export default function PrivatePathComponent({children}) {
    const hasAccessToThisPath = useCheckHasAccess()

    if (!hasAccessToThisPath) {
        return (<NoPermissionAccess/>)
    }

    // TODO: implement error boundary for whole dashboard

    return (children)
}