import { Box, Grid, Typography } from "@mui/material";
import Item from "../../../../common/components/ItemCard";
import ZeroSalesOverViewTable from "./ZeroSalesOverviewTable";
import { useState } from "react";
import SearchBar from "../components/SearchBar";

export default function ZeroSalesOverview({ filters, handleRadioChange, setOverviewTableIsEmpty }) {
    const [searchQuery, setSearchQuery] = useState('');

    return (
        <>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }} mt={1}>
                <Grid container alignItems="center" display={"flex"} alignContent={"center"} >
                    <Grid item xs={12} sm={3} mx={2}>
                        <Typography variant='h6' ml={3} >Topline Count of Zero Sales</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} display="flex" alignItems="center">
                        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Grid>

                </Grid>
            </Box>
            <Grid item xs={12} marginBottom={2} sx={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>
                <Item>
                    <ZeroSalesOverViewTable filters={filters} searchQuery={searchQuery} handleRadioChange={handleRadioChange} setOverviewTableIsEmpty={setOverviewTableIsEmpty} />
                </Item>
            </Grid>
        </>
    )
}
