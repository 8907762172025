import {useMemo} from 'react'
import {USE_QUERY_DEFAULT_OPTIONS} from "../../../../../../../Constants/settings"
import {useQuery} from "react-query";
import ApiClient from "../../../../../../../common/API"
import {TABLE_CUSTOMER_ATTRIBUTES, TABLE_VIEW_CUSTOMER_BEHAVIOR} from "../../../../../Customers/constants"
import {convertParamsToTranDate, preProcessParams} from "../../../../../Customers/util"
import {useAvailableDateRangeV2} from '../../../../../Customers/hooks';
import moment from 'moment/moment'
import {joinDatasets} from "../../../../../../../common/utils";
import {customerMetricDataSelector} from "../../constants";
import {sortByAgeGroupFn} from "../utils";


const useBrandGenderAgeData = (params, useQueryOptions) => {

    const columnList = [
        'final_brand',
        'age_bin',
        'gender_bin',
    ]
    const aggregates = [
        'header_tran_key_count_distinct',
        'pos_sku_gross_sales_sum',
        'pos_sku_tot_qty_sum',
        'gcr_persistent_id_count_distinct',
    ]
    const queryKey = [TABLE_CUSTOMER_ATTRIBUTES, params, 'v3', columnList]

    return useQuery(queryKey, () => {
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...params,
                columns: columnList.join(','),
                group_by: columnList.join(','),
                aggregates: aggregates.join(','),
                limit: 20000,
            }
        }).then(res => {
            return res.data.data;
        }).then(data => {
            return data.map(row => {
                return {
                    ...row,
                    units_sold_per_txn: row.pos_sku_tot_qty_sum / row.header_tran_key_count_distinct,
                    // legacy keys used in v2
                    header_tran_key: row.header_tran_key_count_distinct,
                    pos_sku_gross_sales: row.pos_sku_gross_sales_sum,
                    pos_sku_tot_qty: row.pos_sku_tot_qty_sum,
                }
            })
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions
    })
}


const useBrandGenderData = (params, useQueryOptions) => {

    const columnList = [
        'final_brand',
        'gender_bin',
    ]
    const aggregates = [
        'header_tran_key_count_distinct',
        'pos_sku_gross_sales_sum',
        'pos_sku_tot_qty_sum',
        'gcr_persistent_id_count_distinct',
    ]
    const queryKey = [TABLE_CUSTOMER_ATTRIBUTES, params, 'v3', columnList]

    return useQuery(queryKey, () => {
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...params,
                columns: columnList.join(','),
                group_by: columnList.join(','),
                aggregates: aggregates.join(','),
                limit: 20000,
            }
        }).then(res => {
            return res.data.data;
        }).then(data => {
            return data.map(row => {
                return {
                    ...row,
                    units_sold_per_txn: row.pos_sku_tot_qty_sum / row.header_tran_key_count_distinct,
                    // legacy keys used in v2
                    header_tran_key: row.header_tran_key_count_distinct,
                    pos_sku_gross_sales: row.pos_sku_gross_sales_sum,
                    pos_sku_tot_qty: row.pos_sku_tot_qty_sum,
                }
            })
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions
    })
}


const useBrandGenderCustomerRatio = (params, useQueryOptions) => {
    const queryKey2 = [TABLE_CUSTOMER_ATTRIBUTES, TABLE_VIEW_CUSTOMER_BEHAVIOR, params, 'customer_ratio_brand_gender']
    return useQuery(queryKey2, () => {
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...params,
                view: 'cpb_customer_ratio_brand_gender',
                limit: 20000,
            }
        }).then(res => {
            return res.data.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
}


const useBrandGenderAgeCustomerRatio = (params, useQueryOptions) => {
    const queryKey3 = [TABLE_CUSTOMER_ATTRIBUTES, TABLE_VIEW_CUSTOMER_BEHAVIOR, params, 'customer_ratio_brand_gender_age']
    return useQuery(queryKey3, () => {
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...params,
                view: 'cpb_customer_ratio_brand_gender_age',
                limit: 20000,
            }
        }).then(res => {
            return res.data.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
}


const useBrandGenderBasketSizeAndFrequency = (params, useQueryOptions) => {
    const columnList2 = [
        'final_brand',
        'gender_bin',
        'basket_size',
        'frequency_of_visit',
    ]

    // Basket Size and Average Visit Brand - Gender
    const queryKey4 = [TABLE_CUSTOMER_ATTRIBUTES, TABLE_VIEW_CUSTOMER_BEHAVIOR, params, columnList2, 'basket_size_brand_gender', 'average_brand_gender']

    return useQuery(queryKey4, () => {
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...params,
                columns: columnList2.join(','),
                group_by: columnList2.join(','),
                view: 'cpb_basket_size_visit_frequency_brand_gender',
                limit: 20000,
            }
        }).then(res => {
            return res.data.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
}


const useBrandGenderAgeBasketSizeAndFrequency = (params, useQueryOptions) => {
    const columnList3 = [
        'final_brand',
        'gender_bin',
        'age_bin',
        'basket_size',
        'frequency_of_visit',
    ]

    const queryKey5 = [TABLE_CUSTOMER_ATTRIBUTES, TABLE_VIEW_CUSTOMER_BEHAVIOR, params, columnList3, 'basket_size_brand_gender_age', 'average_brand_gender_age']

    return useQuery(queryKey5, () => {
        return ApiClient().get(`/data/table/${TABLE_CUSTOMER_ATTRIBUTES}/query/`, {
            params: {
                ...params,
                columns: columnList3.join(','),
                group_by: columnList3.join(','),
                view: 'cpb_basket_size_visit_frequency_brand_gender_age',
                limit: 20000,
            }
        }).then(res => {
            return res.data.data;
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions,
    })
}


export const useCustomerPurchaseBehaviorV3 = (params, selectedMetric, useQueryOptions) => {
    const hasCustomerDate = params?.month?.length > 0 && !!params?.year

    const {data: availableDateRange, isLoading: isLoadingDateRange} = useAvailableDateRangeV2();

    const maxDate = availableDateRange ? availableDateRange.max : null;
    const maxSelectedMonth = params?.month?.length > 0 ? Math.max(...params.month) : null;

    const isQueryingForLatestMonth = hasCustomerDate && maxDate && maxSelectedMonth &&
        moment(maxDate).month() + 1 === maxSelectedMonth && moment(maxDate).year() === params.year
    const dayOfMonthMaxDate = (isQueryingForLatestMonth && maxDate) ? moment(maxDate).date() : null;

    // Sales, Transaction and Units sold per Transaction
    const {data: brandGenderAgeData, isLoading: isLoadingBrandAgeGenderData} = useBrandGenderAgeData(
        preProcessParams(convertParamsToTranDate(params, dayOfMonthMaxDate)),
        {enabled: hasCustomerDate}
    )

    // Sales, Transaction and Units sold per Transaction
    const {data: brandGenderData, isLoading: isLoadingBrandGenderData} = useBrandGenderData(
        preProcessParams(convertParamsToTranDate(params, dayOfMonthMaxDate)),
        {enabled: hasCustomerDate}
    )

    // Customer Ratio Brand-Gender
    const {
        data: brandGenderCustomerRatioData,
        isLoading: isLoadingBrandGenderCustomerRatioData
    } = useBrandGenderCustomerRatio(
        preProcessParams(convertParamsToTranDate(params, dayOfMonthMaxDate)),
        {enabled: hasCustomerDate}
    )

    // Customer Ratio Brand-Gender-Age
    const {data: brandGenderAgeRatioData, isLoading: isLoadingBrandGenderAgeRatioData} = useBrandGenderAgeCustomerRatio(
        preProcessParams(convertParamsToTranDate(params, dayOfMonthMaxDate)),
        {enabled: hasCustomerDate}
    )

    const {
        data: brandGenderBasketSizeAndFrequencyData,
        isLoading: isLoadingBrandGenderBasketSizeAndFrequencyData
    } = useBrandGenderBasketSizeAndFrequency(
        preProcessParams(convertParamsToTranDate(params, dayOfMonthMaxDate)),
        {enabled: hasCustomerDate}
    )


    const {
        data: brandGenderAgeBasketSizeAndFrequencyData,
        isLoading: isLoadingBrandGenderAgeBasketSizeAndFrequencyData
    } = useBrandGenderAgeBasketSizeAndFrequency(
        preProcessParams(convertParamsToTranDate(params, dayOfMonthMaxDate)),
        {enabled: hasCustomerDate}
    )

    const isLoadingBrandGenderDatasets = isLoadingBrandGenderData || isLoadingBrandGenderBasketSizeAndFrequencyData || isLoadingBrandGenderCustomerRatioData
    const isLoadingBrandAgeGenderDatasets = isLoadingBrandAgeGenderData || isLoadingBrandGenderAgeBasketSizeAndFrequencyData || isLoadingBrandGenderAgeRatioData
    const isLoading = isLoadingBrandGenderDatasets || isLoadingBrandAgeGenderDatasets

    const joinedBrandGenderData = useMemo(() => {
        if (isLoadingBrandGenderDatasets || !Array.isArray(brandGenderData) || !Array.isArray(brandGenderCustomerRatioData) || !Array.isArray(brandGenderBasketSizeAndFrequencyData)) {
            return null;
        }

        const result = joinDatasets(
            [brandGenderData, brandGenderCustomerRatioData, brandGenderBasketSizeAndFrequencyData],
            ['final_brand', 'gender_bin'],
            ['pos_sku_gross_sales', 'header_tran_key', 'pos_sku_tot_qty', 'basket_size', 'frequency_of_visit', 'customer_ratio', 'gcr_persistent_id_count_distinct']
        )

        return result
    }, [isLoadingBrandGenderDatasets, brandGenderData, brandGenderCustomerRatioData, brandGenderBasketSizeAndFrequencyData])


    const joinedBrandAgeGenderData = useMemo(() => {
        if (isLoadingBrandAgeGenderDatasets || !Array.isArray(brandGenderAgeData) || !Array.isArray(brandGenderAgeRatioData) || !Array.isArray(brandGenderAgeBasketSizeAndFrequencyData)) {
            return null;
        }

        return joinDatasets(
            [brandGenderAgeData, brandGenderAgeRatioData, brandGenderAgeBasketSizeAndFrequencyData],
            ['final_brand', 'age_bin', 'gender_bin'],
            ['pos_sku_gross_sales', 'header_tran_key', 'pos_sku_tot_qty', 'basket_size', 'frequency_of_visit', 'customer_ratio', 'gcr_persistent_id_count_distinct']
        )
    }, [isLoadingBrandAgeGenderDatasets, brandGenderAgeData, brandGenderAgeRatioData, brandGenderAgeBasketSizeAndFrequencyData])

    const metricSelector = customerMetricDataSelector[selectedMetric]

    const ageGroups = useMemo(() => {
        if (isLoadingBrandAgeGenderData || !brandGenderAgeData) {
            return []
        }
        return brandGenderAgeData.reduce((acc, row) => {
            if (!acc.includes(row.age_bin)) {
                acc.push(row.age_bin)
            }
            return acc
        }, [])
    }, [isLoadingBrandAgeGenderData, brandGenderAgeData])

    const genders = useMemo(() => {
        if (isLoadingBrandGenderData || !brandGenderData) {
            return []
        }
        return brandGenderData.reduce((acc, row) => {
            if (!acc.includes(row.gender_bin)) {
                acc.push(row.gender_bin)
            }
            return acc
        }, [])
    }, [isLoadingBrandGenderData, brandGenderData])

    const data2 = useMemo(() => {
        if (isLoadingBrandGenderDatasets || !joinedBrandGenderData) {
            return []
        }

        const totalValuePerGender = Object.keys(joinedBrandGenderData || {}).reduce((acc, brand) => {
            const brandDict = joinedBrandGenderData[brand]
            return genders.reduce((acc, gender) => {
                if (!acc.hasOwnProperty(gender)) {
                    acc[gender] = 0
                }
                const metricValue = metricSelector(brandDict[gender] || {}) || 0
                acc[gender] += Number(metricValue) || 0
                return acc
            }, acc)
        }, {})

        return Object.keys(joinedBrandGenderData || {}).map(brand => {
            const brandResultDict = genders.reduce((acc, gender) => {
                const genderAsAttributeKey = gender.replace(' ', '_').toLowerCase()
                const metricValue = metricSelector(joinedBrandGenderData[brand][gender] || {}) || 0
                return {
                    ...acc,
                    [`${genderAsAttributeKey}_customer_ratio`]: joinedBrandGenderData[brand][gender]?.customer_ratio || 0,
                    [`${genderAsAttributeKey}_totals`]: Number(metricValue) || 0,
                    [`${genderAsAttributeKey}_percentage_contrib`]: (metricValue / totalValuePerGender[gender] * 100) || 0,
                    [`${genderAsAttributeKey}_customer_count`]: joinedBrandGenderData[brand][gender]?.gcr_persistent_id_count_distinct || 0,
                }
            }, {
                brand
            })  // end of gender keys reduce

            // insert totals
            brandResultDict.total_customer_count = genders.reduce((acc, gender) => {
                const genderAsAttributeKey = gender.replace(' ', '_').toLowerCase()
                return acc + brandResultDict[`${genderAsAttributeKey}_customer_count`]
            }, 0)
            brandResultDict.total_totals = genders.reduce((acc, gender) => {
                const genderAsAttributeKey = gender.replace(' ', '_').toLowerCase()
                return acc + brandResultDict[`${genderAsAttributeKey}_totals`]
            }, 0)
            brandResultDict.total_percentage_contrib = brandResultDict.total_totals / genders.reduce((acc, gender) => {
                return acc + totalValuePerGender[gender]
            }, 0) * 100


            if (isLoadingBrandAgeGenderDatasets || !joinedBrandAgeGenderData) {
                return brandResultDict
            }

            const ageGenderData = joinedBrandAgeGenderData[brand]
            const ageGroupsSubRows = ageGroups.map(ageGroup => {
                const ageData = ageGenderData[ageGroup];

                const ageGroupResultDict = genders.reduce((acc, gender) => {
                    const genderData2 = ageData?.[gender] || {};
                    const genderAsAttributeKey = gender.replace(' ', '_').toLowerCase()
                    const metricValue = metricSelector(genderData2 || {}) || null
                    return {
                        ...acc,
                        [`${genderAsAttributeKey}_customer_ratio`]: genderData2?.customer_ratio || 0,
                        [`${genderAsAttributeKey}_totals`]: Number(metricValue) || 0,
                        // contribution is based on the total value for the brand-gender
                        [`${genderAsAttributeKey}_percentage_contrib`]: (metricValue / brandResultDict[`${genderAsAttributeKey}_totals`] * 100) || 0,
                        [`${genderAsAttributeKey}_customer_count`]: genderData2?.gcr_persistent_id_count_distinct || 0,
                    }
                }, {
                    brand: ageGroup === '65-120' ? '65 and Above' : ageGroup,
                    ageGroup
                })

                // insert totals
                ageGroupResultDict.total_customer_count = genders.reduce((acc, gender) => {
                    const genderAsAttributeKey = gender.replace(' ', '_').toLowerCase()
                    return acc + ageGroupResultDict[`${genderAsAttributeKey}_customer_count`]
                }, 0)
                ageGroupResultDict.total_totals = genders.reduce((acc, gender) => {
                    const genderAsAttributeKey = gender.replace(' ', '_').toLowerCase()
                    return acc + ageGroupResultDict[`${genderAsAttributeKey}_totals`]
                }, 0)
                ageGroupResultDict.total_percentage_contrib = ageGroupResultDict.total_totals / genders.reduce((acc, gender) => {
                    const genderAsAttributeKey = gender.replace(' ', '_').toLowerCase()
                    return acc + brandResultDict[`${genderAsAttributeKey}_totals`]
                }, 0) * 100


                return ageGroupResultDict
            })

            return {
                ...brandResultDict,
                subRows: ageGroupsSubRows.sort(sortByAgeGroupFn)
            };
        })  // end of brand keys map
    }, [
        isLoadingBrandGenderDatasets, isLoadingBrandAgeGenderDatasets,
        joinedBrandAgeGenderData, joinedBrandGenderData,
        metricSelector, genders, ageGroups
    ])


    return {
        data: data2,
        isLoading,
        isLoadingBrandGenderDatasets,
        isLoadingBrandAgeGenderDatasets
    }
}





