import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
import {  TreemapChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
} from 'echarts/components';
import {  SVGRenderer } from 'echarts/renderers';

import { useState } from 'react';

export default function TreeMaps(props) {
    echarts.use(
		[TitleComponent, TooltipComponent, GridComponent, TreemapChart, SVGRenderer, DatasetComponent]
	);


    const treemap_option = {
		...props.config,
		series : {
			...props.config.series,
			data : props.data
		}

	}
	
	const [chartOption, setChartOption] = useState(treemap_option);	
	
	const onChartClick=(args)=> {
		const newData = props.data.map(obj => {
			return {
				...obj, 
				children : [
					...obj.children.map(val => {
						return {...val, itemStyle : {
								colorSaturation : .75
							} 
						}
					}),
				]
					
			}
				
		})
		
		setChartOption(option => ({
				...option,
				series : {
					...option.series,
					data : newData 
				}
			})
		)
	}
	const onEvents = {
		// 'click': onChartClick,
	}	

    return <>
			<ReactEChartsCore
				echarts={echarts}
				option={chartOption}
				notMerge={true}
				lazyUpdate={true}
				opts={{renderer: 'svg'}}
				style={{height: '40rem'}}
				onEvents={onEvents}
			/>
    </>
}