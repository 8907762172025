import React, { useState } from "react";
import { Box, Collapse, Grid } from '@mui/material';
import Item from "../../../../common/components/ItemCard";
import { useSelectorValue } from "../../common/providers/selectorProvider";
import FiltersTextDisplay from "./components/FiltersTextDisplay";
import CollapsibleComponent from "../../../../common/components/CollapsibleComponent";
import { apiFilterKeys } from "./constants";
import StoresDisplayedSelector from "./components/StoresDisplayedSelector";
import { StoreMetaDataProvider } from "./providers";
import StorePerformanceTable from "./StorePerformanceTable";
import SectionHeader from "./components/SectionHeader";
import ComparativePivotSelector from "./components/ComparativePivotSelector";
import { getMetricSectionName } from "../DataProvider/util";

export default function StorePerformance({ params, selectedRows, filterSelectedRows, enableBranchLink = true, enableFilterDisplay = true }) {
    const [selectedStores, setSelectedStores] = useState([])
    const [sectionFilters, setSectionFilters] = useState({})

    const [isExpand, setExpand] = useState(true)

    const selectedMetric = useSelectorValue('metric');
    const selectedViewBy = useSelectorValue('viewBy');

    // Fix me: manually changes column name
    const selectedViewByToL3ColName = {
        'channel': 'cm_channel_desc',
        'business_unit': 'pm_business_unit_desc_standardized',
        'zone': 'store_zone',
    }
    const selectedViewByL3 = selectedViewByToL3ColName[selectedViewBy]

    const apiFilterKey = apiFilterKeys.includes(selectedViewByL3) ? selectedViewByL3 : false;
    const storeQueryParams = {
        ...params,
        [apiFilterKey]: apiFilterKey ? selectedRows : null
    }

    const shouldUseComparativeTable = selectedMetric === 'actual_space_nsa';
    const metricSectionName = getMetricSectionName(selectedMetric)

    // for tables that use comparative table, we need to pass the section filters as well
    // regular tables does not need the section filters
    const paramsForTable = shouldUseComparativeTable ? {
        ...params,
        ...sectionFilters
    } : params;


    const sectionFilterComponents = [
        <StoresDisplayedSelector
            selectedStores={selectedStores}
            setSelectedStores={setSelectedStores}
        />
    ]
    if (shouldUseComparativeTable) {
        sectionFilterComponents.push(
            <ComparativePivotSelector
                params={params}
                filters={sectionFilters}
                setFilters={setSectionFilters}
            />
        )
    }

    return (
        <StoreMetaDataProvider params={storeQueryParams}>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }} mt={3}>
                <Grid container columns={12} alignItems="center" py={.5}>
                    <SectionHeader
                        collapseToggleComponent={<CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />}
                        sectionFilterComponents={sectionFilterComponents}>
                        {metricSectionName} Performance
                    </SectionHeader>
                </Grid>
            </Box>
            <Collapse in={isExpand}>
                <Grid item xs={12} marginBottom={2} sx={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>
                    <Grid item xs={12} sm={12} sx={{
                        boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
                        border: '1px solid #e0e0e0',
                        backgroundColor: '#e1ebf2'
                    }}>
                        {enableFilterDisplay === true && (
                            !shouldUseComparativeTable && <FiltersTextDisplay filterSelectedRows={filterSelectedRows} />
                        )}
                    </Grid>
                    <Item>
                        <StorePerformanceTable
                            useComparativeTable={shouldUseComparativeTable}
                            sectionFilters={sectionFilters}
                            params={paramsForTable}
                            selectedRows={selectedRows}
                            enableBranchLink={enableBranchLink}
                            selectedStores={selectedStores}
                        />
                    </Item>
                </Grid>
            </Collapse>
        </StoreMetaDataProvider>

    );
}