export const SEGMENT_COLOR_MAP = {
    "Low Transaction, Low Sales": "#FF0D0D",
    "Low Transaction, High Sales": "#FFF10A",
    "High Transaction, Low Sales": "#FF6700",
    "High Transaction, High Sales": "#000f73",  // SM Royal Blue
}

export const SEGMENTS = [
    "Low Transaction, Low Sales",
    "Low Transaction, High Sales",
    "High Transaction, Low Sales",
    "High Transaction, High Sales",
]

// we need this to change the sorting of charts depending on the selected column
export const SEGMENT_BY_COLUMN = {
    "total_transactions_sum": [
        "High Transaction, High Sales",
        "High Transaction, Low Sales",
        "Low Transaction, High Sales",
        "Low Transaction, Low Sales",
    ],
    "total_sales_sum": [
        "High Transaction, High Sales",
        "Low Transaction, High Sales",
        "High Transaction, Low Sales",
        "Low Transaction, Low Sales",
    ]
}

export const SEGMENT_THRESHOLDS_BY_COMPANY_ID = {
    1: null,
    2: {x: 57, y: 14595},
    3: {x: 41, y: 12363},
    4: {x: 23, y: 5030},
}


// BIG QUERY TABLES
// export const TABLE_MBA_ASSOCIATION = "mkt2023q1_smco_product_combination";
// export const TABLE_MBA_SEGMENTS = "mkt2023q1_smco_product_segment";

// DATABRICKS TABLES
export const TABLE_MBA_ASSOCIATION = "mba_product_combination";
export const TABLE_MBA_SEGMENTS = "mba_product_segment_v1";