import {useQuery} from "react-query";
import ApiClient from "../API";
import {USE_QUERY_DEFAULT_OPTIONS} from "../../Constants/settings";
import {prepareParams} from "../utils";


export const useFilterValues = (tableName, columnOrColumns, params, queryOptions) => {
    return useQuery(['filter-values', tableName, columnOrColumns,params], () => {
        const preparedParams = prepareParams({
                ...params,
                columns: columnOrColumns
            })
        return ApiClient().get(`/data/table/${tableName}/filter_values/`, {
            params: preparedParams
        }).then(response => {
            return response.data
        })
    }, {
        ...USE_QUERY_DEFAULT_OPTIONS,
        ...queryOptions
    })
}