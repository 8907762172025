import React, { useState } from 'react';
import Search from "./Search";
import TreeNodes from "./TreeNodes";
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

export default function TreeView({ tableId, setTableId }) {
    const [searchQuery, setSearchQuery] = useState('');

    return (
        <Card sx={{borderRadius:'15px', mb:2, height:'80vh'}}>
            <Stack direction={'column'} sx={{p:2}}>
                <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                <TreeNodes
                    searchQuery={searchQuery}
                    setTableId={setTableId}
                    tableId={tableId}  
                />
            </Stack>
        </Card>
    );
}