// MapComponent.jsx
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, LayersControl, Polygon, CircleMarker, Marker, Tooltip, useMap, useMapEvents, Pane, Popup } from 'react-leaflet';
import L from 'leaflet';
import { Grid, Typography } from '@mui/material';
import { BingLayer } from '../MapComponent/react-leaflet-bing-v2';
import { parsePolygon } from '../utils';
import { mall_colors } from '../hooks';
import smIcon from '../MapComponent/images/SM-icon.png';
import { SelectedBarangayPolygon } from './SelectedBarangayPolygon';
import { CircleMarkerSMACPopulation } from './CircleMarkerSMACPopulation';
import LegendControl from './LegendControl';
import NumericValueDisplay from '../../../numericValueDisplay';
// import FlowMapComponent from '../FlowMapComponent';

// Leaflet marker fix
const DefaultIcon = L.icon({
    iconUrl: smIcon,
    iconSize: [35, 35],
    iconAnchor: [17.05, 17.3],
});

const DEFAULT_ZOOM_LVL = 15

L.Marker.prototype.options.icon = DefaultIcon;

function CustomMapEvents() {
    const map = useMap();

    // hovering
    const handleHoverStart = () => {
        map.getContainer().style.cursor = 'pointer';
    };
    const handleHoverEnd = () => {
        map.getContainer().style.cursor = 'default';
    };

    // dragging
    const handleDragStart = () => {
        map.getContainer().style.cursor = 'grabbing';
    };
    const handleDragEnd = () => {
        map.getContainer().style.cursor = 'pointer';
    };
    useMapEvents({
        mouseover: handleHoverStart,
        mouseout: handleHoverEnd,
        dragstart: handleDragStart,
        dragend: handleDragEnd,
    });
    return null;
}

export default function MapComponent({
    targetMallCoordinates,
    bingKey,
    showCityPopulation,
    cityPopulation,
    windowSize,
    checkedIsochrones,
    isochroneTimes,
    checkedHomebranches,
    targetMallCoords,
    crossFilteredData,
    crossFilteredIsLoading,
    checkedSMACTypes,
    smacMembers,
    mallCoords,
    mallNames,
    showSMACMembers,
    selectedBarangay,
    setSelectedBarangay,
    cityName,
    store,
}) {
    const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LVL);
    const mapRef = useRef(null)
    const disableComponent = false

    const [polygonMap, setPolygonMap] = useState({});

    const handleMouseEnter = (id) => {
        const polygon = polygonMap[id];
        if (polygon) {
            polygon.setStyle({ fillOpacity: 0.3 });
        }
    };
    const handleMouseLeave = (id) => {
        const polygon = polygonMap[id];
        if (polygon) {
            polygon.setStyle({ fillOpacity: 0.7 });
        }
    };

    useEffect(() => {
        const newPolygonMap = {};
        cityPopulation?.forEach((row, index) => {
            const polygon = document.getElementById(row.barangay_key);
            if (polygon) {
                newPolygonMap[row.barangay_key] = polygon;
            }
        });
        setPolygonMap(newPolygonMap);
    }, [cityPopulation]);

    useEffect(() => {
        const map = mapRef?.current;
        const updateZoomLevel = () => {
            if (map) {
                setZoomLevel(prevZoomLevel => map?.getZoom());
            }
        };
        if (map) {
            map?.addEventListener('zoomend', updateZoomLevel);
            return () => {
                map?.removeEventListener('zoomend', updateZoomLevel);
            };
        }
    }, [mapRef?.current]);
    
    useEffect(() => {
        if (selectedBarangay !== null) {
            const timeout = setTimeout(() => {
                setSelectedBarangay(null)
            }, 2000)
            
            return () => clearTimeout(timeout) 
        }
    }, [selectedBarangay, setSelectedBarangay]);

    useEffect(() => {
    }, [zoomLevel]);
    
    const foundBarangayPolygon = cityPopulation?.filter((row) => row.barangay_name === selectedBarangay)
    
    const selectedBarangayPolygon = (foundBarangayPolygon?.length > 0 && foundBarangayPolygon !== undefined) ? foundBarangayPolygon[0] : undefined;
    
    const filteredBarangays = cityPopulation?.filter((row) => row.polygon_color.toUpperCase() !== '#FFFFFF')

    const maxSMACPopulation = filteredBarangays?.reduce((max, currentSet) => {
        return Math.max(max, currentSet.smac_member_count);
    }, -Infinity);

    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseOver = () => {
        setShowTooltip(true);
    };

    const handleMouseOut = () => {
        setShowTooltip(false);
    };

    return (
        <MapContainer
            key={'MapContainer1'}
            center={[targetMallCoordinates.latitude, targetMallCoordinates.longitude]}
            position={'relative'}
            zoom={DEFAULT_ZOOM_LVL}
            style={{
                border: '1px solid lightgray',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                borderRadius: 10,
                display: 'flex',
                zIndex: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                height: '685px'
            }}
            maxZoom={16}
            minZoom={4}
            maxHeight={'685px'}
            ref={mapRef}
            zIndex={10}
        >
            <LayersControl key={'lc'} position="topright" style={{ alignItems: 'start' }}>
                <Pane name='LayersControlPane' style={{zIndex: 100}}>
                <LayersControl.BaseLayer key={'lc_osm'} name="OpenStreetMap">
                    <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer key={'lc_bingr'} name="Bing Maps Roads">
                    <BingLayer bingkey={bingKey} type="Road" />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer key={'lc_bings'} checked name="Bing Maps Satellite">
                    <BingLayer bingkey={bingKey} />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer key={'lc_bingsl'} name="Bing Maps Satellite with Labels">
                    <BingLayer bingkey={bingKey} type="AerialWithLabels" />
                </LayersControl.BaseLayer>
                </Pane>
            </LayersControl>

            {cityPopulation !== undefined &&
                <LegendControl
                cityPopulation={cityPopulation}
                cityName={cityName}
            />}

            {/* <FlowMapComponent map={mapRef.current}/> */}
            
            {/* Colored polygons */}
            <Pane name='cityPopulationPane' style={{zIndex: 210}}>
            {showCityPopulation && cityPopulation !== undefined && cityPopulation.map((row, index) => 
                (
                    <Polygon
                        key={`${index}_city_${row.polygon_color}`}
                        positions={[parsePolygon(row.geometry)]}
                        color={row.polygon_stroke}
                        fillOpacity={row.fillOpacity}
                        weight={row.weight}
                        fillColor={row.polygon_color}
                        eventHandlers={{
                            mouseover: () => handleMouseEnter(row.barangay_key),
                            mouseout: () => handleMouseLeave(row.barangay_key),
                        }}
                        id={row.barangay_key}
                        ref={(ref) => { if (ref) polygonMap[row.barangay_key] = ref; }}
                    >
                    </Polygon>
            ))}
            </Pane>

            {/* Polygon labels */}
            <Pane name='cityPopulationHoveringLabelsPane' style={{zIndex: 1500}}>
            {showCityPopulation && cityPopulation !== undefined && cityPopulation.map((row, index) => (
                <Polygon
                    key={`${index}cityhoverbrgy2`}
                    positions={[parsePolygon(row.geometry)]}
                    fillOpacity={0}
                    weight={0}
                >
                    {row.polygon_color.toUpperCase() !== '#FFFFFF' && zoomLevel>=15 &&
                        <Tooltip offset={L.point(0, 0)} permanent={true} direction='center' className='custom-tooltip' style={{zIndex: 2000}}>
                            <Typography color={row.polygon_color.toUpperCase() ==='#A0FF9A' ? 'black' : 'white'} variant='subtitle2'>{row.barangay_name}</Typography>
                        </Tooltip>
                }</Polygon>
            ))}
            </Pane>

            {/* Hovering (moving tooltip) polygon */}
            <Pane name='cityPopulationHoveringPane' style={{zIndex: 2000}}>
            {showCityPopulation && cityPopulation !== undefined && cityPopulation.map((row, index) => {
                const p6mData = (parseFloat(row['%P6M'])*100).toFixed(1)
                return (
                    <Polygon
                        key={`${index}cityhoverbrgy`}
                        positions={[parsePolygon(row.geometry)]}
                        fillOpacity={0}
                        weight={0}
                        eventHandlers={{
                            mouseover: () => handleMouseEnter(row.barangay_key),
                            mouseout: () => handleMouseLeave(row.barangay_key),
                        }}
                        id={row.barangay_key}
                    >
                        <Tooltip 
                            direction='right' 
                            sticky={true} 
                            offset={L.point(10, 0)}
                            style={{
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                        <div>
                            <Typography variant="h6">
                                {row.barangay_name}
                            </Typography>
                            <Typography variant="subtitle2">
                                {row.city_name}, {row.province_name}, {row.region_name}
                            </Typography>

                            <br />

                            <Typography variant="body2">
                                Barangay Population: {' '}
                                <NumericValueDisplay 
                                    value={row.population_projected_2024} 
                                    isAbbreviated={windowSize?.isOnMobile}
                                />
                            </Typography>

                            <Typography variant="body2" sx={{ color: '#0B7A00' }} >
                                { `SMAC Members: ${row.smac_member_count} (${(row.smac_penetration_rate * 100).toFixed(2)}%)` }
                            </Typography>
                            
                            <Typography variant="body2" sx={{ color: '#0077B6' }}>
                                P6M Active: { ` ${row.smac_active_p6m}` }
                            </Typography>

                            {/* Bar for P6M */}
                            <Typography variant="subtitle1" style={{ display: 'inline' }}></Typography>
                            <div style={{ width: '100%', height: 40, border: '1px solid #c9c9c9', position: 'relative', backgroundColor: '#c9c9c9' }}>
                                <div style={{ width: `${p6mData}%`, height: '100%', backgroundColor: '#0077B6', position: 'absolute', top: 0, left: 0 }} />
                                <div style={{ position: 'absolute', top: '50%', left: `${p6mData <= 25.99 ? '3%' : p6mData-24 + '%'}`, transform: 'translateY(-50%)', textAlign: 'left', color: 'white' }}>
                                    <Typography variant="subtitle1">{p6mData}%</Typography>
                                </div>
                            </div>
                        </div>
                        </Tooltip>
                    </Polygon>
                )})}
            </Pane>

            {selectedBarangayPolygon !== undefined && 
                <>
                    <SelectedBarangayPolygon selectedBarangayPolygon={selectedBarangayPolygon}/>
                </>
            }

            <Pane name='isochronesPane' style={{zIndex: 220}}>
            {checkedIsochrones?.length > 0 && isochroneTimes !== undefined && (
                <>
                {checkedIsochrones.map((time, timeIndex) => (
                    <Grid key={checkedHomebranches?.length > 0 ? `${timeIndex}_checkedHomebranch` : `${timeIndex}_checkedIsochrone`}>
                        {isochroneTimes
                            .filter((row) => row.store_name.toUpperCase()===store)
                            .filter((row) => checkedHomebranches?.length > 0 ? checkedHomebranches.some(mall => row.store_name === mall && row.travel_time === time) : row.travel_time === time)
                            .map((row, index) => (
                                <Polygon
                                    key={`${index}_${checkedHomebranches?.length > 0 ? row.store_name : time}_isochrone`}
                                    fillColor={row.polygon_color}
                                    fillOpacity={row.fillOpacity}
                                    positions={[parsePolygon(row.geometry)]}
                                    color={row.polygon_stroke}
                                    weight={4}
                                />
                            ))}
                    </Grid>
                ))}
                </>
            )}
            </Pane>

            {/* Init blue SM marker */}
            <Pane name='initSMMarkerPane' style={{zIndex: 2001}}>
            {typeof targetMallCoords !== 'undefined'
            && typeof targetMallCoords[0] !== 'undefined'
            && (checkedIsochrones?.length >= 0 && checkedHomebranches?.length >= 0)
            && targetMallCoords.map(
                (row, index) => (
                    <React.Fragment key={index}>
                        <CircleMarker
                            center={[row.latitude, row.longitude]}
                            radius={17}
                            fillColor="blue"
                            fillOpacity={1.0}
                            zIndexOffset={0}
                        />
                        <Marker
                            position={[row.latitude, row.longitude]}
                            zIndexOffset={1}
                        >
                            <Tooltip sticky offset={L.point(10, 0)}><Typography variant="subtitle2">{row.store_name}</Typography></Tooltip>
                        </Marker>
                    </React.Fragment>
                ),
            )}
            </Pane>

            {/* SMAC TYPES */}
            <Pane name='SMACTypesPane' style={{zIndex: 300}}>
            {disableComponent && ((checkedSMACTypes?.length > 0 && checkedHomebranches?.length >= 0)) && crossFilteredData && !crossFilteredIsLoading && filteredBarangays !== undefined && (
                <>
                    {checkedSMACTypes.map((type, clusterIndex) => (
                        filteredBarangays.map((brgyRow, index) => {
                            const filteredCrossFilteredData = crossFilteredData?.filter((row) =>
                                row.barangay_name === brgyRow.barangay_name &&
                                (
                                    (type === 'smac' && row.is_card_type_smac === 1) ||
                                    (type === 'prestige' && row.is_card_type_prestige === 1) ||
                                    (type === 'start' && row.is_card_type_start === 1)
                                )
                            );
                            
                            // const membersCrossFilteredData = crossFilteredData?.filter((row) =>
                            //     row.barangay_name === brgyRow.barangay_name &&
                            //     (
                            //         (type === row.smac_cdp_card_type)
                            //     )
                            // ); // substitute for filteredCrossFilteredData if member count is required

                            const radius = -0.01 + (clusterIndex / 10);

                            return (
                                <Pane key={index + "tooltipPane"} style={{ zIndex: 10000 }}>
                                <Pane style={{ zIndex: 1001 }}>
                                    <CircleMarkerSMACPopulation 
                                        key={index + "smactypeCircleMarkerKey" + type}
                                        index={index + "smactype" + type}
                                        cityPopulationRow={brgyRow} 
                                        filteredPopulation={filteredCrossFilteredData} 
                                        populationType={'SMAC Type'}
                                        markerCount={index}
                                        radius={radius}
                                        smacType={type}
                                        smacTypeSize={checkedSMACTypes?.length}
                                    />
                                </Pane>
                                </Pane>
                            );
                        })
                    ))}
                </>
            )}
            </Pane>


            {/* HOMEBRANCHES */}
            {disableComponent && (checkedHomebranches?.length > 0 && checkedSMACTypes?.length === 0) && crossFilteredData && !crossFilteredIsLoading && (
                <>
                    {checkedHomebranches.map((homebranch, homebranchIndex) => (
                        filteredBarangays.map((brgyRow, index) => {
                            const filteredCrossFilteredData = crossFilteredData?.filter((smacRow) => 
                                smacRow.barangay_name === brgyRow.barangay_name && smacRow.home_branch === homebranch
                            );

                            const radius = -0.01 + (homebranchIndex / 10);
                            return (
                                <CircleMarkerSMACPopulation 
                                    key={`${index}homebranch${homebranch}`}
                                    index={`${index}homebranch${homebranch}`}
                                    cityPopulationRow={brgyRow} 
                                    filteredPopulation={filteredCrossFilteredData} 
                                    populationType={'Homebranch'}
                                    customColor={mall_colors?.get(homebranch.toUpperCase())}
                                    radius={radius}
                                />
                            );
                        })
                    ))}
                </>
            )}


            {/* SMAC POPULATION */}
            <Pane name='SMACPopulationPane' style={{ zIndex: 300 }}>
                {disableComponent && showSMACMembers && smacMembers !== undefined && filteredBarangays !== undefined && (
                    filteredBarangays.map((brgyRow, index) => {

                        const filteredSMACMembers = smacMembers?.filter(
                            (smacRow) => smacRow.barangay_name === brgyRow.barangay_name
                        );
                        return (
                            <Pane key={index + "tooltipPane"} style={{ zIndex: 10000 }}>
                            <Pane style={{ zIndex: 1001 }}>
                                <CircleMarkerSMACPopulation
                                index={index + "smacpopulation"}
                                cityPopulationRow={brgyRow}
                                filteredPopulation={filteredSMACMembers}
                                populationType={'All SMAC'}
                                maxPopulation={maxSMACPopulation}
                                />
                            </Pane>
                            </Pane>
                        );
                    })
                )}
                </Pane>



            {disableComponent && mallCoords !== undefined && mallNames !== undefined && (checkedIsochrones?.length > 0 || checkedHomebranches?.length > 0) && (
                <>
                {checkedHomebranches?.length > 0
                        ? checkedHomebranches.map((homebranch, homebranchIndex) => (
                            mallCoords
                                .filter((row) => row.mall_name === homebranch)
                                .map((row, index) => {
                                    const uniqueKey = `${row.id}_${homebranchIndex}_${index}`;

                                    return (
                                        <React.Fragment key={uniqueKey}>
                                            <CircleMarker
                                                key={`${uniqueKey}circlemarker`}
                                                center={[row.latitude, row.longitude]}
                                                radius={20}
                                                color={row.color}
                                                fillColor="black"
                                                fillOpacity={1.0}
                                                weight={4}
                                            />
                                            <Marker position={[row.latitude, row.longitude]}>
                                                <Tooltip sticky>
                                                    <Typography variant="subtitle2">{row.mall_name}</Typography>
                                                </Tooltip>
                                            </Marker>
                                        </React.Fragment>
                                    );
                                })
                        ))
                        : mallNames.map((mall, mallIndex) => (
                            <Grid key={mallIndex}>
                                {mallCoords
                                    .filter((row) => row.mall_name === mall)
                                    .map((row, index) => (
                                        <div key={`${index}${row}_mall_container`}>
                                            <CircleMarker
                                                key={`${index}${row}_mall_circle`}
                                                center={[row.latitude, row.longitude]}
                                                radius={20}
                                                color={row.color}
                                                fillColor="black"
                                                fillOpacity={1.0}
                                                weight={4}
                                            />
                                            <Marker key={`${index}${row}_mall_marker`} position={[row.latitude, row.longitude]}>
                                                <Tooltip sticky>
                                                    <Typography variant="subtitle2">{row.mall_name}</Typography>
                                                </Tooltip>
                                            </Marker>
                                        </div>
                                    ))}
                            </Grid>
                        ))}
                </>
            )}

            <CustomMapEvents />
        </MapContainer>
    );
};