import React from 'react';
// import { useSalesOverallPerformanceData } from '../../../../TmpTSSSales/hooks';
import moment from "moment/moment";
import { TopLineContext } from '../../../contexts';
import sales from "../../../../../../img/sales.svg"
import transactions from '../../../../../../img/transactions.svg'
import basket_size from "../../../../../../img/basket_size.svg"
import units_sold from "../../../../../../img/units_sold.svg"
import unit_price from "../../../../../../img/unit_price.svg"
import space_productivity_nsa from "../../../../../../img/space_productivity_nsa.svg"
import units_sold_per_txn from "../../../../../../img/units_sold_per_txn.svg"
import smac_sales from "../../../../../../img/smac_sales.svg"
import { useOverallToplineDataL3, useOverallBasketSizeDataL3 } from '../../../../TmpTSSSales/DataProvider/hooks';

export default function BUToplineDataProvider({ params, children, period, business_unit }) {
    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';
    const paramsBasedOnPeriod = isQoQ
        ? {
            ...params,
            month: Array.from({ length: 3 }, (_, i) =>
                moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month() + i + 1
            )
        }
        : isMTD ? { ...params } : { ...params, month: [1, ...(params?.month || [])] };

    const mtdSalesDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_sales',
            pm_business_unit_desc_standardized: `${business_unit}`

        }
    });
    const mtdTransactionsDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_transactions',
            pm_business_unit_desc_standardized: `${business_unit}`
        }
    });
    const mtdBasketSizeDataHook = useOverallBasketSizeDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_basket_size',
            pm_business_unit_desc_standardized: `${business_unit}`
        }
    })
    const mtdSMACSalesDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_smac_sales',
            pm_business_unit_desc_standardized: `${business_unit}`
        }
    })
    const mtdSpaceProductivityNSADataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_space_nsa',
            pm_business_unit_desc_standardized: `${business_unit}`
        }
    })
    const mtdUnitsSoldPerTxnDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_units_sold_per_txn',
            pm_business_unit_desc_standardized: `${business_unit}`
        }
    })
    const mtdUnitPriceDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_units_price',
            pm_business_unit_desc_standardized: `${business_unit}`
        }
    })
    const mtdUnitsSoldDataHook = useOverallToplineDataL3({
        params: {
            ...paramsBasedOnPeriod,
            metric_column: 'actual_units_sold',
            pm_business_unit_desc_standardized: `${business_unit}`
        }
    })

    const transformTopLineData = (dataHookResponse, item) => {
        const {
            // basket_size
            actualBasketSizeTotal,
            targetBasketSize,
            vsSalesPlanBasketSize,
            vsLastYearTotalBasketSize,
            // other metric
            actualTotal,
            targetTotal,
            totalLastYear,
            isLoading,
        } = dataHookResponse;

        let paramsPeriod = moment().format('YYYY-MM-DD');
        if (params?.month?.[0] && params?.year) {
            paramsPeriod = `${params?.year}-${params?.month?.[0].toString().padStart(2, '0')}-01`;
        }

        const currentYearString = moment(paramsPeriod).format('YYYY');
        const currentMonthString = moment(paramsPeriod).format('MM');

        const minSelectedMonth = Math.min(...params?.month) || currentMonthString;
        const maxSelectedMonth = Math.max(...params?.month) || currentMonthString;

        const periodStartString = `${params?.year || currentYearString}-${minSelectedMonth.toString().padStart(2, '0')}-01`;
        const periodEndString = moment(`${params?.year || currentYearString}-${maxSelectedMonth.toString().padStart(2, '0')}-01`)
            .endOf('month').format('YYYY-MM-DD');

        let periodStartDate = moment(periodStartString).startOf('year').format('YYYY-MM-DD');
        let periodEndDate = moment(periodEndString).endOf('month').format('YYYY-MM-DD');
        if (isQoQ) {
            periodStartDate = moment(paramsPeriod, 'YYYY-MM-DD').startOf('quarter').format('YYYY-MM-DD');
            periodEndDate = moment(periodStartDate).endOf('quarter').format('YYYY-MM-DD');
        } else if (isMTD) {
            periodStartDate = moment(paramsPeriod).startOf('month').format('YYYY-MM-DD');
            periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
        }

        let primaryValue;

        switch (item) {
            case 'Sales':
            case 'Transactions':
            case 'Catchment Rate':
            case 'Conversion Rate':
            case 'Space Productivity NSA':
            case 'Space Productivity TFA':
            case 'SMAC Sales':
            case 'Units Sold Per Transaction':
            case 'Unit Price':
            case 'Units Sold':
                primaryValue = actualTotal;
                break;
            case 'Basket Size':
                primaryValue = actualBasketSizeTotal;
                break;
            default:
                primaryValue = null;
        }

        const allowedItemsWithPlans = ['sales', 'space_prod_nsa']
        const showPlan = false

        const targetValue = showPlan ?
            (item === 'basket_size' ? targetBasketSize : targetTotal) :
            undefined
        return {
            isLoading,
            periodFrom: periodStartDate,
            periodTo: periodEndDate,
            primaryValue: item === 'basket_size' ? actualBasketSizeTotal : actualTotal,
            targetValue: targetValue,
            comparisonValues: [
                showPlan ?
                    {
                        label: 'vs Plan',
                        value: item === 'basket_size' ? vsSalesPlanBasketSize : targetTotal,
                    } :
                    null,
                {
                    label: 'vs Last Year',
                    value: item === 'basket_size' ? vsLastYearTotalBasketSize : totalLastYear,
                },
            ].filter(Boolean),
        }
    }

    const planHeader = isQoQ ? 'QTR Plan' : isMTD ? 'MTD Plan' : 'YTD Plan';

    const items = [
        {
            ...transformTopLineData(mtdSalesDataHook, 'sales'),
            textMap: {
                imgIcon: sales,
                primaryLabel: 'Sales',
                targetLabel: null,
                isThousandsOnly: true,

            }
        },
        {
            ...transformTopLineData(mtdTransactionsDataHook, 'transactions'),
            textMap: {
                imgIcon: transactions,
                primaryLabel: 'Transactions',
                targetLabel: null,
                isThousandsOnly: true
            }
        },
        {
            ...transformTopLineData(mtdBasketSizeDataHook, 'basket_size'),
            textMap: {
                imgIcon: basket_size,
                primaryLabel: 'Basket Size',
                targetLabel: null,
                isThousandsOnly: true
            }
        },
        // TODO: add on when dynamic topline hooks is pushed to main
        {
            ...transformTopLineData(mtdSMACSalesDataHook, 'smac_sales'),
            textMap: {
                imgIcon: smac_sales,
                primaryLabel: 'SMAC Sales',
                targetLabel: null,
                isThousandsOnly: true
            }
        },
        {
            ...transformTopLineData(mtdSpaceProductivityNSADataHook, 'space_prod_nsa'),
            textMap: {
                imgIcon: space_productivity_nsa,
                primaryLabel: 'Space Productivity NSA',
                targetLabel: null,
                isThousandsOnly: true
            }
        },
        {
            ...transformTopLineData(mtdUnitsSoldDataHook, 'units_sold'),
            textMap: {
                imgIcon: units_sold,
                primaryLabel: 'Units Sold',
                targetLabel: null,
                isThousandsOnly: true
            }
        },
        {
            ...transformTopLineData(mtdUnitsSoldPerTxnDataHook, 'units_sold_per_txn'),
            textMap: {
                imgIcon: units_sold_per_txn,
                primaryLabel: 'Units Sold Per Transaction',
                targetLabel: null,
            }
        },
        {
            ...transformTopLineData(mtdUnitPriceDataHook, 'unit_price'),
            textMap: {
                imgIcon: unit_price,
                primaryLabel: 'Unit Price',
                targetLabel: null,
            }
        },
    ]

    return <TopLineContext.Provider value={{ items: items }}>
        {children}
    </TopLineContext.Provider>
}