import { useContext, useState, useEffect } from 'react';
import { WindowSizeContext } from '../../../common/context/WindowSizeContext';
import '../../../index.css'
import AsOfDate from '../Inventory/Filters/AsOfDate';
import { Grid, Typography, Box, GlobalStyles, } from '@mui/material';
import Back from '../../../common/components/BackNavigateComponent';
import InventoryTopline from './InventoryTopline';
import InventoryByGroup from './InventoryByGroup';
import InventoryStoreView from './InventoryStoreView';
import ToggleProvider from './provider/toggleProvider';
import FiltersProvider from './provider/filtersProvider';
import Filters from './Filters';
import InventoryInformationToolTip from './Filters/inventoryInformationTooltip';
import CategoryProvider from './provider/categoryProvider';

export default function Inventory() {
    
    const windowSize = useContext(WindowSizeContext)
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleTooltipChange = (value) => {
        setTooltipOpen(value);
    };

    useEffect(() => {
        const clearToolTipOnScroll = () => {
            // Close the tooltip when scrolling
            setTooltipOpen(false);
        };

        window.addEventListener('scroll', clearToolTipOnScroll);
        return () => {
            window.removeEventListener('scroll', clearToolTipOnScroll);
        };
    }, []);

    return <>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />
        <Grid container>
            <Grid item xs={12} marginY={1} display="flex" alignItems={'center'}>
                <Back />
                <Typography variant='h5' ml={3} >Markets Inventory Dashboard</Typography>
                <InventoryInformationToolTip onChange={handleTooltipChange} open={tooltipOpen} />
            </Grid>
            <FiltersProvider>
                <CategoryProvider>
                    <Grid container sx={{  }}>
                        <Box flexGrow={1} xs={12} paddingX={1} mb={1} sx={{position: `${!windowSize.isOnMobile ? 'sticky' : ''}`, top: `${!windowSize.isOnMobile ? '5rem' : ''}`, zIndex: 5, backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem" }}>
                            <Grid container columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1} paddingX={windowSize.isOnMobile && 1}>
                                {
                                    windowSize.isOnMobile && <Grid item xs={12}>
                                        <AsOfDate />
                                    </Grid>
                                }
                                <Filters />
                                {
                                    !windowSize.isOnMobile && <Grid item sm={4} alignItems="center" display="flex">
                                        <AsOfDate />
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                        <Grid xs={12}>
                            <InventoryTopline />
                            <ToggleProvider>
                                <Grid xs={12}>
                                    <InventoryByGroup />
                                </Grid>
                                <Grid xs={12}>
                                    <InventoryStoreView />
                                </Grid>
                            </ToggleProvider>
                        </Grid>
                    </Grid>
                </CategoryProvider>
            </FiltersProvider>
        </Grid >
    </>
}
