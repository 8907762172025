import React from "react";
import GraphFactory from "../../../../common/components/GraphFactory";
import {SEGMENT_COLOR_MAP, SEGMENT_THRESHOLDS_BY_COMPANY_ID, TABLE_MBA_SEGMENTS} from "../constants";


export default function TransactionXSalesXSegment({filters}) {
    const labelMap = {
        "1": "Key Value",
        "2": "Foreground",
        "3": "Background"
    }

    const markLine = SEGMENT_THRESHOLDS_BY_COMPANY_ID[filters.company_key] ? {
        symbol: "none",
        data: [{
            yAxis: SEGMENT_THRESHOLDS_BY_COMPANY_ID[filters.company_key].y,
            label: {
                formatter: function (params) {
                    return "₱" + params.value.toLocaleString()
                }
            }

        }, {
            xAxis: SEGMENT_THRESHOLDS_BY_COMPANY_ID[filters.company_key].x,
        }]
    } : {}

    const extraData = {
        "limit": 1000,
        "filters": [],
        "columns": [],
        "aggregates": [],
        "x_axis": {
            "label": "total_transactions_sum",
            "value": "total_transactions_sum",
            "get_param": "aggregates"
        },
        "y_axis": {
            "label": "total_sales_sum",
            "value": "total_sales_sum",
            "get_param": "aggregates"
        },
        "label": {"label": "merch_article_desc", "value": "merch_article_desc", "get_param": "columns"},
        "color_field": {"label": "product_segment_desc", "value": "product_segment_desc", "get_param": "columns"},
        "colorMap": SEGMENT_COLOR_MAP,
        "chartOptions": {
            xAxis: {
                name: "Total Transactions",
                nameLocation: "center",
                nameGap: 25,
                type: "log",
                axisTick: {
                    // show: false
                },
                axisLine: {
                    // show: false
                },
                splitLine: {
                    show: false
                }
            },
            yAxis: {
                name: "Total Sales",
                nameLocation: "center",
                nameGap: 55,
                type: "log",
                axisTick: {
                    // show: false
                },
                axisLine: {
                    // show: false
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    formatter: function (value, index) {
                        return "₱" + value.toLocaleString()
                    }
                }
            },
            series: {
                markLine: markLine
            },

            grid: {
                left: 70,
            },
            "tooltip": {
                formatter: (params, ticket) => {
                    const data = params.data;
                    const segmentName = labelMap.hasOwnProperty(data[3]) ? labelMap[data[3]] : data[3]  // use mapped segment name if available, otherwise use the raw value
                    const transactionCount = data[0] ? data[0].toLocaleString() : 0
                    const salesValue = data[1] ? ("₱"+ data[1].toLocaleString()) : "₱" +0
                    return `<strong>${data[2]}</strong><br/>Total Transactions: ${transactionCount}<br/>Total Sales: ${salesValue}<br/>Product Segment: ${segmentName}`
                }
            }
        },
        chartProps: {
            style: {"height": `25em`},
        }
    }
    

    return (<>
        {
           
            <GraphFactory
                filters={filters}
                chart_type={"scatter"}
                table_source={TABLE_MBA_SEGMENTS}
                extra_data={extraData}
                // title={"Number of Unique SKUs per Segment"}
            />
           
            
        }
    </>)
}