import CaretComponent from "./CaretComponent";

export default function CardComponent(props) {

    const ParentCard = <div className="card parent-card mb-3">
        <div className="card-header">
            <h4>{props.title}</h4>
            <div className=" position-absolute top-0 end-0 mt-2 me-4">
                { props.showCaret && <CaretComponent id={props.id}/> }
            
            </div>
        </div>
        <div className="collapse show" id={props.id}>
            <div className="card-body">
                {
                    props.children
                }
            </div>
        </div>
    </div>

    const SmallCard = <div className="card mb-3">
        <div className="card-header">
            <strong>{props.title}</strong>
            <div className=" position-absolute top-0 end-0 mt-2 me-4">
                { props.showCaret && <CaretComponent id={props.id} size="small"/> }
            </div>
        </div>
        <div className="collapse show" id={props.id}>
            <div className="card-body">
                {
                    props.children
                }
            </div>
        </div>
    </div>

    let cardToUse;
    switch (props.size) {
        case "small":
            cardToUse =  SmallCard
            break;
    
        default:
            cardToUse = ParentCard
            break;
    }

    return cardToUse;
}