import StoreAnalytics from "../../index"
import chart from "../../../../../img/sm-store-analytics-mockup.png"

export default function TheSmStoreAnalytics() {
    return(
        <StoreAnalytics
            salesApiEndPointName={"tss"}
            title={"SM Store"}
            locationApiEndPoint={'/custom/location/'}
        />
    )
}