import { Button } from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';


export default function Back() {
    const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}
    
    return  <Button variant="text" size="small" sx={{textTransform:"capitalize"}} startIcon={<ArrowLeftIcon />} onClick={()=>goBack()}> Back </Button>

}