
export default function ScaleRangeDisplay() {

    const data = ['Champions', 'Loyal', 'Potential Loyalist', 'Promising', 'New Customer', 'Need Attention', 'Cannot Lose Them', 'About to Sleep', 'At Risk', 'Hibernating', 'Lost']
    const colors = ['#2EB62C','#57C84D', '#83D475', '#ABE098', '#DADEDF', '#E97452', '#D65D42', '#C34632', '#B12E21', '#9E1711', '#8B0001']
    return <>
        <div className="row">
            {
                data.map((val,index) => {
                    return <div className="col px-1"  key={index}>
                        <div className="text-center h-100 py-3 d-flex align-items-center justify-content-center" style={{ backgroundColor: colors[index]}}><small><strong>{val}</strong></small></div>
                    </div>
                })
            }
        </div>

    </>
}
