import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from '@mui/material/ButtonGroup';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

export default function ActionButtons({toggleEdit, onSaveClicked, isEditing, isEditable}) {
    if (!isEditable) {
        return null  // TODO: add actions for clone/save as for non editable dashboards
    }

    if (!isEditing) {
        // return <button className={"btn btn-link"} onClick={toggleEdit}>
        //     <i className={"bi bi-pencil-square"}/> Edit Dashboard
        // </button>
        return <Button variant="outlined" onClick={toggleEdit} startIcon={<EditIcon />}> Edit Dashboard </Button>
    }

    return (
        // <div className="btn-group">
        //     {/* cancel button */}
        //     <button className={"btn btn-outline-primary"} onClick={toggleEdit}>
        //         <i className={"bi bi-x-lg"}/> Cancel
        //     </button>

        //     <button className={"btn btn-primary"} onClick={onSaveClicked}>
        //         <i className={"bi bi-save"}/> Save Changes
        //     </button>
        // </div>

        <ButtonGroup>
            <Button variant="outlined" onClick={toggleEdit} startIcon={<CloseIcon />}>Cancel</Button>
            <Button variant="contained"  onClick={onSaveClicked} startIcon={<SaveIcon />}>Save Changes</Button>
        </ButtonGroup>
    )


}