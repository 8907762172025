
export function tooltipData(data,firstKey, blacklistedKeys) {
    return Object.entries(data).map(([key,value]) => {
        if(!blacklistedKeys.includes(key) && !key.startsWith('_') ){
            const displayKeyReplace = key.replace(/([A-Z])/g, " $1").replace(/([a-z])([0-9])/g, "$1 $2");
            const displayKey = displayKeyReplace.charAt(0).toUpperCase() + displayKeyReplace.slice(1);

            return <div key={key} className={`px-2 fs-5 ${firstKey === key ? 'fw-bold': ''}`}>
                    <span className="text-capitalize text-black-50">{displayKey}</span>:{" "}
                    {typeof value == "number" ? value.toLocaleString() : value}
                 </div>
        }
    })
}