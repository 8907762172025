import React from 'react';
import { useSelectorContext, useSelectorValue } from '../../common/providers/selectorProvider';
import FilterYear from '../../../../common/components/FilterYear';
import moment from 'moment';


export default function YearSelector({isLoadingDateRange, dateRange}) {
    const yearFromContext = useSelectorValue('year');
    const year = yearFromContext
    const {setValue: setSelectorValue} = useSelectorContext()
    const setYear = (value) => setSelectorValue('year', value)
    //defaults
    React.useEffect(() => {
        if (!yearFromContext) {
            setYear(moment(dateRange?.to).year())
        }
    }, [yearFromContext,dateRange]);

    return (
        <FilterYear
            onDropdownChange={(value) => setYear(value)}
            filters={{year}}
            dateRangeData={dateRange}
            isLoading={isLoadingDateRange}
        />
    )
}