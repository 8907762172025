import React from "react";

export default function Response({children: message}) {
    return (


        <div className="balon2 p-2 m-0 clearfix" data-is="Yusuf - 3:26 pm">

            <a className="float-start sohbet2"> {message}</a>

        </div>
    )
}