import moment from "moment";
import { abbreviateNumber, displayNumber } from "../../../common/utils";
import { PERCENT_TO_SALES_MULTIPLIER } from "./constants";

export const monthYearToYearMonthDate = (months, year, dayOfMonthMaxDate) => {
    if (!months || months.length === 0 || !year) {
        return {}
    }
    // months is an array of numbers, 1=January, 2=February, etc.
    const minMonth = Math.min(...months)
    const maxMonth = Math.max(...months)

    const startDate = new Date(year, minMonth - 1, 1)  // since minMonth is 1-based, we need to subtract 1 from month to get the first day of the month
    const endDate = dayOfMonthMaxDate ?
        new Date(year, maxMonth - 1, dayOfMonthMaxDate) :  // since maxMonth is 1-based, we need to subtract 1 from month to get the last day of the month
        new Date(year, maxMonth, 0);

    const yearMonthFilters = {
        "year_month__gte": moment(startDate).format("YYYY-MM"),
        "year_month__lte": moment(endDate).format("YYYY-MM")
    };

    return yearMonthFilters;
}

export const convertParamsToYearMonthDate = (params, dayOfMonthMaxDate) => {
    // params can NOT be edited in-line of function call
    return {
        ...params,
        ...monthYearToYearMonthDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const convertStoreParamsToYearMonthDate = ({ params, dayOfMonthMaxDate }) => {
    // params CAN be edited in-line of function call
    // Store View params involve inserting updated params values, hence the need for {} in function parameters
    return {
        ...params,
        ...monthYearToYearMonthDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const preProcessParams = (params) => {
    const newParams = {
        ...params,
    }

    // delete "month" and "year" parameters if they exist
    // because we don't want to send them to the backend,
    // we want to send "year_month__gte" and "year_month__lte" instead
    if (newParams.month) {
        delete newParams.month
    }
    if (newParams.year) {
        delete newParams.year
    }

    // remove empty arrays or empty strings or null values
    Object.keys(newParams).forEach(key => {
        if (Array.isArray(newParams[key]) && newParams[key].length === 0) {
            delete newParams[key]
        }
        if (newParams[key] === "") {
            delete newParams[key]
        }
        if (newParams[key] === null) {
            delete newParams[key]
        }
    });

    return newParams
}


export const regroupDataBy = (data, groupBy, additionalGroupBy, values) => {
    if (!data || Object.keys(data).length === 0) {
        return {};
    }

    return data.reduce((acc, curr) => {
        const key = curr[groupBy];
        const additionalKey = curr[additionalGroupBy];

        if (!acc[key]) {
            acc[key] = {};
        }

        if (!acc[key][additionalKey]) {
            acc[key][additionalKey] = {};
            values.forEach(value => {
                acc[key][additionalKey][value] = 0; // Initialize each value as 0 for summation
            });
        }

        values.forEach(value => {
            acc[key][additionalKey][value] += curr[value]; // Sum up each value
        });

        return acc;
    }, {});
};

export const regroupDataByPerGroup = (data, groupBy, values) => {
    if (!data || Object.keys(data).length === 0) {
        return {};
    }

    return data.reduce((acc, curr) => {
        const key = curr[groupBy];

        if (!acc[key]) {
            acc[key] = {};
        }

        // Loop through operation types
        for (const opexType in curr.opex_values) {
            if (opexType !== 'NOI') {
                if (!acc[key][opexType]) {
                    acc[key][opexType] = {};
                    values.forEach(value => {
                        acc[key][opexType][value] = 0; // Initialize each value as 0 for summation
                    });
                }

                // Sum up each value
                values.forEach(value => {
                    acc[key][opexType][value] += curr.opex_values[opexType][value] || 0;
                });
            }
        }

        return acc;
    }, {});
};

export const regroupDataByNOI = (data, groupBy, values) => {
    if (!data || Object.keys(data).length === 0) {
        return {};
    }

    return data.reduce((acc, curr) => {
        const key = curr[groupBy];

        if (!acc[key]) {
            acc[key] = {};
        }

        values.forEach(value => {
            acc[key][value] = curr[value]; // Set the value for each property separately
        });

        return acc;
    }, {});
};

export function computeSum(data, categories) {
    return categories.reduce((sum, category) => sum + (data[category] || 0), 0);
}

export function getCategoryColor(category) {
    const colorMap = {
        'Controllable': '#405e9a',
        'Non-controllable': '#40906b',
        'Salaries & Wages': '#4f85e6',
        'Light & Water': '#84bff7',
        'Security': '#afcdfc',
        'Repairs & Maintenance': '#a2b2d2',
        'Other Controllable Expense': '#7e95c7',
        'Rent': '#a3d6ad',
        'Other Non-Controllable Expense': '#52b888',
    };

    return colorMap[category];
}

export function pieChartData(chartData, isControllable, isNoncontrollable) {

    const controllableSum = computeSum(chartData, [
        'Salaries & Wages',
        'Light & Water',
        'Security',
        'Repairs & Maintenance',
        'Other Controllable Expense',
    ]);

    const nonControllableSum = computeSum(chartData, [
        'Rent',
        'Other Non-Controllable Expense'
    ]);

    const categoriesData = (isControllable, isNoncontrallable) => {
        if (isControllable) {
            return [
                { value: controllableSum, name: 'Controllable', selected: isControllable, itemStyle: { color: getCategoryColor('Controllable') } },
            ];
        }
        else if (isNoncontrollable) {
            return [
                { value: nonControllableSum, name: 'Non-controllable', selected: isNoncontrallable, itemStyle: { color: getCategoryColor('Non-controllable') } },
            ];
        }
        return [
            { value: controllableSum, name: 'Controllable', selected: isControllable, itemStyle: { color: '#405e9a' } },
            { value: nonControllableSum, name: 'Non-controllable', selected: isNoncontrallable, itemStyle: { color: '#40906b' } },
        ];
    }

    const option = {
        animation: false,
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                return `${params.name}: ${'₱' + displayNumber(params.value)}`;
            },
        },

        grid: {
            top: '50%', // Adjust this value to add space
        },
        series: [
            {
                type: 'pie',
                selectedMode: false,
                radius: ['10%', '90%'],
                center: ["50%", "50%"],
                label: {
                    show: true,
                    position: 'inner',
                    fontSize: 12,
                    formatter: function (params) {
                        return `${params.percent.toFixed(0)}%\n(${abbreviateNumber(params.value)})`; // Total Value
                    },
                },
                data: categoriesData(isControllable, isNoncontrollable),
            },

        ],
    };

    return option;
}

export function controllableBarChartData(chartData, isOnMobile) {
    const controllableSum = chartData['Total Controllable Expense']

    const controllable = ["Light & Water", "Repairs & Maintenance", "Other Controllable", "Salaries & Wages"]
    const commonLabelStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 11,
        color: '#000',
    }
    const barOptions = {
        animation: false,
        tooltip: {
            trigger: 'item',
            position: isOnMobile ? 'right' : 'left',
            formatter: function (params) {
                return (controllable.includes(params.name) && isOnMobile) ? `${params.name}:<br>₱${displayNumber(params.value * chartData['Total Sales'])}` : `${params.name}: ₱${displayNumber(params.value * chartData['Total Sales'])}`

            },
        },
        grid: {
            width: isOnMobile ? '65em' : '70em', // Adjust as needed
            left: isOnMobile ? '55%' : '77%',
            top: 10,       // Set top margin to 0
            bottom: 5,    // Set bottom margin to 0
        },
        yAxis: {
            ...chartData.yAxis, // Keep existing yAxis options
            type: "value",
            show: false,
            // Set min and max to ensure the same y-axis scale for both charts
            min: 0,
            max: Math.max(controllableSum, chartData['Rent'], chartData['Other Non-Controllable Expense']),
        },

        xAxis: {
            ...chartData.xAxis, // Add this line to include existing xAxis options
            type: "category",
            show: false,
        },

        series: [
            {
                animation: false,
                name: "Salaries and Wages",
                type: "bar",
                stack: "total",
                label: {
                    show: true,
                    position: 'insideRight',
                    formatter: (params) => {
                        const percentage = chartData['Salaries & Wages'];
                        return isOnMobile ? `{nameStyle1|Salaries &}\n{nameStyle|Wages}{valueStyle|${(percentage * 100).toFixed(2)}%}` : `{nameStyle|Salaries & Wages}{valueStyle|${(percentage * 100).toFixed(2)}%}`
                    },
                    rich: {
                        nameStyle: {
                            ...commonLabelStyle,
                            padding: [0, isOnMobile ? 40 : 10, 0, 0]
                        },
                        valueStyle: {
                            ...commonLabelStyle,
                            padding: [0, 0, 0, 10],
                            fontWeight: 'bold'
                        },
                        nameStyle1: {
                            ...commonLabelStyle,
                            padding: [0, 40, 0, 0]
                        }
                    }
                },
                emphasis: {
                    focus: "series"
                },
                data: [{ value: chartData['Salaries & Wages'], name: 'Salaries & Wages', itemStyle: { color: '#4f85e6' } }],
                itemStyle: { color: '#4f85e6' }
            },
            {
                animation: false,
                name: "Light and Water",
                type: "bar",
                stack: "total",
                label: {
                    show: true,
                    position: 'insideRight',
                    formatter: (params) => {
                        const percentage = chartData['Light & Water'];
                        return isOnMobile ? `{nameStyle1|Light &}\n{nameStyle|Water}{valueStyle|${(percentage * 100).toFixed(2)}%}` : `{nameStyle|Light & Water}{valueStyle|${(percentage * 100).toFixed(2)}%}`
                    },
                    rich: {
                        nameStyle: {
                            ...commonLabelStyle,
                            padding: [0, isOnMobile ? 40 : 10, 0, 0]
                        },
                        valueStyle: {
                            ...commonLabelStyle,
                            padding: [0, 0, 0, 10],
                            fontWeight: 'bold'
                        },
                        nameStyle1: {
                            ...commonLabelStyle,
                            padding: [0, 40, 0, 0]
                        }
                    }
                },
                emphasis: {
                    focus: "series"
                },
                data: [{ value: chartData['Light & Water'], name: 'Light & Water', itemStyle: { color: '#84bff7' } }],
                itemStyle: { color: '#84bff7' }
            },
            {
                animation: false,
                name: "Security",
                type: "bar",
                stack: "total",
                label: {
                    show: true,
                    position: 'insideRight',
                    formatter: (params) => {
                        const percentage = chartData['Security'];
                        return `{nameStyle|Security}{valueStyle|${(percentage * 100).toFixed(2)}%}`
                    },
                    rich: {
                        nameStyle: {
                            ...commonLabelStyle,
                            padding: [0, isOnMobile ? 36 : 10, 0, 0]
                        },
                        valueStyle: {
                            ...commonLabelStyle,
                            padding: [0, 0, 0, 10],
                            fontWeight: 'bold'
                        }
                    }
                },
                emphasis: {
                    focus: "series"
                },
                data: [{ value: chartData['Security'], name: 'Security', itemStyle: { color: '#afcdfc' } },],
                itemStyle: { color: '#afcdfc' }
            },
            {
                animation: false,
                name: "Repairs and Maintenance",
                type: "bar",
                stack: "total",
                label: {
                    show: true,
                    position: 'insideRight',
                    formatter: (params) => {
                        const percentage = chartData['Repairs & Maintenance'];
                        return isOnMobile ? `{nameStyle1|Repairs &}\n{nameStyle|Maintenance}{valueStyle|${(percentage * 100).toFixed(2)}%}` : `{nameStyle|Repairs & Maintenance}{valueStyle|${(percentage * 100).toFixed(2)}%}`
                    },
                    rich: {
                        nameStyle: {
                            ...commonLabelStyle,
                            padding: [0, 10, 0, 0]
                        },
                        valueStyle: {
                            ...commonLabelStyle,
                            padding: [0, 0, 0, isOnMobile ? 10 : 10],
                            fontWeight: 'bold'
                        },
                        nameStyle1: {
                            ...commonLabelStyle,
                            padding: [0, 45, 0, 0]
                        }
                    }
                },
                emphasis: {
                    focus: "series"
                },
                data: [{ value: chartData['Repairs & Maintenance'], name: 'Repairs & Maintenance', itemStyle: { color: '#a2b2d2' } }],
                itemStyle: { color: '#a2b2d2' }
            },
            {
                animation: false,
                name: "Other Controllable",
                type: "bar",
                stack: "total",
                label: {
                    show: true,
                    position: 'insideRight',
                    formatter: (params) => {
                        const percentage = chartData['Other Controllable Expense'];
                        return isOnMobile ? `{nameStyle1|Other}\n{nameStyle|Controllable}{valueStyle|${(percentage * 100).toFixed(2)}%}` : `{nameStyle|Other Controllable}{valueStyle|${(percentage * 100).toFixed(2)}%}`
                    },
                    rich: {
                        nameStyle: {
                            ...commonLabelStyle,
                            padding: [0, 10, 0, 0]
                        },
                        valueStyle: {
                            ...commonLabelStyle,
                            padding: [0, 0, 0, 10],
                            fontWeight: 'bold'
                        },
                        nameStyle1: {
                            ...commonLabelStyle,
                            padding: [0, 45, 0, 0]
                        }
                    }
                },
                emphasis: {
                    focus: "series"
                },
                data: [{ value: chartData['Other Controllable Expense'], name: 'Other Controllable', itemStyle: { color: '#7e95c7' } }],
                itemStyle: { color: '#7e95c7' }
            },
        ]
    };

    return barOptions;
}

export function NonControllableBarChartData(chartData, isOnMobile) {
    const nonControllableSum = chartData['Total Non-Controllable Expense']

    const commonLabelStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 11,
        color: '#000',
    }

    const barOptions = {
        animation: false,
        tooltip: {
            trigger: 'item',
            position: 'left',
            formatter: function (params) {
                return (params.name === 'Other Non-controllable' && isOnMobile) ? `${params.name}:<br>₱${displayNumber(params.value * chartData['Total Sales'])}` :
                    `${params.name}: ₱${displayNumber(params.value * chartData['Total Sales'])}`
            },
        },
        grid: {
            width: isOnMobile ? '65em' : '70em', // Adjust as needed
            left: isOnMobile ? '0%' : '4%',
            top: 10,       // Set top margin to 0
            bottom: 5,    // Set bottom margin to 0
        },
        yAxis: {
            ...chartData.yAxis, // Keep existing yAxis options
            type: "value",
            show: false,
            // Set min and max to ensure the same y-axis scale for both charts
            min: 0,
            max: Math.max(nonControllableSum, chartData['Rent'], chartData['Other Non-Controllable Expense']),
        },

        xAxis: {
            ...chartData.xAxis, // Add this line to include existing xAxis options
            type: "category",
            show: false,
        },
        series: [
            {
                animation: false,
                name: "Rent",
                type: "bar",
                stack: "total",
                label: {
                    show: true,
                    position: 'insideLeft',
                    formatter: (params) => {
                        const percentage = chartData['Rent'];
                        return `{valueStyle|${(percentage * 100).toFixed(2)}%}{nameStyle|Rent}`
                    },
                    rich: {
                        valueStyle: {
                            ...commonLabelStyle,
                            padding: [0, isOnMobile ? 10 : 0, 0, 0],
                            fontWeight: 'bold'
                        },
                        nameStyle: {
                            ...commonLabelStyle,
                            padding: [0, 0, 0, isOnMobile ? 0 : 15]
                        }
                    }
                },
                emphasis: {
                    focus: "series"
                },
                data: [{ value: chartData['Rent'], name: 'Rent', itemStyle: { color: '#a3d6ad' } }],
                itemStyle: { color: '#a3d6ad' }

            },
            {
                animation: false,
                name: "Other Non-Controllable",
                type: "bar",
                stack: "total",
                label: {
                    show: true,
                    position: 'insideLeft',
                    formatter: (params) => {
                        const percentage = chartData['Other Non-Controllable Expense'];
                        return isOnMobile ? `{nameStyle1|Other}\n{valueStyle|${(percentage * 100).toFixed(2)}%}{nameStyle|Non-}\n{nameStyle1|Controllable}` : `{valueStyle|${(percentage * 100).toFixed(2)}%}{nameStyle|Other Non-Controllable}`
                    },
                    rich: {
                        valueStyle: {
                            ...commonLabelStyle,
                            padding: [0, isOnMobile ? 10 : 0, 0, 0],
                            fontWeight: 'bold'
                        },
                        nameStyle: {
                            ...commonLabelStyle,
                            padding: [0, 0, 0, isOnMobile ? 0 : 15]
                        },
                        nameStyle1: {
                            ...commonLabelStyle,
                            padding: [0, 0, 0, 45]
                        }
                    }
                },
                emphasis: {
                    focus: "series"
                },
                data: [{ value: chartData['Other Non-Controllable Expense'], name: 'Other Non-controllable', itemStyle: { color: '#52b888' } }],
                itemStyle: { color: '#52b888' }
            },

        ]
    };

    return barOptions;
}

export function joinOpexAndBranchData(opexData, branchData) {
    // Create a lookup table using branch_id
    const branchLookup = {};
    branchData?.forEach((branch) => {
        branchLookup[branch.branch_id] = branch;
    });

    // Join the data
    const joinedData = opexData?.map((opexItem) => {
        const branchInfo = branchLookup[opexItem.branch_id];
        if (branchInfo) {
            // Combine opexItem and branchInfo
            return { ...opexItem, ...branchInfo };
        }
        // If branch_id is not found in branchData, return opexItem as is
        return opexItem;
    });

    return joinedData;
}

export const mergeLastYearOpex = (currentDataGroupedByRegion, lastYearDataGroupedByRegion, groupingLevel) => {
    const mergedData = { ...currentDataGroupedByRegion };

    Object.keys(currentDataGroupedByRegion).forEach((groupKey) => {
        const groupData = currentDataGroupedByRegion[groupKey];

        Object.keys(groupData)?.forEach((category) => {
            const properties = groupData[category];

            if (lastYearDataGroupedByRegion[groupKey] && lastYearDataGroupedByRegion[groupKey][category]) {
                const lastYearOpex = lastYearDataGroupedByRegion[groupKey][category]['opex_value_sum'];

                if (properties['opex_value_sum']) {
                    if (!groupingLevel) {
                        properties[`last_year_opex_value_sum_${groupingLevel}`] = lastYearOpex;
                    } else {
                        properties[`last_year_opex_value_sum`] = lastYearOpex;
                    }
                }
            }
        });
    });

    return mergedData;
};

export function appendGroupedActualSalesToOpex(actualSales, dataByGroup, grouping) {
    // Create a map for faster lookup of actual sales by group
    const actualSalesMap = new Map();
    actualSales.forEach(item => {
        actualSalesMap.set(item[grouping], item.actual_sales);
    });

    // Loop through each group in dataByGroup
    for (const group in dataByGroup) {
        const groupData = dataByGroup[group];

        // Check if actual sales data is available for the group
        const actualSalesValue = actualSalesMap.get(group);

        // If actual sales data is available, update actual_sales in each operation type
        if (actualSalesValue !== undefined) {
            for (const operationType in groupData) {
                const opexData = groupData[operationType];
                opexData.actual_sales = actualSalesValue;
            }
        } else {
            // If actual sales data is not available, handle null case
            if (group === 'null') {
                for (const operationType in groupData) {
                    const opexData = groupData[operationType];
                    opexData.actual_sales = actualSalesMap.get(null) || 0;
                }
            }
        }
    }

    return dataByGroup;
}

export function calculateDerivedValuesPerGroup(data, groupingLevel) {
    const result = {};
    Object.keys(data).forEach((category) => {
        const categoryData = data[category];
        Object.keys(categoryData).forEach((expenseType) => {
            const expenseData = categoryData[expenseType];
            const vsLastYear = (expenseData.opex_value_sum / expenseData[`last_year_opex_value_sum`]) * 100;
            const vsBudget = (expenseData.opex_value_sum / expenseData.budget_sum) * 100;
            const percentToSales = (expenseData.opex_value_sum / (expenseData.actual_sales * PERCENT_TO_SALES_MULTIPLIER)) * 100; // update new formula

            const income = expenseData.opex_value_sum > expenseData.actual_sales ? "+" : "-";
            result[category] = result[category] || {};
            result[category][expenseType] = {
                ...expenseData,
                vs_last_year: vsLastYear,
                vs_budget: vsBudget,
                percent_to_sales: percentToSales,
                income: income,
            };
        });
    });
    return result;
}

//  Left joins current year OPEX data with last year OPEX data of each branch_id(store)
export function joinCurrentAndLastYearOpexPerStore(currentYearData, lastYearData) {
    const joinedData = {};

    for (const branchId in currentYearData) {
        if (lastYearData[branchId]) {
            joinedData[branchId] = { ...currentYearData[branchId] };

            // Rename last year's opex_value_sum to last_year_opex_value_sum
            for (const expenseCategory in joinedData[branchId]) {
                if (joinedData[branchId][expenseCategory].hasOwnProperty('opex_value_sum')) {
                    joinedData[branchId][expenseCategory].last_year_opex_value_sum =
                        lastYearData[branchId][expenseCategory]?.opex_value_sum || 0;
                }
            }
        } else {
            joinedData[branchId] = { ...currentYearData[branchId] };
        }
    }

    return joinedData;
}

export function mergeOpexDataByOperationType(target, source, operationType) {
    // const listOfOperationTypes = ["Total Operating Expense", "Total Controllable Expense", "Total Non-Controllable Expense", "Light & Water", "Salaries & Wages", "Security", "Repairs & Maintenance", "Other Controllable Expense","Rent", "Other Non-Controllable Expense"]
    const mergedData = { ...target }; // Start with a copy of the target data

    // Loop through all branch IDs
    for (const branchId in target) {
        if (source[branchId]) { // Check if source has data for this branch
            const additionalDataForBranch = source[branchId];

            // Check if the desired operation type exists in the source
            if (additionalDataForBranch && additionalDataForBranch[operationType]) {
                mergedData[branchId][operationType] = additionalDataForBranch[operationType]; // Add the operation type data from source
            }
        }
    }
    return mergedData;
}

export function deriveOpexPerStoreValues(data) {
    return data.map(item => {
        const opexValues = item.opex_values;

        // Iterate through each expense category
        for (const expenseCategory in opexValues) {
            if (expenseCategory === 'remarks' || expenseCategory === 'ytd_remarks') {
                continue; // Skip processing for 'NOI'
            }
            const expense = opexValues[expenseCategory];

            // Derive 'vs_budget'
            expense.vs_budget = expense.opex_value_sum / expense.budget_sum * 100;

            // Derive 'vs_last_year'
            expense.vs_last_year =
                expense.last_year_opex_value_sum !== 0
                    ? expense.opex_value_sum / expense.last_year_opex_value_sum * 100
                    : null;

            // Derive 'income'
            expense.income =
                item.actual_sales !== null
                    ? item.actual_sales < expense.opex_value_sum
                        ? '-'
                        : '+'
                    : null;

            // Derive 'percent_to_sales'
            if (expense.opex_value_sum !== undefined && item.actual_sales !== null && item.actual_sales !== 0) {
                expense.percent_to_sales = (expense.opex_value_sum / (item.actual_sales * PERCENT_TO_SALES_MULTIPLIER)) * 100; // update new formula
            } else {
                expense.percent_to_sales = null;
            }

            // Derive 'opex_per_sqm'
            if (expense.opex_value_sum !== undefined && item.selling_floor !== null && item.selling_floor !== 0) {
                expense.opex_per_sqm = (expense.opex_value_sum / item.selling_floor) * 100;
            } else {
                expense.opex_per_sqm = null;
            }
        }

        return item;
    });
}

export function mergeOpexDataToStores(salesData, opexData) {
    // sales data left joined to opex data (to include nested opex values breakdown)
    const opexMap = {};
    Object.keys(opexData).forEach((branchId) => {
        opexMap[branchId] = opexData[branchId];
    });

    // Merge opex data into salesData
    const mergedData = salesData.map((salesItem) => {
        const branchId = salesItem.branch_id;

        if (opexMap[branchId]) {
            return {
                ...salesItem,
                opex_values: opexMap[branchId],
            };
        }

        return salesItem;
    });

    return mergedData;
}

export function pieChartPerGroupData(chartData, label) { // for generating the uniform looks (options) of the pie charts in OPEX Per Group

    const controllableSum = computeSum(chartData, [
        'Salaries & Wages',
        'Light & Water',
        'Security',
        'Repairs & Maintenance',
        'Other Controllable Expense',
    ]);

    const nonControllableSum = computeSum(chartData, [
        'Rent',
        'Other Non-Controllable Expense'
    ]);

    const categoriesData = () => {


        return [
            { value: controllableSum, name: 'Controllable', selected: false, itemStyle: { color: '#405e9a' } },
            { value: nonControllableSum, name: 'Non-controllable', selected: false, itemStyle: { color: '#40906b' } },
        ];
    }

    const subCategoriesData = () => {

        return [
            { value: chartData['Salaries & Wages'], name: 'Salaries & Wages', itemStyle: { color: '#4f85e6' } },
            { value: chartData['Light & Water'], name: 'Light & Water', itemStyle: { color: '#84bff7' } },
            { value: chartData['Security'], name: 'Security', itemStyle: { color: '#afcdfc' } },
            { value: chartData['Repairs & Maintenance'], name: 'Repairs & Maintenance', itemStyle: { color: '#a2b2d2' } },
            { value: chartData['Other Controllable Expense'], name: 'Other Controllable', itemStyle: { color: '#7e95c7' } },
            { value: chartData['Rent'], name: 'Rent', itemStyle: { color: '#a3d6ad' } },
            { value: chartData['Other Non-Controllable Expense'], name: 'Other Non-controllable', itemStyle: { color: '#52b888' } },
        ];
    };

    const option = {
        title: {
            text: label,
            left: 'center',
        },
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)'
        },
        grid: {
            top: '50%', // Adjust this value to add space
        },
        series: [
            {
                type: 'pie',
                selectedMode: false,
                radius: ['10%', '45%'],
                label: {
                    show: true,
                    position: 'inner',
                    fontSize: 12,
                    formatter: '{d}%', // Use '{d}%' to display only the percentage
                },
                data: categoriesData(),
            },
            {
                type: 'pie',
                radius: ['48%', '80%'],
                label: {
                    show: true,
                    position: 'inner',
                    fontSize: 12,
                    formatter: '{d}%', // Use '{d}%' to display only the percentage
                    color: 'white',
                },
                labelLine: {
                    length: 30
                },
                data: subCategoriesData(),
            }
        ],
    };

    return option;
}

export function generateGraphData(opexData) {
    // Transform the fetched data into the format expected by the pie chart
    const graphData = {};

    opexData?.forEach((item) => {
        const operationType = item?.operation_type.toString();
        const opexValue = item?.opex_current;
        const salesValue = item?.actual_sales;
        const percentToSales = opexValue / (salesValue * PERCENT_TO_SALES_MULTIPLIER); // update new formula
        graphData[operationType] = (graphData[operationType] || 0) + percentToSales;
    });

    // Manually add 'Total Sales' with the value of 'actual_sales'
    const totalSalesValue = opexData.reduce((total, item) => {
        const salesValue = item.actual_sales;
        return salesValue;
    }, 0);
    graphData['Total Sales'] = totalSalesValue;

    return graphData;
};