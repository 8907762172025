import React, { useContext, useMemo} from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useAllAvailableBusinessUnits } from '../hooks';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { getSelectedOption } from '../../../../common/utils';
import { useFiltersContext } from '../Provider/context';

export default function FilterConsent() {
    const windowSize = useContext(WindowSizeContext);
    const { filters, setFilter } = useFiltersContext();
    
    const options = [
        {
            value: true,
            label: "With Consent",
        },
        {
            value: false,
            label: "Without Consent",
        }
    ]

    return (
        <ConditionalSelectComponent
            id="smac_consent_filter"
            labelId="smac_consent_filter-label"
            options={options}
            label="Select Consent"
            value={filters ? (windowSize.isOnMobile ? filters?.smac_consent : getSelectedOption(filters?.smac_consent, options)) : '' &&  null}
            onSelectChange={(event,newValue)=>{
                windowSize.isOnMobile ? setFilter('smac_consent', event.target.value)
                : setFilter('smac_consent', newValue?.value)  
            }}
            endAdornment={true}
            isLoading={false}
            clearSelected={() => setFilter('smac_consent', '')}
            disableClearable={false}
    />
    );
}