import React from "react";
import Modal from "@mui/material/Modal";
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { Typography } from "@mui/material";
import {useFetchAll} from "../../API/hooks";
import ApiClient from "../../API";
import {CSVLink} from "react-csv";
import PropTypes  from "prop-types";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// const customModalStyles = {
//     overlay: {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         zIndex: 99
//     },
//     content: {
//         width: '35rem',
//         position: 'center',
//         padding: '20px',
//         outline: '1px solid black',
//         zIndex: 100
//     },
// };


const LinearProgressWithLabel = (props)=> {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }


export default function Download({apiDataPath, apiParams, title, toggle, isOpen, dataPostProcessor}) {

    const limit = 1000;

    const {data, isLoading, progressData} = useFetchAll(
        ({pageParam}) => {
            return ApiClient().get(apiDataPath, {
                params: {
                    offset: pageParam,
                    limit: limit,
                    distinct: true,
                    ...apiParams
                }
            }).then(response => {
                return response.data
            })
        },
        ["download-all", apiDataPath, apiParams],
        {}
    )


    const isComplete = progressData && progressData.progress >= 1;

    // TODOL: Fix Progress Bar / Progress Label Value 
    const percentage = progressData ? Math.round(progressData.progress * 80) + 20 : 20;
    const progressLabel = progressData ? (
        progressData.progress >= 1 ? "CSV file ready!" : `${percentage}%`
    ) : "Initializing download...";

    const csvData = (dataPostProcessor && data) ? dataPostProcessor(data) : data;
    const notify = () => toast.success("Download Successful!");

    return (
        <>
            {/* Has error */}
            <Modal open={isOpen} 
                   onClose={toggle}
                   style={{display:'flex',alignItems:'center',justifyContent:'center'}}
            >
                <Grid xs={10} sm={7} md={5}>
                    <Card sx={{pb:2, m:{xs:1,md:0},minHeight:'20vh'}}>

                        <Grid sx={{p:2}}>
                            <Typography variant='h6' color='#0F2FEB'> 
                                Download {title}
                            </Typography>
                        </Grid>

                        <Divider sx={{mb:2}} />

                        <Grid sx={{px:2, mb:2}}>
                            <Typography  variant="subtitle">
                                Preparing the CSV file. Please keep this modal open until the CSV file is ready.
                            </Typography>
                        </Grid>

                        <Grid sx={{px:2}}>
                            <Grid direction='column' md={12}>
                                <Box sx={{ width: '100%' }}>
                                        {/* <LinearProgressWithLabel value={percentage}  /> */}

                                        { progressData?.progress ?
                                            <Box sx={{color:'green'}}>CSV File is Ready!</Box>: <LinearProgress color='success'/>
                                        }
                                </Box>
                            </Grid>

                            <Grid container justifyContent={'flex-end'} sx={{mt:2,}}>
                                <Button 
                                    variant="outlined" 
                                    size='small'
                                    onClick={toggle}
                                    sx={{borderRadius:'5px' , textTransform:'none',color:'blue', border:'solid 1px blue', fontWeight:'bold', px:4,mr:1,
                                        '&:hover': {
                                        border:'solid 1px blue',
                                        backgroundColor:'#fcfcfds'
                                        }}}
                                    > 
                                    Cancel
                                </Button>

                                <Button
                                    variant='contained'
                                    disabled={!isComplete}
                                    sx={{textTransform:'none',backgroundColor:'#0F2FEB','&:hover': {
                                        backgroundColor: '#0F2FEB',
                                    }}}
                                    component="a" // Render the Button component as an anchor tag
                                    href={`data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`}
                                    download={`${title}.csv`}
                                    target="_blank"
                                    onClick={()=> {notify();toggle()}}>
                                     Save CSV File  
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Modal>
        </>
    )

}

// {/* <div className="d-flex align-items-center">
//                     <div>
//                         <h3 className="text-primary">Download {title}</h3>
//                         <div className="mt-2 d-flex justify-content-between">{message}</div>
//                     </div>
//                 </div>
//                 <div className={"row-md-12 mt-3"}>
//                     <div className="col-md-12">
//                         Preparing the CSV file. Please keep this modal open until the CSV file is ready.
//                     </div>
//                 </div>

//                 <div className={"row-md-12 mt-3"}>
//                     <div className="col-md-12">
//                         <div className="progress" style={{
//                             height: '30px',
//                         }}>
//                             <div
//                                 className={`progress-bar ${isComplete ? 'bg-success' : 'bg-primary progress-bar-animated progress-bar-striped'}`}
//                                 aria-valuemin={0}
//                                 aria-valuemax={100}
//                                 style={{width: `${percentage}%`}}
//                                 aria-valuenow={percentage}
//                             >

//                                 {progressLabel}
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="row-md-12 mt-3">
//                     <div className="col-md-12 d-flex justify-content-end align-items-end">
//                         <button type="button" className="btn button-style mx-2 btn-outline-primary" onClick={toggle} >
//                             Cancel
//                         </button>

//                         <CSVLink
//                             filename={`${title}.csv`}
//                             disabled={!isComplete}
//                             className={`btn btn-primary button-style ${isComplete ? '' : 'disabled'}`}
//                             data={csvData || []} target="_blank">
//                             <i className="bi bi-download"/>{" "}

//                             Save CSV File
//                         </CSVLink>

//                     </div>
//                 </div> */}