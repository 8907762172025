import { useContext, useState, useEffect } from "react";
import Back from "../../../common/components/BackNavigateComponent";
import SelectorProvider from "../common/providers/selectorProvider";
import { GlobalStyles, Grid, Typography } from "@mui/material";
import { WindowSizeContext } from "../../../common/context/WindowSizeContext";
import AsOfDate from "./AsOfDate";
import { useHasScrolledDownDetector } from "../../../common/utils/hooks";
import SMACMetrics from "./SMACMetrics/index.jsx";
import { PERIOD_MONTHLY } from "../../../Constants/general.js";

export default function SMACPerformance() {
    const windowSize = useContext(WindowSizeContext)
    const scrolling = useHasScrolledDownDetector()
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {    
        if (scrolling && tooltipOpen) {
            setTooltipOpen(false);
        }
    }, [scrolling, tooltipOpen]);
    
    return <SelectorProvider defaults={{
        'period': PERIOD_MONTHLY
    }}>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />
        <Grid container>
            <Grid item xs={12} sx={{ display: !windowSize.isOnMobile && "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                <Grid item xs display="flex" sx={{ alignItems: "center" }}>
                    <Back />
                    <Typography variant="h5" ml={3}> SMAC Performance</Typography>
                    {/* <InformationToolTip onChange={(val)=>setTooltipOpen(val)} open={tooltipOpen} information={'TOOLTIP INFO HERE'} /> */}
                </Grid>
                <Grid item xs>
                    <AsOfDate/>
                </Grid>
            </Grid>

            <SMACMetrics/>

        </Grid>
    </SelectorProvider>
}