
import { useTable,usePagination, useSortBy } from 'react-table'
import { useMemo, useState } from 'react';

export default function CampaignTable(props) {
    const columns = useMemo(
        () => [
          {
            Header: 'Title',
            accessor: 'title'
          },
          {
            Header: 'Broadcast Date',
            accessor: 'targetDatetime',
            Cell: ({row}) => {
                const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
                const broadCasteDatetime = new Date(row.values.targetDatetime)
                const formattedDatetime = row.values.targetDatetime ? broadCasteDatetime.toLocaleDateString([],options) : ''
                return  formattedDatetime
            },
          },
        //   {
        //     Header: 'Status',
        //     accessor: '',
        //   },
          {
            Header: 'Targets',
            accessor: 'summary.totalTargets',
          },
          {
            Header: 'No. of sent thru Viber',
            accessor: 'summary.sentByType.viber',
          },
          {
            Header: 'No. of sent thru SMS',
            accessor: 'summary.sentByType.sms',
          },
          {
            Header: 'Pending',
            accessor: 'summary.pending',
			Cell :({row}) => {
				return row.values['summary.pending'] && row.values['summary.totalTargets']  ? (row.values['summary.pending'] / row.values['summary.totalTargets']) * 100 + '%' : '0%'
			}
          },
          {
            Header: 'Delivered Rate',
            accessor: 'summary.rates.delivered',
			Cell : ({row})=> {
				return row.values['summary.rates.delivered'] ? row.values['summary.rates.delivered']*100 + '%' : '0%'
			}
          }
        ],
        []
    )
    
    const data = useMemo(
        ()=> props.data, [props.data]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page
	
		// The rest of these things are super handy, too ;)
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
      } = useTable(
		{ 	columns, data,
			initialState: { pageIndex: 0, pageSize: 10 } 
		},
			useSortBy,
			usePagination,
		)
      
    return <div className="table-responsive">
    <table className="table table-sm  table-striped table-hover align-middle" id="campaig-list-table" {...getTableProps()}>
        <thead className='bg-primary text-white'>
        {// Loop over the header rows
        headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
            {// Loop over the headers in each row
            headerGroup.headers.map(column => (
                // Apply the header cell props
                <th className=' align-middle py-2' {...column.getHeaderProps(column.getSortByToggleProps())}>
                {// Render the header
                column.render('Header')}
				<span>
				  {column.isSorted
					? column.isSortedDesc
					  ? ' 🔽'
					  : ' 🔼'
					: ''}
				</span>
                </th>
            ))}
            </tr>
        ))}
        </thead>
        {/* Apply the table body props */}
        <tbody  {...getTableBodyProps()}>
        {// Loop over the table rows
        page.map(row => {
            // Prepare the row for display
            prepareRow(row)
            return (
            // Apply the row props
            <tr {...row.getRowProps()}>
                {// Loop over the rows cells
                row.cells.map(cell => {
                // Apply the cell props
                return (
                    <td  className="align-middle py-3" style={{fontSize: '1rem'}} {...cell.getCellProps()}>
                    {// Render the cell contents
                    cell.render('Cell')}
                    </td>
                )
                })}
            </tr>
            )
        })}
        </tbody>
    </table>
	<div className="d-flex  align-items-baseline justify-content-end">
		<nav aria-label="pagination">
			<ul className="pagination">
				<li className="page-item">
					<button type="button" className="btn btn-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}><i className="bi bi-chevron-double-left"></i></button>
				</li>
				<li className="page-item">
					<button type="button" className="btn btn-link" onClick={() => previousPage()} disabled={!canPreviousPage}><i className="bi bi-chevron-left"></i></button>
				</li>
				<li className="page-item">
					<button type="button" className="btn btn-link" onClick={() => nextPage()} disabled={!canNextPage}><i className="bi bi-chevron-right"></i></button>
				</li>
				<li className="page-item">
					<button type="button" className="btn btn-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}><i className="bi bi-chevron-double-right"></i></button>
				</li>
			</ul>
		</nav>
		
		<div>
			Page <strong> {pageIndex + 1 } </strong> of {pageOptions.length} | Go to Page: 
		</div>
		<div className="mx-1">
			<input type="number" className="form-control form-control-sm" style={{ width: '4.5rem'}} defaultValue={pageIndex + 1}
				onChange={e => {
				const page = e.target.value ? Number(e.target.value) - 1 : 0
				gotoPage(page)
				}}
			/>
		</div>
		<div className="mx-1">
			<select className="form-select form-select-sm"
				value={pageSize}
				onChange={e => {
					setPageSize(Number(e.target.value))
				}}>
				{[10, 20, 30, 40, 50].map(pageSize => (
					<option key={pageSize} value={pageSize}>
					Show {pageSize}
					</option>
				))}
			</select>
		</div>
	</div>
</div>
}