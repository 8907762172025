import React from "react";
import { useChartList } from "../../../../common/API/hooks";
import EditChartModal from "../../ChartGallery/EditChartModal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function ChartSelector({ tableId, onChangeChart, chart: selectedChart }) {
    const { data: chartList, isLoading } = useChartList({ table_id: tableId })

    return (
        <>
            <Box sx={{ maxHeight: "80%" }}>
                <Grid container spacing={2} direction="row" >
                    <Grid item md={4}>
                        <Card sx={{ alignItems: "center", justifyContent: "center", height: "100%"}} xs={12}>
                            <CardContent sx={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>
                                <EditChartModal
                                    isCreateNewChart={true}
                                    tableId={tableId}
                                    onCreate={newData => {
                                        onChangeChart(newData.id)
                                    }} />
                            </CardContent>
                        </Card>
                    </Grid>
                    {
                        chartList && chartList.sort((a, b) => {
                            if (a.created_at < b.created_at) {
                                return 1;
                            }
                            if (a.created_at > b.created_at) {
                                return -1;
                            }
                            return 0;
                        }).map((chart) => {
                            const isActive = selectedChart === chart.id;
                            return <Grid item md={4} key={chart.id} >
                                <Card sx={{ alignItems: "center", justifyContent: "center", height: "100%" }}  >
                                    <CardContent>
                                        <Grid container>
                                            <Grid item md={11} onClick={() => {
                                                onChangeChart(chart.id)
                                            }}>
                                                <Typography>{chart.title}</Typography>
                                            </Grid>

                                            {
                                                chart?.is_mine && chart?.is_editable && <Grid item md={1}>
                                                    <EditChartModal chartObjValues={chart} tableId={tableId} />
                                                </Grid>
                                            }

                                            <Grid item xs={12} marginTop={1} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                {chart.image_url ? (
                                                    <Box component="img" src={chart.image_url} alt={chart.title + 'preview'} sx={{ height: '10rem', width: '100%' }} />
                                                ) : (
                                                    <Typography> No preview available </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        })
                    }
                </Grid>
            </Box>
            {/* <div style={{maxHeight: "80%", overflowY: "scroll"}} className="p-4 chart-selector">
                <div className="row">
                    <div className={`col-4 d-flex chart-card `}>
                        <div className="card w-100 mb-4">
                            <div className="card-body d-flex align-items-center justify-content-center">
                                <EditChartModal
                                    isCreateNewChart={true}
                                    tableId={tableId}
                                    onCreate={newData => {
                                        onChangeChart(newData.id)
                                    }}/>
                            </div>
                        </div>
                    </div>

                    {
                        chartList && chartList.sort((a,b) => {
                            if (a.created_at < b.created_at) {
                                return 1;
                            }
                            if (a.created_at > b.created_at) {
                                return -1;
                            }
                            return 0;
                        }).map((chart) => {
                            const isActive = selectedChart === chart.id;
                            return <div className={`col-4 d-flex chart-card ${isActive ? "active" : ""}`}>
                                <div className="card w-100 mb-4">
                                    <div className="card-body">
                                        <div className="col-11" onClick={() => {
                                            onChangeChart(chart.id)
                                        }}>
                                            <h6>{chart.title}</h6>

                                            <div className="d-flex justify-content-center align-items-center mt-3">
                                                {chart.image_url ? (
                                                    <img
                                                        className="img-fluid"
                                                        src={chart.image_url}
                                                        alt={chart.title + ' preview'}
                                                    />
                                                ) : (
                                                    <span> No preview available </span>
                                                )}
                                            </div>
                                        </div>
                                        {
                                            chart?.is_mine && chart?.is_editable && <div className={"col-1 position-absolute top-0 end-0 m-2"}>
                                                <EditChartModal chartObjValues={chart} tableId={tableId}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div> */}
        </>
    )
}