import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { useTestConnect, useSave } from '../utils';
import {toast} from 'react-toastify';
import { styled } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


export default function BigQueryConfig({ closeModal, dataSourceHostName, setIsSubmitted }) {

    const hostType = 'BigQuery';
    const [projectId, setProjectId] = useState('');
    const [credentials, setCredentials] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();
    // Custom hooks
    const { testConnect: testConnection } = useTestConnect()
    const { saveHost: saveHosts } = useSave()

    // handleTestConnect that uses custom hook
    const handleTestConnect = () => {
        const data = {
            host_type: hostType,
            project_id: projectId,
            credentials_json: credentials,
        }
        setIsSubmitted(true);
        setIsLoading(true);
        testConnection(data).finally((data) => {
            setIsLoading(false);
            setIsSubmitted(false);
        });
    }

    const handleSave = () => {
        const connectionConfig = {
            credentials_json: JSON.parse(credentials),
        };

        const hostData = {
            name: dataSourceHostName,
            host_type: hostType,
            connection_config: connectionConfig,
            is_active: true
        };

        setIsSubmitted(true);
        setIsLoading(true);
        saveHosts(hostData).then(() => { // custom hook for saving hosts
                closeModal();
                setIsSubmitted(false);
                setIsLoading(false);
                return toast.success('Data source host saved!');
            })
            .catch(error => {
                console.error(error);
                return toast.error("Save host failed!"); 
            });
    }

    const handleFileUpload = (event) => {
        let file = event.target.files[0];
        let reader = new FileReader();

        reader.onload = (event) => {
            try {
                let json = JSON.parse(event.target.result);
                setCredentials(JSON.stringify(json, null, 2)); 
            } catch (error) {
                console.error('Error reading the file:', error);
            }
        };
        
        reader.onerror = (event) => {
            console.error('Error reading the file:', event.target.error);
        };

        if (file) {
            reader.readAsText(file);
            setSelectedFile(file.name)
        }
    };

    const isSubmitDisabled = !projectId || !credentials || !dataSourceHostName;

    function RenderContent() {
        return (
            <>
                <Typography variant="h6" sx={{ml:3, mt:2,  fontWeight:'bold'}}>Connection Configuration</Typography>

                <Grid item md={12} sx={{ml:6, mt:1}}>
                    <Stack direction={'column'}>
                        <Typography variant="subtitle" sx={{fontWeight:'bold',color:'#878787'}}>Project Id:</Typography>

                        <TextField
                           id="projectInput"
                           size={'small'} 
                           placeholder="Enter Project ID" 
                           value={projectId}
                           onChange={(e) => setProjectId(e.target.value)}
                           sx={{width:'95%', mt:1}}
                        />
                    </Stack>
                </Grid>

                <Grid item md={12} sx={{ml:6, mt:1}}>
                    
                    <Typography variant="subtitle" sx={{ fontWeight:'bold',color:'#878787'}}>Credentials:</Typography>

                    <Stack direction='row' sx={{width:'60%', mt:1}} spacing={1}>
                        <Button component="label" size='small' variant="contained" startIcon={<CloudUploadIcon />}
                                sx={{textTransform:'none'}}>
                            Choose File
                            <VisuallyHiddenInput type='file' accept='.json'
                                onChange={handleFileUpload}/>
                        </Button>

                        <Typography variant="subtitle" alignSelf='center' sx={{color:'black'}}>
                            {selectedFile ? selectedFile : 'No File Chosen'}
                            
                        </Typography>
                    </Stack>

                </Grid>

                <Grid container justifyContent={'flex-end'} sx={{mt:2, pr:4.5}}>
                    <Button 
                    variant="contained" 
                    size='small'
                    disabled={isSubmitDisabled}
                    onClick={handleTestConnect}
                    sx={{borderRadius:'5px',px:1.6, textTransform:'none', backgroundColor:'#66e472', fontWeight:'bold'
                    ,'&:hover': {
                        backgroundColor: `${!isSubmitDisabled ? '#66e472' : ''}`,
                        }}}> 
                    Test Connect</Button>
                </Grid>

                <Grid container justifyContent={'flex-end'} sx={{mt:2, pr:4.5}}>
                    <Button 
                        variant="contained" 
                        size='small'
                        disabled={isSubmitDisabled}
                        onClick={handleSave}
                        sx={{borderRadius:'5px', mr:2,px:5, textTransform:'none', backgroundColor:'blue',fontWeight:'bold'
                        ,'&:hover': {
                            backgroundColor: `${!isSubmitDisabled ? 'blue' : ''}`,
                            }}}
                        color="success"> 
                        Save
                    </Button>

                    <Button 
                        variant="outlined" 
                        size='small'
                        onClick={closeModal}
                        sx={{borderRadius:'5px' , textTransform:'none',color:'blue', border:'solid 1px blue', fontWeight:'bold', px:4,
                            '&:hover': {
                            border:'solid 1px blue',
                            backgroundColor:'#fcfcfds'
                          }}}
                        > 
                        Cancel
                    </Button>
                </Grid>
            </>
        )

    }
    return (
        <>
            {isLoading ? <LoadingComponent /> : RenderContent()}
        </>
    )
}