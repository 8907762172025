import { useEffect, useState, useContext } from "react"
import { Grid } from "@mui/material"
import { WindowSizeContext } from "../../../../../../common/context/WindowSizeContext"
import PerformancePerChannel from "../../branchView/Operations/PerformancePerChannel"
import {useTogglePeriodContext} from "../Provider/context";
// import PerformancePerChannel from "./Operations/PerformanacePerChannel"

export default function Operations({ params, business_unit, onTogglePeriodChange }) {
    const {period} = useTogglePeriodContext()

    const windowSize = useContext(WindowSizeContext);

    const [filters, setFilters] = useState({
        group: "channel",
        month: params?.month,
        year: params?.year,
        metric_column: 'actual_sales'
    })

    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        setSelectedRows([])
    }, [filters.group, filters.metric_column])

    function onDropdownFilterChange(value, field) {
        setFilters({
            ...filters,
            [field]: value
        })
    }

    return (<>
        <Grid container >
            <Grid item xs={12} py={.5}>
                <PerformancePerChannel params={params} period={period} business_unit={business_unit} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropdownFilterChange}>
                </PerformancePerChannel>
            </Grid>
            {/* <Grid item xs={12} py={.5}>
                <StorePerformance params={params} period={period} business_unit={business_unit} onTogglePeriodChange={onTogglePeriodChange} filters={filters} onDropDownFilterChange={onDropdownFilterChange}>
                </StorePerformance>
            </Grid> */}
        </Grid>
    </>
    )
}