import React from "react";
import {useSMACMembersDataV3, useSMACToplineMetricsV2} from "./hooks";
import moment from "moment/moment";
import sales from "../../../../../img/sales.svg";
import {TopLineContext} from "../../contexts";
import transactions from "../../../../../img/transactions.svg";
import frequency_visit from "../../../../../img/frequency_visit.svg";
import basket_size from "../../../../../img/basket_size.svg"
import units_sold_per_txn from "../../../../../img/units_sold_per_txn.svg"
import groupIcon from "../../../../../img/groupIcon.svg";

export default function ToplineItemsProvider({children, params, period, viewBy, blacklistItems}) {
    if (!blacklistItems) {
        // by default, we do not show the following items:
        blacklistItems = [
            'cardMembers',
        ]
    }

    const {data: toplineBaseData, isLoading: isToplineBaseDataLoading} = useSMACToplineMetricsV2({
        params,
        viewBy,
        period
    })
    const {data: toplineLastYearBaseData, isLoading: isToplineLastYearBaseDataLoading} = useSMACToplineMetricsV2({
        params: {
            ...params,
            year: params.year - 1
        },
        viewBy,
        period,
        queryOptions: {
            isQueryForVsLastYear: true
        }
    })

    const isQoQ = period === 'qoq';
    const isMTD = period === 'mtd';
    let paramsPeriod = moment().format('YYYY-MM-DD');
    if (params?.month?.[0] && params?.year) {
        paramsPeriod = `${params?.year}-${params?.month?.[0].toString().padStart(2, '0')}-01`;
    }

    let periodStartDate = moment(paramsPeriod).startOf('year').format('YYYY-MM-DD');
    let periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
    if (isQoQ) {
        periodStartDate = moment(paramsPeriod, 'YYYY-MM-DD').startOf('quarter').format('YYYY-MM-DD');
        periodEndDate = moment(periodStartDate).endOf('quarter').format('YYYY-MM-DD');
    } else if (isMTD) {
        periodStartDate = moment(paramsPeriod).startOf('month').format('YYYY-MM-DD');
        periodEndDate = moment(paramsPeriod).endOf('month').format('YYYY-MM-DD');
    }

    // fixme: improve readability
    const paramsBasedOnPeriod = isQoQ ? {
            ...params,
            month: Array.from({length: 3}, (_, i) =>
                moment(`${params?.year}-${params?.month?.[0]?.toString().padStart(2, '0')}-01`, 'YYYY-MM-DD').startOf('quarter').month() + i + 1
            )
        } : (
            isMTD ?
                {...params} :
                {...params, month: [1, ...(params?.month || [])]}
    );
    // customer card members
    const {totalData, isLoading: isLoadingCardMembersData} = useSMACMembersDataV3({
        params: {...paramsBasedOnPeriod},
        viewBy
    });
    const {totalData: totalDataLastYear, isLoading: isLoadingCardMembersDataLastYear} = useSMACMembersDataV3({
        params: {
            ...paramsBasedOnPeriod,
            year: params.year - 1
        },
        viewBy
    });

    // Calculate total SMAC members and members count by type
    const totalSMACMembers = totalData ? totalData[0]?.card_type_count : 0;
    const totalSMACMembersLastYear = totalDataLastYear ? totalDataLastYear[0]?.card_type_count : 0;


    const toplineItems = [
        {
            key: 'cardMembers',
            isLoading: isLoadingCardMembersData,
            periodFrom: periodStartDate,
            periodTo: periodEndDate,
            primaryValue: totalSMACMembers,
            targetValue: 0,
            comparisonValues: [
                {
                    label: 'vs Last Year',
                    value: totalSMACMembersLastYear,
                    isLoading: isLoadingCardMembersDataLastYear
                },
            ],
            textMap: {
                imgIcon: groupIcon,
                primaryLabel: 'Total SMAC Members',
                targetLabel: 'Target',
                prefix: '',
                isThousandsOnly: true
            }
        },
        {
            isLoading: isToplineBaseDataLoading,
            periodFrom: periodStartDate,
            periodTo: periodEndDate,
            // ...fromToPeriod,
            primaryValue: toplineBaseData?.gross_sales_sum,
            targetValue: 0,
            comparisonValues: [
                {
                    label: 'vs Last Year',
                    value: toplineLastYearBaseData?.gross_sales_sum,
                    isLoading: isToplineLastYearBaseDataLoading
                },
            ],
            textMap: {
                imgIcon: sales,
                primaryLabel: viewBy === 'chain' ? 'Sales' : 'SMAC Sales',
                targetLabel: 'Target',
                isThousandsOnly: viewBy !== 'chain'
            }
        },
        {
            isLoading: isToplineBaseDataLoading,
            periodFrom: periodStartDate,
            periodTo: periodEndDate,
            // ...fromToPeriod,
            primaryValue: toplineBaseData?.txn_count,
            targetValue: 0,
            comparisonValues: [
                {
                    label: 'vs Last Year',
                    value: toplineLastYearBaseData?.txn_count,
                    isLoading: isToplineLastYearBaseDataLoading
                },
            ],
            textMap: {
                imgIcon: transactions,
                primaryLabel: viewBy === 'chain' ? 'Transactions' : 'SMAC Transactions',
                targetLabel: 'Target',
                isThousandsOnly: viewBy !== 'chain'
            }
        },
        {
            isLoading: isToplineBaseDataLoading,
            periodFrom: periodStartDate,
            periodTo: periodEndDate,
            // ...fromToPeriod,
            primaryValue: toplineBaseData?.avg_frequency,  // fixme: I don't think you are supposed to average the average frequency
            targetValue: 0,
            comparisonValues: [
                {
                    label: 'vs Last Year',
                    value: toplineLastYearBaseData?.avg_frequency,
                    isLoading: isToplineLastYearBaseDataLoading
                },
            ],
            textMap: {
                imgIcon: frequency_visit,
                primaryLabel: viewBy === 'chain' ? 'Frequency of Visit' : 'SMAC Avg Freq. of Visit',
                targetLabel: 'Target',
            }
        },
        {
            isLoading: isToplineBaseDataLoading,
            periodFrom: periodStartDate,
            periodTo: periodEndDate,
            // ...fromToPeriod,
            // primaryValue: toplineBaseData?.gross_sales_sum / toplineBaseData?.txn_count,
            primaryValue: toplineBaseData?.basket_size,
            targetValue: 0,
            comparisonValues: [
                {
                    label: 'vs Last Year',
                    // value: toplineLastYearBaseData?.gross_sales_sum / toplineLastYearBaseData?.txn_count,
                    value: toplineLastYearBaseData?.basket_size,
                    isLoading: isToplineLastYearBaseDataLoading
                },
            ],
            textMap: {
                imgIcon: basket_size,
                primaryLabel: viewBy === 'chain' ? 'Basket Size' : 'SMAC Basket Size',
                targetLabel: 'Target',
                isThousandsOnly: true
            }
        },
        {
            isLoading: isToplineBaseDataLoading,
            periodFrom: periodStartDate,
            periodTo: periodEndDate,
            // ...fromToPeriod,
            primaryValue: toplineBaseData?.units_sold_sum / toplineBaseData?.txn_count,
            targetValue: 0,
            comparisonValues: [
                {
                    label: 'vs Last Year',
                    value: toplineLastYearBaseData?.units_sold_sum / toplineLastYearBaseData?.txn_count,
                    isLoading: isToplineLastYearBaseDataLoading
                },
            ],
            textMap: {
                imgIcon: units_sold_per_txn,
                primaryLabel: viewBy === 'chain' ? ' Units Sold per Transaction' : 'SMAC Units Sold per Txn',
                targetLabel: 'Target',
            }
        },
    ].filter(item => {
        return !blacklistItems.includes(item.key)
    })

    return <TopLineContext.Provider value={{
        items: toplineItems
    }}>
        {children}
    </TopLineContext.Provider>
}