import { useState, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Button, Box } from '@mui/material';
import { WindowSizeContext } from '../../../../../common/context/WindowSizeContext';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 'none',
  },
}));

export default function SKUCellTooltip({ skuData }) {
  const [open, setIsOpen] = useState(false);
  const { isOnMobile } = useContext(WindowSizeContext);
  const handleToggle = () => setIsOpen((prev) => !prev);

  const tooltipProps = useMemo(() => {
    if (isOnMobile) {
      return {
        open: open,
        disableTouchListener: true,
        disableHoverListener: true,
        onClose: () => setIsOpen(false),
      };
    }
    return {};
  }, [isOnMobile, open]);

  if (!skuData || skuData.length <= 1) return null;

  return (
    <LightTooltip
      key={isOnMobile}
      title={
        <Box sx={{ maxHeight: '200px', overflow: 'auto' }} px={1}>
          <ul style={{
            paddingLeft: '1.5rem',
            margin: '0',
            listStyleType: 'disc',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            gap: '10px',
            wordBreak: 'break-word',
          }}>
            {skuData.slice(1).map((sku, index) => (
              <li key={index} style={{ marginRight: '10px', fontSize:'15px',  fontWeight:'normal' }}>{sku}</li>
            ))}
          </ul>
        </Box>
      }
      arrow
      placement="bottom-end"
      {...tooltipProps}
    >
      <Button
        size="small"
        onClick={handleToggle}  
        sx={{ textTransform: 'none' }}
      >
        View More
      </Button>
    </LightTooltip>
  );
}
