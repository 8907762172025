// Filters.jsx

import React from 'react';
import {Grid} from '@mui/material';
import FilterMonth from '../../../../common/components/FilterMonth';
import FilterYear from '../../../../common/components/FilterYear';
import {useAvailableDateRangeV2} from '../hooks';
import {useFiltersContext} from '../Provider/context';
import FilterCompany from './FilterCompany';

export default function Filters() {
    const { filters, setFilters } = useFiltersContext();
    const { isLoading, isSuccess, data: dateRangeData } = useAvailableDateRangeV2();

    const dateRange = (dateRangeData && !isLoading) ? {
        from: dateRangeData.min,
        to: dateRangeData.max
    } : {};

    function onDropdownFilterChange(key, value) {
        setFilters((prevFilters) => {
            const updatedFilters = {
                ...prevFilters,
                [key]: value,
            };

            return updatedFilters;
        });
    }

    return (
        <>
            <Grid item xs={12} sm={5}>
                <FilterCompany
                    filters={filters}
                />
            </Grid>
            <Grid item xs={12} sm={1.5}>
                <FilterMonth
                    onDropdownChange={(value) => onDropdownFilterChange('month', value)}
                    filters={filters}
                    dateRangeData={dateRange}
                    isSuccess={isSuccess}
                    dateRangeIsLoading={isLoading}
                />
            </Grid>
            <Grid item xs={12} sm={1.5}>
                <FilterYear
                    onDropdownChange={(value) => onDropdownFilterChange('year', value)}
                    filters={filters}
                    dateRangeData={dateRange}
                    isSuccess={isSuccess}
                    dateRangeIsLoading={isLoading}
                />
            </Grid>
        </>
    );
}
