import React, { useContext, useMemo} from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useAvailableChannels } from '../hooks';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { getSelectedOption } from '../../../../common/utils';
import { useFiltersContext } from '../Provider/context';

export default function FilterChannel() {
    const windowSize = useContext(WindowSizeContext);
    const { filters, setFilter } = useFiltersContext();
    
    const { data, isLoading } = useAvailableChannels()

    const options = useMemo(() => {
        if (isLoading || !data || !data.data || !data.data.length) {
            return []
        }

        return data.data.map(row => {
            return {
                value: row.cm_channel_desc,
                label: row.cm_channel_desc,
            }
        })
    }, [data, isLoading])

    return (
        <ConditionalSelectComponent
            id="channel_filter"
            labelId="channel_filter-label"
            options={options}
            label="Select Channel"
            value={filters && !isLoading ? (windowSize.isOnMobile ? filters?.channel : getSelectedOption(filters?.channel, options)) : '' &&  null}
            onSelectChange={(event,newValue)=>{
                windowSize.isOnMobile ? setFilter('channel', event.target.value)
                : setFilter('channel', newValue?.value)  
            }}
            endAdornment={true}
            isLoading={false}
            // clearSelected={() => setFilter('channel', '')}
            disableClearable={true}
    />
    );
}