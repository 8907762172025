export const TABLE_CUSTOMER_BU_SOW = 'l4_sbu_agg_customer_bu_sow'
export const TABLE_CUSTOMER_METRICS = 'l4_sbu_agg_customer_metrics'
export const TABLE_CUSTOMER_V2 = 'l3_sbu_customer_v2'

// the following tables have the same structure and can be used interchangeably
const TABLE_L3_CUSTOMER_ATTRIBUTES = 'l3_rsa_customer_attributes';
const TABLE_VIEW_L3_CUSTOMER_ATTRIBUTES = 'vw_rsa_customer_attributes';
export const TABLE_CUSTOMER_ATTRIBUTES = TABLE_L3_CUSTOMER_ATTRIBUTES;
export const TABLE_CUSTOMER_ATTRIBUTES_WITH_BRGY = TABLE_L3_CUSTOMER_ATTRIBUTES


export const TABLE_VIEW_BRANCH_SOW = 'branch_sow'
export const TABLE_VIEW_CUSTOMER_CONTACTABILITY = 'customer_contactability'
export const TABLE_VIEW_VALUE_SEGMENTS = 'value_segments'
export const TABLE_VIEW_CUSTOMER_BEHAVIOR = 'customer_behavior'
export const TABLE_VIEW_CHAIN_CADS_CUSTOMER_BIN = 'chain_cads_customer_bin'
export const TABLE_VIEW_CHAIN_CADS_TXN_BIN = 'chain_cads_txn_bin'
