import { useState } from 'react';
import { TogglePeriodContext } from './context';

export default function TogglePeriodProvider( {children} ) {
  const [period, setPeriod] = useState('mtd'); // Initial state

  const updatePeriod = (value) => {
    setPeriod(value);
    // setPeriod('mtd'); // TODO: remove when data for YTD and QoQ are available
  };

  return (
    <TogglePeriodContext.Provider value={{ period, updatePeriod }}>
      {children}
    </TogglePeriodContext.Provider>
  );
};