import { useEffect, useState } from "react";
import CardComponent from "../../../common/components/CardComponent";
import SmsContent from "./smsContent";
import ViberContent from "./viberContent";
import EmailContent from "./emailContent";
import SampleDbTargets from "./sampleDbTargets";

export default function CampaignCreationContent(props) {
    function RenderContent(channel) {
        switch(channel) {
            case 'viber' : 
                return  <CardComponent title="Viber Details" id="collapseCampaignDetailsCard" size="small">
                    <ViberContent values={props.values} onChange={props.onChange} isFallbackSmsRequired = {props.isFallbackSmsRequired} setIsFallbackSmsRequired = {props.setIsFallbackSmsRequired} />
                </CardComponent>
            
            case 'email' : 
                return <CardComponent title="Email Details" id="collapseCampaignDetailsCard" size="small">
                    <EmailContent values={ props.values} onChange={props.onChange} />
            </CardComponent>
            default :
                return <CardComponent title="SMS Details" id="collapseCampaignDetailsCard" size="small">
                <SmsContent values={props.values} onChange={props.onChange} /> 
            </CardComponent>
        }
    }

    const isMaxTargetsReached = props.values.targets.split('\n').filter(val => val !== '').length >= 11;
    
    return <>
        <div className="mb-3">
            <label className="fw-bold">Promo Title <small>*</small></label>
            <input className="form-control" type="text"   onChange={(e)=> props.onChange(e.target.name, e.target.value)} name="title" value={props.values.title}/>
        </div>

        <CardComponent title="Communication Channel" id="collapseCommunicationChannelCard" size="small">
            <div className="row mb-2">
                <label className="fw-semibold col-sm-2">Channel </label>
                <div className="col-sm-10">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio"  onChange={(e)=> props.onChange(e.target.name, e.target.value)} name="channel" id="viber" value="viber" checked={props.values.channel === 'viber'}/>
                        <label className="form-check-label" htmlFor="viber">Viber</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio"  onChange={(e)=> props.onChange(e.target.name, e.target.value)} name="channel" id="sms" value="sms" checked={props.values.channel === 'sms'}/>
                        <label className="form-check-label" htmlFor="sms">SMS</label>
                    </div>                    
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio"  onChange={(e)=> props.onChange(e.target.name, e.target.value)} name="channel" id="email" value="email" checked={props.values.channel === 'email'}/>
                        <label className="form-check-label" htmlFor="email">Email</label>
                    </div>
                </div>
            </div>

            <div className="row mb-2">
                <label className="fw-semibold col-sm-2">Additional Targets {props.values.channel === 'email' ? '(Email)' : '(Mob No.)'} </label>
                <div className="col-sm-10 text-end">
                    <textarea className="form-control" style={{resize: 'none'}} type="text" rows="3" onChange={(e)=> props.onChange(e.target.name, e.target.value)} name="targets" value={props.values.targets}/>
                    { isMaxTargetsReached && <small><i className="bi bi-exclamation-triangle-fill text-warning"></i>Warning: Contact numbers exceeded the maximum limit of 10. Additional inputs will be ignored. </small> }
                </div>
            </div>
        </CardComponent>

        {
            RenderContent(props.values.channel)
        }

        {
            props.values.targetFile === 'db' && <>

        <hr/>
            <SampleDbTargets/>
            </>
        }
    </>
}