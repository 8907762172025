import ApiClient, {LOGIN_LONG_URL, LOGIN_URL} from "../../common/API";
import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import LoadingComponent from "../../common/components/LoadingComponent";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoginIcon from '@mui/icons-material/Login';
import { toast } from 'react-toastify';
import { Grid } from "@mui/material";
import {  LoginContext } from "../../common/context/LoginContext";

export default function LoginForm(props) {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";

    const rememberMe = props.rememberMe;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const params = {
        otp: props.otp,
        username: props.email,
        password: props.password
    }
    
    const { setLoginDetails } = React.useContext(LoginContext)

    const loginUrl = rememberMe ? LOGIN_LONG_URL : LOGIN_URL

    const login = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsLoading(true);

        ApiClient().post(`/${loginUrl}`, params).then(response => {
            setIsLoading(false);
            setIsError(false);
            props.setErrorMessage('')

            const canChangePassword = response.data?.can_change_password || false;
            const requirePasswordChange = response.data?.require_password_change || false;
            
            setLoginDetails({
                canChangePassword : canChangePassword,
                requirePasswordChange : canChangePassword && requirePasswordChange,
                username: response.data?.username
            })
            const tokens = {
                'accessToken': response.data.access,
                'refreshToken': response.data.refresh
            }

            localStorage.setItem('auth-tokens-production', JSON.stringify(tokens));
            localStorage.setItem("remember_me", rememberMe);
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            navigate(from, { replace: true });
        }).catch(error => {
            const errorMsg = error?.response?.data ? error.response.data?.detail : error.message;
            setIsLoading(false);
            setIsError(true);
            toast.error(errorMsg);
        });
    };

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
        >
            <Box
                sx={{
                    typography: "h5",
                    textAlign: "center"
                }}
                style={{
                    fontFamily: "Henry Sans",
                    fontWeight: "bold"
                }}>
                WELCOME
            </Box>
            <Box sx={{
                typography: "body1",
                textAlign: "center",
                mb: "40px",
                mt: "10px"
            }}
                style={{
                    fontFamily: "Henry Sans",
                }}>
                Enter Your Credentials To Access Your Account
            </Box>
            <TextField
                InputProps={{
                    startAdornment: <EmailIcon sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />,
                }}
                required
                id="outlined"
                label="Username / Email"
                type="text"
                placeholder="Enter Your Email or Username"
                fullWidth
                sx={{ my: 1, width: "100%" }}
                onChange={(e) => props.setEmail(e.target.value)} value={props.email}
            />
            <TextField sx={{ my: 1, mt: "15px", width: "100%" }}
                required
                id="outlined"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter Your Password"
                InputProps={{
                    startAdornment:
                        < LockIcon sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />,
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>,
                }}
                onChange={(e) => props.setPassword(e.target.value)} value={props.password}
            >
            </TextField>
            <Grid textAlign='center' sx={{ my: 1 }}>
                {
                    isLoading ? <LoadingComponent /> :
                        <Button
                            type="submit"
                            startIcon={<LoginIcon />}
                            size="large"
                            variant="contained"
                            disabled={props.disabled}
                            onClick={() => { login() }}
                            sx={{ my: 1, width: "100%", fontFamily: "Henry Sans", variant: "h6" }}>Sign in
                        </Button>
                }
            </Grid>
        </Box>
    )
}