import React from 'react';
import SortIcon from '@mui/icons-material/Sort';
import IconButton from '@mui/material/IconButton';

const SortOrder = ({ sortOrder, setSortOrder }) => {
    return (<IconButton
        sx={{
            color: "white",
            borderRadius: 2,
            backgroundColor: "primary.main",
            '&:hover': {
                backgroundColor: "primary.main",
                color: 'white',
            },
            '&.selected': {
                backgroundColor: "primary.main",
                color: 'white',
            },
        }}
        onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
    >
        {sortOrder === 'asc'
            ? <SortIcon sx={{ rotate: '180deg', transform: 'rotateY(180deg)' }} />
            : <SortIcon />
        }
    </IconButton>
    );
}

export default SortOrder;
